import React,{Component} from 'react'
import Window from '../2/Controls/Window';

class SalesModifiers extends Component{

    constructor(props)
    {
      super(props)
      this.state = {
        selectedModifierGroupID:"",
        selectedModifiers:[],
        applicableModifiers:[],
        applicableModifierGroups:[],
        qty:0,
        amount:0
      }
    }

    render() {
        return   <Window 
        // modal={true}
        header={"Modifier (s)"}  
        width={"600px"}
        processing={this.state.saving}
        onClose={this.props.onClose}
        onButtonClick={(buttonType)=>{
            if(buttonType.toLowerCase()==="ok"){
                this.props.onModifierSelectionCompletion()
            }
            else
            {
                this.props.onClose()
            }
        }}
        buttons={
            [{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"}]
        }
        >
                <div className="box-body">
                    {this.props.mobile && (
                        <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
                            <div className="form-group">
                                <label for="tax">Modifier Group </label>
                                <select
                                    className="form-control"
                                    value={this.props.selectedModifierGroupID}
                                    onChange={(evt) =>
                                        this.props.onModifierGroupSelected(
                                            evt.target.value
                                        )
                                    }
                                >
                                    {this.props.applicableModifierGroups.map(
                                        (m) => (
                                            <option value={m.ModifierGroupID}>
                                                {m.ModifierGroup}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="row" style={{ marginLeft: "0", marginRight: "0" }}>
                        {!this.props.mobile && (
                            <div
                                className="col-md-3 "
                                style={{ padding: "0px" }}
                            >
                                Modifier Group
                                <div
                                    className="scroll2"
                                    style={{
                                        height: "50vh",
                                        overflowY: "scroll",
                                        overflowX: "hidden",
                                    }}
                                >
                                    {this.props.applicableModifierGroups.map(
                                        (m, index) => (
                                            <button
                                                key={index}
                                                type="submit"
                                                className={
                                                    m.ModifierGroupID ===
                                                    this.props
                                                        .selectedModifierGroupID
                                                        ? "btn btn-lg btn-success btn-flat"
                                                        : "btn btn-lg btn-default btn-flat"
                                                }
                                                style={{
                                                    width: "100%",
                                                    fontSize: "15px",
                                                }}
                                                onClick={() =>
                                                    this.props.onModifierGroupSelected(
                                                        m.ModifierGroupID
                                                    )
                                                }
                                            >
                                                {m.ModifierGroup}
                                            </button>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        <div
                            className="col-md-4 col-xs-6"
                            style={{ padding: "0px" }}
                        >
                            Available
                            <div
                                className="scroll2 newHeight"
                                style={{
                                    height: "50vh",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                }}
                            >
                                {this.props.applicableModifiers &&
                                    this.props.applicableModifiers
                                        .filter(
                                            (f) =>
                                                f.ModifierGroupID ===
                                                this.props
                                                    .selectedModifierGroupID
                                        )
                                        .map((m, index) => (
                                            <button
                                                key={index}
                                                type="submit"
                                                // className="btn btn-lg btn-block btn-default btn-flat"
                                                // className="row product-select-btn-text-full-product-name"
                                                className="btn btn-lg btn-default btn-flat "
                                                style={{
                                                    width: "100%",
                                                    fontSize: "15px",
                                                }}
                                                onClick={() =>
                                                    this.props.onModifierAdded(
                                                        m
                                                    )
                                                }
                                            >
                                                {m.ModifierName} <br></br>
                                                {m.ModifierPrice}/-
                                            </button>
                                        ))}
                            </div>
                        </div>
                        <div
                            className="col-md-5 col-xs-6"
                            style={{ padding: "0px" }}
                        >
                            Selected
                            <div
                                className="scroll2 newHeight"
                                style={{
                                    height: "50vh",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                }}
                            >
                                {this.props.selectedModifiers &&
                                    this.props.selectedModifiers.map(
                                        (m, index) => (
                                            <button
                                                type="submit"
                                                onClick={() =>
                                                    this.props.onModifierRemoved(
                                                        index,
                                                        m.ModifierPrice
                                                    )
                                                }
                                                className="btn btn-lg btn-default btn-flat"
                                                style={{
                                                    width: "100%",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                {m.ModifierName} <br></br>
                                                {m.ModifierPrice}/-
                                            </button>
                                        )
                                    )}
                                {/* {this.props.selectedModifiers && this.props.selectedModifiers.map((m,index)=><div key={index} className="input-group">
                                    <button 
                                        type="submit" 
                                        className="row product-select-btn-text-full-product-name" 
                                        style={{width:"100%", fontSize:"15px"}}
                                        >{m.ModifierName} <br></br>{m.ModifierPrice}/-
                                    </button>
                                    <div className="input-group-btn">
                                        <button 
                                        type="button" onClick={() => this.props.onModifierRemoved(index, m.ModifierPrice)}
                                            style={{height:"70px"}}
                                            className="btn btn-default btn-lg btn-flat">
                                            <i className="glyphicon glyphicon-remove"></i>
                                        </button> 
                                    </div>
                                </div>)} */}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="box-footer"
                    style={{ padding: "5px 0px 0px 0px" }}
                >
                    <div className="amount-quantity-div">
                        <div
                            className="pull-right"
                            style={{
                                fontSize: "16px",
                                paddingRight: "10px",
                                fontWeight: "bold",
                            }}
                        >
                            Qty:{this.props.modifierQty}{" "}
                        </div>
                        <div
                            className="pull-right"
                            style={{
                                fontSize: "16px",
                                paddingRight: "10px",
                                fontWeight: "bold",
                            }}
                        >
                            Amt:{this.props.modifierAmount}{" "}
                        </div>
                    </div>
                </div>
            </Window>
    }
}
export default SalesModifiers