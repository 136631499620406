import React, { Component } from 'react';
import './Common/Common.css'
import firebase from '../firebase';
import './2/style.css';
// import logo from '../images/Posible_logo_new.png'
import Input from './2/Controls/Input';
import Button from './2/Controls/Button';
import Logo from './2/Controls/Logo';

class ForgotPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loginid: '',
            loginIDError:'',
        }
        this.onSendPasswordResetEmail = this.onSendPasswordResetEmail.bind(this)
    }

    async componentDidMount() {
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSendPasswordResetEmail()
        }
    }

    onSendPasswordResetEmail() {

        let loginIDError = ""
        if (this.state.loginid.trim() === '') { loginIDError = 'Please provide your Login id.' }


        if (loginIDError !== "") {
            this.setState({
                loginIDError: loginIDError,
            })
            return
        }

        firebase.auth().sendPasswordResetEmail(
            this.state.loginid)
            .then(function () {
                // Password reset email sent.
                alert("Password reset email sent. Please check your email");
            })
            .catch(function (error) {
                // Error occurred. Inspect error.code.
            });
    }

    render() {
        return <div className={this.props.config.WhiteLabel.ForgotPasswordBackgroundCSS ?  ("login " + this.props.config.WhiteLabel.ForgotPasswordBackgroundCSS):"login"}>
            <br />
            <br />
            <br />
            <div className="login-container">
                    {/* {(!this.props.isElectron()) && <div className="login-logo" style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                    {window.cordova && <img style={{ width: "210px" }} src={"/android_asset/www/images/logo.png"} />}
                    {!window.cordova &&  <img  style={{ width: "210px" }} src={logo}/>}
                    </div>} */}
                    
                    <Logo {...this.props} logoOption={this.props.config.WhiteLabel.LoginLogoOption} height={this.props.config.WhiteLabel.LogoDefaultheight ? this.props.config.WhiteLabel.LogoDefaultheight : "122px"}/>

                    <div>
                        <Input type="loginid" placeholder="Login id" value={this.state.loginid} onChange={(value)=>this.setState({loginid:value,loginIDError:""})} error={this.state.loginIDError}/>
                        <br />
                        <Button type="primary" text="Reset Password" onClick={this.onSendPasswordResetEmail} processing={this.state.loading}/>
                        <br />
                        <br />
                        <center><Button type="link" text="Already an existing user? Sign In" onClick={()=>{this.props.history.push('/')}}/> </center>
                        <br />
                        <br />
                    </div>
            </div>
        </div>}
}

export default ForgotPassword