import React, { Component } from "react";
import CustomerSearch from "./CustomerSearch";
// import CustomerAddress from "./CustomerAddress";
import TimePicker from "../TimePicker";
import Window from '../2/Controls/Window';
import Input from '../2/Controls/Input';

class Delivery extends Component {
    constructor() {
        super();
        this.state = {
            selectedCustomer: null,
            selectedCustomerAddress: null,
            deliveryDate: "",
            deliveryTime: { HH: "", MM: "", AMPM: "" },
            customerError: "",
            placeOfSupply: "",
            customerError: "",
            customerAddressError: "",
            deliveryDateError: "",
            deliveryNotes: "",
        };
        this.onCustomerChange = this.onCustomerChange.bind(this);
        this.onAddCustomer = this.onAddCustomer.bind(this);
        this.onCustomerEdit = this.onCustomerEdit.bind(this);
        this.onSaveDelivery = this.onSaveDelivery.bind(this);
    }

    componentDidMount() {
        let deliveryDate = null;
        // console.log('this.props.deliveryDate', this.props.deliveryDate)
        // console.log('this.props.deliveryCustomer', this.props.deliveryCustomer)
        if (!this.props.deliveryDate) {
            deliveryDate = new Date().toISOString().split("T")[0];
        } else {
            deliveryDate = this.props.deliveryDate;
        }
        this.setState({
            selectedCustomer: this.props.deliveryCustomer,
            selectedCustomerAddress: this.props.deliveryCustomerAddress,
            deliveryDate: deliveryDate,
            deliveryTime: this.props.deliveryTime,
            deliveryNotes: this.props.deliveryNotes,
        });
    }

    onCustomerChange(customer) {
        console.log('customer',customer)
        this.setState({
            selectedCustomer: customer,
            selectedCustomerAddress: null,
        });
    }

    onAddCustomer(newCustomer) {
        this.props.onAddCustomer(newCustomer);
    }

    onCustomerEdit() {
        this.props.onCustomerEdit();
    }

    onSaveDelivery() {
        let customerError = "";
        let customerAddressError = "";
        let deliveryDateError = "";
        if (!this.state.selectedCustomer) {
            customerError = "Please select customer";
        }
        // if(!this.state.selectedCustomerAddress)
        // {
        //     customerAddressError = "Please specify customer address"
        // }
        if (!this.state.deliveryDate) {
            deliveryDateError = "Please select delivery date";
        }
        if (
            customerError.trim() !== "" ||
            customerAddressError.trim() !== "" ||
            deliveryDateError.trim() !== ""
        ) {
            this.setState({
                customerError: customerError,
                customerAddressError: customerAddressError,
                deliveryDateError: deliveryDateError,
            });
            return;
        }
        // console.log('this.state.deliveryTime',this.state.deliveryTime)
        // console.log('this.state.deliveryDate', this.state.deliveryDate)
        this.props.onSave(
            this.state.selectedCustomer,
            this.state.selectedCustomerAddress,
            this.state.deliveryDate,
            this.state.deliveryTime,
            this.state.deliveryNotes,
            this.state.placeOfSupply
        );
    }

    render() {
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px",
        };

        return   <Window 
            header={"Delivery"}  
            width={"600px"}
            processing={this.state.saving}
            onClose={this.props.onClose}
            onButtonClick={(buttonType)=>{
                if(buttonType.toLowerCase()==="ok"){
                    this.onSaveDelivery()
                }
                else
                {
                    this.props.onClose()
                }
            }}
            buttons={
                [{Type:"secondary", Text:"Cancel", Align:"left"},
                {Type:"primary", Text:"OK", Align:"right"}]
            }
            >
                    <div className="row">
                        {/* style={{ padding: "10px", paddingBottom: "0px" }}> */}
                        <div
                            className="col-md-12 col-xs-12"
                            style={{ paddingTop: "5px" }}
                        >
                            <CustomerSearch
                                {...this.props}
                                // ref="customerSearch"
                                customer={this.state.selectedCustomer}
                                error={this.state.customerError}
                                onCustomerChange={this.onCustomerChange}
                                onAddCustomer={(newCustomer) =>
                                    this.props.onAddCustomer(newCustomer)
                                }
                                onShowCustomerDashBoard={()=>this.props.onShowCustomerDashBoard(this.state.selectedCustomer)}
                                onCustomerEdit={() =>
                                    this.props.onCustomerEdit(
                                        this.state.selectedCustomer
                                    )
                                }
                            ></CustomerSearch>
                        </div>
                    </div>
                    {this.state.selectedCustomer && (
                        <div className="row">
                            {/* style={{padding:"0px 10px 0px 10px"}}   */}
                            <div className="col-md-12 col-xs-12">
                                {/* <CustomerAddress
                                    {...this.props}
                                    customer={this.state.selectedCustomer}
                                    selectedAddress={
                                        this.state.selectedCustomerAddress
                                    }
                                    onAddressChange={(address) => {
                                        this.setState({
                                            selectedCustomerAddress: address,
                                            placeOfSupply: address.State,
                                        });
                                    }}
                                /> */}
                                <Input
                                    type="typeaheadselect"
                                    placeholder="Select address"
                                    options={this.state.selectedCustomer.Addresses}
                                    labelKey="Address1"
                                    filterBy = {['AddressType','Address1', 'Address2', 'City', 'State', 'PostalCode','Country']}
                                    columns={[
                                        {Column:"AddressType", Filter:false,SelectedText:true, SearchText:true, Main:true },
                                        {Column:"Address1", Filter:true, SearchText:true,SelectedText:true },
                                        {Column:"Address2", Filter:true, SearchText:true },
                                        {Column:"City", Filter:true , SearchText:true},
                                        {Column:"State", Filter:false, SearchText:true },
                                        {Column:"PostalCode", Filter:false, SearchText:true },
                                        {Column:"Country", Filter:false, SearchText:true},
                                    ]}
                                    value={this.state.selectedCustomerAddress}
                                    onChange={(selected) => {
                                        this.setState({
                                            selectedCustomerAddress: selected[0],
                                            placeOfSupply: selected[0].State,
                                        });
                                    }}
                                    onClick={(action)=>{if(action==="remove"){
                                        this.setState({ selectedCustomerAddress: null});
                                    }}}
                                    // error={this.state.employeeMandatoryAtTransactionError}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row" style={{ paddingTop: "10px" }}>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <label>Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(evt) =>
                                        this.setState({
                                            deliveryDate: evt.target.value,
                                        })
                                    }
                                    value={this.state.deliveryDate}
                                    // onKeyPress={this.handleKeyPress}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <label>Time</label>
                                <TimePicker
                                    time={this.state.deliveryTime}
                                    onChange={(time) =>
                                        this.setState({ deliveryTime: time })
                                    }
                                />
                                {/* <div className="row">
                                        <div className="col-md-4" style={{paddingRight:"0px"}}>
                                            <input type="number"
                                            onChange={(evt) => this.onHHChange(evt.target.value)}
                                            value={this.state.deliveryTime.HH} 
                                            placeholder="HH"
                                            className="form-control"></input>
                                        </div>
                                        <div style={{padding:"0px"}} className="col-md-4" >
                                            <input type="number" 
                                            className="form-control" 
                                            onChange={(evt) => this.onMMChange(evt.target.value)}
                                            value={this.state.deliveryTime.MM} 
                                            placeholder="MM"
                                            >
                                            </input>
                                        </div>
                                        <div className="col-md-4" style={{paddingLeft:"0px"}}>
                                            <select 
                                                className="form-control" 
                                                onChange={(evt) => this.onAMPMChange(evt.target.value)}
                                                value={this.state.deliveryTime.AMPM} >
                                                    <option>AM</option>
                                                    <option>PM</option>
                                            </select>
                                        </div> 
                                    </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-xs-12">
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea
                                    className="text"
                                    style={styleEditor}
                                    onChange={(evt) =>
                                        this.setState({
                                            deliveryNotes: evt.target.value,
                                        })
                                    }
                                    value={this.state.deliveryNotes}
                                ></textarea>
                            </div>
                        </div>
                    </div>
        </Window>
    }
}

export default Delivery;
