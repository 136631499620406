import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import VendorSearch from '../Inventory/VendorSearch'
import ReactHtmlParser from 'react-html-parser';

class VendorLedger extends Component {
    constructor() {
        super()
        this.state = {
            searchText: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            selectedVendor: null,
            finalLedger: [],
            loading: false
        }

        this.loadVendorLedger = this.loadVendorLedger.bind(this);
        this.getVendorLedgerFromInventory = this.getVendorLedgerFromInventory.bind(this);
        this.getVendorLedgerFromExpense = this.getVendorLedgerFromExpense.bind(this);
        this.getVendorOpeningBalanceFromExpense = this.getVendorOpeningBalanceFromExpense.bind(this);
        this.getVendorOpeningBalanceFromInventory = this.getVendorOpeningBalanceFromInventory.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onExportClick = this.onExportClick.bind(this);
    }

    componentDidMount() {
    }

    async loadVendorLedger(searchText, searchTransactionFromDate, searchTransactionToDate) {
        
        let inventoryLedger = await this.getVendorLedgerFromInventory(searchText, searchTransactionFromDate, searchTransactionToDate);
        let expenseLedger = await this.getVendorLedgerFromExpense(searchText, searchTransactionFromDate, searchTransactionToDate);

        let totaldebits = await this.getVendorOpeningBalanceFromInventory(searchText, searchTransactionFromDate);
        let totalcredits = await this.getVendorOpeningBalanceFromExpense(searchText, searchTransactionFromDate);
       
        let totalOpening = totaldebits - totalcredits

        let finalLedger = [];
        finalLedger.push(
            {
                "Transaction Date": searchTransactionFromDate,
                "Narration": "Opening Balance",
                "Due Amount": totalOpening > 0 ? totalOpening : 0,
                "Credit Amount": totalOpening < 0 ? totalOpening : 0
            }
        )
        for (let index = 0; index < inventoryLedger.length; index++) {
            const ledger = inventoryLedger[index];
            finalLedger.push(
                {
                    "Transaction Date": (new Date(ledger.TransactionDate).toISOString().split('T')[0]), //(new Date(ledger.TransactionDate)).toString().substring(0, 24),
                    "Narration":   ((ledger.Status !== 'Cancelled' && ledger.TransactionType.UpdateStockInwardOrOutward === 'outward') ?  ("Credit for Transaction: " + ledger.TransactionNo) : ("Debit for Transaction: " + (ledger.TransactionNo + (ledger.Status === 'Cancelled' ? '(Cancelled)' : '')))),
                    "Due Amount": ((ledger.Status !== 'Cancelled' && ledger.TransactionType.UpdateStockInwardOrOutward === 'outward') || ledger.Status === 'Cancelled')  ? 0 : ledger.BillAmount,
                    "Credit Amount": (ledger.Status !== 'Cancelled' && ledger.TransactionType.UpdateStockInwardOrOutward === 'outward') ? ledger.BillAmount : 0,
                }
            )
        }

        for (let index = 0; index < expenseLedger.length; index++) {
            const ledger = expenseLedger[index];
            finalLedger.push(
                {
                    "Transaction Date": (new Date(ledger.ExpenseDateTime).toISOString().split('T')[0]),//(new Date(ledger.ExpenseDateTime)).toString().substring(0, 24),
                    "Narration": "Credit for Transaction: " + (ledger.ExpenseNo  + (ledger.Status === 'Cancelled' ? '(Cancelled)' : '')),
                    "Due Amount": 0,
                    "Credit Amount": ledger.Status === 'Paid' ? ledger.Amount : 0,
                }
            )
        }   
        finalLedger.sort(function(a, b){return b.TransactionDate > a.TransactionDate})

        var col = [];
        for (var i = 0; i < finalLedger.length; i++) {
            for (var key in finalLedger[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < finalLedger.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result += j > 1 ? "<td style='text-align:right'>" : "<td>";
                result += finalLedger[i][col[j]] === undefined ? 0 : finalLedger[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }

        let dueTotal = 0;
        let creditTotal = 0;

        // ADD Totals
        result +="<tr><td style='font-weight:bold'>Totals</td>"
        result +="<td style='font-weight:bold'>-</td>"
        for (var j = 2; j < col.length; j++) {
            //console.log(col[j]);    
            let total = 0
            for (var i = 0; i < finalLedger.length; i++) {

                if(col[j] === "Due Amount")
                {
                    dueTotal+= (finalLedger[i][col[j]] === undefined ? 0 : Number(finalLedger[i][col[j]]))
                }
                else if(col[j] === "Credit Amount")
                {
                    creditTotal+= (finalLedger[i][col[j]] === undefined ? 0 : Number(finalLedger[i][col[j]]))
                }

                total += (finalLedger[i][col[j]] === undefined ? 0 : Number(finalLedger[i][col[j]]))
            }
            result += "<td style='font-weight:bold;text-align:right'>";
            result += total;
            result += "</td>";
        }
        result +="</tr>"
        result +="<tr>"
        result += "<td colspan='4' style='font-weight:bold'>Closing Balance <span class='pull-right'>" + (Number(dueTotal) - Number(creditTotal)) + "</span></td>"
        result +="</tr>"

        result += "</tbody>";
        result += "</table>";

        // console.log(dueTotal);
        // console.log(creditTotal); 

        //console.log("Ledger", JSON.stringify(result));
        this.setState({ finalLedger: result, loading: false })
    }

    async getVendorLedgerFromInventory(searchText, searchTransactionFromDate, searchTransactionToDate) {
        let inventoryLedger = [];
        return this.props.getVendorLedgerFromInventory(this.props.user.store.key, searchText, searchTransactionFromDate, searchTransactionToDate).then((result) => {
            inventoryLedger = result.searchResult;
            return inventoryLedger;
        })
    }

    async getVendorLedgerFromExpense(searchText, searchTransactionFromDate, searchTransactionToDate) {
        let expenseLedger = [];
        return this.props.getVendorLedgerFromExpense(this.props.user.store.key, searchText, searchTransactionFromDate, searchTransactionToDate).then((result) => {
            expenseLedger = result.searchResult;
            return expenseLedger;
        })
    }

    async getVendorOpeningBalanceFromInventory(searchText, searchTransactionFromDate) {
        let totaldebit;
        return this.props.getVendorOpeningBalanceFromInventory(this.props.user.store.key, searchText, searchTransactionFromDate).then((result) => {
            totaldebit = result.searchResult.totaldebit;
            return totaldebit;
        })
    }

    async getVendorOpeningBalanceFromExpense(searchText, searchTransactionFromDate) {
        let totalcredits;
        return this.props.getVendorOpeningBalanceFromExpense(this.props.user.store.key, searchText, searchTransactionFromDate).then((result) => {
            totalcredits = result.searchResult.totalexpense;
            return totalcredits;
        })
    }

    onSearchClick() {
        this.setState({ loading: true })
        this.loadVendorLedger(this.state.searchText, this.state.searchTransactionFromDate, this.state.searchTransactionToDate);
    }

    onClearSearch() { 
        this.setState({
            searchText : '', selectedVendor : null
            ,searchTransactionToDate: new Date().toISOString().split('T')[0]
            ,searchTransactionFromDate: new Date().toISOString().split('T')[0]
        })
        this.loadVendorLedger('', new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onExportClick() {

    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Vendor Ledger</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-6" style={{ paddingLeft: "0px" }}>
                                            <div className="form-group">
                                                <label>Search Vendor
                                                    {/* , code, email id,phone no */}

                                                </label>
                                                <VendorSearch {...this.props}
                                                    vendor={this.state.selectedVendor}
                                                    error={this.state.partyError}
                                                    onChange={(vendor) => {
                                                        if (vendor) {
                                                            this.setState({
                                                                selectedVendor: vendor,
                                                                searchText: vendor.key
                                                            })
                                                        }
                                                        else {
                                                            this.setState({
                                                                selectedVendor: null,
                                                                searchText: ''
                                                            })
                                                        }

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                            <div className="form-group">
                                                <label>From Date</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                    value={this.state.searchTransactionFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                            <div className="form-group">
                                                <label>To Date</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                    value={this.state.searchTransactionToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            disabled={(this.state.selectedVendor === null || this.state.searchTransactionFromDate.trim() === '' || this.state.searchTransactionToDate.trim() === '')}
                                            style={{ marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            // disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right"
                                            onClick={this.onClearSearch} >Clear</button>
                                        {/* <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginRight: "5px" }}
                                            onClick={this.onExportClick}>Export <i className="glyphicon glyphicon-download-alt " ></i></button> */}
                                    </div>
                                </div>
                                <br />
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {(!this.state.loading && this.state.selectedVendor != null) &&  <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                {ReactHtmlParser(this.state.finalLedger)}
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(VendorLedger)