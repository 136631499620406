import * as actionTypes from '../actionTypes';

const initialState = {
    postDownloadURL:'',
    logoDownloadURL:'',
    faviconDownloadURL:''
}

export default function(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOGO_FILE_UPLOADED:
            return { postDownloadURL:state.postDownloadURL,
            logoDownloadURL:action.downloadURL,
            faviconDownloadURL:state.faviconDownloadURL};
        case actionTypes.FAVICON_FILE_UPLOADED:
            return { postDownloadURL:state.postDownloadURL,
            logoDownloadURL:state.logoDownloadURL,
            faviconDownloadURL:action.downloadURL};
        default:
            return state;
    }
}

// case actionTypes.POST_FILE_UPLOADED:
// return { postDownloadURL:action.postDownloadURL,
// logoDownloadURL:state.postDownloadURL,
// faviconDownloadURL:state.faviconDownloadURL};
