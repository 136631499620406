import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'


export function getEvents(registrationID) {
    return (dispatch) => {
        return database.collection("Events").where("RegistrationID", "==", registrationID).get().then((querySnapshot) => {
            const events = []
            querySnapshot.forEach((doc) => {
                events.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            return events
        }).catch((error) => { console.log(error) })
    }
}


export function saveEvent(event, key) {
    return (dispatch, getState) => {

        event.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        event.ActionBy = getState().user.user.Name
        event.ActionByUID = getState().user.user.UID
        event.ActionByEmailID = getState().user.user.EmailID

        if (key !== "") {
            event.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Events").doc(key).update({
                ...event
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating customer membership: ", error);
            })
        }
        else {
            event.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Events").add({
                ...event
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding customer event: ", error);
                })
        }
    }
}

export function deleteEvent(key) {
    return (dispatch, getState) => {
        return database.collection("Events").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted: true
        }).then(() => { return true })
    }
}

export function getEventDetails(key) {
    return dispatch => {
        return database.collection("Events").doc(key).get().then((doc) => {
            if (doc.exists) {
                // console.log("Campaign found", doc.data());
                return doc.data()
            } else {
                console.log("No such event!");
            }
        })
    }
}