import React, { Component } from 'react';
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import BusinessCard from '../Common/BusinessCard'
import ActionButton from '../Common/ActionButton'
import Spinner from '../Common/Spinner'

class ViewBusinessCard extends Component {

    constructor() {
        super()
        this.state = {
            backgroundColor: 'white',
            color: 'black',
            logoImage: null,
            logoImageURL: '',
            cardImage: null,
            cardImageURL: '',
            loading: true,
            key: ''
        }
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
    }

    async componentDidMount() {
        let businessCard = await this.props.getBusinessCard(this.props.user.user.RegistrationID);

        if (businessCard) {
            this.setState({
                key: businessCard.key,
                backgroundColor: businessCard.BackgroundColor,
                color: businessCard.Color,
                cardImage: businessCard.Card ? businessCard.Card : null,
                logoImage: businessCard.Logo ? businessCard.Logo : null,
                cardImageURL: businessCard.Card ? businessCard.Card.Preview : '',
                logoImageURL: businessCard.Logo ? businessCard.Logo.Preview : ''
            })
        }
        this.setState({
            loading: false
        });
    }

    async onActionButtonClick(action) {
        if (action === 'edit') {
            this.props.history.push({ pathname: "/home/businesscard/edit/" })
        }
        if (action === 'share') {
            
            let message = ""
            message = message + "https://omni.posible.in/ \n"
            message = message + "*" + this.props.user.store.StoreName + "* \n"
            if (this.props.user.store.PhoneNo.trim() != "")
                message = message + "*" + this.props.user.store.PhoneNo + "* \n"
            if (this.props.user.store.EmailID.trim() != "")
                message = message + "*" + this.props.user.store.EmailID + "* \n"

            if (this.props.registration.registration.Address1.trim() != "")
                message = message + "*" + this.props.registration.registration.Address1.trim() + '* \n'
            if (this.props.registration.registration.Address2.trim() != "")
                message = message + "*" + this.props.registration.registration.Address2.trim() + '* \n'
            if (this.props.registration.registration.City.trim() != "")
                message = message + "*" + this.props.registration.registration.City.trim() + '* \n'
            if (this.props.registration.registration.PostalCode.trim() != "")
                message = message + "*" + this.props.registration.registration.PostalCode.trim() + '* \n'
            if (this.props.registration.registration.State.trim() != "")
                message = message + "*" + this.props.registration.registration.State.trim() + '* \n'
            if (this.props.registration.registration.Country.trim() != "")
                message = message + "*" + this.props.registration.registration.Country.trim()  + '*'

            let subject = "Business Card"  
            let url = ("https://omni.posible.in/" + this.props.registration.registration.UniqueName);
            
            if (this.state.cardImage) {
                let imageURL = this.state.cardImageURL;
                window.plugins.socialsharing.share(message, subject, imageURL, url);
            }
        }
    }

    render() {
        return <React.Fragment>
            <HeaderNew {...this.props}
                back={true}
                title="Business Card"
                onBackClick={() => { this.props.history.push({ pathname: '/home' }) }}
            />

            <div className="header-new-background"></div>
            {this.state.loading && <Spinner />}
            
            <div className="content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            {this.state.cardImage && <img src={this.state.cardImage.Preview} style={{ height: "225px", width:"100%"}}
                                    alt="Attachment" />
                            }
                            {!this.state.cardImage && <BusinessCard {...this.props}
                                backgroundColor={this.state.backgroundColor}
                                color={this.state.color}
                                imageURL={this.state.logoImageURL}
                            />}
                        </div>
                    </div>
                    <ActionButton
                        onClick={(action) => this.onActionButtonClick(action)}
                        fabButtons={[
                            { action: "edit", text: "Edit Business Card" },
                            { action: "share", text: "Share Business Card" }
                        ]} />
                </div>
            </div>
        </React.Fragment>
    }

}
export default ViewBusinessCard

