import React,{Component} from 'react'
import Modal from 'react-responsive-modal'

class CustomerPets extends Component
{
    constructor()
    {
        super()
        this.state={
            showCustomerPetSelection:false
        }
        this.onPetSelected = this.onPetSelected.bind(this)
    }


    componentDidMount()
    {
        let showCustomerPetSelection = false
        let selectedPet = null
        if(this.props.customer)
        {
            if(!this.props.selectedPet)
            {
                if(this.props.customer.hasOwnProperty('PetDetails'))
                {
                    if(this.props.customer.PetDetails.length > 1)
                    {
                        showCustomerPetSelection = true 
                        selectedPet = this.props.customer.PetDetails[0]
                        this.props.onPetChange(selectedPet)
                    }
                    else if (this.props.customer.PetDetails.length ===1)
                    {
                        selectedPet = this.props.customer.PetDetails[0]
                        this.props.onPetChange(selectedPet)
                    }
                }
            }
        }
        this.setState({showCustomerPetSelection:showCustomerPetSelection})
    }

    onPetSelected(index){
        let petDetail = this.props.customer.PetDetails[index]
        this.setState({showCustomerPetSelection: false})
        this.props.onPetChange(petDetail)
    }

    render(){
        return <React.Fragment>
             {this.props.selectedPet && <button type="button" 
                onClick={()=> {
                        if(this.props.customer.PetDetails.length > 1)
                        {
                            this.setState({showCustomerPetSelection:true})
                        }
                    }}
                className="btn btn-default btn-block btn-flat btn-lg" style={{marginTop:"5px",padding:"2px",height:"auto",whiteSpace: "normal"}}>
                 <div style={{fontSize:"12px", marginLeft:"5px",  display:"flex", flexDirection: "column", alignItems: "flex-start"}} className="pull-left">
                    {<div>Name: {this.props.selectedPet.PetName}</div>}
                    {this.props.selectedPet.Gender !== "" && <div>Gender: {this.props.selectedPet.Gender}</div>}
                    {this.props.selectedPet.Type !== "" && <div>Type: {this.props.selectedPet.Type}</div>}
                    {this.props.selectedPet.Breed !== "" && <div>Breed: {this.props.selectedPet.Breed}</div>}
                </div>
            </button>}
            <Modal open={this.state.showCustomerPetSelection}
                onClose={() => this.setState({ showCustomerPetSelection: false })} center>
               {this.state.showCustomerPetSelection &&  <div style={{ width: "300px" }}>
                    <h3 style={{ marginTop: "0px" }}>Pet Selection</h3>
                    <div className="box-body">
                        {this.props.customer.PetDetails.map((c, index) =>
                            <button type="button" onClick={() => this.onPetSelected(index)}
                                className="btn btn-default btn-block btn-flat btn-lg" style={{ padding: "10px", height: "100px", whiteSpace: "normal" }}>
                                <div style={{ fontSize: "15px", marginLeft: "5px", display:"flex", flexDirection: "column", alignItems: "flex-start"}} className="pull-left">
                                    <div>Name: {c.PetName}</div>
                                    {c.Gender !== "" && <div>Gender: {c.Gender}</div>}
                                    {c.Type !== "" && <div>Type: {c.Type}</div>}
                                    {c.Breed !== "" && <div>Breed: {c.Breed}</div>}
                                    {/* {c.Dob !== "" && <div>Date of Birth:  {c.Dob}</div>}
                                    {c.Weight !== "" && <div>Weight:  {c.Weight}</div>}
                                    {c.Height !== "" && <div>Height:  {c.Height}</div>} */}
                                </div>
                            </button>
                        )}
                    </div>
                </div>}
            </Modal>
        </React.Fragment>
    }
}

export default CustomerPets