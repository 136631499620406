import React, { Component } from 'react';
import Form from '../../2/Controls/Form';
import FormSection from '../../2/Controls/FormSection';
import Input from '../../2/Controls/Input';
// import * as actionTypes from '../../../actionTypes'
// import { withRouter } from 'react-router-dom'

// import HeaderNew from '../../HeaderNew'
// import '../../Common/Common.css'
// import Footer from '../../Common/Footer'

class AddEditEmployee extends Component {

    constructor() {
        super()
        this.state = {
            employeeCode: '',
            employeeFirstName: '',
            employeeLastName: '',
            gender: '',
            emailID: '',
            phoneNo: '',
            storeID: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            biometricID: '',
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            customField6: '',
            employeeCodeError: false,
            employeeFirstNameError: false,
            action: '',
            key: '',
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            showInSales: 'Yes',
            saving:false
        }
        this.onSaveEmployee = this.onSaveEmployee.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
    }

    componentDidMount() {
        if (this.props.employee) {
            //console.log('this.props.location.state.key', this.props.location.state.key)
            let employee = this.props.employee
            
            this.setState({
                key:this.props.employee.key,
                employeeCode: employee.hasOwnProperty("EmployeeCode") ? employee.EmployeeCode:"",
                employeeFirstName: employee.hasOwnProperty("EmployeeFirstName") ?employee.EmployeeFirstName:"",
                employeeLastName: employee.hasOwnProperty("EmployeeLastName") ?employee.EmployeeLastName:"",
                gender: employee.hasOwnProperty("Gender") ? employee.Gender:"",
                emailID: employee.hasOwnProperty("EmailID") ? employee.EmailID:"",
                phoneNo: employee.hasOwnProperty("PhoneNo") ?employee.PhoneNo:"",
                storeID: employee.hasOwnProperty("storeID") ?employee.storeID:"",
                address1: employee.hasOwnProperty("Address1") ?employee.Address1:"",
                address2: employee.hasOwnProperty("Address2") ?employee.Address2:"",
                city: employee.hasOwnProperty("City") ?employee.City:"",
                state: employee.hasOwnProperty("State") ?employee.State:"",
                postalCode: employee.hasOwnProperty("PostalCode") ?employee.PostalCode:"",
                country: employee.hasOwnProperty(" Country") ?employee.Country:"",
                biometricID: employee.hasOwnProperty("BiometricID") ? employee.BiometricID :"",
                customField1: employee.hasOwnProperty("CustomField1") ? employee.CustomField1 :"",
                customField2: employee.hasOwnProperty("CustomField2") ?employee.CustomField2:"",
                customField3: employee.hasOwnProperty("CustomField3") ?employee.CustomField3:"",
                customField4: employee.hasOwnProperty("CustomField4") ?employee.CustomField4:"",
                customField5: employee.hasOwnProperty("CustomField5") ?employee.CustomField5:"",
                customField6: employee.hasOwnProperty("CustomField6") ? employee.CustomField6:"",
                showInSales: employee.hasOwnProperty('ShowInSales') ? (employee.ShowInSales === true ? 'Yes' : 'No') : ''
            })
        }
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Employee') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
    }

    onSaveEmployee() {
        let employeeCodeError = "";
        let employeeFirstNameError = "";

        if (this.state.employeeCode.trim() === '') { employeeCodeError = "Please select employee code" }
        if (this.state.employeeFirstName.trim() === '') { employeeFirstNameError = "Please select employee name" }
        if (employeeCodeError!=="" || employeeFirstNameError!=="") {
            this.setState({ employeeCodeError: employeeCodeError, employeeFirstNameError: employeeFirstNameError })
            return
        }

        let showInSales = null;
        if (this.state.showInSales.trim() !== '') {
            showInSales = this.state.showInSales === 'Yes' ? true : false;
        }
        //checK if store code is unique
        //check if store name is unique
        this.setState({saving:true});
        let employee = {
            EmployeeCode: this.state.employeeCode,
            EmployeeFirstName: this.state.employeeFirstName,
            EmployeeLastName: this.state.employeeLastName,
            Gender: this.state.gender,
            EmailID: this.state.emailID,
            PhoneNo: this.state.phoneNo,
            BiometricID: this.state.biometricID,
            StoreID: this.props.user.store.key,
            Address1: this.state.address1,
            Address2: this.state.address2,
            City: this.state.city,
            State: this.state.state,
            PostalCode: this.state.postalCode,
            Country: this.state.country,
            CustomField1: this.state.customField1,
            CustomField2: this.state.customField2,
            CustomField3: this.state.customField3,
            CustomField4: this.state.customField4,
            CustomField5: this.state.customField5,
            CustomField6: this.state.customField6,
            RegistrationID: this.props.user.user.RegistrationID,
            ShowInSales:showInSales,
            IsDeleted: false
        }
        // console.log(employee)
        if (this.state.key  === "") {
            this.props.addNewEmployee(employee).then((key) => {
                this.props.getToastr("Employee Saved Successfully");
                this.setState({saving:false});
                this.props.onSave();
            })
        }
        else{
            // console.log(JSON.stringify("employee Update", employee.key))
            this.props.updateEmployee(employee, this.state.key).then(() => {
                this.props.getToastr("Employee Saved Successfully");
                this.setState({saving:false});
                this.props.onSave();
                
            })
        }
    }

    onCancelClick() {
        this.props.onClose();
    }

    render() {
        return <Form
                title={(this.state.key !== "") ? "Edit Employee" : "New Employee"}
                onClick={(action)=>{
                    if(action==="save"){
                        this.onSaveEmployee();
                    }else{
                        this.props.onClose()
                    }
                }}
                processing={this.state.saving}
            >
                <FormSection title="General details">
                    <Input
                        type="text"
                        label="Employee Code"
                        onChange={(value) => { this.setState({ employeeCode:value, employeeCodeError: "" }) }}
                        value={this.state.employeeCode}
                        error={this.state.employeeCodeError}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="First Name"
                        onChange={(value) => { this.setState({ employeeFirstName:value, employeeFirstNameError: "" }) }}
                        value={this.state.employeeFirstName}
                        error={this.state.employeeFirstNameError}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="Last Name"
                        onChange={(value) => { this.setState({ employeeLastName:value}) }}
                        value={this.state.employeeLastName}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input 
                        type="select"
                        label="Gender"
                        value={this.state.gender}
                        onChange={(value)=>this.setState({gender:value})}
                        onEnter={this.onSaveEmployee}
                    >
                        <option>Please select gender</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                    </Input>
                    <Input
                        type="mobileno"
                        label="Phone Number"
                        onChange={(value) => {this.setState({ phoneNo:value}) }}
                        value={this.state.phoneNo}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="emailid"
                        label="Email"
                        onChange={(value) => {this.setState({ emailID:value}) }}
                        value={this.state.emailID}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="Biometric ID"
                        onChange={(value) => {this.setState({ biometricID:value}) }}
                        value={this.state.biometricID}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input 
                        type="select"
                        label="Show in Sales"
                        value={this.state.showInSales}
                        onChange={(value)=>this.setState({showInSales:value})}
                        onEnter={this.onSaveEmployee}
                    >
                        <option>Please select </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Input>
                </FormSection>    
                <FormSection title="Address">
                    <Input
                        type="text"
                        label="Line Address 1"
                        onChange={(value) => {this.setState({ address1:value}) }}
                        value={this.state.address1}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="Line Address 2"
                        onChange={(value) => {this.setState({ address2:value}) }}
                        value={this.state.address2}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="City"
                        onChange={(value) => {this.setState({ city:value}) }}
                        value={this.state.city}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="State"
                        onChange={(value) => {this.setState({ state:value}) }}
                        value={this.state.state}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="Postal Code"
                        onChange={(value) => {this.setState({ postalCode:value}) }}
                        value={this.state.postalCode}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label="Country"
                        onChange={(value) => {this.setState({ country:value}) }}
                        value={this.state.country}
                        onEnter={this.onSaveEmployee}
                    />
                </FormSection>    
                <FormSection title="Additonal Information">
                    <Input
                        type="text"
                        label={this.state.customField1DisplayName}
                        onChange={(value) => {this.setState({ customField1:value}) }}
                        value={this.state.customField1}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label={this.state.customField2DisplayName}
                        onChange={(value) => {this.setState({ customField2:value}) }}
                        value={this.state.customField2}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label={this.state.customField3DisplayName}
                        onChange={(value) => {this.setState({ customField3:value}) }}
                        value={this.state.customField3}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label={this.state.customField4DisplayName}
                        onChange={(value) => {this.setState({ customField4:value}) }}
                        value={this.state.customField4}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label={this.state.customField5DisplayName}
                        onChange={(value) => {this.setState({ customField5:value}) }}
                        value={this.state.customField5}
                        onEnter={this.onSaveEmployee}
                    />
                    <Input
                        type="text"
                        label={this.state.customField6DisplayName}
                        onChange={(value) => {this.setState({ customField6:value}) }}
                        value={this.state.customField6}
                        onEnter={this.onSaveEmployee}
                    />
                </FormSection>
            </Form>
    }
}


export default AddEditEmployee;
// export default withRouter(AddEditEmployee)