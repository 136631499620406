import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function saveBusinessCard(businessCard, key) {
    return (dispatch, getState) => {

        businessCard.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        businessCard.ActionBy = getState().user.user.Name
        businessCard.ActionByUID = getState().user.user.UID
        businessCard.ActionByEmailID = getState().user.user.EmailID

        if (key.trim() !== "") {
            businessCard.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("BusinessCards").doc(key).update({
                ...businessCard
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating business card: ", error);
            })
        }
        else {
            businessCard.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("BusinessCards").add({
                ...businessCard
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding business card: ", error);
                })
        }

    }
}

export function getBusinessCard(registrationID) {
    return (dispatch) => {
        return database.collection("BusinessCards").where("RegistrationID", "==", registrationID).get().then((querySnapshot) => {
            let businessCard = null
            querySnapshot.forEach((doc) => {
                businessCard = { key: doc.id, ...doc.data() }
            })
            return businessCard
        })
    }
}