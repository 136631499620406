import React, { Component } from 'react'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'

class CashRegisterPaymentProviderNewEdit extends Component {

    constructor(){
        super()
        this.state={
            cashRegister:null,
            title:'New Payment Provider',
            paymentModes:[],
            selectedPaymentModeIndex:-1,
            paymentProvider:"",
            paymentProviderName:"",
            loginID:"",
            password:"",
            providerError:"",
            paymentModeError:"",
            loginIdError:"",
            passwordError:"",
            saving:false,
            printer:"",
            storeID:"",
            customerCode:"",
            terminalID:"",
            saltKey:"",
            storeIDError:"",
            customerCodeError:"",
            saltKeyError:"",
            customerCodeVerification:"",
            customerCodeVerificationError:"",
            phonePeMerchantID:"",
            phonePeMerchantIDError:"",
            phonePeSaltKey:"",
            phonePeSaltKeyError:"",
            phonePeSaltKeyIndex:1,
            phonePeSaltKeyIndexError:"",
            wisePOSTokenAPIClientCode:"",
            wisePOSTokenAPIClientCodeError:"",
            wisePOSVerificationAPIClientCode:"",
            wisePOSVerificationAPIClientCodeError:"",
            wisePOSStoreID:"",
            wisePOSStoreIDError:"",
            wisePOSTerminalID:"",
            wisePOSSaltKey:"",
            wisePOSSaltKeyError:"",
            wisePOSSDKLoginID:"",
            wisePOSSDKLoginIDError:"",
            wisePOSSDKPassword:"",
            wisePOSSDKPasswordError:"",
            wisePOSVerificationAPIUserID:"",
            wisePOSVerificationAPIPassword:"",
            wisePOSVerificationAPIPasswordError:"",
            payTMMerchantID:"",
            payTMMerchantIDError:"",
            payTMPeMerchantKey:"",
            payTMPeMerchantKeyError:"",
        }

        this.onHeaderBackClick  = this.onHeaderBackClick.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onPaymentProviderChange = this.onPaymentProviderChange.bind(this)
    }

    componentDidMount() {

        let selectedPaymentModeIndex = this.props.location.state.selectedPaymentModeIndex
        let cashRegister = this.props.location.state.cashRegister
        
        if(selectedPaymentModeIndex > -1)
        {
            let paymentModes =  this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted)
            paymentModes.map(m=> {
                if(cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PaymentModes.filter(f=> f.PaymentTypeID ==m.key ).length > 0  )
                {
                    m.selected=true
                }
                else
                {
                    m.selected=false
                }
            })
            this.setState({
                cashRegister:cashRegister,
                selectedPaymentModeIndex:selectedPaymentModeIndex, 
                paymentModes:paymentModes, 
                paymentProviderName: cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PaymentProviderName,
                loginID: cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].LoginID,
                password: cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].Password,
                paymentProvider:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PaymentProvider,
                storeID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('StoreID') ?cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].StoreID:"",
                customerCode:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('CustomerCode') ?cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].CustomerCode:"",
                terminalID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('TerminalID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].TerminalID:"",
                saltKey:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('SaltKey') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].SaltKey:"",
                wisePOSTokenAPIClientCode:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSTokenAPIClientCode') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSTokenAPIClientCode:"",
                wisePOSVerificationAPIClientCode:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSVerificationAPIClientCode') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSVerificationAPIClientCode:"",
                wisePOSStoreID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSStoreID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSStoreID:"",
                wisePOSTerminalID: cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSTerminalID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSTerminalID:"",
                wisePOSSaltKey:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSSaltKey') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSSaltKey:"",
                wisePOSSDKLoginID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSSDKLoginID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSSDKLoginID:"",
                wisePOSSDKPassword:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSSDKPassword') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSSDKPassword:"",
                wisePOSVerificationAPIUserID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSVerificationAPIUserID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSVerificationAPIUserID:"",
                wisePOSVerificationAPIPassword: cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('WisePOSVerificationAPIPassword') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].WisePOSVerificationAPIPassword:"",
                phonePeMerchantID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('PhonePeMerchantID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PhonePeMerchantID:"",
                phonePeSaltKey:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('PhonePeSaltKey') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PhonePeSaltKey:"",
                phonePeSaltKeyIndex:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('PhonePeSaltKeyIndex') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PhonePeSaltKeyIndex:"",
                payTMMerchantID:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('PayTMMerchantID') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PayTMMerchantID:"",
                payTMMerchantKey:cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].hasOwnProperty('PayTMMerchantKey') ? cashRegister.CashRegisterPaymentSetup[selectedPaymentModeIndex].PayTMMerchantKey:"",            
                phonePeMerchantIDError:"",
                phonePeSaltKeyError:"",
                phonePeSaltKeyIndexError:"",
                payTMMerchantIDError:"",
                payTMPeMerchantKeyError:"",
                providerError:"",
                paymentModeError:"",
                loginIdError:"",
                passwordError:"",
                storeIDError:"",
                customerCodeError:"",
                storeIDError:"",
                saltKeyError:""
                })
        }
        else
        {
            let paymentModes =  this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted)
            paymentModes.map(m=> m.selected=false)
            this.setState({cashRegister:cashRegister, paymentModes:paymentModes, selectedPaymentModeIndex:selectedPaymentModeIndex})
        }
      
    }

    onPaymentProviderChange(evt)
    {
        this.setState({ paymentProviderName: evt.target.options[evt.target.selectedIndex].text, 
            paymentProvider: evt.target.value })
    }

    onHeaderBackClick(){
        
            this.props.history.push({ pathname: '/home/settings/crpaymentproviders', state:{cashRegister: this.state.cashRegister}}) 
    }

    onSave(){
        let providerError =""
        let paymentModeError = ""
        let loginIdError =""
        let passwordError =""
        let storeIDError =""
        let customerCodeError=""
        let saltKeyError= ""
        let phonePeMerchantIDError = ""
        let phonePeSaltKeyError=""
        let phonePeSaltKeyIndexError=""
        let wisePOSTokenAPIClientCodeError=""
        let wisePOSVerificationAPIClientCodeError=""
        let wisePOSStoreIDError=""
        let wisePOSSaltKeyError=""
        let wisePOSSDKLoginIDError=""
        let wisePOSSDKPasswordError=""
        let wisePOSVerificationAPIUserIDError=""
        let wisePOSVerificationAPIPasswordError=""
        let payTMMerchantIDError=""
        let payTMMerchantKeyError=""

        if(this.state.paymentProvider.trim()==="")
        {
            providerError = "Please select provider"
        }
        if(this.state.paymentModes.filter(m=> m.selected).length ===0)
        {
            paymentModeError="Please select payment mode"
        }
        if(this.state.paymentProvider.trim()==="wisepos")
        {
            if(this.state.loginID.trim()==="")
            {
                loginIdError="Please provide login id"
            }
            if(this.state.password.trim()==="")
            {
                passwordError= "Please provide password"
            }
        }
        if(this.state.paymentProvider.trim()==="wisepos collect call")
        {
            if(this.state.wisePOSTokenAPIClientCode.trim()==="")
            {
                wisePOSTokenAPIClientCodeError="Please provide client code"
            }
            if(this.state.wisePOSVerificationAPIClientCode.trim()==="")
            {
                wisePOSVerificationAPIClientCodeError="Please provide client code"
            }
            if(this.state.wisePOSStoreID.trim()==="")
            {
                wisePOSStoreIDError="Please provide store id"
            }
            if(this.state.wisePOSSaltKey.trim()==="")
            {
                wisePOSSaltKeyError="Please provide salt key"
            }
            if(this.state.wisePOSSDKLoginID.trim()==="")
            {
                wisePOSSDKLoginIDError="Please provide login id"
            }
            if(this.state.wisePOSSDKPassword.trim()==="")
            {
                wisePOSSDKPasswordError="Please provide password"
            }
            if(this.state.wisePOSVerificationAPIUserID.trim()==="")
            {
                wisePOSVerificationAPIUserIDError="Please provide user id"
            }
            if(this.state.wisePOSVerificationAPIPassword.trim()==="")
            {
                wisePOSVerificationAPIPasswordError="Please provide passowrd"
            }
        }
        if(this.state.paymentProvider.trim()==="phonepe" || this.state.paymentProvider.trim()==="phonepe qr")
        {
            if(this.state.phonePeMerchantID.trim()==="")
            {
                phonePeMerchantIDError="Please provide merchant id provided by PhonePe"
            }
            if(this.state.phonePeSaltKey.trim()==="")
            {
                phonePeSaltKeyError="Please provide salt key provided by PhonePe"
            }
            if(this.state.phonePeSaltKeyIndex ==="")
            {
                phonePeSaltKeyIndexError="Please provide salt key index provided by PhonePe"
            }
        }
        if(this.state.paymentProvider.trim()==="paytm")
        {
            if(this.state.payTMMerchantID.trim()==="")
            {
                payTMMerchantIDError="Please provide merchant id provided by PayTM"
            }
            if(this.state.payTMMerchantKey.trim()==="")
            {
                payTMMerchantKeyError="Please provide key provided by PayTM"
            }
        }
        if(this.state.paymentProvider.trim()==="pinelabs")
        {
            if(this.state.loginID.trim()==="")
            {
                loginIdError="Please provide user id"
            }
        }
        //check if this provider is already setup
        let cashRegisterPaymentSetup= this.state.cashRegister.hasOwnProperty('CashRegisterPaymentSetup') ? this.state.cashRegister.CashRegisterPaymentSetup : []
        for(let i=0;i<cashRegisterPaymentSetup.length;i++)
        {
            if(cashRegisterPaymentSetup[i].PaymentProvider.trim() === this.state.paymentProvider.trim() && i!== this.state.selectedPaymentModeIndex)
            {
                providerError = "Setup exists for selected provider"
                break
            }
        }
        //check if payment mode is part of someother provider setup
        //do it when you have multiple

        if(providerError.trim()!=="" || paymentModeError.trim()!==""
        || loginIdError.trim()!=="" || passwordError.trim()!==""
        || storeIDError.trim()!=="" || customerCodeError.trim()!=="" || saltKeyError.trim()!==""
        || phonePeMerchantIDError.trim()!=="" ||  phonePeSaltKeyError.trim()!=="" 
        || phonePeSaltKeyIndexError.trim()!==""
        || wisePOSTokenAPIClientCodeError.trim()!==""
        || wisePOSVerificationAPIClientCodeError.trim()!==""
        || wisePOSStoreIDError.trim()!==""
        || wisePOSSaltKeyError.trim()!==""
        || wisePOSSDKLoginIDError.trim()!==""
        || wisePOSSDKPasswordError.trim()!==""
        || wisePOSVerificationAPIUserIDError.trim()!==""
        || wisePOSVerificationAPIPasswordError.trim()!==""
        || payTMMerchantKeyError.trim()!==""
        || payTMMerchantIDError.trim()!==""
        )
        {
            this.setState({providerError:providerError,paymentModeError:paymentModeError,
                loginIdError:loginIdError,
                passwordError:passwordError,
                storeIDError:storeIDError,
                customerCodeError:customerCodeError,
                phonePeMerchantIDError:phonePeMerchantIDError,
                phonePeSaltKeyError:phonePeSaltKeyError,
                phonePeSaltKeyIndexError:phonePeSaltKeyIndexError,
                saltKeyError:saltKeyError,
                wisePOSTokenAPIClientCodeError:wisePOSTokenAPIClientCodeError,
                wisePOSVerificationAPIClientCodeError:wisePOSVerificationAPIClientCodeError,
                wisePOSStoreIDError:wisePOSStoreIDError,
                wisePOSSaltKeyError:wisePOSSaltKeyError,
                wisePOSSDKLoginIDError:wisePOSSDKLoginIDError,
                wisePOSSDKPasswordError:wisePOSSDKPasswordError,
                wisePOSVerificationAPIUserIDError:wisePOSVerificationAPIUserIDError,
                wisePOSVerificationAPIPasswordError:wisePOSVerificationAPIPasswordError,
                payTMMerchantKeyError:payTMMerchantKeyError,
                payTMMerchantIDError:payTMMerchantIDError
                })
                return
        }

        let cashRegister = this.state.cashRegister
        cashRegister.CashRegisterPaymentSetup = cashRegister.hasOwnProperty('CashRegisterPaymentSetup') ? cashRegister.CashRegisterPaymentSetup : []
        let paymentModes = []
        this.state.paymentModes.filter(f=> f.selected).map(m=>paymentModes.push({PaymentType:m.PaymentType, PaymentTypeID:m.key}))
       
        if(this.state.selectedPaymentModeIndex===-1)
        {
            //new
            cashRegister.CashRegisterPaymentSetup.push({
                PaymentProvider:this.state.paymentProvider,
                PaymentProviderName: this.state.paymentProviderName,
                LoginID: this.state.loginID,
                Password: this.state.password,
                CustomerCode:this.state.customerCode,
                StoreID:this.state.storeID,
                TerminalID: this.state.terminalID,
                PaymentModes:paymentModes,
                SaltKey: this.state.saltKey,
                PhonePeMerchantID:this.state.phonePeMerchantID,
                PhonePeSaltKey:this.state.phonePeSaltKey,
                PhonePeSaltKeyIndex: this.state.phonePeSaltKeyIndex,
                WisePOSTokenAPIClientCode:this.state.wisePOSTokenAPIClientCode,
                WisePOSVerificationAPIClientCode:this.state.wisePOSVerificationAPIClientCode,
                WisePOSStoreID:this.state.wisePOSStoreID,
                WisePOSTerminalID: this.state.wisePOSTerminalID,
                WisePOSSaltKey:this.state.wisePOSSaltKey,
                WisePOSSDKLoginID:this.state.wisePOSSDKLoginID,
                WisePOSSDKPassword:this.state.wisePOSSDKPasswordError,
                WisePOSVerificationAPIUserID:this.state.wisePOSVerificationAPIUserID,
                WisePOSVerificationAPIPassword: this.state.wisePOSVerificationAPIPassword,
            })
        }
        else
        {
            //udpdate 
            // console.log('update')
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PaymentModes = paymentModes
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PaymentProvider = this.state.paymentProvider
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PaymentProviderName = this.state.paymentProviderName
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].LoginID = this.state.loginID
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].Password = this.state.password
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].CustomerCode=this.state.customerCode
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].StoreID=this.state.storeID
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].TerminalID= this.state.terminalID
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].SaltKey= this.state.saltKey            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PhonePeMerchantID= this.state.phonePeMerchantID            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PhonePeSaltKey= this.state.phonePeSaltKey            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PhonePeSaltKeyIndex= this.state.phonePeSaltKeyIndex            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PayTMMerchantID= this.state.payTMMerchantID            
        }

        // console.log('cashRegister', cashRegister)
        
        this.props.updateCashRegister(cashRegister, cashRegister.key).then(()=> {
            this.props.getToastr("Saved Successfully")
            this.onHeaderBackClick()
        })
    }

    onPaymentProviderChange(evt)
    {
        this.setState({ paymentProviderName: evt.target.options[evt.target.selectedIndex].text, 
            paymentProvider: evt.target.value })
    }
 
    
    render() {

        return <React.Fragment>

            <HeaderNew {...this.props}
                back={true}
                title={this.state.title}
                onBackClick={() => this.onHeaderBackClick()}
            />

            <div className="header-new-background"></div>

            <div className="content-wrapper" >

                <div className="container">

                    <br/>
                
                    <div className="form-group">
                        <label>Cash Register Code</label>
                        <input type="text" className="form-control"
                                value={this.state.cashRegister ? this.state.cashRegister.CashRegisterCode : ''}
                            />
                    </div>

                    <div className="form-group">
                        <label>Cash Register Name</label>
                        <input type="text" className="form-control"
                                value={this.state.cashRegister ? this.state.cashRegister.CashRegisterName: ''}
                            />
                    </div>
    
                    <div className={this.state.providerError ? "form-group has-error" : "form-group "}>
                        <label>Payment Provider</label>
                        <select className="form-control"
                            value={this.state.paymentProvider}
                            onChange={this.onPaymentProviderChange}>
                            <option value="">Select</option>
                            <option value="pinelabs">Pine Labs</option>
                            <option value="wisepos">Mswipe - wisepos</option>
                            {!this.props.isMobileApp() &&<option value="wisepos collect call">Mswipe - wisepos collect Call</option>}
                            <option value="paytm">PayTM</option>
                            <option value="phonepe">Phonepe</option>
                            <option value="phonepe qr">Phonepe QR</option>
                        </select>
                        {this.state.providerError !== "" && <span className="help-block">{this.state.providerError}</span>}
                    </div>

                    {this.state.paymentProvider ==="pinelabs" && <div className={this.state.loginIdError ? "form-group has-error" : "form-group "}>
                        <label>User id</label>
                        <input type="text" className="form-control"
                        onChange={(evt) => this.setState({ loginID: evt.target.value })}
                        value={this.state.loginID} />
                        {this.state.loginIdError !== "" && <span className="help-block">{this.state.loginIdError}</span>}
                    </div>}
                    
                    {this.state.paymentProvider ==="paytm" && <React.Fragment>
                        <div className={this.state.payTMMerchantIDError ? "form-group has-error" : "form-group "}>
                            <label>Merchant ID</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ payTMMerchantID: evt.target.value })}
                            value={this.state.payTMMerchantID} />
                            {this.state.payTMMerchantIDError !== "" && <span className="help-block">{this.state.payTMMerchantIDError}</span>}
                        </div>
                        <div className={this.state.payTMMerchantKeyError ? "form-group has-error" : "form-group "}>
                            <label>Merchant Key</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ payTMMerchantKey: evt.target.value })}
                            value={this.state.payTMMerchantKey} />
                            {this.state.payTMMerchantKeyError !== "" && <span className="help-block">{this.state.payTMMerchantKeyError}</span>}
                        </div>
                    </React.Fragment>}

                    {(this.state.paymentProvider ==="phonepe"  || this.state.paymentProvider ==="phonepe qr") && <React.Fragment>
                        <div className={this.state.phonePeMerchantIDError ? "form-group has-error" : "form-group "}>
                            <label>Merchant ID</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ phonePeMerchantID: evt.target.value })}
                            value={this.state.phonePeMerchantID} />
                            {this.state.phonePeMerchantIDError !== "" && <span className="help-block">{this.state.phonePeMerchantIDError}</span>}
                        </div>
                        <div className={this.state.phonePeMerchantIDError ? "form-group has-error" : "form-group "}>
                            <label>Salt Key</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ phonePeSaltKey: evt.target.value })}
                            value={this.state.phonePeSaltKey} />
                            {this.state.phonePeSaltKeyError !== "" && <span className="help-block">{this.state.phonePeSaltKeyError}</span>}
                        </div>
                        <div className={this.state.phonePeMerchantIDError ? "form-group has-error" : "form-group "}>
                            <label>Salt Key Index</label>
                            <input type="number" className="form-control"
                            style={{width:"50px"}}
                            onChange={(evt) => this.setState({ phonePeSaltKeyIndex: evt.target.value })}
                            value={this.state.phonePeSaltKeyIndex} />
                            {this.state.phonePeSaltKeyIndexError !== "" && <span className="help-block">{this.state.phonePeSaltKeyIndexError}</span>}
                        </div>
                    </React.Fragment>}

                    {this.state.paymentProvider ==="wisepos collect call" && <React.Fragment>
                        <div className={this.state.wisePOSTokenAPIClientCodeError ? "form-group has-error" : "form-group "}>
                            <label>Client Code (Token API)</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSTokenAPIClientCode: evt.target.value })}
                            value={this.state.wisePOSTokenAPIClientCode} />
                            {this.state.wisePOSTokenAPIClientCodeError !== "" && <span className="help-block">{this.state.wisePOSTokenAPIClientCodeError}</span>}
                        </div>
                        <div className={this.state.wisePOSVerificationAPIClientCodeError ? "form-group has-error" : "form-group "}>
                            <label>Client Code (Verification API)</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSVerificationAPIClientCode: evt.target.value })}
                            value={this.state.wisePOSVerificationAPIClientCode} />
                            {this.state.wisePOSVerificationAPIClientCodeError !== "" && <span className="help-block">{this.state.wisePOSVerificationAPIClientCodeError}</span>}
                        </div>
                        <div className={this.state.wisePOSStoreIDError ? "form-group has-error" : "form-group "}>
                            <label>Store ID</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSStoreID: evt.target.value })}
                            value={this.state.wisePOSStoreID} />
                            {this.state.wisePOSStoreIDError !== "" && <span className="help-block">{this.state.wisePOSStoreIDError}</span>}
                        </div>
                        <div className="form-group ">
                            <label>Terminal ID</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSTerminalID: evt.target.value })}
                            value={this.state.wisePOSTerminalID} />
                        </div>
                        <div className={this.state.wisePOSSaltKeyError ? "form-group has-error" : "form-group "}>
                            <label>Salt Key</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSSaltKey: evt.target.value })}
                            value={this.state.wisePOSSaltKey} />
                            {this.state.wisePOSSaltKeyError !== "" && <span className="help-block">{this.state.wisePOSSaltKeyError}</span>}
                        </div>
                        <div className={this.state.wisePOSVerificationAPIUserIDError ? "form-group has-error" : "form-group "}>
                            <label>Verification API UserId</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSVerificationAPIUserID: evt.target.value })}
                            value={this.state.wisePOSVerificationAPIUserID} />
                            {this.state.wisePOSVerificationAPIUserIDError !== "" && <span className="help-block">{this.state.wisePOSVerificationAPIUserIDError}</span>}
                        </div>
                        <div className={this.state.wisePOSVerificationAPIPasswordError ? "form-group has-error" : "form-group "}>
                            <label>Verfication API password</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ wisePOSVerificationAPIPassword: evt.target.value })}
                            value={this.state.wisePOSVerificationAPIPassword} />
                            {this.state.wisePOSVerificationAPIPasswordError !== "" && <span className="help-block">{this.state.wisePOSVerificationAPIPasswordError}</span>}
                        </div>    
                    </React.Fragment>}

                    {this.state.paymentProvider ==="wisepos" && <div className={this.state.loginIdError ? "form-group has-error" : "form-group "}>
                        <label>Login id</label>
                        <input type="text" className="form-control"
                        onChange={(evt) => this.setState({ loginID: evt.target.value })}
                        value={this.state.loginID} />
                        {this.state.loginIdError !== "" && <span className="help-block">{this.state.loginIdError}</span>}
                    </div>}

                    {this.state.paymentProvider ==="wisepos" && <div className={this.state.passwordError ? "form-group has-error" : "form-group "}>
                        <label>Password</label>
                        <input type="text" className="form-control"
                        onChange={(evt) => this.setState({ password: evt.target.value })}
                        value={this.state.password} />
                        {this.state.providerError !== "" && <span className="help-block">{this.state.passwordError}</span>}
                    </div>}

                    {this.state.paymentProvider !=="" &&<div className={this.state.paymentModeError ? "form-group has-error" : "form-group "} >
                        <label>Payment modes</label>
                        {this.state.paymentModes.map((m,index)=><div>
                                <input type="checkbox"  
                                checked={m.selected}
                                onChange={()=>
                                {let paymentModes= this.state.paymentModes
                                    paymentModes[index].selected = !paymentModes[index].selected 
                                    this.setState({paymentModes: paymentModes})
                                }
                                }/>
                                <label >{m.PaymentType}</label>
                            </div>)}
                        {this.state.paymentModeError !== "" && <span className="help-block">{this.state.paymentModeError}</span>}
                    </div>}

                </div>
            
            </div>

            <Footer onClick={()=> this.onSave()} />

    </React.Fragment>}

}

export default CashRegisterPaymentProviderNewEdit
