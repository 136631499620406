import React, { Component } from 'react'
import './BusinessCard.css'
import './Common.css'

class BusinessCard extends Component {

    render() {
        return <React.Fragment>
            <div id="card">
                <div className="card" style={{ background: this.props.backgroundColor, color: this.props.color }}>
                    {/* {this.props.imageURL !== "" && <img className="card-img" src={this.props.imageURL} alt="Avatar" style={{ left: (window.innerWidth - 45) +'px' }} />}
                    {this.props.imageURL === "" && <img className="card-img" src={require('../../images/img_avatar2.png')} alt="Avatar" style={{ left: (window.innerWidth - 45) +'px' }}  />} */}

                    <div className="card-container">
                        <h3 className="card-h3"><b>{this.props.registration.registration.BusinessName}</b></h3>
                        <table>
                            <tbody>
                                {this.props.registration.registration.EmailID !== "" && <tr>
                                    {/* <td>
                                        <img src="https://img.icons8.com/material-outlined/24/000000/new-post.png" />
                                    </td> */}
                                    <td>
                                        {this.props.registration.registration.EmailID}
                                    </td>
                                </tr>}
                                <tr>
                                    <td>
                                        <br></br>
                                    </td>
                                </tr>
                                {this.props.registration.registration.ContactNo !== "" && <tr>
                                    {/* <td>
                                        <img src="https://img.icons8.com/windows/32/000000/phone.png" />
                                    </td> */}
                                    <td>
                                        {this.props.registration.registration.ContactNo}
                                    </td>
                                </tr>}
                                <tr>
                                    <td>
                                        <br></br>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td>
                                        <img src="https://img.icons8.com/windows/32/000000/shop.png" />
                                    </td> */}
                                    <td>
                                        {(this.props.registration.registration.Address1.trim() + '\r\n' +
                                            this.props.registration.registration.Address2.trim() + '\r\n' +
                                            this.props.registration.registration.City.trim() + '\r\n' +
                                            this.props.registration.registration.PostalCode.trim() + '\r\n' +
                                            this.props.registration.registration.State.trim() + '\r\n' +
                                            this.props.registration.registration.Country.trim()).trim()}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <br></br>
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td>
                                        <img src="https://img.icons8.com/material-outlined/24/000000/domain.png" />
                                    </td> */}
                                    <td>
                                        {"https://omni.posible.in/" + this.props.registration.registration.UniqueName}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    }
}

export default BusinessCard
