import React, { Component } from "react";
import ImageButton from "./ImageButton";
import Button from "./Button";
import { isMobileApp } from "../../../actions/Util";
import "./Form.css";

class Form extends Component {
  constructor() {
    super();
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.showFooter = this.showFooter.bind(this);
  }

  showFooter() {
    let showFooter = true;
    if (this.props.hasOwnProperty("showFooter")) {
      showFooter = this.props.showFooter;
    }
    return showFooter;
  }

  renderDesktop() {
    return (
      <React.Fragment>
        <div className="form-header">{this.props.title}</div>
        <div className="form-content">{this.props.children}</div>
        {this.showFooter() && (
          <div className="form-footer">
            <div className="left w50">
              <Button
                type="secondary"
                text="Cancel"
                onClick={() => this.props.onClick("cancel")}
                processing={this.props.processing}
              />
            </div>
            <div className="right w50">
              <Button
                type="primary"
                text="Save"
                onClick={() => this.props.onClick("save")}
                processing={this.props.processing}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderMobile() {
    return (
      <div className="form-mobile">
        <div className="list-page-mobile-background"></div>
        <div className="form-mobile-header">
          <div className="left w100">
            <ImageButton
              type={"leftarrow"}
              label={"back"}
              onClick={() => this.props.onClick("cancel")}
            />
            <div className="form-mobile-header-title">{this.props.title}</div>
          </div>
          {/* <div className="right-30">
                </div> */}
        </div>
        <div
          className="form-mobile-content"
          style={{
            height: window.innerHeight - 115 + "px",
            overflowY: "auto",
          }}
        >
          {this.props.children}
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        {this.showFooter() && (
          <div className="form-mobile-footer">
            <div className="w100">
              <Button
                type="primary"
                text="Save"
                onClick={() => this.props.onClick("save")}
                processing={this.props.processing}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {!isMobileApp() && this.renderDesktop()}
        {isMobileApp() && this.renderMobile()}
      </React.Fragment>
    );
  }
}

export default Form;
