import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function searchProductTags(registrationID, from, size, option, searchText)
{
        let searchParameters =   {
            "from": from,
        "size": size,
        "track_total_hits": true,
        "query": {
          "bool": {
            "must": [
              { "match": { "RegistrationID": registrationID}}
            ],
            "must_not": [
            ]
          }
        }
      }

      if(option === "active")
      {
        searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
      }
      if(option === "inactive")
      {
        searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
      }
      searchParameters.query.bool.must.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      return (dispatch) => {
          let addMessage =  firebase.functions().httpsCallable('getProductTags');
        return addMessage({text: searchParameters}).then(function(result) {
            console.log('searchProductTags',JSON.stringify(searchParameters) )
            const tags = []
            let sanitizedMessage = result.data.text;
                sanitizedMessage.hits.hits.map((data, index)=>{
                    tags.push({key: data._id, ...data._source})
            })
            return {totalItemscount:  sanitizedMessage.hits.total.value, searchResult:tags}
            });
    }
}

export function saveProductTag(key,tag){
    return (dispatch, getState) =>{
        tag.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        tag.ActionBy = getState().user.user.Name
        tag.ActionByUID = getState().user.user.UID
        tag.ActionByEmailID = getState().user.user.EmailID

        if(key !== "")
        {
            tag.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("ProductTags").doc(key).update({
                ...tag
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating Product Tags: ", error);
              })
        }

        else if(key == "")
        {
            tag.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("ProductTags").add({
                ...tag
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding Product Tags: ", error);
              })

        }
    }
}

export function getProductTags(registrationID) {
    return (dispatch) => {
        return database.collection("ProductTags").where("RegistrationID","==",registrationID).where("IsDeleted","==",false).get().then((querySnapshot) => {
            const productTags = []
            querySnapshot.forEach((doc) => {
                productTags.push(
                  {
                    key:doc.id,...doc.data()
                  }
                )
           })
            dispatch({type: actionTypes.PRODUCT_TAGS_GET,productTags})
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function deleteProductTag(key)
{
    return (dispatch, getState) => {
        return database.collection("ProductTags").doc(key).update({
            IsDeleted : true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID :getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE
        }).then(()=> {return true})
    }
}

export function getProductTagDetails(key){
    return dispatch => {
    return database.collection("ProductTags").doc(key).get().then((doc) => {
        if (doc.exists) {
            console.log("Tag found", doc.data());
           return doc.data()
        } else {
            console.log("No such Tags!");
        }
    })
    }
}