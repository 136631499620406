import React, { Component } from 'react'
import Scheduler, { SchedulerData, ViewTypes, DATE_FORMAT, CellUnits } from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import withDnDContext from './withDnDContext';
import CustomerSearch from '../Sales/CustomerSearch'
import Modal from 'react-responsive-modal'
import Customer from '../CRM/Customer'
import CustomerDashBoard from '../Reports/CustomerDashboard'
import CreatableSelect from 'react-select'
import * as actionTypes from '../../actionTypes'
import Appointments from './Appointments';
import { isNullOrUndefined } from 'util';

class AppointmentView extends Component {

  constructor() {
    super()
    let startDate = new Date().toISOString().split('T')[0]
    let schedulerData = new SchedulerData(startDate, ViewTypes.Custom, false, false, {
      customCellWidth: 50,
      nonAgendaDayCellHeaderFormat: 'HH:mm',
      schedulerWidth: '95%',
      dayStartFrom: 7, dayStopTo: 22,
      views: [
        { viewName: 'Day', viewType: ViewTypes.Custom, showAgenda: false, isEventPerspective: false },
        { viewName: 'Week', viewType: ViewTypes.Custom1, showAgenda: false, isEventPerspective: false },
        { viewName: 'Month', viewType: ViewTypes.Custom2, showAgenda: false, isEventPerspective: false },
      ]
    }, {
      getCustomDateFunc: this.getCustomDate,
      isNonWorkingTimeFunc: this.isNonWorkingTime,
    });
    schedulerData.localeMoment.locale('en');
    
    this.state = {
      startDate: startDate,
      //height: 50,
      tabSelected:'Services',
      appointmentDate: '',
      resources: [],
      allResources: [],
      events: [],
      slot: '',
      employee: '',
      service: null,
      services: [],
      completeServiceList:[],
      selectedCustomer: null,
      customerError: '',
      serviceError: '',
      comments: '',
      appointments: [],
      showCancelModal: false,
      cancellationReason: '',
      eventID: '',
      showCreateInvoice: false,
      customerAppointments: [],
      rightHeight: 90,
      showAddCustomer: false,
      showAppointment: true,
      showAppointments: false,
      showCustomerDashBoard: false,
      status: '',
      currentView: 5,
      newAppointments: [],
      slotStartDisplay: '',
      slotEndDisplay: '',
      slotEndDisplayError: '',
      slotStartDisplayError: '',
      employeeError: '',
      selectedIndex: '',
      employeeServices: [],
      employeeServiceMapping: [],
      // tabSelected: 'Services',
      
      packages: [],
      packageServices:[],
      packageID: '',
      showError: false,
      viewModel: schedulerData,
      selectedPackageServiceEmployees:[],
      timeSlots:[
        {slot:"7:30"},
        {slot:"8:00"},
        {slot:"8:30"},
        {slot:"9:00"},
        {slot:"9:30"},
        {slot:"10:00"},
        {slot:"10:30"},
        {slot:"11:00"},
        {slot:"11:30"},
        {slot:"12:00"},
        {slot:"12:30"},
        {slot:"13:00"},
        {slot:"13:30"},
        {slot:"14:00"},
        {slot:"14:30"},
        {slot:"15:00"},
        {slot:"15:30"},
        {slot:"16:00"},
        {slot:"16:30"},
        {slot:"17:00"},
        {slot:"17:30"},
        {slot:"18:00"},
        {slot:"18:30"},
        {slot:"19:00"},
        {slot:"19:30"},
        {slot:"20:00"},
        {slot:"20:30"},
        {slot:"21:00"},
        {slot:"21:30"},
        {slot:"22:00"},
        {slot:"22:30"},
        {slot:"23:00"},
        ]                 
    }
    // this.state = {
    //   viewModel: schedulerData
    // }

    this.prevClick = this.prevClick.bind(this);
    this.nextClick = this.nextClick.bind(this);
    this.isNonWorkingTime = this.isNonWorkingTime.bind(this);
    this.getCustomDate = this.getCustomDate.bind(this);
    this.newEvent = this.newEvent.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onCustomerSelected = this.onCustomerSelected.bind(this);
    this.onRemoveCustomer = this.onRemoveCustomer.bind(this);
    this.onSaveAppointment = this.onSaveAppointment.bind(this);
    this.eventClick = this.eventClick.bind(this);
    this.getAppointments = this.getAppointments.bind(this);
    this.eventItemPopoverTemplateResolver = this.eventItemPopoverTemplateResolver.bind(this);
    this.updateAppointmentStatus = this.updateAppointmentStatus.bind(this);
    this.onCancelAppointment = this.onCancelAppointment.bind(this);
    this.onCreateInvoice = this.onCreateInvoice.bind(this);
    this.onRemoveService = this.onRemoveService.bind(this);
    this.onShowCreateInvoice = this.onShowCreateInvoice.bind(this);
    this.updateEventStart = this.updateEventStart.bind(this);
    this.updateEventEnd = this.updateEventEnd.bind(this);
    this.handleOnServiceChange = this.handleOnServiceChange.bind(this);
    this.moveEvent = this.moveEvent.bind(this);
    this.onSelectDate = this.onSelectDate.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
    this.onRemoveAppointment = this.onRemoveAppointment.bind(this);
    this.onAddAppointment = this.onAddAppointment.bind(this);
    this.onModifyAppointment = this.onModifyAppointment.bind(this);
    this.handleOnEmployeeChange = this.handleOnEmployeeChange.bind(this);
    this.handleOnPackageChange = this.handleOnPackageChange.bind(this);
    this.getCustomerAdditionalInfo = this.getCustomerAdditionalInfo.bind(this);
    this.onSlotStartDateChange = this.onSlotStartDateChange.bind(this);
    this.onSlotEndTimeChange = this.onSlotEndTimeChange.bind(this);
  }

  async componentDidMount() {
    // console.log('this.state.tabSelected', this.state.tabSelected)

    let resources = await this.loadResources();
    let schedulerData = this.state.viewModel;
    schedulerData.setResources(resources);
    let services = this.props.config.Products.filter((f => !f.IsDeleted && f.ProductTypeID === 3));
    const serviceList = [];

    services.map((service) => {
      let duration = service.hasOwnProperty("ServiceDuration") ? service.ServiceDuration : "";
      let price = service.hasOwnProperty("RetailPrice") ? service.RetailPrice : "";
      let label = service.Name
      if(price !=="" || duration!==""){
        label+= " (" + duration + (duration !==""? "mins" : "") + " " +  (price!=="" && duration !=="" ? ",": "") + (price!=="" ? "₹" :"") + price +")"
      }
      let obj = {
        value: service.key, 
        label: label , 
        name: service.Name,
        duration:duration,
        price: price
      }
      serviceList.push(obj);
    });

    
    let employeeServiceMapping = [];
    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      if (resource) {
        let mapping = await this.props.getEmployeeServices(resource.id);
        if (mapping && mapping.Products.length > 0) {
          for (let j = 0; j < mapping.Products.length; j++) {    
              if (serviceList.filter((e) => e.value === mapping.Products[j].ProductID).length > 0)
              {
                let service =  serviceList.filter((e) => e.value === mapping.Products[j].ProductID)[0];
                let objService={
                  value: service.value, 
                  label: service.label , 
                  name: service.name,
                  duration:service.duration,
                  price: service.price,
                  employeeID:resource.id,
                  employeeName:resource.name,
                  employeeStatus:resource.status
                }
                // service.employeeID = resource.id;
                // service.employeeName = resource.name;
                // service.employeeStatus =resource.status;
                employeeServiceMapping.push(objService);
              }                
          }
        }
      }
    }
    this.setState({ 
      allResources: resources,
      resources: resources,
      viewModel: schedulerData, 
      showAppointment: true, 
      currentView: 5,
      services: serviceList, 
      completeServiceList : serviceList , 
      employeeServiceMapping: employeeServiceMapping});
    
    let appointmentList = await this.props.getAppointments(this.props.user.store.key, schedulerData.startDate);
    // console.log('appointmentList',appointmentList)
    if (appointmentList && appointmentList.length > 0) {
      for (let index = 0; index < appointmentList.length; index++) {
        const appointment = appointmentList[index];

        let resource = resources.filter((e) => e.status === 'Leave' && appointment.EmployeeID !== e.id)[0];

        if (resource) {

          //console.log("resource", resource);
          //console.log("appointment exists", appointment);
          // console.log('if', index)
          let slotStartDisplay = new Date(new Date().toISOString().split('T')[0] + ' 10:00:00');
          slotStartDisplay = (slotStartDisplay.getHours() > 12) ? (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes())) : (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes()));

          let slotEndDisplay = new Date(new Date().toISOString().split('T')[0] + ' 22:00:00');
          slotEndDisplay = (slotEndDisplay.getHours() > 12) ? (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes())) : (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes()));

          let startDate = new Date().toISOString().split('T')[0] + ' 10:00:00';
          let endDate = new Date().toISOString().split('T')[0] + ' 22:00:00';

          let appointment = {
            AppointmentDate: new Date(new Date().toISOString().split('T')[0]),
            SlotStart: startDate,
            SlotEnd: endDate,
            SlotStartDisplay: slotStartDisplay,
            SlotEndDisplay: slotEndDisplay,
            Customer: null,
            Service: null,
            RetailPrice: 0,
            Comments: '',
            EmployeeID: resource.id,
            EmployeeName: resource.name,
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            StoreName: this.props.user.store.StoreName,
            Status: resource.status
          }
        }
        else {
          // console.log('else', index)
          let statusColor = '#99A1C6'
          if (appointment.hasOwnProperty('Status') && appointment.Status !== '') {
            statusColor = (appointment.Status === 'Leave' ? 'purple' : appointment.Status === 'Guest Arrived' ? '#51CDA0' : appointment.Status === 'Service Done' ? '#4C9CA0' : appointment.Status === 'Invoice Created' ? '#AE7D99' : 'red')
          }

          let title = ''
          if (appointment.Customer && appointment.Customer.PhoneNo !== "") {
            title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.PhoneNo + ')'
          }
          else if (appointment.Customer && appointment.Customer.EmailID !== "") {
            title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.EmailID + ')'
          }

          let newEvent = {
            id: appointment.key,
            title: (appointment.Status === 'Leave' ? 'Blocked - ' + appointment.EmployeeName + ' on Leave' : title),
            start: appointment.SlotStart,
            end: appointment.SlotEnd,
            resourceId: appointment.EmployeeID,
            bgColor: statusColor,
            resizable: appointment.Status === 'Leave' ? false : true,
            movable: appointment.Status === 'Leave' ? false : true,
            startResizable: appointment.Status === 'Leave' ? false : true,
            endResizable: appointment.Status === 'Leave' ? false : true,
            creatable: appointment.Status === 'Leave' ? false : true
          }
          // console.log('newEvent',newEvent)
          schedulerData.addEvent(newEvent);
        }
      }
    }
    else {
      if (resources.filter((e) => e.status === 'Leave').length > 0) {

        for (let index = 0; index < resources.length; index++) {

          const resource = resources[index];

          if (resource.status === 'Leave') {

            let slotStartDisplay = new Date(new Date().toISOString().split('T')[0] + ' 10:00:00');
            slotStartDisplay = (slotStartDisplay.getHours() > 12) ? (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes())) : (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes()));
            let slotEndDisplay = new Date(new Date().toISOString().split('T')[0] + ' 22:00:00');
            slotEndDisplay = (slotEndDisplay.getHours() > 12) ? (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes())) : (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes()));

            let startDate = new Date().toISOString().split('T')[0] + ' 10:00:00';
            let endDate = new Date().toISOString().split('T')[0] + ' 22:00:00';

            let appointment = {
              AppointmentDate: new Date(new Date().toISOString().split('T')[0]),
              SlotStart: startDate,
              SlotEnd: endDate,
              SlotStartDisplay: slotStartDisplay,
              SlotEndDisplay: slotEndDisplay,
              Customer: null,
              Service: null,
              RetailPrice: 0,
              Comments: '',
              EmployeeID: resource.id,
              EmployeeName: resource.name,
              RegistrationID: this.props.user.user.RegistrationID,
              StoreID: this.props.user.store.key,
              StoreName: this.props.user.store.StoreName,
              Status: resource.status
            }

            this.props.saveAppointment(appointment, '').then((key) => {

              let newEvent = {
                id: resource.id,
                title: 'Blocked - ' + resource.name + ' on Leave',
                start: new Date().toISOString().split('T')[0] + ' 10:00:00',
                end: new Date().toISOString().split('T')[0] + ' 22:00:00',
                resourceId: resource.id,
                bgColor: 'purple',
                resizable: false,
                movable: false,
                startResizable: false,
                endResizable: false,
                creatable: false
              }

              schedulerData.addEvent(newEvent);

              let appointments = this.state.appointments;
              //console.log("before", appointments.length);

              let recordIndex = -1;
              appointments.map((f, index) => { if (f.key === key) recordIndex = index });
              //console.log(recordIndex);
              if (recordIndex >= 0)
                appointments.splice(recordIndex, 1);

              appointments.push({ key: key, ...appointment });
              //console.log("after", appointments.length);

              this.setState({ appointments: appointments, viewModel: schedulerData, events: schedulerData.events, showEvent: false, saving: false, service: null, selectedCustomer: null, comments: '', employeeID: '' })
            })
          }
        }
      }
    }
    this.setState({ viewModel: schedulerData, events: schedulerData.events, appointments: (appointmentList ? appointmentList : []) });
  }

  async loadResources() {
    let resources = await this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key, 0, 10000, 'active', '', '', '', '', '',
      '', '', '', '', '', '', 'yes')
      .then((result) => {

        let resources = []
        for (let index = 0; index < result.searchResult.length; index++) {
          const resource = result.searchResult[index];
          resources.push(
            {
              id: resource.key,
              status: '',
              name: (resource.EmployeeFirstName.trim() + ' ' + resource.EmployeeLastName.trim()).trim()
            }
          )
        }
        // this.setState({ resources: resources, totalItemsCount: result.totalItemsCount, loading: false });
        return resources;
      })
    return resources;
  }

  getAppointments(date) {
    //console.log(date);
    this.props.getAppointments(this.props.user.store.key, date).then((appointmentList) => {

      //console.log(appointmentList.length);
      let schedulerData = this.state.viewModel;

      for (let i = 0; i < appointmentList.length; i++) {
        const appointment = appointmentList[i];

        let statusColor = '#99A1C6'
        if (appointment.hasOwnProperty('Status') && appointment.Status !== '') {
          statusColor = (appointment.Status === 'Leave' ? 'purple' : appointment.Status === 'Guest Arrived' ? '#51CDA0' : appointment.Status === 'Service Done' ? '#4C9CA0' : appointment.Status === 'Invoice Created' ? '#AE7D99' : 'red')
        }

        let title = ''
        if (appointment.Customer && appointment.Customer.PhoneNo !== "") {
          title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.PhoneNo + ')'
        }
        else if (appointment.Customer && appointment.Customer.EmailID !== "") {
          title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.EmailID + ')'
        }

        let newEvent = {
          id: appointment.key,
          title: (appointment.Status === 'Leave' ? 'Blocked - ' + appointment.EmployeeName + ' on Leave' : title),
          start: appointment.SlotStart,
          end: appointment.SlotEnd,
          resourceId: appointment.EmployeeID,
          bgColor: statusColor,
          resizable: true,
          movable: true,
          startResizable: true,
          endResizable: true
        }
        schedulerData.addEvent(newEvent);
      }

      this.setState({ viewModel: schedulerData, events: schedulerData.events, appointments: appointmentList });

    })
  }

  onSelectDate = (schedulerData, date) => {
    let selectedDate = new Date(date._d).toISOString().split('T')[0];
    schedulerData.setDate(selectedDate);
    this.getAppointments(selectedDate);
    this.setState({ showEvent: false, saving: false, service: null, selectedCustomer: null, comments: '', employeeID: '', employee: '' })
  }

  prevClick = (schedulerData) => {
    //console.log('prev', schedulerData.startDate);
    schedulerData.prev();
    this.getAppointments(schedulerData.startDate);
    this.setState({ showEvent: false, saving: false, service: null, selectedCustomer: null, comments: '', employeeID: '', employee: '' })
  }

  nextClick = (schedulerData) => {
    schedulerData.next();
    this.getAppointments(schedulerData.startDate);
    this.setState({ showEvent: false, saving: false, service: null, selectedCustomer: null, comments: '', employeeID: '', employee: '' })
  }

  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    if (schedulerData.cellUnit === CellUnits.Hour) {
      let hour = localeMoment(time).hour();
      if (hour < 1)
        return true;
    }
    else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 0 || dayOfWeek === 6)
        return true;
    }

    return false;
  }

  newEvent = async (schedulerData, slotId, slotName, start, end) => {

    //&& this.state.resources.filter((f) => f.id === slotId && f.status === '').length > 0
    if (this.state.currentView === 5) {
      let slotStartDisplay = new Date(start);
      slotStartDisplay = (slotStartDisplay.getHours() > 12) ? (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes())) : (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes()));
      let slotEndDisplay = new Date(end);
      slotEndDisplay = (slotEndDisplay.getHours() > 12) ? (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes())) : (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes()));
      //console.log("slotStartDisplay", slotStartDisplay);
      //console.log("slotEndDisplay", slotEndDisplay);
      let startDate = new Date(start);
      let endDate = new Date(end);

      var res = Math.abs(startDate - endDate) / 1000;
      // get hours        
      var hours = Math.floor(res / 3600) % 24;
      // get minutes
      var minutes = Math.floor(res / 60) % 60;

      var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

      // let employeeServiceMapping = this.state.employeeServiceMapping;
      // console.log("employeeServiceMapping", employeeServiceMapping);
      // let services = employeeServiceMapping ? employeeServiceMapping.filter((f) => f.EmployeeID === slotId) : [];
      // console.log("new event- services", services);

      let resources = this.state.allResources;
      // console.log('tabSelected', this.state.tabSelected)
      this.handleOnEmployeeChange(slotId,slotName)
      this.setState({
        mode: 'new',
        rightHeight: 39, resources: resources,
        showEvent: true, comments: '', viewModel: schedulerData, service: null, status: '', selectedIndex: -1,
        employeeID: slotId, slotEndDisplay: slotEndDisplay, slotStartDisplay: slotStartDisplay, duration: duration, newAppointments: [],
        employee: slotName, slotStart: start, slotEnd: end, eventID: '', appointmentDate: new Date(schedulerData.startDate), 
        // tabSelected: 'Services', 
        packages: []
      })
    }
  }

  eventClick = async (schedulerData, event) => {
    // console.log('Event click')
    if (this.state.currentView === 5) {

      let appointmentList = this.state.appointments;
      //console.log("appointmentList", appointmentList);

      let appointment = appointmentList.filter((e) => e.key === event.id)[0];

      if (appointment && appointment.Status !== 'Leave') {
        let customerID = appointment.Customer.key;

        let appointments = this.state.appointments.filter((c) => c.Status !== 'Leave' && c.Customer.key === customerID);
        // console.log("customer appointments", appointments)
        this.getCustomerAdditionalInfo(appointment.Customer);

        if (appointment.hasOwnProperty('PackageID') && appointment.PackageID !== "") {
          this.setState({ tabSelected: 'Packages' })
        }
        else {
          this.setState({ tabSelected: 'Services' })
        }
        this.setState({
          rightHeight: 39,
          showEvent: true, comments: appointment.Comments, newAppointments: appointments, selectedCustomer: appointment.Customer
        });
      }
    }
  };

  async onSaveAppointment() {

    let customerError = ""
    //let appointmentError = ""
    this.setState({ saving: true })

    if (this.state.selectedCustomer === null || this.state.selectedCustomer === undefined) {
      customerError = "Please select Customer"
    }
    if (this.state.newAppointments.length === 0) {
      //appointmentError = "Please add atleast one appointment"
      alert("Please add atleast one appointment");
      return
    }
    if (customerError !== "" //|| appointmentError !== ""
    ) {
      this.setState({
        customerError: customerError,
        //appointmentError: appointmentError,
        saving: false
      })
      return
    }
    let appointments = this.state.appointments;
    let schedulerData = this.state.viewModel;
    let services = "";
    for (let index = 0; index < this.state.newAppointments.length; index++) {
      
      let appointment = this.state.newAppointments[index];
      appointment.Customer = this.state.selectedCustomer;
      appointment.Customer.Note = this.state.comments;
      appointment.Comments = this.state.comments;
      services = services + (services.trim()!=="" ? ",": "") +  appointment.Service.name;
      console.log("appointment", appointment)
      let key = await this.props.saveAppointment(appointment, appointment.key);

      let title = ''
      if (appointment.Customer.PhoneNo !== "") {
        title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.PhoneNo + ') '
      }
      else if (appointment.Customer.EmailID !== "") {
        title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.EmailID + ') '
      }

      //console.log("key", appointment.key);
      if (appointment.key === "") {
        let newEvent = {
          id: key,
          title: title,
          start: appointment.SlotStart,
          end: appointment.SlotEnd,
          resourceId: appointment.EmployeeID,
          bgColor: '#99A1C6',
          resizable: true,
          movable: true,
          startResizable: true,
          endResizable: true
        }
        schedulerData.addEvent(newEvent);
      }
      else {
        // alert('called');
        let status = appointment.Status;
        let objEvent = schedulerData.events.filter((s => s.id === appointment.key))[0];
        // console.log("event", objEvent);
        let color = (status === 'Guest Arrived' ? '#51CDA0' : status === 'Service Done' ? '#4C9CA0' : status === 'Invoice Created' ? '#AE7D99' : status === 'Cancelled' ? 'red' : status === '' ? '#99A1C6' : '');
        schedulerData.removeEvent(objEvent);

        let newEvent = {
          id: appointment.key,
          title: title,
          start: appointment.SlotStart,
          end: appointment.SlotEnd,
          resourceId: appointment.EmployeeID,
          bgColor: color,
          resizable: true,
          movable: true,
          startResizable: true,
          endResizable: true
        }
        schedulerData.addEvent(newEvent);
      }

      appointment.key = key;

      let recordIndex = -1;
      appointments.map((f, index) => { if (f.key === key) recordIndex = index });
      if (recordIndex >= 0)
        appointments.splice(recordIndex, 1);


      appointments.push(appointment);
    }
    this.props.getToastr("Appointment Saved Successfully");

    this.state.newAppointments.sort(function (a, b) {
      var dateA = new Date(a.SlotStart);
      var dateB = new Date(b.SlotStart);
      return dateA - dateB
    });

    console.log("customerNote", this.state.customerNote)
    console.log("comments", this.state.comments)

    if (this.state.customerNote !== this.state.comments) {
      this.props.updateCustomerNote(this.state.comments, this.state.selectedCustomer.key);
    }

    if (this.state.mode === 'new' && this.state.selectedCustomer && this.state.selectedCustomer.PhoneNo.trim() !== "") {
      let slotStart = this.state.newAppointments[0].SlotStartDisplay;
      let customerName = (this.state.selectedCustomer.FirstName.trim() + ' ' + this.state.selectedCustomer.LastName.trim()).trim();

      let transactionData = {
        AppointmentDate: schedulerData.startDate,
        SlotStartDisplay: slotStart,
        CustomerName: customerName,
        StoreName:this.props.user.store.StoreName,
        StoreContactNo: this.props.user.store.PhoneNo,
        StoreEmailID: this.props.user.store.EmailID,
        StoreAddress: this.props.user.store.Address2 + " ," + this.props.user.store.Address2 + " ," + this.props.user.store.City,
        Services:services
      }

      let sms = {
        RegistrationID: this.props.user.user.RegistrationID,
        StoreID: this.props.user.store.key,
        UserID: this.props.user.user.key,
        Module: 'appointment',
        TransactionType: 'Appointment',
        MobileNo: this.state.selectedCustomer.PhoneNo.trim(),
        TransactionData: transactionData
      }
      console.log("sms" , sms);
      this.props.sendSMS(sms);
    }
    this.setState({ viewModel: schedulerData, events: schedulerData.events, appointments: appointments, showEvent: false, saving: false, service: null, selectedCustomer: null, comments: '', employeeID: '', packageID: '', tabSelected: 'Services', rightHeight: 90 })
  }

  onCustomerChange(customer) {
    if (customer) {
      this.onCustomerSelected(customer)
    }
    else {
      this.onRemoveCustomer()
    }
  }

  onRemoveCustomer() {
    this.setState({ selectedCustomer: null, customerError: '' })
  }

  async onCustomerSelected(selectedCustomer) {
    
    this.getCustomerAdditionalInfo(selectedCustomer);

    this.setState({
      selectedCustomer: selectedCustomer,
      comments: selectedCustomer.Note,
      customerError: ''
    })
  };

  async getCustomerAdditionalInfo(selectedCustomer)
  {

    let packages = await this.props.getPackagesByCustomerID(selectedCustomer.key)
    
    let visitedInDays = 0;
    let visited = '';
    let services = [];
    let customerProducts = [];

    if(selectedCustomer.LastVisitedOn !== "")
    {
      visitedInDays = ((new Date().setHours(0, 0, 0, 0) - new Date(selectedCustomer.LastVisitedOn).setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24));

      const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
      let current_datetime = new Date(selectedCustomer.LastVisitedOn)
      let formatted_month = current_datetime.getDate() + "-" + months[current_datetime.getMonth()]
      let formatted_date = current_datetime.getDate() + "-" + months[current_datetime.getMonth()] + "-" + current_datetime.getFullYear()
      //console.log(formatted_date)

      if (visitedInDays > 365) {
        visited = 'Visited more than a year (' + formatted_date +')'
      }
      else if (visitedInDays > 30) {
        visited = 'Visited more than a month (' + formatted_date +')'
      }
      else if (visitedInDays < 30) {
        visited = 'Visited ' + visitedInDays + ' days back (' + formatted_month + ')'
      }
    }

    if (!isNullOrUndefined(selectedCustomer.LastTransactionKey) &&  selectedCustomer.LastTransactionKey !== "") {
      let sale = await this.props.getSaleDetails(selectedCustomer.LastTransactionKey);
      //console.log('sale', JSON.stringify(sale));
      for (let i = 0; i < sale.LineItems.length; i++) {
        const lineItem = sale.LineItems[i];
        if (!services.includes(lineItem.Product.Name))
          services.push(lineItem.Product.Name);
      }
    }

    let result = await this.props.getTopProductsForACustomer(selectedCustomer.key, 3);
    //console.log("result", result);
    for (let i = 0; i < result.searchResult.length; i++) {
      const product = result.searchResult[i];
      //console.log("prod", product);
      if (!customerProducts.includes(product.name))
        customerProducts.push(product.name);
    }
    // console.log(customerProducts.toString());

    this.setState({
      customerNote: selectedCustomer.Note,
      lastVisited:  visited,
      lastServices: services.toString(),
      packages: packages,
      customerProducts: customerProducts.toString(),
    })

  };

  eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
    let appointment = this.state.appointments.filter((f => f.key === eventItem.id))[0];
    // console.log("this.state.currentView",this.state.currentView);
    // console.log("appointment",appointment);
    if (appointment) {
      return (
        <React.Fragment>
          <h3>{title}</h3>
          <h5>{appointment.Service ? appointment.Service.label : ""}</h5>
          <h5>{appointment.Comments}</h5>
          <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
          {(statusColor === '#99A1C6' && this.state.currentView === 5) &&
            <React.Fragment>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group " >
                    <button className="btn btn-flat btn-md btn-secondary pull-right"
                      onClick={() => this.updateAppointmentStatus('Guest Arrived', eventItem)} style={{ marginTop: "-25px", marginLeft: "10px", width: "150px" }}>Guest Arrived</button>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-12">
                  <div className="form-group " >
                    <button className="btn btn-flat btn-md btn-danger pull-right"
                      onClick={() => this.setState({ event: eventItem, showCancelModal: true })} style={{ marginTop: "-25px", marginLeft: "10px", width: "150px" }}>Cancel</button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          }
          {(statusColor === '#51CDA0' && this.state.currentView === 5) &&
            <div className="row">
              <div className="col-md-12">
                <div className="form-group " >
                  <button className="btn btn-flat btn-md btn-primary pull-right"
                    onClick={() => this.updateAppointmentStatus('Service Done', eventItem)} style={{ marginTop: "-25px", marginLeft: "10px", width: "150px" }}>Service Done</button>
                </div>
              </div>
            </div>
          }
          {((statusColor === '#51CDA0' || statusColor === '#4C9CA0') && appointment.PackageID === "" && this.state.currentView === 5) &&
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-12">
                <div className="form-group " >
                  <button className="btn btn-flat btn-md btn-success pull-right"
                    onClick={() => this.onShowCreateInvoice(eventItem)} style={{ marginTop: "-25px", marginLeft: "10px", width: "150px" }}>Create Invoice</button>
                </div>
              </div>
            </div>}
        </React.Fragment>
      );
    }
    else {
      return (
        <React.Fragment>
          <h3>{title}</h3>
          <h5>{start.format("HH:mm")} - {end.format("HH:mm")}</h5>
        </React.Fragment>
      );
    }
  }

  updateAppointmentStatus = async (status, event) => {

    if (status === 'Guest Arrived') {
      let appointment = this.state.appointments.filter((f => f.key === event.id))[0];
      let appointmentList = this.state.appointments.filter((e => e.Customer.key === appointment.Customer.key));
      for (let i = 0; i < appointmentList.length; i++) {
        const obj = appointmentList[i];
        if (obj.Status === '') {
          obj.Status = status;
          let key = await this.props.updateAppointmentStatus(status, obj.key);
          //console.log("key", key);

          let appointments = this.state.appointments;

          let recordIndex = -1;
          appointments.map((f, index) => { if (f.key === key) recordIndex = index });
          // console.log(recordIndex);
          if (recordIndex >= 0)
            appointments.splice(recordIndex, 1);

          appointments.push({ key: key, ...obj });


          let objEvent = this.state.viewModel.events.filter((s => s.id === obj.key))[0];
          // console.log("Event", JSON.stringify(objEvent));
          objEvent.bgColor = (status === 'Guest Arrived' ? '#51CDA0' : status === 'Service Done' ? '#4C9CA0' : status === 'Invoice Created' ? '#AE7D99' : 'red');
          let schedulerData = this.state.viewModel;
          schedulerData.removeEvent(objEvent);
          schedulerData.addEvent(objEvent);
          this.setState({ viewModel: schedulerData, events: schedulerData.events, appointments: appointments });
        }
      }
    }
    else {
      this.props.updateAppointmentStatus(status, event.id).then(async (key) => {
        let schedulerData = this.state.viewModel;
        event.bgColor = (status === 'Guest Arrived' ? '#51CDA0' : status === 'Service Done' ? '#4C9CA0' : status === 'Invoice Created' ? '#AE7D99' : 'red');
        schedulerData.removeEvent(event);
        schedulerData.addEvent(event);

        let appointments = this.state.appointments;
        let appointment = this.state.appointments.filter((f => f.key === event.id))[0];

        if (appointment)
          appointment.Status = status;

        if (status === 'Service Done' && appointment && appointment.hasOwnProperty('PackageID') && appointment.PackageID !== "") {
          //console.log("appointment", appointment);
          let objPackage = await this.props.getPackageDetails(appointment.PackageID);
          //console.log("objPackage", objPackage);
          let employee = {
            EmployeeID: appointment.EmployeeID,
            EmployeeName: appointment.EmployeeName,
          }
          let packageLineItemIndex =-1;
          if(appointment.hasOwnProperty('PackageLineItemIndex') && appointment.PackageLineItemIndex > -1){
            packageLineItemIndex =appointment.PackageLineItemIndex;
          }
          else{
            //get first non completed status service 
            for (let index = 0; index < objPackage.PackageServices.length; index++) {
              if(objPackage.PackageServices[index].ServiceID === appointment.Service.value && objPackage.PackageServices[index].Status !== 'Completed'){
                packageLineItemIndex = index;
                break;
              }
            }
          }
          console.log("packageLineItemIndex", packageLineItemIndex);
          if (packageLineItemIndex > -1) {
            let completedCount = 0;
            let status = 'Pending';
            //console.log("service",service);
            //console.log("appointment",appointment);
            objPackage.PackageServices[packageLineItemIndex].ServiceDate = (appointment.AppointmentDate.hasOwnProperty('seconds') ? (new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0] : (new Date(appointment.AppointmentDate)).toISOString().split('T')[0]);
            objPackage.PackageServices[packageLineItemIndex].Employee = employee;
            objPackage.PackageServices[packageLineItemIndex].Status = 'Completed';

            for (let index = 0; index < objPackage.PackageServices.length; index++) {
              let packageService = objPackage.PackageServices[index]
              if (packageService.Status === 'Completed')
                completedCount += 1;
            }
            if (completedCount > 0) {
              if (completedCount === objPackage.PackageServices.length) {
                status = 'Completed'
              }
              if (completedCount < objPackage.PackageServices.length) {
                status = 'Partial'
              }
            }
            objPackage.PackageStatus = status;
            console.log('objPackage',objPackage);
            this.props.updatePackage(objPackage, appointment.PackageID);
          }
        }
        let recordIndex = -1;
        appointments.map((f, index) => { if (f.key === appointment.key) recordIndex = index });
        // console.log(recordIndex);
        if (recordIndex >= 0)
          appointments.splice(recordIndex, 1);

        appointments.push({ key: key, ...appointment });
        this.setState({ viewModel: schedulerData, events: schedulerData.events, appointments: appointments });
      });
    }
  }

  onCancelAppointment = () => {
    this.setState({ saving: true });
    if (this.state.cancellationReason.trim() === '') {
      alert('Please provide reason for cancellation');
      return
    }
    let event = this.state.event;
    this.props.cancelAppointment(this.state.cancellationReason, event.id).then((key) => {

      let appointment = this.state.appointments.filter((f => f.key === event.id))[0];
      appointment.Status = 'Cancelled'

      let appointments = this.state.appointments;

      let recordIndex = -1;
      appointments.map((f, index) => { if (f.key === event.id) recordIndex = index });
      // console.log(recordIndex);

      if (recordIndex >= 0)
        appointments.splice(recordIndex, 1);

      appointments.push({ key: key, ...appointment });


      let schedulerData = this.state.viewModel;
      event.bgColor = 'red';
      schedulerData.removeEvent(event);
      schedulerData.addEvent(event);
      this.setState({ viewModel: schedulerData, events: schedulerData.events, saving: false, showCancelModal: false, appointments: appointments });
    });
  };

  onShowCreateInvoice(event) {
    // console.log("appointments 1", this.state.appointments);
    let appointment = this.state.appointments.filter((a) => a.key === event.id)[0];
    let customerID = appointment.Customer.key;
    let appointments = this.state.appointments.filter((c) => c.Customer.key === customerID && (c.Status.trim() === 'Service Done' || c.Status.trim() === 'Guest Arrived') && c.PackageID === '');
    // console.log("appointments 2", appointments);
    this.setState({ customerAppointments: appointments, appointmentCustomer: appointment.Customer, showCreateInvoice: true });
  };

  onCreateInvoice = () => {
    this.setState({ saving: true });

    if (this.state.customerAppointments.length === 0) {
      alert('Please select atleast one service to create invoice');
      this.setState({ saving: false });
      return
    }

    let transactionType = this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted && e.TransactionType === 'Sales Invoice')[0];

    let sale = {
      TransactionType: transactionType,
      Customer: this.state.customerAppointments[0].Customer,
      RegistrationID: this.props.user.user.RegistrationID,
      CashRegister: null,
      Store: this.props.user.store,
      LineItems: [],
      BillQty: 0,
      BillSubTotal: 0,
      BillDiscount: 0,
      BillTaxAmount: 0,
      BillAmount: 0,
      RoundOff: 0,
      BillBalanceAmount: 0,
      BillPaidAmount: 0,
      BillChangeDue: 0,
      Payments: null,
      ParentTransactionNo: "",
      ParentTransactionKey: "",
      Employee: null,
      Notes: "",
      TaxSummaryList: [],
      PlaceOfSupply: "",
      BillingAddress: null,
      DeliveryCustomer: null,
      DeliveryCustomerAddress: null,
      DeliveryDate: "",
      DeliveryTime: "",
      deliveryNotes: "",
      BackEndUpdate: false,
      ReceivedPaymentAmount: null,
      ConvertedFromTransactionBalanceAmount: null,
      IsAppointmentInvoice: true,
    }

    let billQty = 0,
      billSubTotal = 0,
      billDiscount = 0,
      billAmount = 0

    for (let i = 0; i < this.state.customerAppointments.length; i++) {
      let lineItem = {};

      const appointment = this.state.customerAppointments[i];
      //console.log("appointment", appointment);

      let service = this.props.config.Products.filter((f) => f.key === appointment.Service.value)[0];
      // console.log("service", service);

      let employee = this.props.config.ActiveEmployees.filter((e) => e.key === appointment.EmployeeID)[0];
      employee.EmployeeName = (employee.EmployeeFirstName.trim() + ' ' + employee.EmployeeLastName.trim()).trim()
      //console.log("employee", employee);

      lineItem.RetailPrice = service.RetailPrice
      lineItem.DiscountPer = 0;
      lineItem.DiscountAmount = 0;
      lineItem.Qty = 1;
      lineItem.SubTotal = service.RetailPrice
      lineItem.ChargeTaxOnProduct = false
      lineItem.PriceBasedTax = false
      lineItem.HSNSACCode = service.HSNSACCode
      lineItem.TaxInclusive = service.TaxInclusive
      lineItem.TaxGroup = null
      lineItem.TaxID = ''
      lineItem.SubTotalWithDiscount = 0
      lineItem.TaxableAmount = 0
      lineItem.TaxComponentAmount = []
      lineItem.TotalTax = 0
      lineItem.Amount = service.RetailPrice
      lineItem.Product = service
      lineItem.Employee = employee //{ key: appointment.EmployeeID, EmployeeFirstName: appointment.EmployeeName }
      lineItem.Notes = ''
      lineItem.AppointmentID = appointment.key
      billQty = billQty + lineItem.Qty
      billSubTotal = billSubTotal + lineItem.SubTotal
      billDiscount = billDiscount + lineItem.DiscountAmount
      billAmount = billAmount + lineItem.Amount
      sale.LineItems.push(lineItem);
    }

    if (sale) {
      sale.BillQty = billQty
      sale.BillSubTotal = billSubTotal
      sale.BillDiscount = billDiscount
      sale.BillAmount = billAmount
    }
    this.props.history.push({
      pathname: '/home/sales/sales',
      state: {
        type: actionTypes.SALE_NEW,
        key: "",
        transactionType: transactionType,
        sale: sale
      }
    })

    //state:{type:actionTypes.SALE_NEW, key: this.state.key, transactionType:this.state.salesReturnTransactionType, sale:this.state.sale}  } } 
  };

  onRemoveService(index) {
    let customerAppointments = this.state.customerAppointments
    customerAppointments.splice(index, 1)
    this.setState({ customerAppointments: customerAppointments });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (window.confirm(`Do you want to adjust the end time of the appointment? eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {

      let appointments = this.state.appointments;
      let appointment = appointments.filter((a) => a.key === event.id)[0];

      let slotEndDisplay = new Date(newEnd);
      slotEndDisplay = (slotEndDisplay.getHours() > 12) ? (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes())) : (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes()));

      let startDate = new Date(appointment.SlotStart);
      let endDate = new Date(newEnd);

      var res = Math.abs(startDate - endDate) / 1000;
      // get hours        
      var hours = Math.floor(res / 3600) % 24;
      // get minutes
      var minutes = Math.floor(res / 60) % 60;

      var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

      appointment.SlotEnd = newEnd;
      appointment.SlotEndDisplay = slotEndDisplay;
      appointment.Duration = duration;

      this.props.saveAppointment(appointment, appointment.key).then((key) => {
        this.props.getToastr("Appointment Updated Successfully")
        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
          viewModel: schedulerData, appointments: appointments, events: schedulerData.events
        })
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (window.confirm(`Do you want to change the start time of the appointment? {eventTitle: ${event.title}, newStart: ${newStart}}`)) {

      let appointments = this.state.appointments;
      let appointment = appointments.filter((a) => a.key === event.id)[0];

      let slotStartDisplay = new Date(newStart);
      slotStartDisplay = (slotStartDisplay.getHours() > 12) ? (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes())) : (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes()));

      let startDate = new Date(newStart);
      let endDate = new Date(appointment.SlotEnd);

      var res = Math.abs(startDate - endDate) / 1000;
      // get hours        
      var hours = Math.floor(res / 3600) % 24;
      // get minutes
      var minutes = Math.floor(res / 60) % 60;

      var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

      appointment.SlotStart = newStart;
      appointment.SlotStartDisplay = slotStartDisplay;
      appointment.Duration = duration;

      this.props.saveAppointment(appointment, appointment.key).then((key) => {
        this.props.getToastr("Appointment Updated Successfully")
        schedulerData.updateEventStart(event, newStart);
        this.setState({
          viewModel: schedulerData, appointments: appointments, events: schedulerData.events
        })
      })
    }
  }

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (window.confirm(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {

      let appointments = this.state.appointments;
      let appointment = appointments.filter((a) => a.key === event.id)[0];

      let slotStartDisplay = new Date(start);
      slotStartDisplay = (slotStartDisplay.getHours() > 12) ? (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes())) : (slotStartDisplay.getHours() + ":" + (slotStartDisplay.getMinutes() < 10 ? '0' + slotStartDisplay.getMinutes() : slotStartDisplay.getMinutes()));

      let slotEndDisplay = new Date(end);
      slotEndDisplay = (slotEndDisplay.getHours() > 12) ? (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes())) : (slotEndDisplay.getHours() + ":" + (slotEndDisplay.getMinutes() < 10 ? '0' + slotEndDisplay.getMinutes() : slotEndDisplay.getMinutes()));

      let startDate = new Date(start);
      let endDate = new Date(end);

      var res = Math.abs(startDate - endDate) / 1000;
      // get hours        
      var hours = Math.floor(res / 3600) % 24;
      // get minutes
      var minutes = Math.floor(res / 60) % 60;

      var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

      appointment.SlotStart = start;
      appointment.SlotStartDisplay = slotStartDisplay;
      appointment.SlotEnd = end;
      appointment.SlotEndDisplay = slotEndDisplay;
      appointment.Duration = duration;
      appointment.EmployeeID = slotId;
      appointment.EmployeeName = slotName;

      this.props.saveAppointment(appointment, appointment.key).then((key) => {
        this.props.getToastr("Appointment Updated Successfully")
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.setState({
          viewModel: schedulerData, appointments: appointments, events: schedulerData.events
        })
      });
    }
  }

  handleOnServiceChange(service, e) {
    //console.log(service);
    if (this.state.tabSelected === 'Packages' && service) {
      let employeeServiceMapping = this.state.employeeServiceMapping;
      // console.log(employeeServiceMapping);
      let employees = employeeServiceMapping.filter((f) => f.value === service.value);
      //console.log(employees);
      let resources = [];
      for (let index = 0; index < employees.length; index++) {
        const employee = employees[index];
        if (resources.filter((e) => e.id === employee.EmployeeID).length === 0) {
          resources.push({
            id: employee.employeeID,
            name: employee.employeeName,
            status: employee.employeeStatus
          })
        }
      }
      if(resources.length ===0){
        resources = this.state.allResources
      }
      this.onSlotStartDateChange(this.state.slotStartDisplay, service);
      this.setState({ service: service, selectedPackageServiceEmployees: resources, serviceError: '' });
    }
    else {
      console.log('handleOnServiceChange - service selected', service);
      this.onSlotStartDateChange(this.state.slotStartDisplay, service);
      this.setState({ service: service, duration: service.duration, serviceError: '' });
    }
  }

  handleOnEmployeeChange = async (employeeID, employee) => {
    
    let services = []
    if (employeeID !== "" && this.state.tabSelected === 'Services') {
      //get employee service mapping
       let employeeServiceMapping = this.state.employeeServiceMapping;
       if( employeeServiceMapping && employeeServiceMapping.filter((f) => f.employeeID === employeeID).length >  0){
        services = employeeServiceMapping.filter((f) => f.employeeID === employeeID);
       }
      else
      {
        console.log('employee service mapping NOT found')
        services = this.state.completeServiceList
      }
      // console.log("services", services);
      this.setState({
        service: null,
        employeeID: employeeID,
        employee: employee,
        employeeError: "",
        employeeServices: services
      })
    }
    else {
      this.setState({
        employeeID: employeeID,
        employee: employee,
        employeeError: "",
        employeeServices:[]
      })
    }
  }

  handleOnPackageChange = async (evt) => {

    let packageServicesFinal = [];
    let packageID = evt.target.value;
    let packages = this.state.packages;
    console.log('packageID', packageID)
    if (packageID !== "") {
      let objPackage = packages.filter((e) => e.key === packageID)[0];
      if (objPackage) {
        let packageServices = objPackage.PackageServices;
        // .filter((p) => p.Status !== 'Completed');
        console.log('packageServices', packageServices)
        if (packageServices && packageServices.length > 0) {
          let completeServiceList = this.state.completeServiceList;
          console.log('completeServiceList', completeServiceList)
          for (let index = 0; index < packageServices.length; index++) {
            if (completeServiceList.filter((e) => e.value === packageServices[index].ServiceID).length > 0 && packageServices[index].Status !== 'Completed')
              {
                let service =  completeServiceList.filter((e) => e.value === packageServices[index].ServiceID)[0];
                let packageService = {...service};
                console.log('service found', service)
                packageService.serviceID =service.value;
                packageService.value = service.value + "_" + index;
                packageService.packageLineItemIndex = index;
                packageServicesFinal.push(packageService);
              }              
          }
        }
      }
    }
    console.log('packageServicesFinal', packageServicesFinal)
    this.setState({
      service: null,
      employeeID: '',
      packageID: packageID,
      packageServices:packageServicesFinal,
      employeeServices: [],
    });
  }

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;
    if (date != undefined)
      selectDate = date;

    let startDate = num === 0 ? selectDate :
      schedulerData.localeMoment(selectDate).add(1 * num, 'days').format(DATE_FORMAT),
      endDate = schedulerData.localeMoment(startDate).add(0, 'days').format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;
    if (viewType === ViewTypes.Custom1) {
      let monday = schedulerData.localeMoment(selectDate).startOf('week').format(DATE_FORMAT);
      startDate = num === 0 ? monday : schedulerData.localeMoment(monday).add(1 * num, 'weeks').format(DATE_FORMAT);
      endDate = schedulerData.localeMoment(startDate).add(0, 'weeks').endOf('week').format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    } else if (viewType === ViewTypes.Custom2) {
      let firstDayOfMonth = schedulerData.localeMoment(selectDate).startOf('month').format(DATE_FORMAT);
      startDate = num === 0 ? firstDayOfMonth : schedulerData.localeMoment(firstDayOfMonth).add(1 * num, 'months').format(DATE_FORMAT);
      endDate = schedulerData.localeMoment(startDate).add(0, 'months').endOf('month').format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    }

    return {
      startDate,
      endDate,
      cellUnit
    };
  }

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    schedulerData.config.customCellWidth = view.viewType === ViewTypes.Custom ? 50 : 170;

    //console.log("viewType", view.viewType)
    //console.log("start Date", schedulerData.startDate)
    //console.log("end Date", schedulerData.endDate)

    this.setState({ currentView: view.viewType });

    if (view.viewType === 5) {
      schedulerData.setDate(new Date().toISOString().split('T')[0]);
      this.getAppointments(schedulerData.startDate);
    }
    else {

      this.props.getAppointmentsForAPeriod(this.props.user.user.RegistrationID, schedulerData.startDate, schedulerData.endDate).then((appointmentList) => {

        //console.log(appointmentList.length);
        let schedulerData = this.state.viewModel;

        for (let i = 0; i < appointmentList.length; i++) {
          const appointment = appointmentList[i];

          let statusColor = '#99A1C6'
          if (appointment.hasOwnProperty('Status') && appointment.Status !== '') {
            statusColor = (appointment.Status === 'Leave' ? 'purple' : appointment.Status === 'Guest Arrived' ? '#51CDA0' : appointment.Status === 'Service Done' ? '#4C9CA0' : appointment.Status === 'Invoice Created' ? '#AE7D99' : 'red')
          }

          let title = ''
          if (appointment.Customer && appointment.Customer.PhoneNo !== "") {
            title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.PhoneNo + ')'
          }
          else if (appointment.Customer && appointment.Customer.EmailID !== "") {
            title = (appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim() + ' (' + appointment.Customer.EmailID + ')'
          }

          let newEvent = {
            id: appointment.key,
            title: (appointment.Status === 'Leave' ? 'Blocked - ' + appointment.EmployeeName + ' on Leave' : title),
            start: appointment.SlotStart,
            end: appointment.SlotEnd,
            resourceId: appointment.EmployeeID,
            bgColor: statusColor,
            resizable: false,
            movable: false,
            startResizable: false,
            endResizable: false,
            creatable: false,
            selecttable: false,
          }
          schedulerData.addEvent(newEvent);
        }

        this.setState({ viewModel: schedulerData, events: schedulerData.events, appointments: appointmentList });

      })
    }
  }

  onAddAppointment() {
    let serviceError = ""
    let slotEndDisplayError = ""
    let slotStartDisplayError = ""
    let employeeError = ""

    this.setState({ adding: true })

    if (this.state.service === null) {
      serviceError = "Please select service"
    }

    if (this.state.employeeID === '')
      employeeError = "Please select employee"

    if (this.state.slotStartDisplay === '')
      slotStartDisplayError = "Please select start time"

    if (this.state.slotEndDisplay === '')
      slotEndDisplayError = "Please select end time"

    let startIndex = this.state.timeSlots.findIndex(f=> f.slot === this.state.slotStartDisplay);
    let endIndex = this.state.timeSlots.findIndex(f=> f.slot === this.state.slotEndDisplay);

    if (startIndex >= endIndex) {
      slotStartDisplayError = "Slot Start should be less than Slot End"
    }

    if (serviceError !== "" || employeeError !== "" || slotStartDisplayError !== "" || slotEndDisplayError !== "") {
      this.setState({
        serviceError: serviceError,
        employeeError: employeeError,
        slotEndDisplayError: slotEndDisplayError,
        slotStartDisplayError: slotStartDisplayError,
        adding: false
      })
      return
    }
    let schedulerData = this.state.viewModel;
    let service = null;
    let packageLineItemIndex=-1;
    if(this.state.service.hasOwnProperty("packageLineItemIndex")){
      //this is package
      service = this.state.service;
      service.value = service.serviceID;
      // this.props.config.Products.filter((f) => f.key === this.state.service.serviceID)[0];
      packageLineItemIndex = this.state.service.packageLineItemIndex;
    }
    else
    {
      service= this.state.service;
    }
    console.log('this.state.service', this.state.service)
    console.log('service',service)
    let appointments = this.state.newAppointments;
    let key = '';
    if (this.state.selectedIndex !== -1 && appointments.length > 0) {
      key = appointments[this.state.selectedIndex].key;
    }

    let appointment = {
      key: key,
      AppointmentDate: new Date(schedulerData.startDate),
      SlotStart: this.state.slotStart,
      SlotEnd: this.state.slotEnd,
      SlotStartDisplay: this.state.slotStartDisplay,
      SlotEndDisplay: this.state.slotEndDisplay,
      Customer: this.state.selectedCustomer,
      PackageID: this.state.packageID ? this.state.packageID : '',
      PackageLineItemIndex: packageLineItemIndex,
      Service: service,
      RetailPrice: service.price,
      Comments: this.state.comments,
      EmployeeID: this.state.employeeID,
      EmployeeName: this.state.employee,
      RegistrationID: this.props.user.user.RegistrationID,
      StoreID: this.props.user.store.key,
      StoreName: this.props.user.store.StoreName,
      //Duration: duration,
      Status: this.state.status
    }
    console.log('appointment',appointment)
    let recordIndex = this.state.selectedIndex !== -1 ? this.state.selectedIndex : -1;
    if (recordIndex >= 0)
      appointments.splice(recordIndex, 1);

    appointments.push(appointment);

    this.setState({
      newAppointments: appointments, adding: false, duration: '', service: null, resources: (this.state.tabSelected === 'Services' ? this.state.allResources : []), packageID: '',
      employeeID: '', employee: '', slotStartDisplay: this.state.slotEndDisplay, slotEndDisplay: '', selectedIndex: -1
    });
  };

  onRemoveAppointment(index) {
    let newAppointments = this.state.newAppointments
    newAppointments.splice(index, 1)
    this.setState({ newAppointments: newAppointments });
  };

  onModifyAppointment(appointment, index) {

    if (appointment.Status === 'Service Done' || appointment.Status === 'Cancelled') {
      this.setState({ showError: true, alertError: 'Modification not allowed.' });
      return;
    }

    if (appointment.hasOwnProperty('PackageID') && appointment.PackageID !== "") {
      this.setState({ tabSelected: 'Packages' })
    }
    else {
      this.setState({ tabSelected: 'Services' })
    }

    this.setState({
      employeeID: appointment.EmployeeID, employee: appointment.EmployeeName, status: appointment.Status,
      packageID: appointment.hasOwnProperty('PackageID') ? appointment.PackageID : '',
      slotEndDisplay: appointment.SlotEndDisplay, slotStartDisplay: appointment.SlotStartDisplay, service: appointment.Service
    });

    let schedulerData = this.state.viewModel;

    let slotStart = schedulerData.startDate + ' ' + appointment.SlotStartDisplay
    let slotEnd = schedulerData.startDate + ' ' + appointment.SlotEndDisplay

    let startDate = new Date(slotStart);
    let endDate = new Date(slotEnd);

    var res = Math.abs(startDate - endDate) / 1000;
    // get hours        
    var hours = Math.floor(res / 3600) % 24;
    // get minutes
    var minutes = Math.floor(res / 60) % 60;

    var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

    // console.log("index", index);

    this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration, selectedIndex: index });

  };

  onSlotStartDateChange(slotStartDisplay, service){
        let schedulerData = this.state.viewModel;
        let slotEndDisplay = this.state.slotEndDisplay;

        if (service && service.hasOwnProperty('duration') && Number(service.duration) > 0) {
          let slotStart = schedulerData.startDate + ' ' + slotStartDisplay
          let endDate = new Date(slotStart)
          endDate = endDate.setMinutes(endDate.getMinutes() + Number(service.duration));
          endDate = new Date(endDate);
          slotEndDisplay = (endDate.getHours() > 12) ? (endDate.getHours() + ":" + (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes())) : (endDate.getHours() + ":" + (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes()));
          this.setState({ slotEndDisplay: slotEndDisplay })
        }
        else {
          slotEndDisplay = slotStartDisplay;//evt.target.value;
          this.setState({ slotEndDisplay: slotEndDisplay});
        }

        if (slotStartDisplay !== "" && slotEndDisplay !== "") {
          let slotStart = schedulerData.startDate + ' ' + slotStartDisplay
          let slotEnd = schedulerData.startDate + ' ' + slotEndDisplay
          let startDate = new Date(slotStart);
          let endDate = new Date(slotEnd);

          var res = Math.abs(startDate - endDate) / 1000;
          // get hours
          var hours = Math.floor(res / 3600) % 24;
          // get minutes
          var minutes = Math.floor(res / 60) % 60;
          var duration = hours > 0 ? ((hours * 60) + minutes) : minutes
          // console.log('slotStart',slotStart)
          // console.log('slotEnd', slotEnd)
          this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration });
        }
        // console.log('slotStartDisplay', slotStartDisplay)
        // console.log('slotEndDisplay', slotEndDisplay)
        this.setState({
          slotStartDisplay: slotStartDisplay,
          slotEndDisplay: slotEndDisplay,
          slotStartDisplayError: ""
        })
  }

  onSlotEndTimeChange(slotEndDisplay){
    //let slotEndDisplay = evt.target.value;
    let slotStartDisplay = this.state.slotStartDisplay;
    let schedulerData = this.state.viewModel;
    // console.log('slotStartDisplay',slotStartDisplay)
    // console.log('slotEndDisplay',slotEndDisplay)

    if (slotStartDisplay !== "" && slotEndDisplay !== "") {

      let slotStart = schedulerData.startDate + ' ' + slotStartDisplay
      let slotEnd = schedulerData.startDate + ' ' + slotEndDisplay
      let startDate = new Date(slotStart);
      let endDate = new Date(slotEnd);

      var res = Math.abs(startDate - endDate) / 1000;
      // get hours        
      var hours = Math.floor(res / 3600) % 24;
      // get minutes
      var minutes = Math.floor(res / 60) % 60;
      var duration = hours > 0 ? ((hours * 60) + minutes) : minutes
      // console.log('slotEnd', slotEnd)
      // console.log('endDate', endDate)
      this.setState({ slotEnd: slotEnd, duration: duration});
    }
    this.setState({
      slotEndDisplay: slotEndDisplay,
      slotEndDisplayError: ""
    })
  }
  render() {
    let leftCustomHeader = (
      <div>
        
        <button type="submit" className="btn btn-flat btn-success" style={{ color: 'white' }}
          onClick={() => this.setState({
            mode: 'new',
            selectedCustomer: null, service: null, employeeID: '', employee: '',
            slotStartDisplay: '', slotEndDisplay: '', slotEndDisplayError: '', slotStartDisplayError: '', customerError: '', serviceError: '', employeeError: '',
            showEvent: true, rightHeight: 39, status: '', newAppointments: [], selectedIndex: -1, comments: '', tabSelected: 'Services', packages: [], resources: this.state.allResources,
          })} >Create Appointment</button>
        <button type="submit" className="btn btn-flat btn-default" style={{marginLeft: "5px"}} onClick={() => this.setState({ showAppointments: true })}
        ><i className="fa fa-search" aria-hidden="true"></i></button>
      </div>
    );

    return (
      <React.Fragment>
        {this.state.showAppointment &&
          <div className="content-wrapper" >
            <section className="content">
              <div className="row" >
                <div className="col-md-12 scroll2" style={{ height: this.state.rightHeight ? this.state.rightHeight + "vh" : "90vh", backgroundColor: "white", overflowY: "scroll", overflowX: "hidden", marginTop: "-14px" }}>
                  <Scheduler schedulerData={this.state.viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    eventItemClick={this.eventClick}
                    newEvent={this.newEvent}
                    moveEvent={this.moveEvent}
                    updateEventStart={this.updateEventStart}
                    updateEventEnd={this.updateEventEnd}
                    onViewChange={this.onViewChange}
                    leftCustomHeader={leftCustomHeader}
                    eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                  />
                <div className="col-md-12" style={{padding:"0px"}}>
                  <span className="label pull-right" style={{ marginRight: "0px", marginBottom: "0px", fontSize: "14px", backgroundColor: 'red' }}>Cancelled: {this.state.appointments && this.state.appointments.filter((f) => f.Status === 'Cancelled').length}</span>
                  <span className="label pull-right" style={{  marginRight: "10px", marginBottom: "0px", fontSize: "14px", backgroundColor: '#AE7D99' }}>Invoice Created: {this.state.appointments && this.state.appointments.filter((f) => f.Status === 'Invoice Created').length}</span>
                  <span className="label pull-right" style={{  marginRight: "10px", marginBottom: "0px", fontSize: "14px", backgroundColor: '#4C9CA0' }}>Service Done: {this.state.appointments && this.state.appointments.filter((f) => f.Status === 'Service Done').length}</span>
                  <span className="label pull-right" style={{  marginRight: "10px", marginBottom: "0px", fontSize: "14px", backgroundColor: '#51CDA0' }}>Guest Arrived: {this.state.appointments && this.state.appointments.filter((f) => f.Status === 'Guest Arrived').length}</span>
                  <span className="label pull-right" style={{  marginRight: "10px", marginBottom: "0px", fontSize: "14px", backgroundColor: '#99A1C6' }}>Booked: {this.state.appointments && this.state.appointments.filter((f) => f.Status === '').length}</span>
                  <span className="label pull-right" style={{  marginRight: "10px", marginBottom: "0px", fontSize: "14px", backgroundColor: '#00A65A' }}>Total Appointments: {this.state.appointments && this.state.appointments.length}</span>
                </div>
                </div>
              </div>
              {this.state.showEvent &&
                <React.Fragment >
                  <div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-md-4">
                        <div className="box box-body" style={{ height: '300px' }}>
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <div className={this.state.customerError ? "form-group has-error" : "form-group "}>
                              <CustomerSearch
                                {...this.props}
                                ref="refSearchCustomer"
                                showAddOption={true}
                                customer={this.state.selectedCustomer}
                                error={this.state.customerError}
                                onCustomerChange={
                                  this.onCustomerChange}
                                onAddCustomer={(newCustomer) => { this.setState({ showAddCustomer: true, showAppointment: false, newCustomer: newCustomer, customerError: '' }) }}
                                onCustomerEdit={() => { this.setState({ showAddCustomer: true, showAppointment: false }) }}
                                onShowCustomerDashBoard={() => {
                                  this.setState({ showCustomerDashBoard: true, showAppointment: false })
                                }}
                              ></CustomerSearch>
                              {this.state.customerError && <span className="help-block">{this.state.customerError}</span>}
                            </div>
                            <br/>
                          </div>
                          {(this.state.selectedCustomer && this.state.selectedCustomer.LastVisitedOn !== "") && 
                          <div className="col-md-12" style={{ padding: '0px',}}>
                            <div className="form-group" >
                              <div style={{ marginTop: "5px", borderRadius: '10px', border: '2px solid #73AD21', padding: '10px', width: '100%', height: 'auto', wordWrap: 'break-word', wordBreak: 'break-word', overflowY: 'auto'  }}>
                              {this.state.lastVisited && <b> {this.state.lastVisited} , Purchased: <i> {this.state.lastServices} </i>
                              <br />
                              <br /> </b> }
                                Frequent Purchases: <i>{this.state.customerProducts}</i>
                              </div>
                            </div>
                          </div>}
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <div className="form-group" >
                              <textarea className="form-control input-md" style={{ height: "60px" }}
                                onChange={(evt) => this.setState({ comments: evt.target.value })}
                                value={this.state.comments}></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4" style={{ paddingLeft: '5px' }}>
                        <div className="nav-tabs-custom" style={{ cursor: "pointer", height: '300px' }}>
                          <ul className="nav nav-tabs  ui-sortable-handle">
                            <li className={this.state.tabSelected === "Services" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "Services" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'Services', service: null, employeeID: '', employeeError: '', serviceError: '', packageID: '', employeeServices: [], resources: this.state.allResources })} data-toggle="tab">Services</a></li>
                            <li className={this.state.tabSelected === "Packages" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "Packages" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'Packages', service: null, employeeID: '', employeeError: '', serviceError: '', packageID: '', employeeServices: [], resources: [] })} data-toggle="tab">Packages</a></li>
                          </ul>
                          {this.state.tabSelected === "Services" &&
                            <div style={{ margin: '10px' }}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className={this.state.employeeError ? "form-group has-error" : "form-group "}  >
                                    {/* <label>Employee Name</label> */}
                                    <select className="form-control"
                                      value={this.state.employeeID}
                                      onChange={(evt)=>this.handleOnEmployeeChange(evt.target.value,
                                        evt.target.options[evt.target.selectedIndex].text)}>
                                      <option value="">Select Employee</option>
                                      {this.state.resources.filter((e) => e.status !== 'Leave').map(m => <option value={m.id}>{m.name}</option>)}
                                    </select>
                                    {this.state.employeeError && <span className="help-block">{this.state.employeeError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className={this.state.serviceError ? "form-group has-error" : "form-group "} >
                                    {/* <label>Service</label> */}
                                    <CreatableSelect
                                      isSearchable
                                      isMulti={false}
                                      placeholder="Select Service"
                                      value={this.state.service}
                                      options={this.state.employeeServices}
                                      onChange={this.handleOnServiceChange}
                                      style={{ padding: "10px 16px", height: "46px", fontSize: "18px" }}
                                    />
                                    {this.state.serviceError && <span className="help-block">{this.state.serviceError}</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className={this.state.slotStartDisplayError ? "form-group has-error" : "form-group "}>
                                    {/* <label>Start Time</label> */}
                                    <select className="form-control"
                                      value={this.state.slotStartDisplay}
                                      onChange={(evt) => {

                                        this.onSlotStartDateChange(evt.target.value, this.state.service)
                                      }}>
                                      <option value="">Select Start</option>
                                      {this.state.timeSlots.map(t=><option value={t.slot}>{t.slot}</option>)}
                                    </select>
                                    {this.state.slotStartDisplayError && <span className="help-block">{this.state.slotStartDisplayError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className={this.state.slotEndDisplayError ? "form-group has-error" : "form-group "} >
                                    {/* <label >End Time</label> */}
                                    <select className="form-control"
                                      value={this.state.slotEndDisplay}
                                      onChange={(evt) => {
                                        this.onSlotEndTimeChange(evt.target.value)
                                      }}>
                                      <option value="">Select End</option>
                                      {this.state.timeSlots.map(t=><option value={t.slot}>{t.slot}</option>)}
                                    </select>
                                    {this.state.slotEndDisplayError && <span className="help-block">{this.state.slotEndDisplayError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group" >
                                    {/* <label >Duration (mins)</label> */}
                                    <input type="text" className="form-control"
                                      autoFocus={true} disabled
                                      value={this.state.duration} />
                                  </div>
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "80px" }}>
                                <div className="col-md-12">
                                  <button type="submit" className="btn btn-flat btn-primary pull-right"
                                    onClick={this.onAddAppointment} style={{ marginTop: "-25px", marginLeft: "10px" }} disabled={this.state.adding}>Add</button>
                                  <button type="cancel" className="btn btn-flat btn-default pull-right"
                                    onClick={() => this.setState({ service: null, employee: '', employeeID: '', rightHeight: 39, serviceError: '', slotEndDisplay: '', slotStartDisplay: '', duration: '', employeeError: '', slotStartDisplayError: '', slotEndDisplayError: '', packageID: '', resources: this.state.allResources, employeeServices: [], tabSelected: 'Services' })} style={{ marginTop: "-25px", marginLeft: "10px" }} >Cancel</button>
                                  <br />
                                </div>
                              </div>
                            </div>
                          }
                          {this.state.tabSelected === "Packages" &&
                            <div style={{ margin: '10px' }}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className={this.state.packageError ? "form-group has-error" : "form-group "}  >
                                    <select className="form-control"
                                      value={this.state.packageID}
                                      onChange={this.handleOnPackageChange}>
                                      <option value="">Select Package</option>
                                      {this.state.packages.map(m => <option value={m.key}>{m.PackageName}</option>)}
                                    </select>
                                    {this.state.packageError && <span className="help-block">{this.state.packageError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className={this.state.serviceError ? "form-group has-error" : "form-group "} >
                                    <CreatableSelect
                                      isSearchable
                                      isMulti={false}
                                      placeholder="Select Service"
                                      value={this.state.service}
                                      options={this.state.packageServices}
                                      onChange={this.handleOnServiceChange}
                                      style={{ padding: "10px 16px", height: "46px", fontSize: "18px" }}
                                    />
                                    {this.state.serviceError && <span className="help-block">{this.state.serviceError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className={this.state.employeeError ? "form-group has-error" : "form-group "}  >
                                    <select className="form-control"
                                      value={this.state.employeeID}
                                      onChange={(evt)=>this.handleOnEmployeeChange(evt.target.value,
                                        evt.target.options[evt.target.selectedIndex].text)}
                                      >
                                      <option value="">Select Employee</option>
                                      {this.state.selectedPackageServiceEmployees.map(m => <option value={m.id}>{m.name}</option>)}
                                    </select>
                                    {this.state.employeeError && <span className="help-block">{this.state.employeeError}</span>}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className={this.state.slotStartDisplayError ? "form-group has-error" : "form-group "}>
                                    {/* <label>Start Time</label> */}
                                    <select className="form-control"
                                      value={this.state.slotStartDisplay}
                                      onChange={(evt) => {
                                        this.onSlotStartDateChange(evt.target.value,this.state.service)
                                      }}>
                                      <option value="">Select Start</option>
                                      {this.state.timeSlots.map(t=><option value={t.slot}>{t.slot}</option>)}
                                    </select>
                                    {this.state.slotStartDisplayError && <span className="help-block">{this.state.slotStartDisplayError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className={this.state.slotEndDisplayError ? "form-group has-error" : "form-group "} >
                                    {/* <label >End Time</label> */}
                                    <select className="form-control"
                                      value={this.state.slotEndDisplay}
                                      onChange={(evt) => {
                                        this.onSlotEndTimeChange(evt.target.value)
                                      }}>
                                      <option value="">Select End</option>
                                      {this.state.timeSlots.map(t=><option value={t.slot}>{t.slot}</option>)}
                                    </select>
                                    {this.state.slotEndDisplayError && <span className="help-block">{this.state.slotEndDisplayError}</span>}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group" >
                                    {/* <label >Duration (mins)</label> */}
                                    <input type="text" className="form-control"
                                      autoFocus={true} disabled
                                      value={this.state.duration} />
                                  </div>
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "30px" }}>
                                <div className="col-md-12">
                                  <button type="submit" className="btn btn-flat btn-primary pull-right"
                                    onClick={this.onAddAppointment} style={{ marginTop: "-25px", marginLeft: "10px" }} disabled={this.state.adding}>Add</button>
                                  <button type="cancel" className="btn btn-flat btn-default pull-right"
                                    onClick={() => this.setState({ service: null, employee: '', employeeID: '', rightHeight: 39, serviceError: '', slotEndDisplay: '', slotStartDisplay: '', duration: '', employeeError: '', slotStartDisplayError: '', slotEndDisplayError: '', packageID: '', employeeServices: [], resources: [], tabSelected: 'Packages' })} style={{ marginTop: "-25px", marginLeft: "10px" }} >Cancel</button>
                                  <br />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4" style={{ paddingLeft: '5px' }}>
                        <div className="box box-body" style={{ height: '300px' }}>
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <div className="table-responsive scroll2" style={{ height: "247px", overflowY: "scroll", overflowX: "hidden" }}>
                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr >
                                    <th>Employee
                                  </th>
                                    <th>Service</th>
                                    <th>Slot</th>
                                    <th style={{ width: "50px" }}></th>
                                  </tr>
                                </thead>
                                <tbody >
                                  {this.state.newAppointments && this.state.newAppointments.map((appointment, index) =>
                                    <tr onClick={() => this.onModifyAppointment(appointment, index)} style={{ cursor: 'pointer' }}>
                                      <td>
                                        {appointment.EmployeeName}
                                        <br />
                                        <span className="label pull-right" style={{ marginTop: "5px", marginBottom: "0px", fontSize: "10px", backgroundColor: (appointment.Status === 'Leave' ? 'purple' : appointment.Status === 'Guest Arrived' ? '#51CDA0' : appointment.Status === 'Service Done' ? '#4C9CA0' : appointment.Status === 'Invoice Created' ? '#AE7D99' : 'red') }}>{appointment.Status}</span>
                                      </td>
                                      <td>
                                        {appointment.Service.label}
                                      </td>
                                      <td>{appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</td>
                                      <td><div className="input-group-btn">
                                        {(appointment.key === '') && <button type="button" className="btn btn-default btn-md"
                                          onClick={() => this.onRemoveAppointment(index)}>
                                          <i className="fa fa-trash-o"></i>
                                        </button>}
                                      </div>
                                      </td>
                                    </tr>)}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <button type="submit" className="btn btn-flat btn-success pull-right"
                              onClick={this.onSaveAppointment} style={{ marginLeft: "10px" }} disabled={this.state.saving}>Save</button>
                            <button type="cancel" className="btn btn-flat btn-default pull-right"
                              onClick={() => this.setState({ showEvent: false, service: null, employee: '', employeeID: '', selectedCustomer: null, comments: '', rightHeight: 90, serviceError: '', customerError: '', packageID: '', tabSelected: 'Services' })}
                              style={{ marginLeft: "10px" }} >Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              <Modal open={this.state.showCancelModal} onClose={() => this.setState({ showCancelModal: false })} center>
                <div style={{ width: "300px" }}>
                  <h3 style={{ marginTop: "0px" }}>Cancel Appointment</h3>
                  <div className="box-body">
                    <textarea className="textarea" style={{ width: "100%", height: "200px" }}
                      onChange={(evt) => this.setState({ cancellationReason: evt.target.value })}
                      value={this.state.cancellationReason}
                    ></textarea>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-md btn-flat btn-default" onClick={() => this.setState({ showCancelModal: false })} >Cancel</button>
                    <button type="submit"
                      className="btn btn-flat btn-md btn-flat btn-success pull-right"
                      disabled={this.state.saving}
                      onClick={this.onCancelAppointment}>Save</button>
                  </div>
                </div>
              </Modal>
              <Modal open={this.state.showAppointments} onClose={() => this.setState({ showAppointments: false })} center>
                <div style={{ width: "750px" }}>
                    <Appointments  {...this.props}
                    />
                </div>
            </Modal>
              <Modal open={this.state.showCreateInvoice} onClose={() => this.setState({ showCreateInvoice: false })} center>
                <div style={{ width: "400px" }}>
                  {/* <h3 style={{ marginTop: "0px" }}>Create Invoice</h3> */}
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-12" style={{ padding: "0" }}>
                        {this.state.appointmentCustomer &&
                          <React.Fragment>
                            <div className="row" style={{ textAlign: "left", marginLeft: "12px" }}>
                              <font style={{ fontSize: "30px", marginBottom: "4px" }}>{this.state.appointmentCustomer.FirstName.trim() + ' ' + this.state.appointmentCustomer.LastName.trim()}</font>
                            </div>
                            {this.state.appointmentCustomer.EmailID !== "" &&
                              <div className="col-md-12">
                                <i className="fa fa-envelope" ></i><text style={{ marginLeft: "15px" }}></text>{this.state.appointmentCustomer.EmailID}<text></text>
                              </div>
                            }
                            {this.state.appointmentCustomer.PhoneNo !== "" &&
                              <div className="col-md-12">
                                <i className="fa fa-phone-square" ></i><text style={{ marginLeft: "15px" }}></text>{this.state.appointmentCustomer.PhoneNo}<text></text>
                              </div>
                            }
                          </React.Fragment>}
                      </div>
                    </div>
                    <div className="scroll2" style={{ marginTop: "10px", height: "50vh", paddingBottom: "0px", overflowY: "scroll" }}>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th style={{ width: "55%" }}>Appointment</th>
                              <th>Amount</th>
                              <th style={{ width: "50px" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.customerAppointments && this.state.customerAppointments.map((appointment, index) =>
                              <tr>
                                <td>
                                  <div>{appointment.SlotStartDisplay + '-' + appointment.SlotEndDisplay}</div>
                                  <div>{appointment.Service.label}</div>
                                  <div>{appointment.EmployeeName}</div>
                                </td>
                                <td>
                                  {appointment.RetailPrice}
                                </td>
                                <td><div className="input-group-btn">
                                  <button type="button" className="btn btn-default btn-md"
                                    onClick={() => this.onRemoveService(index)}>
                                    <i className="fa fa-trash-o"></i>
                                  </button>
                                </div>
                                </td>
                              </tr>)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-md btn-flat btn-default" onClick={() => this.setState({ showCreateInvoice: false })} >Cancel</button>
                    <button type="submit"
                      className="btn btn-flat btn-md btn-flat btn-success pull-right"
                      disabled={this.state.saving}
                      onClick={() => this.onCreateInvoice()}>Create Invoice</button>
                  </div>
                </div>
              </Modal>
              <Modal open={this.state.showError} onClose={() => this.setState({ showError: false })} center>
                <div style={{ width: "300px" }}>
                  <h3 style={{ marginTop: "0px" }}>Error</h3>
                  <div className="box-body">
                    <ul>
                      {this.state.alertError !== "" && <li>{this.state.alertError}
                      </li>}
                    </ul>
                  </div>
                  <div className="box-footer">
                    <button type="submit" className="btn btn-flat btn-md btn-danger pull-right"
                      onClick={() => this.setState({ showError: false })}>OK</button>
                  </div>
                </div>
              </Modal>
            </section>
          </div>
        }
        <div>
          {this.state.showAddCustomer && <Customer {...this.props}
            calledFromInvoice={true}
            selectedCustomer={this.state.selectedCustomer}
            newCustomer={this.state.newCustomer}
            onSave={(customer) => {
              this.onCustomerSelected(customer)
              this.setState({
                showAddCustomer: false,
                showAppointment: true
              })
            }}
            onClose={() => this.setState({ showAddCustomer: false, showAppointment: true })} />}
          {this.state.showCustomerDashBoard && <CustomerDashBoard {...this.props}
            calledFromInvoice={true}
            cutomerID={this.state.selectedCustomer.key}
            onClose={() => this.setState({ showCustomerDashBoard: false, showAppointment: true })} />}
        </div>
      </React.Fragment>
    )
  }
}

export default withDnDContext(AppointmentView)


// let slotEndDisplay = evt.target.value;
// let slotStartDisplay = this.state.slotStartDisplay;
// let schedulerData = this.state.viewModel;

// if (slotStartDisplay !== "" && slotEndDisplay !== "") {

//   let slotStart = schedulerData.startDate + ' ' + slotStartDisplay
//   let slotEnd = schedulerData.startDate + ' ' + slotEndDisplay

//   let startDate = new Date(slotStart);
//   let endDate = new Date(slotEnd);

//   var res = Math.abs(startDate - endDate) / 1000;
//   // get hours        
//   var hours = Math.floor(res / 3600) % 24;
//   // get minutes
//   var minutes = Math.floor(res / 60) % 60;

//   var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

//   this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration });
// }
// this.setState({
//   slotEndDisplay: evt.target.value,
//   slotEndDisplayError: ""
// })