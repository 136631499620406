import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";
import axios from "axios";

export function generateEInvoice(sale) {
  return (dispatch, getState) => {
    console.log("sale", JSON.stringify(sale));
    return axios
      .post(
        "https://us-central1-posible-app-prod.cloudfunctions.net/generateEInvoice",
        { sale: sale }
      )
      .then((res) => {
        console.log("generateEInvoice", JSON.stringify(res.data));
        if (res.data.success) {
          return {
            success: true,
            message: "",
            data: res.data.data,
          };
        } else {
          return {
            success: false,
            message: res.data.message,
            data: null,
          };
        }
      })
      .catch((err) => {
        console.log("generateEInvoice", JSON.stringify(err));
        return {
          success: false,
          message: "Error while processing data",
          data: null,
        };
      });
  };
}

// import db from './IndexedDBAction'

// export function addSales(sale){
//     return (dispatch, getState) =>{
//         sale.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
//         sale.ActionBy = getState().user.user.Name
//         sale.ActionByUID = getState().user.user.UID
//         sale.ActionByEmailID = getState().user.user.EmailID
//         sale.Action = constants.AUDIT_TRAIL_NEW
//         // console.log('sale',sale)
//         return database.collection("Sales").add({
//           ...sale
//         })
//         .then(function(docRef) {
//             return docRef.id
//         })
//         .catch(function(error) {
//             console.error("Error saving sales : ", error);
//             return ""
//         })
//     }
// }

export function addSales(sale, cashRegisterID, transactionTypeID) {
  return async (dispatch, getState) => {
    // const transaction = firebase.firestore.batch();
    const transaction = database.batch();

    try {
      let runningNo = await database
        .collection("TransactionRunningNo")
        .doc(cashRegisterID + transactionTypeID)
        .get()
        .then((doc) => {
          let runningNo = 1;
          if (doc.exists) {
            runningNo = Number(doc.data().RunningNo) + 1;
          }
          console.log(runningNo);
          return runningNo;
        });
      console.log("runningNo", runningNo);

      sale.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
      sale.ActionBy = getState().user.user.Name;
      sale.ActionByUID = getState().user.user.UID;
      sale.ActionByEmailID = getState().user.user.EmailID;
      sale.Action = constants.AUDIT_TRAIL_NEW;
      sale.TransactionNo = sale.TransactionNo + runningNo;
      console.log("sale", sale);
      const salesCollectionRef = database.collection("Sales");
      const newSalesDocRef = salesCollectionRef.doc(); // Auto-generate a new document ID
      transaction.set(newSalesDocRef, sale);

      const transactionRunningNosRef = database
        .collection("TransactionRunningNo")
        .doc(cashRegisterID + transactionTypeID);
      transaction.set(transactionRunningNosRef, {
        CashRegisterID: cashRegisterID,
        TransactionTypeID: transactionTypeID,
        RunningNo: runningNo,
      });

      // Commit the transaction
      await transaction.commit();
      console.log("newSalesDocRef", newSalesDocRef);
      return newSalesDocRef.id;
    } catch (error) {
      console.error("Transaction failed:", error);
      return "";
    }
  };
}

export function saveKOT(KOT) {
  return (dispatch, getState) => {
    KOT.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    KOT.ActionBy = getState().user.user.Name;
    KOT.ActionByUID = getState().user.user.UID;
    KOT.ActionByEmailID = getState().user.user.EmailID;
    KOT.Action = constants.AUDIT_TRAIL_NEW;
    console.log("KOT", KOT);
    return database
      .collection("KOTs")
      .add({
        ...KOT,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error saving KOT : ", error);
        return "";
      });
  };
}

export function updateSalesStatus(key, status) {
  return (dispatch, getState) => {
    return database
      .collection("Sales")
      .doc(key)
      .update({
        TransactionStatus: status,
        BackEndUpdate: true,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,
        Action: status,
      });
  };
}

export function updateSales(sale, key, action) {
  return (dispatch, getState) => {
    sale.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    sale.ActionBy = getState().user.user.Name;
    sale.ActionByUID = getState().user.user.UID;
    sale.ActionByEmailID = getState().user.user.EmailID;
    sale.Action = constants.AUDIT_TRAIL_EDIT;
    sale.BackEndUpdate = false;
    console.log("updateSales", sale);
    console.log("key", sale.key);
    return database
      .collection("Sales")
      .doc(key)
      .update({
        ...sale,
      });
  };
}

export function cancelSales(
  cancellationReason,
  key,
  ONDCCancellationReasonCode = "",
  sale = null
) {
  return async (dispatch, getState) => {
    if (
      getState().registration.registration.PincodeIntegration &&
      sale &&
      sale.hasOwnProperty("ExternalTransactionNo") &&
      sale.ExternalTransactionNo !== ""
    ) {
      sale.BackEndUpdate = true;
      sale.Action = "Cancelled";
      sale.ActionBy = getState().user.user.Name;
      sale.ActionByUID = getState().user.user.UID;
      sale.ActionByEmailID = getState().user.user.EmailID;
      sale.TransactionStatus = "Cancelled";
      sale.DeliveryStatus = "Cancelled";
      sale.ONDCCancellationReasonCode = ONDCCancellationReasonCode;
      sale.CancellationReason =
        cancellationReason !== ""
          ? cancellationReason
          : ONDCCancellationReasonCode;
      // console.log("sale", sale);
      let response = await axios
        .post(
          "https://backend-o3dyulj4pa-el.a.run.app/v1/pincode/orderstate/update",
          { ...sale }
        )
        .then((res) => {
          // console.log("cancelSales", res.data);
          if (res.data.code === "0") {
            return true;
          } else {
            return false;
          }
          // return res.data;
        })
        .catch((err) => {
          // console.log("updateONDCDeliveryStatus error", err);
          return false;
        });
      return response;
    } else {
      let actionOn = firebase.firestore.FieldValue.serverTimestamp();
      let actionBy = getState().user.user.Name;
      let actionByUID = getState().user.user.UID;
      let actionByEmailID = getState().user.user.EmailID;
      let action = "CANCEL";
      await database
        .collection("Sales")
        .doc(key)
        .update({
          TransactionStatus: "Cancelled",
          DeliveryStatus: "Cancelled",
          CancellationReason: cancellationReason,
          ActionOn: actionOn,
          ActionBy: actionBy,
          ActionByUID: actionByUID,
          ActionByEmailID: actionByEmailID,
          Action: action,
          BackEndUpdate: false,
        });
      return true;
    }
  };
}

export function getTransactionRunningNo(cashRegisterID, transactionTypeID) {
  return (dispatch) => {
    return database
      .collection("TransactionRunningNo")
      .doc(cashRegisterID + transactionTypeID)
      .get()
      .then((doc) => {
        let runningNo = 1;
        if (doc.exists) {
          runningNo = Number(doc.data().RunningNo) + 1;
        }
        console.log(runningNo);
        return runningNo;
      });
  };
}

export function updateTransactionRunningNo(
  cashRegisterID,
  transactionTypeID,
  runningNo
) {
  // return dispatch => {
  //     return database.collection("TransactionRunningNo").doc(cashRegisterID + transactionTypeID).set({
  //         CashRegisterID: cashRegisterID, TransactionTypeID: transactionTypeID, RunningNo: runningNo
  //     }).then(() => {
  //         return runningNo
  //     })
  // }
  return (dispatch) => {
    database
      .collection("TransactionRunningNo")
      .doc(cashRegisterID + transactionTypeID)
      .set({
        CashRegisterID: cashRegisterID,
        TransactionTypeID: transactionTypeID,
        RunningNo: runningNo,
      });
    // return runningNo;
    //console.log("updateTransactionRunningNo", runningNo)
  };
}

export function getKOTRunningNo(cashRegisterID) {
  return (dispatch) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    let key = cashRegisterID + dd + mm + yyyy;
    return database
      .collection("KOTRunningNo")
      .doc(key)
      .get()
      .then((doc) => {
        let runningNo = 1;
        if (doc.exists) {
          runningNo = Number(doc.data().RunningNo) + 1;
        }
        return database
          .collection("KOTRunningNo")
          .doc(key)
          .set({
            CashRegisterID: cashRegisterID,
            RunningNo: runningNo,
          })
          .then(() => {
            return runningNo;
          });
      });
  };
}

export function getSaleDetails(key) {
  return (dispatch) => {
    return database
      .collection("Sales")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
          //dispatch({type: actionTypes.PARK_SALE,draftSales})
        } else {
          console.log("No such sale record!");
        }
      });
  };
}

export function apiGetSaleDetails(transactionID) {
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getSaleDetails");
    return addMessage({ TransactionID: transactionID }).then(function(data) {
      console.log("", data.data);
      let sale = data.data;
      return sale;
    });
  };
}

export function initializseSales(registrationID) {
  return (dispatch) => {
    return database
      .collection("Sales")
      .where("RegistrationID", "==", registrationID)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        const sales = [];
        querySnapshot.forEach((doc) => {
          sales.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        console.log("initializseSales", sales);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function parkSales(draftSales) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PARK_SALE, draftSales });
  };
}

export function generatePowerUserOTP(registrationID, storeID, userID) {
  return (dispatch) => {
    // return true;
    let otp = {
      RegistrationID: registrationID,
      StoreID: storeID,
      UserID: userID,
    };
    let addMessage = firebase.functions().httpsCallable("generatePowerUserOTP");
    return addMessage({ text: otp }).then(function(result) {
      if (result.data.text) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function validatePowerUserOTP(registrationID, storeID, userID, OTP) {
  return (dispatch) => {
    // return false;
    let searchParameters = {
      RegistrationID: registrationID,
      StoreID: storeID,
      UserID: userID,
      OTP: OTP,
    };
    // console.log('validatePowerUserOTP', searchParameters)
    let addMessage = firebase.functions().httpsCallable("validatePowerUserOTP");
    return addMessage({ text: searchParameters }).then(function(result) {
      // console.log('result',result)
      if (result.data.text) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function generateOTP(
  registrationID,
  storeID,
  cashRegisterID,
  customerID,
  moduleID = ""
) {
  return (dispatch) => {
    let otp = {
      RegistrationID: registrationID,
      StoreID: storeID,
      ModuleID: moduleID,
      CashRegisterID: cashRegisterID,
      CustomerID: customerID,
    };
    let addMessage = firebase.functions().httpsCallable("generateOTP");
    return addMessage({ text: otp }).then(function(result) {
      if (result.data.text) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function validateOTP(
  registrationID,
  storeID,
  cashRegisterID,
  customerID,
  OTP
) {
  return (dispatch) => {
    let searchParameters = {
      RegistrationID: registrationID,
      StoreID: storeID,
      CashRegisterID: cashRegisterID,
      CustomerID: customerID,
      OTP: OTP,
    };
    // console.log(searchParameters)
    let addMessage = firebase.functions().httpsCallable("validateOTP");
    return addMessage({ text: searchParameters }).then(function(result) {
      // console.log('result',result)
      if (result.data) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function getCashRegisterOpeningDetails(cashRegisterID) {
  return (dispatch) => {
    return database
      .collection("CashRegisterClosing")
      .where("CashRegisterID", "==", cashRegisterID)
      .where("Status", "==", "Open")
      .get()
      .then((querySnapshot) => {
        let obj;
        querySnapshot.forEach((doc) => {
          obj = { key: doc.id, ...doc.data() };
        });
        // console.log('obj',obj)
        return obj;
      })
      .catch((error) => {
        return null;
        console.log(error);
      });
  };
}

export function getCashRegisterClosingDetails(key) {
  return (dispatch) => {
    return database
      .collection("CashRegisterClosing")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such Cash Register Closing record!");
        }
      });
  };
}

export function openCashRegister(cashRegisterClosing) {
  return (dispatch, getState) => {
    cashRegisterClosing.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    cashRegisterClosing.ActionBy = getState().user.user.Name;
    cashRegisterClosing.ActionByUID = getState().user.user.UID;
    cashRegisterClosing.ActionByEmailID = getState().user.user.EmailID;
    cashRegisterClosing.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("CashRegisterClosing")
      .add({
        ...cashRegisterClosing,
      })
      .then(function(docRef) {
        return { key: docRef.id, ...cashRegisterClosing };
      })
      .catch(function(error) {
        return null;
        console.error("Error saving sales : ", error);
      });
  };
}

export function closeCashRegister(key, cashRegisterClosing) {
  return (dispatch, getState) => {
    cashRegisterClosing.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    cashRegisterClosing.ActionBy = getState().user.user.Name;
    cashRegisterClosing.ActionByUID = getState().user.user.UID;
    cashRegisterClosing.ActionByEmailID = getState().user.user.EmailID;
    cashRegisterClosing.Action = constants.AUDIT_TRAIL_EDIT;
    return database
      .collection("CashRegisterClosing")
      .doc(key)
      .update({
        ...cashRegisterClosing,
      });
  };
}

export function deleteCashRegisterOpening(key, cashRegisterClosing) {
  return (dispatch, getState) => {
    console.log("deleteCashRegister.cashRegisterClosing", cashRegisterClosing);
    cashRegisterClosing.CashRegisterID =
      cashRegisterClosing.CashRegisterID + "_delete";
    cashRegisterClosing.StoreID = cashRegisterClosing.StoreID + "_delete";
    cashRegisterClosing.RegistrationID =
      cashRegisterClosing.RegistrationID + "_delete";
    return database
      .collection("CashRegisterClosing")
      .doc(key)
      .update({
        ...cashRegisterClosing,
      });
  };
}

export function checkIfWalletDebitTransactionExists(
  storeID,
  transactionNo,
  debitAmount
) {
  return (dispatch, getState) => {
    return database
      .collection("WalletLedgers")
      .where("StoreID", "==", storeID)
      .where("TransactionNo", "==", transactionNo)
      .where("DebitAmount", "==", debitAmount)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let exists = false;
        querySnapshot.forEach((doc) => {
          exists = true;
        });
        return exists;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };
}

export function getPaymenDetailstForCashRegisterClosing(
  registrationID,
  cashRegisterID,
  openingTime
) {
  return (dispatch, getState) => {
    return database
      .collection("Sales")
      .where("RegistrationID", "==", registrationID)
      .where("CashRegister.key", "==", cashRegisterID)
      .where("TransactionDate", "<=", new Date())
      .where("TransactionDate", ">=", openingTime)
      .get()
      .then((querySnapshot) => {
        let paymentModeAmount = [];
        let balanceAmount = 0;
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          console.log(doc.data());
          if (!doc.data().hasOwnProperty("CancellationReason")) {
            balanceAmount =
              Number(balanceAmount) + Number(doc.data().BalanceAmount);
            doc.data().Payments.map((payment) => {
              let index = -1;
              let amount = 0;
              if (payment.PaymentMode.toLowerCase() === "cash") {
                amount =
                  Number(payment.Amount) - Number(doc.data().BillChangeDue);
              } else {
                amount = Number(payment.Amount);
              }
              for (var i = 0; i < paymentModeAmount.length; i++) {
                if (paymentModeAmount[i].PaymentMode === payment.PaymentMode) {
                  index = i;
                }
              }
              if (index === -1) {
                paymentModeAmount.push({
                  PaymentMode: payment.PaymentMode,
                  Amount: amount,
                });
              } else {
                paymentModeAmount[index].Amount =
                  Number(paymentModeAmount[index].Amount) + amount;
              }
            });
          }
        });
        // console.log('paymentModeAmount',paymentModeAmount)
        paymentModeAmount.push({
          PaymentMode: "Balance",
          Amount: balanceAmount,
        });

        return paymentModeAmount;
      })
      .catch((error) => {
        console.log("Error getting invoice list: ", error);
      });
  };
}

export function getCashRegisterClosingList(storeID, cashRegisterID, status) {
  return (dispatch) => {
    let cashRegisterClosingRef = database.collection("CashRegisterClosing");
    let query = cashRegisterClosingRef
      .where("StoreID", "==", storeID)
      .where("Status", "==", status);
    if (cashRegisterID.trim() !== "") {
      query = query.where("CashRegisterID", "==", cashRegisterID);
    }
    return query
      .get()
      .then((querySnapshot) => {
        let list = [];
        querySnapshot.forEach((doc) => {
          list.push({ key: doc.id, ...doc.data() });
        });
        // console.log('')
        return list;
      })
      .catch((error) => {
        return null;
        console.log(error);
      });
  };
}

export function getCustomerOutstanding(customerID) {
  return (dispatch, getState) => {
    let searchParameters = {
      _source: [
        "ChildTransactionNo",
        "IsConvert",
        "ReceivedPaymentTransactions",
        "ReceiveAmount",
        "BalanceAmount",
        "TransactionNo",
        "TransactionType",
        "TransactionDate",
        "BillAmount",
      ],
      from: 0,
      size: 10000,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                "Customer.key.keyword": customerID,
              },
            },
            {
              term: {
                "TransactionType.SalesImpactChecked": true,
              },
            },
            // {
            //     "range": {
            //         "BalanceAmount": {
            //             "gt": 0,
            //             "lt": 0
            //         }
            //     }
            // },
            {
              match: {
                "Store.key": getState().user.store.key,
              },
            },
          ],
          must_not: [
            {
              exists: {
                field: "CancellationReason",
              },
            },
            {
              match: { BalanceAmount: 0 },
            },
          ],
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };
    console.log(
      "getCustomerOutstanding - Query",
      JSON.stringify(searchParameters)
    );
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const sales = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        console.log("getCustomerOutstanding data._source", data._source);
        // if(data._source.hasOwnProperty('ChildTransactionNo') && data._source.ChildTransactionNo.trim()!=="")
        if (
          data._source.hasOwnProperty("ChildTransactionNo") &&
          data._source.ChildTransactionNo.trim() !== "" &&
          data._source.hasOwnProperty("IsConvert") &&
          data._source.IsConvert
        ) {
          console.log("index", index);
        } else {
          let receivedPayment = 0;
          // data._source.hasOwnProperty('ReceivedPaymentAmount') ? Number(data._source.ReceivedPaymentAmount) : 0
          if (data._source.hasOwnProperty("ReceivedPaymentTransactions")) {
            // let receivedPayment = 0
            for (
              let i = 0;
              i < data._source.ReceivedPaymentTransactions.length;
              i++
            ) {
              let r = data._source.ReceivedPaymentTransactions[i];
              if (r.hasOwnProperty("IsConvert") && r.IsConvert) {
                //ignore
              } else {
                receivedPayment = receivedPayment + Number(r.ReceiveAmount);
              }
            }
            // if(receivedPayment> 0)
            // {
            //     data._source.BillPaidAmount = data._source.BillPaidAmount + receivedPayment
            //     data._source.BalanceAmount = data._source.BalanceAmount - receivedPayment
            // }
          }
          let outStandingAmount =
            Number(data._source.BalanceAmount) - Number(receivedPayment);
          if (outStandingAmount !== 0) {
            sales.push({
              TransactionKey: data._id,
              TransactionNo: data._source.TransactionNo,
              TransactionType: data._source.TransactionType.TransactionType,
              TransactionDate: data._source.TransactionDate,
              TransactionAmount: Number(data._source.BillAmount),
              OutStandingAmount: outStandingAmount,
              ReceiveAmount: 0,
              Selected: false,
            });
          }
        }
      });
      return sales;
    });
  };
}

export function getOutstandingRRN(customerID) {
  return (dispatch) => {
    // console.log('customerID',customerID)

    return database
      .collection("RRN")
      .where("CustomerID", "==", customerID)
      .where("IsDeleted", "==", false)
      .get()
      .then((querySnapshot) => {
        let outstandingRRNs = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().BalanceAmount > 0) {
            outstandingRRNs.push({ key: doc.id, ...doc.data() });
          }
        });
        // console.log('outstandingRRNs',outstandingRRNs)
        return outstandingRRNs;
      })
      .catch((error) => {
        return null;
        // console.log(error)
      });
  };
}

export function updateDeliveryDetails(deliveryDetails, key) {
  return (dispatch, getState) => {
    return database
      .collection("Sales")
      .doc(key)
      .update({ ...deliveryDetails, BackEndUpdate: false });
  };
}

export function updateONDCDeliveryStatus(
  sale,
  status,
  merchantInvoiceNo = "",
  merchantInvoiceAmount = 0
) {
  return async (dispatch, getState) => {
    let transactionStatus = "";
    let deliveryStatus = status;
    if (status === "Packed") {
      transactionStatus = "In-progress";
    }
    if (status === "Out-for-delivery") {
      transactionStatus = "In-progress";
    }
    if (status === "Order-delivered") {
      transactionStatus = "Completed";
    }
    if (status === "Accepted") {
      transactionStatus = "Accepted";
      deliveryStatus = sale.DeliveryStatus;
    }
    if (status === "Order-picked-up") {
      transactionStatus = "Completed";
    }
    let updateData = {
      DeliveryStatus: deliveryStatus,
      TransactionStatus: transactionStatus,
      BackEndUpdate: true,
      Action: status,
      ActionBy: getState().user.user.Name,
      ActionByUID: getState().user.user.UID,
      ActionByEmailID: getState().user.user.EmailID,
      ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (status === "Packed" && merchantInvoiceNo.trim() !== "") {
      updateData.TransactionNo = merchantInvoiceNo.trim();
      updateData.PincodeMerchantInvoiceAmount = merchantInvoiceAmount;
      sale.TransactionNo = merchantInvoiceNo.trim();
      sale.PincodeMerchantInvoiceAmount = merchantInvoiceAmount;
    }

    if (sale.hasOwnProperty("DeliveryPartnerOrderID")) {
      updateData.DeliveryPartnerOrderID = sale.DeliveryPartnerOrderID;
    }
    if (sale.hasOwnProperty("DeliveryPartnerOrderAmount")) {
      updateData.DeliveryPartnerOrderAmount = sale.DeliveryPartnerOrderAmount;
    }
    if (status === "Out-for-delivery") {
      if (sale.hasOwnProperty("DeliveryPersonKey")) {
        updateData.DeliveryPersonKey = sale.DeliveryPersonKey;
      }
      if (sale.hasOwnProperty("DeliveryPersonName")) {
        updateData.DeliveryPersonName = sale.DeliveryPersonName;
      }
      if (sale.hasOwnProperty("DeliveryPersonMobileNo")) {
        updateData.DeliveryPersonMobileNo = sale.DeliveryPersonMobileNo;
      }
      updateData.DeliveryOutForDeliveryActionOn = firebase.firestore.FieldValue.serverTimestamp();
    }
    // if (
    //   getState().registration.registration.hasOwnProperty("ONDCTest") &&
    //   getState().registration.registration.ONDCTest
    // ) {
    if (transactionStatus !== "") {
      sale.TransactionStatus = transactionStatus;
    }
    sale.BackEndUpdate = true;
    sale.Action = status;
    sale.ActionBy = getState().user.user.Name;
    sale.ActionByUID = getState().user.user.UID;
    sale.ActionByEmailID = getState().user.user.EmailID;
    sale.DeliveryStatus = deliveryStatus;
    // console.log("sale", sale);
    let response = await axios
      .post(
        "https://backend-o3dyulj4pa-el.a.run.app/v1/pincode/orderstate/update",
        { ...sale }
      )
      .then((res) => {
        // console.log(res.data);
        if ((res.data.code = "0")) {
          return true;
        } else {
          return false;
        }
        // return res.data;
      })
      .catch((err) => {
        // console.log("updateONDCDeliveryStatus error", err);
        return false;
      });
    return response;
    // } else {
    //   // console.log("updateONDCDeliveryStatus => updateData", updateData);
    //   await database
    //     .collection("Sales")
    //     .doc(sale.key)
    //     .update(updateData);
    //   return true;
    // }
  };
}

export function orderConfirmed(sale, smsNo) {
  return (dispatch) => {
    return database
      .collection("Sales")
      .doc(sale.key)
      .update({
        BackEndUpdate: true,
        DeliveryStatus: "Confirmed",
        DeliveryConfirmedActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        DeliveryConfirmedSMSNo: smsNo,
      })
      .then(() => {
        //send sms
        if (smsNo.trim() !== "") {
          if (smsNo.trim() !== "") {
            let messageText = "";
            messageText =
              "Dear Customer, Your order " +
              sale.TransactionNo +
              " has been confirmed and will be delivered shortly.";
            let obj = {
              RegistrationID: sale.RegistrationID,
              StoreID: sale.Store.key,
              UserID: sale.ActionByUID,
              Module: "sales",
              CashRegisterID: sale.CashRegister.key,
              MobileNo: smsNo,
              TransactionType: "Delivery",
              TransactionData: messageText,
            };
            let addMessage = firebase.functions().httpsCallable("sendSMS");
            return addMessage({ text: obj }).then(function(result) {
              if (result.data.text) {
                return true;
              } else {
                return false;
              }
            });
          }
        }
      });
  };
}

export function outForDelivery(
  sale,
  deliveryPersonName,
  deliveryPersonKey,
  deliveryPersonMobileNo
) {
  return (dispatch) => {
    return database
      .collection("Sales")
      .doc(sale.key)
      .update({
        BackEndUpdate: true,
        DeliveryStatus: "Out For Delivery",
        DeliveryPersonKey: deliveryPersonKey,
        DeliveryPersonName: deliveryPersonName,
        DeliveryOutForDeliveryActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        DeliveryPersonMobileNo: deliveryPersonMobileNo,
      })
      .then(() => {
        //send sms
        if (deliveryPersonMobileNo.trim() !== "") {
          let messageText = "";
          messageText =
            "Dear Customer, Your order " +
            sale.TransactionNo +
            " is out for delivery.";
          let obj = {
            RegistrationID: sale.RegistrationID,
            StoreID: sale.Store.key,
            UserID: sale.ActionByUID,
            Module: "sales",
            CashRegisterID: sale.CashRegister.key,
            MobileNo: deliveryPersonMobileNo,
            TransactionType: "Delivery",
            TransactionData: messageText,
          };
          let addMessage = firebase.functions().httpsCallable("sendSMS");
          return addMessage({ text: obj }).then(function(result) {
            if (result.data.text) {
              return true;
            } else {
              return false;
            }
          });
        }
      });
  };
}

export function readyForPickup(sale, smsNo) {
  return (dispatch) => {
    return database
      .collection("Sales")
      .doc(sale.key)
      .update({
        BackEndUpdate: true,
        DeliveryStatus: "Ready For Pickup",
        DeliveryReadyForPickupActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        DeliveryReadyForPickupSMSNo: smsNo,
      })
      .then(() => {
        //send sms
        if (smsNo.trim() !== "") {
          if (smsNo.trim() !== "") {
            let messageText = "";
            messageText =
              "Dear Customer, Your order " +
              sale.TransactionNo +
              " is ready for pickup.";
            let obj = {
              RegistrationID: sale.RegistrationID,
              StoreID: sale.Store.key,
              UserID: sale.ActionByUID,
              Module: "sales",
              CashRegisterID: sale.CashRegister.key,
              MobileNo: smsNo,
              TransactionType: "Delivery",
              TransactionData: messageText,
            };
            let addMessage = firebase.functions().httpsCallable("sendSMS");
            return addMessage({ text: obj }).then(function(result) {
              if (result.data.text) {
                return true;
              } else {
                return false;
              }
            });
          }
        }
      });
  };
}

export function markDelivered(sale) {
  return (dispatch) => {
    return database
      .collection("Sales")
      .doc(sale.key)
      .update({
        BackEndUpdate: true,
        DeliveryStatus: "Delivered",
        DeliveryDeliveredActionOn: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {});
  };
}

export function getDeliveryStatusSummary(registrationID, storeID) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: {
                value: registrationID,
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
      },
    },
    aggs: {
      group_by_DeliveryStatus: {
        terms: {
          field: "DeliveryStatus.keyword",
        },
      },
      group_by_TransactionStatus: {
        terms: {
          field: "TransactionStatus.keyword",
        },
      },
    },
  };
  if (storeID !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "Store.key": storeID,
      },
    });
  }
  return (dispatch, getState) => {
    // console.log("getDeliveryStatusSummary", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const summary = [];
      let sanitizedMessage = result.data.text;
      // console.log(result);
      sanitizedMessage.aggregations.group_by_DeliveryStatus.buckets.map(
        (data, index) => {
          // console.log("data", JSON.stringify(data))
          summary.push({ status: data.key, count: data.doc_count });
        }
      );
      sanitizedMessage.aggregations.group_by_TransactionStatus.buckets.map(
        (data, index) => {
          // console.log("data", JSON.stringify(data))
          summary.push({ status: data.key, count: data.doc_count });
        }
      );
      // console.log("summary", JSON.stringify(summary))
      return summary;
    });
  };
}

export function getDeliveryDetailReportInfinityApp(
  registrationID,
  from,
  size,
  transactionStatus,
  searchTransactionNo,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchCustomerDetail,
  searchStore,
  searchEmployeeName,
  externalTransactionNo = ""
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            exists: {
              field: "ExternalTransactionNo",
            },
          },
        ],
        must_not: [
          {
            term: {
              ExternalTransactionNo: "",
            },
          },
        ],
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [],
  };
  if (transactionStatus !== "") {
    searchParameters.query.bool.must.push({
      match_phrase: { TransactionStatus: transactionStatus },
    });
  }
  // if (option.toLowerCase() !== "cancelled" && option.toLowerCase() !== "all") {
  //   searchParameters.query.bool.must_not.push({
  //     exists: {
  //       field: "CancellationReason",
  //     },
  //   });
  // }
  console.log("searchCustomerDetail", searchCustomerDetail);
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: {
        "Customer.FirstName":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: {
        "Customer.LastName":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.EmailID":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.PhoneNo":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.CustomerCode":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });

    searchParameters.query.bool.filter.bool.minimum_should_match = 1;
  }

  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo.trim().toLowerCase() },
    });
  }
  if (externalTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      wildcard: {
        ExternalTransactionNoText:
          "*" + externalTransactionNo.trim().toLowerCase() + "*",
      },
    });
  }
  if (searchStore.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: { "Store.key": searchStore },
    });
  }
  if (searchEmployeeName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        DeliveryOutForDeliveryEmployee: searchEmployeeName.trim(),
      },
    });
  }
  if (
    searchTransactionToDate.trim() !== "" &&
    searchTransactionFromDate.trim() !== ""
  ) {
    searchParameters.query.bool.must.push({
      range: {
        DeliveryDate: {
          lte: searchTransactionToDate,
          gte: searchTransactionFromDate,
          format: "yyyy-MM-dd",
        },
      },
    });
  }

  if (
    transactionStatus.toLowerCase() === "cancelled" ||
    transactionStatus.toLowerCase() === "all" ||
    transactionStatus.toLowerCase() === "delivered"
  ) {
    searchParameters.sort.push({
      TransactionDate: {
        order: "desc",
      },
    });
  } else {
    searchParameters.sort.push({
      TransactionDate: {
        order: "asc",
      },
    });
  }

  return async (dispatch, getState) => {
    let result = await axios
      .post("https://backend-o3dyulj4pa-el.a.run.app/v1/transaction/getsales", {
        text: searchParameters,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
    const invoices = [];
    let sanitizedMessage = result.text;
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ key: data._id, ...data._source });
    });
    return {
      totalItemsCount: sanitizedMessage.hits.total.value,
      searchResult: invoices,
    };

    // console.log(
    //   "getDeliveryDetailReportInfinityApp",
    //   JSON.stringify(searchParameters)
    // );
    // let addMessage = firebase.functions().httpsCallable("getSales");
    // return addMessage({ text: searchParameters }).then(function(result) {
    //   const invoices = [];
    //   let sanitizedMessage = result.data.text;
    //   sanitizedMessage.hits.hits.map((data, index) => {
    //     invoices.push({ key: data._id, ...data._source });
    //   });
    //   return {
    //     totalItemsCount: sanitizedMessage.hits.total.value,
    //     searchResult: invoices,
    //   };
    // });
  };
}

export function getDeliveryDetailReport(
  registrationID,
  from,
  size,
  option,
  searchTransactionNo,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchCustomerDetail,
  // searchCashRegister,
  searchStore,
  searchEmployeeName,
  externalTransactionNo = ""
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
        ],
        must_not: [],
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [],
  };

  // desc
  if (option.toLowerCase() === "created") {
    searchParameters.query.bool.must.push({
      match_phrase: { TransactionStatus: "Created" },
    });
  } else if (option.toLowerCase() === "accepted") {
    searchParameters.query.bool.must.push({
      match_phrase: { TransactionStatus: "Accepted" },
    });
  } else if (option === "pending") {
    // searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "pending" } })
    searchParameters.query.bool.must.push({
      term: { "DeliveryStatus.keyword": "Pending" },
    });
  } else if (option.toLowerCase() === "confirmed") {
    // searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Confirmed" } })
    searchParameters.query.bool.must.push({
      term: { "DeliveryStatus.keyword": "Confirmed" },
    });
  } else if (option.toLowerCase() === "outfordelivery") {
    // searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Out For Delivery" } })
    searchParameters.query.bool.must.push({
      term: { "DeliveryStatus.keyword": "Out For Delivery" },
    });
  } else if (option.toLowerCase() === "readyforpickup") {
    // searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Ready For Pickup" } })
    searchParameters.query.bool.must.push({
      term: { "DeliveryStatus.keyword": "Ready For Pickup" },
    });
  } else if (option.toLowerCase() === "delivered") {
    // searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "delivered" } })
    searchParameters.query.bool.must.push({
      term: { "DeliveryStatus.keyword": "Delivered" },
    });
  } else if (option.toLowerCase() === "all") {
    searchParameters.query.bool.must.push({
      exists: { field: "DeliveryStatus" },
    });
  } else {
    // searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": option } })
    searchParameters.query.bool.must.push({
      term: { "DeliveryStatus.keyword": option },
    });
  }
  if (option.toLowerCase() !== "cancelled" && option.toLowerCase() !== "all") {
    searchParameters.query.bool.must_not.push({
      exists: {
        field: "CancellationReason",
      },
    });
  }

  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: {
        "Customer.FirstName":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: {
        "Customer.LastName":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.EmailID":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.PhoneNo":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.CustomerCode":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });

    searchParameters.query.bool.filter.bool.minimum_should_match = 1;
  }

  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo.trim().toLowerCase() },
    });
  }
  if (externalTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      wildcard: {
        ExternalTransactionNoText:
          "*" + externalTransactionNo.trim().toLowerCase() + "*",
      },
    });
  }
  // if (searchCashRegister.trim() !== "") {
  //     searchParameters.query.bool.filter.bool.must.push({ "match": { "CashRegister.key": searchCashRegister } })
  // }
  if (searchStore.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: { "Store.key": searchStore },
    });
  }
  if (searchEmployeeName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        DeliveryOutForDeliveryEmployee: searchEmployeeName.trim(),
      },
    });
  }
  if (
    searchTransactionToDate.trim() !== "" &&
    searchTransactionFromDate.trim() !== ""
  ) {
    searchParameters.query.bool.must.push({
      range: {
        DeliveryDate: {
          lte: searchTransactionToDate,
          gte: searchTransactionFromDate,
          format: "yyyy-MM-dd",
        },
      },
    });
  }

  if (
    option.toLowerCase() === "cancelled" ||
    option.toLowerCase() === "all" ||
    option.toLowerCase() === "order-delivered"
  ) {
    searchParameters.sort.push({
      TransactionDate: {
        order: "desc",
      },
    });
  } else {
    searchParameters.sort.push({
      TransactionDate: {
        order: "asc",
      },
    });
  }

  return async (dispatch, getState) => {
    // searchParameters.query.bool.must.push({
    //     "match": {
    //         "Store.key": getState().user.store.key
    //     }
    // })
    // console.log("getDeliveryDetailReport", JSON.stringify(searchParameters));

    let result = await axios
      .post("https://backend-o3dyulj4pa-el.a.run.app/v1/transaction/getsales", {
        text: searchParameters,
      })
      .then((res) => {
        // console.log("res", res);
        return res.data;
      })
      .catch((err) => {});
    // console.log("result", result);

    const invoices = [];
    let sanitizedMessage = result.text;
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ key: data._id, ...data._source });
    });
    return {
      totalItemsCount: sanitizedMessage.hits.total.value,
      searchResult: invoices,
    };

    // let addMessage = firebase.functions().httpsCallable("getSales");
    // return addMessage({ text: searchParameters }).then(function(result) {
    //   const invoices = [];
    //   let sanitizedMessage = result.data.text;
    //   sanitizedMessage.hits.hits.map((data, index) => {
    //     invoices.push({ key: data._id, ...data._source });
    //   });
    //   return {
    //     totalItemsCount: sanitizedMessage.hits.total.value,
    //     searchResult: invoices,
    //   };
    // });
  };
}

export function addPaymentProviderTransaction(transaction) {
  return (dispatch, getState) => {
    transaction.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    transaction.ActionBy = getState().user.user.Name;
    transaction.ActionByUID = getState().user.user.UID;
    transaction.ActionByEmailID = getState().user.user.EmailID;
    transaction.Action = constants.AUDIT_TRAIL_NEW;
    // console.log('sale',sale)
    // console.log("addPaymentProviderTransaction transaction", transaction);
    return database
      .collection("PaymentProviderTransaction")
      .add({
        ...transaction,
      })
      .then(function(docRef) {
        console.log("addPaymentProviderTransaction docRef.id", docRef.id);
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error saving Payment Provider Transaction : ", error);
      });
  };
}

export function updatePaymentProviderTransaction(key, status, message) {
  return (dispatch, getState) => {
    return database
      .collection("PaymentProviderTransaction")
      .doc(key)
      .update({
        Status: status,
        Message: message,
      });
  };
}

export function updateOrderStatus(sale, status) {
  return (dispatch, getState) => {
    return database
      .collection("UrbanPiperOrders")
      .doc(sale.key)
      .update({
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,
        Action: constants.AUDIT_TRAIL_EDIT,
        BackEndUpdate: false,
        Status: status,
        Message: "",
      })
      .then(() => {});
  };
}
