import React, { Component } from 'react';
import ImageButton from './ImageButton';
import {isMobileApp} from '../../../actions/Util';
import { HotKeys } from 'react-hotkeys';
import Button   from '../Controls/Button';
import './Page.css';

const keyMap = {
    save: 'ctrl+s',
    back: 'ctrl+b'
};

class Page extends Component {

    constructor() {
        super();
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    renderButtons(){
        return <React.Fragment>
           
             {this.props.showSaveButton && <button type="submit"
                className="btn btn-primary pull-right"
                onClick={()=>this.props.onClick("save")}
                disabled={this.props.processing}
                >Save
            </button>}
            {this.props.showPrintButton &&  <button type="submit"
                    disable={this.props.processing}
                    style={{marginRight:"10px"}}
                    className="btn btn-secondary pull-right"
                    onClick={()=>this.props.onClick("print")}><i className="fa fa-barcode"></i>Print</button>}
            {this.props.showParkTransactionButton && <button type="submit" 
                className="btn btn-secondary pull-right"
                style={{marginRight:"10px"}}
                onClick={()=>this.props.onClick("parktransaction")} 
                disabled={this.props.processing}>Park Transaction
            </button>}
            {this.props.showCancelButton && <button 
                style={{marginRight:"10px"}}
                disabled={this.props.processing}
                onClick={()=>this.props.onClick("cancel")}
                className="btn btn-secondary pull-right">Cancel
            </button>}
            {this.props.showPrintFormats && <React.Fragment> 
                <span className="input-group pull-right" style={{ marginRight: "10px", width: "290px" }}>
                    <span className="input-group-addon">
                    <input type="checkbox"
                        checked={this.props.printEnabled}
                        onChange={() => this.props.onPrintOptionClicked(!this.props.printEnabled)}/>
                    </span>
                    <label className="form-control" style={{ width: "50px" }}>Print</label>
                    <select className="form-control input-md" style={{ width: "200px" }}
                        value={this.props.printFormat}
                        onChange={(evt) => this.props.onPrintFormatChange(evt.target.value)}>
                        {this.props.config.PrintConfigs.filter((e) => !e.IsDeleted && e.TransactionTypeID === this.props.transactionTypeID).map((t, index) =>
                            <option key={index} value={t.key}>{t.PrintDisplayName}</option>
                        )}
                    </select>
                </span>
                <span className="input-group pull-right" style={{ marginRight: "10px", width: "100px" }}>
                    <span className="input-group-addon">
                        <input type="checkbox" 
                        checked={this.props.emailEnabled} 
                        onChange={() => this.props.onEmailOptionClicked(!this.props.emailEnabled)} />
                    </span>
                    <label className="form-control">Email</label>
                </span>
            </React.Fragment>}
        </React.Fragment>
    }

    renderDesktop(){
        return <React.Fragment>
            <div className="content-wrapper">
                <div className="content-header">
                    <h1>{this.props.title}</h1>
                    {this.renderButtons()}
                </div>
                {this.props.children}
                <div className="content-footer">
                    {this.renderButtons()}
                </div>
                </div>
        </React.Fragment>
    }

    renderMobile(){
        return <React.Fragment>
            <div className= "page-mobile-header">
                <div className="left w100">
                    <ImageButton type={"leftarrow"} label={"back"} onClick={()=>this.props.onClick("cancel")}/>
                    <div className="page-mobile-header-title">{this.props.title}</div>
                </div>
                {/* <div className="right-30">
                </div> */}
            </div>
            <div className="page-mobile-content">
                {this.props.children}
                {this.props.showSaveButton && <React.Fragment>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </React.Fragment>}
            </div>
            {this.props.showSaveButton &&  <div className="page-mobile-footer">
                <div className="w100">
                    <Button 
                        type="primary" 
                        text="Save" 
                        onClick={()=>this.props.onClick("save")} 
                        processing={this.props.processing}/>
                </div>
            </div>}
        </React.Fragment>
    }

    onSave(){
        this.props.onClick("save");
    }

    onCancel(){
        this.props.onClick("cancel")
    }

    render(){

        const handlers = {
            save: this.onSave,
            back: this.onCancel
        };

        return <React.Fragment>
            <HotKeys
            keyMap={keyMap}
            handlers={handlers}>

                {!isMobileApp() && this.renderDesktop()}
                {isMobileApp() && this.renderMobile()}
            </HotKeys>
        </React.Fragment>
    }

}

export default Page;