import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import TimePicker from "../TimePicker";
import Input from "../2/Controls/Input";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import GooglePlacesSearchInput from "../2/GooglePlacesSearchInput";
// import GoogleCurrentLocation from "../2/GoogleCurrentLocation";
import GoogleMaps from "../2/GoogleMap";
import { getCities } from "../../actions/Util";
import Window from "../2/Controls/Window";
import Button from "../2/Controls/Button";
import {
  getPinCodeStoreDomain,
  getPinCodeStoreType,
} from "../../services/functions/Pincode";

/* global google */
class AddEditStore extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      storeCode: "",
      storeName: "",
      emailID: "",
      phoneNo: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      currency: "",
      fiscalYearMonth: "",
      language: "",
      timeZone: "",
      cashRegisterClosingSelected: false,
      changeQtyOnPriceUpdate: false,
      cashRegisterClosingIncludeProductSaleDetails: false,
      customField1: "",
      customField2: "",
      customField3: "",
      customField4: "",
      customField5: "",
      customField6: "",
      storeNameError: "",
      storeCodeError: "",
      action: "",
      key: "",
      customField1DisplayName: "Custom Field 1",
      customField2DisplayName: "Custom Field 2",
      customField3DisplayName: "Custom Field 3",
      customField4DisplayName: "Custom Field 4",
      customField5DisplayName: "Custom Field 5",
      customField6DisplayName: "Custom Field 6",
      negativeProductQtyNotAllowedForBilling: false,
      states: [],
      stateCode: "",
      enableFoodDeliveryPlatforms: false,
      minPickUptime: 0,
      minDeliveryTime: 0,
      minOrderValue: 0,
      urbanPiperIntegration: false,
      storeTiming: [],
      storeTimingDefault: [
        {
          Day: "Monday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "", Error: "" },
        },
        {
          Day: "Tuesday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "", Error: "" },
        },
        {
          Day: "Wednesday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "", Error: "" },
        },
        {
          Day: "Thursday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "", Error: "" },
        },
        {
          Day: "Friday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "", Error: "" },
        },
        {
          Day: "Saturday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "", Error: "" },
        },
        {
          Day: "Sunday",
          Open: false,
          StartTime: { HH: "", MM: "", AMPM: "" },
          EndTime: { HH: "", MM: "", AMPM: "" },
          Error: "",
        },
      ],
      foodDeliveryPlatforms: [],
      salesTransactionTypes: [],
      salesProductSearch: 1,
      inventoryProductSearch: 1,
      kotPrinter: "",
      latitude: "",
      longitude: "",
      desktopOption: "",
      enableTableOrder: false,
      tableOrderTransactionTypeID: "",
      deliveryRadius: "",
      place: { Address: "", Lat: "", Lng: "" },
      deliveryCharge: "",
      deliveryTimeType: "min",
      deliveryTime: "",
      shipTime: "",
      shipTimeType: "min",
      disableCustomerCreationAndEdit: false,
      deliveryType: "",
      customerPickUp: false,
      logo: null,
      shortDescription: "",
      longDescription: "",
      images: [],
      autoAcceptOnlineOrders: false,
      acceptOnlineOrders: false,
      googleReviewLink: "",
      cities: [],
      showProductsOnlineAvailabilityBasedOnOutOfStock: false,
      locality: "",
      latitudeTmp: "",
      longitudeTmp: "",
      latTmpError: "",
      lonTmpError: "",
      domain: [],
      storeTypes: [],
      storeDomainID: "",
      storeTypeID: "",
      pincodeHashKey: "",
      storeDomainError: "",
      storeTypeError: "",
      lockStoreConfig: false,
      tmplockStoreConfig: false,
    };

    this.onSaveStore = this.onSaveStore.bind(this);
    this.onDeliveryPlatformSelected = this.onDeliveryPlatformSelected.bind(
      this
    );
    this.onStoreTimingOpenChange = this.onStoreTimingOpenChange.bind(this);
    this.onStoreTimingStartTimeChange = this.onStoreTimingStartTimeChange.bind(
      this
    );
    this.onStoreTimingEndTimeChange = this.onStoreTimingEndTimeChange.bind(
      this
    );
    this.onDeliveryPlatformPriceMarkUpChange = this.onDeliveryPlatformPriceMarkUpChange.bind(
      this
    );
    this.onDeliveryPlatformTransactionTypeChange = this.onDeliveryPlatformTransactionTypeChange.bind(
      this
    );
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    let states = await this.props.getStateCodeMaster();
    let cities = getCities("");
    const domain = getPinCodeStoreDomain();
    this.setState({ states: states, cities: cities, domain: domain });

    const key = this.props.match.params.key;
    // console.log("key",key)
    if (key) {
      //existing product
      let store = await this.props.getStoreDetails(key);
      // console.log("store", store)
      let selectedFoodDeliveryPlatforms = store.hasOwnProperty(
        "FoodDeliveryPlatforms"
      )
        ? store.FoodDeliveryPlatforms
        : [];
      let foodDeliveryPlatforms = await this.props.getFoodDeliveryPlatforms();
      foodDeliveryPlatforms.map((m) => {
        if (
          selectedFoodDeliveryPlatforms.filter((f) => f.Code === m.Code)
            .length > 0
        ) {
          let l = selectedFoodDeliveryPlatforms.filter(
            (f) => f.Code === m.Code
          )[0];
          m.Selected = true;
          m.TransactionTypeID = l.hasOwnProperty("TransactionTypeID")
            ? l.TransactionTypeID
            : "";
          m.PriceMarkUp = l.hasOwnProperty("PriceMarkUp") ? l.PriceMarkUp : 0;
        } else {
          m.Selected = false;
          m.TransactionTypeID = "";
          m.PriceMarkUp = 0;
        }
      });
      let latitude =
        store.hasOwnProperty("Location") && store.Location.lat
          ? store.Location.lat
          : "";
      let longitude =
        store.hasOwnProperty("Location") && store.Location.lon
          ? store.Location.lon
          : "";
      let place = { Address: "", Lat: latitude, Lng: longitude };

      // console.log('store.StoreTiming', store.StoreTiming)
      let deliveryTimeType = "min";
      if (
        store.hasOwnProperty("DeliveryTimeType") &&
        store.DeliveryTimeType !== ""
      ) {
        deliveryTimeType = store.DeliveryTimeType;
      }

      let pincodeHashKey = store.hasOwnProperty("PincodeHashKey")
        ? store.PincodeHashKey
        : "";
      let storeDomainID = store.hasOwnProperty("StoreDomainID")
        ? store.StoreDomainID
        : "";
      let storeTypeID = store.hasOwnProperty("StoreTypeID")
        ? store.StoreTypeID
        : "";
      if (storeDomainID !== "") {
        this.onStoreDomainSelected(storeDomainID);
      }
      this.setState({
        key: key,
        storeCode: store.StoreCode,
        storeName: store.StoreName,
        emailID: store.EmailID,
        phoneNo: store.PhoneNo,
        address1: store.Address1,
        address2: store.Address2,
        city: store.City,
        state: store.hasOwnProperty("State") ? store.State : "",
        stateCode: store.hasOwnProperty("StateCode") ? store.StateCode : "",
        storeTypeID: storeTypeID,
        postalCode: store.PostalCode,
        country: store.Country,
        currency: store.Currency,
        fiscalYearMonth: store.FiscalYearMonth,
        language: store.Language,
        timeZone: store.TimeZone,
        pincodeHashKey: pincodeHashKey,
        storeDomainID: storeDomainID,
        cashRegisterClosingSelected: this.props.user.store.hasOwnProperty(
          "CashRegisterClosing"
        )
          ? this.props.user.store.CashRegisterClosing
          : false,
        cashRegisterClosingIncludeProductSaleDetails: this.props.user.store.hasOwnProperty(
          "CashRegisterClosingIncludeProductSaleDetails"
        )
          ? this.props.user.store.CashRegisterClosingIncludeProductSaleDetails
          : false,
        customField1: store.CustomField1,
        customField2: store.CustomField2,
        customField3: store.CustomField3,
        customField4: store.CustomField4,
        customField5: store.CustomField5,
        customField6: store.CustomField6,
        changeQtyOnPriceUpdate: store.hasOwnProperty("ChangeQtyOnPriceUpdate")
          ? store.ChangeQtyOnPriceUpdate
          : false,
        negativeProductQtyNotAllowedForBilling: store.hasOwnProperty(
          "NegativeProductQtyNotAllowedForBilling"
        )
          ? store.NegativeProductQtyNotAllowedForBilling
          : this.props.registration.registration.hasOwnProperty(
              "NegativeProductQtyNotAllowedForBilling"
            )
          ? this.props.registration.registration
              .NegativeProductQtyNotAllowedForBilling
          : false,
        enableFoodDeliveryPlatforms: store.hasOwnProperty(
          "EnableFoodDeliveryPlatforms"
        )
          ? store.EnableFoodDeliveryPlatforms
          : false,
        urbanPiperIntegration: store.hasOwnProperty("UrbanPiperIntegration")
          ? store.UrbanPiperIntegration
          : false,
        storeTiming:
          store.hasOwnProperty("StoreTiming") && store.StoreTiming.length !== 0
            ? store.StoreTiming
            : this.state.storeTimingDefault,
        foodDeliveryPlatforms: foodDeliveryPlatforms,
        minPickUptime: store.hasOwnProperty("MinPickUptime")
          ? store.MinPickUptime
          : 0,
        minDeliveryTime: store.hasOwnProperty("MinDeliveryTime")
          ? store.MinDeliveryTime
          : 0,
        minOrderValue: store.hasOwnProperty("MinOrderValue")
          ? store.MinOrderValue
          : 0,
        salesTransactionTypes: this.props.config.TransactionTypes.filter(
          (f) => f.Module === "sales" && !f.IsDeleted
        ),
        salesProductSearch: store.hasOwnProperty("SalesProductSearch")
          ? store.SalesProductSearch
          : 1,
        inventoryProductSearch: store.hasOwnProperty("InventoryProductSearch")
          ? store.InventoryProductSearch
          : 1,
        kotPrinter: store.hasOwnProperty("KOTPrinter") ? store.KOTPrinter : "",
        longitude: longitude,
        latitude: latitude,
        place: place,
        desktopOption:
          store.hasOwnProperty("DesktopOption") && store.DesktopOption
            ? store.DesktopOption
            : "1",
        enableTableOrder: store.hasOwnProperty("EnableTableOrder")
          ? store.EnableTableOrder
          : false,
        tableOrderTransactionTypeID: store.hasOwnProperty(
          "TableOrderTransactionTypeID"
        )
          ? store.TableOrderTransactionTypeID
          : 0,
        deliveryRadius: store.hasOwnProperty("DeliveryRadius")
          ? store.DeliveryRadius
          : "",
        deliveryCharge: store.hasOwnProperty("DeliveryCharge")
          ? store.DeliveryCharge
          : "",
        shipTime: store.hasOwnProperty("ShipTime") ? store.ShipTime : "",
        shipTimeType: store.hasOwnProperty("ShipTimeType")
          ? store.ShipTimeType
          : "min",
        deliveryTime: store.hasOwnProperty("DeliveryTime")
          ? store.DeliveryTime
          : "",
        deliveryTimeType: deliveryTimeType,
        disableCustomerCreationAndEdit: store.hasOwnProperty(
          "DisableCustomerCreationAndEdit"
        )
          ? store.DisableCustomerCreationAndEdit
          : "",
        deliveryType: store.hasOwnProperty("DeliveryType")
          ? store.DeliveryType
          : "",
        customerPickUp: store.hasOwnProperty("CustomerPickUp")
          ? store.CustomerPickUp
          : false,
        logo: store.hasOwnProperty("Logo") ? store.Logo : null,
        shortDescription: store.hasOwnProperty("ShortDescription")
          ? store.ShortDescription
          : "",
        longDescription: store.hasOwnProperty("LongDescription")
          ? store.LongDescription
          : "",
        images: store.hasOwnProperty("Images") ? store.Images : "",
        autoAcceptOnlineOrders: store.hasOwnProperty("AutoAcceptOnlineOrders")
          ? store.AutoAcceptOnlineOrders
          : false,
        acceptOnlineOrders: store.hasOwnProperty("AcceptOnlineOrders")
          ? store.AcceptOnlineOrders
          : false,
        googleReviewLink: store.hasOwnProperty("GoogleReviewLink")
          ? store.GoogleReviewLink
          : "",
        showProductsOnlineAvailabilityBasedOnOutOfStock: store.hasOwnProperty(
          "ShowProductsOnlineAvailabilityBasedOnOutOfStock"
        )
          ? store.ShowProductsOnlineAvailabilityBasedOnOutOfStock
          : false,
        locality: store.hasOwnProperty("Locality") ? store.Locality : "",
        lockStoreConfig: store.hasOwnProperty("LockStoreConfig")
          ? store.LockStoreConfig
          : false,
        tmplockStoreConfig: store.hasOwnProperty("LockStoreConfig")
          ? store.LockStoreConfig
          : false,
      });
    } else {
      //check if new store can be added
      //get store count
      let stores = await this.props
        .getStores(
          this.props.user.user.RegistrationID,
          0,
          1000,
          "active",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        )
        .then((result) => {
          return result.searchResult;
        });
      let storeCount = stores.filter((f) => !f.IsDeleted).length;
      if (
        this.props.registration.registration.hasOwnProperty(
          "NoOfStoresAllowed"
        ) &&
        this.props.registration.registration.NoOfStoresAllowed > storeCount
      ) {
        this.setState({ storeTiming: this.state.storeTimingDefault });
      } else {
        alert("Additional Store cannot be added as limit has exceeded.");
        this.props.history.push({ pathname: "/home/settings/stores/" });
      }
      //for pincode make this attribute enabled
      if (this.props.config.WhiteLabel.Code === "PINCODE") {
        this.setState({
          showProductsOnlineAvailabilityBasedOnOutOfStock: true,
        });
      }
    }

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Store") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          customField1DisplayName: customField1DisplayName,
          customField2DisplayName: customField2DisplayName,
          customField3DisplayName: customField3DisplayName,
          customField4DisplayName: customField4DisplayName,
          customField5DisplayName: customField5DisplayName,
          customField6DisplayName: customField6DisplayName,
        });
      }
    });
  }

  isValidLatitude(latitude) {
    // Latitude range: -90 to 90
    return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/.test(latitude);
  }

  isValidLongitude(longitude) {
    // Longitude range: -180 to 180
    return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/.test(longitude);
  }

  onStoreDomainSelected(value) {
    const storeTypes = getPinCodeStoreType(value);
    // console.log("storeTypes",storeTypes)
    this.setState({
      storeDomainID: value,
      storeTypes: storeTypes,
      storeDomainError: "",
    });
  }

  onStoreTypeSelected(value) {
    // console.log("value",value)
    let storeType = "";
    if (value !== "") {
      storeType = this.state.storeTypes.filter((f) => f.id === value)[0].value;
    }
    // console.log("storeType", storeType);
    this.setState({
      storeTypeID: value,
      storeType: storeType,
      storeTypeError: "",
    });
  }

  onChange(value) {
    this.setState({
      place: value,
      error: "",
      latitudeTmp: value && value.Lat ? value.Lat : "",
      longitudeTmp: value && value.Lng ? value.Lng : "",
    });
    // this.props.onAddressSelcted(value);
  }

  onStoreTimingOpenChange(index) {
    let storeTiming = this.state.storeTiming;
    storeTiming[index].Open = !storeTiming[index].Open;
    this.setState({ storeTiming: storeTiming });
  }

  onStoreTimingStartTimeChange(startTime, index) {
    let storeTiming = this.state.storeTiming;
    storeTiming[index].StartTime = startTime;
    this.setState({ storeTiming: storeTiming });
  }

  onStoreTimingEndTimeChange(endTime, index) {
    let storeTiming = this.state.storeTiming;
    storeTiming[index].EndTime = endTime;
    this.setState({ storeTiming: storeTiming });
  }

  onDeliveryPlatformSelected(index) {
    let foodDeliveryPlatforms = this.state.foodDeliveryPlatforms;
    foodDeliveryPlatforms[index].Selected = !foodDeliveryPlatforms[index]
      .Selected;
    this.setState({ foodDeliveryPlatforms: foodDeliveryPlatforms });
  }

  onDeliveryPlatformPriceMarkUpChange(index, value) {
    let foodDeliveryPlatforms = this.state.foodDeliveryPlatforms;
    foodDeliveryPlatforms[index].PriceMarkUp = value;
    this.setState({ foodDeliveryPlatforms: foodDeliveryPlatforms });
  }

  onDeliveryPlatformTransactionTypeChange(index, value) {
    let foodDeliveryPlatforms = this.state.foodDeliveryPlatforms;
    foodDeliveryPlatforms[index].TransactionTypeID = value;
    this.setState({ foodDeliveryPlatforms: foodDeliveryPlatforms });
  }

  async onSaveStore() {
    this.setState({ saving: true });
    let storeCodeError = "";
    let storeNameError = "";
    let stores = await this.props
      .getStores(
        this.props.user.user.RegistrationID,
        0,
        1000,
        "active",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
      .then((result) => {
        return result.searchResult;
      });
    let storeCount = stores.filter((f) => !f.IsDeleted).length;
    if (
      this.props.registration.registration.hasOwnProperty(
        "NoOfStoresAllowed"
      ) &&
      this.props.registration.registration.NoOfStoresAllowed > storeCount
    ) {
    } else {
      if (this.state.key === "") {
        this.setState({
          storeNameError:
            "Additional Store cannot be added as limit has exceeded.",
        });
      }
    }
    if (this.state.storeCode.trim() === "") {
      storeCodeError = "Please provide store code";
    }
    if (this.state.storeName.trim() === "") {
      storeNameError = "Please provide store name";
    }
    // if (storeCodeError !== "" || storeNameError !== "") {
    //   this.setState({
    //     storeCodeError: storeCodeError,
    //     storeNameError: storeNameError,
    //     saving: false,
    //   });
    //   return;
    // }
    let isUnique = await this.props.checkIfStoreCodeIsUnique(
      this.state.storeCode,
      this.state.key,
      this.props.user.user.RegistrationID
    );
    if (!isUnique) {
      storeCodeError = "Store code already exists";
      // this.setState({
      //   storeCodeError: "Store code already exists",
      //   saving: false,
      // });
      // return;
    }
    let foodDeliveryPlatforms = this.state.foodDeliveryPlatforms.filter(
      (f) => f.Selected
    );
    console.log("this.state.city", this.state.city);
    if (this.props.config.WhiteLabel.Code === "PINCODE") {
      let phoneNoError = "";
      if (this.state.phoneNo === "") {
        phoneNoError = "Please provide store contact no";
      }
      let address1Error = "";
      if (this.state.address1.trim() === "") {
        address1Error = "Please provide value";
      }
      let localityError = "";
      if (this.state.locality.trim() === "") {
        localityError = "Please provide value";
      }
      let cityError = "";
      if (this.state.city.trim() === "") {
        cityError = "Please provide value";
      }
      let stateError = "";
      if (this.state.state.trim() === "") {
        stateError = "Please provide value";
      }
      let postalCodeError = "";
      if (this.state.postalCode.trim() === "") {
        postalCodeError = "Please provide value";
      }
      let latError = "";
      if (this.state.latitude === "") {
        latError = "Please provide value";
      }
      if (
        this.state.latitude !== "" &&
        !this.isValidLatitude(this.state.latitude)
      ) {
        latError = "Latitude is incorrect";
      }
      let lonError = "";
      if (this.state.longitude === "") {
        lonError = "Please provide value";
      }
      if (
        this.state.longitude !== "" &&
        !this.isValidLongitude(this.state.longitude)
      ) {
        lonError = "Longitude is incorrect";
      }
      if (
        storeCodeError !== "" ||
        storeNameError !== "" ||
        phoneNoError !== "" ||
        address1Error !== "" ||
        cityError !== "" ||
        stateError !== "" ||
        postalCodeError !== "" ||
        localityError !== "" ||
        latError !== "" ||
        lonError !== ""
      ) {
        this.setState({
          phoneNoError: phoneNoError,
          address1Error: address1Error,
          cityError: cityError,
          stateError: stateError,
          postalCodeError: postalCodeError,
          localityError: localityError,
          latError: latError,
          lonError: lonError,
          storeCodeError: storeCodeError,
          storeNameError: storeNameError,
          saving: false,
        });
        return;
      }
    } else {
      if (storeCodeError !== "" || storeNameError !== "") {
        this.setState({
          storeCodeError: storeCodeError,
          storeNameError: storeNameError,
          saving: false,
        });
        return;
      }
    }
    // console.log("this.state.deliveryTimeType",this.state.deliveryTimeType)
    let storeDomainID = this.state.storeDomainID;
    let storeTypeID = this.state.storeTypeID;
    let pincodeHashKey = this.state.pincodeHashKey;

    if (storeDomainID === "") {
      //check if registration has store domain id
      storeDomainID = this.props.registration.registration.StoreDomainID
        ? this.props.registration.registration.StoreDomainID
        : "";
    }
    if (storeTypeID === "") {
      storeTypeID = this.props.registration.registration.StoreTypeID
        ? this.props.registration.registration.StoreTypeID
        : "";
    }
    if (pincodeHashKey === "") {
      pincodeHashKey = this.props.registration.registration.PincodeHashKey
        ? this.props.registration.registration.PincodeHashKey
        : "";
    }

    let store = {
      StoreDomainID: storeDomainID,
      StoreTypeID: storeTypeID,
      PincodeHashKey: pincodeHashKey,
      StoreCode: this.state.storeCode,
      StoreName: this.state.storeName,
      EmailID: this.state.emailID,
      PhoneNo: this.state.phoneNo,
      Address1: this.state.address1,
      Address2: this.state.address2,
      Locality: this.state.locality,
      City: this.state.city,
      State: this.state.state,
      StateCode: this.state.stateCode,
      PostalCode: this.state.postalCode,
      Country: this.state.country,
      Currency: this.state.currency,
      FiscalYearMonth: this.state.fiscalYearMonth,
      Language: this.state.language,
      TimeZone: this.state.timeZone,
      CashRegisterClosing: this.state.cashRegisterClosingSelected,
      CashRegisterClosingIncludeProductSaleDetails: this.state
        .cashRegisterClosingIncludeProductSaleDetails,
      CustomField1: this.state.customField1,
      CustomField2: this.state.customField2,
      CustomField3: this.state.customField3,
      CustomField4: this.state.customField4,
      CustomField5: this.state.customField5,
      CustomField6: this.state.customField6,
      RegistrationID: this.props.user.user.RegistrationID,
      ChangeQtyOnPriceUpdate: this.state.changeQtyOnPriceUpdate,
      NegativeProductQtyNotAllowedForBilling: this.state
        .negativeProductQtyNotAllowedForBilling,
      EnableFoodDeliveryPlatforms: this.state.enableFoodDeliveryPlatforms,
      MinPickUptime: this.state.minPickUptime,
      MinDeliveryTime: this.state.minDeliveryTime,
      MinOrderValue: this.state.minOrderValue,
      FoodDeliveryPlatforms: foodDeliveryPlatforms,
      StoreTiming: this.state.storeTiming,
      SalesProductSearch: this.state.salesProductSearch,
      InventoryProductSearch: this.state.inventoryProductSearch,
      IsDeleted: false,
      KOTPrinter: this.state.kotPrinter,
      DesktopOption: this.state.desktopOption,
      EnableTableOrder: this.state.enableTableOrder,
      TableOrderTransactionTypeID: this.state.tableOrderTransactionTypeID,
      DeliveryRadius: this.state.deliveryRadius,
      DeliveryCharge: this.state.deliveryCharge,
      DeliveryTime: this.state.deliveryTime,
      DeliveryTimeType: this.state.deliveryTimeType,
      DisableCustomerCreationAndEdit: this.state.disableCustomerCreationAndEdit,
      DeliveryType: this.state.deliveryType,
      CustomerPickUp: this.state.customerPickUp,
      Logo: this.state.logo,
      ShortDescription: this.state.shortDescription,
      LongDescription: this.state.longDescription,
      Images: this.state.images,
      AutoAcceptOnlineOrders: this.state.autoAcceptOnlineOrders,
      AcceptOnlineOrders: this.state.acceptOnlineOrders,
      GoogleReviewLink: this.state.googleReviewLink,
      ShipTime: this.state.shipTime,
      ShipTimeType: this.state.shipTimeType,
      ShowProductsOnlineAvailabilityBasedOnOutOfStock: this.state
        .showProductsOnlineAvailabilityBasedOnOutOfStock,
      LockStoreConfig: this.state.tmplockStoreConfig,
    };

    // if (this.state.latitude.trim() !== '' && this.state.longitude.trim() !== '') {
    let lat = "";
    let lon = "";
    if (this.state.latitude === "") {
      lat = "0";
    } else {
      lat = this.state.latitude;
    }
    if (this.state.longitude === "") {
      lon = "0";
    } else {
      lon = this.state.longitude;
    }
    store.Location = {
      lat: lat,
      lon: lon,
    };

    if (this.state.key === "") {
      this.props.addNewStore(store).then((key) => {
        this.props.getToastr("New Store created Successfully");
        //redirect to store view page
        setTimeout(
          function() {
            //Start the timer
            this.setState({ saving: false });
            if (this.props.isMobileApp()) {
              this.props.history.push({ pathname: "/home/settings/stores/" });
            } else {
              this.props.history.push({
                pathname: "/home/settings/stores/viewstoredetails/" + key,
                state: { key: key },
              });
            }
          }.bind(this),
          1000
        );
      });
    } else {
      // console.log('store', store)
      // console.log('this.state.key',this.state.key)
      this.props.updateStore(store, this.state.key).then(() => {
        this.props.getToastr("Store Saved Successfully");
        //redirect to store view page
        setTimeout(
          function() {
            //Start the timer
            if (this.props.isMobileApp()) {
              this.props.history.push({ pathname: "/home/settings/stores/" });
            } else {
              this.props.history.push({
                pathname:
                  "/home/settings/stores/viewstoredetails/" + this.state.key,
                state: { key: this.state.key },
              });
            }
          }.bind(this),
          1000
        );
      });
    }
  }

  renderGoogleMapswindow() {
    return (
      <React.Fragment>
        {this.state.showGoogleMapsWindow && (
          <Window
            header={"Google Maps"}
            height={"300px"}
            modal={false}
            onClose={() => this.setState({ showGoogleMapsWindow: false })}
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "save") {
                this.setState({
                  showGoogleMapsWindow: false,
                  latitude: this.state.latitudeTmp,
                  longitude: this.state.longitudeTmp,
                  latError: "",
                  lonError: "",
                });
              } else {
                this.setState({ showGoogleMapsWindow: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Latitude"
                  onChange={(value) =>
                    this.setState({ latitudeTmp: value, latTmpError: "" })
                  }
                  value={this.state.latitudeTmp}
                  error={this.state.latTmpError}
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="text"
                  label="Longitude"
                  onChange={(value) =>
                    this.setState({ longitudeTmp: value, lonTmpError: "" })
                  }
                  value={this.state.longitudeTmp}
                  error={this.state.lonTmpError}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <GooglePlacesSearchInput
                  value={this.state.place}
                  onChange={(value) => {
                    this.onChange(value);
                  }}
                />
                <GoogleMaps
                  value={this.state.place}
                  onChange={(value) => {
                    this.onChange(value);
                  }}
                  draggable={true}
                  height="300px"
                />
              </div>
            </div>
          </Window>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderGoogleMapswindow()}

        <Page
          title={this.state.key !== "" ? "Edit Store" : "New Store"}
          showSaveButton={
            this.state.lockStoreConfig ? !this.state.lockStoreConfig : true
          }
          showCancelButton={true}
          processing={this.state.saving}
          onClick={(action) => {
            if (action === "cancel") {
              this.props.history.push("/home/settings/stores");
            } else if (action === "save") {
              this.onSaveStore();
            }
          }}
        >
          <PageSection title="General details">
            {this.props.config.WhiteLabel.Code === "PINCODE" && (
              <div className="row">
                <div className="col-md-3">
                  {!this.state.lockStoreConfig && (
                    <Input
                      type="switch"
                      label="Lock store config"
                      value={this.state.tmplockStoreConfig}
                      onChange={(value) =>
                        this.setState({ tmplockStoreConfig: value })
                      }
                    />
                  )}
                  {this.state.lockStoreConfig && (
                    <>
                      <span className="badge green uppercase">
                        Store config locked{" "}
                      </span>
                      <br></br>
                      <br></br>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Store Code"
                  onChange={(value) =>
                    this.setState({ storeCode: value, storeCodeError: "" })
                  }
                  value={this.state.storeCode}
                  error={this.state.storeCodeError}
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Store Name"
                  onChange={(value) =>
                    this.setState({ storeName: value, storeNameError: "" })
                  }
                  value={this.state.storeName}
                  error={this.state.storeNameError}
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="mobileno"
                  label="Phone Number"
                  onChange={(value) =>
                    this.setState({ phoneNo: value, phoneNoError: "" })
                  }
                  value={this.state.phoneNo}
                  error={this.state.phoneNoError}
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="emailid"
                  label="Email ID"
                  onChange={(value) => this.setState({ emailID: value })}
                  value={this.state.emailID}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="textarea"
                  label="Short Description"
                  value={this.state.shortDescription}
                  onChange={(value) =>
                    this.setState({ shortDescription: value })
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="textarea"
                  label="Long Description"
                  value={this.state.longDescription}
                  onChange={(value) =>
                    this.setState({ longDescription: value })
                  }
                />
              </div>
            </div>
          </PageSection>
          <PageSection title="Address">
            <div className="row">
              <div className="col-md-4">
                <Input
                  type="text"
                  label="Line Address 1"
                  onChange={(value) =>
                    this.setState({ address1: value, address1Error: "" })
                  }
                  value={this.state.address1}
                  error={this.state.address1Error}
                />
                <Input
                  type="text"
                  label="Line Address 2"
                  onChange={(value) => this.setState({ address2: value })}
                  value={this.state.address2}
                />
                <Input
                  type="text"
                  label="Locality"
                  onChange={(value) =>
                    this.setState({ locality: value, localityError: "" })
                  }
                  value={this.state.locality}
                  error={this.state.localityError}
                />
                <Input
                  type="select"
                  label="City"
                  onChange={(value) =>
                    this.setState({ city: value, cityError: "" })
                  }
                  value={this.state.city}
                  error={this.state.cityError}
                >
                  <option value={""}>Please select</option>
                  {this.state.cities.map((c) => (
                    <option value={c.City}>{c.City}</option>
                  ))}
                </Input>
                <Input
                  type="text"
                  label="Postal Code"
                  onChange={(value) =>
                    this.setState({ postalCode: value, postalCodeError: "" })
                  }
                  value={this.state.postalCode}
                  error={this.state.postalCodeError}
                />
                {(this.state.country === "" ||
                  this.state.country === "India") && (
                  <Input
                    type="select"
                    label="State"
                    value={this.state.stateCode}
                    error={this.state.stateError}
                    onChange={(value) => {
                      // console.log('value', value);
                      // console.log('this.state.states', this.state.states)

                      let state = "";
                      if (
                        value !== "" &&
                        this.state.states.filter(
                          (f) => Number(f.code) === Number(value)
                        ).length > 0
                      ) {
                        state = this.state.states.filter(
                          (f) => Number(f.code) === Number(value)
                        )[0].name;
                        // console.log(state);
                      }
                      this.setState({
                        stateCode: value,
                        state: state,
                        stateError: "",
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {this.state.states.map((m) => (
                      <option value={m.code}>{m.name}</option>
                    ))}
                  </Input>
                )}
                {this.state.country !== "" &&
                  this.state.country !== "India" && (
                    <Input
                      type="text"
                      label="State"
                      onChange={(value) =>
                        this.setState({ stateCode: value, state: value })
                      }
                      value={this.state.state}
                    />
                  )}
                <Input
                  type="text"
                  label="Country"
                  onChange={(value) => this.setState({ country: value })}
                  value={this.state.country}
                />
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="text"
                      label="Latitude"
                      onChange={(value) =>
                        this.setState({ latitude: value, latError: "" })
                      }
                      value={this.state.latitude}
                      error={this.state.latError}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="text"
                      label="Longitude"
                      onChange={(value) =>
                        this.setState({ longitude: value, lonError: "" })
                      }
                      value={this.state.longitude}
                      error={this.state.lonError}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Button
                      type="secondary"
                      text="Open Google Maps"
                      onClick={() => {
                        this.setState({
                          showGoogleMapsWindow: true,
                          latitudeTmp: this.state.latitude,
                          longitudeTmp: this.state.longitude,
                        });
                      }}
                    />
                  </div>
                </div>
                {/* <div className='row'>
                                <div className='col-md-12'>
                                    <GooglePlacesSearchInput value={this.state.place} onChange={(value)=>{this.onChange(value)}}/>
                                    <GoogleMaps 
                                    value={this.state.place} 
                                    onChange={(value)=>{this.onChange(value)}}
                                    draggable={true} height="300px"/>
                                </div>
                            </div>
                             */}
              </div>
            </div>
          </PageSection>
          <PageSection title="Logo & Images">
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="image"
                  label="Logo"
                  value={this.state.logo ? [{ ...this.state.logo }] : null}
                  onChange={(value) =>
                    this.setState({ logo: value.length > 0 ? value[0] : null })
                  }
                  maxImageCount={1}
                  {...this.props}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="image"
                  label="Images"
                  value={this.state.images}
                  onChange={(value) => this.setState({ images: value })}
                  maxImageCount={6}
                  {...this.props}
                />
              </div>
            </div>
          </PageSection>
          {this.props.config.WhiteLabel.Code === "PINCODE" && (
            <PageSection title="Delivery">
              <div className="row">
                {/* <div className="col-md-3">
                <Input
                  type="switch"
                  label="Allow Customer PickUp"
                  value={this.state.customerPickUp}
                  onChange={(value) => this.setState({ customerPickUp: value })}
                />
              </div> */}
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Input
                    type="select"
                    label="Delivery"
                    onChange={(value) => this.setState({ deliveryType: value })}
                    value={this.state.deliveryType}
                  >
                    <option>Please select</option>
                    <option value="self">Self Delivery</option>
                    <option value="shadowfax">Shadowfax</option>
                    {this.props.registration.registration
                      .EnablePincodeDelivery && (
                      <option value="pincode">Pincode</option>
                    )}
                    {/* <option value="ondc">ONDC Network Logistics</option> */}
                  </Input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Time To Ship</label>
                  <div className="timePicker">
                    <input
                      type="number"
                      onChange={(evt) => {
                        this.setState({ shipTime: evt.target.value });
                      }}
                      value={this.state.shipTime}
                      className="form-control"
                    ></input>
                    <select
                      onChange={(evt) => {
                        this.setState({ shipTimeType: evt.target.value });
                      }}
                      value={this.state.shipTimeType}
                      placeholder="DD"
                      className="form-control"
                    >
                      <option value="min">mins</option>
                      <option value="hr">hrs</option>
                      {this.props.registration.registration
                        .EnablePincodeDeliveryInDays && (
                        <option value="day">days</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Time To Deliver</label>
                  <div className="timePicker">
                    <input
                      type="number"
                      onChange={(evt) => {
                        this.setState({ deliveryTime: evt.target.value });
                      }}
                      value={this.state.deliveryTime}
                      className="form-control"
                    ></input>
                    <select
                      onChange={(evt) => {
                        this.setState({ deliveryTimeType: evt.target.value });
                      }}
                      value={this.state.deliveryTimeType}
                      placeholder="DD"
                      className="form-control"
                    >
                      <option value="min">mins</option>
                      <option value="hr">hrs</option>
                      {this.props.registration.registration
                        .EnablePincodeDeliveryInDays && (
                        <option value="day">days</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Input
                    type="number"
                    label="Delivery Radius (kms)"
                    onChange={(value) => {
                      if (value <= 50) {
                        this.setState({ deliveryRadius: value });
                      }
                    }}
                    value={this.state.deliveryRadius}
                  />
                </div>
              </div>
              {this.state.deliveryType === "self" &&
                this.props.registration.registration
                  .EnablePincodeDeliveryCharges && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          type="number"
                          label="Delivery Charge"
                          onChange={(value) =>
                            this.setState({ deliveryCharge: value })
                          }
                          value={this.state.deliveryCharge}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
            </PageSection>
          )}
          <PageSection title="Settings">
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="switch"
                  label="Accept online orders"
                  onChange={(value) =>
                    this.setState({ acceptOnlineOrders: value })
                  }
                  value={this.state.acceptOnlineOrders}
                />
                {this.state.acceptOnlineOrders && (
                  <React.Fragment>
                    <Input
                      type="switch"
                      label="Auto accept online orders"
                      onChange={(value) =>
                        this.setState({ autoAcceptOnlineOrders: value })
                      }
                      value={this.state.autoAcceptOnlineOrders}
                    />
                    {/* <Input
                      type="switch"
                      label="Display product availability online based on the 'Out of Stock' attribute, without considering inventory counts."
                      onChange={(value) =>
                        this.setState({
                          showProductsOnlineAvailabilityBasedOnOutOfStock: value,
                        })
                      }
                      // disabled={true}
                      value={
                        this.state
                          .showProductsOnlineAvailabilityBasedOnOutOfStock
                      }
                    /> */}
                  </React.Fragment>
                )}
              </div>
              <div className="col-md-3">
                {/* <Input
                  type="select"
                  label="Domain"
                  value={this.state.storeDomainID}
                  onChange={(value) => this.onStoreDomainSelected(value)}
                  error={this.state.storeDomainError}
                >
                  <option value="">Please select domain</option>
                  {this.state.domain.map((b) => (
                    <option value={b.id}>{b.value}</option>
                  ))}
                </Input>
                <Input
                  type="select"
                  label="Store Type"
                  value={this.state.storeTypeID}
                  onChange={(value) => this.onStoreTypeSelected(value)}
                  error={this.state.storeTypeError}
                >
                  <option value="">Please select store type</option>
                  {this.state.storeTypes.map((c) => (
                    <option value={c.id}>{c.value}</option>
                  ))}
                </Input> */}
                {/* <Input
                  type="text"
                  label="Merchant Hash Key"
                  value={this.state.pincodeHashKey}
                  onChange={(value) =>
                    this.setState({
                      pincodeHashKey: value,
                      pincodeHashKeyError: "",
                    })
                  }
                  error={this.state.pincodeHashKeyError}
                /> */}
              </div>
            </div>
            {this.props.config.WhiteLabel.Code !== "PINCODE" && (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="switch"
                      label="Enable cash register closing"
                      onChange={(value) =>
                        this.setState({ cashRegisterClosingSelected: value })
                      }
                      value={this.state.cashRegisterClosingSelected}
                    />
                  </div>
                  {this.state.cashRegisterClosingSelected && (
                    <div className="col-md-3">
                      <Input
                        type="switch"
                        label="Show product sale details during cash register closing"
                        onChange={(value) =>
                          this.setState({
                            cashRegisterClosingIncludeProductSaleDetails: value,
                          })
                        }
                        value={
                          this.state
                            .cashRegisterClosingIncludeProductSaleDetails
                        }
                      />
                    </div>
                  )}
                  <div className="col-md-3">
                    <Input
                      type="switch"
                      label="Negative product qty not allowed for billing"
                      onChange={(value) =>
                        this.setState({
                          negativeProductQtyNotAllowedForBilling: value,
                        })
                      }
                      value={this.state.negativeProductQtyNotAllowedForBilling}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="switch"
                      label="Change qty on price update"
                      onChange={(value) =>
                        this.setState({ changeQtyOnPriceUpdate: value })
                      }
                      value={this.state.changeQtyOnPriceUpdate}
                    />
                  </div>
                  {this.state.urbanPiperIntegration && (
                    <div className="col-md-3">
                      <Input
                        type="switch"
                        label="Enable food delivery platforms"
                        onChange={(value) =>
                          this.setState({ enableFoodDeliveryPlatforms: value })
                        }
                        value={this.state.enableFoodDeliveryPlatforms}
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="select"
                      label="Sales default product search"
                      value={this.state.salesProductSearch}
                      onChange={(value) =>
                        this.setState({ salesProductSearch: value })
                      }
                    >
                      <option value="1">Search mode</option>
                      <option value="2">Scan mode</option>
                    </Input>
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="select"
                      label="Inventory default product search"
                      value={this.state.inventoryProductSearch}
                      onChange={(value) =>
                        this.setState({ inventoryProductSearch: value })
                      }
                    >
                      <option value="1">Search mode</option>
                      <option value="2">Scan mode</option>
                    </Input>
                  </div>
                  {this.props.registration.registration.hasOwnProperty("KOT") &&
                    this.props.registration.registration.KOT && (
                      <div className="col-md-3">
                        <Input
                          type="text"
                          label="KOT Printer Name"
                          onChange={(value) =>
                            this.setState({ kotPrinter: value })
                          }
                          value={this.state.kotPrinter}
                        />
                      </div>
                    )}
                  <div className="col-md-3">
                    <Input
                      type="select"
                      label="Sales screen option"
                      value={this.state.desktopOption}
                      onChange={(value) =>
                        this.setState({ desktopOption: value })
                      }
                    >
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                      <option value="3">Option 3</option>
                    </Input>
                  </div>
                </div>
                {this.props.registration.registration
                  .BillingCustomerRestriction && (
                  <div className="row">
                    <div className="col-md-3">
                      <Input
                        type="switch"
                        label="Disable customer creation and edit"
                        onChange={(value) =>
                          this.setState({
                            disableCustomerCreationAndEdit: value,
                          })
                        }
                        value={this.state.disableCustomerCreationAndEdit}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="switch"
                      label="Enable Table Order"
                      onChange={(value) =>
                        this.setState({ enableTableOrder: value })
                      }
                      value={this.state.enableTableOrder}
                    />
                  </div>
                  {this.state.enableTableOrder && (
                    <div className="col-md-3">
                      <Input
                        type="select"
                        label="Transaction Type"
                        value={this.state.tableOrderTransactionTypeID}
                        onChange={(value) => {
                          this.setState({ tableOrderTransactionTypeID: value });
                        }}
                      >
                        <option value="">Select transaction type</option>
                        {this.props.config.TransactionTypes.filter(
                          (e) => !e.IsDeleted && e.Module === "sales"
                        ).map((t) => (
                          <option value={t.key}>{t.TransactionType}</option>
                        ))}
                      </Input>
                    </div>
                  )}
                </div>
                {this.state.enableFoodDeliveryPlatforms && (
                  <div className="row">
                    <div className="col-md-2">
                      <Input
                        type="number"
                        label="Min Order Value"
                        onChange={(value) =>
                          this.setState({ minOrderValue: value })
                        }
                        value={this.state.minOrderValue}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="number"
                        label="Min Pickup Time"
                        onChange={(value) =>
                          this.setState({ minPickUptime: value })
                        }
                        value={this.state.minPickUptime}
                        suffixText="mins"
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="number"
                        label="Min Delivery Time"
                        onChange={(value) =>
                          this.setState({ minDeliveryTime: value })
                        }
                        value={this.state.minDeliveryTime}
                        suffixText="mins"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Delivery Platforms</th>
                              <th>Price Markup (%)</th>
                              <th>Transaction Type</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.foodDeliveryPlatforms.map(
                              (m, index) => (
                                <tr>
                                  <td>
                                    <Input
                                      type="checkbox"
                                      value={m.Selected}
                                      onChange={() =>
                                        this.onDeliveryPlatformSelected(index)
                                      }
                                    />
                                  </td>
                                  <td>{m.Name}</td>
                                  <td>
                                    {m.Selected && (
                                      <Input
                                        type="number"
                                        onChange={(value) =>
                                          this.onDeliveryPlatformPriceMarkUpChange(
                                            index,
                                            value
                                          )
                                        }
                                        value={m.PriceMarkUp}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {m.Selected && (
                                      <Input
                                        type="select"
                                        value={m.TransactionTypeID}
                                        onChange={(value) =>
                                          this.onDeliveryPlatformTransactionTypeChange(
                                            index,
                                            value
                                          )
                                        }
                                      >
                                        <option value="">Select</option>
                                        {this.state.salesTransactionTypes.map(
                                          (m) => (
                                            <option value={m.key}>
                                              {m.TransactionType}
                                            </option>
                                          )
                                        )}
                                      </Input>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </PageSection>
          <PageSection title="Store Timing">
            <div className="row">
              <div className="col-md-8">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "100px" }}>Day</th>
                      <th style={{ width: "50px" }}>Open</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.storeTiming.map((m, index) => (
                      <tr>
                        <td>{m.Day}</td>
                        <td>
                          <Input
                            type="checkbox"
                            value={m.Open}
                            onChange={() => this.onStoreTimingOpenChange(index)}
                          />
                        </td>
                        <td>
                          {m.Open && (
                            <TimePicker
                              time={m.StartTime}
                              onChange={(time) =>
                                this.onStoreTimingStartTimeChange(time, index)
                              }
                            />
                          )}
                        </td>
                        <td>
                          {m.Open && (
                            <TimePicker
                              time={m.EndTime}
                              onChange={(time) =>
                                this.onStoreTimingEndTimeChange(time, index)
                              }
                            />
                          )}
                          {index === 0 && m.Open && (
                            <button
                              style={{
                                marginTop: "10px",
                              }}
                              type="button"
                              className="btn btn-md btn-default btn-lg btn-flat"
                              onClick={() => {
                                let storeTiming = this.state.storeTiming;
                                for (let i = 1; i < storeTiming.length; i++) {
                                  storeTiming[i].Open = m.Open;
                                  storeTiming[i].StartTime = m.StartTime;
                                  storeTiming[i].EndTime = m.EndTime;
                                }
                                this.setState({ storeTiming: storeTiming });
                              }}
                            >
                              Apply To All
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </PageSection>
          {this.props.config.WhiteLabel.Code !== "PINCODE" && (
            <PageSection title="Additonal Information">
              <div className="row">
                <div className="col-md-3">
                  <Input
                    type="text"
                    label={this.state.customField1DisplayName}
                    onChange={(value) => this.setState({ customField1: value })}
                    value={this.state.customField1}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label={this.state.customField2DisplayName}
                    onChange={(value) => this.setState({ customField2: value })}
                    value={this.state.customField2}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label={this.state.customField3DisplayName}
                    onChange={(value) => this.setState({ customField3: value })}
                    value={this.state.customField3}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label={this.state.customField4DisplayName}
                    onChange={(value) => this.setState({ customField4: value })}
                    value={this.state.customField4}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Input
                    type="text"
                    label={this.state.customField5DisplayName}
                    onChange={(value) => this.setState({ customField5: value })}
                    value={this.state.customField5}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label={this.state.customField6DisplayName}
                    onChange={(value) => this.setState({ customField6: value })}
                    value={this.state.customField6}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="Google Review Link"
                    onChange={(value) =>
                      this.setState({ googleReviewLink: value })
                    }
                    value={this.state.googleReviewLink}
                  />
                </div>
              </div>
            </PageSection>
          )}
        </Page>
      </React.Fragment>
    );
  }
}

export default withRouter(AddEditStore);
