import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import AddEditTables from './AddEditTables'

class Tables extends Component {
    constructor() {
        super()
        this.state = {
            showModal: false,
            searchActiveInactiveAllRecords: 'active',
            tables: [],
            saving: false,
            selectedTable: null
        }
        this.onTableDelete = this.onTableDelete.bind(this)
        this.loadTables = this.loadTables.bind(this)
    }

    componentDidMount() {
        this.loadTables(this.state.searchActiveInactiveAllRecords)
    }


    loadTables(option) {
        this.props.getTables(this.props.user.store.key).then((tableList) => {
            console.log(tableList);
            let allTables = tableList
            let tables = [];
            if (option === "all") {
                tables = allTables
            }
            else if (option === "active") {
                tables = allTables.filter((e) => !e.IsDeleted)
            }
            else if (option === "inactive") {
                tables = allTables.filter((e) => e.IsDeleted)
            }
            this.setState({ loading: false, tables: tables, searchActiveInactiveAllRecords: option })
        })
    }

    onTableDelete(key) {
        this.props.deleteTable(key).then(() => {
            this.props.getToastr("Table deleted successfully")
            this.loadTables(this.state.searchActiveInactiveAllRecords)
        })
    }

    render() {
        const btnColStyle = { width: "120px" }

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Tables</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <button
                                                onClick={() => {
                                                    this.setState({ showModal: true, selectedTable: null })
                                                }}
                                                className="btn btn-success btn-md btn-flat"
                                            >Add New Table</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadTables("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadTables("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadTables("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <React.Fragment>
                                    {this.state.tables &&
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Table</th>
                                                    <th>Sequence</th>
                                                    <th>Revision History</th>
                                                    <th style={btnColStyle}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.tables.map((table, index) =>
                                                    <tr key={index}>
                                                        <td>{table.Table}</td>
                                                        <td>{table.SequenceNo}</td>
                                                        <td>
                                                            {table.hasOwnProperty('Action') && <React.Fragment>
                                                                <div>Action :{table.Action}</div>
                                                                <div>Action By :{table.ActionBy}</div>
                                                                <div>Action Date :{(new Date(table.ActionOn.seconds * 1000)).toString().substring(0, 24)}</div>
                                                                <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={table.key} />
                                                            </React.Fragment>}
                                                        </td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {table.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> :
                                                                    <div>
                                                                        <button onClick={() => {
                                                                            this.setState({
                                                                                showModal: true,
                                                                                selectedTable: table
                                                                            });
                                                                        }}
                                                                            type="button" className="btn btn-md btn-flat btn-default "><i className="fa fa-edit"></i></button>
                                                                        <Confirm
                                                                            onConfirm={() => this.onTableDelete(table.key)}
                                                                            body="Are you sure you would like to delete this table?"
                                                                            confirmText="Yes"
                                                                            title="Table Delete">
                                                                            <button className="btn btn-flat btn-default"><i className="fa fa-trash-o"></i></button>
                                                                        </Confirm>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>}
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                <div style={{ width: "500px" }}>
                    <AddEditTables
                        onClose={() => this.setState({ showModal: false })}
                        onSave={() => {
                            this.loadTables(this.state.searchActiveInactiveAllRecords)
                            this.setState({ showModal: false })
                        }
                        }
                        table={this.state.selectedTable}
                        {...this.props} />
                </div>
            </Modal>
        </div>
    }
}

export default withRouter(Tables)