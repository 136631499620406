import firebase,{database} from '../firebase'
import * as constants from '../Constatnts'


export function getPackages(registrationID, storeID, from, size, option, 
    packageName,transactionNo, searchText) {
  let searchParameters = {
    "from": from,
    "size": size,
    "track_total_hits": true,
    "query": {
      "bool": {
        "must": [
          { "term": { "RegistrationID": registrationID } },
          { "term": { "StoreID": storeID } }
        ],
        "filter": {
          "bool": {
            "should": [

            ],
            "minimum_should_match": 1
          }
        }
      }
    },
    "sort": [
      { "ActionOn": { "order": "desc" } }
  ]
  }
  if (option.trim() !== "")
    searchParameters.query.bool.must.push({ "match_phrase": { "PackageStatus": option } })
  if (packageName.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "PackageName": packageName.trim() } })
  }
  if (transactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": transactionNo.trim() } })
  }
  if (searchText.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerName": searchText.trim() } })
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerEmailID": searchText.trim() } })
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerPhoneNo": searchText.trim() } })
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerCode": searchText.trim() } })
  }
  
   console.log(JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getPackages');

    return addMessage({ text: searchParameters }).then(function (result) {
      const packages = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        packages.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: packages }
    });
  }
}

export function getPackageDetails(key){
    return (dispatch) => {
    return database.collection("Packages").doc(key).get().then((doc) => {
        if (doc.exists) {
           return doc.data()
        } else {
            console.log("No such package found!");
        }
    })
    }
}

export function updatePackage(objPackage, key){
  return (dispatch, getState) => {
    objPackage.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
    objPackage.ActionBy = getState().user.user.Name
    objPackage.ActionByUID = getState().user.user.UID
    objPackage.ActionByEmailID = getState().user.user.EmailID

    objPackage.Action = constants.AUDIT_TRAIL_EDIT
    // console.log("updatePackage", JSON.stringify(objPackage));
      return database.collection("Packages").doc(key).update({
        ...objPackage
      })
  }
}

// export function updatePackage(objPackage, key){
//   return (dispatch, getState) => {
//     objPackage.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
//     objPackage.ActionBy = getState().user.user.Name
//     objPackage.ActionByUID = getState().user.user.UID
//     objPackage.ActionByEmailID = getState().user.user.EmailID

//     objPackage.Action = constants.AUDIT_TRAIL_EDIT
//     console.log("updatePackage", JSON.stringify(objPackage));
//       return database.collection("Packages").doc(key).update({
//         ...objPackage
//       })
//   }
// }

export function getCustomerPackages(customerID) {
  let searchParameters = {
    "from": 0,
    "size": 10,
    "track_total_hits": true,
    "query": {
      "bool": {
        "must": [
          { "match": { "CustomerID": customerID } }
        ]
      }
    },
    "sort": [
      { "PackageStartDate": { "order": "desc" } }
    ]
  }

  // console.log(JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getPackages');

    return addMessage({ text: searchParameters }).then(function (result) {
      const packages = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        packages.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: packages }
    });
  }
}