import React, { Component } from 'react'
import './ListSearch.css'

class ListSearch extends Component {

    constructor() {
        super()
    }

    render() {
        return <div className="list-search">
            <div className="list-search-div">
                <span><i className="fa fa-search fa-2x" /></span>
                <input
                    placeholder={this.props.searchPlaceHolder}
                    onChange={(evt) => { this.props.onChange(evt.target.value); }}
                    value={this.props.value}
                />
                {this.props.value.length > 0 && <i className="fa fa-times fa-2x"
                    onClick={() => { this.props.onChange(""); }} />}
            </div>
        </div>
    }
}

export default ListSearch