import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getCoupons(registrationID)
{
    return (dispatch) => {
        return database.collection("Coupons").where("RegistrationID","==",registrationID).get().then((querySnapshot) => {        
            const coupons = []
            querySnapshot.forEach((doc) => {
                coupons.push(
                  {
                    key:doc.id,...doc.data()
                  }
                )
           })
        
            return coupons
        }).catch((error) => {console.log(error)})
    }
}

export function saveCoupon(coupon, key){
    return (dispatch, getState) => {
        coupon.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        coupon.ActionBy = getState().user.user.Name
        coupon.ActionByUID = getState().user.user.UID
        coupon.ActionByEmailID = getState().user.user.EmailID

        if(key !== "")
        {
            coupon.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Coupons").doc(key).update({
                ...coupon
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating coupon: ", error);
              })
       }
        else
        {
            coupon.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Coupons").add({
                ...coupon
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding coupon: ", error);
              })
        }
    

    }
}

export function deleteCoupon(key)
{
    return (dispatch, getState) => {
        return database.collection("Coupons").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted : true
        }).then(()=> {return true})
    }
}

export function getCouponsDetails(key){
    return dispatch => {
    return database.collection("Coupons").doc(key).get().then((doc) => {
        if (doc.exists) {
            console.log("Coupon found", doc.data());
           return doc.data()
        } else {
            console.log("No such coupon!");
        }
    })
    }
}

export function getApplicableCoupons(registrationID)
{
    return (dispatch,getState) => {
        return database.collection("Coupons").where("RegistrationID","==",registrationID).where("IsDeleted","==",false).get().then((querySnapshot) => {        
            const coupons = []
            querySnapshot.forEach((doc) => {
                coupons.push({
                    key:doc.id,...doc.data()
                  })
           })
            let applicableCoupons = [];
            var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            if (coupons.length > 0) {
                for (let index = 0; index < coupons.length; index++) {
                    const coupon = coupons[index];

                    if (coupon.AllStores) // For All Stores
                    {
                        console.log(coupon.Name + "  " + new Date(coupon.ValidityFrom));
                        if (new Date(coupon.ValidityFrom).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) <= new Date(coupon.ValidityTo).setHours(0, 0, 0, 0)) // Validity Check
                        {
                            if (coupon.SpecificDay != "" && coupon.SpecificDay.toLowerCase() === "yes")// Specific Day Selected
                            {
                                if (coupon.SpecificDays.includes(days[new Date().getDay()])) // Check Specific Day
                                {
                                    applicableCoupons.push(coupon);                                    
                                }
                            }  
                            else
                            {
                                applicableCoupons.push(coupon);
                            }                         
                        }                        
                    }                    
                    else {
                        for (let index = 0; index < coupon.Stores.length; index++) {

                            const store = coupon.Stores[index];

                            if (store.value === getState().user.store.key) {
                                if (new Date(coupon.ValidityFrom).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) <= new Date(coupon.ValidityTo).setHours(0, 0, 0, 0)) // Validity Check
                                {
                                    if (coupon.SpecificDay != "" && coupon.SpecificDay.toLowerCase() === "yes")// Specific Day Selected
                                    {
                                        if (coupon.SpecificDays.includes(days[new Date().getDay()])) // Check Specific Day
                                        {
                                            applicableCoupons.push(coupon);                                            
                                        }
                                    }
                                    else{
                                        applicableCoupons.push(coupon);
                                    }    
                                }
                            }
                        }
                    }
                }
            }
            console.log(applicableCoupons);
            return applicableCoupons;
        }).catch((error) => {console.log(error)})
    }
}

