import React from 'react'
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Input from '../2/Controls/Input';
import {isMobileApp} from '../../actions/Util';

class CustomFields extends React.Component {
    constructor() {
        super()
        this.state = {
            moduleName: '',
            customField1Name: '',
            customField2Name: '',
            customField3Name: '',
            customField4Name: '',
            customField5Name: '',
            customField6Name: '',
            customField7Name: '',
            customField8Name: '',
            customField9Name: '',
            customField10Name: '',
            customField11Name: '',
            customField12Name: '',
            customField13Name: '',
            customField14Name: '',
            customField15Name: '',
            customField16Name: '',
            customField17Name: '',
            customField18Name: '',
            customField19Name: '',
            customField20Name: '',
            key: '',
            saving: false
        }
        this.onModuleNameChange = this.onModuleNameChange.bind(this)
        this.onSaveCustomFields = this.onSaveCustomFields.bind(this)
    }

    onModuleNameChange(value){
        let moduleName = value;
        this.setState({ moduleName: moduleName })
        if (moduleName.trim() !== "") {
            this.setState({
                customField1Name: '',
                customField2Name: '',
                customField3Name: '',
                customField4Name: '',
                customField5Name: '',
                customField6Name: '',
                customField7Name: '',
                customField8Name: '',
                customField9Name: '',
                customField10Name: '',
                customField11Name: '',
                customField12Name: '',
                customField13Name: '',
                customField14Name: '',
                customField15Name: '',
                customField16Name: '',
                customField17Name: '',
                customField18Name: '',
                customField19Name: '',
                customField20Name: '',
                key: ''
            })
            //get additional field names from db
            this.props.getCustomFieldNames(this.props.user.user.RegistrationID, moduleName).then((customFieldNames) => {
                // console.log('before', customFieldNames)
                if (!customFieldNames) { return }
                // console.log('after', customFieldNames)

                this.setState({
                    customField1Name: customFieldNames.CustomField1Name,
                    customField2Name: customFieldNames.CustomField2Name,
                    customField3Name: customFieldNames.CustomField3Name,
                    customField4Name: customFieldNames.CustomField4Name,
                    customField5Name: customFieldNames.CustomField5Name,
                    customField6Name: customFieldNames.CustomField6Name,
                    customField7Name: customFieldNames.hasOwnProperty('CustomField7Name')? customFieldNames.CustomField7Name : '',
                    customField8Name: customFieldNames.hasOwnProperty('CustomField8Name')? customFieldNames.CustomField8Name : '',
                    customField9Name: customFieldNames.hasOwnProperty('CustomField9Name')? customFieldNames.CustomField9Name : '',
                    customField10Name: customFieldNames.hasOwnProperty('CustomField10Name')? customFieldNames.CustomField10Name : '',
                    customField11Name: customFieldNames.hasOwnProperty('CustomField11Name')? customFieldNames.CustomField11Name : '',
                    customField12Name: customFieldNames.hasOwnProperty('CustomField12Name')? customFieldNames.CustomField12Name : '',
                    customField13Name: customFieldNames.hasOwnProperty('CustomField13Name')? customFieldNames.CustomField13Name : '',
                    customField14Name: customFieldNames.hasOwnProperty('CustomField14Name')? customFieldNames.CustomField14Name : '',
                    customField15Name: customFieldNames.hasOwnProperty('CustomField15Name')? customFieldNames.CustomField15Name : '',
                    customField16Name: customFieldNames.hasOwnProperty('CustomField16Name')? customFieldNames.CustomField16Name : '',
                    customField17Name: customFieldNames.hasOwnProperty('CustomField17Name')? customFieldNames.CustomField17Name : '',
                    customField18Name: customFieldNames.hasOwnProperty('CustomField18Name')? customFieldNames.CustomField18Name : '',
                    customField19Name: customFieldNames.hasOwnProperty('CustomField19Name')? customFieldNames.CustomField19Name : '',
                    customField20Name: customFieldNames.hasOwnProperty('CustomField20Name')? customFieldNames.CustomField20Name : '',
                    key: customFieldNames.key
                })
            })
        } else {
            this.setState({
                customField1Name: '',
                customField2Name: '',
                customField3Name: '',
                customField4Name: '',
                customField5Name: '',
                customField6Name: '',
                customField7Name: '',
                customField8Name: '',
                customField9Name: '',
                customField10Name: '',
                customField11Name: '',
                customField12Name: '',
                customField13Name: '',
                customField14Name: '',
                customField15Name: '',
                customField16Name: '',
                customField17Name: '',
                customField18Name: '',
                customField19Name: '',
                customField20Name: '',
                key: ''
            })
        }
    };

    onSaveCustomFields() {
        this.setState({ saving: true })
        //check if  code and  name is entered 
        if (this.state.moduleName.trim() === "") {
            alert('Please select module')
        }
        let customFields = {
            ModuleName: this.state.moduleName,
            CustomField1Name: this.state.customField1Name,
            CustomField2Name: this.state.customField2Name,
            CustomField3Name: this.state.customField3Name,
            CustomField4Name: this.state.customField4Name,
            CustomField5Name: this.state.customField5Name,
            CustomField6Name: this.state.customField6Name,
            CustomField7Name: this.state.customField7Name,
            CustomField8Name: this.state.customField8Name,
            CustomField9Name: this.state.customField9Name,
            CustomField10Name: this.state.customField10Name,
            CustomField11Name: this.state.customField11Name,
            CustomField12Name: this.state.customField12Name,
            CustomField13Name: this.state.customField13Name,
            CustomField14Name: this.state.customField14Name,
            CustomField15Name: this.state.customField15Name,
            CustomField16Name: this.state.customField16Name,
            CustomField17Name: this.state.customField17Name,
            CustomField18Name: this.state.customField18Name,
            CustomField19Name: this.state.customField19Name,
            CustomField20Name: this.state.customField20Name,
            RegistrationID: this.props.user.user.RegistrationID
        }
        this.props.saveCustomFieldNames(customFields, this.state.key).then(() => {
            this.props.getToastr("Custom Field Saved Successfully")
            this.setState({ saving: false })
            this.props.getAllCustomFieldNames(this.props.user.user.RegistrationID)
        })
    }

    render() {

        return <Page 
            title={"Custom Fields"} 
            showSaveButton={true} 
            showCancelButton={true}
            processing={this.state.saving}
            onClick={(action)=>{
                if(action==="cancel"){
                    this.onModuleNameChange(this.state.moduleName);
                    if(isMobileApp()){
                        this.props.history.push("/home")
                    }
                }else if(action==="save"){
                    this.onSaveCustomFields();
                }
            }}
            >
                <PageSection title="">
                    <Input
                        type="select"
                        label="Module"
                        value={this.state.moduleName}
                        onChange={this.onModuleNameChange}
                    >
                            <option value="">Select module</option>
                            <option value="Customer">Customer</option>
                            <option value="Employee">Employee</option>
                            <option value="Product">Product</option>
                            <option value="Sales">Sales</option>
                            <option value="Store">Store</option>
                            <option value="Vendor">Vendor</option> 
                    </Input>
                    {this.state.moduleName.trim() !== "" && <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Custom Field</th>
                                <th>Display Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Custom Field 1</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField1Name: evt.target.value })}
                                        value={this.state.customField1Name} />
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Field 2</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField2Name: evt.target.value })}
                                        value={this.state.customField2Name} />
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Field 3</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField3Name: evt.target.value })}
                                        value={this.state.customField3Name} />
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Field 4</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField4Name: evt.target.value })}
                                        value={this.state.customField4Name} />
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Field 5</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField5Name: evt.target.value })}
                                        value={this.state.customField5Name} />
                                </td>
                            </tr>
                            <tr>
                                <td>Custom Field 6</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField6Name: evt.target.value })}
                                        value={this.state.customField6Name} />
                                </td>
                            </tr>
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 7</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField7Name: evt.target.value })}
                                        value={this.state.customField7Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 8</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField8Name: evt.target.value })}
                                        value={this.state.customField8Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 9</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField9Name: evt.target.value })}
                                        value={this.state.customField9Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 10</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField10Name: evt.target.value })}
                                        value={this.state.customField10Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 11</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField11Name: evt.target.value })}
                                        value={this.state.customField11Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 12</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField12Name: evt.target.value })}
                                        value={this.state.customField12Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 13</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField13Name: evt.target.value })}
                                        value={this.state.customField13Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 14</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField14Name: evt.target.value })}
                                        value={this.state.customField14Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 15</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField15Name: evt.target.value })}
                                        value={this.state.customField15Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 16</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField16Name: evt.target.value })}
                                        value={this.state.customField16Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 17</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField17Name: evt.target.value })}
                                        value={this.state.customField17Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 18</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField18Name: evt.target.value })}
                                        value={this.state.customField18Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 19</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField19Name: evt.target.value })}
                                        value={this.state.customField19Name} />
                                </td>
                            </tr>}
                            {(this.state.moduleName && (this.state.moduleName.toLowerCase() ==="sales" || this.state.moduleName.toLowerCase() ==="sales - line item" )) && <tr>
                                <td>Custom Field 20</td>
                                <td>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField20Name: evt.target.value })}
                                        value={this.state.customField20Name} />
                                </td>
                            </tr>}
                        </tbody>
                    </table>}
                </PageSection>
            </Page>
    }
}

export default CustomFields