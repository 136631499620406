import React, { Component } from "react";
import { connect } from "react-redux";
import Window from '../2/Controls/Window';
import Input from "../2/Controls/Input";

class SalesNotes extends Component {
    constructor() {
        super();
        this.state = {
            notes: "",
        };
    }

    componentDidMount() {
        this.setState({ notes: this.props.notes });
    }

    render(){
        return <Window 
                header={"Notes"}  
                height={"300px"}
                modal={true}
                processing={this.state.saving}
                onClose={this.props.onClose}
                onButtonClick={(buttonType)=>{
                    if(buttonType.toLowerCase()==="ok"){this.props.onOK(this.state.notes);}
                    else{this.props.onClose()}
                }}
                buttons={[
                    {Type:"secondary", Text:"Cancel", Align:"left"},
                    {Type:"primary", Text:"OK", Align:"right"},
                ]}
                >
                    <Input 
                    error={this.state.error}
                    type="textarea"
                     onChange={(value) =>this.setState({notes: value})}
                     value={this.state.notes} />
            </Window>
    }
}

const mapStateToProps = (state) => ({
    user: state.user
  });
  
  export default connect(mapStateToProps, {})(SalesNotes);