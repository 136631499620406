// import { auth, googleProvider, twitterProvider, facebookProvider } from '../firebase';
import * as actionTypes from '../actionTypes';
import firebase, {database} from '../firebase'

export function getUserACL(uid)
{
    return async (dispatch) => {
            let addMessage = firebase.functions().httpsCallable('getUserACL');
            return addMessage().then(function (result) {
            let acl = result.data.text;
            //let acl = getUserACLTmp(uid);
            dispatch({type:actionTypes.SET_ACL,acl});
            });
    }
}


async function getUserACLTmp(uid)
{
    let user = await GetUserRole(uid);
    let modules = {}
    if(user.hasOwnProperty('PlanID') && user.PlanID !== '')
    {
        if (user.Role === "Power User") {
            modules = await GetACLModulesForARole(user.UID, user.RegistrationID, "***");
        }
        else {
            modules = await GetACLModulesForARole(user.UID, user.RegistrationID, user.Stores[0].StoreKey);
        }
    }
    else {
        if (user.Role === "Power User")
            modules = await GetACLModulesForPowerUser(user.Role);
        else if (user.Role === "MSwipe User") {
            let stores = await getAllStores(user.RegistrationID);
            modules = await GetACLModulesForARole(user.UID, user.RegistrationID, stores[0].key);
        }
        else
            modules = await GetACLModulesForARole(user.UID, user.RegistrationID, user.Stores[0].StoreKey);
    }
    return { text: modules };
}

async function GetUserRole(uid) {
    return database.collection("User").where("UID", "==", uid).limit(1).get().then((querySnapshot) => {
        let user = null
        querySnapshot.forEach((doc) => {
            user = doc.data();
        });
        return user;
    });
}

async function GetACLModulesForPowerUser(role) {
    return database.collection('GlobalConfigurations').doc("_" + role).collection('aclModules').get().then((querySnapshot) => {
        let modules = [];
        querySnapshot.forEach((doc) => {
            let module = {};
            module.id = doc.id;
            module.perms = doc.data().perms;
            modules.push(module);
        })
        return modules;
    });
}

async function GetACLModulesForARole(uid, registrationID, storeID) {
    // console.log("uid", uid)
    // console.log("registrationID", registrationID)
    // console.log("storeID", storeID)

    return database.collection('ACL').doc(uid).collection('aclRegistrations').doc(registrationID).collection('aclStores').doc(storeID).collection("aclModules").get().then((querySnapshot) => {
        let modules = [];
        querySnapshot.forEach((doc) => {
            let module = {};
            module.id = doc.id;
            module.perms = doc.data().perms;
            modules.push(module);
        })
        // console.log("Modules", JSON.stringify(modules));
        return modules;
    });
}

async function getAllStores(registrationID) {
    console.log("getAllStores", registrationID);
    return database.collection("Stores").where("RegistrationID", "==", registrationID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
        let stores = []
        querySnapshot.forEach((doc) => {
            let store = doc.data()
            store.key = doc.id
            stores.push(store)
        })
        // console.log(JSON.stringify(stores));
        return stores
    })
}

function checkUserACLInLocalStorage()
{
    let acl = JSON.parse(localStorage.getItem('acl'))
    console.log('checkUserACLInLocalStorage', acl)
    if(acl)
    {
        return acl
    }
    else{
        return null
    }
}

function setUserACLInLocalStorage(acl)
{
    console.log('setUserACLInLocalStorage', acl)
    localStorage.setItem("acl", JSON.stringify(acl));
}

export function updateUser(user) {
    return (dispatch, getState) => {
        user.Action = "EDIT";
        user.ActionBy= getState().user.user.Name;
        user.ActionByEmailID= getState().user.user.EmailID;
        user.ActionByUID= getState().user.user.UID;
        
        let addMessage = firebase.functions().httpsCallable('updateUser');
        return addMessage({ text: user }).then(function (result) {
            return result
        });
    }
}

// export function getUsersForAStore() {
//     return (dispatch, getState) => {
//         return database.collection("User").where("RegistrationID", "==", getState().user.user.RegistrationID).get().then((querySnapshot) => {
//             const users = []
//             querySnapshot.forEach((doc) => {
//                 let user = doc.data();
//                 if(user.Role === "Power User")
//                 {
//                     users.push(
//                         {
//                             key: doc.id, ...user
//                         }
//                     )
//                 }
//                 else {
//                     for (let i = 0; i < user.Stores.length; i++) {
//                         const store = user.Stores[i];
                        
//                         if(store.StoreKey === getState().user.store.key)
//                         {
//                             users.push(
//                                 {
//                                     key: doc.id, ...user
//                                 }
//                             )
//                         }
//                     }
//                 }
//             })
//             // console.log("users", users);
//             return users
//         }).catch((error) => {
//             console.log("Error getting user list: ", error)
//         })
//     }
// }

export function setAuthUser(auth)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_AUTH, auth })
    }
}

export function signOutUser()
{
    return dispatch => {
        dispatch({type:actionTypes.SIGN_OUT})
    }
}


// export function getUserDetails(uid, login = false)
// {
//     return (dispatch) => {
//         console.log('uid',uid)
//         return database.collection("User").where("UID","==", uid).get().then((querySnapshot) => {
//             return querySnapshot.forEach((doc) => {
//                 const user =  doc.data();
//                 console.log('user',user)
//                 dispatch({type:actionTypes.SET_USER, user })
//                 // {id, EmailID , Name, RegistrationID, UID}=
//            })
//         }).catch((error) => {
//             console.log(error)
//         })
//     }
// }

export  function getUserDetails(UID, login = false)
{
    
    return async (dispatch) => {

        
            let addMessage = firebase.functions().httpsCallable('getUserDetails');
            return addMessage({ text: { UID: UID } }).then(function (result) {
                let user = result.data.text; 
                if(login)
                {
                    dispatch({ type: actionTypes.SET_USER, user })
                }
                else
                    return user;
            });
    }
}

function checkUserInLocalStorage(UID)
{
    //return null;
    let user = JSON.parse(localStorage.getItem('user'))
    console.log('checkUserInLocalStorage', user)
    if(user && user.UID ===UID)
    {
        return user
    }
    else{
        return null
    }
}

function setUserInLocalStorage(user)
{
    console.log('setUserInLocalStorage', user)
    localStorage.setItem("user", JSON.stringify(user));
}

export function createUser(user) {
    return (dispatch, getState) => {
        // user.Action = "New";
        // user.ActionOn= firebase.firestore.FieldValue.serverTimestamp();
        // user.ActionBy= getState().user.user.Name;
        // user.ActionByEmailID= getState().user.user.EmailID;
        // user.ActionByUID= getState().user.user.UID;
        
        // console.log("createUser", JSON.stringify(user));
        let addMessage = firebase.functions().httpsCallable('createUser');
        return addMessage({ text: user }).then(function (result) {
            // console.log('result',result)
            return result
        });
    }
}
export function setStore(store)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_STORE, store})
    }
}


export function setHasAccessToMultipleStores(hasAccessToMultipleStores)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_HAS_ACCESS_TO_MULTIPLE_STORES, hasAccessToMultipleStores})
    }
}

function setStoreInLocalStorage(store)
{
    console.log('setStoreInLocalStorage', store)
    localStorage.setItem("store", JSON.stringify(store));
}

export function setCashRegister(cashRegister)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_CASH_REGISTER, cashRegister})
    }
}

export function setCashRegisterClosing(cashRegisterClosing)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_CASH_REGISTER_CLOSING, cashRegisterClosing})
    }
}