import React from 'react'
import * as actionTypes from '../../actionTypes'
import Dropzone from 'react-dropzone'
import { SketchPicker } from 'react-color';
import Input from '../2/Controls/Input';
import Form from '../2/Controls/Form';
import Window from '../2/Controls/Window'; 

class AddEditProductCategory extends React.Component {
    constructor() {
        super()
        this.state = {
            parent: '',
            parentID: '',
            parentError: '',
            name: '',
            nameError: '',
            sequence: 0,
            color: '',
            image: null, 
            OMNIEnabled:false,
            key: '',
            type: '',
            imageLoading: false,
            saving: false,
            title:""
            // disableCategory:false
        }
        this.onSaveProductCategory = this.onSaveProductCategory.bind(this);
        this.onColorSelection = this.onColorSelection.bind(this);
        this.onImageRemove = this.onImageRemove.bind(this);
        this.onImageDrop = this.onImageDrop.bind(this);
        this.save = this.save.bind(this);
        this.renderControls = this.renderControls.bind(this);
    }

    componentDidMount() {

        if (this.props.productCategory) {
            let productCategory = this.props.productCategory
            // console.log('productCategory', productCategory)
            this.setState({
                parent: productCategory.Parent,
                parentID: productCategory.ParentID,
                name: productCategory.Name,
                sequence: productCategory.Sequence,
                color: productCategory.Color,
                image: productCategory.Image,
                key: productCategory.key,
                type: this.props.type,
                OMNIEnabled: productCategory.hasOwnProperty('OMNIEnabled') ? productCategory.OMNIEnabled : false,
                title:this.props.type  === actionTypes.PRODUCT_CATEGORY ? "Edit Category" : "Edit Sub Category"
            })
        }
        else {
            if (this.props.type === actionTypes.PRODUCT_CATEGORY) {
                this.setState({ parentID: "", 
                    parent: "", 
                    type: this.props.type,
                    title: "New Category"
                })
            } else {
                this.setState({ parentID: this.props.parentID, 
                    parent: this.props.parent, 
                    type: this.props.type,
                    title: "New Sub Category" 
                })
            }
        }
    }

    onColorSelection = (color) => {
        this.setState({ color: color.hex });
    };


    onImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 300) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ imageLoading: true })

            let fileName = + new Date().getTime() + acceptedFiles[0].name;
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/ProductCategory', fileName).then((downloadURL) => {
                //update product with images url

                let bucket = 'posible.in/'
                let folder = this.props.registration.registration.StorageFolder + '/ProductCategory/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + fileName

                let image = { Name: fileName, Size: acceptedFiles[0].size, Preview: url }
                this.setState({ image: image })
                //console.log('File available at', downloadURL);
                this.setState({ imageLoading: false })
            })
        }
        else { alert("No file selected") }
    }

    onImageRemove() {
        this.setState({ image: null })
    }

    onSaveProductCategory() {
        this.setState({ saving: true })
        if (this.state.type === actionTypes.PRODUCT_CATEGORY) {
            if (this.state.name.trim() === "") {
                this.setState({ nameError: "Please provide name", saving: false })
                return
            }
            //check id name is unique 
            this.props.checkIfProductCategoryIsUnique(this.state.name.trim().toLowerCase(), this.state.key, this.props.user.user.RegistrationID).then((isUnique) => {
                if (!isUnique) {
                    this.setState({ nameError: "Category already exits", saving: false })
                    return
                }
                this.save()
            })
        }
        if (this.state.type === actionTypes.PRODUCT_SUB_CATEGORY) {
            let nameError = ""
            let parentError = ""
            if (this.state.name.trim() === "") {
                nameError = "Please provide name"
            }
            if (this.state.parentID === "") {
                parentError = "Please select category"
            }
            if (parentError !== "" || nameError !== "") {
                this.setState({ nameError: nameError, parentError: parentError, saving: false })
                return
            }
            this.props.checkIfProductSubCategoryIsUnique(this.state.name.trim().toLowerCase(), this.state.parentID, this.state.key, this.props.user.user.RegistrationID).then((isUnique) => {
                if (!isUnique) {
                    this.setState({ nameError: "Sub category already exits", saving: false })
                    return
                }
                this.save()
            })
        }
    }

    save() {
        //check id name is unique 
        let productCategory = {
            Parent: this.state.parent.trim(),
            ParentID: this.state.parentID,
            Name: this.state.name.trim(),
            Sequence: this.state.sequence,
            Color: this.state.color,
            Image: this.state.image,
            RegistrationID: this.props.user.user.RegistrationID,
            IsDeleted: false,
            BackEndUpdate: false ,
            OMNIEnabled:this.state.OMNIEnabled
        }

        let omniTag = '', category = '', subCategory = ''

        if (this.state.type === actionTypes.PRODUCT_CATEGORY) {
            // omniTag = productCategory.Name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(' ','_');
            omniTag = productCategory.Name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/ /g,"_")
        }
        else {
            // category = productCategory.Parent.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/[' ']/g,'_');
            // subCategory = productCategory.Name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/[' ']/g,'_');
            
            category = productCategory.Parent.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/[' ']/g,'_');
            subCategory = productCategory.Name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/[' ']/g,'_');
            omniTag = category + '_' + subCategory
        }

        productCategory.OMNITag = omniTag;

        // console.log(JSON.stringify(productCategory));

        this.props.saveProductCategory(this.state.key, productCategory).then((key) => {
            
            

            setTimeout(async function () { //Start the timer
                if (this.state.type === actionTypes.PRODUCT_CATEGORY) 
                this.props.getToastr("Category Saved Successfully")
                else
                    this.props.getToastr("Sub Category Saved Successfully")

                this.setState({ saving: false })
                
                if (this.state.type === actionTypes.PRODUCT_CATEGORY) {
                    await this.props.getProductCategories(this.props.user.user.RegistrationID);
                }
                else {
                    await this.props.getProductSubCategories(this.props.user.user.RegistrationID)
                }
                this.props.onSave(key, this.state.name);
            }.bind(this), 3000)
        })
    }

    renderControls(){
        return <React.Fragment>
            {this.state.type === actionTypes.PRODUCT_SUB_CATEGORY && <Input
                type="select"
                label="Category"
                value={this.state.parentID}
                onChange={(value) => this.setState({
                    parentID: value,
                    parent: this.props.config.ProductCategories.filter(f=> f.key===value)[0].Name
                })}
                error = {this.state.parentError}
                onEnter={this.onSaveProductCategory}
            >
                <option value="">Select category</option>
                {this.props.config.ProductCategories.filter(f=> !f.IsDeleted).map((productCategory) =>
                    <option value={productCategory.key}>{productCategory.Name}</option>
                )}
            </Input>}
            <Input
                type="text"
                label={this.state.type === actionTypes.PRODUCT_CATEGORY ? "Category name" : "Sub Category name"}
                value={this.state.name}
                onChange={(value) => this.setState({ name: value, nameError: '' })}
                error={this.state.nameError}  
                onEnter={this.onSaveProductCategory}
            />
            <Input
                type="number"
                label="Sequence"
                onChange={(value) => this.setState({ sequence: Number(value) })}
                value={this.state.sequence}
            />

            {!this.state.imageLoading && <div className="form-group">
                <label>Image</label>
                {!this.state.image &&
                        <Dropzone onDrop={(files) => this.onImageDrop(files)}>
                            <div>Try dropping some files here, or click to select files to upload.</div>
                        </Dropzone>
                    }
                    {this.state.image && <div className="form-group">
                    <ul className="mailbox-attachments clearfix">
                        <li>
                            <span className="mailbox-attachment-icon has-img"><img src={this.state.image.Preview}
                                alt="Attachment" /></span>
                            <div className="mailbox-attachment-info">
                                <span className="mailbox-attachment-name">{this.state.image.Name.length > 20 ? this.state.image.Name.substr(0, 20) + "..." : this.state.image.Name}</span>
                                <span className="mailbox-attachment-size">
                                    {Math.round(this.state.image.Size / 1024)} KB
                                            <a onClick={this.onImageRemove}
                                        className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>}
            </div>}
            {this.state.imageLoading && <div>Uploading image</div>}
            <div className="form-group">
                <label >Color</label>
                <SketchPicker
                    color={this.state.color}
                    onChangeComplete={this.onColorSelection}
                />
            </div>
            <Input
                type="switch"
                label="Show Online"
                value={this.state.OMNIEnabled}
                onChange={(value) => { this.setState({ OMNIEnabled: value }) }}
            />
        </React.Fragment>
    }

    render() {

        return <React.Fragment>
            {!this.props.showWindow && <Form
                        title={this.state.title}
                        onClick={(action)=>{
                            if(action==="save"){
                                this.onSaveProductCategory();
                            }else{
                                this.props.onClose()
                            }
                        }}
                        processing={this.state.saving}
                    >
                        {this.renderControls()}
            </Form>}
            {this.props.showWindow && <Window
                header={this.state.title}
                height="500px"
                onButtonClick={(action)=>{
                    if(action==="Save"){
                        this.onSaveProductCategory();
                    }else{
                        this.props.onClose()
                    }
                }}
                buttons={[{Type:"secondary", Text:"Cancel", Align:"left"},
                    {Type:"primary", Text:"Save", Align:"right"},
                ]}
                processing={this.state.saving}
                onClose={()=>this.props.onClose()}
            >
                {this.renderControls()}
            </Window>}
        </React.Fragment>
    }
}

export default AddEditProductCategory
