import React from 'react'
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';

class AddEditPaymentTypes extends React.Component{
    constructor () {
        super()
        this.state={
            tempPaymentType:'',
            tempSequenceNo:'',
            tempSales:false, 
            tempExpense:false,
            tempIsSystemDefined:false,
            tempOTPVerification:false,
            tempPaymentTypeError :'',
            tempSalesExpenseError:'',
            tempSequenceNoError:'',
            key:'',
            saving: false
        }
        this.onSave = this.onSave.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    componentDidMount()
    {
        if(this.props.paymentType )
        {
            this.setState({
                tempPaymentType: this.props.paymentType.PaymentType, 
                tempSequenceNo: this.props.paymentType.SequenceNo,
                tempIsSystemDefined: this.props.paymentType.IsSystemDefined,
                tempSales:this.props.paymentType.ShowInSales,
                tempExpense: this.props.paymentType.ShowInExpense,
                tempOTPVerification: this.props.paymentType.hasOwnProperty('OTPVerification')? this.props.paymentType.OTPVerification : false,
                key: this.props.paymentType.key})
        }
    }
   
    onSave()
    {
        this.setState({saving:true})
        let tempPaymentTypeError = "" 
        let tempSequenceNoError= ""
        let tempSalesExpenseError = ""
        if(this.state.tempPaymentType.trim() ==="")
        {
            tempPaymentTypeError= 'Please provide payment type' 
        }
        if(this.state.tempSequenceNo.toString().trim() ==="")
        {
            tempSequenceNoError = 'Please provide sequence no'
        }
        if(!this.state.tempSales && !this.state.tempExpense)
        {
            tempSalesExpenseError="Please select at least sales or expense"
        }
        if(this.props.config.PaymentTypes.filter((e)=> !e.IsDeleted &&  e.key !==  (this.state.key !=='' ? this.state.key : e.key) && e.PaymentType.trim().toLowerCase()=== this.state.tempPaymentType.trim().toLowerCase() ).length >0)
        {
            tempPaymentTypeError= 'Payment type already exists' 
        }

        if(tempPaymentTypeError !=="" || tempSequenceNoError !=="" || tempSalesExpenseError!=="")
        {
            this.setState({saving:false,
            tempPaymentTypeError:tempPaymentTypeError,
            tempSequenceNoError:tempSequenceNoError, tempSalesExpenseError: tempSalesExpenseError})
            return
        }

        let showInSales = this.state.tempSales ? this.state.tempSales  : false
        let showInExpense = this.state.tempExpense ? this.state.tempExpense  : false
        let paymentType = {
            RegistrationID: this.props.user.user.RegistrationID,
            PaymentType: this.state.tempPaymentType,
            SequenceNo: this.state.tempSequenceNo,
            IsSystemDefined: this.state.tempIsSystemDefined,
            ShowInSales:showInSales,
            ShowInExpense: showInExpense,
            IsDeleted: false 
        }
        if(this.state.tempPaymentType.toLowerCase()==="wallet"|| this.state.tempPaymentType.toLowerCase()==="loyalty")
        {
            paymentType.OTPVerification = this.state.tempOTPVerification
        }
        this.props.savePaymentType(paymentType, this.state.key).then(()=>{
            this.props.getToastr("Payment type saved successfully")        
            this.props.onSave();       
        })
    }    

    onButtonClick(action){
        if(action==="save"){
            this.onSave();
        }else{
            this.props.onClose()
        }
    }

    render(){
        return  <Form
            title= {this.state.key ? "Edit Payment Type" : "New Payment Type"}
            onClick={(action)=>this.onButtonClick(action)}
            processing={this.state.saving}
            >
            <Input
                type="text"
                label="Payment Type"
                onChange={(value)=> this.setState({tempPaymentType:value,tempPaymentTypeError:"" })}
                value={this.state.tempPaymentType}
                error={this.state.tempPaymentTypeError}
                disabled = {this.state.tempIsSystemDefined}
                onEnter={this.onSave}
            />
            <Input
                type="number"
                label="Sequence No"
                onChange={(value)=> this.setState({tempSequenceNo:value,tempSequenceNoError:"" })}
                value={this.state.tempSequenceNo}
                error={this.state.tempSequenceNoError}
                onEnter={this.onSave}
            />
            <Input
                type="switch"
                label="Show in sales"
                onChange={(value)=> this.setState({tempSales:value,tempSalesExpenseError:"" })}
                value={this.state.tempSales}
                error={this.state.tempSalesExpenseError}
                onEnter={this.onSave}
            />
            <Input
                type="switch"
                label="Show in expense"
                onChange={(value)=> this.setState({tempExpense:value,tempSalesExpenseError:"" })}
                value={this.state.tempExpense}
                error={this.state.tempSalesExpenseError}
                onEnter={this.onSave}
            />
            {(this.state.tempPaymentType.toLowerCase()==="wallet" || this.state.tempPaymentType.toLowerCase()==="loyalty") &&<Input
                type="switch"
                label="OTP verification required"
                onChange={(value)=> this.setState({tempOTPVerification:value })}
                value={this.state.tempOTPVerification}
                onEnter={this.onSave}
            />}
        </Form>
    }
}

export default AddEditPaymentTypes