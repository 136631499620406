import React, { Component } from 'react'
import VendorSearch from './VendorSearch'
import * as actionTypes from '../../actionTypes'
var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;


class BulkPurchaseReturn extends Component {
    constructor() {
        super()
        this.state = {
            selectedVendor:null,
            vendorError:"",
            storeLocationError:"",
            storeLocations:[],
            transactionDate: new Date().toISOString().split('T')[0],
            placeOfSupply:"",
            transactionDateError:"",
            vendorProducts:[],
            noLineItemExistError:"",
            productCustomField1DisplayName:"",
            productCustomField2DisplayName:"",
            productCustomField3DisplayName:"",
            productCustomField4DisplayName:"",
            productCustomField5DisplayName:"",
            productCustomField6DisplayName:"",
            lineItems:[]
        }
        this.onVendorChange= this.onVendorChange.bind(this)
        this.onStoreLocationChange = this.onStoreLocationChange.bind(this)
        this.onRenderSearchOptions = this.onRenderSearchOptions.bind(this)
        this.onProductTypeAheadSearch= this.onProductTypeAheadSearch.bind(this)
        this.onProductSelected = this.onProductSelected.bind(this)
        this.onProductSelected = this.onProductSelected.bind(this)
        this.onLineItemChange= this.onLineItemChange.bind(this)
        this.roundUpTo2= this.roundUpTo2.bind(this)
    }

    componentWillMount(){
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    }

    async componentDidMount() {
        let storeLocationsTmp = await this.props.getStoreLocations(this.props.user.store.key)
        let storeLocations = storeLocationsTmp.filter(f=> !f.IsDeleted)
        if(this.props.config.TransactionTypes.filter(f=> f.TransactionType.toLowerCase()==="purchase return").length ===0)
        {
            alert('Tansaction type not found')

        }
        let transactionType = this.props.config.TransactionTypes.filter(f=> f.TransactionType.toLowerCase()==="purchase return")[0]
        this.setState({ storeLocations: storeLocations, storeLocationID: storeLocations[0].key, 
            storeLocation:storeLocations[0].LocationCode + ' ' + storeLocations[0].LocationName ,
            transactionType:transactionType})
    
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Product') {
            let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
            let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
            let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
            let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
            let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
            let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
            this.setState({
                productCustomField1DisplayName: customField1DisplayName,
                productCustomField2DisplayName: customField2DisplayName,
                productCustomField3DisplayName: customField3DisplayName,
                productCustomField4DisplayName: customField4DisplayName,
                productCustomField5DisplayName: customField5DisplayName,
                productCustomField6DisplayName: customField6DisplayName
            })
        }
    })
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }

    onVendorChange(vendor)
    {
            let vendorProducts=[]
            if(this.state.converted)
            {
                return false
            }
            let placeOfSupply = ""
            let vendorNew = null
            if(vendor)
            {
                vendorNew =  {
                    key:vendor.key,
                    VendorCode: vendor.VendorCode,
                    VendorFirstName: vendor.VendorFirstName,
                    VendorLastName: vendor.VendorLastName,
                    Gender: vendor.Gender,
                    EmailID: vendor.EmailID,
                    PhoneNo: vendor.PhoneNo,
                    Address1: vendor.Address1,
                    Address2: vendor.Address2,
                    City: vendor.City,
                    State: vendor.State,
                    PostalCode: vendor.PostalCode,
                    Country: vendor.Country,
                    CustomField1: vendor.CustomField1,
                    CustomField2: vendor.CustomField1,
                    CustomField3: vendor.CustomField1,
                    CustomField4: vendor.CustomField1,
                    CustomField5: vendor.CustomField1,
                    CustomField6: vendor.CustomField1,
                    StoreID:vendor.StoreID,
                    RegistrationID: vendor.RegistrationID,
                    IsDeleted: vendor.IsDeleted,
                    VendorStoreID: vendor.hasOwnProperty('VendorStoreID')? vendor.VendorStoreID : ''
                } 
                vendorProducts = vendor.hasOwnProperty('VendorProducts') ?  vendor.VendorProducts : []
                placeOfSupply = vendor.State.trim()
                if(vendorProducts.length> 0)
                {
                    this.setState({lineItems:[], vendorProducts:vendorProducts})
                }
            }
            else
            {
                if(this.state.vendorProducts.length>0)
                {
                    this.setState({lineItems:[], vendorProducts:[]})
                }
            }
            
            if(this.state.placeOfSupply.trim()!== placeOfSupply)
            {//recalculate taxes
                let lineItems = []
                this.state.lineItems.map((l) => {
                    let lineItem = l
                    lineItem = this.onLineItemChange( lineItem, placeOfSupply, this.state.computeTax, this.state.transactionType)
                    lineItems.push(lineItem)
                })
                this.setState({ lineItems: lineItems, vendorProducts:vendorProducts })
                this.calculateBillTotal(lineItems, this.state.billRoundOff, this.state.billAdditionalCharges, this.state.billAdditionalDiscount , this.state.transactionType)
            }
            this.setState({ selectedVendor: vendorNew, 
                placeOfSupply:placeOfSupply,
                vendorError: '' })
    }

    onLineItemChange(lineItem, placeOfSupply, computeTax=true, transactionType) {
        let includePriceAndTax = false
        if (transactionType.hasOwnProperty('IncludePriceAndTax')) {
            includePriceAndTax = transactionType.IncludePriceAndTax
        }
        if (!includePriceAndTax) {
            lineItem.SubTotal = 0
            lineItem.ChargeTaxOnProduct = false
            lineItem.TaxInclusive = false
            lineItem.DiscountPer = 0
            lineItem.DiscountAmount = 0
            lineItem.SubTotalWithDiscount = 0
            lineItem.TaxableAmount = 0
            lineItem.TaxComponentAmount = []
            lineItem.TotalTax = 0
            lineItem.Amount = 0
            return lineItem
        }

        let subTotal = this.roundUpTo2(Number(lineItem.SupplyPrice) * Number(lineItem.LineItemQty))
        let discountAmt
        let discountPer
        if (Number(lineItem.DiscountAmount) > 0 && Number(lineItem.DiscountPer) === 0) {//calculate discount per based on discount amount
            discountPer = Number(((Number(lineItem.DiscountAmount) * 100) / Number(subTotal)).toFixed(3))
            discountAmt = this.roundUpTo2(lineItem.DiscountAmount)
        }
        else {
            discountPer = Number(lineItem.DiscountPer)
            discountAmt = this.roundUpTo2((Number(subTotal) * Number(lineItem.DiscountPer)) / 100)
        }
        let subTotalWithDiscount = this.roundUpTo2(Number(subTotal) - Number(discountAmt))
        let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct
        let priceBasedTax = lineItem.PriceBasedTax
        let taxInclusive = lineItem.TaxInclusive
        let taxableAmount = 0
        let totalTax = 0
        let amount = 0
        let taxComponentAmount = []
        let supplyPriceWithoutTax = 0 //TBD

        if (chargeTaxOnProduct && computeTax) {
            if (priceBasedTax) {
                let unitPriceAfterDiscount = this.roundUpTo2(Number(subTotalWithDiscount) / Number(lineItem.LineItemQty))
                //console.log('unitPriceAfterDiscount',unitPriceAfterDiscount)
                //console.log('lineItem.Product.TaxPriceRange',lineItem.Product.TaxPriceRange)
                lineItem.Product.TaxPriceRange.map((t) => {
                    if (Number(unitPriceAfterDiscount) >= Number(t.FromPrice) && Number(unitPriceAfterDiscount) <= Number(t.ToPrice)) {
                        lineItem.TaxID = t.TaxID
                        lineItem.TaxGroup = t.TaxGroup
                        return
                    }
                })
            }
            let taxfound = false
            let customerFromSameState = true //TBD IMP IMP

            if (placeOfSupply !== "" && this.props.user.store.State !== "") {
                if (placeOfSupply.toLowerCase() !== this.props.user.store.State.toLowerCase()) {
                    customerFromSameState = false
                }
            }
            this.props.config.ProductTaxes.filter((t) => t.key === lineItem.TaxID.trim()).map((taxGroup, index) => {
                taxfound = true
                let taxAmount = 0
                let taxComponentTemp = []
                taxGroup.Taxes.map((t) => {
                    if (customerFromSameState && (t.TaxType === "CGST" || t.TaxType === "SGST")) {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                    else if (!customerFromSameState && t.TaxType === "IGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                    else if (t.TaxType !== "CGST" && t.TaxType !== "IGST" && t.TaxType !== "SGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                })

                if (taxInclusive) {//tax inclusive
                    let totalTaxRate = 0
                    taxComponentTemp.map((t) => {
                        totalTaxRate = this.roundUpTo2(Number(t.TaxPercentage) + Number(totalTaxRate))
                    })
                    totalTax = this.roundUpTo2(Number(subTotalWithDiscount) * Number(totalTaxRate) / (100 + Number(totalTaxRate)))
                    taxableAmount = this.roundUpTo2(Number(subTotalWithDiscount) - Number(totalTax))
                    amount = this.roundUpTo2(subTotalWithDiscount)
                    taxComponentTemp.map((t) => {
                        taxAmount = this.roundUpTo2(Number(totalTax) * ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate)) / 100)
                        taxComponentAmount.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: taxAmount
                        })
                    })
                }
                else {
                    taxableAmount = subTotalWithDiscount
                    taxComponentTemp.map((t) => {
                        taxAmount = this.roundUpTo2((Number(taxableAmount) * Number(t.TaxPercentage)) / 100)
                        totalTax = this.roundUpTo2(Number(totalTax) + Number(taxAmount))
                        taxComponentAmount.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: taxAmount
                        })
                    })
                    amount = this.roundUpTo2(taxableAmount + totalTax)
                }
            })
            //tax is not found then
            if (!taxfound) {
                //alert("Tax not found for selected product. Please check item ")
                taxableAmount = 0
                taxComponentAmount = []
                totalTax = 0
                amount = subTotalWithDiscount
            }
        }
        else {
            taxableAmount = 0
            taxComponentAmount = []
            totalTax = 0
            amount = subTotalWithDiscount
        }
        // lineItem.Qty = qty
        // lineItem.RetailPrice = price
        lineItem.SubTotal = this.roundUpTo2(subTotal)
        lineItem.ChargeTaxOnProduct = chargeTaxOnProduct
        lineItem.TaxInclusive = taxInclusive
        lineItem.DiscountPer = this.roundUpTo2(discountPer)
        lineItem.DiscountAmount = this.roundUpTo2(discountAmt)
        lineItem.SubTotalWithDiscount = this.roundUpTo2(subTotalWithDiscount)
        lineItem.TaxableAmount = this.roundUpTo2(taxableAmount)
        lineItem.TaxComponentAmount = taxComponentAmount
        lineItem.TotalTax = this.roundUpTo2(totalTax)
        lineItem.Amount = this.roundUpTo2(amount)
        return lineItem
    }

    async onStoreLocationChange(e){
        
        this.setState({
            storeLocationID: e.target.value,
            storeLocation: e.target.options[e.target.selectedIndex].text,
            storeLocationError: ''
        })
        if(this.state.storeLocationID !== e.target.value)
        {
            //refresh instock qty 
            let lineItems = this.state.lineItems
            for(let i=0;i<lineItems.length; i++)
            {
                lineItems[i].InStockQty= await this.props.getProductInStockQty(this.props.user.store.key,  e.target.value, lineItems[i].Product.key)      
            }
            this.setState({lineItems:lineItems})
        }   
    }

    onRenderSearchOptions(option){
       return <div>
            <div><b>{option.Name}</b></div>
            <div>{option.SKU}</div>
            <div>{(option.hasOwnProperty('CustomField1') && option.CustomField1.trim() !=="" && this.state.productCustomField1DisplayName.trim()!=="")&& <React.Fragment>{this.state.productCustomField1DisplayName}: {option.CustomField1}</React.Fragment>}
            {(option.hasOwnProperty('CustomField2') && option.CustomField2.trim() !=="" && this.state.productCustomField2DisplayName.trim()!=="")&& <React.Fragment>, {this.state.productCustomField2DisplayName}: {option.CustomField2}</React.Fragment>}
            {(option.hasOwnProperty('CustomField3') && option.CustomField3.trim() !=="" && this.state.productCustomField3DisplayName.trim()!=="")&& <React.Fragment>, {this.state.productCustomField3DisplayName}: {option.CustomField3}</React.Fragment>}
            {(option.hasOwnProperty('CustomField4') && option.CustomField4.trim() !=="" && this.state.productCustomField4DisplayName.trim()!=="")&& <React.Fragment>, {this.state.productCustomField4DisplayName}: {option.CustomField4}</React.Fragment>}
            {(option.hasOwnProperty('CustomField5') && option.CustomField5.trim() !=="" && this.state.productCustomField5DisplayName.trim()!=="")&& <React.Fragment>, {this.state.productCustomField5DisplayName}: {option.CustomField5}</React.Fragment>}
            {(option.hasOwnProperty('CustomField6') && option.CustomField6.trim() !=="" && this.state.productCustomField6DisplayName.trim()!=="")&& <React.Fragment>, {this.state.productCustomField6DisplayName}: {option.CustomField6}</React.Fragment>}
            </div>
        </div>
    }

    onProductTypeAheadSearch(query){

        let excludeUniqueNo = this.state.transactionType.TransactionType.toLowerCase() ==="stock update" ? true : false

        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProductsForInventorySearch(this.props.user.user.RegistrationID, query, excludeUniqueNo)
            .then((result) => {
                let productSearchResult = []
                let rowCount = 0
                for (let i = 0; i < result.searchResult.length; i++) {
                    if (rowCount > 10) {
                        break
                    }
                    let e = result.searchResult[i]
                    
                    if(this.state.vendorProducts.length> 0)
                    {
                        if(this.state.vendorProducts.filter(f=> f.ProductID ===e.key &&  (f.StoreID ==="" || f.StoreID==this.props.user.store.key)).length> 0)
                        {
                            productSearchResult.push(e)
                            rowCount = rowCount + 1
                            continue
                        }
                        else
                        {
                            continue
                        }
                    }
                    // if(e.)
                    if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                        if (e.AccessibleToSelectedStores) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.MRP = storePrice[0].MRP
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                productSearchResult.push(e)
                                rowCount = rowCount + 1
                            }
                            continue
                        }
                    }
                    if (e.hasOwnProperty('StoreSpecificPricing')) {
                        if (e.StoreSpecificPricing) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                e.MRP = storePrice[0].MRP
                            }
                        }
                    }
                    rowCount = rowCount + 1
                    productSearchResult.push(e)
                }
                this.setState({ productsTypeAheadOptions: productSearchResult })
                this.setState({ isProductTypeAheadLoading: false });
            })
    }

    async onProductSelected(product) {
        // console.log('onProductSelected.product', product)
        if (this.props.config.Products.filter(f => f.ParentProductID === product.key).length > 0) {
            this.setState({showVariants: true, selectedVariantProduct: product, selectedLineItem: null});
            return;
        }
        if(this.state.transactionType.UpdateStockChecked 
        && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="outward"
        && product.hasOwnProperty('InventoryType') 
        && product.InventoryType.toLowerCase() === "unique no")
        {//give option to select unique id 
            this.setState({showUniqueNoSelection:true, selectedProduct:product})
            return
        }

        if(this.state.transactionType.UpdateStockChecked 
        && this.state.transactionType.hasOwnProperty('UpdateStockInwardOrOutward')
        && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="outward"
        && product.hasOwnProperty('InventoryType') 
        && product.InventoryType.toLowerCase() === "batch tracking")
        {
            //show batch list 
            // console.log('show batches for selection')
            this.setState({showBatchsForSelection:true, selectedProduct:product, selectedLineItem:null})
            return
        }
        this.productSelected(product, null)
    }

    async productSelected(productPara, uniqueNoList, batch =null){
        let lineItemIndex = -1
        let lineItem = {}
        let selectedLineItemIndex= -1
        let selectedLineItem = null
        let supplyPrice = 0
        let discount = 0 
        let showLineItemModal=false
        let getInstockQty = true 
        let product = {...productPara}
        // //check if product is already added
        // console.log('productSelected  - product',product)
        this.state.lineItems.map((l, index) => {
            if (l.Product.key === product.key) {
                //increase qty 
                lineItem = l
                lineItemIndex = index
                return
            }
        })
        
        if(product.hasOwnProperty('InventoryType') 
        && this.state.transactionType.UpdateStockChecked
        && this.state.transactionType.hasOwnProperty('UpdateStockInwardOrOutward')
        && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="outward"
        && product.InventoryType.toLowerCase() === "batch tracking")
        {
            if(!batch){
                return 
            }
            // console.log('batch', batch)
            lineItem = {}
            lineItem.BatchID = batch.key
            lineItem.BatchNo = batch.BatchNo
            lineItem.BatchMRP = batch.BatchMRP
            lineItem.BatchRetailPrice = batch.BatchRetailPrice
            lineItem.BatchMfgDate = batch.BatchMfgDate
            lineItem.BatchExpDate = batch.BatchExpDate
            lineItem.BatchMfgDateValue = batch.BatchMfgDateValue
            lineItem.BatchExpDateValue = batch.BatchExpDateValue
            lineItem.InStockQty = batch.Qty
            if(product.BatchTrackingMRPRequired)
            {
                product.MRP = batch.BatchMRP
                lineItem.RetailPrice = batch.BatchRetailPrice
            }
            lineItemIndex=-1
            showLineItemModal= false
            getInstockQty= false
        }

        if (lineItemIndex === -1) {
            //get PE in which this item is purchased
            let results  = await this.props.getPurchaseEntryForPurchaseReturn(this.props.user.store.key, this.state.selectedVendor.VendorCode, product.key)
            console.log('results',results)
            lineItem.LineItemQty =1
            lineItem.FreeQty =0
            lineItem.Qty =1
            lineItem.SupplyPrice = supplyPrice
            lineItem.UOM = product.hasOwnProperty('PurchaseUOM') ? product.PurchaseUOM : ''
            lineItem.SubTotal = 0
            lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct
            lineItem.PriceBasedTax = product.ChargeTaxOnProduct ? product.PriceBasedTax : false
            lineItem.HSNSACCode = product.HSNSACCode
            if(product.ChargeTaxOnProduct)
            {
                if(product.hasOwnProperty('TaxInclusivePurchase') )
                {
                    lineItem.TaxInclusive = product.TaxInclusivePurchase
                }
                else{
                    lineItem.TaxInclusive = product.TaxInclusive 
                }
            }
            else
            {
                lineItem.TaxInclusive =false
            }
            // lineItem.TaxInclusive = product.ChargeTaxOnProduct ? product.TaxInclusive : false
            lineItem.TaxGroup = product.TaxGroup
            lineItem.TaxID = product.TaxID
            lineItem.DiscountPer = 0
            lineItem.DiscountAmount = discount
            lineItem.SubTotalWithDiscount = 0
            lineItem.TaxableAmount = 0
            lineItem.TotalTax = 0
            lineItem.Amount = 0
            lineItem.Product = product
            lineItem.Notes = ''
            if(uniqueNoList && uniqueNoList.length> 0)
            {
                lineItem.UniqueNoList = uniqueNoList
                lineItem.LineItemQty = uniqueNoList.length
                lineItem.Qty =lineItem.LineItemQty
                lineItem.FreeQty =0
            }
        }
        else {
           
            if(uniqueNoList && uniqueNoList.length> 0)
            {
                lineItem.UniqueNoList = uniqueNoList
                lineItem.LineItemQty = uniqueNoList.length
                lineItem.Qty =lineItem.LineItemQty
                lineItem.FreeQty =0
            }
            else
            {
                
                lineItem.LineItemQty = Number(lineItem.LineItemQty) + 1 + Number(lineItem.FreeQty)
                lineItem.Qty = lineItem.LineItemQty
            }
        }
        
        // let products = [];
        
        // products.push(product.key)
        
        // let result = await this.props.getProductInStockQty(this.props.user.store.key,this.state.storeLocationID,products);
        // console.log('lineItem', lineItem)
        if(getInstockQty)
        {
            lineItem.InStockQty = 0
            lineItem.InStockQty= await this.props.getProductInStockQty(this.props.user.store.key, this.state.storeLocationID, product.key)      
        }
        // if(result && result.searchResult && result.searchResult.length > 0){
        //     lineItem.InStockQty = result.searchResult[0].InStockQty;
        //     console.log("lineItem.InStockQty",lineItem.InStockQty)
        // }
        
        lineItem = await  this.onLineItemChange(lineItem, this.state.placeOfSupply, this.state.computeTax, this.state.transactionType)
        //lineItem.Error=""
        
        let lineItems = this.state.lineItems
        if (lineItemIndex >= 0) {
            lineItems[lineItemIndex] = lineItem
        }
        else {
            lineItems.unshift(lineItem)
            selectedLineItem= lineItem
            selectedLineItemIndex = 0 //2Dec
        }
        console.log('selectedLineItemIndex', selectedLineItemIndex)
        this.setState({ lineItems: lineItems,
            selectedLineItem:selectedLineItem,
            selectedLineItemIndex:selectedLineItemIndex,
            showLineItemModal:showLineItemModal,
            noLineItemExistError: '' })
        this.calculateBillTotal(lineItems, this.state.billRoundOff, this.state.billAdditionalCharges, this.state.billAdditionalDiscount, this.state.transactionType)
    }

    render(){
        return <React.Fragment>
            <div className="content-wrapper">
                    <section className="content-header">
                    <h1>Bulk Purchase Return</h1>
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                        style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={this.onSave} disabled={this.state.saving}>Save
                    </button>
                    <button type="submit" className="btn btn-md btn-default btn-flat pull-right"
                        style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={this.onSaveAsDraft} disabled={this.state.saving}>Park Transaction
                    </button>
                    <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginTop: "-25px" }}
                        onClick={this.onCancel} >Cancel
                    </button>
                </section>
                <section className="content">
                    <div className="box ">
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-2 col-xs-12">
                                    <div className={this.state.transactionDateError !== "" ? "form-group has-error" : "form-group "} >
                                        <label>Date</label>
                                        <input type="date" className="form-control"
                                            onChange={(evt) => this.setState({ transactionDate: evt.target.value, transactionDateError: '' })}
                                            value={this.state.transactionDate}
                                          />
                                        {this.state.transactionDateError !== "" && <span className="help-block">{this.state.transactionDateError}</span>}
                                    </div>
                                </div>
                                <div className="col-md-5 col-xs-12">
                                    <div className={this.state.vendorError !== "" ? "form-group has-error" : "form-group "} >
                                        <label>Vendor</label>
                                        <VendorSearch {...this.props}
                                            vendor={this.state.selectedVendor}
                                            error={this.state.partyError}
                                            onEditVendor={()=>this.setState({showInventory:false, showVendor:true, vendorAction:actionTypes.VENDOR_EDIT})}
                                            onAddVendor={()=> this.setState({showInventory:false, showVendor:true,vendorAction:actionTypes.VENDOR_NEW})}
                                            onChange={(vendor) => this.onVendorChange(vendor)}
                                        />
                                        {this.state.vendorError !== "" && <span className="help-block">{this.state.vendorError}</span>}
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <div className={this.state.storeLocationError !== "" ? "form-group has-error" : "form-group "} >
                                        <label>From Location</label>
                                        <select className="form-control"
                                            value={this.state.storeLocationID}
                                            onChange={(e) => {this.onStoreLocationChange(e)}}>
                                            <option value="">Select Store Location</option>
                                            {this.state.storeLocations.filter((e) => !e.IsDeleted).map((storeLocation) =>
                                                <option  key={storeLocation.key} value={storeLocation.key}>{storeLocation.LocationCode}  {storeLocation.LocationName}</option>
                                            )}
                                        </select>
                                        {this.state.storeLocationError !== "" && <span className="help-block">{this.state.storeLocationError}</span>}
                                    </div>
                                </div>
                            </div>
                            {this.state.selectedVendor && <div className="row">
                                    <div className="col-md-12">
                                        <div className={this.state.noLineItemExistError !== "" ? "form-group has-error" : "form-group "} >
                                            <div className="input-group" style={{ width: "50%" }}>
                                                {!this.state.toggleSearch && <AsyncTypeahead
                                                    autoFocus={true}
                                                    allowNew={false}
                                                    isLoading={this.state.isProductTypeAheadLoading}
                                                    multiple={false}
                                                    options={this.state.productsTypeAheadOptions}
                                                    labelKey="Name"
                                                    minLength={3}
                                                    onSearch={this.onProductTypeAheadSearch}
                                                    placeholder="Start typing to search products"
                                                    filterBy={['Name', 'SKU','BarCode']}
                                                    autoFocus={true}
                                                    dropup={false}
                                                    highlightOnlyResult={true}
                                                    selected={this.state.productsTypeAheadSelected}
                                                    onChange={(selected) => {
                                                        this.onProductSelected(selected[0])
                                                        this.setState({ productsTypeAheadSelected: [] })
                                                    }}
                                                    renderMenuItemChildren={(option, props) => this.onRenderSearchOptions(option)}
                                                />}
                                                {this.state.toggleSearch && <input type="text" 
                                                    onChange={(evt) => {this.setState({ queryText: evt.target.value })}}
                                                    value={this.state.queryText}
                                                    onKeyPress={this.onProductBarCodeSearch}
                                                    placeholder="Start scanning to search products"
                                                    className="form-control" style={{ height: "33px", fontSize: "14px" }} />}
                                                <div className="input-group-btn">
                                                    <button type="button"
                                                        onClick={() => this.setState({ toggleSearch: !this.state.toggleSearch })}
                                                        className="btn btn-default btn-flat">
                                                        <i className="fa fa-exchange"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {this.state.noLineItemExistError !== "" && <span className="help-block">{this.state.noLineItemExistError}</span>}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </section>                                   
            </div>
    </React.Fragment>}
}

export default BulkPurchaseReturn