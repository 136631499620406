import React, { Component } from "react";
import Window from "../2/Controls/Window";

class BarcodeScanner extends Component {
  constructor() {
    super();
    this.onScanSuccess = this.onScanSuccess.bind(this);
  }

  componentDidMount() {
    const html5QrcodeScanner = new window.Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      /* verbose= */ false
    );
    html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
  }

  async onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    // console.log(`Code matched = ${decodedText}`, decodedResult);
    console.log("decodedtext", decodedText);
    console.log("decodedresult", decodedResult);
    this.props.onScanSuccess(decodedText);
  }

  onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    // console.warn(`Code scan error = ${error}`);
  }

  render() {
    return (
      <Window
        header={"Scan barcode"}
        modal={false}
        onClose={() => this.props.onClose()}
        buttons={[]}
      >
        <div id="reader" width="100%"></div>
      </Window>
    );
  }
}

export default BarcodeScanner;
