import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'

export function getAttendance(from, size, registrationID, storeID, searchText, searchAttendanceFromDate, searchAttendanceToDate , searchStatus) {
    return (dispatch) => {
        let searchParameters = {
            "from": from,
            "size": size,
            "track_total_hits": true,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } }, 
                        { "match": { "StoreID": storeID}},
                        { 
                          "range": {
                            "Date": {
                              "lte": searchAttendanceToDate,
                              "gte": searchAttendanceFromDate,
                              "format": "yyyy-MM-dd"
                            }
                          }
                        }
                    ],
                    "filter": {
                        "bool": {
                            "must": [

                            ]
                        }
                    }
                }
            }, 
            "sort": [
                {
                    "ActionOn": {
                        "order": "desc"
                    }
                }
            ]
        }
        if(searchStatus != "")
        {
            searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "AttendanceType": searchStatus.trim().toLowerCase() } });
        }
        if(searchText !="")
        {
            searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Employee.label": searchText.trim().toLowerCase() } })
        }
        // console.log("Get Attendance - Query", JSON.stringify(searchParameters))
        let addMessage = firebase.functions().httpsCallable('getAttendance');
        return addMessage({ text: searchParameters }).then(function (result) {
            const attendance = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                attendance.push({ key: data._id, ...data._source })
            })
            // return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: attendance }
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: attendance }

        });
    }
}

export function saveAttendance(attendance, key) {
    return (dispatch, getState) => {
        if (key !== "") {
            attendance.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
            attendance.ActionBy = getState().user.user.Name
            attendance.ActionByUID = getState().user.user.UID
            attendance.ActionByEmailID = getState().user.user.EmailID
            attendance.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Attendance").doc(key).update({
                ...attendance
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating attendance: ", error);
            })
        }
        else {
            attendance.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
            attendance.ActionBy = getState().user.user.Name
            attendance.ActionByUID = getState().user.user.UID
            attendance.ActionByEmailID = getState().user.user.EmailID
            attendance.Action = constants.AUDIT_TRAIL_NEW

            return database.collection("Attendance").add({
                ...attendance
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding attendance: ", error);
                })
        }
    }
}
