import React, { Component } from 'react'
import CustomerSearch from './CustomerSearch'
import Customer from '../CRM/Customer'
import Payment from './Payment'
import CustomerDashBoard from '../Reports/CustomerDashboard'
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Input from '../2/Controls/Input';

class ReceivePayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showAddCustomer: false,
            show: true,
            customerError: '',
            lineItemError: '',
            selectedCustomer: null,
            outStandingPayments: [],
            selectAll: false,
            totalOutStandingAmount: 0,
            totalReceiveAmount: 0,
            sale: null,
            notes: '',
            showCustomerDashBoard: false,
            saving:false
        }
        this.onCustomerChange = this.onCustomerChange.bind(this)
        this.calculateTotal = this.calculateTotal.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }
 
    async onCustomerChange(customer) {
        let outStandingPayments = []
        if (customer) {
            outStandingPayments = await this.props.getCustomerOutstanding(customer.key)
            console.log("outStandingPayments", outStandingPayments);
            if (customer.hasOwnProperty('OpeningOutStandingAmountCurrent') && customer.OpeningOutStandingAmountCurrent > 0) {
                console.log('OpeningOutStandingAmountCurrent', customer.OpeningOutStandingAmountCurrent)
                outStandingPayments = [{
                    TransactionKey: "",
                    TransactionNo: "",
                    TransactionType: "Customer Opening Outstanding Amount",
                    TransactionDate: '',
                    TransactionAmount: 0,
                    OutStandingAmount: Number(customer.OpeningOutStandingAmountCurrent),
                    ReceiveAmount: 0,
                    Selected: false
                }, ...outStandingPayments]
            }
        }
        this.setState({ outStandingPayments: outStandingPayments, selectedCustomer: customer, totalOutStandingAmount: 0, totalReceiveAmount: 0 })
    }

    calculateTotal() {
        let totalOutStandingAmount = 0
        let totalReceiveAmount = 0
        this.state.outStandingPayments.map(m => {
            totalOutStandingAmount = Number(totalOutStandingAmount) + Number(m.OutStandingAmount)
            if (m.Selected) {
                totalReceiveAmount = totalReceiveAmount + Number(m.ReceiveAmount)
            }
        })
        this.setState({ totalOutStandingAmount: totalOutStandingAmount, totalReceiveAmount: totalReceiveAmount })
    }

    onSave() {
        let customerError = ""
        let lineItemError = ""
        if (!this.state.selectedCustomer) {
            customerError = "Please select customer"
        }
        else if (this.state.totalReceiveAmount <= 0) {
            lineItemError = "Please speicfy amount to be received"
        }
        if (customerError.trim() !== "" || lineItemError.trim() !== "") {
            this.setState({ customerError: customerError, lineItemError: lineItemError })
            return
        }
        let lineItems = this.state.outStandingPayments.filter(f => f.Selected)
        let transactionNo = ""
        this.props.location.state.transactionType.NumberingFormat.map((parameters) => {
            let para = ""
            if (parameters.Option === "text") {
                para = parameters.Value
            }
            else if (parameters.Option == "storecode") {
                para = this.props.user.store.StoreCode
            }
            else if (parameters.Option == "locationcode") {
                para = this.props.user.cashRegister.LocationCode
            }
            else if (parameters.Option == "cashregistercode") {
                para = this.props.user.cashRegister.CashRegisterCode
            }
            else if (parameters.Option == "calendardmonthyear") {
                let d = new Date();
                let a = d.getFullYear() + ""
                para = (d.getMonth() + 1) + a.substring(2, 4)
                if (para.length < 3) {
                    para = "0" + para
                }
            }
            else if (parameters.Option === "fiscalyear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            else if (parameters.Option === "calendarmonth") {
                let d = new Date();
                let a = (d.getMonth() + 1) + ""
                if (a.length < 2) {
                    para = "0" + a
                }
                else
                {
                    para = a
                }
            }
            else if (parameters.Option === "calendaryear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            transactionNo = transactionNo + para + parameters.Separator
        })

        let sale = {
            TransactionNo: transactionNo,
            TransactionType: this.props.location.state.transactionType,
            Customer: this.state.selectedCustomer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: this.props.user.cashRegister,
            Store: this.props.user.store,
            LineItems: [],
            OutStandingPayments: lineItems,
            BillQty: 0,
            BillSubTotal: 0,
            BillDiscount: 0,
            BillTaxAmount: 0,
            BillAmount: this.state.totalReceiveAmount,
            RoundOff: 0,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            Payments: null,
            ParentTransactionNo: '',
            ParentTransactionKey: '',
            Employee: null,
            Notes: this.state.notes,
            TaxSummaryList: null,
            PlaceOfSupply: '',
            BillingAddress: null,
            DeliveryCustomer: null,
            DeliveryCustomerAddress: null,
            DeliveryDate: null,
            DeliveryTime: null,
            DeliveryNotes: '',
            BackEndUpdate: false
        }
        this.setState({ sale: sale, show: false, showPayment: true })
    }

    onCancel(){
        if(this.props.isMobileApp()){
            this.props.history.push("/home");
        }
        this.setState({
            showAddCustomer: false,
            showPayment: false,
            show: true,
            customerError: '',
            lineItemError: '',
            selectedCustomer: null,
            outStandingPayments: [],
            selectAll: false,
            totalOutStandingAmount: 0,
            totalReceiveAmount: 0,
            sale: null,
            notes: ''
        })
    }

    renderOutStandingLineItems()
    {
        return  <table className="table table-bordered table-striped">
        <thead>
            <tr>
                <th>
                    <input type="checkbox"
                        checked={this.state.selectAll}
                        onChange={() => {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments.map((b) => {
                                b.Selected = !this.state.selectAll
                                    b.ReceiveAmount = !this.state.selectAll ? b.OutStandingAmount : 0
                            })
                            this.setState({ selectAll: !this.state.selectAll, outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }}
                    />
                </th>
                <th style={{ width: "10px" }}>#</th>
                {this.props.isMobileApp() && <th className="">Transaction Details</th>}
                {!this.props.isMobileApp() && <th className="">Transaction No</th>}
                {!this.props.isMobileApp() &&<th className="">Transaction Date</th>}
                {!this.props.isMobileApp() &&<th className="">Transaction Type</th>}
                {!this.props.isMobileApp() &&<th className="">Transaction Amount</th>}
                {!this.props.isMobileApp() &&<th className="">Balance Amount</th>}
                {!this.props.isMobileApp() &&<th >Receive Amount</th>}
            </tr>
        </thead>
        <tbody>
            {this.state.outStandingPayments.map((lineItem, index) => <tr>
                <td>
                    <input type="checkbox"
                        checked={lineItem.Selected}
                        onChange={() => {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments[index].Selected = !outStandingPayments[index].Selected
                            outStandingPayments[index].ReceiveAmount = outStandingPayments[index].Selected ? outStandingPayments[index].OutStandingAmount : 0
                            this.setState({ outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }}
                    />
                </td>
                <td>{index + 1}</td>
                {this.props.isMobileApp() && <td className="">
                    <div>{lineItem.TransactionNo}</div>
                    <div>{lineItem.TransactionDate !== "" ? (new Date(lineItem.TransactionDate).toISOString().split('T')[0]): ""}</div>
                    <div>{lineItem.TransactionType}</div>
                    <div>Amount: {lineItem.TransactionAmount}</div>
                    <div>Balance Amt: {lineItem.OutStandingAmount}</div>
                    <Input 
                        type="number"
                        disabled={!lineItem.Selected}
                        value={lineItem.ReceiveAmount}
                        onChange={value => {
                            let receiveAmount = Number(value)
                            if ( lineItem.OutStandingAmount > 0 && receiveAmount <= lineItem.OutStandingAmount && receiveAmount > 0) {
                                let outStandingPayments = this.state.outStandingPayments
                                outStandingPayments[index].ReceiveAmount = receiveAmount
                                this.setState({ outStandingPayments: outStandingPayments })
                                this.calculateTotal()
                            }
                            else if ( lineItem.OutStandingAmount < 0 && receiveAmount >= lineItem.OutStandingAmount && receiveAmount < 0) {
                                let outStandingPayments = this.state.outStandingPayments
                                outStandingPayments[index].ReceiveAmount = receiveAmount
                                this.setState({ outStandingPayments: outStandingPayments })
                                this.calculateTotal()
                            }
                        }}
                    />
                </td>}
                {!this.props.isMobileApp() && <td className="">{lineItem.TransactionNo}</td>}
                {!this.props.isMobileApp() && <td>{ lineItem.TransactionDate !== "" ? (new Date(lineItem.TransactionDate).toISOString().split('T')[0]): ""}</td>}
                {!this.props.isMobileApp() && <td className="">{lineItem.TransactionType}</td>}
                {!this.props.isMobileApp() && <td className="" style={{textAlign:"right"}}>{lineItem.TransactionAmount}</td>}
                {!this.props.isMobileApp() && <td className="" style={{textAlign:"right"}} >{lineItem.OutStandingAmount}</td>}
                {!this.props.isMobileApp() &&<td ><input type="number"
                    disabled={!lineItem.Selected}
                    value={lineItem.ReceiveAmount}
                    onChange={evt => {
                        let receiveAmount = Number(evt.target.value)
                        if ( lineItem.OutStandingAmount > 0 && receiveAmount <= lineItem.OutStandingAmount && receiveAmount > 0) {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments[index].ReceiveAmount = receiveAmount
                            this.setState({ outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }
                        else if ( lineItem.OutStandingAmount < 0 && receiveAmount >= lineItem.OutStandingAmount && receiveAmount < 0) {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments[index].ReceiveAmount = receiveAmount
                            this.setState({ outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }
                    }}
                ></input></td>}
                {/* <td>{lineItem.BalanceAmount}</td> */}
            </tr>)}
            <tr>
                <td></td>
                <td></td>
                {this.props.isMobileApp() &&<td>
                    <div>Balance Amt:<b>{this.state.totalOutStandingAmount}</b></div>
                    <div>Receive Amt:<b>{this.state.totalReceiveAmount}</b></div>
                </td>}
                {!this.props.isMobileApp() &&<td ></td>}
                {!this.props.isMobileApp() &&<td className=""></td>}
                {!this.props.isMobileApp() &&<td className=""></td>}
                {!this.props.isMobileApp() &&<td className=""></td>}
                {!this.props.isMobileApp() &&<td className=""><b>{this.state.totalOutStandingAmount}</b></td>}
                {!this.props.isMobileApp() &&<td><b>{this.state.totalReceiveAmount}</b></td>}
            </tr>
        </tbody>
    </table>
    }
    
    renderMobileOutStandingLineItems()
    {
        return  <table className="table table-bordered table-striped">
        <thead>
            <tr>
                <th>
                    <input type="checkbox"
                        checked={this.state.selectAll}
                        onChange={() => {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments.map((b) => {
                                b.Selected = !this.state.selectAll
                                    b.ReceiveAmount = !this.state.selectAll ? b.OutStandingAmount : 0
                            })
                            this.setState({ selectAll: !this.state.selectAll, outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }}
                    />
                </th>
                <th style={{ width: "10px" }}>#</th>
                <th className="hidden-md hidden-lg" >Transaction</th>
                <th >Receive Amount</th>
            </tr>
        </thead>
        <tbody>
            {this.state.outStandingPayments.map((lineItem, index) => <tr>
                <td>
                    <input type="checkbox"
                        checked={lineItem.Selected}
                        onChange={() => {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments[index].Selected = !outStandingPayments[index].Selected
                            outStandingPayments[index].ReceiveAmount = outStandingPayments[index].Selected ? outStandingPayments[index].OutStandingAmount : 0
                            this.setState({ outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }}
                    />
                </td>
                <td>{index + 1}</td>
                <td className="hidden-md hidden-lg" >
                    {lineItem.TransactionNo}
                    <div>{lineItem.TransactionType}</div>
                    <div>Sale Amt: ₹ {lineItem.TransactionAmount}</div>
                    <div>O/S: ₹ {lineItem.OutStandingAmount}</div>
                </td>
                <td>
                    <input type="number"
                    disabled={!lineItem.Selected}
                    value={lineItem.ReceiveAmount}
                    onChange={evt => {
                        let receiveAmount = Number(evt.target.value)
                        if ( lineItem.OutStandingAmount > 0 && receiveAmount <= lineItem.OutStandingAmount && receiveAmount > 0) {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments[index].ReceiveAmount = receiveAmount
                            this.setState({ outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }
                        else if ( lineItem.OutStandingAmount < 0 && receiveAmount >= lineItem.OutStandingAmount && receiveAmount < 0) {
                            let outStandingPayments = this.state.outStandingPayments
                            outStandingPayments[index].ReceiveAmount = receiveAmount
                            this.setState({ outStandingPayments: outStandingPayments })
                            this.calculateTotal()
                        }
                    }}>
                    </input>
                </td>
            </tr>)}
            <tr>
                <td></td>
                <td></td>
                <td className=""><b>₹ {this.state.totalOutStandingAmount}</b></td>
                <td className=""><b>₹ {this.state.totalReceiveAmount}</b></td>
            </tr>
        </tbody>
    </table>
    }

    renderCustomerSearch()
    {
        return  <CustomerSearch
        {...this.props}
        customer={this.state.selectedCustomer}
        error={this.state.customerError}
        mobile={false}
        onCustomerChange={
            this.onCustomerChange}
        onAddCustomer={() => { this.setState({ showAddCustomer: true, show: false }) }}
        onCustomerEdit={() => { this.setState({ showAddCustomer: true, show: false }) }}
        onShowCustomerDashBoard={() => {
            this.setState({ showCustomerDashBoard: true, show: false })
        }}
    ></CustomerSearch>
    }   

    renderNotes()
    {
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }

        return <div className="form-group">
        <label>Notes</label>
        <textarea className="textarea" style={styleEditor}
            onChange={(evt) => this.setState({ notes: evt.target.value })}
            value={this.state.notes}
        ></textarea>
    </div>
    }

 render() {
        
        return <React.Fragment>

{/* !this.props.isMobileApp() &&  */}
            {(this.state.show) && <Page 
                title="Receive Payment"
                showSaveButton={true} 
                showCancelButton={true}
                processing={this.state.saving}
                onClick={(action)=>{
                    if(action==="cancel"){
                        this.onCancel();
                    }else if(action==="save"){
                        this.onSave();
                    }
                }}>
                    <PageSection >
                        <div className="row">
                            <div className="col-md-6 ">
                                {this.renderCustomerSearch()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                {this.renderOutStandingLineItems()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                {this.renderNotes()}
                            </div>
                        </div>
                    </PageSection>
            </Page>}
        
            {this.state.showAddCustomer && <Customer {...this.props}
                calledFromInvoice={true}
                selectedCustomer={this.state.selectedCustomer}
                onSave={(customer) => {
                    this.onCustomerChange(customer)
                    this.setState({
                        showAddCustomer: false,
                        show: true
                    })
                }}
                onClose={() => this.setState({ showAddCustomer: false, show: true })} />}

            {this.state.showCustomerDashBoard && <CustomerDashBoard {...this.props}
                calledFromInvoice={true}
                cutomerID={this.state.fromDelivery ? this.state.tmpDeliveryCustomer.key : this.state.selectedCustomer.key}
                onClose={() => this.setState({ showCustomerDashBoard: false, show: true})} />}

            {this.state.showPayment && <Payment  {...this.props}
                transactionID=""
                onCancel={() => { this.setState({ showPayment: false, show: true }) }}
                onSave={() => {
                    this.onCancel()
                }}
                sale={this.state.sale}
            />}

        </React.Fragment>
    }
}

export default ReceivePayment