import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

// export function getProductTaxes(registrationID) {
//     return (dispatch) => {
//         return database.collection("ProductTaxes").where("RegistrationID","==",registrationID).get().then((querySnapshot) => {        
//             const productTaxes = []
//             querySnapshot.forEach((doc) => {
//                 productTaxes.push(
//                   {
//                     key:doc.id,...doc.data()
//                   }
//                 )
//            })
//             dispatch({type: actionTypes.GET_TAXES,productTaxes})
//         }).catch((error) => {
//             console.log(error)
//         })
//     }
// }

export function getProductTaxes(registrationID) {
    return (dispatch) => {
        return database.collection("ProductTaxes").where("RegistrationID", "==", registrationID).get().then((querySnapshot) => {
            const productTaxes = []
            querySnapshot.forEach((doc) => {
                productTaxes.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            dispatch({ type: actionTypes.GET_TAXES, productTaxes })
            return productTaxes;
        }).catch((error) => {
            console.log(error)
        })
    }
    // return (dispatch) => {
    //     let addMessage = firebase.functions().httpsCallable('apiGetProductTaxes');
    //     return addMessage({ registrationID: registrationID}).then(function (result) {
    //         let productTaxes  = result.data;
    //         dispatch({ type: actionTypes.GET_TAXES,productTaxes })
    //     });
    // }
}

export function saveProductTaxes(productTax, key){
    return (dispatch,getState) =>{
        productTax.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        productTax.ActionBy = getState().user.user.Name
        productTax.ActionByUID = getState().user.user.UID
        productTax.ActionByEmailID = getState().user.user.EmailID
        if(key.trim()!== "")
        {
            productTax.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("ProductTaxes").doc(key).update({
                ...productTax
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating product tax: ", error);
              })
        }
        else
        {
            productTax.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("ProductTaxes").add({
                ...productTax
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding product tax: ", error);
              })
        }
        
    }
}

export function deleteProductTax(key){
    return (dispatch, getState) =>{
        return database.collection("ProductTaxes").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}