import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import ReactHtmlParser from "react-html-parser";
import Input from "../2/Controls/Input";
import "./InvoiceDetail.css";

class PincodeOrderReport extends Component {
  constructor() {
    super();
    this.state = {
      invoices: [],
      stores: [],
      storeList: [],
      searchText: "",
      searchTransactionNo: "",
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCustomerDetail: "",
      searchCashRegister: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      paginationStatus: "No records to display",
      exportCalled: false,
      loading: true,
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadTransactionData = this.loadTransactionData.bind(this);
  }

  componentDidMount() {
    // this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    this.renderData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  async renderData(
    storeList,
    from,
    size,
    searchTransactionFromDate,
    searchTransactionToDate
  ) {
    let invoices = await this.loadTransactionData(
      storeList,
      from,
      size,
      searchTransactionFromDate,
      searchTransactionToDate
    );

    var col = [];
    for (var i = 0; i < invoices.length; i++) {
      for (var key in invoices[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }
    // console.log("col", col);
    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < invoices.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        result += "<td>";
        result += invoices[i][col[j]] === undefined ? 0 : invoices[i][col[j]];
        result += "</td>";
      }
      result += "</tr>";
    }
    result += "</tbody>";
    result += "</table>";
    this.setState({ invoices: invoices, finalTable: result, loading: false });
  }

  async loadTransactionData(
    storeList,
    from,
    size,
    searchTransactionFromDate,
    searchTransactionToDate
  ) {
    this.setState({ loading: true });

    let result = await this.props.getBillWiseDetailReport(
      this.props.user.user.RegistrationID,
      storeList,
      from,
      size,
      "",
      "",
      searchTransactionFromDate,
      searchTransactionToDate,
      "",
      ""
    );

    let invoices = [];
    for (let i = 0; i < result.searchResult.length; i++) {
      // console.log("data", data);
      const data = result.searchResult[i];
      let deliveryBy = data.DeliveryBy;
      if (data.hasOwnProperty("DeliveryType") && data.DeliveryType === "Self") {
        deliveryBy =
          data.hasOwnProperty("DeliveryPersonName") &&
          data.DeliveryPersonName !== ""
            ? data.DeliveryPersonName
            : "Self";
      }
      let invoice = {
        "Store Name": data.Store.StoreName,
        "Store ID": data.Store.key,
        "Order ID": data.ExternalTransactionNo,
        "Buyer Postal Code":
          data.DeliveryCustomerAddress &&
          data.DeliveryCustomerAddress.PostalCode
            ? data.DeliveryCustomerAddress.PostalCode
            : "",
        "Order Delivery Status": data.DeliveryStatus,
        "Order Value": data.BillAmount,
        "Order Date and Time": new Date(data.TransactionDate)
          .toString()
          .substring(0, 24),
        "Order Cancelled By": data.CancellationBy ? data.CancellationBy : "",
        "Order Cancellation Reason": data.CancellationReason
          ? data.CancellationReason
          : "",
        "Delivered by": deliveryBy,
        "Delivered Date and Time": "",
      };
      if (this.props.registration.registration.EnablePincodeMerchantInvoiceNo) {
        invoice["Invoice No"] = data.TransactionNo;
        invoice["Invoice Amount"] = data.hasOwnProperty(
          "PincodeMerchantInvoiceAmount"
        )
          ? data.PincodeMerchantInvoiceAmount
          : "";
      }
      if (
        data.DeliveryStatus === "Order-delivered" ||
        data.DeliveryStatus === "Order-picked-up"
      ) {
        invoice["Delivered Date and Time"] = new Date(data.ActionOn)
          .toString()
          .substring(0, 24);
      }

      invoices.push(invoice);
    }
    this.setState({
      totalItemsCount: result.totalItemsCount,
      loading: false,
    });
    return invoices;
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      storeID: this.props.user.store.key,
      recordFrom: 0,
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
    });

    this.renderData(
      storeList,
      0,
      this.state.itemsCountPerPage,
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0]
    );
  }

  async onExportClick() {
    let invoices = await this.loadTransactionData(
      this.state.storeList,
      0,
      10000,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );

    const csvData = constatnts.objToCSV(invoices);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "OrderReport.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ searchFrom: 0, loading: true });
    this.renderData(
      this.state.storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  onStoreChange(value) {
    let storeList = [];
    //console.log("key", e.target.value);
    if (value != "") {
      storeList.push({ key: value });
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    //console.log("storeList", JSON.stringify(storeList));
    this.setState({ storeList: storeList, storeID: value });
  }

  handlePageChange(pageNumber) {
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.renderData(
      this.state.storeList,
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    // this.setState({ paginationStatus: paginationStatus });
    return paginationStatus;
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>Order Report</font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Store"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction From"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionFromDate: value,
                          })
                        }
                        value={this.state.searchTransactionFromDate}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction To"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionToDate: value,
                          })
                        }
                        value={this.state.searchTransactionToDate}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.finalTable)}
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="col-md-12">
                      <div className="pull-left" style={{ marginTop: "25px" }}>
                        {/* {this.state.paginationStatus} */}
                        {this.showPaginationStatus()}
                      </div>
                      <div className="pull-right">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={2}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PincodeOrderReport);
