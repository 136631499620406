import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';

class WidgetPaymentDashboard extends Component {

    constructor({ stores, fromDate, toDate }) {
        super();
        this.state = {
            stores: stores,
            fromDate: fromDate,
            toDate: toDate,
            showPaymentModeChart: true,
            showPaymentModeList: false,
            paymentsData:{
                labels: [],
                datasets: []
            }
        }
    }

    async componentDidMount() {
        this.loadData(this.state.stores, this.state.fromDate, this.state.toDate);
    }

    loadData(stores, fromDate, toDate) { 
 
        this.props.getPaymentModeTransactionTypeWiseSales(this.props.user.user.RegistrationID, stores, '', fromDate, toDate, true)
            .then((result) => {

                let paymentsData = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Payment Mode',
                            backgroundColor: ['#6e2d90', '#00C0EF', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
                                '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                                '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                                '#C77B85', '#C39762'],
                            hoverBackgroundColor: ['#6e2d90', '#00C0EF', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
                                '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                                '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                                '#C77B85', '#C39762'],
                            data: []
                        }
                    ]
                };

                let paymentData = []
                let paymentsShort = []
                for (let index = 0; index < result.searchResult.length; index++) {
                    const payment = result.searchResult[index];

                    //For Pie Chart
                    paymentsData.labels.push(payment.name);
                    paymentsData.datasets[0].data.push(payment.total_mode_amount);
                    paymentsShort.push({ "PaymentMode": payment.name, "Amount": payment.total_mode_amount })
                    var obj = {
                        "Payment Mode": payment.name
                    }

                    this.props.config.TransactionTypes.filter((e) => e.PaymentModeChecked === true && e.SalesImpactChecked === true && !e.IsDeleted).map((e) => {

                        obj[e.TransactionType] = 0;
                        for (let index1 = 0; index1 < payment.transactionTypes.length; index1++) {

                            if (e.TransactionType === payment.transactionTypes[index1].name) {
                                obj[e.TransactionType] = payment.transactionTypes[index1].total_tran_amount
                            }
                        }
                    })
                    obj["Total"] = payment.total_mode_amount
                    paymentData.push(obj);
                }
                var col = [];
                for (var i = 0; i < paymentData.length; i++) {
                    for (var key in paymentData[i]) {
                        if (col.indexOf(key) === -1) {
                            col.push(key);
                        }
                    }
                }
                var result = "<table class='table table-bordered table-striped'><thead><tr>";
                for (var i = 0; i < col.length; i++) {
                    result += "<th>";
                    result += col[i];
                    result += "</th>";
                }
                result += "</tr></thead>";

                // ADD JSON DATA TO THE TABLE AS ROWS.
                result += "<tbody>";
                for (var i = 0; i < paymentData.length; i++) {
                    result += "<tr>";
                    for (var j = 0; j < col.length; j++) {
                        result += j === 0 ? "<td>" : "<td style='text-align:right'>";
                        result += paymentData[i][col[j]] == undefined ? 0 : paymentData[i][col[j]];
                        result += "</td>";
                    }
                    result += "</tr>";
                }
                result += "</tbody>";
                result += "</table>";
                this.setState({ payments: result, paymentsData: paymentsData, paymentsShort: paymentsShort });
            });
    }

    componentWillReceiveProps({ stores, fromDate, toDate }) {
        this.loadData(stores, fromDate, toDate);
    }

    render() {
        return <div className="dashboard-box " >
            <div className="dashboard-box-header ">
                <div className="dashboard-box-title left">Payment Mode </div>
                <div className="right">
                <button type="button"
                    className="btn btn-default btn-flat btn-md pull-right fa fa-list"
                    style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showPaymentModeList ? "#6e2d90" : "gray" }}
                    onClick={() => this.setState({ showPaymentModeChart: false, showPaymentModeList: true })}></button>
                <button type="button"
                    className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
                    style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showPaymentModeChart ? "#6e2d90" : "gray" }}
                    onClick={() => this.setState({ showPaymentModeChart: true, showPaymentModeList: false })}></button>
                </div>
            </div>
            <div className="dashboard-box-body">
                    {this.state.showPaymentModeList && <div className="table-responsive scroll2" >
                        <table className='table table-bordered table-striped'>
                            <thead>
                                <tr>
                                    <th>Payment Mode
                                    </th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.paymentsShort.map((m,index) => <tr key={"payment_"+ index}>
                                    <td>
                                        {m.PaymentMode}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {m.Amount}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>}
                    {this.state.showPaymentModeChart && <div className="chart">
                            <Doughnut
                                width={623}
                                height={300}
                                data={this.state.paymentsData}
                                options={{
                                    maintainAspectRatio: false
                                }}
                            />
                    </div>}
                </div>
    </div>
    }
}

export default WidgetPaymentDashboard