import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';
import { SketchPicker } from 'react-color';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import CreatableSelect from 'react-select'
import { Link } from 'react-router-dom';
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'

class Survey extends Component {

    constructor() {
        super()
        this.state = {
            key:"",
            headerImage: null,
            headerImageLoading: false,
            footerImage: null,
            footerImageLoading: false,   
            headerEditorState: EditorState.createEmpty(),
            footerEditorState: EditorState.createEmpty(),
            submissionEditorState:EditorState.createEmpty(),
            questions:[],
            captureCustomer:false,
            customerCustomField1Display: false,
            customerCustomField2Display: false,
            customerCustomField3Display: false,
            customerCustomField4Display: false,
            customerCustomField5Display: false,
            customerCustomField6Display: false,
            customerCustomField1DisplayName: 'Custom Field 1',
            customerCustomField2DisplayName: 'Custom Field 2',
            customerCustomField3DisplayName: 'Custom Field 3',
            customerCustomField4DisplayName: 'Custom Field 4',
            customerCustomField5DisplayName: 'Custom Field 5',
            customerCustomField6DisplayName: 'Custom Field 6',
            customerNameDisplay:true,
            customerPhoneNoDisplay:true,
            customerEmailIDDisplay:true,
            headerBackgroundColor:'',
            footerBackgroundColor:'',
            headerImageAlign:'center',
            headerImageHeight:'',
            headerImageWidth:'',
            footerImageAlign:'center',
            footerImageHeight:'',
            footerImageWidth:'',
            name:'',
            nameError:'',
            storeNameError:'',
            allStores:false,
            associatedStores: [],
            stores: [],
            facebook:'',
            facebookURL:''
            
        }   
        this.onHeaderImageDrop= this.onHeaderImageDrop.bind(this)
        this.onHeaderImageRemove = this.onHeaderImageRemove.bind(this)
        this.onHeaderEditorStateChange = this.onHeaderEditorStateChange.bind(this)
        this.onHeaderColorSelection= this.onHeaderColorSelection.bind(this)
        this.onFooterImageDrop= this.onFooterImageDrop.bind(this)
        this.onFooterImageRemove = this.onFooterImageRemove.bind(this)
        this.onFooterEditorStateChange = this.onFooterEditorStateChange.bind(this)
        this.onFooterColorSelection= this.onFooterColorSelection.bind(this)
        this.onSave= this.onSave.bind(this)
        this.onSubmissionEditorStateChange = this.onSubmissionEditorStateChange.bind(this)
        this.onQuestionChange = this.onQuestionChange.bind(this)
        this.onQuestionTypeChange = this.onQuestionTypeChange.bind(this)
        this.addQuestion = this.addQuestion.bind(this)
        this.onOptionChange = this.onOptionChange.bind(this)
        this.onOptionPointChange = this.onOptionPointChange.bind(this)
        this.removeOption = this.removeOption.bind(this)
        this.addOption = this.addOption.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.onOptionRatingChange = this.onOptionRatingChange.bind(this)
        this.removeQuestion = this.removeQuestion.bind(this)
        this.formControls = this.formControls.bind(this)
    }

    onQuestionChange(index, question)
    {
        let questions = this.state.questions
        questions[index].Question = question
        questions[index].QuestionError=""
        this.setState({questions:questions})
    }

    onQuestionTypeChange(index, questionTypeID, questionType)
    {
        // console.log('index',index)
        // console.log('questionTypeID', questionTypeID)
        // console.log('questionType', questionType)
        let questions = this.state.questions
        questions[index].QuestionTypeID= questionTypeID
        questions[index].QuestionType= questionType
        if(questionTypeID==="3" || questionTypeID==="4")
        {
            questions[index].Options=[]
            questions[index].Options.push({OptionText:"", OptionPoints:"",OptionError:""})
            questions[index].Options.push({OptionText:"", OptionPoints:"", OptionError:""})
        }
        else if(questionTypeID==="5")
        {
            questions[index].Rating = 5
        }
        else{
            questions[index].Options= []
        }
        // console.log('questions',questions)
        this.setState({questions:questions})
    }

    addQuestion()
    {
        let questions = this.state.questions
        questions.push({Question:"", QuestionTypeID:0, QuestionType:"", QuestionError:"", QuestionTypeError:""})
        this.setState({questions:questions})
    }
 
    removeQuestion(index)
    {
        let questions = this.state.questions
        questions.splice(index,1)
        this.setState({questions:questions})
    }

    onOptionChange(index, optionIndex, optionText){
        let questions = this.state.questions
        questions[index].Options[optionIndex].OptionText = optionText
        questions[index].Options[optionIndex].OptionError= ""
        this.setState({questions:questions})
    }

    onOptionRatingChange(index, rating){
        let questions = this.state.questions
        questions[index].Rating = rating
        this.setState({questions:questions})
    }
    onOptionPointChange(index, optionIndex, optionPoint){
        let questions = this.state.questions
        questions[index].Options[optionIndex].OptionPoints = optionPoint
        this.setState({questions:questions})
    }

    removeOption(index, optionIndex)
    {
        // console.log('optionIndex',optionIndex)
        let questions = this.state.questions
        questions[index].Options.splice(optionIndex,1)
        this.setState({questions:questions})
    }

    addOption(index)
    {
        let questions = this.state.questions
        questions[index].Options.push({OptionText:"", OptionPoints:"",OptionError:""})
        this.setState({questions:questions})
    }

    async componentDidMount()
    {
       let result=  await this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
       , "", "", "", "")
        // console.log('result',result)
        let stores = result.searchResult;
        const storeList = [];
        stores.map((store) => {
            let obj = {
                value: store.key, label: store.StoreName
            }
            storeList.push(obj);
        });
        // console.log('storeList',storeList)
        this.setState({ stores: storeList })

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customerCustomField1DisplayName: customField1DisplayName,
                    customerCustomField2DisplayName: customField2DisplayName,
                    customerCustomField3DisplayName: customField3DisplayName,
                    customerCustomField4DisplayName: customField4DisplayName,
                    customerCustomField5DisplayName: customField5DisplayName,
                    customerCustomField6DisplayName: customField6DisplayName,
                })
            }
        })
        const key = this.props.match.params.key
        if (key) {
            // this.setState({ key: key })
            let survey = await this.props.getSurveyDetails(key)
            if(survey)
            {
                // console.log('survey',survey)
                let hblocksFromHtml = htmlToDraft(survey.HeaderText);
                let hcontentState = ContentState.createFromBlockArray(hblocksFromHtml.contentBlocks, hblocksFromHtml.entityMap);
                let heditorState = EditorState.createWithContent(hcontentState);

                let fblocksFromHtml = htmlToDraft(survey.FooterText);
                let fcontentState = ContentState.createFromBlockArray(fblocksFromHtml.contentBlocks, fblocksFromHtml.entityMap);
                let feditorState = EditorState.createWithContent(fcontentState);

                let sblocksFromHtml = htmlToDraft(survey.hasOwnProperty('SubmissionText') ?  survey.SubmissionText: '');
                let scontentState = ContentState.createFromBlockArray(sblocksFromHtml.contentBlocks, sblocksFromHtml.entityMap);
                let seditorState = EditorState.createWithContent(scontentState);


                this.setState({
                    key:key,
                    name: survey.Name,
                    questions:survey.Questions,
                    allStores: survey.AllStores,
                    associatedStores: survey.Stores,
                    headerImage: survey.HeaderImage,
                    footerImage: survey.FooterImage,
                    headerEditorState:  heditorState ? heditorState : EditorState.createEmpty(),
                    footerEditorState: feditorState ? feditorState: EditorState.createEmpty(),
                    submissionEditorState: seditorState ?  seditorState : EditorState.createEmpty(),
                    customerCustomField1Display: survey.hasOwnProperty('CustomerCustomField1Display')? survey.CustomerCustomField1Display:'',
                    customerCustomField2Display: survey.hasOwnProperty('CustomerCustomField2Display')?survey.CustomerCustomField2Display:'',
                    customerCustomField3Display: survey.hasOwnProperty('CustomerCustomField3Display')?survey.CustomerCustomField3Display:'',
                    customerCustomField4Display: survey.hasOwnProperty('CustomerCustomField4Display')?survey.CustomerCustomField4Display:'',
                    customerCustomField5Display: survey.hasOwnProperty('CustomerCustomField5Display')?survey.CustomerCustomField5Display:'',
                    customerCustomField6Display: survey.hasOwnProperty('CustomerCustomField6Display')?survey.CustomerCustomField6Display:'',
                    customerNameDisplay:survey.hasOwnProperty('CustomerNameDisplay')?survey.CustomerNameDisplay:'',
                    customerPhoneNoDisplay:survey.hasOwnProperty('CustomerPhoneNoDisplay')?survey.CustomerPhoneNoDisplay:'',
                    customerEmailIDDisplay:survey.hasOwnProperty('CustomerEmailIDDisplay')?survey.CustomerEmailIDDisplay:'',
                    headerBackgroundColor:survey.hasOwnProperty('HeaderBackgroundColor')?survey.HeaderBackgroundColor:'',
                    footerBackgroundColor: survey.hasOwnProperty('FooterBackgroundColor')?survey.FooterBackgroundColor:'',
                    headerImageAlign:survey.hasOwnProperty('HeaderImageAlign') ? survey.HeaderImageAlign : '',
                    headerImageHeight:survey.hasOwnProperty('HeaderImageHeight') ? survey.HeaderImageHeight : '',
                    headerImageWidth:survey.hasOwnProperty('HeaderImageWidth') ? survey.HeaderImageWidth : '',
                    footerImageAlign:survey.hasOwnProperty('FooterImageAlign') ? survey.FooterImageAlign : '',
                    footerImageHeight:survey.hasOwnProperty('FooterImageHeight') ? survey.FooterImageHeight : '',
                    footerImageWidth:survey.hasOwnProperty('FooterImageWidth') ? survey.FooterImageWidth : '',
                    facebook: survey.hasOwnProperty('Facebook') ? survey.Facebook :'',
                    facebookURL:survey.hasOwnProperty('FacebookURL') ? survey.FacebookURL :''
                    })
                }
        }
        else
        {
            this.addQuestion()
        }
    }


    onHeaderColorSelection = (color) => {
        // console.log('color', color)
        this.setState({ headerBackgroundColor: color.hex });
      };

    onHeaderEditorStateChange(editorState) {
        this.setState({
            headerEditorState: editorState
        })
    }

    onSubmissionEditorStateChange(editorState) {
        this.setState({
            submissionEditorState: editorState
        })
    }

    onHeaderImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ headerImageLoading: true })
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/PrintConfig').then((downloadURL) => {
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                this.setState({ headerImage: image })
                this.setState({ headerImageLoading: false })
            })
        }
        else { alert("No file selected") }
    }

    onHeaderImageRemove() {
        this.setState({ headerImage: null, headerImageLoading: false })
    }
    
    onFooterColorSelection = (color) => {
        this.setState({ footerBackgroundColor: color.hex });
      };

    onFooterEditorStateChange(editorState) {
        this.setState({
            footerEditorState: editorState
        })
    }

    onFooterImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ footerImageLoading: true })
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/PrintConfig').then((downloadURL) => {
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                this.setState({ footerImage: image })
                this.setState({ footerImageLoading: false })
            })
        }
        else { alert("No file selected") }
    }

    onFooterImageRemove() {
        this.setState({ footerImage: null, footerImageLoading: false })
    }

    onSave()
    {
        this.setState({saving:true})
        //check for mandatory fields
        let questionError = false
        let nameError=""
        let storeNameError=""
        let questions = this.state.questions
        for (let i = 0; i < questions.length; i++) {
            if(questions[i].Question.trim()==="")
            {
                questions[i].QuestionError ="Please provide value"
                questionError= true
            }
            if(questions[i].QuestionType.trim()===""){
                questions[i].QuestionTypeError="Please provide value"
                questionError= true
            }
            if(questions[i].hasOwnProperty('Options') && questions[i].Options.length> 0)
            {
                for (let j = 0; j < questions[i].Options.length; j++) {
                    if(questions[i].Options[j].OptionText.trim()==="")
                    {
                        questions[i].Options[j].OptionError="Please provide value"
                        questionError= true
                    }
                }
            }
        }
        if(this.state.name.trim()==="")
        {
            nameError = "Please provide name"
        }
        if(!this.state.allStores && this.state.associatedStores.lenght)
        {
            storeNameError="Please select"   
        }
        if(questionError || nameError!=="" || storeNameError!=="")
        {
            this.setState({questions:questions,nameError:nameError,storeNameError:storeNameError, saving:false})
            return
        }
       
        let survey = {
            HeaderText: draftToHtml(convertToRaw(this.state.headerEditorState.getCurrentContent())),
            HeaderImage: this.state.headerImage,
            FooterText: draftToHtml(convertToRaw(this.state.footerEditorState.getCurrentContent())),
            SubmissionText:draftToHtml(convertToRaw(this.state.submissionEditorState.getCurrentContent())),
            FooterImage: this.state.footerImage,
            CustomerCustomField1Display: this.state.customerCustomField1Display,
            CustomerCustomField2Display: this.state.customerCustomField2Display,
            CustomerCustomField3Display: this.state.customerCustomField3Display,
            CustomerCustomField4Display: this.state.customerCustomField4Display,
            CustomerCustomField5Display: this.state.customerCustomField5Display,
            CustomerCustomField6Display: this.state.customerCustomField6Display,
            CustomerNameDisplay:this.state.customerNameDisplay,
            CustomerPhoneNoDisplay:this.state.customerPhoneNoDisplay,
            CustomerEmailIDDisplay:this.state.customerEmailIDDisplay,
            HeaderBackgroundColor:this.state.headerBackgroundColor,
            FooterBackgroundColor: this.state.footerBackgroundColor,
            RegistrationID: this.props.registration.registration.key,
            HeaderImageAlign:this.state.headerImageAlign,
            HeaderImageHeight:this.state.headerImageHeight,
            HeaderImageWidth:this.state.headerImageWidth,
            FooterImageAlign:this.state.footerImageAlign,
            FooterImageHeight:this.state.footerImageHeight,
            FooterImageWidth:this.state.footerImageWidth,
            Name: this.state.name,
            Questions:this.state.questions,
            AllStores: this.state.allStores,
            Stores: this.state.allStores ? [] :this.state.associatedStores,
            RegistrationID: this.props.registration.registration.key,
            Facebook:this.state.facebook,
            FacebookURL:this.state.facebookURL,
            IsDeleted:false
        }
        
        // console.log('survey', survey)
        this.props.saveSurvey(survey, this.state.key).then(()=>{
            this.props.getToastr("Data saved successfully")
            setTimeout(function() { //Start the timer
                this.setState({ saving:false})
            }.bind(this), 1000)
            this.props.history.push('/home/survey/')
        })
    }

    handleOnChange(storeList) {
        this.setState({ associatedStores: storeList, storeNameError: "" });
    }

    formControls(){
        return <React.Fragment>
            <div className="box ">
                    <div className="box-header">
                        {/* <h3 className="box-title">Header</h3> */}
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className={this.state.nameError.trim() !== "" ? "form-group has-error" : "form-group "}>
                                    <label>Name</label>
                                    <input 
                                        // onKeyPress={this.handleKeyPress} 
                                        type="text" className="form-control"
                                        onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })}
                                        value={this.state.name} />
                                    {this.state.nameError.trim() !== "" && <span className="help-block">{this.state.nameError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={this.state.nameError.trim() !== "" ? "form-group has-error" : "form-group "}>
                                    <label>Applicable Stores</label>
                                    <select className="form-control"
                                        onChange={(evt)=> this.setState({allStores: evt.target.value==="all" ? true : false})} 
                                        value={this.state.allStores ? "all" : "specific"}>
                                        <option value="all">All</option>
                                        <option value="specific">Specific</option>
                                    </select>
                                    {this.state.nameError.trim() !== "" && <span className="help-block">{this.state.nameError}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">

                            </div>
                            <div className="col-md-6">
                                {!this.state.allStores &&
                                <div className={this.state.storeNameError !== "" ? "form-group has-error" : "form-group "}>
                                     <label>Stores</label>
                                    <CreatableSelect
                                        defaultValue={this.state.associatedStores}
                                        options={this.state.stores}
                                        onChange={this.handleOnChange}
                                        isMulti
                                    />
                                    {this.state.storeNameError !== "" && <span className="help-block">{this.state.storeNameError}</span>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Header</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <Editor
                                        editorState={this.state.headerEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onHeaderEditorStateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Header Image</label>
                                    {!this.state.headerImageLoading && <React.Fragment>
                                        {!this.state.headerImage &&
                                            <Dropzone onDrop={(files) => this.onHeaderImageDrop(files)} >
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                                        {this.state.headerImage && <div className="form-group">
                                            <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.headerImage.Preview}
                                                        alt="Attachment" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{this.state.headerImage.Name.length > 20 ? this.state.headerImage.Name.substr(0, 20) + "..." : this.state.headerImage.Name}</span>
                                                        <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.headerImage.Size / 1024)} KB
                                                                                <a onClick={this.onHeaderImageRemove}
                                                                className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                    <td>
                                                        Align:
                                                    </td>
                                                    <td>
                                                        <select onChange={(evt)=> this.setState({headerImageAlign: evt.target.value})} 
                                                            value={this.state.headerImageAlign}>
                                                            <option value="center">Center</option>
                                                            <option value="left">Left</option>
                                                            <option value="right">Right</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Width:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number" 
                                                        onChange={(evt)=> this.setState({headerImageWidth: evt.target.value})} 
                                                        value={this.state.headerImageWidth}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Height:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number"  
                                                        onChange={(evt)=> this.setState({headerImageHeight: evt.target.value})} 
                                                        value={this.state.headerImageHeight}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>}
                                    </React.Fragment>}
                                    {this.state.headerImageLoading && <div>Uploading image</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group">
                                <label >Background Color</label>
                                <SketchPicker
                                    color={ this.state.headerBackgroundColor }
                                    onChangeComplete={ this.onHeaderColorSelection }
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Question</h3>
                    </div>
                <div className="box-body">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.questions.map((m,index) => <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className={m.QuestionError !=="" ? "form-group has-error" : "form-group "} >
                                            <label >{index+1} Question</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.onQuestionChange(index, evt.target.value)}
                                                value={m.Question}
                                                placeholder="" />
                                            {m.QuestionError !=="" && <span className="help-block">{m.QuestionError}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className={m.QuestionTypeError !=="" ? "form-group has-error" : "form-group "} >
                                            <label>Type</label>
                                            <select type="text" className="form-control"
                                            onChange={(e) => this.onQuestionTypeChange(index, 
                                                e.target.value,
                                                e.target.options[e.target.selectedIndex].text)}
                                                value={m.QuestionTypeID}>
                                                <option value="">Select Type</option>
                                                <option value="1">Text</option>
                                                <option value="2">Comments</option>
                                                <option value="3">Multi choice</option>
                                                <option value="4">Dropdown</option>
                                                <option value="5">Star rating</option>
                                            </select>
                                            {m.QuestionTypeError !=="" && <span className="help-block">{m.QuestionTypeError}</span>}
                                        </div>
                                    </div>
                                </div>
                                {m.QuestionTypeID === "5" && <div className="row">
                                        <div className="col-md-2" style={{paddingBottom:"20px"}}>
                                            <label >Rating</label>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.onOptionRatingChange(index, evt.target.value)}
                                                    value={m.Rating}
                                                />
                                        </div>
                                </div>}
                                {(m.Options && m.Options.length > 0) && <React.Fragment>
                                    {m.Options.map((option,optionIndex)=><div className="row">
                                        <div className="col-md-8 col-xs-8">
                                            <div className={option.OptionError !=="" ? "form-group has-error" : "form-group "} >
                                                {optionIndex === 0 && <label >Option</label>}
                                                <input type="text" className="form-control"
                                                    onChange={(evt) => this.onOptionChange(index,optionIndex, evt.target.value)}
                                                    value={option.OptionText}
                                                />
                                                {option.OptionError !=="" && <span className="help-block">{option.OptionError}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-xs-2">
                                            <div className="form-group " >
                                                {optionIndex ===0 && <label>Points</label>}
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.onOptionPointChange(index, optionIndex, evt.target.value)}
                                                    value={option.OptionPoints}
                                                />
                                            </div>
                                        </div>
                                        {optionIndex >1 && <div className="col-md-1 col-xs-2" >
                                            <button className="btn btn-md btn-flat btn-default" onClick={()=> this.removeOption(index,optionIndex)}>{this.props.isMobileApp() && <i className="fa fa-trash-o"/>}{!this.props.isMobileApp() && <span>Remove</span>}</button>
                                        </div>}
                                        {(m.Options.length-1 === optionIndex) && <div className="col-md-2 col-xs-4" style={{paddingBottom:"10px"}}>
                                        <button className="btn btn-md btn-flat btn-default"  onClick={()=>this.addOption(index)}>Add Option</button>
                                    </div>}
                                    </div>)}
                                    {/* <div className="col-md-8">
                                    </div>
                                    <div className="col-md-2">
                                    </div>
                                    <div className="col-md-2" style={{paddingLeft:"0px",paddingBottom:"10px"}}>
                                        <button className="btn btn-md btn-flat btn-default"  onClick={()=>this.addOption(index)}>Add Option</button>
                                    </div> */}
                                    </React.Fragment>}
                                    </div>
                                    {index> 0 && <div className="col-md-12" style={{paddingBottom:"20px"}}>
                                        <button className="btn btn-md btn-flat btn-default"  onClick={()=>this.removeQuestion(index)}>Remove Question</button>
                                    </div>}
                                </div>)}
                                <div className="row">
                                    <div className="col-md-12" >
                                        <button className="btn btn-md btn-flat btn-default"  onClick={this.addQuestion}>Add Question</button>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                </div>
                </div>
                {/* <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Customer information</h3>
                    </div>
                    <div className="box-body">
                        <div className="col-md-12">
                            <div className="form-group " >
                                <label >Capture customer information</label>
                                <input type="checkbox" 
                                    onChange={() => this.setState({captureCustomer: !this.state.captureCustomer})}
                                    value={this.state.captureCustomer}
                                />
                            </div>
                        </div>
                        {this.state.captureCustomer && <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th>Field</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerNameDisplay}
                                        onChange={() =>this.setState({ customerNameDisplay: !this.state.customerNameDisplay})}/>
                                    </td>
                                    <td>Full Name</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerPhoneNoDisplay}
                                        onChange={() =>this.setState({ customerPhoneNoDisplay: !this.state.customerPhoneNoDisplay})}/>
                                    </td>
                                    <td>Phone No</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerEmailIDDisplay}
                                        onChange={() =>this.setState({ customerEmailIDDisplay: !this.state.customerEmailIDDisplay})}/>
                                    </td>
                                    <td>Email ID</td>
                                </tr>
                                <tr>                                    
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField1Display}
                                        onChange={() =>this.setState({ customerCustomField1Display: !this.state.customerCustomField1Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField1DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField2Display}
                                        onChange={() =>this.setState({ customerCustomField2Display: !this.state.customerCustomField2Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField2DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField3Display}
                                        onChange={() =>this.setState({ customerCustomField3Display: !this.state.customerCustomField3Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField3DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField4Display}
                                        onChange={() =>this.setState({ customerCustomField4Display: !this.state.customerCustomField4Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField4DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField5Display}
                                        onChange={() =>this.setState({ customerCustomField5Display: !this.state.customerCustomField5Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField5DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField6Display}
                                        onChange={() =>this.setState({ customerCustomField6Display: !this.state.customerCustomField6Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField6DisplayName}</td>
                                </tr>
                            </tbody>
                        </table>}
                    </div>
                </div> */}
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Footer</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <Editor
                                        editorState={this.state.footerEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onFooterEditorStateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Footer Image</label>
                                    {!this.state.footerImageLoading && <React.Fragment>
                                        {!this.state.footerImage &&
                                            <Dropzone onDrop={(files) => this.onHeaderImageDrop(files)} >
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                                        {this.state.footermage && <div className="form-group">
                                            <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.footerImage.Preview}
                                                        alt="Attachment" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{this.state.footerImage.Name.length > 20 ? this.state.footerImage.Name.substr(0, 20) + "..." : this.state.footerImage.Name}</span>
                                                        <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.footerImage.Size / 1024)} KB
                                                                                <a onClick={this.onFooterImageRemove}
                                                                className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                    <td>
                                                        Align:
                                                    </td>
                                                    <td>
                                                        <select onChange={(evt)=> this.setState({footerImageAlign: evt.target.value})} 
                                                            value={this.state.footerImageAlign}>
                                                            <option value="center">Center</option>
                                                            <option value="left">Left</option>
                                                            <option value="right">Right</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Width:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number" 
                                                        onChange={(evt)=> this.setState({footerImageWidth: evt.target.value})} 
                                                        value={this.state.footerImageWidth}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Height:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number"  
                                                        onChange={(evt)=> this.setState({footerImageHeight: evt.target.value})} 
                                                        value={this.state.footerImageHeight}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>}
                                    </React.Fragment>}
                                    {this.state.footerImageLoading && <div>Uploading image</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group">
                                <label >Background Color</label>
                                <SketchPicker
                                    color={ this.state.footerBackgroundColor }
                                    onChangeComplete={ this.onFooterColorSelection }
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">On submission</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <label >Completion message</label>
                                <div>
                                    <Editor
                                        editorState={this.state.submissionEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onSubmissionEditorStateChange}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="col-md-12">
                                    <div className="form-group ">
                                        <label>Include Facebook</label>
                                        <select className="form-control"
                                            value={this.state.facebook}
                                            onChange={(evt)=>this.setState({facebook:evt.target.value})}>
                                            <option value="">Select</option>
                                            <option value="like">Like</option>
                                            <option value="share">Share</option>
                                        </select>
                                    </div>
                                </div>
                                {this.state.facebook.trim() !=="" &&<div className="col-md-12">
                                    <div className="form-group ">
                                        <label>Facebook - URL</label>
                                        <input type="text" className="form-control"
                                            value={this.state.facebookURL}
                                            onChange={(evt)=>this.setState({facebookURL:evt.target.value})}>
                                        </input>
                                    </div>
                                </div>}
                            </div> */}
                        </div>
                    </div>
                </div>
        </React.Fragment>
    }

    render() {
        return <React.Fragment>

        {!this.props.isMobileApp() && <div className="content-wrapper">
            <section className="content-header">
                <h1>Survey</h1>
                <button type="submit"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-md btn-success pull-right btn-flat"
                    onClick={this.onSave}>Save</button>
                    <Link to="/home/survey/" type="submit" className="btn btn-flat btn-md btn-default pull-right" 
                style={{ marginTop: "-25px" }}>Cancel</Link>
            </section>
            <section className="content" >
                {this.formControls()}
            </section>
        </div>}

        {this.props.isMobileApp() &&  <React.Fragment>   
            <HeaderNew {...this.props}
                back={true}
                title= { this.state.key === "" ? "Add New Survey" : "Edit Survey" }
                onBackClick={() => {  this.props.history.push({ pathname: '/home'})   }}
            />
            <div className="header-new-background"/>
            <div className="content-wrapper">
                <div className="container">
                    <br/>
                    {this.formControls()}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Footer onClick={()=> this.onSave()} />
                </div>
            </div>
        </React.Fragment>}
    </React.Fragment>
    }
}
export default Survey