import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
// import { isNullOrUndefined } from 'util';
import ReactHtmlParser from 'react-html-parser';
import Loader from '../Loader'
import { result } from 'lodash';

class InventoryPurchaseSalesInStock extends Component {
    constructor() {
        super()
        this.state = {
            products: [],
            stores: [],
            storeList: [],
            searchText: '',
            searchStoreID: '',
            searchCategory: '',
            searchSubCategory: '',
            searchProductSKU: '',
            searchProductName: '',
            searchBrand: '',
            exportCalled: false,
            loading: false,
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0]
        }
        this.loadInventoryHistory = this.loadInventoryHistory.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.loadInventoryHistoryData = this.loadInventoryHistoryData.bind(this);
        this.getDataForProducts = this.getDataForProducts.bind(this);
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });
        // this.loadInventoryHistory(storeList, this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
        //     this.state.searchCategory,
        //     this.state.searchSubCategory, this.state.searchProductSKU,
        //     this.state.searchProductName, this.state.searchBrand);
    }


    async loadInventoryHistory(storeList, searchTransactionFromDate, searchTransactionToDate,
        searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand) {
        this.setState({ loading: true })

        let finalProducts = await this.loadInventoryHistoryData(storeList, searchTransactionFromDate, searchTransactionToDate,
            searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand);
        
        //console.log("finalProducts",JSON.stringify(finalProducts));

        var col = [];
        for (var i = 0; i < finalProducts.length; i++) {
            for (var key in finalProducts[i]) {
                if (col.indexOf(key) === -1 && (key !== "Inward" && key !== "Outward"  && key !== "Opening Stock")) {
                    // console.log(key)
                    col.push(key);
                }
            }
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < finalProducts.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result +=  j> 4 ? "<td style='text-align:right'>":"<td>";
                result += finalProducts[i][col[j]] === undefined ? 0 : finalProducts[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }

        // ADD Totals
        result +="<tr><td style='font-weight:bold'>Totals</td>"
        result +="<td style='font-weight:bold'>-</td>"
        result +="<td style='font-weight:bold'>-</td>"
        result +="<td style='font-weight:bold'>-</td>"
        result +="<td style='font-weight:bold'>-</td>"
        result +="<td style='font-weight:bold'>-</td>"
        result +="<td style='font-weight:bold'>-</td>"
        for (var j = 7; j < col.length; j++) {    
            let total = 0
            for (var i = 0; i < finalProducts.length; i++) {
                total += (finalProducts[i][col[j]] === undefined ? 0 : Number(finalProducts[i][col[j]]))
            }
            result += "<td style='font-weight:bold;text-align:right'>";
            result += Number(total).toFixed(2) ;
            result += "</td>";
        }
        result +="</tr>"

        result += "</tbody>";
        result += "</table>";
        this.setState({ finalTable: result, loading: false })
    }

    async loadInventoryHistoryData(
        storeList, 
        searchTransactionFromDate, 
        searchTransactionToDate,
        searchCategory, 
        searchSubCategory, 
        searchProductSKU, 
        searchProductName, 
        searchBrand)
    {

        let transactionTypes = await this.props.config.TransactionTypes.filter((f) => f.UpdateStockChecked === true && !f.IsDeleted);  

        let result = await this.props.getActiveProductsForInventoryHistory(
            this.props.user.user.RegistrationID, 
            '', this.props.user.store.key, 
            searchCategory, 
            searchSubCategory, 
            searchProductSKU, 
            searchProductName, 
            searchBrand);

        let finalProducts = await this.getDataForProducts(
            storeList, 
            searchTransactionFromDate, 
            searchTransactionToDate, 
            result.searchResult,
            transactionTypes)

        let scrollID = result.scrollID;

        for (let index = 1; index < (result.totalItemsCount / 2000); index++) {

            let result = await this.props.getActiveProductsForInventoryHistory(
                this.props.user.user.RegistrationID, 
                scrollID, 
                this.props.user.store.key);
            
            let returnData = await this.getDataForProducts(storeList, 
            searchTransactionFromDate, 
            searchTransactionToDate, 
            result.searchResult,
            transactionTypes);
            
            returnData.forEach(element => {
                finalProducts.push(element)
            });
        }
        
        //get instock value and calulate closing stock value
  
        console.log('finalProducts', finalProducts)
        return finalProducts;

    }

    async getDataForProducts(storeList, 
        searchTransactionFromDate, 
        searchTransactionToDate, 
        products,
        transactionTypes){

        let productIDs = [];
        for (let i = 0; i < products.length; i++) {
            const prod = products[i];
            productIDs.push(prod.key);
        }
        
        let denomSalesHistoryList = await this.props.getDenomSalesHistoryReport(
            storeList, 
            searchTransactionFromDate, 
            searchTransactionToDate, 
            productIDs)
        .then((result) => {
                return result.searchResult;
        });
            
        let denomInventoryHistoryList = await this.props.getDenomInventoryHistoryReport(
            storeList, 
            searchTransactionFromDate, 
            searchTransactionToDate, 
            productIDs)
        .then((result) => {
            return result.searchResult;
        });

        let instock = await this.props.getInstockQtybyProductIDs(storeList,productIDs).then((result)=>{
            return result.searchResult;
        })

        let finalProducts = []

        for (let p = 0; p < products.length; p++) {
            const prod = products[p];

            let finalProduct = {
                "Brand" : prod.Brand,
                "Category" : prod.Category,
                "Sub Category" : prod.SubCategory,
                "SKU" : prod.SKU,
                "Name" : prod.Name,
                "Retail Price": prod.RetailPrice,
                "Supply Price": prod.SupplyPrice,
                "Purchase" : 0,
                "Sales": 0, 
                "InStockQty":0,
                "StockValue":0
            }
            
            let dataList1 = denomInventoryHistoryList.filter((i) => i.sku === prod.key);
            for (let d1 = 0; d1 < dataList1.length; d1++) {
                if(transactionTypes.filter((e) => e.TransactionType === dataList1[d1].transaction_type && (e.TransactionMovement ==="vendortostore" || e.TransactionMovement ==="storetovendor")).length ===0){
                    continue;
                }
                let denomTransactionType = transactionTypes.filter((e) => e.TransactionType === dataList1[d1].transaction_type && (e.TransactionMovement ==="vendortostore" || e.TransactionMovement ==="storetovendor"))[0];
                console.log('denomTransactionType',denomTransactionType)
                if (denomTransactionType && denomTransactionType.UpdateStockInwardOrOutward === "inward") {
                    finalProduct.Purchase = Number(finalProduct.Purchase) + Number(dataList1[d1].total_quantity);
                }
                else if (denomTransactionType && denomTransactionType.UpdateStockInwardOrOutward === "outward") {
                    finalProduct.Purchase = Number(finalProduct.Purchase) + Number(dataList1[d1].total_quantity);
                }
            }

            let dataList2 = denomSalesHistoryList.filter((i) => i.sku === prod.key);
            for (let d2 = 0; d2 < dataList2.length; d2++) {

                let denomSalesTransactionType = transactionTypes.filter((e) => e.TransactionType === dataList2[d2].transaction_type)[0];

                if(denomSalesTransactionType && denomSalesTransactionType.UpdateStockChecked)
                {
                    finalProduct.Sales = Number(finalProduct.Sales) +  Number(dataList2[d2].total_quantity);
                }
            }

            let dataist3 = instock.filter((i)=>i.sku === prod.key);
            if(dataist3.length > 0) {
                finalProduct.InStockQty =  Number(dataist3[0].total_quantity);
                finalProduct.StockValue =  Number(dataist3[0].total_quantity) * Number(prod.SupplyPrice);
                finalProduct.StockValue =  Number(finalProduct.StockValue).toFixed(2)
            }

            finalProducts.push(finalProduct);
        }

        return finalProducts;
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});

        this.setState({
            storeList : storeList, searchTransactionFromDate: new Date().toISOString().split('T')[0], searchTransactionToDate: new Date().toISOString().split('T')[0]
            ,searchCategory: '', searchSubCategory: '', searchProductSKU: '', searchProductName: '', searchBrand: ''
        })
        this.loadInventoryHistory(storeList, new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "", "", "", "");
    }

    async onExportClick() {
        this.setState({ loading: true });
        
        let finalProducts = await this.loadInventoryHistoryData(this.state.storeList, this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchCategory, this.state.searchSubCategory, this.state.searchProductSKU, this.state.searchProductName, this.state.searchBrand);

        const csvData = constatnts.objToCSV(finalProducts);
        this.setState({ loading: false, exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "InventoryHistory.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    
    onSearchClick() {
        this.setState({ loading: true })
        this.loadInventoryHistory(
            this.state.storeList, this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e){
        let storeList = []
        if (e.target.value !=="") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Inventory Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            <option value="">All Stores</option>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>                                    
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchBrand}
                                                onChange={(evt) => this.setState({ searchBrand: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{ padding: "0px" }}>
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductSKU}
                                                onChange={(evt) => this.setState({ searchProductSKU: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductName}
                                                onChange={(evt) => this.setState({ searchProductName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-12" >
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download" ></i></button>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <h4><b>Total Qty: {Number(this.state.instockTotals.totalqty).toFixed(2)}</b></h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <h4><b>Total Purchase : {Number(this.state.instockTotals.totalpurchase).toFixed(2)}</b></h4>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <h4><b>Total Sales : {Number(this.state.instockTotals.totalsales).toFixed(2)}</b></h4>
                                        </div>
                                    </div>
                                </div> */}
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    {ReactHtmlParser(this.state.finalTable)}
                                    
                                        </div>
                                    }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(InventoryPurchaseSalesInStock)