import React, { Component } from "react";

import scan from "../../../images/barcode-scanner.png";

class Icon extends Component {
  renderIcon() {
    if (this.props.type === "customer") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" />
          <path d="M9,14a9.011,9.011,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.011,9.011,0,0,0,9,14Z" />
          <path d="M22,7.875a2.107,2.107,0,0,0-2,2.2,2.107,2.107,0,0,0-2-2.2,2.107,2.107,0,0,0-2,2.2c0,1.73,2.256,3.757,3.38,4.659a.992.992,0,0,0,1.24,0c1.124-.9,3.38-2.929,3.38-4.659A2.107,2.107,0,0,0,22,7.875Z" />
        </svg>
      );
    }
    if (this.props.type === "catalogue") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M4.5,17.5H2a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,4.5,17.5Zm0,4.5H2V19.5H4.5Z" />
          <path d="M22,17.5H19.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V19.5A2,2,0,0,0,22,17.5ZM22,22H19.5V19.5H22Z" />
          <path d="M4.5,8.75H2a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,4.5,8.75Zm0,4.5H2v-2.5H4.5Z" />
          <path d="M22,8.75H19.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,22,8.75Zm0,4.5H19.5v-2.5H22Z" />
          <path d="M4.5,0H2A2,2,0,0,0,0,2V4.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V2A2,2,0,0,0,4.5,0Zm0,4.5H2V2H4.5Z" />
          <path d="M13.25,17.5h-2.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,13.25,17.5Zm0,4.5h-2.5V19.5h2.5Z" />
          <path d="M13.25,8.75h-2.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,13.25,8.75Zm0,4.5h-2.5v-2.5h2.5Z" />
          <path d="M13.25,0h-2.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V2A2,2,0,0,0,13.25,0Zm0,4.5h-2.5V2h2.5Z" />
          <path d="M22,0H19.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,4.5H19.5V2H22Z" />
        </svg>
      );
    }
    if (this.props.type === "sales") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23,19H21V17a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V21h2a1,1,0,0,0,0-2Z" />
          <path d="M21,6H18A6,6,0,0,0,6,6H3A3,3,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5h9a1,1,0,0,0,0-2H5a3,3,0,0,1-3-3V9A1,1,0,0,1,3,8H6v2a1,1,0,0,0,2,0V8h8v2a1,1,0,0,0,2,0V8h3a1,1,0,0,1,1,1v5a1,1,0,0,0,2,0V9A3,3,0,0,0,21,6ZM8,6a4,4,0,0,1,8,0Z" />
        </svg>
      );
    }
    if (this.props.type === "inventory") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z" />
          <path d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z" />
          <path d="M20,13H17a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V17A4,4,0,0,0,20,13Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z" />
          <path d="M14,7h3v3a1,1,0,0,0,2,0V7h3a1,1,0,0,0,0-2H19V2a1,1,0,0,0-2,0V5H14a1,1,0,0,0,0,2Z" />
        </svg>
      );
    }
    if (this.props.type === "staff") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M7.5,13A4.5,4.5,0,1,1,12,8.5,4.505,4.505,0,0,1,7.5,13Zm0-7A2.5,2.5,0,1,0,10,8.5,2.5,2.5,0,0,0,7.5,6ZM15,23v-.5a7.5,7.5,0,0,0-15,0V23a1,1,0,0,0,2,0v-.5a5.5,5.5,0,0,1,11,0V23a1,1,0,0,0,2,0Zm9-5a7,7,0,0,0-11.667-5.217,1,1,0,1,0,1.334,1.49A5,5,0,0,1,22,18a1,1,0,0,0,2,0ZM17.5,9A4.5,4.5,0,1,1,22,4.5,4.505,4.505,0,0,1,17.5,9Zm0-7A2.5,2.5,0,1,0,20,4.5,2.5,2.5,0,0,0,17.5,2Z" />
        </svg>
      );
    }
    if (this.props.type === "reports") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M13,20a5.006,5.006,0,0,0,5-5V6.243a3.972,3.972,0,0,0-1.172-2.829L14.586,1.172A3.972,3.972,0,0,0,11.757,0H7A5.006,5.006,0,0,0,2,5V15a5.006,5.006,0,0,0,5,5ZM4,15V5A3,3,0,0,1,7,2s4.919.014,5,.024V4a2,2,0,0,0,2,2h1.976c.01.081.024,9,.024,9a3,3,0,0,1-3,3H7A3,3,0,0,1,4,15ZM22,8V19a5.006,5.006,0,0,1-5,5H8a1,1,0,0,1,0-2h9a3,3,0,0,0,3-3V8a1,1,0,0,1,2,0Z" />
        </svg>
      );
    }
    if (this.props.type === "onlinestore") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41A1,1,0,0,1,21.4,6.178Z" />
          <circle cx="7" cy="22" r="2" />
          <circle cx="17" cy="22" r="2" />
        </svg>
      );
    }
    if (this.props.type === "crm") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z" />
        </svg>
      );
    }
    if (this.props.type === "loyalty") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22.766,4.566A1.994,1.994,0,0,0,20.586,5L18,7.586,13.414,3a2,2,0,0,0-2.828,0L6,7.586,3.414,5A2,2,0,0,0,0,6.414V17a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6.414A2,2,0,0,0,22.766,4.566ZM22,17a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6.414L5.293,9.707a1,1,0,0,0,1.414,0L12,4.414l5.293,5.293a1,1,0,0,0,1.414,0L22,6.414Z" />
        </svg>
      );
    }
    if (this.props.type === "expense") {
      return (
        <svg viewBox="0 0 24 24">
          <circle cx="5.5" cy="15.5" r="1.5" />
          <path
            d="M19,3H5C2.2,3,0,5.2,0,8v8c0,2.8,2.2,5,5,5h14c2.8,0,5-2.2,5-5V8C24,5.2,21.8,3,19,3z M5,5h14c1.7,0,3,1.3,3,3H2
                C2,6.3,3.3,5,5,5z M19,19H5c-1.7,0-3-1.3-3-3v-6h20v6C22,17.7,20.7,19,19,19z"
          />
        </svg>
      );
    }
    if (this.props.type === "setup") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" />
          <path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" />
        </svg>
      );
    }
    if (this.props.type === "rightarrow") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M15.4,9.879,10.811,5.293A1,1,0,0,0,9.4,6.707l4.586,4.586a1,1,0,0,1,0,1.414L9.4,17.293a1,1,0,0,0,1.415,1.414L15.4,14.121A3,3,0,0,0,15.4,9.879Z" />
        </svg>
      );
    }
    if (this.props.type === "rightarrowdouble") {
      return (
        <svg
          id="Outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M13.1,19a1,1,0,0,1-.708-1.707l4.586-4.586a1,1,0,0,0,0-1.414L12.4,6.707a1,1,0,0,1,1.415-1.414L18.4,9.879a3,3,0,0,1,0,4.242l-4.586,4.586A1,1,0,0,1,13.1,19Z" />
          <path d="M6.1,19A1,1,0,0,1,5.4,17.293L10.689,12,5.4,6.707A1,1,0,0,1,6.811,5.293l6,6a1,1,0,0,1,0,1.414l-6,6A1,1,0,0,1,6.1,19Z" />
        </svg>
      );
    }
    if (this.props.type === "search") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" />
        </svg>
      );
    }
    if (this.props.type === "downarrow") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,0,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586A1,1,0,0,0,18.707,8.207Z" />
        </svg>
      );
    }
    if (this.props.type === "new") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z" />
        </svg>
      );
    }
    if (this.props.type === "list") {
      // return <svg viewBox="0 0 24 24"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z"/><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"/><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z"/><circle cx="2" cy="5" r="2"/><circle cx="2" cy="12" r="2"/><circle cx="2" cy="19" r="2"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M4,6a2.982,2.982,0,0,1-2.122-.879L.334,3.747A1,1,0,0,1,1.666,2.253L3.251,3.667a1,1,0,0,0,1.456.04L8.311.276A1,1,0,0,1,9.689,1.724L6.1,5.138A2.964,2.964,0,0,1,4,6ZM24,4a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,4ZM6.1,13.138,9.689,9.724A1,1,0,1,0,8.311,8.276l-3.6,3.431a1.023,1.023,0,0,1-1.414,0L1.707,10.122A1,1,0,0,0,.293,11.536l1.585,1.585a3,3,0,0,0,4.226.017ZM24,12a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,12ZM6.1,21.138l3.585-3.414a1,1,0,1,0-1.378-1.448l-3.6,3.431a1,1,0,0,1-1.456-.04L1.666,18.253A1,1,0,0,0,.334,19.747l1.544,1.374a3,3,0,0,0,4.226.017ZM24,20a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,20Z" />
        </svg>
      );
    }
    // if(this.props.type==="cashregister"){
    //     return <svg  viewBox="0 0 24 24"><path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1Zm3,13a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6A3,3,0,0,1,5,3H19a3,3,0,0,1,3,3Zm-2-4a1,1,0,0,1-1,1H16.035l-1.7,2.555A1,1,0,0,1,13.5,14c-.021,0-.042,0-.062,0a1,1,0,0,1-.833-.551L10.376,8.988,9.332,10.555A1,1,0,0,1,8.5,11H5A1,1,0,0,1,5,9H7.965l1.7-2.555A.987.987,0,0,1,10.562,6a1,1,0,0,1,.833.551l2.229,4.459,1.044-1.567A1,1,0,0,1,15.5,9H19A1,1,0,0,1,20,10Z"/></svg>
    // }
    if (this.props.type === "campaign") {
      // return <svg  viewBox="0 0 24 24"><path d="M23.119.882a2.966,2.966,0,0,0-2.8-.8l-16,3.37a4.995,4.995,0,0,0-2.853,8.481L3.184,13.65a1,1,0,0,1,.293.708v3.168a2.965,2.965,0,0,0,.3,1.285l-.008.007.026.026A3,3,0,0,0,5.157,20.2l.026.026.007-.008a2.965,2.965,0,0,0,1.285.3H9.643a1,1,0,0,1,.707.292l1.717,1.717A4.963,4.963,0,0,0,15.587,24a5.049,5.049,0,0,0,1.605-.264,4.933,4.933,0,0,0,3.344-3.986L23.911,3.715A2.975,2.975,0,0,0,23.119.882ZM4.6,12.238,2.881,10.521a2.94,2.94,0,0,1-.722-3.074,2.978,2.978,0,0,1,2.5-2.026L20.5,2.086,5.475,17.113V14.358A2.978,2.978,0,0,0,4.6,12.238Zm13.971,7.17a3,3,0,0,1-5.089,1.712L11.762,19.4a2.978,2.978,0,0,0-2.119-.878H6.888L21.915,3.5Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M17,0a1,1,0,0,0-1,1c0,2.949-2.583,4-5,4H4A4,4,0,0,0,0,9v2a3.979,3.979,0,0,0,1.514,3.109l3.572,7.972A3.233,3.233,0,0,0,8.039,24a2.982,2.982,0,0,0,2.72-4.2L8.559,15H11c2.417,0,5,1.051,5,4a1,1,0,0,0,2,0V1A1,1,0,0,0,17,0ZM8.937,20.619A.983.983,0,0,1,8.039,22a1.232,1.232,0,0,1-1.126-.734L4.105,15H6.359ZM16,14.6A7.723,7.723,0,0,0,11,13H4a2,2,0,0,1-2-2V9A2,2,0,0,1,4,7h7a7.723,7.723,0,0,0,5-1.595Zm7.9.852a1,1,0,0,1-1.342.448l-2-1a1,1,0,0,1,.894-1.79l2,1A1,1,0,0,1,23.9,15.447Zm-3.79-9a1,1,0,0,1,.448-1.342l2-1a1,1,0,1,1,.894,1.79l-2,1a1,1,0,0,1-1.342-.448ZM20,10a1,1,0,0,1,1-1h2a1,1,0,0,1,0,2H21A1,1,0,0,1,20,10Z" />
        </svg>
      );
    }
    if (this.props.type === "membership") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23.836,8.794a3.179,3.179,0,0,0-3.067-2.226H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832L4.887,15,3.535,19.187A3.178,3.178,0,0,0,4.719,22.8a3.177,3.177,0,0,0,3.8-.019L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6A3.177,3.177,0,0,0,23.836,8.794Zm-2.343,1.991-4.144,3.029a1,1,0,0,0-.362,1.116L18.562,19.8a1.227,1.227,0,0,1-1.895,1.365l-4.075-3a1,1,0,0,0-1.184,0l-4.075,3a1.227,1.227,0,0,1-1.9-1.365L7.013,14.93a1,1,0,0,0-.362-1.116L2.507,10.785a1.227,1.227,0,0,1,.724-2.217h5.1a1,1,0,0,0,.952-.694l1.55-4.831a1.227,1.227,0,0,1,2.336,0l1.55,4.831a1,1,0,0,0,.952.694h5.1a1.227,1.227,0,0,1,.724,2.217Z" />
        </svg>
      );
    }
    if (this.props.type === "coupon") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,22H5a5.006,5.006,0,0,1-5-5V7A5.006,5.006,0,0,1,5,2H19a5.006,5.006,0,0,1,5,5V17A5.006,5.006,0,0,1,19,22ZM5,4A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7a3,3,0,0,0-3-3Z" />
          <path d="M19,13H11a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" />
          <path d="M7,13H5a1,1,0,0,1,0-2H7a1,1,0,0,1,0,2Z" />
          <path d="M13,18H5a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" />
          <path d="M19,18H17a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z" />
        </svg>
      );
    }
    if (this.props.type === "category") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M10.007,3,8,2.986A3,3,0,0,0,5,0H3A3,3,0,0,0,0,3V5A3,3,0,0,0,2.993,8L2.979,9.993A1,1,0,0,0,3.973,11h.006a1,1,0,0,0,1-.993L4.993,8H5A3,3,0,0,0,8,5V4.986L9.993,5H10a1,1,0,0,0,.007-2ZM6,5A1,1,0,0,1,5,6H3A1,1,0,0,1,2,5V3A1,1,0,0,1,3,2H5A1,1,0,0,1,6,3Z" />
          <path d="M21,0H19a3,3,0,0,0-3,2.993l-1.994-.014H14a1,1,0,0,0-.007,2L16,4.993V5a3,3,0,0,0,3,3h.014L19,9.993A1,1,0,0,0,19.993,11H20a1,1,0,0,0,1-.993L21.014,8A3,3,0,0,0,24,5V3A3,3,0,0,0,21,0Zm1,5a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" />
          <path d="M21.007,16l.014-1.994A1,1,0,0,0,20.027,13h-.006a1,1,0,0,0-1,.993L19.007,16H19a3,3,0,0,0-3,3v.014L14.007,19H14a1,1,0,0,0-.007,2L16,21.014A3,3,0,0,0,19,24h2a3,3,0,0,0,3-3V19A3,3,0,0,0,21.007,16ZM22,21a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" />
          <path d="M10.007,19.021,8,19.007V19a3,3,0,0,0-3-3H4.986L5,14.007A1,1,0,0,0,4.007,13H4a1,1,0,0,0-1,.993L2.986,16A3,3,0,0,0,0,19v2a3,3,0,0,0,3,3H5a3,3,0,0,0,3-2.993l1.994.014H10a1,1,0,0,0,.007-2ZM6,21a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1H5a1,1,0,0,1,1,1Z" />
        </svg>
      );
    }
    if (this.props.type === "subcategory") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M21,16V12a8.983,8.983,0,0,0-3.356-7h2.633a1.991,1.991,0,1,0-.009-2h-4.41A3.981,3.981,0,0,0,8.142,3H3.723a1.991,1.991,0,1,0,.009,2H6.356A8.983,8.983,0,0,0,3,12v4a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3H5a3,3,0,0,0,3-3V19a3,3,0,0,0-3-3V12A7,7,0,0,1,8.521,5.935a3.963,3.963,0,0,0,6.958,0A7,7,0,0,1,19,12v4a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h2a3,3,0,0,0,3-3V19A3,3,0,0,0,21,16ZM6,19v2a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1H5A1,1,0,0,1,6,19ZM12,6a2,2,0,1,1,2-2A2,2,0,0,1,12,6ZM22,21a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" />
        </svg>
      );
    }
    if (this.props.type === "offers") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M7.54,13H2.732L7.865,4.109l3.982,6.9c.051,0,.1-.008.153-.008a5.953,5.953,0,0,1,2.46.534L9.6,3.108a2,2,0,0,0-3.464,0h0L.134,13.5A1,1,0,0,0,1,15H6.35A6.009,6.009,0,0,1,7.54,13Z" />
          <path d="M23,3H13a1,1,0,0,0-1,1v7a5.976,5.976,0,0,1,2,.35V5h8v8H16.46a6.009,6.009,0,0,1,1.19,2H23a1,1,0,0,0,1-1V4A1,1,0,0,0,23,3Z" />
          <path d="M12,12a5,5,0,1,1-5,5,5.006,5.006,0,0,1,5-5m0-2a7,7,0,1,0,7,7,7,7,0,0,0-7-7Z" />
        </svg>
      );
    }
    if (this.props.type === "kds") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,3,3v7H2V6A3,3,0,0,1,5,3ZM19,17H5a3,3,0,0,1-2.816-2H21.816A3,3,0,0,1,19,17Z" />
        </svg>
      );
    }
    if (this.props.type === "taxes") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0Zm3,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3ZM18,9a1,1,0,0,1-2,0,1,1,0,0,0-1-1H13v8h1a1,1,0,0,1,0,2H10a1,1,0,0,1,0-2h1V8H9A1,1,0,0,0,8,9,1,1,0,0,1,6,9,3,3,0,0,1,9,6h6A3,3,0,0,1,18,9Z" />
        </svg>
      );
    }
    if (this.props.type === "product") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM16,11H13V8h3Zm2-3h4v3H18ZM13,6V2h3V6ZM11,6H8V2h3Zm0,2v3H8V8ZM6,11H2V8H6ZM2,13H6v3H2Zm6,0h3v3H8Zm3,5v4H8V18Zm2,0h3v4H13Zm0-2V13h3v3Zm5-3h4v3H18Zm4-8V6H18V2h1A3,3,0,0,1,22,5ZM5,2H6V6H2V5A3,3,0,0,1,5,2ZM2,19V18H6v4H5A3,3,0,0,1,2,19Zm17,3H18V18h4v1A3,3,0,0,1,19,22Z" />
        </svg>
      );
    }
    if (this.props.type === "tags") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M16,0h-.13a2.02,2.02,0,0,0-1.941,1.532,2,2,0,0,1-3.858,0A2.02,2.02,0,0,0,8.13,0H8A5.006,5.006,0,0,0,3,5V21a3,3,0,0,0,3,3H8.13a2.02,2.02,0,0,0,1.941-1.532,2,2,0,0,1,3.858,0A2.02,2.02,0,0,0,15.87,24H18a3,3,0,0,0,3-3V5A5.006,5.006,0,0,0,16,0Zm2,22-2.143-.063A4,4,0,0,0,8.13,22H6a1,1,0,0,1-1-1V17H7a1,1,0,0,0,0-2H5V5A3,3,0,0,1,8,2l.143.063A4.01,4.01,0,0,0,12,5a4.071,4.071,0,0,0,3.893-3H16a3,3,0,0,1,3,3V15H17a1,1,0,0,0,0,2h2v4A1,1,0,0,1,18,22Z" />
          <path d="M13,15H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "brand") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M2,11H13a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V9A2,2,0,0,0,2,11ZM2,2H13V9H2Z" />
          <path d="M22,0H19a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,9H19V2h3Z" />
          <path d="M5,13H2a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V15A2,2,0,0,0,5,13Zm0,9H2V15H5Z" />
          <path d="M22,13H11a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V15A2,2,0,0,0,22,13Zm0,9H11V15H22Z" />
        </svg>
      );
    }
    if (this.props.type === "bom") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M9,9a1,1,0,0,1,1-1h1V7a1,1,0,0,1,2,0V8h1a1,1,0,0,1,0,2H13v1a1,1,0,0,1-2,0V10H10A1,1,0,0,1,9,9Zm7,5H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H8a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2ZM21,7V19a5.006,5.006,0,0,1-5,5H8a5.006,5.006,0,0,1-5-5V7A5.006,5.006,0,0,1,8,2h.171A3.006,3.006,0,0,1,11,0h2a3.006,3.006,0,0,1,2.829,2H16A5.006,5.006,0,0,1,21,7ZM19,7a3,3,0,0,0-3-3H15a1,1,0,0,1-1-1,1,1,0,0,0-1-1H11a1,1,0,0,0-1,1A1,1,0,0,1,9,4H8A3,3,0,0,0,5,7V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3Z" />
        </svg>
      );
    }
    if (this.props.type === "modifiers") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M24,11.5A4.476,4.476,0,0,0,22.294,8,4.481,4.481,0,0,0,19.5,0H4.5A4.481,4.481,0,0,0,1.706,8a4.443,4.443,0,0,0,0,7A4.481,4.481,0,0,0,4.5,23h15a4.481,4.481,0,0,0,2.794-8A4.476,4.476,0,0,0,24,11.5ZM2,4.5A2.5,2.5,0,0,1,4.5,2H5V3A1,1,0,0,0,7,3V2H9V3a1,1,0,0,0,2,0V2h8.5a2.5,2.5,0,0,1,0,5H4.5A2.5,2.5,0,0,1,2,4.5Zm20,14A2.5,2.5,0,0,1,19.5,21H4.5a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V16H9v1a1,1,0,0,0,2,0V16h8.5A2.5,2.5,0,0,1,22,18.5ZM4.5,14a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V9H9v1a1,1,0,0,0,2,0V9h8.5a2.5,2.5,0,0,1,0,5Z" />
        </svg>
      );
    }
    if (this.props.type === "attendance") {
      // return <svg  viewBox="0 0 24 24"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23,11a1,1,0,0,0-1,1,10.034,10.034,0,1,1-2.9-7.021A.862.862,0,0,1,19,5H16a1,1,0,0,0,0,2h3a3,3,0,0,0,3-3V1a1,1,0,0,0-2,0V3.065A11.994,11.994,0,1,0,24,12,1,1,0,0,0,23,11Z" />
          <path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z" />
        </svg>
      );
    }
    if (this.props.type === "staff") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M7.5,13A4.5,4.5,0,1,1,12,8.5,4.505,4.505,0,0,1,7.5,13Zm0-7A2.5,2.5,0,1,0,10,8.5,2.5,2.5,0,0,0,7.5,6ZM15,23v-.5a7.5,7.5,0,0,0-15,0V23a1,1,0,0,0,2,0v-.5a5.5,5.5,0,0,1,11,0V23a1,1,0,0,0,2,0Zm9-5a7,7,0,0,0-11.667-5.217,1,1,0,1,0,1.334,1.49A5,5,0,0,1,22,18a1,1,0,0,0,2,0ZM17.5,9A4.5,4.5,0,1,1,22,4.5,4.505,4.505,0,0,1,17.5,9Zm0-7A2.5,2.5,0,1,0,20,4.5,2.5,2.5,0,0,0,17.5,2Z" />
        </svg>
      );
    }
    if (this.props.type === "stores") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M24,10a.988.988,0,0,0-.024-.217l-1.3-5.868A4.968,4.968,0,0,0,17.792,0H6.208a4.968,4.968,0,0,0-4.88,3.915L.024,9.783A.988.988,0,0,0,0,10v1a3.984,3.984,0,0,0,1,2.643V19a5.006,5.006,0,0,0,5,5H18a5.006,5.006,0,0,0,5-5V13.643A3.984,3.984,0,0,0,24,11ZM2,10.109l1.28-5.76A2.982,2.982,0,0,1,6.208,2H7V5A1,1,0,0,0,9,5V2h6V5a1,1,0,0,0,2,0V2h.792A2.982,2.982,0,0,1,20.72,4.349L22,10.109V11a2,2,0,0,1-2,2H19a2,2,0,0,1-2-2,1,1,0,0,0-2,0,2,2,0,0,1-2,2H11a2,2,0,0,1-2-2,1,1,0,0,0-2,0,2,2,0,0,1-2,2H4a2,2,0,0,1-2-2ZM18,22H6a3,3,0,0,1-3-3V14.873A3.978,3.978,0,0,0,4,15H5a3.99,3.99,0,0,0,3-1.357A3.99,3.99,0,0,0,11,15h2a3.99,3.99,0,0,0,3-1.357A3.99,3.99,0,0,0,19,15h1a3.978,3.978,0,0,0,1-.127V19A3,3,0,0,1,18,22Z" />
        </svg>
      );
    }
    if (this.props.type === "dashboard") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23,22H3a1,1,0,0,1-1-1V1A1,1,0,0,0,0,1V21a3,3,0,0,0,3,3H23a1,1,0,0,0,0-2Z" />
          <path d="M15,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,15,20Z" />
          <path d="M7,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,7,20Z" />
          <path d="M19,20a1,1,0,0,0,1-1V7a1,1,0,0,0-2,0V19A1,1,0,0,0,19,20Z" />
          <path d="M11,20a1,1,0,0,0,1-1V7a1,1,0,0,0-2,0V19A1,1,0,0,0,11,20Z" />
        </svg>
      );
    }
    if (this.props.type === "registration") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M24,23a1,1,0,0,1-1,1H1a1,1,0,0,1,0-2H23A1,1,0,0,1,24,23ZM.291,8.552A2.443,2.443,0,0,1,.444,5.986a4.716,4.716,0,0,1,1.668-1.5L9.613.582a5.174,5.174,0,0,1,4.774,0l7.5,3.907h0a4.716,4.716,0,0,1,1.668,1.5,2.443,2.443,0,0,1,.153,2.566A2.713,2.713,0,0,1,21.292,10H21v8h1a1,1,0,0,1,0,2H2a1,1,0,0,1,0-2H3V10H2.708A2.713,2.713,0,0,1,.291,8.552ZM5,18H8V10H5Zm5-8v8h4V10Zm9,0H16v8h3ZM2.063,7.625A.717.717,0,0,0,2.708,8H21.292a.717.717,0,0,0,.645-.375.452.452,0,0,0-.024-.5,2.7,2.7,0,0,0-.949-.864h0l-7.5-3.907a3.176,3.176,0,0,0-2.926,0l-7.5,3.907a2.712,2.712,0,0,0-.949.865A.452.452,0,0,0,2.063,7.625Z" />
        </svg>
      );
    }
    if (this.props.type === "adduser") {
      return (
        <svg
          id="Outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z" />
          <path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" />
          <path d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z" />
        </svg>
      );
    }
    if (this.props.type === "paymenttypes") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22.485,10.975,12,17.267,1.515,10.975A1,1,0,1,0,.486,12.69l11,6.6a1,1,0,0,0,1.03,0l11-6.6a1,1,0,1,0-1.029-1.715Z" />
          <path d="M22.485,15.543,12,21.834,1.515,15.543A1,1,0,1,0,.486,17.258l11,6.6a1,1,0,0,0,1.03,0l11-6.6a1,1,0,1,0-1.029-1.715Z" />
          <path d="M12,14.773a2.976,2.976,0,0,1-1.531-.425L.485,8.357a1,1,0,0,1,0-1.714L10.469.652a2.973,2.973,0,0,1,3.062,0l9.984,5.991a1,1,0,0,1,0,1.714l-9.984,5.991A2.976,2.976,0,0,1,12,14.773ZM2.944,7.5,11.5,12.633a.974.974,0,0,0,1,0L21.056,7.5,12.5,2.367a.974.974,0,0,0-1,0h0Z" />
        </svg>
      );
    }
    if (this.props.type === "menubar") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M1,6H23a1,1,0,0,0,0-2H1A1,1,0,0,0,1,6Z" />
          <path d="M1,11H15a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" />
          <path d="M15,19H1a1,1,0,0,0,0,2H15a1,1,0,0,0,0-2Z" />
          <path d="M23,14H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "store") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z" />
        </svg>
      );
    }
    if (this.props.type === "cashregister") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1Zm3,13a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6A3,3,0,0,1,5,3H19a3,3,0,0,1,3,3Zm-2-4a1,1,0,0,1-1,1H16.035l-1.7,2.555A1,1,0,0,1,13.5,14c-.021,0-.042,0-.062,0a1,1,0,0,1-.833-.551L10.376,8.988,9.332,10.555A1,1,0,0,1,8.5,11H5A1,1,0,0,1,5,9H7.965l1.7-2.555A.987.987,0,0,1,10.562,6a1,1,0,0,1,.833.551l2.229,4.459,1.044-1.567A1,1,0,0,1,15.5,9H19A1,1,0,0,1,20,10Z" />
        </svg>
      );
    }
    if (this.props.type === "user") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" />
          <path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" />
        </svg>
      );
    }
    if (this.props.type === "add") {
      // return <svg viewBox="0 0 24 24"><path d="M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z" />
        </svg>
      );
    }
    if (this.props.type === "addlg") {
      // return <svg viewBox="0 0 24 24"><path d="M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z" />
        </svg>
      );
    }
    if (this.props.type === "help") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12,15a1,1,0,0,1-1-1,3.953,3.953,0,0,1,1.963-3.195,1.994,1.994,0,0,0,1-2.124,2.024,2.024,0,0,0-1.6-1.6A2,2,0,0,0,10,9.052a1,1,0,0,1-2,0A4,4,0,0,1,9.429,5.987a4,4,0,1,1,4.5,6.57A1.987,1.987,0,0,0,13,14,1,1,0,0,1,12,15Zm.793,8.76C14.953,22.892,22,19.479,22,12.043V6.872a4.993,4.993,0,0,0-3.426-4.746L12.315.051a.985.985,0,0,0-.63,0L5.426,2.126A4.993,4.993,0,0,0,2,6.872v5.171c0,6.562,7.005,10.576,9.153,11.65A2.331,2.331,0,0,0,12,24,2.242,2.242,0,0,0,12.793,23.76ZM17.944,4.024A3,3,0,0,1,20,6.872v5.171c0,6.183-6.087,9.111-7.953,9.861h0C10.159,20.96,4,17.459,4,12.043V6.872A3,3,0,0,1,6.056,4.024L12,2.054ZM12,17a1,1,0,1,0,1,1A1,1,0,0,0,12,17Z" />
        </svg>
      );
    }
    if (this.props.type === "notification") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836A2.977,2.977,0,0,1,20.126,16.815Z" />
        </svg>
      );
    }
    if (this.props.type === "park") {
      // return <svg viewBox="0 0 24 24"><path d="M6.5,0A3.5,3.5,0,0,0,3,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,6.5,0ZM8,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z"/><path d="M17.5,0A3.5,3.5,0,0,0,14,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,17.5,0ZM19,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z" />
          <path d="M5.293,13.538l4.586,4.587a3.007,3.007,0,0,0,4.242,0l4.586-4.586a1,1,0,1,0-1.414-1.414L13,16.416V1a1,1,0,0,0-2,0V16.417L6.707,12.124a1,1,0,1,0-1.414,1.414Z" />
        </svg>
      );
    }
    if (this.props.type === "retrieve") {
      // return <svg  viewBox="0 0 24 24"><path d="M0,23V16A9.01,9.01,0,0,1,9,7h4.83V5.414A2,2,0,0,1,17.244,4l5.88,5.879a3,3,0,0,1,0,4.242L17.244,20a2,2,0,0,1-3.414-1.414V17H8a6.006,6.006,0,0,0-6,6,1,1,0,0,1-2,0ZM15.83,8a1,1,0,0,1-1,1H9a7.008,7.008,0,0,0-7,7v1.714A7.984,7.984,0,0,1,8,15h6.83a1,1,0,0,1,1,1v2.586l5.879-5.879a1,1,0,0,0,0-1.414L15.83,5.414Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" />
          <path d="M18.707,5.462,14.121.875a3.007,3.007,0,0,0-4.242,0L5.293,5.462A1,1,0,1,0,6.707,6.876L11,2.584V18a1,1,0,0,0,2,0V2.583l4.293,4.293a1,1,0,1,0,1.414-1.414Z" />
        </svg>
      );
    }
    if (this.props.type === "discount") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,0H14a1,1,0,0,0,0,2h5a2.951,2.951,0,0,1,1.285.3L.293,22.293a1,1,0,1,0,1.414,1.414L21.7,3.715A2.951,2.951,0,0,1,22,5v5a1,1,0,0,0,2,0V5A5.006,5.006,0,0,0,19,0Z" />
          <path d="M6,10A4,4,0,1,0,2,6,4,4,0,0,0,6,10ZM6,4A2,2,0,1,1,4,6,2,2,0,0,1,6,4Z" />
          <path d="M18,14a4,4,0,1,0,4,4A4,4,0,0,0,18,14Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z" />
        </svg>
      );
    }
    if (this.props.type === "notes") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18.656.93,6.464,13.122A4.966,4.966,0,0,0,5,16.657V18a1,1,0,0,0,1,1H7.343a4.966,4.966,0,0,0,3.535-1.464L23.07,5.344a3.125,3.125,0,0,0,0-4.414A3.194,3.194,0,0,0,18.656.93Zm3,3L9.464,16.122A3.02,3.02,0,0,1,7.343,17H7v-.343a3.02,3.02,0,0,1,.878-2.121L20.07,2.344a1.148,1.148,0,0,1,1.586,0A1.123,1.123,0,0,1,21.656,3.93Z" />
          <path d="M23,8.979a1,1,0,0,0-1,1V15H18a3,3,0,0,0-3,3v4H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2h9.042a1,1,0,0,0,0-2H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.968,4.968,0,0,0,3.536-1.464l2.656-2.658A4.968,4.968,0,0,0,24,16.343V9.979A1,1,0,0,0,23,8.979ZM18.465,21.122a2.975,2.975,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.925a3.016,3.016,0,0,1-.8,1.464Z" />
        </svg>
      );
    }
    if (this.props.type === "delete") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" />
          <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" />
          <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" />
        </svg>
      );
    }
    if (this.props.type === "delivery") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M20,11H19V5a4,4,0,0,0-4-4H4A4,4,0,0,0,0,5V16a2.994,2.994,0,0,0,2.071,2.838A3.4,3.4,0,0,0,2,19.5a3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5h6.082a3.465,3.465,0,0,0-.041.5,3.5,3.5,0,0,0,7,0,3.4,3.4,0,0,0-.071-.662A2.994,2.994,0,0,0,24,16V15A4,4,0,0,0,20,11ZM2,11V7H4v4ZM6,7H8v4H6Zm4,0h3V17H10Zm5,0h2v4H15ZM4,3H15a2,2,0,0,1,2,2H2A2,2,0,0,1,4,3ZM2,13H8v4H3a1,1,0,0,1-1-1Zm5,6.5a1.5,1.5,0,0,1-3,0,1.41,1.41,0,0,1,.093-.5H6.907A1.41,1.41,0,0,1,7,19.5ZM18.5,21A1.5,1.5,0,0,1,17,19.5a1.41,1.41,0,0,1,.093-.5h2.814a1.41,1.41,0,0,1,.093.5A1.5,1.5,0,0,1,18.5,21ZM22,16a1,1,0,0,1-1,1H15V13h5a2,2,0,0,1,2,2Z" />
        </svg>
      );
    }
    if (this.props.type === "info") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M4,6a2.982,2.982,0,0,1-2.122-.879L.334,3.747A1,1,0,0,1,1.666,2.253L3.251,3.667a1,1,0,0,0,1.456.04L8.311.276A1,1,0,0,1,9.689,1.724L6.1,5.138A2.964,2.964,0,0,1,4,6ZM24,4a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,4ZM6.1,13.138,9.689,9.724A1,1,0,1,0,8.311,8.276l-3.6,3.431a1.023,1.023,0,0,1-1.414,0L1.707,10.122A1,1,0,0,0,.293,11.536l1.585,1.585a3,3,0,0,0,4.226.017ZM24,12a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,12ZM6.1,21.138l3.585-3.414a1,1,0,1,0-1.378-1.448l-3.6,3.431a1,1,0,0,1-1.456-.04L1.666,18.253A1,1,0,0,0,.334,19.747l1.544,1.374a3,3,0,0,0,4.226.017ZM24,20a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,20Z" />
        </svg>
      );
    }
    if (this.props.type === "") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18,24H6a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,6,0H18a5.006,5.006,0,0,1,5,5V19A5.006,5.006,0,0,1,18,24ZM6,2A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3Zm10,8H8A3,3,0,0,1,8,4h8a3,3,0,0,1,0,6ZM8,6A1,1,0,0,0,8,8h8a1,1,0,0,0,0-2ZM6,13a1,1,0,1,0,1,1A1,1,0,0,0,6,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,14,13ZM6,17a1,1,0,1,0,1,1A1,1,0,0,0,6,17Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,17Zm8-4a1,1,0,1,0,1,1A1,1,0,0,0,18,13Zm1,5a1,1,0,0,0-1-1H14a1,1,0,0,0,0,2h4A1,1,0,0,0,19,18Z" />
        </svg>
      );
    }
    if (this.props.type === "remove") {
      // return <svg  viewBox="0 0 24 24"><path d="M23.707.293h0a1,1,0,0,0-1.414,0L12,10.586,1.707.293a1,1,0,0,0-1.414,0h0a1,1,0,0,0,0,1.414L10.586,12,.293,22.293a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0L12,13.414,22.293,23.707a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L13.414,12,23.707,1.707A1,1,0,0,0,23.707.293Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" />
        </svg>
      );
    }
    if (this.props.type === "toggle") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23,12a.982.982,0,0,0-.988.878A9.986,9.986,0,0,1,4.924,19H7.669a1,1,0,1,0,0-2h-4a2,2,0,0,0-2,2v4a1,1,0,0,0,2,0V20.562A11.977,11.977,0,0,0,24,13.1,1.007,1.007,0,0,0,23,12Z" />
          <path d="M21.331,0a1,1,0,0,0-1,1V3.438A11.977,11.977,0,0,0,0,10.9,1.007,1.007,0,0,0,1,12a.982.982,0,0,0,.988-.878A9.986,9.986,0,0,1,19.076,5H16.331a1,1,0,0,0,0,2h4a2,2,0,0,0,2-2V1A1,1,0,0,0,21.331,0Z" />
        </svg>
      );
    }
    if (this.props.type === "minus") {
      return (
        <svg viewBox="0 0 24 24">
          <rect x="6" y="11" width="12" height="2" rx="1" />
        </svg>
      );
    }
    if (this.props.type === "appointment") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" />
          <circle cx="12" cy="15" r="1.5" />
          <circle cx="7" cy="15" r="1.5" />
          <circle cx="17" cy="15" r="1.5" />
        </svg>
      );
    }
    if (this.props.type === "expensecategories") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M17,0H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,17,0ZM7,2H17a3,3,0,0,1,3,3v6H4V5A3,3,0,0,1,7,2ZM17,22H7a3,3,0,0,1-3-3V13H20v6A3,3,0,0,1,17,22Z" />
          <path d="M11,7h2a1,1,0,0,0,0-2H11a1,1,0,0,0,0,2Z" />
          <path d="M13,17H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "account") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M17,0H7A5.006,5.006,0,0,0,2,5V20a4,4,0,0,0,4,4H17a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,17,0Zm3,5V16H8V2h9A3,3,0,0,1,20,5ZM6,2.172V16a3.98,3.98,0,0,0-2,.537V5A3,3,0,0,1,6,2.172ZM17,22H6a2,2,0,0,1,0-4H20v1A3,3,0,0,1,17,22Z" />
        </svg>
      );
    }
    if (this.props.type === "accountmanagement") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22.2,2.163a4.992,4.992,0,0,0-4.1-1.081l-3.822.694A4,4,0,0,0,12,3.065,4,4,0,0,0,9.716,1.776L5.9,1.082A5,5,0,0,0,0,6V16.793a5,5,0,0,0,4.105,4.919l6.286,1.143a9,9,0,0,0,3.218,0L19.9,21.712A5,5,0,0,0,24,16.793V6A4.983,4.983,0,0,0,22.2,2.163ZM11,20.928c-.084-.012-.168-.026-.252-.041L4.463,19.745A3,3,0,0,1,2,16.793V6A3,3,0,0,1,5,3a3.081,3.081,0,0,1,.54.049l3.82.7A2,2,0,0,1,11,5.712Zm11-4.135a3,3,0,0,1-2.463,2.952l-6.285,1.142c-.084.015-.168.029-.252.041V5.712a2,2,0,0,1,1.642-1.968l3.821-.7A3,3,0,0,1,22,6Z" />
        </svg>
      );
    }
    if (this.props.type === "onlinehomesections") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M24,11.5A4.476,4.476,0,0,0,22.294,8,4.481,4.481,0,0,0,19.5,0H4.5A4.481,4.481,0,0,0,1.706,8a4.443,4.443,0,0,0,0,7A4.481,4.481,0,0,0,4.5,23h15a4.481,4.481,0,0,0,2.794-8A4.476,4.476,0,0,0,24,11.5ZM2,4.5A2.5,2.5,0,0,1,4.5,2H5V3A1,1,0,0,0,7,3V2H9V3a1,1,0,0,0,2,0V2h8.5a2.5,2.5,0,0,1,0,5H4.5A2.5,2.5,0,0,1,2,4.5Zm20,14A2.5,2.5,0,0,1,19.5,21H4.5a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V16H9v1a1,1,0,0,0,2,0V16h8.5A2.5,2.5,0,0,1,22,18.5ZM4.5,14a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V9H9v1a1,1,0,0,0,2,0V9h8.5a2.5,2.5,0,0,1,0,5Z" />
        </svg>
      );
    }
    if (this.props.type === "onlinebanner") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0Zm1,11h2v2H20Zm0-2V7h2V9Zm-2,2H6V2H18ZM4,13H2V11H4ZM4,9H2V7H4ZM2,15H4v2H2Zm4-2H18v9H6Zm14,2h2v2H20ZM22,5H20V2.184A3,3,0,0,1,22,5ZM4,2.184V5H2A3,3,0,0,1,4,2.184ZM2,19H4v2.816A3,3,0,0,1,2,19Zm18,2.816V19h2A3,3,0,0,1,20,21.816Z" />
        </svg>
      );
    }
    if (this.props.type === "onlineprofile") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,4H15V3A3,3,0,0,0,9,3V4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,3a1,1,0,0,1,2,0V5a1,1,0,0,1-2,0ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9A3,3,0,0,1,5,6H9.184a2.982,2.982,0,0,0,5.632,0H19a3,3,0,0,1,3,3ZM10,10H5a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V11A1,1,0,0,0,10,10ZM9,18H6V12H9Zm11-3a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h5A1,1,0,0,1,20,15Zm0-4a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h5A1,1,0,0,1,20,11Zm-2,8a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h3A1,1,0,0,1,18,19Z" />
        </svg>
      );
    }
    if (this.props.type === "onlineproductdetail") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M16,16a1,1,0,0,1-1,1H13v2a1,1,0,0,1-2,0V17H9a1,1,0,0,1,0-2h2V13a1,1,0,0,1,2,0v2h2A1,1,0,0,1,16,16Zm6-5.515V19a5.006,5.006,0,0,1-5,5H7a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,7,0h4.515a6.958,6.958,0,0,1,4.95,2.05l3.484,3.486A6.951,6.951,0,0,1,22,10.485ZM15.051,3.464A5.01,5.01,0,0,0,14,2.684V7a1,1,0,0,0,1,1h4.316a4.983,4.983,0,0,0-.781-1.05ZM20,10.485c0-.165-.032-.323-.047-.485H15a3,3,0,0,1-3-3V2.047C11.838,2.032,11.679,2,11.515,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3Z" />
        </svg>
      );
    }
    if (this.props.type === "onlinepages") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,0H13A5.006,5.006,0,0,0,8,5v.1A5.009,5.009,0,0,0,4,10v.1A5.009,5.009,0,0,0,0,15v4a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5v-.1A5.009,5.009,0,0,0,20,14v-.1A5.009,5.009,0,0,0,24,9V5A5.006,5.006,0,0,0,19,0ZM2,15a3,3,0,0,1,3-3h6a2.988,2.988,0,0,1,2.638,1.6l-3.455,3.463-.475-.479A1.992,1.992,0,0,0,7,16.473l-4.621,3.96A2.96,2.96,0,0,1,2,19Zm12,4a3,3,0,0,1-3,3H5a2.971,2.971,0,0,1-1.118-.221L8.288,18l.476.481a2,2,0,0,0,2.828,0L14,16.068Zm4-5a3,3,0,0,1-2,2.816V15a5.006,5.006,0,0,0-5-5H6A3,3,0,0,1,9,7h6a3,3,0,0,1,3,3Zm4-5a3,3,0,0,1-2,2.816V10a5.006,5.006,0,0,0-5-5H10a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3ZM4,15a1,1,0,1,1,1,1A1,1,0,0,1,4,15Z" />
        </svg>
      );
    }
    if (this.props.type === "") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18,24H6a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,6,0H18a5.006,5.006,0,0,1,5,5V19A5.006,5.006,0,0,1,18,24ZM6,2A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3Zm10,8H8A3,3,0,0,1,8,4h8a3,3,0,0,1,0,6ZM8,6A1,1,0,0,0,8,8h8a1,1,0,0,0,0-2ZM6,13a1,1,0,1,0,1,1A1,1,0,0,0,6,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,14,13ZM6,17a1,1,0,1,0,1,1A1,1,0,0,0,6,17Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,17Zm8-4a1,1,0,1,0,1,1A1,1,0,0,0,18,13Zm1,5a1,1,0,0,0-1-1H14a1,1,0,0,0,0,2h4A1,1,0,0,0,19,18Z" />
        </svg>
      );
    }
    if (this.props.type === "option") {
      return (
        <svg viewBox="0 0 24 24">
          <circle cx="12" cy="2" r="2" />
          <circle cx="12" cy="12" r="2" />
          <circle cx="12" cy="22" r="2" />
        </svg>
      );
    }
    if (this.props.type === "filter") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" />
          <path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" />
          <path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" />
        </svg>
      );
    }
    if (this.props.type === "edit") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M18.656.93,6.464,13.122A4.966,4.966,0,0,0,5,16.657V18a1,1,0,0,0,1,1H7.343a4.966,4.966,0,0,0,3.535-1.464L23.07,5.344a3.125,3.125,0,0,0,0-4.414A3.194,3.194,0,0,0,18.656.93Zm3,3L9.464,16.122A3.02,3.02,0,0,1,7.343,17H7v-.343a3.02,3.02,0,0,1,.878-2.121L20.07,2.344a1.148,1.148,0,0,1,1.586,0A1.123,1.123,0,0,1,21.656,3.93Z" />
          <path d="M23,8.979a1,1,0,0,0-1,1V15H18a3,3,0,0,0-3,3v4H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2h9.042a1,1,0,0,0,0-2H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.968,4.968,0,0,0,3.536-1.464l2.656-2.658A4.968,4.968,0,0,0,24,16.343V9.979A1,1,0,0,0,23,8.979ZM18.465,21.122a2.975,2.975,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.925a3.016,3.016,0,0,1-.8,1.464Z" />
        </svg>
      );
    }
    if (this.props.type === "view") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M16,6a1,1,0,0,1,0,2H8A1,1,0,0,1,8,6Zm7.707,17.707a1,1,0,0,1-1.414,0L19.886,21.3A4.457,4.457,0,0,1,17.5,22,4.5,4.5,0,1,1,22,17.5a4.457,4.457,0,0,1-.7,2.386l2.407,2.407A1,1,0,0,1,23.707,23.707ZM17.5,20A2.5,2.5,0,1,0,15,17.5,2.5,2.5,0,0,0,17.5,20ZM13,22H7a3,3,0,0,1-3-3V5A3,3,0,0,1,7,2H19a1,1,0,0,1,1,1v8a1,1,0,0,0,2,0V3a3,3,0,0,0-3-3H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5h6a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "sms") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M24,11.247A12.012,12.012,0,1,0,12.017,24H19a5.005,5.005,0,0,0,5-5V11.247ZM22,19a3,3,0,0,1-3,3H12.017a10.041,10.041,0,0,1-7.476-3.343,9.917,9.917,0,0,1-2.476-7.814,10.043,10.043,0,0,1,8.656-8.761A10.564,10.564,0,0,1,12.021,2,9.921,9.921,0,0,1,18.4,4.3,10.041,10.041,0,0,1,22,11.342Z" />
          <path d="M8,9h4a1,1,0,0,0,0-2H8A1,1,0,0,0,8,9Z" />
          <path d="M16,11H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" />
          <path d="M16,15H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "survey") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M24,9a3,3,0,1,0-4,2.816V16A6,6,0,0,1,8,16v-.08A7.006,7.006,0,0,0,14,9V5A5.006,5.006,0,0,0,9,0,1,1,0,0,0,9,2a3,3,0,0,1,3,3V9A5,5,0,0,1,2,9V5A3,3,0,0,1,5,2,1,1,0,0,0,5,0,5.006,5.006,0,0,0,0,5V9a7.006,7.006,0,0,0,6,6.92V16a8,8,0,0,0,16,0V11.816A3,3,0,0,0,24,9Zm-3,1a1,1,0,1,1,1-1A1,1,0,0,1,21,10Z" />
        </svg>
      );
    }
    if (this.props.type === "thumbsup") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M22.773,7.721A4.994,4.994,0,0,0,19,6H15.011l.336-2.041A3.037,3.037,0,0,0,9.626,2.122L7.712,6H5a5.006,5.006,0,0,0-5,5v5a5.006,5.006,0,0,0,5,5H18.3a5.024,5.024,0,0,0,4.951-4.3l.705-5A5,5,0,0,0,22.773,7.721ZM2,16V11A3,3,0,0,1,5,8H7V19H5A3,3,0,0,1,2,16Zm19.971-4.581-.706,5A3.012,3.012,0,0,1,18.3,19H9V7.734a1,1,0,0,0,.23-.292l2.189-4.435A1.07,1.07,0,0,1,13.141,2.8a1.024,1.024,0,0,1,.233.84l-.528,3.2A1,1,0,0,0,13.833,8H19a3,3,0,0,1,2.971,3.419Z" />
        </svg>
      );
    }
    if (this.props.type === "other") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M16,6a1,1,0,0,1,0,2H8A1,1,0,0,1,8,6Zm7.707,17.707a1,1,0,0,1-1.414,0L19.886,21.3A4.457,4.457,0,0,1,17.5,22,4.5,4.5,0,1,1,22,17.5a4.457,4.457,0,0,1-.7,2.386l2.407,2.407A1,1,0,0,1,23.707,23.707ZM17.5,20A2.5,2.5,0,1,0,15,17.5,2.5,2.5,0,0,0,17.5,20ZM13,22H7a3,3,0,0,1-3-3V5A3,3,0,0,1,7,2H19a1,1,0,0,1,1,1v8a1,1,0,0,0,2,0V3a3,3,0,0,0-3-3H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5h6a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "wallet") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0Zm3,5H15V2h4A3,3,0,0,1,22,5ZM11,2h2V7a1,1,0,0,1-2,0ZM5,2H9V5H2A3,3,0,0,1,5,2ZM19,22H5a3,3,0,0,1-3-3V7H9a3,3,0,0,0,6,0h7V19A3,3,0,0,1,19,22Zm1-3a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h3A1,1,0,0,1,20,19Z" />
        </svg>
      );
    }
    if (this.props.type === "leftarrow") {
      // return <svg  viewBox="0 0 24 24"><path d="M17.172,24a1,1,0,0,1-.707-.293L8.293,15.535a5,5,0,0,1,0-7.07L16.465.293a1,1,0,0,1,1.414,1.414L9.707,9.879a3,3,0,0,0,0,4.242l8.172,8.172A1,1,0,0,1,17.172,24Z"/></svg>
      return (
        <svg viewBox="0 0 24 24">
          <path d="M10.6,12.707a1,1,0,0,1,0-1.414l4.585-4.586a1,1,0,0,0-1.414-1.414L9.189,9.879a3,3,0,0,0,0,4.242l4.586,4.586a1,1,0,0,0,1.414-1.414Z" />
        </svg>
      );
    }
    if (this.props.type === "leftarrowdouble") {
      return (
        <svg
          id="Outline"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M10.482,19a1,1,0,0,1-.707-.293L5.189,14.121a3,3,0,0,1,0-4.242L9.775,5.293a1,1,0,0,1,1.414,1.414L6.6,11.293a1,1,0,0,0,0,1.414l4.585,4.586A1,1,0,0,1,10.482,19Z" />
          <path d="M17.482,19a1,1,0,0,1-.707-.293l-6-6a1,1,0,0,1,0-1.414l6-6a1,1,0,1,1,1.414,1.414L12.9,12l5.293,5.293A1,1,0,0,1,17.482,19Z" />
        </svg>
      );
    }

    if (this.props.type === "moduleconfiguration") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,12,20ZM12,6a6,6,0,1,0,6,6A6.006,6.006,0,0,0,12,6Zm-.143,9.515,3-5A1,1,0,0,0,14,9H10a1,1,0,0,0,0,2h2.234l-2.091,3.485a1,1,0,1,0,1.714,1.03Z" />
        </svg>
      );
    }
    if (this.props.type === "customfields") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M20.137,24a2.8,2.8,0,0,1-1.987-.835L12,17.051,5.85,23.169a2.8,2.8,0,0,1-3.095.609A2.8,2.8,0,0,1,1,21.154V5A5,5,0,0,1,6,0H18a5,5,0,0,1,5,5V21.154a2.8,2.8,0,0,1-1.751,2.624A2.867,2.867,0,0,1,20.137,24ZM6,2A3,3,0,0,0,3,5V21.154a.843.843,0,0,0,1.437.6h0L11.3,14.933a1,1,0,0,1,1.41,0l6.855,6.819a.843.843,0,0,0,1.437-.6V5a3,3,0,0,0-3-3Z" />
        </svg>
      );
    }
    if (this.props.type === "vendors") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M16,0h-.13a2.02,2.02,0,0,0-1.941,1.532,2,2,0,0,1-3.858,0A2.02,2.02,0,0,0,8.13,0H8A5.006,5.006,0,0,0,3,5V21a3,3,0,0,0,3,3H8.13a2.02,2.02,0,0,0,1.941-1.532,2,2,0,0,1,3.858,0A2.02,2.02,0,0,0,15.87,24H18a3,3,0,0,0,3-3V5A5.006,5.006,0,0,0,16,0Zm2,22-2.143-.063A4,4,0,0,0,8.13,22H6a1,1,0,0,1-1-1V17H7a1,1,0,0,0,0-2H5V5A3,3,0,0,1,8,2l.143.063A4.01,4.01,0,0,0,12,5a4.071,4.071,0,0,0,3.893-3H16a3,3,0,0,1,3,3V15H17a1,1,0,0,0,0,2h2v4A1,1,0,0,1,18,22Z" />
          <path d="M13,15H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "trophy") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M16,0h-.13a2.02,2.02,0,0,0-1.941,1.532,2,2,0,0,1-3.858,0A2.02,2.02,0,0,0,8.13,0H8A5.006,5.006,0,0,0,3,5V21a3,3,0,0,0,3,3H8.13a2.02,2.02,0,0,0,1.941-1.532,2,2,0,0,1,3.858,0A2.02,2.02,0,0,0,15.87,24H18a3,3,0,0,0,3-3V5A5.006,5.006,0,0,0,16,0Zm2,22-2.143-.063A4,4,0,0,0,8.13,22H6a1,1,0,0,1-1-1V17H7a1,1,0,0,0,0-2H5V5A3,3,0,0,1,8,2l.143.063A4.01,4.01,0,0,0,12,5a4.071,4.071,0,0,0,3.893-3H16a3,3,0,0,1,3,3V15H17a1,1,0,0,0,0,2h2v4A1,1,0,0,1,18,22Z" />
          <path d="M13,15H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
        </svg>
      );
    }
    if (this.props.type === "lock") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M19,8.424V7A7,7,0,0,0,5,7V8.424A5,5,0,0,0,2,13v6a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V13A5,5,0,0,0,19,8.424ZM7,7A5,5,0,0,1,17,7V8H7ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V13a3,3,0,0,1,3-3H17a3,3,0,0,1,3,3Z" />
          <path d="M12,14a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V15A1,1,0,0,0,12,14Z" />
        </svg>
      );
    }
    if (this.props.type === "scan") {
      return <img src={scan} />;
    }
    if (this.props.type === "packages") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M9,14h6a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z" />
          <path d="M19,0H5A5.006,5.006,0,0,0,0,5V6A3,3,0,0,0,1,8.234V19a5.006,5.006,0,0,0,5,5H18a5.006,5.006,0,0,0,5-5V8.234A3,3,0,0,0,24,6V5A5.006,5.006,0,0,0,19,0ZM2,5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3V6a1,1,0,0,1-1,1H3A1,1,0,0,1,2,6ZM21,19a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V9H21Z" />
        </svg>
      );
    }
    if (this.props.type === "hidepassword") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23.271,9.419A15.866,15.866,0,0,0,19.9,5.51l2.8-2.8a1,1,0,0,0-1.414-1.414L18.241,4.345A12.054,12.054,0,0,0,12,2.655C5.809,2.655,2.281,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162A15.866,15.866,0,0,0,4.1,18.49l-2.8,2.8a1,1,0,1,0,1.414,1.414l3.052-3.052A12.054,12.054,0,0,0,12,21.345c6.191,0,9.719-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419ZM2.433,13.534a2.918,2.918,0,0,1,0-3.068C3.767,8.3,6.782,4.655,12,4.655A10.1,10.1,0,0,1,16.766,5.82L14.753,7.833a4.992,4.992,0,0,0-6.92,6.92l-2.31,2.31A13.723,13.723,0,0,1,2.433,13.534ZM15,12a3,3,0,0,1-3,3,2.951,2.951,0,0,1-1.285-.3L14.7,10.715A2.951,2.951,0,0,1,15,12ZM9,12a3,3,0,0,1,3-3,2.951,2.951,0,0,1,1.285.3L9.3,13.285A2.951,2.951,0,0,1,9,12Zm12.567,1.534C20.233,15.7,17.218,19.345,12,19.345A10.1,10.1,0,0,1,7.234,18.18l2.013-2.013a4.992,4.992,0,0,0,6.92-6.92l2.31-2.31a13.723,13.723,0,0,1,3.09,3.529A2.918,2.918,0,0,1,21.567,13.534Z" />
        </svg>
      );
    }
    if (this.props.type === "showpassword") {
      return (
        <svg viewBox="0 0 24 24">
          <path d="M23.271,9.419C21.72,6.893,18.192,2.655,12,2.655S2.28,6.893.729,9.419a4.908,4.908,0,0,0,0,5.162C2.28,17.107,5.808,21.345,12,21.345s9.72-4.238,11.271-6.764A4.908,4.908,0,0,0,23.271,9.419Zm-1.705,4.115C20.234,15.7,17.219,19.345,12,19.345S3.766,15.7,2.434,13.534a2.918,2.918,0,0,1,0-3.068C3.766,8.3,6.781,4.655,12,4.655s8.234,3.641,9.566,5.811A2.918,2.918,0,0,1,21.566,13.534Z" />
          <path d="M12,7a5,5,0,1,0,5,5A5.006,5.006,0,0,0,12,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
        </svg>
      );
    }
    if (this.props.type === "pay") {
      // viewBox="0 0 512.000000 512.000000"
      return (
        <svg viewBox="0 0 512.000000 512.000000">
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            // stroke="none"
          >
            <path
              stroke-width="20"
              d="M1243 5106 c-188 -46 -335 -208 -363 -401 -13 -96 -13 -4194 0 -4290 29 -197 184 -364 378 -404 75 -15 2530 -15 2604 0 194 40 350 207 378 404 13 96 13 4195 0 4290 -29 198 -183 363 -378 404 -80 17 -2549 14 -2619 -3z m2621 -206 c64 -24 124 -80 157 -148 l24 -47 3 -1852 2 -1853 -1490 0 -1490 0 2 1853 3 1852 23 46 c31 64 96 125 157 149 49 18 87 19 1302 19 1237 1 1253 1 1307 -19z m184 -4264 c2 -118 -1 -174 -13 -219 -25 -97 -82 -162 -173 -197 -52 -20 -71 -20 -1302 -20 -1224 0 -1250 0 -1303 20 -63 24 -127 83 -158 148 -20 42 -23 66 -27 224 -2 98 -2 185 2 193 5 13 172 15 1488 13 l1483 -3 3 -159z"
            />
            <path
              stroke-width="20"
              d="M2016 4694 c-55 -55 -23 -152 55 -168 41 -8 937 -8 978 0 78 16 110 113 55 168 l-27 26 -517 0 -517 0 -27 -26z"
            />
            <path
              stroke-width="20"
              d="M1533 3451 c-63 -23 -98 -47 -146 -100 -95 -105 -99 -138 -95 -752 3 -489 4 -495 26 -551 33 -81 106 -158 184 -194 l63 -29 974 -3 c1060 -3 1031 -4 1121 53 55 35 116 110 142 174 23 56 23 57 23 596 l0 540 -23 57 c-31 76 -108 160 -180 195 l-57 28 -990 2 c-949 2 -992 2 -1042 -16z m2024 -207 c15 -12 37 -36 48 -55 19 -33 20 -52 20 -544 0 -492 -1 -511 -20 -544 -11 -19 -33 -43 -48 -55 l-28 -21 -969 0 -969 0 -28 21 c-15 12 -37 36 -48 55 -19 33 -20 55 -23 522 -3 507 -1 541 40 592 44 57 2 55 1040 52 955 -2 957 -2 985 -23z"
            />
            <path
              stroke-width="20"
              d="M1739 3000 l-30 -29 3 -331 3 -332 28 -24 c38 -33 96 -33 134 0 27 23 28 29 31 125 l4 101 52 0 c108 0 190 48 241 141 24 44 29 66 29 119 -1 36 -8 81 -16 101 -24 55 -78 110 -133 135 -44 21 -65 24 -183 24 l-134 0 -29 -30z m290 -202 c14 -25 14 -29 -5 -55 -17 -23 -30 -29 -68 -31 l-46 -3 0 61 0 61 52 -3 c45 -3 54 -7 67 -30z"
            />
            <path
              stroke-width="20"
              d="M2490 3003 c-23 -22 -53 -90 -145 -333 -110 -288 -116 -307 -105 -342 13 -46 43 -68 90 -68 49 0 78 24 106 86 l24 54 104 0 104 0 17 -50 c9 -27 28 -59 42 -70 28 -22 82 -26 114 -10 21 12 49 60 49 86 0 9 -52 154 -115 323 -128 340 -135 351 -210 351 -35 0 -51 -6 -75 -27z m84 -401 c-35 -8 -41 2 -26 38 l15 35 12 -35 c10 -29 10 -36 -1 -38z"
            />
            <path
              stroke-width="20"
              d="M2884 3015 c-28 -19 -48 -68 -40 -98 3 -13 46 -86 96 -162 l90 -137 0 -150 0 -150 29 -29 c53 -52 135 -33 162 38 5 13 9 86 9 162 l0 139 91 138 c84 129 90 142 86 179 -7 51 -44 85 -93 85 -53 0 -68 -12 -128 -105 -31 -47 -57 -85 -60 -85 -2 0 -27 36 -55 79 -28 44 -61 87 -73 95 -28 20 -87 21 -114 1z"
            />
            <path
              stroke-width="20"
              d="M2455 591 c-37 -16 -60 -51 -60 -93 0 -33 6 -45 33 -69 31 -28 36 -29 132 -29 95 0 101 1 130 28 40 36 47 77 20 120 -11 18 -32 37 -46 42 -30 12 -181 12 -209 1z"
            />
          </g>
        </svg>
      );
    }
    if (this.props.type === "complaints") {
      return (
        <svg
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            stroke="none"
          >
            <path d="M1075 5114 c-215 -32 -378 -91 -527 -191 -202 -135 -347 -307 -442 -522 -312 -709 101 -1506 864 -1668 117 -24 343 -24 460 0 373 80 681 316 848 652 96 194 139 446 113 661 -66 529 -442 942 -956 1049 -78 16 -299 28 -360 19z m275 -308 c349 -62 625 -320 722 -673 31 -113 30 -334 -1 -448 -68 -251 -246 -468 -476 -580 -142 -70 -223 -88 -395 -88 -172 0 -253 18 -395 88 -230 112 -408 329 -476 580 -31 114 -32 335 -1 448 48 175 136 322 266 444 206 193 485 277 756 229z" />
            <path d="M828 4339 c-43 -22 -78 -81 -78 -129 0 -76 74 -150 150 -150 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z" />
            <path d="M1428 4339 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 21 -11 53 -20 70 -20 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z" />
            <path d="M1087 3799 c-77 -18 -152 -62 -217 -128 -70 -70 -120 -159 -120 -212 0 -49 36 -106 80 -129 82 -42 154 -16 210 76 51 83 77 99 160 99 83 0 110 -16 157 -96 36 -59 94 -99 145 -99 74 0 148 75 148 149 0 53 -50 142 -120 212 -67 68 -139 110 -221 128 -53 13 -170 12 -222 0z" />
            <path d="M2788 5099 c-23 -12 -46 -35 -58 -59 -20 -39 -20 -57 -20 -1125 0 -1068 0 -1086 20 -1125 13 -26 34 -47 60 -60 39 -20 57 -20 1125 -20 1068 0 1086 0 1125 20 26 13 47 34 60 60 20 39 20 57 20 1125 0 1068 0 1086 -20 1125 -13 26 -34 47 -60 60 -39 20 -56 20 -1127 20 -1063 -1 -1089 -1 -1125 -21z m2032 -1184 l0 -905 -905 0 -905 0 0 905 0 905 905 0 905 0 0 -905z" />
            <path d="M1025 2396 c-402 -68 -726 -305 -903 -661 -239 -482 -109 -1103 303 -1445 239 -199 474 -285 775 -285 393 0 721 167 966 490 193 254 275 627 209 950 -85 416 -388 763 -785 898 -172 59 -403 80 -565 53z m400 -316 c308 -80 561 -333 646 -645 31 -114 32 -335 1 -448 -48 -175 -136 -322 -266 -444 -498 -466 -1295 -227 -1478 444 -31 113 -30 334 1 448 91 336 370 596 708 660 105 20 282 13 388 -15z" />
            <path d="M828 1639 c-43 -22 -78 -81 -78 -129 0 -76 74 -150 150 -150 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z" />
            <path d="M1428 1639 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 21 -11 53 -20 70 -20 76 0 150 74 150 150 0 50 -35 107 -80 130 -49 25 -94 25 -142 -1z" />
            <path d="M819 1087 c-39 -26 -69 -79 -69 -122 0 -51 55 -152 118 -215 186 -186 478 -186 664 0 63 63 118 164 118 215 0 78 -69 145 -150 145 -62 0 -106 -30 -145 -100 -73 -133 -237 -133 -310 0 -17 30 -46 65 -64 77 -46 31 -116 31 -162 0z" />
            <path d="M2788 2389 c-23 -12 -46 -35 -58 -59 -20 -39 -20 -57 -20 -1125 0 -1068 0 -1086 20 -1125 13 -26 34 -47 60 -60 39 -20 57 -20 1125 -20 1068 0 1086 0 1125 20 26 13 47 34 60 60 20 39 20 57 20 1125 0 1068 0 1086 -20 1125 -13 26 -34 47 -60 60 -39 20 -56 20 -1127 20 -1063 -1 -1089 -1 -1125 -21z m2032 -1184 l0 -905 -905 0 -905 0 0 905 0 905 905 0 905 0 0 -905z" />
            <path d="M4300 1649 c-19 -11 -146 -132 -282 -267 l-247 -246 -108 105 c-115 113 -153 139 -204 139 -72 0 -149 -77 -149 -149 0 -18 7 -46 16 -64 22 -43 307 -332 363 -368 59 -38 103 -38 162 0 57 37 632 616 653 658 34 68 6 153 -64 192 -48 27 -95 27 -140 0z" />
          </g>
        </svg>
      );
    }
    if (this.props.type === "onlineorder") {
      return (
        <svg
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            stroke="none"
          >
            <path d="M1353 5105 c-136 -37 -239 -123 -301 -252 l-37 -78 -3 -728 -3 -728 30 -29 c40 -41 89 -41 133 -1 l33 29 5 714 c4 587 7 717 19 738 31 55 63 88 113 116 53 29 53 29 254 32 l201 3 5 -106 c5 -93 10 -115 37 -170 37 -74 92 -127 170 -164 l56 -26 495 0 495 0 67 33 c135 66 198 182 198 363 l0 70 203 -3 c224 -3 247 -9 313 -75 73 -73 68 -23 74 -778 4 -525 8 -685 18 -702 30 -53 107 -59 153 -13 l30 29 -3 698 -3 698 -37 78 c-63 131 -165 216 -305 252 -83 22 -2330 21 -2410 0z" />
            <path d="M1065 3129 c-99 -12 -193 -80 -243 -174 l-27 -50 0 -300 0 -300 30 -54 c38 -68 102 -125 174 -152 73 -27 183 -23 253 10 64 31 125 94 157 161 l26 55 0 280 0 280 -33 67 c-33 68 -82 118 -148 151 -43 22 -129 34 -189 26z m140 -239 l30 -30 3 -237 c3 -257 0 -276 -50 -318 -54 -45 -151 -27 -183 35 -12 24 -15 75 -15 274 l0 246 28 31 c50 56 130 55 187 -1z" />
            <path d="M1633 3130 c-17 -6 -41 -22 -52 -36 -21 -26 -21 -37 -21 -491 l0 -465 29 -29 c46 -45 123 -39 153 14 9 14 15 83 18 200 l5 178 62 -31 c105 -53 169 -144 180 -258 10 -98 38 -132 108 -132 27 0 44 8 66 29 25 25 29 37 29 83 0 100 -46 226 -112 311 l-30 38 45 42 c75 71 92 114 92 237 0 98 -2 109 -30 159 -36 65 -92 114 -154 137 -55 19 -337 29 -388 14z m308 -200 c47 -13 69 -47 69 -105 0 -95 -26 -114 -152 -115 l-98 0 0 115 0 115 73 0 c39 0 88 -5 108 -10z" />
            <path d="M2373 3133 c-12 -2 -34 -18 -48 -34 l-25 -31 0 -465 0 -465 29 -29 c28 -28 33 -29 118 -29 150 0 259 44 358 144 106 107 145 210 145 381 0 163 -39 273 -132 371 -63 66 -131 111 -206 134 -58 18 -199 32 -239 23z m215 -229 c69 -34 128 -103 148 -172 23 -79 16 -232 -14 -289 -44 -84 -110 -138 -194 -158 l-28 -7 0 326 c0 371 -7 347 88 300z" />
            <path d="M3104 3130 c-17 -5 -39 -19 -50 -31 -18 -20 -19 -44 -22 -477 -2 -331 1 -464 9 -485 23 -54 35 -57 262 -57 l209 0 29 29 c51 51 33 137 -33 160 -17 6 -87 11 -154 11 l-124 0 0 115 0 115 129 0 c124 0 129 1 154 25 34 34 40 67 23 110 -22 52 -62 65 -196 65 l-111 0 3 113 3 112 142 3 142 3 25 30 c37 44 35 96 -5 135 l-30 29 -187 2 c-103 1 -201 -2 -218 -7z" />
            <path d="M3754 3130 c-17 -5 -39 -19 -50 -31 -18 -20 -19 -44 -22 -477 -3 -479 -1 -497 42 -527 32 -23 90 -19 123 9 l28 24 5 186 5 186 60 -28 c105 -50 168 -139 185 -263 14 -98 38 -128 103 -129 29 0 45 7 68 29 25 26 29 37 29 88 0 106 -35 206 -104 296 l-35 46 53 53 c35 35 58 70 69 102 22 63 22 187 1 249 -21 63 -84 130 -151 163 -53 26 -64 28 -217 31 -88 1 -175 -2 -192 -7z m308 -200 c51 -15 68 -42 68 -113 0 -48 -4 -62 -25 -82 -22 -23 -31 -25 -125 -25 l-100 0 0 108 c0 60 3 112 7 115 10 11 134 8 175 -3z" />
            <path d="M3948 1991 l-33 -29 -5 -794 c-4 -656 -7 -797 -19 -818 -31 -55 -63 -88 -113 -116 l-53 -29 -1165 0 -1165 0 -41 22 c-49 26 -98 73 -122 117 -16 28 -17 97 -22 794 l-5 764 -33 29 c-44 40 -93 40 -133 -1 l-30 -29 3 -778 3 -778 37 -78 c63 -131 165 -216 305 -252 86 -22 2320 -22 2406 0 140 36 242 121 305 252 l37 78 3 795 c1 437 0 805 -3 818 -7 28 -60 62 -97 62 -16 0 -41 -12 -60 -29z" />
            <path d="M2345 518 c-62 -34 -74 -110 -26 -159 l29 -29 212 0 212 0 29 29 c49 49 35 128 -28 160 -29 15 -401 14 -428 -1z" />
          </g>
        </svg>
      );
    }
    return <div></div>;
  }

  render() {
    return <React.Fragment>{this.renderIcon()}</React.Fragment>;
  }
}

export default Icon;
