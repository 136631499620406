import React, { Component } from "react";
import { isNull } from "util";
import { withRouter } from "react-router-dom";

class AuthenticatedComponent extends Component {
  componentDidMount() {
    let publicPath = false;

    if (this.props.location.pathname === "/pincodesignup") {
      publicPath = true;
    }
    if (this.props.location.pathname === "/signup") {
      publicPath = true;
    }
    if (this.props.location.pathname === "/ri") {
      publicPath = true;
    } else if (
      this.props.location.pathname.includes("/salestransactionview/")
    ) {
      publicPath = true;
    } else if (this.props.location.pathname.includes("/s/")) {
      publicPath = true;
    } else if (this.props.location.pathname.includes("/r/")) {
      publicPath = true;
    } else if (this.props.location.pathname.includes("/customerdisplay")) {
      publicPath = true;
    }
    if (isNull(this.props.user.user) && !publicPath) {
      this.props.history.push("/");
      // this.setState({showChildren:false})
    }
  }

  render() {
    return !isNull(this.props.user.user) ? (
      <div>{this.props.children}</div>
    ) : null;

    // return (!isNull(this.props.user.user) && this.props.registration.registration) ? <div>{this.props.children}</div> : null;
  }
}

export default withRouter(AuthenticatedComponent);
