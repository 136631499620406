import React, { Component } from 'react';
import './PageSection.css';

class PageSection extends Component {

    render(){
        return <React.Fragment>
             <div className="pagesection">
                {this.props.title && this.props.title !=="" && <div className="pagesection-header">
                    {/* <div className="pagesection-title"> */}
                    {this.props.title}
                    {/* </div> */}
                </div>}
                <div className="pagesection-body">
                    {this.props.children}
                </div>
            </div>
        </React.Fragment>
    }
}

export default PageSection;