import React,{Component} from 'react'
import { timingSafeEqual } from 'crypto';
var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class ProductConsumption extends Component{

    constructor(props)
    {
      super(props)
      this.state = {
        isProductTypeAheadLoading:false,
        productsTypeAheadOptions:[],
        productConsumptionLineItems:[],
        noRecordsAddedError:"",
        missingQtyError:"",
        saving:false,
        sale:null,
        saleID:"",
        productConsumptionID:"",
        selectedLineItemIndex:0,
        product:null
      }
      this.onProuctTypeAheadSearch = this.onProuctTypeAheadSearch.bind(this)
      this.onProductSelected = this.onProductSelected.bind(this)
      this.onRemoveProduct = this.onRemoveProduct.bind(this)
      this.onQtyChange= this.onQtyChange.bind(this)
      this.onSave= this.onSave.bind(this)
      this.onCancel = this.onCancel.bind(this)
    }

    async componentDidMount()
    {
        let productConsumptionLineItems = []
        if(this.props.location.state.productConsumptionID)
        {
            //get product consumtion details
            let productConsumptionDetails =  await this.props.getProductConsumptionDetails(this.props.location.state.productConsumptionID)
            productConsumptionLineItems = productConsumptionDetails.LineItems
        }
        this.setState({
            product:this.props.location.state.sale.LineItems[this.props.location.state.selectedLineItemIndex].Product,
            productConsumptionID:this.props.location.state.productConsumptionID,
            sale:this.props.location.state.sale,
            saleID:this.props.location.state.saleID,
            selectedLineItemIndex:this.props.location.state.selectedLineItemIndex,
            productConsumptionLineItems:productConsumptionLineItems
        })
    }

    onProuctTypeAheadSearch = (query) => {
        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProducts(this.props.user.user.RegistrationID, 0, 10, "active", query, "", "", "",
            "", "", "","")
            .then((result) => {
                this.setState({ productsTypeAheadOptions: result.searchResult,isProductTypeAheadLoading: false  })
            })
    }

    onProductSelected(product) {
        let lineItem = {Product:product,
        // {
        //     key:product.key,
        //     SKU:product.SKU,
        //     Name: product.Name,
        //     ProductType:product.ProductType,
        //     ProductTypeID: product.ProductTypeID,
        //     CategoryID: product.CategoryID,
        //     Category: product.Category,
        //     SubCategoryID: product.SubCategoryID,
        //     SubCategory: product.SubCategory,
        //     BrandID: product.BrandID,
        //     Brand: product.Brand,
        //     BarCode:product.BarCode,
        //     MRP:product.MRP,
        //     SupplyPrice:product.SupplyPrice,
        //     RetailPrice:product.RetailPrice
        // },
        Qty:0,
        Unit: product.hasOwnProperty('UOM') ? product.UOM :"",
        Error:""
        }
        let productConsumptionLineItems = this.state.productConsumptionLineItems
        productConsumptionLineItems.push(lineItem)
        this.setState({productConsumptionLineItems:productConsumptionLineItems})
    }

    onRemoveProduct(index)
    {
        let productConsumptionLineItems = this.state.productConsumptionLineItems
        productConsumptionLineItems.splice(index, 1)
        this.setState({productConsumptionLineItems:productConsumptionLineItems})
    }

    onQtyChange(qty, index){
        let productConsumptionLineItems = this.state.productConsumptionLineItems
        productConsumptionLineItems[index].Qty = qty
        this.setState({productConsumptionLineItems:productConsumptionLineItems})
    }

    onCancel(){
        this.props.history.push({pathname: '/home/sales/viewsales/' + this.state.saleID})
    }
    
    onSave()
    {
        this.setState({saving:true})
        let noRecordsAddedError = ""
        let missingQtyError=""
        let lineItems = this.state.productConsumptionLineItems
        if(lineItems.length===0)
        {
            noRecordsAddedError = "Please select prodcuts"
        }
        lineItems.map(m=>{
            if(m.Qty ===0)
            {
                missingQtyError ="Please specify qty"
                m.Error="Please specify qty"
            }
        })
        if(noRecordsAddedError !=="" || missingQtyError!=="")
        {
            this.setState({noRecordsAddedError:noRecordsAddedError, missingQtyError:missingQtyError,saving:false})
            return 
        }
        let productConsumption = {
            ProductConsumptionType:'Manual',
            TransactionNo:this.state.sale.TransactionNo,
            TransactionID: this.state.saleID,
            StoreID : this.state.sale.Store.key,
            StoreCode: this.state.sale.Store.StoreCode,
            StoreName: this.state.sale.Store.StoreName,
            StoreLocationID:this.state.sale.CashRegister.StoreLocationID,
            StoreLocation:this.state.sale.CashRegister.StoreLocation,
            ProductID: this.state.product.key,
            ProductSKU: this.state.product.SKU,
            ProductName: this.state.product.Name,
            LineItems:this.state.productConsumptionLineItems
        }
        console.log('productConsumption', productConsumption)
        return
        this.props.saveProductConsumption(this.state.productConsumptionID, productConsumption).then((key)=>{
            this.setState({saving:false})
            let sale = this.state.sale
            sale.LineItems[this.state.selectedLineItemIndex].ManualProductConsumptionID= key
            this.props.updateSales(sale, this.state.saleID).then(()=> {
                //redirect back to sales
                this.props.history.push({pathname: '/home/sales/viewsales/' + this.state.saleID})
            })
        })
    }

    render(){
        
        return <div className="content-wrapper" >
            <section className="content-header">
                <h1>Product Consumption</h1>
            </section>   
            <section className="content">
                <div className="box" style={{marginBottom:this.props.mobile ? "0px" :"" }}>            
                    <div className="box-body">
                        {this.state.product &&<div className="form-group" >
                             <div><b>SKU: {this.state.product.SKU}</b></div>
                             <div><b>Name:{this.state.product.Name}</b></div>
                        </div>}
                        <div className={(this.state.noRecordsAddedError!=="" || this.state.missingQtyError !=="" )? "form-group  has-error" : "form-group"} style={{width:"50%"}}>
                            <label>Select product</label>
                            <AsyncTypeahead
                                autoFocus={true}
                                allowNew={false}
                                isLoading={this.state.isProductTypeAheadLoading}
                                multiple={false}
                                options={this.state.productsTypeAheadOptions}
                                labelKey="Name"
                                minLength={3}
                                onSearch={this.onProuctTypeAheadSearch}
                                placeholder="Start typing to search products"
                                filterBy={['Name', 'SKU']}
                                // bsSize={'large'}
                                autoFocus={true}
                                dropup={false}
                                highlightOnlyResult={true}
                                selected={this.state.productsTypeAheadSelected}
                                onChange={(selected) => {
                                    this.onProductSelected(selected[0])
                                    this.setState({ productsTypeAheadSelected: [] })
                                }}
                                renderMenuItemChildren={(option, props) => (
                                    <div>
                                        <div><b>{option.Name}</b></div>
                                        <div>{option.SKU}</div>
                                    </div>
                                )}
                            />
                             {this.state.noRecordsAddedError !== "" && <span className="help-block">{this.state.noRecordsAddedError}</span>}
                             {this.state.missingQtyError !== "" && <span className="help-block">{this.state.missingQtyError}</span>}
                        </div>
                        <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Name</th>
                                        <th style={{width:"80px"}}>Qty</th>
                                        <th style={{width:"80px"}}> Unit</th>
                                        <th style={{width:"60px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.productConsumptionLineItems.map((m,index)=>  <tr>
                                        <td>
                                            {m.Product.SKU}
                                        </td>
                                        <td>
                                            {m.Product.Name}
                                        </td>
                                        <td>
                                            <div className={m.Error!=="" ? "form-group  has-error" : "form-group"} >
                                                <input type="number"
                                                    className="form-control"
                                                    onChange={(evt) => this.onQtyChange(evt.target.value,index)}
                                                    value={m.Qty}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                        {m.Unit}
                                        </td>
                                        <td>
                                            <div className="input-group-btn">
                                            <button type="button" className="btn btn-default btn-md" 
                                            onClick={() => this.onRemoveProduct(index)}>
                                            <i className="fa fa-remove"></i>
                                            </button>
                                            </div>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" disabled={this.state.saving} onClick={this.onCancel} >Cancel</button>
                        <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" disabled={this.state.saving} onClick={()=> this.onSave()} disabled={this.state.saving}>Save</button>
                    </div>
                </div>
            </section>
    </div>
    }
}
export default ProductConsumption