import React, { Component } from "react";
import Icon from "../../2/Controls/Icon";
class WidgetPincodeStoreoperatingParameters extends Component {
  constructor() {
    super();
    this.state = {
      showStoreOperatingParameters: false,
      pincodeSelectionScore: "",
      pincodePricingScore: "",
      pincodeAppRating: "",
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.store) {
      let pincodeSelectionScore = this.props.user.store.PincodeSelectionScore;
      console.log(
        "this.props.user.store.PincodeSelectionScore",
        this.props.user.store.PincodeSelectionScore
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.user &&
          this.props.user.store &&
          this.props.user.store.PincodeSelectionScore && (
            <div className="row">
              <div className="col-md-3">
                <div className="info-box info-yellow">
                  <div className="info-box-icon ">
                    <Icon type="product" />
                  </div>
                  <div className="info-box-content">
                    <div className="info-box-text">
                      Catalogue selection score
                    </div>
                    <div className="info-box-number">
                      {this.props.user.store.PincodeSelectionScore}%
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="info-box info-yellow">
                  <div className="info-box-icon ">
                    <Icon type="customer" />
                  </div>
                  <div className="info-box-content">
                    <div className="info-box-text">Pricing score</div>
                    <div className="info-box-number">
                      {this.props.user.store.PincodePricingScore}%
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="info-box info-yellow">
                  <div className="info-box-icon ">
                    <Icon type="membership" />
                  </div>
                  <div className="info-box-content">
                    <div className="info-box-text">Ratings on Pincode app</div>
                    <div className="info-box-number">
                      {this.props.user.store.PincodeAppRating}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }
}

export default WidgetPincodeStoreoperatingParameters;
