import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';
import Input from '../2/Controls/Input';

class AllInventoryReport extends Component {
    constructor() {
        super()
        this.state = {
            taxes: [{ "name": "CGST", "perc": "CGST%" }, { "name": "SGST", "perc": "SGST%" }, { "name": "IGST", "perc": "IGST%" }, { "name": "CESS", "perc": "CESS%" }],
            inventoryList: [],
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: '',
            searchProductDetail: '',
            activePage: 1,
            itemsCountPerPage: 2000,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            exportCalled: false,
            loading: true,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            productCustomField1DisplayName:'ProductCustomField1',
            productCustomField2DisplayName:'ProductCustomField2',
            productCustomField3DisplayName:'ProductCustomField3',
            productCustomField4DisplayName:'ProductCustomField4',
            productCustomField5DisplayName:'ProductCustomField5',
            productCustomField6DisplayName:'ProductCustomField6',
            searchProductCustomField1: '', 
            searchProductCustomField2:'', 
            searchProductCustomField3:'', 
            searchProductCustomField4:'', 
            searchProductCustomField5:'', 
            searchProductCustomField6:'',
            transactionType:null,
            showOption:"1"
        }

        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onExportClick = this.onExportClick.bind(this);
        this.getData = this.getData.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Vendor') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
            if (customField.ModuleName === 'Product') {
                let productCustomField1DisplayName = customField.CustomField1Name.trim() === '' ? 'ProductCustomField1' : customField.CustomField1Name.trim()
                let productCustomField2DisplayName = customField.CustomField2Name.trim() === '' ? 'ProductCustomField2' : customField.CustomField2Name.trim()
                let productCustomField3DisplayName = customField.CustomField3Name.trim() === '' ? 'ProductCustomField3' : customField.CustomField3Name.trim()
                let productCustomField4DisplayName = customField.CustomField4Name.trim() === '' ? 'ProductCustomField4' : customField.CustomField4Name.trim()
                let productCustomField5DisplayName = customField.CustomField5Name.trim() === '' ? 'ProductCustomField5' : customField.CustomField5Name.trim()
                let productCustomField6DisplayName = customField.CustomField6Name.trim() === '' ? 'ProductCustomField6' : customField.CustomField6Name.trim()
                this.setState({
                    productCustomField1DisplayName: productCustomField1DisplayName,
                    productCustomField2DisplayName: productCustomField2DisplayName,
                    productCustomField3DisplayName: productCustomField3DisplayName,
                    productCustomField4DisplayName: productCustomField4DisplayName,
                    productCustomField5DisplayName: productCustomField5DisplayName,
                    productCustomField6DisplayName: productCustomField6DisplayName
                })
            }
        })

        this.setState({transactionType:this.props.location.state.transactionType});
        this.loadInventoryDetailReport(this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail,
            this.state.showOption
        )   
    }

    async loadInventoryDetailReport(from, searchTransactionNo, searchTransactionFromDate
        , searchTransactionToDate, searchVendorDetail, searchProductDetail, showOption) {

        this.setState({ loading: true });
        let inventoryList =[]
        // console.log('this.props.location.state.transactionType.',this.props.location.state.transactionType)
        inventoryList = await this.getData(
            showOption,
            from, 
            searchTransactionNo, 
            searchTransactionFromDate,
            searchTransactionToDate, 
            searchVendorDetail, 
            searchProductDetail)   
        
        var col = [];
        for (var i = 0; i < inventoryList.length; i++) {
            for (var key in inventoryList[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < inventoryList.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result += "<td>";
                result += inventoryList[i][col[j]] == undefined ? '' : inventoryList[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }
        result += "</tbody>";
        result += "</table>";
        // console.log(result);
        this.setState({ inventoryList: result, loading: false })
    }

    async getData(showOption,from, searchTransactionNo, searchTransactionFromDate
        , searchTransactionToDate, searchVendorDetail, searchProductDetail){
        
        let type = this.props.location.state.type && this.props.location.state.type ==="Received" ? this.props.location.state.type : ""
        let result1 = await this.props.getAllInvetoryDetailReport(
            showOption,
            from, 
            this.state.itemsCountPerPage,
            this.props.user.user.RegistrationID,
            type==="" ?  this.props.user.store.key : "",
            type!=="" ?  this.props.user.store.key : "",
            searchTransactionNo, 
            this.props.location.state.transactionType.key,
            searchTransactionFromDate, 
            searchTransactionToDate,
            searchVendorDetail, 
            searchProductDetail);
//             from, size, registrationID, fromStoreID, toStoreID, searchTransactionNo, searchTransactionType,
//   searchTransactionFromDate, searchTransactionToDate, searchVendorDetail, searchProductDetail, searchProductCustomField1 = "", searchProductCustomField2 = "", searchProductCustomField3 = "",
//   searchProductCustomField4 = "", searchProductCustomField5 = "", searchProductCustomField6 = "", scrollID = ""

        let rawData = result1.searchResult;
        console.log('rawData',rawData);
        let inventoryList = [];

        if(showOption ==="1"){
            let scrollID = result1.scrollID
 
            for (let index = 1; index < (result1.totalItemsCount / 2000); index++) {
                let result = await this.props.getAllInvetoryDetailReport("", "", "", "", "", "", "", "", "", "", "", "", "", "","","","", scrollID);
                result.searchResult.forEach(element => {
                    rawData.push(element)
                });
            }

            for (let index = 0; index < rawData.length; index++) {
                const data = rawData[index];

                let inventory = {
                    "Store Name ": data.StoreName,
                    "Store Location": data.StoreLocation,
                    "Transaction No": data.TransactionNo,
                    "Transaction Type": data.TransactionType,
                    "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                }

                if (this.props.location.state.transactionType.TransactionType !== 'Transfer In' 
                && this.props.location.state.transactionType.TransactionType !== 'Transfer Out' 
                && this.props.location.state.transactionType.TransactionMovement === "storetostore"
                ) {
                    inventory["To Store"] = data.ToStore ? data.ToStore : ""
                }

                if (this.props.location.state.transactionType.TransactionMovement === "vendortostore" ||
                    this.props.location.state.transactionType.TransactionMovement === "storetovendor") {

                    inventory["Vendor Code"] = data.VendorCode ? data.VendorCode : ""
                    inventory["Vendor Name"] = data.VendorName ? data.VendorName : ""
                    inventory["Vendor Phone No."] = data.VendorPhoneNo ? data.VendorPhoneNo : ""
                    inventory[this.state.customField1DisplayName] = ""
                    inventory[this.state.customField2DisplayName] = ""
                    inventory[this.state.customField3DisplayName] = ""
                    inventory[this.state.customField4DisplayName] = ""
                    inventory[this.state.customField5DisplayName] = ""
                    inventory[this.state.customField6DisplayName] = ""

                    inventory[this.state.customField1DisplayName] = data.hasOwnProperty('VendorCustomField1') ? data.VendorCustomField1 : ""
                    inventory[this.state.customField2DisplayName] = data.hasOwnProperty('VendorCustomField2') ? data.VendorCustomField2 : ""
                    inventory[this.state.customField3DisplayName] = data.hasOwnProperty('VendorCustomField3') ? data.VendorCustomField3 : ""
                    inventory[this.state.customField4DisplayName] = data.hasOwnProperty('VendorCustomField4') ? data.VendorCustomField4 : ""
                    inventory[this.state.customField5DisplayName] = data.hasOwnProperty('VendorCustomField5') ? data.VendorCustomField5 : ""
                    inventory[this.state.customField6DisplayName] = data.hasOwnProperty('VendorCustomField6') ? data.VendorCustomField6 : ""
                }

                if (this.props.location.state.transactionType.TransactionType === 'Transfer In') {
                    inventory["From Store"] = data.FromStore ? data.FromStore : ""
                }

                if (this.props.location.state.transactionType.TransactionType === 'Transfer Out') {
                    inventory["To Store"] = data.ToStore ? data.ToStore : ""
                }
                inventory["Type"] =  data.ProductType
                inventory["HSNSACCode" ] =  data.HSNSACCode
                inventory["Item Code"] = data.SKU
                inventory["Item Name"] =  data.ProductName
                inventory["BarCode"] =  data.BarCode
                inventory["Brand"] =  data.Brand
                inventory["Category"] =  data.Category
                inventory["SubCategory"] =  data.SubCategory
                inventory[this.state.productCustomField1DisplayName] = data.hasOwnProperty('CustomField1') ? data.CustomField1 : ""
                inventory[this.state.productCustomField2DisplayName] = data.hasOwnProperty('CustomField2') ? data.CustomField2 : ""
                inventory[this.state.productCustomField3DisplayName] = data.hasOwnProperty('CustomField3') ? data.CustomField3 : ""
                inventory[this.state.productCustomField4DisplayName] = data.hasOwnProperty('CustomField4') ? data.CustomField4 : ""
                inventory[this.state.productCustomField5DisplayName] = data.hasOwnProperty('CustomField5') ? data.CustomField5 : ""
                inventory[this.state.productCustomField6DisplayName] = data.hasOwnProperty('CustomField6') ? data.CustomField6 : ""

                if (this.props.location.state.transactionType.IncludePriceAndTax) {
                    inventory["UniqueNo1"] = data.UniqueNo1
                    inventory["UniqueNo2"] = data.UniqueNo2
                    inventory["UniqueNo3"] = data.UniqueNo3
                    inventory["UniqueNo4"] = data.UniqueNo4

                    if (this.props.registration.registration.hasOwnProperty('BatchTrackingEnabled') && this.props.registration.registration.BatchTrackingEnabled) {
                        inventory["BatchExpDate"] = data.BatchExpDate
                        inventory["BatchMRP"] = data.BatchMRP
                        inventory["BatchMfgDate"] = data.BatchMfgDate
                        inventory["BatchNo"] = data.BatchNo
                        inventory["BatchRetailPrice"] = data.BatchRetailPrice
                    }
                }
                inventory["InStock Qty"] = data.InStockQty ? data.InStockQty : 0
                inventory["Qty"] = data.Qty
                inventory["Cancelled"] = ""
                inventory["RefNo"] = data.hasOwnProperty('RefNo') ? data.RefNo : ''
                inventory["Notes"] = data.hasOwnProperty('Notes') ? data.Notes : ''

                if (this.props.location.state.transactionType.IncludePriceAndTax) {
                    inventory["Supply Price"] = data.SupplyPrice
                    inventory.Discount = data.DiscountAmount
                    inventory["Taxble Amount"] = data.TaxableAmount
                    inventory["Tax Inclusive"] = data.TaxInclusive
                }

                inventory.Cancelled = "No"
                if (data.CancellationReason) {
                    inventory.Cancelled = "Yes"
                }

                if (this.props.location.state.transactionType.IncludePriceAndTax ) {

                    if(data.TaxComponentAmount){
                        for (let index = 0; index < this.state.taxes.length; index++) {
                            inventory[this.state.taxes[index].name] = 0
                            inventory[this.state.taxes[index].perc] = ""
                        }
        
                        if (data.TaxComponentAmount) {
                            data.TaxComponentAmount.map((tax) => {
                                inventory[tax.TaxName] = tax.TaxAmount
                                inventory[tax.TaxName + '%'] = tax.TaxPercentage
                            })
                        }
                    }
                    inventory["Amount"] = data.Amount
                }
                inventoryList.push(inventory);
            }
            
            }
            else
            {
                for (let index = 0; index < rawData.length; index++) {
                    const data = rawData[index];
    
                    let inventory = {
                        "SKU": data.sku,
                        "Product": data.product,
                        "Qty" : data.total_quantity
                    }

                    if (this.props.location.state.transactionType.IncludePriceAndTax) {
                        inventory.Discount = data.total_discount
                        inventory["Taxble Amount"] = data.total_taxable
                        inventory["Tax Amount"] = data.total_taxes
                        inventory["Amount"] = data.total_amount
                    }
                    inventoryList.push(inventory);
                }
            }
            return inventoryList;
    }

    handleKeyPress() {
            this.onSearchClick()
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: '', searchProductDetail: ''
        })
        this.loadInventoryDetailReport(0, "", new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "", this.state.showOption)
    }

    async onExportClick() {
        let inventoryList = []
        inventoryList = await this.getData(
            this.state.showOption,
            0, 
            this.state.searchTransactionNo, 
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate, 
            this.state.searchVendorDetail, 
            this.state.searchProductDetail
        )    
        
        const csvData = constatnts.objToCSV(inventoryList);
        this.setState({ loading: false, exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", this.props.location.state.transactionType.TransactionType + "_Details.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    // async onExportClick() {

    //     let result1 = await this.props.getAllInvetoryDetailReport(0, 2000,

    //         this.state.searchTransactionNo, this.props.location.state.transactionType.key,
    //         this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
    //         this.state.searchVendorDetail, this.state.searchProductDetail);

    //     let rawData = result1.searchResult;
    //     let scrollID = result1.scrollID

    //     for (let index = 1; index < (result1.totalItemsCount / 2000); index++) {
    //         let result = await this.props.getAllInvetoryDetailReport("", "", "", "", "", "", "", "", "", "", "", "", "", "","","","", scrollID);
    //         result.searchResult.forEach(element => {
    //             rawData.push(element)
    //         });
    //     }
      
    //     let inventoryList = [];
    //     for (let index = 0; index < rawData.length; index++) {
    //         const data = rawData[index];

    //         let inventory = {
    //             "Store Name ": data.StoreName,
    //             "Store Location": data.StoreLocation,
    //             "Transaction No": data.TransactionNo,
    //             "Transaction Type": data.TransactionType,
    //             "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
    //             "Type": data.ProductType,
    //             "HSNSACCode" : data.HSNSACCode,
    //             "Item Code": data.SKU,
    //             "Item Name": data.ProductName,
    //             "BarCode": data.BarCode,
    //             "Brand": data.Brand,
    //             "Category": data.Category,
    //             "SubCategory": data.SubCategory
    //         }

    //         inventory[this.state.productCustomField1DisplayName] = data.hasOwnProperty('CustomField1') ? data.CustomField1 : ""
    //         inventory[this.state.productCustomField2DisplayName] = data.hasOwnProperty('CustomField2') ? data.CustomField2 : ""
    //         inventory[this.state.productCustomField3DisplayName] = data.hasOwnProperty('CustomField3') ? data.CustomField3 : ""
    //         inventory[this.state.productCustomField4DisplayName] = data.hasOwnProperty('CustomField4') ? data.CustomField4 : ""
    //         inventory[this.state.productCustomField5DisplayName] = data.hasOwnProperty('CustomField5') ? data.CustomField5 : ""
    //         inventory[this.state.productCustomField6DisplayName] = data.hasOwnProperty('CustomField6') ? data.CustomField6 : ""


    //         inventory["UniqueNo1"] = data.UniqueNo1
    //         inventory["UniqueNo2"] = data.UniqueNo2
    //         inventory["UniqueNo3"] = data.UniqueNo3
    //         inventory["UniqueNo4"] = data.UniqueNo4

    //         if (this.props.registration.registration.hasOwnProperty('BatchTrackingEnabled') && this.props.registration.registration.BatchTrackingEnabled) {
    //             inventory["BatchExpDate"] = data.BatchExpDate
    //             inventory["BatchMRP"] = data.BatchMRP
    //             inventory["BatchMfgDate"] = data.BatchMfgDate
    //             inventory["BatchNo"] = data.BatchNo
    //             inventory["BatchRetailPrice"] = data.BatchRetailPrice
    //         }

    //         inventory["InStock Qty"] = data.InStockQty ? data.InStockQty : 0
    //         inventory["Qty"] = data.Qty
    //         inventory["Cancelled"] = ""
    //         inventory["RefNo"] = data.hasOwnProperty('RefNo') ? data.RefNo : ''
    //         inventory["Notes"] = data.hasOwnProperty('Notes') ? data.Notes : ''

    //         if (this.props.location.state.transactionType.TransactionMovement === "vendortostore" ||
    //             this.props.location.state.transactionType.TransactionMovement === "storetovendor") {

    //             inventory["Vendor Code"] = data.VendorCode ? data.VendorCode : ""
    //             inventory["Vendor Name"] = data.VendorName ? data.VendorName : ""
    //             inventory["Vendor Phone No."] = data.VendorPhoneNo ? data.VendorPhoneNo : ""
    //             inventory[this.state.customField1DisplayName] = ""
    //             inventory[this.state.customField2DisplayName] = ""
    //             inventory[this.state.customField3DisplayName] = ""
    //             inventory[this.state.customField4DisplayName] = ""
    //             inventory[this.state.customField5DisplayName] = ""
    //             inventory[this.state.customField6DisplayName] = ""

    //             inventory[this.state.customField1DisplayName] = data.hasOwnProperty('VendorCustomField1') ? data.VendorCustomField1 : ""
    //             inventory[this.state.customField2DisplayName] = data.hasOwnProperty('VendorCustomField2') ? data.VendorCustomField2 : ""
    //             inventory[this.state.customField3DisplayName] = data.hasOwnProperty('VendorCustomField3') ? data.VendorCustomField3 : ""
    //             inventory[this.state.customField4DisplayName] = data.hasOwnProperty('VendorCustomField4') ? data.VendorCustomField4 : ""
    //             inventory[this.state.customField5DisplayName] = data.hasOwnProperty('VendorCustomField5') ? data.VendorCustomField5 : ""
    //             inventory[this.state.customField6DisplayName] = data.hasOwnProperty('VendorCustomField6') ? data.VendorCustomField6 : ""
    //         }

    //         if (this.props.location.state.transactionType.TransactionType === 'Transfer In') {
    //             inventory["From Store"] = data.FromStore ? data.FromStore : ""
    //         }

    //         if (this.props.location.state.transactionType.TransactionType === 'Transfer Out') {
    //             inventory["To Store"] = data.ToStore ? data.ToStore : ""
    //         }

    //         if (this.props.location.state.transactionType.IncludePriceAndTax) {
    //             inventory["Supply Price"] = data.SupplyPrice
    //             inventory.Discount = data.DiscountAmount
    //             inventory["Taxble Amount"] = data.TaxableAmount
    //             inventory["Tax Inclusive"] = data.TaxInclusive
    //         }

    //         inventory.Cancelled = "No"
    //         if (data.CancellationReason) {
    //             inventory.Cancelled = "Yes"
    //         }

    //         if (this.props.location.state.transactionType.IncludePriceAndTax && data.TaxComponentAmount) {

    //             for (let index = 0; index < this.state.taxes.length; index++) {
    //                 inventory[this.state.taxes[index].name] = 0
    //                 inventory[this.state.taxes[index].perc] = ""
    //             }

    //             if (data.TaxComponentAmount) {
    //                 data.TaxComponentAmount.map((tax) => {
    //                     inventory[tax.TaxName] = tax.TaxAmount
    //                     inventory[tax.TaxName + '%'] = tax.TaxPercentage
    //                 })
    //             }
    //         }

    //         inventory["Amount"] = data.Amount

    //         inventoryList.push(inventory);
    //     }

    //     const csvData = constatnts.objToCSV(inventoryList);
    //     this.setState({ loading: false, exportCalled: false })
    //     const csvBlob = new Blob([csvData], { type: "text/csv" });
    //     const url = window.URL.createObjectURL(csvBlob);
    //     const a = document.createElement("a");
    //     a.setAttribute("hidden", "");
    //     a.setAttribute("href", url);
    //     a.setAttribute("download", this.props.location.state.transactionType.TransactionType + "_Details.csv");
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
            
    // }


    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadInventoryDetailReport(
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail,
            this.state.showOption
        )
    }


    returnReportTitle(){
        let title = this.props.location.state.transactionType.TransactionType
        if(this.props.location.state.type){
            title = title + " - " + this.props.location.state.type;
        }
        return title;
    }

    render() {
    
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6  col-xs-9">
                                        <font style={{ fontSize: "30px" }}>{this.returnReportTitle()}</font>
                                    </div>
                                    <div className="col-md-6  col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                            <div className="row">
                                    <div className="col-md-4">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <Input
                                         type="date"
                                         label="From Date"
                                         value={this.state.searchTransactionFromDate}
                                         onChange={(value) => this.setState({ searchTransactionFromDate:value })}
                                         onEnter={this.handleKeyPress}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Input
                                         type="date"
                                         label="To Date"
                                         value={this.state.searchTransactionToDate}
                                         onChange={(value) => this.setState({ searchTransactionToDate:value })}
                                         onEnter={this.handleKeyPress}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Input
                                         type="text"
                                         label="Transaction No"
                                         value={this.state.searchTransactionNo}
                                         onChange={(value) => this.setState({ searchTransactionNo:value })}
                                         onEnter={this.handleKeyPress}
                                        />
                                    </div>
                                    {(this.props.location.state.transactionType.TransactionMovement === "vendortostore" || this.props.location.state.transactionType.TransactionMovement === "storetovendor") && <div className="col-md-2">
                                        <Input
                                         type="text"
                                         label="Vendor"
                                         placeholder="Vendor Name, Code, Phone Number, Email"
                                         value={this.state.searchVendorDetail}
                                         onChange={(value) => this.setState({ searchVendorDetail:value })}
                                         onEnter={this.handleKeyPress}
                                        />
                                    </div>}
                                    <div className="col-md-2">
                                        <Input
                                         type="text"
                                         label="Product Name, SKU"
                                         value={this.state.searchProductDetail}
                                         onChange={(value) => this.setState({ searchProductDetail:value })}
                                         onEnter={this.handleKeyPress}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Input
                                         type="select"
                                         label="Show"
                                         value={this.state.showOption}
                                         onChange={(value) => this.setState({ showOption:value })}
                                         onEnter={this.handleKeyPress}
                                        >
                                            <option value="1">Transaction line item wise</option>
                                            <option value="2">Product wise total</option>
                                        </Input>
                                    </div>
                                    {/* <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField1DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField1}
                                                onChange={(evt) => this.setState({ searchProductCustomField1: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField2DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField2}
                                                onChange={(evt) => this.setState({ searchProductCustomField2: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField3DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField3}
                                                onChange={(evt) => this.setState({ searchProductCustomField3: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField4DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField4}
                                                onChange={(evt) => this.setState({ searchProductCustomField4: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField5DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField5}
                                                onChange={(evt) => this.setState({ searchProductCustomField5: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField6DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField6}
                                                onChange={(evt) => this.setState({ searchProductCustomField6: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{width:"auto", overflowX:"auto"}} className="scroll2">
                                        {ReactHtmlParser(this.state.inventoryList)}
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(AllInventoryReport)