import React, { Component } from 'react';
import Icon from './Icon';

class Button extends Component {

    returnCSS(){
        return "button-" + this.props.type + " " + (this.props.selected ? "button-selected " :"") +  (this.props.processing ? "button-processing" : "") + " " + (this.props.css ? "button-" + this.props.css : "button-rounded" )
    }

    renderButton(){
        return <button 
        disabled={this.props.processing}
        className={this.returnCSS()} 
        onClick={this.props.onClick}>
        {this.props.icon && <Icon type={this.props.icon}/>}
        {!this.props.processing && <div>{this.props.text}</div>}
        {this.props.processing && <i className="fa fa-spinner fa-spin"/>}
        </button>
    }

    renderLink(){
        return <a className={"button-" + this.props.type} onClick={this.props.onClick}>{this.props.text}</a>
    }

    render(){
        return <React.Fragment>
            {(this.props.type==="primary" || this.props.type==="secondary") && this.renderButton()}
            {this.props.type==="link" && this.renderLink()}
            {(this.props.type==="remove" && <button 
            onClick={this.props.onClick}
            disabled={this.props.processing}
            className="btn btn-default btn-flat">Remove slected <i className="fa fa-trash-o"></i></button>)}
        </React.Fragment>
    }
}

export default Button