import React, { Component } from "react";
import { isMobileApp } from "../../../actions/Util";
import ImageButton from "./ImageButton";
import Button from "./Button";
import "./Window.css";

// .window-close-btn {
//     align-items: end;
//     display: flex;
//     width: 100%;
//     flex-direction: column;
//     margin-bottom: 2px;
// }

class Window extends Component {
  width() {
    // console.log('isMobileApp()', isMobileApp())
    if (isMobileApp() && !this.props.modal) {
      return "100%";
    } else if (isMobileApp() && this.props.modal) {
      return "90%";
    } else if (this.props.width) {
      return this.props.width;
    } else {
      return "400px";
    }
  }

  height() {
    if (isMobileApp() && !this.props.modal) {
      return "100%";
    } else if (this.props.height) {
      return this.props.height;
    } else {
      return "auto";
    }
  }

  contentHeight() {
    if (isMobileApp()) {
      return "100%";
    } else {
      return "auto";
    }
    // else if(this.props.height){
    //     return this.props.height;
    // }
    // else
    // {
    //     return "400px";
    // }
  }

  renderCloseButton() {
    return (
      <div className="window-close-btn" onClick={() => this.props.onClose()}>
        <ImageButton type="remove" onClick={() => this.props.onClose()} />
      </div>
    );
  }

  returnClassName(className) {
    let windowBackGroundClassName = this.props.showBackGroudColor
      ? " window-bg-color"
      : "";

    if (this.props.modal) {
      return className;
    } else if (isMobileApp()) {
      return className + "-mobile" + windowBackGroundClassName;
    } else {
      return className;
    }
  }

  returnClassNameHeader(className) {
    if (this.props.modal) {
      return className + " no-print";
    } else if (isMobileApp()) {
      return className + "-mobile no-print";
    } else {
      return className + " no-print";
    }
  }

  showClose() {
    let show = true;
    if (this.props.hasOwnProperty("hideClose") && this.props.hideClose) {
      show = false;
    }
    // else if(isMobileApp()){
    //     show = false;
    // }
    console.log("show", show);
    return show;
  }

  renderModal() {
    //in case it is modal or desktop then show as modal
    return (
      <React.Fragment>
        <div
          className="window-overlay"
          onClick={() => {
            if (!this.props.hideClose) {
              this.props.onClose();
            }
          }}
        ></div>
        <div
          className={this.returnClassName("window")}
          style={{ width: this.width(), minHeight: this.height() }}
        >
          {!this.props.hideClose && !isMobileApp() && this.renderCloseButton()}
          <div className={this.returnClassName("window-container")}>
            {this.props.header && this.props.header !== "" && (
              <div
                className={this.returnClassNameHeader(
                  this.props.header
                    ? "window-header"
                    : "window-header-borderless"
                )}
              >
                <div className="left-100">
                  <div className="window-mobile-header-title">
                    {this.props.header}
                  </div>
                </div>
              </div>
            )}
            <div
              className={this.returnClassName(
                this.props.header
                  ? "window-content"
                  : "window-content-borderless"
              )}
            >
              {this.props.children}
            </div>
            {this.renderButtons()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  returnContentHeight() {
    let screenHeight = window.innerHeight;
    let heightPx =
      Number(screenHeight) -
      Number(67) -
      Number(this.props.buttons && this.props.buttons.length > 0 ? 67 : 0) +
      "px";
    return { height: heightPx };
  }

  renderFullScreen() {
    return (
      <React.Fragment>
        <div
          className={this.returnClassName("window")}
          style={{ width: this.width(), minHeight: this.height() }}
        >
          <div className={this.returnClassName("window-container")}>
            <div
              className={this.returnClassNameHeader(
                this.props.header ? "window-header" : "window-header-borderless"
              )}
            >
              <div className="left-100">
                {this.showClose() && (
                  <ImageButton
                    type={"leftarrow"}
                    label={"back"}
                    onClick={() => this.props.onClose()}
                  />
                )}
                <div className="window-mobile-header-title">
                  {this.props.header}
                </div>
              </div>
            </div>
            <div
              className={this.returnClassName(
                this.props.header
                  ? "window-content"
                  : "window-content-borderless"
              )}
              style={this.returnContentHeight()}
            >
              {this.props.children}
            </div>
            {this.renderButtons()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderButtons() {
    return (
      <React.Fragment>
        {this.props.buttons && this.props.buttons.length > 0 && (
          <div className={this.returnClassName("window-footer")}>
            <div className="left">
              {this.props.buttons
                .filter((f) => f.Align === "left")
                .map((b) => (
                  <Button
                    type={b.Type}
                    text={b.Text}
                    processing={this.props.processing}
                    onClick={() => {
                      if (this.props.hasOwnProperty("onButtonClick")) {
                        this.props.onButtonClick(b.Text);
                      }
                    }}
                  />
                ))}
            </div>
            <div className="right">
              {this.props.buttons
                .filter((f) => f.Align === "right")
                .map((b) => (
                  <Button
                    type={b.Type}
                    text={b.Text}
                    processing={this.props.processing}
                    onClick={() => {
                      if (this.props.hasOwnProperty("onButtonClick")) {
                        this.props.onButtonClick(b.Text);
                      }
                    }}
                  />
                ))}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {(!isMobileApp() || this.props.modal) && this.renderModal()}
        {isMobileApp() && !this.props.modal && this.renderFullScreen()}
      </React.Fragment>
    );
  }
  // render(){
  //     return <React.Fragment>
  //         <div className="window-overlay"
  //             onClick={()=>{
  //                 if(this.showClose())
  //                 {this.props.onClose()}
  //             }}>
  //         </div>
  //         <div className={this.returnClassName("window")}
  //             style={{width:this.width(),minHeight:this.height()}}>
  //             {this.showClose() && this.renderCloseButton()}
  //             <div className={this.returnClassName("window-container")}>
  //                 <div className={this.returnClassName(this.props.header ? "window-header": "window-header-borderless")}>
  //                     <div className="left-100">
  //                         {this.showClose() && <ImageButton type={"leftarrow"} label={"back"} onClick={()=>this.props.onClose()}/>}
  //                         <div className="window-mobile-header-title">{this.props.header}</div>
  //                     </div>
  //                 </div>
  //                 <div className={this.returnClassName(this.props.header ? "window-content" : "window-content-borderless")}>
  //                     {this.props.children}
  //                 </div>
  //                 {(this.props.buttons && this.props.buttons.length > 0) &&  <div className={this.returnClassName("window-footer")}>
  //                     <div className="left">
  //                         {this.props.buttons.filter(f=> f.Align ==="left").map(b=><Button
  //                         type={b.Type}
  //                         text={b.Text}
  //                         processing = {this.props.processing}
  //                         onClick={()=>{
  //                             if(this.props.hasOwnProperty('onButtonClick') )
  //                             {
  //                                 this.props.onButtonClick(b.Text)
  //                             }
  //                         }}
  //                         />)}
  //                     </div>
  //                     <div className="right">
  //                         {this.props.buttons.filter(f=> f.Align ==="right").map(b=><Button
  //                         type={b.Type}
  //                         text={b.Text}
  //                         processing = {this.props.processing}
  //                         onClick={()=>{
  //                             if(this.props.hasOwnProperty('onButtonClick') )
  //                             {
  //                                 this.props.onButtonClick(b.Text)
  //                             }
  //                         }}
  //                         />)}
  //                     </div>
  //                 </div>}
  //             </div>
  //         </div>
  //     </React.Fragment>
  // }
}

export default Window;
