import React, { Component } from 'react'
import './Filter.css'

class Filter extends Component {

    constructor () {
        super()
        this.state={     
            filterOptions:[]
        }
        this.onTextChange = this.onTextChange.bind(this)
        this.renderControl = this.renderControl.bind(this)
    }

    
    onTextChange(evt,index)
    {
        let filterOptions = this.state.filterOptions
        filterOptions[index].value = evt.target.value 
        this.setState({filterOptions:filterOptions}) 
    }

    componentDidMount()
    {
        this.setState({filterOptions:this.props.filterOptions})
    }

    renderControl( m, index){
        if(m.type==="selection")
        {
            return <select type="text" className="form-control"
            onChange={(evt) => { this.onTextChange(evt, index)}}
            value={this.state.filterOptions[index].value}>
            {m.options.map(o=> <option value={o.value}>{o.label}</option>)}
        </select>
        }
        else
        {
            return <input type={m.type} className="form-control"
            onChange={(evt) => { this.onTextChange(evt, index) }}
            value={this.state.filterOptions[index].value} />
        }
    }

    render() {
        return <React.Fragment>
            <div className="filter">
                <div className="filter-overlay" onClick={()=>this.props.onCloseClick()}></div>
                <div className="filter-content">
                    <div className="filter-header">
                        <div className="filter-title">Filters</div>
                        <div className="pull-right filter-close" onClick={()=>this.props.onCloseClick()}>
                            <svg viewBox="0 0 18 18" width="18px" height="18px" xmlns="http://www.w3.org/2000/svg"><path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z"></path></svg>
                        </div>
                    </div>
                    <div className="filter-body">
                        {this.state.filterOptions.map((m,index)=>
                            <div className="form-group " key={"filter_options_" + index}  >
                                <label>{m.label}</label>
                                {this.renderControl(m, index)}
                            </div>
                        )}
                    </div>    
                    <div className="filter-footer">
                        <button type="button" className="filter-button-clearall" onClick={()=>this.props.onClearClick(this.state.filterOptions)}>Clear All</button>
                        <button type="button" className="filter-button-apply" onClick={()=> this.props.onApplyClick(this.state.filterOptions)}>Apply</button>
                    </div>   
                </div>
            </div>
        </React.Fragment>
        }
    }

export default Filter