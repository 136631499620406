import * as actionTypes from '../actionTypes';

const intialState= {
    auth:null,
    user:null,
    registration:null,
    store:null,
    cashRegister:null,
    acl: null,
    cashRegisterClosing:null,
    hasAccessToMultipleStores:false
}
export default function(state=intialState, action) {
    switch (action.type) {
        case actionTypes.STORE_RESET_USER:
            return {...state, 
                registration:null,
                store:null,
                cashRegister:null,
                acl: null,
                cashRegisterClosing:null,
                hasAccessToMultipleStores:false}
        case actionTypes.SIGN_OUT:
            // return intialState
            return {...state, 
                auth:null,
                user:null,
                registration:null,
                store:null,
                cashRegister:null,
                acl: null,
                cashRegisterClosing:null,
                hasAccessToMultipleStores:false
            }
        case actionTypes.SET_AUTH:
            return {...state, auth:action.auth } 
        case actionTypes.SET_USER:
            return {...state, user:action.user} 
        case actionTypes.SET_REGISTRATION:
            return {...state,registration: action.registration }     
        case actionTypes.SET_STORE:
            return {...state, store: action.store }  
        case actionTypes.SET_CASH_REGISTER:
            return {...state, cashRegister: action.cashRegister }  
        case actionTypes.SET_ACL:
            return { ...state, acl: action.acl }
        case actionTypes.SET_CASH_REGISTER_CLOSING:
            return {...state, cashRegisterClosing: action.cashRegisterClosing}
        case actionTypes.SET_HAS_ACCESS_TO_MULTIPLE_STORES:
            return {...state, hasAccessToMultipleStores: action.hasAccessToMultipleStores}
        default:
            return state;
    }
}
