import React, { Component } from 'react';
import Loader from '../Loader'
import CSVReader from 'react-csv-reader';
import * as constatnts from '../../Constatnts'

class ProductImageUpload extends Component {
    constructor() {
        super()
        this.state = {
            showImportOptions: true,
            processing: false,
            processingMessage: "",
            processingCompleted: false,
            imageFiles: null,
            records: [],
            processingResult: []
        }
        this.onCSVFileSelected = this.onCSVFileSelected.bind(this)
        this.onFolderSelected = this.onFolderSelected.bind(this)
        this.onUploadClicked = this.onUploadClicked.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    onFolderSelected(files) {
        this.setState({ imageFiles: files })
    }

    onCSVFileSelected(records) {
        this.setState({ records: records })
    }

    async onUploadClicked() {
        //check if csv ile columsn are correct
        let records = this.state.records
        let processingResult = []
        if (records.length < 2) {
            alert('No products specified')
            return
        }
        if (records[0][0] !== "SKU" || records[0][1] !== "Image") {
            alert('Invald file format')
            return
        }
        if (!this.state.imageFiles) {
            alert('Image folder not selected')
            return
        }
        if (this.state.imageFiles.length === 0) {
            alert('Selected folder does not have any files')
            return
        }
        this.setState({ processing: true, showImportOptions: false })
        let images = []
        for (let i = 0; i < this.state.imageFiles.length; i++) {
            images.push(this.state.imageFiles["" + i + ""])
        }
        // console.log('records', records);
        for (let ctr = 1; ctr < records.length; ctr++) {
            //check if SKU is valid

            if (records[ctr][0].trim() === "") {
                processingResult.push({
                    SKU: records[ctr][0],
                    Image: records[ctr][1],
                    Status: "Error",
                    Message: "SKU not provided"
                })
                continue
            }
            if (records[ctr][1].trim() === "") {
                processingResult.push({
                    SKU: records[ctr][0],
                    Image: records[ctr][1],
                    Status: "Error",
                    Message: "Image not provided"
                })
                // records[ctr].Status="Error"
                // records[ctr].Message="Image not provided"
                continue
            }
            
            this.setState({ processingMessage: "Row:" + Number(ctr + 1) + ", SKU:" + records[ctr][0] + ", Image:" + records[ctr][1] })
            let productFilter = this.props.config.Products.filter(f => f.SKU.toLowerCase() === records[ctr][0].toLowerCase())
            if (productFilter.length > 0) {
                //product found 
                let productID = productFilter[0].key
                ///check for image
                let imageFilter = images.filter(f => f.name.toLowerCase() === records[ctr][1].toLowerCase())
                if (imageFilter.length > 0) {
                    //image found 

                    let image = imageFilter[0]
                    let fileName = + new Date().getTime() + image.name;

                    let downloadURL = await this.props.uploadFileToServer(image, this.props.registration.registration.StorageFolder + '/Product', fileName)
                    if (downloadURL !== "") {
                        let bucket = 'posible.in/'
                        let folder = this.props.registration.registration.StorageFolder + '/Product/'
                        let url = 'https://storage.googleapis.com/' + bucket + folder + fileName

                        console.log("url", url);
                        await this.props.updateProductImage(productID, url, image.name, image.size)
                        processingResult.push({
                            SKU: records[ctr][0],
                            Image: records[ctr][1],
                            Status: "Success",
                            Message: ""
                        })
                        // records[ctr].Status="Success"
                        // records[ctr].Message=""
                    }
                    else {
                        //unable to upload image
                        processingResult.push({
                            SKU: records[ctr][0],
                            Image: records[ctr][1],
                            Status: "Error",
                            Message: "Unable to upload Image"
                        })
                        // records[ctr].Status="Error"
                        // records[ctr].Message="Unable to upload Image"
                    }
                }
                else {
                    //image not found 
                    processingResult.push({
                        SKU: records[ctr][0],
                        Image: records[ctr][1],
                        Status: "Error",
                        Message: "Image not found"
                    })
                    // records[ctr].Status="Error"
                    // records[ctr].Message="Image not found"
                }
            }
            else {
                //product no tfound 
                processingResult.push({
                    SKU: records[ctr][0],
                    Image: records[ctr][1],
                    Status: "Error",
                    Message: "SKU not found"
                })
                // records[ctr].Status="Error"
                // records[ctr].Message="SKU not found"
            }
        }
        //on processing complete
        this.setState({ records: records, processing: false, processingCompleted: true, processingMessage: "Finished uploading", processingResult: processingResult })
    }

    onExportClick() {
        const csvData = constatnts.objToCSV(this.state.processingResult);
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "ProductImageImportProcessing.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {

        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Import Product Image(s)</h1>
            </section>
            <section className="content" >
                <div className="row">
                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                        <div className="box ">
                            <div className="box-body">
                                {this.state.showImportOptions && <div className="row">
                                    <div className="col-md-12">
                                        {/* <label>Please select csv file with product sku and image file name</label> */}
                                        <CSVReader
                                            cssClass="csv-input"
                                            label="Please select csv file with product sku and image file name"
                                            onFileLoaded={this.onCSVFileSelected}
                                            inputId="ObiWan"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <br />
                                        <label>Please select folder containing product images</label>
                                        <input type="file" directory="" webkitdirectory="" onChange={(e) => this.onFolderSelected(e.target.files)} />
                                    </div>
                                </div>}
                                {this.state.processing && <div className="row">
                                    <div className="col-md-12">
                                        <div><h4>Please do not close this window while upload is in progress</h4></div>
                                        <br />
                                        <div><Loader show={this.state.processing} /></div>
                                        <br />
                                        <div><b>{this.state.processingMessage}</b></div>
                                        <br />
                                    </div>
                                </div>}
                                {this.state.processingCompleted && <div className="row">
                                    <div className="col-md-12">
                                        <button className="btn btn-default btn-flat btn-md pull-left"
                                            onClick={this.onExportClick}
                                        >Download Processing File <i className="fa fa-download" ></i></button>
                                    </div>
                                    <br />
                                    <br/>
                                    <div className="col-md-12">
                                        <button className="btn btn-default btn-flat btn-md pull-left"
                                            onClick={() => this.setState({
                                                showImportOptions: true,
                                                processing: false,
                                                processingCompleted: false,
                                                records: [],
                                                imageFiles: null,
                                                processingMessage: ""
                                            })}
                                        >Import Again<i className="fa fa-upload"></i></button>
                                    </div>
                                </div>}
                            </div>
                            {this.state.showImportOptions && <div className="box-footer">
                                <button className="btn btn-flat btn-md btn-success pull-right" onClick={this.onUploadClicked} disabled={this.state.processing}>Upload</button>
                                <button className="btn btn-flat btn-md btn-flat btn-default" onClick={ ()=> this.props.history.push({pathname: "/home/product/"})} >Cancel</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default ProductImageUpload
