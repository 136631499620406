import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'

class OmniInquiries extends Component {
    constructor() {
        super()
        this.state = {
            payments: [],
            loading: true,
        }
        this.loadOmniInquiries = this.loadOmniInquiries.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        this.loadOmniInquiries()
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSave()
        }
    }

    loadOmniInquiries(option) {
        this.props.getOmniInquiries(this.props.user.user.RegistrationID).then((inquiries) => {
            this.setState({ loading: false, inquiries: inquiries })
        })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Omni Inquries</font>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <React.Fragment>
                                    {this.state.inquiries &&
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email ID</th>
                                                    <th>Contact No</th>
                                                    <th>Message</th>
                                                    <th>Created On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.inquiries.map((inquiry) =>
                                                    <tr>
                                                        <td>{inquiry.Name}</td>
                                                        <td>{inquiry.EmailID}</td>
                                                        <td>{inquiry.ContactNo}</td>
                                                        <td>{inquiry.Message}</td>
                                                        <td>{(new Date(inquiry.CreatedOn.seconds * 1000)).toString().substring(0, 24)}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>}
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(OmniInquiries)