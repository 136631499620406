import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import Switch from "react-switch"
import Dropzone from 'react-dropzone'
import { SketchPicker } from 'react-color';
import Modal from 'react-responsive-modal';
import Input from '../2/Controls/Input';
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';

class OmniProfile extends Component {
    constructor() {
        super();
        this.state = {
            storeID: '',
            stores: [],
            cashRegisters: [],
            cashRegisterID: '',
            transactionTypeID: '',
            showProductPrice: false,
            saving: false,
            emailID: "",
            contactNo: "",
            whatsAppNo: "",
            facebookURL: "",
            instagramURL: "",
            twitterURL: "",
            youtubeURL: "",
            pinterestURL: "",
            metaDescription:'',
            metaKeywords:'',
            officeAddress: '',
            showContactDetailsInMenu: false,
            showContactDetailsInFooter: false,
            showSocialMediaDetailsInMenu: false,
            showSocialMediaDetailsInFooter: false,
            appTitle: "",
            appImageLogo: null,
            appImageIcon: null,
            websiteImageLogo:null,
            websiteImageIcon:null,
            showMultipleStores:false,
            enableMobileOTPLogin:false,
            gaCode:'',
            primaryColor:'',
            geoFencing:false,
            customCSS:"",
            loginTypes:[
                {value:"customercode", text:"Customer Code"},
                {value:"mobile", text:"Mobile"},
                {value:"emailid", text:"Email Id"},
            ],
            customerLoginType:"mobile"
        }
        this.onStoreChange = this.onStoreChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.loadCashRegisters = this.loadCashRegisters.bind(this)
        this.renderGeneralSettings = this.renderGeneralSettings.bind(this)
        this.renderSocialMediaSettings = this.renderSocialMediaSettings.bind(this)
        this.renderTransactionSettings = this.renderTransactionSettings.bind(this)
        this.renderImage = this.renderImage.bind(this)
        this.onImageDrop = this.onImageDrop.bind(this)
        this.onImageRemove = this.onImageRemove.bind(this)
        this.onColorSelected = this.onColorSelected.bind(this)
        this.loadSelectedStoreDetails= this.loadSelectedStoreDetails.bind(this);

    }

    async componentDidMount() {
 
        let stores = await this.props.getUserStores(this.props.user.user.RegistrationID, "") ;
        this.setState({ stores: stores});  
        let checkoutConfig = await this.props.getOmniSettings();//
        console.log('checkoutConfig', checkoutConfig);
        if(checkoutConfig){
            if (checkoutConfig.hasOwnProperty('StoreID') && checkoutConfig.StoreID !== "") {
                await this.loadCashRegisters(checkoutConfig.StoreID);
            }
            let geoFencing = checkoutConfig.hasOwnProperty('GeoFencing') ? checkoutConfig.GeoFencing : false;
            let storeID = checkoutConfig.hasOwnProperty('StoreID') ? checkoutConfig.StoreID : ''
            this.setState({
                showProductPrice: checkoutConfig.hasOwnProperty('ShowProductPrice') ? checkoutConfig.ShowProductPrice : false,
                storeID: storeID,
                cashRegisterID: checkoutConfig.hasOwnProperty('CashRegisterID') ? checkoutConfig.CashRegisterID : '',
                transactionTypeID: checkoutConfig.hasOwnProperty('TransactionTypeID') ? checkoutConfig.TransactionTypeID : '',
                showBillingAddress: checkoutConfig.hasOwnProperty('ShowBillingAddress') ? checkoutConfig.ShowBillingAddress : false,
                emailID: checkoutConfig.hasOwnProperty('EmailID') ? checkoutConfig.EmailID : "",
                contactNo: checkoutConfig.hasOwnProperty('ContactNo') ? checkoutConfig.ContactNo : "",
                whatsAppNo: checkoutConfig.hasOwnProperty('WhatsAppNo') ? checkoutConfig.WhatsAppNo : "",
                facebookURL: checkoutConfig.hasOwnProperty('FacebookURL') ? checkoutConfig.FacebookURL : "",
                instagramURL: checkoutConfig.hasOwnProperty('InstagramURL') ? checkoutConfig.InstagramURL : "",
                twitterURL: checkoutConfig.hasOwnProperty('TwitterURL') ? checkoutConfig.TwitterURL : "",
                youtubeURL: checkoutConfig.hasOwnProperty('YoutubeURL') ? checkoutConfig.YoutubeURL : "",
                pinterestURL: checkoutConfig.hasOwnProperty('PinterestURL') ? checkoutConfig.PinterestURL : "",
                showContactDetailsInMenu: checkoutConfig.hasOwnProperty('ShowContactDetailsInMenu') ? checkoutConfig.ShowContactDetailsInMenu : "",
                showContactDetailsInFooter: checkoutConfig.hasOwnProperty('ShowContactDetailsInFooter') ? checkoutConfig.ShowContactDetailsInFooter : "",
                showSocialMediaDetailsInMenu: checkoutConfig.hasOwnProperty('ShowSocialMediaDetailsInMenu') ? checkoutConfig.ShowSocialMediaDetailsInMenu : "",
                showSocialMediaDetailsInFooter: checkoutConfig.hasOwnProperty('ShowSocialMediaDetailsInFooter') ? checkoutConfig.ShowSocialMediaDetailsInFooter : "",
                appTitle: checkoutConfig.hasOwnProperty('AppTitle') ? checkoutConfig.AppTitle : "",
                appImageLogo: checkoutConfig.hasOwnProperty('AppLogo') ? checkoutConfig.AppLogo : null,
                appImageIcon: checkoutConfig.hasOwnProperty('AppIcon') ? checkoutConfig.AppIcon : null,
                metaDescription: checkoutConfig.hasOwnProperty('MetaDescription') ? checkoutConfig.MetaDescription : "",
                metaKeywords: checkoutConfig.hasOwnProperty('MetaKeywords') ? checkoutConfig.MetaKeywords : "",
                officeAddress: checkoutConfig.hasOwnProperty('OfficeAddress') ? checkoutConfig.OfficeAddress : "",
                websiteImageLogo: checkoutConfig.hasOwnProperty('WebsiteImageLogo') ? checkoutConfig.WebsiteImageLogo : null,
                websiteImageIcon: checkoutConfig.hasOwnProperty('WebsiteImageIcon') ? checkoutConfig.WebsiteImageIcon : null,
                primaryColor: checkoutConfig.hasOwnProperty('PrimaryColor') ? checkoutConfig.PrimaryColor : "",
                gaCode: checkoutConfig.hasOwnProperty('GA') ? checkoutConfig.GA : "",
                showMultipleStores: checkoutConfig.hasOwnProperty('ShowMultipleStores') ? checkoutConfig.ShowMultipleStores : false,
                enableMobileOTPLogin: checkoutConfig.hasOwnProperty('EnableMobileOTPLogin') ? checkoutConfig.EnableMobileOTPLogin : false,
                customerLoginType:checkoutConfig.hasOwnProperty('CustomerLoginType') ? checkoutConfig.CustomerLoginType : "mobile",
                geoFencing: geoFencing,
                customCSS: checkoutConfig.hasOwnProperty("CustomCSS") ? checkoutConfig.CustomCSS : ""
            });

            for(let s=0; s<stores.length;s++){
                stores[s].CashRegisters  = await this.props.getCashRegisters(stores[s].key)
            }
            this.loadSelectedStoreDetails(storeID, geoFencing, stores);  
        }       
    }

    loadSelectedStoreDetails(storeID, geoFencing, stores){
        let storeLat="";
        let storeLng="";
        let deliveryRadius = ""
        if(geoFencing && storeID!==""){
            if(stores.filter(f=> f.key === storeID).length > 0){
                let store = stores.filter(f=> f.key === storeID)[0];
                if(store.hasOwnProperty('Location')){
                    storeLat = store.Location.lat;
                    storeLng = store.Location.lon
                }
                if(store.hasOwnProperty('DeliveryRadius')){
                    deliveryRadius = store.DeliveryRadius;
                }
            }
        }
        this.setState({ storeLat:storeLat, storeLng:storeLng, deliveryRadius:deliveryRadius })
    }

    async onSave() {
        // console.log('this.state.showProductPrice', this.state.showProductPrice)
        let deliveryRadiusError = "";
        let storeLatError = "";
        let storeLngError = "";

        if(this.state.showProductPrice){
            if(this.state.transactionTypeID===""){
                alert('Please select transaction type');
                return;
            }
            if(!this.state.showMultipleStores)
            {
                if(this.state.storeID===""){
                    alert('Please select store');
                    return;
                }
                if(this.state.cashRegisterID===""){
                    alert('Please select cash register');
                    return;
                }
            }
            else{
                // console.log('else block')
                let showOnline = false;
                let multipleStoreError = false
                this.state.stores.map(s=>{
                    if(s.hasOwnProperty('ShowOnline') && s.ShowOnline){
                        showOnline= true;
                        if(!s.hasOwnProperty('OmniCashRegisterID')){
                            alert('Please select cash register for online stores');
                            multipleStoreError = false;
                            // return;
                        }
                        if(s.hasOwnProperty('OmniCashRegisterID') && s.OmniCashRegisterID===""){
                            alert('Please select cash register for online stores');
                            multipleStoreError = false;
                            // return;
                        }   
                    }
                })
                // console.log('showOnline',showOnline)
                if(!showOnline){
                    alert('At least one store needs to be online');
                    return;
                }
                if(multipleStoreError){
                    return;
                }
            }
        }
        if(this.state.geoFencing &&  !this.state.showMultipleStores){
            if(this.state.deliveryRadius ===""){
                deliveryRadiusError= "Please provide delivery radius";
            }
            if(this.state.storeLat ===""){
                storeLatError = "Please provide store latitude"
            }
            if(this.state.storeLat ===""){
                storeLngError = "Please provide store longitude"
            }
        }

        if(deliveryRadiusError!=="" || storeLatError !=="" || storeLngError !==""){
            this.setState({deliveryRadiusError:deliveryRadiusError,storeLatError:storeLatError, storeLngError:storeLngError });
            return;
        }

        // let registration = this.props.registration.registration;
        // let checkoutConfig = null
        // if (registration.hasOwnProperty('CheckoutConfig')) {checkoutConfig = registration.CheckoutConfig;}
        // else {checkoutConfig = {}}

        let checkoutConfig = await this.props.getOmniSettings();
        // console.log('checkoutConfig', checkoutConfig);
        if(!checkoutConfig){
            checkoutConfig = {}
        }
        checkoutConfig.StoreID = this.state.showMultipleStores ? "" : this.state.storeID
        checkoutConfig.CashRegisterID = this.state.showMultipleStores ? "" :this.state.cashRegisterID
        checkoutConfig.TransactionTypeID = this.state.transactionTypeID
        checkoutConfig.ShowProductPrice = this.state.showProductPrice
        checkoutConfig.EmailID = this.state.emailID
        checkoutConfig.ContactNo = this.state.contactNo
        checkoutConfig.WhatsAppNo = this.state.whatsAppNo
        checkoutConfig.OfficeAddress = this.state.officeAddress
        checkoutConfig.FacebookURL = this.state.facebookURL
        checkoutConfig.InstagramURL = this.state.instagramURL
        checkoutConfig.TwitterURL = this.state.twitterURL
        checkoutConfig.YoutubeURL = this.state.youtubeURL
        checkoutConfig.PinterestURL = this.state.pinterestURL
        checkoutConfig.ShowContactDetailsInMenu = this.state.showContactDetailsInMenu
        checkoutConfig.ShowContactDetailsInFooter = this.state.showContactDetailsInFooter
        checkoutConfig.ShowSocialMediaDetailsInMenu = this.state.showSocialMediaDetailsInMenu
        checkoutConfig.ShowSocialMediaDetailsInFooter = this.state.showSocialMediaDetailsInFooter
        checkoutConfig.AppTitle = this.state.appTitle
        checkoutConfig.AppLogo = this.state.appImageLogo
        checkoutConfig.AppIcon = this.state.appImageIcon
        checkoutConfig.MetaKeywords = this.state.metaKeywords
        checkoutConfig.MetaDescription = this.state.metaDescription
        checkoutConfig.WebsiteImageIcon = this.state.websiteImageIcon
        checkoutConfig.WebsiteImageLogo = this.state.websiteImageLogo
        checkoutConfig.PrimaryColor = this.state.primaryColor
        checkoutConfig.GA = this.state.gaCode
        checkoutConfig.ShowMultipleStores = this.state.showMultipleStores
        checkoutConfig.EnableMobileOTPLogin = this.state.enableMobileOTPLogin;
        checkoutConfig.CustomerLoginType = this.state.customerLoginType;
        checkoutConfig.GeoFencing = this.state.geoFencing;
        checkoutConfig.CustomCSS = this.state.customCSS;
        // registration.CheckoutConfig = checkoutConfig;

        // console.log('registration', registration);

        await this.props.saveOmniSettings(checkoutConfig)
        this.props.getToastr("Online store settings saved successfully")
        // this.props.getRegistration(this.props.registration.registration.key)
        
        //if multiple stroes then save store details
        if(this.state.showMultipleStores){
            //start 
            this.state.stores.map(async s=>{
                s.CashRegisters = [];
                // console.log('store saving', s)
                await this.props.updateStore(s, s.key)
            })
        }
        if(this.state.geoFencing &&  !this.state.showMultipleStores && this.state.storeID!==""){
            // console.log('store saving')
            let stores = this.state.stores;
            if(stores.filter(f=> f.key === this.state.storeID).length > 0){
                let store = stores.filter(f=> f.key === this.state.storeID)[0];
                store.Location = {lat:this.state.storeLat, lon:this.state.storeLng}
                store.DeliveryRadius = this.state.deliveryRadius;
                // console.log(store)
                await this.props.updateStore(store, this.state.storeID)
            }
        }
    }

    onStoreChange(e) {

        if (e.target.value !== "") {
            this.loadSelectedStoreDetails(e.target.value, this.state.geoFencing, this.state.stores);
            this.loadCashRegisters(e.target.value);
        }
        else {
            this.setState({ cashRegisters: [] })
        }
        this.setState({ storeID: e.target.value })
    }

    loadCashRegisters(storeID) {
        this.props.getCashRegisters(storeID).then((cashRegisters) => {
            this.setState({ cashRegisters: cashRegisters })
        })
    }

    onImageDrop(acceptedFiles, type) {

        if (acceptedFiles.length > 0) {
            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({loadingType: type})
            let fileName = + new Date().getTime() + acceptedFiles[0].name;
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/omni', fileName).then((downloadURL) => {
                //update product with images url
                let bucket = 'posible.in/'
                let folder = this.props.registration.registration.StorageFolder + '/omni/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + fileName

                let image = { Name: fileName, Size: acceptedFiles[0].size, Preview: url }
                if (type === "mobilelogo") {
                    this.setState({ loadingType: '', appImageLogo: image })
                }
                else if (type === "mobileicon"){
                    this.setState({ loadingType: '', appImageIcon: image })
                }else if (type === "websitelogo") {
                    this.setState({ loadingType: '', websiteImageLogo: image })
                }
                else if (type === "websiteicon"){
                    this.setState({ loadingType: '', websiteImageIcon: image })
                }
            })
        }
        else { alert("No file selected") }
    }

    onImageRemove(type) {
        // if (type === "logo") {
        //     this.setState({ appImageLogo: null })
        // }
        // else {
        //     this.setState({ appImageIcon: null })
        // }
        if (type === "mobilelogo") {
            this.setState({appImageLogo: null })
        }
        else if (type === "mobileicon"){
            this.setState({appImageIcon: null })
        }else if (type === "websitelogo") {
            this.setState({websiteImageLogo: null })
        }
        else if (type === "websiteicon"){
            this.setState({websiteImageIcon: null })
        }
    }

    renderImage(type) {
        const image = type=="websitelogo" ? this.state.websiteImageLogo : type==="websiteicon" ? this.state.websiteImageIcon : type==="mobilelogo" ? this.state.appImageLogo : type==="mobileicon" ? this.state.appImageIcon :  null;
        return <React.Fragment>
            {!image &&
                <Dropzone onDrop={(files) => this.onImageDrop(files, type)}>
                    <div>Try dropping some files here, or click to select files to upload.</div>
                </Dropzone>}
            {image && <div className="form-group">
                {/* <ul className="mailbox-attachments clearfix">
                    <li> */}
                        <span className="mailbox-attachment-icon has-img"><img src={image.Preview}
                            alt="Attachment" /></span>
                        <div className="mailbox-attachment-info">
                            <span className="mailbox-attachment-name">{image.Name.length > 20 ? image.Name.substr(0, 20) + "..." : image.Name}</span>
                            <span className="mailbox-attachment-size">
                                {Math.round(image.Size / 1024)} KB
                                <a onClick={() => this.onImageRemove(type)}
                                    className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                            </span>
                        </div>
                    {/* </li>
                </ul> */}
            </div>}
        </React.Fragment>
    }

    // renderAppIcon() {
    //     return <React.Fragment>
    //         {!this.state.appImageIcon &&
    //             <Dropzone onDrop={(files) => this.onImageDrop(files, "icon")}>
    //                 <div>Try dropping some files here, or click to select files to upload.</div>
    //             </Dropzone>}
    //         {this.state.appImageIcon && <div className="form-group">
    //             <ul className="mailbox-attachments clearfix">
    //                 <li>
    //                     <span className="mailbox-attachment-icon has-img"><img src={this.state.appImageIcon.Preview}
    //                         alt="Attachment" /></span>
    //                     <div className="mailbox-attachment-info">
    //                         <span className="mailbox-attachment-name">{this.state.appImageIcon.Name.length > 20 ? this.state.appImageIcon.Name.substr(0, 20) + "..." : this.state.appImageIcon.Name}</span>
    //                         <span className="mailbox-attachment-size">
    //                             {Math.round(this.state.appImageIcon.Size / 1024)} KB
    //                             <a onClick={() => this.onImageRemove("icon")}
    //                                 className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
    //                         </span>
    //                     </div>
    //                 </li>
    //             </ul>
    //         </div>}
    //     </React.Fragment>
    // }

    
    onColorChangeComplete = (color, event) => {
        this.setState({ selectedColor: color.hex });
      };

    onColorSelected(){
        this.setState({ showColorPicker: false });
        if(this.state.control==="primarycolor")
        {
            this.setState({primaryColor: this.state.selectedColor})
        }
    }

    renderColorPickerControl(){
        return <Modal open={this.state.showColorPicker} onClose={() => this.setState({ showColorPicker: false })} center>
            <div className="box-body">
                <SketchPicker
                color={ this.state.selectedColor }
                onChangeComplete={ this.onColorChangeComplete }/>
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showColorPicker: false })} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.onColorSelected}>OK</button>
            </div>
        </Modal>
    }

    onShowColorPicker(control, color){
        this.setState({showColorPicker:true, control:control, selectedColor:color})
    }

    renderGeneralSettings() {
        return <PageSection title="General details">

            <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Online store link&nbsp;</label><br />
                            {(this.props.registration.registration.hasOwnProperty('DomainName') && this.props.registration.registration.DomainName !== '') &&
                                <a href={'https://' + this.props.registration.registration.DomainName} target="_blank">{this.props.registration.registration.DomainName}</a>

                            }
                            {!this.props.registration.registration.hasOwnProperty('DomainName') &&
                                <a href={'https://omni.posible.in/' + this.props.registration.registration.UniqueName} target="_blank">{'omni.posible.in/' + this.props.registration.registration.UniqueName}</a>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>App/store title&nbsp;</label><br />
                            <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ appTitle: evt.target.value })}
                                value={this.state.appTitle}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Type of online site &nbsp;</label>
                            <span className="fa fa-info-circle" data-tip data-for='typeOFOnlineSitetoolTip' ></span>
                            <ReactTooltip id='typeOFOnlineSitetoolTip' type='dark' place="top" effect="solid">
                                <span>Online Ordering: Prodcut pricing will be displayed to your customers. You will be able to accept online orders and payments<br />Online Catalog: Product pricing is not displayed to your customers. Your will be able to accept enqueries and orders without payments</span>
                            </ReactTooltip>
                            <select className="form-control"
                                onChange={(evt) => {
                                    let showProductPrice = evt.target.value == "1" ? true : false;
                                    this.setState({ showProductPrice: showProductPrice });
                                }}
                                value={this.state.showProductPrice ? "1" : "2"}>
                                <option value="1">Online Ordering</option>
                                <option value="2">Online Catalog</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className= "form-group ">
                            <label>Primary color</label>
                            <div className="input-group">
                                <input className="form-control"
                                        value={this.state.primaryColor}
                                        onChange={(e)=> this.setState({primaryColor:e.target.value})}/>
                                <div className="input-group-btn">
                                    <button className="btn btn-default btn-md" onClick={()=>this.onShowColorPicker("primarycolor", this.state.primaryColor)}>Pick color</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Website logo&nbsp;</label><br />
                            {/* {this.renderAppLogo('website')} */}
                            {this.renderImage('websitelogo')}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Website icon&nbsp;</label><br />
                            {/* {this.renderAppIcon('website')} */}
                            {this.renderImage('websiteicon')}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Mobile logo&nbsp;</label><br />
                            {this.renderImage('mobilelogo')}
                            {/* {this.renderAppLogo('mobile')} */}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Mobile icon&nbsp;</label><br />
                            {this.renderImage('mobileicon')}
                            {/* {this.renderAppIcon('mobile')} */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Input
                            type="textarea"
                            label="Description"
                            value={this.state.metaDescription}
                            onChange={(value)=>this.setState({metaDescription:value})}
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            type="textarea"
                            label="Keywords"
                            value={this.state.metaKeywords}
                            onChange={(value)=>this.setState({metaKeywords:value})}
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            type="text"
                            label="Google analytics code"
                            value={this.state.gaCode}
                            onChange={(value)=>this.setState({gaCode:value})}
                        />
                    </div>
                    <div className="col-md-3">
                        <Input
                            type="textarea"
                            label="Custom CSS"
                            value={this.state.customCSS}
                            onChange={(value)=>this.setState({customCSS:value})}
                        />
                    </div>
                </div>

        </PageSection>
    }


    renderTransactionSettings() {
        return <PageSection title="Order Mapping">
            <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Transaction type &nbsp;</label>
                            <span className="fa fa-info-circle" data-tip data-for='transactionTypeToolTip' ></span>
                            <ReactTooltip id='transactionTypeToolTip' type='dark' place="top" effect="solid">
                                <span>Online orders / sales will be created as per selected transaction type.</span>
                            </ReactTooltip>
                            <select className="form-control"
                                onChange={(evt) => this.setState({ transactionTypeID: evt.target.value })}
                                value={this.state.transactionTypeID}>
                                <option value="">Select Transaction Type</option>
                                {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                            </select>
                            {/* <label><i></i></label> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Input type="switch" 
                            label= "Allow online orders from multiple stores"
                            value={this.state.showMultipleStores}
                            onChange={(value) => { this.setState({ showMultipleStores: value }) }}
                        />
                    </div>
                </div>
                {!this.state.showMultipleStores && <React.Fragment>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Store &nbsp;</label><span className="fa fa-info-circle" data-tip data-for='storeToolTip' ></span>
                                <ReactTooltip id='storeToolTip' type='dark' place="top" effect="solid">
                                    <span>Please select store which will represent your online store</span>
                                </ReactTooltip>
                                <select className="form-control"
                                    value={this.state.storeID}
                                    onChange={this.onStoreChange}>
                                    <option value="">Select Store</option>
                                    {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                        <option key={store.key} value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Cash register &nbsp;</label><span className="fa fa-info-circle" data-tip data-for='cashRegisterToolTip' ></span>
                                <ReactTooltip id='cashRegisterToolTip' type='dark' place="top" effect="solid">
                                    <span>Please select cashregister under which online orders/sales will be recorded. <br />We suggest selecting a different cash register from your physical store</span>
                                </ReactTooltip>
                                <select className="form-control"
                                    onChange={(evt) => this.setState({ cashRegisterID: evt.target.value })}
                                    value={this.state.cashRegisterID}>
                                    <option value="">Select Cash Register</option>
                                    {this.state.cashRegisters.map((cashRegister) =>
                                        <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                                    )}
                                </select>
                                {/* <label><i></i></label> */}
                            </div>
                        </div>
                    </div>
                </React.Fragment>}
                <div className="row">
                    <div className="col-md-3">
                        <Input type="switch" 
                        label="Allow customers near to the store to place online orders (capture customer geo location)" 
                        value={this.state.geoFencing}
                        onChange={(value)=>{
                            this.loadSelectedStoreDetails(this.state.storeID, value, this.state.stores);
                            this.setState({geoFencing:value})
                        }}/>
                    </div>
                </div>
                {this.state.geoFencing && !this.state.showMultipleStores && <React.Fragment>
                <div className="row">
                    <div className="col-md-3">
                        <Input type="number" 
                        label="Delivery radius (KMs)" 
                        value={this.state.deliveryRadius}
                        error={this.state.deliveryRadiusError}
                        onChange={(value)=>this.setState({deliveryRadius:value, deliveryRadiusError:""})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Input type="number" 
                        label="Store - Lat" 
                        value={this.state.storeLat}
                        error={this.state.storeLatError}
                        onChange={(value)=>this.setState({storeLat:value,storeLatError:""})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <Input type="number" 
                        label="Store - Lng" 
                        value={this.state.storeLng}
                        error={this.state.storeLngError}
                        onChange={(value)=>this.setState({storeLng:value, storeLngError:""})}/>
                    </div>
                </div>
                </React.Fragment>}
                
                {this.state.showMultipleStores && <React.Fragment>
                        <div className="row">
                            <div className="col-md-7">
                                <label>Please select stores that have to be enabled online.</label>
                                <br/>
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Store</th>
                                            <th style={{width: "100px"}}>Enable online ordering</th>
                                            <th style={{width: "200px"}}>Cash register</th>
                                            {this.state.geoFencing && <React.Fragment>
                                                <th>Lat</th>
                                                <th>Lng</th>
                                                <th>Delivery radius (KM)</th>
                                            </React.Fragment>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.stores.map((store,index)=> <tr key={"key_" + index}>
                                            <td>
                                                {store.StoreCode}-{store.StoreName}
                                            </td>
                                            <td>
                                                <Switch
                                                    checked={store.ShowOnline}
                                                    onChange={() => { let stores= this.state.stores; stores[index].ShowOnline = !stores[index].ShowOnline; this.setState({stores:stores}) }}
                                                />
                                            </td>
                                            <td>
                                                <select className="form-control"
                                                    onChange={(evt) =>{let stores= this.state.stores; stores[index].OmniCashRegisterID = evt.target.value; this.setState({stores:stores})}}
                                                    value={store.OmniCashRegisterID}>
                                                    <option value="">Select Cash Register</option>
                                                    {store.CashRegisters && store.CashRegisters.map((cashRegister) =>
                                                        <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                                                    )}
                                                </select>
                                            </td>
                                            {this.state.geoFencing && <React.Fragment>
                                                <th><Input type="number" value={(store.hasOwnProperty('Location') && store.Location.lat) ? store.Location.lat: ""}
                                                    onChange={(value) => { let stores= this.state.stores; stores[index].Location.lat = value; this.setState({stores:stores}) }}
                                                /></th>
                                                <th><Input type="number" 
                                                onChange={(value) => { let stores= this.state.stores; stores[index].Location.lon = value; this.setState({stores:stores}) }}
                                                value={(store.hasOwnProperty('Location') && store.Location.lon) ? store.Location.lon: ""}/> </th>
                                                <th><Input type="number" 
                                                onChange={(value) => { let stores= this.state.stores; stores[index].DeliveryRadius = value; this.setState({stores:stores}) }}
                                                value={store.DeliveryRadius ? store.DeliveryRadius: ""}/></th>
                                            </React.Fragment>}
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>}
                {/* <div className="row">
                    <div className="col-md-3">
                        <Input
                            label="Customer Login"
                            type="select"
                            value={this.state.customerLoginType}
                            onChange={(value)=>this.setState({customerLoginType:value})}
                        >
                            {this.state.loginTypes.map(l=><option value={l.value}>{l.text}</option>)}
                        </Input>
                    </div>
                </div> */}
               <div className="row">
                    <div className="col-md-3">
                        <Input
                            type="switch"
                            label="Send OTP for login"
                            value={this.state.enableMobileOTPLogin}
                            onChange={(value) => { this.setState({ enableMobileOTPLogin: value }) }}
                        />
                    </div>
                </div>
        </PageSection>

    }

    renderSiteContactDetails() {
        return <PageSection title="Contact details">
            <div>
                <div className="col-md-3">
                        <div className="form-group" >
                            <label>Email id</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-envelope-o"></i></span>
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ emailID: evt.target.value })}
                                    placeholder="youremailid@abc.com"
                                    value={this.state.emailID}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Phone no</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-phone"></i></span>
                                <input type="number" className="form-control"
                                    onChange={(evt) => this.setState({ contactNo: evt.target.value })}
                                    placeholder="Phone no"
                                    value={this.state.contactNo}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Whatsapp no</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-whatsapp"></i></span>
                                <input type="number" className="form-control"
                                    onChange={(evt) => this.setState({ whatsAppNo: evt.target.value })}
                                    placeholder="WhatsApp no"
                                    value={this.state.whatsAppNo}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Address</label>
                            <div className="input-group">
                                <span className="input-group-addon" style={{verticalAlign:"top"}}><i className="fa fa-address-card-o"></i></span>
                                <textarea type="text" className="form-control" style={{height: "116px"}}
                                    onChange={(evt) => this.setState({ officeAddress: evt.target.value })}
                                    placeholder="Enter address"
                                    value={this.state.officeAddress}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Show in menu&nbsp;</label>
                            <div className="input-group">
                                <Switch
                                    checked={this.state.showContactDetailsInMenu}
                                    onChange={() => { this.setState({ showContactDetailsInMenu: !this.state.showContactDetailsInMenu }) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Show in footer&nbsp;</label>
                            <div className="input-group">
                                <Switch
                                    checked={this.state.showContactDetailsInFooter}
                                    onChange={() => { this.setState({ showContactDetailsInFooter: !this.state.showContactDetailsInFooter }) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </PageSection>
    }

    renderSocialMediaSettings() {
        return <PageSection title="Social media accounts">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Facebook</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-facebook"></i></span>
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ facebookURL: evt.target.value })}
                                    placeholder="https://facebook.com/page"
                                    value={this.state.facebookURL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Instagram</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-instagram"></i></span>
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ instagramURL: evt.target.value })}
                                    placeholder="https://instagram.com/username"
                                    value={this.state.instagramURL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Twitter</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-twitter"></i></span>
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ twitterURL: evt.target.value })}
                                    placeholder="https://twitter.com/username"
                                    value={this.state.twitterURL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>YouTube</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-youtube"></i></span>
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ youtubeURL: evt.target.value })}
                                    placeholder="https://youtube.com/channel/channel_id"
                                    value={this.state.youtubeURL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group" >
                            <label>Pinterest</label>
                            <div className="input-group">
                                <span className="input-group-addon"><i className="fa fa-pinterest"></i></span>
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ pinterestURL: evt.target.value })}
                                    placeholder="https://pinterest.com/page"
                                    value={this.state.pinterestURL}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Show in menu&nbsp;</label>
                            <div className="input-group">
                                <Switch
                                    checked={this.state.showSocialMediaDetailsInMenu}
                                    onChange={() => { this.setState({ showSocialMediaDetailsInMenu: !this.state.showSocialMediaDetailsInMenu }) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Show in footer&nbsp;</label>
                            <div className="input-group">
                                <Switch
                                    checked={this.state.showSocialMediaDetailsInFooter}
                                    onChange={() => { this.setState({ showSocialMediaDetailsInFooter: !this.state.showSocialMediaDetailsInFooter }) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
        </PageSection>
    }

    render() {
        return <React.Fragment>

            {this.renderColorPickerControl()}

            <Page 
                title="Online Store - Profile"
                showSaveButton={true} 
                showCancelButton={true}
                processing={this.state.saving}
                onClick={(action)=>{
                    if(action==="cancel"){
                        this.onCancel();
                    }else if(action==="save"){
                        this.onSave();
                    }
                }}
            > 
                {this.renderGeneralSettings()}
                {this.renderSiteContactDetails()}
                {this.renderSocialMediaSettings()}
                {this.renderTransactionSettings()}
            </Page>
            
        </React.Fragment>
    }

}

export default OmniProfile