import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import './ImageTemplate.css'

class ImageTemplate extends Component {

    constructor() {
        super()
    }

    render() {
        return <React.Fragment>
            
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                    {!this.props.image && <React.Fragment>
                            <label >Image</label>
                            <Dropzone onDrop={(files) => this.props.onImageDrop(files)}>
                                <div>Try dropping some files here, or click to select files to upload.</div>
                            </Dropzone>
                        </React.Fragment>}
                        {this.props.image && <div id="imageTemplate" className="image-container">
                            <img className="message-img" src={this.props.image.Preview} alt="Image" />
                            {this.props.placeTextOnImage && <div className="centered">{this.props.imageTitle}</div>}
                            {this.props.placeTextOnImage && <div className="bottom-left">{this.props.imageSubTitle}</div>}
                        </div>}
                    </div>
                </div>
            </div>
            {this.props.image && <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                        <button type="button" className="footer-button-secondary" onClick={() => this.props.onImageRemove()}>Delete Image</button>
                    </div>
                </div>
            </div>}
            {/* <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                        <label >Title</label>
                        <input type="text" className="form-control"
                            onChange={(evt) => this.props.onTitleTextChange(evt)}
                            value={this.props.imageTitle} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                        <label >Sub Title</label>
                        <input type="text" className="form-control"
                            onChange={(evt) => this.props.onSubTitleTextChange(evt)}
                            value={this.props.imageSubTitle} />
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="form-group">
                        <label >Sequence No</label>
                        <input type="number" className="form-control"
                            onChange={(evt) => this.props.onSequenceNoChange(evt)}
                            value={this.props.sequenceNo} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

export default ImageTemplate