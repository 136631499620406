import React, { Component, createRef } from 'react';
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';
import { Editor } from '@tinymce/tinymce-react';

class OmniPageEdit extends Component {

    constructor()
    {
        super()
        this.state = {
            key: '',
            title:'',
            content:'',
            showInMenu:false,
            showInFooter:false,
            sequence:0,
            saving:false,
            titleError:"",
        }
        this.onSave = this.onSave.bind(this);
        this.editorRef = createRef();
    }

    async componentDidMount()
    {
        // console.log('this.props.selectedRecord',this.props.selectedRecord)
        if(this.props.selectedRecord)
        {
            let page = this.props.selectedRecord;
            this.setState({ 
                key: page.key ,
                title: page.hasOwnProperty('Title') ? page.Title : '',
                content:  page.hasOwnProperty('Content') ? page.Content : '',
                showInMenu: page.hasOwnProperty('ShowInMenu') ?  page.ShowInMenu : false,
                showInFooter: page.hasOwnProperty('ShowInFooter') ?  page.ShowInFooter : false,
                sequence: page.hasOwnProperty('Sequence') ?  page.Sequence : 0,
            })
        }
    }
 
    async onSave()
    {
        this.setState({saving:true});
        let content = this.editorRef.current.getContent();
        let page = {
            Title: this.state.title,
            Content: content,
            ShowInMenu: this.state.showInMenu,
            ShowInFooter: this.state.showInFooter,
            Sequence:this.state.sequence
        }
        this.props.saveOmniPage(page, this.state.key).then(() => {
            this.props.getToastr("Page saved successfully");
            this.props.onSave()
        })
    }
 
    render(){
        return <Form
            title={(this.state.key !== "") ? "Edit Page" : "New Page"}
            onClick={(action)=>{
                if(action==="save"){
                    this.onSave();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
            <Input
                type="text"
                label="Title"
                value={this.state.title}
                onChange={(value)=> this.setState({title:value})}
                error={this.state.titleError}
            />
            {/* <Input
                type="textarea"
                label="Content"
                value={this.state.content}
                onChange={(value)=>this.setState({content: value})}
            /> */}
            <Editor
                apiKey='5t1d5flmdrmruy3o4ogwyazepn6n8qezlohtn06e87unjkiu'
                onInit={(evt, editor) => this.editorRef.current = editor}
                initialValue={this.state.content}
                init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                    'bold italic forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help' +    ' | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                    ,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
            <Input
                type="number"
                label="Sequence"
                value={this.state.sequence}
                onChange={(value)=>this.setState({sequence:value})}
            />
            <Input
                type="switch"
                label="Show in menu"
                value={this.state.showInMenu}
                onChange={(value)=>this.setState({showInMenu:value})}
            />
            <Input
                type="switch"
                label="Show in footer"
                value={this.state.showInFooter}
                onChange={(value)=>this.setState({showInFooter:value})}
            />
        </Form>
    }

}
export default OmniPageEdit

