import React, { Component } from 'react';
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Spinner from '../Common/Spinner'
import Avatar from '../Common/Avatar'
import ListSearch from '../Common/ListSearch'

import ActionButton from '../Common/ActionButton'
import PopupMenu from '../Common/PopupMenu';
import Confirmation from '../Common/Confirmation';
import RecordNotFound from '../Common/RecordNotFound';
import TabStrip from '../Common/TabStrip'

class ViewImageTemplates extends Component {

    constructor() {
        super()
        this.state = {
            searchText: '',
            searchPlaceHolder: 'Search message templates',
            messageTemplates: [],
            loading: true,
            showPopupMenu: false,
            selectedTemplate: null,
            showDelete: false,
            tabButtons:[],
            selectedTabIndex: 0,
            selectedTab: '',
            sysTemplates: [],
            popButtons:[]
        }
        this.onRowClick = this.onRowClick.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this)
        this.onTabSelected= this.onTabSelected.bind(this)
    }

    async componentDidMount() {

        let sysTemplates =  await this.props.getMessageTemplates();

        let tabButtons = await this.props.getMessageTemplateTypes();
        // console.log("tabButtons", tabButtons);
        let selectedTab = tabButtons[this.state.selectedTabIndex].text;
        // console.log(selectedTab)

        let messageTemplates = await this.props.getSMSTemplates(this.props.user.user.RegistrationID, this.props.user.store.key);

        this.setState({
            messageTemplates: messageTemplates,
            loading: false,
            tabButtons: tabButtons,
            sysTemplates: sysTemplates,
            selectedTab : selectedTab
        });
    }

    onRowClick(messageTemplate) {
        let popButtons = [
            // { value: "delete", text: "Delete", module: 'messageTemplate' },
            // { value: "edit", text: "Edit", module: 'messageTemplate' },
            { value: "share", text: "Share", module: 'messageTemplate' },
            { value: "schedule", text: "Schedule", module: 'messageTemplate' }
        ];
        if(this.state.selectedTabIndex === 0)
        {
            popButtons.push({ value: "delete", text: "Delete", module: 'messageTemplate' },
                { value: "edit", text: "Edit", module: 'messageTemplate' })
        }
        this.setState({popButtons: popButtons});
        if (this.props.location.state && this.props.location.state.source === "crm") {
            let campaign = null;
            if (this.props.location.state.campaign) {
                campaign = this.props.location.state.campaign
            }
            else {
                let date = new Date();
                date.setDate(date.getDate() + 1);

                campaign = {
                    key: '',
                    AllCustomersSelected: true,
                    AllStoresSelected: true,
                    Type: 'One Time',
                    StartDate: new Date(date).toISOString().split('T')[0],
                    Slot: 8,
                }
            }

            campaign.ImageTemplate = messageTemplate.Template;
            campaign.Name = messageTemplate.Name;
            campaign.SMSTemplate = messageTemplate.SMSTemplate.replace('<StoreName>',this.props.user.store.StoreName).replace('<StoreContactNumber>',this.props.user.store.PhoneNo);

            if (campaign.key !== "") {
                this.props.history.push({ pathname: '/home/campaign/edit/', state: { campaign: campaign } });
            }
            else {
                this.props.history.push({ pathname: '/home/campaign/newcampaign', state: { campaign: campaign } })
            }
        }
        else {
            this.setState({ selectedTemplate: messageTemplate, showPopupMenu: true })
        }
    }

    onDeleteConfirmation(value) {
        if (value) {
            let object = this.state.selectedTemplate;
            this.setState({ loading: true, showDelete: false })
            this.props.deleteSMSTemplate(object.key).then(() => {
                setTimeout(function () {
                    this.props.getToastr("Message Template deleted successfully");
                    this.loadData();
                }.bind(this), 2000)
            })
        }
        else {
            this.setState({ showDelete: false });
        }
    }

    async onShowPopupMenuClick(value) {
        let object = this.state.selectedTemplate;
        if (value === "share") {
            let message = ""
            let image = ""
            let url = ""
            let subject = ""
            message = message + object.SMSTemplate.replace('<StoreName>',this.props.user.store.StoreName).replace('<StoreContactNumber>',this.props.user.store.PhoneNo);
            if (object.Template) {
                image = object.Template.Preview
            }
            this.setState({ showPopupMenu: false, selectedTemplate: null })
            window.plugins.socialsharing.share(message, subject, image, url);
        }
        else if (value === "edit") {
            this.setState({ showPopupMenu: false, selectedTemplate: null });
            if (this.props.location.state && this.props.location.state.source === "crm") {
                let campaign = this.props.location.state.campaign;
                if (campaign.key !== "") {
                    this.props.history.push({ pathname: '/home/campaign/edit/', state: { campaign: campaign, source: 'crm', messageTemplate: object } });
                }
                else {
                    this.props.history.push({ pathname: '/home/campaign/newcampaign', state: { campaign: campaign, source: 'crm', messageTemplate: object } })
                }
            }
            else {
                this.props.history.push({ pathname: '/home/messagetemplate/edit/', state: { messageTemplate: object } })
            }
        }
        else if (value === "delete") {
            this.setState({ showDelete: true, showPopupMenu: false });
        }
        else if (value === "schedule") {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            let campaign = {
                key: '',
                AllCustomersSelected: true,
                AllStoresSelected: true,
                Type: 'One Time',
                StartDate: new Date(date).toISOString().split('T')[0],
                Slot: 8,
            }
            campaign.ImageTemplate = object.Template;
            campaign.Name = object.Name;
            campaign.SMSTemplate = object.SMSTemplate.replace('<StoreName>',this.props.user.store.StoreName).replace('<StoreContactNumber>',this.props.user.store.PhoneNo);

            this.props.history.push({ pathname: '/home/campaign/edit/', state: { campaign: campaign, source: 'crm', messageTemplate: object } });
        }
        else {
            this.setState({ showPopupMenu: false, selectedTemplate: null })
        }
    }

    onActionButtonClick(action) {

        if (this.props.location.state && this.props.location.state.source === "crm") {

            let campaign = null;
            if (this.props.location.state.campaign) {
                campaign = this.props.location.state.campaign
            }
            else {
                let date = new Date();
                date.setDate(date.getDate() + 1);

                campaign = {
                    key: '',
                    AllCustomersSelected: true,
                    AllStoresSelected: true,
                    Type: 'One Time',
                    StartDate: new Date(date).toISOString().split('T')[0],
                    Slot: 8,
                }
            }

            if (campaign.key !== "") {
                this.props.history.push({ pathname: '/home/messagetemplate/edit/', state: { campaign: campaign, source: 'crm', messageTemplate: null } });
            }
            else {
                this.props.history.push({ pathname: '/home/messagetemplate/edit/', state: { campaign: campaign, source: 'crm', messageTemplate: null } });
            }
        }
        else {
            this.props.history.push({ pathname: '/home/messagetemplate/edit/', state: { messageTemplate: null } });
        }

    }

    onTabSelected(selectedTabIndex){
        let selectedTab = this.state.tabButtons[selectedTabIndex].text;
        // console.log(selectedTab)
        this.setState({ selectedTabIndex: selectedTabIndex, selectedTab: selectedTab })
    }

    render() {
        return <React.Fragment>
            {this.props.isMobileApp() && <HeaderNew {...this.props}
                back={true}
                title="Message Templates"
                onBackClick={() => { this.props.history.push({ pathname: '/home' }) }}
            />}
            <div className="header-new-background"></div>

            <div className="content-wrapper" >
                <div className="container">
                    <ListSearch
                        value={this.state.searchText}
                        onChange={(searchText) => { this.setState({ searchText: searchText }) }}
                        searchPlaceHolder={this.state.searchPlaceHolder} />

                    {this.state.loading && <Spinner />}
                    <div className="list">

                        <TabStrip tabButtons={this.state.tabButtons}
                            selectedTabIndex={this.state.selectedTabIndex}
                            onTabSelected={(index) => this.onTabSelected(index)}
                        />
                        {this.state.selectedTabIndex === 0 && <React.Fragment>
                            {this.state.messageTemplates.filter((f) => f.Name.toLowerCase().includes(this.state.searchText.toLowerCase()) && !f.IsDeleted).map((messageTemplate) => <div className="list-row seperator" onClick={() => this.onRowClick(messageTemplate)} >
                                <Avatar text={messageTemplate.Name} image={messageTemplate.Template ? messageTemplate.Template.Preview : ""} />
                                <div>
                                    <div className="row-title">{messageTemplate.Name}</div>
                                    <div className="row-sub-text">{messageTemplate.SMSTemplate}</div>
                                </div>
                            </div>)}

                            {(this.state.messageTemplates.filter((f) => !f.IsDeleted).length === 0) && <div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <RecordNotFound title="Message Template not set" text="Please click + button to create new message template" />
                            </div>}
                        </React.Fragment>}

                        {this.state.selectedTabIndex > 0 && <React.Fragment>

                            {this.state.sysTemplates.filter((f) => f.Type.toLowerCase() === (this.state.selectedTab.toLowerCase())).map((sysTemplate) => 
                            <div className="list-row seperator" onClick={() => this.onRowClick(sysTemplate)} >
                                <Avatar text={sysTemplate.Name} image={sysTemplate.Template ? sysTemplate.Template.Preview : ""} />
                                <div>
                                    <div className="row-title">{sysTemplate.Name}</div>
                                    <div className="row-sub-text">{sysTemplate.SMSTemplate.replace('<StoreName>',this.props.user.store.StoreName).replace('<StoreContactNumber>',this.props.user.store.PhoneNo)}</div>
                                </div>
                            </div>)}
                        </React.Fragment>}
                    </div>
                </div>
            </div>

            <ActionButton
                onClick={(action) => this.onActionButtonClick(action)}
                fabButtons={this.state.fabButtons} />

            <PopupMenu
                show={this.state.showPopupMenu}
                buttons={this.state.buttons}
                buttons={this.state.popButtons}
                onClick={(action) => this.onShowPopupMenuClick(action)} />

            <Confirmation show={this.state.showDelete} onClick={(value) => this.onDeleteConfirmation(value)} />


        </React.Fragment>
    }

}

export default ViewImageTemplates

