import React, { Component } from 'react';
import Icon from './Controls/Icon';
import './common.css';
/* global google */

class GooglePlacesSearchInput extends Component {

    constructor()
    {
        super();
        this.state={
            options:[]
        }
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.initializeAutoFill = this.initializeAutoFill.bind(this);
    }

    componentDidMount(){
        this.initializeAutoFill()
    }

    initializeAutoFill(){
      this.autocompleteInput.current.value="";
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
        {componentRestrictions: { country: "in" },
        fields: ["formatted_address", "geometry", "name"],});
        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        if(place.hasOwnProperty('formatted_address') && place.formatted_address !==""){
          let lat = parseFloat(place.geometry.location.lat());
          let lng =  parseFloat(place.geometry.location.lng());
          this.props.onChange({Address:place.formatted_address, Lat: lat, Lng: lng});
      }
    }
  
    componentWillReceiveProps(nextProps) {
      // console.log('GoogleMaps',nextProps);
      // this.showMap(nextProps.value ? nextProps.value : {Address: "", Lat:"21.4446067", Lng:"72.0859428"})
      if(nextProps.value && nextProps.value.Address && nextProps.value.Address!==""){
        this.autocompleteInput.current.value=nextProps.value.Address;
      }
    }

    render(){
      let displaySearch = (this.props.value && this.props.value.Address ==="") ? "" : "none"
      return <React.Fragment>
        
          {/* {(this.props.value && this.props.value.Address ==="") &&  */}
          <div className="google-place-input-search" 
          // style={{display:displaySearch}}
          >
                <span><Icon type="search"/></span>
                <input 
                ref={this.autocompleteInput}  
                id="autocomplete" 
                placeholder={this.props.placeholder}
                type="text" className="form-control"></input>
                <span onClick={()=>{this.initializeAutoFill();this.props.onChange({Address:"", Lat: "", Lng: ""})}}><Icon type="remove" /></span>
            </div>
            {/* } */}

            {/* {(this.props.value && this.props.value.Address !=="") && <div className="google-place-input-search">  
                <span><Icon type="search"/></span>
                <input type="text" value={this.props.value ?  this.props.value.Address: ""}/>
                <span onClick={()=>{this.initializeAutoFill();this.props.onChange({Address:"", Lat: "", Lng: ""})}}><Icon type="remove" /></span>
            </div>} */}

                

      </React.Fragment>
    }
}

export default GooglePlacesSearchInput;