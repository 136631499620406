import React, { Component } from 'react'
import CustomerSearch from './CustomerSearch'
import CustomerAddress from './CustomerAddress'
import Customer from '../CRM/Customer'
import { debounce } from 'lodash';
import Avatar from '../Common/Avatar';
import ListSearch from '../Common/ListSearch';
import ActionButton from '../Common/ActionButton';
import Footer from '../Common/Footer';
import CustomerViewNew from '../CRM/CustomerViewNew';
import Spinner from '../Common/Spinner';
import Page from '../2/Controls/Page';

class MobileCustomer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedCustomer:null,
            selectedCustomerAddress:null,
            placeOfSupply:'',
            customerError:'',
            saving:false,
            edit:false,
            new:false,
            customersTypeAheadOptions:[],
            customerSearchText:"",
            searchText:'',
            loading:false
        }
        this.refAddEditCustomer = React.createRef();
        this.onAddCustomer = this.onAddCustomer.bind(this)
        this.onCustomerEdit = this.onCustomerEdit.bind(this)
        this.onShowCustomerDashBoard = this.onShowCustomerDashBoard.bind(this)
        this.onOkClicked= this.onOkClicked.bind(this)
        this.onCustomerTypeAheadSearch = this.onCustomerTypeAheadSearch.bind(this)
        this.handleCustomerSearchThrottle = debounce(this.onCustomerTypeAheadSearch, 100)
        this.onCustomerSelected = this.onCustomerSelected.bind(this)
        this.renderCustomerSearch = this.renderCustomerSearch.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
    }

    componentDidMount()
    {
        // console.log('Mobile customer', this.props.customer)
        this.setState({selectedCustomer:this.props.customer,
            selectedCustomerAddress:this.props.customerAddress, 
            placeOfSupply:this.props.placeOfSupply })

        if(!this.props.customer)
        {
            //load initial customer list 
            this.onCustomerTypeAheadSearch("") 
        }

    }

    onCustomerSelected(customer)
    {
        let address = null
        let placeOfSupply = ""
        if(customer && customer.Addresses.length > 0)
        {
            address =customer.Addresses[0]
            placeOfSupply = customer.Addresses[0].State
        }
        this.props.onCustomerChange(customer, 
            address,
            placeOfSupply)
    }

    onCustomerTypeAheadSearch(query){
        this.setState({query:query, loading:true});
        this.props.searchCustomer(this.props.user.user.RegistrationID,this.props.user.store.key, query)
            .then((result) => {
                // console.log('result.searchResult ', result.searchResult )
                this.setState({ customersTypeAheadOptions: result.searchResult, loading:false})
            })
    }

    async onOkClicked()
    {
        this.setState({saving:true})
        if(this.state.selectedCustomer && !this.state.edit)
        {
            this.props.onCustomerChange(this.state.selectedCustomer, 
                this.state.selectedCustomerAddress,
                this.state.placeOfSupply)
        }
        else
        {
            let status = await this.onsaveCustomer()
        }
        this.setState({saving:false})
    }

    onAddCustomer(){

    }

    onCustomerEdit(){

    }

    onShowCustomerDashBoard(){
        
    }

    renderCustomerSearch()
    {
        return <React.Fragment>
            <ListSearch 
                value = {this.state.searchText}
                onChange={(searchText) => {
                    this.setState({searchText:searchText})
                    if(searchText.length<4)
                    {
                        this.setState({customersTypeAheadOptions:[]})
                    }
                    else
                    {
                        this.handleCustomerSearchThrottle(searchText)
                    }   
                }}
                searchPlaceHolder="Search by name, e-mail or mobile number"/>

             {this.state.loading && <Spinner/>}

             {!this.state.loading &&  <div className="list">
                {this.state.customersTypeAheadOptions.map((customer) => <div className="list-row seperator" 
                onClick={() => {
                        this.onCustomerSelected(customer)
                        this.setState({customersTypeAheadOptions:[],customerSearchText:""})
                }}>         
                <Avatar text={customer.FirstName}/>
                 <div>
                    <div className="row-title">{customer.FirstName + ' ' + customer.LastName}</div>
                    <div className="row-sub-text">{customer.PhoneNo}</div>
                    <div className="row-sub-text">{customer.EmailID}</div>
                    {(customer.OutStandingAmount > 0) && <span className="outstanding pull-left">O/S: {customer.OutStandingAmount}</span> }
                    {(customer.WalletBalance > 0 )&& <span className="wallet pull-left">Wallet: {customer.WalletBalance}</span> }
                    {(customer.Membership && customer.Membership.hasOwnProperty('MembershipID') ) && customer.Membership.MembershipID != "" &&
                    <span className="membership pull-left">
                    {customer.Membership.Name}</span>}
                    {(customer.hasOwnProperty('LoyaltyType') && customer.LoyaltyType.trim()!=="") && <span className="loyalty pull-left">
                    {customer.LoyaltyType}</span>}
                 </div>
             </div>)}
        </div>}

        <ActionButton 
            onClick={(action)=>this.onActionButtonClick(action)}
            fabButtons={[]}/> 

    </React.Fragment>
    }

    onActionButtonClick(){
        this.setState({new:true})
    }

    render(){
        return <React.Fragment>

            {(!this.state.selectedCustomer && !this.state.new  && !this.state.edit) && <React.Fragment>
                {/* Customer search */}
                <div className="header-new-background"/>
                    <Page 
                        title="Customer Search" 
                        showCancelButton={true}
                        onClick={(action)=>{
                            // console.log('action', action)
                            if(action==="cancel"){
                                this.props.onCancel();
                            }
                        }}
                        >
                            {this.renderCustomerSearch()}
                    </Page>

            </React.Fragment>}

            {(this.state.new || this.state.edit) && <React.Fragment>

                    <Customer 
                        {...this.props}
                        showAsComponent={true}
                        setOnSaveClick={click => this.onsaveCustomer = click}
                        calledFromInvoice={true}
                        mobile={true}
                        selectedCustomer={this.state.selectedCustomer}
                        newCustomer={null}
                        onSave={(customer) => {this.onCustomerSelected(customer)}}
                        onClose={()=>{this.setState({new:false, edit:false})}}
                    />
                    
                    {/* <Footer 
                        onClick={(action)=>{
                            if(action==="save")
                            {
                                this.onOkClicked()
                            }
                        }}
                /> */}
            </React.Fragment>}

            {(this.state.selectedCustomer  && !this.state.edit) &&  <React.Fragment>

                <CustomerViewNew  {...this.props} showAsComponent={true} customerID={this.state.selectedCustomer.key}/>
                
                <Footer
                    onClick={(action)=>{
                        if(action==="remove")
                        {
                            this.setState({selectedCustomer:null})
                            this.onCustomerSelected(null)
                        }
                        else if(action==="edit")
                        {
                            this.setState({edit:true})
                        }
                    }} 
                    buttons={[{action:"remove", text:"Remove", type:"secondary"},
                    {action:"edit", text:"Edit", type:"primary"} ]}/>

            </React.Fragment>}
            
        </React.Fragment>
    }
}

export default MobileCustomer