import React, { Component } from 'react';
import Form from '../../2/Controls/Form';
import Input from '../../2/Controls/Input';

class SMS extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            image: null,
            imageLoading: false,
            status: '',
            name: '',
            nameError: '',
            messageType:'sms',
            smsType: 'Promotional',
            smsTypeError: '',
            storeID: '',
            storeCode: '',
            storeName: '',
            action: '',
            moduleError: '',
            moduleID: '',
            module: '',
            transactionTypeError: '',
            transactionTypeID: '',
            transactionType: '',
            smsTemplate: '',
            smsTemplateError: '',
            surveyID: '',
            survey: '',
            surveyError: '',
            smsTypes: [{ "Name": "Promotional" }
                // , { "Name": "Transactional" }
            ],
            placeholders: [
                { text: "Transaction No", placeholder: "<transaction no>", moduleID: "sales" },
                { text: "Transaction Date", placeholder: "<transaction date>", moduleID: "sales" },
                { text: "Customer First Name", placeholder: "<first name>", moduleID: "sales" },
                { text: "Customer Last Name", placeholder: "<last name>", moduleID: "sales" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "sales" },
                { text: "Store Phone No", placeholder: "<store no>", moduleID: "sales" },
                { text: "Store Email ID", placeholder: "<store emailid>", moduleID: "sales" },
                { text: "Transaction Amount", placeholder: "<amount>", moduleID: "sales" },
                { text: "Paid Amount", placeholder: "<paid>", moduleID: "sales" },
                { text: "Balance Amount", placeholder: "<balance>", moduleID: "sales" },
                { text: "Transaction Link", placeholder: "<transaction link>", moduleID: "sales" },
                { text: "Customer Name", placeholder: "<customer name>", moduleID: "productalert" },
                { text: "Product Name", placeholder: "<product name>", moduleID: "productalert" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "productalert" },

                // { text: "Date", placeholder: "<date>", moduleID: "productalert" },
                { text: "Membership Name", placeholder: "<membership name>", moduleID: "membershipexpiryalert" },
                { text: "Customer Name", placeholder: "<customer>", moduleID: "membershipexpiryalert" },
                { text: "Membership Expiry Date", placeholder: "<membership expiry date>", moduleID: "membershipexpiryalert" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "membershipexpiryalert" },

                { text: "Package Name", placeholder: "<package name>", moduleID: "packageexpiryalert" },
                { text: "Customer Name", placeholder: "<customer>", moduleID: "packageexpiryalert" },
                { text: "Package Expiry Date", placeholder: "<package expiry date>", moduleID: "packageexpiryalert" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "packageexpiryalert" },

                { text: "Offer Name", placeholder: "<offer>", moduleID: "campaign" },
                { text: "Coupon Name", placeholder: "<coupon>", moduleID: "campaign" },
                { text: "Coupon Name", placeholder: "<coupon>", moduleID: "anniversary" },
                { text: "Coupon Code", placeholder: "<coupon_code>", moduleID: "anniversary" },
                { text: "Coupon Code", placeholder: "<coupon_code>", moduleID: "birthday" },
                { text: "Coupon Name", placeholder: "<coupon>", moduleID: "birthday" },
                { text: "OTP", placeholder: "<OTP>", moduleID: "walletotp" },
                // { text: "Coupon Name", placeholder: "<store name>", moduleID: "walletotp" },
                { text: "OTP", placeholder: "<OTP>", moduleID: "loyaltyotp" },
                // { text: "Coupon Name", placeholder: "<store name>", moduleID: "loyaltyotp" },
                { text: "OTP", placeholder: "<OTP>", moduleID: "omniotp" },
                { text: "OTP", placeholder: "<OTP>", moduleID: "omniloginotp" },
                
                { text: "Customer Name", placeholder: "<customer name>", moduleID: "appointment" },
                { text: "Appointment Date", placeholder: "<appointment date>", moduleID: "appointment" },
                { text: "Appointment Time", placeholder: "<appointment time>", moduleID: "appointment" },
                { text: "Appointment Services", placeholder: "<appointment services>", moduleID: "appointment" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "appointment" },
                { text: "Store Phone No", placeholder: "<store no>", moduleID: "appointment" },
                { text: "Store Email ID", placeholder: "<store emailid>", moduleID: "appointment" },
                { text: "Store Address", placeholder: "<store address>", moduleID: "appointment" },

                { text: "Customer Name", placeholder: "<customer name>", moduleID: "walletrecharge" },
                { text: "Transaction No", placeholder: "<transaction no>", moduleID: "walletrecharge" },
                { text: "Transaction Date", placeholder: "<transaction date>", moduleID: "walletrecharge" },
                { text: "Recharge Amount", placeholder: "<recharge amount>", moduleID: "walletrecharge" },
                { text: "Wallet Balance", placeholder: "<wallet balance>", moduleID: "walletrecharge" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "walletrecharge" },
                // { text: "Store Phone No", placeholder: "<store no>", moduleID: "walletrecharge" },
                // { text: "Store Email ID", placeholder: "<store emailid>", moduleID: "walletrecharge" },
                // { text: "Store Address", placeholder: "<store address>", moduleID: "walletrecharge" },

                
                { text: "Customer Name", placeholder: "<customer name>", moduleID: "walletredemption" },
                { text: "Transaction No", placeholder: "<transaction no>", moduleID: "walletredemption" },
                { text: "Transaction Date", placeholder: "<transaction date>", moduleID: "walletredemption" },
                { text: "Redemption Amount", placeholder: "<redemption amount>", moduleID: "walletredemption" },
                { text: "Wallet Balance", placeholder: "<wallet balance>", moduleID: "walletredemption" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "walletredemption" },
                // { text: "Store Phone No", placeholder: "<store no>", moduleID: "walletredemption" },
                // { text: "Store Email ID", placeholder: "<store emailid>", moduleID: "walletredemption" },
                // { text: "Store Address", placeholder: "<store address>", moduleID: "walletredemption" },

                { text: "Customer Name", placeholder: "<customer name>", moduleID: "loyaltyearn" },
                { text: "Transaction No", placeholder: "<transaction no>", moduleID: "loyaltyearn" },
                { text: "Transaction Date", placeholder: "<transaction date>", moduleID: "loyaltyearn" },
                { text: "Loyalty Points Earned", placeholder: "<loyalty points earned>", moduleID: "loyaltyearn" },
                { text: "Total Loyalty Points", placeholder: "<loyalty balance>", moduleID: "loyaltyearn" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "loyaltyearn" },
                // { text: "Store Phone No", placeholder: "<store no>", moduleID: "loyaltyearn" },
                // { text: "Store Email ID", placeholder: "<store emailid>", moduleID: "loyaltyearn" },
                // { text: "Store Address", placeholder: "<store address>", moduleID: "loyaltyearn" },

                { text: "Customer Name", placeholder: "<customer name>", moduleID: "loyaltyredemption" },
                { text: "Transaction No", placeholder: "<transaction no>", moduleID: "loyaltyredemption" },
                { text: "Transaction Date", placeholder: "<transaction date>", moduleID: "loyaltyredemption" },
                { text: "Loyalty Points Redeemed", placeholder: "<loyalty points redeemed>", moduleID: "loyaltyredemption" },
                { text: "Total Loyalty Points", placeholder: "<loyalty balance>", moduleID: "loyaltyredemption" },
                { text: "Store Name", placeholder: "<store name>", moduleID: "loyaltyredemption" },
                // { text: "Store Phone No", placeholder: "<store no>", moduleID: "loyaltyredemption" },
                // { text: "Store Email ID", placeholder: "<store emailid>", moduleID: "loyaltyredemption" },
                // { text: "Store Address", placeholder: "<store address>", moduleID: "loyaltyredemption" },
            ],
            surveys: [],
            includeSurvey: false
        }
        this.onSave = this.onSave.bind(this)
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    async componentDidMount() {
        let name = ""
        let key = ""
        let storeID = ""
        let storeName = ""
        let storeCode = ""
        let moduleID = ""
        let moduleName = ""
        let transactionTypeID = ""
        let transactionType = ""
        let smsTemplateContent = ""
        let placeholders = this.state.placeholders
        let includeSurvey = false
        let surveyID = ""
        let smsType = ""
        let survey = ""
        let status = ""
        let surveys = []
        let image = null
        if(this.props.registration.registration.FeedbackEnabled)
        {
            placeholders.push({ text: "Feedback Link", placeholder: "<feedback link>" })
            let tmp = await this.props.getSurveys(this.props.user.user.RegistrationID)
            for (let i = 0; i < tmp.length; i++) {
                let survey = tmp[i]
                if (!survey.IsDeleted) {
                    if (storeID.trim() === "") {
                        if (survey.AllStores) {
                            surveys.push(survey)
                        }
                    }
                    else {
                        if (survey.Stores.filter(f => f.value == storeID).length > 0) {
                            surveys.push(survey)
                        }
                    }
                }
            }
        }
        if (this.props.registration.registration.ReferralEnabled) {
            placeholders.push({ text: "Referral Link", placeholder: "<referral link>" })
        }
        if (this.props.sms) {
            console.log('this.props.sms',this.props.sms)
            key = this.props.sms.key
            let smsTemplate = await this.props.getSMSTemplateDetails(key)
            smsType = smsTemplate.hasOwnProperty('SMSType') ? smsTemplate.SMSType : 'Transactional'
            name = smsTemplate.hasOwnProperty('Name') ? smsTemplate.Name : ''
            storeID = smsTemplate.StoreID
            storeName =  smsTemplate.hasOwnProperty('StoreName') ? smsTemplate.StoreName : ''
            storeCode = smsTemplate.hasOwnProperty('StoreCode') ? smsTemplate.StoreCode : ''
            moduleID =  smsTemplate.hasOwnProperty('ModuleID') ? smsTemplate.ModuleID : ''
            moduleName = smsTemplate.hasOwnProperty('Module') ?  smsTemplate.Module : ''
            transactionTypeID = smsTemplate.hasOwnProperty('TransactionTypeID') ?  smsTemplate.TransactionTypeID : ''
            transactionType = smsTemplate.hasOwnProperty('TransactionType') ?   smsTemplate.TransactionType : ''
            smsTemplateContent = smsTemplate.SMSTemplate
            includeSurvey = smsTemplate.hasOwnProperty('IncludeSurvey') ? smsTemplate.IncludeSurvey : false
            surveyID = smsTemplate.hasOwnProperty('SurveyID') ? smsTemplate.SurveyID : ''
            survey = smsTemplate.hasOwnProperty('Survey') ? smsTemplate.Survey : ''
            status = smsTemplate.hasOwnProperty('Status') ? smsTemplate.Status : 'Pending'
            image = smsTemplate.hasOwnProperty('Image') ? smsTemplate.Image : null 
        }
        else {
            //alert(this.props.location.state.source);
            // if (this.props.location.state.source.toLowerCase() === "promotiona") {
            //     smsType = "Promotional";
            //     moduleID = 'campaign';
            //     moduleName = 'Campaign';
            // }
            // else
            // {
            //     smsType = "Transacional";
            // }
            smsType = this.props.smsType;
            storeID = this.props.user.store.key;
            storeName = this.props.user.store.StoreName;
            storeCode = this.props.user.store.StoreCode;
        }

        this.setState({
            placeholders: placeholders,
            surveys: surveys,
            name: name,
            key: key,
            storeID: storeID,
            storeName: storeName,
            storeCode: storeCode,
            moduleID: moduleID,
            module: moduleName,
            transactionTypeID: transactionTypeID,
            smsTemplate: smsTemplateContent,
            transactionType: transactionType,
            includeSurvey: includeSurvey,
            surveyID: surveyID,
            smsType: smsType,
            status: status,
            image:image
        })
    }

    onSave() {
        let nameError = ""
        let smsTypeError = ""
        let surveyError = ""
        let moduleError = ""
        let transactionTypeError = ""
        let smsTemplateError = ""


        if (this.state.name.trim() === "") {
            nameError = "Please enter Template Name"
        }
        if (this.state.smsType.trim() === "") {
            smsTypeError = "Please select SMS Type"
        }
        if (this.state.moduleID.trim() === "") {
            moduleError = "Please select module"
        }
        if (this.state.moduleID.trim() !== "" && this.state.moduleID === "sales") {
            if (this.state.transactionTypeID.trim() === "") {
                transactionTypeError = "Please select transaction type"
            }
        }
        if (this.state.includeSurvey) {
            if (this.state.surveyID.trim() === "") {
                surveyError = "Please select survey"
            }
        }
        if (this.state.smsTemplate.trim() === "") {
            smsTemplateError = "Please provide SMS content"
        }

        if (this.state.smsTemplate.trim() !== "" &&
            (
                this.state.smsTemplate.includes("₹") ||
                this.state.smsTemplate.includes("^")
            )) {
            smsTemplateError = "Special characters are not allowed"
        }

        if (surveyError.trim() !== "" || moduleError.trim() !== "" || smsTypeError.trim() !== "" || nameError.trim() !== ""
            || transactionTypeError.trim() !== "" || smsTemplateError.trim() !== "") {
            this.setState({
                surveyError: surveyError, moduleError: moduleError, smsTypeError: smsTypeError,
                transactionTypeError: transactionTypeError, smsTemplateError: smsTemplateError, nameError: nameError
            })
            return
        }

        let smsTemplate = {
            Name: this.state.name,
            SMSType: this.state.smsType,
            StoreID: this.state.smsType ==="Promotional" ? this.state.storeID : "",
            StoreCode: this.state.smsType ==="Promotional" ?  this.state.storeCode : "",
            StoreName: this.state.smsType ==="Promotional" ?  this.state.storeName : "",
            ModuleID: this.state.moduleID,
            Module: this.state.module,
            TransactionTypeID: this.state.transactionTypeID,
            TransactionType: this.state.transactionType,
            SMSTemplate: this.state.smsTemplate,
            IncludeSurvey: this.state.includeSurvey,
            SurveyID: this.state.surveyID,
            Survey: this.state.survey,
            RegistrationID: this.props.user.user.RegistrationID,
            Status: 'Approved',
            IsDeleted: false,
            Image: this.state.image,
        }
        console.log('smsTemplate',smsTemplate)
        this.props.saveSMSTemplate(smsTemplate, this.state.key).then(() => {
            this.props.getToastr("Saved successfully")
            setTimeout(function () { //Start the timer
                this.setState({ saving: false });
                this.props.onSave();
            }.bind(this), 1000)  
        })
    }

    onButtonClick(action){
        if(action==="save"){
            this.onSave();
        }else{
            this.props.onClose()
        }
    }

    render() {

        return <Form
        title= {(this.state.key  && this.state.key ==="") ? "New Template" : "Edit Template"}
        onClick={(action)=>this.onButtonClick(action)}
        processing={this.state.saving}
        >
            <Input
                type="text"
                label="Template Name"
                onChange={(value) => this.setState({ name: value, nameError: '' })}
                value={this.state.name}
                error = {this.state.nameError}
            />
            <Input
            type="select" 
            label={"Module"}
            value={this.state.moduleID}
            onChange={(value) => {this.setState({moduleID: value, module: value, moduleError: ""})}}
            error={this.state.moduleError}>
                <option value="">Select module</option>
                {this.state.smsType === 'Transactional' && <option value="sales">Sales</option>}
                {this.state.smsType === 'Transactional' && <option value="appointment">Appointment</option>}
                {this.state.smsType === 'Transactional' && <option value="productalert">Product Alert</option>}
                {this.state.smsType === 'Transactional' && <option value="membershipexpiryalert">Membership Expiry Alert</option>}
                {this.state.smsType === 'Transactional' && <option value="packageexpiryalert">Package Expiry Alert</option>}
                {this.state.smsType === 'Transactional' &&<option value="walletotp">Wallet OTP</option>}
                {this.state.smsType === 'Transactional' && <option value="loyaltyotp">Loyalty OTP</option>}
                {this.state.smsType === 'Transactional' &&<option value="omniotp">Online store new user registration OTP</option>}
                {this.state.smsType === 'Transactional' &&<option value="omniloginotp">Online store login OTP</option>}
                {this.state.smsType === 'Transactional' &&<option value="walletrecharge">Wallet recharge</option>}
                {this.state.smsType === 'Transactional' &&<option value="walletredemption">Wallet redemption</option>}
                {this.state.smsType === 'Transactional' &&<option value="loyaltyearn">Loyalty point earn</option>}
                {this.state.smsType === 'Transactional' &&<option value="loyaltyredemption">Loyalty point redemption</option>}
                {this.state.smsType === 'Promotional' && <option value="campaign">Campaign</option>}
                {this.state.smsType === 'Promotional' && <option value="anniversary">Anniversary</option>}
                {this.state.smsType === 'Promotional' && <option value="birthday">Birthday</option>}
            </Input>
            {(this.state.smsType === 'Transactional' && this.state.moduleID === "sales") && 
            <Input
                type="select"
                label="Transaction Type"
                value={this.state.transactionTypeID}
                onChange={(value) => {
                    this.setState({
                        transactionTypeID: value,
                        transactionType: this.props.config.TransactionTypes.filter(f=> f.key === value)[0].TransactionType,
                        transactionTypeError: ""
                    })
                }}
                error ={this.state.transactionTypeError}
            >
                <option value="">Select transaction type</option>
                {this.props.config.TransactionTypes.filter((e) => !e.IsDeleted && e.Module === this.state.moduleID).map((t) =>
                    <option value={t.key}>New {t.TransactionType}</option>
                )}
               
            </Input>}
            <Input
                type="textarea"
                label="Message Content"
                onChange={(value) => this.setState({ smsTemplate:value, smsTemplateError: "" })}
                value={this.state.smsTemplate}
                error={this.state.smsTemplateError}
            />
            <label>{this.state.smsTemplate.length} characters</label>
            {this.props.registration.registration.FeedbackEnabled && <React.Fragment>
                <Input
                    type="switch"
                    label="Include Survey"
                    value={this.state.includeSurvey}
                    onChange={(value) => this.setState({ includeSurvey: value})}
                />
                {this.state.includeSurvey && <Input
                    type="select"
                    label="Please select survey"
                    value={this.state.surveyID}
                    error={this.state.surveyError}
                        onChange={(value) => {
                            this.setState({
                                surveyID: value,
                                survey: this.state.surveys.filter(f=> f.key === value)[0].Name, 
                                surveyError: ""
                            })
                        }}
                    >
                        <option value="">Select</option>
                        {this.state.surveys.map(m => <option value={m.key}>{m.Name}</option>)}
                </Input>}
            </React.Fragment>}
            {this.state.moduleID !== "" && <div className="col-md-4">
                <div className="form-group">
                    <label>Placeholders</label>
                    <table className="table">
                        {this.state.placeholders.filter(f => f.moduleID === this.state.moduleID).map((m, index) => <tr key={index}>
                            <td>{m.text}</td>
                            <td><input value={m.placeholder}></input></td>
                        </tr>)}
                    </table>
                </div>
            </div>}
        </Form>    
    }
}

export default SMS