import React, { Component } from "react";
import Form from "../2/Controls/Form";
import FormSection from "../2/Controls/FormSection";
import Input from "../2/Controls/Input";

class AddEditTransactionType extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      key: "",
      module: "sales",
      moduleError: "",
      transactionType: "",
      sequenceNo: 0,
      numberingFormat: [
        {
          Option: "text",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
        {
          Option: "storecode",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
        {
          Option: "cashregistercode",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
      ],
      salesImpactChecked: false,
      paymentModeChecked: false,
      calculateTaxChecked: false,
      customerMandatoryChecked: false,
      employeeMandatoryChecked: false,
      employeeManadatoryTransactionORLineItemLevel: "",
      paymentModeAllOrSpecific: "",
      discountChecked: false,
      discountPercentage: 0,
      key: "",
      isSystemDefined: false,
      paymentModeCheckedError: "",
      numberingOptions: [
        { value: "", text: "Select" },
        { value: "text", text: "Text" },
        { value: "storecode", text: "Store Code" },
        { value: "locationcode", text: "Location Code" },
        { value: "cashregistercode", text: "Cash Register Code" },
        { value: "fiscalyear", text: "Fiscal Year (YYYY)" },
        { value: "calendardmonthyear", text: "Calendar Month Year (MMYY)" },
        { value: "calendarmonth", text: "Calendar Month (MM)" },
        { value: "calendaryear", text: "Calendar Year (YYYY)" },
      ],
      numberingFormatDisplay: "",
      selectedTransactionType: null,
      updateStockChecked: false,
      updateStockInwardOrOutward: "",
      includePriceAndTax: false,
      transactionMovement: "",
      includePriceAndTax: false,
      balanceAmount: "",
      storeList: [],
      accessibleToSpecificStores: false,
      accessibleToSpecificStoresList: [],
      allowSpecificProductTypes: false,
      allowSpecificProductTypesGoodsForSale: false,
      allowSpecificProductTypesGoodsForSaleConsumable: false,
      allowSpecificProductTypesGoodsNotForSale: false,
      paymentModes: [],
      roles: [],
      enableApproval: false,
      firstApprover: "",
      firstApproverDisplay: "",
      secondApprover: "",
      secondApproverDisplay: "",
      finalApprover: "",
      finalApproverDisplay: "",
      powerUserAutorizationRequired: false,
      transferInAllowEdit: false,
      prePopulateProducts: false,
      productGroupID: "",
      inventoryToStore: "",
      hideFreeQty: false,
    };
    this.onOptionChanged = this.onOptionChanged.bind(this);
    this.onSeparatorChange = this.onSeparatorChange.bind(this);
    this.onRemoveText = this.onRemoveText.bind(this);
    this.displayNumberFormat = this.displayNumberFormat.bind(this);
    this.onSaveTransactionType = this.onSaveTransactionType.bind(this);
    this.getStoreList = this.getStoreList.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  async componentDidMount() {
    let roles = await this.props.getCustomRoles(
      this.props.user.user.RegistrationID
    );
    this.setState({ roles: roles });

    let paymentModes = this.props.config.PaymentTypes.filter(
      (e) => e.ShowInSales && !e.IsDeleted
    );
    paymentModes.map((m) => (m.selected = true));

    let storeList = await this.getStoreList();
    // console.log("storeList",storeList)
    this.setState({ storeList: storeList });
    if (this.props.transactionType !== null) {
      let transactionType = this.props.transactionType;

      if (
        transactionType.hasOwnProperty("PaymentTypes") &&
        transactionType.PaymentTypes.length > 0
      ) {
        for (let index = 0; index < paymentModes.length; index++) {
          const paymentType = paymentModes[index];
          if (
            transactionType.PaymentTypes.filter(
              (f) => paymentType.key === f.PaymentTypeID
            ).length > 0
          ) {
            paymentType.selected = true;
          } else {
            paymentType.selected = false;
          }
        }
      }

      let accessibleToSpecificStoresList = transactionType.hasOwnProperty(
        "AccessibleToSpecificStoresList"
      )
        ? transactionType.AccessibleToSpecificStoresList
        : [];
      storeList.map((m) => {
        if (
          accessibleToSpecificStoresList.filter((f) => f.StoreID === m.StoreID)
            .length > 0
        ) {
          m.Selected = true;
        } else {
          m.Selected = false;
        }
      });
      let numberingFormatTemp = [];
      transactionType.NumberingFormat.map((n, index) => {
        numberingFormatTemp.push({
          Option: n.Option,
          Value: n.Value,
          Separator: n.Separator,
          OptionError: "",
          ValueError: "",
        });
      });
      // console.log('transactionType',transactionType)
      this.setState({
        selectedTransactionType: transactionType,
        key: transactionType.key,
        module: transactionType.Module,
        transactionType: transactionType.TransactionType,
        numberingFormat: numberingFormatTemp,
        numberingFormatDisplay: transactionType.NumberingFormatDisplay,
        salesImpactChecked: transactionType.SalesImpactChecked,
        paymentModeChecked: transactionType.PaymentModeChecked,
        calculateTaxChecked: transactionType.CalculateTaxChecked,
        customerMandatoryChecked: transactionType.CustomerMandatoryChecked,
        employeeMandatoryChecked: transactionType.EmployeeMandatoryChecked,
        employeeManadatoryTransactionORLineItemLevel:
          transactionType.EmployeeManadatoryTransactionORLineItemLevel,
        updateStockChecked: transactionType.hasOwnProperty("UpdateStockChecked")
          ? transactionType.UpdateStockChecked
          : false,
        updateStockInwardOrOutward: transactionType.hasOwnProperty(
          "UpdateStockInwardOrOutward"
        )
          ? transactionType.UpdateStockInwardOrOutward
          : false,
        transactionMovement: transactionType.hasOwnProperty(
          "TransactionMovement"
        )
          ? transactionType.TransactionMovement
          : "",
        includePriceAndTax: transactionType.hasOwnProperty("IncludePriceAndTax")
          ? transactionType.IncludePriceAndTax
          : false,
        discountChecked: transactionType.DiscountChecked,
        isSystemDefined: transactionType.hasOwnProperty("IsSystemDefined")
          ? transactionType.IsSystemDefined
          : false,
        balanceAmount: transactionType.hasOwnProperty("BalanceAmount")
          ? transactionType.BalanceAmount
          : "partial",
        sequenceNo: transactionType.hasOwnProperty("SequenceNo")
          ? transactionType.SequenceNo
          : "",
        accessibleToSpecificStores: transactionType.hasOwnProperty(
          "AccessibleToSpecificStores"
        )
          ? transactionType.AccessibleToSpecificStores
          : "",
        allowSpecificProductTypes: transactionType.hasOwnProperty(
          "AllowSpecificProductTypes"
        )
          ? transactionType.AllowSpecificProductTypes
          : "",
        allowSpecificProductTypesGoodsForSale: transactionType.hasOwnProperty(
          "AllowSpecificProductTypesGoodsForSale"
        )
          ? transactionType.AllowSpecificProductTypesGoodsForSale
          : "",
        allowSpecificProductTypesGoodsForSaleConsumable: transactionType.hasOwnProperty(
          "AllowSpecificProductTypesGoodsForSaleConsumable"
        )
          ? transactionType.AllowSpecificProductTypesGoodsForSaleConsumable
          : "",
        allowSpecificProductTypesGoodsNotForSale: transactionType.hasOwnProperty(
          "AllowSpecificProductTypesGoodsNotForSale"
        )
          ? transactionType.AllowSpecificProductTypesGoodsNotForSale
          : "",
        accessibleToSpecificStoresList: accessibleToSpecificStoresList,
        storeList: storeList,
        enableApproval: transactionType.hasOwnProperty("EnableApproval")
          ? transactionType.EnableApproval
          : false,
        firstApprover: transactionType.hasOwnProperty("FirstApprover")
          ? transactionType.FirstApprover
          : "",
        firstApproverDisplay: transactionType.hasOwnProperty(
          "FirstApproverDisplay"
        )
          ? transactionType.FirstApproverDisplay
          : "",
        secondApprover: transactionType.hasOwnProperty("SecondApprover")
          ? transactionType.SecondApprover
          : "",
        secondApproverDisplay: transactionType.hasOwnProperty(
          "SecondApproverDisplay"
        )
          ? transactionType.SecondApproverDisplay
          : "",
        finalApprover: transactionType.hasOwnProperty("FinalApprover")
          ? transactionType.FinalApprover
          : "",
        finalApproverDisplay: transactionType.hasOwnProperty(
          "FinalApproverDisplay"
        )
          ? transactionType.FinalApproverDisplay
          : "",
        powerUserAutorizationRequired: transactionType.hasOwnProperty(
          "PowerUserAutorizationRequired"
        )
          ? transactionType.PowerUserAutorizationRequired
          : false,
        transferInAllowEdit: transactionType.hasOwnProperty(
          "TransferInAllowEdit"
        )
          ? transactionType.TransferInAllowEdit
          : false,
        prePopulateProducts: transactionType.hasOwnProperty(
          "PrePopulateProducts"
        )
          ? transactionType.PrePopulateProducts
          : false,
        productGroupID: transactionType.hasOwnProperty("ProductGroupID")
          ? transactionType.ProductGroupID
          : false,
        inventoryToStore: transactionType.hasOwnProperty("InventoryToStore")
          ? transactionType.InventoryToStore
          : "",
        hideFreeQty: transactionType.HideFreeQty
          ? transactionType.HideFreeQty
          : false,
      });
    }
    this.setState({ paymentModes: paymentModes });
  }

  async getStoreList() {
    const storeList = [];
    let result = await this.props.getStores(
      this.props.user.user.RegistrationID,
      0,
      10000,
      "active",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    let stores = result.searchResult;
    stores.map((store) => {
      let obj = {
        StoreID: store.key,
        StoreName: store.StoreName,
        Selected: false,
      };
      storeList.push(obj);
    });
    return storeList;
  }

  onOptionChanged(index, value) {
    let numberingFormat = this.state.numberingFormat;
    numberingFormat[index].Option = value;
    this.setState({ numberingFormat: numberingFormat });
    this.displayNumberFormat();
  }

  onSeparatorChange(index, value) {
    let numberingFormat = this.state.numberingFormat;
    numberingFormat[index].Separator = value;
    this.setState({ numberingFormat: numberingFormat });
    this.displayNumberFormat();
  }

  onRemoveText(index) {
    let numberingFormat = this.state.numberingFormat;
    numberingFormat[index].Option = "";
    numberingFormat[index].Value = "";
    this.setState({ numberingFormat: numberingFormat });
    this.displayNumberFormat();
  }

  onTextChange(index, value) {
    let numberingFormat = this.state.numberingFormat;
    numberingFormat[index].Value = value;
    numberingFormat[index].ValueError = "";
    this.setState({ numberingFormat: numberingFormat });
    this.displayNumberFormat();
  }

  onSaveTransactionType() {
    this.setState({ saving: true });
    let transactionTypeError = "";
    let moduleNameError = "";
    let numberingFormatError = false;
    let numberingFormat = this.state.numberingFormat;
    let paymentModeCheckedError = "";

    if (this.state.transactionType.trim() === "") {
      transactionTypeError = "Please provide transaction type";
    }
    if (this.state.module.trim() === "") {
      moduleNameError = "Please select module";
    }
    this.state.numberingFormat.map((para, index) => {
      let optionError = "";
      let valueError = "";

      if (para.Option === "") {
        numberingFormatError = true;
        optionError = "Please select option";
      }
      if (para.Option === "text" && para.Value === "") {
        numberingFormatError = true;
        valueError = "Please provide value";
      }
      numberingFormat[index].OptionError = optionError;
      numberingFormat[index].ValueError = valueError;
    });
    if (
      this.state.paymentModeChecked &&
      this.state.balanceAmount.trim() === ""
    ) {
      paymentModeCheckedError = "Please select option";
    }
    this.setState({ numberingFormat: numberingFormat });

    if (
      transactionTypeError !== "" ||
      moduleNameError !== "" ||
      numberingFormatError ||
      paymentModeCheckedError.trim() !== ""
    ) {
      this.setState({
        transactionTypeError: transactionTypeError,
        moduleNameError: moduleNameError,
        paymentModeCheckedError: paymentModeCheckedError,
      });
      // console.log('Error')
      this.setState({ saving: false });
      return;
    }

    let numberingFormatTemp = [];
    this.state.numberingFormat.map((n, index) => {
      numberingFormatTemp.push({
        Option: n.Option,
        Value: n.Value,
        Separator: n.Separator,
      });
    });
    let accessibleToSpecificStoresList = [];
    if (this.state.accessibleToSpecificStores) {
      accessibleToSpecificStoresList = this.state.storeList.filter(
        (f) => f.Selected
      );
    }
    let selectedPaymentModes = [];
    if (this.state.paymentModeChecked) {
      for (let i = 0; i < this.state.paymentModes.length; i++) {
        if (this.state.paymentModes[i].selected) {
          selectedPaymentModes.push({
            PaymentTypeID: this.state.paymentModes[i].key,
          });
        }
      }
    }
    // console.log("selectedPaymentModes", selectedPaymentModes)
    let transactionType = {
      Module: this.state.module,
      TransactionType: this.state.transactionType,
      SequenceNo: this.state.sequenceNo,
      NumberingFormat: numberingFormatTemp,
      NumberingFormatDisplay: this.state.numberingFormatDisplay,
      SalesImpactChecked: this.state.salesImpactChecked,
      PaymentModeChecked: this.state.paymentModeChecked,
      CalculateTaxChecked: this.state.calculateTaxChecked,
      CustomerMandatoryChecked: this.state.customerMandatoryChecked,
      EmployeeMandatoryChecked: this.state.employeeMandatoryChecked,
      EmployeeManadatoryTransactionORLineItemLevel: this.state
        .employeeManadatoryTransactionORLineItemLevel,
      DiscountChecked: this.state.discountChecked,
      DiscountPercentage: this.state.discountPercentage,
      UpdateStockChecked: this.state.updateStockChecked,
      UpdateStockInwardOrOutward: this.state.updateStockInwardOrOutward,
      IncludePriceAndTax: this.state.includePriceAndTax,
      TransactionMovement: this.state.transactionMovement,
      IsDeleted: false,
      BalanceAmount: this.state.balanceAmount,
      RegistrationID: this.props.user.user.RegistrationID,
      AccessibleToSpecificStores: this.state.accessibleToSpecificStores,
      AccessibleToSpecificStoresList: accessibleToSpecificStoresList,
      AllowSpecificProductTypes: this.state.allowSpecificProductTypes,
      AllowSpecificProductTypesGoodsForSale: this.state
        .allowSpecificProductTypesGoodsForSale,
      AllowSpecificProductTypesGoodsForSaleConsumable: this.state
        .allowSpecificProductTypesGoodsForSaleConsumable,
      AllowSpecificProductTypesGoodsNotForSale: this.state
        .allowSpecificProductTypesGoodsNotForSale,
      PaymentTypes: selectedPaymentModes,
      EnableApproval: this.state.enableApproval,
      FirstApprover: this.state.firstApprover,
      FirstApproverDisplay: this.state.firstApproverDisplay,
      SecondApprover: this.state.secondApprover,
      SecondApproverDisplay: this.state.secondApproverDisplay,
      FinalApprover: this.state.finalApprover,
      FinalApproverDisplay: this.state.finalApproverDisplay,
      PowerUserAutorizationRequired: this.state.powerUserAutorizationRequired,
      TransferInAllowEdit: this.state.transferInAllowEdit,
      PrePopulateProducts: this.state.prePopulateProducts,
      ProductGroupID: this.state.productGroupID,
      InventoryToStore: this.state.inventoryToStore,
      HideFreeQty: this.state.hideFreeQty,
    };
    // console.log("transactionType", transactionType);
    this.props.saveTransactionType(transactionType, this.state.key).then(() => {
      this.props.getToastr("Transaction type Saved Successfully");
      this.setState({ saving: false });
      this.props.onSave();
      // this.props.history.push("/home/settings/moduleconfig/")
    });
  }

  displayNumberFormat() {
    let numberingFormatDisplay = "";
    this.state.numberingFormat.map((n, index) => {
      let para = "";
      if (n.Option === "text") {
        para = n.Value;
      }
      if (n.Option == "storecode") {
        para = "S01";
      }
      if (n.Option == "locationcode") {
        para = "L01";
      }
      if (n.Option == "cashregistercode") {
        para = "CR1";
      }
      if (n.Option == "calendardmonthyear") {
        let d = new Date();
        let a = d.getFullYear() + "";
        para = d.getMonth() + 1 + a.substring(2, 4);
        if (para.length < 3) {
          para = "0" + para;
        }
      }
      if (n.Option === "fiscalyear") {
        let d = new Date();
        para = d.getFullYear() + "";
      }
      if (n.Option === "calendarmonth") {
        let d = new Date();
        let a = d.getMonth() + 1 + "";
        if (a.length < 2) {
          para = "0" + a;
        }
      }
      if (n.Option === "calendaryear") {
        let d = new Date();
        para = d.getFullYear() + "";
      }
      numberingFormatDisplay = numberingFormatDisplay + para + n.Separator;
    });
    numberingFormatDisplay = numberingFormatDisplay + "<Running Number>";
    this.setState({ numberingFormatDisplay: numberingFormatDisplay });
  }

  onModuleChange(value) {
    let numberingOptions = [];
    let numberingFormat = [];
    if (value === "sales") {
      numberingOptions = [
        { value: "", text: "Select" },
        { value: "text", text: "Text" },
        { value: "storecode", text: "Store Code" },
        { value: "locationcode", text: "Location Code" },
        { value: "cashregistercode", text: "Cash Register Code" },
        { value: "fiscalyear", text: "Fiscal Year (YYYY)" },
        { value: "calendardmonthyear", text: "Calendar Month Year (MMYY)" },
        { value: "calendarmonth", text: "Calendar Month (MM)" },
        { value: "calendaryear", text: "Calendar Year (YYYY)" },
      ];
      numberingFormat = [
        {
          Option: "text",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
        {
          Option: "storecode",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
        {
          Option: "cashregistercode",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
      ];
    } else {
      numberingOptions = [
        { value: "", text: "Select" },
        { value: "text", text: "Text" },
        { value: "storecode", text: "Store Code" },
        { value: "locationcode", text: "Location Code" },
        { value: "fiscalyear", text: "Fiscal Year (YYYY)" },
        { value: "calendardmonthyear", text: "Calendar Month Year (MMYY)" },
        { value: "calendarmonth", text: "Calendar Month (MM)" },
        { value: "calendaryear", text: "Calendar Year (YYYY)" },
      ];
      numberingFormat = [
        {
          Option: "text",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
        {
          Option: "storecode",
          Value: "",
          Separator: "",
          OptionError: "",
          ValueError: "",
        },
      ];
    }
    this.setState({
      module: value,
      moduleError: "",
      numberingOptions: numberingOptions,
      numberingFormat: numberingFormat,
    });
  }

  onButtonClick(action) {
    if (action === "save") {
      this.onSaveTransactionType();
    } else {
      this.props.onClose();
    }
  }

  render() {
    return (
      <Form
        title={
          this.state.key ? "Edit Transaction Type" : "New Transaction Type"
        }
        onClick={(action) => this.onButtonClick(action)}
        processing={this.state.saving}
      >
        <Input
          type="select"
          label="Module"
          value={this.state.module}
          disabled={this.state.key !== ""}
          onChange={(value) => this.onModuleChange(value)}
          error={this.state.moduleError}
        >
          <option value="sales">Sales</option>
          <option value="inventory">Inventory</option>
        </Input>
        <Input
          type="text"
          label="Transaction Type"
          disabled={this.state.isSystemDefined}
          onChange={(value) => {
            this.setState({ transactionType: value, transactionTypeError: "" });
          }}
          value={this.state.transactionType}
          error={this.state.transactionTypeError}
        />
        <Input
          type="number"
          label="Sequence No"
          onChange={(value) => {
            this.setState({ sequenceNo: value });
          }}
          value={this.state.sequenceNo}
        />
        <FormSection title="Options">
          {this.state.module === "sales" && (
            <React.Fragment>
              <Input
                type="switch"
                label="Show in sales report"
                value={this.state.salesImpactChecked}
                onChange={(value) =>
                  this.setState({ salesImpactChecked: value })
                }
              />
              <Input
                type="switch"
                label="Allow payment modes"
                value={this.state.paymentModeChecked}
                onChange={(value) =>
                  this.setState({
                    paymentModeChecked: value,
                    paymentModeAllOrSpecific: !value ? "all" : "",
                    paymentModeCheckedError: "",
                  })
                }
                error={this.state.paymentModeCheckedError}
              />
              {this.state.paymentModeChecked && (
                <Input
                  type="select"
                  label="Balance allowed"
                  value={this.state.balanceAmount}
                  // disabled={this.state.key !== ""}
                  onChange={(value) => {
                    this.setState({
                      balanceAmount: value,
                      paymentModeCheckedError: "",
                    });
                  }}
                  error={this.state.paymentModeCheckedError}
                >
                  <option value="">Please select</option>
                  <option value="partial">Partial Balance Allowed</option>
                  <option value="full">Full Balance Allowed</option>
                  <option value="no">Balance Not Allowed</option>
                </Input>
              )}
              <Input
                type="switch"
                label="Update stock"
                value={this.state.updateStockChecked}
                onChange={(value) =>
                  this.setState({ updateStockChecked: value })
                }
              />
              <Input
                type="switch"
                label="Calculate taxes"
                value={this.state.calculateTaxChecked}
                onChange={(value) =>
                  this.setState({ calculateTaxChecked: value })
                }
              />
              <Input
                type="switch"
                label="Customer selection manadatory"
                value={this.state.customerMandatoryChecked}
                onChange={(value) =>
                  this.setState({ customerMandatoryChecked: value })
                }
              />
              <Input
                type="switch"
                label="Employee tagging mandatory"
                value={this.state.employeeMandatoryChecked}
                onChange={(value) =>
                  this.setState({ employeeMandatoryChecked: value })
                }
              />
              {this.state.employeeMandatoryChecked && (
                <Input
                  type="select"
                  label="Employee tagging"
                  value={
                    this.state.employeeManadatoryTransactionORLineItemLevel
                  }
                  onChange={(value) => {
                    this.setState({
                      employeeManadatoryTransactionORLineItemLevel: value,
                    });
                  }}
                >
                  <option value="">Please select</option>
                  <option value="transaction">Transaction Level</option>
                  <option value="lineitem">Transaction Line Item Level</option>
                </Input>
              )}
              <Input
                type="switch"
                label="Discount allowed"
                value={this.state.discountChecked}
                onChange={(value) => this.setState({ discountChecked: value })}
              />
              <Input
                type="switch"
                label="Power user authorization (OTP) required to proceed with transaction"
                value={this.state.powerUserAutorizationRequired}
                onChange={(value) =>
                  this.setState({ powerUserAutorizationRequired: value })
                }
              />
            </React.Fragment>
          )}
          {this.state.module === "inventory" && (
            <React.Fragment>
              <Input
                type="switch"
                label="Update stock"
                value={this.state.updateStockChecked}
                onChange={(value) =>
                  this.setState({ updateStockChecked: value })
                }
              />
              <Input
                type="switch"
                label="Hide Free Qty"
                value={this.state.hideFreeQty}
                onChange={(value) => this.setState({ hideFreeQty: value })}
              />
              {this.state.updateStockChecked && (
                <Input
                  type="select"
                  label="Inward / Outward"
                  value={this.state.updateStockInwardOrOutward}
                  onChange={(value) => {
                    this.setState({ updateStockInwardOrOutward: value });
                  }}
                >
                  <option value="">Please select</option>
                  <option value="inward">
                    Inward (Stock qty will increase)
                  </option>
                  <option value="outward">
                    Outward (Stock qty will reduce)
                  </option>
                </Input>
              )}
              <Input
                type="switch"
                label="Include supply price and taxes"
                value={this.state.includePriceAndTax}
                onChange={(value) =>
                  this.setState({ includePriceAndTax: value })
                }
              />
              <Input
                type="select"
                label="Transaction movement"
                value={this.state.transactionMovement}
                onChange={(value) => {
                  this.setState({ transactionMovement: value });
                }}
              >
                <option value="">Select option</option>
                <option value="vendortostore">Vendor to Store</option>
                <option value="storetovendor">Store to Vendor</option>
                <option value="storetostore">Store to Store</option>
                <option value="store">Store</option>
                <option value="storelocationtostorelocation">
                  Store location to Store location
                </option>
              </Input>
              {this.state.transactionMovement === "storetostore" && (
                <Input
                  label="To Store"
                  type="select"
                  value={this.state.inventoryToStore}
                  onChange={(value) =>
                    this.setState({ inventoryToStore: value })
                  }
                >
                  <option value="">
                    All stores will be available for selection
                  </option>
                  {this.state.storeList.map((m) => (
                    <option value={m.StoreID}>{m.StoreName}</option>
                  ))}
                </Input>
              )}
              <Input
                type="switch"
                label="Allow specific product types"
                value={this.state.allowSpecificProductTypes}
                onChange={(value) =>
                  this.setState({ allowSpecificProductTypes: value })
                }
              />
              {this.state.allowSpecificProductTypes && (
                <React.Fragment>
                  <Input
                    type="checkbox"
                    label="Goods For Sale"
                    value={this.state.allowSpecificProductTypesGoodsForSale}
                    onChange={(value) =>
                      this.setState({
                        allowSpecificProductTypesGoodsForSale: value,
                      })
                    }
                  />
                  <Input
                    type="checkbox"
                    label="Goods For Sale (Consumable)"
                    value={
                      this.state.allowSpecificProductTypesGoodsForSaleConsumable
                    }
                    onChange={(value) =>
                      this.setState({
                        allowSpecificProductTypesGoodsForSaleConsumable: value,
                      })
                    }
                  />
                  <Input
                    type="checkbox"
                    label="Goods Not For Sale"
                    value={this.state.allowSpecificProductTypesGoodsNotForSale}
                    onChange={(value) =>
                      this.setState({
                        allowSpecificProductTypesGoodsNotForSale: value,
                      })
                    }
                  />
                </React.Fragment>
              )}
              {this.state.transactionType.toLowerCase() === "transfer in" && (
                <Input
                  type="switch"
                  label="Allow line item Qty and Price edit"
                  value={this.state.transferInAllowEdit}
                  onChange={(value) =>
                    this.setState({ transferInAllowEdit: value })
                  }
                />
              )}
              <Input
                type="switch"
                label="Pre populate products"
                value={this.state.prePopulateProducts}
                onChange={(value) =>
                  this.setState({ prePopulateProducts: value })
                }
              />
              {this.state.prePopulateProducts && (
                <Input
                  type="select"
                  label="Please select product group"
                  value={this.state.productGroupID}
                  onChange={(value) => this.setState({ productGroupID: value })}
                >
                  <option value="">Please select</option>
                  {this.props.config.ProductTags.map((m) => (
                    <option value={m.key}>{m.Name}</option>
                  ))}
                </Input>
              )}
            </React.Fragment>
          )}
        </FormSection>
        {this.props.registration.registration.EnableInventoryApproval &&
          this.state.module === "inventory" &&
          !this.state.updateStockChecked && (
            <FormSection title="Approval Flow">
              <Input
                type="switch"
                label="Enable approval flow"
                value={this.state.enableApproval}
                onChange={(value) => this.setState({ enableApproval: value })}
              />
              {this.state.enableApproval && (
                <React.Fragment>
                  <div className="form-group">
                    <label>First approver</label>
                    <select
                      type="text"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState({
                          firstApprover: evt.target.value,
                          firstApproverDisplay:
                            evt.target.value.trim() === ""
                              ? ""
                              : evt.target.options[evt.target.selectedIndex]
                                  .text,
                        })
                      }
                      value={this.state.firstApprover}
                    >
                      <option value="">Select first approver </option>
                      <option key="Power User">Power User</option>
                      <option key="Store Owner">Store Owner</option>
                      <option key="Store Manager">Store Manager</option>
                      <option key="Store Cashier">Store Cashier</option>
                      {this.state.roles.map((role) => (
                        <option key={role.key} value={role.key}>
                          {role.Role}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Second approver</label>
                    <select
                      type="text"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState({
                          secondApprover: evt.target.value,
                          secondApproverDisplay:
                            evt.target.value.trim() === ""
                              ? ""
                              : evt.target.options[evt.target.selectedIndex]
                                  .text,
                        })
                      }
                      value={this.state.secondApprover}
                    >
                      <option value="">Select second approver </option>
                      <option key="Power User">Power User</option>
                      <option key="Store Owner">Store Owner</option>
                      <option key="Store Manager">Store Manager</option>
                      <option key="Store Cashier">Store Cashier</option>
                      {this.state.roles.map((role) => (
                        <option key={role.key} value={role.key}>
                          {role.Role}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Final approver</label>
                    <select
                      type="text"
                      className="form-control"
                      onChange={(evt) =>
                        this.setState({
                          finalApprover: evt.target.value,
                          finalApproverDisplay:
                            evt.target.value.trim() === ""
                              ? ""
                              : evt.target.options[evt.target.selectedIndex]
                                  .text,
                        })
                      }
                      value={this.state.finalApprover}
                    >
                      <option value="">Select final approver </option>
                      <option key="Power User">Power User</option>
                      <option key="Store Owner">Store Owner</option>
                      <option key="Store Manager">Store Manager</option>
                      <option key="Store Cashier">Store Cashier</option>
                      {this.state.roles.map((role) => (
                        <option key={role.key} value={role.key}>
                          {role.Role}
                        </option>
                      ))}
                    </select>
                  </div>
                </React.Fragment>
              )}
            </FormSection>
          )}
        <FormSection title="Accessible to specific stores">
          <Input
            type="switch"
            label=""
            value={this.state.accessibleToSpecificStores}
            onChange={(value) =>
              this.setState({ accessibleToSpecificStores: value })
            }
          />
          {this.state.accessibleToSpecificStores && (
            <React.Fragment>
              {this.state.storeList.map((m, index) => (
                <div key={index}>
                  {" "}
                  <input
                    type="checkbox"
                    checked={m.Selected}
                    onChange={() => {
                      let storeList = this.state.storeList;
                      storeList[index].Selected = !storeList[index].Selected;
                      this.setState({ storeList: storeList });
                    }}
                  />
                  {m.StoreName}
                </div>
              ))}
            </React.Fragment>
          )}
        </FormSection>
        {this.state.module === "sales" && this.state.paymentModeChecked && (
          <FormSection title="Payment modes to be considered for billing">
            {this.state.paymentModes.map((m, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  checked={m.selected}
                  onChange={() => {
                    let paymentModes = this.state.paymentModes;
                    paymentModes[index].selected = !paymentModes[index]
                      .selected;
                    this.setState({ paymentModes: paymentModes });
                  }}
                />
                {m.PaymentType}
              </div>
            ))}
          </FormSection>
        )}
        <FormSection title="Numbering format">
          <div className="row">
            <div className="col-md-5">
              <div className="form-group">
                <label>Parameter</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Separator</label>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          {this.state.numberingFormat.map((m, index) => (
            <div className="row">
              <div className="col-md-5 col-xs-8">
                {m.Option !== "text" && (
                  <div
                    className={
                      m.OptionError !== ""
                        ? "form-group has-error"
                        : "form-group "
                    }
                  >
                    <select
                      className="form-control"
                      onChange={(evt) =>
                        this.onOptionChanged(index, evt.target.value)
                      }
                      value={m.Option}
                    >
                      {this.state.numberingOptions.map((m) => (
                        <option value={m.value}>{m.text}</option>
                      ))}
                    </select>
                    {m.OptionError !== "" && (
                      <span className="help-block">{m.OptionError}</span>
                    )}
                  </div>
                )}
                {m.Option === "text" && (
                  <div
                    className={
                      m.ValueError !== ""
                        ? "form-group has-error"
                        : "form-group "
                    }
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.onTextChange(index, evt.target.value)
                        }
                        value={m.Value}
                      />
                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-default btn-md"
                          onClick={() => this.onRemoveText(index)}
                        >
                          <i className="fa fa-remove"></i>{" "}
                        </button>
                      </div>
                    </div>
                    {m.ValueError !== "" && (
                      <span className="help-block">{m.ValueError}</span>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-4 col-xs-2">
                <input
                  type="text"
                  value={m.Separator}
                  className="form-control"
                  onChange={(evt) =>
                    this.onSeparatorChange(index, evt.target.value)
                  }
                />
              </div>
              {index === this.state.numberingFormat.length - 1 && (
                <div className="col-md-2 col-xs-2">
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-default btn-md"
                      onClick={() => {
                        let numberingFormat = this.state.numberingFormat;
                        numberingFormat.push({
                          Option: "",
                          Value: "",
                          Separator: "",
                          OptionError: "",
                          ValueError: "",
                        });
                        this.setState({ numberingFormat: numberingFormat });
                      }}
                    >
                      <i className="fa fa-plus"></i>{" "}
                    </button>
                  </div>
                </div>
              )}
              {index !== this.state.numberingFormat.length - 1 && (
                <div className="col-md-2 col-xs-2">
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-default btn-md"
                      onClick={() => {
                        let numberingFormat = this.state.numberingFormat;
                        numberingFormat.splice(index, 1);
                        this.setState({ numberingFormat: numberingFormat });
                      }}
                    >
                      <i className="fa fa-remove"></i>{" "}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div>
            <font style={{ fontSize: "20px", marginTop: "30px" }}>
              <b>{this.state.numberingFormatDisplay}</b>
            </font>
          </div>
        </FormSection>

        {/* <div className="row">
                <div className="col-md-4">
                    
                    
                </div>
                <div className="col-md-6">
                    <div style={{ borderBottom: "1px solid #f4f4f4", padding: "5px 0px", marginBottom: "20px", fontSize: "18px" }}  >
                        <b>Numbering</b>
                    </div>
                    
                </div>
            </div> */}
      </Form>
    );
  }
}

export default AddEditTransactionType;
