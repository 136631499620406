
import {database, storageRef} from '../firebase'
import * as actionTypes from '../actionTypes'

export function getPosts(registrationID) {
    return (dispatch) => {
        console.log(registrationID)
        return database.collection("Post").where("RegistrationID","==",registrationID).orderBy("Sequence","desc").get().then((querySnapshot) => {
            const posts = []
            querySnapshot.forEach((doc) => {
                const {PostDescription, PostRedirectionURL, PostSourceType, PostSourceUrl, Registration, RegistrationID, Sequence, Status}= doc.data()
                posts.push(
                  {
                        key:doc.id,
                      PostDescription, PostRedirectionURL, PostSourceType, PostSourceUrl, Registration, RegistrationID, Sequence, Status
                  }
                )
           })
           console.log('getPost:', posts)
           dispatch({type: actionTypes.GET_POSTS,posts})
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function savePost(post){
    console.log('savePost.start',post)
    return dispatch =>{
        return database.collection("Post").add({
            PostDescription: post.PostDescription,
            PostRedirectionURL: post.PostRedirectionURL,
            PostSourceType: post.PostSourceType,
            PostSourceUrl: post.PostSourceUrl,
            Registration: post.Registration,
            RegistrationID: post.RegistrationID,
            Sequence: post.Sequence,
            Status: post.Status
        })
        .then(function(docRef) {
            console.log("Post saved with ID: ", docRef.id);
        })
        .catch(function(error) {
            console.error("Error adding post: ", error);
        })
    }
}

export function publishPost(postID){
    console.log('deactivatePost Start',postID)
    return dispatch =>{
        return database.collection("Post").doc(postID).update({
            Status: 1
        })
        .then(function(docRef) {
            console.log("Post with ID deactivated: ", docRef.id);
        })
        .catch(function(error) {
            console.error("Error deactivating post: ", error);
        })
    }
}

export function deactivatePost(postID){
    console.log('deactivatePost Start',postID)
    return dispatch =>{
        return database.collection("Post").doc(postID).update({
            Status: 2
        })
        .then(function(docRef) {
            console.log("Post with ID deactivated: ", docRef.id);
        })
        .catch(function(error) {
            console.error("Error deactivating post: ", error);
        })
    }
}

export function uploadFileToServer(filesTobeUploaded, storageFolder)
{
    return (dispatch) => {
        const fileNameAndPath = storageFolder + '/' + filesTobeUploaded[0][0].name
        let postDownloadURL = ''
        return storageRef.child(fileNameAndPath).put(filesTobeUploaded[0][0]).then(function(snapshot) {
            console.log('uploadFileToServer - Uploaded a blob or file!');
            return snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                postDownloadURL= downloadURL
                dispatch({type: actionTypes.POST_FILE_UPLOADED,postDownloadURL})
                });
        });
    }
}

export function getNextSequenceNo(){
    return (dispatch) =>{
    let postNextSequenceNo = 0
    return database.collection("Post").orderBy("Sequence", "desc").limit(1).get().then((querySnapshot) => {
       querySnapshot.forEach((doc) => {
        postNextSequenceNo = doc.data().Sequence
           console.log(postNextSequenceNo)
       })
       postNextSequenceNo = postNextSequenceNo + 1
       console.log('postNextSequenceNo',postNextSequenceNo)
       dispatch({type:actionTypes.POST_NEXT_SEQUENCE_NO_UPDATED, postNextSequenceNo})
    })
  }
}
