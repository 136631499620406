import React, { Component } from "react";
import { getTables } from "../../actions/TableAction";
import { getOpenTableOrders } from "../../actions/TableOrderAction";
import { HotKeys } from "react-hotkeys";
import Loader from "../Loader";
import "./Style.css";
var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;

const keyMap = {
  tableSearch: "f1",
  delivery: "f2",
  pickUp: "f3",
  list: "f4",
  refresh: "f5",
};

class TableOrder extends Component {
  constructor() {
    super();
    this.state = {
      tableGroups: [],
      tables: [],
      orders: [],
      tableSearchResult: [],
      transactionType: null,
      loading: false,
    };
    this.onTableClick = this.onTableClick.bind(this);
    this.shortcutTableSearch = this.shortcutTableSearch.bind(this);
    this.onDeliveryClicked = this.onDeliveryClicked.bind(this);
    this.onPickUpClicked = this.onPickUpClicked.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showList = this.showList.bind(this);
  }

  componentWillMount() {
    document.body.className =
      "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
  }

  componentDidMount() {
    //get list of tables
    this.setState({ loading: true });
    setTimeout(
      function() {
        //Start the timer
        this.loadData();
      }.bind(this),
      2000
    );
  }

  async loadData() {
    let tables = await this.props.getTables(this.props.user.store.key);
    console.log("tables", tables);

    ///get orders related to tables
    let tableOrders = await getOpenTableOrders(this.props.user.store.key);
    console.log("tableOrders", tableOrders);
    // return
    tables
      .filter((f) => !f.IsDeleted)
      .map((m) => {
        for (let i = 0; i < tableOrders.length; i++) {
          if (
            tableOrders[i].hasOwnProperty("Table") &&
            tableOrders[i].Table &&
            tableOrders[i].Table.key === m.key
          ) {
            m.Sale = tableOrders[i];
          }
        }
      });
    let transactionType = this.props.config.TransactionTypes.filter(
      (e) =>
        !e.IsDeleted &&
        e.key === this.props.user.store.TableOrderTransactionTypeID
    )[0];
    this.setState({
      tables: tables,
      transactionType: transactionType,
      loading: false,
    });
  }

  async onTableClick(table) {
    if (!table.hasOwnProperty("Sale")) {
      this.props.history.push({
        pathname: "/home/sales/sales",
        state: {
          type: "SALE_NEW",
          module: "TableOrder",
          table: table,
          KOTType: "Dine In",
          key: "",
          transactionType: this.state.transactionType,
          sale: null,
        },
      });
    } else {
      let sale = await this.props.getSaleDetails(table.Sale.key);
      this.props.history.push({
        pathname: "/home/sales/sales",
        state: {
          type: "SALE_EDIT",
          module: "TableOrder",
          table: table,
          KOTType: "Dine In",
          key: table.Sale.key,
          transactionType: this.state.transactionType,
          sale: sale,
        },
      });
    }
  }

  shortcutTableSearch(event) {
    event.preventDefault();
    this.refs.refTableSearch.getInstance().focus();
  }

  onDeliveryClicked() {
    this.props.history.push({
      pathname: "/home/sales/sales",
      state: {
        type: "SALE_NEW",
        module: "TableOrder",
        table: null,
        KOTType: "Delivery",
        key: "",
        transactionType: this.state.transactionType,
        sale: null,
      },
    });
  }

  onPickUpClicked() {
    this.props.history.push({
      pathname: "/home/sales/sales",
      state: {
        type: "SALE_NEW",
        module: "TableOrder",
        KOTType: "Pick Up",
        table: null,
        key: "",
        transactionType: this.state.transactionType,
        sale: null,
      },
    });
  }

  showList() {
    this.props.history.push({
      pathname: "/home/sales/saleslist",
      state: { type: "list" },
    });
  }

  render() {
    const handlers = {
      tableSearch: this.shortcutTableSearch,
      delivery: this.onDeliveryClicked,
      pickUp: this.onPickUpClicked,
      refresh: this.loadData,
      list: this.showList,
    };

    return (
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <div className="main-container">
          <div className="content-wrapper">
            <section className="content">
              <div className="row">
                <div className="col-md-4">
                  <AsyncTypeahead
                    ref="refTableSearch"
                    autoFocus={true}
                    allowNew={false}
                    // isLoading={this.state.isProductTypeAheadLoading}
                    multiple={false}
                    options={this.state.tableSearchResult}
                    labelKey="key"
                    minLength={1}
                    onSearch={(query) => {
                      let tables = this.state.tables;
                      let tableSearchResult = tables.filter(
                        (p) =>
                          !p.IsDeleted &&
                          p.Table.toLowerCase().includes(query.toLowerCase())
                      );
                      this.setState({ tableSearchResult: tableSearchResult });
                    }}
                    placeholder="(F1) Start typing to search table"
                    filterBy={["Table"]}
                    bsSize={"large"}
                    dropup={false}
                    highlightOnlyResult={true}
                    //onInputChange={(option) => console.log('option', option)}
                    // selected={this.state.selectedTable}
                    onChange={(selected) => {
                      this.onTableClick(selected[0]);
                      this.setState({ tableSearchResult: [] });
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <div>
                        <div>
                          <b>{option.Table}</b>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="col-md-4">
                  <div className="">
                    <button
                      className="btn btn-lg  btn-flat"
                      onClick={this.showList}
                    >
                      List (F4)
                    </button>
                    <button
                      className="btn btn-lg  btn-flat"
                      onClick={this.loadData}
                    >
                      Refresh (F5)
                    </button>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="pull-right">
                    <button
                      className="btn btn-lg btn-primary btn-flat"
                      onClick={this.onDeliveryClicked}
                    >
                      Delivery (F2)
                    </button>
                    <button
                      className="btn btn-lg btn-primary btn-flat"
                      onClick={this.onPickUpClicked}
                    >
                      Pick Up (F3)
                    </button>
                  </div>
                </div>
              </div>

              <br />

              {this.state.loading && <Loader show={this.state.loading} />}

              {!this.state.loading && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="item-list-scroll scroll2">
                      {this.state.tables
                        .filter((f) => !f.IsDeleted)
                        .map((m, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              this.onTableClick(m);
                            }}
                            className={
                              m.Sale && m.Sale.TransactionStatus === "Pending"
                                ? "table-button-running"
                                : m.Sale &&
                                  m.Sale.TransactionStatus === "PrintBill"
                                ? "table-button-billprinted"
                                : "table-button"
                            }
                          >
                            <div className="table-button-tableno">
                              {m.Table}
                            </div>
                            {m.Sale && (
                              <div className="table-button-amt">
                                Amt: {m.Sale.BillAmount}
                              </div>
                            )}
                            {m.Sale && (
                              <div className="table-button-qty">
                                Qty: {m.Sale.BillQty}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </HotKeys>
    );
  }
}

export default TableOrder;
