import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';

class AddEditCustomRoles extends Component {

    constructor() {
        super()
        this.state = {
            // customRole: [],
            // roles: [],
            role: null,
            key: '',
            roleName: '',
            roleNameError: '',
            systemModules: [
                { 'name': 'Sales' },
                { 'name': 'Inventory' },
                { 'name': 'CRM' },
                { 'name': 'Expenses' },
                { 'name': 'Catalogue' },
                { 'name': 'Loyalty' },
                { 'name': 'OMNI' },
                { 'name': 'Reports' },
                { 'name': 'Setup' },
            ],
            modules: []
        }
        this.onSaveCustomRole = this.onSaveCustomRole.bind(this)
        this.onSelectionChange = this.onSelectionChange.bind(this)
        this.onButtonClick = this.onButtonClick.bind(this);
    }

    async componentDidMount() {

        let modules = []
        if (this.props.registration.registration.hasOwnProperty('PlanID') && this.props.registration.registration.PlanID !== '') {
            let planModules = this.props.registration.registration.PlanModules;
            for (let k = 0; k < planModules.length; k++) {
                const planModule = planModules[k];
                modules.push(planModule);
            }
            // console.log("modules", JSON.stringify(this.props.registration.registration.PlanModules));
        }
        else
            modules = await this.props.getModules();

        this.props.config.TransactionTypes.filter((e) => {
            if (e.Module === "sales" && !e.IsDeleted) {
                return true
            }
            else {
                return false
            }
        }).map((e, index) =>
            modules.push({ 'name': e.TransactionType, 'perms': { 'read': false, 'create': false, 'update': false, 'delete': false, 'export': false, 'module': 'Sales' }, enabled: true })
        )

        this.props.config.TransactionTypes.filter((e) => {
            if (e.Module === "inventory" && !e.IsDeleted) {
                return true
            }
            else {
                return false
            }
        }).map((e, index) =>
            modules.push({ 'name': e.TransactionType, 'perms': { 'read': false, 'create': false, 'update': false, 'delete': false, 'export': false, 'module': 'Inventory' }, enabled: true })
        )

        let key = '';
        // console.log('this.props.customRole',this.props.customRole)
        if (this.props.customRole) {
            key = this.props.customRole.key;
            let role = await this.props.getCustomRoleDetails(key);
            this.setState({ roleName: role.Role });
            let roleModules = await this.props.getModulesForARole(key);
            for (let i = 0; i < modules.length; i++) {
                let module = modules[i];
                let roleModule = roleModules.filter((f) => f.name === module.name)[0];
                if (roleModule) {
                    // if (this.props.registration.registration.hasOwnProperty('PlanID') && this.props.registration.registration.PlanID !== '')
                    //     module.enabled = roleModule.hasOwnProperty('enabled') ? roleModule.enabled : false       
                    module.perms = roleModule.perms;
                }
            }
        }
        // console.log('modules', JSON.stringify(modules));
        this.setState({ modules: modules, key: key });
    }

    onSaveCustomRole() {
        this.setState({ saving: true })
        let roleNameError = ""

        if (this.state.roleName.trim() === "") {
            roleNameError = "Please provide role name"
        }

        if (roleNameError !== "") {
            this.setState({ roleNameError: roleNameError, saving: false })
            return
        }

        let role = {
            Name: this.state.roleName,
            Modules: this.state.modules
        } 
        let key = this.state.key;
        this.props.saveCustomRoles(role, key).then(() => {
            this.props.getToastr("Role Saved Successfully")
            this.setState({ saving: false })
            this.props.onSave();
        })
    }

    onSelectionChange(name, perms) {
        let modules = this.state.modules
        let index = -1
        modules.map((m, i) => {
            if (m.name === name)
                index = i
        })
        if (perms === 'read')
            modules[index].perms.read = !modules[index].perms.read
        else if (perms === 'create')
            modules[index].perms.create = !modules[index].perms.create
        else if (perms === 'update')
            modules[index].perms.update = !modules[index].perms.update
        else if (perms === 'delete')
            modules[index].perms.delete = !modules[index].perms.delete
        else if (perms === 'export')
            modules[index].perms.export = !modules[index].perms.export
        this.setState({ modules: modules })
    }

    onModuleEnabledChange(name) {
        let modules = this.state.modules;
        let index = -1;
        modules.map((m, i) => {
            if (m.name === name)
                index = i;
            return null;
        });
        modules[index].enabled = !modules[index].enabled;
        modules[index].perms.read = !modules[index].perms.read;
        modules[index].perms.create = !modules[index].perms.create;
        modules[index].perms.update = !modules[index].perms.update;
        modules[index].perms.delete = !modules[index].perms.delete;
        modules[index].perms.export = !modules[index].perms.export;
        this.setState({ modules: modules });
    }

    onButtonClick(action){
        if(action==="save"){
            this.onSaveCustomRole();
        }else{
            this.props.onClose()
        }
    }


    render() {
        return  <Form
            title= {"Role"}
            onClick={(action)=>this.onButtonClick(action)}
            processing={this.state.saving}
            >
                 <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            label="Role"
                            onChange={(value)=> this.setState({roleName:value,roleNameError:"" })}
                            value={this.state.roleName}
                            error={this.state.roleNameError}
                        />
                    </div>
                </div>
                <div className="row">
                    {this.state.systemModules.map((sysModule, sysIndex) =>
                        <div className="col-md-12">
                            <div className="panel-group">
                                <div className="panel panel-default">
                                    <div className="panel-heading">
                                        <h4 className="panel-title" data-toggle="collapse" href={'#' + sysModule.name} style={{ cursor: "pointer" }}>
                                            {sysModule.name}
                                        </h4>
                                    </div>
                                    <div id={sysModule.name} className="panel-collapse collapse">
                                        <div className="scroll2" style={{ height: "auto", overflowY: "scroll", overflowX: "hidden", marginLeft: "10px" }}>
                                            <br />
                                            {this.state.modules.filter((f) => f.perms.module === sysModule.name).map((module, modIndex) =>
                                                <React.Fragment>
                                                    {(this.props.registration.registration.hasOwnProperty('PlanID') && this.props.registration.registration.PlanID !== '' && module.enabled || !this.props.registration.registration.hasOwnProperty('PlanID')) && <div key={modIndex} className="form-group" >
                                                        {/* <input type="checkbox"
                                                            checked={module.enabled}
                                                            value={module.enabled}
                                                            onChange={(evt) => this.onModuleEnabledChange(module.name)}
                                                        />&nbsp; */}
                                                        <label>{module.name}</label>
                                                        <br />
                                                        {(!module.perms.module || module.perms.module !== "Reports") && <React.Fragment>
                                                            <input type="checkbox"
                                                                checked={module.perms.create}
                                                                value={module.perms.create}
                                                                onChange={(evt) => this.onSelectionChange(module.name, 'create')}
                                                            />&nbsp;
                                                    <label >Create</label>&nbsp;&nbsp;
                                                    </React.Fragment>}
                                                        <React.Fragment>
                                                            <input type="checkbox"
                                                                checked={module.perms.read}
                                                                value={module.perms.read}
                                                                onChange={(evt) => this.onSelectionChange(module.name, 'read')
                                                                } />&nbsp;
                                                    <label >Read</label>&nbsp;&nbsp;
                                                    </React.Fragment>
                                                        {(!module.perms.module || module.perms.module !== "Reports") && <React.Fragment>
                                                            <input type="checkbox"
                                                                value={module.perms.update}
                                                                onChange={(evt) => this.onSelectionChange(module.name, 'update')}
                                                                checked={module.perms.update}
                                                            />&nbsp;
                                                    <label >Update</label>&nbsp;&nbsp;
                                                    </React.Fragment>}
                                                        {(!module.perms.module || module.perms.module !== "Reports") && <React.Fragment>

                                                            <input type="checkbox"
                                                                value={module.perms.delete}
                                                                onChange={(evt) => this.onSelectionChange(module.name, 'delete')}
                                                                checked={module.perms.delete}
                                                            />&nbsp;
                                                    <label >{(module.perms.module === "Sales" || module.perms.module === "Inventory" ) ? "Cancel" : "Delete"} </label>&nbsp;&nbsp;
                                                    </React.Fragment>}
                                                        <React.Fragment>
                                                            <input type="checkbox"
                                                                value={module.perms.export}
                                                                onChange={(evt) => this.onSelectionChange(module.name, 'export')}
                                                                checked={module.perms.export}
                                                            />&nbsp;
                                                    <label >Export</label>
                                                        </React.Fragment>
                                                    </div>} </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>)}
                </div>
            </Form>
        
    }
}
export default withRouter(AddEditCustomRoles)