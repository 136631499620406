import React, { Component } from "react";
import Modal from "react-responsive-modal";
import AddEditStoreLocation from "./AddEditStoreLocation";
import AddEditCashRegister from "./AddEditCashRegister";
import * as actions from "../../actionTypes";
import Confirm from "react-confirm-bootstrap";
import { Link } from "react-router-dom";
import PageSection from "../2/Controls/PageSection";
import Input from "../2/Controls/Input";
import GoogleMaps from "../2/GoogleMap";
import TimePicker from "../TimePicker";
import Button from "../2/Controls/Button";
import Window from "../2/Controls/Window";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
// import TimePicker from '../TimePicker'

class ViewStoreDetails extends Component {
  constructor() {
    super();
    this.state = {
      storeID: "",
      storeDetails: null,
      isShowAddEditLocation: false,
      addEditStoreLocationAction: "",
      selectedStoreLocation: null,
      storeLocations: [],
      cashRegisters: [],
      showError: false,
      cashRegisterError: "",
      isShowAddEditCashRegister: false,
      selectedCashRegister: null,
      addEditCashRegisterAction: "",
      customField1DisplayName: "Custom Field 1",
      customField2DisplayName: "Custom Field 2",
      customField3DisplayName: "Custom Field 3",
      customField4DisplayName: "Custom Field 4",
      customField5DisplayName: "Custom Field 5",
      customField6DisplayName: "Custom Field 6",
      paymentProvider: "",
      paymentProviders: [],
      domain: [],
      storeTypes: [],
      storeDomainID: "",
      storeTypeID: "",
      pincodeHashKey: "",
    };
    this.onStoreLocationSave = this.onStoreLocationSave.bind(this);
    this.onStoreLocationDelete = this.onStoreLocationDelete.bind(this);
    this.loadStoreLocations = this.loadStoreLocations.bind(this);
    this.loadCashRegisters = this.loadCashRegisters.bind(this);
    this.ShowAddEditCashRegister = this.ShowAddEditCashRegister.bind(this);
    this.onCashRegisterSave = this.onCashRegisterSave.bind(this);
    this.onCashRegisterDelete = this.onCashRegisterDelete.bind(this);
    this.loadPaymentProviders = this.loadPaymentProviders.bind(this);
    this.onSavePaymenProvider = this.onSavePaymenProvider.bind(this);
    this.renderCashRegisterRowOptions = this.renderCashRegisterRowOptions.bind(
      this
    );
    this.renderLocationRowOptions = this.renderLocationRowOptions.bind(this);
    this.renderGoogleMaps = this.renderGoogleMaps.bind(this);
  }

  async componentDidMount() {
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Store") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          customField1DisplayName: customField1DisplayName,
          customField2DisplayName: customField2DisplayName,
          customField3DisplayName: customField3DisplayName,
          customField4DisplayName: customField4DisplayName,
          customField5DisplayName: customField5DisplayName,
          customField6DisplayName: customField6DisplayName,
        });
      }
    });
    let store = await this.props.getStoreDetails(this.props.match.params.key);
    // .then((store) => {
    // console.log('store',store)
    store.key = this.props.match.params.key;
    this.setState({
      storeDetails: store,
      storeID: this.props.match.params.key,
    });
    //console.log('key', this.props.location.state.key)
    this.loadStoreLocations();
    this.loadCashRegisters();
    // this.loadPaymentProviders()
    // })
  }

  async loadPaymentProviders() {
    this.props
      .getStorePaymentProviders(this.state.storeID)
      .then((paymentProviders) => {
        console.log("paymentProviders", paymentProviders);
        this.setState({ paymentProviders: paymentProviders, loading: false });
      });
  }

  onPaymentProviderChange(paymentProvider) {
    let paymentMode = "";
    let merchantID = "";
    let saltKey = "";
    let saltIndex = "";

    if (
      this.state.paymentProviders.filter(
        (f) => f.PaymentProvider === paymentProvider
      ).length > 0
    ) {
      let tmp = this.state.paymentProviders.filter(
        (f) => f.PaymentProvider === paymentProvider
      )[0];
      paymentMode = tmp.paymentMode;
      merchantID = tmp.MerchantID;
      saltKey = tmp.SaltKey;
      saltIndex = tmp.SaltIndex;
    }
    this.setState({
      paymentProvider: paymentProvider,
      paymentMode: paymentMode,
      merchantID: merchantID,
      saltKey: saltKey,
      saltIndex: saltIndex,
    });
  }

  async onSavePaymenProvider() {
    let paymentProviderError = "";
    let paymentModeError = "";
    let merchantIDError = "";
    let saltKeyError = "";
    let saltIndexError = "";

    if (this.state.paymentProvider === "") {
      paymentProviderError = "Please select payment provder";
      // alert(paymentProviderError)
    }
    if (this.state.paymentMode === "") {
      paymentModeError = "Please select payment provder";
      // alert(paymentModeError)
    }
    if (this.state.merchantID === "") {
      merchantIDError = "Please enter merchant id";
    }
    if (this.state.saltKey === "") {
      saltKeyError = "Please enter salt key ";
    }
    if (this.state.saltIndex === "") {
      saltIndexError = "Please enter salt indexr";
    }
    if (
      paymentProviderError !== "" ||
      paymentModeError !== "" ||
      merchantIDError !== "" ||
      saltKeyError !== "" ||
      saltIndexError !== ""
    ) {
      this.setState({
        paymentProviderError: paymentProviderError,
        paymentModeError: paymentModeError,
        merchantIDError: merchantIDError,
        saltKeyError: saltKeyError,
        saltIndexError: saltIndexError,
      });
      alert("Error");
      return true;
    }
    let key = "";
    await this.props.saveStorePaymentProvider(key, {
      StoreID: this.state.storeID,
      PaymentProvider: this.state.paymentProvider,
      PaymentMode: this.state.paymentMode,
      MerchantID: this.state.merchantID,
      SaltKey: this.state.saltKey,
      SaltIndex: this.state.saltIndex,
    });
    this.loadPaymentProviders();
  }

  showAddEditStoreLocationsModal(action, storeLocation) {
    this.setState({
      isShowAddEditLocation: true,
      addEditStoreLocationAction: action,
      selectedStoreLocation: storeLocation,
    });
  }

  onStoreLocationSave() {
    this.setState({ isShowAddEditLocation: false });
    this.loadStoreLocations();
  }

  loadStoreLocations() {
    this.props.getStoreLocations(this.state.storeID).then((storeLocations) => {
      console.log("storeLocations", storeLocations);
      this.setState({ storeLocations: storeLocations, loading: false });
    });
  }

  onStoreLocationDelete(key) {
    this.props.deleteStoreLocations(key).then(() => {
      this.loadStoreLocations();
    });
  }

  loadCashRegisters() {
    this.props.getCashRegisters(this.state.storeID).then((cashRegisters) => {
      this.setState({ cashRegisters: cashRegisters, loading: false });
    });
  }

  ShowAddEditCashRegister(cashRegister, action) {
    if (action === actions.CASH_REGISTER_NEW) {
      let cashRegisterCount = this.state.cashRegisters.filter(
        (f) => !f.IsDeleted
      ).length;
      // console.log(cashRegisterCount);

      if (
        this.state.storeDetails.hasOwnProperty("NoOfCashRegistersAllowed") &&
        this.state.storeDetails.NoOfCashRegistersAllowed > cashRegisterCount
      ) {
        this.setState({
          isShowAddEditCashRegister: true,
          selectedCashRegister: cashRegister,
          addEditCashRegisterAction: action,
        });
      } else {
        this.setState({
          showError: true,
          cashRegisterError:
            "Additional CashRegister cannot be added as limit has exceeded.",
        });
      }
    } else {
      this.setState({
        isShowAddEditCashRegister: true,
        selectedCashRegister: cashRegister,
        addEditCashRegisterAction: action,
      });
    }
  }

  onCashRegisterSave() {
    this.setState({ isShowAddEditCashRegister: false });
    this.loadCashRegisters();
  }

  onCashRegisterDelete(key) {
    this.props.deleteCashRegister(key).then(() => {
      this.loadCashRegisters();
    });
  }

  renderLocationRowOptions(storeLocation) {
    if (storeLocation.IsDeleted) {
      return <span className="label label-danger pull-right">Inactive</span>;
    } else {
      if (this.props.config.WhiteLabel.Code === "PINCODE") {
        return <React.Fragment></React.Fragment>;
      } else {
        return (
          <div className="btn-group">
            <div>
              <button
                onClick={() =>
                  this.showAddEditStoreLocationsModal(
                    actions.STORELOCATION_EDIT,
                    storeLocation
                  )
                }
                type="button"
                className="btn btn-md btn-default "
              >
                <i className="fa fa-edit"></i>
              </button>
              <Confirm
                onConfirm={() => this.onStoreLocationDelete(storeLocation.key)}
                body="Are you sure you would like to delete this store location?"
                confirmText="Yes"
                title="Store Location Delete"
              >
                <button className="btn btn-default">
                  <i className="fa fa-trash-o"></i>
                </button>
              </Confirm>
            </div>
          </div>
        );
      }
    }
  }

  renderCashRegisterRowOptions(cashRegister) {
    if (cashRegister.IsDeleted) {
      return <span className="label label-danger pull-right">Inactive</span>;
    } else {
      if (this.props.config.WhiteLabel.Code === "PINCODE") {
        return <React.Fragment></React.Fragment>;
      } else {
        return (
          <div className="btn-group">
            <div>
              <button
                onClick={() =>
                  this.ShowAddEditCashRegister(
                    cashRegister,
                    actions.CASH_REGISTER_EDIT
                  )
                }
                type="button"
                className="btn btn-md btn-default "
              >
                <i className="fa fa-edit"></i>
              </button>
              <Confirm
                onConfirm={() => this.onCashRegisterDelete(cashRegister.key)}
                body="Are you sure you would like to delete this cash register?"
                confirmText="Yes"
                title="Cash Register Delete"
              >
                <button className="btn btn-default">
                  <i className="fa fa-trash-o"></i>
                </button>
              </Confirm>
            </div>
          </div>
        );
      }
    }
  }

  renderGoogleMaps() {
    return (
      <React.Fragment>
        {this.state.showGoogleMaps && (
          <Window
            header={"Maps"}
            height={"300px"}
            modal={false}
            onClose={() => this.setState({ showGoogleMaps: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showGoogleMaps: false });
            }}
            buttons={[]}
          >
            <div className="row">
              <div className="col-md-12">
                <GoogleMaps
                  value={this.state.storeDetails.Place}
                  draggable={true}
                  height="300px"
                />
              </div>
            </div>
          </Window>
        )}
      </React.Fragment>
    );
  }

  render() {
    const btnColStyle = { width: "120px" };

    return (
      <React.Fragment>
        {this.renderGoogleMaps()}

        <div className="content-wrapper">
          <section className="content-header">
            <h1>View Store Details</h1>
            {this.state.storeDetails && (
              <React.Fragment>
                {/* <button type="submit" className="btn btn-default pull-right btn-flat"
                            style={{ marginTop: "-25px", marginLeft: "10px" }}
                            onClick={() => this.setState({showPaymentSetup:true})}>Payment Setup</button> */}
                <button
                  type="submit"
                  className="btn btn-primary pull-right btn-flat"
                  style={{ marginTop: "-25px", marginLeft: "10px" }}
                  onClick={() =>
                    this.props.history.push({
                      pathname:
                        "/home/settings/stores/editstore/" + this.state.storeID,
                      // , state: { action: actions.STORE_EDIT, storeID: this.state.storeID, store: this.state.storeDetails }
                    })
                  }
                >
                  Edit Store Details
                </button>
                <Link
                  to={{
                    pathname: "/home/settings/stores/printconfig",
                    state: {
                      storeID: this.state.storeID,
                      storeCode: this.state.storeDetails.StoreCode,
                      storeName: this.state.storeDetails.StoreName,
                    },
                  }}
                  style={{ marginTop: "-25px", marginLeft: "10px" }}
                  className="btn btn-default pull-right btn-flat"
                >
                  <i className="fa fa-print"></i>Print Config
                </Link>
                <Link
                  to={{
                    pathname: "/home/settings/stores/barcodesettings",
                    state: {
                      storeID: this.state.storeID,
                      storeCode: this.state.storeDetails.StoreCode,
                      storeName: this.state.storeDetails.StoreName,
                    },
                  }}
                  className="btn btn-default pull-right btn-flat"
                  style={{ marginTop: "-25px", marginLeft: "10px" }}
                >
                  <i className="fa fa-barcode"></i>Barcode setting
                </Link>
                {this.props.config.WhiteLabel.Code !== "PINCODE" && (
                  <React.Fragment>
                    <button
                      style={{ marginTop: "-25px", marginLeft: "10px" }}
                      type="submit"
                      className="btn btn-default pull-right btn-flat"
                      onClick={() =>
                        this.showAddEditStoreLocationsModal(
                          actions.STORELOCATION_NEW,
                          null
                        )
                      }
                    >
                      Add Location
                    </button>
                    <button
                      type="submit"
                      className="btn btn-default pull-right btn-flat"
                      style={{ marginTop: "-25px", marginLeft: "10px" }}
                      onClick={() =>
                        this.ShowAddEditCashRegister(
                          null,
                          actions.CASH_REGISTER_NEW
                        )
                      }
                    >
                      Add Cash Register
                    </button>
                  </React.Fragment>
                )}
                <button
                  type="cancel"
                  className="btn btn-default pull-right btn-flat"
                  style={{ marginTop: "-25px", marginLeft: "10px" }}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/home/settings/stores",
                    })
                  }
                >
                  <i className="fa fa-arrow-left"></i>Back
                </button>
              </React.Fragment>
            )}
          </section>
          {this.state.storeDetails && (
            <section className="content">
              <PageSection title="General details">
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="text"
                      label="Store ID"
                      value={this.state.storeID}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="text"
                      label="Store Code"
                      value={this.state.storeDetails.StoreCode}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="text"
                      label="Store Name"
                      value={this.state.storeDetails.StoreName}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="mobileno"
                      label="Phone Number"
                      value={this.state.storeDetails.PhoneNo}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="emailid"
                      label="Email ID"
                      value={this.state.storeDetails.EmailID}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      type="textarea"
                      label="Short Description"
                      value={this.state.storeDetails.ShortDescription}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="textarea"
                      label="Long Description"
                      value={this.state.storeDetails.LongDescription}
                    />
                  </div>
                </div>
              </PageSection>
              <PageSection title="Address">
                <div className="row">
                  <div className="col-md-4">
                    <Input
                      type="text"
                      label="Line Address 1"
                      value={this.state.storeDetails.Address1}
                    />
                    <Input
                      type="text"
                      label="Line Address 2"
                      value={this.state.storeDetails.Address2}
                    />
                    <Input
                      type="text"
                      label="Locality"
                      value={this.state.storeDetails.Locality}
                    />
                    <Input
                      type="text"
                      label="City"
                      value={this.state.storeDetails.City}
                    />
                    <Input
                      type="text"
                      label="Postal Code"
                      value={this.state.storeDetails.PostalCode}
                    />
                    <Input
                      type="text"
                      label="State"
                      value={this.state.storeDetails.State}
                    />
                    <Input
                      type="text"
                      label="Country"
                      value={this.state.storeDetails.Country}
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          type="text"
                          label="Latitude"
                          value={
                            this.state.storeDetails.Location
                              ? this.state.storeDetails.Location.lat
                              : ""
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <Input
                          type="text"
                          label="Longitude"
                          value={
                            this.state.storeDetails.Location
                              ? this.state.storeDetails.Location.lon
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <Button
                          type="secondary"
                          text="Google Maps"
                          onClick={() => {
                            this.setState({ showGoogleMaps: true });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </PageSection>
              <PageSection title="Logo & Images">
                {this.state.storeDetails.Logo && (
                  <div className="row">
                    <div className="col-md-12">
                      <span className="mailbox-attachment-icon has-img">
                        <img
                          src={this.state.storeDetails.Logo.ImagePath}
                          alt="Attachment"
                        />
                      </span>
                    </div>
                  </div>
                )}
                {this.state.storeDetails.Images && (
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.storeDetails.Images.map((m) => (
                        <span className="mailbox-attachment-icon has-img">
                          <img src={m.ImagePath} alt="Attachment" />
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </PageSection>
              <PageSection title="Delivery">
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="switch"
                      label="Allow Customer PickUp"
                      value={this.state.storeDetails.CustomerPickUp}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="select"
                      label="Delivery"
                      value={this.state.storeDetails.DeliveryType}
                      disabled={true}
                    >
                      <option>Please select</option>
                      <option value="self">Self Delivery</option>
                      <option value="shadowfax">Shadowfax</option>
                    </Input>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Time To Ship</label>
                    <div className="timePicker">
                      <input
                        type="number"
                        value={this.state.storeDetails.ShipTime}
                        className="form-control"
                      ></input>
                      <select
                        disabled={true}
                        value={this.state.storeDetails.ShipTimeType}
                        placeholder="DD"
                        className="form-control"
                      >
                        <option value="min">mins</option>
                        <option value="hr">hrs</option>
                        <option value="day">days</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Time To Deliver</label>
                    <div className="timePicker">
                      <input
                        type="number"
                        value={this.state.storeDetails.DeliveryTime}
                        className="form-control"
                      ></input>
                      <select
                        value={this.state.storeDetails.DeliveryTimeType}
                        disabled={true}
                        placeholder="DD"
                        className="form-control"
                      >
                        <option value="min">mins</option>
                        <option value="hr">hrs</option>
                        <option value="day">days</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="number"
                      label="Delivery Radius (kms)"
                      value={this.state.storeDetails.DeliveryRadius}
                    />
                  </div>
                </div>
                {/* {this.state.deliveryType === "self" && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          type="number"
                          label="Delivery Charge"
                          value={this.state.storeDetails.DeliveryCharge}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )} */}
              </PageSection>

              <PageSection title="Settings">
                <div className="row">
                  <div className="col-md-3">
                    <Input
                      type="switch"
                      label="Accept online orders"
                      value={this.state.storeDetails.AcceptOnlineOrders}
                    />
                    {this.state.storeDetails.AcceptOnlineOrders && (
                      <Input
                        type="switch"
                        label="Auto accept online orders"
                        value={this.state.storeDetails.AutoAcceptOnlineOrders}
                      />
                    )}
                    <Input
                      type="switch"
                      label="Display product availability online based on the 'Out of Stock' attribute, without considering inventory counts."
                      value={
                        this.state.storeDetails
                          .ShowProductsOnlineAvailabilityBasedOnOutOfStock
                      }
                    />
                  </div>
                  {/* <div className="col-md-3">
                    <Input
                      type="select"
                      label="Domain"
                      value={this.state.storeDomainID}
                      onChange={(value) => this.onStoreDomainSelected(value)}
                      error={this.state.storeDomainError}
                    >
                      <option value="">Please select domain</option>
                      {this.state.domain.map((b) => (
                        <option value={b.id}>{b.value}</option>
                      ))}
                    </Input>
                    <Input
                      type="select"
                      label="Store Type"
                      value={this.state.storeTypeID}
                      onChange={(value) => this.onStoreTypeSelected(value)}
                      error={this.state.storeTypeError}
                    >
                      <option value="">Please select store type</option>
                      {this.state.storeTypes.map((c) => (
                        <option value={c.id}>{c.value}</option>
                      ))}
                    </Input>
                    <Input
                      type="text"
                      label="Merchant Hash Key"
                      value={this.state.pincodeHashKey}
                      onChange={(value) =>
                        this.setState({
                          pincodeHashKey: value,
                          pincodeHashKeyError: "",
                        })
                      }
                      error={this.state.pincodeHashKeyError}
                    />
                  </div> */}
                </div>
                {this.props.config.WhiteLabel.Code !== "PINCODE" && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          type="switch"
                          label="Enable cash register closing"
                          value={
                            this.state.storeDetails.CashRegisterClosingSelected
                          }
                        />
                      </div>
                      {this.state.storeDetails.CashRegisterClosingSelected && (
                        <div className="col-md-3">
                          <Input
                            type="switch"
                            label="Show product sale details during cash register closing"
                            value={
                              this.state.storeDetails
                                .CashRegisterClosingIncludeProductSaleDetails
                            }
                          />
                        </div>
                      )}
                      <div className="col-md-3">
                        <Input
                          type="switch"
                          label="Negative product qty not allowed for billing"
                          value={
                            this.state.storeDetails
                              .NegativeProductQtyNotAllowedForBilling
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <Input
                          type="switch"
                          label="Change qty on price update"
                          value={this.state.storeDetails.ChangeQtyOnPriceUpdate}
                        />
                      </div>
                      {this.state.urbanPiperIntegration && (
                        <div className="col-md-3">
                          <Input
                            type="switch"
                            label="Enable food delivery platforms"
                            value={
                              this.state.storeDetails
                                .EnableFoodDeliveryPlatforms
                            }
                          />
                        </div>
                      )}
                    </div>
                    {this.props.registration.registration
                      .BillingCustomerRestriction && (
                      <div className="row">
                        <div className="col-md-3">
                          <Input
                            type="switch"
                            label="Disable customer creation and edit"
                            value={
                              this.state.storeDetails
                                .DisableCustomerCreationAndEdit
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-3">
                        <Input
                          type="switch"
                          label="Enable Table Order"
                          value={this.state.storeDetails.EnableTableOrder}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </PageSection>
              <PageSection title="Store Timing">
                <div className="row">
                  <div className="col-md-8">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>Day</th>
                          <th style={{ width: "50px" }}>Open</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.storeDetails &&
                          this.state.storeDetails.StoreTiming &&
                          this.state.storeDetails.StoreTiming.map(
                            (m, index) => (
                              <tr>
                                <td>{m.Day}</td>
                                <td>
                                  <Input type="checkbox" value={m.Open} />
                                </td>
                                <td>
                                  {m.Open && <TimePicker time={m.StartTime} />}
                                </td>
                                <td>
                                  {m.Open && <TimePicker time={m.EndTime} />}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </PageSection>
              <div className="row">
                <div className="col-md-6">
                  <PageSection title="Location">
                    {this.state.storeLocations.length > 0 && (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Location Code</th>
                            <th>Location Name</th>
                            <th style={btnColStyle}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.storeLocations.map(
                            (storeLocation, index) => (
                              <tr key={"location" + index}>
                                <td>{storeLocation.LocationCode}</td>
                                <td>{storeLocation.LocationName}</td>
                                <td>
                                  {this.renderLocationRowOptions(storeLocation)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </PageSection>
                </div>
                <div className="col-md-6">
                  <PageSection title="Cash Register">
                    {this.state.cashRegisters.length > 0 && (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Cash Register Code</th>
                            <th>Cash Register Name</th>
                            {/* <th>Revision History</th> */}
                            <th style={btnColStyle}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.cashRegisters.map(
                            (cashRegister, index) => (
                              <tr key={index}>
                                <td>{cashRegister.CashRegisterCode}</td>
                                <td>{cashRegister.CashRegisterName}</td>
                                {/* <td>
                                            {cashRegister.hasOwnProperty('Action') && <React.Fragment>
                                                <div>Action :{cashRegister.Action}</div>
                                                <div>Action By :{cashRegister.ActionBy}</div>
                                                <div>Action Date :{(new Date(cashRegister.ActionOn.seconds * 1000)).toString().substring(0, 24)}</div>
                                                <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={cashRegister.key} />
                                            </React.Fragment>}
                                        </td> */}
                                <td>
                                  {this.renderCashRegisterRowOptions(
                                    cashRegister
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </PageSection>
                </div>
              </div>
              {this.props.config.WhiteLabel.Code !== "PINCODE" && (
                <PageSection title="Additonal Information">
                  <div className="row">
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label={this.state.customField1DisplayName}
                        value={this.state.storeDetails.CustomField1}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label={this.state.customField2DisplayName}
                        value={this.state.storeDetails.CustomField2}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label={this.state.customField3DisplayName}
                        value={this.state.storeDetails.CustomField3}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label={this.state.customField4DisplayName}
                        value={this.state.storeDetails.CustomField4}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label={this.state.customField5DisplayName}
                        value={this.state.storeDetails.CustomField5}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label={this.state.customField6DisplayName}
                        value={this.state.storeDetails.CustomField6}
                      />
                    </div>
                    <div className="col-md-3">
                      <Input
                        type="text"
                        label="Google Review Link"
                        value={this.state.storeDetails.GoogleReviewLink}
                      />
                    </div>
                  </div>
                </PageSection>
              )}

              <AuditTrail
                {...this.props}
                module={constants.MODULE_STORE}
                parentKey={this.props.match.params.key}
              />
              {/* <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Location</h3>
                        <button type="submit" className="btn btn-primary pull-right btn-flat" onClick={() => this.showAddEditStoreLocationsModal(actions.STORELOCATION_NEW, null)}>Add Location</button>
                    </div>
                    <div className="box-body">
                        
                    </div>
                </div> */}
              {/* <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Cash Register</h3>
                        <button type="submit" className="btn btn-primary pull-right btn-flat" onClick={() => this.ShowAddEditCashRegister(null, actions.CASH_REGISTER_NEW)}>Add Cash Register</button>
                    </div>
                    <div className="box-body">
                        
                    </div>
                </div> */}
            </section>
          )}
          <Modal
            open={this.state.isShowAddEditLocation}
            onClose={() => this.setState({ isShowAddEditLocation: false })}
            center
          >
            <div style={{ width: "400px" }}>
              <AddEditStoreLocation
                {...this.props}
                // storeID={this.state.storeID}
                store={this.state.storeDetails}
                action={this.state.addEditStoreLocationAction}
                storeLocation={this.state.selectedStoreLocation}
                onSave={() => this.onStoreLocationSave()}
                onClose={() => this.setState({ isShowAddEditLocation: false })}
              />
            </div>
          </Modal>
          <Modal
            open={this.state.isShowAddEditCashRegister}
            onClose={() => this.setState({ isShowAddEditCashRegister: false })}
            center
          >
            <div style={{ width: "400px" }}>
              <AddEditCashRegister
                {...this.props}
                storeID={this.state.storeID}
                action={this.state.addEditCashRegisterAction}
                cashRegister={this.state.selectedCashRegister}
                storeLocations={this.state.storeLocations}
                onSave={() => this.onCashRegisterSave()}
                onClose={() =>
                  this.setState({ isShowAddEditCashRegister: false })
                }
              />
            </div>
          </Modal>
          <Modal
            open={this.state.showError}
            onClose={() => this.setState({ showError: false })}
            center
          >
            <div style={{ width: "300px" }}>
              <h3 style={{ marginTop: "0px" }}>Error</h3>
              <div className="box-body">
                <ul>
                  {this.state.cashRegisterError !== "" && (
                    <li>{this.state.cashRegisterError}</li>
                  )}
                </ul>
              </div>
              <div className="box-footer">
                <button
                  type="submit"
                  className="btn btn-flat btn-md btn-danger pull-right"
                  onClick={() => this.setState({ showError: false })}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            open={this.state.showPaymentSetup}
            onClose={() => this.setState({ showPaymentSetup: false })}
            center
          >
            <div style={{ width: "300px" }}>
              <h3 style={{ marginTop: "0px" }}>Payment Options</h3>
              {/* <div className="box-body"> */}
              <div className="col-md-12">
                <div
                  className={
                    this.state.paymentProviderError
                      ? "form-group has-error"
                      : "form-group "
                  }
                >
                  <label>Payment provider</label>
                  <select
                    className="form-control"
                    value={this.state.paymentProvider}
                    onChange={(evt) =>
                      this.onPaymentProviderChange(evt.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="phonepe">Phone Pe</option>
                    <option value="paytm">PayTM</option>
                  </select>
                </div>
              </div>
              {/* </div> */}
              {this.state.paymentProvider !== "" && (
                <React.Fragment>
                  <div className="col-md-12">
                    <div
                      className={
                        this.state.paymentModeError
                          ? "form-group has-error"
                          : "form-group "
                      }
                    >
                      <label>Payment Type</label>
                      <select
                        className="form-control"
                        value={this.state.paymentMode}
                        onChange={(evt) =>
                          this.setState({ paymentMode: evt.target.value })
                        }
                      >
                        <option value="">Select</option>
                        {this.props.config.PaymentTypes.filter(
                          (f) => f.ShowInSales && !f.IsDeleted
                        ).map((e) => (
                          <option value={e.PaymentType}>{e.PaymentType}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div
                      className={
                        this.state.merchantIDError
                          ? "form-group has-error"
                          : "form-group "
                      }
                    >
                      <label>Merchat ID</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ merchantID: evt.target.value })
                        }
                        value={this.state.merchantID}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className={
                        this.state.saltKeyError
                          ? "form-group has-error"
                          : "form-group "
                      }
                    >
                      <label>Salt Key</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ saltKey: evt.target.value })
                        }
                        value={this.state.saltKey}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div
                      className={
                        this.state.saltIndexError
                          ? "form-group has-error"
                          : "form-group "
                      }
                    >
                      <label>Salt Index</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ saltIndex: evt.target.value })
                        }
                        value={this.state.saltIndex}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="box-footer">
                <button
                  type="submit"
                  className="btn btn-flat btn-md btn-danger pull-right"
                  onClick={this.onSavePaymenProvider}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default ViewStoreDetails;
