import React, { Component } from 'react'

var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class PartySearch extends Component {
    constructor() {
        super()
        this.state = {
            isPartyTypeAheadLoading: false,
            partyTypeAheadSelected: [],
            partyTypeAheadOptions: []
        }
        this.onPartyTypeAheadSearch = this.onPartyTypeAheadSearch.bind(this)
        this.onPartySelected = this.onPartySelected.bind(this)
        this.onRemoveParty = this.onRemoveParty.bind(this)
    }

    onPartyTypeAheadSearch(query) {
        this.setState({ isPartyTypeAheadLoading: true });
        this.props.searchParty(this.props.user.user.RegistrationID, this.props.user.store.key, query)
            .then((result) => {
                this.setState({ partyTypeAheadOptions: result.searchResult })
                this.setState({ isPartyTypeAheadLoading: false });
            })
    }

    onRemoveParty() {
        this.props.onPartyChange(null)
    }

    async onPartySelected(selected) {
        // console.log('selected[0]', selected[0])
        if(selected[0].PartyType.toLowerCase() ==="vendor")
        {
            //get vendor outstanding
            let vendor = await this.props.getVendorDetails(selected[0].DocKey)
            selected[0].DueAmount = vendor.hasOwnProperty('DueAmount') ? vendor.DueAmount : 0
        }
        this.props.onPartyChange(selected[0])
    }

    render() {
        return <div className="input-ctrl">
            {!this.props.party && <React.Fragment>
                        <label>Party</label>
                        <AsyncTypeahead
                            ref="asyncPartySearch"
                            autoFocus={true}
                            allowNew={false}
                            isLoading={this.state.isPartyTypeAheadLoading}
                            multiple={false}
                            options={this.state.partyTypeAheadOptions}
                            labelKey="PartyFirstName"
                            minLength={3}
                            // bsSize={'large'}
                            onSearch={this.onPartyTypeAheadSearch}
                            placeholder="Search party"
                            filterBy={['PartyFirstName', 'PartyLastName', 'PhoneNo', 'EmailID', 'PartyCode']}
                            dropup={false}
                            highlightOnlyResult={true}
                            selected={this.state.partyTypeAheadSelected}
                            onChange={(selected) => {
                                this.onPartySelected(selected)
                            }}
                            renderMenuItemChildren={(option, props) => (
                                <div>
                                    <div><b>{option.PartyFirstName} {option.PartyLastName}</b></div>
                                    <div>{option.PhoneNo}</div>
                                    <div>{option.EmailID}</div>
                                    <div>{option.PartyCode}</div>
                                </div>
                            )}
                        />
            </React.Fragment>}
            {this.props.party && <React.Fragment>
                <label>Party</label>
                <div className="input-group">
                    <button type="button"
                        className="btn btn-default btn-block btn-flat btn-md" style={{ padding: "0px",height:"34px"}}>
                        <div style={{ fontSize: "15px", marginLeft: "5px" }} className="pull-left">{this.props.party.PartyFirstName} {this.props.party.PartyLastName}  {this.props.party.PartyCode !== "" ? " (" + this.props.party.PartyCode + ")" : ""}</div><br></br>
                        {/* {this.props.party.PhoneNo !== "" && <div style={{ fontSize: "12px", marginLeft: "5px" }} className="pull-left"><i className="fa fa-phone"></i> {this.props.party.PhoneNo}</div>}
                        {this.props.party.EmailID !== "" && <div style={{ fontSize: "12px", marginRight: "5px" }} className="pull-right"><i className="fa fa-envelope"></i> {this.props.party.EmailID}</div>} */}
                    </button>
                    <div className="input-group-btn">
                        <button type="button" className="btn btn-default btn-md btn-flat"
                            onClick={() => this.onRemoveParty()}><i className="fa fa-times" ></i> </button>
                    </div>
                </div>
                {(this.props.party && this.props.party.DueAmount > 0 && !this.props.hideOutStanding) && <div><span className="label label-danger pull-right" style={{margin:"5px",marginBottom:"0px",fontSize:"14px"}}>Due Amount: {this.props.party.DueAmount}</span> </div>}
            </React.Fragment>}
        </div>
    }
}

export default PartySearch