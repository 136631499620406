import {storageRef} from '../firebase'

export function uploadFileToServer(fileTobeUploaded, storageFolder, fileName="")
{
    return (dispatch) => {
        // console.log('fileTobeUploaded', fileTobeUploaded)
        var fileNameAndPath = storageFolder + '/' + (fileName === "" ? fileTobeUploaded.name : fileName)
        //var fileNameAndPath = "images" + '/' + fileTobeUploaded.name
        //Math.floor(Math.random() * 10000) + "_" + 
        return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
            // console.log('uploadFileToServer - Uploaded a blob or file!', snapshot);
            // console.log('ref - fullPath path', snapshot.ref.fullPath)
            return snapshot.ref.getDownloadURL()
        });
    }
}

export function uploadImagesToServer(fileTobeUploaded, storageFolder, action)
{
    return (dispatch) => {
        const fileNameAndPath = storageFolder + '/' + Math.floor(Math.random() * 10000) + "_" + fileTobeUploaded.name
        return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
            console.log('uploadFileToServer - Uploaded a blob or file!', snapshot);
            console.log('ref - fullPath path', snapshot.ref.fullPath)
            return snapshot.ref.getDownloadURL()
        });
    }
}


// export function uploadFileToServer1(fileTobeUploaded, storageFolder, action)
// {
//     return (dispatch) => {
//         const fileNameAndPath = storageFolder + '/' + fileTobeUploaded.name
//         return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
//             console.log('uploadFileToServer - Uploaded a blob or file!');
//             return snapshot.ref.getDownloadURL()
//         });
//     }
// }


// export function uploadFileToServer(fileTobeUploaded, storageFolder, action)
// {
//     return (dispatch) => {
//         const fileNameAndPath = storageFolder + '/' + fileTobeUploaded.name
//         return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
//             console.log('uploadFileToServer - Uploaded a blob or file!');
//             return snapshot.ref.getDownloadURL().then(function(downloadURL) {
//                 console.log('File available at', downloadURL);
//                 dispatch({type: action,downloadURL})
//                 });
//         });
//     }
// }