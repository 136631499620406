import React, { Component } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Loader from '../Loader'

class PaymentModeWise extends Component {

    constructor() {
        super();
        this.state = {
            paymentModes: []
            , paymentModeData: []
            , searchStore: ""
            , searchCashRegister: ""
            , searchTransactionType: ""
            , searchTransactionFromDate: new Date().toISOString().split('T')[0]
            , searchTransactionToDate: new Date().toISOString().split('T')[0]
            , loading: true
        };
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.loadPaymentModeWiseData = this.loadPaymentModeWiseData.bind(this);
        this.loadPaymentModeDashboard = this.loadPaymentModeDashboard.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getPivotArray = this.getPivotArray.bind(this);
        this.arrayToHTMLTable = this.arrayToHTMLTable.bind(this);
        this.onExportClick = this.onExportClick.bind(this)
    };

    componentDidMount() {
        var date = this.props.addDays(-7);
        date = date.toISOString().split('T')[0]
        this.setState({ searchTransactionFromDate: date });

        this.loadPaymentModeDashboard(date, this.state.searchTransactionToDate)
    };

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    loadPaymentModeDashboard(searchTransactionFromDate, searchTransactionToDate) {
        this.loadPaymentModeWiseData(searchTransactionFromDate, searchTransactionToDate)
    }

    getPivotArray(dataArray, rowIndex, colIndex, dataIndex) {
        var result = {}, ret = [];
        var newCols = [];
        for (var i = 0; i < dataArray.length; i++) {
            if (!result[dataArray[i][rowIndex]]) {
                result[dataArray[i][rowIndex]] = {};
            }
            result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];

            //To get column names
            if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
                newCols.push(dataArray[i][colIndex]);
            }
        }

        newCols.sort();
        var item = [];

        //Add Header Row
        item.push('Transaction Date');
        item.push.apply(item, newCols);
        ret.push(item);

        //Add content 
        for (var key in result) {
            item = [];
            item.push(key);
            for (var i = 0; i < newCols.length; i++) {
                item.push(result[key][newCols[i]] || "-");
            }
            ret.push(item);
        }
        return ret;
    } 

    loadPaymentModeWiseData(searchTransactionFromDate, searchTransactionToDate) {
        this.props.getPaymentModeWiseData(this.props.user.user.RegistrationID, this.props.user.store.key
            , searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                let paymentModeData = this.getPivotArray(result.searchResult, 0, 1, 2);
                this.setState({ paymentModeData: paymentModeData , loading: false })
            });
    };

    arrayToHTMLTable(myArray) {
        var result = "<table class='table table-bordered'>";
        for (var i = 0; i < myArray.length; i++) {
            if (i == 0)
                result += "<thead><tr>";
            else
                result += "<tbody><tr>";
            for (var j = 0; j < myArray[i].length; j++) {
                if (i == 0)
                    result += "<th>" + myArray[i][j] + "</th>";
                else
                    result += "<td>" + myArray[i][j] + "</td>";
            }
            if (i == 0)
                result += "</tr></thead>";
            else
                result += "</tr></tbody>";
        }
        result += "</table>";
        return result;
    }

    onSearchClick() {
        this.setState({
            loading: true
        })

        this.loadPaymentModeWiseData(this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate);
    };

    async onExportClick() {
        this.props.getPaymentModeWiseData(this.props.user.user.RegistrationID, this.props.user.store.key
            , this.state.searchTransactionFromDate, this.state.searchTransactionToDate)
            .then(async (result) => {
                let paymentModeData = await this.getPivotArray(result.searchResult, 0, 1, 2);
                const csv = paymentModeData.map(row => row.map(item => (typeof item === 'string' && item.indexOf(',') >= 0) ? `"${item}"`: String(item)).join(',')).join('\n');
                // Format the CSV string
                const data = encodeURI('data:text/csv;charset=utf-8,' + csv);
                const link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', 'PaymentModeWise.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            });
    }

    onClearSearch() {
        var date = this.props.addDays(-7);
        date = date.toISOString().split('T')[0]
        this.setState({
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: date,
            loading:true
        })
        this.loadPaymentModeWiseData(date, new Date().toISOString().split('T')[0])
    };

    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px", marginBottom: "4px" }}>Payment Mode Wise Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"                                            
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                                <div className="row" style={{padding:"10px"}}>
                                    <div className="col-md-4">
                                        <input type="date" className="form-control" 
                                            style={{ marginTop: "25px"}}
                                            onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                            value={this.state.searchTransactionFromDate} onKeyPress={this.handleKeyPress} />
                                    </div>
                                    <div className="col-md-4">
                                        <input type="date" className="form-control" 
                                            style={{ marginTop: "25px"}}
                                            onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                            value={this.state.searchTransactionToDate} onKeyPress={this.handleKeyPress} />
                                    </div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch}
                                        >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        >Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                        {this.state.loading && <Loader show={this.state.loading} />}
                                        {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                            {ReactHtmlParser(this.arrayToHTMLTable(this.state.paymentModeData))}
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default PaymentModeWise;