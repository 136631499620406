import React, { Component } from 'react'
import * as actionTypes from '../../actionTypes'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'

class BarcodePrintSettings extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            storeID: "",
            storeName: "",
            storeCode: "",
            printFieldError: "",
            leftMargin: 0,
            topMargin: 0,
            defaultPrinterLeftMargin: 0,
            defaultPrinterTopMargin: 0,
            noOfColumns: 0,
            noOfRows: 0,
            marginBetweenField: 0,
            labelWidth: 0,
            labelHeight: 0,
            leftMarginInsideLabel: 0,
            topMarginInsideLabel: 0,
            horizontalSpace: 0,
            verticalSpace: 0,
            noOfCharacter: 0,
            barcodeHeight: 0,
            barWidthScale: 1,
            fieldPositioning: 1,
            barcodeXPosition: 0,
            barcodeYPosition: 0,
            barcodeWidth: 0,
            printFields: [
                { FieldSelected: true, FieldName: 'SKU', FieldDisplayName: 'SKU', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Name', FieldDisplayName: 'Name', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Barcode', FieldDisplayName: 'Barcode', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Retail Price', FieldDisplayName: 'Retail Price', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Supply Price', FieldDisplayName: 'Supply Price', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'MRP', FieldDisplayName: 'MRP', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Category', FieldDisplayName: 'Category', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Sub Category', FieldDisplayName: 'Sub category', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Brand', FieldDisplayName: 'Brand', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Vendor Code', FieldDisplayName: 'Vendor Code', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Vendor Name', FieldDisplayName: 'Vendor Name', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Store Code', FieldDisplayName: 'Store Code', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Store Name', FieldDisplayName: 'Store Name', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Transaction No', FieldDisplayName: 'Transaction No', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Transaction Date', FieldDisplayName: 'Transaction Date', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 1', FieldDisplayName: 'Place Holder 1', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 2', FieldDisplayName: 'Place Holder 2', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 3', FieldDisplayName: 'Place Holder 3', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 4', FieldDisplayName: 'Place Holder 4', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 5', FieldDisplayName: 'Place Holder 5', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 6', FieldDisplayName: 'Place Holder 6', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 7', FieldDisplayName: 'Place Holder 7', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
                { FieldSelected: false, FieldName: 'Place Holder 8', FieldDisplayName: 'Place Holder 8', FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 },
            ],
            barcodeValue: "SKU",

        }
        this.onFieldDisplayNameChange = this.onFieldDisplayNameChange.bind(this)
        this.onFieldFontStyleChange = this.onFieldFontStyleChange.bind(this)
        this.onFieldFontSizeChange = this.onFieldFontSizeChange.bind(this)
        this.onFieldPositionChange = this.onFieldPositionChange.bind(this)
        this.onFieldSequenceChange = this.onFieldSequenceChange.bind(this)
        this.onXPositionChange = this.onXPositionChange.bind(this)
        this.onYPositionChange = this.onYPositionChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
    }

    async componentDidMount() {

        this.setState({
            storeID: this.props.location.state.storeID,
            storeName: this.props.location.state.storeName,
            storeCode: this.props.location.state.storeCode
        })
        //get barcode settings if it exits 
        let barcodeSetting = await this.props.getBarcodeSettingDetails(this.props.location.state.storeID)
        if (barcodeSetting) {
          
            let printFields = barcodeSetting.PrintFields;
            // console.log("printFields", JSON.stringify(printFields));

            this.props.config.CustomFields.map((customField) => {

                if (customField.ModuleName === 'Product') {

                    let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                    let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                    let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                    let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                    let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                    let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()


                    if (printFields.filter((e) => e.FieldName === 'CustomField1').length === 0)
                        printFields.push({ FieldSelected: false, FieldName: 'CustomField1', FieldDisplayName: customField1DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                    if (printFields.filter((e) => e.FieldName === 'CustomField2').length === 0)
                        printFields.push({ FieldSelected: false, FieldName: 'CustomField2', FieldDisplayName: customField2DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                    if (printFields.filter((e) => e.FieldName === 'CustomField3').length === 0)
                        printFields.push({ FieldSelected: false, FieldName: 'CustomField3', FieldDisplayName: customField3DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                    if (printFields.filter((e) => e.FieldName === 'CustomField4').length === 0)
                        printFields.push({ FieldSelected: false, FieldName: 'CustomField4', FieldDisplayName: customField4DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                    if (printFields.filter((e) => e.FieldName === 'CustomField5').length === 0)
                        printFields.push({ FieldSelected: false, FieldName: 'CustomField5', FieldDisplayName: customField5DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                    if (printFields.filter((e) => e.FieldName === 'CustomField6').length === 0)
                        printFields.push({ FieldSelected: false, FieldName: 'CustomField6', FieldDisplayName: customField6DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                }
            })
            this.setState({
                leftMargin: barcodeSetting.LeftMargin,
                topMargin: barcodeSetting.TopMargin,
                defaultPrinterLeftMargin: barcodeSetting.DefaultPrinterLeftMargin,
                defaultPrinterTopMargin: barcodeSetting.DefaultPrinterTopMargin,
                noOfColumns: barcodeSetting.NoOfColumns,
                noOfRows: barcodeSetting.NoOfRows,
                marginBetweenField: barcodeSetting.MarginBetweenField,
                labelWidth: barcodeSetting.LabelWidth,
                labelHeight: barcodeSetting.LabelHeight,
                leftMarginInsideLabel: barcodeSetting.LeftMarginInsideLabel,
                topMarginInsideLabel: barcodeSetting.TopMarginInsideLabel,
                horizontalSpace: barcodeSetting.HorizontalSpace,
                verticalSpace: barcodeSetting.VerticalSpace,
                noOfCharacter: barcodeSetting.NoOfCharacter,
                barcodeHeight: barcodeSetting.BarcodeHeight,
                barWidthScale: barcodeSetting.BarWidthScale,
                fieldPositioning: barcodeSetting.hasOwnProperty('FieldPositioning') ? barcodeSetting.FieldPositioning : 1,
                barcodeXPosition: barcodeSetting.hasOwnProperty('BarcodeXPosition') ? barcodeSetting.BarcodeXPosition : 0,
                barcodeYPosition: barcodeSetting.hasOwnProperty('BarcodeYPosition') ? barcodeSetting.BarcodeYPosition : 0,
                barcodeWidth: barcodeSetting.hasOwnProperty('BarcodeWidth') ? barcodeSetting.BarcodeWidth : 0,
                barcodeValue: barcodeSetting.hasOwnProperty('BarcodeValue') ? barcodeSetting.BarcodeValue : "SKU",
                printFields: printFields
            })
        } else {
            this.props.config.CustomFields.map((customField) => {
                if (customField.ModuleName === 'Product') {
                    let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                    let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                    let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                    let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                    let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                    let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()

                    let printFields = this.state.printFields;

                    printFields.push({ FieldSelected: false, FieldName: "CustomField1", FieldDisplayName: customField1DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });
                    printFields.push({ FieldSelected: false, FieldName: "CustomField2", FieldDisplayName: customField2DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });
                    printFields.push({ FieldSelected: false, FieldName: "CustomField3", FieldDisplayName: customField3DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });
                    printFields.push({ FieldSelected: false, FieldName: "CustomField4", FieldDisplayName: customField4DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });
                    printFields.push({ FieldSelected: false, FieldName: "CustomField5", FieldDisplayName: customField5DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });
                    printFields.push({ FieldSelected: false, FieldName: "CustomField6", FieldDisplayName: customField6DisplayName, FieldFontStyle: '', FieldFontSize: 0, FieldPosition: '', FieldSequence: 0, xPosition: 0, yPosition:0 });

                    this.setState({ printFields: printFields });
                }
            })
        }
    }

    onFieldDisplayNameChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].FieldDisplayName = value
        this.setState({ printFields: printFields })
    }

    onFieldFontStyleChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].FieldFontStyle = value
        this.setState({ printFields: printFields })
    }

    onFieldFontSizeChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].FieldFontSize = value
        this.setState({ printFields: printFields })
    }

    onFieldPositionChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].FieldPosition = value
        this.setState({ printFields: printFields })
    }

    onFieldSequenceChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].FieldSequence = value
        this.setState({ printFields: printFields })
    }

    onXPositionChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].xPosition = value
        this.setState({ printFields: printFields })
    }
    
    onYPositionChange(index, value) {
        let printFields = this.state.printFields
        printFields[index].yPosition = value
        this.setState({ printFields: printFields })
    }

    onSave() {

        this.setState({ saving: true })
        //check if any fields is selected 
        let printFieldError = ""
        if (this.state.printFields.filter((f) => f.FieldSelected).length === 0) {
            printFieldError = "Please select at least one display field"
        }
        if (printFieldError.trim() !== "") {
            this.setState({ printFieldError: printFieldError, saving: false })
            alert("Please select at least one display field")
            return
        }
        let barcodeSetting = {
            LeftMargin: this.state.leftMargin,
            TopMargin: this.state.topMargin,
            DefaultPrinterLeftMargin: this.state.defaultPrinterLeftMargin,
            DefaultPrinterTopMargin: this.state.defaultPrinterTopMargin,
            NoOfColumns: this.state.noOfColumns,
            NoOfRows: this.state.noOfRows,
            MarginBetweenField: this.state.marginBetweenField,
            LabelWidth: this.state.labelWidth,
            LabelHeight: this.state.labelHeight,
            LeftMarginInsideLabel: this.state.leftMarginInsideLabel,
            TopMarginInsideLabel: this.state.topMarginInsideLabel,
            HorizontalSpace: this.state.horizontalSpace,
            VerticalSpace: this.state.verticalSpace,
            NoOfCharacter: this.state.noOfCharacter,
            BarcodeHeight: this.state.barcodeHeight,
            BarWidthScale: this.state.barWidthScale,
            FieldPositioning: this.state.fieldPositioning,
            BarcodeXPosition: this.state.barcodeXPosition,
            BarcodeYPosition: this.state.barcodeYPosition,
            BarcodeWidth: this.state.barcodeWidth,
            BarcodeValue: this.state.barcodeValue,
            PrintFields: [],
        }
        this.state.printFields.map(m => {
            barcodeSetting.PrintFields.push(m)
        })
        // console.log('barcodeSetting', barcodeSetting)
        // return 
        this.props.saveBarcodeSetting(barcodeSetting, this.state.storeID).then((() => {
            this.props.getToastr("Barcode setting saved successfully")
            this.setState({ saving: false })
            this.onCancelClick()
        }))
    }

    onCancelClick() {
        // this.props.history.push({
        //     pathname: "/home/settings/stores/viewstoredetails",
        //     state: { key: this.state.storeID, action: actionTypes.STORE_EDIT }
        // })
        if(this.props.isMobileApp() )
        {
            this.props.history.push({pathname: "/home/settings/stores"})    
        }
        else
        {
            this.props.history.push({
                pathname: "/home/settings/stores/viewstoredetails/" + this.state.storeID
                // ,
                // state: { key: this.state.storeID, action: actionTypes.STORE_EDIT }
            })
        }
    }

    formControls()
    {
        return <React.Fragment>
            <div className="row">
                    <div className="col-md-6">
                        <div className={this.props.isMobileApp() ? "box  no-border" : "box"}>
                            <div className={this.props.isMobileApp() ? "box-header" : "box-header with-border"}>
                                <h3 className="box-title">Page setting</h3>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Left margin (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ leftMargin: Number(evt.target.value) })}
                                                value={this.state.leftMargin}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Top margin (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ topMargin: Number(evt.target.value) })}
                                                value={this.state.topMargin}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Default printer left margin (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ defaultPrinterLeftMargin: Number(evt.target.value) })}
                                                value={this.state.defaultPrinterLeftMargin}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Default printer top margin (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ defaultPrinterTopMargin: Number(evt.target.value) })}
                                                value={this.state.defaultPrinterTopMargin}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >No of columns</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ noOfColumns: Number(evt.target.value) })}
                                                value={this.state.noOfColumns}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >No of rows</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ noOfRows: Number(evt.target.value) })}
                                                value={this.state.noOfRows}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Margin between field (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ marginBetweenField: Number(evt.target.value) })}
                                                value={this.state.marginBetweenField}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={this.props.isMobileApp() ? "box  no-border" : "box"}>
                            <div className={this.props.isMobileApp() ? "box-header" : "box-header with-border"}>
                                <h3 className="box-title">Label setting</h3>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Label width (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ labelWidth: Number(evt.target.value) })}
                                                value={this.state.labelWidth}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Label height (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ labelHeight: Number(evt.target.value) })}
                                                value={this.state.labelHeight}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Left margin inside label (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ leftMarginInsideLabel: Number(evt.target.value) })}
                                                value={this.state.leftMarginInsideLabel}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Top margin inside label (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ topMarginInsideLabel: Number(evt.target.value) })}
                                                value={this.state.topMarginInsideLabel}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Horizontal space (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ horizontalSpace: Number(evt.target.value) })}
                                                value={this.state.horizontalSpace}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Vertical space (mm)</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ verticalSpace: Number(evt.target.value) })}
                                                value={this.state.verticalSpace}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >No of character</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ noOfCharacter: Number(evt.target.value) })}
                                                value={this.state.noOfCharacter}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Barcode height</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ barcodeHeight: Number(evt.target.value) })}
                                                value={this.state.barcodeHeight}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Bar width scale</label>
                                            <select className="form-control"
                                                value={this.state.barWidthScale}
                                                onChange={e => {
                                                    this.setState({ barWidthScale: e.target.value })
                                                }}>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Label Positioning</label>
                                            <select className="form-control"
                                                value={this.state.fieldPositioning}
                                                onChange={e => {
                                                    this.setState({ fieldPositioning: Number(e.target.value) })
                                                }}>
                                                <option value="1">Auto</option>
                                                <option value="2">Manual</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.state.fieldPositioning === 2 && <React.Fragment>
                                        <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Barcode XPosition</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ barcodeXPosition: Number(evt.target.value) })}
                                                value={this.state.barcodeXPosition}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Barcode YPosition</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ barcodeYPosition: Number(evt.target.value) })}
                                                value={this.state.barcodeYPosition}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Barcode Width</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.setState({ barcodeWidth: Number(evt.target.value) })}
                                                value={this.state.barcodeWidth}
                                            />
                                        </div>
                                    </div>
                                    </React.Fragment>}
                                    <div className="col-md-6">
                                        <div className="form-group " >
                                            <label >Barcode Value</label>
                                            <select className="form-control input-md"
                                                onChange={(evt) => this.setState({ barcodeValue: evt.target.value })}
                                                value={this.state.barcodeValue}>
                                                <option value="SKU">SKU</option>
                                                <option value="Barcode">Barcode</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.props.isMobileApp() ? "box  no-border" : "box"}>
                    <div className={this.props.isMobileApp() ? "box-header" : "box-header with-border"}>
                        <h3 className="box-title">Fields to be printed</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped" style={{ width: "800px"}}>
                                        <thead>
                                            <tr>
                                                <th>
                                                </th>
                                                <th>Field Name</th>
                                                <th>Display Name</th>
                                                <th>Font Style</th>
                                                <th style={{width:"120px"}}>Font Size (mm)</th>
                                                {this.state.fieldPositioning === 1 && <React.Fragment>
                                                <th>Position</th>
                                                <th style={{width:"50px"}}>Sequence</th></React.Fragment>}
                                                {this.state.fieldPositioning === 2 && <React.Fragment> <th style={{width: "40px"}}>xPosition</th>
                                                <th style={{width: "40px"}}>yPosition</th> </React.Fragment>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.printFields.map((printField, index) =>
                                                <tr>
                                                    <td>
                                                        <input type="checkbox"
                                                            checked={printField.FieldSelected}
                                                            onChange={() => {
                                                                let printFields = this.state.printFields
                                                                printFields[index].FieldSelected = !printFields[index].FieldSelected
                                                                printFields[index].FieldPosition = "Above barcode"
                                                                this.setState({ printFields: printFields })
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{printField.FieldName}</td>
                                                    <td>
                                                        <input type="text"
                                                            className="form-control"
                                                            onChange={(evt) => this.onFieldDisplayNameChange(index, evt.target.value)}
                                                            value={printField.FieldDisplayName}
                                                            disabled={!printField.FieldSelected}
                                                        />
                                                    </td>
                                                    <td>
                                                        <select className="form-control input-md"
                                                            onChange={(evt) => this.onFieldFontStyleChange(index, evt.target.value)}
                                                            value={printField.FieldFontStyle}
                                                            disabled={!printField.FieldSelected}>
                                                            <option value=""></option>
                                                            <option value="Normal">Normal</option>
                                                            <option value="Bold">Bold</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="number"
                                                            className="form-control"
                                                            onChange={(evt) => this.onFieldFontSizeChange(index, evt.target.value)}
                                                            value={printField.FieldFontSize}
                                                            disabled={!printField.FieldSelected}
                                                        />
                                                    </td>
                                                    {this.state.fieldPositioning === 1 && <React.Fragment><td>
                                                        <select className="form-control input-md"
                                                            onChange={(evt) => this.onFieldPositionChange(index, evt.target.value)}
                                                            value={printField.FieldPosition}
                                                            disabled={!printField.FieldSelected}>
                                                            <option value="Above barcode">Above barcode</option>
                                                            <option value="Below barcode">Below barcode</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input type="number"
                                                            className="form-control"
                                                            onChange={(evt) => this.onFieldSequenceChange(index, evt.target.value)}
                                                            value={printField.FieldSequence}
                                                            disabled={!printField.FieldSelected}
                                                        />
                                                    </td>
                                                    </React.Fragment>}
                                                    {this.state.fieldPositioning === 2 && <React.Fragment><td>
                                                        <input type="number"
                                                            className="form-control"
                                                            onChange={(evt) => this.onXPositionChange(index, evt.target.value)}
                                                            value={printField.xPosition}
                                                            disabled={!printField.FieldSelected}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input type="number"
                                                            className="form-control"
                                                            onChange={(evt) => this.onYPositionChange(index, evt.target.value)}
                                                            value={printField.yPosition}
                                                            disabled={!printField.FieldSelected}
                                                        />
                                                    </td></React.Fragment>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    }

    render() {
        return <React.Fragment>
        
        {!this.props.isMobileApp() && <div className="content-wrapper">
            <section className="content-header">
                <h1>Barcode settings - {this.state.storeName}</h1>
                <button type="submit"
                    disable={this.state.saving}
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-md btn-success btn-flat pull-right"
                    onClick={this.onSave}>Save</button>

                <button style={{ marginTop: "-25px", marginLeft: "10px" }}
                    onClick={this.onCancelClick}
                    className="btn btn-md btn-default btn-flat pull-right">Cancel</button>
            </section>
            <section className="content" >
                {this.formControls()}
            </section>
        </div>}


        {this.props.isMobileApp() &&  <React.Fragment>   

            <HeaderNew {...this.props}
                back={true}
                title= "Barcode Settings"
                onBackClick={() => {
                    this.props.history.push({pathname: "/home/settings/stores"})    
                }
            }
            />
            <div className="header-new-background"/>
            <div className="content-wrapper">
                <div className="container">
                    <br/>

                    <div className="form-group">
                        <label>Store Code</label>
                        <input type="text" className="form-control"
                            value={this.state.storeCode}
                        />
                    </div>

                    <div className="form-group">
                        <label>Store Name</label>
                        <input type="text" className="form-control"
                                value={this.state.storeName}
                            />
                    </div>

                    {this.formControls()}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Footer onClick={()=> this.onSave()} />
                </div>
            </div>
        </React.Fragment>}

        </React.Fragment>
    }
}

export default BarcodePrintSettings