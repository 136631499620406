import React, { Component } from 'react';
import Icon from '../../2/Controls/Icon';

class WidgetTotalReceived extends Component {

    constructor({ stores, fromDate, toDate, widget }) {
        super();
        this.state = {
            total_received: 0,
            stores: stores,
            fromDate: fromDate,
            toDate: toDate,
            widget: widget
        }
    }

    async componentDidMount() {
        this.loadData(this.state.stores, this.state.fromDate, this.state.toDate, this.state.widget)
    }

    loadData(stores, fromDate, toDate) {
        this.props.getTotalPaymentReceived(this.props.user.user.RegistrationID, stores, toDate,  fromDate)
            .then((result) => {
                //console.log("total_received", JSON.stringify(result));
                this.setState({ total_received: result.total_received })
            });
    }

    componentWillReceiveProps({ stores, fromDate, toDate, widget }) {
        this.loadData(stores, fromDate, toDate)
    }

    render(){
        return <div className="info-box info-green" >
        <div className="info-box-icon ">
            <Icon type="expense"/>
        </div>
        <div className="info-box-content">
            <div className="info-box-text" >{this.state.widget && this.state.widget.hasOwnProperty('Title') && this.state.widget.Title.trim()!=="" ? this.state.widget.Title : "Credit Received"}</div>
            <div className="info-box-number">{Number(this.state.total_received).toFixed(0)}</div>
        </div>
    </div>
    }
    // render() {
    //     return <div className="col-md-3 col-sm-6 col-xs-12">
    //         <div className="info-box" style={{ marginTop: "10px" }}>
    //             <span className="info-box-icon " style={{backgroundColor:this.state.widget && this.state.widget.hasOwnProperty('WidgetOptionLeftBlockColor') && this.state.widget.WidgetOptionLeftBlockColor.trim()!=="" ? this.state.widget.WidgetOptionLeftBlockColor : "#00a65a"}}>
    //             <i style={{color:"white"}} className={this.state.widget && this.state.widget.hasOwnProperty('WidgetOptionLeftBlockIcon') && this.state.widget.WidgetOptionLeftBlockIcon.trim()!=="" ? this.state.widget.WidgetOptionLeftBlockIcon : "fa fa-money"} ></i></span>
    //             <div className="info-box-content">
    //                 <span className="info-box-text" style={{ fontSize: "13px" }}>{this.state.widget && this.state.widget.hasOwnProperty('Title') && this.state.widget.Title.trim()!=="" ? this.state.widget.Title : "Credit Received"}</span>
    //                 <span className="info-box-number">{Number(this.state.total_received).toFixed(0)}</span>
    //             </div>
    //         </div>
    //     </div>
    // }
}

export default WidgetTotalReceived