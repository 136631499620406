import firebase from "../../firebase";

export async function getCustomerData( searchText, filters, sort,recordFrom, itemsCountPerPage, api, exportData, props, stores, listPageSettings){

    console.log("listPageSettings",listPageSettings)
        let scrollData = false;
        let scrollID = "";
        let itemsCountPerPageFinal = itemsCountPerPage;
        if(exportData && itemsCountPerPage> 10000){
            //get incremetal data
            scrollData= true;
            itemsCountPerPageFinal = 2000;
        }

        let searchParameters = {
            "from": recordFrom,
            "size": itemsCountPerPageFinal,
            "track_total_hits": true,
            "query": {
              "bool": {
                "must": [
                ],
                "must_not": [
                ],
                "should": [],
                "filter": {
                  "bool": {
                    "must": [],
                    "should":[]
                  }
                }
              }
            }
            ,
            "sort": []
        }
        
        filters.map(f=>{
                if(f.hasOwnProperty('GeneralSearch') && f.GeneralSearch && searchText.trim()!==""){
                    if(f.hasOwnProperty('Type') && f.QueryType==="match_phrase"){
                        let q =  '{"match_phrase" : {"' + f.Column + '" : "' + searchText.trim().toLowerCase() + '" }}'
                        searchParameters.query.bool.filter.bool.should.push(JSON.parse(q))
                    }
                    else
                    {
                        let q =  '{"regexp" : {"' + f.Column + '" : "@' + searchText.trim().toLowerCase() + '.*" }}'
                        searchParameters.query.bool.filter.bool.should.push(JSON.parse(q))
                    }
                }
        })
        for(let i=0;i<filters.length;i++){
            let f = filters[i];
            if(f.Column ==="ParentProductID" && exportData){
                continue;
            }
            if(f.Type==="status"){
                if (f.Value === "active") {
                    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
                }
                if (f.Value === "inactive") {
                    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
                }
              }
            else if(f.Type==="approvalstatus"){
                if (f.Value !== "all") {
                    let para = '{"match": {"' +  f.Column + '":"' + f.Value + '" }}'
                    searchParameters.query.bool.must.push( JSON.parse(para) )
                }   
              }
            else if(f.Type ==="daterange"){
                if(f.Value.ToDate !=="" &&   f.Value.FromDate !==""){
                    let para = '{"range": {' + '"' + f.Column + '":{"lte":"' + f.Value.ToDate + '","gte":"' + f.Value.FromDate + '","format": "yyyy-MM-dd"}}}';
                    searchParameters.query.bool.must.push(JSON.parse(para));
                }
            }
            else if(f.Type ==="registrationid"){
                searchParameters.query.bool.must.push({
                    "match": {"RegistrationID": props.registration.registration.key}
                })
            }
            else if(f.Type ==="storeID"){
                searchParameters.query.bool.must.push({
                    "match": {"StoreID": props.user.store.key}
                })
            }
            else if(f.Type ==="stores"){
                if(f.Value && f.Value!=="" && f.Column  && f.Column !=="" ){
                    //specific store
                    if(f.Column.toLowerCase() ==="store.key"){
                        let q =  '{"regexp" : {"' + f.Column + '" : "@' + f.Value.trim().toLowerCase() + '.*" }}'
                        searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
                    }
                    else
                    {
                        let q = '{"term": {"' + f.Column + '":"' +  f.Value + '"}}'
                        searchParameters.query.bool.should.push(JSON.parse(q));
                        if(api==="getInventory"){
                            console.log('invenotry listpage');
                            let l = '{"term": {"ToStoreID.keyword":"' +  f.Value + '"}}'
                            searchParameters.query.bool.should.push(JSON.parse(l));
                        }
                    }
                }
                else 
                {
                    if(this.state.stores.length < 50){
                        for (let i = 0; i < this.state.stores.length; i++) {
                            const store = this.state.stores[i];
                            if(f.Column.toLowerCase() ==="store.key"){
                                let q =  '{"regexp" : {"' + f.Column + '" : "@' + store.key.trim().toLowerCase() + '.*" }}'
                                searchParameters.query.bool.filter.bool.should.push(JSON.parse(q));
                            }
                            else
                            {
                                let q = '{"term": {"' + f.Column + '":"' +  store.key + '"}}'
                                searchParameters.query.bool.should.push(JSON.parse(q));
                            }
                            
                        }
                    }
                    
                }
            }
            else{
                if(f.hasOwnProperty('Value') && f.Value!==""){
                    let queryType = "regexp";
                    if(f.hasOwnProperty('QueryType') && f.QueryType ==="term"){
                        queryType = "term";
                        let q =  '{"' + queryType + '" : {"' + f.Column + '" : "' + f.Value.trim().toLowerCase() + '" }}'
                        searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
                    }
                    else if(f.hasOwnProperty('QueryType') && f.QueryType ==="must_not"){
                        searchParameters.query.bool.must_not.push(f.Value)
                       
                    }
                    else if(f.hasOwnProperty('QueryType') && f.QueryType ==="must" ){
                        if(f.Column!==""){
                            queryType = f.QueryType;
                            let q =  '{"match" : {"' + f.Column + '" : "' + f.Value.trim().toLowerCase() + '" }}'
                            searchParameters.query.bool.must.push(JSON.parse(q));
                        }
                        else{
                            console.log(f)
                            searchParameters.query.bool.must.push(f.Value)
                        }
                    }
                    else if(f.hasOwnProperty('QueryType') && f.QueryType !=="" && f.Column!==""){
                        queryType = f.QueryType;
                        let q =  '{"' + queryType + '" : {"' + f.Column + '" : "@' + f.Value.trim().toLowerCase() + '.*" }}'
                        searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
                    }
                    else if( f.Column !=="" && f.Value !=="")
                    {
                        let q =  '{"' + queryType + '" : {"' + f.Column + '" : "@' + f.Value.trim().toLowerCase() + '.*" }}'
                        searchParameters.query.bool.filter.bool.must.push(JSON.parse(q));
                    }
                } 
            }
        }

        if (props.type==="customer" && props.user.user.Role !== "Power User") {
            searchParameters.query.bool.must.push({
              "match": {
                "StoreID": props.user.store.key
              }
            })
          }

        if(sort && sort.length > 0){
            sort.map(s=>{
                let q= '{"'+ s.Column + '":{"order":"'+ s.Order + '"}}'
                searchParameters.sort.push(JSON.parse(q))
            })
        }

        if(exportData){
            let exportColumns =[];
            listPageSettings.ExportColumns.map(e=>{
                exportColumns.push(e.Column)
            })
            if(exportColumns.length >  0 ){
                searchParameters["_source"] = exportColumns;
            }
        }
        if(searchParameters.query.bool.should.length> 0){
            searchParameters.query.bool["minimum_should_match"] = 1;
        }
        // console.log('searchParameters',JSON.stringify(searchParameters));
        let finalAPI = api;
        let finalAPIMessage = { text: searchParameters };
        if(scrollData){
            finalAPI= "scrollData";
            finalAPIMessage.collection = listPageSettings.Collection;
            finalAPIMessage.scrollID=""
        }
        console.log('finalAPI',finalAPI);
        console.log("finalAPIMessage",JSON.stringify(finalAPIMessage) );
        let addMessage = firebase.functions().httpsCallable(finalAPI);
        let result = await addMessage(finalAPIMessage);
        const list = []
        let sanitizedMessage = result.data.text;
        let total = sanitizedMessage.hits.total.value;
        let customerIDs =[];
        sanitizedMessage.hits.hits.map(async (data) => {
            list.push(dataTransformation(data, props, stores));
            customerIDs.push(data._id);
        })
        if(customerIDs.length > 0){
            let customerWiseAmountSpentAndNoOfVisits = await getCustomerWiseAmountSpentAndNoOfVisits(customerIDs);
            if(customerWiseAmountSpentAndNoOfVisits.length>0){
                for(let i=0;i<list.length;i++){
                    if(customerWiseAmountSpentAndNoOfVisits.filter(f=> f.key === list[i].key).length>0){
                        console.log("list[i]",list[i]);
                        list[i].AmountSpent = customerWiseAmountSpentAndNoOfVisits.filter(f=> f.key === list[i].key)[0].AmountSpent;
                        list[i].NoOfVisits = customerWiseAmountSpentAndNoOfVisits.filter(f=> f.key === list[i].key)[0].NoOfVisits;
                    }
                }
            }
        }
        if(scrollData){
            scrollID = sanitizedMessage._scroll_id
            searchParameters = {
                "scroll": "1m",
                "scroll_id": scrollID
              }
            finalAPIMessage.text =searchParameters;
            finalAPIMessage.scrollID = scrollID;
            for (let index = 1; index < (total / 2000); index++) {
                let addMessage = firebase.functions().httpsCallable(finalAPI);
                let loopResult = await addMessage(finalAPIMessage);
                customerIDs =[];
                loopResult.data.text.hits.hits.map((data, index) => {
                        list.push(dataTransformation(data,props, stores));
                        customerIDs.push(data._id);
                })
                if(customerIDs.length > 0){
                    let customerWiseAmountSpentAndNoOfVisits = await getCustomerWiseAmountSpentAndNoOfVisits(customerIDs);
                    if(customerWiseAmountSpentAndNoOfVisits.length>0){
                        for(let i=0;i<list.length;i++){
                            if(customerWiseAmountSpentAndNoOfVisits.filter(f=> f.key === list[i].key).length>0){
                                list[i].AmountSpent = customerWiseAmountSpentAndNoOfVisits.filter(f=> f.key === list[i].key)[0].AmountSpent;
                                list[i].NoOfVisits = customerWiseAmountSpentAndNoOfVisits.filter(f=> f.key === list[i].key)[0].NoOfVisits;
                            }
                        }
                    }
                }
            }
        }
        return {list:list, total:total}

}

export async function getCustomerWiseAmountSpentAndNoOfVisits(customers){

    let searchParameters ={
        "size": 0,
        "query": {
          "bool": {
            "must": [
              {
                "terms": {
                  "Customer.key.keyword": customers
                }
              }
            ],
            "must_not": [
              {
                "exists": {"field": "CancellationReason"}
              }
            ]
          }
        },
        "aggs": {
          "customer": {
            "terms": {
              "field": "Customer.key.keyword","size": 10000
            },
            "aggs": {
              "total_spent": {
                "sum": {
                  "field": "BillAmount"
                }
              }
            }
          }
        }
    }
    console.log("getCustomerWiseAmountSpentAndNoOfVisits",JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
        let customerWiseAmountSpentAndNoOfVisits = []
        let sanitizedMessage = result.data.text;
        console.log("sanitizedMessage",sanitizedMessage)
        sanitizedMessage.aggregations.customer.buckets.map((data, index) => {
            if (data.key !== "") {
                customerWiseAmountSpentAndNoOfVisits.push({
                    key: data.key,
                    AmountSpent: Number(data.total_spent.value).toFixed(2),
                    NoOfVisits: data.doc_count
                })
            }
        })
        console.log("customerWiseAmountSpentAndNoOfVisits",customerWiseAmountSpentAndNoOfVisits)
        return customerWiseAmountSpentAndNoOfVisits;
    });
}

export function dataTransformation(record, props, stores){
    let data = { key: record._id, ...record._source };
    // console.log('data',data)
    if(props.type=="product"){
        
        data.Image = data.Images && data.Images.length >  0 ?    data.Images[0] : null;
        
        if (data.hasOwnProperty('Variants') &&  data.Variants.length > 0) {
            for (let i = 0; i < data.Variants.length; i++) {
                const variant = data.Variants[i];
                if (!variant.hasOwnProperty('IsDeleted')) {
                    variant.IsDeleted = false
                }
            }
        }

        if (data.hasOwnProperty('AccessibleToSelectedStores')) {
            if (data.AccessibleToSelectedStores) {
                let storePrice = data.StoreSpecificPrices.filter(f => f.StoreID === props.user.store.key)
                if (storePrice.length > 0) {
                    data.RetailPrice = storePrice[0].RetailPrice
                    data.SupplyPrice = storePrice[0].SupplyPrice
                    data.Markup = storePrice[0].Markup
                    data.MRP = storePrice[0].MRP
                    data.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                    data.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                    data.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                    data.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                    data.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                }
            }
        }
        if(data.hasOwnProperty('StoreSpecificPricing')){
            if(data.StoreSpecificPricing)
            {
                let storePrice = data.StoreSpecificPrices.filter(f=> f.StoreID === props.user.store.key)
                
                if(storePrice.length> 0)
                {
                    data.SupplyPrice = storePrice[0].SupplyPrice
                    data.Markup = storePrice[0].Markup
                    data.RetailPrice = storePrice[0].RetailPrice
                    data.MRP = storePrice[0].MRP
                    data.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount:0
                    data.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage:0
                    data.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                    data.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount')? storePrice[0].WalletTopUpAmount : 0
                    data.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount: false
                }
            }
        }

        data.UOM = data.hasOwnProperty('UOM') ? data.UOM: ''
        data.SecondaryUOM = (data.hasOwnProperty('SecondaryUOMs') && data.SecondaryUOMs.length > 0) ? data.SecondaryUOMs[0].SecondaryUOMValue:  ''
        data.UOMToSecondaryUOMConversion = (data.hasOwnProperty('SecondaryUOMs') && data.SecondaryUOMs.length > 0) ? data.SecondaryUOMs[0].Conversion : ''
        data.SalesUOM = data.hasOwnProperty('SalesUOM') ? data.SalesUOM: ''
        data.PurchaseUOM = data.hasOwnProperty('PurchaseUOM') ? data.PurchaseUOM: ''


    }
    if(props.type==="customer"){
        data.Store = stores.filter((s) => s.key === data.StoreID).length > 0 ? stores.filter((s) => s.key === data.StoreID)[0].StoreName : ''
    }
    if(props.type==="saleshistory"){
        data.CustomerName = data.Customer ? (data.Customer.FirstName + " "  + data.Customer.LastName) : "";
        if (data.CancellationReason) {
            data.Cancelled = "Yes"
        }else
        {
            data.Cancelled = "No"
        }
        if(data.hasOwnProperty('ReceivedPaymentTransactions') && data.ReceivedPaymentTransactions.length > 0){
            // console.log(data);

            let balanceAmount = Number(data.BalanceAmount);
            let receivedAount = 0;
            
            data.ReceivedPaymentTransactions.map(r=>{
                if(r.hasOwnProperty('ReceiveAmount')){
                    receivedAount = Number(receivedAount) + Number(r.ReceiveAmount);
                    if(data.hasOwnProperty('Payments')){
                        data.Payments.push({PaymentMode:r.ReceivePaymentNo , Amount:r.ReceiveAmount});
                    }
                }
            })
            balanceAmount = balanceAmount - receivedAount;
            data.BalanceAmount = balanceAmount;
            data.BillPaidAmount = Number(data.BillAmount) - Number(balanceAmount);
        }
    }
    if(props.type ==="deliverylist"){
        data.DeliveryTimeDisplay = data.DeliveryTime && data.DeliveryTime.HH  && data.DeliveryTime.HH !=="" ? (data.DeliveryTime.HH + ":" + data.DeliveryTime.MM + " " + data.DeliveryTime.AMPM):"";
    }
    if(props.type==="attendance"){
        let inTimeStr = "";
        let outTimeStr = "";
        if(data.AttendanceType != "Leave"  && data.AttendanceType != "Week-Off"){
            inTimeStr = data.InTime.HH ? (data.InTime.HH + ":" + data.InTime.MM + " " + data.InTime.AMPM) : "";
            outTimeStr = data.OutTime.HH ? (data.OutTime.HH + ":" + data.OutTime.MM + " " + data.OutTime.AMPM) : "";
        } 
        data.InTimeDisplay = inTimeStr;
        data.OutTimeDisplay = outTimeStr;
        data.EmployeeDisplay = data.Employee.label;
    }
   if(props.type==="expense"){
       data.StatusDisplay = data.Status;
       if(data.Status.toLowerCase() ==="cancelled"){
            data.StatusDisplay = "";
            data.CancellationReasonDisplay = "Cancelled: " + data.CancellationReason;
            data.IsDeleted = true;
       }
   }
    return data;
}
