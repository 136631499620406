import React,{Component} from 'react'

class PaymentWalletWidget extends Component{ 

    render(){
        return <React.Fragment>
            {this.props.mobile && <div className="row">
                <div className="col-xs-4" style={{padding:"0px"}}>
                    <input type="number" 
                        className="form-control input-lg" 
                        value={this.props.paymentModeAmount}
                        onChange={(evt)=> this.props.onPaymentModeAmount(evt.target.value) }
                        />
                    </div>
                    <div className="col-xs-8" style={{padding:"0px"}}>
                        <input type="text" 
                        className="form-control input-lg"  
                        onChange={(evt)=> this.props.onPaymentModeRemarks(evt.target.value)}
                        value={this.props.paymentModeRemarks}
                        placeholder = "Remarks"/>
                </div>
            </div>}
            <div className="row">
                <div className="form-group">
                    <label>Wallet Balance</label>
                    <input type="text" className="form-control input-lg" 
                    disabled={true}
                    value={this.props.walletBalance}
                    />
                </div>
                {this.props.OTPVerificationRequired  &&  <div className="form-group">
                    <label>OTP</label>
                    <input type="text"  className="form-control input-lg" 
                    value={this.props.OTP}
                    onChange={(evt)=> this.props.onOPTChange(evt.target.value)} />
                </div>}
            </div>  
            {this.props.mobile && <div className="row">
                <button type="submit" className="btn btn-flat btn-md btn-default" onClick={this.props.onCancelClicked} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.props.onOKClicked}>OK</button>
            </div>}         
        </React.Fragment>
    }
}

export default PaymentWalletWidget