import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function getSMSTemplatesByModule(registrationID, module){
    return (dispatch, getState) => {
        let q = database.collection("SMSTemplates").where("RegistrationID","==",registrationID).where("ModuleID","==", module).where("IsDeleted", "==", false).where("StoreID","==",getState().user.store.key.trim()).orderBy("Name","asc")
        return q.get().then((querySnapshot) => {
            const smsTemplates = []
            querySnapshot.forEach((doc) => {
                smsTemplates.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
            return smsTemplates
        }).catch((error) => {console.log(error)})

      }
}

export function getSMSTemplatesBySMSType(registrationID, smsType){
    return (dispatch, getState) => {
        let q = database.collection("SMSTemplates").where("RegistrationID","==",registrationID).where("SMSType","==", smsType).where("StoreID", "==", getState().user.store.key).where("Status","==","Approved").where("IsDeleted", "==", false).orderBy("Name","asc");
        return q.get().then((querySnapshot) => {
            const smsTemplates = []
            querySnapshot.forEach((doc) => {
                smsTemplates.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
            return smsTemplates
        }).catch((error) => {console.log(error)})

      }
}

export function getSMSTemplates(registrationID)
{
    return (dispatch, getState) => {
        let q = database.collection("SMSTemplates").where("RegistrationID", "==", registrationID).where("IsDeleted", "==",false);
        
        return q.get().then((querySnapshot) => {
            const smsTemplates = []
            querySnapshot.forEach((doc) => {
                smsTemplates.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
            return smsTemplates
        }).catch((error) => {console.log(error)})
    }
}

// // export function getSMSTemplatesByModule(registrationID, module){
// //     return (dispatch) => {
// //         return database.collection("SMSTemplates").where("RegistrationID", "==", registrationID).where("Module","==", module).where("IsDeleted", "==", false).orderBy("Name","asc").get().then((querySnapshot) => {
// //           let smsTemplates = []
// //           querySnapshot.forEach((doc) => {
// //             smsTemplates.push({ key: doc.id, ...doc.data() });
// //           });
// //           //console.log("getSMSTemplates", JSON.stringify(smsTemplates));
// //           return smsTemplates;
// //         });
// //       }
// // }

// // export function getSMSTemplatesBySMSType(registrationID, smsType){
// //     return (dispatch) => {
// //         return database.collection("SMSTemplates").where("RegistrationID", "==", registrationID).where("SMSType","==", smsType).where("Status","==","Approved").where("IsDeleted", "==", false).orderBy("Name","asc").get().then((querySnapshot) => {
// //           let smsTemplates = []
// //           querySnapshot.forEach((doc) => {
// //             smsTemplates.push({ key: doc.id, ...doc.data() });
// //           });
// //           console.log("getSMSTemplates", JSON.stringify(smsTemplates));
// //           return smsTemplates;
// //         });
// //       }
// // }

// // export function getSMSTemplates(registrationID, storeID)
// // {
// //     return (dispatch, getState) => {
// //         let q = database.collection("SMSTemplates").where("RegistrationID","==",registrationID)
// //         if(getState().user.user.Role === "Store Owner" && storeID.trim()!=="")
// //         {
// //             q.where("StoreID","==",storeID)
// //         }
// //         return q.get().then((querySnapshot) => {
// //             const smsTemplates = []
// //             querySnapshot.forEach((doc) => {
// //                 smsTemplates.push(
// //                   {
// //                     key:doc.id,...doc.data()
// //                   })
// //            })
// //             return smsTemplates
// //         }).catch((error) => {console.log(error)})
// //     }
// // }

// export function getSMSTemplatesByModule(registrationID, module){
//     return (dispatch, getState) => {
//         // return database.collection("SMSTemplates").where("StoreID", "==", registrationID).where("Module","==", module).where("IsDeleted", "==", false).orderBy("Name","asc").get().then((querySnapshot) => {
//         //   let smsTemplates = []
//         //   querySnapshot.forEach((doc) => {
//         //     smsTemplates.push({ key: doc.id, ...doc.data() });
//         //   });
//         //   //console.log("getSMSTemplates", JSON.stringify(smsTemplates));
//         //   return smsTemplates;
//         // });
//         let q = database.collection("SMSTemplates").where("RegistrationID","==",registrationID).where("Module","==", module).where("StoreID","==",getState().user.store.key.trim())
//         return q.get().then((querySnapshot) => {
//             const smsTemplates = []
//             querySnapshot.forEach((doc) => {
//                 smsTemplates.push(
//                   {
//                     key:doc.id,...doc.data()
//                   })
//            })
//             return smsTemplates
//         }).catch((error) => {console.log(error)})

//       }
// }

// export function getSMSTemplatesBySMSType(registrationID, smsType){
//     return (dispatch, getState) => {
//         // return database.collection("SMSTemplates").where("RegistrationID", "==", registrationID).where("SMSType","==", smsType).where("Status","==","Approved").where("IsDeleted", "==", false).orderBy("Name","asc").get().then((querySnapshot) => {
//         //   let smsTemplates = []
//         //   querySnapshot.forEach((doc) => {
//         //     smsTemplates.push({ key: doc.id, ...doc.data() });
//         //   });
//         //   console.log("getSMSTemplates", JSON.stringify(smsTemplates));
//         //   return smsTemplates;
//         // });

//         //let q = database.collection("SMSTemplates").where("RegistrationID","==",registrationID).where("SMSType","==", smsType).where("StoreID", "==", getState().user.store.key);
        
//         let q = database.collection("SMSTemplates").where("RegistrationID","==",registrationID).where("SMSType","==", smsType).where("StoreID", "==", getState().user.store.key).where("Status","==","Approved").where("IsDeleted", "==", false).orderBy("Name","asc");
//         return q.get().then((querySnapshot) => {
//             const smsTemplates = []
//             querySnapshot.forEach((doc) => {
//                 smsTemplates.push(
//                   {
//                     key:doc.id,...doc.data()
//                   })
//            })
//             return smsTemplates
//         }).catch((error) => {console.log(error)})

//       }
// }

// export function getSMSTemplates(registrationID, storeID)
// {
//     return (dispatch, getState) => {
//         let q = database.collection("SMSTemplates").where("RegistrationID", "==", registrationID).where("StoreID", "==", getState().user.store.key)
        
//         return q.get().then((querySnapshot) => {
//             const smsTemplates = []
//             querySnapshot.forEach((doc) => {
//                 smsTemplates.push(
//                   {
//                     key:doc.id,...doc.data()
//                   })
//            })
//             return smsTemplates
//         }).catch((error) => {console.log(error)})
//     }
// }

export function saveSMSTemplate(smsTemplate, key){
    return (dispatch, getState) => {
        smsTemplate.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        smsTemplate.ActionBy = getState().user.user.Name
        smsTemplate.ActionByUID = getState().user.user.UID
        smsTemplate.ActionByEmailID = getState().user.user.EmailID

        if(key !== "")
        {
            smsTemplate.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("SMSTemplates").doc(key).update({
                ...smsTemplate
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating SMSTemplates: ", error);
              })
        }
        else
        {
            smsTemplate.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("SMSTemplates").add({
                ...smsTemplate
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding SMSTemplates: ", error);
              })
        }
    }
}

export function deleteSMSTemplate(key)
{
    return (dispatch, getState) => {
        return database.collection("SMSTemplates").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted : true
        }).then(()=> {return true})
    }
}

export function getSMSTemplateDetails(key){
    return dispatch => {
    return database.collection("SMSTemplates").doc(key).get().then((doc) => {
        if (doc.exists) {
            console.log("SMSTemplate found", doc.data());
           return doc.data()
        } else {
            console.log("No such SMSTemplate!");
        }
    })
    }
}


export function createMessageTemplates(messageTemplate) {
    return (dispatch, getState) => {
        messageTemplate.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        messageTemplate.ActionBy = getState().user.user.Name
        messageTemplate.ActionByUID = getState().user.user.UID
        messageTemplate.ActionByEmailID = getState().user.user.EmailID
        return database.collection("MessageTemplates").add(messageTemplate).then(() => {
            return
        }).catch(function (error) {
            console.error("Error updating MessageTemplates ", error);
        })
    }
};

export function getMessageTemplates()
{
    return (dispatch) => {
        let q = database.collection("MessageTemplates").where("IsDeleted", "==", false).orderBy("Type", "asc");
        
        return q.get().then((querySnapshot) => {
            const messageTemplates = []
            querySnapshot.forEach((doc) => {
                messageTemplates.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
            return messageTemplates
        }).catch((error) => {console.log(error)})
    }
}

export function getMessageTemplateTypes()
{
    return (dispatch) => {
        let q = database.collection("GlobalConfigurations");
        
        return q.get().then((querySnapshot) => {
            const messageTemplateTypes = []
            querySnapshot.forEach((doc) => {
                if(doc.id === "_TemplateTypes")
                {
                   let types = doc.data().Types;
                   for (let i = 0; i < types.length; i++) {
                       const obj = types[i];
                       messageTemplateTypes.push({
                           text: obj
                       })
                   }
                }
           })
            return messageTemplateTypes;
        }).catch((error) => {console.log(error)})
    }
}
