import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function apiGetCustomerReferralProgram(registrationID) {
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getCustomerReferralProgram');
        return addMessage({ RegistrationID: registrationID }).then(function (data) {
          console.log('', data.data)
          let customerReferralProgram = {...data.data}
          return customerReferralProgram
        });
    }
}


export function getCustomerReferralProgram(registrationID) {
    return (dispatch) => {
        return database.collection("CustomerReferralProgram").where("RegistrationID","==",registrationID).limit(1).get().then((querySnapshot) => {        
            let customerReferralProgram = null
            querySnapshot.forEach((doc) => {
                customerReferralProgram =
                  {
                    key:doc.id,...doc.data()
                  }
           })
            return customerReferralProgram
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function saveReferrals(referrals, customerID,
    transactionID, transactionNo, storeID, registrationID)
{
    return (dispatch,getState) =>{
        let customerReferral = {
            TransactionID:transactionID,
            TransactionNo:transactionNo,
            StoreID:storeID,
            RegistrationID:registrationID,
            CustomerID:customerID,
            Referrals: referrals,
            ActionOn:firebase.firestore.FieldValue.serverTimestamp()
        }
        return database.collection("CustomerReferrals").add({
            ...customerReferral
          })
          .then(function(docRef) {
              return docRef.id
          })
          .catch(function(error) {
              console.error("Error adding customerReferralProgram: ", error);
          })
    }
}

export function saveCustomerReferralProgram(customerReferralProgram, key){
    return (dispatch,getState) =>{
        customerReferralProgram.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        customerReferralProgram.ActionBy = getState().user.user.Name
        customerReferralProgram.ActionByUID = getState().user.user.UID
        customerReferralProgram.ActionByEmailID = getState().user.user.EmailID
        if(key.trim()!== "")
        {
            customerReferralProgram.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("CustomerReferralProgram").doc(key).update({
                ...customerReferralProgram
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating customerReferralProgram : ", error);
              })
        }
        else
        {
            customerReferralProgram.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("CustomerReferralProgram").add({
                ...customerReferralProgram
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding customerReferralProgram: ", error);
              })
        }
        
    }
}

// export function deleteProductTax(key){
//     return (dispatch, getState) =>{
//         return database.collection("ProductTaxes").doc(key).update({
//           IsDeleted:true,
//           ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
//             ActionBy: getState().user.user.Name,
//             ActionByUID: getState().user.user.UID,
//             ActionByEmailID: getState().user.user.EmailID,

//             Action: constants.AUDIT_TRAIL_DELETE
//         }).then(() => { return true })
//     }
// }

