import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Bar } from "react-chartjs-2";

class WidgetLast12MonthsSalesVsPurchase extends Component {
  constructor({ stores }) {
    super();
    this.state = {
      stores: stores,
      showLastSalesList: false,
      showLastSalesChart: true,
      sales12MonthsDashboard: {
        labels: [],
        datasets: [],
      },
    };
  }

  async componentDidMount() {
    this.loadData(this.state.stores, this.state.fromDate, this.state.toDate);
  }

  async loadData(stores) {
    let fromDate = new Date();

    fromDate = fromDate.setMonth(fromDate.getMonth() - 12);
    fromDate = new Date(fromDate).toISOString().split("T")[0];

    let toDate = new Date().toISOString().split("T")[0];

    let result = await this.props.getSalesForLast12Months(
      this.props.user.user.RegistrationID,
      stores,
      fromDate,
      toDate,
      "month"
    );
    let purchaseResult = await this.props.getPurchaseForLast12Months(
      this.props.user.user.RegistrationID,
      stores,
      fromDate,
      toDate,
      "month"
    );
    //    console.log("purchaseResult",purchaseResult)
    // .then((result) => {
    let sales12MonthsDashboard = {
      labels: [],
      datasets: [
        {
          label: "Sales",
          backgroundColor: "#6e2d90",
          borderColor: "#6e2d90",
          hoverBorderColor: "#6e2d90",
          hoverBackgroundColor: "#6e2d90",
          data: [],
        },
        {
          label: "Purchase",
          backgroundColor: "#ff6f00",
          borderColor: "#ff6f00",
          hoverBorderColor: "#ff6f00",
          hoverBackgroundColor: "#ff6f00",
          data: [],
        },
      ],
    };
    let sales12MonthsData = [];
    let totalSales = 0;
    // let totalTransactions = 0;
    let months = 0;
    // let avgMonthlySales = 0;
    // let avgTicketSize = 0;
    let totalPuchase = 0;
    for (let index = 0; index < result.searchResult.length; index++) {
      let total_purchase = 0;
      let total_sales = 0;

      const data = result.searchResult[index];
      total_sales = Number(data.total_sales).toFixed(2);

      if (
        purchaseResult.searchResult.filter((f) => f.date === data.date).length >
        0
      ) {
        let tmp = purchaseResult.searchResult.filter(
          (f) => f.date === data.date
        );
        total_purchase = Number(tmp[0].total_purchase).toFixed(2);
      }

      sales12MonthsDashboard.labels.push(data.date);
      sales12MonthsDashboard.datasets[0].data.push(total_sales);
      sales12MonthsDashboard.datasets[1].data.push(total_purchase);

      var obj = {
        Month: data.date,
        "Total Sales": total_sales,
        "Total Purchase": total_purchase,
      };
      totalSales = totalSales + Number(total_sales);
      totalPuchase = totalPuchase + Number(total_purchase);
      // totalTransactions = totalTransactions + Number(data.total_transactions);
      months = months + 1;
      sales12MonthsData.push(obj);
    }
    // avgMonthlySales = totalSales === 0 ? 0 : Number(totalSales) / Number(months);
    // avgTicketSize = totalSales === 0 ? 0 : Number(totalSales) / Number(totalTransactions);

    this.setState({
      totalSales: Number(totalSales).toFixed(0),
      totalPuchase: Number(totalPuchase).toFixed(0),
    });
    // avgMonthlySales: Number(avgMonthlySales).toFixed(0), avgTicketSize: Number(avgTicketSize).toFixed(2)
    var col = [];
    for (var i = 0; i < sales12MonthsData.length; i++) {
      for (var key in sales12MonthsData[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }
    var html = "<table class='table table-bordered table-striped'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      html += "<th>";
      html += col[i];
      html += "</th>";
    }
    html += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    html += "<tbody>";
    for (var i = 0; i < sales12MonthsData.length; i++) {
      html += "<tr>";
      for (var j = 0; j < col.length; j++) {
        html += j === 0 ? "<td>" : "<td style='text-align:right'>";
        html +=
          sales12MonthsData[i][col[j]] == undefined
            ? 0
            : sales12MonthsData[i][col[j]];
        html += "</td>";
      }
      html += "</tr>";
    }
    html += "</tbody>";
    html += "</table>";

    this.setState({
      sales12MonthsDashboard: sales12MonthsDashboard,
      sales12MonthsData: html,
    });
    // });
  }

  componentWillReceiveProps({ stores }) {
    this.loadData(stores);
  }

  render() {
    return (
      <div className="dashboard-box ">
        <div className="dashboard-box-header">
          <div className="dashboard-box-title left">
            Last 12 Months Sales vs Purchase
          </div>
          <div className="right">
            <button
              type="button"
              className="btn btn-default btn-flat btn-md pull-right fa fa-list"
              style={{
                padding: "6px",
                marginLeft: 0,
                marginRight: 0,
                color: this.state.showLastSalesList ? "#6e2d90" : "gray",
              }}
              onClick={() =>
                this.setState({
                  showLastSalesChart: false,
                  showLastSalesList: true,
                })
              }
            ></button>
            <button
              type="button"
              className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
              style={{
                padding: "6px",
                marginLeft: 0,
                marginRight: 0,
                color: this.state.showLastSalesChart ? "#6e2d90" : "gray",
              }}
              onClick={() =>
                this.setState({
                  showLastSalesChart: true,
                  showLastSalesList: false,
                })
              }
            ></button>
          </div>
        </div>
        <div className="dashboard-box-body">
          {this.state.showLastSalesChart && (
            <React.Fragment>
              <div className="chart">
                <Bar
                  width={500}
                  height={300}
                  data={this.state.sales12MonthsDashboard}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {this.state.showLastSalesList && (
            <div className="table-responsive scroll2">
              {ReactHtmlParser(this.state.sales12MonthsData)}
            </div>
          )}
        </div>
        <div className="dashboard-box-footer">
          <div className="description-block border-right">
            <h5 className="description-header">{this.state.totalSales}</h5>
            <span className="description-text">TOTAL SALES</span>
          </div>
          <div className="description-block border-right">
            <h5 className="description-header">{this.state.totalPuchase}</h5>
            <span className="description-text">TOTAL PURCHASE</span>
          </div>
        </div>
      </div>
    );
  }
}

export default WidgetLast12MonthsSalesVsPurchase;
