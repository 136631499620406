import React, {Component} from 'react'
import Loader from '../Loader'
import firebase,{ database } from '../../firebase';
import Window from '../2/Controls/Window';
import Button from '../2/Controls/Button';

class BatchSelection extends Component{
    constructor () {
        super()
        this.state={
            batches:[],
            loading: false,
            searchText:'',
            selectAll:false,
            totalQty:0,
            colSpan:0,
            buttons:[],
            batchTrackingExpRequired:false
        }
        this.loadList = this.loadList.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onSelectAllChange= this.onSelectAllChange.bind(this);
        this.onRecordSelectionChange = this.onRecordSelectionChange.bind(this);
        this.updateInStockQty = this.updateInStockQty.bind(this);
        this.returnBatchCSS = this.returnBatchCSS.bind(this);
        this.onOkClicked = this.onOkClicked.bind(this);
        this.showBatchAuditTrail = this.showBatchAuditTrail.bind(this);
    }

    componentDidMount(){ 
        this.loadList();
        if(!this.props.hideSelection)
        {
            this.setState({buttons:[{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"},
            ]})
        }
        // console.log('key', this.props.id);
     }

    async loadList() {
        console.log('this.props.storeLocationID', this.props.storeLocationID);
        console.log('this.props.product',this.props.product)
        let batchTrackingExpRequired = false;
        let batchesTemp = []
        let batches=[];
        this.setState({loading:true})
        batchesTemp = await this.props.getBatches(this.props.storeLocationID, this.props.product.key, this.props.showAllBatches);
        console.log('batchesTemp',batchesTemp)
        let totalQty = 0 
        batchesTemp.map((m,index)=>{
            let lineItem = this.props.lineItems.filter(f=> f.hasOwnProperty('BatchID') && f.BatchID ===m.key);
            totalQty = Number(totalQty) + Number(m.Qty);
            if(lineItem.length===0)
            {
                m.Selected = false;  
                batches.push(m);
            }
            else{
                if(m.Qty > lineItem[0].Qty)
                {
                    m.Qty = m.Qty - lineItem[0].Qty;
                    batches.push(m);
                }
            }
        })
        let colSpan=0;
        if(this.props.product.hasOwnProperty("BatchTrackingBatchNoRequired") && this.props.product.BatchTrackingBatchNoRequired){
            colSpan = Number(colSpan) +1;
        }
        if(this.props.product.hasOwnProperty("BatchTrackingMRPRequired") && this.props.product.BatchTrackingMRPRequired){
            colSpan = Number(colSpan) +2;
        }
        // if(this.props.product.hasOwnProperty("BatchTrackingMRPRequired") && this.props.product.BatchTrackingMRPRequired){
        //     colSpan = Number(colSpan) +1;
        // }
        if(this.props.product.hasOwnProperty("BatchTrackingMfgDateRequired") && this.props.product.BatchTrackingMfgDateRequired){
            colSpan = Number(colSpan) +1;
        }
        if(this.props.product.hasOwnProperty("BatchTrackingExpDateRequired") && this.props.product.BatchTrackingExpDateRequired){
            colSpan = Number(colSpan) +1;
            batchTrackingExpRequired= true;
        }
        if(!this.props.hideSelection){
            colSpan = Number(colSpan) +1;
        }
        // console.log('colSpan', colSpan)
        if(batches.length ===1 && !this.props.hideSelection){
            //only one item 
            this.props.onOK(batches);
        }
        if(batchTrackingExpRequired)
        {
            //hilight expired ones with red color
            //sort data with ones 
            // console.log('this.props.product',this.props.product)
            // console.log('batches',batches)
            batches.sort(function(a,b){
                if(!a.BatchExpDateValue || !b.BatchExpDateValue){
                    return 1;
                }
                else
                {
                    return  new Date(a.BatchExpDateValue.seconds*1000) - new Date(b.BatchExpDateValue.seconds*1000);                    
                }

            })
            batches.map(b=>{
                if(b.BatchExpDateValue){
                    let expiryDate = new Date(b.BatchExpDateValue.seconds*1000);
                    if(expiryDate < new Date()){
                        b.Expired = true;
                    }
                    else
                    {
                        b.Expired = false;
                    }
                }
            })
        }
        // console.log('batches',batches)
        this.setState({batches:batches, loading:false, totalQty:totalQty, colSpan:colSpan, batchTrackingExpRequired:batchTrackingExpRequired})
    }
    
    async showBatchAuditTrail(batchID){
        let batchAuditTrail= await this.props.getBatchAuditTrail(batchID);
        console.log('batchAuditTrail',batchAuditTrail)
        this.setState({showAuditTrail:true,batchAuditTrail:batchAuditTrail })
    }

    onClearSearch() {
        this.setState({recordFrom: 0, searchText: ''})
        this.loadList(0)
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadList(0)
    }

    onSelectAllChange()
    {
        let batches  = this.state.batches
        batches.map((b)=>{b.Selected=!this.state.selectAll})
        this.setState({ selectAll: !this.state.selectAll,batches:batches })
    }

    onRecordSelectionChange(index){
            let batches  = this.state.batches
            batches[index].Selected = ! batches[index].Selected
            this.setState({batches:batches})
    }

    async updateInStockQty(){
        if(this.props.id){
            // console.log('this.state.totalQty', this.state.totalQty)
            // console.log('this.props.id', this.props.id)
            await database.collection("InventoryInStock").doc(this.props.id).update({ InStockQty: this.state.totalQty, ActionOn: firebase.firestore.FieldValue.serverTimestamp() })
            alert('Instock qty updated successfully');
            this.props.reLoad();
        }
    }

    returnBatchCSS(batch){
            
    }

    onOkClicked(){
        let batches = this.state.batches.filter((f) => f.Selected);
                if (batches.length == 0) {
                    alert("Please select record(s)");
                    return;
                }
                // if(batches.filter(f=>f.Expired).length >)
                if(this.props.registration.registration.NearingExpiryBatchToBeSelectedFirstValidation && this.state.batchTrackingExpRequired){
                    // console.log('nearingExpiryBatchToBeSelectedFirstValidation')
                    ///first non expired should be selected
                    let validationSuccess = true;
                    let nonExpiredBatches = this.state.batches.filter((f) => !f.Expired);
                    // console.log('nonExpiredBatches',nonExpiredBatches)
                    // if(batches.length > 1){
                       for(let i=0;i<nonExpiredBatches.length; i++){
                        //    console.log('nonExpiredBatches[i]',nonExpiredBatches[i]);
                           if(nonExpiredBatches[i].Selected && i>0){
                                validationSuccess= false;
                           }
                       }
                    // }
                    if(!validationSuccess){
                        alert("Please select batch that is nearing expiry first");
                        return;
                    }
                }
                this.props.onOK(batches);
    }

    render() {
        return <Window 
        // modal={true}
        header={"Batch Selection"}  
        width={"600px"}
        processing={this.state.saving}
        onClose={this.props.onClose}
        onButtonClick={(buttonType)=>{
            if(buttonType.toLowerCase()==="ok"){
                this.onOkClicked();
            }
            else
            {
                this.props.onClose()
            }
        }}
        buttons={this.state.buttons}
        >
           
                <div className="box-body" style={{height: "73vh",overflowY: "scroll",overflowX: "hidden",padding: "0px"}}>
                    {this.state.loading && <Loader show={this.state.loading} />}
                    {!this.state.loading && <React.Fragment>
                        {/* {(this.props.inStockQty && Number(this.props.inStockQty) !== Number(this.state.totalQty)) && <div className="row">
                            <div className="col-md-4">In Stock Qty: <br/>{this.props.inStockQty}</div>
                            <div className="col-md-4">Total Batch Qty:<br/>{this.state.totalQty}</div>
                            <div className="col-md-4">
                                <button 
                                    type="submit" 
                                    className="btn btn-flat btn-md btn-default btn-rounded" 
                                    onClick={()=>this.updateInStockQty()}>
                                    Update In Stock Qty
                                </button>
                            </div>
                        </div>} */}
                        {this.props.registration.registration.ShowOtherStoreInStockAtBilling && <div className="row">
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-4">
                                        <Button 
                                            type="secondary" 
                                            text="Store wise in stock qty" 
                                            onClick={()=>{
                                                
                                               if(this.props.onShowAllStoreInStockQty){
                                                   this.props.onShowAllStoreInStockQty(this.props.product);
                                               }
                                            }}
                                        />
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            {!this.props.hideSelection &&< th style={{ width: "20px" }}>
                                                <input type="checkbox" checked={this.state.selectAll} onChange={this.onSelectAllChange}/>
                                            </th>}
                                            <th style={{ width: "20px" }}>#</th>
                                            {this.props.product.hasOwnProperty("BatchTrackingBatchNoRequired") && this.props.product.BatchTrackingBatchNoRequired && (<th>Batch No</th>)}
                                            {this.props.product.hasOwnProperty("BatchTrackingMRPRequired") && this.props.product.BatchTrackingMRPRequired && (<th>MRP</th>)}
                                            {this.props.product.hasOwnProperty("BatchTrackingMRPRequired") && this.props.product.BatchTrackingMRPRequired && (<th>Retail Price</th>)}
                                            {this.props.product.hasOwnProperty("BatchTrackingMfgDateRequired") && this.props.product.BatchTrackingMfgDateRequired && (<th>Mfg</th>)}
                                            {this.props.product.hasOwnProperty("BatchTrackingExpDateRequired") && this.props.product.BatchTrackingExpDateRequired && (<th>Exp</th>)}
                                            <th>Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.batches.map((m, index) => (
                                            <tr key={index} className={this.returnBatchCSS(m)}>
                                                {!this.props.hideSelection &&<td style={{backgroundColor:"white"}}>
                                                    <input
                                                        type="checkbox"
                                                        checked={m.Selected}
                                                        onChange={() =>this.onRecordSelectionChange(index)}
                                                    />
                                                </td>}
                                                <td style={{backgroundColor:m.Expired ? "red" : "white"}}>{index + 1}</td>
                                                {this.props.product.hasOwnProperty("BatchTrackingBatchNoRequired") && this.props.product.BatchTrackingBatchNoRequired && (<td style={{backgroundColor:m.Expired ? "red" : "white"}}>{m.BatchNo}</td>)}
                                                {this.props.product.hasOwnProperty("BatchTrackingMRPRequired") && this.props.product.BatchTrackingMRPRequired && (<td style={{textAlign: "right",backgroundColor:m.Expired ? "red" : "white"}}>{m.BatchMRP}</td>)}
                                                {this.props.product.hasOwnProperty("BatchTrackingMRPRequired") && this.props.product.BatchTrackingMRPRequired && (<td style={{textAlign: "right",backgroundColor:m.Expired ? "red" : "white"}}>{m.BatchRetailPrice}</td>)}
                                                {this.props.product.hasOwnProperty("BatchTrackingMfgDateRequired") && this.props.product.BatchTrackingMfgDateRequired && (<td style={{backgroundColor:m.Expired ? "red" : "white"}}>{m.BatchMfgDate}</td>)}
                                                {this.props.product.hasOwnProperty("BatchTrackingExpDateRequired") && this.props.product.BatchTrackingExpDateRequired && (<td style={{backgroundColor:m.Expired ? "red" : "white"}}>{m.BatchExpDate}</td>)}
                                                <td style={{ textAlign: "right" ,backgroundColor:m.Expired ? "red" : "white"}} >{m.Qty}</td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={Number(this.state.colSpan) + 1}>Total</td>
                                            <td style={{ textAlign: "right" }}>{this.state.totalQty}</td>
                                        </tr>
                                    </tbody>       
                                </table>
                                {this.state.showAuditTrail && <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <td>Transaction No</td>
                                            <td>Transaction Qty</td>
                                            <td>Batch Qty</td>
                                            <td>Action On</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.batchAuditTrail.map((b,index)=><tr>
                                            <td>{b.TransactionNo}</td>
                                            <td>{b.TransactionQty}</td>
                                            <td>{b.Qty}</td>
                                            <td>{(new Date(b.ActionOn.seconds*1000)).toString().substring(0,24)}</td>
                                        </tr>)}
                                    </tbody>
                                </table>}
                            </div>
                        </div>
                        
                    </React.Fragment>}
                </div>
        </Window>
    }
}

export default BatchSelection