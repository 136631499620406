import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';


class GSTR2Report extends Component {
    constructor() {
        super()
        this.state = {
            taxes: [{ "name": "CGST", "perc": "CGST%" }, { "name": "SGST", "perc": "SGST%" }, { "name": "IGST", "perc": "IGST%" }, { "name": "CESS", "perc": "CESS%" }],
            inventoryList: [],
            searchTransactionNo: '',
            searchTransactionType: null,
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: '',
            searchProductDetail: '',
            activePage: 1,
            itemsCountPerPage: 10000,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            exportCalled: false,
            loading: true,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
        }

        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {

        let transactionType = this.props.config.TransactionTypes.filter(f=> f.TransactionType.trim() === "Purchase Entry")[0]
        this.setState({searchTransactionType: transactionType});

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Vendor') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })

        this.loadInventoryDetailReport(this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail,
            transactionType
        )
    }

    loadInventoryDetailReport(from, searchTransactionNo, searchTransactionFromDate
        , searchTransactionToDate, searchVendorDetail, searchProductDetail, searchTransactionType) {

        this.setState({ loading: true });
 
        this.props.getAllInvetoryDetailReport(
            "1",
            from, 
            this.state.itemsCountPerPage,
            this.props.user.user.RegistrationID,
            this.props.user.store.key,
            "",
            searchTransactionNo, searchTransactionType.key,
            searchTransactionFromDate, searchTransactionToDate,
            searchVendorDetail, searchProductDetail)
            .then((result) => {

                // this.setState({ inventoryLists: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                // this.showPaginationStatus()

                let inventoryList = [];
                for (let index = 0; index < result.searchResult.length; index++) 
                {
                    const data = result.searchResult[index];

                    let inventory = {
                        "Store Name ": data.StoreName,
                        "Store Location": data.StoreLocation,
                        "Transaction No": data.TransactionNo,
                        "Transaction Type": data.TransactionType,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Type": data.ProductType,
                        "Item Code": data.SKU,
                        "Item Name": data.ProductName,
                        "Qty" : data.Qty,
                        "Cancelled": "",
                        "RefNo": data.hasOwnProperty('RefNo') ? data.RefNo : '',
                        "Notes": data.hasOwnProperty('Notes') ? data.Notes : ''
                    }

                    if (this.state.searchTransactionType.TransactionMovement === "vendortostore" ||
                        this.state.searchTransactionType.TransactionMovement === "storetovendor") {
                       
                        inventory["Vendor Code"] = data.VendorCode ? data.VendorCode : ""
                        inventory["Vendor Name"] = data.VendorName ? data.VendorName : ""
                        inventory["Vendor Phone No."] = data.VendorPhoneNo ? data.VendorPhoneNo : ""
                        inventory[this.state.customField1DisplayName] = ""
                        inventory[this.state.customField2DisplayName] = ""
                        inventory[this.state.customField3DisplayName] = ""
                        inventory[this.state.customField4DisplayName] = ""
                        inventory[this.state.customField5DisplayName] = ""
                        inventory[this.state.customField6DisplayName] = ""

                        inventory[this.state.customField1DisplayName] = data.hasOwnProperty('VendorCustomField1') ? data.VendorCustomField1 : ""
                        inventory[this.state.customField2DisplayName] = data.hasOwnProperty('VendorCustomField2') ? data.VendorCustomField2 : ""
                        inventory[this.state.customField3DisplayName] = data.hasOwnProperty('VendorCustomField3') ? data.VendorCustomField3 : ""
                        inventory[this.state.customField4DisplayName] = data.hasOwnProperty('VendorCustomField4') ? data.VendorCustomField4 : ""
                        inventory[this.state.customField5DisplayName] = data.hasOwnProperty('VendorCustomField5') ? data.VendorCustomField5 : ""
                        inventory[this.state.customField6DisplayName] = data.hasOwnProperty('VendorCustomField6') ? data.VendorCustomField6 : ""
                    }

                    if (this.state.searchTransactionType.TransactionType === 'Transfer In') {
                        inventory["From Store"] = data.FromStore ? data.FromStore : ""
                    }

                    if (this.state.searchTransactionType.TransactionType === 'Transfer Out') {
                        inventory["To Store"] = data.ToStore ? data.ToStore : ""
                    }

                    if (this.state.searchTransactionType.IncludePriceAndTax) {
                        inventory["Supply Price"] = data.SupplyPrice
                        inventory.Discount = data.DiscountAmount
                        inventory["Taxble Amount"] = data.TaxableAmount
                        inventory["Tax Inclusive"] = data.TaxInclusive
                        //inventory["Tax Summary"] = ""
                    }

                    inventory.Cancelled = "No"
                    if (data.CancellationReason) {
                        inventory.Cancelled = "Yes"
                    }

                    if (this.state.searchTransactionType.IncludePriceAndTax && data.TaxComponentAmount) {

                        for (let index = 0; index < this.state.taxes.length; index++) {
                            inventory[this.state.taxes[index].name] = 0
                            inventory[this.state.taxes[index].perc] = ""
                        }

                        if (data.TaxComponentAmount) {
                            data.TaxComponentAmount.map((tax) => {
                                inventory[tax.TaxName] = tax.TaxAmount
                                inventory[tax.TaxName + '%'] = tax.TaxPercentage
                            })
                        }
                    }
                    inventoryList.push(inventory);
                }

                var col = [];
                for (var i = 0; i < inventoryList.length; i++) {
                    for (var key in inventoryList[i]) {
                        if (col.indexOf(key) === -1) {
                            col.push(key);
                        }
                    }
                }

                var result = "<table class='table table-bordered'><thead><tr>";
                for (var i = 0; i < col.length; i++) {
                    result += "<th>";
                    result += col[i];
                    result += "</th>";
                }
                result += "</tr></thead>";

                // ADD JSON DATA TO THE TABLE AS ROWS.
                result += "<tbody>";
                for (var i = 0; i < inventoryList.length; i++) {
                    result += "<tr>";
                    for (var j = 0; j < col.length; j++) {
                        result += "<td>";
                        result += inventoryList[i][col[j]] == undefined ? 0 : inventoryList[i][col[j]];
                        result += "</td>";
                    }
                    result += "</tr>";
                }
                result += "</tbody>";
                result += "</table>";
                console.log(result);
                this.setState({ inventoryList: result, loading: false })
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        let transactionType = this.props.config.TransactionTypes.filter(f=> f.TransactionType.trim() === "Purchase Entry")[0]

        this.setState({
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: transactionType ,
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: '', searchProductDetail: ''
        })
        this.loadInventoryDetailReport(0, "", new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "", transactionType)
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getAllInvetoryDetailReport(
            "1",
            this.state.recordFrom,  
            this.state.itemsCountPerPage,
            this.props.user.user.RegistrationID,
            this.props.user.store.key,
            "",
            this.state.searchTransactionNo, 
            this.state.searchTransactionType.key,
            this.state.searchTransactionFromDate, 
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail, 
            this.state.searchProductDetail)
            .then((result) => {
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                
                let inventoryList = [];
                for (let index = 0; index < result.searchResult.length; index++) 
                {
                    const data = result.searchResult[index];

                    let inventory = {
                        "Store Name ": data.StoreName,
                        "Store Location": data.StoreLocation,
                        "Transaction No": data.TransactionNo,
                        "Transaction Type": data.TransactionType,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Type": data.ProductType,
                        "Item Code": data.SKU,
                        "Item Name": data.ProductName,
                        "Qty" : data.Qty,
                        "Cancelled": "",
                        "RefNo": data.hasOwnProperty('RefNo') ? data.RefNo : '',
                        "Notes": data.hasOwnProperty('Notes') ? data.Notes : ''
                    }

                    if (this.state.searchTransactionType.TransactionMovement === "vendortostore" ||
                        this.state.searchTransactionType.TransactionMovement === "storetovendor") {
                       
                        inventory["Vendor Code"] = data.VendorCode ? data.VendorCode : ""
                        inventory["Vendor Name"] = data.VendorName ? data.VendorName : ""
                        inventory["Vendor Phone No."] = data.VendorPhoneNo ? data.VendorPhoneNo : ""
                        inventory[this.state.customField1DisplayName] = ""
                        inventory[this.state.customField2DisplayName] = ""
                        inventory[this.state.customField3DisplayName] = ""
                        inventory[this.state.customField4DisplayName] = ""
                        inventory[this.state.customField5DisplayName] = ""
                        inventory[this.state.customField6DisplayName] = ""

                        inventory[this.state.customField1DisplayName] = data.hasOwnProperty('VendorCustomField1') ? data.VendorCustomField1 : ""
                        inventory[this.state.customField2DisplayName] = data.hasOwnProperty('VendorCustomField2') ? data.VendorCustomField2 : ""
                        inventory[this.state.customField3DisplayName] = data.hasOwnProperty('VendorCustomField3') ? data.VendorCustomField3 : ""
                        inventory[this.state.customField4DisplayName] = data.hasOwnProperty('VendorCustomField4') ? data.VendorCustomField4 : ""
                        inventory[this.state.customField5DisplayName] = data.hasOwnProperty('VendorCustomField5') ? data.VendorCustomField5 : ""
                        inventory[this.state.customField6DisplayName] = data.hasOwnProperty('VendorCustomField6') ? data.VendorCustomField6 : ""
                    }

                    if (this.state.searchTransactionType.TransactionType === 'Transfer In') {
                        inventory["From Store"] = data.FromStore ? data.FromStore : ""
                    }

                    if (this.state.searchTransactionType.TransactionType === 'Transfer Out') {
                        inventory["To Store"] = data.ToStore ? data.ToStore : ""
                    }

                    if (this.state.searchTransactionType.IncludePriceAndTax) {
                        inventory["Supply Price"] = data.SupplyPrice
                        inventory.Discount = data.DiscountAmount
                        inventory["Taxble Amount"] = data.TaxableAmount
                        inventory["Tax Inclusive"] = data.TaxInclusive
                        //inventory["Tax Summary"] = ""
                    }

                    inventory.Cancelled = "No"
                    if (data.CancellationReason) {
                        inventory.Cancelled = "Yes"
                    }

                    if (this.state.searchTransactionType.IncludePriceAndTax && data.TaxComponentAmount) {

                        for (let index = 0; index < this.state.taxes.length; index++) {
                            inventory[this.state.taxes[index].name] = 0
                            inventory[this.state.taxes[index].perc] = ""
                        }

                        if (data.TaxComponentAmount) {
                            data.TaxComponentAmount.map((tax) => {
                                inventory[tax.TaxName] = tax.TaxAmount
                                inventory[tax.TaxName + '%'] = tax.TaxPercentage
                            })
                        }
                    }
                    inventoryList.push(inventory);
                }

                const csvData = constatnts.objToCSV(inventoryList);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", this.state.searchTransactionType.TransactionType + "_Details.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadInventoryDetailReport(
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail, this.state.searchTransactionType)
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6  col-xs-9">
                                        <font style={{ fontSize: "30px" }}>GSTR 2 Report</font>
                                    </div>
                                    <div className="col-md-6  col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Vendor Name,Code,Phone Number,Email</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchVendorDetail: evt.target.value })}
                                                value={this.state.searchVendorDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Name,Code</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchProductDetail: evt.target.value })}
                                                value={this.state.searchProductDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{width:"auto", overflowX:"auto"}} className="scroll2">
                                        {ReactHtmlParser(this.state.inventoryList)}
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(GSTR2Report)