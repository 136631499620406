import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

export function getOffers(registrationID) {
  return (dispatch) => {
    return database
      .collection("Offers")
      .where("RegistrationID", "==", registrationID)
      .orderBy("ActionOn", "desc")
      .get()
      .then((querySnapshot) => {
        const offers = [];
        querySnapshot.forEach((doc) => {
          offers.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        //    console.log("Get Offers", offers);
        return offers;
        // dispatch({ type: actionTypes.GET_OFFER, offers })
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function saveOffer(offer, key) {
  return (dispatch, getState) => {
    offer.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    offer.ActionBy = getState().user.user.Name;
    offer.ActionByUID = getState().user.user.UID;
    offer.ActionByEmailID = getState().user.user.EmailID;

    if (key !== "") {
      offer.Action = constants.AUDIT_TRAIL_EDIT;
      return database
        .collection("Offers")
        .doc(key)
        .update({
          ...offer,
        })
        .then(() => {
          return key;
        })
        .catch(function(error) {
          console.error("Error updating offer: ", error);
        });
    } else {
      offer.Action = constants.AUDIT_TRAIL_NEW;
      return database
        .collection("Offers")
        .add({
          ...offer,
        })
        .then(function(docRef) {
          return docRef.id;
        })
        .catch(function(error) {
          console.error("Error adding offer: ", error);
        });
    }
  };
}

export function saveCouponCode(couponCode) {
  return (dispatch, getState) => {
    couponCode.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    couponCode.ActionBy = getState().user.user.Name;
    couponCode.ActionByUID = getState().user.user.UID;
    couponCode.ActionByEmailID = getState().user.user.EmailID;
    couponCode.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("Coupons")
      .add({
        ...couponCode,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding Coupons: ", error);
      });
  };
}

export function deleteOffer(key) {
  return (dispatch, getState) => {
    return database
      .collection("Offers")
      .doc(key)
      .update({
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,
        Action: constants.AUDIT_TRAIL_DELETE,
        IsDeleted: true,
      })
      .then(() => {
        return true;
      });
  };
}

export function getOfferDetails(key) {
  return (dispatch) => {
    return database
      .collection("Offers")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Offer found", doc.data());
          return doc.data();
        } else {
          console.log("No such offers!");
        }
      });
  };
}

export function getCouponDetails(couponID) {
  return (dispatch) => {
    return database
      .collection("Coupons")
      .where("CouponID", "==", couponID)
      .where("IsDeleted", "==", false)
      .get()
      .then((querySnapshot) => {
        const coupons = [];
        querySnapshot.forEach((doc) => {
          coupons.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        return coupons;
      });
  };
}

export function getApplicableOffers(registrationID) {
  return (dispatch, getState) => {
    return database
      .collection("Offers")
      .where("RegistrationID", "==", registrationID)
      .where("IsDeleted", "==", false)
      .get()
      .then((querySnapshot) => {
        const offers = [];
        querySnapshot.forEach((doc) => {
          offers.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        let applicableOffers = [];
        var days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        if (offers.length > 0) {
          console.log("offers", offers);
          for (let index = 0; index < offers.length; index++) {
            const offer = offers[index];
            // if(offer.hasOwnProperty("Type") && offer.Type ==="Coupon")
            // {
            //     console.log('it is coupon')
            //     continue
            // }
            if (offer.AllStores) {
              // For All Stores
              // console.log(offer.Name + "  " + new Date(offer.ValidityFrom));
              if (
                new Date(offer.ValidityFrom).setHours(0, 0, 0, 0) <=
                  new Date().setHours(0, 0, 0, 0) &&
                new Date().setHours(0, 0, 0, 0) <=
                  new Date(offer.ValidityTo).setHours(0, 0, 0, 0)
              ) {
                // Validity Check
                if (
                  offer.SpecificDay != "" &&
                  offer.SpecificDay.toLowerCase() === "yes"
                ) {
                  // Specific Day Selected
                  if (offer.SpecificDays.includes(days[new Date().getDay()])) {
                    // Check Specific Day
                    applicableOffers.push(offer);
                  }
                } else {
                  applicableOffers.push(offer);
                }
              }
            } else {
              for (let index = 0; index < offer.Stores.length; index++) {
                const store = offer.Stores[index];

                if (store.value === getState().user.store.key) {
                  if (
                    new Date(offer.ValidityFrom).setHours(0, 0, 0, 0) <=
                      new Date().setHours(0, 0, 0, 0) &&
                    new Date().setHours(0, 0, 0, 0) <=
                      new Date(offer.ValidityTo).setHours(0, 0, 0, 0)
                  ) {
                    // Validity Check
                    if (
                      offer.SpecificDay != "" &&
                      offer.SpecificDay.toLowerCase() === "yes"
                    ) {
                      // Specific Day Selected
                      if (
                        offer.SpecificDays.includes(days[new Date().getDay()])
                      ) {
                        // Check Specific Day
                        applicableOffers.push(offer);
                      }
                    } else {
                      applicableOffers.push(offer);
                    }
                  }
                }
              }
            }
          }
        }
        // console.log(applicableOffers);
        dispatch({ type: actionTypes.GET_APPLICABLE_OFFERS, applicableOffers });
        // return applicableOffers;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function validateCoupon(
  registrationID,
  storeID,
  couponCode,
  customerID
) {
  return (dispatch) => {
    console.log("registrationID", registrationID);
    console.log("storeID", storeID);
    console.log("couponCode", couponCode);
    console.log("customerID", customerID);
    let data = {
      RegistrationID: registrationID,
      StoreID: storeID,
      CouponCode: couponCode,
      CustomerID: customerID,
    };
    // console.log('data', data)
    let addMessage = firebase.functions().httpsCallable("validateCoupon");
    return addMessage({ text: data }).then(function(result) {
      // console.log('validateCoupon', result);
      return result.data;
    });
  };
}
