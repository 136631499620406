import React, { Component } from 'react';
import MobileCustomer from '../Sales/MobileCustomer'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'
import Avatar from '../Common/Avatar'
import '../Common/RecordNotFound/style.css';
// import {Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap'

class CreateUpdateAppointmentNew extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            startDate: new Date().toISOString().split('T')[0],
            key: '',
            selectedCustomer: null,
            customerError: '',
            startDateError: '',
            status:'', 
            mobileShowCustomer:true,
            mobileShowAppointment: false,
            appointments: [],
            comments:'',
            customerNote: ''
        }
        this.onCustomerChange = this.onCustomerChange.bind(this);
        this.onAppointmentDateChange = this.onAppointmentDateChange.bind(this);
        this.onCustomerSelected = this.onCustomerSelected.bind(this);
        this.onRemoveCustomer = this.onRemoveCustomer.bind(this);
        this.onSaveAppointment  = this.onSaveAppointment.bind(this);
        this.onRemoveBooking = this.onRemoveBooking.bind(this);

    };

    async componentDidMount() {

        if(this.props.location.state && (this.props.location.state.source === 'booking' || this.props.location.state.source === 'editbooking'))
        {
            // console.log(this.props.location.state.source);

            let appointments = this.props.location.state.appointments;
            let startDate =  new Date(this.props.location.state.startDate).toISOString().split('T')[0];
            let selectedCustomer = this.props.location.state.selectedCustomer;

            this.setState({ appointments: appointments, startDate: startDate, selectedCustomer: selectedCustomer })
        }
        if(this.props.location.state && this.props.location.state.source === 'edit')
        {
            let selectedCustomer = this.props.location.state.selectedCustomer;
            let startDate =  this.props.location.state.startDate;
            let appointments = await this.props.getAppointmentsForCustomer(selectedCustomer.key, startDate); 
            this.setState({ appointments: appointments, startDate: startDate, selectedCustomer: selectedCustomer });
        }
    };

    onCustomerChange(customer) {
        console.log(customer);
        if (customer) {
            this.onCustomerSelected(customer)
        }
        else {
            this.onRemoveCustomer()
        }
    }

    onRemoveCustomer() {
        this.setState({ selectedCustomer: null, mobileShowAppointment: false, mobileShowCustomer: true, customerError: '' })
    }

    async onCustomerSelected(selectedCustomer) {
        //console.log(selectedCustomer);
        this.getCustomerAdditionalInfo(selectedCustomer);
        this.setState({
            selectedCustomer: selectedCustomer,
            comments: selectedCustomer.Note,
            customerNote: selectedCustomer.Note,
            customerError: ''
        })
    }

    async getCustomerAdditionalInfo(selectedCustomer) {

        // let visitedInDays = 0;
        // let visited = '';
        // let services = [];
        // let customerProducts = [];

        // if (selectedCustomer.LastVisitedOn !== "") {
        //     visitedInDays = ((new Date().setHours(0, 0, 0, 0) - new Date(selectedCustomer.LastVisitedOn).setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24));

        //     const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        //     let current_datetime = new Date(selectedCustomer.LastVisitedOn)
        //     let formatted_month = current_datetime.getDate() + "-" + months[current_datetime.getMonth()]
        //     let formatted_date = current_datetime.getDate() + "-" + months[current_datetime.getMonth()] + "-" + current_datetime.getFullYear()
        //     //console.log(formatted_date)

        //     if (visitedInDays > 365) {
        //         visited = 'Visited more than a year (' + formatted_date + ')'
        //     }
        //     else if (visitedInDays > 30) {
        //         visited = 'Visited more than a month (' + formatted_date + ')'
        //     }
        //     else if (visitedInDays < 30) {
        //         visited = 'Visited ' + visitedInDays + ' days back (' + formatted_month + ')'
        //     }
        // }

        // if (!isNullOrUndefined(selectedCustomer.LastTransactionKey) && selectedCustomer.LastTransactionKey !== "") {
        //     let sale = await this.props.getSaleDetails(selectedCustomer.LastTransactionKey);
        //     if(sale)
        //     {
        //         for (let i = 0; i < sale.LineItems.length; i++) {
        //             const lineItem = sale.LineItems[i];
        //             if (!services.includes(lineItem.Product.Name))
        //                 services.push(lineItem.Product.Name);
        //         }
        //     }
        // }

        // let result = await this.props.getTopProductsForACustomer(selectedCustomer.key, 3);
        // for (let i = 0; i < result.searchResult.length; i++) {
        //     const product = result.searchResult[i];
        //     if (!customerProducts.includes(product.name))
        //         customerProducts.push(product.name);
        // }

        // this.setState({
        //     comments: selectedCustomer.Note,
        //     lastVisited: visited,
        //     lastServices: services.toString(),
        //     customerProducts: customerProducts.toString(),
        // })


        if(selectedCustomer && this.state.startDate !== "")
        {
            let appointments = await this.props.getAppointmentsForCustomer(selectedCustomer.key, this.state.startDate); 
            this.setState({ appointments: appointments });
        }
        
    };

    onRemoveBooking(index) {
        let appointments = this.state.appointments
        appointments.splice(index, 1)
        this.setState({ appointments: appointments });
    };

    async onAppointmentDateChange(evt) {

        this.setState({ startDate: evt.target.value, startDateError: '' })
        
        if(this.state.selectedCustomer && evt.target.value !== "")
        {
            let appointments = await this.props.getAppointmentsForCustomer(this.state.selectedCustomer.key, evt.target.value); 
            // console.log("appointments", appointments);
            this.setState({ appointments: appointments });
        }
        
    };

    async onSaveAppointment() {
        let customerError = ""
        if (this.state.selectedCustomer === null || this.state.selectedCustomer === undefined) {
            customerError = "Please select Customer"
        }
        if (this.state.appointments.length === 0) {
            alert("Please add atleast one appointment");
            return
        }
        if (customerError !== "") {
            this.setState({
                customerError: customerError,
                saving: false
            })
            return
        }

        let appointments = this.state.appointments;
        for (let index = 0; index < appointments.length; index++) {
            const appointment = appointments[index];
            appointment.Customer.Note = this.state.comments;
            appointment.Comments = this.state.comments
            console.log("CreateUpdateAppointmentNew appointment",appointment)
            await this.props.saveAppointment(appointment, appointment.key);
        }

        if (this.state.customerNote !== this.state.comments) {
            await this.props.updateCustomerNote(this.state.comments, this.state.selectedCustomer.key);
        }
        

        let newAppointments = this.state.appointments.filter((f) => f.key === '')

        console.log('new count',newAppointments.length);
        console.log('total count', this.state.appointments.length);
        if(newAppointments.length === this.state.appointments.length && this.state.selectedCustomer.PhoneNo.trim() !== "")
        {
            let slotStart = this.state.appointments[0].SlotStartDisplay;
            let customerName = (this.state.selectedCustomer.FirstName.trim() + ' ' + this.state.selectedCustomer.LastName.trim()).trim();
      
            let transactionData = {
              AppointmentDate: this.state.startDate,
              SlotStartDisplay: slotStart,
              CustomerName: customerName,
            }
      
            let sms = {
              RegistrationID: this.props.user.user.RegistrationID,
              StoreID: this.props.user.store.key,
              UserID: this.props.user.user.key,
              Module: 'appointment',
              TransactionType: 'Appointment',
              MobileNo: this.state.selectedCustomer.PhoneNo.trim(),
              TransactionData: transactionData
            }
            console.log("sms" , JSON.stringify(sms));
            this.props.sendSMS(sms);
        };


        this.props.getToastr("Appointment Saved Successfully");
        this.props.history.push({ pathname: '/home/appointments' });
    };

    render() {
        return <React.Fragment>
            <HeaderNew {...this.props}
                back={true}
                title={!this.state.selectedCustomer ? "Customer Search" : (this.props.location.state && (this.props.location.state.source === 'edit' || this.props.location.state.source === 'editbooking') ) ? "Edit Appointment" : "New Appointment"}
                onBackClick={() => { this.props.history.push({ pathname: '/home/appointments' }) }}
            />
            <div className="header-new-background"></div>
            {this.state.selectedCustomer && <React.Fragment>
                <div className="container">
                    <br />
                    <br />
                    <br />
                    <div className="list-row">
                        <Avatar text={this.state.selectedCustomer.FirstName} big={false} />
                        <div >
                            <div className="text1">{this.state.selectedCustomer.FirstName + " " + this.state.selectedCustomer.LastName} </div>
                            {this.state.selectedCustomer.PhoneNo !== "" && <div><i className="fa fa-phone" /> {this.state.selectedCustomer.PhoneNo}</div>}
                            {this.state.selectedCustomer.EmailID !== "" && <div><i className="fa fa-envelope" /> {this.state.selectedCustomer.EmailID}</div>}
                            {this.state.selectedCustomer.hasOwnProperty('AadharNo') && this.state.selectedCustomer.AadharNo !== "" && <div>AadharNo:  {this.state.selectedCustomer.AadharNo}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={this.state.startDateError !== "" ? "form-group has-error" : "form-group "} >
                                <label>Service Date</label>
                                <input type="date" className="form-control"
                                    onChange={(evt) => this.onAppointmentDateChange(evt)}
                                    value={this.state.startDate}
                                />
                                {this.state.startDateError !== "" && <span className="help-block">{this.state.startDateError}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea className="form-control input-md" style={{ height: "60px" }}
                                    onChange={(evt) => this.setState({ comments: evt.target.value })}
                                    value={this.state.comments}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <button type="button" style={{borderRadius: "50px", width: "48%", fontSize: "17px", border: "none", backgroundColor: "#17a2b8", height: "50px", color: "white"}} onClick={() => this.props.history.push({ pathname: '/home/appointment/book/', state: { appointments: this.state.appointments, bookingType: 'Services', selectedCustomer: this.state.selectedCustomer, startDate: this.state.startDate, appointment: null, source: (this.props.location.state && this.props.location.state.source === 'edit' ? 'editbooking': '') } })}>Service Booking</button>
                            &nbsp;&nbsp;<button type="button" style={{borderRadius: "50px", width: "48%", fontSize: "17px", border: "none", backgroundColor: "#17a2b8", height: "50px", color: "white"}} onClick={() => this.props.history.push({ pathname: '/home/appointment/book/', state: { appointments: this.state.appointments, bookingType: 'Packages', selectedCustomer: this.state.selectedCustomer, startDate: this.state.startDate, appointment: null, source: (this.props.location.state && this.props.location.state.source === 'edit' ? 'editbooking': '') } })}>Package Booking</button>
                        </div>
                    </div>
                    <div className="list" style={{paddingTop: "0px"}}>
                       <React.Fragment>
                            {this.state.appointments.map((appointment, index) => 
                            <div className="list-row seperator">
                                    <div style={{ width: "60%" }}>
                                        <div className="row-text pull-left">{appointment.Service.label}</div><br/>
                                        <div className="row-text pull-left">{appointment.EmployeeName}</div><br/>
                                        <div className="row-text pull-left">{appointment.SlotStartDisplay + ' to ' + appointment.SlotEndDisplay}</div><br/>
                                        <div className="row-text pull-left"><i><b>{appointment.Status}</b> </i></div>
                                    </div>
                                    <div style={{ width: "40%" }} >
                                        <div className="btn-group pull-right">
                                            <div>
                                                {(appointment.Status === '' || appointment.Status === 'Guest Arrived') && <React.Fragment> <button type="button"
                                                onClick={()=> this.props.history.push({ pathname: '/home/appointment/book/', state: { appointments: this.state.appointments, bookingType: (appointment.PackageID !== "" ? "Packages" : "Services"), selectedCustomer: appointment.Customer, startDate: this.state.startDate, appointment: appointment, selectedIndex: index, source: 'editbooking' } })} 
                                                className="btn btn-md btn-flat btn-default "><i className="fa fa-edit"></i></button>
                                                &nbsp;&nbsp;</React.Fragment>}
                                                {appointment.key === "" && <Confirm
                                                    onConfirm={() => this.onRemoveBooking(index)}
                                                    body="Are you sure you would like to delete this appointment?"
                                                    confirmText="Delete"
                                                    title="Delete Appointment">
                                                    <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                </Confirm>}
                                            </div>
                                        </div>
                                    </div>
                            </div>)}
                            {(this.state.appointments.length === 0) && <div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="record-not-found-title" style={{textAlign: "center"}}>No Appointment booked</div>
                            </div>}

                        </React.Fragment>
                        <br />
                        <br />
                        <br />
                        <br />
                        {this.props.isMobileApp() && <Footer
                            onClick={() => this.onSaveAppointment()}
                        />}
                    </div>
                </div>
            </React.Fragment>
            }
            {!this.state.selectedCustomer && <div style={{ marginTop: "36px" }}>
                <MobileCustomer {...this.props}
                    customer={this.state.selectedCustomer}
                    customerAddress={this.state.selectedCustomerAddress}
                    placeOfSupply={this.state.placeOfSupply}
                    error={this.state.customerError}
                    onCustomerChange={(customer, customerAddress, placeOfSupply) => {
                        this.setState({
                            selectedCustomer: customer,
                            selectedCustomerAddress: customerAddress,
                            mobileShowAppointment: true,
                            mobileShowCustomer: false
                        })
                        this.onCustomerChange(customer)
                    }}
                    onCancel={() => {
                        this.setState({ mobileShowAppointment: true, mobileShowCustomer: false })
                    }}
                />
            </div>}

        </React.Fragment>
    };
};

export default CreateUpdateAppointmentNew