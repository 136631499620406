import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';


class OfferReport extends Component {
    constructor() {
        super()
        this.state = {
            invoices: [],
            stores: [],
            storeList: [],
            searchText: '',
            searchTransactionNo: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '',
            searchProductDetail: '',
            activePage: 1,
            itemsCountPerPage: 10000,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            exportCalled: false,
            loading: true,
            productCustomField1DisplayName:'Custom Field 1',
            productCustomField2DisplayName:'Custom Field 2',
            productCustomField3DisplayName:'Custom Field 3',
            productCustomField4DisplayName:'Custom Field 4',
            productCustomField5DisplayName:'Custom Field 5',
            productCustomField6DisplayName:'Custom Field 6',
            gstinCustomFieldNo:0,
            years:[],
            months:[],
            selectedYear:0,
            selectedMonth:0,
            searchOffer: ''
        }

        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    componentDidMount() {

        let storeList = []
        storeList.push({ key: this.props.user.store.key })
        this.setState({storeID: this.props.user.store.key, storeList: storeList})
    
        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {                
            this.setState({ stores: stores })
        });
    
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer')
            {
                let gstinCustomFieldNo= 0
                if(customField.CustomField1Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=1
                }
                else if(customField.CustomField2Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=2
                }
                else if(customField.CustomField3Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=3
                }
                else if(customField.CustomField4Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=4
                }
                else if(customField.CustomField5Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=5
                }
                else if(customField.CustomField6Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=6
                }
                this.setState({gstinCustomFieldNo:gstinCustomFieldNo})
            }
            else if (customField.ModuleName === 'Product')
            {
                let productCustomField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let productCustomField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let productCustomField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let productCustomField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let productCustomField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let productCustomField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    productCustomField1DisplayName:productCustomField1DisplayName,
                    productCustomField2DisplayName:productCustomField2DisplayName,
                    productCustomField3DisplayName:productCustomField3DisplayName,
                    productCustomField4DisplayName:productCustomField4DisplayName,
                    productCustomField5DisplayName:productCustomField5DisplayName,
                    productCustomField6DisplayName:productCustomField6DisplayName
                })
            }
        })

        this.loadInvoiceDetailReport(storeList,
            this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail,
            this.props.location.state.offerName
            )
    }


    getGSTINCustomField()
    {
        let gstinCustomFieldNo= 0
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer')
            {
                
                if(customField.CustomField1Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=1
                }
                else if(customField.CustomField2Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=2
                }
                else if(customField.CustomField3Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=3
                }
                else if(customField.CustomField4Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=4
                }
                else if(customField.CustomField5Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=5
                }
                else if(customField.CustomField6Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=6
                }
                this.setState({gstinCustomFieldNo:gstinCustomFieldNo})
            }
        })
        return gstinCustomFieldNo
    }
 
    loadInvoiceDetailReport(storeList, from, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate,
        searchCustomerDetail, searchProductDetail, searchOffer) {

        this.setState({ loading: true })
        let gstinCustomFieldNo= this.getGSTINCustomField()
        

        this.props.getOfferReport(this.props.user.user.RegistrationID, storeList, from, this.state.itemsCountPerPage,
            searchTransactionNo, searchTransactionFromDate, searchTransactionToDate,
            searchCustomerDetail, searchProductDetail, this.props.type, this.props.location.state.offerName)
            .then((result) => {

                let invoices = []

                for (let index = 0; index < result.searchResult.length; index++) {
                    const data = result.searchResult[index];
                    console.log(data);
                    let invoice = {
                        "Store Name ": data.StoreName,
                        "Customer Code": data.CustomerCode,
                        "Customer Name": data.CustomerName,
                        "Customer Mobile No.": data.CustomerPhoneNo,
                        "Customer GSTIN":""
                    }

                    if(gstinCustomFieldNo ===1)
                    {
                        invoice["Customer GSTIN"] =  data.hasOwnProperty('CustomerCustomField1') ? data.CustomerCustomField1 : ""
                    }
                    else if(gstinCustomFieldNo ===2)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField2') ? data.CustomerCustomField2 : ""
                    }
                    else if(gstinCustomFieldNo ===3)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField3') ? data.CustomerCustomField3 : ""
                    }
                    else if(gstinCustomFieldNo ===4)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField4') ? data.CustomerCustomField4 : ""
                    }
                    else if(gstinCustomFieldNo ===5)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField5') ? data.CustomerCustomField5 : ""
                    }
                    else if(gstinCustomFieldNo ===6)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField6') ? data.CustomerCustomField6 : ""
                    }
                   
                    invoice["Transaction No"] = data.TransactionNo
                    invoice["Transaction Type"] = data.TransactionType
                    invoice["Transaction Date"] = (new Date(data.TransactionDate).toISOString().split('T')[0])
                    invoice["Offer"] = data.OfferName
                    invoice["Offer Type"] = data.OfferType

                    invoice.Cancelled = "No"
                    if (data.CancellationReason) {
                        invoice.Cancelled = "Yes"
                    }
                    invoice["Type"] = data.ProductType
                    invoice["Brand"] = data.Brand ? data.Brand : ""
                    invoice["Category"] = data.Category
                    invoice["Sub Category"] = data.SubCategory ? data.SubCategory : ""
                    invoice["Item Code"] = data.SKU
                    invoice["Item Name"] = data.ProductName
                    invoice["Employee"] = data.hasOwnProperty('EmployeeName') ? data.EmployeeName : ''
                    
                    invoice["Retail Price"] = data.RetailPrice
                    invoice["Qty"] = data.Qty
                    invoice["Taxble Amount"] = data.TaxableAmount
                    invoice["Offer Discount %"] = data.OfferPer
                    invoice["Offer Discount Amt"] = data.OfferAmt
                    
                    invoice["Sub Total"] = data.SubTotal
                    invoice["Total Tax"] = data.TotalTax
                   
                    invoice["Line Amount"] = data.Amount
                    invoice["Bill Amount"] = data.hasOwnProperty('BillAmount') ?  data.BillAmount : 0

                    invoices.push(invoice);
                }

                var col = [];
                for (var i = 0; i < invoices.length; i++) {
                    for (var key in invoices[i]) {
                        if (col.indexOf(key) === -1) {
                            col.push(key);
                        }
                    }
                }

                var result = "<table class='table table-bordered'><thead><tr>";
                for (var i = 0; i < col.length; i++) {
                    result += "<th>";
                    result += col[i];
                    result += "</th>";
                }
                result += "</tr></thead>";

                // ADD JSON DATA TO THE TABLE AS ROWS.
                result += "<tbody>";
                for (var i = 0; i < invoices.length; i++) {
                    result += "<tr>";
                    for (var j = 0; j < col.length; j++) {
                        result += "<td>";
                        result += invoices[i][col[j]] == undefined ? 0 : invoices[i][col[j]];
                        result += "</td>";
                    }
                    result += "</tr>";
                }
                result += "</tbody>";
                result += "</table>";
                //console.log(result);
                this.setState({ invoices: result, loading: false })
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});

        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()

        let searchTransactionFromDate =  new Date().toISOString().split('T')[0]
        let searchTransactionToDate =  new Date().toISOString().split('T')[0] 

        this.setState({
            selectedYear: selectedYear,
            selectedMonth: selectedMonth,
            searchTransactionFromDate: searchTransactionFromDate,
            searchTransactionToDate: searchTransactionToDate,
            storeList : storeList,
            storeID : this.props.user.store.key, 
            recordFrom: 0, searchTransactionNo: '',
            searchCustomerDetail: '', searchProductDetail: '', searchOffer: ''
        })
        this.loadInvoiceDetailReport(storeList, 0, "", searchTransactionFromDate, searchTransactionToDate, "", "", "");
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        let gstinCustomFieldNo= this.getGSTINCustomField()
        this.props.getOfferReport(this.props.user.user.RegistrationID, this.state.storeList, 0, 10000,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail,
            this.props.type,
            this.props.location.state.offerName)
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let invoices = []
                result.searchResult.map((data) => {
                    let invoice = {
                        "Store Name ": data.StoreName,
                        // "Cash Register": data.CashRegisterName,
                        "Customer Code": data.CustomerCode,
                        "Customer Name": data.CustomerName,
                        "Customer Mobile No.": data.CustomerPhoneNo,
                        "Customer GSTIN":""
                    }

                    if(gstinCustomFieldNo ===1)
                    {
                        invoice["Customer GSTIN"] =  data.hasOwnProperty('CustomerCustomField1') ? data.CustomerCustomField1 : ""
                    }
                    else if(gstinCustomFieldNo ===2)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField2') ? data.CustomerCustomField2 : ""
                    }
                    else if(gstinCustomFieldNo ===3)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField3') ? data.CustomerCustomField3 : ""
                    }
                    else if(gstinCustomFieldNo ===4)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField4') ? data.CustomerCustomField4 : ""
                    }
                    else if(gstinCustomFieldNo ===5)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField5') ? data.CustomerCustomField5 : ""
                    }
                    else if(gstinCustomFieldNo ===6)
                    {
                        invoice["Customer GSTIN"] = data.hasOwnProperty('CustomerCustomField6') ? data.CustomerCustomField6 : ""
                    }
                   
                    invoice["Transaction No"] = data.TransactionNo
                    invoice["Transaction Type"] = data.TransactionType
                    invoice["Transaction Date"] = (new Date(data.TransactionDate).toISOString().split('T')[0])
                    invoice["Offer"] = data.OfferName
                    invoice["Offer Type"] = data.OfferType

                    invoice.Cancelled = "No"
                    if (data.CancellationReason) {
                        invoice.Cancelled = "Yes"
                    }
                    invoice["Type"] = data.ProductType
                    invoice["Brand"] = data.Brand ? data.Brand : ""
                    invoice["Category"] = data.Category
                    invoice["Sub Category"] = data.SubCategory ? data.SubCategory : ""
                    invoice["Item Code"] = data.SKU
                    invoice["Item Name"] = data.ProductName
                    invoice["Employee"] = data.hasOwnProperty('EmployeeName') ? data.EmployeeName : ''
                    invoice["Retail Price"] = data.RetailPrice
                    invoice["Qty"] = data.Qty
                    invoice["Taxble Amount"] = data.TaxableAmount
                    invoice["Offer Discount %"] = data.OfferPer
                    invoice["Offer Discount Amt"] = data.OfferAmt
                   
                    invoice["Sub Total"] = data.SubTotal
                    invoice["Total Tax"] = data.TotalTax
                   
                    invoice["Line Amount"] = data.Amount
                    invoice["Bill Amount"] = data.hasOwnProperty('BillAmount') ?  data.BillAmount : 0

                    invoices.push(invoice);
                })
                const csvData = constatnts.objToCSV(invoices);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                let fileName = (this.props.type === "offer" ? "Offer Detailed Report.csv" : "Coupon Detailed Report.csv") 
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true })
        this.loadInvoiceDetailReport(
            this.state.storeList,
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate, 
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail,
            this.state.searchOffer
        )
    }

    onStoreChange(e){
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>{this.props.type === "offer" ? "Offer Detailed Report" : "Coupon Detailed Report" }</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: ( this.props.type === "offer" ?  "/home/reports/offersummary" : "/home/reports/couponsummary" ) })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "5px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download " ></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {/* <div className="row">
                                    <div className="col-md-4">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>{this.props.type === "offer" ? "Offer Name" : "Coupon Name" }</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchOffer: evt.target.value })}
                                                value={this.state.searchOffer}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Customer Name,Code,Phone Number,Email</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchCustomerDetail: evt.target.value })}
                                                value={this.state.searchCustomerDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Name,Code,</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchProductDetail: evt.target.value })}
                                                value={this.state.searchProductDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download " ></i></button>
                                    </div>
                                </div>
                                */}
                               
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{width:"auto", overflowX:"auto"}} className="scroll2">
                                        {ReactHtmlParser(this.state.invoices)}
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(OfferReport)