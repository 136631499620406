import React from "react";
import Button from "./Button";
import Input from "./Input";
import ImageButton from "./ImageButton";

export default function Grid(props) {
  // const [selectAll, setSelectAll]= useState(false);

  const rowButtonWidth = "100px";

  return (
    <React.Fragment>
      {props.columns && props.data && (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              {props.columns.map((c, index) => (
                <th key={index}>
                  {c.Type === "checkbox" && (
                    <Input
                      type="checkbox"
                      noMargin={true}
                      value={props.selectAll}
                      onChange={(value) => {
                        if (props.onSelectAll) {
                          props.onSelectAll(value);
                        }
                      }}
                    />
                  )}
                  {c.Name}
                </th>
              ))}
              {props.buttons && props.buttons.length > 0 && (
                <th
                  className="list-page-row-btns-th"
                  style={{ width: rowButtonWidth }}
                  key={"rowBtns"}
                ></th>
              )}
            </tr>
          </thead>
          <tbody>
            {props.data.map((l, index) => (
              <React.Fragment>
                <tr key={"tdtr" + index}>
                  {props.columns.map((col, colIndex) => (
                    <td key={"td" + colIndex}>
                      {col.Type === "checkbox" && (
                        <Input
                          type="checkbox"
                          value={l.Selected}
                          onChange={() => {
                            if (props.onChange) {
                              l.Selected = !l.Selected;
                              props.onChange(index, l);
                            }
                          }}
                          noMargin={true}
                        />
                      )}
                      {col.Columns &&
                        col.Columns.length > 0 &&
                        col.Columns.map((subCol, subColIndex) => (
                          <div
                            key={"tddiv" + subColIndex}
                            className={subCol.Type}
                          >
                            {subCol.Input && subCol.Type && subCol.Type !== "" && (
                              <Input
                                type={subCol.Type}
                                value={l[subCol.Column]}
                                // onKeyDown={}
                                onChange={(value) => {
                                  if (props.onChange) {
                                    // console.log('value', value);
                                    l[subCol.Column] = value;
                                    props.onChange(index, l);
                                    // console.log('l[subCol.Column]', l[subCol.Column]);
                                  }
                                }}
                              />
                            )}
                            {!subCol.Input && l[subCol.Column]}
                          </div>
                        ))}
                    </td>
                  ))}
                  {props.buttons && props.buttons.length > 0 && (
                    <td key={"rowBtns" + index}>
                      <div className="list-page-row-btns">
                        {props.buttons.map((button) => (
                          <React.Fragment>
                            {button.ButtonType.toLowerCase() === "button" && (
                              <Button
                                type={button.Type}
                                text={button.Text}
                                label={button.Text}
                                icon={button.Icon}
                                onClick={() =>
                                  props.onRowButtonClicked(button, index, l)
                                }
                              />
                            )}
                            {button.ButtonType.toLowerCase() ===
                              "imagebutton" && (
                              <ImageButton
                                type={button.Type}
                                label={button.Text}
                                onClick={() =>
                                  props.onRowButtonClicked(button, index, l)
                                }
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </td>
                  )}
                </tr>
                {l.Error && l.Error !== "" && (
                  <tr key={"tdtreror" + index} className="error-msg">
                    <td colSpan={props.columns.length}>{l.Error}</td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
}
