import React, { Component } from 'react';
import Form from '../../2/Controls/Form';
import Input from '../../2/Controls/Input';
import ImageButton from '../../2/Controls/ImageButton';

import Modal from 'react-responsive-modal';
import { withRouter } from 'react-router-dom';
import ImportMaster from "../../ImportMaster";
import Confirm from 'react-confirm-bootstrap';

import HeaderNew from '../../HeaderNew'
import '../../Common/Common.css'
import Footer from '../../Common/Footer'

var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;



class EmployeeServiceMapping extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            key: '',
            showImport: false,
            records: [],
            selectAll: false,
            isProductTypeAheadLoading: false,
            productsTypeAheadOptions: [],

        }
        this.onSaveMappings = this.onSaveMappings.bind(this);
        this.onDeleteItems = this.onDeleteItems.bind(this);
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
        this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
        this.onProductSelected = this.onProductSelected.bind(this);
    }

    componentDidMount() {

        //console.log(this.props.employee);
        this.props.getEmployeeServices(this.props.employee.key).then((mapping) => {
            // console.log(JSON.stringify(tag))
            if (mapping) {
                mapping.Products.map(m => m.selected = false)
                this.setState({
                    records: mapping.Products,
                    key: mapping.key
                })
            }
        })
    }

    onSaveMappings() {
        this.setState({ saving: true })

        if (this.state.records.length === 0) {
            alert('Please add atleast one service');
            this.setState({ saving: false })
            return;
        }

        let mapping = {
            EmployeeID: this.props.employee.key,
            EmployeeName: (this.props.employee.EmployeeFirstName.trim() + ' ' + this.props.employee.EmployeeLastName.trim()).trim(),
            StoreID: this.props.user.store.key,
            RegistrationID: this.props.user.user.RegistrationID,
            Products: this.state.records,
            IsDeleted: false,
        }
        const key = this.state.key ? this.state.key : "";
        //console.log(key);

        this.props.saveEmployeeServices(key, mapping).then(() => {
            setTimeout(function () { //Start the timer
                this.props.getToastr("Service Mapping saved successfully")
                this.setState({ saving: false });
                this.props.onClose();
            }.bind(this), 3000)
        });
    }

    onDeleteItems() {
        let records = this.state.records
        if (records.filter(f => f.Selected).length === 0) {
            alert('Please select records')
            return
        }
        let recordsTmp = []
        records.map(m => {
            if (!m.Selected) {
                recordsTmp.push(m)
            }
        })
        this.setState({ records: recordsTmp })
    }

    onProductTypeAheadSearch = async (query) => {
        this.setState({ isProductTypeAheadLoading: true });
        let searchedProducts = [];
        
        searchedProducts = await this.props.config.Products.filter(p => (p.SKU.toLowerCase().includes(query.toLowerCase())
            || p.Name.toLowerCase().includes(query.toLowerCase()) || p.BarCode.toLowerCase().includes(query.toLowerCase())) && !p.IsDeleted && p.ProductTypeID === 3);

        this.setState({ productsTypeAheadOptions: searchedProducts, isProductTypeAheadLoading: false })
    }

    onProductBarCodeSearch(evt) {

        if (evt.key === 'Enter') {
            var productList = this.props.config.Products.filter((e) => e.SKU.toLowerCase() === this.state.queryText.toLowerCase() || e.BarCode.toLowerCase() === this.state.queryText.toLowerCase())
            if (productList.length === 0) {
                alert('Product not found')
                return
            }
            this.onProductSelected(productList[0])
        }
    }

    onProductSelected(product) {
        //check if product is arelady added
        let records = this.state.records
        if (records.filter(f => f.ProductID === product.key).length > 0) {
            alert('Product already added')
            return
        }
        records.unshift({
            ProductID: product.key,
            SKU: product.SKU,
            Name: product.Name,
            RetailPrice: '',
        })
        this.setState({ records: records })
    }

    render() {
        return <Form
            title="Service Mapping"
            onClick={(action)=>{
                if(action==="save"){
                    this.onSaveMappings();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
            <Input
                label="Employee Code"
                type="text"
                value={this.props.employee.EmployeeCode}
                disabled={true}
            />
            <Input
                label="Employee Name"
                type="text"
                value={(this.props.employee.EmployeeFirstName.trim() + ' ' + this.props.employee.EmployeeLastName.trim()).trim()}
                disabled={true}
            />
            <div className="row">
                <div className="col-md-12">
                    <label >Selected Service(s) </label>
                    <AsyncTypeahead
                        autoFocus={true}
                        allowNew={false}
                        isLoading={this.state.isProductTypeAheadLoading}
                        multiple={false}
                        options={this.state.productsTypeAheadOptions}
                        labelKey="Name"
                        minLength={3}
                        onSearch={this.onProductTypeAheadSearch}
                        placeholder="Start typing or scanning to search products"
                        filterBy={['Name', 'SKU', 'BarCode']}
                        autoFocus={true}
                        dropup={false}
                        highlightOnlyResult={true}
                        selected={this.state.productsTypeAheadSelected}
                        onChange={(selected) => {
                            this.onProductSelected(selected[0])
                            this.setState({ productsTypeAheadSelected: [] })
                        }}
                        renderMenuItemChildren={(option, props) => (
                            <div>
                                <div><b>{option.Name}</b></div>
                                <div>{option.SKU}</div>
                            </div>
                        )}
                    />
                </div>
            </div>
            <br/>
           <div className="row">
                <div className="col-md-12">
                    {this.state.records.length > 0 && <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                {/* <th>
                                    <input type="checkbox"
                                        checked={this.state.selectAll}
                                        onChange={() => {
                                            let records = this.state.records
                                            records.map((b) => { b.Selected = !this.state.selectAll })
                                            this.setState({ selectAll: !this.state.selectAll, records: records })
                                        }}
                                    />
                                </th> */}
                                <th>SKU</th>
                                <th>Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.records.map((product, index) =>
                                <tr>
                                    {/* <td>
                                        <input type="checkbox"
                                            checked={product.Selected}
                                            onChange={() => {
                                                let records = this.state.records
                                                records[index].Selected = !records[index].Selected
                                                this.setState({ records: records })
                                            }}
                                        />
                                    </td> */}
                                    <td>
                                        {product.SKU}
                                    </td>
                                    <td>
                                        {product.Name}
                                    </td>
                                    <td>
                                        <ImageButton type="delete" icon="delete" onClick={()=>{
                                             let records = this.state.records;
                                             records.splice(index,1);
                                             this.setState({ records: records });
                                        }}/>
                                       
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>}
                </div>
            </div>
        </Form>
    }
}

export default EmployeeServiceMapping



{/* <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                        <div style={modalWidth}>
                            <ImportMaster   {...this.props}
                                importType="employeeservices"
                                onClose={() => this.setState({ showImport: false })}
                                onImportCompletion={(importRecords) => {
                                    let records = this.state.records
                                    for (let i = 0; i < importRecords.length; i++) {
                                        if (records.filter(f => f.ProductID === importRecords[i].ProductID).length === 0) {
                                            //new product 
                                            records.push(importRecords[i])
                                        }
                                    }
                                    this.setState({ records: records, showImport: false })
                                }
                                }
                            />
                        </div>
                    </Modal> */}