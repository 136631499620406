import firebase, {database, functions} from '../firebase'
import * as constants from '../Constatnts'

export function addProductsFoodDeliveryPlatforms(foodDeliveryPlatformProduct){
    return (dispatch, getState) =>{
        foodDeliveryPlatformProduct.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        foodDeliveryPlatformProduct.ActionBy = getState().user.user.Name
        foodDeliveryPlatformProduct.ActionByUID = getState().user.user.UID
        foodDeliveryPlatformProduct.ActionByEmailID = getState().user.user.EmailID
        foodDeliveryPlatformProduct.Action = constants.AUDIT_TRAIL_NEW
        return database.collection("FoodDeliveryPlatformProducts").add({
          ...foodDeliveryPlatformProduct
        })
        .then(function(docRef) {
            return docRef.id
        })
        .catch(function(error) {
            console.error("Error saving foodDeliveryPlatformProduct : ", error);
        })
    }
}

export function updateProductsFoodDeliveryPlatforms(key, foodDeliveryPlatformProduct){
    return (dispatch, getState) =>{
        // console.log('foodDeliveryPlatformProduct', foodDeliveryPlatformProduct)
        foodDeliveryPlatformProduct.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        foodDeliveryPlatformProduct.ActionBy = getState().user.user.Name
        foodDeliveryPlatformProduct.ActionByUID = getState().user.user.UID
        foodDeliveryPlatformProduct.ActionByEmailID = getState().user.user.EmailID
        foodDeliveryPlatformProduct.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("FoodDeliveryPlatformProducts").doc(key).update({
          ...foodDeliveryPlatformProduct
        })
    }
}

export function addToFoodDeliveryPlatformUpdateQueue(message){
    return (dispatch, getState) =>{
        message.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        message.ActionBy = getState().user.user.Name
        message.ActionByUID = getState().user.user.UID
        message.ActionByEmailID = getState().user.user.EmailID
        message.Action = constants.AUDIT_TRAIL_NEW
        message.RegistrationID = getState().user.user.RegistrationID
        return database.collection("FoodDeliveryPlatformsQueue").add({
          ...message
        })
        .then(function(docRef) {
            return docRef.id
        })
        .catch(function(error) {
            console.error("Error saving message : ", error);
        })
    }
}


export function getFoodDeliveryPlatformProducts( storeID) {
    return (dispatch) => {
          return database.collection("FoodDeliveryPlatformProducts").where("StoreID","==", storeID).get().then((querySnapshot) => {
              const foodDeliveryPlatformProducts = []
              querySnapshot.forEach((doc) => {
                foodDeliveryPlatformProducts.push(
                    {
                      key:doc.id,
                      ...doc.data()
                    })
             })
             return foodDeliveryPlatformProducts
          })
      }
  }

 


export function createSalesTransactionForUrbanPiperOrder(urbanPiperOrderID) {   
    return (dispatch,getState) => {
        let addMessage = firebase.functions().httpsCallable('CreateSalesTransactionForUrbanPiperOrder');
        return addMessage({ text: {UrbanPiperOrderID:urbanPiperOrderID, CashRegister: getState().user.cashRegister, User: getState().user.user} }).then(function (result) {
            return result
        });
    }
}

export function cancelUrbanPiperOrder(urbanPiperOrderID, cancellationReason) {
    return (dispatch) => {
        return database.collection("UrbanPiperOrders").doc(urbanPiperOrderID).update({
            Status: "Cancelled", Message: cancellationReason,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp()
        })
    }
}

export function getLatestOrderNo()
{
    return (dispatch, getState) => {
        if(getState() && getState().user && getState().user.store)
        {
            return database.collection("UrbanPiperOrders").where("StoreID","==", getState().user.store.key).orderBy("OrderID","desc").limit(1).get().then((querySnapshot) => {
                let order = {}
                querySnapshot.forEach((doc) => {
                    order = { key: doc.id, ...doc.data() }
               })
               return order
            })
        }
        return null;
    }
}

export function getCompletedCancelledOrders(status) {
    return (dispatch, getState) => {
        console.log("Status", status);
        return database.collection("UrbanPiperOrders").where("StoreID", "==", getState().user.store.key).where("Status", "==", status).orderBy("ActionOn","desc").get().then((querySnapshot) => {
            const orders = []
            querySnapshot.forEach((doc) => {
                orders.push(
                    {
                        key: doc.id,
                        ...doc.data()
                    })
            })
           return orders
        })
    }
}

export function getInProgressOrders() {
    return (dispatch, getState) => {
        return database.collection("UrbanPiperOrders").where("StoreID","==", getState().user.store.key).orderBy("ActionOn","asc").get().then((querySnapshot) => {
            const orders = []
            querySnapshot.forEach((doc) => {
                if (doc.data().Status === 'Acknowledged' || doc.data().Status === 'Food Ready' || doc.data().Status === 'Dispatched')
                {
                    orders.push(
                        {
                            key: doc.id,
                            ...doc.data()
                        })
                }
           })
           return orders
        })
    }
}

export function getFoodDeliveryNewOrders() {
    return (dispatch,getState) => {
          return database.collection("UrbanPiperOrders").where("StoreID","==", getState().user.store.key).orderBy("ActionOn", "desc").get().then((querySnapshot) => {
              const orders = []
              querySnapshot.forEach((doc) => {
                  if(!doc.data().hasOwnProperty('SaleID') && doc.data().Status.toLowerCase() !== "cancelled")
                  {
                    orders.push(
                        {
                          key:doc.id,
                          ...doc.data()
                        })
                  }
             })
             return orders
          })
      }
  }