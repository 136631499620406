import React, { Component } from 'react'
import Confirm from 'react-confirm-bootstrap';

class AddEditModifierGroup extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            modifierGroups: [],
            modifierGroupName: '',
            modifierGroupError: '',
            editModifierGroupName: '',
            editModifierGroupError: '',
            edit:-1
        }
        this.onSaveModifierGroup = this.onSaveModifierGroup.bind(this)
        this.onRemoveModifierGroup = this.onRemoveModifierGroup.bind(this)
        this.loadModifierGroups= this.loadModifierGroups.bind(this)
    }

    componentDidMount() {
        this.loadModifierGroups()
    }    

    loadModifierGroups()
    {
        this.props.getModifierGroups(this.props.user.user.RegistrationID).then((modifierGroups) => {
            this.setState({modifierGroups : modifierGroups})
        })
    }

    onSaveModifierGroup(index) {
        
        let key = index >= 0 ? this.state.modifierGroups[index].key : ""
        let modifierGroupError = ""
        let editModifierGroupError=""
        if ( key.trim()==="" && this.state.modifierGroupName.trim() === "") {
            modifierGroupError = 'Please enter group name';
        }
        if ( key.trim()!=="" && this.state.editModifierGroupName.trim() === "") {
            editModifierGroupError = 'Please enter group name';
        }
        if(this.state.modifierGroups.filter(f=> !f.IsDeleted 
            && f.key !== key
            && f.Name.trim() ===this.state.modifierGroupName.trim()).length> 0)
        {
            modifierGroupError = 'Please group already exists';
        }
        if (modifierGroupError !=="" || editModifierGroupError !=="") {
            this.setState({ modifierGroupError: modifierGroupError, editModifierGroupError:editModifierGroupError });
            return;
        }
        
        let omniTag = '';
        let name =key.trim()!=="" ? this.state.editModifierGroupName :  this.state.modifierGroupName;
        omniTag = name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(' ','_');

        let modifierGroup = {
            Name: name, 
            IsDeleted: false,
            RegistrationID: this.props.user.user.RegistrationID,
            OMNITag:omniTag
        }
        
        this.props.saveModifierGroup(modifierGroup, key).then(()=>{
            //reload modifier groups
            //toast 
            this.setState({modifierGroupName:"", editModifierGroupName:"", edit:-1})
            this.loadModifierGroups()
            this.props.getToastr("Modifier group saved successfully")
        })     
    }

    onRemoveModifierGroup(modifierGroupID) {
        this.props.deleteModifierGroup(modifierGroupID).then(()=>{
            this.loadModifierGroups()
            this.props.getToastr("Modifier group deleted successfully")
        })
    }

    render() {
        const btnColStyle = { width: "200px" }
        return <React.Fragment>
                <div className="form-header">Modifier Group(s)</div>
                <div className="form-content">
                    <div className="row">
                        <div className="col-md-12">
                            <table>
                                <tr>
                                    <td className={this.state.modifierGroupError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control" 
                                            placeholder="Enter Modifier Group"
                                            onChange={(evt) => this.setState({ modifierGroupName: evt.target.value, modifierGroupError: '' })}
                                            value={this.state.modifierGroupName} />
                                        {this.state.modifierGroupError !== "" && <span className="help-block">{this.state.modifierGroupError}</span>}
                                    </td>
                                    <td style={{ verticalAlign: "top"}}>
                                            <button type="button" className="btn btn-flat btn-md btn-flat btn-default" onClick={()=> this.setState({modifierGroupName:""})}>Cancel</button>
                                            <button type="button" className="btn btn-flat btn-md btn-flat btn-success" onClick={()=>this.onSaveModifierGroup(-1)} disabled={this.state.saving}>Save</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    {this.state.modifierGroups && this.state.modifierGroups.length > 0 &&
                    <table className="table table-bordered table-striped" style={{ marginTop: "10px" }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th style={btnColStyle}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.modifierGroups.filter((e) => !e.IsDeleted).map((group, index) =>
                                <tr>
                                    {!(this.state.edit === index)&& <td>
                                        {group.Name}
                                    </td>}
                                        {(this.state.edit === index) && <td>
                                    <input type="text" className="form-control"                                        
                                        onChange={(evt) => {
                                            this.setState({editModifierGroupName:evt.target.value, editModifierGroupError:""}) 
                                        }}                                        
                                        value={this.state.editModifierGroupName}/>
                                    </td>}
                                    <td>
                                    {!(this.state.edit === index) && <button type="button" className="btn btn-md btn-flat btn-default " 
                                            onClick={() => {
                                                this.setState({edit:index, editModifierGroupName:group.Name})}}>
                                                <i className="fa fa-edit"></i>Edit
                                        </button>}
                                        {!(this.state.edit === index)&& <Confirm
                                                                    onConfirm={() => this.onRemoveModifierGroup(group.key)}
                                                                    body="Are you sure you would like to delete this modifier group?"
                                                                    confirmText="Delete"
                                                                    title="Delete Modifier Group">
                                                                    <button className="btn btn-md btn-default btn-flat"><i className="fa fa-trash-o"></i>Delete</button>
                                                                </Confirm>}
                                        {(this.state.edit === index) && <button className="btn btn-md btn-flat btn-default"
                                            onClick={() => this.onSaveModifierGroup(index )}><i className="fa fa-check-square"></i>Save</button>}
                                        {(this.state.edit === index) && <button className="btn btn-md btn-flat btn-default"
                                            onClick={() => this.setState({edit:-1} )}>Cancel</button>}
                                            
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>}
                </div>
        </React.Fragment>
    }
}

export default AddEditModifierGroup