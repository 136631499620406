import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Pie } from 'react-chartjs-2';

class WidgetQuarterSales extends Component {

    constructor({ stores }) {
        super();
        this.state = {
            stores: stores,
            showQuarterSalesList: false,
            showQuarterSalesChart: true,
            salesQuarterDashboard:{
                labels: [],
                datasets: []
            }
        }
    }

    async componentDidMount() {
        this.loadData(this.state.stores);
    }

    loadData(stores) {

        let searchTransactionFromDate = new Date();

        var date = new Date(), y = date.getFullYear();
        var firstDay = new Date(y, 0, 1);

        let fromDate = new Date(firstDay).setHours(23, 59)

        searchTransactionFromDate = new Date(fromDate).toISOString().split('T')[0]
        let searchTransactionToDate = new Date().toISOString().split('T')[0];

        this.props.getSalesForLast12Months(this.props.user.user.RegistrationID, stores, searchTransactionFromDate, searchTransactionToDate, "quarter")
            .then((result) => {

                let salesQuarterDashboard = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Sales',
                            backgroundColor: ['#6e2d90', '#ff5252', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
                                '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                                '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                                '#C77B85', '#C39762'],
                            hoverBackgroundColor: ['#6e2d90', '#ff5252', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
                                '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                                '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                                '#C77B85', '#C39762'],
                            data: []
                        }
                    ]
                };

                let salesQuarterDashboardData = []

                for (let index = 0; index < result.searchResult.length; index++) {

                    const data = result.searchResult[index];
                    salesQuarterDashboard.labels.push(data.date);
                    salesQuarterDashboard.datasets[0].data.push(Number(data.total_sales).toFixed(2));

                    var obj = {
                        "Quarter": data.date,
                        "Total": data.total_sales
                    }
                    salesQuarterDashboardData.push(obj);
                }

                var col = [];
                for (var i = 0; i < salesQuarterDashboardData.length; i++) {
                    for (var key in salesQuarterDashboardData[i]) {
                        if (col.indexOf(key) === -1) {
                            col.push(key);
                        }
                    }
                }
                var result = "<table class='table table-bordered table-striped'><thead><tr>";
                for (var i = 0; i < col.length; i++) {
                    result += "<th>";
                    result += col[i];
                    result += "</th>";
                }
                result += "</tr></thead>";

                // ADD JSON DATA TO THE TABLE AS ROWS.
                result += "<tbody>";
                for (var i = 0; i < salesQuarterDashboardData.length; i++) {
                    result += "<tr>";
                    for (var j = 0; j < col.length; j++) {
                        result += j === 0 ? "<td>" : "<td style='text-align:right'>";
                        result += salesQuarterDashboardData[i][col[j]] == undefined ? 0 : salesQuarterDashboardData[i][col[j]];
                        result += "</td>";
                    }
                    result += "</tr>";
                }
                result += "</tbody>";
                result += "</table>";
                this.setState({ salesQuarterDashboard: salesQuarterDashboard, salesQuarterDashboardData: result });
            });
    }

    componentWillReceiveProps({ stores, fromDate, toDate }) {
        this.loadData(stores, fromDate, toDate);
    }

    render() {
        return <div className="dashboard-box " >
                <div className="dashboard-box-header">
                    <div className="dashboard-box-title left">Quarterly Sales</div>
                    <div className="right">
                    <button type="button"
                        className="btn btn-default btn-flat btn-md pull-right fa fa-list"
                        style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showQuarterSalesList ? "#6e2d90" : "gray" }}
                        onClick={() => this.setState({ showQuarterSalesChart: false, showQuarterSalesList: true })}></button>
                    <button type="button"
                        className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
                        style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showQuarterSalesChart ? "#6e2d90" : "gray" }}
                        onClick={() => this.setState({ showQuarterSalesChart: true, showQuarterSalesList: false })}></button>
                    </div>
                </div>
                <div className="dashboard-box-body">
                    {this.state.showQuarterSalesChart && <div className="chart" >
                                <Pie
                                    width={623}
                                    height={300}
                                    data={this.state.salesQuarterDashboard}
                                    options={{
                                        maintainAspectRatio: false
                                    }}
                                />
                    </div>}
                    {this.state.showQuarterSalesList && 
                            <div className="table-responsive scroll2">
                                {ReactHtmlParser(this.state.salesQuarterDashboardData)}
                            </div>}
                </div>
            </div>}
}
export default WidgetQuarterSales