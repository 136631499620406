import React, { Component } from 'react';
// import ReactTooltip from 'react-tooltip';
import Spinner from '../components/Common/Spinner'

class Loader extends Component {
    // constructor() {
    //     super()
    // }

    // componentDidMount() {
    //     console.log(this.props.show)
    // }

    // render() {
    //     const loaderStyle = { margin_left: "auto", margin_right: "auto", display: "block", width: "100%" }

    //     return <div className="col-sm-6 col-sm-offset-6 col-md-6 col-md-offset-6" style={loaderStyle} >
    //         <img src={require('../images/Spin-1s-75px1.gif')} className="loading" alt="Loading" />
    //     </div>
    // marginLeft:"-70px",
    // }
    render() {
        // const loaderStyle = {
        //     margin_left: "auto", margin_right: "auto", display: "block", width: "100%",
        // }

        return <Spinner />
        // <div className="col-md-12" style={{ textAlign: "center" }}>
        //     <img src={!window.cordova ? require('../images/Spin-1s-75px1.gif') : "/android_asset/www/images/Spin-1s-75px1.gif"} className="loading" alt="Loading" /><br></br>
        //     {this.props.value && <div style={loaderStyle}><h3 style={{ fontFamily: "Nunito", color: "#00a65a" }}>{this.props.value}</h3></div>}
        // </div>

           
    }
}

export default Loader