import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function saveExpenseCategories(Expense, key){
    return (dispatch, getState) =>{
        Expense.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        Expense.ActionBy = getState().user.user.Name
        Expense.ActionByUID = getState().user.user.UID
        Expense.ActionByEmailID = getState().user.user.EmailID
        if(key.trim()!== "")
        {
            Expense.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("ExpenseCategories").doc(key).update({
                ...Expense
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating Expense Categories: ", error);
              })
        }
        else
        {
            Expense.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("ExpenseCategories").add({
                ...Expense
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding Expense Categories: ", error);
              })
        }
    }
}

export function deleteExpenseCategories(key)
{
    return (dispatch, getState) => {
        return database.collection("ExpenseCategories").doc(key).update({
            IsDeleted : true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            
            Action: constants.AUDIT_TRAIL_DELETE
        }).then(()=> {return true})
    }
}


export function getExpenseCategories(registrationID, storeID) {
  return (dispatch) => {
        return database.collection("ExpenseCategories").where("StoreID","==", storeID).where("RegistrationID","==", registrationID).get().then((querySnapshot) => {
            const ExpenseCategories = []
            querySnapshot.forEach((doc) => {
                ExpenseCategories.push(
                  {
                    key:doc.id,
                    ...doc.data()
                  })
           })
         
           return ExpenseCategories
       
        })
    }
}

export function getActiveExpenseCategories(registrationID,storeID) {
    return (dispatch) => {
        return database.collection("ExpenseCategories").where("RegistrationID","==", registrationID).where("StoreID","==", storeID).where("IsDeleted","==",false).orderBy("ExpenseCategory","asc").get().then((querySnapshot) => {
            const ExpenseCategories = []
            querySnapshot.forEach((doc) => {
                ExpenseCategories.push(
                  {
                    key:doc.id,
                    ...doc.data()
                  })
           })
      
           return ExpenseCategories
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function checkIfExpenseCategoriesIsUnique(ExpenseCategory, registrationID, storeID)
{
    return (dispatch) => {
        let ExpenseIsUnique = true
            return database.collection("ExpenseCategories").where("RegistrationID","==", registrationID).where("StoreID","==", storeID).where("ExpenseCategory","==",ExpenseCategory).where("IsDeleted","==",false).limit(1).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    ExpenseIsUnique = false
                    console.log('insise loop')
            })
            console.log('ExpenseIsUnique', ExpenseIsUnique)
            return ExpenseIsUnique
        }).catch((error) => {
        console.log(error)
        })
    }
}
