import React, { Component } from 'react'
import './TabStrip.css'

class TabStrip extends Component {
    render() {
    return <div className="tabstrip">
        {this.props.tabButtons.map((m,index)=><button key={"tab_button_" + index} 
            type="button" 
            className={this.props.selectedTabValue ===m.Value ? "tabstrip-button-selected" :"tabstrip-button" } 
            onClick={()=>this.props.onTabSelected(m.Value)}>{m.Text}
        </button>
        )}       
    </div>
    }
    }

export default TabStrip