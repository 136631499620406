import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Form from "../2/Controls/Form";
import { searchPincodeGlobalCatalog } from "../../services/api/PincodeAPI";
import { checkIfProductAlreadyExist } from "../../services/api/ProductAPI";
import "./ProductQuickAdd.css";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import { isMobileApp } from "../../actions/Util";
import Icon from "../2/Controls/Icon";
import BarcodeScanner from "./BarcodeScanner";

class ProductQuickAdd extends Component {
  constructor() {
    super();
    this.inputRef = React.createRef();
    this.scanRef = React.createRef();
    this.searchProductNameRef = React.createRef();

    this.state = {
      key: "",
      SKU: "",
      SKUError: "",
      isGlobalProduct: false,
      globalCatalogProduct: null,
      globalCatalogCode: "",
      globalCatalogImage: "",
      productTypeID: 1,
      productType: "Goods for sale",
      sku: "",
      barCode: "",
      name: "",
      category: "",
      categoryID: "",
      subCategoryID: "",
      subCategory: "",
      packingQty: "",
      packingUOM: "",
      retailPrice: "",
      MRP: "",
      OMNIEnabled: true,
      outOfStock: false,
      brandID: "",
      brand: "",
      pincodeProductType: "",
      pincodeProductProductType: "", //PincodeProductType
      maxOrderQty: "",
      pincodeModel: "",
      pincodePackaging: "",
      pincodeBundling: "",
      description: "",
      pincodeCancellable: false,
      pincodeReturnable: false,
      pincodeManufacturer: "",
      pincodeMRP: "",
      codeSearchOptions: [],
      nameSearchOptions: [],
      processingSearch: false,

      categories: [],
      subCategories: [],
      filteredSubCategories: [],
      brands: [],
      productTypes: [],
      uoms: [],
      models: [],
      packaging: [],
      bundling: [],
      images: [],
      nameError: "",
      barCodeError: "",
      categoryError: "",
      subCategoryError: "",
      mrpError: "",
      retailPriceError: "",
      packingQtyError: "",
      packingUOMError: "",
      pincodeProductProductTypeError: "",
      includeSKU: false,
      saving: false,
      stores: [],
      storeSpecificPrices: [],
      pincodeProductProductTypeTA: [],
      packingUOMTA: [],
      pincodeModelTA: [],
      pincodeBundlingTA: [],
      pincodePackagingTA: [],
      categoryTA: [],
      subcategoryTA: [],
      filteredSubCategories: [],
    };
    this.onCodeSearch = this.onCodeSearch.bind(this);
    this.onNameSearch = this.onNameSearch.bind(this);
    this.onSearchSelected = this.onSearchSelected.bind(this);
    this.removeGlobalCatalog = this.removeGlobalCatalog.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  async componentDidMount() {
    let storeSpecificPrices = [];
    // let stores = await this.props
    //   .getStores(this.props.registration.registration.key, 0, 1000)
    //   .then((result) => {
    //     return result.searchResult;
    //   });
    // console.log("stores", stores);
    let stores = this.props.config.Stores;
    // console.log("stores", stores);
    if (
      this.props.registration.registration.hasOwnProperty(
        "StoreSpecificCatalog"
      ) &&
      this.props.registration.registration.StoreSpecificCatalog
    ) {
      storeSpecificPrices.push({
        DiscountAmount: 0,
        DiscountPercentage: 0,
        EditWalletTopUpAmount: false,
        MRP: "",
        MRPError: "",
        MSP: "",
        Markup: "",
        PriceError: "",
        RetailPrice: "",
        Store:
          this.props.user.store.StoreCode +
          " " +
          this.props.user.store.StoreName,
        StoreID: this.props.user.store.key,
        SupplyPrice: 0,
        WalletTopUpAmount: 0,
        WalletTopUpSameAsRetailPrice: false,
        OMNIEnabled: false,
        OutOfStock: false,
      });
    } else {
      stores.map((s) => {
        storeSpecificPrices.push({
          DiscountAmount: 0,
          DiscountPercentage: 0,
          EditWalletTopUpAmount: false,
          MRP: "",
          MRPError: "",
          MSP: "",
          Markup: "",
          PriceError: "",
          RetailPrice: "",
          Store: s.StoreCode + " " + s.StoreName,
          StoreID: s.key,
          SupplyPrice: 0,
          WalletTopUpAmount: 0,
          WalletTopUpSameAsRetailPrice: false,
          OMNIEnabled: false,
          OutOfStock: false,
        });
      });
    }

    this.scanRef.current.getInstance().focus();
    let categories = this.props.config.PincodeCategories;
    let subCategories = this.props.config.PincodeSubCategories;
    let brands = this.props.config.PincodeBrands;
    let productTypes = this.props.config.PincodeProductTypes;
    let uoms = this.props.config.PincodeUOMs;
    let models = this.props.config.PincodeModels;
    let packaging = this.props.config.PincodePackaging;
    let bundling = this.props.config.PincodeBundling;

    let globalCatalogProduct = null;
    let includeSKU = false;
    let key = "";
    let name = "";
    let SKU = "";
    let barCode = "";
    let categoryID = "";
    let category = "";
    let subCategoryID = "";
    let subCategory = "";
    let brand = "";
    let brandID = "";
    let MRP = "";
    let retailPrice = "";
    let pincodeProductProductType = "";
    let packingQty = "";
    let packingUOM = "";
    let pincodeModel = "";
    let pincodePackaging = "";
    let pincodeBundling = "";
    let pincodeCancellable = "";
    let pincodeReturnable = "";
    let pincodeManufacturer = "";
    let description = "";
    let maxOrderQty = "";
    let pincodeProductType = "Seller Code";
    let images = [];
    let OMNIEnabled = true;
    let outOfStock = false;
    let pincodeProductProductTypeTA = [];
    let packingUOMTA = [];
    let pincodeModelTA = [];
    let pincodeBundlingTA = [];
    let pincodePackagingTA = [];
    let categoryTA = [];
    let subCategoryTA = [];

    if (this.props.product) {
      let product = this.props.product;
      let storeID = product.hasOwnProperty("StoreID") ? product.StoreID : "";
      if (product.CustomField1.trim() !== "") {
        //this product is from global product
        globalCatalogProduct = {
          Code: product.SKU,
          Name: product.Name,
        };
      }
      key = product.key;
      name = product.Name;
      SKU = product.SKU;
      barCode = product.BarCode;
      categoryID = product.CategoryID;
      category = product.Category;
      subCategoryID = product.SubCategory;
      subCategory = product.SubCategory;
      brand = product.Brand;
      brandID = product.BrandID;
      OMNIEnabled = product.hasOwnProperty("OMNIEnabled")
        ? product.OMNIEnabled
        : true;
      outOfStock = product.hasOwnProperty("OutOfStock")
        ? product.OutOfStock
        : false;
      MRP = product.MRP;
      pincodeProductType = product.PinCodeProductType
        ? product.PinCodeProductType
        : "Seller Code";
      maxOrderQty = product.MaxOrderQty ? product.MaxOrderQty : "";
      retailPrice = product.RetailPrice;
      pincodeProductProductType = product.PincodeProductProductType
        ? product.PincodeProductProductType
        : "";
      packingQty = product.PincodePackingQty ? product.PincodePackingQty : "";
      packingUOM = product.PincodePackingUOM ? product.PincodePackingUOM : "";
      pincodeModel = product.PincodeModel ? product.PincodeModel : "";
      pincodePackaging = product.PincodePackaging
        ? product.PincodePackaging
        : "";
      pincodeBundling = product.PincodeBundling ? product.PincodeBundling : "";
      images = product.Images ? product.Images : [];
      pincodeCancellable = product.PincodeCancellable
        ? product.PincodeCancellable
        : false;
      pincodeReturnable = product.PincodeReturnable
        ? product.PincodeReturnable
        : false;
      description = product.description;
      pincodeManufacturer = product.PincodeManufacturer
        ? product.PincodeManufacturer
        : "";
      // if (
      //   product.StoreSpecificPrices &&
      //   product.StoreSpecificPrices.length > 0
      // ) {
      if (pincodeProductProductType !== "") {
        pincodeProductProductTypeTA = [{ Name: pincodeProductProductType }];
      }
      if (packingUOM !== "") {
        packingUOMTA = [{ Name: packingUOM }];
      }
      if (pincodeModel !== "") {
        pincodeModelTA = [{ Name: pincodeModel }];
      }
      if (pincodePackaging !== "") {
        pincodePackagingTA = [{ Name: pincodePackaging }];
      }
      if (pincodeBundling !== "") {
        pincodeBundlingTA = [{ Name: pincodeBundling }];
      }
      if (category !== "") {
        categoryTA = [{ Name: category }];
      }
      if (subCategory !== "") {
        subCategoryTA = [{ SubCategory: subCategory }];
      }
      if (storeID !== "") {
        storeSpecificPrices = product.StoreSpecificPrices;
      } else {
        storeSpecificPrices.map((m) => {
          let tmp1 = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === m.StoreID
          );
          if (tmp1.length > 0) {
            let tmp = tmp1[0];
            m.DiscountAmount = tmp.DiscountAmount;
            m.DiscountPercentage = tmp.DiscountPercentage;
            m.EditWalletTopUpAmount = tmp.EditWalletTopUpAmount;
            m.MRP = tmp.MRP;
            m.MRPError = tmp.MRPError;
            m.MSP = tmp.MSP;
            m.Markup = tmp.Markup;
            m.PriceError = tmp.PriceError;
            m.RetailPrice = tmp.RetailPrice;
            m.Store = tmp.Store;
            m.StoreID = tmp.StoreID;
            m.SupplyPrice = tmp.SupplyPrice;
            m.WalletTopUpAmount = tmp.WalletTopUpAmount;
            m.WalletTopUpSameAsRetailPrice = tmp.WalletTopUpSameAsRetailPrice;
            m.OMNIEnabled = tmp.hasOwnProperty("OMNIEnabled")
              ? tmp.OMNIEnabled
              : true;
            m.OutOfStock = tmp.hasOwnProperty("OutOfStock")
              ? tmp.OutOfStock
              : false;
          } else {
            // console.log("MRP", MRP);
            m.MRP = MRP;
            m.RetailPrice = retailPrice;
            m.OMNIEnabled = false;
            m.OutOfStock = false;
          }
        });
      }

      // }
    }

    if (this.props.registration.registration.EnablePincodeSKU) {
      includeSKU = this.props.registration.registration.EnablePincodeSKU;
    }
    // console.log("storeSpecificPrices", storeSpecificPrices);
    this.setState({
      OMNIEnabled: OMNIEnabled,
      outOfStock: outOfStock,
      stores: stores,
      storeSpecificPrices: storeSpecificPrices,
      includeSKU: includeSKU,
      key: key,
      globalCatalogProduct: globalCatalogProduct,
      name: name,
      SKU: SKU,
      barCode: barCode,
      description: description,
      category: category,
      categoryID: categoryID,
      subCategory: subCategory,
      subCategoryID: subCategoryID,
      brand: brand,
      brandID: brandID,
      MRP: MRP,
      pincodeProductType: pincodeProductType,
      maxOrderQty: maxOrderQty,
      retailPrice: retailPrice,
      pincodeProductProductType: pincodeProductProductType,
      packingQty: packingQty,
      packingUOM: packingUOM,
      pincodeModel: pincodeModel,
      pincodeBundling: pincodeBundling,
      pincodePackaging: pincodePackaging,
      pincodeCancellable: pincodeCancellable,
      pincodeReturnable: pincodeReturnable,
      pincodeManufacturer: pincodeManufacturer,
      categories: categories,
      subCategories: subCategories,
      brands: brands,
      productTypes: productTypes,
      uoms: uoms,
      models: models,
      packaging: packaging,
      bundling: bundling,
      images: images,
      pincodeProductProductTypeTA: pincodeProductProductTypeTA,
      packingUOMTA: packingUOMTA,
      pincodeModelTA: pincodeModelTA,
      pincodeBundlingTA: pincodeBundlingTA,
      pincodePackagingTA: pincodePackagingTA,
      categoryTA: categoryTA,
      subCategoryTA: subCategoryTA,
    });
  }

  async onCodeSearch(query) {
    this.setState({ processingSearch: true, barCode: query, barCodeError: "" });
    let result = await searchPincodeGlobalCatalog(query, "");
    if (result && result.length === 1 && result[0].Code === query) {
      this.onSearchSelected(result[0]);
      this.setState({ codeSearchOptions: [], processingSearch: false });
      return;
    }
    this.setState({ codeSearchOptions: result, processingSearch: false });
  }

  async onNameSearch(query) {
    this.setState({ processingSearch: true, name: query });
    let result = await searchPincodeGlobalCatalog("", query);
    this.setState({ nameSearchOptions: result, processingSearch: false });
  }

  removeGlobalCatalog() {
    let storeSpecificPrices = this.state.storeSpecificPrices;
    storeSpecificPrices.map((s) => {
      s.MRP = "";
      s.RetailPrice = "";
      s.RetailPriceError = "";
      s.MRPError = "";
      s.OMNIEnabled = true;
      s.OutOfStock = false;
    });
    this.setState({
      globalCatalogProduct: null,
      globalCatalogImage: "",
      sku: "",
      barCode: "",
      barCodeError: "",
      name: "",
      pincodeProductType: "Seller Code",
      packingQty: "",
      packingUOM: "",
      category: "",
      categoryID: "",
      categoryError: "",
      subCategoryID: "",
      subCategory: "",
      brand: "",
      model: "",
      packingUOM: "",
      packingQty: "",
      pincodeProductProductType: "",
      pincodePackaging: "",
      pincodeBundling: "",
      processingSearch: false,
      categoryError: "",
      subCategoryError: "",
      packingQtyError: "",
      packingUOMError: "",
      mrpError: "",
      retailPriceError: "",
      globalCatalogImage: "",
      categoryTA: [],
      subCategoryTA: [],
      packingUOMTA: [],
      pincodeProductProductTypeTA: [],
      pincodeBundlingTA: [],
      pincodeModelTA: [],
      PincodePackaging: [],
      storeSpecificPrices: storeSpecificPrices,
    });
  }

  onSearchSelected(selected) {
    console.log("selected", selected);
    let name = "";
    let barCode = "";
    let category = "";
    let categoryID = "";
    let subCategoryID = "";
    let subCategory = "";
    let pincodeProductType = "";
    let brand = "";
    let model = "";
    let packingUOM = "";
    let packingQty = "";
    let pincodeProductProductType = "";
    let pincodePackaging = "";
    let pincodeBundling = "";
    let MRP = "";
    let filteredSubCategories = [];
    let globalCatalogImage = "";
    let pincodeProductProductTypeTA = [];
    let packingUOMTA = [];
    let pincodeModelTA = [];
    let pincodePackagingTA = [];
    let pincodeBundlingTA = [];
    let categoryTA = [];
    let subCategoryTA = [];

    if (selected) {
      name = selected.Name + " " + selected.Quantity + " " + selected.UOM;
      barCode = selected.Code;
      category = selected.Category;
      categoryID = selected.Category;
      subCategory = selected.Subcategory;
      subCategoryID = selected.Subcategory;
      pincodeProductType = selected.Type;
      pincodeProductProductType = selected.ProductType;
      brand = selected.Brand;
      model = selected.Model;
      packingUOM = selected.UOM;
      packingQty = selected.Quantity;
      pincodePackaging = selected.Packaging;
      pincodeBundling = selected.Bundling;
      MRP = selected.MRP;
      globalCatalogImage = selected.Image;
      filteredSubCategories = this.state.subCategories.filter(
        (f) => f.Category === categoryID
      );
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }
    if (pincodeProductProductType !== "") {
      pincodeProductProductTypeTA = [{ Name: pincodeProductProductType }];
    }
    if (packingUOM !== "") {
      packingUOMTA = [{ Name: packingUOM }];
    }
    if (model !== "") {
      pincodeModelTA = [{ Name: model }];
    }
    if (pincodePackaging !== "") {
      pincodePackagingTA = [{ Name: pincodePackaging }];
    }
    if (pincodeBundling !== "") {
      pincodeBundlingTA = [{ Name: pincodeBundling }];
    }
    if (category !== "") {
      categoryTA = [{ Name: category }];
    }
    if (subCategory !== "") {
      subCategoryTA = [{ SubCategory: subCategory }];
    }

    // console.log("categoryID", categoryID);
    // console.log("filteredSubCategories", filteredSubCategories);
    // console.log("subCategoryID", subCategoryID);
    this.setState({
      globalCatalogProduct: selected,
      globalCatalogImage: globalCatalogImage,
      barCode: barCode,
      name: name,
      category: category,
      categoryID: categoryID,
      subCategoryID: subCategoryID,
      subCategory: subCategory,
      packingQty: packingQty,
      packingUOM: packingUOM,
      pincodeProductType: pincodeProductType,
      pincodeProductProductType: pincodeProductProductType,
      brand: brand,
      brandID: brand,
      pincodeModel: model,
      pincodePackaging: pincodePackaging,
      pincodeBundling: pincodeBundling,
      PincodeMRP: MRP,
      MRP: "",
      subCategoryError: "",
      categoryError: "",
      packingUOMError: "",
      packingQtyError: "",
      pincodeProductProductTypeTA: pincodeProductProductTypeTA,
      packingUOMTA: packingUOMTA,
      pincodeModelTA: pincodeModelTA,
      pincodeBundlingTA: pincodeBundlingTA,
      pincodePackagingTA: pincodePackagingTA,
      categoryTA: categoryTA,
      subCategoryTA: subCategoryTA,
    });
  }

  async onSave() {
    let nameError = "";
    let barCodeError = "";
    let categoryError = "";
    let mrpError = "";
    let retailPriceError = "";
    let packingQtyError = "";
    let packingUOMError = "";
    let SKUError = "";
    let storeSpecificPrices = this.state.storeSpecificPrices;
    let storeSpecificPricesError = false;

    if (this.state.includeSKU) {
      if (this.state.SKU.trim() === "") {
        SKUError = "Please prvoide product SKU";
      }
    } else {
      if (this.state.barCode.trim() === "") {
        barCodeError = "Please provide product barcode";
      }
    }

    if (this.state.name.trim() === "") {
      nameError = "Please provide product name";
    }

    if (this.state.category.trim() === "") {
      categoryError = "Please select category";
    }

    if (this.state.packingQty === "") {
      packingQtyError = "Please provide packing qty";
    }
    if (this.state.packingUOM === "") {
      packingUOMError = "Please select packing UOM";
    }

    if (this.state.storeSpecificPrices.length > 1) {
      //mrp can not be blank
      storeSpecificPrices.map((s) => {
        if (s.OMNIEnabled) {
          if (s.MRP === "") {
            s.MRPError = "Please provide value";
            storeSpecificPricesError = true;
          }
          if (s.RetailPrice === "") {
            s.RetailPriceError = "Please provide value";
            storeSpecificPricesError = true;
          }
          if (
            s.MRP !== "" &&
            s.RetailPrice !== "" &&
            Number(s.RetailPrice) > Number(s.MRP)
          ) {
            s.RetailPriceError = "Retail price error can not be more than MRP";
            storeSpecificPricesError = true;
          }
        }
      });
    } else {
      if (this.state.MRP === "") {
        mrpError = "Please provide product MRP";
      }
      if (this.state.retailPrice === "") {
        retailPriceError = "Please provide product retail price";
      }
      if (
        this.state.retailPrice !== "" &&
        this.state.MRP !== "" &&
        Number(this.state.retailPrice) > Number(this.state.MRP)
      ) {
        retailPriceError = "Retail Price can not be more than MRP";
      }
    }
    if (
      SKUError !== "" ||
      nameError !== "" ||
      barCodeError !== "" ||
      categoryError !== "" ||
      mrpError !== "" ||
      retailPriceError !== "" ||
      packingQtyError !== "" ||
      packingUOMError !== "" ||
      storeSpecificPricesError
    ) {
      this.setState({
        SKUError: SKUError,
        nameError: nameError,
        barCodeError: barCodeError,
        categoryError: categoryError,
        mrpError: mrpError,
        retailPriceError: retailPriceError,
        packingQtyError: packingQtyError,
        packingUOMError: packingUOMError,
        storeSpecificPrices: storeSpecificPrices,
      });
      return;
    }

    //now check if
    this.setState({ saving: true });

    let key = this.state.key;
    if (key === "") {
      let dupliateProduct = await checkIfProductAlreadyExist(
        this.props.user.user.RegistrationID,
        this.state.barCode,
        this.state.SKU
      );
      if (dupliateProduct) {
        alert("Product already exists");
        if (this.state.includeSKU) {
          this.setState({
            SKUError: "Product already exists",
            saving: false,
          });
        } else {
          this.setState({
            barCodeError: "Product already exists",
            saving: false,
          });
        }

        return;
      }
    }
    let SKU = this.state.barCode.trim();
    if (this.state.includeSKU) {
      SKU = this.state.SKU.trim();
    }

    if (storeSpecificPrices.length === 1) {
      storeSpecificPrices[0].MRP = this.state.MRP;
      storeSpecificPrices[0].RetailPrice = this.state.retailPrice;
      storeSpecificPrices[0].OMNIEnabled = this.state.OMNIEnabled;
      storeSpecificPrices[0].OutOfStock = this.state.outOfStock;
    }
    for (let i = 0; i < storeSpecificPrices.length; i++) {
      storeSpecificPrices[i].MSP = 0;
      storeSpecificPrices[i].PriceError = "";
      storeSpecificPrices[i].DiscountAmount = 0;
      storeSpecificPrices[i].DiscountPercentage = 0;
      storeSpecificPrices[i].EditWalletTopUpAmount = 0;
      storeSpecificPrices[i].MRPError = "";
      storeSpecificPrices[i].Markup = 0;
      storeSpecificPrices[i].PriceError = "";
      storeSpecificPrices[i].SupplyPrice = 0;
      storeSpecificPrices[i].WalletTopUpAmount = 0;
      storeSpecificPrices[i].WalletTopUpSameAsRetailPrice = false;
    }
    let product = {
      RegistrationID: this.props.user.user.RegistrationID,
      Name: this.state.name.trim(),
      ProductTypeID: this.state.productTypeID,
      ProductType: this.state.productType,
      Description: "",
      SKU: SKU,
      BarCode: this.state.barCode.trim(),
      Category: this.state.category,
      CategoryID: this.state.categoryID,
      SubCategoryID: this.state.subCategoryID,
      SubCategory: this.state.subCategory,
      BrandID: this.state.brandID,
      Brand: this.state.brand,
      SupplyPrice: "",
      Markup: "",
      RetailPrice: this.state.retailPrice,
      MRP: this.state.MRP,
      MSP: "",
      WalletTopUpSameAsRetailPrice: false,
      WalletTopUpAmount: "",
      AllowDiscount: true,
      AllowPriceEdit: true,
      AllowTaxChange: true,
      ChargeTaxOnProduct: false,
      TaxInclusive: false,
      TaxInclusivePurchase: false,
      HSNSACCode: "",
      TaxID: "",
      TaxGroup: "",
      PriceBasedTax: false,
      TaxPriceRange: [],
      Images: [],
      IsDeleted: false,
      EditWalletTopUpAmount: false,
      AccessibleToSelectedStores: false,
      StoreSpecificPricing: false,
      StoreSpecificPrices: storeSpecificPrices,
      DiscountAmount: "",
      DiscountPercentage: "",
      MinQty: 0,
      MaxQty: 0,
      ReorderQty: "",
      UniqueLabel: "",
      InventoryType: "FIFO",
      UOM: "",
      SearchMode: "",
      RepackProduce: false,
      //Package
      PackageServices: [],
      ValidityInDays: 0,
      QtyPrompt: false,
      Membership: "",
      MembershipID: "",
      AssociateMembership: false,
      StoreSpecificQtys: [],
      EnableAlerts: false,
      NotifyCustomerInDays: 0,
      SMSTemplateID: "",
      CustomField1: this.state.globalCatalogProduct
        ? this.state.globalCatalogProduct.Code
        : "", //global product code
      CustomField2: this.state.globalCatalogProduct
        ? ""
        : "not in master catalog", //status
      CustomField3: this.state.outOfStock ? true : false, //out of stock
      CustomField4: "",
      CustomField5: "",
      CustomField6: "",
      Favourite: false,
      ServiceDuration: "",
      BOM: false,
      BOMManual: false,
      CanBeUsedAsConsumable: false,
      BatchTrackingBatchNoRequired: false,
      BatchTrackingAutoBatchNoRequired: false,
      BatchTrackingMRPRequired: false,
      BatchTrackingMfgDateRequired: false,
      BatchTrackingExpDateRequired: false,
      BatchTrackingDateFormat: "",
      OMNIEnabled: this.state.OMNIEnabled,
      OutOfStock: this.state.outOfStock,
      SecondaryUOMs: [],
      SalesUOM: "",
      PurchaseUOM: "",
      Attributes: [],
      Variants: [],
      AutoGenerateSKU: false,
      CustomSKU: this.state.barCode.trim(),
      ShowAsAdditionalCharges: false,
      PackagingCharges: "",
      LongDescription: "",
      SequenceNo: 0,
      MaxOrderQty: this.state.maxOrderQty,
      PincodePackingQty: this.state.packingQty,
      PincodePackingUOM: this.state.packingUOM,
      PinCodeProductType: this.state.pincodeProductType,
      PincodeProductProductType: this.state.pincodeProductProductType,
      PincodeModel: this.state.pincodeModel,
      PincodePackaging: this.state.pincodePackaging,
      PincodeBundling: this.state.pincodeBundling,
      PincodeCancellable: this.state.pincodeCancellable,
      PincodeReturnable: this.state.pincodeReturnable,
      PincodeManufacturer: this.state.pincodeManufacturer,
    };
    let omniTag = "",
      prodName = "",
      prodSKU = "";
    prodName = product.Name.replace(
      /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g,
      ""
    ).replace(/[' ']/g, "_");
    prodSKU = product.SKU.replace(
      /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g,
      ""
    ).replace(/[' ']/g, "_");
    omniTag = prodName + "_" + prodSKU;
    product.OMNITag = omniTag;

    if (this.state.globalCatalogImage.trim() !== "") {
      product.Images.push({
        ImageName: product.SKU,
        ImagePath: this.state.globalCatalogImage,
        ImageSize: 0,
      });
    }
    // console.log("product", product);
    if (key) {
      //update product details
      // console.log("product", product);
      // console.log("this.state.images", this.state.images);
      product.Images =
        this.state.images && this.state.images.length > 0
          ? this.state.images
          : [];
      await this.props.updateProduct(product, key);
      this.setState({ saving: false });
      this.props.getToastr("Product Saved Successfully");
      this.refreshControl();
      setTimeout(
        function() {
          this.props.onSave(true);
        }.bind(this),
        4000
      );
    } else {
      //create new product
      let key = await this.props.addNewProduct(product);
      this.setState({ saving: false });
      this.props.getToastr("Product Saved Successfully");
      this.refreshControl();
      setTimeout(
        function() {
          this.props.onSave(true);
        }.bind(this),
        4000
      );
    }
  }

  refreshControl() {
    // if (this.scanRef.current) {
    // this.scanRef.current.focus();

    // }
    let storeSpecificPrices = this.state.storeSpecificPrices;
    storeSpecificPrices.map((s) => {
      s.MRP = "";
      s.RetailPrice = "";
      s.RetailPriceError = "";
      s.MRPError = "";
      s.OMNIEnabled = false;
      s.OutOfStock = false;
    });
    this.setState({
      key: "",
      SKU: "",
      globalCatalogProduct: null,
      productTypeID: 1,
      productType: "Goods for sale",
      barCode: "",
      name: "",
      category: "",
      categoryID: "",
      subcategory: "",
      subCategoryID: "",
      brand: "",
      brandID: "",
      packingQty: "",
      packingUOM: "",
      retailPrice: "",
      MRP: "",
      OMNIEnabled: true,
      outOfStock: false,
      pincodeProductProductType: "",
      pincodeModel: "",
      pincodePackaging: "",
      pincodeBundling: "",
      pincodeProductType: "", //PincodeProductType
      saving: false,
      maxOrderQty: "",
      codeSearchOptions: [],
      nameSearchOptions: [],
      processingSearch: false,
      nameError: "",
      barCodeError: "",
      categoryError: "",
      mrpError: "",
      retailPriceError: "",
      packingQtyError: "",
      packingUOMError: "",
      globalCatalogImage: "",
      categoryTA: [],
      subCategoryTA: [],
      packingUOMTA: [],
      pincodeProductProductTypeTA: [],
      pincodeBundlingTA: [],
      pincodeModelTA: [],
      PincodePackaging: [],
      storeSpecificPrices: storeSpecificPrices,
      globalCatalogImage: null,
      images: [],
    });
    if (this.scanRef.current) {
      console.log("scan found");
      this.scanRef.current.getInstance().focus();
      this.scanRef.current.getInstance().clear();
      this.searchProductNameRef.current.getInstance().clear();
    }
  }

  renderPriceControl() {
    return (
      <React.Fragment>
        {this.state.storeSpecificPrices.length <= 1 && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6">
                <Input
                  inputRef={this.inputRef}
                  type="number"
                  label="MRP"
                  error={this.state.mrpError}
                  value={this.state.MRP}
                  onEnter={() => this.onSave()}
                  onChange={(value) =>
                    this.setState({ MRP: value, mrpError: "" })
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="number"
                  label="Retail Price"
                  error={this.state.retailPriceError}
                  value={this.state.retailPrice}
                  onEnter={() => this.onSave()}
                  onChange={(value) =>
                    this.setState({ retailPrice: value, retailPriceError: "" })
                  }
                />
              </div>
            </div>
            {this.state.key !== "" && (
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="switch"
                    label="Show on Pincode App"
                    value={this.state.OMNIEnabled}
                    onChange={(value) => {
                      this.setState({ OMNIEnabled: value });
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="switch"
                    label="Show Out of Stock"
                    value={this.state.outOfStock}
                    onChange={(value) => {
                      this.setState({ outOfStock: value });
                    }}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {this.state.storeSpecificPrices.length > 1 && (
          <React.Fragment>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Store</th>
                  <th className="productquickadd-th-switch">
                    Show on Pincode App
                  </th>
                  <th className="productquickadd-th-input">MRP</th>
                  <th className="productquickadd-th-input">Retail Price</th>

                  <th className="productquickadd-th-switch">
                    Show Out of Stock
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.storeSpecificPrices.map((s, index) => (
                  <React.Fragment>
                    {this.props.config.UserStores.filter(
                      (f) => f.key === s.StoreID
                    ).length > 0 && (
                      <tr>
                        <td>{s.Store}</td>
                        <td>
                          <Input
                            type="switch"
                            value={s.OMNIEnabled}
                            onChange={(value) => {
                              let storeSpecificPrices = this.state
                                .storeSpecificPrices;
                              storeSpecificPrices[index].OMNIEnabled = value;
                              this.setState({
                                storeSpecificPrices: storeSpecificPrices,
                              });
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={s.MRP}
                            onChange={(value) => {
                              let storeSpecificPrices = this.state
                                .storeSpecificPrices;
                              storeSpecificPrices[index].MRP = value;
                              storeSpecificPrices[index].MRPError = "";
                              this.setState({
                                storeSpecificPrices: storeSpecificPrices,
                              });
                            }}
                            error={s.MRPError}
                            disabled={!s.OMNIEnabled}
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            value={s.RetailPrice}
                            onChange={(value) => {
                              let storeSpecificPrices = this.state
                                .storeSpecificPrices;
                              storeSpecificPrices[index].RetailPrice = value;
                              storeSpecificPrices[index].RetailPriceError = "";
                              this.setState({
                                storeSpecificPrices: storeSpecificPrices,
                              });
                            }}
                            error={s.RetailPriceError}
                            disabled={!s.OMNIEnabled}
                          />
                        </td>

                        <td>
                          <Input
                            type="switch"
                            value={s.OutOfStock}
                            onChange={(value) => {
                              let storeSpecificPrices = this.state
                                .storeSpecificPrices;
                              storeSpecificPrices[index].OutOfStock = value;
                              this.setState({
                                storeSpecificPrices: storeSpecificPrices,
                              });
                            }}
                            disabled={!s.OMNIEnabled}
                          />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showScanner && (
          <BarcodeScanner
            onClose={() => this.setState({ showScanner: false })}
            onScanSuccess={async (value) => {
              this.setState({
                processingSearch: true,
                barCode: value,
                barCodeError: "",
              });
              let result = await searchPincodeGlobalCatalog(value, "");
              if (result && result.length === 1 && result[0].Code === value) {
                this.onSearchSelected(result[0]);
                this.setState({
                  codeSearchOptions: [],
                  processingSearch: false,
                  showScanner: false,
                });
                return;
              }
              this.setState({
                codeSearchOptions: result,
                processingSearch: false,
                showScanner: false,
              });
            }}
          />
        )}

        <Form
          title={this.state.key !== "" ? "Edit Product" : "New Product"}
          onClick={(action) => {
            if (action === "save") {
              this.onSave();
            } else {
              this.props.onClose();
            }
          }}
          processing={this.state.saving}
        >
          {this.state.key === "" && (
            <React.Fragment>
              {isMobileApp() && this.props.user.store.EnableBarcodeScanner && (
                <button
                  class="barcode-button"
                  onClick={() => this.setState({ showScanner: true })}
                >
                  <Icon type="scan" />
                  <span>Scan barcode</span>
                </button>
              )}
              {this.state.includeSKU && (
                <Input
                  type="text"
                  label="SKU"
                  onChange={(value) =>
                    this.setState({ SKU: value, SKUError: "" })
                  }
                  error={this.state.SKUError}
                />
              )}
              <Input
                inputRef={this.scanRef}
                type="typeaheadselection"
                label="Barcode (ISBN, UPC, GTIN, etc.)"
                placeholder="Scan code or enter code to search product in master catalog"
                options={this.state.codeSearchOptions}
                labelKey="Code"
                filterBy={["Code", "Name"]}
                onSearch={this.onCodeSearch}
                selected={this.state.globalCatalogProduct}
                processing={this.state.processingSearch}
                error={this.state.barCodeError}
                columns={[
                  {
                    Column: "Code",
                    Filter: true,
                    SelectedText: true,
                    SearchText: true,
                    Main: true,
                  },
                ]}
                onChange={(selected) => {
                  this.onSearchSelected(selected);
                }}
                renderMenuItemChildren={(option, props) => (
                  <div>
                    <div>
                      <b>{option.Code}</b>
                    </div>
                    <div>
                      {option.Name} {option.Quantity} {option.UOM}
                    </div>
                  </div>
                )}
                onClick={(type) => {
                  if (type === "remove") {
                    this.removeGlobalCatalog();
                  }
                }}
              />
              <Input
                inputRef={this.searchProductNameRef}
                type="typeaheadselection"
                label="Product Name"
                placeholder="Type product name to search product in master catalog"
                options={this.state.nameSearchOptions}
                labelKey="Name"
                filterBy={["Code", "Name"]}
                onSearch={this.onNameSearch}
                selected={this.state.globalCatalogProduct}
                processing={this.state.processingSearch}
                error={this.state.nameError}
                columns={[
                  {
                    Column: "Name",
                    Filter: true,
                    SearchText: true,
                    SelectedText: true,
                    Main: true,
                  },
                ]}
                onChange={(selected) => {
                  this.onSearchSelected(selected);
                }}
                renderMenuItemChildren={(option, props) => (
                  <div>
                    <div>
                      <b>{option.Code}</b>
                    </div>
                    <div>
                      {option.Name} {option.Quantity} {option.UOM}
                    </div>
                  </div>
                )}
                onClick={(type) => {
                  if (type === "remove") {
                    this.removeGlobalCatalog();
                  }
                }}
              />
            </React.Fragment>
          )}
          {this.state.key !== "" && (
            <React.Fragment>
              {this.state.includeSKU && (
                <Input
                  type="text"
                  label="SKU"
                  value={this.state.SKU}
                  onChange={(value) =>
                    this.setState({ SKU: value, SKUError: "" })
                  }
                  error={this.state.SKUError}
                  disabled={this.state.globalCatalogProduct}
                />
              )}
              <Input
                type="text"
                label="Barcode (ISBN, UPC, GTIN, etc.)"
                value={this.state.barCode}
                onChange={(value) => this.setState({ sku: value })}
                error={this.state.barCodeError}
                disabled={this.state.globalCatalogProduct}
              />
              <Input
                type="text"
                label="Name"
                value={this.state.name}
                onChange={(value) => this.setState({ name: value })}
                error={this.state.nameError}
                disabled={this.state.globalCatalogProduct}
              />
            </React.Fragment>
          )}
          <div className="row">
            <div className="col-md-6">
              <Input
                type="select"
                label="Category"
                onChange={(value) => {
                  this.setState({
                    categoryID: value,
                    category: value,
                    categoryError: "",
                    filteredSubCategories: this.state.subCategories.filter(
                      (f) => f.Category === value
                    ),
                  });
                }}
                value={this.state.categoryID}
                error={this.state.categoryError}
                disabled={this.state.globalCatalogProduct}
              >
                <option value="">Select category</option>
                {this.state.categories.map((category) => (
                  <option value={category.Name}>{category.Name}</option>
                ))}
              </Input>
              {/* <Input
              type="typeahead"
              label="Category"
              options={this.state.categories}
              selected={this.state.categoryTA}
              onChange={(value) => {
                this.setState({
                  categoryTA: value,
                  categoryID: value && value.length > 0 ? value[0].Name : "",
                  category: value && value.length > 0 ? value[0].Name : "",
                  categoryError: "",
                  filteredSubCategories: this.state.subCategories.filter(
                    (f) => f.Category === value
                  ),
                });
              }}
              disabled={this.state.globalCatalogProduct}
              labelKey="Name"
              error={this.state.categoryError}
            /> */}
            </div>
            <div className="col-md-6">
              <Input
                type="select"
                label="Sub Category"
                onChange={(value) => {
                  this.setState({
                    subCategory: value,
                    subCategoryID: value,
                    subCategoryError: "",
                  });
                }}
                value={this.state.subCategoryID}
                error={this.state.subCategoryError}
                disabled={this.state.globalCatalogProduct}
              >
                <option value="">Select sub category</option>
                {this.state.subCategories
                  .filter((f) => f.Category === this.state.categoryID)
                  .map((subCategory) => (
                    <option value={subCategory.SubCategory}>
                      {subCategory.SubCategory}
                    </option>
                  ))}
              </Input>
            </div>
          </div>
          {this.renderPriceControl()}
          <Input
            type="typeahead"
            label="Product Type"
            options={this.state.productTypes}
            selected={this.state.pincodeProductProductTypeTA}
            onChange={(value) => {
              // console.log("value", value);
              this.setState({
                pincodeProductProductTypeTA: value,
                pincodeProductProductType:
                  value && value.length > 0 ? value[0].Name : "",
                pincodeProductProductTypeError: "",
              });
            }}
            error={this.state.pincodeProductProductTypeError}
            disabled={this.state.globalCatalogProduct}
            labelKey="Name"
          />
          <div className="row">
            <div className="col-md-6">
              <Input
                type="text"
                label="Packing Quantity"
                value={this.state.packingQty}
                onChange={(value) =>
                  this.setState({ packingQty: value, packingQtyError: "" })
                }
                disabled={this.state.globalCatalogProduct}
                error={this.state.packingQtyError}
              />
            </div>
            <div className="col-md-6">
              <Input
                type="typeahead"
                label="Packing UOM"
                options={this.state.uoms}
                selected={this.state.packingUOMTA}
                onChange={(value) => {
                  // console.log("value", value);
                  this.setState({
                    packingUOMTA: value,
                    packingUOM: value && value.length > 0 ? value[0].Name : "",
                    packingUOMError: "",
                  });
                }}
                error={this.state.packingUOMError}
                disabled={this.state.globalCatalogProduct}
                labelKey="Name"
              />
            </div>
          </div>

          {this.state.key !== "" && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="number"
                    label="Max Order Qty"
                    value={this.state.maxOrderQty}
                    onChange={(value) => this.setState({ maxOrderQty: value })}
                  />
                </div>
                <div className="col-md-6">
                  {/* <Input
                  type="select"
                  label="Model Name"
                  value={this.state.pincodeModel}
                  onChange={(value) => this.setState({ pincodeModel: value })}
                  disabled={this.state.globalCatalogProduct}
                >
                  <option value="">Select Model</option>
                  {this.state.models.map((u) => (
                    <option value={u.Name}>{u.Name}</option>
                  ))}
                </Input> */}
                  <Input
                    type="typeahead"
                    label="Model Name"
                    options={this.state.models}
                    selected={this.state.pincodeModelTA}
                    onChange={(value) => {
                      this.setState({
                        pincodeModelTA: value,
                        pincodeModel:
                          value && value.length > 0 ? value[0].Name : "",
                      });
                    }}
                    disabled={this.state.globalCatalogProduct}
                    labelKey="Name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {/* <Input
                  type="select"
                  label="Packaging"
                  value={this.state.pincodePackaging}
                  onChange={(value) =>
                    this.setState({ pincodePackaging: value })
                  }
                  disabled={this.state.globalCatalogProduct}
                >
                  <option value="">Select Packaging</option>
                  {this.state.packaging.map((u) => (
                    <option value={u.Name}>{u.Name}</option>
                  ))}
                </Input> */}
                  <Input
                    type="typeahead"
                    label="Packaging"
                    options={this.state.packaging}
                    selected={this.state.pincodePackagingTA}
                    onChange={(value) => {
                      this.setState({
                        pincodePackagingTA: value,
                        pincodePackaging:
                          value && value.length > 0 ? value[0].Name : "",
                      });
                    }}
                    disabled={this.state.globalCatalogProduct}
                    labelKey="Name"
                  />
                </div>
                <div className="col-md-6">
                  {/* <Input
                  type="select"
                  label="Bundling"
                  value={this.state.pincodeBundling}
                  onChange={(value) =>
                    this.setState({ pincodeBundling: value })
                  }
                  disabled={this.state.globalCatalogProduct}
                >
                  <option value="">Select Bundling</option>
                  {this.state.bundling.map((u) => (
                    <option value={u.Name}>{u.Name}</option>
                  ))}
                </Input> */}
                  <Input
                    type="typeahead"
                    label="Bundling"
                    options={this.state.bundling}
                    selected={this.state.pincodeBundlingTA}
                    onChange={(value) => {
                      this.setState({
                        pincodeBundlingTA: value,
                        pincodeBundling:
                          value && value.length > 0 ? value[0].Name : "",
                      });
                    }}
                    disabled={this.state.globalCatalogProduct}
                    labelKey="Name"
                  />
                </div>
              </div>
              {this.state.pincodeProductType.toLowerCase() ===
                "seller code" && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        type="textarea"
                        label="Product Description"
                        value={this.state.description}
                        onChange={(value) =>
                          this.setState({ description: value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        type="text"
                        label="Manufacturer"
                        value={this.state.pincodeManufacturer}
                        onChange={(value) =>
                          this.setState({ pincodeManufacturer: value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        type="switch"
                        label="Cancellable"
                        value={this.state.pincodeCancellable}
                        onChange={(value) =>
                          this.setState({ pincodeCancellable: value })
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        type="switch"
                        label="Returnable"
                        value={this.state.pincodeReturnable}
                        onChange={(value) =>
                          this.setState({ pincodeReturnable: value })
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {this.state.globalCatalogImage !== "" && (
            <div className="row">
              <div className="col-md-12">
                <img
                  src={this.state.globalCatalogImage}
                  className="productquickadd-image"
                />
              </div>
            </div>
          )}
          {this.state.images.map((i) => (
            <img src={i.ImagePath} className="productquickadd-image" />
          ))}
          <br />
          <br />
          {this.state.key && (
            <div className="row">
              <div className="col-md-12">
                <AuditTrail
                  {...this.props}
                  module={constants.MODULE_PRODUCT}
                  parentKey={this.state.key}
                />
              </div>
            </div>
          )}

          <br />
          <br />
          <br />
          <br />
          <br />
        </Form>
      </React.Fragment>
    );
  }
}

export default ProductQuickAdd;
