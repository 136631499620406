import React, { Component } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import ReactHtmlParser from 'react-html-parser';

class LoyaltyDashboard extends Component {

    constructor() {
        super();
        this.state = {
            loyaltyCustomers: {},
            loyaltyLedger: [],
            loyaltyEarnAndBurn: []
        };
        this.loadCustomerLoyaltyHeaders = this.loadCustomerLoyaltyHeaders.bind(this)
        this.loadLoyaltyTypeCustomerCount = this.loadLoyaltyTypeCustomerCount.bind(this)
        this.loadCustomerEarnBurnPoints = this.loadCustomerEarnBurnPoints.bind(this)
        this.loadMonthWiseLoyaltyPointEarnAndBurn = this.loadMonthWiseLoyaltyPointEarnAndBurn.bind(this)
    };

    async componentDidMount() {
        this.loadCustomerLoyaltyHeaders();
        this.loadLoyaltyTypeCustomerCount();
        this.loadCustomerEarnBurnPoints();
        this.loadMonthWiseLoyaltyPointEarnAndBurn();
    };

    loadCustomerLoyaltyHeaders() {
        this.props.getTotalLoyaltyCustomer(this.props.user.user.RegistrationID)
            .then((result) => {
                this.setState({ loyaltyCustomers: result.searchResult, totalItemsCount: result.totalItemsCount })
            });
    };

    loadCustomerEarnBurnPoints() {
        this.props.getLoyaltyPointEarnAndBurn(this.props.user.user.RegistrationID)
            .then((result) => {
                console.log(result.searchResult);
                this.setState({ loyaltyEarnAndBurn: result.searchResult, totalItemsCount: result.totalItemsCount })
            });
    };

    loadMonthWiseLoyaltyPointEarnAndBurn() {
        this.props.getMonthWiseLoyaltyPointEarnAndBurn(this.props.user.user.RegistrationID).then((result) => {
            let loyaltyDashboardData = {
                labels: [],
                datasets: [
                    {
                        label: 'Earn',
                        backgroundColor: '#00A65A',
                        borderColor: '#00a65a',
                        hoverBorderColor: '#00a65a',
                        hoverBackgroundColor: '#00A65A',
                        data: []
                    },
                    {
                        label: 'Burn',
                        backgroundColor: 'grey',
                        borderColor: 'grey',
                        hoverBorderColor: 'grey',
                        hoverBackgroundColor: 'grey',
                        data: []
                    }
                ]
            };

            let month =''
            for (let l = 0; l < result.searchResult.length; l++) {
                const data = result.searchResult[l];
                if (month !== data.Month) {
                    month = data.Month;
                    loyaltyDashboardData.labels.push(data.Month);
                }
                if (data.Type === 'earn') {
                    loyaltyDashboardData.datasets[0].data.push(data.LoyaltyPoints)
                }
                else {
                    loyaltyDashboardData.datasets[1].data.push(data.LoyaltyPoints)
                }
            }
            this.setState({ loyaltyDashboardData: loyaltyDashboardData });
        });
    }

    loadLoyaltyTypeCustomerCount() {
        this.props.getLoyaltyTypeCustomerCount(this.props.user.user.RegistrationID)
            .then((result) => {
                let loyaltyLedgerData = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Loyalty Type',
                            backgroundColor: ['#99A1C6', '#51CDA0', '#DF7970',
                                '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                                '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                                '#C77B85', '#C39762'],
                            hoverBackgroundColor: ['#99A1C6', '#51CDA0', '#DF7970',
                                '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                                '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                                '#C77B85', '#C39762'],
                            data: []
                        }
                    ]
                };
                for (let i = 0; i < result.searchResult.length; i++) {
                    loyaltyLedgerData.datasets[0].data.push(result.searchResult[i].totalcustomers);
                    loyaltyLedgerData.labels.push(result.searchResult[i].type);
                }
                this.setState({ loyaltyLedgerData: loyaltyLedgerData, loyaltyLedger: result.searchResult, totalItemsCount: result.totalItemsCount })
            });
    };
    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-md-12 hidden-xs">
                        <div className="row">
                            <div className="col-md-4">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>Loyalty Dashboard</font>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-green"><i className="fa fa-users"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total Customers</span>
                                    <span className="info-box-number">{this.state.loyaltyCustomers.total_customers}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-aqua"><i className="fa fa-tint"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total Earn</span>
                                    <span className="info-box-number">{this.state.loyaltyEarnAndBurn.filter((e) => e.type === "earn").length > 0 ? this.state.loyaltyEarnAndBurn.filter((e) => e.type === "earn")[0].totalpoints : 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-yellow"><i className="fa fa-fire"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total Burn</span>
                                    <span className="info-box-number">{this.state.loyaltyEarnAndBurn.filter((e) => e.type === "burn").length > 0 ? this.state.loyaltyEarnAndBurn.filter((e) => e.type === "burn")[0].totalpoints : 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12 col-sm-6  col-xs-12">
                                    <div className="box ">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Loyalty Type Wise</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div style={{ height: "50vh", width: "auto", overflowX: "auto" }} className="scroll2">
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Loyalty Type</th>
                                                                    <th>Customer Count</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.loyaltyLedger.map((loyaltyLedger, index) =>
                                                                    <tr>
                                                                        <td>{loyaltyLedger.type}</td>
                                                                        <td>{loyaltyLedger.totalcustomers}</td>
                                                                    </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="chart">
                                                        <Pie
                                                            width={623}
                                                            height={300}
                                                            data={this.state.loyaltyLedgerData}
                                                            options={{
                                                                maintainAspectRatio: false
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-6  col-xs-12">
                                    <div className="box ">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Month-wise (Earn vs Burn)</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="chart">
                                                        <Bar
                                                            width={500}
                                                            height={200}
                                                            data={this.state.loyaltyDashboardData}
                                                            options={{
                                                                maintainAspectRatio: false,
                                                                scales: {
                                                                    yAxes: [{
                                                                        ticks: {
                                                                            beginAtZero: true
                                                                        }
                                                                    }]
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default LoyaltyDashboard;