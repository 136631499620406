import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AllReportOptions from './AllReportOptions'
class AllReport extends Component {

    constructor() {
        super()
    }

    render(){

        return <div className="content-wrapper" >
            <section className="content" >
                <AllReportOptions {...this.props}/>
            </section>
        </div>
    }
};
export default AllReport;