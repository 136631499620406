import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getTransactionTypes(registrationID){
    // return async (dispatch, getState) => {
    //     alert('getTransactionTypes start ' + registrationID)
    // return database.collection("TransactionTypes").where("RegistrationID","==",registrationID).orderBy("TransactionType").get().then((querySnapshot) => {
    //     alert('getTransactionTypes firebase start')
    //     let transactionTypes = []
    //     querySnapshot.forEach((doc) => {
    //         transactionTypes.push( { key:doc.id,...doc.data()}) 
    //     })
    //     //now sort data as per module and 
    //     transactionTypes.sort(function(a, b){return  (a.hasOwnProperty('SequenceNo') ? a.SequenceNo : 20 ) - (b.hasOwnProperty('SequenceNo') ? b.SequenceNo : 20 )})
    //     alert('getTransactionTypes firebase transactionTypes count' + transactionTypes.length)
    //     dispatch({type: actionTypes.TRANSACTION_TYPES_GET,transactionTypes})
    //  })
    // }
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getTransactionTypes');
        return addMessage({ registrationID: registrationID}).then(function (result) {
            // console.log(result.data)
            let transactionTypes = result.data;
            dispatch({ type: actionTypes.TRANSACTION_TYPES_GET,transactionTypes })
        });
    }
}

export function deleteTransactionType(key){
    return (dispatch, getState) =>{
        return database.collection("TransactionTypes").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}

export function saveTransactionType(transactionType, key){
    return (dispatch, getState) => {
        transactionType.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        transactionType.ActionBy = getState().user.user.Name
        transactionType.ActionByUID = getState().user.user.UID
        transactionType.ActionByEmailID = getState().user.user.EmailID

        if(key.trim()!== "")
        {
            transactionType.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("TransactionTypes").doc(key).update({
                ...transactionType
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating transaction types: ", error);
              })
        }
        else
        {
            transactionType.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("TransactionTypes").add({
                ...transactionType
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding transaction types: ", error);
              })
        }
        
    }
}
