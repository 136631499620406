import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';

class GSTR1B2B extends Component {
    constructor() {
        super()
        this.state = {
            invoices: [],
            stores: [],
            storeList: [],
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            activePage: 1,
            itemsCountPerPage: 10000,
            totalItemsCount: 0,
            recordFrom: 0,
            loading: true,
            gstinCustomFieldNo:0,
            includeGSTImportColumnsOnly:false,
            years:[],
            months:[],
            selectedYear:0,
            selectedMonth:0,
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.loadInvoiceDetailReport = this.loadInvoiceDetailReport.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this)
        this.onYearChange = this.onYearChange.bind(this)
    }

    componentDidMount() {
        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()
        let years= this.props.getYears()
        let months = this.props.getMonths(selectedYear)
        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth)  
        let storeList = []
        storeList.push({ key: this.props.user.store.key })
        this.setState({storeID: this.props.user.store.key, storeList: storeList, years: years, months:months,selectedYear:selectedYear,selectedMonth:selectedMonth,searchTransactionFromDate:searchTransactionFromDate,  searchTransactionToDate:searchTransactionToDate})

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let gstinCustomFieldNo= 0
                if(customField.CustomField1Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=1
                }
                else if(customField.CustomField2Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=2
                }
                else if(customField.CustomField3Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=3
                }
                else if(customField.CustomField4Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=4
                }
                else if(customField.CustomField5Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=5
                }
                else if(customField.CustomField6Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=6
                }
                this.setState({gstinCustomFieldNo:gstinCustomFieldNo})
            }

        })

        this.loadInvoiceDetailReport(storeList,
            this.state.recordFrom,
            this.state.itemsCountPerPage,
            this.state.searchTransactiontype,
            searchTransactionFromDate,
            searchTransactionToDate)
    }

    onYearChange(evt)
    {
        let selectedYear = evt.target.value
        let months = this.props.getMonths(selectedYear)
        let selectedMonth = months[months.length-1].Month
        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth)  
        this.setState({
            months:months,
            selectedYear:selectedYear,
            selectedMonth:selectedMonth,
            searchTransactionFromDate:searchTransactionFromDate,  
            searchTransactionToDate:searchTransactionToDate
        })
    }

    onMonthChange(evt)
    {
        let selectedMonth = evt.target.value
        let searchTransactionFromDate = this.getMonthStartDate(this.state.selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(this.state.selectedYear, selectedMonth)  
        this.setState({
            selectedMonth:selectedMonth,
            searchTransactionFromDate:searchTransactionFromDate,  
            searchTransactionToDate:searchTransactionToDate
        })
    }

    getMonthStartDate(selectedYear, selectedMonth)
    {
        return this.props.getStartDateOfMonth(selectedYear, selectedMonth)
    }

    getMonthEndDate(selectedYear, selectedMonth)
    {
        return this.props.getEndDateOfMonth(selectedYear, selectedMonth)
    }
    
    async loadData(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate) {

        let result = await this.props.getInvoicesForGSTR1B2B(this.props.user.user.RegistrationID, storeList, from, size, searchTransactiontype,
            searchTransactionFromDate, searchTransactionToDate)

        let invoices = [];
        let gstinCustomFieldNo = this.state.gstinCustomFieldNo
        for (let index = 0; index < result.searchResult.length; index++) {
            const data = result.searchResult[index];
            if (!data.hasOwnProperty('TaxSummaryList')) {
                continue
            }

            if(data.TaxSummaryList)
            {
                let invoiceTaxes = []
                for (let l = 0; l < data.TaxSummaryList.length; l++) {
                    let taxRate= 0 
                    let cessAmount = 0 
                    let cgstPerc = 0 
                    let cgstAmount = 0 
                    let sgstPerc = 0
                    let sgstAmount = 0 
                    let igstPerc = 0 
                    let igstAmount = 0 
                    let cessPerc = 0
                    data.TaxSummaryList[l].TaxComponentAmount.map(m=>{
                        if(m.TaxType==="CGST" || m.TaxType==="SGST" || m.TaxType==="IGST")
                        {
                            taxRate = Number(taxRate)+ Number(m.TaxPercentage)
                            if(m.TaxType==="CGST" )
                            {
                                cgstPerc =  Number(m.TaxPercentage)
                                cgstAmount = Number(cgstAmount) + Number(m.TaxAmount)
                            }
                            if(m.TaxType==="SGST" )
                            {
                                sgstPerc =  Number(m.TaxPercentage)
                                sgstAmount = Number(sgstAmount) + Number(m.TaxAmount)
                            }
                            if(m.TaxType==="IGST" )
                            {
                                igstPerc =  Number(m.TaxPercentage)
                                igstAmount = Number(igstAmount) + Number(m.TaxAmount)
                            }
                        }
                        else if(m.TaxType==="CESS")
                        {
                            cessPerc= Number(m.TaxPercentage)
                            cessAmount = Number(cessAmount)+ Number(m.TaxAmount)
                        }
                    })
                    if(invoiceTaxes.filter(f=> f.taxRate === taxRate).length> 0)
                    {
                        invoiceTaxes.map(k=>{
                            if(k.taxRate===taxRate)
                            {
                                k.taxableAmount = Number( k.taxableAmount) + Number(data.TaxSummaryList[l].TaxableAmount)
                                k.cessAmount =Number(k.cessAmount)  + Number(cessAmount) 
                                k.cgstPerc=Number(k.cgstPerc)  
                                k.cgstAmount=Number(k.cgstAmount)  + Number(cgstAmount)
                                k.sgstPerc=Number(k.sgstPerc) 
                                k.sgstAmount=Number(k.sgstAmount)  + Number(sgstAmount)
                                k.igstPerc=Number(k.igstPerc) 
                                k.igstAmount=Number(k.igstAmount)  + Number(igstAmount)
                                k.cessPerc=Number(k.cessPerc)  
                            }
                        })
                    }
                    else
                    {
                        invoiceTaxes.push({taxRate:taxRate, 
                            cessAmount:cessAmount, 
                            taxableAmount:data.TaxSummaryList[l].TaxableAmount,
                             cgstPerc:cgstPerc,
                             cgstAmount:cgstAmount,
                             sgstPerc:sgstPerc,
                             sgstAmount:sgstAmount,
                             igstPerc:igstPerc,
                             igstAmount:igstAmount, 
                             cessPerc:cessPerc
                            })
                    }
                }
                for(let g=0; g<invoiceTaxes.length;g++)
                {
                    let invoice = {
                        "GSTIN/UIN of Receipient":"",
                        "Receiver Name": data.Customer.FirstName + ' ' + data.Customer.LastName,
                    }
                    if(gstinCustomFieldNo ===1)
                    {
                        invoice["GSTIN/UIN of Receipient"] = data.Customer.CustomField1
                    }
                    else if(gstinCustomFieldNo ===2)
                    {
                        invoice["GSTIN/UIN of Receipient"] = data.Customer.CustomField2
                    }
                    else if(gstinCustomFieldNo ===3)
                    {
                        invoice["GSTIN/UIN of Receipient"] = data.Customer.CustomField3
                    }
                    else if(gstinCustomFieldNo ===4)
                    {
                        invoice["GSTIN/UIN of Receipient"] = data.Customer.CustomField4
                    }
                    else if(gstinCustomFieldNo ===5)
                    {
                        invoice["GSTIN/UIN of Receipient"] = data.Customer.CustomField5
                    }
                    else if(gstinCustomFieldNo ===6)
                    {
                        invoice["GSTIN/UIN of Receipient"] = data.Customer.CustomField6
                    }
                    invoice["Invoice Number"] = data.TransactionNo
                    invoice["Invoice Date"] = (new Date(data.TransactionDate).toISOString().split('T')[0])
                    invoice["Invoice Value"] = data.BillAmount
                    invoice["Place Of Supply"] = data.PlaceOfSupply
                    invoice["Reverse Charge"] = "N"
                    invoice["Applicable % of Tax Rate"] = ""
                    invoice["Invoice Type"] = "Regular"
                    invoice["E-Commerce GSTIN"] = ""
                    invoice["Rate"] = invoiceTaxes[g].taxRate
                    invoice["Taxable Value"] = Number(invoiceTaxes[g].taxableAmount).toFixed(2)
                    invoice["Cess Amount"] = Number(invoiceTaxes[g].cessAmount).toFixed(2)
                    // if(!this.state.includeGSTImportColumnsOnly)
                    // {
                    //     invoice["CGST %"] = Number(invoiceTaxes[g].cgstPerc)
                    //     invoice["CGST Amount"] = Number(invoiceTaxes[g].cgstAmount).toFixed(2)
                    //     invoice["SGST %"] = Number(invoiceTaxes[g].sgstPerc)
                    //     invoice["SGST Amount"] = Number(invoiceTaxes[g].sgstAmount).toFixed(2)
                    //     invoice["IGST %"] = Number(invoiceTaxes[g].igstPerc)
                    //     invoice["IGST Amount"] = Number(invoiceTaxes[g].igstAmount).toFixed(2)
                    //     invoice["CESS %"] = Number(invoiceTaxes[g].cessPerc)
                    //     invoice["CESS Amount"] = Number(invoiceTaxes[g].cessAmount).toFixed(2)
                    // }
                    invoices.push(invoice);
                }
                
            }
        }
        return invoices
    }

    async loadInvoiceDetailReport(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate) {

        this.setState({ loading: true })

        let invoices = await this.loadData(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate)
        var col = [];
        for (var i = 0; i < invoices.length; i++) {
            for (var key in invoices[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < invoices.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result += "<td>";
                result += invoices[i][col[j]] == undefined ? 0 : invoices[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }
        result += "</tbody>";
        result += "</table>";
        //console.log(result);
        this.setState({ invoices: result, loading: false })

    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()

        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth) 


        this.setState({
            selectedYear: selectedYear,
            selectedMonth: selectedMonth,
            searchTransactionFromDate: searchTransactionFromDate,
            searchTransactionToDate: searchTransactionToDate,
             storeList: storeList,
            storeID: this.props.user.store.key,
            recordFrom: 0, 
            searchTransactiontype: '',
        })
        this.loadInvoiceDetailReport(storeList, 0, this.state.itemsCountPerPage, "", searchTransactionFromDate, searchTransactionToDate);
    }

    async onExportClick() {
    
        let invoices = await this.loadData(this.state.storeList,
            0, 1000,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate)

        const csvData = constatnts.objToCSV(invoices);
        this.setState({ loading: false, exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "GST Report.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    onSearchClick() {
        this.setState({ recordFrom: 0, loading: true })
        this.loadInvoiceDetailReport(this.state.storeList,0,
            this.state.itemsCountPerPage,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate, this.state.searchTransactionToDate)
    }

    onStoreChange(e) {
        let storeList = []
        // console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        // console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>GSTR1 - B2B</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction Type</label>
                                            <select type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactiontype: evt.target.value })}
                                                value={this.state.searchTransactiontype}
                                            >
                                                <option value="">All</option>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Year</label>
                                            <select type="text" className="form-control"
                                                value={this.state.selectedYear}
                                                onChange={this.onYearChange}>
                                                {this.state.years.map((e) => <option value={e}>{e}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Month</label>
                                            <select type="text" className="form-control"
                                                value={this.state.selectedMonth}
                                                onChange={this.onMonthChange}>
                                                {this.state.months.map((e) => <option value={e.Month}>{e.MonthName}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Show</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download " ></i></button>
                                        {/* <span className="pull-right" style={{ marginTop: "30px", marginRight: "5px" }}>
                                                <input type="checkbox" checked={this.state.includeGSTImportColumnsOnly} onClick={()=> this.setState({includeGSTImportColumnsOnly:!this.state.includeGSTImportColumnsOnly})}/>
                                            <label>Include only GST import format columns</label>
                                        </span> */}
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                        {ReactHtmlParser(this.state.invoices)}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(GSTR1B2B)