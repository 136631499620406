import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import { HotKeys } from "react-hotkeys";
import "toastr/build/toastr.min.css";
import "./Style.css";
import Print from "./Print";
import PaymentWalletWidget from "./PaymentWalletWidget";
import PaymentLoyaltyWidget from "./PaymentLoyaltyWidget";
import PaymentRRNWidget from "./PaymentRRNWidget";
import Modal from "react-responsive-modal";
// import HeaderNew from '../HeaderNew';
// import Footer from '../Common/Footer';
// import { isNullOrUndefined } from '../../../node_modules/util';
import { mSwipePrinting, isMobileApp } from "../../actions/Util";
import Button from "../2/Controls/Button";
import ImageButton from "../2/Controls/ImageButton";
import Window from "../2/Controls/Window";
import Input from "../2/Controls/Input";
import * as constants from "../../Constatnts";
import { pinelabsDevicePrinting } from "./SalesFunctions";
import { releaseEasyRewardzRedemptionPoints } from "../../services/api/EasyRewardz";

var QRCode = require("qrcode.react");

const paymentKeyMap = {
  paymentOptions: [
    "f1",
    "f2",
    "f3",
    "f4",
    "f5",
    "f6",
    "f7",
    "f8",
    "f9",
    "f10",
    "f11",
  ],
  done: "f12",
};

class Payment extends Component {
  constructor() {
    super();
    this.state = {
      sale: null,
      key: "",
      selectedPaymentMode: "",
      selectedPaymentModeID: "",
      balanceAmount: 0,
      paidAmount: 0,
      paymentModeAmount: 0,
      paymentModeRemarks: "",
      changeDue: 0,
      payments: [],
      firstCurrencyClick: true,
      printChecked: true,
      sendSMSChecked: false,
      sendWhatsAppChecked: false,
      sendEmailChecked: false,
      SMSNo: "",
      emailID: "",
      selectedPringConfigID: "",
      selectedPrintConfigPrintDisplayName: "",
      selectedPrintConfig: null,
      walletBalance: 0,
      OTPVerificationRequired: false,
      OTP: "",
      walletAdded: false,
      saving: false,
      selectedPaymentOptionLoading: false,
      print: false,
      printKOT: false,
      printKOTData: null,
      paymentModes: [],
      outstandingRRNs: [],
      selectedRRNID: "",
      loyaltyPoints: 0,
      loyaltyValue: 0,
      pointRedemption: 0,
      loyaltyTypeID: "",
      loyaltyType: "",
      mobileShowMoreOptions: false,
      showThirdPartyPaymentProcessing: false,
      thirdPartyPaymentProcessingMessage: "Please wait",
      thirdPartyPaymentProcessingStatus: "Payment Initiated",
      thirdPartyPaymentProviderReferenceID: "",
      thiryPartyPaymentTransactionID: "",
      checkThirdPartyPaymentStatusTimerID: null,
      showThirdPartyPaymentProcessingLoader: true,
      showQR: false,
      qrString: "",
      dueDate: "",
      powerUserOTP: "",
      powerUserOTPError: "",
      easyRewardzTransactionCode: "",
      easyRewardzTransactionDate: "",
      easyRewardzOTP: "",
    };

    this.onPaymentModeSelected = this.onPaymentModeSelected.bind(this);
    this.onAddPaymentAmountClicked = this.onAddPaymentAmountClicked.bind(this);
    this.onSaveSalesTransaction = this.onSaveSalesTransaction.bind(this);
    this.preSaveChecking = this.preSaveChecking.bind(this);
    this.onCurrencyClick = this.onCurrencyClick.bind(this);
    this.addPaymentAmount = this.addPaymentAmount.bind(this);
    this.paymentModeSelected = this.paymentModeSelected.bind(this);
    this.roundUpTo2 = this.roundUpTo2.bind(this);
    this.printFormatting = this.printFormatting.bind(this);
    this.shortcutPaymentOptions = this.shortcutPaymentOptions.bind(this);
    this.shortcutDone = this.shortcutDone.bind(this);
    //   this.wiseposPrinting = this.wiseposPrinting.bind(this)
    this.thirdPartyPaymentProcessing = this.thirdPartyPaymentProcessing.bind(
      this
    );
    this.checkThirdPartyPaymentStatusTimer = this.checkThirdPartyPaymentStatusTimer.bind(
      this
    );
    this.blueToothPrinting = this.blueToothPrinting.bind(this);
    this.checkIfThirdPartyPaymentProcessingApplicable = this.checkIfThirdPartyPaymentProcessingApplicable.bind(
      this
    );
    this.pinelabsPrinting = this.pinelabsPrinting.bind(this);
    this.worldlineDevicePrinting = this.worldlineDevicePrinting.bind(this);
    this.getKOTGroupPrints = this.getKOTGroupPrints.bind(this);
    this.postSaveActions = this.postSaveActions.bind(this);
    this.getKOTGroupDataForPrinting = this.getKOTGroupDataForPrinting.bind(
      this
    );
    this.onValidatePowerUserOTP = this.onValidatePowerUserOTP.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.onClosePaymentScreen = this.onClosePaymentScreen.bind(this);
    this.rollBackEasyRewardz = this.rollBackEasyRewardz.bind(this);
    //   this.mSwipePrinting = this.mSwipePrinting.bind(this)
  }

  shortcutPaymentOptions(event) {
    event.preventDefault();
    let index = -1;
    if (event.key.toLowerCase() === "f1") {
      index = 0;
    } else if (event.key.toLowerCase() === "f2") {
      index = 1;
    } else if (event.key.toLowerCase() === "f3") {
      index = 2;
    } else if (event.key.toLowerCase() === "f4") {
      index = 3;
    } else if (event.key.toLowerCase() === "f5") {
      index = 4;
    } else if (event.key.toLowerCase() === "f6") {
      index = 5;
    } else if (event.key.toLowerCase() === "f7") {
      index = 6;
    } else if (event.key.toLowerCase() === "f8") {
      index = 7;
    } else if (event.key.toLowerCase() === "f9") {
      index = 8;
    } else if (event.key.toLowerCase() === "f10") {
      index = 9;
    } else if (event.key.toLowerCase() === "f11") {
      index = 10;
    }
    if (this.state.paymentModes.length >= index) {
      // console.log('this.state.paymentModes[index]',this.state.paymentModes[index])
      this.onPaymentModeSelected(this.state.paymentModes[index], true);
    }
  }

  shortcutDone(event) {
    event.preventDefault();
    // this.onSaveSalesTransaction()

    this.preSaveChecking(
      this.state.key,
      this.state.sale,
      this.state.balanceAmount,
      this.state.paidAmount,
      this.state.changeDue,
      this.state.payments,
      this.state.printChecked,
      this.state.sendSMSChecked,
      this.state.SMSNo,
      this.state.sendEmailChecked,
      this.state.emailID,
      this.state.selectedPringConfigID,
      this.state.sendWhatsAppChecked,
      this.state.dueDate
    );
    // this.onSaveSalesTransaction(this.state.key, this.state.sale, this.state.balanceAmount, this.state.paidAmount, this.state.changeDue, this.state.payments, this.state.printChecked, this.state.sendSMSChecked,  this.state.SMSNo, this.state.sendEmailChecked, this.state.emailID, this.state.selectedPringConfigID, this.state.sendWhatsAppChecked, this.state.dueDate)
  }

  componentDidMount() {
    //firt payment mode to be made selected
    // console.log('payment - this.props.transactionID', this.props.transactionID)
    let selectedPaymentMode = "";
    let selectedPaymentModeID = "";
    let selectedPringConfigID = "";
    let paymentModes = this.props.config.PaymentTypes.filter(
      (e) => e.ShowInSales && !e.IsDeleted
    );
    let printChecked = false;
    let sendSMSChecked = false;
    let sendWhatsAppChecked = false;
    let SMSNo = "";
    let sendEmailChecked = false;
    let emailID = "";
    let selectedPrintConfigPrintDisplayName = "";

    // console.log('mobile',this.props.mobile)
    if (this.props.sale.TransactionType.hasOwnProperty("PaymentTypes")) {
      let transactionTypePaymentModes = [];
      paymentModes.map((m) => {
        if (
          this.props.sale.TransactionType.PaymentTypes.filter(
            (f) => f.PaymentTypeID === m.key
          ).length > 0
        ) {
          transactionTypePaymentModes.push(m);
        }
      });
      paymentModes = transactionTypePaymentModes;
    }

    if (paymentModes.length > 0) {
      if (
        paymentModes[0].PaymentType.toLowerCase() !== "wallet" &&
        paymentModes[0].PaymentType.toLowerCase() !==
          "return redemption note" &&
        paymentModes[0].PaymentType.toLowerCase() !== "loyalty" &&
        !this.props.isMobileApp()
      ) {
        selectedPaymentModeID = paymentModes[0].key;
        selectedPaymentMode = paymentModes[0].PaymentType;
      }
    }
    //console.log('paymentModes', paymentModes)
    //firt print confign to be made selected
    let printConfig = this.props.config.PrintConfigs.filter(
      (e) =>
        !e.IsDeleted &&
        e.TransactionTypeID === this.props.sale.TransactionType.key
    );
    if (printConfig.length > 0) {
      selectedPringConfigID = printConfig[0].key;
      selectedPrintConfigPrintDisplayName = printConfig[0].PrintDisplayName;
      printChecked = true;
    } else {
      printChecked = false;
    }
    //if(this.props.location.state.sale.Customer)
    if (this.props.sale.Customer) {
      if (this.props.sale.Customer.PhoneNo !== "") {
        sendSMSChecked = true;
        // sendWhatsAppChecked = true
        SMSNo = this.props.sale.Customer.PhoneNo;
      }
      if (this.props.sale.Customer.EmailID !== "") {
        sendEmailChecked = true;
        emailID = this.props.sale.Customer.EmailID;
      }
    }

    if (this.props.sale.BillAmount > 0) {
      var date = this.props.addDays(7);
      date = date.toISOString().split("T")[0];
    }

    let payments = [];
    let balanceAmount = this.props.sale.BillAmount;
    let paidAmount = 0;
    if (this.props.payments && this.props.payments.length > 0) {
      payments = this.props.payments;
      payments.map((p) => {
        balanceAmount = Number(balanceAmount) - Number(p.Amount);
      });
      paidAmount = Number(this.props.sale.BillAmount) - Number(balanceAmount);
      console.log("payments passed", payments);
    }
    this.setState({
      sale: this.props.sale,
      balanceAmount: balanceAmount,
      paymentModeAmount: balanceAmount,
      key: this.props.transactionID,
      selectedPaymentMode: selectedPaymentMode,
      selectedPaymentModeID: selectedPaymentModeID,
      selectedPringConfigID: selectedPringConfigID,
      selectedPrintConfigPrintDisplayName: selectedPrintConfigPrintDisplayName,
      printChecked: printChecked,
      sendSMSChecked: sendSMSChecked,
      sendWhatsAppChecked: sendWhatsAppChecked,
      SMSNo: SMSNo,
      sendEmailChecked: sendEmailChecked,
      emailID: emailID,
      paymentModes: paymentModes,
      dueDate: date,
      payments: payments,
      paidAmount: paidAmount,
    });

    if (this.props.tableOrder && this.props.printKOT) {
      // alert('printKOT')
      let sale = this.props.sale;
      // sale.TransactionStatus = "Pending"
      this.preSaveChecking(
        this.props.transactionID,
        sale,
        this.props.sale.BillAmount,
        0,
        0,
        [],
        true,
        false,
        "",
        false,
        "",
        selectedPringConfigID,
        false
      );
      // this.onSaveSalesTransaction(this.props.transactionID, sale, this.props.sale.BillAmount, 0, 0, [], true, false, "", false, "", selectedPringConfigID, false)
    }
    if (this.props.tableOrder && this.props.printBill) {
      let sale = this.props.sale;
      // sale.TransactionStatus = "PrintBill"
      this.preSaveChecking(
        this.props.transactionID,
        sale,
        this.props.sale.BillAmount,
        0,
        0,
        [],
        true,
        false,
        "",
        false,
        "",
        selectedPringConfigID,
        false
      );
      // this.onSaveSalesTransaction(this.props.transactionID, sale, this.props.sale.BillAmount, 0, 0, [], true, false, "", false, "", selectedPringConfigID, false)
    }
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  async onPaymentModeSelected(paymentMode, shortCut = false) {
    console.log("onPaymentModeSelected", paymentMode);
    if (
      this.state.selectedPaymentMode.toLowerCase() ===
      paymentMode.PaymentType.toLowerCase()
    ) {
      console.log("onPaymentModeSelected - same payment mode slected again");
      return;
    }
    if (
      paymentMode.PaymentType.toLowerCase() === "cash" ||
      paymentMode.PaymentType.toLowerCase() === "wallet" ||
      paymentMode.PaymentType.toLowerCase() === "loyalty"
    ) {
      if (
        this.state.payments.filter(
          (p) =>
            p.PaymentMode.toLowerCase() ===
            paymentMode.PaymentType.toLowerCase()
        ).length > 0
      ) {
        alert("Payment mode already added");
        // this.setState({ selectedPaymentOptionLoading: false })
        return;
      }
    }

    if (
      this.state.selectedPaymentMode.toLowerCase() === "loyalty" &&
      this.state.payments.filter(
        (p) => p.PaymentMode.toLowerCase() === "loyalty"
      ).length === 0
    ) {
      // alert('previosu loyalty')
      this.rollBackEasyRewardz();
      this.setState({
        easyRewardzTransactionCode: "",
        easyRewardzTransactionDate: "",
      });
    }
    let walletBalance = 0;
    let OTPVerificationRequired = false;
    this.setState({
      selectedPaymentOptionLoading: true,
      outstandingRRNs: [],
      selectedRRNID: "",
      paymentProvider: "",
    });

    if (paymentMode.PaymentType.toLowerCase() === "wallet") {
      if (!this.state.sale.Customer) {
        alert("Please select customer");
        if (!window.cordova) {
          this.refs.txtPaymentModeAmount.focus();
        }
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }
      let w = await this.props.getCustomerWalletBalance(
        this.state.sale.Customer.key
      );
      // .then((w)=>{
      walletBalance = this.roundUpTo2(w);
      let sale = this.state.sale;
      sale.Customer.WalletBalance = walletBalance;
      this.setState({ sale: sale });
      if (walletBalance <= 0) {
        alert("No wallet balance");
        if (!window.cordova) {
          this.refs.txtPaymentModeAmount.focus();
        }
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }
      if (paymentMode.OTPVerification) { 
        OTPVerificationRequired = paymentMode.OTPVerification;
        let otpGenerated = await this.props.generateOTP(
          this.props.user.user.RegistrationID,
          this.props.user.store.key,
          this.props.user.cashRegister.key,
          this.state.sale.Customer.key,
          "walletotp"
        );
        if (!otpGenerated) {
          alert("Unable to generate OTP");
          if (!window.cordova) {
            this.refs.txtPaymentModeAmount.focus();
          }
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        }
      }
      this.setState({ selectedPaymentOptionLoading: false });
      this.paymentModeSelected(
        paymentMode.key,
        paymentMode.PaymentType,
        this.state.balanceAmount,
        walletBalance,
        OTPVerificationRequired
      );
    } else if (paymentMode.PaymentType.toLowerCase() === "loyalty") {
      // console.log('paymentMode', paymentMode)
      if (!this.state.sale.Customer) {
        alert("Please select customer");
        // if (!window.cordova) { this.refs.txtPaymentModeAmount.focus() }
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }

      this.paymentModeSelected(
        paymentMode.key,
        paymentMode.PaymentType,
        this.state.balanceAmount,
        walletBalance,
        OTPVerificationRequired
      );
      //get loyalty setup
    } else if (
      paymentMode.PaymentType.toLowerCase() === "return redemption note" &&
      this.state.balanceAmount > 0
    ) {
      if (!this.state.sale.Customer) {
        this.setState({ selectedPaymentOptionLoading: false });
        alert("Please select customer");
        if (!window.cordova) {
          this.refs.txtPaymentModeAmount.focus();
        }
        return;
      }
      //get outsanding rrn
      let outstandingRRNs = await this.props.getOutstandingRRN(
        this.state.sale.Customer.key
      );
      if (outstandingRRNs.length === 0) {
        this.setState({ selectedPaymentOptionLoading: false });
        alert("No outstanding RRN found");
        if (!window.cordova) {
          this.refs.txtPaymentModeAmount.focus();
        }
        return;
      }
      this.setState({
        outstandingRRNs: outstandingRRNs,
        selectedPaymentOptionLoading: false,
      });
      this.paymentModeSelected(
        paymentMode.key,
        paymentMode.PaymentType,
        this.state.balanceAmount,
        walletBalance,
        OTPVerificationRequired
      );
    } else if (
      paymentMode.PaymentType.toLowerCase() === "return redemption note" &&
      this.state.balanceAmount < 0
    ) {
      this.setState({ selectedPaymentOptionLoading: false });
      if (!this.state.sale.Customer) {
        alert("Please select customer");
        if (!window.cordova) {
          this.refs.txtPaymentModeAmount.focus();
        }
        return;
      }
      this.paymentModeSelected(
        paymentMode.key,
        paymentMode.PaymentType,
        this.state.balanceAmount,
        walletBalance,
        OTPVerificationRequired
      );
    } else {
      if (shortCut) {
        //add to selected payment mode
        this.setState({ selectedPaymentOptionLoading: false });
        await this.onAddPaymentAmountClicked(
          paymentMode.key,
          paymentMode.PaymentType,
          this.state.paymentModeAmount
        );
      } else {
        this.setState({ selectedPaymentOptionLoading: false });
        this.paymentModeSelected(
          paymentMode.key,
          paymentMode.PaymentType,
          this.state.balanceAmount,
          walletBalance,
          OTPVerificationRequired
        );
        // this.refs.txtPaymentModeAmount.focus()
      }
    }
  }

  async checkIfThirdPartyPaymentProcessingApplicable(paymentModeID) {
    // console.log('paymentModeID',paymentModeID)
    let paymentProvider = "";
    if (
      this.props.user.cashRegister.hasOwnProperty("CashRegisterPaymentSetup")
    ) {
      let cashRegisterPaymentSetup = this.props.user.cashRegister
        .CashRegisterPaymentSetup;
      for (let i = 0; i < cashRegisterPaymentSetup.length; i++) {
        if (
          cashRegisterPaymentSetup[i].PaymentModes.filter(
            (f) => f.PaymentTypeID === paymentModeID
          ).length > 0
        ) {
          //initiate payment provider code
          paymentProvider = cashRegisterPaymentSetup[i].PaymentProvider;
        }
      }
    }
    return paymentProvider;
  }

  paymentModeSelected(
    selectedPaymentModeID,
    selectedPaymentMode,
    paymentModeAmount,
    walletBalance,
    OTPVerificationRequired
  ) {
    this.setState({
      selectedPaymentModeID: selectedPaymentModeID,
      selectedPaymentMode: selectedPaymentMode,
      paymentModeAmount: paymentModeAmount,
      firstCurrencyClick: true,
      walletBalance: walletBalance,
      OTPVerificationRequired: OTPVerificationRequired,
      selectedPaymentOptionLoading: false,
    });
  }

  async onAddPaymentAmountClicked(
    selectedPaymentModeID,
    selectedPaymentMode,
    paymentModeAmount
  ) {
    //payment amount should be greater than zero
    this.setState({ selectedPaymentOptionLoading: true });
    let paymentProvider = await this.checkIfThirdPartyPaymentProcessingApplicable(
      selectedPaymentModeID
    );

    if (
      selectedPaymentMode.toLowerCase() === "" ||
      selectedPaymentModeID === ""
    ) {
      alert("Please select payment mode");
      this.setState({ selectedPaymentOptionLoading: false });
      return;
    }
    if (paymentModeAmount === 0) {
      alert("Please provide amount");
      this.setState({ selectedPaymentOptionLoading: false });
      return;
    }
    if (
      selectedPaymentMode.toLowerCase() !== "cash" &&
      paymentModeAmount > 0 &&
      paymentModeAmount > this.state.balanceAmount
    ) {
      alert("Amount can not be more than balance amount");
      this.setState({ selectedPaymentOptionLoading: false });
      if (!window.cordova) {
        this.refs.txtPaymentModeAmount.focus();
      }
      return;
    }
    if (
      selectedPaymentMode.toLowerCase() === "cash" ||
      selectedPaymentMode.toLowerCase() === "wallet" ||
      selectedPaymentMode.toLowerCase() === "loyalty" ||
      (selectedPaymentMode.toLowerCase() === "return redemption note" &&
        paymentModeAmount < 0)
    ) {
      if (
        this.state.payments.filter(
          (p) =>
            p.PaymentMode.toLowerCase() === selectedPaymentMode.toLowerCase()
        ).length > 0
      ) {
        alert("Payment mode already added");
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }
    }
    if (
      selectedPaymentMode.toLowerCase() === "return redemption note" &&
      paymentModeAmount > 0
    ) {
      //ceheck if selected rrn exists
      if (this.state.selectedRRNID.trim() === "") {
        alert("Please select RRN");
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }
      //user can not add the sam ereturn redemption note twice
      if (
        this.state.payments.filter(
          (p) =>
            p.PaymentMode.toLowerCase() === selectedPaymentMode.toLowerCase() &&
            p.hasOwnProperty("RRNID") &&
            p.RRNID === this.state.selectedRRNID
        ).length > 0
      ) {
        alert("Selected RRN already added");
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }
      //check amount selected should be valaible in selected RRN
      if (
        paymentModeAmount >
        Number(
          this.state.outstandingRRNs.filter(
            (f) => f.key === this.state.selectedRRNID
          )[0].BalanceAmount
        )
      ) {
        alert("Insuficient RRN balance");
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      }
    }
    if (
      paymentModeAmount <= 0 &&
      this.state.sale.TransactionType.TransactionType.toLowerCase() !==
        "sales return"
    ) {
      alert("Please provide payment amount");
      this.setState({ selectedPaymentOptionLoading: false });
      return;
    }
    if (selectedPaymentMode.toLowerCase() === "wallet") {
      if (paymentModeAmount > this.state.walletBalance) {
        alert("Insuficient wallet balance");
        this.setState({ selectedPaymentOptionLoading: false });
        return;
      } else if (this.state.OTPVerificationRequired) {
        if (this.state.OTP.trim() === "") {
          alert("Please provide OTP");
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        } else {
          //validate OTP
          let otpVerified = false;
          otpVerified = await this.props.validateOTP(
            this.props.user.user.RegistrationID,
            this.props.user.store.key,
            this.props.user.cashRegister.key,
            this.state.sale.Customer.key,
            this.state.OTP
          );
          if (!otpVerified) {
            alert("Invalid OTP");
            this.setState({ selectedPaymentOptionLoading: false });
            return;
          }
        }
        this.setState({
          walletAdded: true,
          selectedPaymentOptionLoading: false,
        });
      }
      this.setState({ walletAdded: true, selectedPaymentOptionLoading: false });
    }
    if (selectedPaymentMode.toLowerCase() === "loyalty") {
      //do nothing
      return;
    }
    if (
      this.props.config.Device &&
      this.props.config.Device !== "" &&
      selectedPaymentMode.toLowerCase() === "card"
    ) {
      paymentProvider = this.props.config.Device; //this was introduced worldline device
    }
    if (paymentProvider !== "") {
      // console.log('paymentProvider',paymentProvider)
      // console.log('selectedPaymentModeID', selectedPaymentModeID);
      // console.log('selectedPaymentMode', selectedPaymentMode);
      // console.log('paymentModeAmount', paymentModeAmount)
      await this.thirdPartyPaymentProcessing(
        paymentProvider,
        selectedPaymentModeID,
        selectedPaymentMode,
        paymentModeAmount
      );
    } else {
      this.setState({ selectedPaymentOptionLoading: false });
      this.addPaymentAmount(
        selectedPaymentModeID,
        selectedPaymentMode,
        paymentModeAmount,
        paymentProvider,
        "",
        ""
      );
    }
  }

  addPaymentAmount(
    selectedPaymentModeID,
    selectedPaymentMode,
    paymentModeAmount,
    paymentProvider = "",
    thirdPartyTransactionID = "",
    paymentProviderReferenceID = ""
  ) {
    // console.log('selectedPaymentMode', selectedPaymentMode)
    // console.log('thirdPartyTransactionID', thirdPartyTransactionID)
    // console.log('paymentProviderReferenceID', paymentProviderReferenceID)
    let changeDue = 0;
    let balanceAmount = 0;
    let paidAmount = 0;
    let payments = [];
    if (selectedPaymentMode.toLowerCase() === "cash") {
      //amount more than bill amount is allowed
      if (paymentModeAmount > this.state.balanceAmount) {
        changeDue =
          Number(paymentModeAmount) - Number(this.state.balanceAmount);
        balanceAmount = 0;
        paidAmount = Number(this.state.sale.BillAmount);
      } else {
        changeDue = 0;
        balanceAmount =
          Number(this.state.balanceAmount) - Number(paymentModeAmount);
        paidAmount = Number(this.state.paidAmount) + Number(paymentModeAmount);
      }
      payments = this.state.payments;
      payments.push({
        PaymentMode: selectedPaymentMode,
        PaymentModeID: selectedPaymentModeID,
        PaymentProvider: paymentProvider,
        Amount: paymentModeAmount,
        PaymentModeRemarks: this.state.paymentModeRemarks,
      });
      this.setState({
        changeDue: changeDue,
        balanceAmount: balanceAmount,
        paidAmount: paidAmount,
        paymentModeAmount: balanceAmount,
        payments: payments,
        paymentModeRemarks: "",
        outstandingRRNs: [],
        selectedRRNID: "",
      });
    } else {
      // console.log('return redemption note selectedPaymentMode ...1',selectedPaymentMode)
      payments = this.state.payments;
      let RRNID = "";
      let RRNTransactionNo = "";
      // console.log('return redemption note selectedPaymentMode...2',selectedPaymentMode)
      if (
        selectedPaymentMode.toLowerCase() === "return redemption note" &&
        paymentModeAmount > 0
      ) {
        RRNTransactionNo = this.state.outstandingRRNs.filter(
          (f) => f.key === this.state.selectedRRNID
        )[0].TransactionNo;
        RRNID = this.state.outstandingRRNs.filter(
          (f) => f.key === this.state.selectedRRNID
        )[0].key;
      }
      payments.push({
        PaymentMode: selectedPaymentMode,
        PaymentModeID: selectedPaymentModeID,
        Amount: paymentModeAmount,
        PaymentModeRemarks: this.state.paymentModeRemarks,
        RRNID: RRNID,
        RRNTransactionNo: RRNTransactionNo,
        PaymentProvider: paymentProvider,
        ThirdPartyTransactionID: thirdPartyTransactionID,
        PaymentProviderReferenceID: paymentProviderReferenceID,
      });

      changeDue = 0;
      balanceAmount =
        Number(this.state.balanceAmount) - Number(paymentModeAmount);
      paidAmount = Number(this.state.paidAmount) + Number(paymentModeAmount);
      // let selectedPaymentModeIDNew= ''
      // let selectedPaymentMode1 = ''
      // // let paymentModes = this.props.config.PaymentTypes.filter((e)=> !e.IsDeleted)
      // let paymentModes = this.state.paymentModes
      // if(paymentModes.length >  0 )
      // {
      //     selectedPaymentModeID = paymentModes[0].key
      //     selectedPaymentMode1 = paymentModes[0].PaymentType
      // }
      this.setState({
        payments: payments,
        selectedPaymentModeID: "",
        selectedPaymentMode: "",
        walletBalance: 0,
        changeDue: changeDue,
        balanceAmount: balanceAmount,
        paidAmount: paidAmount,
        paymentModeAmount: balanceAmount,
        OTPVerificationRequired: false,
        paymentModeRemarks: "",
        selectedRRNID: "",
        outstandingRRNs: [],
      });
    }
    // if (!window.cordova) { this.refs.txtPaymentModeAmount.focus() }
  }

  async thirdPartyPaymentProcessing(
    paymentProvider,
    paymentModeID,
    paymentMode,
    paymentModeAmount
  ) {
    this.setState({
      thirdPartyPaymentProcessingMessage: "Please wait",
      thirdPartyPaymentProcessingStatus: "Payment Initiated",
      thirdPartyPaymentProviderReferenceID: "",
      thiryPartyPaymentTransactionID: "",
    });
    // console.log('paymentProvider', paymentProvider)
    if (paymentProvider.trim() !== "") {
      if (paymentProvider === "worldline") {
        this.setState({ showThirdPartyPaymentProcessing: true });
        let transaction = {
          RegistrationID: this.props.user.user.RegistrationID,
          StoreID: this.props.user.store.key,
          CashRegisterID: this.props.user.cashRegister.key,
          PaymentProvider: paymentProvider,
          PaymentTypeID: paymentModeID,
          PaymentType: paymentMode,
          Amount: paymentModeAmount,
          UserID: this.props.user.user.key,
        };
        // console.log('worldline transaction',transaction)
        let response = await this.props.initiateThirdPartyPayment(transaction);
        // console.log('paymentModeID',paymentModeID);
        // console.log('paymentMode',paymentMode);
        // console.log('paymentModeAmount',paymentModeAmount);

        this.setState({
          thirdPartyPaymentProcessingMessage: response.data.text.message,
          thirdPartyPaymentProcessingStatus: response.data.text.status,
          thirdPartyPaymentProviderReferenceID:
            response.data.text.thirdPartyPaymentProviderReferenceID,
          thiryPartyPaymentTransactionID: response.data.text.transactionID,
          paymentProvider: paymentProvider,
          paymentModeID: paymentModeID,
          paymentMode: paymentMode,
          paymentModeAmount: paymentModeAmount,
        });
        // console.log('third party payment response', response)
        if (response.data.text.status.toLowerCase() !== "payment error") {
          let checkThirdPartyPaymentStatusTimerID = setInterval(
            this.checkThirdPartyPaymentStatusTimer,
            5000
          );
          this.setState({
            checkThirdPartyPaymentStatusTimerID: checkThirdPartyPaymentStatusTimerID,
          });
        } else {
          this.setState({ selectedPaymentOptionLoading: false });
          setTimeout(
            function() {
              //Start tselectedPaymentMode
              this.setState({ showThirdPartyPaymentProcessing: false });
            }.bind(this),
            2000
          );
        }
      } else if (
        paymentProvider === "phonepe" ||
        paymentProvider === "phonepe qr"
      ) {
        if (!this.state.sale.Customer && paymentProvider === "phonepe") {
          alert("Please select customer");
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        }
        this.setState({ showThirdPartyPaymentProcessing: true });
        let transaction = {
          RegistrationID: this.props.user.user.RegistrationID,
          StoreID: this.props.user.store.key,
          CashRegisterID: this.props.user.cashRegister.key,
          PaymentProvider: paymentProvider,
          PaymentTypeID: paymentModeID,
          PaymentType: paymentMode,
          Amount: paymentModeAmount,
          CustomerCode: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.CustomerCode,
          CustomerName: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.FirstName,
          CustomerNo: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.PhoneNo,
          CustomerEmailID: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.EmailID,
          CustomerKey: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.key,
          UserID: this.props.user.user.key,
        };
        // console.log('transaction',transaction)
        let response = await this.props.initiateThirdPartyPayment(transaction);

        this.setState({
          thirdPartyPaymentProcessingMessage: response.data.text.message,
          thirdPartyPaymentProcessingStatus: response.data.text.status,
          thirdPartyPaymentProviderReferenceID:
            response.data.text.thirdPartyPaymentProviderReferenceID,
          thiryPartyPaymentTransactionID: response.data.text.transactionID,
          paymentProvider: paymentProvider,
          paymentModeID: paymentModeID,
          paymentMode: paymentMode,
          paymentModeAmount: paymentModeAmount,
        });
        // console.log('initiateThirdPartyPayment - response', response)
        if (response.data.text.status.toLowerCase() !== "payment error") {
          //if qr then render qr
          if (
            paymentProvider === "phonepe qr" &&
            response.data.text.qrString === ""
          ) {
            alert("Unable to get QR code from Phonepe. Please try again");
            this.setState({ selectedPaymentOptionLoading: false });
            setTimeout(
              function() {
                //Start tselectedPaymentMode
                this.setState({ showThirdPartyPaymentProcessing: false });
              }.bind(this),
              2000
            );
            return;
          } else if (
            paymentProvider === "phonepe qr" &&
            response.data.text.qrString !== ""
          ) {
            // console.log('response.data.text.qrString', response.data.text.qrString)
            this.setState({
              showQR: true,
              qrString: response.data.text.qrString,
            });
          }
          let checkThirdPartyPaymentStatusTimerID = setInterval(
            this.checkThirdPartyPaymentStatusTimer,
            5000
          );
          this.setState({
            checkThirdPartyPaymentStatusTimerID: checkThirdPartyPaymentStatusTimerID,
          });
        } else {
          //if it is error then show window for some time and then close the window
          this.setState({ selectedPaymentOptionLoading: false });
          setTimeout(
            function() {
              //Start tselectedPaymentMode
              this.setState({ showThirdPartyPaymentProcessing: false });
            }.bind(this),
            2000
          );
        }
      } else if (paymentProvider === "paytm") {
        if (!this.state.sale.Customer) {
          alert("Please select customer");
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        }
        this.setState({ showThirdPartyPaymentProcessing: true });
        let transaction = {
          RegistrationID: this.props.user.user.RegistrationID,
          StoreID: this.props.user.store.key,
          CashRegisterID: this.props.user.cashRegister.key,
          PaymentProvider: paymentProvider,
          PaymentTypeID: paymentModeID,
          PaymentType: paymentMode,
          Amount: paymentModeAmount,
          CustomerCode: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.CustomerCode,
          CustomerName: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.FirstName,
          CustomerNo: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.PhoneNo,
          CustomerEmailID: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.EmailID,
          CustomerKey: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.key,
          UserID: this.props.user.user.key,
        };
        let response = await this.props.initiateThirdPartyPayment(transaction);
        this.setState({
          thirdPartyPaymentProcessingMessage: response.data.text.message,
          thirdPartyPaymentProcessingStatus: response.data.text.status,
          thirdPartyPaymentProviderReferenceID:
            response.data.text.thirdPartyPaymentProviderReferenceID,
          thiryPartyPaymentTransactionID: response.data.text.transactionID,
          paymentProvider: paymentProvider,
          paymentModeID: paymentModeID,
          paymentMode: paymentMode,
          paymentModeAmount: paymentModeAmount,
        });
        // console.log('paytm initiateThirdPartyPayment', response)
        if (response.data.text.status.toLowerCase() !== "payment error") {
          let checkThirdPartyPaymentStatusTimerID = setInterval(
            this.checkThirdPartyPaymentStatusTimer,
            5000
          );
          this.setState({
            checkThirdPartyPaymentStatusTimerID: checkThirdPartyPaymentStatusTimerID,
          });
        } else {
          //if it is error then show window for some time and then close the window
          this.setState({ selectedPaymentOptionLoading: false });
          setTimeout(
            function() {
              //Start tselectedPaymentMode
              this.setState({ showThirdPartyPaymentProcessing: false });
            }.bind(this),
            2000
          );
        }
      } else if (paymentProvider === "wisepos collect call") {
        // console.log(paymentProvider)
        this.setState({ showThirdPartyPaymentProcessing: true });
        let transaction = {
          RegistrationID: this.props.user.user.RegistrationID,
          StoreID: this.props.user.store.key,
          CashRegisterID: this.props.user.cashRegister.key,
          PaymentProvider: paymentProvider,
          PaymentTypeID: paymentModeID,
          PaymentType: paymentMode,
          Amount: paymentModeAmount,
          CustomerCode: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.CustomerCode,
          CustomerName: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.FirstName,
          CustomerNo: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.PhoneNo,
          CustomerEmailID: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.EmailID,
          CustomerKey: !this.state.sale.Customer
            ? ""
            : this.state.sale.Customer.key,
          UserID: this.props.user.user.key,
        };
        let response = await this.props.initiateThirdPartyPayment(transaction);
        this.setState({
          thirdPartyPaymentProcessingMessage: response.data.text.message,
          thirdPartyPaymentProcessingStatus: response.data.text.status,
          thirdPartyPaymentProviderReferenceID:
            response.data.text.thirdPartyPaymentProviderReferenceID,
          thiryPartyPaymentTransactionID: response.data.text.transactionID,
          paymentProvider: paymentProvider,
          paymentModeID: paymentModeID,
          paymentMode: paymentMode,
          paymentModeAmount: paymentModeAmount,
        });
        // console.log('third party payment response', response)
        if (response.data.text.status.toLowerCase() !== "payment error") {
          let checkThirdPartyPaymentStatusTimerID = setInterval(
            this.checkThirdPartyPaymentStatusTimer,
            5000
          );
          this.setState({
            checkThirdPartyPaymentStatusTimerID: checkThirdPartyPaymentStatusTimerID,
          });
        } else {
          this.setState({ selectedPaymentOptionLoading: false });
          setTimeout(
            function() {
              //Start tselectedPaymentMode
              this.setState({ showThirdPartyPaymentProcessing: false });
            }.bind(this),
            2000
          );
        }
      } else if (
        paymentProvider === "worldlinedevice" &&
        this.props.isMobileApp() &&
        paymentMode.toLowerCase() === "card"
      ) {
        let transactionID = "POS" + Math.floor(Math.random() * 10000000000);
        // alert('worldline called')
        window.cordova.plugins.worldline.cardSale(
          {
            transactionID: transactionID,
            amount: paymentModeAmount,
          },
          (result) => {
            // console.log('result', result)
            this.addPaymentAmount(
              paymentModeID,
              paymentMode,
              paymentModeAmount,
              paymentProvider,
              transactionID,
              ""
            );
          },
          (error) => {
            alert("Transaction failed:" + error);
            this.setState({ selectedPaymentOptionLoading: false });
          }
        );
      } else if (paymentProvider === "wisepos" && this.props.isMobileApp()) {
        //get loginid and password
        if (
          !this.props.user.cashRegister.hasOwnProperty(
            "CashRegisterPaymentSetup"
          )
        ) {
          alert("Payment provider setup not found");
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        }
        if (
          this.props.user.cashRegister.CashRegisterPaymentSetup.filter(
            (f) => f.PaymentProvider === paymentProvider
          ).length === 0
        ) {
          alert("Payment provider setup not found");
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        }
        let paymentProviderDetails = this.props.user.cashRegister.CashRegisterPaymentSetup.filter(
          (f) => f.PaymentProvider === paymentProvider
        )[0];
        if (
          !paymentProviderDetails.hasOwnProperty("LoginID") ||
          !paymentProviderDetails.hasOwnProperty("Password")
        ) {
          alert("Payment provider configuration not found");
          this.setState({ selectedPaymentOptionLoading: false });
          return;
        }

        let paymentProviderLoginID = paymentProviderDetails.LoginID;
        let paymentProviderPassword = paymentProviderDetails.Password;

        let billingRefNo = "POS" + Math.floor(Math.random() * 10000000000);
        window.cordova.plugins.mswipe.cardSale(
          {
            username: paymentProviderLoginID,
            password: paymentProviderPassword,
            production: true,
            amount: paymentModeAmount,
            mobileNumber:
              this.props.sale.Customer &&
              this.props.sale.Customer.PhoneNo !== ""
                ? this.props.sale.Customer.PhoneNo
                : "",
            reciept: billingRefNo,
            notes: "",
            isPrintSignatureOnReceipt: true,
          },
          (result) => {
            this.addPaymentAmount(
              paymentModeID,
              paymentMode,
              paymentModeAmount,
              paymentProvider,
              billingRefNo,
              ""
            );
          },
          (error) => {
            alert("Transaction failed:" + error);
            this.setState({ selectedPaymentOptionLoading: false });
          }
        );
      }
      //old pine labs code 24 Sep 22
      // else if (paymentProvider === "pinelabs" && this.props.isMobileApp()) {
      //     if (!this.props.user.cashRegister.hasOwnProperty('CashRegisterPaymentSetup')) {
      //         alert('Payment provider setup not found')
      //         this.setState({ selectedPaymentOptionLoading: false })
      //         return
      //     }
      //     if (this.props.user.cashRegister.CashRegisterPaymentSetup.filter(f => f.PaymentProvider === paymentProvider).length === 0) {
      //         alert('Payment provider setup not found')
      //         this.setState({ selectedPaymentOptionLoading: false })
      //         return
      //     }
      //     let paymentProviderDetails = this.props.user.cashRegister.CashRegisterPaymentSetup.filter(f => f.PaymentProvider === paymentProvider)[0]
      //     if (!paymentProviderDetails.hasOwnProperty('LoginID')) {
      //         alert('Payment provider configuration not found')
      //         this.setState({ selectedPaymentOptionLoading: false })
      //         return
      //     }
      //     let paymentProviderLoginID = paymentProviderDetails.LoginID
      //     let billingRefNo = "POS" + Math.floor(Math.random() * 10000000000)
      //     window.cordova.plugins.plutus.call(
      //         {
      //             "Header": {
      //                 "ApplicationId": "79099725d3304811bc39a917ae358078",
      //                 "UserId": paymentProviderLoginID,
      //                 "MethodId": "1001",
      //                 "VersionNo": "1.0"
      //             },
      //             "Detail": {
      //                 "TransactionType": 4001,
      //                 "BillingRefNo": billingRefNo,
      //                 "PaymentAmount": (paymentModeAmount * 100)
      //             }
      //         },
      //         (response) => {
      //             let result = JSON.parse(response);
      //             if (result.Response.ResponseMsg.toLowerCase() === "approved") {
      //                 this.addPaymentAmount(paymentModeID, paymentMode, paymentModeAmount, paymentProvider, billingRefNo, "")
      //                 this.setState({ selectedPaymentOptionLoading: false })
      //                 return
      //             }
      //             else {
      //                 alert('Error: ' + result.Response.ResponseMsg)
      //                 this.setState({ selectedPaymentOptionLoading: false })
      //                 return
      //             }
      //         },
      //         (error) => {
      //             alert("Error: " + error)
      //             this.setState({ selectedPaymentOptionLoading: false })
      //             return
      //         }
      //     )
      // }
      else if (
        paymentProvider === constants.PINELABS_DEVICE &&
        isMobileApp() &&
        paymentMode.toLowerCase() === "card"
      ) {
        // alert('payment called');
        let transactionID = "POS" + Math.floor(Math.random() * 10000000000);
        // alert('PINELABS_DEVICE called');
        let payLoad = {
          Header: {
            ApplicationId: this.props.config.PineLabsApplicationID,
            UserId: this.props.config.AndroidPackageName,
            MethodId: "1001",
            VersionNo: "1.0",
          },
          Detail: {
            TransactionType: "4001",
            BillingRefNo: transactionID,
            PaymentAmount: (paymentModeAmount * 100).toString(),
          },
        };
        let strPayLoad = JSON.stringify(payLoad);
        // alert(strPayLoad);
        window.cordova.plugins.pinelabs.cardSale(
          {
            payLoad: strPayLoad,
          },
          (result1) => {
            let result = JSON.parse(result1);
            // alert(result.Response.ResponseMsg);
            if (
              result.Response &&
              result.Response.ResponseMsg &&
              result.Response.ResponseMsg.toLowerCase() === "approved"
            ) {
              this.addPaymentAmount(
                paymentModeID,
                paymentMode,
                paymentModeAmount,
                paymentProvider,
                transactionID,
                ""
              );
              this.setState({ selectedPaymentOptionLoading: false });
            } else {
              alert(
                "Card transaction failed. Please try again or try different payment mode"
              );
            }
            // alert(result.Response.ResponseMsg.toLowerCase());
          },
          (error) => {
            alert(
              "Card transaction failed. Please try again or try different payment mode"
            );
            // this.setState({ selectedPaymentOptionLoading: false })
          }
        );
      } else {
        this.addPaymentAmount(
          paymentModeID,
          paymentMode,
          paymentModeAmount,
          paymentProvider,
          "",
          ""
        );
        // this.addPaymentAmount( paymentModeID, paymentMode, paymentModeAmount)
        this.setState({ selectedPaymentOptionLoading: false });
      }
    }
  }

  async checkThirdPartyPaymentStatusTimer() {
    // console.log('checkThirdPartyPaymentStatusTimer --called ')
    // console.log('this.state.thiryPartyPaymentTransactionID', this.state.thiryPartyPaymentTransactionID)
    let response = await this.props.checkThirdPartyPaymentStatus(
      this.state.thiryPartyPaymentTransactionID
    );
    // console.log('checkThirdPartyPaymentStatusTimer-- response', response)
    let status = response.data.text.status;
    let message = response.data.text.message;
    let showThirdPartyPaymentProcessingLoader = true;
    if (
      response.data.text.status === "Payment Success" ||
      response.data.text.status === "Payment Fail"
    ) {
      clearInterval(this.state.checkThirdPartyPaymentStatusTimerID);
      // console.log('stop checkThirdPartyPaymentStatusTimer')
      if (response.data.text.status === "Payment Success") {
        //add directly to
        // this.addPaymentAmount(this.state.selectedPaymentMode, this.state.thiryPartyPaymentTransactionID, this.state.thirdPartyPaymentProviderReferenceID)
        // paymentProvider, paymentModeID, paymentMode, paymentModeAmount
        // this.state.paymentProvider,
        //     this.state.thiryPartyPaymentTransactionID,
        //     this.state.thirdPartyPaymentProviderReferenceID

        this.addPaymentAmount(
          this.state.paymentModeID,
          this.state.paymentMode,
          this.state.paymentModeAmount,
          this.state.paymentProvider,
          this.state.thiryPartyPaymentTransactionID,
          this.state.thirdPartyPaymentProviderReferenceID
        );

        setTimeout(
          function() {
            //Start tselectedPaymentMode
            this.setState({
              showThirdPartyPaymentProcessing: false,
              selectedPaymentOptionLoading: false,
            });
          }.bind(this),
          2000
        );
      }
      showThirdPartyPaymentProcessingLoader = false;
    }
    this.setState({
      thirdPartyPaymentProcessingStatus: status,
      thirdPartyPaymentProcessingMessage: message,
      showThirdPartyPaymentProcessingLoader: showThirdPartyPaymentProcessingLoader,
    });
  }

  async onRemovePayment(index) {
    let payments = this.state.payments;
    if (payments[index].PaymentMode.toLowerCase() === "wallet") {
      this.setState({ walletAdded: false });
    }
    if (
      payments[index].PaymentMode.toLowerCase() === "loyalty" &&
      this.props.registration.registration.EnableEasyRewardz
    ) {
      // console.log('call easyrewardz api to release blocked points')
      // let resposne = await
      this.rollBackEasyRewardz();
      this.setState({
        easyRewardzTransactionCode: "",
        easyRewardzTransactionDate: "",
      });
    }
    payments.splice(index, 1);
    let balanceAmount = 0;
    let paidAmount = 0;
    let paymentModeAmount = 0;
    let changeDue = 0;

    payments.map((p) => {
      paidAmount = Number(paidAmount) + Number(p.Amount);
    });

    balanceAmount = Number(this.state.sale.BillAmount) - Number(paidAmount);
    paymentModeAmount = Number(balanceAmount);
    if (!this.props.mobile) {
      this.refs.txtPaymentModeAmount.focus();
    }
    this.setState({
      payments: payments,
      changeDue: changeDue,
      balanceAmount: balanceAmount,
      paidAmount: paidAmount,
      paymentModeAmount: paymentModeAmount,
    });
  }

  async preSaveChecking(
    key,
    sale,
    balanceAmount,
    paidAmount,
    changeDue,
    payments,
    printChecked,
    sendSMSChecked,
    SMSNo,
    sendEmailChecked,
    emailID,
    selectedPringConfigID,
    sendWhatsAppChecked = false,
    dueDate = ""
  ) {
    if (
      sale.TransactionType &&
      sale.TransactionType.PowerUserAutorizationRequired
    ) {
      let otpGenerated = await this.props.generatePowerUserOTP(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        this.props.user.user.key
      );
      if (!otpGenerated) {
        alert("Unable to generate OTP");
        return;
      }
      this.setState({ showPowerUserAutorizationRequired: true });
      return;
    }
    this.onSaveSalesTransaction(
      key,
      sale,
      balanceAmount,
      paidAmount,
      changeDue,
      payments,
      printChecked,
      sendSMSChecked,
      SMSNo,
      sendEmailChecked,
      emailID,
      selectedPringConfigID,
      (sendWhatsAppChecked = false),
      (dueDate = "")
    );
  }

  async onValidatePowerUserOTP() {
    // console.log('validatePowerUserOTP')
    if (this.state.powerUserOTP === "") {
      // console.log('validateOTP error')
      this.setState({ powerUserOTPError: "Please provide OTP" });
      return;
      // alert('Please provide OTP')
    }
    let status = await this.props.validatePowerUserOTP(
      this.props.user.user.RegistrationID,
      this.props.user.store.key,
      this.props.user.user.key,
      this.state.powerUserOTP
    );
    if (status) {
      // console.log('validateOTP success ')
      this.setState({
        powerUserOTPError: "",
        powerUserOTP: "",
        showPowerUserAutorizationRequired: false,
      });
      this.onSaveSalesTransaction(
        this.state.key,
        this.state.sale,
        this.state.balanceAmount,
        this.state.paidAmount,
        this.state.changeDue,
        this.state.payments,
        this.state.printChecked,
        this.state.sendSMSChecked,
        this.state.SMSNo,
        this.state.sendEmailChecked,
        this.state.emailID,
        this.state.selectedPringConfigID,
        this.state.sendWhatsAppChecked,
        this.state.dueDate
      );
      // this.onSaveSalesTransaction(key, sale, balanceAmount, paidAmount, changeDue, payments, printChecked, sendSMSChecked, SMSNo, sendEmailChecked, emailID, selectedPringConfigID, sendWhatsAppChecked = false, dueDate = '')
    } else {
      this.setState({ powerUserOTPError: "Invalid OTP" });
      return;
    }
  }

  async onSaveSalesTransaction(
    key,
    sale,
    balanceAmount,
    paidAmount,
    changeDue,
    payments,
    printChecked,
    sendSMSChecked,
    SMSNo,
    sendEmailChecked,
    emailID,
    selectedPringConfigID,
    sendWhatsAppChecked = false,
    dueDate = ""
  ) {
    //save invoice
    this.setState({ saving: true });
    sale.BalanceAmount = Number(balanceAmount); //Number(this.state.balanceAmount)
    sale.BillPaidAmount = Number(paidAmount); //Number(this.state.paidAmount)
    sale.BillChangeDue = Number(changeDue); //Number(this.state.changeDue)
    sale.Payments = payments; //this.state.payments
    sale.PrintChecked = printChecked; //this.state.printChecked
    sale.PrintConfigID = selectedPringConfigID; //this.state.selectedPringConfigID
    sale.SendSMSChecked = sendSMSChecked; //this.state.sendSMSChecked
    sale.SendWhatsAppChecked = sendWhatsAppChecked;
    sale.SMSNo = SMSNo; //this.state.SMSNo
    sale.SendEmailChecked = sendEmailChecked; //this.state.sendEmailChecked
    sale.EmailID = emailID; //this.state.emailID
    sale.TransactionStatus = "Completed";
    sale.DueDate = dueDate;

    if (
      sale.TransactionType.TransactionType.toLowerCase() === "receive payment"
    ) {
      if (sale.BalanceAmount > 0) {
        alert("Balance amount not allowed.");
        if (!window.cordova) {
        }
        this.setState({ saving: false });
        return;
      }
    } else {
      if (sale.TransactionType.hasOwnProperty("BalanceAmount")) {
        if (sale.TransactionType.BalanceAmount === "partial") {
          if (sale.BillPaidAmount === 0) {
            alert("Full balance not allowed.");
            this.setState({ saving: false });
            if (!window.cordova) {
              this.refs.txtPaymentModeAmount.focus();
            }
            return;
          }
        } else if (sale.TransactionType.BalanceAmount === "no") {
          if (sale.BalanceAmount > 0) {
            alert("Balance not allowed.");
            if (!window.cordova) {
              this.refs.txtPaymentModeAmount.focus();
            }
            this.setState({ saving: false });
            return;
          }
        }
      }
    }
    sale.Payments.filter((f) => f.PaymentMode.toLowerCase() === "wallet").map(
      (m) =>
        (sale.WalletBalance =
          Number(sale.Customer.WalletBalance) - Number(m.Amount))
    );

    if (sale.BalanceAmount === 0) {
      sale.DueDate = "";
    }
    if (key.trim() === "") {
      //new sale

      // let nextRunningNo = await this.props.getTransactionRunningNo(sale.CashRegister.key, sale.TransactionType.key)
      sale.TransactionDate = new Date();
      // sale.TransactionNo = sale.TransactionNo + nextRunningNo
      sale.TransactionDateShort = new Date(sale.TransactionDate)
        .toISOString()
        .split("T")[0];
      sale.EasyRewardzTransactionCode = this.state.easyRewardzTransactionCode;
      if (this.state.easyRewardzTransactionDate === "") {
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let day = new Date().getDate();
        if (day.toString().length === 1) {
          day = "0" + day;
        }
        sale.EasyRewardzTransactionDate =
          day +
          " " +
          monthNames[new Date().getMonth()] +
          " " +
          new Date().getFullYear();
      } else {
        sale.EasyRewardzTransactionDate = this.state.easyRewardzTransactionDate;
      }
      sale.EasyRewardzOTP = this.state.easyRewardzOTP;

      //if kot then get kot no assign kot no to line items and then save KOt records
      let KOTNo = 0;
      let KOT = null;
      if (
        sale.KOTType &&
        sale.KOTType !== "" &&
        this.props.tableOrder &&
        (this.props.printKOT || this.props.printBill)
      ) {
        // KOT = true
        KOTNo = await this.props.getKOTRunningNo(sale.CashRegister.key);
        sale.LineItems.map((m) => (m.KOTNo = KOTNo));
        sale.TransactionStatus = this.props.printBill ? "PrintBill" : "Pending";
      }
      if (
        sale.KOTType &&
        sale.KOTType !== "" &&
        this.props.registration.registration.hasOwnProperty("KOT") &&
        this.props.registration.registration.KOT
      ) {
        // KOT = true
        KOTNo = await this.props.getKOTRunningNo(sale.CashRegister.key);
        sale.KOTNo = KOTNo;
        sale.KOTStatus = "Pending";
      }
      key = await this.props.addSales(
        sale,
        sale.CashRegister.key,
        sale.TransactionType.key
      );
      console.log("key", key);
      if (key === "") {
        alert(
          "Unable to save transaction. Please try again. Please report this issue to suppor team."
        );
        return;
      }
      sale.key = key;
      if (KOTNo > 0) {
        //save KOT
        KOT = {
          KOTNo: KOTNo,
          Status: "Pending",
          RegistrationID: this.props.user.user.RegistrationID,
          CashRegister: this.props.user.cashRegister.key,
          StoreID: this.props.user.store.key,
          SaleID: sale.key,
          LineItems: sale.LineItems,
          KOTType: sale.KOTType,
          TransactionNo: sale.TransactionNo,
          TransactionDate: sale.TransactionDate,
          Customer: sale.hasOwnProperty("Customer") ? sale.Customer : null,
        };
        if (this.props.tableOrder) {
          KOT.Table = sale.Table ? sale.Table.Table : "";
        }
        // console.log("KOT", JSON.stringify(KOT))
        this.props.saveKOT(KOT);
      }
      if (key === "") {
        alert("Unable to save transaction. Pls try again.");
        return;
      }
      //update sales running no
      if (!this.props.isElectron()) {
        toastr.options = {
          positionClass: "toast-bottom-full-width",
          hideDuration: 300,
          timeOut: 3000,
        };
        toastr.success(
          "Transaction #" + sale.TransactionNo + " created successfully"
        );
      }
      this.postSaveActions(sale, KOT);
    } else {
      //edit sale
      let KOT = null;
      let KOTNo = 0;
      if (
        this.props.tableOrder &&
        (this.props.printKOT || this.props.printBill)
      ) {
        // KOT = true
        if (
          sale.LineItems.filter((f) => !f.hasOwnProperty("KOTNo")).length > 0
        ) {
          //KOT not yet created for some line items
          KOTNo = await this.props.getKOTRunningNo(sale.CashRegister.key);
          sale.LineItems.map((m) => {
            if (!m.hasOwnProperty("KOTNo")) {
              m.KOTNo = KOTNo;
            }
          });
        }
        sale.TransactionStatus = this.props.printBill ? "PrintBill" : "Pending";
      }
      if (KOTNo > 0) {
        //save KOT
        KOT = {
          KOTNO: KOTNo,
          Status: "Pending",
          RegistrationID: this.props.user.user.RegistrationID,
          CashRegister: this.props.user.cashRegister.key,
          StoreID: this.props.user.store.key,
          SaleID: key,
          KOTType: sale.KOTType,
          TransactionNo: sale.TransactionNo,
          LineItems: sale.LineItems.filter((f) => f.KOTNo === KOTNo),
          Customer: sale.hasOwnProperty("Customer") ? sale.Customer : null,
        };
        this.props.saveKOT(KOT);
      }
      this.props.updateSales(sale, key);
      if (!this.props.isElectron()) {
        toastr.options = {
          positionClass: "toast-bottom-full-width",
          hideDuration: 300,
          timeOut: 3000,
        };
        toastr.success(
          "Invoice#" + sale.TransactionNo + " updated successfully"
        );
      }
      sale.key = this.state.key;
      this.postSaveActions(sale, KOT);
    }
  }

  getKOTGroupPrints(sale) {
    let KOTGroupPrints = [];
    if (
      this.props.registration.registration.hasOwnProperty("KOTGroup") &&
      this.props.registration.registration.KOTGroup
    ) {
      for (let i = 0; i < sale.LineItems.length; i++) {
        if (
          sale.LineItems[i].Product.hasOwnProperty("KOTGroup") &&
          sale.LineItems[i].Product.KOTGroup.trim() !== ""
        ) {
          if (
            KOTGroupPrints.filter(
              (f) => f.KOTGroup === sale.LineItems[i].Product.KOTGroup.trim()
            ).length === 0
          ) {
            KOTGroupPrints.push({
              KOTGroup: sale.LineItems[i].Product.KOTGroup.trim(),
            });
          }
        }
      }
    }
    return KOTGroupPrints;
  }

  async postSaveActions(sale, tableKOTPrintData) {
    if (!this.props.isMobileApp()) {
      window.sendMessageToCustomerDisplay({
        lineItems: [],
        billQty: 0,
        billSubTotal: 0,
        billAmount: 0,
        billTaxAmount: 0,
        billDiscount: 0,
        taxSummaryList: 0,
        roundOff: 0,
        store: this.props.user.store,
        message: "Thank you for visiting!!!!",
        productCustomField1DisplayName: "",
        productCustomField2DisplayName: "",
        productCustomField3DisplayName: "",
        productCustomField4DisplayName: "",
        productCustomField5DisplayName: "",
        productCustomField6DisplayName: "",
      });
    }

    let print = true;
    let printChecked = this.state.printChecked;
    let sendSMSChecked = this.state.sendSMSChecked;
    let sendWhatsAppChecked = this.state.sendWhatsAppChecked;
    let sendEmailChecked = this.state.sendEmailChecked;
    let currentPrintIndex = 0;
    let salePrint = { ...sale };
    await this.printFormatting(salePrint);

    if (this.state.selectedPringConfigID === "") {
      printChecked = false;
      sendSMSChecked = false;
      sendWhatsAppChecked = false;
      this.setState({ printChecked: false, sendEmailChecked: false });
    }

    if (this.props.isMobileApp() && printChecked) {
      // printChecked=false
      // this.props.user.cashRegister.hasOwnProperty('Printer') && this.props.user.cashRegister.Printer !=="" &&
      let printer = "";
      if (localStorage.getItem("printer")) {
        printer = localStorage.getItem("printer");
      }
      if (this.props.config.Device === "worldlinedevice") {
        printer = "worldline";
      }
      if (printer.toLowerCase() === "wisepos") {
        //print
        // this.wiseposPrinting(salePrint)
        printChecked = false;
        // await this.mSwipePrinting(salePrint)
        await mSwipePrinting(
          salePrint,
          this.props.config.PrintConfigs.filter(
            (e) => e.key === this.state.selectedPringConfigID
          )[0]
        );
        // this.androidPrinting(salePrint, "mswipe")
      } else if (printer.toLowerCase() === "bluetooth") {
        printChecked = false;
        await this.blueToothPrinting(salePrint);
      }
      // else if (printer.toLowerCase() === "pinelabs") {

      //     printChecked = false
      //     await this.pinelabsPrinting(salePrint);
      // }
      else if (printer.toLowerCase() === "worldline") {
        // alert('worline print called')
        printChecked = false;
        await this.worldlineDevicePrinting(salePrint);
      }
      if (this.props.config.Device === constants.PINELABS_DEVICE) {
        let printConfig = this.props.config.PrintConfigs.filter(
          (e) =>
            !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
        )[0];
        let printData = pinelabsDevicePrinting(
          sale,
          printConfig,
          this.props.config.PineLabsApplicationID,
          this.props.config.AndroidPackageName
        );
        // alert(JSON.stringify(printData));
        window.cordova.plugins.pinelabs.print(
          { payLoad: printData },
          (result) => {
            // alert('print result');
          },
          (error) => {
            alert(error);
          }
        );
      }
    }
    if (
      printChecked ||
      sendSMSChecked ||
      sendWhatsAppChecked ||
      sendEmailChecked
    ) {
      // console.log('this.props.tableOrder', this.props.tableOrder)
      // console.log(' this.props.printKOT',  this.props.printKOT)
      let printKOT = false;
      let printData = {};
      let printKOTConfig = {};
      let printKOTData = {};
      let KOTGroupPrints = [];
      if (this.props.tableOrder && this.props.printKOT) {
        // if( this.props.registration.registration.hasOwnProperty("KOTGroup") && this.props.registration.registration.KOTGroup){
        //     for(let i=0;i<sale.LineItems.length;i++)
        //     {
        //         if(sale.LineItems[i].Product.hasOwnProperty('KOTGroup') && sale.LineItems[i].Product.KOTGroup.trim() !==""){
        //             if(KOTGroupPrints.filter(f=> f.KOTGroup ===sale.LineItems[i].Product.KOTGroup.trim()).length ===0){
        //                 KOTGroupPrints.push({
        //                     KOTGroup:sale.LineItems[i].Product.KOTGroup.trim()
        //                 })
        //             }
        //         }
        //     }
        //     console.log('KOTGroupPrints',KOTGroupPrints)
        // }

        KOTGroupPrints = this.getKOTGroupPrints(tableKOTPrintData);
        if (KOTGroupPrints.length > 0) {
          let sale = this.getKOTGroupDataForPrinting(
            tableKOTPrintData,
            KOTGroupPrints,
            0
          );
          printKOTConfig.PrintSize = "3 inch";
          printKOTConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty(
            "KOTTemplate"
          )
            ? this.props.registration.registration.KOTTemplate
            : "";
          printData = { Sale: sale, PrintConfig: printKOTConfig };
          // console.log('table order KOT group print');
          currentPrintIndex = 1;
          printKOT = true;
          printKOTData = {
            Sale: tableKOTPrintData,
            PrintConfig: printKOTConfig,
          };
        } else {
          // console.log('table order normal print')
          printKOTConfig.PrintSize = "3 inch";
          printKOTConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty(
            "KOTTemplate"
          )
            ? this.props.registration.registration.KOTTemplate
            : "";
          printData = { Sale: tableKOTPrintData, PrintConfig: printKOTConfig };
        }
      } else {
        printData = {
          Sale: salePrint,
          PrintConfig: this.props.config.PrintConfigs.filter(
            (e) => e.key === this.state.selectedPringConfigID
          )[0],
        };

        if (
          !this.props.tableOrder &&
          this.props.registration.registration.hasOwnProperty("KOT") &&
          this.props.registration.registration.KOT
        ) {
          // if( this.props.registration.registration.hasOwnProperty("KOTGroup") && this.props.registration.registration.KOTGroup){
          //     for(let i=0;i<sale.LineItems.length;i++)
          //     {
          //         if(sale.LineItems[i].Product.hasOwnProperty('KOTGroup') && sale.LineItems[i].Product.KOTGroup.trim() !==""){
          //             if(KOTGroupPrints.filter(f=> f.KOTGroup ===sale.LineItems[i].Product.KOTGroup.trim()).length ===0){
          //                 KOTGroupPrints.push({
          //                     KOTGroup:sale.LineItems[i].Product.KOTGroup.trim()
          //                 })
          //             }
          //         }
          //     }
          //     console.log('KOTGroupPrints',KOTGroupPrints)
          // }
          KOTGroupPrints = this.getKOTGroupPrints(sale);
          printKOT = true;
          printKOTConfig.PrintSize = "3 inch";
          printKOTConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty(
            "KOTTemplate"
          )
            ? this.props.registration.registration.KOTTemplate
            : "";
          printKOTData = { Sale: salePrint, PrintConfig: printKOTConfig };
        }
      }

      print = true;

      this.setState({
        printChecked: printChecked,
        sendSMSChecked: sendSMSChecked,
        sendWhatsAppChecked: sendWhatsAppChecked,
        sendEmailChecked: sendEmailChecked,
        print: print,
        sale: sale,
        printData: printData,
        printKOT: printKOT,
        printKOTData: printKOTData,
        saving: false,
        KOTGroupPrints: KOTGroupPrints,
        currentPrintIndex: currentPrintIndex,
      }); //TBDpawan

      // this.props.hidePayment()
    } else {
      this.props.onSave();
      this.setState({ saving: false });
    }
  }

  async worldlineDevicePrinting(sale) {
    let printData = [];
    let printConfig = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
    )[0];

    var printLine = [
      {
        PrintType: "text",
        PrintText: "SALE INVOICE",
        Alignment: "center",
        Bold: true,
      },
      { PrintType: "text", PrintText: "", Alignment: "center", Bold: true },
      {
        PrintType: "text",
        PrintText: "ABC STORE",
        Alignment: "center",
        Bold: true,
      },
      {
        PrintType: "text",
        PrintText: "Shop No 1",
        Alignment: "center",
        Bold: true,
      },
      {
        PrintType: "text",
        PrintText: "Near Market Place",
        Alignment: "center",
        Bold: true,
      },
      {
        PrintType: "text",
        PrintText: "Jaipur",
        Alignment: "center",
        Bold: true,
      },
      {
        PrintType: "text",
        PrintText: "PIN - 401010",
        Alignment: "center",
        Bold: true,
      },
      {
        PrintType: "text",
        PrintText: "Invoice No: INV01010101",
        Alignment: "left",
        Bold: true,
      },
      {
        PrintType: "text",
        PrintText: "Invoice Date: 15-Jan-2022",
        Alignment: "left",
        Bold: true,
      },
      { PrintType: "text", PrintText: "", Alignment: "center", Bold: true },
      { PrintType: "header", PrintText: "", Alignment: "left", Bold: true },
      {
        PrintType: "lineitem",
        Item: "ROYAL SAUNF (HERITAGE LIQUOR). RHL-750 ML",
        Qty: "10",
        Rate: "1015",
        Amount: "10150",
        Alignment: "left",
        Bold: true,
      },
      {
        PrintType: "lineitem",
        Item: "ROYAL CHANDR HASS LIQUOR (RHL) RHL-750 ML(6 Bott.)",
        Qty: "1",
        Rate: "1575",
        Amount: "1575",
        Alignment: "left",
        Bold: true,
      },
      {
        PrintType: "lineitem",
        Item: "Yuvraj Premium Whisky RML A B Pack-180 ml",
        Qty: "10",
        Rate: "76",
        Amount: "760",
        Alignment: "left",
        Bold: true,
      },
      { PrintType: "seperator", Bold: true },
      {
        PrintType: "leftright",
        LeftText: "Total:",
        RightText: "99999",
        Bold: true,
      },
      {
        PrintType: "leftright",
        LeftText: "Paid:",
        RightText: "99999",
        Bold: true,
      },
      {
        PrintType: "leftright",
        LeftText: "Balance:",
        RightText: "0",
        Bold: true,
      },
      { PrintType: "text", PrintText: "", Alignment: "center", Bold: true },
      {
        PrintType: "text",
        PrintText: "Thank you!!",
        Alignment: "center",
        Bold: true,
      },

      // 999 x 9999 = 99999
    ];

    printData.push({
      PrintType: "text",
      PrintText: "SALE INVOICE",
      Alignment: "center",
      Bold: true,
    });
    if (printConfig.StoreCodeDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.StoreCode,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreNameDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.StoreName,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StorePhoneNoDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.PhoneNo,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.EmailID) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.EmailID,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreAddressLine1Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.Address1,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreAddressLine2Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.Address2,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreAddressCityDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.City,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreAddressStateDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.State,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreAddressPostalCodeDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.PostalCode,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreAddressCountryDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.Country,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreCustomField1Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.CustomField1,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreCustomField2Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.CustomField2,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreCustomField3Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.CustomField3,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreCustomField4Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.CustomField4,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreCustomField5Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.CustomField5,
        Alignment: "center",
        Bold: true,
      });
    }
    if (printConfig.StoreCustomField6Display) {
      printData.push({
        PrintType: "text",
        PrintText: sale.Store.CustomField6,
        Alignment: "center",
        Bold: true,
      });
    }
    printData.push({
      PrintType: "text",
      PrintText: "",
      Alignment: "center",
      Bold: true,
    });

    if (printConfig.TransactionNoDisplay) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo,
        Alignment: "left",
        Bold: true,
      });
    }
    if (printConfig.TransactionDateDisplay) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate,
        Alignment: "left",
        Bold: true,
      });
    }
    printData.push({
      PrintType: "text",
      PrintText: "",
      Alignment: "center",
      Bold: true,
    });
    //customer details
    if (
      sale.Customer &&
      printConfig.CustomerCodeDisplay &&
      sale.Customer.CustomerCode !== ""
    ) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCodeDisplayName +
          ": " +
          sale.Customer.CustomerCode,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerNameDisplay) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerNameDisplayName +
          ": " +
          sale.Customer.FirstName +
          " " +
          sale.Customer.LastName,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField1Display) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCustomField1DisplayName +
          ": " +
          sale.Customer.CustomField1,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField2Display) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCustomField2DisplayName +
          ": " +
          sale.Customer.CustomField2,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField3Display) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCustomField3DisplayName +
          ": " +
          sale.Customer.CustomField3,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField4Display) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCustomField4DisplayName +
          ": " +
          sale.Customer.CustomField4,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField5Display) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCustomField5DisplayName +
          ": " +
          sale.Customer.CustomField5,
        Alignment: "left",
        Bold: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField6Display) {
      printData.push({
        PrintType: "text",
        PrintText:
          printConfig.CustomerCustomField6DisplayName +
          ": " +
          sale.Customer.CustomField6,
        Alignment: "left",
        Bold: true,
      });
    }

    if (sale.Customer) {
      printData.push({
        PrintType: "text",
        PrintText: "",
        Alignment: "center",
        Bold: true,
      });
    }

    printData.push({
      PrintType: "header",
      PrintText: "",
      Alignment: "left",
      Bold: true,
    });

    for (let i = 0; i < sale.LineItems.length; i++) {
      let l = sale.LineItems[i];
      printData.push({
        PrintType: "lineitem",
        Item: l.Product.Name,
        Qty: l.Qty.toString(),
        Rate: l.RetailPrice.toString(),
        Amount: l.Amount.toString(),
        Alignment: "left",
        Bold: true,
      });

      //check for modifiers
      if (l.hasOwnProperty("Modifiers")) {
        let modifierText = "";
        for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
          modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName;
        }
        printData.push({
          PrintType: "text",
          PrintText: modifierText,
          Alignment: "left",
          Bold: true,
        });
      }
      //check for uniqueno
      if (l.hasOwnProperty("UniqueNoList")) {
        for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
          if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
            printData.push({
              PrintType: "text",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1,
              Alignment: "left",
              Bold: true,
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
            printData.push({
              PrintType: "text",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2,
              Alignment: "left",
              Bold: true,
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
            printData.push({
              PrintType: "text",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3,
              Alignment: "left",
              Bold: true,
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
            printData.push({
              PrintType: "text",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4,
              Alignment: "left",
              Bold: true,
            });
          }
        }
      }
    }

    printData.push({ PrintType: "seperator", Bold: true });

    if (printConfig.TransactionTotalQtyDisplay) {
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionTotalQtyDisplayName + ":",
        RightText: sale.BillQty.toString(),
        Bold: true,
      });
    }
    if (printConfig.TransactionDiscountDisplay) {
      //printData.push({PrintType:"leftright",LeftText:printConfig.TransactionDiscountDisplayName + ":", RightText:sale.BillQty.toString(),Bold:true})
    }
    if (printConfig.TransactionTotalDisplay) {
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionTotalDisplayName + ":",
        RightText: sale.BillAmount.toString(),
        Bold: true,
      });
    }
    if (printConfig.TransactionPaidDisplay) {
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionPaidDisplayName + ":",
        RightText: sale.BillPaidAmount.toString(),
        Bold: true,
      });
    }
    if (printConfig.TransactionBalanceDisplay) {
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionBalanceDisplayName + ":",
        RightText: sale.BalanceAmount.toString(),
        Bold: true,
      });
    }
    if (printConfig.TransactionRoundOffDisplay) {
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionRoundOffDisplayName + ":",
        RightText: sale.RoundOff.toString(),
        Bold: true,
      });
    }
    let paymentMode = "";
    if (sale.Payments.length > 0) {
      for (let l = 0; l < sale.Payments.length; l++) {
        let p = sale.Payments[l];
        paymentMode = paymentMode + " " + p.PaymentMode.toString();
      }
    }
    if (printConfig.TransactionPaymentModeDisplay) {
      printData.push({
        PrintType: "text",
        PrintText: "",
        Alignment: "center",
        Bold: true,
      });
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionPaymentModeDisplayName + ":",
        RightText: paymentMode,
        Bold: true,
      });
    }
    if (printConfig.TransactionNotesDisplay && sale.Notes) {
      printData.push({
        PrintType: "text",
        PrintText: "",
        Alignment: "center",
        Bold: true,
      });
      printData.push({
        PrintType: "leftright",
        LeftText: printConfig.TransactionNotesDisplayName + ":",
        RightText: sale.Notes,
        Bold: true,
      });
    }
    if (printConfig.FooterText) {
      printData.push({
        PrintType: "text",
        PrintText: printConfig.FooterText,
        Alignment: "center",
        Bold: true,
      });
    }
    // if (sale.PrintTaxComponents.length > 0) {
    //     for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
    //         let p = sale.PrintTaxComponents[l]
    //         printData.push({
    //             "PrintDataType": 0,
    //             "PrinterWidth": 24,
    //             "IsCenterAligned": true,
    //             "DataToPrint": p.TaxType + ":" + " ".repeat((30 - p.TaxType.toString().length - p.TaxAmount.toString().length) > 0 ? (30 - p.TaxType.toString().length - p.TaxAmount.toString().length) : 0) + p.TaxAmount.toString(),
    //             "ImagePath": "",
    //             "ImageData": ""
    //         })
    //     }
    // }
    console.log(printData);
    // return;
    alert("worline print data");
    window.cordova.plugins.worldline.print(
      { PrintData: printData },
      (result) => {
        // alert(result)
        alert("Print completed");
      },
      (error) => {
        alert("error" + error);
      }
    );
  }

  async pinelabsPrinting(sale) {
    // alert('pinelabsPrinting')
    let printData = [];
    let printConfig = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
    )[0];
    if (printConfig.StoreCodeDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.StoreCode,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreNameDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.StoreName,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StorePhoneNoDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.PhoneNo,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.EmailID) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.EmailID,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreAddressLine1Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.Address1,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreAddressLine2Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.Address2,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreAddressCityDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.City,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreAddressStateDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.State,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreAddressPostalCodeDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.PostalCode,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreAddressCountryDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.Country,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreCustomField1Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.CustomField1,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreCustomField2Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.CustomField2,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreCustomField3Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.CustomField3,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreCustomField4Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.CustomField4,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreCustomField5Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.CustomField5,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.StoreCustomField6Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: sale.Store.CustomField6,
        ImagePath: "",
        ImageData: "",
      });
    }
    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "",
      ImagePath: "",
      ImageData: "",
    });

    if (printConfig.TransactionNoDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (printConfig.TransactionDateDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate,
        ImagePath: "",
        ImageData: "",
      });
    }

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "",
      ImagePath: "",
      ImageData: "",
    });

    //customer details
    if (sale.Customer && printConfig.CustomerCodeDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCodeDisplayName +
          ": " +
          sale.Customer.CustomerCode,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerNameDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerNameDisplayName +
          ": " +
          sale.Customer.FirstName +
          " " +
          sale.Customer.LastName,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField1Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCustomField1DisplayName +
          ": " +
          sale.Customer.CustomField1,
        ImagePath: "",
        ImageData: "",
      });
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField1DisplayName +
          ": " +
          sale.Customer.CustomField1,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField2Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCustomField2DisplayName +
          ": " +
          sale.Customer.CustomField2,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField3Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCustomField3DisplayName +
          ": " +
          sale.Customer.CustomField3,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField4Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCustomField4DisplayName +
          ": " +
          sale.Customer.CustomField4,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField5Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCustomField5DisplayName +
          ": " +
          sale.Customer.CustomField5,
        ImagePath: "",
        ImageData: "",
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField6Display) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint:
          printConfig.CustomerCustomField6DisplayName +
          ": " +
          sale.Customer.CustomField6,
        ImagePath: "",
        ImageData: "",
      });
    }

    if (sale.Customer) {
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: "",
        ImagePath: "",
        ImageData: "",
      });
    }

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "--------------------------------",
      ImagePath: "",
      ImageData: "",
    });

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "Item           Qty  Rate  Amount",
      ImagePath: "",
      ImageData: "",
    });

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "-------------------------------",
      ImagePath: "",
      ImageData: "",
    });

    for (let i = 0; i < sale.LineItems.length; i++) {
      let l = sale.LineItems[i];
      let printText =
        l.Product.Name.substring(0, 14) +
        " ".repeat(
          14 - l.Product.Name.toString().length > 0
            ? 14 - l.Product.Name.toString().length
            : 0
        );
      printText =
        printText +
        " " +
        l.Qty.toString() +
        " ".repeat(
          4 - l.Qty.toString().length > 0 ? 4 - l.Qty.toString().length : 0
        );
      printText =
        printText +
        " " +
        " ".repeat(
          4 - l.RetailPrice.toString().length > 0
            ? 4 - l.RetailPrice.toString().length
            : 0
        ) +
        l.RetailPrice.toString();
      printText =
        printText +
        " " +
        " ".repeat(
          6 - l.Amount.toString().length > 0
            ? 6 - l.Amount.toString().length
            : 0
        ) +
        l.Amount.toString();
      printData.push({
        PrintDataType: 0,
        PrinterWidth: 24,
        IsCenterAligned: true,
        DataToPrint: printText,
        ImagePath: "",
        ImageData: "",
      });

      //check for modifiers
      if (l.hasOwnProperty("Modifiers")) {
        let modifierText = "";
        for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
          modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName;
        }
        printData.push({
          PrintDataType: 0,
          PrinterWidth: 24,
          IsCenterAligned: true,
          DataToPrint: modifierText,
          ImagePath: "",
          ImageData: "",
        });
      }
      //check for uniqueno
      if (l.hasOwnProperty("UniqueNoList")) {
        for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
          if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
            printData.push({
              PrintDataType: 0,
              PrinterWidth: 24,
              IsCenterAligned: true,
              DataToPrint:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1,
              ImagePath: "",
              ImageData: "",
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
            printData.push({
              PrintDataType: 0,
              PrinterWidth: 24,
              IsCenterAligned: true,
              DataToPrint:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2,
              ImagePath: "",
              ImageData: "",
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
            printData.push({
              PrintDataType: 0,
              PrinterWidth: 24,
              IsCenterAligned: true,
              DataToPrint:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3,
              ImagePath: "",
              ImageData: "",
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
            printData.push({
              PrintDataType: 0,
              PrinterWidth: 24,
              IsCenterAligned: true,
              DataToPrint:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4,
              ImagePath: "",
              ImageData: "",
            });
          }
        }
      }
    }

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint:
        "-".repeat(32) +
        " ".repeat(15) +
        " ".repeat(
          4 - sale.BillQty.toString().length > 0
            ? 4 - sale.BillQty.toString().length
            : 0
        ) +
        sale.BillQty +
        "       " +
        " ".repeat(
          6 - sale.BillAmount.toString().length > 0
            ? 6 - sale.BillAmount.toString().length
            : 0
        ) +
        sale.BillAmount.toString() +
        "-".repeat(32),
      ImagePath: "",
      ImageData: "",
    });

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint:
        "Discount:" +
        " ".repeat(
          23 - sale.BillDiscount.toString().length > 0
            ? 23 - sale.BillDiscount.toString().length
            : 0
        ),
      ImagePath: "",
      ImageData: "",
    });

    if (sale.PrintTaxComponents.length > 0) {
      for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
        let p = sale.PrintTaxComponents[l];
        printData.push({
          PrintDataType: 0,
          PrinterWidth: 24,
          IsCenterAligned: true,
          DataToPrint:
            p.TaxType +
            ":" +
            " ".repeat(
              30 - p.TaxType.toString().length - p.TaxAmount.toString().length >
                0
                ? 30 -
                    p.TaxType.toString().length -
                    p.TaxAmount.toString().length
                : 0
            ) +
            p.TaxAmount.toString(),
          ImagePath: "",
          ImageData: "",
        });
      }
    }

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint:
        "-".repeat(32) +
        "Total:" +
        " ".repeat(
          26 - sale.BillAmount.toString().length > 0
            ? 26 - sale.BillAmount.toString().length
            : 0
        ) +
        sale.BillAmount.toString() +
        "-".repeat(31),
      ImagePath: "",
      ImageData: "",
    });
    if (sale.Payments.length > 0) {
      for (let l = 0; l < sale.Payments.length; l++) {
        let p = sale.Payments[l];
        printData.push({
          PrintDataType: 0,
          PrinterWidth: 24,
          IsCenterAligned: true,
          DataToPrint:
            p.PaymentMode +
            ":" +
            " ".repeat(
              31 -
                p.PaymentMode.toString().length -
                p.Amount.toString().length >
                0
                ? 31 -
                    p.PaymentMode.toString().length -
                    p.Amount.toString().length
                : 0
            ) +
            p.Amount.toString(),
          ImagePath: "",
          ImageData: "",
        });
      }
    }

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint:
        "Balance:" +
        " ".repeat(
          24 - sale.BalanceAmount.toString().length > 0
            ? 24 - sale.BalanceAmount.toString().length
            : 0
        ) +
        sale.BalanceAmount.toString(),
      ImagePath: "",
      ImageData: "",
    });

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "",
      ImagePath: "",
      ImageData: "",
    });

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "",
      ImagePath: "",
      ImageData: "",
    });

    printData.push({
      PrintDataType: 0,
      PrinterWidth: 24,
      IsCenterAligned: true,
      DataToPrint: "",
      ImagePath: "",
      ImageData: "",
    });

    // console.log(printData)
    // alert(printData)
    window.cordova.plugins.plutus.call(
      {
        Header: {
          ApplicationId: "79099725d3304811bc39a917ae358078",
          UserId: "1234",
          MethodId: "1002",
          VersionNo: "1.0",
        },
        Detail: {
          PrintRefNo: sale.TransactionNo,
          SavePrintData: false,
          Data: printData,
        },
      },
      (result) => {
        alert(result);
      },
      (error) => {
        alert("error" + error);
      }
    );
  }

  async blueToothPrinting(sale) {
    alert("blueToothPrinting -- start");
    let printerName = this.props.user.cashRegister.PrinterConfig.Name;
    let printerAddress = this.props.user.cashRegister.PrinterConfig.Address;
    alert("printerName:" + printerName);
    alert("printerAddress:" + printerAddress);
    let printConfig = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
    )[0];
    // console.log('printConfig', printConfig)
    return;
    if (printConfig.StoreCodeDisplay) {
      // alert('sale.Store.StoreCode' + sale.Store.StoreCode)
      await this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.StoreCode,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreNameDisplay) {
      // alert('sale.Store.StoreName' + sale.Store.StoreName)
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.StoreName,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StorePhoneNoDisplay) {
      // alert('sale.Store.PhoneNo'+ sale.Store.PhoneNo)
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.PhoneNo,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.EmailID) {
      alert("sale.Store.EmailID" + sale.Store.EmailID);
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.EmailID,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreAddressLine1Display) {
      alert("sale.Store.Address1" + sale.Store.Address1);
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.Address1,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreAddressLine2Display) {
      alert("sale.Store.Address2" + sale.Store.Address2);
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.Address2,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreAddressCityDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.City,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreAddressStateDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.State,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreAddressPostalCodeDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.PostalCode,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreAddressCountryDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.Country,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreCustomField1Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.CustomField1,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreCustomField2Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.CustomField2,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreCustomField3Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.CustomField3,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreCustomField4Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.CustomField4,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreCustomField5Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.CustomField5,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.StoreCustomField6Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        sale.Store.CustomField6,
        "center",
        "normal",
        30
      );
    }
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "",
      "center",
      "normal",
      30
    );

    //transaction no and date
    if (printConfig.TransactionNoDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo,
        "center",
        "normal",
        30
      );
    }
    if (printConfig.TransactionDateDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate,
        "center",
        "normal",
        30
      );
    }
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "",
      "center",
      "normal",
      30
    );

    //customer details
    if (sale.Customer && printConfig.CustomerCodeDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerCodeDisplayName + ": " + sale.Customer.CustomerCode,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerNameDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerNameDisplayName +
          ": " +
          sale.Customer.FirstName +
          " " +
          sale.Customer.LastName,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerCustomField1Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerCustomField1DisplayName +
          ": " +
          sale.Customer.CustomField1,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerCustomField2Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerCustomField2DisplayName +
          ": " +
          sale.Customer.CustomField2,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerCustomField3Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerCustomField3DisplayName +
          ": " +
          sale.Customer.CustomField3,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerCustomField4Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printConfig.CustomerCustomField4DisplayName +
          ": " +
          sale.Customer.CustomField4,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerCustomField5Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerCustomField5DisplayName +
          ": " +
          sale.Customer.CustomField5,
        "center",
        "normal",
        30
      );
    }
    if (sale.Customer && printConfig.CustomerCustomField6Display) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printConfig.CustomerCustomField6DisplayName +
          ": " +
          sale.Customer.CustomField6,
        "center",
        "normal",
        30
      );
    }

    if (sale.Customer) {
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        "",
        "center",
        "normal",
        30
      );
    }

    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "--------------------------------",
      "center",
      "normal",
      30
    );
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "Item           Qty  Rate  Amount",
      "center",
      "normal",
      30
    );
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "--------------------------------",
      "center",
      "normal",
      30
    );

    for (let i = 0; i < sale.LineItems.length; i++) {
      let l = sale.LineItems[i];
      let printText =
        l.Product.Name.substring(0, 14) +
        " ".repeat(
          14 - l.Product.Name.toString().length > 0
            ? 14 - l.Product.Name.toString().length
            : 0
        );
      printText =
        printText +
        " " +
        l.Qty.toString() +
        " ".repeat(
          4 - l.Qty.toString().length > 0 ? 4 - l.Qty.toString().length : 0
        );
      printText =
        printText +
        " " +
        " ".repeat(
          4 - l.RetailPrice.toString().length > 0
            ? 4 - l.RetailPrice.toString().length
            : 0
        ) +
        l.RetailPrice.toString();
      printText =
        printText +
        " " +
        " ".repeat(
          6 - l.Amount.toString().length > 0
            ? 6 - l.Amount.toString().length
            : 0
        ) +
        l.Amount.toString();
      this.props.printTextBlueToothPrinter(
        printerName,
        printerAddress,
        printText,
        "center",
        "normal",
        30
      );

      //check for modifiers
      if (l.hasOwnProperty("Modifiers")) {
        let modifierText = "";
        for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
          modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName;
        }
        this.props.printTextBlueToothPrinter(
          printerName,
          printerAddress,
          modifierText,
          "center",
          "normal",
          30
        );
      }
      //check for uniqueno
      if (l.hasOwnProperty("UniqueNoList")) {
        for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
          if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
            this.props.printTextBlueToothPrinter(
              printerName,
              printerAddress,
              l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1,
              "center",
              "normal",
              30
            );
          }
          if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
            this.props.printTextBlueToothPrinter(
              printerName,
              printerAddress,
              l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2,
              "center",
              "normal",
              30
            );
          }
          if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
            this.props.printTextBlueToothPrinter(
              printerName,
              printerAddress,
              l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3,
              "center",
              "normal",
              30
            );
          }
          if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
            this.props.printTextBlueToothPrinter(
              printerName,
              printerAddress,
              l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4,
              "center",
              "normal",
              30
            );
          }
        }
      }
    }
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "-".repeat(32) +
        " ".repeat(15) +
        " ".repeat(
          4 - sale.BillQty.toString().length > 0
            ? 4 - sale.BillQty.toString().length
            : 0
        ) +
        sale.BillQty +
        "       " +
        " ".repeat(
          6 - sale.BillAmount.toString().length > 0
            ? 6 - sale.BillAmount.toString().length
            : 0
        ) +
        sale.BillAmount.toString() +
        "-".repeat(32),
      "center",
      "normal",
      30
    );

    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "Discount:" +
        " ".repeat(
          23 - sale.BillDiscount.toString().length > 0
            ? 23 - sale.BillDiscount.toString().length
            : 0
        ),
      "center",
      "normal",
      30
    );

    if (sale.PrintTaxComponents.length > 0) {
      for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
        let p = sale.PrintTaxComponents[l];
        this.props.printTextBlueToothPrinter(
          printerName,
          printerAddress,
          p.TaxType +
            ":" +
            " ".repeat(
              30 - p.TaxType.toString().length - p.TaxAmount.toString().length >
                0
                ? 30 -
                    p.TaxType.toString().length -
                    p.TaxAmount.toString().length
                : 0
            ) +
            p.TaxAmount.toString(),
          "center",
          "normal",
          0
        );
      }
    }
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "-".repeat(32) +
        "Total:" +
        " ".repeat(
          26 - sale.BillAmount.toString().length > 0
            ? 26 - sale.BillAmount.toString().length
            : 0
        ) +
        sale.BillAmount.toString() +
        "-".repeat(31),
      "center",
      "normal",
      0
    );

    if (sale.Payments.length > 0) {
      for (let l = 0; l < sale.Payments.length; l++) {
        let p = sale.Payments[l];
        this.props.printTextBlueToothPrinter(
          printerName,
          printerAddress,
          p.PaymentMode +
            ":" +
            " ".repeat(
              31 -
                p.PaymentMode.toString().length -
                p.Amount.toString().length >
                0
                ? 31 -
                    p.PaymentMode.toString().length -
                    p.Amount.toString().length
                : 0
            ) +
            p.Amount.toString(),
          "center",
          "normal",
          0
        );
      }
    }
    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "Balance:" +
        " ".repeat(
          24 - sale.BalanceAmount.toString().length > 0
            ? 24 - sale.BalanceAmount.toString().length
            : 0
        ) +
        sale.BalanceAmount.toString(),
      "center",
      "normal",
      0
    );

    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "",
      "center",
      "normal",
      0
    );

    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "",
      "center",
      "normal",
      0
    );

    this.props.printTextBlueToothPrinter(
      printerName,
      printerAddress,
      "",
      "center",
      "normal",
      0
    );
  }

  async printFormatting(sale) {
    // sale1.TransactionDate =  (new Date(sale.TransactionDate.seconds*1000)).toString().substring(0,24)
    //in case of sales order to invoice

    //check if any line item has showAsAdditionalCharges

    if (sale.hasOwnProperty("ReceivedPaymentTransactions")) {
      for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
        sale.BillAmount =
          Number(sale.BillAmount) +
          Number(sale.ReceivedPaymentTransactions[i].ReceiveAmount);
      }
    }

    sale.TransactionDateShort = new Date(sale.TransactionDate)
      .toISOString()
      .split("T")[0];
    sale.TransactionDate = sale.TransactionDate.toString().substring(0, 24);
    sale.AmountInWords = this.props.convertNumberToWords(sale.BillAmount);
    // console.log('sale1.AmountInWords', sale1.AmountInWords)
    let billTaxableAmount = 0;
    let lineItemsTmp = [];
    for (let i = 0; i < sale.LineItems.length; i++) {
      lineItemsTmp.push(sale.LineItems[sale.LineItems.length - (i + 1)]);
    }
    let lineItemsWithOutAdditionalCharges = [];
    let lineItemsWithAdditonalCharges = [];
    let subTotalWithoutAdditionalCharges = 0;

    sale.LineItems = lineItemsTmp;
    let costPriceTotal = 0;
    let mrpTotal = 0;
    sale.LineItems.map((lineItem, index) => {
      sale.LineItems[index].SrNo = index + 1;
      sale.LineItems[index].BasePrice = this.roundUpTo2(
        Number(sale.LineItems[index].TaxableAmount) /
          Number(sale.LineItems[index].Qty)
      );
      if (
        lineItem.TaxComponentAmount &&
        lineItem.TaxComponentAmount.length > 0 &&
        lineItem.TaxInclusive
      ) {
        let taxPercentage = 0;
        let taxAmount = 0;
        lineItem.TaxComponentAmount.map((m) => {
          taxPercentage = taxPercentage + Number(m.TaxPercentage);
        });
        taxAmount = this.roundUpTo2(
          (Number(lineItem.RetailPrice) * Number(taxPercentage)) /
            (100 + Number(taxPercentage))
        );
        sale.LineItems[index].CostPrice =
          Number(lineItem.RetailPrice) - Number(taxAmount);
      } else {
        sale.LineItems[index].CostPrice = lineItem.RetailPrice;
      }
      sale.LineItems[index].CostPriceTotal = this.roundUpTo2(
        Number(sale.LineItems[index].CostPrice) *
          Number(sale.LineItems[index].Qty)
      );
      costPriceTotal =
        costPriceTotal + Number(sale.LineItems[index].CostPriceTotal);

      sale.LineItems[index].MRP = Number(
        lineItem.Product.MRP === 0 ? lineItem.RetailPrice : lineItem.Product.MRP
      );

      sale.LineItems[index].MRPTotal = this.roundUpTo2(
        Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty)
      );
      mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal);

      billTaxableAmount = this.roundUpTo2(
        Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount)
      );

      if (
        lineItem.Product.hasOwnProperty("ShowAsAdditionalCharges") &&
        lineItem.Product.ShowAsAdditionalCharges
      ) {
        lineItemsWithAdditonalCharges.push(sale.LineItems[index]);
      } else {
        lineItemsWithOutAdditionalCharges.push(sale.LineItems[index]);
        subTotalWithoutAdditionalCharges =
          Number(subTotalWithoutAdditionalCharges) +
          Number(sale.LineItems[index].Amount);
      }
    });

    sale.BillCostPriceTotal = costPriceTotal;
    sale.BillMRPTotal = mrpTotal;
    sale.MRPSaving = this.roundUpTo2(
      this.roundUpTo2(
        Number(sale.BillMRPTotal) - this.roundUpTo2(Number(sale.BillAmount))
      )
    );

    sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount));
    let printTaxComponents = [];
    let printTaxComponentsWithRate = [];
    sale.LineItems.map((lineItem, index) => {
      //check modifers
      if (lineItem.Modifiers && lineItem.Modifiers.length > 0) {
        let modifiersPrint = [];
        for (let k = 0; k < lineItem.Modifiers.length; k++) {
          let index = -1;
          for (let l = 0; l < modifiersPrint.length; l++) {
            if (
              modifiersPrint[l].ModifierName ===
              lineItem.Modifiers[k].ModifierName
            ) {
              index = l;
              break;
            }
          }
          if (index === -1) {
            modifiersPrint.push({
              key: lineItem.Modifiers[k].key,
              ModifierName: lineItem.Modifiers[k].ModifierName,
              ModifierPrice: lineItem.Modifiers[k].ModifierPrice,
              ModifierGroup: lineItem.Modifiers[k].ModifierGroup,
              ModifierGroupID: lineItem.Modifiers[k].ModifierGroupID,
              ModifierQty: 1,
            });
          } else {
            modifiersPrint[index].ModifierQty =
              modifiersPrint[index].ModifierQty + 1;
            modifiersPrint[index].ModifierPrice =
              modifiersPrint[index].ModifierPrice +
              lineItem.Modifiers[k].ModifierPrice;
          }
        }
        // console.log('modifiersPrint', modifiersPrint)
        lineItem.ModifiersPrint = modifiersPrint;
      }
      if (lineItem.TaxID !== "") {
        lineItem.TaxComponentAmount.map((t) => {
          let indexFound = -1;
          printTaxComponents.map((pt, ptIndex) => {
            if (t.TaxType === pt.TaxType) {
              indexFound = ptIndex;
              return;
            }
          });
          if (indexFound < 0) {
            printTaxComponents.push({
              TaxType: t.TaxType,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
            });
          } else {
            printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(
              Number(printTaxComponents[indexFound].TaxAmount) +
                Number(t.TaxAmount)
            );
          }

          let indexFoundTaxRate = -1;
          printTaxComponentsWithRate.map((pt, ptIndex) => {
            if (
              t.TaxType === pt.TaxType &&
              t.TaxPercentage === pt.TaxPercentage
            ) {
              indexFoundTaxRate = ptIndex;
              return;
            }
          });
          if (indexFoundTaxRate < 0) {
            printTaxComponentsWithRate.push({
              TaxType: t.TaxType,
              TaxPercentage: t.TaxPercentage,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
              TaxableAmount: Number(lineItem.TaxableAmount),
            });
          } else {
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxAmount = this.roundUpTo2(
              Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount) +
                Number(t.TaxAmount)
            );
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxableAmount = this.roundUpTo2(
              Number(
                printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount
              ) + Number(lineItem.TaxableAmount)
            );
          }
        });
      }
    });
    sale.PrintTaxComponents = printTaxComponents;
    sale.PrintTaxComponentsWithRate = printTaxComponentsWithRate;
    sale.LineItems.map((lineItem, index) => {
      for (var i = 0; i < printTaxComponents.length; i++) {
        if (lineItem.TaxComponentAmount.length < i + 1) {
          lineItem.TaxComponentAmount.push({
            TaxName: "",
            TaxAmount: 0,
            TaxPercentage: "",
            TaxType: "",
          });
        }
      }
    });
    let previousBalance = 0;
    if (sale.Customer && sale.Customer.hasOwnProperty("OutStandingAmount")) {
      previousBalance = sale.Customer.OutStandingAmount;
    }
    sale.PreviousBalance = previousBalance;
    sale.TotalBalanceAmount = sale.BalanceAmount + previousBalance;
    sale.TotalBalanceAfterPaid = sale.TotalBalanceAmount - sale.BillPaidAmount;

    sale.LineItemsWithOutAdditionalCharges = lineItemsWithOutAdditionalCharges;
    sale.LineItemsWithAdditonalCharges = lineItemsWithAdditonalCharges;
    sale.SubTotalWithoutAdditionalCharges = subTotalWithoutAdditionalCharges;

    console.log("sale print", sale);
    //wallet balance
    // sale.Payments.filter(f=> f.PaymentMode.toLowerCase()==="wallet").map(m=> sale.WalletBalance = Number(sale.Customer.WalletBalance )- Number(m.Amount))
    // console.log('print sale', sale)
  }

  onCurrencyClick(currencyAmt) {
    let paymentModeAmount = this.state.firstCurrencyClick
      ? Number(currencyAmt)
      : Number(this.state.paymentModeAmount) + Number(currencyAmt);
    this.setState({
      paymentModeAmount: paymentModeAmount,
      firstCurrencyClick: this.state.firstCurrencyClick
        ? !this.state.firstCurrencyClick
        : this.state.firstCurrencyClick,
    });
  }

  getKOTGroupDataForPrinting(sale1, KOTGroupPrints, currentPrintIndex) {
    let sale = { ...sale1 };
    // console.log('currentPrintIndex', currentPrintIndex);
    // console.log('KOTGroupPrints', KOTGroupPrints);
    // console.log('sale',sale)
    let lineItems = [];
    // console.log('sale.LineItems',sale.LineItems)
    sale.LineItems.filter(
      (f) => f.Product.KOTGroup === KOTGroupPrints[currentPrintIndex].KOTGroup
    ).map((m) => {
      lineItems.push(m);
    });
    // console.log('this.state.currentPrintIndex',this.state.currentPrintIndex)
    // console.log('this.state.KOTGroupPrints[this.state.currentPrintIndex].KOTGroup',this.state.KOTGroupPrints[this.state.currentPrintIndex].KOTGroup)
    // console.log('KOT group lineItems', lineItems)
    sale.LineItems = lineItems;
    return sale;
  }

  rollBackEasyRewardz() {
    if (
      this.state.easyRewardzTransactionCode === "" &&
      this.state.easyRewardzTransactionDate === ""
    ) {
      return;
    }
    console.log(
      "this.state.easyRewardzTransactionCode",
      this.state.easyRewardzTransactionCode
    );
    console.log(
      "this.state.easyRewardzTransactionDate",
      this.state.easyRewardzTransactionDate
    );
    releaseEasyRewardzRedemptionPoints(
      this.props.user.store.RegistrationID,
      this.state.sale.Customer.PhoneNo,
      this.props.user.store.StoreCode,
      this.state.easyRewardzTransactionCode,
      this.state.easyRewardzTransactionDate
    ).then((resposne) => {
      if (!resposne.Success) {
        alert("Unable to release blocked points. Please try again.");
        return;
      }
    });
  }

  onClosePaymentScreen() {
    if (this.props.registration.registration.EnableEasyRewardz) {
      console.log("call easyrewardz api to release blocked points");
      // let resposne = await
      this.rollBackEasyRewardz();
      this.setState({
        easyRewardzTransactionCode: "",
        easyRewardzTransactionDate: "",
      });
    }
    this.props.onCancel();
  }

  renderDesktop() {
    // const currencyBtn = {
    //     margin: "0px",
    //     width: "80px",
    //     height: "80px",
    //     fontWeight: "bold",
    //     color: "gray",
    // }

    const handlers = {
      paymentOptions: this.shortcutPaymentOptions,
      done: this.shortcutDone,
    };

    return (
      <React.Fragment>
        {!this.state.print &&
          !this.props.isMobileApp() &&
          !this.props.printKOT &&
          !this.props.printBill && (
            <React.Fragment>
              <div className="menu-overlay" />
              <HotKeys keyMap={paymentKeyMap} handlers={handlers}>
                <div
                  className="sales-payment-close-button"
                  onClick={() => this.onClosePaymentScreen()}
                >
                  <ImageButton
                    type="remove"
                    onClick={() => this.setState({ showQuickAdd: false })}
                  />
                </div>

                {this.state.sale && (
                  <div className="sales-payment">
                    <div className="row">
                      <div className="col-md-6">
                        {this.state.sale.TransactionType.PaymentModeChecked && (
                          <div className="col-md-5 scroll2 payment-left-section">
                            {this.state.paymentModes.map(
                              (paymentMode, index) => (
                                <Button
                                  type="secondary"
                                  text={
                                    index < 12
                                      ? "(F" +
                                        (index + 1) +
                                        ")" +
                                        paymentMode.PaymentType
                                      : paymentMode.PaymentType
                                  }
                                  selected={
                                    this.state.selectedPaymentMode ===
                                    paymentMode.PaymentType
                                  }
                                  disabled={
                                    (paymentMode.PaymentType.toLowerCase() ===
                                      "wallet" &&
                                      this.state.walletAdded) ||
                                    this.state.selectedPaymentOptionLoading
                                      ? true
                                      : false
                                  }
                                  onClick={(evt) =>
                                    this.onPaymentModeSelected(
                                      paymentMode,
                                      false
                                    )
                                  }
                                />
                              )
                            )}
                          </div>
                        )}
                        {this.state.sale.TransactionType.PaymentModeChecked && (
                          <div className="col-md-7">
                            {this.state.selectedPaymentMode.toLowerCase() !==
                              "loyalty" && (
                              <React.Fragment>
                                <div className="row">
                                  <div className="input-group ">
                                    <input
                                      type="number"
                                      className="form-control input-lg input-left-rounded"
                                      value={this.state.paymentModeAmount}
                                      autoFocus={true}
                                      onChange={(evt) =>
                                        this.setState({
                                          paymentModeAmount: evt.target.value,
                                        })
                                      }
                                      disabled={
                                        this.state.selectedPaymentOptionLoading
                                      }
                                      ref="txtPaymentModeAmount"
                                      onKeyPress={(ev) => {
                                        //console.log(`Pressed keyCode ${ev.key}`);
                                        if (ev.key === "Enter") {
                                          // Do code here
                                          this.onAddPaymentAmountClicked(
                                            this.state.selectedPaymentModeID,
                                            this.state.selectedPaymentMode,
                                            this.state.paymentModeAmount
                                          );
                                        }
                                      }}
                                    />
                                    <span className="input-group-btn">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.onAddPaymentAmountClicked(
                                            this.state.selectedPaymentModeID,
                                            this.state.selectedPaymentMode,
                                            this.state.paymentModeAmount
                                          )
                                        }
                                        disabled={
                                          this.state
                                            .selectedPaymentOptionLoading
                                        }
                                        className="btn button-right-rounded button-lg button-selected"
                                      >
                                        <div className="add-payment">
                                          Add Payment{" "}
                                        </div>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                                <div className="row">
                                  {this.state.selectedPaymentMode.toLowerCase() ===
                                    "cash" && (
                                    <div className="row">
                                      <div className="cash-payment-mode">
                                        {this.props.config.hasOwnProperty(
                                          "CountryConfig"
                                        ) &&
                                          this.props.config.CountryConfig.hasOwnProperty(
                                            "CurrencyDenominations"
                                          ) &&
                                          this.props.config.CountryConfig.CurrencyDenominations.map(
                                            (c) => (
                                              <button
                                                onClick={() => {
                                                  this.onCurrencyClick(c);
                                                }}
                                                disabled={
                                                  this.state
                                                    .selectedPaymentOptionLoading
                                                }
                                                className="button-currency"
                                              >
                                                {this.props.config.CountryConfig.hasOwnProperty(
                                                  "CurrencySymbol"
                                                )
                                                  ? this.props.config
                                                      .CountryConfig
                                                      .CurrencySymbol
                                                  : ""}{" "}
                                                {c}
                                              </button>
                                            )
                                          )}
                                        <button
                                          onClick={() => {
                                            this.setState({
                                              paymentModeAmount: this.state
                                                .balanceAmount,
                                              firstCurrencyClick: false,
                                            });
                                          }}
                                          disabled={
                                            this.state
                                              .selectedPaymentOptionLoading
                                          }
                                          className=" button-currency"
                                        >
                                          Exact
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="row">
                                  <div style={{ marginTop: "5px" }}>
                                    {this.state.selectedPaymentMode.toLowerCase() !==
                                      "cash" && (
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control input-lg"
                                          onChange={(evt) =>
                                            this.setState({
                                              paymentModeRemarks:
                                                evt.target.value,
                                            })
                                          }
                                          value={this.state.paymentModeRemarks}
                                          placeholder="Remarks"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {this.state.selectedPaymentMode.toLowerCase() ===
                              "wallet" && (
                              <PaymentWalletWidget
                                mobile={this.props.mobile}
                                paymentModeAmount={this.state.paymentModeAmount}
                                onPaymentModeAmount={(value) =>
                                  this.setState({ paymentModeAmount: value })
                                }
                                paymentModeRemarks={
                                  this.state.paymentModeRemarks
                                }
                                onPaymentModeRemarks={(value) =>
                                  this.setState({ paymentModeRemarks: value })
                                }
                                walletBalance={this.state.walletBalance}
                                OTPVerificationRequired={
                                  this.state.OTPVerificationRequired
                                }
                                OTP={this.state.OTP}
                                onOPTChange={(value) =>
                                  this.setState({ OTP: value })
                                }
                              />
                            )}
                            {this.state.selectedPaymentMode.toLowerCase() ===
                              "loyalty" && (
                              <PaymentLoyaltyWidget
                                {...this.props}
                                // PaymentMode = {this.state.selectedPaymentMode}
                                BalanceAmount={this.state.balanceAmount}
                                Customer={this.state.sale.Customer}
                                Sale={this.state.sale}
                                redemptionInitiated={(
                                  easyRewardzTransactionCode,
                                  easyRewardzTransactionDate
                                ) => {
                                  console.log(
                                    "easyRewardzTransactionCode",
                                    easyRewardzTransactionCode
                                  );
                                  this.setState({
                                    easyRewardzTransactionCode: easyRewardzTransactionCode,
                                    easyRewardzTransactionDate: easyRewardzTransactionDate,
                                  });
                                }}
                                addToPayments={(
                                  amountRedemmed,
                                  easyRewardzTransactionCode,
                                  easyRewardzTransactionDate,
                                  easyRewardzOTP
                                ) => {
                                  console.log("easyRewardzOTP", easyRewardzOTP);
                                  console.log(
                                    "easyRewardzTransactionCode",
                                    easyRewardzTransactionCode
                                  );
                                  this.setState({
                                    easyRewardzTransactionCode: easyRewardzTransactionCode,
                                    easyRewardzTransactionDate: easyRewardzTransactionDate,
                                    easyRewardzOTP: easyRewardzOTP,
                                  });

                                  this.addPaymentAmount(
                                    this.state.selectedPaymentModeID,
                                    this.state.selectedPaymentMode,
                                    amountRedemmed,
                                    "",
                                    "",
                                    ""
                                  );
                                }}
                                onCancel={() => {
                                  this.setState({
                                    selectedPaymentModeID: "",
                                    selectedPaymentMode: "",
                                  });
                                }}
                              />
                            )}
                            {this.state.outstandingRRNs.length > 0 && (
                              <PaymentRRNWidget
                                {...this.props}
                                mobile={this.props.mobile}
                                selectedRRNID={this.state.selectedRRNID}
                                outstandingRRNs={this.state.outstandingRRNs}
                                onChange={(value) =>
                                  this.setState({ selectedRRNID: value })
                                }
                              />
                            )}
                          </div>
                        )}
                        {/* </div> */}
                      </div>
                      <div className="col-md-6">
                        {this.state.sale && (
                          <div>
                            {/* className="payment-container-right" */}
                            {/* SHOW SELECTED PAYMENT OPTION */}
                            <div className="payment-right-section">
                              <div className="col-md-12 payment-right-section-header">
                                <label>
                                  {this.state.sale.TransactionType
                                    .PaymentModeChecked
                                    ? "Pay"
                                    : "Total"}
                                  :{" "}
                                </label>
                                <label className="pull-right">
                                  {this.props.user.store.Currency}{" "}
                                  {this.state.sale.BillAmount}
                                </label>
                              </div>
                              {this.state.sale.TransactionType
                                .PaymentModeChecked && (
                                <React.Fragment>
                                  {this.state.payments.map((payment, index) => (
                                    <div
                                      key={index}
                                      className="col-md-12 payment-right-section-paymentmode"
                                    >
                                      <div className="left">
                                        <ImageButton
                                          type="delete"
                                          onClick={() =>
                                            this.onRemovePayment(index)
                                          }
                                        />
                                        <label> {payment.PaymentMode}: </label>
                                        {payment.PaymentModeRemarks.trim() !==
                                          "" && (
                                          <label>
                                            {" "}
                                            ({payment.PaymentModeRemarks})
                                          </label>
                                        )}
                                      </div>
                                      <div className="right">
                                        <label>
                                          {this.props.user.store.Currency}{" "}
                                          {payment.Amount}{" "}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </React.Fragment>
                              )}
                              {this.state.sale.TransactionType
                                .PaymentModeChecked && (
                                <div className="col-md-12 payment-right-section-paid-amt">
                                  <label>Paid Amount:</label>
                                  <label className="pull-right">
                                    {this.props.user.store.Currency}{" "}
                                    {this.state.paidAmount}
                                  </label>
                                </div>
                              )}
                              {this.state.sale.TransactionType
                                .PaymentModeChecked && (
                                <div className="col-md-12 payment-right-section-bal-amt">
                                  <label>Balance Amount:</label>
                                  <label className="pull-right">
                                    {this.props.user.store.Currency}{" "}
                                    {this.state.balanceAmount}
                                  </label>
                                </div>
                              )}
                              {this.state.balanceAmount > 0 && (
                                <div className="col-md-12">
                                  <div className="payment-right-section-due-date">
                                    <div className="col-md-8">
                                      <label>Due Date:</label>
                                    </div>
                                    <div className="col-md-4">
                                      <input
                                        type="date"
                                        className="form-control"
                                        onChange={(evt) =>
                                          this.setState({
                                            dueDate: evt.target.value,
                                          })
                                        }
                                        value={this.state.dueDate}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {this.state.changeDue > 0 && (
                                <div className="col-md-12 payment-right-section-change-due">
                                  <label>Change Due:</label>
                                  <label className="pull-right">
                                    {this.props.user.store.Currency}{" "}
                                    {this.state.changeDue}
                                  </label>
                                </div>
                              )}
                            </div>

                            <div className="col-md-12">
                              <div
                                className="col-md-3"
                                style={{ padding: "0px" }}
                              >
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    disabled={
                                      this.state.selectedPringConfigID === ""
                                    }
                                    onChange={() =>
                                      this.setState({
                                        printChecked: !this.state.printChecked,
                                      })
                                    }
                                    checked={this.state.printChecked}
                                  />
                                  &nbsp;<label> Print</label>
                                  <select
                                    className="form-control input-lg"
                                    disabled={
                                      !this.state.printChecked ||
                                      this.state.selectedPringConfigID === ""
                                    }
                                    value={this.state.selectedPringConfigID}
                                    onChange={(evt) =>
                                      this.setState({
                                        selectedPringConfigID: evt.target.value,
                                        selectedPrintConfigPrintDisplayName:
                                          evt.target.options[
                                            evt.target.selectedIndex
                                          ].text,
                                      })
                                    }
                                  >
                                    {this.props.config.PrintConfigs.filter(
                                      (e) =>
                                        !e.IsDeleted &&
                                        e.TransactionTypeID ===
                                          this.state.sale.TransactionType.key
                                    ).map((t, index) => (
                                      <option key={index} value={t.key}>
                                        {t.PrintDisplayName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-3"
                                style={{ padding: "0px" }}
                              >
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      this.setState({
                                        sendSMSChecked: !this.state
                                          .sendSMSChecked,
                                      })
                                    }
                                    // checked = {this.state.sendSMSChecked}
                                    checked={
                                      this.state.sendSMSChecked &&
                                      this.props.config.ConnectionStatus
                                        .Status === "connected"
                                    }
                                    disabled={
                                      this.props.config.ConnectionStatus
                                        .Status === "disconnected"
                                    }
                                  />
                                  &nbsp;<label>Send SMS</label>
                                  <input
                                    type="number"
                                    disabled={!this.state.sendSMSChecked}
                                    onChange={(evt) =>
                                      this.setState({ SMSNo: evt.target.value })
                                    }
                                    value={this.state.SMSNo}
                                    className="form-control input-lg"
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-3"
                                style={{ padding: "0px" }}
                              >
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      this.setState({
                                        sendWhatsAppChecked: !this.state
                                          .sendWhatsAppChecked,
                                      })
                                    }
                                    checked={
                                      this.state.sendWhatsAppChecked &&
                                      this.props.config.ConnectionStatus
                                        .Status === "connected"
                                    }
                                    disabled={
                                      this.props.config.ConnectionStatus
                                        .Status === "disconnected"
                                    }
                                  />
                                  &nbsp;<label>Send WhatsApp</label>
                                  <input
                                    type="number"
                                    disabled={!this.state.sendWhatsAppChecked}
                                    onChange={(evt) =>
                                      this.setState({ SMSNo: evt.target.value })
                                    }
                                    value={this.state.SMSNo}
                                    className="form-control input-lg"
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-3"
                                style={{ padding: "0px" }}
                              >
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      this.setState({
                                        sendEmailChecked: !this.state
                                          .sendEmailChecked,
                                      })
                                    }
                                    // checked = {this.state.sendEmailChecked}
                                    checked={
                                      this.state.sendEmailChecked &&
                                      this.props.config.ConnectionStatus
                                        .Status === "connected"
                                    }
                                    disabled={
                                      this.props.config.ConnectionStatus
                                        .Status === "disconnected"
                                    }
                                  />
                                  &nbsp;<label>Send Email</label>
                                  <input
                                    type="text"
                                    disabled={!this.state.sendEmailChecked}
                                    onChange={(evt) =>
                                      this.setState({
                                        emailID: evt.target.value,
                                      })
                                    }
                                    value={this.state.emailID}
                                    className="form-control input-lg"
                                  />
                                </div>
                              </div>
                            </div>
                            {this.state.sale && (
                              <div className="col-md-12">
                                <button
                                  className="pay-btn"
                                  onClick={() =>
                                    this.preSaveChecking(
                                      this.state.key,
                                      this.state.sale,
                                      this.state.balanceAmount,
                                      this.state.paidAmount,
                                      this.state.changeDue,
                                      this.state.payments,
                                      this.state.printChecked,
                                      this.state.sendSMSChecked,
                                      this.state.SMSNo,
                                      this.state.sendEmailChecked,
                                      this.state.emailID,
                                      this.state.selectedPringConfigID,
                                      this.state.sendWhatsAppChecked,
                                      this.state.dueDate
                                    )
                                  }
                                  // onClick={() => this.onSaveSalesTransaction(this.state.key, this.state.sale, this.state.balanceAmount, this.state.paidAmount, this.state.changeDue, this.state.payments, this.state.printChecked, this.state.sendSMSChecked, this.state.SMSNo, this.state.sendEmailChecked, this.state.emailID, this.state.selectedPringConfigID, this.state.sendWhatsAppChecked, this.state.dueDate)}

                                  disabled={this.state.saving}
                                >
                                  {this.state.saving
                                    ? "Saving Data..."
                                    : "(F12) Done"}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* </div> */}
              </HotKeys>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        {!this.state.print && this.props.isMobileApp() && this.state.sale && (
          <React.Fragment>
            <div className="sales-header">
              <div className="sales-header-back">
                <ImageButton
                  type="leftarrow"
                  onClick={() => {
                    this.props.onCancel();
                  }}
                />
              </div>
              <div className="sales-header-title">Payments</div>
            </div>
            <div className="sales-content">
              <div className="header-new-background"></div>
              <div className="payment-total">
                <span>
                  {this.state.sale.TransactionType.PaymentModeChecked
                    ? "Pay"
                    : "Total"}
                  :{" "}
                </span>
                <span className="pull-right">
                  {this.props.user.store.Currency} {this.state.sale.BillAmount}
                </span>
              </div>
              {this.state.sale.TransactionType.PaymentModeChecked && (
                <div>
                  {this.state.payments.map((payment, index) => (
                    <div className="payment-selected-paymentmode">
                      <button
                        className="btn btn-flat btn-lg btn-default"
                        onClick={() => this.onRemovePayment(index)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </button>
                      <span> {payment.PaymentMode}: </span>
                      {payment.PaymentModeRemarks.trim() !== "" && (
                        <span> ({payment.PaymentModeRemarks})</span>
                      )}
                      <span
                        className="pull-right"
                        style={{ marginTop: "12px" }}
                      >
                        {this.props.user.store.Currency} {payment.Amount}{" "}
                      </span>
                    </div>
                  ))}
                </div>
              )}
              {this.state.sale.TransactionType.PaymentModeChecked && (
                <div className="payment-text">
                  <span>Paid Amount:</span>
                  <span className="pull-right">
                    {this.props.user.store.Currency} {this.state.paidAmount}
                  </span>
                </div>
              )}
              {this.state.sale.TransactionType.PaymentModeChecked && (
                <div className="payment-text">
                  <span>Balance Amount:</span>
                  <span className="pull-right">
                    {this.props.user.store.Currency} {this.state.balanceAmount}
                  </span>
                </div>
              )}
              {this.state.changeDue > 0 && (
                <div className="payment-text">
                  <span>Change Due:</span>
                  <span className="pull-right">
                    {this.props.user.store.Currency} {this.state.changeDue}
                  </span>
                </div>
              )}
              {/* <br /> */}
              <div className="col-xs-4 payment-amount">
                <input
                  type="number"
                  className="form-control input-lg"
                  value={this.state.paymentModeAmount}
                  onChange={(evt) =>
                    this.setState({ paymentModeAmount: evt.target.value })
                  }
                  disabled={this.state.selectedPaymentOptionLoading}
                  ref="txtPaymentModeAmount"
                />
              </div>
              <div className="col-xs-8 payment-remarks">
                <input
                  type="text"
                  className="form-control input-lg"
                  onChange={(evt) =>
                    this.setState({ paymentModeRemarks: evt.target.value })
                  }
                  value={this.state.paymentModeRemarks}
                  placeholder="Remarks"
                />
              </div>
              {this.state.paymentModes.map((paymentMode, index) => (
                <div className="">
                  <button
                    key={index}
                    style={{
                      marginTop: "10px",
                      marginRight: "10px",
                      width: "100%",
                      borderRadius: "50px",
                    }}
                    className="btn btn-flat btn-lg btn-secondary"
                    onClick={(evt) =>
                      this.onPaymentModeSelected(paymentMode, true)
                    }
                    disabled={
                      (paymentMode.PaymentType.toLowerCase() === "wallet" &&
                        this.state.walletAdded) ||
                      this.state.selectedPaymentOptionLoading
                        ? true
                        : false
                    }
                  >
                    {paymentMode.PaymentType}
                  </button>
                </div>
              ))}
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            <div className="sales-new-mobile-footer">
              <Button
                type="primary"
                text={!this.state.saving ? "Done" : "Please wait ..."}
                disabled={this.state.saving}
                onClick={(action) =>
                  this.preSaveChecking(
                    this.state.key,
                    this.state.sale,
                    this.state.balanceAmount,
                    this.state.paidAmount,
                    this.state.changeDue,
                    this.state.payments,
                    this.state.printChecked,
                    this.state.sendSMSChecked,
                    this.state.SMSNo,
                    this.state.sendEmailChecked,
                    this.state.emailID,
                    this.state.selectedPringConfigID,
                    this.state.sendWhatsAppChecked
                  )
                }
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderMobile()}

        {this.renderDesktop()}

        {this.state.print && (
          <div>
            <Print
              {...this.props}
              printChecked={this.state.printChecked}
              sendEmailChecked={this.state.sendEmailChecked}
              sendSMSChecked={this.state.sendSMSChecked}
              sendWhatsAppChecked={this.state.sendWhatsAppChecked}
              printData={this.state.printData}
              kotPrinter={
                this.props.user.store.hasOwnProperty("KOTPrinter")
                  ? this.props.user.store.KOTPrinter
                  : ""
              }
              onClose={() => {
                if (
                  this.state.sale.TransactionType.TransactionType.toLowerCase() ===
                  "receive payment"
                ) {
                  this.setState({ print: false });
                  this.props.onSave();
                }
                if (this.props.registration.registration.KOT) {
                  //KOT printing to be done
                  if (this.state.printKOT) {
                    console.log("KOT printing started");
                    //now check for KOT group
                    if (
                      this.props.registration.registration.hasOwnProperty(
                        "KOTGroup"
                      ) &&
                      this.props.registration.registration.KOTGroup
                    ) {
                      if (
                        this.state.KOTGroupPrints.length > 0 &&
                        this.state.currentPrintIndex <
                          this.state.KOTGroupPrints.length
                      ) {
                        let sale = this.getKOTGroupDataForPrinting(
                          this.state.printKOTData.Sale,
                          this.state.KOTGroupPrints,
                          this.state.currentPrintIndex
                        );
                        this.setState({
                          printChecked: true,
                          sendEmailChecked: false,
                          sendSMSChecked: false,
                          sendWhatsAppChecked: false,
                          printData: {
                            Sale: sale,
                            PrintConfig: this.state.printKOTData.PrintConfig,
                          },
                          printKOT:
                            this.state.currentPrintIndex + 1 <
                            this.state.KOTGroupPrints.length
                              ? true
                              : false,
                          currentPrintIndex: this.state.currentPrintIndex + 1,
                        });
                      } else {
                        //no need to do anything
                        this.setState({
                          printKOT: false,
                          currentPrintIndex: 0,
                          print: false,
                        });
                        this.props.onSave();
                      }
                    } else {
                      // console.log('else kot print')
                      this.setState({
                        printChecked: true,
                        sendEmailChecked: false,
                        sendSMSChecked: false,
                        sendWhatsAppChecked: false,
                        printData: this.state.printKOTData,
                        printKOT: false,
                      });
                    }
                  } else {
                    //KOT printing done
                    // console.log("KOT printing done")
                    this.setState({ print: false });
                    this.props.onSave();
                  }
                } else {
                  //non KOT printing
                  this.setState({ print: false });
                  this.props.onSave();
                }
              }}
            />
          </div>
        )}

        {this.state.showPowerUserAutorizationRequired && (
          <Window
            header={"OTP Verification"}
            modal={true}
            processing={this.state.validatingOTP}
            onClose={() =>
              this.setState({ showPowerUserAutorizationRequired: false })
            }
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "ok") {
                console.log("OTP Verification OK clicked");
                this.onValidatePowerUserOTP();
              } else {
                this.setState({ showPowerUserAutorizationRequired: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <Input
              type="number"
              label="Please enter OTP"
              value={this.state.powerUserOTP}
              error={this.state.powerUserOTPError}
              onChange={(value) =>
                this.setState({ powerUserOTP: value, powerUserOTPError: "" })
              }
            />
          </Window>
        )}

        <Modal
          open={this.state.showThirdPartyPaymentProcessing}
          onClose={() =>
            this.setState({
              showThirdPartyPaymentProcessing: false,
              selectedPaymentOptionLoading: false,
            })
          }
          center
        >
          <div className="box-body" style={{ width: "300px" }}>
            {/* {this.state.showThirdPartyPaymentProcessingLoader &&  <h3 style={{ marginTop: "10px" }}>Please wait for processing to complete</h3>  }           */}
            {/* <Loader show={this.state.showThirdPartyPaymentProcessingLoader} /> */}
            <div
              style={{
                marginTop: "0px",
                textAlign: "center",
                color:
                  this.state.thirdPartyPaymentProcessingStatus ===
                  "Payment Success"
                    ? "green"
                    : this.state.thirdPartyPaymentProcessingStatus ===
                      "Payment Fail"
                    ? "red"
                    : "black",
              }}
            >
              {this.state.showThirdPartyPaymentProcessingLoader && (
                <img
                  style={{ width: "100px" }}
                  src={
                    !window.cordova
                      ? require("../../images/payment-processing.gif")
                      : "/android_asset/www/images/payment-processing.gif"
                  }
                />
              )}
              {this.state.thirdPartyPaymentProcessingStatus ===
                "Payment Success" && (
                <img
                  style={{ width: "100px" }}
                  src={
                    !window.cordova
                      ? require("../../images/payment-success.png")
                      : "/android_asset/www/images/payment-success.png"
                  }
                />
              )}
              {(this.state.thirdPartyPaymentProcessingStatus ===
                "Payment Error" ||
                this.state.thirdPartyPaymentProcessingStatus ===
                  "Payment Fail") && (
                <img
                  style={{ width: "100px" }}
                  src={
                    !window.cordova
                      ? require("../../images/payment-error.png")
                      : "/android_asset/www/images/payment-error.png"
                  }
                />
              )}
              {this.state.thirdPartyPaymentProcessingStatus !==
                "Payment Success" && (
                <h3 style={{ marginTop: "0px" }}>
                  {this.state.selectedPaymentMode}-
                  {this.state.paymentModeAmount}
                </h3>
              )}
              {this.state.showQR && <QRCode value={this.state.qrString} />}
              <h3 style={{ marginTop: "0px" }}>
                {this.state.thirdPartyPaymentProcessingStatus}
              </h3>
              <h6 style={{ marginTop: "0px" }}>
                {this.state.thirdPartyPaymentProcessingMessage}
              </h6>
              <h6>
                Transaction ID: {this.state.thiryPartyPaymentTransactionID}
              </h6>
              {/* <h6>{this.state.selectedPaymentMode } Transaction ID:{this.state.thirdPartyPaymentProviderReferenceID}</h6> */}
            </div>
          </div>
        </Modal>
        <Modal
          open={
            this.state.selectedPaymentMode.toLowerCase() === "wallet" &&
            this.props.mobile
          }
          onClose={() => this.setState({ selectedPaymentMode: "" })}
          center
        >
          <h3 style={{ marginTop: "0px" }}>Wallet</h3>
          <div className="box-body">
            <PaymentWalletWidget
              mobile={this.props.mobile}
              paymentModeAmount={this.state.paymentModeAmount}
              onPaymentModeAmount={(value) =>
                this.setState({ paymentModeAmount: value })
              }
              paymentModeRemarks={this.state.paymentModeRemarks}
              onPaymentModeRemarks={(value) =>
                this.setState({ paymentModeRemarks: value })
              }
              walletBalance={this.state.walletBalance}
              OTPVerificationRequired={this.state.OTPVerificationRequired}
              OTP={this.state.OTP}
              onOPTChange={(value) => this.setState({ OTP: value })}
              onOKClicked={() =>
                this.onAddPaymentAmountClicked(
                  this.state.selectedPaymentModeID,
                  this.state.selectedPaymentMode,
                  this.state.paymentModeAmount
                )
              }
              onCancelClicked={() => this.setState({ selectedPaymentMode: "" })}
            />
          </div>
        </Modal>
        <Modal
          open={
            this.state.selectedPaymentMode.toLowerCase() === "loyalty" &&
            this.props.mobile
          }
          onClose={() => this.setState({ selectedPaymentMode: "" })}
          center
        >
          <h3 style={{ marginTop: "0px" }}>Loyalty</h3>
          <div className="box-body">
            <PaymentLoyaltyWidget
              mobile={this.props.mobile}
              PaymentMode={this.state.selectedPaymentMode}
              paymentModeAmount={this.state.paymentModeAmount}
              onPaymentModeAmount={(value) =>
                this.setState({ paymentModeAmount: value })
              }
              paymentModeRemarks={this.state.paymentModeRemarks}
              onPaymentModeRemarks={(value) =>
                this.setState({ paymentModeRemarks: value })
              }
              loyaltyTypeID={this.state.loyaltyTypeID}
              loyaltyType={this.state.loyaltyType}
              loyaltyPoints={this.state.loyaltyPoints}
              loyaltyValue={this.state.loyaltyValue}
              OTPVerificationRequired={this.state.OTPVerificationRequired}
              OTP={this.state.OTP}
              onOPTChange={(value) => this.setState({ OTP: value })}
              onOKClicked={() =>
                this.onAddPaymentAmountClicked(
                  this.state.selectedPaymentModeID,
                  this.state.selectedPaymentMode,
                  this.state.paymentModeAmount
                )
              }
              onCancelClicked={() => this.setState({ selectedPaymentMode: "" })}
            />
          </div>
        </Modal>
        <Modal
          open={
            this.state.selectedPaymentMode.toLowerCase() ===
              "return redemption note" && this.props.mobile
          }
          onClose={() => this.setState({ selectedPaymentMode: "" })}
          center
        >
          <h3 style={{ marginTop: "0px" }}>RRN</h3>
          <div className="box-body">
            <PaymentRRNWidget
              {...this.props}
              mobile={this.props.mobile}
              paymentModeAmount={this.state.paymentModeAmount}
              onPaymentModeAmount={(value) =>
                this.setState({ paymentModeAmount: value })
              }
              paymentModeRemarks={this.state.paymentModeRemarks}
              onPaymentModeRemarks={(value) =>
                this.setState({ paymentModeRemarks: value })
              }
              selectedRRNID={this.state.selectedRRNID}
              outstandingRRNs={this.state.outstandingRRNs}
              onChange={(value) => this.setState({ selectedRRNID: value })}
              onOKClicked={() =>
                this.onAddPaymentAmountClicked(
                  this.state.selectedPaymentModeID,
                  this.state.selectedPaymentMode,
                  this.state.paymentModeAmount
                )
              }
              onCancelClicked={() => this.setState({ selectedPaymentMode: "" })}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withRouter(Payment);
