import React, { Component } from 'react';
import { isNullOrUndefined } from 'util';
import * as constatnts from '../../Constatnts'
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';
import FormSection from '../2/Controls/FormSection';
import Button from '../2/Controls/Button';

class addCampaign extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            key: '',
            name: '',
            type: 'One Time',
            startDate: '',
            nameError: '',
            typeError: '',
            startDateError: '',
            slotError: '',
            purchaseAmountFrom: '',
            purchaseAmountTo: '',
            membership: null,
            customerGender: '',
            customerAgeFrom: '',
            customerAgeTo: '',
            walletBalanceFrom: '',
            walletBalanceTo: '',
            outStandingAmtFrom: '',
            outStandingAmtTo: '',
            customerSelected:  false,
            customerVisited:'',
            customerVisitedError: '',
            customerInDays: '',
            customerInDaysError: '',
            purchaseAmountSelected: false,
            membershipSelected: false,
            customerGenderSelected: false,
            customerAgeSelected: false,
            walletBalanceSelected: false,
            outStandingAmtSelected: false,
            membershipList: [],
            offerList: [],
            couponList: [],
            offerID: '',
            specificStoreSelected: false,
            allStoresSelected: true,
            associatedStores: [],
            addedStores: [],
            slots: [
                { value: 8, name: "8:00 - 10:00" },
                { value: 10, name: "10:00 - 12:00" },
                { value: 12, name: "12:00 - 14:00" },
                { value: 14, name: "14:00 - 16:00" },
                { value: 16, name: "16:00 - 18:00" },
                { value: 18, name: "18:00 - 20:00" },
                { value: 20, name: "20:00 - 22:00" },
            ],
            slot: '',
            purchaseAmountFromError: '',
            purchaseAmountToError: '',
            membershipError: '',
            customerGenderError: '',
            customerAgeFromError: '',
            customerAgeToError: '',
            walletBalanceFromError: '',
            walletBalanceToError: '',
            outStandingAmtFromError: '',
            outStandingAmtToError: '',
            optionError: '',
            storeNameError: '',
            stores: [],
            smsTemplateError: '',
            smsTemplates: [],
            smsTemplateID: '',

            specificCustomerSelected: false,
            allCustomersSelected: true,
            customerOptionError: ''
        }
        this.onSaveCampaign = this.onSaveCampaign.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.onMembershipChange = this.onMembershipChange.bind(this);
        this.loadMembership = this.loadMembership.bind(this);
        this.loadOffers = this.loadOffers.bind(this);
        this.onFetchCustomers = this.onFetchCustomers.bind(this);
    }

    async componentDidMount() {


        // await this.loadMembership();
        this.loadMembership();

        // await this.loadOffers();
         this.loadOffers();

        // let smsTemplates = await 
        this.props.getSMSTemplatesBySMSType(this.props.user.user.RegistrationID, "Promotional").then((smsTemplates) => {
            this.setState({ smsTemplates: smsTemplates });
            // return smsTemplates;
        });


        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {           
            const storeList = [];
            stores.map((store) => {
                let obj = {
                    value: store.key, label: store.StoreName
                }
                storeList.push(obj);
            });
            this.setState({ stores: storeList })
        });
        
        const key = this.props.campaign ? this.props.campaign.key : "";
        if (key) {
            // this.setState({ key: key })
            this.props.getCampaignDetails(key).then((campaign) => {
                let smsTemplate = this.state.smsTemplates.filter((s) => s.key === campaign.SMSTemplateID).length > 0 ? this.state.smsTemplates.filter((s) => s.key === campaign.SMSTemplateID)[0].SMSTemplate : ''
                if (campaign.OfferID !== "") {
                    let offer = this.state.offerList.filter((o) => o.key === campaign.OfferID).length > 0 ? this.state.offerList.filter((o) => o.key === campaign.OfferID)[0] : null
                    //console.log("offer applied", offer);
                    if (offer) {
                        smsTemplate = smsTemplate.replace('<offer>', offer.Name);
                    }
                    else {
                        let coupon = this.state.couponList.filter((o) => o.key === campaign.OfferID).length > 0 ? this.state.couponList.filter((o) => o.key === campaign.OfferID)[0] : null
                        //console.log("coupon applied", coupon);
                        if (coupon) {
                            smsTemplate = smsTemplate.replace('<coupon>', coupon.Name);
                        }
                    }
                }

                this.setState({
                    key: key,
                    name: campaign.Name,
                    type: campaign.Type,
                    startDate: campaign.StartDate,
                    slot: campaign.Slot,
                    allCustomersSelected: campaign.AllCustomersSelected,
                    specificCustomerSelected: campaign.SpecificCustomerSelected,
                    smsTemplateID: campaign.SMSTemplateID,
                    offerID: campaign.OfferID,
                    smsTemplate: smsTemplate,
                    customerSelected: campaign.hasOwnProperty('CustomerSelected') ? campaign.CustomerSelected : false,
                    customerInDays: campaign.CustomerInDays,
                    customerVisited: campaign.hasOwnProperty('CustomerVisited') ? campaign.CustomerVisited : '',
                    purchaseAmountSelected: campaign.PurchaseAmountSelected,
                    purchaseAmountFrom: campaign.PurchaseAmountFrom,
                    purchaseAmountTo: campaign.PurchaseAmountTo,
                    storeName: campaign.StoreName,
                    allStoresSelected: campaign.AllStoresSelected,
                    associatedStores: !isNullOrUndefined(campaign.Stores) ? campaign.Stores : [],
                    membershipSelected: campaign.MembershipSelected,
                    membership: campaign.Membership,
                    customerGenderSelected: campaign.CustomerGenderSelected,
                    customerGender: campaign.CustomerGender,
                    walletBalanceSelected: campaign.WalletBalanceSelected,
                    walletBalanceFrom: campaign.WalletBalanceFrom,
                    walletBalanceTo: campaign.WalletBalanceTo,
                    specificStoreSelected: campaign.SpecificStoreSelected,
                    outStandingAmtSelected: campaign.OutStandingAmtSelected,
                    outStandingAmtFrom: campaign.OutStandingAmtFrom,
                    outStandingAmtTo: campaign.OutStandingAmtTo
                })
            })
        }
    };

    onSaveCampaign() {
        this.setState({ saving: true })
        let nameError = ""
        let typeError = ""
        let startDateError = ""
        let slotError = ""
        let optionError = ""
        let customerOptionError = ''
        let customerVisitedError=  ''
        let customerInDaysError = ""
        let storeNameError = ""
        let smsTemplateError = ""
        let purchaseAmountFromError = ""
        let purchaseAmountToError = ""
        let membershipError = ""
        let customerGenderError = ""
        let customerAgeFromError = ""
        let customerAgeToError = ""
        let walletBalanceFromError = ""
        let walletBalanceToError = ""
        let outStandingAmtFromError = ""
        let outStandingAmtToError = ""
        let customerNotInDaysError = ""

        if (this.state.name.trim() === "") {
            nameError = "Please provide campaign name"
        }

        if (this.state.type.trim() === "") {
            typeError = "Please provide campaign type"
        }

        if (this.state.type.trim() === "One Time") {
            if (this.state.startDate.trim() === "") {
                startDateError = "Please select campaign date"
            }

            if (this.state.slot === '') {
                slotError = "Please select campaign slot"
            }
        }

        if (this.state.startDate.trim() !== "") {
            if (new Date(this.state.startDate).toISOString().split('T')[0] <= new Date().toISOString().split('T')[0]) {
                startDateError = "Date should be greater than the current date"
            }
        }

        if (this.state.specificCustomerSelected) {
            if (this.state.customerLastVisited) {
                
                if (this.state.customerVisited.trim() === "") {
                    customerVisitedError = "Please select either customer last visited or not visited"
                }
                
                if (this.state.customerInDays.trim() === "") {
                    customerInDaysError = "Please provide days"
                }

                if (this.state.customerInDays.trim() !== "") {
                    if (Number(this.state.customerInDays) <= 0) {
                        customerInDaysError = "Days should be more than 0"
                    }
                    if (Number(this.state.customerInDays) > 365) {
                        customerInDaysError = "Days should be less than 365"
                    }
                }  
            }

            if (this.state.customerNotLastVisited) {
                if (this.state.customerNotInDays.trim() === "") {
                    customerNotInDaysError = "Please provide days"
                }

                if (this.state.customerNotInDays.trim() !== "") {
                    if (Number(this.state.customerNotInDays) <= 0) {
                        customerNotInDaysError = "Day should be more than 0"
                    }
                    if (Number(this.state.customerNotInDays) > 365) {
                        customerNotInDaysError = "Days should be less than 365"
                    }
                }
            }

            if (this.state.purchaseAmountSelected) {
                if (this.state.purchaseAmountFrom.trim() === "") {
                    purchaseAmountFromError = "Please provide value"
                }
                if (this.state.purchaseAmountTo.trim() === "") {
                    purchaseAmountToError = "Please provide value"
                }

                if (this.state.purchaseAmountFrom.trim() !== "" && this.state.purchaseAmountTo.trim() !== "") {
                    // console.log("purchaseAmountFrom", this.state.purchaseAmountFrom)
                    // console.log("purchaseAmountTo", this.state.purchaseAmountTo)

                    if (Number(this.state.purchaseAmountFrom) > Number(this.state.purchaseAmountTo)) {
                        purchaseAmountFromError = "Purchase from amount cannot be more than to amount"
                    }
                }
            }

            if (this.state.membershipSelected) {
                if (isNullOrUndefined(this.state.membership)) {
                    membershipError = "Please provide membership"
                }
            }

            if (this.state.customerGenderSelected) {
                if (this.state.customerGender.trim() === "") {
                    customerGenderError = "Please provide customer gender"
                }
            }

            if (this.state.walletBalanceSelected) {
                if (this.state.walletBalanceFrom.trim() === "") {
                    walletBalanceFromError = "Please provide value"
                }
                if (this.state.walletBalanceTo.trim() === "") {
                    walletBalanceToError = "Please provide value"
                }

                if (this.state.walletBalanceFrom.trim() !== "" && this.state.walletBalanceTo.trim() !== "") {
                    if (Number(this.state.walletBalanceFrom) > Number(this.state.walletBalanceTo)) {
                        walletBalanceFromError = "Wallet balance from amount cannot be more than to amount"
                    }
                }
            }

            if (this.state.outStandingAmtSelected) {
                if (this.state.outStandingAmtFrom.trim() === "") {
                    outStandingAmtFromError = "Please provide value"
                }
                if (this.state.outStandingAmtTo.trim() === "") {
                    outStandingAmtToError = "Please provide value"
                }

                if (this.state.outStandingAmtFrom.trim() !== "" && this.state.outStandingAmtTo.trim() !== "") {
                    if (Number(this.state.outStandingAmtFrom) > Number(this.state.outStandingAmtTo)) {
                        outStandingAmtFromError = "Outstanding from amount cannot be more than to amount"
                    }
                }
            }
        }

        if (this.state.smsTemplateID.trim() === "") {
            smsTemplateError = "Please select message"
        }

        if (!this.state.allStoresSelected && !this.state.specificStoreSelected) {
            optionError = "Please select either All or Specific Store option."
        }

        if (!this.state.allCustomersSelected && !this.state.specificCustomerSelected) {
            customerOptionError = "Please select either All or Specific Customer option."
        }

        if (this.state.specificStoreSelected) {
            if (this.state.associatedStores.length === 0) {
                storeNameError = "Please select store"
            }
        }

        if (optionError !== "" || nameError !== ""
            || slotError != "" || smsTemplateError !== ""
            || customerInDaysError !== "" || purchaseAmountFromError !== ""
            || purchaseAmountToError !== "" || membershipError !== ""
            || customerGenderError !== "" || customerAgeFromError !== ""
            || customerAgeToError !== "" || walletBalanceFromError !== ""
            || walletBalanceToError !== "" || storeNameError !== ""
            || typeError !== "" || startDateError !== ""
            || outStandingAmtFromError !== "" || outStandingAmtToError !== "" 
            || customerNotInDaysError !== "" || customerVisitedError !=""
        ) {
            this.setState({
                nameError: nameError,
                typeError: typeError,
                startDateError: startDateError,
                customerInDaysError: customerInDaysError,
                purchaseAmountFromError: purchaseAmountFromError,
                purchaseAmountToError: purchaseAmountToError,
                membershipError: membershipError,
                customerGenderError: customerGenderError,
                customerAgeFromError: customerAgeFromError,
                customerAgeToError: customerAgeToError,
                walletBalanceFromError: walletBalanceFromError,
                walletBalanceToError: walletBalanceToError,
                optionError: optionError,
                storeNameError: storeNameError,
                smsTemplateError: smsTemplateError,
                slotError: slotError,
                customerOptionError: customerOptionError,
                outStandingAmtFromError: outStandingAmtFromError,
                outStandingAmtToError: outStandingAmtToError,
                customerNotInDaysError: customerNotInDaysError,
                customerVisitedError: customerVisitedError,
                saving: false
            });
            return
        }

        let campaign = {

            RegistrationID: this.props.user.user.RegistrationID,
            Name: this.state.name,
            Type: this.state.type,
            StartDate: this.state.startDate,
            Slot: Number(this.state.slot),
            CustomerSelected: this.state.customerSelected,
            CustomerInDays: this.state.customerInDays,
            CustomerVisited: this.state.customerVisited,
            PurchaseAmountSelected: this.state.purchaseAmountSelected,
            PurchaseAmountFrom: this.state.purchaseAmountFrom,
            PurchaseAmountTo: this.state.purchaseAmountTo,
            MembershipSelected: this.state.membershipSelected,
            Membership: this.state.membership,
            CustomerGenderSelected: this.state.customerGenderSelected,
            CustomerGender: this.state.customerGender,
            WalletBalanceSelected: this.state.walletBalanceSelected,
            WalletBalanceFrom: this.state.walletBalanceFrom,
            WalletBalanceTo: this.state.walletBalanceTo,
            OutStandingAmtSelected: this.state.outStandingAmtSelected,
            OutStandingAmtFrom: this.state.outStandingAmtFrom,
            OutStandingAmtTo: this.state.outStandingAmtTo,
            SMSTemplateID: this.state.smsTemplateID,
            OfferID: this.state.offerID,
            SpecificStoreSelected: this.state.specificStoreSelected,
            AllStoresSelected: this.state.allStoresSelected,
            Stores: this.state.allStoresSelected ? this.state.stores : this.state.associatedStores,
            AllCustomersSelected: this.state.allCustomersSelected,
            SpecificCustomerSelected: this.state.specificCustomerSelected,
            IsDeleted: false,
        }
        //console.log("Save Campaign", campaign)

        this.props.saveCampaign(campaign, this.state.key).then(() => {
            setTimeout(function () { //Start the timer
                this.props.getToastr("Campaign saved successfully")
                this.setState({ saving: false })
                this.props.onSave();
            }.bind(this), 3000)
        })
    }

    onMembershipChange(value) {
        this.setState({ membershipError: "" });
        if (value != "") {
            let membership = {
                MembershipID: value,
                Name:  this.state.membershipList.filter(f=> f.key === value).length > 0 ?  this.state.membershipList.filter(f=> f.key === value)[0].Name : ""
            }
            this.setState({ membership: membership })
        }
        else
            this.setState({ membership: null });
    }

    loadMembership() {
        this.setState({ membershipList: this.props.config.ActiveMemberships });
        return;
    }

    loadOffers() {
        let offers = [], coupons = []
        this.props.config.ApplicableOffers.map(m => {
            if (m.hasOwnProperty('Type') && m.Type === "Coupon") {
                coupons.push(m)
            }
            else {
                offers.push(m)
            }
        })
        this.setState({ offerList: offers, couponList: coupons });
        return;
    }


    handleOnChange(storeList, e) {
        this.setState({ associatedStores: storeList });
    }

    async onFetchCustomers() {
        let stores = [];
        // console.log("all stores",  this.state.stores);
        // console.log("associated store", this.state.associatedStores);
        if (this.state.allStoresSelected) {
            this.state.stores.map((store) => {
                stores.push(store.value);
            });
        }
        else {
            this.state.associatedStores.map((store) => {
                stores.push(store.value);
            });
        }
        // console.log("stores", JSON.stringify(stores));

        //console.log("lastVisitedStartDate",lastVisitedStartDate);
        //console.log("lastVisitedEndDate", lastVisitedEndDate); 

        let customers = await this.props.getCustomersForCampaign(0, 10000, stores, this.state.customerVisited, this.state.customerInDays, this.state.membership ? this.state.membership.Name : '', this.state.customerGender, this.state.walletBalanceFrom, this.state.walletBalanceTo, this.state.outStandingAmtFrom, this.state.outStandingAmtTo)
            .then((result) => {
                return result.searchResult;
                //this.setState({ customers: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
            });
        // console.log("customers", customers);

        let nonVisitedCustomers = [];
        if (this.state.customerVisited === "Not Visited") {
            nonVisitedCustomers = await this.props.getNonVisitedCustomersForCampaign(0, 10000, stores, this.state.customerVisited, this.state.customerInDays, this.state.membership ? this.state.membership.Name : '', this.state.customerGender, this.state.walletBalanceFrom, this.state.walletBalanceTo, this.state.outStandingAmtFrom, this.state.outStandingAmtTo)
                .then((result) => {
                    return result.searchResult;
                });
        }

        let salesCustomers = [];
        if (this.state.purchaseAmountSelected && this.state.purchaseAmountFrom !== "" && this.state.purchaseAmountTo != "") {
            let searchCustomers = [];

            for (let i = 0; i < customers.length; i++) {
                const customer = customers[i];
                searchCustomers.push(customer.key);
            }

            if(nonVisitedCustomers && nonVisitedCustomers.length > 0)
            {
                for (let i = 0; i < nonVisitedCustomers.length; i++) {
                    const nonVisitedCustomer = nonVisitedCustomers[i];
                    searchCustomers.push(nonVisitedCustomer.key);
                }
            }

            salesCustomers = await this.props.getSalesCustomersForCampaign(searchCustomers, this.state.purchaseAmountFrom, this.state.purchaseAmountTo)
                .then((result) => {
                    return result.searchResult;
                });
        }
        //console.log("salesCustomers", salesCustomers);

        let finalCustomers = [];
        if (customers && customers.length > 0 && this.state.purchaseAmountSelected) {

            if (salesCustomers && salesCustomers.length > 0) {
                // console.log("Called");
                for (let i = 0; i < customers.length; i++) {
                    let data = customers[i];
                    let obj = salesCustomers.filter((f) => f.key === data.key);
                    if (obj.length > 0) {
                        let customer = {
                            "Customer Code": data.CustomerCode,
                            "First Name": data.FirstName,
                            "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                            "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                            "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                            "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                            "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                            "OutStanding Amount": !isNullOrUndefined(data.OutstandingAmount) ? (data.OutstandingAmount) : 0,
                            "Wallet Balance": !isNullOrUndefined(data.WalletBalance) ? (data.WalletBalance) : 0,
                            "Last Visited On": data.hasOwnProperty('LastVisitedOn') ? (new Date(data.LastVisitedOn).toISOString().split('T')[0]) : '',
                            "Total Purchases": salesCustomers.filter((f) => f.key === data.key)[0].total_sales
                        }
                        //console.log("customer", customer);
                        finalCustomers.push(customer);
                    }
                }

                
                if (nonVisitedCustomers && nonVisitedCustomers.length > 0) {
                    for (let i = 0; i < nonVisitedCustomers.length; i++) {
                        let data = nonVisitedCustomers[i];
                        let obj = salesCustomers.filter((f) => f.key === data.key);
                        if (obj.length > 0) {
                            let customer = {
                                "Customer Code": data.CustomerCode,
                                "First Name": data.FirstName,
                                "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                                "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                                "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                                "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                                "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                                "OutStanding Amount": !isNullOrUndefined(data.OutstandingAmount) ? (data.OutstandingAmount) : 0,
                                "Wallet Balance": !isNullOrUndefined(data.WalletBalance) ? (data.WalletBalance) : 0,
                                "Last Visited On": data.hasOwnProperty('LastVisitedOn') ? (new Date(data.LastVisitedOn).toISOString().split('T')[0]) : '',
                                "Total Purchases": salesCustomers.filter((f) => f.key === data.key)[0].total_sales
                            }
                            //console.log("customer", customer);
                            finalCustomers.push(customer);
                        }
                    }
                }
            }

        }
        else {
            for (let i = 0; i < customers.length; i++) {
                let data = customers[i];
                let customer = {
                    "Customer Code": data.CustomerCode,
                    "First Name": data.FirstName,
                    "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                    "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                    "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                    "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                    "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                    "OutStanding Amount": data.hasOwnProperty('OutStandingAmount') ? (data.OutStandingAmount) : 0,
                    "Wallet Balance": !isNullOrUndefined(data.WalletBalance) ? (data.WalletBalance) : 0,
                    "Last Visited On": data.hasOwnProperty('LastVisitedOn') ? (new Date(data.LastVisitedOn).toISOString().split('T')[0]) : '',
                }
                finalCustomers.push(customer);
            }
            if (nonVisitedCustomers && nonVisitedCustomers.length > 0) {
                for (let i = 0; i < nonVisitedCustomers.length; i++) {
                    let data = nonVisitedCustomers[i];
                    let nonVisitedCustomer = {
                        "Customer Code": data.CustomerCode,
                        "First Name": data.FirstName,
                        "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                        "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                        "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                        "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                        "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                        "OutStanding Amount": data.hasOwnProperty('OutStandingAmount') ? (data.OutStandingAmount) : 0,
                        "Wallet Balance": !isNullOrUndefined(data.WalletBalance) ? (data.WalletBalance) : 0,
                        "Last Visited On": data.hasOwnProperty('LastVisitedOn') ? (new Date(data.LastVisitedOn).toISOString().split('T')[0]) : '',
                    }
                    finalCustomers.push(nonVisitedCustomer);
                }
            }
        }
        if (finalCustomers.length > 0) {
            const csvData = constatnts.objToCSV(finalCustomers);
            this.setState({ loading: false, exportCalled: false })
            const csvBlob = new Blob([csvData], { type: "text/csv" });
            const url = window.URL.createObjectURL(csvBlob);
            const a = document.createElement("a");
            a.setAttribute("hidden", "");
            a.setAttribute("href", url);
            a.setAttribute("download", "CampaignCustomers.csv");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else {
            this.props.getToastr("No customers found!!!")
        }

    }

    render() {
        return <Form
            title="Campaign"
            onClick={(action)=>{
                if(action==="save"){
                    this.onSaveCampaign();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
            <Input
                type="text"
                label="Campaign Name"
                onChange={(value) => this.setState({ name:value, nameError: "" })}
                value={this.state.name}
                error={this.state.nameError}
                onEnter={this.onSaveCampaign}
            />
            {/* <Input
                type="select"
                value={this.state.type}
                onChange={(value)=>{
                    if (value !== "") {
                        this.setState({ typeError: "", startDateError: "", slotError: "", startDate: "", slot: 0 })
                    }
                    this.setState({ type: value, typeError: "" });
                }}
                error={this.state.typeError}
            >
                    <option value="">Select Campaign Type</option>
                    <option value="One Time">One Time</option>
                    <option value="Specific Day">Specific Day</option>
                    <option value="Recurring">Recurring</option> 
            </Input> */}
            {this.state.type !== "" && <React.Fragment>
                <Input  
                    type="date"
                    label="Select Date On Which Campaign Will Run"
                    onChange={(value) => this.setState({ startDate: value, startDateError: "" })}
                    value={this.state.startDate}
                    error = {this.state.startDateError}
                />
                <Input
                    type="select"
                    label="Select Time Slot"
                    value={this.state.slot}
                    onChange={(value) => {
                        this.setState({ slot: value, slotError: "" });
                    }}
                    error={this.state.slotError}
                >
                    <option value="0">Select time slot</option>
                    {this.state.slots.map((slot) =>
                        <option value={slot.value}>{slot.name}</option>
                    )}
                </Input>
            </React.Fragment>}
            {this.state.stores.length > 0 && <React.Fragment>
                <Input
                    type="switch"
                    label="Run Campaign For Specific Stores"
                    value={this.state.specificStoreSelected}
                    onChange={(value)=>this.setState({specificStoreSelected:value, 
                        allStoresSelected:!value, 
                        associatedStores: [],
                        optionError:"" })}
                    error={this.state.optionError}
                />
                {this.state.specificStoreSelected && <Input
                    type="multiselect"
                    label="Please Select Store(s)"
                    value={this.state.associatedStores}
                    options={this.state.stores}
                    onChange={(value)=>this.handleOnChange(value)}
                />}
             </React.Fragment>}
            <FormSection title="Filter Customer">
                <Input  
                    type="switch"
                    label="Filter By Customer Visit Frequency"
                    value={this.state.customerSelected}
                    onChange={(value)=>{this.setState({ customerSelected: value, customerInDays:"", customerInDaysError: "", customerVisited:"", customerVisitedError: "" })}}
                />
                {this.state.customerSelected &&  <div className="left w100">
                    <Input
                        type="select"
                        value={this.state.customerVisited}
                        onChange={(value) => this.setState({ customerVisited:value, customerVisitedError: "" })}
                        error={this.state.customerVisitedError}
                    >
                        <option value="">Select</option>
                        <option value="Visited">Visited</option>
                        <option value="Not Visited">Not Visited</option>
                    </Input>
                    <div> In</div>
                    <Input
                        type="number"
                        onChange={(value) => this.setState({ customerInDays:value, customerInDaysError: "" })}
                        value={this.state.customerInDays} 
                        error={this.state.customerInDaysError}
                    />
                    <div> Days</div>
                </div>}
                <Input  
                    type="switch"
                    label="Filter By Customer Purchase Amount"
                    value={this.state.purchaseAmountSelected}
                    onChange={(value)=>{this.setState({ purchaseAmountSelected: value, purchaseAmountFrom:"", purchaseAmountTo: "", purchaseAmountFromError: "", purchaseAmountToError: "" })}}
                />
                {this.state.purchaseAmountSelected &&  <div className="left w100">
                    <Input
                        type="number"
                        label="Purchase Amount From"
                        onChange={(value) => this.setState({ purchaseAmountFrom: value, purchaseAmountFromError: "", purchaseAmountToError: "" })}
                        value={this.state.purchaseAmountFrom} 
                        error={this.state.purchaseAmountFromError}
                    />
                    <Input
                        type="number"
                        label="Purchase Amount To"
                        onChange={(value) => this.setState({ purchaseAmountTo: value, purchaseAmountFromError: "", purchaseAmountToError: "" })}
                        value={this.state.purchaseAmountTo} 
                        error={this.state.purchaseAmountToError}
                    />
                </div>}
                <Input  
                    type="switch"
                    label="Filter By Customer Membership"
                    value={this.state.membershipSelected}
                    onChange={(value)=>{this.setState({ membershipSelected: value, membership: null, membershipError: "" })}}
                />
                {this.state.membershipSelected &&  <Input
                    type="select"
                    label="Please Select Membership"
                    value={this.state.membership ? this.state.membership.MembershipID : ""}
                    onChange={this.onMembershipChange}
                    error={this.state.membershipError}
                >
                    <option value="">Select</option>
                    {this.state.membershipList.map((membership) =>
                        <option value={membership.key}>{membership.Name}</option>
                    )}
                </Input>}
                <Input  
                    type="switch"
                    label="Filter By Customer Gender"
                    value={this.state.customerGenderSelected}
                    onChange={(value)=>{this.setState({ customerGenderSelected: value, customerGenderError: "", customerGender: "" })}}
                />
                {this.state.customerGenderSelected &&  <div className="left w100">
                    <Input
                        type="select"
                        label="Please Select Gender"
                        value={this.state.customerGender}
                        onChange={(value) => this.setState({ customerGender:value, customerGenderError: "" })}
                        error={this.state.customerGenderError}
                    >
                        <option value="">Select</option>
                        <option value="Male" >Male</option>
                        <option value="Female">Female</option>
                    </Input>
                </div>}
                <Input  
                    type="switch"
                    label="Filter By Customer Wallet Amount"
                    value={this.state.walletBalanceSelected}
                    onChange={(value)=>{this.setState({ walletBalanceSelected: value, walletBalanceFromError: "", walletBalanceToError: "", walletBalanceFrom: "", walletBalanceTo: ""})}}
                />
                {this.state.walletBalanceSelected &&  <div className="left w100">
                    <Input
                        type="number"
                        label="Wallet Amount From"
                        onChange={(value) => this.setState({ walletBalanceFrom: value, walletBalanceFromError: ""})}
                        value={this.state.walletBalanceFrom} 
                        error={this.state.walletBalanceFromError}
                    />
                    <Input
                        type="number"
                        label="Wallet Amount To"
                        onChange={(value) => this.setState({ walletBalanceTo: value, walletBalanceToError: "" })}
                        value={this.state.walletBalanceTo} 
                        error={this.state.walletBalanceToError}
                    />
                </div>}
                <Input  
                    type="switch"
                    label="Filter By Customer Outstanding Amount"
                    value={this.state.outStandingAmtSelected}
                    onChange={(value)=>{this.setState({ outStandingAmtSelected: value, outStandingAmtFromError: "", outStandingAmtToError: "", outStandingAmtFrom: "", outStandingAmtTo: "" })}}
                />
                {this.state.outStandingAmtSelected &&  <div className="left w100">
                    <Input
                        type="number"
                        label="Outstanding Amount From"
                        onChange={(value) => this.setState({ outStandingAmtFrom: value, outStandingAmtFromError: ""})}
                        value={this.state.outStandingAmtFrom} 
                        error={this.state.outStandingAmtFromError}
                    />
                    <Input
                        type="number"
                        label="Outstanding Amount To"
                        onChange={(value) => this.setState({ outStandingAmtTo: value, outStandingAmtToError: "" })}
                        value={this.state.outStandingAmtTo} 
                        error={this.state.outStandingAmtToError}
                    />
                </div>}
                <Button type="secondary" text="Fetch Customers" onClick={this.onFetchCustomers}/>
            </FormSection>
            <FormSection title="Campaign Message">
                <Input
                    type="select"
                    label="Please Select Message"
                    value={this.state.smsTemplateID}
                    onChange={(value) => {
                        let smsTemplate = this.state.smsTemplates.filter((s) => s.key === value).length > 0 ? this.state.smsTemplates.filter((s) => s.key === value)[0].SMSTemplate : ''
                        if (smsTemplate != "" && this.state.offerID !== "") {
                            let offer = this.state.offerList.filter((o) => o.key === this.state.offerID).length > 0 ? this.state.offerList.filter((o) => o.key === this.state.offerID)[0] : null
                            if (offer) {
                                smsTemplate = smsTemplate.replace('<offer>', offer.Name);
                            }
                            else {
                                let coupon = this.state.couponList.filter((o) => o.key === this.state.offerID).length > 0 ? this.state.couponList.filter((o) => o.key === this.state.offerID)[0] : null
                                if (coupon) {
                                    smsTemplate = smsTemplate.replace('<coupon>', coupon.Name);
                                }
                            }
                        }
                        this.setState({ smsTemplateID: value, smsTemplateError: "", smsTemplate: smsTemplate });
                    }}
                    error={this.state.smsTemplateError}
                    >
                    <option value="">Select template</option>
                    {this.state.smsTemplates.map((temmplate) =>
                        <option value={temmplate.key}>{temmplate.Name}</option>
                    )}
                </Input>
                {this.state.smsTemplateID !== "" && <React.Fragment>
                    <Input
                        type="textarea"
                        label="Message Preview"
                        value={this.state.smsTemplate}
                    />
                    {(this.state.smsTemplate && this.state.smsTemplate.hasOwnProperty('Image') &&  this.state.smsTemplate.Image) &&  <div className="col-md-12">
                        <div className="form-group ">
                                <label >Image</label>
                                <img src={this.state.image.Preview}/>
                            </div>
                    </div>}
                </React.Fragment>}
            </FormSection>
            <FormSection title="Associate Offers / Coupons">
                <Input
                    type="select"
                    label="Please Select Offers / Coupons"
                    value={this.state.offerID}
                    onChange={(value) => {
                        let smsTemplate = this.state.smsTemplates.filter((s) => s.key === this.state.smsTemplateID).length > 0 ? this.state.smsTemplates.filter((s) => s.key === this.state.smsTemplateID)[0].SMSTemplate : ''
                        if (smsTemplate != "" && value !== "") {
                            let offer = this.state.offerList.filter((o) => o.key === value).length > 0 ? this.state.offerList.filter((o) => o.key === value)[0] : null
                            // console.log("offer", offer);
                            if (offer) {
                                smsTemplate = smsTemplate.replace('<offer>', offer.Name);
                            }
                            else {
                                let coupon = this.state.couponList.filter((o) => o.key === value).length > 0 ? this.state.couponList.filter((o) => o.key === value)[0] : null
                                // console.log("coupon", coupon);
                                if (coupon) {
                                    smsTemplate = smsTemplate.replace('<coupon>', coupon.Name);
                                }
                            }
                        }
                        this.setState({ offerID: value, smsTemplate: smsTemplate });
                    }
                }>
                    <option value="">Select Offers / Coupon</option>
                    <optgroup label="Coupons">
                        {this.state.couponList.map((e) =>
                            <option value={e.key}>{e.Name}</option>
                        )}
                    </optgroup>
                    <optgroup label="Offers">
                        {this.state.offerList.map((e) =>
                            <option value={e.key}>{e.Name}</option>
                        )}
                    </optgroup>
                </Input>
            </FormSection>
        </Form>
    }
}

export default addCampaign


// render() {
//     return <div className="content-wrapper">
//         <section className="content-header">
//             <h1>Campaign</h1>
//             <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
//                 onClick={this.onSaveCampaign} style={{ marginTop: "-25px", marginLeft: "10px" }} disabled={this.state.saving}>Save</button>
//             <Link to="/home/campaign" type="submit" className="btn btn-flat btn-md btn-default pull-right" style={{ marginTop: "-25px" }}>Cancel</Link>
//         </section>
//         <section className="content">
//             <div className="row">
//                 <div className="col-md-6">
//                     <div className="box">
//                         <div className="box-body">
//                             <div className="row">
//                                 <div className="col-md-6">
//                                     <div className={this.state.nameError !== "" ? "form-group has-error" : "form-group "}>
//                                         <label>Name</label>
//                                         <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
//                                             onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })}
//                                             value={this.state.name} />
//                                         {this.state.nameError !== "" && <span className="help-block">{this.state.nameError}</span>}
//                                     </div>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <div className={this.state.typeError !== "" ? "form-group has-error" : "form-group "}>
//                                         <label >Type</label>
//                                         <select className="form-control" value={this.state.type}
//                                             onChange={this.handleOnTypeChange}>
//                                             <option value="">Select Campaign Type</option>
//                                             <option value="One Time">One Time</option>
//                                             {/* <option value="Specific Day">Specific Day</option>
//                                             <option value="Recurring">Recurring</option> */}
//                                         </select>
//                                         {this.state.typeError !== "" && <span className="help-block">{this.state.typeError}</span>}
//                                     </div>
//                                 </div>
//                             </div>
//                             {this.state.type !== "" &&
//                                 <div className="row">
//                                     <div className="col-md-6">
//                                         <div className={this.state.startDateError !== "" ? "form-group has-error" : "form-group "}>
//                                             <label >Select Date</label>
//                                             <input type="date" className="form-control"
//                                                 onChange={(evt) => this.setState({ startDate: evt.target.value, startDateError: "" })}
//                                                 value={this.state.startDate}
//                                             />
//                                             {this.state.startDateError !== "" && <span className="help-block">{this.state.startDateError}</span>}
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <div className={this.state.slotError !== "" ? "form-group has-error" : "form-group "}>
//                                             <label >Select Slot</label>
//                                             <select className="form-control"
//                                                 value={this.state.slot}
//                                                 onChange={(evt) => {
//                                                     this.setState({ slot: evt.target.value, slotError: '' });
//                                                 }
//                                                 }>
//                                                 <option value="0">Select slot</option>
//                                                 {this.state.slots.map((slot) =>
//                                                     <option value={slot.value}>{slot.name}</option>
//                                                 )}
//                                             </select>
//                                             {this.state.slotError !== "" && <span className="help-block">{this.state.slotError}</span>}
//                                         </div>
//                                     </div>
//                                 </div>}
//                             <div className="row">
//                                 <div className={this.state.optionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
//                                     <input type="radio" name="stores" value={this.state.allStoresSelected} checked={this.state.allStoresSelected}
//                                         onChange={() => {
//                                             this.setState({ specificStoreSelected: false, allStoresSelected: true, associatedStores: [], optionError: '' })
//                                         }} />
//                                     &nbsp;&nbsp;<label><strong>All Stores</strong></label>
//                                     {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
//                                 </div>
//                                 <div className={this.state.optionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
//                                     <input type="radio" name="stores" value={this.state.specificStoreSelected} checked={this.state.specificStoreSelected}
//                                         onChange={() => { this.setState({ specificStoreSelected: true, allStoresSelected: false, associatedStores: [], optionError: '' }) }} />
//                                     &nbsp;&nbsp;<label><strong>Specific Stores</strong></label>
//                                     {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
//                                 </div>
//                             </div>
//                             {this.state.specificStoreSelected &&
//                                 <div className="row">
//                                     <div className="col-md-12">
//                                         <div className={this.state.storeNameError !== "" ? "form-group has-error" : "form-group "}>
//                                             <CreatableSelect
//                                                 defaultValue={this.state.associatedStores}
//                                                 options={this.state.stores}
//                                                 onChange={this.handleOnChange}
//                                                 isMulti
//                                             />
//                                             {this.state.storeNameError !== "" && <span className="help-block">{this.state.storeNameError}</span>}
//                                         </div>
//                                     </div>
//                                 </div>}
//                             <div className="row">
//                                 <div className={this.state.customerOptionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
//                                     <input type="radio" name="customers" value={this.state.allCustomersSelected} checked={this.state.allCustomersSelected}
//                                         onChange={() => { this.setState({ specificCustomerSelected: false, allCustomersSelected: true, customerOptionError: '' }) }} />
//                                     &nbsp;&nbsp;<label><strong>All Customers</strong></label>
//                                     {this.state.customerOptionError !== "" && <span className="help-block">{this.state.customerOptionError}</span>}
//                                 </div>
//                                 <div className={this.state.customerOptionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
//                                     <input type="radio" name="customers" value={this.state.specificCustomerSelected} checked={this.state.specificCustomerSelected}
//                                         onChange={() => { this.setState({ specificCustomerSelected: true, allCustomersSelected: false, customerOptionError: '' }) }} />
//                                     &nbsp;&nbsp;<label><strong>Specific Customers</strong></label>
//                                     {this.state.customerOptionError !== "" && <span className="help-block">{this.state.customerOptionError}</span>}
//                                 </div>
//                             </div>
//                             {this.state.specificCustomerSelected &&
//                                 <React.Fragment>
//                                     <div className="row">
//                                         <div className="col-md-4">
//                                             <input type="checkbox" checked={this.state.customerSelected}
//                                                 onChange={() => { this.setState({ customerSelected: !this.state.customerSelected, customerInDays: '', customerInDaysError: "", customerVisited:'', customerVisitedError: '' }) }} />
//                                             &nbsp;&nbsp;<label><strong>Customer</strong></label>
//                                         </div>
//                                         <div className="col-md-3">
//                                             <div className={this.state.customerVisitedError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <select className="form-control" value={this.state.customerVisited}
//                                                     onChange={(evt) => this.setState({ customerVisited: evt.target.value, customerVisitedError: "" })}
//                                                     disabled={!this.state.customerSelected}>
//                                                     <option value="">Select</option>
//                                                     <option >Visited</option>
//                                                     <option >Not Visited</option>
//                                                 </select>
//                                                 {this.state.customerVisitedError !== "" && <span className="help-block">{this.state.customerVisitedError}</span>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-1">
//                                             <div className="form-group">
//                                                 <label style={{ padding: "6px 2px" }}>In</label> 
//                                             </div>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.customerInDaysError !== "" ? "form-group has-error" : "form-group "}>
//                                                  <input type="number" onKeyPress={this.handleKeyPress} style={{ width: "100px", lineHeight: "1.42857143", height: "34px", fontSize: "14px", padding: "6px 12px" }}
//                                                     onChange={(evt) => this.setState({ customerInDays: evt.target.value, customerInDaysError: "" })}
//                                                     value={this.state.customerInDays} disabled={!this.state.customerSelected}
//                                                 />&nbsp;&nbsp;Day(s)
//                                         {this.state.customerInDaysError !== "" && <span className="help-block">{this.state.customerInDaysError}</span>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-4">
//                                             <input type="checkbox" checked={this.state.purchaseAmountSelected}
//                                                 onChange={() => { this.setState({ purchaseAmountSelected: !this.state.purchaseAmountSelected, purchaseAmountFrom: '', purchaseAmountTo: '', purchaseAmountFromError: '', purchaseAmountToError: '' }) }} />
//                                             &nbsp;&nbsp;<label><strong>Purchase Amount</strong></label>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.purchaseAmountFromError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="From"
//                                                     onChange={(evt) => this.setState({ purchaseAmountFrom: evt.target.value, purchaseAmountFromError: "", purchaseAmountToError: "" })}
//                                                     value={this.state.purchaseAmountFrom} disabled={!this.state.purchaseAmountSelected}
//                                                 />
//                                                 {this.state.purchaseAmountFromError !== "" && <span className="help-block">{this.state.purchaseAmountFromError}</span>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.purchaseAmountToError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <input type="text" onKeyPress={this.handleKeyPress} className="form-control" placeholder="To"
//                                                     onChange={(evt) => this.setState({ purchaseAmountTo: evt.target.value, purchaseAmountToError: "" })}
//                                                     value={this.state.purchaseAmountTo} disabled={!this.state.purchaseAmountSelected}
//                                                 />
//                                                 {this.state.purchaseAmountToError !== "" && <span className="help-block">{this.state.purchaseAmountToError}</span>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-4">
//                                             <input type="checkbox" checked={this.state.membershipSelected}
//                                                 onChange={() => { this.setState({ membershipSelected: !this.state.membershipSelected, membership: null, membershipError: "" }) }} />
//                                             &nbsp;&nbsp;<label><strong>Membership</strong></label>
//                                         </div>
//                                         <div className="col-md-8">
//                                             <div className={this.state.membershipError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <select className="form-control" value={this.state.membership ? this.state.membership.MembershipID : null}
//                                                     onChange={this.onMembershipChange}
//                                                     disabled={!this.state.membershipSelected}>
//                                                     <option value="">Select Membership</option>
//                                                     {this.state.membershipList.map((membership) =>
//                                                         <option value={membership.key}>{membership.Name}</option>
//                                                     )}
//                                                 </select>
//                                                 {this.state.membershipError !== "" && <span className="help-block">{this.state.membershipError}</span>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-4">
//                                             <input type="checkbox" checked={this.state.customerGenderSelected}
//                                                 onChange={() => { this.setState({ customerGenderSelected: !this.state.customerGenderSelected, customerGenderError: "", customerGender: '' }) }} />
//                                             &nbsp;&nbsp;<label><strong>Customer Gender</strong></label>
//                                         </div>
//                                         <div className="col-md-8">
//                                             <div className={this.state.customerGenderError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <select className="form-control" value={this.state.customerGender}
//                                                     onChange={(evt) => this.setState({ customerGender: evt.target.value, customerGenderError: "" })}
//                                                     disabled={!this.state.customerGenderSelected}
//                                                 >
//                                                     <option >Select</option>
//                                                     <option >Male</option>
//                                                     <option >Female</option>
//                                                 </select>
//                                                 {this.state.customerGenderError !== "" && <span className="help-block">{this.state.customerGenderError}</span>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-4">
//                                             <input type="checkbox" checked={this.state.walletBalanceSelected}
//                                                 onChange={() => { this.setState({ walletBalanceSelected: !this.state.walletBalanceSelected, walletBalanceFromError: "", walletBalanceToError: "", walletBalanceFrom: '', walletBalanceTo: '' }) }} />
//                                             &nbsp;&nbsp;<label><strong>Wallet Balance</strong></label>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.walletBalanceFromError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="From"
//                                                     onChange={(evt) => this.setState({ walletBalanceFrom: evt.target.value, walletBalanceFromError: "" })}
//                                                     value={this.state.walletBalanceFrom} disabled={!this.state.walletBalanceSelected}
//                                                 />
//                                                 {this.state.walletBalanceFromError !== "" && <span className="help-block">{this.state.walletBalanceFromError}</span>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.walletBalanceToError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="To"
//                                                     onChange={(evt) => this.setState({ walletBalanceTo: evt.target.value, walletBalanceToError: "" })}
//                                                     value={this.state.walletBalanceTo} disabled={!this.state.walletBalanceSelected}
//                                                 />
//                                                 {this.state.walletBalanceToError !== "" && <span className="help-block">{this.state.walletBalanceToError}</span>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-4">
//                                             <input type="checkbox" checked={this.state.outStandingAmtSelected}
//                                                 onChange={() => { this.setState({ outStandingAmtSelected: !this.state.outStandingAmtSelected, outStandingAmtFromError: "", outStandingAmtToError: "", outStandingAmtFrom: '', outStandingAmtTo: '' }) }} />
//                                             &nbsp;&nbsp;<label><strong>Outstanding Amount</strong></label>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.outStandingAmtFromError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="From"
//                                                     onChange={(evt) => this.setState({ outStandingAmtFrom: evt.target.value, outStandingAmtFromError: "" })}
//                                                     value={this.state.outStandingAmtFrom} disabled={!this.state.outStandingAmtSelected}
//                                                 />
//                                                 {this.state.outStandingAmtFromError !== "" && <span className="help-block">{this.state.outStandingAmtFromError}</span>}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-4">
//                                             <div className={this.state.outStandingAmtToError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="To"
//                                                     onChange={(evt) => this.setState({ outStandingAmtTo: evt.target.value, outStandingAmtToError: "" })}
//                                                     value={this.state.outStandingAmtTo} disabled={!this.state.outStandingAmtSelected}
//                                                 />
//                                                 {this.state.outStandingAmtToError !== "" && <span className="help-block">{this.state.outStandingAmtToError}</span>}
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-12">
//                                             <button type="submit" className="btn btn-flat btn-md btn-primary pull-right"
//                                                 onClick={this.onFetchCustomers} style={{ marginLeft: "10px" }}>Fetch Customers</button>
//                                         </div>
//                                     </div>
//                                 </React.Fragment>}
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-md-6">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="box">
//                                 <div className="box-header with-border">
//                                     <h3 className="box-title">Promotional SMS</h3>
//                                 </div>
//                                 <div className="box-body">
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <div className={this.state.smsTemplateError !== "" ? "form-group has-error" : "form-group "}>
//                                                 <label >Message Template</label>
//                                                 <select className="form-control"
//                                                     value={this.state.smsTemplateID}
//                                                     onChange={(evt) => {
//                                                         let smsTemplate = this.state.smsTemplates.filter((s) => s.key === evt.target.value).length > 0 ? this.state.smsTemplates.filter((s) => s.key === evt.target.value)[0].SMSTemplate : ''
//                                                         if (smsTemplate != "" && this.state.offerID !== "") {
//                                                             let offer = this.state.offerList.filter((o) => o.key === this.state.offerID).length > 0 ? this.state.offerList.filter((o) => o.key === this.state.offerID)[0] : null
//                                                             if (offer) {
//                                                                 smsTemplate = smsTemplate.replace('<offer>', offer.Name);
//                                                             }
//                                                             else {
//                                                                 let coupon = this.state.couponList.filter((o) => o.key === this.state.offerID).length > 0 ? this.state.couponList.filter((o) => o.key === this.state.offerID)[0] : null
//                                                                 if (coupon) {
//                                                                     smsTemplate = smsTemplate.replace('<coupon>', coupon.Name);
//                                                                 }
//                                                             }
//                                                         }
//                                                         this.setState({ smsTemplateID: evt.target.value, smsTemplateError: '', smsTemplate: smsTemplate });
//                                                     }}>
//                                                     <option value="">Select template</option>
//                                                     {this.state.smsTemplates.map((temmplate) =>
//                                                         <option value={temmplate.key}>{temmplate.Name}</option>
//                                                     )}
//                                                 </select>
//                                                 {this.state.smsTemplateError !== "" && <span className="help-block">{this.state.smsTemplateError}</span>}
//                                             </div>
//                                         </div>
//                                     </div><br />
//                                     {this.state.smsTemplate !== "" && <div className="row">
//                                         <div className="col-md-6">
//                                             <div className="form-group ">
//                                                 <label >Preview</label>
//                                                 <textarea className="form-control" disabled
//                                                     style={{ height: "200px", width: "278px" }}
//                                                     value={this.state.smsTemplate}
//                                                 ></textarea>
//                                             </div>
//                                         </div>
//                                         {(this.state.smsTemplate && this.state.smsTemplate.hasOwnProperty('Image') &&  this.state.smsTemplate.Image) &&  <div className="col-md-6">
//                                             <div className="form-group ">
//                                                     <label >Image</label>
//                                                     <img src={this.state.image.Preview}/>
//                                                 </div>
//                                         </div>}
//                                         {/* <br /> */}
//                                     </div>}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="box">
//                                 <div className="box-header with-border">
//                                     <h3 className="box-title">Associate Offers / Coupons</h3>
//                                 </div>
//                                 <div className="box-body">
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <div className="form-group ">
//                                                 <label >Offers / Coupons</label>
//                                                 <select className="form-control"
//                                                     value={this.state.offerID}
//                                                     onChange={(evt) => {
//                                                         let smsTemplate = this.state.smsTemplates.filter((s) => s.key === this.state.smsTemplateID).length > 0 ? this.state.smsTemplates.filter((s) => s.key === this.state.smsTemplateID)[0].SMSTemplate : ''
//                                                         // console.log("smsTemplate", smsTemplate);
//                                                         if (smsTemplate != "" && evt.target.value !== "") {
//                                                             let offer = this.state.offerList.filter((o) => o.key === evt.target.value).length > 0 ? this.state.offerList.filter((o) => o.key === evt.target.value)[0] : null
//                                                             // console.log("offer", offer);
//                                                             if (offer) {
//                                                                 smsTemplate = smsTemplate.replace('<offer>', offer.Name);
//                                                             }
//                                                             else {
//                                                                 let coupon = this.state.couponList.filter((o) => o.key === evt.target.value).length > 0 ? this.state.couponList.filter((o) => o.key === evt.target.value)[0] : null
//                                                                 // console.log("coupon", coupon);
//                                                                 if (coupon) {
//                                                                     smsTemplate = smsTemplate.replace('<coupon>', coupon.Name);
//                                                                 }
//                                                             }
//                                                         }
//                                                         this.setState({ offerID: evt.target.value, smsTemplate: smsTemplate });
//                                                     }
//                                                     }>
//                                                     <option value="">Select Offers / Coupon</option>
//                                                     <optgroup label="Coupons">
//                                                         {this.state.couponList.map((e) =>
//                                                             <option value={e.key}>{e.Name}</option>
//                                                         )}
//                                                     </optgroup>
//                                                     <optgroup label="Offers">
//                                                         {this.state.offerList.map((e) =>
//                                                             <option value={e.key}>{e.Name}</option>
//                                                         )}
//                                                     </optgroup>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                     </div><br />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
//                 onClick={this.onSaveCampaign} style={{ marginLeft: "10px" }} disabled={this.state.saving}>Save</button>
//             <Link to="/home/campaign" type="submit" className="btn btn-flat btn-md btn-default pull-right">Cancel</Link>
//         </section>
//     </div>
// }