import React, { Component } from 'react';
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import ImageTemplate from '../Common/ImageTemplate'
import Footer from '../Common/Footer'
import * as htmlToImage from 'html-to-image';

class EditImageTemplate extends Component {

    constructor()
    {
        super()
        this.state = {
            name:'',
            smsTemplate:'',
            nameError:'',
            smsTemplateError:'',
            backgroundColor: 'white',
            color: 'black',
            image: null,
            imageURL: '',
            key: '',
            imageTitle: '',
            imageSubTitle: '',
        }
        this.onImageRemove = this.onImageRemove.bind(this)
        this.onImageDrop = this.onImageDrop.bind(this)
        this.onTitleTextChange = this.onTitleTextChange.bind(this) 
        this.onSubTitleTextChange = this.onSubTitleTextChange.bind(this) 
        this.onHeaderBackClick = this.onHeaderBackClick.bind(this) 

        // this.onColorSelection = this.onColorSelection.bind(this)
        // this.onBackgroundColorSelection = this.onBackgroundColorSelection.bind(this)
        this.onSaveImageTemplate = this.onSaveImageTemplate.bind(this)
    }

    async componentDidMount()
    {
        if(this.props.location.state.messageTemplate)
        {
            let messageTemplate = this.props.location.state.messageTemplate;

            this.setState({ key: messageTemplate.key ,
                imageTitle: messageTemplate.hasOwnProperty('Title') ? messageTemplate.Title : '',
                imageSubTitle:  messageTemplate.hasOwnProperty('SubTitle') ? messageTemplate.SubTitle : '',
                image: messageTemplate.hasOwnProperty('Image') ?  messageTemplate.Image : null,
                name: messageTemplate.Name,
                smsTemplate: messageTemplate.SMSTemplate
            })
        }
    }

    // onColorSelection = (color) => {
    //     this.setState({ color: color.hex });
    // };

    // onBackgroundColorSelection =  (color) => {
    //     this.setState({ backgroundColor: color.hex });
    // };

    onImageDrop(acceptedFiles, rejectedFiles) {
        
        if (acceptedFiles.length > 0) {
            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/ImageTemplate').then((downloadURL) => {
                let bucket = 'posible.in/'
                let folder =  this.props.registration.registration.StorageFolder + '/ImageTemplate/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + acceptedFiles[0].name
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: url }
                this.setState({ image: image, imageURL: url})
            })
        }
        else { alert("No file selected") }
    }

    onImageRemove() {
        this.setState({ image: null })
    }

    onSubTitleTextChange(evt){
        this.setState({ imageSubTitle: evt.target.value });
    }

    onTitleTextChange(evt){
        this.setState({ imageTitle: evt.target.value });
    }

    async onSaveImageTemplate()
    {
        let nameError = "", smsTemplateError =""

        if (this.state.name.trim() === "") {
            nameError = "Please enter name"
        }

        if (this.state.smsTemplate.trim() === "") {
            smsTemplateError = "Please enter Message content"
        }

        if(nameError!=="" || smsTemplateError !=="")
        {
            this.setState({ nameError: nameError, smsTemplateError: smsTemplateError });
            return;
        }


        let templateImage = null;

        if(this.state.image)
        {
            var b64toBlob = require('b64-to-blob');
            let template = document.getElementById('imageTemplate')
            let messageTemplate = await htmlToImage.toPng(template)
            let templateParts = messageTemplate.split(";base64,");
            let templateBase64 = templateParts[1];
    
            var contentType = 'image/png';
            var b64Data = templateBase64
            var blob = b64toBlob(b64Data, contentType);
    
            let fileName = "ImageTemplate_" + new Date().getTime() + ".png";
    
            await this.props.uploadFileToServer(blob, this.props.registration.registration.StorageFolder + '/ImageTemplate', fileName);
            let bucket = 'posible.in/'
            let folder = this.props.registration.registration.StorageFolder + '/ImageTemplate/'
            let url = 'https://storage.googleapis.com/' + bucket + folder + fileName
            templateImage = { Name: fileName, Size: blob.size, Preview: url }
        }

        let objImageTemplate = {
            Name: this.state.name,
            SMSTemplate: this.state.smsTemplate,
            Title: this.state.imageTitle,
            SubTitle: this.state.imageSubTitle,
            Image: this.state.image,
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            Template: templateImage,
            SMSType : "Promotional",
            ModuleID : 'campaign',
            ModuleName : 'Campaign',
        }
        this.props.saveSMSTemplate(objImageTemplate, this.state.key).then(() => {
         
            this.props.getToastr("Message Template saved successfully");

            if (this.props.location.state && this.props.location.state.source === "crm") {
                let campaign = this.props.location.state.campaign;
                campaign.ImageTemplate = objImageTemplate.Template;
                campaign.Name = objImageTemplate.Name;
                campaign.SMSTemplate = objImageTemplate.SMSTemplate;

                if (campaign.key !== "") {
                    this.props.history.push({ pathname: '/home/campaign/edit/', state: { campaign: campaign } });
                }
                else {
                    this.props.history.push({ pathname: '/home/campaign/newcampaign', state: { campaign: campaign } })
                }
            }
            else {
                this.props.history.push("/home/messagetemplates/")
            }

        })
    }

    onHeaderBackClick() {
        if (this.props.location.state && this.props.location.state.source === "crm") {
            let campaign = this.props.location.state.campaign;
            this.props.history.push({ pathname: '/home/messagetemplates/', state: { source: 'crm', campaign: campaign } })
        }
        else {
            this.props.history.push("/home/messagetemplates/")
        }
    }

    render(){
        return <React.Fragment>
            {this.props.isMobileApp() && <HeaderNew {...this.props}
                back={true}
                title={this.props.location.state.messageTemplate ? "Edit Message Template" : "New Message Template"}
                onBackClick={() => this.onHeaderBackClick()}
            />}
            <div className="header-new-background"></div>
            <div className="content-wrapper">
                <div className="box box-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className={this.state.nameError !== "" ? "form-group has-error" : "form-group "}>
                                <label>Name</label>
                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                    onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })}
                                    value={this.state.name} />
                                {this.state.nameError !== "" && <span className="help-block">{this.state.nameError}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={this.state.smsTemplateError !== "" ? "form-group has-error" : "form-group "}>
                                <label >Message Content</label>
                                <textarea className="form-control"
                                    style={{ height: "129px", width: "100%" }}
                                    onChange={(evt) => this.setState({ smsTemplate: evt.target.value, smsTemplateError: "" })}
                                    value={this.state.smsTemplate}
                                ></textarea>
                                {this.state.smsTemplateError !== "" && <span className="help-block">{this.state.smsTemplateError}</span>}
                            </div>
                            <i><label>No of characters:- {this.state.smsTemplate ? this.state.smsTemplate.length : 0} </label></i>
                        </div>
                    </div>
                    <ImageTemplate {...this.props}
                        imageTitle={this.state.imageTitle}
                        placeTextOnImage={true}
                        imageSubTitle={this.state.imageSubTitle}
                        image={this.state.image}
                        onImageDrop={(files) => this.onImageDrop(files)}
                        onImageRemove={() => this.onImageRemove()}
                        onTitleTextChange={(evt) => this.onTitleTextChange(evt)}
                        onSubTitleTextChange={(evt) => this.onSubTitleTextChange(evt)}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <Footer onClick={() => this.onSaveImageTemplate()} />
            </div>
        </React.Fragment>
    }

}
export default EditImageTemplate

