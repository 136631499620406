import React,{Component} from 'react';
import Input from '../2/Controls/Input';
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';

class OmniPayment extends Component
{
    constructor()
    {
        super();
        this.state={
            paymentModeCashOnDelivery:false,
            paymentModeLoyalty:false,
            paymentModeRazorPay:false,
            paymentModeWallet:false,
            razorPayKeyId:"",
            razorPayKeySecret:"",
            razorPayKeyIdError:"",
            razorPayKeySecretError:"",
            saving:false,
            configFound:true,
            enableWalletRecharge:false,
            transactionTypeID:'',
            walletRechargeProductID:'',
            payUKey:"",
            payUKeyError:"",
            payUSalt:"",
            payUSaltError:"",
            onlinePaymentGateways:[
                {Text:"Razorpay", Value:"razorpay"},
                {Text:"Atom", Value:"atom"},
                // {Text:"PayU", Value:"payu"},
            ],
            selectedPaymentGateway:"",
            atomLoginID:"",
            atomPassword:"",
            atomProductID:"",
            atomTransactionType:"",
            atomReqHashKey:"",
            atomRespHashKey:"",
            atomRequestSalt:"",
            atomResponseSalt:"",
            atomEncryptionRequestKey:"",
            atomEncryptionResponseKey:"",
            atomLoginIDError:"",
            atomPasswordError:"",
            atomProductIDError:"",
            atomReqHashKeyError:"",
            atomRespHashKey:"",
            atomRequestSaltError:"",
            atomResponseSaltError:"",
            atomEncryptionRequestKeyError:"",
            atomEncryptionResponseKeyError:""
        }
        this.onSave= this.onSave.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }

    async componentDidMount() {
       
        let paymentModeCashOnDelivery=false
        let paymentModeLoyalty=false
        let paymentModeWallet=false
        let razorPayKeyId=""
        let razorPayKeySecret=""
        let enableWalletRecharge = false
        let transactionTypeID=""
        let walletRechargeProductID = "";
        let payUKey  = "";
        let payUSalt="";
        let enablePaymentGateway= false;
        let selectedPaymentGateway= "";
        let atomLoginID=""
        let atomPassword=""
        let atomProductID=""
        let atomReqHashKey=""
        let atomRespHashKey=""
        let atomRequestSalt=""
        let atomResponseSalt=""
        let atomEncryptionRequestKey=""
        let atomEncryptionResponseKey=""
        let checkoutConfig = await this.props.getOmniSettings();
        let pgSettings = null;
        // console.log('checkoutConfig',checkoutConfig)
        if(checkoutConfig){
            if(checkoutConfig.hasOwnProperty('PaymentModes'))
            {
                checkoutConfig.PaymentModes.map(async m=>{
                    if(m.Value==="cashondelivery")
                    {
                        paymentModeCashOnDelivery= true
                    }
                    else if(m.Value==="wallet")
                    {
                        paymentModeWallet= true
                    }
                    else if(m.Value==="loyalty")
                    {
                        paymentModeLoyalty= true
                    }
                    if(m.Value==="razorpay")
                    {
                        enablePaymentGateway= true;
                        selectedPaymentGateway= m.Value;
                       
                    }
                    if(m.Value==="atom"){
                        enablePaymentGateway= true;
                        selectedPaymentGateway= m.Value;
                        
                    }
                    // if(m.Value==="payu")
                    // {
                    //     enablePaymentGateway= true;
                    //     selectedPaymentGateway= m.Value;
                    //     // paymentModePayU= true;
                    //     pgSettings = await this.props.getOmniPaymentGatewaySettings();
                    //     if(pgSettings){
                    //         payUKey = pgSettings.PayUKey;
                    //         payUSalt = pgSettings.hasOwnProperty("PayUSalt") ? pgSettings.PayUSalt : "";
                    //     }
                        
                    // }
                })
            }
            // if(selectedPaymentGateway==="razorpay"){
            //     pgSettings = await this.props.getOmniPaymentGatewaySettings();
            //     if(pgSettings){
            //         razorPayKeyId = pgSettings.RazorPayKeyID
            //         razorPayKeySecret = pgSettings.RazorPayKeySecret
            //     }    
            // }
            if(selectedPaymentGateway==="atom" || selectedPaymentGateway==="razorpay"){
                pgSettings = await this.props.getOmniPaymentGatewaySettings();
                if(pgSettings){
                    console.log('pgSettings',pgSettings)                            
                    atomLoginID=pgSettings.hasOwnProperty("AtomLoginID") ? pgSettings.AtomLoginID : "";
                    atomPassword=pgSettings.hasOwnProperty("AtomPassword") ? pgSettings.AtomPassword : "";
                    atomProductID=pgSettings.hasOwnProperty("AtomProductID") ? pgSettings.AtomProductID : "";
                    atomReqHashKey=pgSettings.hasOwnProperty("AtomReqHashKey") ? pgSettings.AtomReqHashKey : "";
                    atomRespHashKey=pgSettings.hasOwnProperty("AtomRespHashKey") ? pgSettings.AtomRespHashKey : "";
                    atomRequestSalt=pgSettings.hasOwnProperty("AtomRequestSalt") ? pgSettings.AtomRequestSalt : "";
                    atomResponseSalt=pgSettings.hasOwnProperty("AtomResponseSalt") ? pgSettings.AtomResponseSalt : "";
                    atomEncryptionRequestKey=pgSettings.hasOwnProperty("AtomEncryptionRequestKey") ? pgSettings.AtomEncryptionRequestKey : "";
                    atomEncryptionResponseKey=pgSettings.hasOwnProperty("AtomEncryptionResponseKey") ? pgSettings.AtomEncryptionResponseKey : "";
                    razorPayKeyId = pgSettings.hasOwnProperty("RazorPayKeyID") ? pgSettings.RazorPayKeyID : ""; //m.RazorPayKeyID
                    razorPayKeySecret = pgSettings.hasOwnProperty("RazorPayKeySecret") ? pgSettings.RazorPayKeySecret : ""; //m.RazorPayKeySecret
                }
            }
            // if(checkoutConfig.hasOwnProperty('EnableWalletRecharge')){
            //     enableWalletRecharge = checkoutConfig.EnableWalletRecharge;
            // }
            // if(checkoutConfig.hasOwnProperty('WalletRechargeTransactionTypeID')){
            //     transactionTypeID=checkoutConfig.WalletRechargeTransactionTypeID
            // }
            // if(checkoutConfig.hasOwnProperty('WalletRechargeProductID')){
            //     walletRechargeProductID = checkoutConfig.WalletRechargeProductID
            // }
        }
        this.setState({
            paymentModeCashOnDelivery: paymentModeCashOnDelivery,
            paymentModeLoyalty:paymentModeLoyalty,
            paymentModeWallet: paymentModeWallet,
            razorPayKeyId:razorPayKeyId,
            razorPayKeySecret:razorPayKeySecret,
            enableWalletRecharge:enableWalletRecharge,
            transactionTypeID:transactionTypeID,
            walletRechargeProductID:walletRechargeProductID,
            payUKey:payUKey,
            payUSalt:payUSalt,
            enablePaymentGateway:enablePaymentGateway,
            selectedPaymentGateway:selectedPaymentGateway,
            atomLoginID:atomLoginID,
            atomPassword:atomPassword,
            atomProductID:atomProductID,
            atomReqHashKey:atomReqHashKey,
            atomRespHashKey:atomRespHashKey,
            atomRequestSalt:atomRequestSalt,
            atomResponseSalt:atomResponseSalt,
            atomEncryptionRequestKey:atomEncryptionRequestKey,
            atomEncryptionResponseKey:atomEncryptionResponseKey,
        })
    }

    async onSave() {

        // let registration = this.props.registration.registration;
        let checkoutConfig = await this.props.getOmniSettings();
        
        let razorPayKeyIdError = "";
        let razorPayKeySecretError ="";
        let payUKeyError = "";
        let payUSaltError ="";
        let atomLoginIDError= "";
        let atomPasswordError= "";
        let atomProductIDError= "";
        let atomReqHashKeyError= "";
        let atomRespHashKeyError= "";
        let atomRequestSaltError= "";
        let atomResponseSaltError= "";
        let atomEncryptionRequestKeyError= "";
        let atomEncryptionResponseKeyError= "";
        let pgSettings=null;
        // let checkoutConfig = this.props.registration.registration.hasOwnProperty('CheckoutConfig') ? this.props.registration.registration.CheckoutConfig : {}
        //check payment modes
        let paymentModes = []
        if(this.state.paymentModeCashOnDelivery)
        {
            paymentModes.push({Value:"cashondelivery", Text:"Cash On delivery"})
        }
        if(this.state.paymentModeWallet)
        {
            paymentModes.push({Value:"wallet", Text:"Wallet"})
        }
        if(this.state.paymentModeLoyalty)
        {
            paymentModes.push({Value:"loyalty", Text:"Loyalty Points"})
        }
        if(this.state.selectedPaymentGateway==="razorpay")
        {
           
            if(this.state.razorPayKeyId.trim()==="")
            {
                razorPayKeyIdError="Please provide value"
            }
            if(this.state.razorPayKeySecret.trim()==="")
            {
                razorPayKeySecretError="Please provide value"
            }      
            paymentModes.push({
                Value:"razorpay", 
                Text:"Razorpay",
                RazorPayKeyID:this.state.razorPayKeyId, 
                RazorPayKeySecret:this.state.razorPayKeySecret
            });
            pgSettings= {
                RazorPayKeyID:this.state.razorPayKeyId, 
                RazorPayKeySecret:this.state.razorPayKeySecret,
                PG:"razorpay",
                RegistrationID: this.props.user.user.RegistrationID
            }
        }
        if(this.state.selectedPaymentGateway==="payu"){
            if(this.state.payUKey.trim()===""){
                payUKeyError="Please provide value";
            }
            if(this.state.payUSalt.trim()===""){
                payUSaltError="Please provide value";
            }
            paymentModes.push({
            Value:"payu", 
            Text:"PayU"
            });
            pgSettings= {
                PayUKey:this.state.payUKey,
                PayUSalt: this.state.payUSalt,
                PG:"payu",
                RegistrationID: this.props.user.user.RegistrationID
            }
        }
        if(this.state.selectedPaymentGateway==="atom"){
            if(this.state.atomLoginID.trim()===""){
                atomLoginIDError = "Please provide value";
            }
            if(this.state.atomPassword.trim()===""){
                atomPasswordError = "Please provide value";
            }
            if(this.state.atomProductID.trim()===""){
                atomProductIDError = "Please provide value";
            }
            if(this.state.atomEncryptionRequestKey.trim()===""){
                atomEncryptionRequestKeyError = "Please provide value";
            }
            if(this.state.atomEncryptionResponseKey.trim()===""){
                atomEncryptionResponseKeyError = "Please provide value";
            }
            if(this.state.atomReqHashKey.trim()===""){
                atomReqHashKeyError = "Please provide value";
            }
            if(this.state.atomRespHashKey.trim()===""){
                atomRespHashKeyError = "Please provide value";
            }
            if(this.state.atomRequestSalt.trim()===""){
                atomRequestSaltError= "Please provide value";
            }
            if(this.state.atomResponseSalt.trim()===""){
                atomResponseSaltError= "Please provide value";
            }
            paymentModes.push({
                Value:"atom", 
                Text:"Atom"});

                pgSettings= {
                    PG:"atom",
                    AtomLoginID:this.state.atomLoginID,
                    AtomPassword:this.state.atomPassword,
                    AtomProductID:this.state.atomProductID,
                    AtomReqHashKey:this.state.atomReqHashKey,
                    AtomRespHashKey:this.state.atomRespHashKey,
                    AtomRequestSalt:this.state.atomRequestSalt,
                    AtomResponseSalt:this.state.atomResponseSalt,
                    AtomEncryptionRequestKey:this.state.atomEncryptionRequestKey,
                    AtomEncryptionResponseKey:this.state.atomEncryptionResponseKey,
                    RegistrationID: this.props.user.user.RegistrationID
                }
        }

        if(razorPayKeyIdError!=="" || 
        razorPayKeySecretError!=="" || 
        payUKeyError!=="" || 
        payUSaltError!=="" ||
        atomLoginIDError!=="" ||
        atomPasswordError!=="" ||
        atomProductIDError !=="" ||
        atomEncryptionRequestKeyError!==""||
        atomEncryptionResponseKeyError!=="" ||
        atomReqHashKeyError!=="" ||
        atomRespHashKeyError!=="" ||
        atomRequestSaltError!=="" ||
        atomResponseSaltError!==""
        )
        {
            this.setState({razorPayKeyIdError:razorPayKeyIdError,
                razorPayKeySecretError:razorPayKeySecretError,
                payUKeyError:payUKeyError,
                payUSaltError:payUSaltError,
                atomLoginIDError:atomLoginIDError,
                atomPasswordError:atomPasswordError,
                atomProductIDError:atomProductIDError,
                atomEncryptionRequestKeyError:atomEncryptionRequestKeyError,
                atomEncryptionResponseKeyError:atomEncryptionResponseKeyError,
                atomReqHashKeyError:atomReqHashKeyError,
                atomRespHashKeyError:atomRespHashKeyError,
                atomRequestSaltError:atomRequestSaltError,
                atomResponseSaltError:atomResponseSaltError
            })
            return;
        }
        // checkoutConfig.EnableWalletRecharge = this.state.enableWalletRecharge,
        // checkoutConfig.WalletRechargeTransactionTypeID=this.state.transactionTypeID
        // checkoutConfig.WalletRechargeProductID = this.state.walletRechargeProductID
        checkoutConfig.PaymentModes = paymentModes;
        await this.props.saveOmniSettings(checkoutConfig);
        if(pgSettings){
            await this.props.saveOmniPaymentGatewaySettings(pgSettings);

        }
        this.props.getToastr("Payment option saved successfully");
        // registration.CheckoutConfig = checkoutConfig;
        // console.log('registration', registration);
        // return;
        // this.props.updateRegistrationGeneralDetails(this.props.registration.registration.key, registration).then(
        //     () => {
        //         this.props.getToastr("Online store settings saved successfully")
        //         this.props.getRegistration(this.props.registration.registration.key)
        //     })
    }
    
    onCancel(){

    }

   

    render() {

        return <Page 
        title="Online Store - Payment"
        showSaveButton={true} 
        showCancelButton={false}
        processing={this.state.saving}
        onClick={(action)=>{
            if(action==="cancel"){
                this.onCancel();
            }else if(action==="save"){
                this.onSave();
            }
        }}
        > 
            <PageSection>

                <div className="row">   
                    <div className="col-md-3">
                        <Input
                            label="Cash on delivery"
                            type="switch"
                            value={this.state.paymentModeCashOnDelivery}
                            onChange={(value) => { this.setState({ paymentModeCashOnDelivery: value }) }}
                        />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-md-3">
                        <Input
                            label="Wallet"
                            type="switch"
                            value={this.state.paymentModeWallet}
                            onChange={(value) => { this.setState({ paymentModeWallet: value }) }}
                        />
                    </div>
                </div>
                <div className="row">   
                    <div className="col-md-3">
                        <Input
                            label="Loyalty"
                            type="switch"
                            value={this.state.paymentModeLoyalty}
                            onChange={(value) => { this.setState({ paymentModeLoyalty: value }) }}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-3'>
                        <Input
                            label="Accept payment using payment gateway"
                            type="switch"
                            value={this.state.enablePaymentGateway}
                            onChange={(value)=>{this.setState({enablePaymentGateway:value})}}
                        />
                    </div>
                </div>
                {this.state.enablePaymentGateway && <div className='row'>
                    <div className='col-md-3'>
                        <Input
                            label="Select payment gateway"  
                            type="select"
                            value={this.state.selectedPaymentGateway}
                            onChange={(value)=>{this.setState({selectedPaymentGateway:value})}}
                        >
                            <option value="">Please select</option>
                            {this.state.onlinePaymentGateways.map(o=><option value={o.Value}>{o.Text}</option>)}
                        </Input>
                    </div>
                </div>}
                {/* <div className="row">   
                    <div className="col-md-3">
                        <Input
                            label="Razorpay"
                            type="switch"
                            value={this.state.paymentModeRazorPay}
                            onChange={(value) => { this.setState({ paymentModeRazorPay: value }) }}
                        />
                    </div>
                </div> */}
                {this.state.selectedPaymentGateway==="razorpay" && <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="text"
                            label="Key id"
                            onChange={(value) => this.setState({ razorPayKeyId:value,razorPayKeyIdError:"" })}
                            value={this.state.razorPayKeyId}
                            error={this.state.razorPayKeyIdError}
                        />
                    </div>
                </div>}
                {this.state.selectedPaymentGateway==="razorpay"  && <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="text"
                            label="Key Secret"
                            onChange={(value) => this.setState({ razorPayKeySecret:value,razorPayKeySecretError:"" })}
                            value={this.state.razorPayKeySecret}
                            error={this.state.razorPayKeySecretError}
                        />
                    </div>
                </div>}
                {/* <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="switch"
                            label="PayU"
                            onChange={(value) => this.setState({ paymentModePayU:value })}
                            value={this.state.paymentModePayU}
                        />
                    </div>
                </div> */}
                {this.state.selectedPaymentGateway==="payu"  && <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="text"
                            label="PayU Key"
                            onChange={(value) => this.setState({ payUKey:value })}
                            value={this.state.payUKey}
                            error={this.state.payUKeyError}
                        />
                    </div>
                </div>}
                {this.state.selectedPaymentGateway==="payu"  && <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="text"
                            label="PayU Salt"
                            onChange={(value) => this.setState({ payUSalt:value })}
                            value={this.state.payUSalt}
                            error={this.state.payUSaltError}
                        />
                    </div>
                </div>}
                {this.state.selectedPaymentGateway==="atom"  && <React.Fragment>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Login ID"
                                onChange={(value) => this.setState({ atomLoginID:value,atomLoginIDError:"" })}
                                value={this.state.atomLoginID}
                                error={this.state.atomLoginIDError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Password"
                                onChange={(value) => this.setState({ atomPassword:value,atomPasswordError:"" })}
                                value={this.state.atomPassword}
                                error={this.state.atomPasswordError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Product ID"
                                onChange={(value) => this.setState({ atomProductID:value,atomProductIDError:"" })}
                                value={this.state.atomProductID}
                                error={this.state.atomProductIDError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Request Hash Key"
                                onChange={(value) => this.setState({ atomReqHashKey:value,atomReqHashKeyError:"" })}
                                value={this.state.atomReqHashKey}
                                error={this.state.atomReqHashKeyError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Response Hash Key"
                                onChange={(value) => this.setState({ atomRespHashKey:value,atomRespHashKeyError:"" })}
                                value={this.state.atomRespHashKey}
                                error={this.state.atomRespHashKeyError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Request Salt"
                                onChange={(value) => this.setState({ atomRequestSalt:value,atomRequestSaltError:"" })}
                                value={this.state.atomRequestSalt}
                                error={this.state.atomRequestSaltError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Response Salt"
                                onChange={(value) => this.setState({ atomResponseSalt:value,atomResponseSaltError:"" })}
                                value={this.state.atomResponseSalt}
                                error={this.state.atomResponseSaltError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Request Encryption Key"
                                onChange={(value) => this.setState({ atomEncryptionRequestKey:value,atomEncryptionRequestKeyError:"" })}
                                value={this.state.atomEncryptionRequestKey}
                                error={this.state.atomEncryptionRequestKeyError}
                            />
                        </div>
                    </div>
                    <div className="row">   
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Response Encryption Key"
                                onChange={(value) => this.setState({ atomEncryptionResponseKey:value,atomEncryptionResponseKeyError:"" })}
                                value={this.state.atomEncryptionResponseKey}
                                error={this.state.atomEncryptionResponseKeyError}
                            />
                        </div>
                    </div>
                </React.Fragment>}

                {/* <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="switch"
                            label="Allow Wallet Recharge"
                            onChange={(value) => this.setState({ enableWalletRecharge:value })}
                            value={this.state.enableWalletRecharge}
                        />
                    </div>
                </div> */}
                {/* {this.state.enableWalletRecharge && <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="select"
                            label="Transaction type"
                            onChange={(value) => this.setState({ transactionTypeID: value })}
                            value={this.state.transactionTypeID}>
                            <option value="">Select transaction type</option>
                            {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                        </Input>
                    </div>
                </div>}
                {this.state.enableWalletRecharge && <div className="row">   
                    <div className="col-md-3">
                        <Input
                            type="select"
                            label="Product mapped"
                            onChange={(value) => this.setState({ walletRechargeProductID: value })}
                            value={this.state.walletRechargeProductID}>
                            <option value="">Select wallet recharge product</option>
                            {this.props.config.Products.filter((e) => e.ProductTypeID === 4 && !e.IsDeleted).map((e) => <option value={e.key}>{e.Name}</option>)}
                        </Input>
                    </div>
                </div>}  */}
            </PageSection>
    </Page>}
}

export default OmniPayment