import React, { Component } from 'react'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'

class CashRegisterPrinterSettings extends Component {

    constructor(){
        super()
        this.state={
            printer:'',
            defaultSalesOption:'categories',
            // cashRegister:null
        }

        this.onHeaderBackClick  = this.onHeaderBackClick.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {

        // let cashRegister = this.props.location.state.cashRegister
        // let printer = cashRegister.hasOwnProperty('Printer') ? cashRegister.Printer : ''
        // let defaultSalesOption = cashRegister.hasOwnProperty('DefaultSalesOption') ? cashRegister.DefaultSalesOption :'categories'
        let printer = localStorage.getItem('printer') 
        let defaultSalesOption =  localStorage.getItem('defaultSalesOption') 
        // console.log('printer', printer)
        // console.log('defaultSalesOption', defaultSalesOption)
        this.setState({
            // cashRegister:cashRegister, 
            printer:printer, defaultSalesOption:defaultSalesOption})
      
    }

    onHeaderBackClick(){

        this.props.history.push({ pathname: '/home'})  

    }

    onSave()
    {
        // let cashRegister = this.state.cashRegister
        // cashRegister.Printer = this.state.printer
        // cashRegister.DefaultSalesOption = this.state.defaultSalesOption

        // this.props.updateCashRegister(cashRegister, cashRegister.key).then(()=> {
        //     this.props.getToastr("Saved Successfully")
        //     this.onHeaderBackClick()
        // })

        localStorage.setItem('printer', this.state.printer);
        localStorage.setItem('defaultSalesOption', this.state.defaultSalesOption);
        this.props.getToastr("Saved Successfully");
        this.onHeaderBackClick();

    }

    render() {
        return <React.Fragment>

            <HeaderNew {...this.props}
                back={true}
                title="Printer Settings"
                onBackClick={() => this.onHeaderBackClick()}
            />

            <div className="header-new-background"></div>

            <div className="content-wrapper" >

                <div className="container">

                    <br/>
                
                    {/* <div className="form-group">
                        <label>Cash Register Code</label>
                        <input type="text" className="form-control"
                                value={this.state.cashRegister ? this.state.cashRegister.CashRegisterCode : ''}
                            />
                    </div>

                    <div className="form-group">
                        <label>Cash Register Name</label>
                        <input type="text" className="form-control"
                                value={this.state.cashRegister ? this.state.cashRegister.CashRegisterName: ''}
                            />
                    </div> */}

                    <div className="form-group">
                        <label>Default Sales Option</label>
                        <select className="form-control"
                            value={this.state.defaultSalesOption}
                            onChange={(evt)=>{this.setState({defaultSalesOption: evt.target.value })}}>
                            <option value="">Select</option>
                            <option value="scan">Scan Products</option>
                            <option value="search">Search Products</option>
                            <option value="categories">Product Categories</option>
                            <option value="favourites">Favourites</option>
                            <option value="customer">Customer</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Printer</label>
                        <select className="form-control"
                            value={this.state.printer}
                            onChange={(evt)=>{this.setState({printer: evt.target.value })}}>
                            <option value="">Select</option>
                            <option value="pinelabs">Pine Labs</option>
                            <option value="wisepos">Mswipe - wisepos</option>
                            {/* <option value="bluetooth">Bluetooth Printer</option> */}
                        </select>
                    </div>

                </div>
            
            </div>

            <Footer onClick={()=> this.onSave()} />

    </React.Fragment>
    }
}

export default CashRegisterPrinterSettings
