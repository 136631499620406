import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Form from "../2/Controls/Form";

class AddEditBOM extends Component {
  constructor() {
    super();
    this.state = {
      isProductTypeAheadLoading: false,
      productsTypeAheadOptions: [],
      selectedProduct: null,
      isRawMaterialTypeAheadLoading: false,
      rawMaterialsTypeAheadOptions: [],
      selectedRawMaterial: null,
      bomProducts: [],
      product: "",
      productID: "",
      quantity: "",
      specificStoreSelected: false,
      allStoresSelected: false,
      associatedStores: [],
      addedStores: [],
      stores: [],
      key: "",
      bomName: "",
      bomNameError: "",
      selectedProductError: "",
      selectedRawMaterialError: "",
      quantityError: "",
      optionError: "",
      storeNameError: "",
      productSKU: "",
      productUOM: "",
      totalCost: 0.0,
      userSelection: false,
      selectedRawmaterialUOM: "",
      rawMaterialUOMS: [],
      rawMaterialSupplyPrice: 0,
      selectedRawmaterialUOMError: "",
    };
    this.onAddRawMaterial = this.onAddRawMaterial.bind(this);
    this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
    this.onRawMaterialTypeAheadSearch = this.onRawMaterialTypeAheadSearch.bind(
      this
    );
    this.onRemoveRawMaterial = this.onRemoveRawMaterial.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSaveBOM = this.onSaveBOM.bind(this);
  }

  componentDidMount() {
    let key = this.props.selectedRecord ? this.props.selectedRecord.key : "";
    this.setState({ key: key });
    this.props
      .getStores(
        this.props.user.user.RegistrationID,
        0,
        10000,
        "active",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
      .then((result) => {
        let stores = result.searchResult;
        const storeList = [];
        stores.map((store) => {
          let obj = {
            value: store.key,
            label: store.StoreName,
          };
          storeList.push(obj);
        });
        this.setState({ stores: storeList });
      });
    if (key && key !== "") {
      this.props.getBOMDetails(key).then((bom) => {
        this.setState({
          bomName: bom.hasOwnProperty("BOMName") ? bom.BOMName : "",
          selectedProduct: bom.hasOwnProperty("Product") ? bom.Product : null,
          bomProducts: bom.hasOwnProperty("RawMaterials")
            ? bom.RawMaterials
            : [],
          specificStoreSelected: bom.hasOwnProperty("SpecificStoreSelected")
            ? bom.SpecificStoreSelected
            : false,
          allStoresSelected: bom.hasOwnProperty("AllStoresSelected")
            ? bom.AllStoresSelected
            : false,
          totalCost: bom.hasOwnProperty("TotalCost") ? bom.TotalCost : 0.0,
          associatedStores: bom.hasOwnProperty("Stores") ? bom.Stores : [],
        });
      });
    }
  }

  onSaveBOM() {
    let selectedProductError = "";
    let selectedRawMaterialError = "";
    let quantityError = "";
    let optionError = "";
    let storeNameError = "";
    let bomNameError = "";
    this.setState({ saving: true });
    // if(this.state.bomName.trim()==="")
    // {
    //     bomNameError ="Please provide name"
    // }
    if (this.state.selectedProduct === null) {
      selectedProductError = "Please select your product";
    }

    // if (!this.state.allStoresSelected && !this.state.specificStoreSelected) {
    //     optionError = "Please select either All or Specific Store option."
    // }

    if (this.state.bomProducts.length === 0) {
      selectedRawMaterialError = "Please select atleast one raw material";
      quantityError = "Please mention the quantity";
    }

    // if (this.state.specificStoreSelected) {
    //     if (this.state.associatedStores.length === 0) {
    //         storeNameError = "Please select store"
    //     }
    // }

    if (this.state.selectedProduct != "" && this.state.bomProducts.length > 0) {
      if (
        this.state.bomProducts.filter(
          (e) => e.SKU === this.state.selectedProduct
        ).length > 0
      ) {
        selectedRawMaterialError = "Raw Material cannot be same as BOM Product";
      }
    }

    if (
      bomNameError.trim() !== "" ||
      selectedProductError !== "" ||
      optionError !== "" ||
      storeNameError !== "" ||
      selectedRawMaterialError !== "" ||
      quantityError !== ""
    ) {
      this.setState({
        selectedProductError: selectedProductError,
        selectedRawMaterialError: selectedRawMaterialError,
        quantityError: quantityError,
        optionError: optionError,
        storeNameError: storeNameError,
        bomNameError: bomNameError,
        saving: false,
      });
      return;
    }

    let bom = {
      BOMName: this.state.bomName,
      RegistrationID: this.props.user.user.RegistrationID,
      Product: this.state.selectedProduct,
      RawMaterials: this.state.bomProducts,
      SpecificStoreSelected: this.state.specificStoreSelected,
      AllStoresSelected: this.state.allStoresSelected,
      Stores: this.state.associatedStores,
      IsDeleted: false,
      TotalCost: this.state.totalCost,
    };
    const key = this.state.key ? this.state.key : "";
    this.props.saveBOM(bom, key).then(() => {
      setTimeout(
        function() {
          //Start the timer
          this.props.getToastr("BOM saved successfully");
          this.setState({ saving: false });
          this.props.onSave();
          // this.props.history.push('/home/catalogue/bom')
        }.bind(this),
        2000
      );
    });
  }

  onProductTypeAheadSearch(query) {
    this.setState({ isProductTypeAheadLoading: true });
    this.props
      .getProducts(
        this.props.user.user.RegistrationID,
        0,
        100,
        "active",
        query,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true
      )
      .then((result) => {
        let r = [];
        result.searchResult.map((m) => {
          if (
            m.ProductTypeID === 1 ||
            m.ProductTypeID === 2 ||
            m.ProductTypeID === 3
          ) {
            if (m.hasOwnProperty("BOM") && m.BOM) {
              r.push(m);
            }
          }
        });
        this.setState({
          productsTypeAheadOptions: r,
          isProductTypeAheadLoading: false,
        });
      });
  }

  onRawMaterialTypeAheadSearch(query) {
    this.setState({ isRawMaterialTypeAheadLoading: true });
    this.props
      .getProducts(
        this.props.user.user.RegistrationID,
        0,
        100,
        "active",
        query,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true
      )
      .then((result) => {
        // console.log('result',result.searchResult)
        let r = [];
        result.searchResult.map((m) => {
          if (m.ProductTypeID === 1 || m.ProductTypeID === 2) {
            r.push(m);
          }
        });
        // console.log(r)
        this.setState({
          rawMaterialsTypeAheadOptions: r,
          isRawMaterialTypeAheadLoading: false,
        });
      });
  }

  onAddRawMaterial() {
    let selectedRawMaterialError = "";
    let quantityError = "";
    if (this.state.selectedRawMaterial === null) {
      selectedRawMaterialError = "Please select your raw material";
    }

    if (this.state.quantity === 0 || this.state.quantity === "") {
      quantityError = "Please mention the quantity";
    }

    if (
      this.state.selectedProduct &&
      this.state.selectedRawMaterial &&
      this.state.selectedProduct.SKU === this.state.selectedRawMaterial.SKU
    ) {
      selectedRawMaterialError = "Raw Material cannot be same as BOM Product";
    }

    if (selectedRawMaterialError !== "" || quantityError !== "") {
      this.setState({
        selectedRawMaterialError: selectedRawMaterialError,
        quantityError: quantityError,
      });
      return;
    }

    //console.log("Product", JSON.stringify(this.state.product));
    let bomProduct = {
      Product: this.state.product,
      ProductSKU: this.state.productSKU,
      ProductUOM: this.state.selectedRawmaterialUOM,
      ProductID: this.state.productID,
      UserSelection: this.state.userSelection,
      SupplyPrice: this.state.rawMaterialSupplyPrice,
      Qty: this.state.quantity ? Number(this.state.quantity) : 0,
      Cost: this.state.rawMaterialSupplyPrice
        ? Number(
            Number(this.state.rawMaterialSupplyPrice) *
              Number(this.state.quantity)
          ).toFixed(2)
        : 0,
    };
    let bomProducts = this.state.bomProducts;
    bomProducts.push(bomProduct);
    //total cost
    let totalCost = 0;
    bomProducts.map((m) => {
      totalCost = Number(totalCost) + Number(m.Cost);
    });
    totalCost = Number(totalCost).toFixed(2);
    this.setState({
      bomProducts: bomProducts,
      selectedRawMaterial: null,
      quantity: "",
      totalCost: totalCost,
      rawMaterialSupplyPrice: 0,
      rawMaterialUOMS: [],
    });
  }

  onRemoveRawMaterial(index) {
    let bomProducts = this.state.bomProducts;
    bomProducts.splice(index, 1);
    this.setState({ bomProducts: bomProducts });
  }

  handleOnChange(storeList, e) {
    this.setState({ associatedStores: storeList });
  }

  onRawMaterialUOMChange(value, rawMaterial) {
    if (!rawMaterial) {
      return;
    }
    if (value === "") {
      return;
    }
    let supplyPrice = rawMaterial.SupplyPrice;
    if (
      value !== "" &&
      rawMaterial.SecondaryUOMs &&
      rawMaterial.SecondaryUOMs.length > 0
    ) {
      ///current selected uom to
      let uoms = [];
      rawMaterial.SecondaryUOMs.map((m) => uoms.push(m));
      uoms.push({
        SecondaryUOMValue: rawMaterial.UOM,
        Conversion: 1,
      });

      let currentConversionVlaue = 0;
      let tmp1 = uoms.filter(
        (f) => f.SecondaryUOMValue === this.state.selectedRawmaterialUOM
      );
      if (tmp1.length > 0) {
        currentConversionVlaue = tmp1[0].Conversion;
      }
      let supplyPriceForPurchaseUOM = supplyPrice / currentConversionVlaue;

      ///let new uom to
      let tmp = uoms.filter((f) => f.SecondaryUOMValue === value);
      if (tmp.length > 0) {
        let conversionValue = tmp[0].Conversion; ///conversion value with respect to primary UOM
        supplyPrice = supplyPriceForPurchaseUOM * conversionValue;
      }
    }
    this.setState({
      selectedRawmaterialUOM: value,
      rawMaterialSupplyPrice: supplyPrice,
    });
  }

  render() {
    return (
      <Form
        title={this.state.key !== "" ? "Edit BOM" : "New BOM"}
        onClick={(action) => {
          if (action === "save") {
            this.onSaveBOM();
          } else {
            this.props.onClose();
          }
        }}
        processing={this.state.saving}
      >
        <Input
          type="typeaheadselection"
          label="Product for which BOM is to be created"
          options={this.state.productsTypeAheadOptions}
          labelKey="Name"
          filterBy={["Name", "SKU", "BarCode"]}
          onSearch={this.onProductTypeAheadSearch}
          selected={this.state.selectedProduct}
          columns={[
            {
              Column: "Name",
              Filter: false,
              SelectedText: true,
              SearchText: true,
              Main: true,
            },
            {
              Column: "SKU",
              Filter: true,
              SearchText: true,
              SelectedText: true,
            },
          ]}
          onChange={(selected) => {
            console.log("selected", selected);
            let selectedProduct = {
              ProductID: selected.key,
              SKU: selected.SKU,
              Name: selected.Name,
              ProductType: selected.ProductType,
              ProductTypeID: selected.ProductTypeID,
              Category: selected.Category,
              CategoryID: selected.CategoryID,
              SubCategoryID: selected.SubCategoryID,
              SubCategory: selected.SubCategory,
              BrandID: selected.BrandID,
              Brand: selected.Brand,
              BarCode: selected.BarCode,
              MRP: selected.MRP,
              SupplyPrice: selected.SupplyPrice,
              RetailPrice: selected.RetailPrice,
            };
            // console.log('selectedProduct',selectedProduct)
            this.setState({ selectedProduct: selectedProduct });
          }}
          renderMenuItemChildren={(option, props) => (
            <div>
              <div>
                <b>{option.Name}</b>
              </div>
              <div>{option.SKU}</div>
              <div>
                {option.hasOwnProperty("CustomField1") &&
                  option.CustomField1.trim() !== "" &&
                  this.state.productCustomField1DisplayName.trim() !== "" && (
                    <React.Fragment>
                      {this.state.productCustomField1DisplayName}:{" "}
                      {option.CustomField1}
                    </React.Fragment>
                  )}
                {option.hasOwnProperty("CustomField2") &&
                  option.CustomField2.trim() !== "" &&
                  this.state.productCustomField2DisplayName.trim() !== "" && (
                    <React.Fragment>
                      , {this.state.productCustomField2DisplayName}:{" "}
                      {option.CustomField2}
                    </React.Fragment>
                  )}
                {option.hasOwnProperty("CustomField3") &&
                  option.CustomField3.trim() !== "" &&
                  this.state.productCustomField3DisplayName.trim() !== "" && (
                    <React.Fragment>
                      , {this.state.productCustomField3DisplayName}:{" "}
                      {option.CustomField3}
                    </React.Fragment>
                  )}
                {option.hasOwnProperty("CustomField4") &&
                  option.CustomField4.trim() !== "" &&
                  this.state.productCustomField4DisplayName.trim() !== "" && (
                    <React.Fragment>
                      , {this.state.productCustomField4DisplayName}:{" "}
                      {option.CustomField4}
                    </React.Fragment>
                  )}
                {option.hasOwnProperty("CustomField5") &&
                  option.CustomField5.trim() !== "" &&
                  this.state.productCustomField5DisplayName.trim() !== "" && (
                    <React.Fragment>
                      , {this.state.productCustomField5DisplayName}:{" "}
                      {option.CustomField5}
                    </React.Fragment>
                  )}
                {option.hasOwnProperty("CustomField6") &&
                  option.CustomField6.trim() !== "" &&
                  this.state.productCustomField6DisplayName.trim() !== "" && (
                    <React.Fragment>
                      , {this.state.productCustomField6DisplayName}:{" "}
                      {option.CustomField6}
                    </React.Fragment>
                  )}
              </div>
            </div>
          )}
          onClick={(type) => {
            if (type === "remove") {
              this.setState({ selectedProduct: null });
            }
          }}
        />
        <Input
          type="typeaheadselection"
          label="Please select Raw Material"
          placeholder="Start typing to search raw materials"
          options={this.state.rawMaterialsTypeAheadOptions}
          labelKey="Name"
          filterBy={["Name", "SKU", "BarCode"]}
          processing={this.state.isRawMaterialTypeAheadLoading}
          onSearch={this.onRawMaterialTypeAheadSearch}
          selected={this.state.selectedRawMaterial}
          columns={[
            {
              Column: "Name",
              Filter: false,
              SelectedText: true,
              SearchText: true,
              Main: true,
            },
            {
              Column: "SKU",
              Filter: true,
              SearchText: true,
              SelectedText: true,
            },
          ]}
          onChange={(selected) => {
            let selectedRawmaterialUOM = selected.UOM;
            let rawMaterialUOMS = [];
            if (selected.UOM && selected.UOM !== "") {
              rawMaterialUOMS.push(selected.UOM);
            }
            if (selected.SecondaryUOMs) {
              selected.SecondaryUOMs.map((m) =>
                rawMaterialUOMS.push(m.SecondaryUOMValue)
              );
            }
            if (selected.PurchaseUOM && selected.PurchaseUOM !== "") {
              selectedRawmaterialUOM = selected.PurchaseUOM;
            }
            // console.log("selected", selected);
            // console.log("rawMaterialUOMS", rawMaterialUOMS);
            this.setState({
              selectedRawMaterial: selected,
              rawMaterialSupplyPrice: selected.SupplyPrice,
              selectedRawmaterialUOM: selectedRawmaterialUOM,
              rawMaterialUOMS: rawMaterialUOMS,

              product: selected.Name,
              productID: selected.key,
              supplyPrice: selected.SupplyPrice,
              productSKU: selected.SKU,
              selectedRawMaterialError: "",
            });
          }}
          renderMenuItemChildren={(option, props) => (
            <div>
              <div>
                <b>{option.Name}</b>
              </div>
              <div>{option.SKU}</div>
            </div>
          )}
          onClick={(type) => {
            if (type === "remove") {
              this.setState({ selectedRawMaterial: null });
            }
          }}
        />
        {this.state.selectedRawMaterial && (
          <div className="row">
            <div className="col-md-3">
              <Input
                type="number"
                label="Supply Price"
                value={this.state.rawMaterialSupplyPrice}
                onChange={(value) =>
                  this.setState({ rawMaterialSupplyPrice: value })
                }
              />
            </div>
            <div className="col-md-3">
              <Input
                type="select"
                label="UOM"
                onChange={(value) =>
                  this.onRawMaterialUOMChange(
                    value,
                    this.state.selectedRawMaterial
                  )
                }
                value={this.state.selectedRawmaterialUOM}
                error={this.state.selectedRawmaterialUOMError}
              >
                <option>Please select</option>
                {this.state.rawMaterialUOMS.map((m) => (
                  <option value={m}>{m}</option>
                ))}
              </Input>
            </div>
            <div className="col-md-3">
              <Input
                type="number"
                label="Qty Consumed"
                onChange={(value) =>
                  this.setState({ quantity: value, quantityError: "" })
                }
                value={this.state.quantity}
                error={this.state.quantityError}
              />
            </div>
            <div className="col-md-3">
              <br />
              <button
                type="button"
                className="btn btn-default btn-md pull-left btn-flat"
                onClick={this.onAddRawMaterial}
              >
                Add to list
              </button>
            </div>
          </div>
        )}

        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th style={{ width: "45%" }}>Raw Material</th>
              <th>Supply Price</th>
              <th>Qty Consumed</th>
              <th>Unit</th>
              <th>Cost</th>
              <th style={{ width: "50px" }}></th>
            </tr>
          </thead>
          <tbody>
            {this.state.bomProducts.map((bomProduct, index) => (
              <tr>
                <td>
                  {bomProduct.Product}
                  <div>{bomProduct.ProductSKU}</div>
                </td>
                <td style={{ textAlign: "right" }}>
                  {bomProduct.SupplyPrice ? bomProduct.SupplyPrice : 0}
                </td>
                <td style={{ textAlign: "right" }}>{bomProduct.Qty}</td>
                <td style={{ textAlign: "right" }}>{bomProduct.ProductUOM}</td>
                <td style={{ textAlign: "right" }}>
                  {bomProduct.SupplyPrice
                    ? Number(
                        Number(bomProduct.SupplyPrice) * Number(bomProduct.Qty)
                      ).toFixed(2)
                    : 0}
                </td>
                <td>
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-default btn-md"
                      onClick={() => this.onRemoveRawMaterial(index)}
                    >
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ textAlign: "right" }}>
                <b>{this.state.totalCost}</b>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </Form>
    );
  }
}

export default AddEditBOM;
