import React, { Component } from 'react'
import HeaderNew from '../HeaderNew';
import PopupMenu from '../Common/PopupMenu';
import ActionButton from '../Common/ActionButton'
import Confirmation from '../Common/Confirmation';
import Modal from 'react-responsive-modal'

class CashRegisterList extends Component {

    constructor(){
        super()
        this.state = {
            cashRegisters:[],
            searchText:'',
            selectedCashRegister:null,
            showPopupMenu:false,
            module:'',
            store:null
        }
        this.loadCashRegisters = this.loadCashRegisters.bind(this)
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onRowClick= this.onRowClick.bind(this)
        this.onHeaderBackClick =this.onHeaderBackClick.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this)
    }

    async componentDidMount() {

        
        this.setState({module:this.props.location.state.module, store: this.props.location.state.store})
        this.loadCashRegisters(this.props.location.state.store.key)

    }

    async loadCashRegisters(storeID)
    {
        let cashRegisters = await this.props.getCashRegisters(storeID)
        this.setState({cashRegisters:cashRegisters.filter(f=> !f.IsDeleted)})
    }

    onHeaderBackClick(){
        if(this.state.module === "payment")
        {
            this.props.history.push({ pathname: '/home'})   
        }
        else
        {
            this.props.history.push({ pathname: '/home/settings/stores'})   
        }
    }
    
    async onRowClick(cashRegister)
    {
        if(this.state.module === "store")
        {
            this.setState({selectedCashRegister:cashRegister, showPopupMenu:true})
        }
        else
        {
            this.props.history.push({ pathname: '/home/settings/crpaymentproviders', state:{cashRegister:cashRegister} })
        }
    }


    async onShowPopupMenuClick(value) {

        if (value === "edit") {
            this.setState({showPopupMenu:false, selectedCashRegister:null})
            this.props.history.push({ pathname: "/home/settings/credit", state: { module:'cashregister',store: this.state.store, storeID: this.state.store.key , cashRegister:this.state.selectedCashRegister}})
        }
        else if (value === "delete") {
            this.setState({showDelete:true, showPopupMenu:false});
        }
        else
        {
            this.setState({showPopupMenu:false, selectedCashRegister:null})
        }
    }

    async onActionButtonClick(action) {
        let cashRegisterCount = this.state.cashRegisters.filter((f) => !f.IsDeleted).length;
        if (this.state.store.hasOwnProperty('NoOfCashRegistersAllowed') && this.state.store.NoOfCashRegistersAllowed > cashRegisterCount) {
            this.props.history.push({ pathname: '/home/settings/crnew', state: { module: 'cashregister', storeID: this.state.store.key,  store:this.state.store} })       
        }
        else {
            this.setState({ showError: true, cashRegisterError: 'Additional CashRegister cannot be added as limit has exceeded.' });
        }
    }
    
    onDeleteConfirmation(value)
    {   
        if(value)
        {
            // let object= this.state.selectedLocation;
            
            this.props.deleteCashRegister(this.state.selectedCashRegister.key).then(() => {
                    this.props.getToastr("Cashregister deleted successfully");
                    this.setState({ loading: true, showDelete:false })
                    this.loadCashRegisters(this.state.store.key)
            })
        }
        else
        {
            this.setState({showDelete:false});
        }
       
    }

    render(){
        return <React.Fragment>

        <HeaderNew {...this.props}
        back={true}
        title="Cash Register List"
        filter={false}
        onBackClick={() => this.onHeaderBackClick()}
        />
    
        <div className="header-new-background"/>

        <div className="content-wrapper">

            <div className="container">

                {/* <ListSearch 
                    value = {this.state.searchText}
                    onChange={(searchText) => {this.setState({ searchText: searchText })}}
                    searchPlaceHolder="Search cash register"/> */}

                <div>

                <br/>
                
                <div className="form-group">
                    <label>Store Code</label>
                    <input type="text" className="form-control"
                            value={this.state.store ? this.state.store.StoreCode : ''}
                        />
                </div>

                <div className="form-group">
                    <label>Store Name</label>
                    <input type="text" className="form-control"
                            value={this.state.store ? this.state.store.StoreName: ''}
                        />
                </div>

                {this.state.cashRegisters.filter(f=> {
                        let searchText = this.state.searchText;
                        if(searchText.length === 0)
                        {return true}
                        else
                        {
                            if(f.CashRegisterName.toLowerCase().includes(searchText.toLowerCase())
                            || f.CashRegisterCode.toLowerCase().includes(searchText.toLowerCase()))
                            {return true } 
                        }
                        return false
                    }).map((cr) => <div className="list-row seperator" 
                    onClick={()=> {this.onRowClick(cr);}}>
                    <div>   
                        <div className="row-title">{cr.CashRegisterCode + ' - ' + cr.CashRegisterName}</div>
                    </div>
                </div>)}

                </div>

            </div>

        </div>

        {(this.state.module === "store") && <ActionButton
                    onClick={(action) => this.onActionButtonClick(action)}
                    fabButtons={this.state.fabButtons} />}

        <PopupMenu 
            show={this.state.showPopupMenu} 
            buttons ={ [{value: "delete", text: "Delete", module: 'Location'}, 
            {value: "edit", text: "Edit", module: 'Location'}]}
            onClick={(action)=> this.onShowPopupMenuClick(action)}/>

        <Confirmation show={this.state.showDelete} onClick={(value)=>this.onDeleteConfirmation(value)}/>

        <Modal open={this.state.showError} onClose={() => this.setState({ showError: false })} center>
            <div style={{ width: "300px" }}>
                <h3 style={{ marginTop: "0px" }}>Error</h3>
                <div className="box-body">
                    <ul>
                        {this.state.cashRegisterError !=="" && <li>{this.state.cashRegisterError}</li>}
                    </ul>
                </div>
                <div className="box-footer">
                    <button type="submit" className="btn btn-flat btn-md btn-danger pull-right" 
                    onClick={() => this.setState({showError:false})}>OK</button>
                </div>
            </div>
        </Modal>

        </React.Fragment>
    }
}

export default CashRegisterList

