import React from 'react'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'

class AddEditTables extends React.Component {
    constructor() {
        super()
        this.state = {
            temptable: '',
            tempSequenceNo: '',
            tempSales: false,
            tempExpense: false,
            tempIsSystemDefined: false,
            tempOTPVerification: false,
            temptableError: '',
            tempSalesExpenseError: '',
            tempSequenceNoError: '',
            key: '',
            title: "New Table",
            saving: false
        }
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {
        if (this.props.table) {
            this.setState({
                title: "Edit Table",
                temptable: this.props.table.table,
                tempSequenceNo: this.props.table.SequenceNo,
                key: this.props.table.key
            })
        }
    }

    async onSave() {
        this.setState({ saving: true })
        let temptableError = ""
        let tempSequenceNoError = ""
        if (this.state.temptable.trim() === "") {
            temptableError = 'Please provide table'
        }
        if (this.state.tempSequenceNo.toString().trim() === "") {
            tempSequenceNoError = 'Please provide sequence no'
        }
        
        if (temptableError !== "" || tempSequenceNoError !== "") {
            this.setState({
                saving: false,
                temptableError: temptableError,
                tempSequenceNoError: tempSequenceNoError
            })
            return
        }

        let isUnique = await this.props.checkIfTableIsUnique(this.state.temptable, this.props.user.store.key);

        if (!isUnique) {
            temptableError = 'Table already exists'
            this.setState({ temptableError: temptableError, saving: false })
            return
        }

        let table = {
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            Table: this.state.temptable,
            SequenceNo: this.state.tempSequenceNo,
            IsDeleted: false
        }

        this.props.saveTable(table, this.state.key).then(() => {
            this.props.getToastr("Table saved successfully")
            this.props.onSave()
        })
    }

    formControls() {
        return <React.Fragment>
            <div className={this.state.temptableError !== "" ? "form-group has-error" : "form-group "} >
                <label>Table</label>
                <input type="text" className="form-control"
                    onKeyPress={this.handleKeyPress}
                    onChange={(evt) => this.setState({ temptable: evt.target.value, temptableError: "" })}
                    value={this.state.temptable}
                    autoFocus
                />
                {this.state.temptableError !== "" && <span className="help-block">{this.state.temptableError}</span>}
            </div>
            <div className={this.state.tempSequenceNoError !== "" ? "form-group has-error" : "form-group "} >
                <label>Sequence No</label>
                <input type="number" className="form-control"
                    style={{ width: "90px" }}
                    onKeyPress={this.handleKeyPress}
                    onChange={(evt) => this.setState({ tempSequenceNo: Number(evt.target.value), tempSequenceNoError: "" })}
                    value={this.state.tempSequenceNo}
                />
                {this.state.tempSequenceNoError !== "" && <span className="help-block">{this.state.tempSequenceNoError}</span>}
            </div>
        </React.Fragment>
    }

    render() {
        return <div>
            {this.props.isMobileApp() && <React.Fragment>
                <HeaderNew {...this.props}
                    back={true}
                    title={this.state.title}
                    onBackClick={() => { this.props.onSave() }}
                />
                <div className="header-new-background" />
                <div className="content-wrapper">
                    <div className="container">
                        <br />
                        {this.formControls()}
                        <br />
                        <br />
                        <br />
                        <br />
                        <Footer onClick={() => this.onSave()} />
                    </div>
                </div>
            </React.Fragment>}

            {!this.props.isMobileApp() && <React.Fragment>
                <h3 style={{ marginTop: "0px" }}>{this.state.title}</h3>
                <div className="box-body">
                    {this.formControls()}
                </div>
                <div className="box-footer">
                    <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.onClose()} >Cancel</button>
                    <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={() => this.onSave()}>OK</button>
                </div>
            </React.Fragment>}
        </div>
    }
}

export default AddEditTables