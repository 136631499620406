import React, { Component } from 'react';
import Icon from '../../2/Controls/Icon';

class WidgetTotalTransactions extends Component {

    constructor({stores, fromDate, toDate, widget}) {
        super();
        this.state = {
            total_transactions:0,
            total_qty:0,
            stores:stores,
            fromDate:fromDate,
            toDate:toDate,
            widget:widget
        }
    }

    async componentDidMount() {
        this.loadData(this.state.stores, this.state.fromDate, this.state.toDate)
    }

    loadData(stores, fromDate, toDate){
        this.props.getDashboardHeaders(this.props.user.user.RegistrationID,stores, "",
        "", fromDate, toDate)
        .then((result) => {
            this.setState({ total_transactions: result.searchResult.total_transactions,
                total_qty:result.searchResult.total_qty })
        });
    }

    componentWillReceiveProps({stores, fromDate, toDate}) {
         this.loadData(stores, fromDate, toDate)
      }

      render(){
          return <div className="info-box info-red">
          <div className="info-box-icon ">
              <Icon type="list"/>
          </div>
          <div className="info-box-content">
              <div className="info-box-text">{this.state.widget && this.state.widget.hasOwnProperty('Title') && this.state.widget.Title.trim()!=="" ? this.state.widget.Title : "Transactions"}</div>
              <div className="info-box-number">{Number(this.state.total_transactions).toFixed(0)} </div>
              {/* <div>Qty: {this.state.dashBoardHeader.total_qty}</div> */}
          </div>
      </div>
      }
    // render() {
    //     return <div className="col-md-3 col-sm-6 col-xs-12">
    //         <div className="info-box" style={{ marginTop: "10px" }}>
    //             <span className="info-box-icon " style={{backgroundColor:this.state.widget && this.state.widget.hasOwnProperty('WidgetOptionLeftBlockColor') && this.state.widget.WidgetOptionLeftBlockColor.trim()!=="" ? this.state.widget.WidgetOptionLeftBlockColor : "#00a65a"}}>
    //             <i style={{color:"white"}} className={this.state.widget && this.state.widget.hasOwnProperty('WidgetOptionLeftBlockIcon') && this.state.widget.WidgetOptionLeftBlockIcon.trim()!=="" ? this.state.widget.WidgetOptionLeftBlockIcon : "fa fa-file-o"} ></i></span>
    //             <div className="info-box-content">
    //                 <span className="info-box-text" style={{ fontSize: "13px" }}>{this.state.widget && this.state.widget.hasOwnProperty('Title') && this.state.widget.Title.trim()!=="" ? this.state.widget.Title : "Transactions"}</span>
    //                 <span className="info-box-number">{Number(this.state.total_transactions).toFixed(0)}</span>
    //                 <div>Qty: {this.state.total_qty}</div>
    //             </div>
    //         </div>
    //     </div>
    // }
}

export default WidgetTotalTransactions