import React, { Component } from 'react'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import ActionButton from '../Common/ActionButton'
import RecordNotFound from '../Common/RecordNotFound';
import PopupMenu from '../Common/PopupMenu';
import Confirmation from '../Common/Confirmation';

class CashRegisterPaymentProvider extends Component {

    constructor(){
        super()
        this.state={
            cashRegister:null,
            title:'Cash Register - Payment Providers',
            cashRegisterPaymentSetup:[],
            selectedPaymentModeIndex:-1,
            showPopupMenu:false,
            showDelete:false
        }

        this.onHeaderBackClick  = this.onHeaderBackClick.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this)
        
    }

    componentDidMount() {

        let cashRegister = this.props.location.state.cashRegister
        let cashRegisterPaymentSetup = cashRegister.hasOwnProperty('CashRegisterPaymentSetup') ? cashRegister.CashRegisterPaymentSetup : []
        this.setState({cashRegister:cashRegister, cashRegisterPaymentSetup:cashRegisterPaymentSetup})
      
    }


    onHeaderBackClick(){
        this.props.history.push({ pathname: '/home/settings/crlist'}) 
    }

    onActionButtonClick(){
        this.props.history.push({ pathname: '/home/settings/crpaymentprovidersnewedit', state:{cashRegister:this.state.cashRegister, selectedPaymentModeIndex:-1}}) 
    }    

    onRowClick(index)
    {
        this.setState({selectedPaymentModeIndex:index, showPopupMenu:true})
    }

    async onShowPopupMenuClick(value) {
        if(value ==="edit")
        {
            this.setState({showPopupMenu:false, selectedPaymentModeIndex:-1})
            this.props.history.push({ pathname: '/home/settings/crpaymentprovidersnewedit', state: {selectedPaymentModeIndex: this.state.selectedPaymentModeIndex, cashRegister: this.state.cashRegister} })
        }
        else if (value === "delete") {
            this.setState({showDelete:true, showPopupMenu:false});
        }
        else{
            this.setState({showPopupMenu:false})
        }
    }

    onDeleteConfirmation(value)
    {   
        if(value)
        {
            let cashRegisterPaymentSetup = this.state.cashRegisterPaymentSetup
            cashRegisterPaymentSetup.splice(this.state.selectedPaymentModeIndex, 1)
            let cashRegister = this.state.cashRegister
            cashRegister.CashRegisterPaymentSetup = cashRegisterPaymentSetup
            this.props.updateCashRegister(cashRegister, cashRegister.key).then(()=> {
                this.props.getToastr("Deleted Successfully")
                this.setState({cashRegisterPaymentSetup:cashRegisterPaymentSetup, showDelete:false})  
                console.log('showDelete')
            })
        }
        else
        {
            this.setState({showDelete:false});
        }
       
    }

    render() {

        return <React.Fragment>

            <HeaderNew {...this.props}
                back={true}
                title={this.state.title}
                onBackClick={() => this.onHeaderBackClick()}
            />

            <div className="header-new-background"></div>

            <div className="content-wrapper" >

                <div className="container">

                    <br/>
                
                    <div className="form-group">
                        <label>Cash Register Code</label>
                        <input type="text" className="form-control"
                                value={this.state.cashRegister ? this.state.cashRegister.CashRegisterCode : ''}
                            />
                    </div>

                    <div className="form-group">
                        <label>Cash Register Name</label>
                        <input type="text" className="form-control"
                                value={this.state.cashRegister ? this.state.cashRegister.CashRegisterName: ''}
                            />
                    </div>

                    <label>Mapped Payment Provider(s)</label>
                    {this.state.cashRegisterPaymentSetup.length>0 && <div className="list no-top-padding">
                        {this.state.cashRegisterPaymentSetup.map((m,index) => <div className="list-row seperator"  onClick={() => this.onRowClick(index)}>
                            <div>
                                <div className="row-title">{m.PaymentProviderName}</div>
                                {m.PaymentModes.map(m=><div className="row-sub-text">{m.PaymentType}</div>)}
                            </div>
                        </div>)}
                    </div>}

                    {this.state.cashRegisterPaymentSetup.length===0 && <div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <RecordNotFound title="Payment provider not mapped" text="Please click + button to map payment provider to selected cash register"/>
                    </div>}

                </div>
            
            </div>

            <ActionButton 
                onClick={(action)=>this.onActionButtonClick(action)}
                fabButtons={[]}/>

             <PopupMenu 
            show={this.state.showPopupMenu} 
            buttons ={ [{ value: "delete", text: "Delete", module: ''}, 
            { value: "edit", text: "Edit", module: '' }]}
            onClick={(action)=> this.onShowPopupMenuClick(action)}/>

            <Confirmation show={this.state.showDelete} onClick={(value)=>this.onDeleteConfirmation(value)}/>

    </React.Fragment>}

}

export default CashRegisterPaymentProvider