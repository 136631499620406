import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import Pagination from 'react-js-pagination'
import ReactHtmlParser from 'react-html-parser';
import Loader from '../Loader'

class ProfitLossReport extends Component {

    constructor() {
        super()
        this.state = {
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            itemSold: [],
            stores: [],
            storeList: [],
            searchText: '',
            searchCategory: '',
            searchSubCategory: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchProductSKU: '',
            searchProductName: '',
            searchBrand: '',
            exportCalled: false,
            loading: true,
            searchProductCustomField1: '',
            searchProductCustomField2: '',
            searchProductCustomField3: '',
            searchProductCustomField4: '',
            searchProductCustomField5: '',
            searchProductCustomField6: '',
            storeSpecificBreakup: true
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.loadItemSoldReportData = this.loadItemSoldReportData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.calculateSupplyPriceTax= this.calculateSupplyPriceTax.bind(this)
        this.roundUpTo2 = this.roundUpTo2.bind(this);
        this.loadData = this.loadData.bind(this);

        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
    }

    componentDidMount() {

        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Product') {
                let productCustomField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let productCustomField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let productCustomField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let productCustomField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let productCustomField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let productCustomField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    productCustomField1DisplayName: productCustomField1DisplayName,
                    productCustomField2DisplayName: productCustomField2DisplayName,
                    productCustomField3DisplayName: productCustomField3DisplayName,
                    productCustomField4DisplayName: productCustomField4DisplayName,
                    productCustomField5DisplayName: productCustomField5DisplayName,
                    productCustomField6DisplayName: productCustomField6DisplayName
                })
            }
        })


        this.loadItemSoldReportData(storeList, this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate, this.state.searchCategory,
            this.state.searchSubCategory, this.state.searchProductSKU,
            this.state.searchProductName, this.state.searchBrand, 
            this.state.searchProductCustomField1, this.state.searchProductCustomField2, 
            this.state.searchProductCustomField3, this.state.searchProductCustomField4, 
            this.state.searchProductCustomField5, this.state.searchProductCustomField6,
            this.state.storeSpecificBreakup
            );
    }

    async loadData(storeList, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchSubCategory,
        searchProductSKU, searchProductName, searchBrand, 
        searchProductCustomField1, searchProductCustomField2, searchProductCustomField3, searchProductCustomField4, 
        searchProductCustomField5, searchProductCustomField6 , searchStoreSpecific)
    {
        this.setState({ loading: true });

        let pandlData = await this.props.getProfitandLossReport(this.props.user.user.RegistrationID, storeList, searchTransactionToDate,
        searchTransactionFromDate, searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand, searchProductCustomField1, searchProductCustomField2, searchProductCustomField3, searchProductCustomField4, searchProductCustomField5, searchProductCustomField6, searchStoreSpecific)
        // .then((res) => {
        //     return res.searchResult;
        // })
        console.log('pandlData',pandlData)
        let productIDs = [];
        let finalProducts = [];

        if (pandlData && pandlData.length > 0) {
            let products = []
            pandlData.map((data) => {
                if(this.props.config.Products.filter(f=> f.key ===data.productID).length > 0 ){
                    if(products.filter(f=>f.key===data.productID).length ===0){
                        let tmp = this.props.config.Products.filter(f=> f.key ===data.productID)[0];
                        products.push({
                            key:tmp.key,
                            brand: tmp.Brand,
                            category: tmp.Category,
                            sub_category: tmp.SubCategory,
                            product_type:tmp.ProductType,
                            sku: tmp.SKU,
                            product: tmp.Name,
                            taxInclusivePurchase:tmp.TaxInclusivePurchase,
                            taxID:tmp.TaxID,
                            supplyprice:tmp.SupplyPrice
                        })
                    }    
                }
                else{
                    console.log('product not found locally get from server')
                    productIDs.push(data.productID);
                }
                
            });
            let productsTmp=[]
            if(productIDs.length > 0)
            {
                console.log('proucts not found', productIDs)
                let resultProduct = await this.props.getProductsByProductIDs(productIDs);
                productsTmp = resultProduct.searchResult;
            }
            productsTmp.map((p)=>{
                products.push(p);
            })

            console.log("products",products);
            // console.log("pandlData",pandlData);

            for (let i = 0; i < pandlData.length; i++) {

                const obj = pandlData[i];
                // console.log(obj.productID);

                for (let j = 0; j < products.length; j++) {

                    const obj1 = products[j];
                    // console.log(obj1.key);

                    if (obj.productID === obj1.key) {
                        console.log('obj1',obj1)
                        let objProduct = {};
                        let supplyAmountWithoutTax = 0
                        let supplyAmountWithTax = 0 
                        let supplyTaxAmount = 0 
                        let taxInclusivePurchase = obj1.taxInclusivePurchase
                        let supplyAmount = Number(Number(obj.total_quantity) * Number(obj1.supplyprice)).toFixed(2)
                        let taxID = obj1.taxID
                        let response = await this.calculateSupplyPriceTax(supplyAmount, taxID, taxInclusivePurchase)
                        console.log(response)
                        supplyTaxAmount = response.supplyTaxAmount
                        supplyAmountWithoutTax= response.supplyAmountWithoutTax
                        supplyAmountWithTax = response.supplyAmountWithTax

                        objProduct["Store Name"] = obj.store ? obj.store : ''
                        objProduct["Brand"] = obj1.brand
                        objProduct["Category"] = obj1.category
                        objProduct["Sub Category"] = obj1.sub_category
                        objProduct["Product Type"] = obj1.product_type
                        objProduct["SKU"] = obj1.sku
                        objProduct["Product Name"] = obj1.product
                        objProduct["Qty"] = obj.total_quantity
                        objProduct["Discount"] = obj.total_discount
                        objProduct["Sales Taxable Amount"] = obj.total_taxable
                        objProduct["Sales Total Tax"] = obj.total_taxes
                        objProduct["Total Sales Amount"] = obj.total_sales
                        objProduct["Purchase Taxable Amount"] = supplyAmountWithoutTax
                        objProduct["Purchase Total Tax"] = supplyTaxAmount
                        objProduct["Total Purchase Amount"] = supplyAmountWithTax
                        objProduct["Profit / Loss (Before Tax)"] = Number(Number(obj.total_taxable) - Number(supplyAmountWithoutTax)).toFixed(2);
                        objProduct["Profit / Loss (After Tax)"] = Number(Number(obj.total_sales) - Number(supplyAmountWithTax)).toFixed(2);
                        finalProducts.push(objProduct);
                    }
                }
            }
        }

        return finalProducts;
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }

    async calculateSupplyPriceTax(supplyAmount,taxID, taxInclusive )
    {
        let supplyTaxAmount =0
        let supplyAmountWithoutTax =0
        let supplyAmountWithTax=0

        if(this.props.config.ProductTaxes.filter((t) => t.key === taxID.trim()).length===0)
        {
            supplyTaxAmount=0
            supplyAmountWithoutTax = supplyAmount
            supplyAmountWithTax = supplyAmount
            return {
                supplyTaxAmount:supplyTaxAmount,
                supplyAmountWithoutTax :supplyAmountWithoutTax,
                supplyAmountWithTax:supplyAmountWithTax
            } 
        }
        let taxComponentTemp = this.props.config.ProductTaxes.filter((t) => t.key === taxID.trim())[0]
        if (taxInclusive) {//tax inclusive
            let totalTaxRate = 0
            supplyAmountWithTax = supplyAmount
            taxComponentTemp.Taxes.map((t) => {
                if(t.TaxType !=="IGST")
                {
                    totalTaxRate = this.roundUpTo2(Number(t.TaxPercentage) + Number(totalTaxRate))
                }
            })
            supplyTaxAmount = this.roundUpTo2(Number(supplyAmountWithTax) * Number(totalTaxRate) / (100 + Number(totalTaxRate)))
            supplyAmountWithoutTax = this.roundUpTo2(Number(supplyAmountWithTax) - Number(supplyTaxAmount))
            
        }
        else {
            supplyAmountWithoutTax = supplyAmount
            taxComponentTemp.Taxes.map((t) => {
                if(t.TaxType !=="IGST")
                {
                    supplyTaxAmount = this.roundUpTo2(Number(supplyTaxAmount) + Number(this.roundUpTo2((Number(supplyAmountWithoutTax) * Number(t.TaxPercentage)) / 100)))
                }
            })
            supplyAmountWithTax = this.roundUpTo2(Number(supplyAmountWithoutTax) + Number(supplyTaxAmount))
        }

        return {
            supplyTaxAmount:supplyTaxAmount,
            supplyAmountWithoutTax :supplyAmountWithoutTax,
            supplyAmountWithTax:supplyAmountWithTax
        }
    }

    async loadItemSoldReportData(storeList, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchSubCategory,
        searchProductSKU, searchProductName, searchBrand, 
        searchProductCustomField1, searchProductCustomField2, searchProductCustomField3, searchProductCustomField4, 
        searchProductCustomField5, searchProductCustomField6 , searchStoreSpecific) {
   
        let finalProducts = await this.loadData(storeList, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchSubCategory,
        searchProductSKU, searchProductName, searchBrand, 
        searchProductCustomField1, searchProductCustomField2, searchProductCustomField3, searchProductCustomField4, 
        searchProductCustomField5, searchProductCustomField6 , searchStoreSpecific);
        this.renderTable(finalProducts, 0)
        this.setState({finalProducts:finalProducts, totalItemsCount:finalProducts.length, activePage:1, recordFrom:0})
    }

    renderTable(finalProducts, from){
        // console.log('from',from)
        // console.log('finalProducts', finalProducts)
        var col = [];
        for (var i = 0; i < finalProducts.length; i++) {
            for (var key in finalProducts[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

   


        let recordToDisplayCount = 0;
        if(finalProducts.length < (from + this.state.itemsCountPerPage))
        {
            recordToDisplayCount = finalProducts.length;
        }else{
            recordToDisplayCount = this.state.itemsCountPerPage + from;
        } 
        // console.log('recordToDisplayCount', recordToDisplayCount);
        let recordsToDisplay = [];
        for(let i=from; i<recordToDisplayCount;i++){
            recordsToDisplay.push(finalProducts[i])
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < recordsToDisplay.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result += "<td>";
                result += recordsToDisplay[i][col[j]] == undefined ? 0 : recordsToDisplay[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }
        result += "</tbody>";
        result += "</table>";
        
        // console.log('recordsToDisplay', recordsToDisplay)
        // console.log('result', result)
        this.setState({ itemSold: result, loading: false })
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.renderTable(this.state.finalProducts, recordFrom);
    }

    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            storeList: storeList, storeID: this.props.user.store.key,
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0], searchCategory: '', searchSubCategory: '',
            searchProductSKU: '', searchProductName: '', searchBrand: '', searchProductCustomField1: '', searchProductCustomField2: '', searchProductCustomField3: '',
            searchProductCustomField4: '', searchProductCustomField5: '', searchProductCustomField6: '', storeSpecificBreakup: true
        })
        this.loadItemSoldReportData(storeList, new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "", "", "", "", "", "", "", "", "", "",true);
    }

    async onExportClick() {
        
        // let finalProducts = await this.loadData(this.state.storeList, this.state.searchTransactionToDate, this.state.searchTransactionFromDate, this.state.searchCategory, this.state.searchSubCategory,
        //     this.state.searchProductSKU, this.state.searchProductName, this.state.searchBrand,
        //     this.state.searchProductCustomField1, this.state.searchProductCustomField2, this.state.searchProductCustomField3, this.state.searchProductCustomField4,
        //     this.state.searchProductCustomField5, this.state.searchProductCustomField6, this.state.searchStoreSpecific);

        this.setState({ loading: false })

        const csvData = constatnts.objToCSV(this.state.finalProducts);
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "ProfitandLoss.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    onSearchClick() {
        this.setState({ loading: true })
        this.loadItemSoldReportData(
            this.state.storeList,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand,
            this.state.searchProductCustomField1, this.state.searchProductCustomField2, this.state.searchProductCustomField3, 
            this.state.searchProductCustomField4, this.state.searchProductCustomField5, this.state.searchProductCustomField6,
            this.state.searchStoreSpecific
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e) {
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Profit &amp; Loss Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            <option value="">All Stores</option>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchBrand}
                                                onChange={(evt) => this.setState({ searchBrand: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{ padding: "0px" }}>
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductSKU}
                                                onChange={(evt) => this.setState({ searchProductSKU: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductName}
                                                onChange={(evt) => this.setState({ searchProductName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField1DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField1}
                                                onChange={(evt) => this.setState({ searchProductCustomField1: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField2DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField2}
                                                onChange={(evt) => this.setState({ searchProductCustomField2: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField3DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField3}
                                                onChange={(evt) => this.setState({ searchProductCustomField3: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField4DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField4}
                                                onChange={(evt) => this.setState({ searchProductCustomField4: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField5DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField5}
                                                onChange={(evt) => this.setState({ searchProductCustomField5: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField6DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField6}
                                                onChange={(evt) => this.setState({ searchProductCustomField6: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    {ReactHtmlParser(this.state.itemSold)}
                                </div>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(ProfitLossReport)