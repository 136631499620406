import firebase, { database } from '../firebase';

// export function getOpenTableOrders(storeID){
//     // return (dispatch) => {
//     return database.collection("Sales").where("Store.key","==",storeID).where("TableOrder","==", true).get().then((querySnapshot) => {
//         let records = []
//         querySnapshot.forEach((doc) => {
//             // console.log(doc)
//             if(doc.data().hasOwnProperty('TransactionStatus') && (doc.data().TransactionStatus ==="Pending" || doc.data().TransactionStatus ==="PrintBill"))
//             {
//                 // .where("TransactionStatus","==","Pending")
//                 records.push( { key:doc.id,...doc.data()})
//             }
//         })
//         return records
//      })
//     // }
// }



export function getOpenTableOrders(storeID){
    let searchParameters = {
        "from": 0,
        "size": 1000,
        "track_total_hits": true,
        "query": {
            "bool": {
            "must": [
                {
                "match": {
                    "Store.key": storeID
                }
                }
            ],
            "must_not": [
                {
                "match_phrase": {
                    "TransactionStatus": "Completed"
                }
                },
                {
                "exists": {
                    "field": "CancellationReason"
                }
                }
            ]
            }
        },
        "sort": [
            {
                "TransactionDate": {
                    "order": "desc"
                }
            }
        ]
    }
    // return (dispatch) => {
         let addMessage = firebase.functions().httpsCallable('getSales');
        return addMessage({ text: searchParameters }).then(function (result) {
            const invoices = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                // console.log("data", JSON.stringify(data))
                invoices.push({ key: data._id, ...data._source })
            })
            // console.log("Invoices", JSON.stringify(sanitizedMessage.hits.total.value))
            return invoices 
        });
    // }
}
