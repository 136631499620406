import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import '../Common/Common.css'
import RecordNotFound from '../Common/RecordNotFound';
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import alertSound from '../../audio/audio/electronic-school-bell-81033.mp3';

const audio = new Audio(alertSound);
let  refreshIntervalId = null;

class KDS extends Component {

    constructor() {
        super()
        this.state = {
            searchType: 'Pending',
            orders: [],
        }
        this.loadKOTOrders = this.loadKOTOrders.bind(this);
        this.onSearchType = this.onSearchType.bind(this);
        this.updateKOTStatus = this.updateKOTStatus.bind(this);
        this.handleStartStop = this.handleStartStop.bind(this);
        this.fecthOrders = this.fecthOrders.bind(this);
    }

    componentDidMount() {
        this.handleStartStop();
        this.loadKOTOrders(this.state.searchType, true);
    }

    async loadKOTOrders(option, initialLoad = false) {
        try
        {
            let orders = await this.props.getKOTS(option);
            // console.log("orders",orders);
            // console.log("initialLoad",initialLoad);
            //play music if new kot
            if(!initialLoad && this.state.searchType === option && orders.length>0){
                // console.log("check for dound")
                let playSound = false;
                orders.map(o=>{
                    if(this.state.orders.filter(f=> f.key === o.key).length ===0){
                        //play sound 
                        console.log('order because of which sound is playing', o);
                        playSound= true;
                    }
                })
                console.log("playSound",playSound)
                if(playSound){
                    //make some noise for desi boys
                    audio.play(); 
                    setTimeout(function(){
                        audio.pause();
                    }, 2000)
                }
            }
            this.setState({ orders: orders, searchType: option});
        }
        catch (error){
            audio.pause();
        }
        
    }

    onSearchType(option) {
        this.loadKOTOrders(option);
    }

    async updateKOTStatus(key, status) {
        await this.props.updateKOTStatus(key, status);
        this.loadKOTOrders(this.state.searchType);
    }

    //  setInterval(fname, 10000);
    handleStartStop() {
        refreshIntervalId = setInterval(this.fecthOrders, 10000);
    }

    fecthOrders() {
        this.loadKOTOrders(this.state.searchType);
    }

    componentWillUnmount(){
        console.log("KDS componet is umounted");
        clearInterval(refreshIntervalId);
    }

    render() {
        return   <Page 
        title={"KOT - Kitchen Display"}
        showSaveButton={false} 
        showCancelButton={false}
        processing={this.state.saving}
        onClick={(action)=>{}}
        >   
           <PageSection title="">
                <div className="row">
                    <div className="col-md-12">
                        <div className="btn-group pull-right" style={{ paddingRight: "10px" }}>
                            <button type="button" className={this.state.searchType === "Pending" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                onClick={() => this.onSearchType("Pending")}>Pending</button>
                            <button type="button" className={this.state.searchType === "Ready" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                onClick={() => this.onSearchType("Ready")}>Ready</button>
                            <button type="button" className={this.state.searchType === "Completed" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                onClick={() => this.onSearchType("Completed")}>Completed</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                                
                                {this.state.orders.length===0 && <RecordNotFound/>}
                                {this.state.orders.map((m, index) => <div key={index} className="col-md-3" style={{ width: "20%" }}>
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            <h3 className="panel-title">
                                                <span style={{ fontSize: "30px" }}>KOT: {m.KOTNo}</span>
                                                <span className="pull-right">
                                                    {new Date(m.TransactionDate.seconds * 1000).toLocaleString('en-US', { timeZone: "Asia/Kolkata", hour: 'numeric', minute: 'numeric', hour12: true })}
                                                </span>
                                            </h3>
                                            <div style={{ width: "70px" }}>
                                                {m.RequestSource && <div className="wallet">{m.RequestSource}</div>}
                                                {m.KOTType && <div className="membership">{m.KOTType}</div>}
                                                {(m.TransactionNo && m.TransactionNo !=="") && <div className='badge'>{m.TransactionNo}</div>}

                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            {m.LineItems.map((l, i) => <div key={i} className="list-row seperator" style={{ border: "none", minHeight: "50px", padding: "0px", minHeight: "30px" }}>
                                                <div style={{ width: "80%" }}>
                                                    <div className="row-sub-text pull-left" >{l.Product.Name}</div>
                                                    {l.Modifiers && l.Modifiers.length > 0 && l.Modifiers.map((p, pi) => [ 
                                                        pi > 0 && ", ",
                                                        <span key={pi} style={{ fontSize: "1rem", fontStyle:"italic" }}>{p.ModifierName}</span>
                                                    ]
                                                    )}
                                                    <br/>
                                                    {l.Notes !== '' && <span style={{ fontSize: "1rem", fontStyle: "italic" }}>{l.Notes}</span>}
                                                </div>
                                                <div style={{ width: "20%", verticalAlign: "top" }}>
                                                    <div className="row-sub-text pull-right"> {l.Qty}</div>
                                                </div>
                                            </div>)}
                                        </div>
                                        <div className="panel-footer" >
                                            <div style={{ textAlign: "center" }}>
                                                {m.Status === 'Pending' && <button className="btn btn-primary" onClick={() => this.updateKOTStatus(m.key, 'Ready')}>Ready</button>}
                                                {m.Status === 'Ready' && <button className="btn btn-primary" onClick={() => this.updateKOTStatus(m.key, 'Completed')}>Served</button>}
                                                {m.Status === 'Completed' && <span><b><i>Completed</i></b></span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                        
                    </div>
            </PageSection>
    </Page>
    }
}

export default withRouter(KDS)
