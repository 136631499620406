import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function getLoyaltyType(registrationID) {
    return (dispatch) => {
        return database.collection("LoyaltyTypes").where("RegistrationID", "==", registrationID).get().then((querySnapshot) => {
            let loyaltyTypes = []
            querySnapshot.forEach((doc) => {
                loyaltyTypes.push({ key: doc.id, ...doc.data() })
                // console.log('loyaltyTypes', loyaltyTypes)
            })
            return loyaltyTypes            
        })
    }
}

export function deleteLoyaltyType(key) {
    return (dispatch, getState) => {
        return database.collection("LoyaltyTypes").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted:true
        }).then(() => { return true })
    }
}

export function saveLoyaltyType(loyaltyType, key) {
    return (dispatch, getState) => {
        loyaltyType.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        loyaltyType.ActionBy = getState().user.user.Name
        loyaltyType.ActionByUID = getState().user.user.UID
        loyaltyType.ActionByEmailID = getState().user.user.EmailID
        
        if (key.trim() !== "") {
            loyaltyType.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("LoyaltyTypes").doc(key).update({
                ...loyaltyType
            }).then(() => {            
                return key
            }).catch(function (error) {
                console.error("Error updating loyalty type: ", error);
            })
        }
        else {
            loyaltyType.Action = constants.AUDIT_TRAIL_NEW 
            return database.collection("LoyaltyTypes").add({
                ...loyaltyType
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding loyalty type: ", error);
                })
        }
    }
}

export function getLoyaltyTypeDetails(key){
    return dispatch => {
    return database.collection("LoyaltyTypes").doc(key).get().then((doc) => {
        if (doc.exists) {
            console.log("Loyalty Type found", doc.data());
           return doc.data()
        } else {
            console.log("No such Loyalty Type!");
        }
    })
    }
}


export function saveLoyaltySetup(loyaltySetup){
    return (dispatch,getState)=>{
        loyaltySetup.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        loyaltySetup.ActionBy = getState().user.user.Name
        loyaltySetup.ActionByUID = getState().user.user.UID
        loyaltySetup.ActionByEmailID = getState().user.user.EmailID
        loyaltySetup.Action = constants.AUDIT_TRAIL_EDIT
        console.log('loyaltySetup',loyaltySetup)
        return database.collection("LoyaltySetup").doc(loyaltySetup.RegistrationID).set({
            ...loyaltySetup
        })
    }
}

export function getLoyaltySetupDetails(registraitonID)
{
    return dispatch => {
        return database.collection("LoyaltySetup").doc(registraitonID).get().then((doc) => {
            if (doc.exists) {
                console.log("Loyalty setup found", doc.data());
               return doc.data()
            } else {
                console.log("No such Loyalty setup!");
            }
        })
        }
}


export function getCustomerLoyaltyPoints(customerID)
{
    return (dispatch) => {
        
            return database.collection("LoyaltyCustomer").where("CustomerID", "==", customerID).get().then((querySnapshot) => {
                let customerLoyalty = null
                querySnapshot.forEach((doc) => {
                    customerLoyalty = doc.data()
                    customerLoyalty.key = doc.id
                })
                return customerLoyalty
            })
    }
}

export function getTotalLoyaltyCustomer(registrationID) {
    let searchParameters =
    {
        "size": 0,
        "query": {
            "match": {
                "RegistrationID": registrationID
            }
        },
        "aggs": {
            "total_customers": {
                "cardinality": {
                    "field": "CustomerID.keyword"
                }
            }
        }
    }
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getLoyaltyCustomer');
        return addMessage({ text: searchParameters }).then(function (result) {
            let sanitizedMessage = result.data.text;
            const loyaltyCustomers = {
                total_customers: Number(sanitizedMessage.aggregations.total_customers.value).toFixed(0),
            }
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: loyaltyCustomers }
        });
    }
};

export function getLoyaltyTypeCustomerCount(registrationID) {
    let searchParameters =
    {
        "size": 0,
        "query": {
            "match": {
                "RegistrationID": registrationID
            }
        },
        "aggs": {
            "loyaltytype": {
                "terms": {
                    "field": "LoyaltyType.keyword",
                    "size": 10
                },
                "aggs": {
                    "totalcustomers": {
                        "cardinality": {
                            "field": "CustomerID.keyword"
                        }
                    }
                }
            }
        }
    }
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getLoyaltyCustomer');
        return addMessage({ text: searchParameters }).then(function (result) {
            let sanitizedMessage = result.data.text;
            let buckets = sanitizedMessage.aggregations.loyaltytype.buckets;
            let loyaltyLedger = [];
            for (let i = 0; i < buckets.length; i++) {

                const bucket = buckets[i];
                console.log("bucket", bucket)
                loyaltyLedger.push(
                    {
                        type: bucket.key,
                        totalcustomers: bucket.totalcustomers.value
                    }
                )
            }
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: loyaltyLedger }
        });
    }
};

export function getLoyaltyPointEarnAndBurn(registrationID) {
    let searchParameters =
    {
        "size": 0,
        "query": {
            "match": {
                "RegistrationID": registrationID
            }
        },
        "aggs": {
            "type": {
                "terms": {
                    "field": "Type.keyword",
                    "size": 10
                },
                "aggs": {
                    "totalpoints": {
                        "sum": {
                            "field": "LoyaltyPoints"
                        }
                    }
                }
            }
        }
    }
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getLoyaltyLedger');
        return addMessage({ text: searchParameters }).then(function (result) {
            let sanitizedMessage = result.data.text;
            let buckets = sanitizedMessage.aggregations.type.buckets;
            let loyaltyLedger = [];
            for (let i = 0; i < buckets.length; i++) {
                const bucket = buckets[i];
                loyaltyLedger.push(
                    {
                        type: bucket.key,
                        totalpoints: bucket.totalpoints.value
                    }
                )
            }
            //console.log("loyaltyLedger", JSON.stringify(loyaltyLedger));
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: loyaltyLedger }
        });
    }
};

export function getMonthWiseLoyaltyPointEarnAndBurn(registrationID) {
    let searchParameters =
    {
        "size": 0,
        "query": {
            "match": {
                "RegistrationID": registrationID
            }
        },
        "aggs": {
            "month": {
                "date_histogram": {
                    "field": "TransactionDate",
                    "interval": "month",
                    "format": "MMM-yyyy"
                },
                "aggs": {
                    "type": {
                        "terms": {
                            "field": "Type.keyword",
                            "size": 10
                        },
                        "aggs": {
                            "loyalty_points": {
                                "sum": {
                                    "field": "LoyaltyPoints"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getLoyaltyLedger');
        return addMessage({ text: searchParameters }).then(function (result) {
            const loyaltyLedger = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.aggregations.month.buckets.map((data, index) => {
                data.type.buckets.map((data1, index) => {
                    let ledger = {}
                    ledger["Month"] = data.key_as_string
                    ledger["Type"] = data1.key
                    ledger["LoyaltyPoints"] = Number(data1.loyalty_points.value).toFixed(2)
                    loyaltyLedger.push(ledger);
                })
            })
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: loyaltyLedger }
        });
    }
};