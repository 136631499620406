import React, { Component } from 'react';
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import BusinessCard from '../Common/BusinessCard'
import { TwitterPicker  } from 'react-color';
import Dropzone from 'react-dropzone'
import Footer from '../Common/Footer'
import * as htmlToImage from 'html-to-image';

class EditBusinessCard extends Component {

    
    constructor()
    {
        super()
        this.state = {
            backgroundColor: 'white',
            color: 'black',
            logoImage: null,
            logoImageURL: '',
            cardImage: null,
            cardImageURL: '',
            key:''
        }
        this.onImageRemove = this.onImageRemove.bind(this)
        this.onImageDrop = this.onImageDrop.bind(this)
        this.onColorSelection = this.onColorSelection.bind(this)
        this.onBackgroundColorSelection = this.onBackgroundColorSelection.bind(this)
        this.onSaveBusinessCard = this.onSaveBusinessCard.bind(this)
    }

    async componentDidMount()
    {
        let businessCard = await this.props.getBusinessCard(this.props.user.user.RegistrationID);

        if(businessCard)
        {
            this.setState({
                key: businessCard.key,
                backgroundColor: businessCard.BackgroundColor,
                color: businessCard.Color,
                logoImage: businessCard.Logo ? businessCard.Logo : null,
                logoImageURL: businessCard.Logo ? businessCard.Logo.Preview: ''
            })
        }
    }

    onColorSelection = (color) => {
        this.setState({ color: color.hex });
    };

    onBackgroundColorSelection =  (color) => {
        this.setState({ backgroundColor: color.hex });
    };

    onImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {
            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/BusinessCard').then((downloadURL) => {
                //console.log(downloadURL);
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                let bucket = 'posible.in/'
                let folder =  this.props.registration.registration.StorageFolder + '/BusinessCard/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + acceptedFiles[0].name
                //console.log(url);
                this.setState({ logoImage: image, logoImageURL: url})// image.Preview })
            })
        }
        else { alert("No file selected") }
    }

    onImageRemove() {
        this.setState({ logoImage: null })
    }

    async onSaveBusinessCard()
    {
        var b64toBlob = require('b64-to-blob');
        let card = document.getElementById('card')
        let cardImage = await htmlToImage.toPng(card)
        let cardParts = cardImage.split(";base64,");
        let cardBase64 = cardParts[1];

        var contentType = 'image/png';
        var b64Data = cardBase64
        var blob = b64toBlob(b64Data, contentType);

        let fileName = + new Date().getTime() + "MyBusinessCard.png";
        await this.props.uploadFileToServer(blob, this.props.registration.registration.StorageFolder + '/BusinessCard', fileName);
        let bucket = 'posible.in/'
        let folder = this.props.registration.registration.StorageFolder + '/BusinessCard/'
        let url = 'https://storage.googleapis.com/' + bucket + folder + fileName
        let image = { Name: fileName, Size: blob.size, Preview: url }

        let businessCard = {
            BackgroundColor: this.state.backgroundColor,
            Color: this.state.color,
            RegistrationID: this.props.user.user.RegistrationID,
            Card: image,
            Logo: this.state.logoImage
        }
        this.props.saveBusinessCard(businessCard, this.state.key).then(() => {
            this.props.getToastr("Business Card saved successfully")
            this.props.history.push("/home/businesscard/")
        })
    }

    render(){
        return <React.Fragment>
             <HeaderNew {...this.props}
                back={true}
                title="Business Card"
                onBackClick={() => { this.props.history.push({ pathname: '/home/businesscard/' }) }}
            />

            <div className="header-new-background"></div>

            <div className="content-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <BusinessCard {...this.props}
                                backgroundColor={this.state.backgroundColor}
                                color={this.state.color}
                                imageURL={this.state.logoImageURL}
                            />
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <label>Background Color</label>
                                <TwitterPicker 
                                    colors={['#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', 
                                    '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', 
                                    '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                    color={this.state.backgroundColor}
                                    width="100%"
                                    onChangeComplete={this.onBackgroundColorSelection}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group">
                                <label>Font Color</label>
                                <TwitterPicker 
                                    colors= {['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00',
                                     '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc',
                                      '#D33115', '#E27300','#AB149E']}
                                    color={this.state.color}
                                    onChangeComplete={this.onColorSelection}
                                    width="100%"
                                />
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-xs-12">
                            {!this.state.logoImage && <div className="form-group">
                                <label>Business Logo</label>
                                <Dropzone onDrop={(files) => this.props.onImageDrop(files)}>
                                    <div>Try dropping some files here, or click to select files to upload.</div>
                                </Dropzone>
                            </div>
                            }
                            {this.state.logoImage && <div className="form-group">
                                <ul className="mailbox-attachments clearfix">
                                    <li>
                                        <span className="mailbox-attachment-icon has-img"><img src={this.state.logoImage.Preview}
                                            alt="Attachment" /></span>
                                        <div className="mailbox-attachment-info">
                                            <span className="mailbox-attachment-name">{this.state.logoImage.Name.length > 20 ? this.state.logoImage.Name.substr(0, 20) + "..." : this.state.logoImage.Name}</span>
                                            <span className="mailbox-attachment-size">
                                                {Math.round(this.state.logoImage.Size / 1024)} KB
                                                        <a onClick={this.onImageRemove} className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>}
                        </div> */}
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <Footer onClick={() => this.onSaveBusinessCard()}/>
            </div>
        </React.Fragment>
    }

}
export default EditBusinessCard

