import * as actionTypes from '../actionTypes';

const intialState= {
    businessNameIsUnique:false,
    registration:null
}
export default function(state=intialState, action) {
    switch (action.type) {
        case actionTypes.CHECK_BUSINESS_NAME_IS_UNIQUE:
            return {businessNameIsUnique:action.businessNameIsUnique, registration:state.registration }
        case actionTypes.GET_REGISTRATION:
            // console.log('reducer.',action.registration)
            return {businessNameIsUnique:state.businessNameIsUnique, registration:action.registration }
            
        default:
            return state;
    }
}
