import React, { Component } from "react";
// import { withRouter } from 'react-router-dom'
import * as constant from "../Constatnts";
// import Modal from 'react-responsive-modal'
import Window from "./2/Controls/Window";

class AuditTrail extends Component {
  constructor() {
    super();
    this.state = {
      showAuditTrail: false,
      auditTrails: [],
      parentKey: "",
      collectionName: "",
    };
    this.loadAuditTrail = this.loadAuditTrail.bind(this);
  }

  componentDidMount() {
    let Module = this.props.module;
    let parentKey = this.props.parentKey;
    let collectionName = "";
    switch (Module) {
      case constant.MODULE_PRODUCT_BRANDS:
        collectionName = "ProductBrandsAuditTrail";
        break;
      case constant.MODULE_PRODUCT_CATEGORIES:
        collectionName = "ProductCategoriesAuditTrail";
        break;
      case constant.MODULE_PRODUCT:
        collectionName = "ProductsAuditTrail";
        break;
      case constant.MODULE_PRODUCT_TAX:
        collectionName = "ProductTaxesAuditTrail";
        break;
      case constant.MODULE_EMPLOYEE:
        collectionName = "EmployeesAuditTrail";
        break;
      case constant.MODULE_VENDOR:
        collectionName = "VendorsAuditTrail";
        break;
      case constant.MODULE_CUSTOMER:
        collectionName = "CustomersAuditTrail";
        break;
      case constant.MODULE_MEMBERSHIP:
        collectionName = "MembershipsAuditTrail";
        break;
      case constant.MODULE_STORE:
        collectionName = "StoresAuditTrail";
        break;
      case constant.MODULE_PAYMENT_TYPE:
        collectionName = "PaymentTypesAuditTrail";
        break;
      case constant.MODULE_EXPENSE:
        collectionName = "ExpensesAuditTrail";
        break;
      case constant.MODULE_EXPENSE_CATEGORY:
        collectionName = "ExpenseCategoriesAuditTrail";
        break;
      case constant.MODULE_REGISTRATION:
        collectionName = "RegistrationAuditTrail";
        break;
      case constant.MODULE_TRANSACTION_TYPE:
        collectionName = "TransactionTypesAuditTrail";
        break;
      case constant.MODULE_CUSTOM_FIELD:
        collectionName = "CustomFieldsAuditTrail";
        break;
      case constant.MODULE_STORE_LOCATION:
        collectionName = "StoreLocationsdAuditTrail";
        break;
      case constant.MODULE_CASH_REGISTER:
        collectionName = "CashRegistersAuditTrail";
        break;
      case constant.MODULE_PRINT_CONFIG:
        collectionName = "PrintConfigsAuditTrail";
        break;
      case constant.MODULE_SALES:
        collectionName = "SalesAuditTrail";
        break;
      case constant.MODULE_ATTENDANCE:
        collectionName = "AttendanceAuditTrail";
        break;
      case constant.MODULE_INVENTORY:
        collectionName = "InventoryAuditTrail";
        break;
      case constant.PRODUCT_TAG:
        collectionName = "ProductTagsAuditTrail";
        break;
      case constant.MODULE_OFFER:
        collectionName = "OfferAuditTrail";
        break;
      case constant.MODULE_PRODUCT_MODIFIER:
        collectionName = "ModifiersAuditTrail";
        break;
      case constant.MODULE_PACKAGE:
        collectionName = "PackagesAuditTrail";
        break;
      case constant.MODULE_ACCOUNTS:
        collectionName = "AccountsAuditTrail";
        break;
      case constant.MODULE_BOM:
        collectionName = "BOMAuditTrail";
        break;
      case constant.MODULE_LOYALTY_TYPE:
        collectionName = "LoyaltyTypeAuditTrail";
        break;
      case constant.MODULE_LOYALTY_POINT_MAPPING:
        collectionName = "LoyaltyPointMappingAuditTrail";
        break;
      case constant.MODULE_ACCOUNT_MANAGEMENT:
        collectionName = "AccountManagementAuditTrail";
        break;
      case constant.MODULE_SURVEY:
        collectionName = "Survey";
        break;
      case constant.MODULE_CAMPAIGN:
        collectionName = "CampaignsAuditTrail";
        break;
      case constant.MODULE_SMS_TEMPLATE:
        collectionName = "SMSTemplatesAuditTrail";
        break;
      case constant.MODULE_EVENT:
        collectionName = "EventsAuditTrail";
        break;
      default:
        collectionName = "";
    }
    this.setState({ parentKey: parentKey, collectionName: collectionName });
  }

  loadAuditTrail() {
    this.props
      .getAuditTrails(
        this.props.user.user.RegistrationID,
        this.state.collectionName,
        this.props.parentKey
      )
      .then((auditTrails) => {
        this.setState({ auditTrails: auditTrails, showAuditTrail: true });
      });
  }

  render() {
    return (
      <React.Fragment>
        <a onClick={() => this.loadAuditTrail()}>Audit Trail</a>

        {this.state.showAuditTrail && (
          <Window
            header={"Audit Trail"}
            // modal={true}
            onClose={() => this.setState({ showAuditTrail: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showAuditTrail: false });
            }}
            buttons={[]}
          >
            <div
              className="box-body scroll2"
              style={{ width: "auto", overflowX: "auto", height: "400px" }}
            >
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Date & time</th>
                    <th>Action Performed</th>
                    <th>Action By</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.auditTrails.map((audit, index) => (
                    <tr key={index}>
                      <td>
                        {new Date(audit.ActionOn.seconds * 1000)
                          .toString()
                          .substring(0, 24)}
                      </td>
                      <td>{audit.Action}</td>
                      <td>{audit.ActionBy}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Window>
        )}

        {/* <Modal open={this.state.showAuditTrail} onClose={() => this.setState({ showAuditTrail: false })} center>
                    <div style={{ width: "600px" }}>

                        <h3 style={{ marginTop: "0px" }}>Audit Trail</h3>

                        <div className="box-body scroll2" style={{width:"auto", overflowX:"auto" , height:"400px"}} >

                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Date & time</th>
                                        <th>Action Performed</th>
                                        <th>Action By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.auditTrails.map((audit, index) =>
                                        <tr key={index}> 

                                            <td>{(new Date(audit.ActionOn.seconds*1000)).toString().substring(0,24)}</td>
                                            <td>{audit.Action}</td>
                                            <td>{audit.ActionBy}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal> */}
      </React.Fragment>
    );
  }
}

export default AuditTrail;
