import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'


class InvoiceDetail2 extends Component {
    constructor() {
        super()
        this.state = {
            invoices: [],
            searchTransactionNo: '',
            loading: false,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',

            productCustomField1DisplayName: 'Custom Field 1',
            productCustomField2DisplayName: 'Custom Field 2',
            productCustomField3DisplayName: 'Custom Field 3',
            productCustomField4DisplayName: 'Custom Field 4',
            productCustomField5DisplayName: 'Custom Field 5',
            productCustomField6DisplayName: 'Custom Field 6',
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
            else if (customField.ModuleName === 'Product') {
                let productCustomField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Product Custom Field 1' : customField.CustomField1Name.trim()
                let productCustomField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Product Custom Field 2' : customField.CustomField2Name.trim()
                let productCustomField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Product Custom Field 3' : customField.CustomField3Name.trim()
                let productCustomField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Product Custom Field 4' : customField.CustomField4Name.trim()
                let productCustomField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Product Custom Field 5' : customField.CustomField5Name.trim()
                let productCustomField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Product Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    productCustomField1DisplayName: productCustomField1DisplayName,
                    productCustomField2DisplayName: productCustomField2DisplayName,
                    productCustomField3DisplayName: productCustomField3DisplayName,
                    productCustomField4DisplayName: productCustomField4DisplayName,
                    productCustomField5DisplayName: productCustomField5DisplayName,
                    productCustomField6DisplayName: productCustomField6DisplayName
                })
            }
        })
    }

    loadInvoiceDetailReport(searchTransactionNo) {

        this.setState({ loading: true })

        this.props.getInvoiceDetailReport2(this.props.user.user.RegistrationID, searchTransactionNo)
            .then((result) => {

                let invoices = []

                for (let index = 0; index < result.searchResult.length; index++) {
                    const data = result.searchResult[index];
                    let invoice = {
                        "CustomerCode": data.CustomerCode,
                        "CustomerName": data.CustomerName,
                        "CustomerPhoneNo": data.CustomerPhoneNo
                    }

                    invoice[this.state.customField1DisplayName] = data.hasOwnProperty('CustomerCustomField1') ? data.CustomerCustomField1 : ""
                    invoice[this.state.customField2DisplayName] = data.hasOwnProperty('CustomerCustomField2') ? data.CustomerCustomField2 : ""
                    invoice[this.state.customField3DisplayName] = data.hasOwnProperty('CustomerCustomField3') ? data.CustomerCustomField3 : ""
                    invoice[this.state.customField4DisplayName] = data.hasOwnProperty('CustomerCustomField4') ? data.CustomerCustomField4 : ""
                    invoice[this.state.customField5DisplayName] = data.hasOwnProperty('CustomerCustomField5') ? data.CustomerCustomField5 : ""
                    invoice[this.state.customField6DisplayName] = data.hasOwnProperty('CustomerCustomField6') ? data.CustomerCustomField6 : ""


                    invoice["TransactionNo"] = data.TransactionNo
                    invoice["TransactionType"] = data.TransactionType
                    invoice.Cancelled = "No"
                    if (data.CancellationReason) {
                        invoice.Cancelled = "Yes"
                    }
                    invoice["Brand"] = data.Brand ? data.Brand : ""

                    invoice["Category"] = data.Category
                    invoice["CategoryImage"] = ''
                    if(this.props.config.ProductCategories.filter((f)=> f.key === data.CategoryID).length > 0)
                    {
                        let category = this.props.config.ProductCategories.filter((f)=> f.key === data.CategoryID)[0]
                        invoice["CategoryImage"] = category.Image ? category.Image.Preview : '';
                    }

                    invoice["SubCategory"] = data.SubCategory ? data.SubCategory : ""
                    invoice["SubCategoryImage"] = ''
                    if(data.SubCategory !== "" && this.props.config.ProductSubCategories.filter((f)=> f.key === data.SubCategoryID).length > 0)
                    {
                        let subcategory = this.props.config.ProductSubCategories.filter((f)=> f.key === data.SubCategoryID)[0]
                        invoice["SubCategoryImage"] = subcategory.Image ? subcategory.Image.Preview : '';
                    }

                    invoice["SKU"] = data.SKU
                    invoice["Qty"] = data.Qty
                    invoice["ProductName"] = data.ProductName
                    invoice["ProductImage"] = ''
                    if(this.props.config.Products.filter((f)=> f.key === data.ProductID).length > 0)
                    {
                        let product = this.props.config.Products.filter((f)=> f.key === data.ProductID)[0]
                        invoice["ProductImage"] = product.Images.length > 0 ? product.Images[0].ImagePath : '';
                    }


                    invoice[this.state.productCustomField1DisplayName] = data.hasOwnProperty('ProductCustomField1') ? data.ProductCustomField1 : ""
                    invoice[this.state.productCustomField2DisplayName] = data.hasOwnProperty('ProductCustomField2') ? data.ProductCustomField2 : ""
                    invoice[this.state.productCustomField3DisplayName] = data.hasOwnProperty('ProductCustomField3') ? data.ProductCustomField3 : ""
                    invoice[this.state.productCustomField4DisplayName] = data.hasOwnProperty('ProductCustomField4') ? data.ProductCustomField4 : ""
                    invoice[this.state.productCustomField5DisplayName] = data.hasOwnProperty('ProductCustomField5') ? data.ProductCustomField5 : ""
                    invoice[this.state.productCustomField6DisplayName] = data.hasOwnProperty('ProductCustomField6') ? data.ProductCustomField6 : ""
                    invoices.push(invoice);
                }
                this.setState({ invoices: invoices, loading: false });
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            searchTransactionNo: '',
        })
        this.loadInvoiceDetailReport("");
    }

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true })
        this.loadInvoiceDetailReport(this.state.searchTransactionNo);
    }

    render() {

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Invoice Detail - Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                        {!this.state.loading &&
                                            <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Transaction No</th>
                                                            <th>Customer Detail</th>
                                                            <th>Brand</th>
                                                            <th>Category</th>
                                                            <th>Sub Category</th>
                                                            <th>SKU</th>
                                                            <th>Product Name</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.invoices.map((invoice) =>
                                                            <tr>
                                                                <td>{invoice.TransactionNo}</td>
                                                                <td>
                                                                    <div>{invoice.CustomerName}</div>
                                                                    <div>{invoice.CustomerEmailID}</div>
                                                                    <div>{invoice.CustomerPhoneNo}</div>
                                                                </td>
                                                                <td>{invoice.Brand}</td>
                                                                <td>
                                                                    <div>{invoice.Category}</div>
                                                                    {invoice.CategoryImage !== "" && <img style={{height:"200px"}} src={invoice.CategoryImage} />}
                                                                </td>
                                                                <td>
                                                                    <div>{invoice.SubCategory}</div>
                                                                    {invoice.SubCategoryImage !== "" && <img style={{height:"200px"}} src={invoice.SubCategoryImage} />}
                                                                </td>
                                                                <td>{invoice.SKU}</td>
                                                                <td>
                                                                    <div>{invoice.ProductName}</div>
                                                                    {invoice.ProductImage !== "" && <img style={{height:"200px"}} src={invoice.ProductImage} />}
                                                                </td>
                                                                <td>{invoice.Qty}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table> </div>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(InvoiceDetail2)