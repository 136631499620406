import * as actionTypes from '../actionTypes';

const intialState= {DraftSales:[]}

export default function(state=intialState, action) {
    switch (action.type) {
        case actionTypes.PARK_SALE:
            return {DraftSales: action.draftSales}
        default:
            return state;
    }
}
