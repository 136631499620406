import React, { Component } from 'react'
import HeaderNew from '../HeaderNew';
import PopupMenu from '../Common/PopupMenu';
import ActionButton from '../Common/ActionButton'
import Confirmation from '../Common/Confirmation';

class PrintConfigListNew extends Component {

    constructor(){
        super()
        this.state = {
            searchText:'',
            selectedPrintConfig:null,
            showPopupMenu:false,
            showDelete:false
        }
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onRowClick= this.onRowClick.bind(this)
        this.onHeaderBackClick =this.onHeaderBackClick.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this)
        this.loadPrintConfigs = this.loadPrintConfigs.bind(this)
    }

    async componentDidMount() {
        this.loadPrintConfigs()
    }

    async loadPrintConfigs(){
        this.props.getPrintConfigs(this.props.user.user.RegistrationID, this.props.location.state.storeID)
    }

    onHeaderBackClick(){
        this.props.history.push({ pathname: '/home/settings/stores'})    
    }

    async onActionButtonClick(action) {
        this.props.history.push({pathname:"/home/settings/stores/printconfig/newprintconfig",state:{storeID:this.props.location.state.storeID,storeCode: this.props.location.state.storeCode, storeName:this.props.location.state.storeName, printConfig:null}})
    }

    async onRowClick(selectedPrintConfig)
    {
        this.setState({selectedPrintConfig:selectedPrintConfig, showPopupMenu:true})
        // this.props.history.push({ pathname: '/home/settings/stores/locations/edit', state:{location:location} })
    }

    async onShowPopupMenuClick(value) {

       
        if (value === "edit") {
            this.setState({showPopupMenu:false, selectedStore:null})
            // this.props.history.push({ pathname: "/home/settings/stores/locations/edit", state: { module:'location',printConfig: this.state.selectedPrintConfig, storeLocation: location }})
            this.props.history.push({pathname:"/home/settings/stores/printconfig/editprintconfig", 
                state:{storeID:this.props.location.state.storeID,
                storeCode: this.props.location.state.storeCode, 
                storeName:this.props.location.state.storeName, 
                printConfig: this.state.selectedPrintConfig}})
        }
        else if (value === "delete") {
            this.setState({showDelete:true, showPopupMenu:false});
        }
        else if (value === "barcode setting") {

        }
        else
        {
            this.setState({showPopupMenu:false, selectedStore:null})
        }
    }

    onDeleteConfirmation(value)
    {   
        if(value)
        {
            let object= this.state.selectedPrintConfig;
            
            this.props.deletePrintConfig(object.key).then(() => {
                    this.loadPrintConfigs()
                    this.props.getToastr("Print config deleted successfully");
                    this.setState({ loading: true, showDelete:false })
            })
        }
        else
        {
            this.setState({showDelete:false});
        }
    }

    render(){
        return <React.Fragment>

        <HeaderNew {...this.props}
        back={true}
        title="Print Config List"
        filter={false}
        onBackClick={() => this.onHeaderBackClick()}
        />
    
        <div className="header-new-background"/>

        <div className="content-wrapper">

            <div className="container">

                <br/>
                
                <div className="form-group">
                    <label>Store Code</label>
                    <input type="text" className="form-control"
                            value={this.props.location.state.storeCode}
                        />
                </div>

                <div className="form-group">
                    <label>Store Name</label>
                    <input type="text" className="form-control"
                            value={this.props.location.state.storeName}
                        />
                </div>

                <div>

                {this.props.config.PrintConfigs.filter(f=> !f.IsDeleted).map((printConfig) => <div className="list-row seperator" 
                    onClick={()=> {this.onRowClick(printConfig);}}>
                    <div>   
                        <div className="row-title">{printConfig.PrintDisplayName}</div>
                        <div className="row-text">{printConfig.Module}</div>
                        <div className="row-text">{printConfig.TransactionType}</div>
                        <div className="row-text">{printConfig.PrintSize}</div>        
                    </div>
                </div>)}

                </div>
            </div>
        </div>

         <ActionButton
                    onClick={(action) => this.onActionButtonClick(action)}
                    fabButtons={[]} />

        <PopupMenu 
            show={this.state.showPopupMenu} 
            buttons ={ [{value: "delete", text: "Delete", module: 'Print Config'}, 
            {value: "edit", text: "Edit", module: 'Print Config'}]}
            onClick={(action)=> this.onShowPopupMenuClick(action)}/>

        <Confirmation show={this.state.showDelete} onClick={(value)=>this.onDeleteConfirmation(value)}/>

        </React.Fragment>
    }
}

export default PrintConfigListNew

