import React, { Component } from 'react';
import Input from '../2/Controls/Input';
import Loader from '../Loader';
import firebase from '../../firebase';
import * as constatnts from '../../Constatnts'

class CategoryWiseTransactionReport extends Component {
    constructor() {
        super();
        this.state = {
            searchCategoryID: '',
            searchTransactionFromDate: null,
            searchTransactionToDate: null,            
            exportCalled: false,
            loading: false,
            reportData:[]
        }
        this.onBackClick = this.onBackClick.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onExportClick = this.onExportClick.bind(this);
    }

    onExportClick(){
        this.setState({exportCalled:true});
        let exportData = [];
        let reportData = this.state.reportData;
        for(let i=0;i<reportData.length;i++){  
                let data =  reportData[i];
                exportData.push({
                    "Store":data.Store.StoreName,
                    "Cash Register":data.CashRegister.CashRegisterName,
                    "Customer Code":data.Customer.CustomerCode,
                    "Customer First Name": data.Customer.FirstName,
                    "Customer Last Name":data.Customer.LastName,
                    "Customer Phone No":data.Customer.PhoneNo,
                    "Transaction No": data.TransactionNo,
                    "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                    "Transaction Type":data.TransactionType.TransactionType,
                    "Total Qty":data.BillQty,
                    "Sub Total":data.BillSubTotal,
                    "Tax Amount" :data.BillTaxAmount,
                    "Discount":data.BillDiscount,
                    "Amount":data.BillAmount,
                    "Paid Amount":data.BillPaidAmount,
                    "Balance Amount":data.BalanceAmount,
                    "Cancelled":data.CancellationReason  && data.CancellationReason !=="" ? "yes" : "no",
                    "Cancellation Reason":data.CancellationReason ? data.CancellationReason : "",
                    "Notes":data.Notes,
                    "CustomField1" :data.Customer.CustomField1,
                    "customField2":data.Customer.CustomField2,
                    "CustomField3":data.Customer.CustomField3,
                    "CustomField4":data.Customer.CustomField4,
                    "CustomField5":data.Customer.CustomField5,
                    "CustomField6":data.Customer.CustomField6,
                })
        }
        const csvData = constatnts.objToCSV(exportData);
        this.setState({ exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "CategorywiseTransactionData.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({ storeList: storeList, 
            searchTransactionToDate: null,
            searchTransactionFromDate: null, 
            searchCategoryID: '',
            reportData:[]
        })
    }

    onBackClick()
    {
        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
            this.props.history.push({ pathname: "/home/reports/salesdashboard" });
        }
        else
        {
            this.props.history.push({ pathname: "/home/reports/allreport" });
        }
    };

    onSearchClick(){
        this.loadData();
    }

    async loadData(){

        //get category specific transctions
        this.setState({loading:true});

        let denomSalesQuery = {
            "size": 0,
            "query": {
              "bool": {
                "must": [
                  {"match": {
                    "CategoryID.keyword": this.state.categoryID
                  }},
                  {
                    "match": {
                      "StoreID.keyword": this.props.user.store.key
                    }
                  }
                ]
              }
            }, 
            "aggs": {
              "distinct_values": {
                "terms": {
                  "field": "TransactionNo.keyword",
                  "size": 10000
                }
              }
            }
          }

        
        let addMessage = firebase.functions().httpsCallable('getDenomSales');
        console.log("denomSalesQuery",JSON.stringify(denomSalesQuery));
        let denomSalesTransactionIDs = await  addMessage({ text: denomSalesQuery }).then(function (result) {
            const transactionIDs = []
            let sanitizedMessage = result.data.text;
            // console.log('sanitizedMessage',sanitizedMessage)
            sanitizedMessage.aggregations.distinct_values.buckets.map((data, index) => {
                    transactionIDs.push(data.key);
            })
            console.log("transactionIDs",transactionIDs);
            return transactionIDs;
        })
        console.log("denomSalesTransactionIDs",denomSalesTransactionIDs);

        let salesQuery = {
            "size": 10000,
            "query": {
              "bool": {
                "must": [
                  {
                    "terms": {
                "TransactionNo.keyword": denomSalesTransactionIDs
              }
                  },
                  {
                    "term": {
                      "Store.key.keyword": {
                        "value": this.props.user.store.key
                      }
                    }
                  }   
                ]
              }
            }
        }
        console.log("salesQuery",JSON.stringify(salesQuery))
        let addMessageSales = firebase.functions().httpsCallable('getSales');
        let salesData = await  addMessageSales({ text: salesQuery }).then(function (result) {
            const tmp = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data) => {
                let record = data._source;
                let balanceAmount = Number(record.BalanceAmount);
                let receivedAount = 0;
                
                if(record.hasOwnProperty("ReceivedPaymentTransactions")){
                    record.ReceivedPaymentTransactions.map(r=>{
                        if(r.hasOwnProperty('ReceiveAmount')){
                            receivedAount = Number(receivedAount) + Number(r.ReceiveAmount);
                            if(record.hasOwnProperty('Payments')){
                                record.Payments.push({PaymentMode:r.ReceivePaymentNo , Amount:r.ReceiveAmount});
                            }
                        }
                    })
                }
                
                balanceAmount = balanceAmount - receivedAount;
                record.BalanceAmount = balanceAmount;
                record.BillPaidAmount = Number(record.BillAmount) - Number(balanceAmount);
                tmp.push(record);
            })
            console.log("tmp",tmp);
            return tmp;
        })
        console.log("salesData",salesData);
        this.setState({reportData:salesData, loading:false})

}

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">                                       
                                        <font style={{ fontSize: "30px" }}>Category Wise Transaction Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={this.onBackClick}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3" >
                                        <Input
                                            type="select"
                                            label="Category"
                                            onChange={(value)=>{this.setState({categoryID:value})}}
                                            value={this.state.categoryID}
                                        >
                                        <option>Please select</option>
                                        {this.props.config.ProductCategories.filter(f=> !f.IsDeleted).map(c=><option value={c.key}>{c.Name}</option>)}
                                        </Input>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                            />
                                        </div>
                                    </div> */}
                                    
                                    </div>
                                    <div className="row" >
                                    <div className="col-md-12" style={ {marginTop: "-25px"} }>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            // disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px", marginRight: "5px" }} onClick={this.onExportClick} > Export <i className="glyphicon glyphicon-download-alt " ></i></button>

                                        {/* <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a> */}
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Store</th>
                                                <th>Cash Register</th>
                                                <th>Customer Code</th>
                                                <th>Customer First Name</th>
                                                <th>Customer Last Name</th>
                                                <th>Customer Phone No</th>
                                                <th>Transaction No</th>
                                                <th>Transaction Date</th>
                                                <th>Transaction Type</th>
                                                <th>Total Qty</th>
                                                <th>Sub Total</th>
                                                <th>Tax Amount</th>
                                                <th>Discount</th>
                                                <th>Amount</th>
                                                <th>Paid Amount</th>
                                                <th>Balance Amount</th>
                                                <th>Cancelled</th>
                                                <th>Cancellation Reason</th>
                                                <th>Notes</th>
                                                <th>Custom Field1</th>
                                                <th>Custom Field2</th>
                                                <th>Custom Field3</th>
                                                <th>Custom Field4</th>
                                                <th>Custom Field5</th>
                                                <th>Custom Field6</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reportData.map((data, index) =>
                                                <tr>
                                                    <td>{data.Store.StoreName}</td>
                                                    <th>{data.CashRegister.CashRegisterName}</th>
                                                    <th>{data.Customer.CustomerCode}</th>
                                                    <th>{data.Customer.FirstName}</th>
                                                    <th>{data.Customer.LastName}</th>
                                                    <th>{data.Customer.PhoneNo}</th>
                                                    <th>{data.TransactionNo}</th>
                                                    <th>{(new Date(data.TransactionDate).toISOString().split('T')[0])}</th>
                                                    <th>{data.TransactionType.TransactionType}</th>
                                                    <th>{data.BillQty}</th>
                                                    <th>{data.BillSubTotal}</th>
                                                    <th>{data.BillTaxAmount}</th>
                                                    <th>{data.BillDiscount}</th>
                                                    <th>{data.BillAmount}</th>
                                                    <th>{data.BillPaidAmount}</th>
                                                    <th>{data.BalanceAmount}</th>
                                                    <th>{data.CancellationReason  && data.CancellationReason !=="" ? "yes" : "no"}</th>
                                                    <th>{data.CancellationReason}</th>
                                                    <th>{data.Notes}</th>
                                                    <th>{data.Customer.CustomField1}</th>
                                                    <th>{data.Customer.CustomField2}</th>
                                                    <th>{data.Customer.CustomField3}</th>
                                                    <th>{data.Customer.CustomField4}</th>
                                                    <th>{data.Customer.CustomField5}</th>
                                                    <th>{data.Customer.CustomField6}</th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    </div>}                                    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default CategoryWiseTransactionReport;