import React, { Component } from 'react';
import HeaderNew from '../HeaderNew'
import Spinner from '../Common/Spinner'
import ActionButton from '../Common/ActionButton'
import Avatar from '../Common/Avatar'
import TabStrip from '../Common/TabStrip'
import RecordNotFound from '../Common/RecordNotFound'
import '../Common/Common.css'

class CustomerViewNew extends Component{
    constructor() {
        super();
        this.state = {
            showFabButtons:false,
            dashBoardHeader: {},
            customer: null,
            transactions: [],
            walletLedgers: [],
            loyaltyLedgers: [],
            customerProducts: [],
            customerServices: [],
            totalItemsCount: 0,
            selectedTabIndex: 0,
            selectedProductsTabIndex: 0,
            selectedAppointmentTabIndex:0,
            campaigns: [],
            customerLoyalty: null,
            customField1DisplayName: "",
            customField2DisplayName: "",
            customField3DisplayName: "",
            customField4DisplayName: "",
            customField5DisplayName: "",
            customField6DisplayName: ""
        };
        this.loadDashboard = this.loadDashboard.bind(this);
        this.onTabSelected= this.onTabSelected.bind(this)
        this.onProductsTabSelected= this.onProductsTabSelected.bind(this)
        this.onAppointmentTabSelected= this.onAppointmentTabSelected.bind(this)
        this.renderCustomerMain = this.renderCustomerMain.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
    };

    componentWillMount() {
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    };

    async componentDidMount() {
        // let customerID = this.props.location.state.id  
        let customerID = this.props.customerID      
        if(this.props.action)
        {
            if(this.props.action ==='showactionbutton')
            {
                this.setState({showFabButtons:true})
            }
        }
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                this.setState({
                    customField1DisplayName: customField.CustomField1Name.trim() === '' ? '' : customField.CustomField1Name.trim(),
                    customField2DisplayName: customField.CustomField2Name.trim() === '' ? '' : customField.CustomField2Name.trim(),
                    customField3DisplayName: customField.CustomField3Name.trim() === '' ? '' : customField.CustomField3Name.trim(),
                    customField4DisplayName: customField.CustomField4Name.trim() === '' ? '' : customField.CustomField4Name.trim(),
                    customField5DisplayName: customField.CustomField5Name.trim() === '' ? '' : customField.CustomField5Name.trim(),
                    customField6DisplayName: customField.CustomField6Name.trim() === '' ? '' : customField.CustomField6Name.trim()
                })
            }
        })
        this.loadDashboard(customerID)
    };

    async loadDashboard(customerID) {

        this.props.getCustomerDetails(customerID)
            .then((customer) => {
                this.setState({ customer: customer })
            });

        this.props.getCustomerDashboardHeaders(customerID)
            .then((result) => {
                this.setState({ dashBoardHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerTransactions(customerID)
            .then((result) => {
                this.setState({ transactions: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerProducts(customerID, 'Goods for sale')
            .then((result) => {
                this.setState({ customerProducts: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerProducts(customerID, 'Services')
            .then((result) => {
                this.setState({ customerServices: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getWalletLedgersByCustomerID(customerID)
            .then((result) => {
                this.setState({ walletLedgers: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerPackages(customerID)
            .then((result) => {
                this.setState({ packages: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

            let loyaltyLedgers =  await this.props.getLoyaltyLedgersByCustomerID(customerID);
            console.log('loyaltyLedgers',loyaltyLedgers)
            this.setState({ loyaltyLedgers: loyaltyLedgers.searchResult})
            // .then((result) => {
            //     console.log("Loyalty Ledger", result);
            //     this.setState({ loyaltyLedgers: result.searchResult, totalItemsCount: result.totalItemsCount })
            // });

        this.props.getUpcomingAppointmentsForCustomer(customerID)
            .then((upcomingAppointments) => {
                // console.log("Upcoming", JSON.stringify(upcomingAppointments));
                this.setState({ upcomingAppointments: upcomingAppointments })
            });

        this.props.getPastAppointmentsForCustomer(customerID)
            .then((pastAppointments) => {
                // console.log("Past", JSON.stringify(pastAppointments));
                this.setState({ pastAppointments: pastAppointments })
            });

        if (this.props.registration.registration.hasOwnProperty('LoyaltyEnabled') && this.props.registration.registration.LoyaltyEnabled) {
            this.props.getCustomerLoyaltyPoints(customerID).then((customerLoyalty)=>{
                this.setState({ customerLoyalty: customerLoyalty })
            })
        }
    };

    onTabSelected(selectedTabIndex){
        this.setState({selectedTabIndex:selectedTabIndex})
    }

    onProductsTabSelected(selectedProductsTabIndex) {
        this.setState({ selectedProductsTabIndex: selectedProductsTabIndex })
    }

    onAppointmentTabSelected(selectedAppointmentTabIndex) {
        this.setState({ selectedAppointmentTabIndex: selectedAppointmentTabIndex })
    }

    renderSales(){
        return <div>
            <br/>
            {this.state.transactions.length > 0 &&
                <div className="list" style={{paddingTop:"0px"}}>
                    {this.state.transactions.map((sale) => <div className="list-row seperator"  onClick={() => this.onRowClick(sale.key, sale)}>
                        <div style={{width:"50%"}} >
                            <div className="row-title pull-left" ><b># {sale.TransactionNo}</b></div>
                            <br/>
                            <div className="row-sub-text pull-left">{new Date(sale.TransactionDate).toDateString()}</div>
                        </div>
                        <div style={{width:"50%"}} >
                            <div className="row-title pull-right"> <b>₹ {sale.BillAmount}</b></div>
                            <br/>
                            {sale.BalanceAmount > 0  && <div className="row-sub-text pull-right outstanding" style={{marginRight:"0px"}}>O/S: {sale.BalanceAmount}</div>}
                        </div>
                    </div>)}
                </div>}
                {this.state.transactions.length === 0 && <RecordNotFound/>}
    </div>
    }

    renderCustomerProducts(){
        return <div>
            <br/>
            {this.state.customerProducts.length > 0 &&
                <div className="list" style={{paddingTop:"0px"}}>
                    {this.state.customerProducts.map((customerProduct) => <div className="list-row seperator">
                        <div style={{width:"50%"}} >
                            <div className="row-title pull-left" ><b>{customerProduct.name}</b></div>
                        </div>
                        <div style={{width:"50%"}} >
                            <div className="row-title pull-right"> <b>₹ {customerProduct.total_sales}</b></div>
                            <br/>
                            <div className="row-sub-text pull-right">Qty: {customerProduct.total_quantity}</div>
                        </div>
                    </div>)}
                </div>}
                {this.state.customerProducts.length === 0 && <RecordNotFound/>}
    </div>
    }

    renderCustomerServices() {
        return <div>
            <br />
            {this.state.customerServices.length > 0 &&
                <div className="list" style={{ paddingTop: "0px" }}>
                    {this.state.customerServices.map((customerService) => <div className="list-row seperator">
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-left" ><b>{customerService.name}</b></div>
                        </div>
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-right"> <b>₹ {customerService.total_sales}</b></div>
                            {/* <br/>
                            <div className="row-sub-text pull-right">Qty: {customerService.total_quantity}</div> */}
                        </div>
                    </div>)}
                </div>}
            {this.state.customerServices.length === 0 && <RecordNotFound />}
        </div>
    }

    renderCustomerWallets() {
        return <div>
            <br />
            {this.state.walletLedgers.length > 0 &&
                <div className="list" style={{ paddingTop: "0px" }}>
                    {this.state.walletLedgers.map((ledger) => <div className="list-row seperator">
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-left" ><b>{Number(ledger.CreditAmount).toFixed(2) > 0 ? "Credit: ₹ " + Number(ledger.CreditAmount).toFixed(2) : "Debit: ₹ " + Number(ledger.DebitAmount).toFixed(2)}</b></div>
                            <div className="row-sub-text pull-left" >{(new Date(ledger.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{ "# " + ledger.TransactionNo }</div>
                            <br/>
                          </div>
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-right"> <b>₹ {Number(ledger.WalletClosingBalance).toFixed(2)}</b></div>
                        </div>
                    </div>)}
                </div>}
            {this.state.walletLedgers.length === 0 && <RecordNotFound />}
        </div>
    }


    renderCustomerPackages() {
        return <div>
            <br />
            {this.state.packages.length > 0 &&
                <div className="list" style={{ paddingTop: "0px" }}>
                    {this.state.packages.map((objPackage) => <div className="list-row seperator">
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-left" ><b># {objPackage.TransactionNo}</b></div>
                            <br/>
                            <div className="row-sub-text pull-left" >{objPackage.PackageName}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{new Date(objPackage.CreatedOn).toISOString().split('T')[0]}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{new Date(objPackage.PackageEndDate).toISOString().split('T')[0]}</div>
                        </div>
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-right"> <b>{objPackage.PackageStatus}</b></div>
                        </div>
                    </div>)}
                </div>}
            {this.state.packages.length === 0 && <RecordNotFound />}
        </div>
    }

    renderUpcomingAppointments() {
        return <div>
            <br />
            {this.state.upcomingAppointments.length > 0 &&
                <div className="list" style={{ paddingTop: "0px" }}>
                    {this.state.upcomingAppointments.map((appointment) => <div className="list-row seperator">
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-left" ><b>{(new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0]}</b></div>
                            <br/>
                            <div className="row-sub-text pull-left" >{appointment.Service.label}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{appointment.EmployeeName}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</div>
                        </div>
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-right"> <b>{appointment.Status === "" ? "Booked" : appointment.Status}</b></div>
                        </div>
                    </div>)}
                </div>}
            {this.state.upcomingAppointments.length === 0 && <RecordNotFound />}
        </div>
    }

    renderPastAppointments() {
        return <div>
            <br />
            {this.state.pastAppointments.length > 0 &&
                <div className="list" style={{ paddingTop: "0px" }}>
                    {this.state.pastAppointments.map((appointment) => <div className="list-row seperator">
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-left" ><b>{(new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0]}</b></div>
                            <br/>
                            <div className="row-sub-text pull-left" >{appointment.Service.label}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{appointment.EmployeeName}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</div>
                        </div>
                        <div style={{ width: "50%" }} >
                            <div className="row-title pull-right"> <b>{appointment.Status === "" ? "Booked" : appointment.Status}</b></div>
                        </div>
                    </div>)}
                </div>}
            {this.state.pastAppointments.length === 0 && <RecordNotFound />}
        </div>
    }

    renderCustomerLoyalty() {
        return <div>
            <br />
            {this.state.loyaltyLedgers.length > 0 &&
                <div className="list" style={{ paddingTop: "0px" }}>
                    {this.state.loyaltyLedgers.map((ledger) => <div className="list-row seperator">
                        <div>
                            <div className="row-title pull-left" >{ledger.Type === "earn" ? "Earn: " + Number(ledger.LoyaltyPoints).toFixed(2) : "Burn: " + Number(ledger.LoyaltyPoints).toFixed(2)}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{(new Date(ledger.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</div>
                            <br/>
                            <div className="row-sub-text pull-left" >{"# " + ledger.TransactionNo}</div>
                        </div>
                    </div>)}
                </div>}
            {this.state.loyaltyLedgers.length === 0 && <RecordNotFound />}
        </div>
    }

    renderCustomerMain()
    {
        return <React.Fragment>
        <div className="container">
                <br/>
                <div className="list-row">
                    <Avatar text={this.state.customer.FirstName} big={true}/>
                    <div >
                        <div className="text1">{this.state.customer.FirstName +  " " + this.state.customer.LastName} </div>
                        {this.state.customer.PhoneNo !=="" && <div><i className="fa fa-phone"/> {this.state.customer.PhoneNo}</div>}
                        {this.state.customer.EmailID !==""  && <div><i className="fa fa-envelope"/> {this.state.customer.EmailID}</div>}
                        {this.state.customer.hasOwnProperty('AadharNo') && this.state.customer.AadharNo !==""  && <div>AadharNo:  {this.state.customer.AadharNo}</div>}
                    </div>
                </div>
                {/* <br/> */}
                <div className="list-row">
                    {(this.state.customer.OutStandingAmount > 0) && <span className="outstanding pull-left">O/S: {this.state.customer.OutStandingAmount}</span> }
                    {(this.state.customer.WalletBalance > 0 )&& <span className="wallet pull-left">Wallet: {this.state.customer.WalletBalance}</span> }
                    {(this.state.customer.Membership && this.state.customer.Membership.hasOwnProperty('MembershipID') ) && this.state.customer.Membership.MembershipID != "" &&
                    <span className="membership pull-left">
                    {this.state.customer.Membership.Name}</span>}
                    {(this.state.customer.hasOwnProperty('LoyaltyType') && this.state.customer.LoyaltyType.trim()!=="") && <span className="loyalty pull-left">
                    {this.state.customer.LoyaltyType}</span>}
                </div>
                <TabStrip tabButtons={[
                    {text:"GENERAL DETAILS"},
                    {text:"ADDRESS"},
                    {text:"SALES"},
                    {text:"PRODUCTS"},
                    {text:"APPOINTMENTS"},
                    // {text:"CAMPAIGNS"},
                    {text:"LOYALTY"}
                    ]}
                    selectedTabIndex={this.state.selectedTabIndex}
                    onTabSelected={(index)=>this.onTabSelected(index)}
                    />
                    {this.state.selectedTabIndex ===0 && <div>
                        <div className="field">Date of Birth</div>
                        <div className="value seperator">
                        {this.state.customer.DOBNew && <React.Fragment> {this.state.customer.DOBNew.Day + "-" + this.state.customer.DOBNew.Month + "-" + this.state.customer.DOBNew.Year === "--" ? "" : this.state.customer.DOBNew.Day + "-" + this.state.customer.DOBNew.Month + "-" + this.state.customer.DOBNew.Year} </React.Fragment>}
                        </div>
                        <div className="field">Anniversary Date</div>
                        <div className="value seperator">
                        {this.state.customer.AnniversaryNew && <React.Fragment> {this.state.customer.AnniversaryNew.Day + "-" + this.state.customer.AnniversaryNew.Month + "-" + this.state.customer.AnniversaryNew.Year === "--" ? "" : this.state.customer.AnniversaryNew.Day + "-" + this.state.customer.AnniversaryNew.Month + "-" + this.state.customer.AnniversaryNew.Year}</React.Fragment>}
                        </div>
                        <div className="field">Gender</div>
                        <div className="value seperator">
                        {this.state.customer.Gender}
                        </div>
                        <div className="field">Notes</div>
                        <div className="value seperator">
                        {this.state.customer.Note}
                        </div>
                        <div className="field">Customer Code</div>
                        <div className="value seperator">
                        {this.state.customer.CustomerCode}
                        </div>
                        <div className="field">Referred By</div>
                        <div className="value seperator">
                        {this.state.customer.ReferredBy}
                        </div>
                        <div className="field">Customer Accepts Marketing</div>
                        <div className="value seperator">
                        {this.state.customer.CustomerAcceptsMarketing ? "Yes" : "No"}
                        </div>
                        {this.state.customField1DisplayName.trim() !== '' && <React.Fragment> 
                            <div className="field">{this.state.customField1DisplayName}</div>
                            <div className="value seperator">
                                {this.state.customer.CustomField1}
                            </div>
                        </React.Fragment>}
                        {this.state.customField2DisplayName.trim() !== '' && <React.Fragment> 
                            <div className="field">{this.state.customField2DisplayName}</div>
                            <div className="value seperator">
                                {this.state.customer.CustomField2}
                            </div>
                        </React.Fragment>}
                        {this.state.customField3DisplayName.trim() !== '' && <React.Fragment> 
                            <div className="field">{this.state.customField3DisplayName}</div>
                            <div className="value seperator">
                                {this.state.customer.CustomField3}
                            </div>
                        </React.Fragment>}
                        {this.state.customField4DisplayName.trim() !== '' && <React.Fragment> 
                            <div className="field">{this.state.customField4DisplayName}</div>
                            <div className="value seperator">
                                {this.state.customer.CustomField4}
                            </div>
                        </React.Fragment>}
                        {this.state.customField5DisplayName.trim() !== '' && <React.Fragment> 
                            <div className="field">{this.state.customField5DisplayName}</div>
                            <div className="value seperator">
                                {this.state.customer.CustomField5}
                            </div>
                        </React.Fragment>}
                        {this.state.customField6DisplayName.trim() !== '' && <React.Fragment> 
                            <div className="field">{this.state.customField6DisplayName}</div>
                            <div className="value seperator">
                                {this.state.customer.CustomField6}
                            </div>
                        </React.Fragment>}
                    </div>}
                    {this.state.selectedTabIndex ===1 && <div className="address">
                        {this.state.customer.Addresses.map((address,index)=><div key={"customer_address_" + index}>
                                <div className="address-main">
                                    <i className={address.AddressType.toLowerCase() === "home" ? "fa fa-home" : address.AddressType.toLowerCase() === "office"  ? "fa fa-office" : "fa fa-other" }/>
                                    {address.AddressType}
                                </div>
                                <div className="address-sub">{address.Address1}</div>
                                <div className="address-sub">{address.Address2}</div>
                                <div className="address-sub">{address.City}</div>
                                <div className="address-sub">{address.PostalCode}</div>
                                <div className="address-sub">{address.State}</div>
                                <div className="address-sub">{address.Country}</div>
                        </div>)}
                    </div>}
                    {this.state.selectedTabIndex ===2 && this.renderSales()}
                    {this.state.selectedTabIndex ===3 && <div>
                        <TabStrip tabButtons={[
                            { text: "PRODUCTS" },
                            { text: "SERVICES" },
                            { text: "WALLET" },
                            { text: "PACKAGES" }
                        ]}
                            selectedTabIndex={this.state.selectedProductsTabIndex}
                            onTabSelected={(index) => this.onProductsTabSelected(index)}
                        />
                        {this.state.selectedProductsTabIndex === 0 && this.renderCustomerProducts()}
                        {this.state.selectedProductsTabIndex === 1 && this.renderCustomerServices()}
                        {this.state.selectedProductsTabIndex === 2 && this.renderCustomerWallets()}
                        {this.state.selectedProductsTabIndex === 3 && this.renderCustomerPackages()}
                   </div>}
                    {this.state.selectedTabIndex ===4 && <React.Fragment>
                        <TabStrip tabButtons={[
                            { text: "UPCOMING" },
                            { text: "PAST" },
                        ]}
                            selectedTabIndex={this.state.selectedAppointmentTabIndex}
                            onTabSelected={(index) => this.onAppointmentTabSelected(index)}
                        />
                        {this.state.selectedAppointmentTabIndex === 0 && this.renderUpcomingAppointments()}
                        {this.state.selectedAppointmentTabIndex === 1 && this.renderPastAppointments()}
                        </React.Fragment>}
                    {(this.state.selectedTabIndex ===5 && this.props.registration.registration.hasOwnProperty('LoyaltyEnabled') && this.props.registration.registration.LoyaltyEnabled) &&
                        this.renderCustomerLoyalty()
                    }
                
                </div>
            </React.Fragment>
    }

    onActionButtonClick(action) {
        if (action === "edit") {
            this.props.history.push({ pathname: "/home/customer/edit/" + this.props.customerID})
        }
        else if (action === "whatsapp" && this.state.customer.PhoneNo.trim() !== "") {
            // let name = (this.state.customer.FirstName.trim() + ' ' + this.state.customer.LastName.trim()).trim()
            let message = ""
            message = message + "*" + this.props.user.store.StoreName + "* \n"
            if (this.props.user.store.PhoneNo.trim() != "")
                message = message + "*" + this.props.user.store.PhoneNo + "* \n"
            if (this.props.user.store.EmailID.trim() != "")
                message = message + "*" + this.props.user.store.EmailID + "* \n"
    
            message = message + "https://omni.posible.in/" + this.props.registration.registration.UniqueName + " \n"

            let url = encodeURI('https://wa.me/91' + this.state.customer.PhoneNo.trim() + "?text=" + message)

            window.open(url);
        }
    }

    render() {    

        return <React.Fragment>
            
            {!this.props.showAsComponent && <React.Fragment>

                <HeaderNew {...this.props} 
                back={true} 
                title="Customer"
                edit={true} 
                delete={true}
                onBackClick={()=> {this.props.history.push({pathname:'/home/customer'})}}
                onEditClick={()=> {this.props.history.push({pathname:`/home/customer/edit/${this.props.location.state.id}`})}}
                onDeleteClick={()=> alert('delete clicked')}
                />

                <div className="header-new-background"></div>

                <div className="content-wrapper" >

                    {!this.state.customer && <Spinner/>}

                    {this.state.customer && this.renderCustomerMain()}

                    {this.state.customer && <ActionButton 
                        showFabButtons={this.state.showFabButtons}
                       onClick={(action) => this.onActionButtonClick(action)}
                        fabButtons={[
                        {action:"email", text: this.state.customer.EmailID},
                        // {action:"sms", text:"SMS"},
                        {action:"whatsapp", text:"WhatsApp"},
                        { action: "call", text: this.state.customer.PhoneNo},
                        { action: "edit", text: "Edit"}
                    ]}/>}
                </div>
        </React.Fragment>}

        {this.props.showAsComponent && <React.Fragment>

            <div className="header-new-background"></div>
            
            {!this.state.customer && <Spinner/>}

            {this.state.customer && this.renderCustomerMain()}

        </React.Fragment>}

    </React.Fragment>
    }
}
export default CustomerViewNew