import React, { Component } from 'react';
import Icon from '../2/Controls/Icon';
import ImageButton from '../2/Controls/ImageButton';
import {getCustomerMLoyalLoyaltyPoints} from '../../services/api/MLoyal';
import {getCustomerEasyRewardzLoyaltyPoints, getEasyRewardWorkflowWidget} from '../../services/api/EasyRewardz';

import {isMobileApp} from '../../actions/Util';
import Window from '../2/Controls/Window';
import Button from '../2/Controls/Button';

class CustomerDashboard extends Component {

    constructor() {
        super();
        this.state = {
            dashBoardHeader: {}
            , customerDetails: {}
            , transactions: []
            , walletLedgers: []
            , loyaltyLedgers: []
            , customerProducts: []
            , customerServices: []
            , totalItemsCount: 0
            , rightHeight: 100
            , tabSelected: 'GD'
            , campaigns: []
            , customerLoyalty: null
            , hasProducts:false
            , hasServices:false
            , hasWallet:false
            , hasPackage:false
            ,hasPaymentReceive:false
            ,hasAppointment:false
            , hasLoyalty:false
            ,hasCampaign:false
            ,loading:false
        };
        this.loadDashboard = this.loadDashboard.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.renderDashboardSummary= this.renderDashboardSummary.bind(this);
        this.renderDesktop = this.renderDesktop.bind(this);
        this.renderMobile = this.renderMobile.bind(this);
        this.onViewEasyRewardzDashboard = this.onViewEasyRewardzDashboard.bind(this);
    };

    async componentDidMount() {
        
        let hasProducts=false
        let hasServices=false
        let hasWallet=false
        let hasPackage=false
        let hasPaymentReceive=false
        let hasAppointment=false
        let hasLoyalty=false
        let hasCampaign=false
        let customerLoyalty = null
        if(this.props.config.Products.filter(f=> !f.IsDeleted && f.ProductTypeID===1).length > 0){
            hasProducts= true
        }
        if(this.props.config.Products.filter(f=> !f.IsDeleted && f.ProductTypeID===3).length > 0){
            hasServices= true
        }
        if(this.props.config.Products.filter(f=> !f.IsDeleted && f.ProductTypeID===4).length > 0){
            hasWallet= true
        }
        if(this.props.config.Products.filter(f=> !f.IsDeleted && f.ProductTypeID===5).length > 0){
            hasPackage= true
        }
        let customerID = ""
        if (this.props.calledFromInvoice) {
            customerID = this.props.cutomerID
        }
        else {
            customerID = this.props.match.params.key
        }

        if(this.props.registration.registration.hasOwnProperty('LoyaltyEnabled') && this.props.registration.registration.LoyaltyEnabled){
            hasLoyalty=true;
            customerLoyalty = await this.props.getCustomerLoyaltyPoints(customerID)
            this.setState({ customerLoyalty: customerLoyalty })
        }
        
        // if(this.props.location.state.transactionType && this.props.location.state.transactionType.TransactionType.toLowerCase()==="receive payment"){
        //     hasPaymentReceive= true
        // }
        if(this.props.registration.registration && this.props.registration.registration.EnableAppointment === true){
            hasAppointment= true
        }
        hasCampaign= true;
        this.setState({hasProducts:hasProducts,hasServices:hasServices,hasWallet:hasWallet, hasPackage:hasPackage,
            hasLoyalty:hasLoyalty,hasPaymentReceive:hasPaymentReceive, hasCampaign:hasCampaign })

        
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        this.loadDashboard(customerID)

    };

    loadDashboard(customerID) {

        this.props.getCustomerDashboardHeaders(customerID)
            .then((result) => {
                this.setState({ dashBoardHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerDetails(customerID)
            .then((customer) => {
                // console.log('customer',customer)
                if(this.props.registration.registration.hasOwnProperty('EnableMLoyal') && this.props.registration.registration.EnableMLoyal){
                    getCustomerMLoyalLoyaltyPoints(this.props.user.store.RegistrationID, 
                        this.props.user.store.key, 
                        customerID, 
                        customer.PhoneNo).then(response =>{
                            console.log('response',response)
                            this.setState({
                                hasLoyalty:true, 
                                customerLoyalty:{
                                LoyaltyType:response.CUSTOMER_DETAILS[0].Slab + " (Pt: " + response.CUSTOMER_DETAILS[0].LoyalityPoints + ", Vl: " + response.CUSTOMER_DETAILS[0].LoyalityPointsValue + ")",
                                LoyaltyPoints:response.CUSTOMER_DETAILS[0].LoyalityPoints
                            }})
                        });
                }
                if(this.props.registration.registration.hasOwnProperty('EnableEasyRewardz') && this.props.registration.registration.EnableEasyRewardz){
                    console.log('this.props.user.store.RegistrationID',this.props.user.store.RegistrationID)
                    console.log('this.props.user.store.key',this.props.user.store.key)
                    console.log('customerID',customerID)
                    console.log('customer.PhoneNo',customer.PhoneNo)
                    // registrationID, 
                    // storeID, 
                    // storeCode, 
                    // customerID, 
                    // customerMobileNo
                    
                    getCustomerEasyRewardzLoyaltyPoints(
                        this.props.user.store.RegistrationID, 
                        this.props.user.store.key, 
                        this.props.user.store.StoreCode, 
                        customerID, 
                        customer.PhoneNo
                        ).then(response =>{
                            console.log('response',response)
                            if(response.Success){
                                this.setState({
                                    hasLoyalty:true, 
                                    customerLoyalty:{
                                    LoyaltyType:"Loyalty",
                                    LoyaltyPoints:response.Data.AvailablePoints
                                }})
                            }
                            
                        });
                }
                this.setState({ customerDetails: customer })
            });

        this.props.getCustomerTransactions(customerID)
            .then((result) => {
                this.setState({ transactions: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerProducts(customerID, 'Goods for sale')
            .then((result) => {
                this.setState({ customerProducts: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerProducts(customerID, 'Services')
            .then((result) => {
                this.setState({ customerServices: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getWalletLedgersByCustomerID(customerID)
            .then((result) => {
                this.setState({ walletLedgers: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerPackages(customerID)
            .then((result) => {
                this.setState({ packages: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getLoyaltyLedgersByCustomerID(customerID)
            .then((result) => {
                // console.log("Loyalty Ledger", result);
                this.setState({ loyaltyLedgers: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getUpcomingAppointmentsForCustomer(customerID)
            .then((upcomingAppointments) => {
                // console.log("Upcoming", JSON.stringify(upcomingAppointments));
                this.setState({ upcomingAppointments: upcomingAppointments })
            });

        this.props.getPastAppointmentsForCustomer(customerID)
            .then((pastAppointments) => {
                // console.log("Past", JSON.stringify(pastAppointments));
                this.setState({ pastAppointments: pastAppointments })
            });
    };

    onCancel() {
        if (this.props.calledFromInvoice) {
            this.props.onClose()
        }
        else {
            this.props.history.push("/home/customer")
        }
    }

    
    async onViewEasyRewardzDashboard(){
        this.setState({loading:false});
        let response = await getEasyRewardWorkflowWidget(this.props.registration.registration.key,
            this.props.user.store.StoreCode,
            this.state.customerDetails.PhoneNo
            )
        if(response.Success){
            let url = response.Data.WidgetURL;
            window.open(url,"_blank");
        }
        else{
            alert("Unable to open EasyRewardz dashboard");
        }

    }

    renderDetails(){
        return <div className="customer-dashboard-details">
        <ul className="nav nav-tabs  ui-sortable-handle">
            <li className={this.state.tabSelected === "GD" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "GD" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'GD' })} data-toggle="tab">General Details</a></li>
            {this.state.hasProducts && <li className={this.state.tabSelected === "PP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "PP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'PP' })} data-toggle="tab">Products</a></li>}
            {this.state.hasServices && <li className={this.state.tabSelected === "SP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "SP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'SP' })} data-toggle="tab">Services</a></li>}
            {this.state.hasWallet && <li className={this.state.tabSelected === "WL" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "WL" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'WL' })} data-toggle="tab">Wallet</a></li>}
            {this.state.hasPackage &&  <li className={this.state.tabSelected === "PK" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "PK" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'PK' })} data-toggle="tab">Packages</a></li>}
            <li className={this.state.tabSelected === "TD" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "TD" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'TD' })} data-toggle="tab">Transaction Details</a></li>
            {this.state.hasPaymentReceive && <li className={this.state.tabSelected === "RP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "RP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'RP' })} data-toggle="tab">Payment Received</a></li>}
            {this.state.hasAppointment && <li className={this.state.tabSelected === "AP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "AP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'AP' })} data-toggle="tab">Appointments</a></li>}
            {this.state.hasLoyalty && <li className={this.state.tabSelected === "LL" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "LL" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'LL' })} data-toggle="tab">Loyalty Ledger</a></li>}
            {this.state.hasCampaign && <li className={this.state.tabSelected === "CP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "CP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'CP' })} data-toggle="tab">Campaigns</a></li>}
        </ul>
        <div className="customer-dashboard-details-tab-section">
            {this.state.tabSelected === "GD" && this.renderGD()}
            {this.state.tabSelected === "PP" && this.renderPP()}
            {this.state.tabSelected === "SP" && this.renderSP()}
            {this.state.tabSelected === "TD" && this.renderTD()}
            {this.state.tabSelected === "WL" && this.renderWL()}
            {this.state.tabSelected === "LL" && this.renderLL()}
        </div>
    </div>}

    renderDashboardSummary(){
        // return <div className="dashboard-summary">
        return  <div className="row">
                    <div className="col-md-3">
                        <div className="info-box info-blue" >
                            <div className="info-box-icon ">
                                <Icon type="sales"/>
                            </div>
                            <div className="info-box-content">
                                <div className="info-box-text">Total Spent</div>
                                <div className="info-box-number">
                                    {Number(this.state.dashBoardHeader.total_spent).toFixed(0)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info-box info-red" >
                            <div className="info-box-icon ">
                                <Icon type="list"/>
                            </div>
                            <div className="info-box-content">
                                <div className="info-box-text">Transactions</div>
                                <div className="info-box-number">
                                    {Number(this.state.dashBoardHeader.total_transactions).toFixed(0)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="info-box info-yellow" >
                            <div className="info-box-icon ">
                                <Icon type="list"/>
                            </div>
                            <div className="info-box-content">
                                <div className="info-box-text">Outstanding</div>
                                <div className="info-box-number">
                                    {Number(this.state.customerDetails.OutStandingAmount ? this.state.customerDetails.OutStandingAmount : 0).toFixed(0)}
                                </div>
                            </div>
                        </div>
                    </div>        
                    {this.state.hasWallet && <div className="col-md-3">
                        <div className="info-box info-green" >
                            <div className="info-box-icon ">
                                <Icon type="wallet"/>
                            </div>
                            <div className="info-box-content">
                                <div className="info-box-text">Wallet Amount</div>
                                <div className="info-box-number">
                                    {Number(this.state.customerDetails.WalletBalance ? this.state.customerDetails.WalletBalance : 0).toFixed(0)}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="col-md-3">
                        <div className="info-box info-yellow" >
                            <div className="info-box-icon ">
                                <Icon type="membership"/>
                            </div>
                            <div className="info-box-content">
                                <div className="info-box-text">Membership</div>
                                <div className="info-box-number">
                                    {this.state.customerDetails.Membership ? this.state.customerDetails.Membership.Name : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(this.state.hasLoyalty && this.state.customerLoyalty) && <div className="col-md-3">
                        <div className="info-box info-red" >
                            <div className="info-box-icon ">
                                <Icon type="loyalty"/>
                            </div>
                            <div className="info-box-content">
                                <div className="info-box-text">{this.state.customerLoyalty.LoyaltyType}</div>
                                <div className="info-box-number">
                                    {this.state.customerLoyalty.LoyaltyPoints}
                                </div>
                            </div>
                        </div>
                    </div>}
    </div>}

    renderGD(){
        return <React.Fragment>
            <div className="row">
                <div className="col-md-4">
                    <div className="row">
                        {this.state.customerDetails.EmailID !== "" &&
                            <React.Fragment>
                                <div className="col-md-12" style={{marginBottom: "10px"}}>
                                    <i className="fa fa-envelope" ></i>&nbsp;&nbsp;{this.state.customerDetails.EmailID}
                                </div>
                            </React.Fragment>}
                        {this.state.customerDetails.PhoneNo !== "" &&
                            <React.Fragment>
                                <div className="col-md-12" style={{marginBottom: "10px"}}>
                                    <i className="fa fa-phone-square" ></i>&nbsp;&nbsp;{this.state.customerDetails.PhoneNo}
                                </div>
                            </React.Fragment>}
                        {this.state.customerDetails.Gender !== "" &&
                            <React.Fragment>
                                <div className="col-md-12" style={{marginBottom: "10px"}}>
                                    <i className="fa fa-venus-mars" ></i>&nbsp;&nbsp;{this.state.customerDetails.Gender}
                                </div>
                            </React.Fragment>}
                        {this.state.customerDetails.DOBNew && this.state.customerDetails.DOBNew.Day !== "" &&
                            <React.Fragment>
                                <div className="col-md-12" style={{marginBottom: "10px"}}>
                                    <i className="fa fa-calendar" ></i>&nbsp;&nbsp;{this.state.customerDetails.DOBNew.Day + "-" + this.state.customerDetails.DOBNew.Month + "-" + this.state.customerDetails.DOBNew.Year === "--" ? "" : this.state.customerDetails.DOBNew.Day + "-" + this.state.customerDetails.DOBNew.Month + "-" + this.state.customerDetails.DOBNew.Year}
                                </div>
                            </React.Fragment>}
                        {this.state.customerDetails.AnniversaryNew && this.state.customerDetails.AnniversaryNew.Day !== "" &&
                            <React.Fragment>
                                <div className="col-md-12" style={{marginBottom: "10px"}}> 
                                    <i className="fa fa-calendar-o" ></i>&nbsp;&nbsp;{this.state.customerDetails.AnniversaryNew.Day + "-" + this.state.customerDetails.AnniversaryNew.Month + "-" + this.state.customerDetails.AnniversaryNew.Year === "--" ? "" : this.state.customerDetails.AnniversaryNew.Day + "-" + this.state.customerDetails.AnniversaryNew.Month + "-" + this.state.customerDetails.AnniversaryNew.Year}
                                </div>
                            </React.Fragment>}
                        {this.state.customerDetails.CustomField1 && this.state.customerDetails.CustomField1.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField1DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField1} <br /></div>}
                        {this.state.customerDetails.CustomField2 && this.state.customerDetails.CustomField2.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField2DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField2} <br /></div>}
                        {this.state.customerDetails.CustomField3 && this.state.customerDetails.CustomField3.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField3DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField3} <br /></div>}
                        {this.state.customerDetails.CustomField4 && this.state.customerDetails.CustomField4.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField4DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField4} <br /></div>}
                        {this.state.customerDetails.CustomField5 && this.state.customerDetails.CustomField5.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField5DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField5} <br /></div>}
                        {this.state.customerDetails.CustomField6 && this.state.customerDetails.CustomField6.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField6DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField6} <br /></div>}
                    </div>
                    
                </div>
                <div className="col-md-8">
                    <div className="row">
                        {this.state.customerDetails.Addresses
                            && this.state.customerDetails.Addresses.map((address) =>
                                <React.Fragment>
                                    <div className="col-md-4">
                                        <text >
                                            <div style={{marginBottom: "10px"}}><i className="fa fa-map-marker"></i> {address.AddressType}</div>
                                            <div style={{marginBottom: "10px"}}>{address.Address1}</div>
                                            <div style={{marginBottom: "10px"}}>{address.Address2}</div>
                                            <div style={{marginBottom: "10px"}}>{address.City}</div>
                                            <div style={{marginBottom: "10px"}}>{address.State}</div>
                                            <div style={{marginBottom: "10px"}}>{address.PostalCode}</div>
                                            <div style={{marginBottom: "10px"}}>{address.Country}</div>
                                        </text>
                                    </div>
                                </React.Fragment>)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    renderPP(){
        return <div className="table-bordered table-responsive">
            {this.state.customerProducts.length > 0 && <table className="table no-margin table-striped">
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Total Qty</th>
                        <th>Total Sales</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.customerProducts.map((customerProduct) =>
                        <tr>
                            <td>{customerProduct.name}</td>
                            <td>{customerProduct.total_quantity}</td>
                            <td>{customerProduct.total_sales}</td>
                        </tr>
                    )}
                </tbody>
            </table>}
            {this.state.customerProducts.length === 0 && "No records found"}
        </div>
    }

    renderSP(){
        return <div className="table-bordered table-responsive">
            {this.state.customerServices.length > 0 && <table className="table no-margin table-striped">
                <thead>
                    <tr>
                        <th>Service Name</th>
                        <th>Total Sales</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.customerServices.map((customerService) =>
                        <tr>
                            <td>{customerService.name}</td>
                            <td>{customerService.total_sales}</td>
                        </tr>
                    )}
                </tbody>
            </table>}
            {this.state.customerServices.length === 0 && "No records found"}
        </div>
    }

    renderTD(){
        return <div className=" table-bordered table-responsive" >
            {this.state.transactions.length > 0 &&
                <table className="table no-margin table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Retail Price</th>
                            <th>Discount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.transactions.map((transaction) => transaction.LineItems.map((lineItem) =>
                            <tr>
                                <td>{transaction.TransactionNo}</td>
                                <td>{(new Date(transaction.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                                <td>{lineItem.Product.Name}</td>
                                <td>{lineItem.Qty}</td>
                                <td>{lineItem.RetailPrice}</td>
                                <td>{lineItem.DiscountAmount}</td>
                                <td>{lineItem.Amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            {this.state.transactions.length === 0 && "No records found"}
        </div>
    }

    renderWL(){
        return <div className="table table-bordered">
            {this.state.walletLedgers.length > 0 && <table className="table no-margin table-striped">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Narration</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.walletLedgers.map((ledger) =>
                        <tr>
                            <td>{(new Date(ledger.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                            <td>{Number(ledger.CreditAmount).toFixed(2) > 0 ? "Credit for Transaction: " + ledger.TransactionNo : "Debit for Transaction: " + ledger.TransactionNo}</td>
                            <td>{Number(ledger.CreditAmount).toFixed(2)}</td>
                            <td>{Number(ledger.DebitAmount).toFixed(2)}</td>
                            <td>{Number(ledger.WalletClosingBalance).toFixed(2)}</td>
                        </tr>
                    )}
                </tbody>
            </table>}
            {this.state.walletLedgers.length === 0 && "No records found"}
        </div>
    }

    renderLL(){
        return <div className="table-bordered table-responsive ">
            {this.state.loyaltyLedgers.length > 0 && <table className="table no-margin table-striped">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Narration</th>
                        <th>Earn</th>
                        <th>Burn</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.loyaltyLedgers.map((ledger) =>
                        <tr>
                            <td>{(new Date(ledger.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                            <td>{ledger.Type === "earn" ? "Earn for Transaction: " + ledger.TransactionNo : "Burn for Transaction: " + ledger.TransactionNo}</td>
                            <td>{ledger.Type === "earn" ? ledger.LoyaltyPoints : 0}</td>
                            <td>{ledger.Type === "burn" ? ledger.LoyaltyPoints : 0}</td>
                        </tr>
                    )}
                </tbody>
            </table>}
            {this.state.loyaltyLedgers.length === 0 && "No records found"}
            {this.props.registration.registration.EnableEasyRewardz && <Button type="secondary" text="View EasyRewardz Dashboard" onClick={this.onViewEasyRewardzDashboard}/>}
        </div>
    }

    render1() {
        return <div className="content-wrapper">
            <section className="content" >
               
                <div className="row">
                    <div className="col-md-12">
                                <h1>{this.state.customerDetails.FirstName + ' ' + this.state.customerDetails.LastName}</h1>
                                <button className="btn btn-md btn-flat btn-default pull-right" onClick={this.onCancel}>
                                    <i className="fa fa-arrow-left"></i> Back</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="customer-info-section">
                            <div className="info-box">
                                <span className="info-box-icon bg-Yellow"><i className="fa fa-money" ></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" >Total Spent</span>
                                    <span className="info-box-number">{this.state.dashBoardHeader.total_spent}</span>
                                </div>
                            </div>
                            <div className="info-box">
                                <span className="info-box-icon " >
                                    <i className="fa fa-file-o" ></i>
                                </span>
                                <div className="info-box-content">
                                    <span className="info-box-text" >Transactions</span>
                                    <span className="info-box-number">{this.state.dashBoardHeader.total_transactions}</span>
                                </div>
                            </div>
                            <div className="info-box">
                                <span className="info-box-icon"><i className="fa fa-money"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" >Outstanding</span>
                                    <span className="info-box-number" >{this.state.customerDetails.OutStandingAmount ? this.state.customerDetails.OutStandingAmount : 0}</span>
                                </div>
                            </div>
                            <div className="info-box">
                                <span className="info-box-icon" ><i className="fa fa-money"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Wallet Amount</span>

                                    <span className="info-box-number">{Number(this.state.customerDetails.WalletBalance ? this.state.customerDetails.WalletBalance : 0).toFixed(2)}</span>
                                </div>
                            </div>
                            <div className="info-box">
                                <span className="info-box-icon" ><i  className="fa fa-star"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" >Membership</span>

                                    <span className="info-box-number" >{this.state.customerDetails.Membership ? this.state.customerDetails.Membership.Name : ""}</span>
                                </div>
                            </div>
                            {(this.props.registration.registration.hasOwnProperty('LoyaltyEnabled') && this.props.registration.registration.LoyaltyEnabled && this.state.customerLoyalty) &&
                                <div className="info-box" >
                                    <span className="info-box-icon bg-aqua"><i  className="fa fa-bullseye" ></i></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text" >{this.state.customerLoyalty.LoyaltyType}</span>
                                        <span className="info-box-number">{this.state.customerLoyalty.LoyaltyPoints}</span>
                                    </div>
                            </div>}
                        </div>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="nav-tabs-custom" style={{ cursor: "move" }}>
                                    <ul className="nav nav-tabs  ui-sortable-handle">
                                        <li className={this.state.tabSelected === "GD" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "GD" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'GD' })} data-toggle="tab">General Details</a></li>
                                        <li className={this.state.tabSelected === "PP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "PP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'PP' })} data-toggle="tab">Products</a></li>
                                        <li className={this.state.tabSelected === "SP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "SP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'SP' })} data-toggle="tab">Services</a></li>
                                        <li className={this.state.tabSelected === "WL" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "WL" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'WL' })} data-toggle="tab">Wallet</a></li>
                                        <li className={this.state.tabSelected === "PK" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "PK" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'PK' })} data-toggle="tab">Packages</a></li>
                                        <li className={this.state.tabSelected === "TD" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "TD" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'TD' })} data-toggle="tab">Transaction Details</a></li>
                                        <li className={this.state.tabSelected === "RP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "RP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'RP' })} data-toggle="tab">Payment Received</a></li>
                                        <li className={this.state.tabSelected === "AP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "AP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'AP' })} data-toggle="tab">Appointments</a></li>
                                        <li className={this.state.tabSelected === "LL" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "LL" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'LL' })} data-toggle="tab">Loyalty Ledger</a></li>
                                        <li className={this.state.tabSelected === "CP" ? "active" : ""} style={{ borderTopColor: this.state.tabSelected === "CP" ? "#D2D6DE" : "transparent" }}><a onClick={() => this.setState({ tabSelected: 'CP' })} data-toggle="tab">Campaigns</a></li>
                                    </ul>
                                    <div className="scroll2" style={{ height: "465px", padding: "10px", paddingBottom: "0px", overflowY: "scroll", overflowX: "hidden" }}>
                                        {this.state.tabSelected === "GD" &&
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="row">
                                                            {this.state.customerDetails.EmailID !== "" &&
                                                                <React.Fragment>
                                                                    <div className="col-md-12" style={{marginBottom: "10px"}}>
                                                                        <i className="fa fa-envelope" ></i>&nbsp;&nbsp;{this.state.customerDetails.EmailID}
                                                                    </div>
                                                                </React.Fragment>}
                                                            {this.state.customerDetails.PhoneNo !== "" &&
                                                                <React.Fragment>
                                                                    <div className="col-md-12" style={{marginBottom: "10px"}}>
                                                                        <i className="fa fa-phone-square" ></i>&nbsp;&nbsp;{this.state.customerDetails.PhoneNo}
                                                                    </div>
                                                                </React.Fragment>}
                                                            {this.state.customerDetails.Gender !== "" &&
                                                                <React.Fragment>
                                                                    <div className="col-md-12" style={{marginBottom: "10px"}}>
                                                                        <i className="fa fa-venus-mars" ></i>&nbsp;&nbsp;{this.state.customerDetails.Gender}
                                                                    </div>
                                                                </React.Fragment>}
                                                            {this.state.customerDetails.DOBNew && this.state.customerDetails.DOBNew.Day !== "" &&
                                                                <React.Fragment>
                                                                    <div className="col-md-12" style={{marginBottom: "10px"}}>
                                                                        <i className="fa fa-calendar" ></i>&nbsp;&nbsp;{this.state.customerDetails.DOBNew.Day + "-" + this.state.customerDetails.DOBNew.Month + "-" + this.state.customerDetails.DOBNew.Year === "--" ? "" : this.state.customerDetails.DOBNew.Day + "-" + this.state.customerDetails.DOBNew.Month + "-" + this.state.customerDetails.DOBNew.Year}
                                                                    </div>
                                                                </React.Fragment>}
                                                            {this.state.customerDetails.AnniversaryNew && this.state.customerDetails.AnniversaryNew.Day !== "" &&
                                                                <React.Fragment>
                                                                    <div className="col-md-12" style={{marginBottom: "10px"}}> 
                                                                        <i className="fa fa-calendar-o" ></i>&nbsp;&nbsp;{this.state.customerDetails.AnniversaryNew.Day + "-" + this.state.customerDetails.AnniversaryNew.Month + "-" + this.state.customerDetails.AnniversaryNew.Year === "--" ? "" : this.state.customerDetails.AnniversaryNew.Day + "-" + this.state.customerDetails.AnniversaryNew.Month + "-" + this.state.customerDetails.AnniversaryNew.Year}
                                                                    </div>
                                                                </React.Fragment>}
                                                            {this.state.customerDetails.CustomField1 && this.state.customerDetails.CustomField1.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField1DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField1} <br /></div>}
                                                            {this.state.customerDetails.CustomField2 && this.state.customerDetails.CustomField2.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField2DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField2} <br /></div>}
                                                            {this.state.customerDetails.CustomField3 && this.state.customerDetails.CustomField3.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField3DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField3} <br /></div>}
                                                            {this.state.customerDetails.CustomField4 && this.state.customerDetails.CustomField4.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField4DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField4} <br /></div>}
                                                            {this.state.customerDetails.CustomField5 && this.state.customerDetails.CustomField5.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField5DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField5} <br /></div>}
                                                            {this.state.customerDetails.CustomField6 && this.state.customerDetails.CustomField6.trim() !== "" && <div style={{ marginLeft: "15px", marginBottom: "10px" }}>{this.state.customField6DisplayName}:&nbsp;&nbsp;{this.state.customerDetails.CustomField6} <br /></div>}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            {this.state.customerDetails.Addresses
                                                                && this.state.customerDetails.Addresses.map((address) =>
                                                                    <React.Fragment>
                                                                        <div className="col-md-4">
                                                                            <text >
                                                                                <div style={{marginBottom: "10px"}}><i className="fa fa-map-marker"></i> {address.AddressType}</div>
                                                                                <div style={{marginBottom: "10px"}}>{address.Address1}</div>
                                                                                <div style={{marginBottom: "10px"}}>{address.Address2}</div>
                                                                                <div style={{marginBottom: "10px"}}>{address.City}</div>
                                                                                <div style={{marginBottom: "10px"}}>{address.State}</div>
                                                                                <div style={{marginBottom: "10px"}}>{address.PostalCode}</div>
                                                                                <div style={{marginBottom: "10px"}}>{address.Country}</div>
                                                                            </text>
                                                                        </div>
                                                                    </React.Fragment>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {this.state.tabSelected === "PP" &&
                                            <div className="table-bordered table-responsive">
                                                {this.state.customerProducts.length > 0 && <table className="table no-margin table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Total Qty</th>
                                                            <th>Total Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.customerProducts.map((customerProduct) =>
                                                            <tr>
                                                                <td>{customerProduct.name}</td>
                                                                <td>{customerProduct.total_quantity}</td>
                                                                <td>{customerProduct.total_sales}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.customerProducts.length === 0 && "No records found"}
                                            </div>}
                                        {this.state.tabSelected === "SP" &&
                                            <div className="table-bordered table-responsive">
                                                {this.state.customerServices.length > 0 && <table className="table no-margin table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Service Name</th>
                                                            <th>Total Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.customerServices.map((customerService) =>
                                                            <tr>
                                                                <td>{customerService.name}</td>
                                                                <td>{customerService.total_sales}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.customerServices.length === 0 && "No records found"}
                                            </div>}
                                        {this.state.tabSelected === "TD" &&
                                            <div className=" table-bordered table-responsive" >
                                                {this.state.transactions.length > 0 &&
                                                    <table className="table no-margin table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Date</th>
                                                                <th>Product</th>
                                                                <th>Qty</th>
                                                                <th>Retail Price</th>
                                                                <th>Discount</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.transactions.map((transaction) => transaction.LineItems.map((lineItem) =>
                                                                <tr>
                                                                    <td>{transaction.TransactionNo}</td>
                                                                    <td>{(new Date(transaction.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                                                                    <td>{lineItem.Product.Name}</td>
                                                                    <td>{lineItem.Qty}</td>
                                                                    <td>{lineItem.RetailPrice}</td>
                                                                    <td>{lineItem.DiscountAmount}</td>
                                                                    <td>{lineItem.Amount}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>}
                                                {this.state.transactions.length === 0 && "No records found"}
                                            </div>}
                                        {this.state.tabSelected === "WL" &&
                                            <div className="table table-bordered">
                                                {this.state.walletLedgers.length > 0 && <table className="table no-margin table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Narration</th>
                                                            <th>Credit</th>
                                                            <th>Debit</th>
                                                            <th>Balance</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.walletLedgers.map((ledger) =>
                                                            <tr>
                                                                <td>{(new Date(ledger.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                                                                <td>{Number(ledger.CreditAmount).toFixed(2) > 0 ? "Credit for Transaction: " + ledger.TransactionNo : "Debit for Transaction: " + ledger.TransactionNo}</td>
                                                                <td>{Number(ledger.CreditAmount).toFixed(2)}</td>
                                                                <td>{Number(ledger.DebitAmount).toFixed(2)}</td>
                                                                <td>{Number(ledger.WalletClosingBalance).toFixed(2)}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.walletLedgers.length === 0 && "No records found"}
                                            </div>
                                        }
                                        {(this.state.tabSelected === "LL" && this.props.registration.registration.hasOwnProperty('LoyaltyEnabled') && this.props.registration.registration.LoyaltyEnabled) &&
                                            <div className="table-bordered table-responsive ">
                                                LOYALRY LEDGER
                                                {this.state.loyaltyLedgers.length > 0 && <table className="table no-margin table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Narration</th>
                                                            <th>Earn</th>
                                                            <th>Burn</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.loyaltyLedgers.map((ledger) =>
                                                            <tr>
                                                                <td>{(new Date(ledger.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                                                                <td>{ledger.Type === "earn" ? "Earn for Transaction: " + ledger.TransactionNo : "Burn for Transaction: " + ledger.TransactionNo}</td>
                                                                <td>{ledger.Type === "earn" ? ledger.LoyaltyPoints : 0}</td>
                                                                <td>{ledger.Type === "burn" ? ledger.LoyaltyPoints : 0}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.loyaltyLedgers.length === 0 && "No records found"}
                                            </div>
                                        }
                                        {this.state.tabSelected === "RP" &&
                                            <div className="table-bordered table-responsive">
                                                {this.state.transactions.length > 0 &&
                                                    <table className="table no-margin table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Date</th>
                                                                <th>OutStanding Transaction No</th>
                                                                <th>OutStanding Amount</th>
                                                                <th>Received Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                                            {this.state.transactions.filter((e) => e.TransactionType.TransactionType === 'Receive Payment').map((transaction) => transaction.OutStandingPayments.map((lineItem) =>
                                                                <tr>
                                                                    <td>{transaction.TransactionNo}</td>
                                                                    <td>{(new Date(transaction.TransactionDate.seconds * 1000)).toString().substring(0, 24)}</td>
                                                                    <td>{lineItem.TransactionNo}</td>
                                                                    <td>{lineItem.OutStandingAmount}</td>
                                                                    <td>{lineItem.ReceiveAmount}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>}
                                                {this.state.transactions.length === 0 && "No records found"}
                                            </div>
                                        }
                                        {this.state.tabSelected === "AP" &&
                                            <React.Fragment>
                                                <div className="box-header">
                                                    <h3 className="box-title">Upcoming Appointments</h3>
                                                </div>
                                                <div className="table-bordered table-responsive">
                                                    {this.state.upcomingAppointments.length > 0 &&
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Service</th>
                                                                    <th>Employee</th>
                                                                    <th>Slot</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.upcomingAppointments.map((appointment) =>
                                                                    <tr>
                                                                        <td>{(new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0]}</td>
                                                                        <td>{appointment.Service.label}</td>
                                                                        <td>{appointment.EmployeeName}</td>
                                                                        <td>{appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</td>
                                                                        <td>{appointment.Status ==="" ?"Booked": appointment.Status}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>}
                                                    {this.state.upcomingAppointments.length === 0 && "No records found"}
                                                </div>
                                                <br />
                                                <br />
                                                <div className="box-header">
                                                    <h3 className="box-title">Past Appointments</h3>
                                                </div>
                                                <div className="table-bordered table-responsive">
                                                    {this.state.pastAppointments.length > 0 &&
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date</th>
                                                                    <th>Service</th>
                                                                    <th>Employee</th>
                                                                    <th>Slot</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.pastAppointments.map((appointment) =>
                                                                    <tr>
                                                                        <td>{(new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0]}</td>
                                                                        <td>{appointment.Service.label}</td>
                                                                        <td>{appointment.EmployeeName}</td>
                                                                        <td>{appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</td>
                                                                        <td>{appointment.Status}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>}
                                                    {this.state.upcomingAppointments.length === 0 && "No records found"}
                                                </div>
                                            </React.Fragment>
                                        }
                                        {this.state.tabSelected === "PK" &&
                                            <React.Fragment>
                                               <div className="table-bordered table-responsive">
                                                {this.state.packages.length > 0 &&
                                                    <table className="table no-margin table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Package</th>
                                                                <th>Package Start Date</th>
                                                                <th>Package End Date</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.packages.map((objPackage) =>
                                                                <tr>
                                                                    <td>{objPackage.TransactionNo}</td>
                                                                    <td>{objPackage.PackageName}</td>
                                                                    <td>{new Date(objPackage.CreatedOn).toISOString().split('T')[0]}</td>
                                                                    <td>{new Date(objPackage.PackageEndDate).toISOString().split('T')[0]}</td>
                                                                    <td>{objPackage.PackageStatus}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>}
                                                {this.state.packages.length === 0 && "No records found"}
                                            </div>
                                            </React.Fragment>
                                        }
                                        {this.state.tabSelected === "CP" &&
                                            <React.Fragment>
                                               <div className="table-bordered table-responsive">
                                                {this.state.campaigns.length > 0 &&
                                                    <table className="table no-margin table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Package</th>
                                                                <th>Package Start Date</th>
                                                                <th>Package End Date</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.campaigns.map((objPackage) =>
                                                                <tr>
                                                                    <td>{objPackage.TransactionNo}</td>
                                                                    <td>{objPackage.PackageName}</td>
                                                                    <td>{new Date(objPackage.CreatedOn).toISOString().split('T')[0]}</td>
                                                                    <td>{new Date(objPackage.PackageEndDate).toISOString().split('T')[0]}</td>
                                                                    <td>{objPackage.PackageStatus}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>}
                                                {this.state.campaigns.length === 0 && "No records found"}
                                            </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };

    renderMobile(){
        return <Window
        header={this.state.customerDetails.FirstName + ' ' + this.state.customerDetails.LastName}
        onClose={()=>this.onCancel()}
        onButtonClick={(buttonType)=>{}}
        buttons={[]}
        >
            {this.renderDashboardSummary()}
            {this.renderDetails()}
        </Window>
    }

    renderDesktop(){
        return <div className="content-wrapper">
                <section className="content" >
                <div  className="dashboard-header">
                    <div className="dashboard-header-title left">
                        {this.state.customerDetails.FirstName + ' ' + this.state.customerDetails.LastName}
                    </div>
                    <div className="dashboard-header-options right">
                        <ImageButton type="remove" onClick={this.onCancel}/>
                    </div>
                </div>
                {this.renderDashboardSummary()}
                {this.renderDetails()}
           
        </section>
    </div>
    }

    render(){
        return <React.Fragment>
            {!isMobileApp() && this.renderDesktop()}
            {isMobileApp() && this.renderMobile()}
        </React.Fragment>
    }
};
export default CustomerDashboard;