export function getPinCodeStoreDomain() {
  return [
    { id: "Grocery", value: "Grocery" },
    { id: "Food", value: "Food" },
    { id: "Fashion", value: "Fashion" },
    { id: "Electronics", value: "Electronics" },
    { id: "Home Decor", value: "Home Decor" },
    { id: "Pharmacy", value: "Pharmacy" },
  ];
}

export function getPinCodeStoreType(domain) {
  const storeTypes = [
    { id: "C7T1VNDB", value: "Supermarket", domain: "Grocery" },
    { id: "CLLFB9UF", value: "Organic & Dry Fruits", domain: "Grocery" },
    { id: "CHELTROZ", value: "Meat & Poultry", domain: "Grocery" },
    { id: "CI548YMP", value: "Fruits & Vegetables", domain: "Grocery" },
    { id: "COZBVOU4", value: "Brand Store", domain: "Grocery" },
    { id: "CEWM0PQC", value: "Pet Store", domain: "Grocery" },

    { id: "CPSANXFM", value: "Kirana and General Store", domain: "Grocery" },
    { id: "CFZJDXAN", value: "Spice and Condiment Store", domain: "Grocery" },
    { id: "CJV2X1SC", value: "Dairy Shop", domain: "Grocery" },
    { id: "CMM3TNXH", value: "Stationery and Book Store", domain: "Grocery" },

    { id: "CZMQ8MCQ", value: "Sweet Shop", domain: "Food" },
    { id: "C882XMAR", value: "Cakes & Bakery", domain: "Food" },
    { id: "CU0V6HY8", value: "Bakery", domain: "Food" },
    { id: "CQHAFXBO", value: "Cafe", domain: "Food" },
    { id: "CKEA0YUY", value: "Quick Bites", domain: "Food" },
    { id: "CD5TKAXS", value: "Budget Eats", domain: "Food" },
    { id: "CWEZYP1B", value: "Casual Dining", domain: "Food" },
    { id: "CZKDDJRQ", value: "Fine Dining", domain: "Food" },
    { id: "C2JIZFKY", value: "Delivery Only", domain: "Food" },
    { id: "C1MR1UOV", value: "Bars, Pubs & Lounge", domain: "Food" },
    { id: "CHBAND8S", value: "Canteen & Mess", domain: "Food" },
    { id: "CUNWOHUO", value: "Desserts and Ice Cream Parlour", domain: "Food" },
    { id: "CMCJI69D", value: "Food Delivery", domain: "Food" },
    { id: "CYWG5MT2", value: "Internet Kitchen", domain: "Food" },
    { id: "CP83HQKS", value: "Fast Food", domain: "Food" },
    { id: "CS7ZTZEE", value: "Juice Corner", domain: "Food" },
    { id: "CHWKFSLQ", value: "Snacks", domain: "Food" },
    { id: "C2VPROVY", value: "Tea Shop", domain: "Food" },

    { id: "CXYNL6KL", value: "Multi-Brand Fashion Store", domain: "Fashion" },
    { id: "CN1JQODL", value: "Fashion Brand Store", domain: "Fashion" },
    { id: "CKPBOIDG", value: "Womens Wear", domain: "Fashion" },
    { id: "CDGDKS5Q", value: "Ready-Made Garment Store", domain: "Fashion" },
    { id: "CBFISVYT", value: "Kids Wear Store", domain: "Fashion" },
    { id: "CJOFNQE7", value: "Fashion Accessories", domain: "Fashion" },
    { id: "C571TN1P", value: "Mens Wear Store", domain: "Fashion" },

    { id: "CMUYLUYQ", value: "Smart Phone Store", domain: "Electronics" },
    { id: "CFPM4DVM", value: "Electronics Store", domain: "Electronics" },
    { id: "CPWXSZJX", value: "Appliances Store", domain: "Electronics" },
    { id: "CNE219BS", value: "Computer Store", domain: "Electronics" },
    {
      id: "CHRUCRZJ",
      value: "Musical Instrument Store",
      domain: "Electronics",
    },

    {
      id: "CDUB4SKA",
      value: "Refurbished Electronics Store",
      domain: "Electronics",
    },
    { id: "CIUNUGIB", value: "Electrical Store", domain: "Electronics" },
    { id: "CEC23CFX", value: "Electronics Brand Store", domain: "Electronics" },
    {
      id: "CC1KJUTD",
      value: "Mobile and Accessories Store",
      domain: "Electronics",
    },

    { id: "C4TIYDUS", value: "Furniture Store", domain: "Home Decor" },
    { id: "CDQUKYD2", value: "Mattress House", domain: "Home Decor" },
    { id: "C5HW4DNF", value: "Gift Shop", domain: "Home Decor" },
    { id: "CDEBJZGI", value: "Furnishing Store", domain: "Home Decor" },
    { id: "C4CJAYRY", value: "Lightnings Store", domain: "Home Decor" },
    { id: "C0F2BHO3", value: "Art & Decor House", domain: "Home Decor" },

    { id: "CDHG3LGK", value: "Kitchenware Store", domain: "Home Decor" },
    { id: "CWRUQ7C4", value: "Carpets and Rugs Shop", domain: "Home Decor" },
    {
      id: "CXAFNS3X",
      value: "Gardenening Supplies Store",
      domain: "Home Decor",
    },
    { id: "CVFQ12MX", value: "Lighting Store", domain: "Home Decor" },
    { id: "C1KZ7O4S", value: "Home Care", domain: "Home Decor" },

    { id: "CADUPK5H", value: "Pharmacy", domain: "Pharmacy" },
    {
      id: "CXX8HTKO",
      value: "Vitamins and Supplements Store",
      domain: "Pharmacy",
    },
    { id: "CERLYHSR", value: "Ayurvedic Pharmacy", domain: "Pharmacy" },
    { id: "CRAFDIUX", value: "Pharmacy Brand Store", domain: "Pharmacy" },
  ];
  return storeTypes.filter((f) => f.domain === domain);
}
