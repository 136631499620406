import React, { Component } from 'react';

class WidgetCustomerAnalysis extends Component {

    constructor({ stores, fromDate, toDate, widget }) {
        super();
        this.state = {
            stores: stores,
            fromDate: fromDate, 
            toDate: toDate,
            widget: widget,
            categories: []
            , totalWalkinCount: 0
            , totalRepeatedCount: 0
            , totalNewCount: 0
        }
    }

    async componentDidMount() {
        this.loadData(this.state.stores, this.state.fromDate, this.state.toDate, this.state.widget);
    }

    async loadData(stores, fromDate, toDate, widget) {
        
        let totalWalkinCount = await this.props.getWalkinCustomers(this.props.user.user.RegistrationID, stores, fromDate, toDate).then((result) => {
            return result.totalWalkinsCount
        });

        let customers = await this.props.getSalesCustomers(this.props.user.user.RegistrationID, stores, fromDate, toDate).then((result) => {
            return result.searchResult;
        });

        let totalNewCount = await this.props.getNewCustomers(customers, fromDate, toDate).then((result) => {
            return result.totalNewCount;
        });

        let totalRepeatedCount = await this.props.getRepeatedCustomers(customers, fromDate, toDate).then((result) => {
            return result.totalRepeatedCount
        });

        this.setState({ totalWalkinCount: totalWalkinCount, totalNewCount: totalNewCount, totalRepeatedCount: totalRepeatedCount })
    }

    componentWillReceiveProps({ stores, fromDate, toDate, widget }) {
        this.loadData(stores, fromDate, toDate, widget);
    }

    render() {
        return <div className="dashboard-box">
            <div className="dashboard-box-header ">
                <div className="box-title">Customers</div>
            </div>
            <div className="dashboard-box-body">
                <div className="table-responsive scroll2">
                    <table className="table table-striped no-margin">
                        <thead>
                            <tr>
                                <th>Customers</th>
                                <th style={{ textAlign: "right" }}>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Walkins</td>
                                <td style={{ textAlign: "right" }}>{this.state.totalWalkinCount}</td>
                            </tr>
                            <tr>
                                <td>New</td>
                                <td style={{ textAlign: "right" }}>{this.state.totalNewCount}</td>
                            </tr>
                            <tr>
                                <td>Repeated</td>
                                <td style={{ textAlign: "right" }}>{this.state.totalRepeatedCount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
}

export default WidgetCustomerAnalysis