import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getSurveys(registrationID)
{
    return (dispatch) => {
        return database.collection("Surveys").where("RegistrationID","==",registrationID).get().then((querySnapshot) => {        
            const surveys = []
            querySnapshot.forEach((doc) => {
                surveys.push(
                  {
                    key:doc.id,...doc.data()
                  }
                )
           })
            return surveys
        }).catch((error) => {console.log(error)})
    }
}

export function saveSurvey(survey, key){
    return (dispatch, getState) => {
        survey.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        survey.ActionBy = getState().user.user.Name
        survey.ActionByUID = getState().user.user.UID
        survey.ActionByEmailID = getState().user.user.EmailID

        if(key !== "")
        {
            survey.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Surveys").doc(key).update({
                ...survey
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating survey: ", error);
              })
        }
        else
        {
            survey.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Surveys").add({
                ...survey
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding survey: ", error);
              })
        }
    }
}

export function deleteSurvey(key)
{
    return (dispatch, getState) => {
        return database.collection("Surveys").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted : true
        }).then(()=> {return true})
    }
}

export function getSurveyDetails(key){
    return dispatch => {
    return database.collection("Surveys").doc(key).get().then((doc) => {
        if (doc.exists) {
            console.log("Survey found", doc.data());
           return doc.data()
        } else {
            console.log("No such Surveys!");
        }
    })
    }
}
