import React, { Component } from 'react'
import HeaderNew from '../HeaderNew';
import ListSearch from '../Common/ListSearch';
import PopupMenu from '../Common/PopupMenu';
import Confirmation from '../Common/Confirmation';
import ActionButton from '../Common/ActionButton'
import Spinner from '../Common/Spinner'

class TablesNew extends Component {

    constructor() {
        super()
        this.state = {
            tables: [],
            searchText: '',
            selectedTable: null,
            showPopupMenu: false
        }
        this.load = this.load.bind(this)
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onHeaderBackClick = this.onHeaderBackClick.bind(this)
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
    }

    async componentDidMount() {
        this.load()
    }

    async load() {
        this.setState({ loading: true })
        let tables = await this.props.getTables(this.props.user.user.RegistrationID)
        this.setState({ tables: tables.filter((e) => !e.IsDeleted), loading: false })
    }

    onHeaderBackClick() {
        this.props.history.push({ pathname: '/home' })
    }

    async onRowClick(selectedTable) {
        this.setState({ selectedTable: selectedTable, showPopupMenu: true })
    }

    async onShowPopupMenuClick(value) {

        if (value === "edit") {
            this.setState({ showPopupMenu: false, selectedTable: null })
            this.props.history.push({ pathname: '/home/settings/tables/edit', state: { module: "tables", table: this.state.selectedTable } })
        }
        else if (value === "delete") {
            this.setState({ showDelete: true, showPopupMenu: false });
        }
        else {
            this.setState({ showPopupMenu: false, selectedTable: null })
        }
    }

    onDeleteConfirmation(value) {
        if (value) {
            let object = this.state.selectedTable;
            this.props.deleteTable(object.key).then(() => {
                this.load()
                setTimeout(function () {
                    this.props.getToastr("Table deleted successfully")
                    this.setState({ showDelete: false, loading: false });
                }.bind(this), 2000)
            })
        }
        else {
            this.setState({ showDelete: false });
        }
    }

    async onActionButtonClick(action) {
        this.props.history.push({ pathname: '/home/settings/tables/new', state: { module: "tables", table: null } })
    }

    render() {
        return <React.Fragment>

            <HeaderNew {...this.props}
                back={true}
                title="Payments"
                filter={false}
                onBackClick={() => this.onHeaderBackClick()}
            />

            <div className="header-new-background" />

            <div className="content-wrapper">

                <div className="container">

                    <ListSearch
                        value={this.state.searchText}
                        onChange={(searchText) => { this.setState({ searchText: searchText }) }}
                        searchPlaceHolder="Search tables" />

                    {this.state.loading && <Spinner />}

                    {!this.state.loading && <div className="list">

                        {this.state.tables.filter(f => {
                            let searchText = this.state.searchText;
                            if (searchText.length === 0) { return true }
                            else {
                                if (f.Table.toLowerCase().includes(searchText.toLowerCase())) { return true }
                            }
                            return false
                        }).map((table) => <div className="list-row seperator"
                            onClick={() => { this.onRowClick(table); }}>
                            <div>
                                <div className="row-title">{table.Table}</div>
                                <div className="row-text">{table.SequenceNo}</div>
                            </div>
                        </div>)}
                    </div>}
                </div>
            </div>
            <ActionButton
                onClick={(action) => this.onActionButtonClick(action)}
                fabButtons={[]} />

            <PopupMenu
                show={this.state.showPopupMenu}
                buttons={[{ value: "delete", text: "Delete", module: 'tables' },
                { value: "edit", text: "Edit", module: 'tables' }
                ]}
                onClick={(action) => this.onShowPopupMenuClick(action)} />


            <Confirmation show={this.state.showDelete} onClick={(value) => this.onDeleteConfirmation(value)} />

        </React.Fragment>
    }
}

export default TablesNew

