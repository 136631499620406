import * as actionTypes from '../actionTypes'
import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function savePrintConfig(printConfig, key){
    return async (dispatch, getState) =>{
        console.log('savePrintConfig key', key)
        printConfig.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        printConfig.ActionBy = getState().user.user.Name
        printConfig.ActionByUID = getState().user.user.UID
        printConfig.ActionByEmailID = getState().user.user.EmailID

//         printConfig.WhiteLabel = "PINCODE"
//         printConfig.RegistrationID="";
//         printConfig.StoreID="";
//         printConfig.TransactionTypeID="";
// printConfig.ActionOn = "";
//         printConfig.ActionBy = "";
//         printConfig.ActionByUID = "";
//         printConfig.ActionByEmailID = "";
//         //get all print config
//         let pringConfigs = await database.collection("OnBoarding").doc("PrintConfigs").get().then((doc) => {
//             // console.log(doc.data())
//             return doc.data()
//         });
//         console.log("pringConfigs",pringConfigs)
//         pringConfigs.data.push(printConfig);
//         // return;
//         let r = {
//             data:pringConfigs.data
//            }
//         console.log(r);
//         // return;
//         return database.collection("OnBoarding").doc("PrintConfigs").update(r).then(()=>{
//             return ""
//           }).catch(function(error) {
//               console.error("Error updating print config: ", error);
//           })

        if(key.trim()!== "")
        {
            console.log('update called')
            printConfig.Action = constants.AUDIT_TRAIL_EDIT
            
            return database.collection("PrintConfigs").doc(key).update({
                ...printConfig
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating print config: ", error);
              })
        }
        else
        {
            console.log('new called')
            printConfig.Action = constants.AUDIT_TRAIL_NEW
            
            return database.collection("PrintConfigs").add({
                ...printConfig
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding print config: ", error);
              })
        }
    }
}

export function savePrintConfigTemplate(printTemplate, key){
    return (dispatch, getState) => {
      
        if(key.trim()!== "")
        {
            return database.collection("PrintConfigs").doc(key).update({
                ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
                ActionBy: getState().user.user.Name,
                ActionByUID: getState().user.user.UID,
                ActionByEmailID: getState().user.user.EmailID,
                Action: constants.AUDIT_TRAIL_EDIT,
                PrintTemplate:printTemplate
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating print config: ", error);
              })
        }
    }
}
export function deletePrintConfig(key)
{
    return (dispatch, getState) => {
        
        return database.collection("PrintConfigs").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted : true
        }).then(()=> {return true})
    }
}

export function getPrintConfigsList(registrationID, storeID) {
   
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getPrintConfigs');
        return addMessage({ registrationID: registrationID, storeID:storeID}).then(function (result) {
            // console.log(result.data)
            let printConfigs  = result.data;
            return printConfigs;
        });
    }
}

export function getPrintConfigs(registrationID, storeID) {
    // return (dispatch) => {
    //     return database.collection("PrintConfigs").where("RegistrationID","==", registrationID).where("StoreID","==",storeID).get().then((querySnapshot) => {
    //         const printConfigs = []
    //         querySnapshot.forEach((doc) => {
    //             printConfigs.push(
    //               {
    //                 key:doc.id,
    //                 ...doc.data()
    //               })
    //        })
    //        //console.log(productCategories)
    //        dispatch({type: actionTypes.PRINT_CONFIG_GET,printConfigs})
    //        //return printConfigs
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getPrintConfigs');
        return addMessage({ registrationID: registrationID, storeID:storeID}).then(function (result) {
            // console.log(result.data)
            let printConfigs  = result.data;
            dispatch({ type: actionTypes.PRINT_CONFIG_GET,printConfigs })
        });
    }
}

export function getPrintConfigByID(key) {
    return (dispatch) => {
        return database.collection("PrintConfigs").doc(key).get().then((doc) => {
            console.log(doc.data())
            return doc.data()
        });
    }
}

export function apiGetPrintConfigByID(key) {
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getPrintConfigByID');
        return addMessage({ key: key }).then(function (data) {
        //   console.log('apiGetPrintConfigByID', data.data)
          let printConfig = {...data.data}
          return printConfig
        });
    }
}

export function pushPrintConfigToRedux(printConfig) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PUSH_PRINT_CONFIG_TO_REDUX, printConfig })
    }
}
