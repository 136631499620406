import React, { Component } from 'react';

class TimePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {HH:'',
                    MM:'',
                    AMPM:''}
        this.onHHChange= this.onHHChange.bind(this)
        this.onMMChange = this.onMMChange.bind(this)
        this.onAMPMChange = this.onAMPMChange.bind(this)   
    }

    onHHChange(HH)
    {
        if(HH>12)
        {
            return
        }
        this.setState({HH:HH})
        let time = {HH:HH, MM:this.props.time.MM, AMPM:this.props.time.AMPM}
        if(this.props.onChange){
            this.props.onChange(time);
        }
        
    }

    onMMChange(MM)
    {
        if(MM>60)
        {
            return
        }
        this.setState({MM:MM})
        let time = {HH:this.props.time.HH, MM:MM, AMPM:this.props.time.AMPM}
        if(this.props.onChange){
            this.props.onChange(time)

        }
    }

    onAMPMChange(AMPM)
    {
        this.setState({AMPM:AMPM})
        let time = {HH:this.props.time.HH, MM:this.props.time.MM, AMPM:AMPM}
        if(this.props.onChange){
            this.props.onChange(time)
        
        }
    }

    render(){
        return <div className="row">
            <div className="col-md-4 col-xs-4" style={{paddingRight:"0px"}}>
                <input type="number"
                 onChange={(evt) => this.onHHChange(evt.target.value)}
                 value={this.props.time.HH} 
                className="form-control"
                placeholder="HH"
                ></input>
            </div>
            <div className="col-md-4 col-xs-4" style={{padding:"0px"}}>
                <input type="number" 
                className="form-control" 
                onChange={(evt) => this.onMMChange(evt.target.value)}
                value={this.props.time.MM} 
                placeholder="MM"
                >
                </input>
            </div>
            <div style={{paddingLeft:"0px"}} className="col-md-4 col-xs-4" >
                <select 
                className="form-control" 
                onChange={(evt) => this.onAMPMChange(evt.target.value)}
                value={this.props.time.AMPM}    
                >
                    <option ></option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
            </select></div>
        </div>

    } 
}

export default TimePicker
