import React, { Component } from "react";
import "./Tooltip.css";

class Tooltip extends Component {
    constructor(props){
        super(props);
        this.state={
            active:false,
            setActive:false,
        }
        this.showTip= this.showTip.bind(this);
        this.hideTip = this.hideTip.bind(this);   
    }

    showTip() {
        let timeout = setTimeout(() => {
          this.setState({active:true});
        }, this.props.delay || 400);
        this.setState({timeout:timeout})
      };
    
      hideTip (){
        clearInterval(this.state.timeout);
        this.setState({active:false});
      };

      render(){
        return (
            <div
              className="Tooltip-Wrapper"
              // When to show the tooltip
              onMouseEnter={this.showTip}
              onMouseLeave={this.hideTip}
            >
              {/* Wrapping */}
              {this.props.children}
              {this.state.active && (
                <div className={`Tooltip-Tip ${this.props.direction || "top"}`}>
                  {/* Content */}
                  {this.props.content}
                </div>
              )}
            </div>
          );
      }
}

// const Tooltip = (props) => {
//   let timeout;
//   const [active, setActive] = useState(false);

//   const showTip = () => {
//     timeout = setTimeout(() => {
//       setActive(true);
//     }, props.delay || 400);
//   };

//   const hideTip = () => {
//     clearInterval(timeout);
//     setActive(false);
//   };

//   return (
//     <div
//       className="Tooltip-Wrapper"
//       // When to show the tooltip
//       onMouseEnter={showTip}
//       onMouseLeave={hideTip}
//     >
//       {/* Wrapping */}
//       {props.children}
//       {active && (
//         <div className={`Tooltip-Tip ${props.direction || "top"}`}>
//           {/* Content */}
//           {props.content}
//         </div>
//       )}
//     </div>
//   );
// };

export default Tooltip;
