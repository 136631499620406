import React, { Component } from 'react'
import * as actions from '../../actionTypes'
import Input from '../2/Controls/Input';
import Form from '../2/Controls/Form';

class AddEditProductTax extends Component {
    constructor() {
        super()
        this.state = {
            saving: false,
            taxGroup: '',
            taxName: '',
            taxType: '',
            taxPercentage: 0,
            taxComponents: [],
            key: '',
            action: '',
            taxGroupError: false,
            taxNames: [{ value: 'CGST', label: 'CGST' },
            { value: 'SGST', label: 'SGST' },
            { value: 'IGST', label: 'IGST' },
            { value: 'CESS', label: 'CESS' }
            ]
        }
        this.onAddTax = this.onAddTax.bind(this)
        this.onRemoveTax = this.onRemoveTax.bind(this)
        this.onSaveTax = this.onSaveTax.bind(this)
        this.onTaxNameChange = this.onTaxNameChange.bind(this)
    }

    componentDidMount() {
        let taxGroup = ''
        let taxes = []
        let key = ''
        let action = actions.ADD_NEW_TAX;
        if (this.props.productTax) {
            taxGroup = this.props.productTax.TaxGroup;
            taxes = this.props.productTax.Taxes;
            key = this.props.productTax.key;
            action= actions.EDIT_PRODUCT_TAX;
        }
       this.setState({ action: action, taxGroup: taxGroup, taxComponents: taxes, key: key })
    }

    onRemoveTax(index) {
        let taxComponents = this.state.taxComponents
        taxComponents.splice(index, 1)
        this.setState({ taxComponents: taxComponents })
    }

    onAddTax() {

        if (this.state.taxName.trim() === "") { alert("Please provide tax name"); return }
        if (this.state.taxPercentage < 0) { alert("Tax percentage should be greater than 0."); return }

        let taxComponent = {
            TaxName: this.state.taxName,
            TaxType: this.state.taxType,
            TaxPercentage: this.state.taxPercentage
        }
        let taxComponents = this.state.taxComponents
        taxComponents.push(taxComponent)
        this.setState({ taxComponents: taxComponents })
    }

    onSaveTax() {
        this.setState({ saving: true })
        //console.log(this.state.taxComponents)
        if(this.state.taxGroup.trim()===""){
            this.setState({taxGroupError:"Please provide tax group name"});
            return;
        }
        if (this.state.taxComponents.length === 0) {
            alert('Please define tax components')
            return
        }
        let productTax = {
            TaxGroup: this.state.taxGroup,
            Taxes: this.state.taxComponents,
            RegistrationID: this.props.user.user.RegistrationID,
            IsDeleted: false
        }
        this.props.saveProductTaxes(productTax, this.state.key).then(
            (key) => {
                this.props.getToastr("Tax Saved Successfully")
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    this.props.onSave()
                }.bind(this), 2000)
            })
    }

    onTaxNameChange(evt) {
        this.setState({
            taxType: evt.target.value,
            taxName: evt.target.value !== "Other" ? evt.target.value : ""
        })
    }

    
    render() {
        return <Form
            title={this.state.key === "" ? "Add New Tax" : "Edit Tax"}
            onClick={(action)=>{
                if(action==="save"){
                    this.onSaveTax();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
            <Input
                type="text"
                label="Tax Group"
                placeholder="Enter tax group"
                value={this.state.taxGroup}
                onChange={(value) => this.setState({ taxGroup:value })} 
                error={this.state.taxGroupError}
            />
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th style={{ width: "45%" }}>Tax Name</th>
                        <th>Tax % </th>
                        <th style={{ width: "120px" }}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {this.state.taxType !== "Other" && <select className="form-control"
                                value={this.state.taxName}
                                onChange={this.onTaxNameChange}>
                                <option value="">Select Tax Name</option>
                                <option value="CGST">CGST</option>
                                <option value="SGST">SGST</option>
                                <option value="IGST">IGST</option>
                                <option value="CESS">CESS</option>
                                <option value="Other">Other</option>
                            </select>}
                            {this.state.taxType === "Other" && <div className="input-group">
                                <input type="text" className="form-control"
                                    onChange={(evt) => this.setState({ taxName: evt.target.value })}
                                    value={this.state.taxName} />
                                <div className="input-group-btn">
                                    <button type="button" className="btn btn-default btn-md"
                                        onClick={() => this.setState({ taxType: '', taxName: '' })}>
                                        <i className="fa fa-trash"></i> </button>
                                </div>
                            </div>
                            }
                        </td>
                        <td>
                            <input type="number" className="form-control"
                                placeholder="enter tax percentage"
                                onChange={(evt) => this.setState({ taxPercentage: evt.target.value })}
                                value={this.state.taxPercentage} />
                        </td>
                        <td>
                            <button type="button" className="btn btn-default btn-md" onClick={this.onAddTax}>
                                {/* <i className="glyphicon glyphicon-plus"></i> ADD TO LIST */}
                                ADD TO LIST
                                </button>
                        </td>
                    </tr>
                    {this.state.taxComponents.map((taxComponent, index) =>
                        <tr>
                            <td>{taxComponent.TaxName}</td>
                            <td>{taxComponent.TaxPercentage}</td>
                            <td><div className="input-group-btn">
                                <button type="button" className="btn btn-default btn-md"
                                    onClick={() => this.onRemoveTax(index)}>
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                            </td>
                        </tr>)}
                </tbody>
            </table>
        </Form>
    }
}

export default AddEditProductTax 