import firebase, {database} from '../firebase'

// export function saveDraftTransaction(transaction,transactionType, key){
//     return (dispatch, getState) => {
//         let draft = {}
//         draft.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
//         draft.ActionBy = getState().user.user.Name
//         draft.ActionByUID = getState().user.user.UID
//         draft.ActionByEmailID = getState().user.user.EmailID
//         draft.RegistrationID= getState().user.user.RegistrationID
//         draft.StoreID= getState().user.store.key
//         draft.TransactionType = transactionType //inventory, sales
//         draft.Transaction = transaction
//         if(key.trim()!== "")
//         {
//             return database.collection("Drafts").doc(key).update({
//                 ...draft
//               })
//         }
//         else
//         {
//             return database.collection("Drafts").add({
//                 ...draft
//               })
//               .then(function(docRef) {
//                   return docRef.id
//               })
//               .catch(function(error) {
//                   console.error("Error adding drafts: ", error);
//               })
//         }
        
//     }
// }

export function saveDraftTransaction(transaction,transactionType, key){
    return (dispatch, getState) => {
        let draft = {}
        draft.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        draft.ActionBy = getState().user.user.Name
        draft.ActionByUID = getState().user.user.UID
        draft.ActionByEmailID = getState().user.user.EmailID
        draft.RegistrationID= getState().user.user.RegistrationID
        draft.StoreID= getState().user.store.key
        draft.TransactionType = transactionType //inventory, sales
        draft.Transaction = transaction
        if(key.trim()!== "")
        {
            return database.collection("Drafts").doc(key).update({
                ...draft
              })
        }
        else
        {
             let newDraftDoc = database.collection("Drafts").doc();
             newDraftDoc.set(draft)
             return newDraftDoc.id

            // return database.collection("Drafts").add({
            //     ...draft
            //   })
            //   .then(function(docRef) {
            //       return docRef.id
            //   })
            //   .catch(function(error) {
            //       console.error("Error adding drafts: ", error);
            //   })
        }
        
    }
}

export function deleteDraftTransaction(key){
    return (dispatch, getState) => {
        return database.collection("Drafts").doc(key).delete()
          .then(function() {
              return true
          })
          .catch(function(error) {
              console.error("Error deleting drafts: ", error);
          })
    }
}

export function getDraftTransactions(transactionType){
    return (dispatch, getState) => {
    return database.collection("Drafts").where("RegistrationID","==",getState().user.user.RegistrationID).where("StoreID","==",getState().user.store.key).where("TransactionType","==", transactionType).get().then((querySnapshot) => {
        let drafts = []
        querySnapshot.forEach((doc) => {
            drafts.push({ key:doc.id,...doc.data()})
        })
         return drafts
     })
    }
}