import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import Loader from '../Loader'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import * as actionTypes from '../../actionTypes'



class BillWiseDetailList extends Component {
    constructor() {
        super()
        this.state = {
            billWiseDetail: [],
            cashRegisterList: [],
            cashRegister: {},
            stores: [],
            storeList: [],
            searchText: '',
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '',
            searchCashRegister: '',
            activePage: 1, 
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            loading: true,
            exportBillWiseDetail: [],
            exportCalled: false,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            selectAll: false,
            showSearchOptions: false,
            mobile: false,
            lineItemSelected: false
            , page: ''
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onConvertTransaction = this.onConvertTransaction.bind(this)
        this.checkIfrecordCanBeConverted = this.checkIfrecordCanBeConverted(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.checkIfAtLeastOneSelected = this.checkIfAtLeastOneSelected.bind(this)
    }

    // componentWillMount() {
    //     document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    // }

    async componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        let pageFilters = null;
        let page = ''

        let cashRegisters = await this.props.getCashRegisters(this.props.user.store.key)
        //.then((cashRegisters) => {
            // console.log("cashRegisters", cashRegisters);
            this.setState({ cashRegisterList: cashRegisters })
        //})
        if (this.props.location.state.type === "report") {
            page = 'Transaction Wise Detail Report'
            pageFilters = this.props.getPageFilters(page);
            this.setState({ title: "Transaction Wise Detail Report", page: page });
        }
        else if (this.props.location.state.type === "list") {
            page = 'Sales History'
            pageFilters = this.props.getPageFilters(page);
            this.setState({ title: "Sales History", page: page });
        }

        if (pageFilters === null) {
            this.loadBillWiseDetailReport(storeList,
                this.state.recordFrom,
                this.state.searchTransactionNo,
                this.state.searchTransactiontype,
                this.state.searchTransactionFromDate,
                this.state.searchTransactionToDate,
                this.state.searchCustomerDetail,
                this.state.searchCashRegister
            );

            let searchParameters = {
                storeList: storeList,
                recordFrom: this.state.recordFrom,
                searchTransactionNo: this.state.searchTransactionNo,
                searchTransactiontype: this.state.searchTransactiontype,
                searchTransactionFromDate: this.state.searchTransactionFromDate,
                searchTransactionToDate: this.state.searchTransactionToDate,
                searchCustomerDetail: this.state.searchCustomerDetail,
                searchCashRegister: this.state.searchCashRegister,
                type: this.props.location.state.type,
                activePage: this.state.activePage,
            }
            this.props.setPageFilters(page, searchParameters);
        }
        else {
            this.loadBillWiseDetailReport(pageFilters.storeList,
                pageFilters.recordFrom,
                pageFilters.searchTransactionNo,
                pageFilters.searchTransactiontype,
                pageFilters.searchTransactionFromDate,
                pageFilters.searchTransactionToDate,
                pageFilters.searchCustomerDetail,
                pageFilters.searchCashRegister
            );

            this.setState({
                storeList: pageFilters.storeList,
                recordFrom: pageFilters.recordFrom,
                searchTransactionNo: pageFilters.searchTransactionNo,
                searchTransactiontype: pageFilters.searchTransactiontype,
                searchTransactionFromDate: pageFilters.searchTransactionFromDate,
                searchTransactionToDate: pageFilters.searchTransactionToDate,
                searchCustomerDetail: pageFilters.searchCustomerDetail,
                searchCashRegister: pageFilters.searchCashRegister,
                type: pageFilters.type,
                activePage: pageFilters.activePage
            });
        }
    }

    loadBillWiseDetailReport(storeList, from, searchTransactionNo, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate,
        searchCustomerDetail, searchCashRegister) {
        this.setState({ loading: true })
        this.props.getBillWiseDetailReport(this.props.user.user.RegistrationID, storeList, from, this.state.itemsCountPerPage,
            searchTransactionNo, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate, searchCustomerDetail, searchCashRegister)
            .then((result) => {
                result.searchResult.map((l) => {
                    if (l.hasOwnProperty('ReceivedPaymentTransactions')) {
                        let receivedPayment = 0
                        for (let i = 0; i < l.ReceivedPaymentTransactions.length; i++) {
                            let r = l.ReceivedPaymentTransactions[i]
                            if (r.hasOwnProperty('IsConvert') && r.IsConvert) {
                                //ignore
                            }
                            else {
                                receivedPayment = receivedPayment + Number(r.ReceiveAmount)
                            }
                        }
                        if (receivedPayment > 0) {
                            l.BillPaidAmount = l.BillPaidAmount + receivedPayment
                            l.BalanceAmount = l.BalanceAmount - receivedPayment
                        }
                        // console.log('receivedPayment', receivedPayment)
                    }
                    l.Selected = false
                    // console.log(l)
                })
                // console.log('pawan',result.searchResult)
                this.setState({ billWiseDetail: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false, showSearchOptions: false })
                this.showPaginationStatus()
            })
    }

    checkIfAtLeastOneSelected() {
        let lineItemSelected = false
        if (this.state.billWiseDetail.filter(f => f.Selected).length > 0) {
            lineItemSelected = true
        }
        this.setState({ lineItemSelected: lineItemSelected })
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            storeList: storeList,
            storeID: this.props.user.store.key,
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: '', searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '', searchCashRegister: '',
            showSearchOptions: false
        })

        let searchParameters = {
            storeList: this.state.storeList,
            recordFrom: 0,
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '',
            searchCashRegister: '',
            type: this.props.location.state.type,
            activePage: this.state.activePage,
        }
        this.props.setPageFilters(this.state.page, searchParameters)

        this.loadBillWiseDetailReport(storeList, 0, "", "", new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "")
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getBillWiseDetailReport(this.props.user.user.RegistrationID, this.state.storeList, 0, 10000,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let invoices = []
                result.searchResult.map((data) => {

                    if (data.hasOwnProperty('ReceivedPaymentTransactions')) {
                        let receivedPayment = 0
                        for (let i = 0; i < data.ReceivedPaymentTransactions.length; i++) {
                            let r = data.ReceivedPaymentTransactions[i]
                            if (r.hasOwnProperty('IsConvert') && r.IsConvert) {
                                //ignore
                            }
                            else {
                                receivedPayment = receivedPayment + Number(r.ReceiveAmount)
                            }
                        }
                        if (receivedPayment > 0) {
                            data.BillPaidAmount = data.BillPaidAmount + receivedPayment
                            data.BalanceAmount = data.BalanceAmount - receivedPayment
                        }
                    }
                    let invoice = {
                        "Store Name ": data.Store.StoreName,
                        "Cash Register": data.CashRegister.CashRegisterName,
                        "Customer Code": !isNullOrUndefined(data.Customer) ? data.Customer.CustomerCode : "",
                        "Customer Name": !isNullOrUndefined(data.Customer) ? data.Customer.FirstName + '' + data.Customer.LastName : "",
                        "Customer Mobile No.": !isNullOrUndefined(data.Customer) ? data.Customer.PhoneNo : "",
                        "Transaction No": data.TransactionNo,
                        "Transaction Type": data.TransactionType.TransactionType,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Qty": data.BillQty,
                        "Sub Total": data.BillSubTotal,
                        "Total Tax": data.BillTaxAmount,
                        "Discount": Number(data.BillDiscount).toFixed(2),
                        "Total": Number(data.BillAmount).toFixed(2),
                        "Paid": data.BillPaidAmount,
                        "Balance": data.BalanceAmount,
                        // "Paid": data.BillPaidAmount + (data.hasOwnProperty('ReceivedPaymentAmount') ? data.ReceivedPaymentAmount: 0),
                        // "Balance": data.BalanceAmount - (data.hasOwnProperty('ReceivedPaymentAmount') ? data.ReceivedPaymentAmount: 0),
                        "Cancelled": "",
                        "SalesReturnTransactionNo": data.hasOwnProperty('SalesReturnTransactionNo') ? data.SalesReturnTransactionNo : ""
                    }
                    invoice[this.state.customField1DisplayName] = ""
                    invoice[this.state.customField2DisplayName] = ""
                    invoice[this.state.customField3DisplayName] = ""
                    invoice[this.state.customField4DisplayName] = ""
                    invoice[this.state.customField5DisplayName] = ""
                    invoice[this.state.customField6DisplayName] = ""

                    for (let index = 0; index < this.props.config.PaymentTypes.length; index++) {
                        if (this.props.config.PaymentTypes[index].ShowInSales)
                            invoice[this.props.config.PaymentTypes[index].PaymentType] = ""
                    }
                    if (data.CancellationReason) {
                        invoice.Cancelled = "Yes"
                    }
                    if (data.Customer) {
                        invoice[this.state.customField1DisplayName] = data.Customer.CustomField1
                        invoice[this.state.customField2DisplayName] = data.Customer.CustomField2
                        invoice[this.state.customField3DisplayName] = data.Customer.CustomField3
                        invoice[this.state.customField4DisplayName] = data.Customer.CustomField4
                        invoice[this.state.customField5DisplayName] = data.Customer.CustomField5
                        invoice[this.state.customField6DisplayName] = data.Customer.CustomField6
                        //addresss
                    }
                    data.Payments.map((payment) => {
                        invoice[payment.PaymentMode] = (payment.PaymentMode === "Cash" ? (payment.Amount - (data.BillChangeDue ? data.BillChangeDue : 0)) : (payment.Amount != undefined ? payment.Amount : 0))
                    }) 
                    invoices.push(invoice);
                })
                const csvData = constatnts.objToCSV(invoices);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "Invoices.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.setState({ recordFrom: 0, loading: true })
        this.loadBillWiseDetailReport(
            this.state.storeList,
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister)

        let searchParameters = {
            storeList: this.state.storeList,
            recordFrom: 0,
            searchTransactionNo: this.state.searchTransactionNo,
            searchTransactiontype: this.state.searchTransactiontype,
            searchTransactionFromDate: this.state.searchTransactionFromDate,
            searchTransactionToDate: this.state.searchTransactionToDate,
            searchCustomerDetail: this.state.searchCustomerDetail,
            searchCashRegister: this.state.searchCashRegister,
            type: this.props.location.state.type,
            activePage: this.state.activePage,
        }
        this.props.setPageFilters(this.state.page, searchParameters)
    }

    onStoreChange(e) {
        let storeList = []
        // console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        // console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadBillWiseDetailReport(
            this.state.storeList,
            recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister)

        let searchParameters = {
            storeList: this.state.storeList,
            recordFrom: recordFrom,
            searchTransactionNo: this.state.searchTransactionNo,
            searchTransactiontype: this.state.searchTransactiontype,
            searchTransactionFromDate: this.state.searchTransactionFromDate,
            searchTransactionToDate: this.state.searchTransactionToDate,
            searchCustomerDetail: this.state.searchCustomerDetail,
            searchCashRegister: this.state.searchCashRegister,
            type: this.props.location.state.type,
            activePage: pageNumber,
        }
        this.props.setPageFilters(this.state.page, searchParameters)
    }

    showPaginationStatus() {
        // console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    async onCopyTransaction() {

    }

    async onConvertTransaction(transactionType, copy = false) {
        // console.log('onConvertTransaction', transactionType)
        //get selected records
        let selectedRecords = []
        let selectedRecordsTemp = []
        let firstRecordTransactionType = null
        let firstRecordCustomer = null
        let firstRecord = false
        let firstRecordData = null
        let paymentReceivedForSelectedTransaction = false
        let receivedPaymentAmount = 0
        let convertedFromTransactionBalanceAmount = 0
        selectedRecordsTemp = this.state.billWiseDetail.filter((f) => f.Selected)
        if (selectedRecordsTemp.length === 0) {
            alert("Please select record(s).")
            return;
        }
        for (let k = 0; k < selectedRecordsTemp.length; k++) {//get data from firestore
            // console.log('selectedRecordsTemp[k]', selectedRecordsTemp[k])
            let record = await this.props.getSaleDetails(selectedRecordsTemp[k].key)
            // console.log('record',record)
            record.key = selectedRecordsTemp[k].key
            selectedRecords.push(record)
        }

        for (let i = 0; i < selectedRecords.length; i++) {
            let record = selectedRecords[i]
            // console.log('record',record)
            if (!firstRecord) {
                firstRecordData = selectedRecords[i]
                firstRecordTransactionType = record.TransactionType
                firstRecordCustomer = record.Customer
                firstRecord = true
                if (transactionType && transactionType.TransactionType === firstRecordTransactionType.TransactionType) {
                    alert('Transaction can not be converted to same type. Please select different transaction types.')
                    return
                }
            }
            else {

                if (record.TransactionType.TransactionType !== firstRecordTransactionType.TransactionType) {
                    alert('Selected records should be of same transaction type')
                    return
                }
                if (!firstRecordCustomer && record.Customer) {
                    alert('Selected records should have same customer')
                    return
                }
                if (firstRecordCustomer && !record.Customer) {
                    alert('Selected records should have same customer')
                    return
                }
                // if(!billWiseDetail.ChildTransactionNo && !billWiseDetail.SalesReturnTransactionNo && !billWiseDetail.CancellationReason )
                if (firstRecordCustomer && record.Customer) {
                    if (firstRecordCustomer.key !== record.Customer.key) {
                        alert('Selected records should have same customer')
                        return
                    }
                }
            }
            if (!copy && record.hasOwnProperty('ChildTransactionNo')) {
                if (record.ChildTransactionNo.trim() !== "") {
                    alert('#' + record.TransactionNo + ' is already converted')
                    return
                }
            }
            if (record.Payments && record.Payments.length > 0) {
                receivedPaymentAmount = record.BillPaidAmount
                convertedFromTransactionBalanceAmount = record.BalanceAmount
                // console.log('convertedFromTransactionBalanceAmount', convertedFromTransactionBalanceAmount)
                paymentReceivedForSelectedTransaction = true
            }
        }
        if (!copy && paymentReceivedForSelectedTransaction && selectedRecords.length > 1) {
            alert('Payment collected for one or more transaction. Please select only one transaction at a time to convert.')
            return
        }
        //all checksout well...so lets create a new sales that conatains all these records.
        let sale = {
            TransactionType: firstRecordTransactionType,
            Customer: firstRecordCustomer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: null,
            Store: this.props.user.store,
            LineItems: [],
            BillQty: 0,
            BillSubTotal: 0,
            BillDiscount: 0,
            BillTaxAmount: 0,
            BillAmount: 0,
            RoundOff: 0,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            Payments: null,
            ParentTransactionNo: "",
            ParentTransactionKey: "",
            Employee: null,
            Notes: "",
            TaxSummaryList: [],
            PlaceOfSupply: "",
            BillingAddress: null,
            DeliveryCustomer: null,
            DeliveryCustomerAddress: null,
            DeliveryDate: "",
            DeliveryTime: "",
            deliveryNotes: "",
            BackEndUpdate: false,
            ReceivedPaymentAmount: !copy ? receivedPaymentAmount : 0,
            ConvertedFromTransactionBalanceAmount: !copy ? convertedFromTransactionBalanceAmount : 0,
            IsConvert: !copy
            //  true
            // IsConvert:paymentReceivedForSelectedTransaction
        }
        if (paymentReceivedForSelectedTransaction && !copy) {//e.g sales order to sales invoice in which payment was received during sales order
            // sale.IsConvert = paymentReceivedForSelectedTransaction
            sale.ReceivedPaymentTransactions = []
            sale.ReceivedPaymentTransactions.push({
                ReceiveAmount: receivedPaymentAmount,
                //ReceivePaymentDate:firstRecord.TransactionDate,
                ReceivePaymentDate: null,
                ReceivePaymentKey: firstRecordData.key,
                ReceivePaymentNo: firstRecordData.TransactionNo,
                IsConvert: true
            })
        }
        for (let i = 0; i < selectedRecords.length; i++) {
            let record = selectedRecords[i]
            sale.CustomField1 = record.hasOwnProperty('CustomField1') ? record.CustomField1 : ''
            sale.CustomField2 = record.hasOwnProperty('CustomField2') ? record.CustomField2 : ''
            sale.CustomField3 = record.hasOwnProperty('CustomField3') ? record.CustomField3 : ''
            sale.CustomField4 = record.hasOwnProperty('CustomField4') ? record.CustomField4 : ''
            sale.CustomField5 = record.hasOwnProperty('CustomField5') ? record.CustomField5 : ''
            sale.CustomField6 = record.hasOwnProperty('CustomField6') ? record.CustomField6 : ''
            sale.CustomField7 = record.hasOwnProperty('CustomField7') ? record.CustomField7 : ''
            sale.CustomField8 = record.hasOwnProperty('CustomField8') ? record.CustomField8 : ''
            sale.CustomField9 = record.hasOwnProperty('CustomField9') ? record.CustomField9 : ''
            sale.CustomField10 = record.hasOwnProperty('CustomField10') ? record.CustomField10 : ''
            sale.CustomField11 = record.hasOwnProperty('CustomField11') ? record.CustomField11 : ''
            sale.CustomField12 = record.hasOwnProperty('CustomField12') ? record.CustomField12 : ''
            sale.CustomField13 = record.hasOwnProperty('CustomField13') ? record.CustomField13 : ''
            sale.CustomField14 = record.hasOwnProperty('CustomField14') ? record.CustomField14 : ''
            sale.CustomField15 = record.hasOwnProperty('CustomField15') ? record.CustomField15 : ''
            sale.CustomField16 = record.hasOwnProperty('CustomField16') ? record.CustomField16 : ''
            sale.CustomField17 = record.hasOwnProperty('CustomField17') ? record.CustomField17 : ''
            sale.CustomField18 = record.hasOwnProperty('CustomField18') ? record.CustomField18 : ''
            sale.CustomField19 = record.hasOwnProperty('CustomField19') ? record.CustomField19 : ''
            sale.CustomField20 = record.hasOwnProperty('CustomField20') ? record.CustomField20 : ''
            record.LineItems.map((l) => {
                if (!copy) {
                    l.ParentTransactionNo = record.TransactionNo
                    l.ParentTransactionKey = record.key
                    l.ParentTransactionType = record.TransactionType.TransactionType
                    l.Notes = record.TransactionNo
                }
                sale.LineItems.push(l)
            })
        }
        if (firstRecordTransactionType.hasOwnProperty('UpdateStockChecked')) {
            if (firstRecordTransactionType.UpdateStockChecked && !copy) {
                firstRecordTransactionType.UpdateStockChecked = false//donot update stock again
            }
        }
        // console.log('firstRecordTransactionType ', firstRecordTransactionType)
        // console.log('sale ', sale)
        this.props.history.push({
            pathname: '/home/sales/sales',
            state: {
                type: actionTypes.SALE_NEW,
                key: "",
                transactionType: (copy ? firstRecordTransactionType : transactionType),
                sale: sale
            }
        })
    }

    checkIfrecordCanBeConverted(billWiseDetail) {
        if (billWiseDetail.ChildTransactionNo) {
            if (billWiseDetail.ChildTransactionNo.trim() !== "") {
                return false
            }
        }
        if (billWiseDetail.SalesReturnTransactionNo) {
            if (billWiseDetail.SalesReturnTransactionNo.trim() !== "") {
                return false
            }
        }
        if (billWiseDetail.CancellationReason) {
            if (billWiseDetail.CancellationReason !== "") {
                return false
            }
        }
        return true
    }

    render() {
        const btnColStyle = { width: "20px" }
        const mobileFooter = {
            height: "46px",
            backgroundColor: "white",
            paddingTop: "0px",
            position: "fixed",
            left: "0",
            bottom: "0",
            borderTop: "solid 1px #ddd",
            width: "100%"
        }

        return <div className="content-wrapper" >
            {/* <section className={this.state.mobile ?"" :"content"} > */}
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row" >
                                <div className="col-md-9 col-xs-9">
                                    <font style={{ fontSize: "30px" }}>{this.state.title}</font>
                                </div>
                                {/* {this.state.title !== "Sales History" &&<div className="col-md-6 col-xs-3" style={{paddingTop:"4px"}}>
                                        
                                    </div>} */}
                                <div className="col-md-3 col-xs-3 pull-right hidden-xs" style={{ paddingTop: "4px" }}>
                                    {this.state.title !== "Sales History" && <button type="cancel" className="btn btn-default pull-right btn-flat"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                    ><i className="fa fa-arrow-left"></i> Back</button>}
                                    <button className={this.state.showSearchOptions ? "pull-right btn btn-primary btn-md btn-flat" : "pull-right btn btn-default btn-md btn-flat"}
                                        onClick={() => this.setState({ showSearchOptions: !this.state.showSearchOptions, mobile: false })}
                                    ><i className="fa fa-filter " /> Filter</button>
                                    <button
                                        className="pull-right btn btn-success btn-md btn-flat"
                                        style={{
                                            marginRight: "5px",
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                newSalesButtonSelected: !this
                                                    .state
                                                    .newSalesButtonSelected,
                                            })
                                        }
                                    >
                                        <i className="fa fa-plus" /> New
                                        </button>
                                    <ul
                                        className="dropdown-menu pull-right"
                                        style={{
                                            display: this.state
                                                .newSalesButtonSelected
                                                ? "block"
                                                : "none",
                                            marginRight: "5px",
                                        }}
                                    >
                                        {this.props.config.TransactionTypes.filter(
                                            (e) =>
                                                e.Module === "sales" &&
                                                !e.IsDeleted
                                        ).map((e, index) => (
                                            <li
                                                key={index}
                                                onClick={() =>
                                                    this.setState({
                                                        selectedSubMenu:
                                                            e.TransactionType,
                                                    })
                                                }
                                                className={
                                                    this.state
                                                        .selectedSubMenu ===
                                                        e.TransactionType
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <Link
                                                    to={{
                                                        pathname:
                                                            "/home/sales/sales",
                                                        state: {
                                                            transactionType: e,
                                                            type:
                                                                actionTypes.SALE_NEW,
                                                        },
                                                    }}
                                                >
                                                    <i className="fa fa-file"></i>
                                                    <span>
                                                        New{" "}
                                                        {e.TransactionType}
                                                    </span>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-3 col-xs-3 pull-right hidden-lg hidden-md" >
                                    <button className={this.state.showSearchOptions ? "pull-right btn btn-primary btn-lg btn-flat" : "pull-right btn btn-default btn-lg btn-flat"}
                                        onClick={() => this.setState({ showSearchOptions: !this.state.showSearchOptions, mobile: true })}
                                    ><i className="fa fa-filter " /></button>
                                </div>
                            </div>
                        </div>
                        <div className="box-body" style={{ padding: "0px" }}>
                            {this.state.showSearchOptions && <div style={{ width: "auto", overflow: this.state.mobile ? "auto" : "none", height: this.state.mobile ? "80vh" : "" }} className="scroll2">
                                <div className="row" style={{ padding: "0px 10px 0px 10px" }}>
                                    <div className="col-md-4 col-xs-12">
                                        <div className="form-group">
                                            <label>Store Name</label>
                                            <select className="form-control"
                                                value={this.state.storeID}
                                                onChange={this.onStoreChange}>
                                                <option value="">All Stores</option>
                                                {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                    <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <div className="form-group">
                                            <label>Transaction Type</label>
                                            <select type="text" className="form-control"

                                                onChange={(evt) => this.setState({ searchTransactiontype: evt.target.value })}
                                                value={this.state.searchTransactiontype}
                                                onKeyPress={this.handleKeyPress}>
                                                <option value="">All</option>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: "0px 10px 0px 10px" }}>
                                    <div className="col-md-2 col-xs-12">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-xs-12">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <div className="form-group">
                                            <label>Customer Name, Code, Phone Number, Email</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchCustomerDetail: evt.target.value })}
                                                value={this.state.searchCustomerDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12">
                                        <div className="form-group">
                                            <label>Cash Register</label>
                                            <select type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchCashRegister: evt.target.value })}
                                                value={this.state.searchCashRegister}
                                                onKeyPress={this.handleKeyPress}>
                                                <option value="">All</option>
                                                {this.state.cashRegisterList.map((cashRegister) =>
                                                    <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.mobile && <br></br>}
                                {this.state.mobile && <br></br>}
                            </div>}
                            {(!this.state.mobile && this.state.showSearchOptions) && <div className="row" style={{ paddingBottom: "10px", paddingRight: "10px" }} >
                                <div className="col-md-12  col-xs-12">
                                    <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                        style={{ marginLeft: "5px" }}
                                        disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        onClick={this.onSearchClick} onKeyPress={this.handleKeyPress}
                                    >Search</button>
                                    <button type="button" className="btn btn-default btn-flat btn-md pull-right"
                                        onClick={this.onClearSearch}
                                    >Clear</button>
                                    <button className="btn btn-default btn-flat btn-md pull-right"
                                        // style={{ marginRight: "5px" }}
                                        style={{ marginRight: "5px", display: (this.props.user.user.hasOwnProperty('RoleKey') && this.props.user.user.RoleKey !== "") ? this.props.getVisibility("Sales Invoice", "export") : 'inline' }}
                                        onClick={this.onExportClick}
                                    >Export <i className="fa fa-download" ></i></button>
                                </div>
                            </div>}
                            {this.state.lineItemSelected && <div className="row" style={{ padding: "10px 10px 10px 10px" }}>
                                <div className="col-md-12  col-xs-12">
                                    {this.state.title === "Sales History" && <React.Fragment>
                                        <button type="button" className="btn btn-default dropdown-toggle btn-flat  btn-md pull-left" data-toggle="dropdown">Convert
                                                <span className="fa fa-caret-down"></span></button>
                                        <ul className="dropdown-menu" style={{ right: "auto", marginLeft: "15px" }}>
                                            {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted && e.TransactionType.toLowerCase().trim() !== "receive payment").map((e) => <li><a onClick={() => this.onConvertTransaction(e, false)}>{e.TransactionType}</a></li>)}
                                        </ul>
                                        <button style={{ marginLeft: "10px" }} className="btn btn-default btn-flat btn-md pull-left" onClick={() => this.onConvertTransaction(null, true)}>Copy</button>
                                    </React.Fragment>}
                                </div>
                            </div>}
                            {this.state.loading && <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2"><Loader show={this.state.loading} /></div>}
                            {(!this.state.loading && (!this.state.mobile || (!this.state.showSearchOptions && this.state.mobile))) && <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            {this.state.title === "Sales History" &&
                                                <th>
                                                    <input type="checkbox"
                                                        checked={this.state.selectAll}
                                                        onChange={() => {
                                                            let billWiseDetail = this.state.billWiseDetail
                                                            billWiseDetail.map((b) => {
                                                                if (b.TransactionType.TransactionType.toLowerCase().trim() !== "receive payment"
                                                                    && !b.hasOwnProperty('CancellationReason')) {
                                                                    b.Selected = !this.state.selectAll
                                                                }
                                                            })

                                                            this.setState({ selectAll: !this.state.selectAll, billWiseDetail: billWiseDetail })
                                                            this.checkIfAtLeastOneSelected()
                                                        }}
                                                    />
                                                </th>}
                                            <th className="hidden-md hidden-lg">Transaction</th>
                                            <th className="hidden-xs">Transaction No</th>
                                            {/* <th className="hidden-xs"> Transaction Type</th> */}
                                            <th className="hidden-xs">Transaction Date</th>
                                            <th className="hidden-xs">Store Name</th>
                                            {/* <th className="hidden-xs">Cash Register</th> */}
                                            <th className="hidden-xs">Customer Detail</th>
                                            <th className="hidden-xs">Qty</th>
                                            <th className="hidden-xs">Sub Total</th>
                                            <th className="hidden-xs">Total Tax</th>
                                            <th className="hidden-xs">Discount</th>
                                            <th className="hidden-xs">Total</th>
                                            <th className="hidden-xs">Paid</th>
                                            <th className="hidden-xs">Balance</th>
                                            <th className="hidden-xs">Payment Type</th>
                                            {this.state.title === "Sales History" &&
                                                <th className="hidden-xs" style={btnColStyle} ></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.billWiseDetail.map((billWiseDetail, index) =>
                                            <tr>
                                                {this.state.title === "Sales History" &&
                                                    <td>
                                                        {/* {(this.checkIfrecordCanBeConverted(billWiseDetail)) &&  */}
                                                        {(billWiseDetail.TransactionType.TransactionType.toLowerCase().trim() !== "receive payment" && !billWiseDetail.hasOwnProperty('CancellationReason')) && <input type="checkbox"
                                                            checked={billWiseDetail.Selected}
                                                            // disabled={billWiseDetail.TransactionType.TransactionType.toLowerCase().trim()==="receive payment" ? true :false }
                                                            onChange={() => {
                                                                let billWiseDetail = this.state.billWiseDetail
                                                                billWiseDetail[index].Selected = !billWiseDetail[index].Selected
                                                                this.setState({ billWiseDetail: billWiseDetail })
                                                                this.checkIfAtLeastOneSelected()
                                                            }}
                                                        />}
                                                    </td>}
                                                <td className="hidden-md hidden-lg" onClick={() => {
                                                    let redirectTo = "/home/sales/viewsales/" + billWiseDetail.key
                                                    this.props.history.push({ pathname: redirectTo })
                                                }}>
                                                    <div className="pull-left"><div>{billWiseDetail.TransactionNo}</div>
                                                        <div>{billWiseDetail.TransactionType.TransactionType}</div>
                                                        <div>{(new Date(billWiseDetail.TransactionDate).toISOString().split('T')[0])}</div>
                                                        <div>{billWiseDetail.Store.StoreName}</div>
                                                        <div>{billWiseDetail.CancellationReason && <span className="badge bg-red">Cancelled </span>}</div>
                                                        <div>{billWiseDetail.ParentTransactionKey && <span className="badge bg-lg bg-green">{billWiseDetail.ParentTransactionNo} </span>}</div>
                                                        <div>{billWiseDetail.SalesReturnTransactionNo && <span className="badge bg-orange">{billWiseDetail.SalesReturnTransactionNo}</span>}</div>
                                                        <div>{billWiseDetail.ChildTransactionNo && <span className="badge bg-orange">{billWiseDetail.ChildTransactionNo}</span>}</div>
                                                        {billWiseDetail.DeliveryStatus && billWiseDetail.DeliveryStatus !=="" && <div className="row-sub-text pull-left wallet">{billWiseDetail.DeliveryStatus}</div>}

                                                        {billWiseDetail.Customer && <div>
                                                            <div style={{ fontWeight: "bold" }}>Customer:</div>
                                                            <div style={{ paddingLeft: "10px" }}>{billWiseDetail.Customer.FirstName + ' ' + billWiseDetail.Customer.LastName}</div>
                                                            <div style={{ paddingLeft: "10px" }}>{billWiseDetail.Customer.EmailID}</div>
                                                            <div style={{ paddingLeft: "10px" }}>{billWiseDetail.Customer.PhoneNo}</div>
                                                            {billWiseDetail.Customer.CustomField1 && billWiseDetail.Customer.CustomField1.trim() !== "" && <div>{this.state.customField1DisplayName}:{billWiseDetail.Customer.CustomField1}</div>}
                                                            {billWiseDetail.Customer.CustomField2 && billWiseDetail.Customer.CustomField2.trim() !== "" && <div>{this.state.customField2DisplayName}:{billWiseDetail.Customer.CustomField2}</div>}
                                                            {billWiseDetail.Customer.CustomField3 && billWiseDetail.Customer.CustomField3.trim() !== "" && <div>{this.state.customField3DisplayName}:{billWiseDetail.Customer.CustomField3}</div>}
                                                            {billWiseDetail.Customer.CustomField4 && billWiseDetail.Customer.CustomField4.trim() !== "" && <div>{this.state.customField4DisplayName}:{billWiseDetail.Customer.CustomField4}</div>}
                                                            {billWiseDetail.Customer.CustomField5 && billWiseDetail.Customer.CustomField5.trim() !== "" && <div>{this.state.customField5DisplayName}:{billWiseDetail.Customer.CustomField5}</div>}
                                                            {billWiseDetail.Customer.CustomField6 && billWiseDetail.Customer.CustomField6.trim() !== "" && <div>{this.state.customField6DisplayName}:{billWiseDetail.Customer.CustomField6}</div>}


                                                            {billWiseDetail.BillingAddress && (
                                                                <div>
                                                                    <div>
                                                                        <b>{
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .AddressType
                                                                        }</b>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .Address1
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .Address2
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .City
                                                                        }, {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .State
                                                                        }
                                                                    </div>
                                                                    {/* <div>
                                                                        
                                                                    </div> */}
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .Country
                                                                        }
                                                                        - {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .PostalCode
                                                                        }
                                                                    </div>
                                                                    {/* <div>
                                                                        
                                                                    </div> */}
                                                                    
                                                                </div>
                                                            )}
                                                        </div>}
                                                    </div>
                                                    <div className="pull-right">
                                                        <div>Qty: {billWiseDetail.BillQty}</div>
                                                        <div>Sub Total: {billWiseDetail.BillSubTotal}</div>
                                                        <div>Tax: {billWiseDetail.BillTaxAmount}</div>
                                                        <div>Dis: {Number(billWiseDetail.BillDiscount).toFixed(2)}</div>
                                                        <div>Amt:{Number(billWiseDetail.BillAmount).toFixed(2)}</div>
                                                        <div>Paid: {billWiseDetail.BillPaidAmount}</div>
                                                        <div>Balance: {billWiseDetail.BalanceAmount}</div>
                                                    </div>
                                                    {/* <div>{billWiseDetail.TransactionType.TransactionType}</div>
                                                            <div>
                                                                {billWiseDetail.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                            </div>
                                                            <div>
                                                                {billWiseDetail.ParentTransactionKey && <span className="badge bg-lg bg-green">{billWiseDetail.ParentTransactionNo} </span>}
                                                            </div>
                                                            <div>
                                                                {billWiseDetail.SalesReturnTransactionNo && <span className="badge bg-orange">Sales return done via {billWiseDetail.SalesReturnTransactionNo}</span>}
                                                            </div>
                                                            <div>
                                                                {billWiseDetail.ChildTransactionNo && <span className="badge bg-orange">{billWiseDetail.ChildTransactionNo}</span>}
                                                            </div>
                                                            <div>{(new Date(billWiseDetail.TransactionDate).toISOString().split('T')[0])}</div>
                                                            <div>{billWiseDetail.Store.StoreName}</div>
                                                            {billWiseDetail.Customer && <div>
                                                            <div style={{fontWeight:"bold"}}>Customer:</div>
                                                            <div style={{paddingLeft:"10px"}}>{billWiseDetail.Customer.FirstName + ' ' + billWiseDetail.Customer.LastName}</div>
                                                            <div style={{paddingLeft:"10px"}}>{billWiseDetail.Customer.EmailID}</div>
                                                            <div style={{paddingLeft:"10px"}}>{billWiseDetail.Customer.PhoneNo}</div>
                                                            {billWiseDetail.Customer.CustomField1.trim() !== "" && <div>{this.state.customField1DisplayName}:{billWiseDetail.Customer.CustomField1}</div>}
                                                            {billWiseDetail.Customer.CustomField2.trim() !== "" && <div>{this.state.customField2DisplayName}:{billWiseDetail.Customer.CustomField2}</div>}
                                                            {billWiseDetail.Customer.CustomField3.trim() !== "" && <div>{this.state.customField3DisplayName}:{billWiseDetail.Customer.CustomField3}</div>}
                                                            {billWiseDetail.Customer.CustomField4.trim() !== "" && <div>{this.state.customField4DisplayName}:{billWiseDetail.Customer.CustomField4}</div>}
                                                            {billWiseDetail.Customer.CustomField5.trim() !== "" && <div>{this.state.customField5DisplayName}:{billWiseDetail.Customer.CustomField5}</div>}
                                                            {billWiseDetail.Customer.CustomField6.trim() !== "" && <div>{this.state.customField6DisplayName}:{billWiseDetail.Customer.CustomField6}</div>}
                                                        </div>}
                                                        <div>Qty: {billWiseDetail.BillQty}</div>
                                                        <div>Tax: {billWiseDetail.BillTaxAmount}</div>
                                                        <div>Dis: {Number(billWiseDetail.BillDiscount).toFixed(2)}</div>
                                                        <div>Amt:{Number(billWiseDetail.BillAmount).toFixed(2)}</div>
                                                        <div>Paid: {billWiseDetail.BillPaidAmount}</div>
                                                        <div>Balance: {billWiseDetail.BalanceAmount }</div> */}
                                                </td>
                                                <td className="hidden-xs">{billWiseDetail.TransactionNo}
                                                {/*</td>
                                                 <td className="hidden-xs"> */}
                                                    <div>{billWiseDetail.TransactionType.TransactionType}</div>
                                                    {billWiseDetail.DeliveryStatus && billWiseDetail.DeliveryStatus !=="" && <div className="row-sub-text pull-left wallet">{billWiseDetail.DeliveryStatus}</div>}

                                                    <div>
                                                        {billWiseDetail.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                    </div>
                                                    <div>
                                                        {billWiseDetail.ParentTransactionKey && <span className="badge bg-lg bg-green">{billWiseDetail.ParentTransactionNo} </span>}
                                                    </div>
                                                    <div>
                                                        {billWiseDetail.SalesReturnTransactionNo && <span className="badge bg-orange">Sales return done via {billWiseDetail.SalesReturnTransactionNo}</span>}
                                                    </div>
                                                    <div>
                                                        {billWiseDetail.ChildTransactionNo && <span className="badge bg-orange">{billWiseDetail.ChildTransactionNo}</span>}
                                                    </div>
                                                </td>
                                                <td className="hidden-xs">{(new Date(billWiseDetail.TransactionDate).toISOString().split('T')[0])}</td>
                                                <td className="hidden-xs">
                                                    {billWiseDetail.Store.StoreName}
                                                    <div><i>{billWiseDetail.CashRegister.CashRegisterCode} {billWiseDetail.CashRegister.CashRegisterName}</i></div>
                                                </td>
                                                {/* <td className="hidden-xs">
                                                    <div>{billWiseDetail.CashRegister.CashRegisterCode}</div>
                                                    <div>{billWiseDetail.CashRegister.CashRegisterName}</div>
                                                    <div>{billWiseDetail.CashRegister.StoreCode}</div>
                                                    <div>{billWiseDetail.CashRegister.StoreName}</div>
                                                </td> */}
                                                <td className="hidden-xs">
                                                    {/* <div>{billWiseDetail.Customer.CustomerCode}</div> */}
                                                    {billWiseDetail.Customer && <div>
                                                        <div>{billWiseDetail.Customer.FirstName + ' ' + billWiseDetail.Customer.LastName}</div>
                                                        <div>{billWiseDetail.Customer.EmailID}</div>
                                                        <div>{billWiseDetail.Customer.PhoneNo}</div>
                                                        {billWiseDetail.Customer.CustomField1 && billWiseDetail.Customer.CustomField1.trim() !== "" && <div>{this.state.customField1DisplayName}:{billWiseDetail.Customer.CustomField1}</div>}
                                                        {billWiseDetail.Customer.CustomField2 && billWiseDetail.Customer.CustomField2.trim() !== "" && <div>{this.state.customField2DisplayName}:{billWiseDetail.Customer.CustomField2}</div>}
                                                        {billWiseDetail.Customer.CustomField3 && billWiseDetail.Customer.CustomField3.trim() !== "" && <div>{this.state.customField3DisplayName}:{billWiseDetail.Customer.CustomField3}</div>}
                                                        {billWiseDetail.Customer.CustomField4 && billWiseDetail.Customer.CustomField4.trim() !== "" && <div>{this.state.customField4DisplayName}:{billWiseDetail.Customer.CustomField4}</div>}
                                                        {billWiseDetail.Customer.CustomField5 && billWiseDetail.Customer.CustomField5.trim() !== "" && <div>{this.state.customField5DisplayName}:{billWiseDetail.Customer.CustomField5}</div>}
                                                        {billWiseDetail.Customer.CustomField6 && billWiseDetail.Customer.CustomField6.trim() !== "" && <div>{this.state.customField6DisplayName}:{billWiseDetail.Customer.CustomField6}</div>}
                                                        {billWiseDetail.BillingAddress && (
                                                                <div>
                                                                    <div>
                                                                        <b>{
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .AddressType
                                                                        }</b>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .Address1
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .Address2
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .City
                                                                        }, {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .State
                                                                        }
                                                                    </div>
                                                                    {/* <div>
                                                                        
                                                                    </div> */}
                                                                    <div>
                                                                        {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .Country
                                                                        }
                                                                        - {
                                                                            billWiseDetail
                                                                                .BillingAddress
                                                                                .PostalCode
                                                                        }
                                                                    </div>
                                                                    {/* <div>
                                                                        
                                                                    </div> */}
                                                                    
                                                                </div>
                                                            )}
                                                    </div>}
                                                </td>
                                                <td className="hidden-xs">{billWiseDetail.BillQty}</td>
                                                <td className="hidden-xs">{billWiseDetail.BillSubTotal}</td>
                                                <td className="hidden-xs">{billWiseDetail.BillTaxAmount}</td>
                                                <td className="hidden-xs">{Number(billWiseDetail.BillDiscount).toFixed(2)}</td>
                                                <td className="hidden-xs">{Number(billWiseDetail.BillAmount).toFixed(2)}</td>
                                                <td className="hidden-xs">{billWiseDetail.BillPaidAmount}</td>
                                                {/* + (billWiseDetail.hasOwnProperty('ReceivedPaymentAmount')? (!billWiseDetail.hasOwnProperty('IsConvert')) ?  billWiseDetail.ReceivedPaymentAmount: 0:0) }</td> */}
                                                <td className="hidden-xs">{billWiseDetail.BalanceAmount}</td>
                                                {/* - (billWiseDetail.hasOwnProperty('ReceivedPaymentAmount') ? (!billWiseDetail.hasOwnProperty('IsConvert')) ?  billWiseDetail.ReceivedPaymentAmount: 0:0) }</td> */}
                                                <td className="hidden-xs">
                                                    {billWiseDetail.Payments.map((payment) =>
                                                        <div>{payment.PaymentMode + ' ' + ':' + ' ' + (payment.PaymentMode === "Cash" ? (payment.Amount - billWiseDetail.BillChangeDue) : payment.Amount)}</div>
                                                    )}
                                                </td>
                                                {this.state.title === "Sales History" &&
                                                    <td className="hidden-xs">
                                                        <div>
                                                            <Link to={`/home/sales/viewsales/${billWiseDetail.key}`}
                                                                type="button" className="btn btn-md btn-flat btn-default "><i className="fa fa-edit"></i></Link>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                            <div style={mobileFooter}>
                                {(this.state.totalItemsCount > 0) && <div className="pull-left hidden-xs" style={{ paddingTop: "10px", marginLeft: "60px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                {(this.state.mobile && this.state.showSearchOptions) && <React.Fragment>
                                    <button type="button"
                                        onClick={this.onExportClick}
                                        className="btn btn-default btn-flat btn-lg"
                                        style={{ backgroundColor: "white", width: "33%" }}>
                                        Export
                                            </button>
                                    <button type="button"
                                        onClick={this.onClearSearch}
                                        className="btn btn-default btn-flat btn-lg"
                                        style={{ backgroundColor: "white", width: "33%" }}>
                                        Clear
                                            </button>
                                    <button type="button" className="btn btn-success btn-flat btn-lg"
                                        onClick={this.onSearchClick}
                                        style={{ width: "33%" }}>
                                        Search
                                            </button>
                                </React.Fragment>}
                                {((this.state.mobile && !this.state.showSearchOptions) || !this.state.mobile) && <div className="pull-right"
                                    style={{ marginTop: "-14px", paddingRight: "10px" }}
                                >
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </section> */}
        </div>
    }
}
export default withRouter(BillWiseDetailList)