import React, {Component} from 'react';
import './style.css';

class Confirmation extends Component
{


    constructor () {
        super()
        this.onButtonClicked = this.onButtonClicked.bind(this)
    }

    onButtonClicked(action){
        this.props.onClick(action)
    }

    // componentWillReceiveProps(nextProps) {
    //     // console.log('nextProps',nextProps);
    //   if (nextProps.newProduct !== this.props.newProduct) {
    //     this.addProduct(nextProps.newProduct);
    //   }
  
    //   if (nextProps.productToRemove !== this.props.productToRemove) {
    //     this.removeProduct(nextProps.productToRemove);
    //   }
  
    //   if (nextProps.productToChange !== this.props.productToChange) {
    //     this.changeProductQuantity(nextProps.productToChange);
    //   }
    // }

    render()
    {
        return <React.Fragment>
            
            {this.props.show && <React.Fragment>
                <div className="confirmation-overlay" onClick={()=>this.onButtonClicked(false)}></div>
                <div className="confiramtion">
                    <div className="pull-right confirmation-close" onClick={()=>this.onButtonClicked(false)}>
                        <svg viewBox="0 0 18 18" width="18px" height="18px" xmlns="http://www.w3.org/2000/svg"><path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z"></path></svg>
                    </div>
                    <div className="confirmation-heading">{this.props.title? this.props.title : "Delete"}</div>
                    <div className="confirmation-text">{this.props.text ? this.props.text : "Are you sure? This action cannot be undone." }</div>
                    {/* {this.props.children} */}

                    <div className="confirmation-button-section">
                        <button className="confirmation-button1"  onClick={()=>this.onButtonClicked(true)}>{this.props.buttonText ? this.props.buttonText : "Delete"}</button>
                    </div>
                </div>
            </React.Fragment>}
       </React.Fragment>
    }
}

export default Confirmation