import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function saveOmniPaymentGatewaySettings(pgSettings){
    return (dispatch, getState) => {
        pgSettings.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        pgSettings.ActionBy = getState().user.user.Name
        pgSettings.ActionByUID = getState().user.user.UID
        pgSettings.ActionByEmailID = getState().user.user.EmailID
        pgSettings.RegistrationID = getState().user.user.RegistrationID
        pgSettings.IsDeleted = false
        pgSettings.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("OmniPGSettings").doc(pgSettings.RegistrationID).set({
                ...pgSettings
            }).then(()=>{
                return getState().user.user.RegistrationID;
            }).catch(function(error) {
                console.error("Error updating omni pg settings: ", error);
            })
    }
}

export function getOmniPaymentGatewaySettings(){
    return (dispatch, getState) => {
        return database.collection("OmniPGSettings").doc(getState().user.user.RegistrationID).get().then((doc) => {
            if (doc.exists) {
                // console.log('doc.data();',doc.data())
                return doc.data();
            } else {
                return null;
            }
        })
    }
}

export function saveOmniSettings(omniSettings){
    return (dispatch, getState) => {
        omniSettings.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        omniSettings.ActionBy = getState().user.user.Name
        omniSettings.ActionByUID = getState().user.user.UID
        omniSettings.ActionByEmailID = getState().user.user.EmailID
        omniSettings.RegistrationID = getState().user.user.RegistrationID
        omniSettings.IsDeleted = false
        omniSettings.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("OmniSettings").doc(getState().user.user.RegistrationID).set({
                ...omniSettings
            }).then(()=>{
                return getState().user.user.RegistrationID;
            }).catch(function(error) {
                console.error("Error updating omni settings: ", error);
            })
    }
}

export function getOmniSettings(){
    return (dispatch, getState) => {
        return database.collection("OmniSettings").doc(getState().user.user.RegistrationID).get().then((doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                return null;
            }
        })
    }
}

export function getOmniShippingOptions(registrationID)
{
    return (dispatch, getState) => {
        let q = database.collection("OmniShippingOptions").where("RegistrationID", "==", registrationID).orderBy("ActionOn","desc");
        
        return q.get().then((querySnapshot) => {
            const shippingOptions = []
            querySnapshot.forEach((doc) => {
                shippingOptions.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
            return shippingOptions
        }).catch((error) => {console.log(error)})
    }
}

export function getOmniPages(registrationID)
{
    return (dispatch, getState) => {
        let q = database.collection("OmniPages").where("RegistrationID", "==", registrationID).orderBy("ActionOn","desc");
        
        return q.get().then((querySnapshot) => {
            const pages = []
            querySnapshot.forEach((doc) => {
                pages.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
            return pages
        }).catch((error) => {console.log(error)})
    }
}

export function saveOmniPage(page, key){
    return (dispatch, getState) => {
        page.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        page.ActionBy = getState().user.user.Name
        page.ActionByUID = getState().user.user.UID
        page.ActionByEmailID = getState().user.user.EmailID
        page.RegistrationID = getState().user.user.RegistrationID
        page.IsDeleted = false
        if(key !== "")
        {
            page.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("OmniPages").doc(key).update({
                ...page
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating omni page: ", error);
              })
        }
        else
        {
            page.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("OmniPages").add({
                ...page
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding omni page: ", error);
              })
        }
    }
}

export function deleteOmniPage(key)
{
    return (dispatch, getState) => {
        return database.collection("OmniPages").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted : true
        }).then(()=> {return true})
    }
}

export function savePincodes(pincodes){
    return (dispatch, getState) => {
        return database.collection("Pincodes").doc(getState().user.user.RegistrationID).set({
            Pincodes:pincodes
        })
    }
}

export function getPincodes(){
    return (dispatch, getState) => {
    return database.collection("Pincodes").doc(getState().user.user.RegistrationID).get().then((doc) => {
        if (doc.exists) {
           return doc.data().Pincodes;
        } else {
            return [];
            console.log("No spincodes found!");
        }
    })
    }
}
