import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import Pagination from "react-js-pagination";

class BatchExpiryReport extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      stores: [],
      storeLocations: [],
      storeList: [],
      showCustomFields: true,
      searchBatchNo: "",
      searchExpiryFromDate: "",
      searchExpiryToDate: "",
      searchCategory: "",
      searchSubCategory: "",
      searchProductSKU: "",
      searchProductName: "",
      searchBrand: "",
      searchBarCode: "",
      searchProductCustomField1: "",
      searchProductCustomField2: "",
      searchProductCustomField3: "",
      searchProductCustomField4: "",
      searchProductCustomField5: "",
      searchProductCustomField6: "",

      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      paginationStatus: "No records to display",
      loading: true,
      productCustomField1DisplayName: "ProductCustomField1",
      productCustomField2DisplayName: "ProductCustomField2",
      productCustomField3DisplayName: "ProductCustomField3",
      productCustomField4DisplayName: "ProductCustomField4",
      productCustomField5DisplayName: "ProductCustomField5",
      productCustomField6DisplayName: "ProductCustomField6",
    };
    this.loadProductsReportData = this.loadProductsReportData.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
  }

  async componentDidMount() {
    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    let storeLocationsTmp = [];
    let storeLocations = [];

    storeLocationsTmp = await this.props.getStoreLocations(
      this.props.user.store.key
    );
    storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);

    this.setState({ storeLocations: storeLocations });

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Product") {
        let productCustomField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "ProductCustomField1"
            : customField.CustomField1Name.trim();
        let productCustomField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "ProductCustomField2"
            : customField.CustomField2Name.trim();
        let productCustomField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "ProductCustomField3"
            : customField.CustomField3Name.trim();
        let productCustomField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "ProductCustomField4"
            : customField.CustomField4Name.trim();
        let productCustomField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "ProductCustomField5"
            : customField.CustomField5Name.trim();
        let productCustomField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "ProductCustomField6"
            : customField.CustomField6Name.trim();
        this.setState({
          productCustomField1DisplayName: productCustomField1DisplayName,
          productCustomField2DisplayName: productCustomField2DisplayName,
          productCustomField3DisplayName: productCustomField3DisplayName,
          productCustomField4DisplayName: productCustomField4DisplayName,
          productCustomField5DisplayName: productCustomField5DisplayName,
          productCustomField6DisplayName: productCustomField6DisplayName,
        });
      }
    });

    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

    this.loadProductsReportData(
      storeList,
      0,
      this.state.searchBatchNo,
      this.state.searchExpiryFromDate,
      this.state.searchExpiryToDate,
      this.state.searchBrand,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBarCode
    );
  }

  async loadProductsReportData(
    storeList,
    from,
    searchBatchNo,
    searchExpiryFromDate,
    searchExpiryToDate,
    searchBrand,
    searchCategory,
    searchSubCategory,
    searchProductSKU,
    searchProductName,
    searchBarCode
  ) {
    this.setState({ loading: true });

    let result = await this.props.getBatchData(
      storeList,
      from,
      this.state.itemsCountPerPage,
      searchBatchNo,
      searchExpiryFromDate,
      searchExpiryToDate,
      searchBrand,
      searchCategory,
      searchSubCategory,
      searchProductSKU,
      searchProductName,
      searchBarCode
    );

    let products = [];
    for (let index = 0; index < result.searchResult.length; index++) {
      const data = result.searchResult[index];

      let product = {
        StoreName: data.StoreName ? data.StoreName : "",
        //"StoreLocation": data.Location,
        Brand: data.Brand,
        Category: data.Category,
        SubCategory: data.SubCategory,
        ProductSKU: data.ProductSKU,
        BarCode: data.BarCode,
        ProductName: data.ProductName,
      };

      if (this.state.showCustomFields) {
        product[this.state.productCustomField1DisplayName] =
          data.ProductCustomField1;
        product[this.state.productCustomField2DisplayName] =
          data.ProductCustomField2;
        product[this.state.productCustomField3DisplayName] =
          data.ProductCustomField3;
        product[this.state.productCustomField4DisplayName] =
          data.ProductCustomField4;
        product[this.state.productCustomField5DisplayName] =
          data.ProductCustomField5;
        product[this.state.productCustomField6DisplayName] =
          data.ProductCustomField6;
      }

      product["BatchNo"] = data.BatchNo;
      product["MRP"] = Number(data.BatchMRP).toFixed(2);
      product["SupplyPrice"] = Number(data.SupplyPrice).toFixed(2);
      product["RetailPrice"] = Number(data.BatchRetailPrice).toFixed(2);
      product["Qty"] = Number(data.Qty ? data.Qty : 0).toFixed(2);
      product["BatchMfgDate"] = data.BatchMfgDate;
      product["BatchExpDate"] = data.BatchExpDate;
      products.push(product);
    }

    this.setState({
      products: products,
      totalItemsCount: result.totalItemsCount,
      loading: false,
    });

    this.showPaginationStatus();
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      recordFrom: 0,
      searchBatchNo: "",
      searchExpiryFromDate: "",
      searchExpiryToDate: "",
      searchBarCode: "",
      searchProductCustomField1: "",
      searchProductCustomField2: "",
      searchProductCustomField3: "",
      searchProductCustomField4: "",
      searchProductCustomField5: "",
      searchProductCustomField6: "",
      searchCategory: "",
      searchSubCategory: "",
      searchBrand: "",
      searchProductName: "",
      searchProductSKU: "",
    });

    this.loadProductsReportData(
      this.state.storeList,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }

  async onExportClick() {
    this.setState({ loading: false });

    let result = await this.props.getBatchData(
      this.state.storeList,
      0,
      10000,
      this.state.searchBatchNo,
      this.state.searchExpiryFromDate,
      this.state.searchExpiryToDate,
      this.state.searchBrand,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBarCode
    );

    let products = [];
    for (let index = 0; index < result.searchResult.length; index++) {
      const data = result.searchResult[index];

      let product = {
        StoreName: data.StoreName ? data.StoreName : "",
        Brand: data.Brand,
        Category: data.Category,
        "Sub Category": data.SubCategory,
        "Product SKU": data.ProductSKU,
        BarCode: data.BarCode,
        "Product Name": data.ProductName,
      };

      if (this.state.showCustomFields) {
        product[this.state.productCustomField1DisplayName] =
          data.ProductCustomField1;
        product[this.state.productCustomField2DisplayName] =
          data.ProductCustomField2;
        product[this.state.productCustomField3DisplayName] =
          data.ProductCustomField3;
        product[this.state.productCustomField4DisplayName] =
          data.ProductCustomField4;
        product[this.state.productCustomField5DisplayName] =
          data.ProductCustomField5;
        product[this.state.productCustomField6DisplayName] =
          data.ProductCustomField6;
      }

      product["BatchNo"] = data.BatchNo;

      product["MRP"] = Number(data.BatchMRP).toFixed(2);
      product["Supply Price"] = Number(data.SupplyPrice).toFixed(2);
      product["Retail Price"] = Number(data.BatchRetailPrice).toFixed(2);
      product["Qty"] = Number(data.Qty ? data.Qty : 0).toFixed(2);
      product["Batch Mfg Date"] = data.BatchMfgDate;
      product["Batch Exp Date"] = data.BatchExpDate;
      products.push(product);
    }

    const csvData = constatnts.objToCSV(products);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "BatchExpiryReport.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ loading: true });
    this.loadProductsReportData(
      this.state.storeList,
      0,
      this.state.searchBatchNo,
      this.state.searchExpiryFromDate,
      this.state.searchExpiryToDate,
      this.state.searchBrand,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBarCode
    );
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  async onStoreChange(evt) {
    let storeID = evt.target.value;
    let storeList = [];
    let storeLocationsTmp = [];
    let storeLocations = [];

    if (storeID !== "") {
      storeList.push({ key: storeID });

      storeLocationsTmp = await this.props.getStoreLocations(storeID);
      storeLocations = storeLocationsTmp.filter((f) => !f.IsDeleted);
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    this.setState({
      storeList: storeList,
      storeID: storeID,
      storeLocations: storeLocations,
      searchStoreLocation: "",
    });
  }

  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });

    this.loadProductsReportData(
      this.state.storeList,
      recordFrom,
      this.state.searchBatchNo,
      this.state.searchExpiryFromDate,
      this.state.searchExpiryToDate,
      this.state.searchBrand,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchProductSKU,
      this.state.searchProductName,
      this.state.searchBarCode
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Batch Expiry Report
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-3">
                      <label>Store Name</label>
                      <select
                        className="form-control"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </select>
                    </div>
                    {/* <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Store Location</label>
                                            <select className="form-control"
                                                value={this.state.searchStoreLocation}
                                                onChange={(e) => {
                                                    console.log("searchStoreLocation", e.target.value)
                                                    this.setState({ searchStoreLocation: e.target.value })
                                                }}>
                                                <option value="">Select Store Location</option>
                                                {this.state.storeLocations.filter((e) => !e.IsDeleted).map((storeLocation) =>
                                                    <option key={storeLocation.key} value={storeLocation.key}>{storeLocation.LocationCode}  {storeLocation.LocationName}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div> */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Batch No</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchBatchNo}
                          onChange={(evt) =>
                            this.setState({ searchBatchNo: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Expiry From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchExpiryFromDate: evt.target.value,
                            })
                          }
                          value={this.state.searchExpiryFromDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Expiry To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchExpiryToDate: evt.target.value,
                            })
                          }
                          value={this.state.searchExpiryToDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Category</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchCategory}
                          onChange={(evt) =>
                            this.setState({ searchCategory: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Sub Category</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchSubCategory}
                          onChange={(evt) =>
                            this.setState({
                              searchSubCategory: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Brand</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchBrand}
                          onChange={(evt) =>
                            this.setState({ searchBrand: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Product Name</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchProductName}
                          onChange={(evt) =>
                            this.setState({
                              searchProductName: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3" style1={{ padding: "0px" }}>
                      <div className="form-group">
                        <label>SKU</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchProductSKU}
                          onChange={(evt) =>
                            this.setState({
                              searchProductSKU: evt.target.value,
                            })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>BarCode</label>
                        <input
                          type="text"
                          className="form-control input-md"
                          value={this.state.searchBarCode}
                          onChange={(evt) =>
                            this.setState({ searchBarCode: evt.target.value })
                          }
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Show Product Custom Fields</label>
                        <br />
                        <input
                          type="checkbox"
                          checked={this.state.showCustomFields}
                          onChange={() => {
                            this.setState({
                              showCustomFields: !this.state.showCustomFields,
                            });
                          }}
                        />
                        {/* &nbsp;&nbsp;<label><strong>Show Custom Fields</strong></label> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                                    
                                </div> */}
                  {this.state.showCustomFields && (
                    <React.Fragment>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField1DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField1}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField1: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField2DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField2}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField2: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField3DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField3}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField3: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField4DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField4}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField4: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField5DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField5}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField5: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>
                              {this.state.productCustomField6DisplayName}
                            </label>
                            <input
                              type="text"
                              className="form-control input-md"
                              value={this.state.searchProductCustomField6}
                              onChange={(evt) =>
                                this.setState({
                                  searchProductCustomField6: evt.target.value,
                                })
                              }
                              onKeyPress={this.handleKeyPress}
                            />
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        {" "}
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <br />
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Store Name</th>
                            {/* <th>Store Location</th> */}
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>SKU</th>
                            <th>Barcode</th>
                            <th>Product Name</th>
                            {this.state.showCustomFields && (
                              <React.Fragment>
                                <th>
                                  {this.state.productCustomField1DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField2DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField3DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField4DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField5DisplayName}
                                </th>
                                <th>
                                  {this.state.productCustomField6DisplayName}
                                </th>
                              </React.Fragment>
                            )}
                            <th>Batch No</th>
                            <th>Mfg. Date</th>
                            <th>Expiry Date</th>
                            <th>MRP</th>
                            <th>Supply Price</th>
                            <th>Retail Price</th>
                            <th>Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.products.map((item, index) => (
                            <tr>
                              <td>{item.StoreName}</td>
                              {/* <td>{item.StoreLocation}</td> */}
                              <td>{item.Brand}</td>
                              <td>{item.Category}</td>
                              <td>{item.SubCategory}</td>
                              <td>{item.ProductSKU}</td>
                              <td>{item.BarCode}</td>
                              <td>{item.ProductName}</td>
                              {this.state.showCustomFields && (
                                <React.Fragment>
                                  {" "}
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField1DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField2DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField3DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField4DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField5DisplayName
                                      ]
                                    }
                                  </td>
                                  <td>
                                    {
                                      item[
                                        this.state
                                          .productCustomField6DisplayName
                                      ]
                                    }
                                  </td>
                                </React.Fragment>
                              )}
                              <td>{item.BatchNo}</td>
                              <td>{item.BatchMfgDate}</td>
                              <td>{item.BatchExpDate}</td>
                              <td>{Number(item.MRP).toFixed(2)}</td>
                              <td>{Number(item.SupplyPrice).toFixed(2)}</td>
                              <td>{Number(item.RetailPrice).toFixed(2)}</td>
                              <td>{Number(item.Qty).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="pull-left" style={{ marginTop: "25px" }}>
                      {this.state.paginationStatus}
                    </div>
                  )}
                  <div className="pull-right">
                    <Pagination
                      activePage={this.state.activePage} //this.state.activePage
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalItemsCount}
                      pageRangeDisplayed={2}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(BatchExpiryReport);
