import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Loader from '../Loader'
import * as constants from '../../Constatnts'
import { isNullOrUndefined } from 'util';

class Appointments extends Component {
    constructor() {
        super();
        this.state = {
            appointments: [],
            loading: true,
            searchStartDate: new Date().toISOString().split('T')[0],
            searchEndDate: new Date().toISOString().split('T')[0],
            employeeID:'',
            employee: '',
            status:'',
            resources: []
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.loadAppointments = this.loadAppointments.bind(this)
        this.onExportClick= this.onExportClick.bind(this)
    }

    componentDidMount() {
        this.loadResources();
        this.loadAppointments(this.state.searchStartDate, this.state.searchEndDate, this.state.employeeID, this.state.status);
    }

    loadResources() {
        this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key, 0, 10000, 'active', '', '', '', '', '',
            '', '', '', '', '', '', 'yes')
            .then((result) => {

                let resources = []
                for (let index = 0; index < result.searchResult.length; index++) {
                    const resource = result.searchResult[index];
                    resources.push(
                        {
                            id: resource.key,
                            status: '',
                            name: (resource.EmployeeFirstName.trim() + ' ' + resource.EmployeeLastName.trim()).trim()
                        }
                    )
                }
                this.setState({ resources: resources });
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick();
        }
    }

    loadAppointments(startDate, endDate, employeeID, status) {
        this.setState({ loading: true });
        this.props.getAllAppointments(this.props.user.store.key, startDate, endDate, employeeID, status).then((appointmentList) => {
            this.setState({ appointments: appointmentList, loading: false });
        })
    }

    onClearSearch() {
        this.setState({
            searchStartDate: new Date().toISOString().split('T')[0],
            searchEndDate: new Date().toISOString().split('T')[0],
            employeeID: '',
            status:''
        })
        this.loadAppointments(new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], '', '');
    }

    onSearchClick() {
        this.loadAppointments(this.state.searchStartDate, this.state.searchEndDate, this.state.employeeID, this.state.status);
    }

    onExportClick(){
        this.setState({ loading: false });
        this.props.getAllAppointments(this.props.user.store.key, this.state.searchStartDate, this.state.searchEndDate, this.state.employeeID, this.state.status).then((appointmentList) => {
            
            if(isNullOrUndefined(appointmentList) || appointmentList.length === 0){
                this.props.getToastr("No Records Found");
            }
            let appointments = []
            appointmentList.map((data) => {
                let appointment = {
                    "Date" : (new Date(data.AppointmentDate.seconds * 1000)).toISOString().split('T')[0],
                    "Customer Name" :(data.Customer.FirstName.trim() + ' ' + data.Customer.LastName.trim()).trim(), 
                    "Customer EmailID" :data.Customer.EmailID, 
                    "Customer PhoneNo" :data.Customer.PhoneNo, 
                    "Service" : data.Service.label,
                    "Employee" : data.EmployeeName,
                    "Slot" : data.SlotStartDisplay + ' - ' + data.SlotEndDisplay,
                    "Status" : data.Status,
                }
                appointments.push(appointment)
            })
            const csvData = constants.objToCSV(appointments);
            this.setState({ loading: false, exportCalled: false })
            const csvBlob = new Blob([csvData], { type: "text/csv" });
            const url = window.URL.createObjectURL(csvBlob);
            const a = document.createElement("a");
            a.setAttribute("hidden", "");
            a.setAttribute("href", url);
            a.setAttribute("download", "Appointments.csv");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
    }

    render() {
        return <div>
            <h3 style={{ marginTop: "0px" }}>Appointments</h3>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Start Date</label>
                            <input type="date" className="form-control"
                                onChange={(evt) => this.setState({ searchStartDate: evt.target.value })}
                                value={this.state.searchStartDate}
                                onKeyPress={this.handleKeyPress} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>End Date</label>
                            <input type="date" className="form-control"
                                onChange={(evt) => this.setState({ searchEndDate: evt.target.value })}
                                value={this.state.searchEndDate}
                                onKeyPress={this.handleKeyPress} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Search Employee</label>
                            <select className="form-control"
                                value={this.state.employeeID}
                                onChange={(evt) => this.setState({ employeeID: evt.target.value, employee: evt.target.options[evt.target.selectedIndex].text })}
                            >
                                <option value="">Select Employee</option>
                                {this.state.resources.map(m => <option value={m.id}>{m.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Status</label>
                            <select className="form-control"
                                onChange={(evt) => this.setState({ status: evt.target.value })}
                                value={this.state.status}>
                                <option>Select</option>
                                <option>Guest Arrived</option>
                                <option>Service Done</option>
                                <option>Invoice Created</option>
                                <option>Cancelled</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                            style={{ marginTop: "15px", marginLeft: "5px" }}
                            disabled={this.state.searchAttendanceFromDate === '' || this.state.searchAttendanceToDate === ''}
                            onClick={this.onSearchClick}
                        >Search</button>
                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "15px" }}
                            onClick={this.onClearSearch} >Clear</button>
                        <button className="btn btn-default btn-flat btn-md pull-right"
                            style={{ marginTop: "15px", marginRight: "5px" }}
                            onClick={this.onExportClick}> Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                    </div>
                </div>
                {this.state.loading && <Loader show={this.state.loading} />}
                {!this.state.loading &&
                    <div className="scroll2" style={{ marginTop: "15px", height: "50vh", paddingBottom: "0px", overflowY: "scroll", overflowX: "auto" }}>
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Customer</th>
                                    <th>Service</th>
                                    <th>Employee</th>
                                    <th>Slot</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.appointments.map((appointment) =>
                                    <tr>
                                        <td>{(new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0]}</td>
                                        <td>{(appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim()}
                                            {appointment.Customer.EmailID !== "" && <div>{appointment.Customer.EmailID}</div>}
                                            {appointment.Customer.PhoneNo !== "" && <div>{appointment.Customer.PhoneNo}</div>}
                                        </td>
                                        <td>{appointment.Service.label}</td>
                                        <td>{appointment.EmployeeName}</td>
                                        <td>{appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</td>
                                        <td>{appointment.Status}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    }
}

export default withRouter(Appointments)