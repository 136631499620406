import React, { Component } from 'react';
import Icon from './Icon';
import Tooltip from './Tooltip';

class ImageButton extends Component {

   renderButton(){
       return <div className={"img-btn btn-"  + this.props.type} 
        onClick={()=>{if(this.props.hasOwnProperty('onClick')){this.props.onClick()}}}>
        <Icon type={this.props.icon ? this.props.icon : this.props.type}/>
    </div>
    
   }
    render(){
        return <React.Fragment>
            {this.props.label && <Tooltip content={this.props.label} direction="top">
                {this.renderButton()}
            </Tooltip>}

            {!this.props.label && this.renderButton()}
        </React.Fragment>
    }
}

export default ImageButton;
