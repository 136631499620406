import React, { Component } from 'react';
import Window from '../2/Controls/Window';
import Input from '../2/Controls/Input';

class CustomerAddress extends Component{

    constructor()
    {
        super()
        this.state = {
            addressType:'',
            address1:'',
            address2:'',
            city:'',
            state:'',
            postalCode:'',
            country:'',
            countryCode:'',
            newAddress:true,
            states: [],
            stateCode: '',
            countries:[]
        }
        this.onSaveAddress  = this.onSaveAddress.bind(this);
        // this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
    }

    // handleKeyPress(e){
    //     if (e.key === 'Enter') {
    //         this.onSaveAddress()
    //       }
    // }

    async componentDidMount(){
        let states = await this.props.getStateCodeMaster();
        let countries = await this.props.getAllCountries();
        this.setState({ states: states,countries:countries });
        let country = ""
        let countryCode =""
        if(this.props.selectedAddress)
        {
            country="India"
            countryCode="IN"
            if(this.props.selectedAddress.Country !=="")
            {
                let tmp = countries.filter(f=> f.name.toLowerCase().trim() ===  this.props.selectedAddress.Country.toLowerCase().trim())
                if(tmp.length> 0)
                {
                    country = tmp[0].name
                    countryCode = tmp[0].code
                }
            }
            this.setState({
                addressType:this.props.selectedAddress.AddressType,
                address1:this.props.selectedAddress.Address1,
                address2:this.props.selectedAddress.Address2,
                city:this.props.selectedAddress.City,
                state:this.props.selectedAddress.State,
                stateCode:this.props.selectedAddress.hasOwnProperty('StateCode') ? this.props.selectedAddress.StateCode : '',
                postalCode:this.props.selectedAddress.PostalCode,
                country:country,
                countryCode:countryCode,
                newAddress:false
            })
        }
        else{
            if(this.props.registration.registration.Country !=="")
            {
                let tmp = countries.filter(f=> f.name.toLowerCase().trim() ===  this.props.registration.registration.Country.toLowerCase().trim())
                if(tmp.length> 0)
                {
                    country = tmp[0].name
                    countryCode = tmp[0].code
                }
            }
            this.setState({addressType:"Home", country:country, countryCode:countryCode})
        }
    }

    onCountryChange(countryCode) {
        let country="";
        if(this.state.countries.filter(f=>f.code === countryCode).length> 0 ){
            country = this.state.countries.filter(f=>f.code === countryCode)[0].name;
        }
        this.setState({ countryCode: countryCode, country: country, countryError: '' })
      }

      onStateChange(stateCode){
        //   console.log('stateCode', stateCode);
        //   console.log('this.state.states', this.state.states)
        let state = "";
        if(this.state.states.filter(f=> f.code.toString() === stateCode.toString()).length> 0){
            state = this.state.states.filter(f=> f.code.toString() === stateCode.toString())[0].name;
        }    
        // console.log('state', state)       
        this.setState({ stateCode: stateCode, state: state })
      }

    onSaveAddress()
    {
        // this.state.address2.trim()==="" &&
        // this.state.city.trim()==="" && 
        // this.state.state.trim()==="" &&
        // this.state.postalCode.trim()==="" &&
        // this.state.country.trim()===""
        if(this.state.address1.trim()==="" )
        {
            this.setState({addressError:"Please provide address"})
            return
        }
        let address = {
        AddressType:this.state.addressType,
        Address1: this.state.address1,
        Address2:this.state.address2,
        City:this.state.city,
        State:this.state.state,
        StateCode: this.state.stateCode,
        PostalCode:this.state.postalCode,
        Country:this.state.country,
        CountryCode: this.state.countryCode}
        
        this.props.onAddressAdded(address, this.props.selectedAddressIndex)
        this.props.onClose()
    }

    render (){
        return <Window 
            header={this.props.selectedAddress ? "Edit Address" :  "Add New Address"}  
            width={"600px"}
            modal={false}
            onClose={this.props.onClose}
            onButtonClick={(buttonType)=>{
                if(buttonType.toLowerCase()==="save"){
                    this.onSaveAddress();
                }
                else
                {
                    this.props.onClose();
                }
            }}
            buttons={[
                {Type:"secondary", Text:"Cancel", Align:"left"},
                {Type:"primary", Text:"Save", Align:"right"},
            ]}>
               
                <Input
                    type="select"
                    label="Address Type"
                    value={this.state.addressType} 
                    onChange= {(value) => this.setState({addressType:value})}
                    onEnter={this.onSaveAddress}
                >
                    <option >Home</option>
                    <option >Work</option>
                    <option >Other</option>
                </Input>
                <Input
                    type="text"
                    label="Line Address 1"
                    onChange={(value)=> this.setState({address1:value, addressError:""})}
                    value={this.state.address1}
                    error = {this.state.addressError}
                    onEnter={this.onSaveAddress}
                />
                <Input
                    type="text"
                    label="Line Address 2"
                    onChange={(value)=> this.setState({address2:value})}
                    value={this.state.address2}
                    onEnter={this.onSaveAddress}
                />
                <Input
                    type="text"
                    label="City"
                    onChange={(value)=> this.setState({city:value})}
                    value={this.state.city}
                    onEnter={this.onSaveAddress}
                />           
                {(this.state.countryCode ==="" || this.state.countryCode==="IN") && <Input
                    type="select"
                    label="State"
                    value={this.state.stateCode}
                    onChange={(value) =>this.onStateChange(value)}
                    onEnter={this.onSaveAddress}
                >
                    <option value="">Select</option>
                    {this.state.states.map(m => <option value={m.code}>{m.name}</option>)}
                </Input>}
                {(this.state.countryCode!=="IN") && <Input 
                    type="text" 
                    onChange={(value)=> this.setState({stateCode:value, state:value})}
                    value={this.state.state}
                    onEnter={this.onSaveAddress}
                />}
                <Input
                    type="select"
                    label="Country"
                    value={this.state.countryCode} 
                    onChange= {(value) => this.onCountryChange(value)}
                    onEnter={this.onSaveAddress}
                >
                     {this.state.countries.map((b) =><option value={b.code}>{b.name}</option>)}
                </Input>   
                <Input
                    type="text"
                    label="Postal Code"
                    onChange={(value)=> this.setState({postalCode:value})}
                    value={this.state.postalCode}
                    onEnter={this.onSaveAddress}
                />
                   
                    {/* <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group" >
                                <label >Country</label>
                                <select className="form-control"
                                    value={this.state.countryCode}
                                    onChange={this.onCountryChange}>
                                    {this.state.countries.map((b) =>
                                    <option value={b.code}>{b.name}</option>
                                    )}
                                </select>
                             </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="form-group" >
                                <label >Postal Code</label>
                                <input type="text" className="form-control"  
                                onKeyPress={this.handleKeyPress}
                                onChange={(evt)=> this.setState({postalCode:evt.target.value, addressError:""})}
                                value={this.state.postalCode}
                                />
                            </div>
                        </div>
                    </div> */}
        </Window>}
}

export default CustomerAddress