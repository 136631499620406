import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'
import { isNullOrUndefined } from 'util';

// export function importCustomer(filePath, originalFileName){
//   return (dispatch, getState) =>{
      
//       return database.collection("CustomerImport").add({
//           Action:'NEW',
//           ActionOn:firebase.firestore.FieldValue.serverTimestamp(),
//           ActionBy:getState().user.user.Name,
//           ActionByEmailID:getState().user.user.EmailID,
//           ActionByUID:getState().user.user.UID,
//           FilePath:filePath,
//           RegistrationID:getState().user.user.RegistrationID,
//           OriginalFileName:originalFileName
//       })
//       .then(function(docRef) {
//           return docRef.id
//           console.log('customerimport',docRef.id)
//       })
//       .catch(function(error) {
//           console.error("Error adding customer import: ", error);
//       })
//   }
// }

export function getCustomerDetailsByStoreID(storeID) {

  return (dispatch) => {
    return database.collection("Customers").where("SaleToPurchaseStoreID", "==", storeID).where("IsDeleted", "==", false).limit(1).get().then((querySnapshot) => {
      let customer = null
      querySnapshot.forEach((doc) => {
        customer = doc.data()
      })
      return customer
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function addCustomer(customer){
    return (dispatch, getState) => {

      customer.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      customer.ActionBy = getState().user.user.Name
      customer.ActionByUID = getState().user.user.UID
      customer.ActionByEmailID = getState().user.user.EmailID
      customer.CreatedOn = firebase.firestore.FieldValue.serverTimestamp()
      customer.Action = constants.AUDIT_TRAIL_NEW
      // console.log('customer', customer)
      // return
        return database.collection('Customers').add({
          ...customer
        })
        .then(function(docRef) {
          // console.log('docRef',docRef)
            return docRef.id
        })
        .catch(function(error) {
            console.error('Error adding customer: ', error);
        })
    }
}
    
// export function addCustomer(customer){
//   return (dispatch, getState) => {
//     let newCustomerDoc = database.collection('Customers').doc()
//     customer.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
//     customer.ActionBy = getState().user.user.Name
//     customer.ActionByUID = getState().user.user.UID
//     customer.ActionByEmailID = getState().user.user.EmailID
//     customer.CreatedOn = firebase.firestore.FieldValue.serverTimestamp()
//     customer.Action = constants.AUDIT_TRAIL_NEW

//     newCustomerDoc.set(customer)
//     return newCustomerDoc.id;
//   }
// }

export function getCustomers(RegistrationID, StoreID, from, size, option, searchText, searchCity,
  searchState, searchPinCode, searchCountry, searchMembership, searchWalletFrom, searchWalletTo,
  customField1, customField2, customField3, customField4, customField5, customField6) {
  return (dispatch) => {
    let searchParameters = {
      "from": from,
      "size": size,
      "track_total_hits": true,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } }
            , {
              "match": {
                "StoreID": StoreID
              }
            }
          ],
          "must_not": [
          ],
          "should": [],
          "minimum_should_match": 1,
          "filter": {
            "bool": {
              "must": []
            }
          }
        }
      },
      "sort": [
        {
          "ActionOn": {
            "order": "desc"
          }
        }
      ]
    }
    if (option === "active") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
    }
    searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText + ".*" } })
    searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } })
    searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
    if (searchCity.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.City": "@" + searchCity.trim().toLowerCase() + ".*" } })
    }
    if (searchState.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.State": "@" + searchState.trim().toLowerCase() + ".*" } })
    }
    if (searchPinCode.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
    }
    if (searchCountry.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
    }
    if (searchMembership.trim() !== ""){
      searchParameters.query.bool.filter.bool.must.push({"regexp": {"Membership.Name" : "@" + searchMembership.trim().toLowerCase() + ".*"} })
    }    
    if (searchWalletFrom !== "") {
      searchParameters.query.bool.filter.bool.must.push({"range": {"WalletBalance": {"gte": searchWalletFrom} }})
    }
    if (searchWalletTo !== "") {
      searchParameters.query.bool.filter.bool.must.push({"range": { "WalletBalance": {"lte": searchWalletTo} }})
    }    
    if (customField1.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + customField1.trim().toLowerCase() + ".*" } })
    }
    if (customField2.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + customField2.trim().toLowerCase() + ".*" } })
    }
    if (customField3.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + customField3.trim().toLowerCase() + ".*" } })
    }
    if (customField4.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + customField4.trim().toLowerCase() + ".*" } })
    }
    if (customField5.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + customField5.trim().toLowerCase() + ".*" } })
    }
    if (customField6.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + customField6.trim().toLowerCase() + ".*" } })
    }
    // console.log('searchParameters',searchParameters)
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
    });
  }
}

// export function getCustomers(RegistrationID, StoreID, from, size, option, searchText, searchCity,
//   searchState, searchPinCode, searchCountry,
//   customField1, customField2, customField3, customField4, customField5, customField6) {
//   return (dispatch) => {
//     let searchParameters = {
//       "from": from,
//       "size": size,
//       "query": {
//         "bool": {
//           "must": [
//             { "match": { "RegistrationID": RegistrationID } }
//             , {
//               "match": {
//                 "StoreID": StoreID
//               }
//             }
//           ],
//           "must_not": [
//           ],
//           "should": [

//           ],
//           "minimum_should_match": 1,
//           "filter": {
//             "bool": {
//               "must": [

//               ]
//             }
//           }
//         }
//       },
//       "sort": [
//         {
//           "ActionOn": {
//             "order": "desc"
//           }
//         }
//       ]
//     }
//     if (option === "active") {
//       searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
//     }
//     if (option === "inactive") {
//       searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
//     }
//     searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText + ".*" } })
//     searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } })
//     searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
//     searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
//     if (searchCity.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.City": "@" + searchCity.trim().toLowerCase() + ".*" } })
//     }
//     if (searchState.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.State": "@" + searchState.trim().toLowerCase() + ".*" } })
//     }
//     if (searchPinCode.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
//     }
//     if (searchCountry.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
//     }
//     if (customField1.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + customField1.trim().toLowerCase() + ".*" } })
//     }
//     if (customField2.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + customField2.trim().toLowerCase() + ".*" } })
//     }
//     if (customField3.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + customField3.trim().toLowerCase() + ".*" } })
//     }
//     if (customField4.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + customField4.trim().toLowerCase() + ".*" } })
//     }
//     if (customField5.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + customField5.trim().toLowerCase() + ".*" } })
//     }
//     if (customField6.trim() !== "") {
//       searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + customField6.trim().toLowerCase() + ".*" } })
//     }
//     let addMessage = firebase.functions().httpsCallable('getCustomers');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const customers = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         customers.push({ key: data._id, ...data._source })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
//     });
//   }
// }

export function searchCustomer(RegistrationID, StoreID, searchText) {
  return (dispatch, getState) => {
    let searchParameters = {
      "from": 0,
      "size": 50,
      "track_total_hits": true,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } },
            { "match": { "IsDeleted": "false" } }
          ],
          "should": [
            { "match": { "CustomerDetails": { "query": searchText.toLowerCase(), "operator": "and" } } },
            { "regexp": { "CustomerDetails": "@" + searchText.toLowerCase() + ".*" } }
          ], "minimum_should_match": 1
        }
      }
    }
    if (getState().registration.registration.hasOwnProperty('GlobalCustomer')) {
      if (!getState().registration.registration.GlobalCustomer) {
        searchParameters.query.bool.must.push({ "match": { "StoreID": StoreID } })
      }
    }
    console.log("Search Customer", JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })  
      })
      console.log("Search Customer", customers)
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
    });
  }
}

export  function getCustomerDetails(key){
    return (dispatch) => {
    return database.collection('Customers').doc(key).get().then((doc) => {
        if (doc.exists) {
            // console.log('Customer found', doc.data());
           return doc.data()
        } else {
            console.log('No such customer!');
        }
    })
    }

}


export function updateCustomer(customer, key){
    return (dispatch, getState) => {
      customer.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      customer.ActionBy = getState().user.user.Name
      customer.ActionByUID = getState().user.user.UID
      customer.ActionByEmailID = getState().user.user.EmailID

      customer.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection('Customers').doc(key).update({
          ...customer
        })
    }
}


export function deleteCustomer(key){
    return (dispatch, getState) => {
        return database.collection('Customers').doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID:getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
        
    }
}


export function getCustomerWalletBalance(key) {
  return (dispatch) => {
    return database.collection('Customers').doc(key).get().then((doc) => {
      if (doc.exists) {
        //console.log('Customer found', doc.data());
        let walletBalance = 0
        if (doc.data().WalletBalance) { walletBalance = doc.data().WalletBalance }
        return walletBalance
      } else {
        console.log('No such customer!');
      }
    })
  }
}

export function getCustomerDashboardHeaders(customerID) {
  let searchParameters = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "CustomerID": customerID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          },
          {
            "match_phrase": {
              "TransactionType": "Receive Payment"
            }
          }
        ]
      }
    },
    "aggs": {
      "total_spent": {
        "sum": {
          "field": "Amount"
        }
      },
      "total_qty": {
        "sum": {
          "field": "Qty"
        }
      },
      "total_transactions": {
        "cardinality": {
          "field": "TransactionID"
        }
      }
    }
  };
  return (dispatch) => {
    //console.log("Get Customer Dashboard Header - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const header = {};
      let sanitizedMessage = result.data.text;
      // console.log("Customer Dashboard Header Data", JSON.stringify(sanitizedMessage));
      header.total_spent = Number(sanitizedMessage.aggregations.total_spent.value).toFixed(2);
      header.total_transactions = sanitizedMessage.aggregations.total_transactions.value;
      header.total_qty = Number(sanitizedMessage.aggregations.total_qty.value).toFixed(2);
      // console.log("Customer Dashboard Header Data", JSON.stringify(header));
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: header }
    });
  }
};

// export function getCustomerDashboardHeaders(customerID) {
//   let searchParameters = {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "match": {
//               "Customer.key.keyword": customerID
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "exists": {
//               "field": "CancellationReason"
//             }
//           }
//         ]
//       }
//     },
//     "aggs": {
//       "total_spent": {
//         "sum": {
//           "field": "BillAmount"
//         }
//       },
//       "total_qty": {
//         "sum": {
//           "field": "BillQty"
//         }
//       },
//       "total_transactions": {
//         "cardinality": {
//           "field": "_id"
//         }
//       }
//     }
//   };
//   return (dispatch) => {
//     // console.log("Get Customer Dashboard Header - Query", JSON.stringify(searchParameters));
//     let addMessage = firebase.functions().httpsCallable('getSales');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const header = {};
//       let sanitizedMessage = result.data.text;
//       // console.log("Customer Dashboard Header Data", JSON.stringify(sanitizedMessage));
//       header.total_spent = Number(sanitizedMessage.aggregations.total_spent.value).toFixed(2);
//       header.total_transactions = sanitizedMessage.aggregations.total_transactions.value;
//       header.total_qty = Number(sanitizedMessage.aggregations.total_qty.value).toFixed(2);
//       // console.log("Customer Dashboard Header Data", JSON.stringify(header));
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: header }
//     });
//   }
// };

export function getWalletLedgersByCustomerID(customerID) {
  return (dispatch) => {
    return database.collection("WalletLedgers").where("CustomerID", "==", customerID).limit(100).orderBy("TransactionDate","desc").get().then((querySnapshot) => {
      const sales = []
      querySnapshot.forEach((doc) => {
        sales.push(
          {
            key: doc.id, ...doc.data()
          }
        )
      })
      return { totalItemsCount: sales.length, searchResult: sales }
      // console.log('initializseSales',sales)
    }).catch((error) => {
      console.log(error)
    })
  }
};

export function getCustomerTransactions(customerID) {

  return (dispatch) => {
    return database.collection("Sales").where("Customer.key", "==", customerID).limit(100).orderBy("TransactionDate","desc").get().then((querySnapshot) => {
      const sales = []
      querySnapshot.forEach((doc) => {
        sales.push(
          {
            key: doc.id, ...doc.data()
          }
        )
      })
      return { totalItemsCount: sales.length, searchResult: sales }
      // console.log('initializseSales',sales)
    }).catch((error) => {
      console.log(error)
    })
  }
};

export function checkIfCustomerCodeIsUnique(customercode, customerID, registrationID) {
  return (dispatch) => {
    if(customercode.trim()==="")
    {
      return true 
    }
      let searchParameters = {
          "from": 0,
          "size": 1,
          "query": {
              "bool": {
                  "must": [
                      { "term": { "RegistrationID": registrationID } },
                      { "term": { "IsDeleted": "false" } },
                      { "term": { "CustomerCode": customercode.trim().toLowerCase() } }
                  ],
                  "must_not": [
                  ]
              }
          }
      }
      if (!isNullOrUndefined(customerID) && customerID !== "") {
          searchParameters.query.bool.must_not.push({ "match": { "_id": customerID } })
      }
      // console.log('customer searchParameters', JSON.stringify(searchParameters));
      let addMessage = firebase.functions().httpsCallable('getCustomers');
      return addMessage({ text: searchParameters }).then(function (result) {
          let sanitizedMessage = result.data.text
          let isUnique = true;
          if (sanitizedMessage.hits.total.value > 0) {
              isUnique = false
          }
          // console.log('isUnique', isUnique)
          return isUnique
      });
  }
}


export function checkIfCustomerPhoneNoIsUnique(phoneNo, customerID) {
  return (dispatch, getState) => {
    
    let searchParameters = {
      "from": 0,
      "size": 1,
      "query": {
        "bool": {
          "must": [
            { "term": { "IsDeleted": "false" } },
            { "term": { "PhoneNo": phoneNo.trim() } }
          ],
          "must_not": [
          ]
        }
      }
    }

    if(!getState().registration.registration.hasOwnProperty('GlobalCustomer') || getState().registration.registration.GlobalCustomer)
    {
      searchParameters.query.bool.must.push({ "term": { "RegistrationID": getState().user.user.RegistrationID } })
    }
    else
    {
      searchParameters.query.bool.must.push({ "match": { "StoreID": getState().user.store.key } })
    }

    if (!isNullOrUndefined(customerID) && customerID !== "") {
      searchParameters.query.bool.must_not.push({ "match": { "_id": customerID } })
    }

    console.log('customer searchParameters', JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text
      console.log('sanitizedMessage',sanitizedMessage)
      let isUnique = true;
      if (sanitizedMessage.hits.total.value > 0) {
        isUnique = false
      }
      return isUnique
    });
  }
}


export function checkIfCustomerStoreMappingExists(saleToPurchaseStoreID, customerID) {
  return (dispatch, getState) => {
    let searchParameters = {
      "from": 0,
      "size": 1,
      "query": {
        "bool": {
          "must": [
            { "term": { "IsDeleted": "false" } },
            { "term": { "SaleToPurchaseStoreID.keyword": saleToPurchaseStoreID.trim() } }
          ],
          "must_not": [
          ]
        }
      }
    }

    if(!getState().registration.registration.hasOwnProperty('GlobalCustomer') || getState().registration.registration.GlobalCustomer)
    {
      searchParameters.query.bool.must.push({ "term": { "RegistrationID": getState().user.user.RegistrationID } })
    }
    else
    {
      searchParameters.query.bool.must.push({ "match": { "StoreID": getState().user.store.key } })
    }

    if (!isNullOrUndefined(customerID) && customerID !== "") {
      searchParameters.query.bool.must_not.push({ "match": { "_id": customerID } })
    }
    console.log('customer searchParameters', JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text
      let isExists  = false;
      if (sanitizedMessage.hits.total.value > 0) {
        isExists = true
      }
      return isExists
    });
  }
}

export function pushCustomersToRedux(customers) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ACTIVE_CUSTOMERS, customers })
  }
}

export function getActiveCustomers(registrationID) {
  return (dispatch,getState) => {
    return database.collection("Customers").where("RegistrationID", "==", registrationID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
      let customers = []
      querySnapshot.forEach((doc) => {
        let customer = doc.data();
        customer.ActionOn = null
        customers.push({ key: doc.id, ...customer })
      });
      dispatch({ type: actionTypes.GET_ACTIVE_CUSTOMERS, customers })
      return customers;
    });
  }
};


export function getBulkCustomers(registrationID, scrollID, option, searchText, searchCity,
  searchState, searchPinCode, searchCountry, searchMembership, searchWalletFrom, searchWalletTo,
  customField1, customField2, customField3, customField4, customField5, customField6) {
  let searchParameters = null
  if (scrollID == "") {
    searchParameters = {
      "from": 0,
      "size": 2000,
      "track_total_hits": true,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": registrationID } }
          ],
          "must_not": [],
          "filter": {
            "bool": {
              "must": []
            }
          }
        }
      },
      "sort": [
        {
          "ActionOn": {
            "order": "desc"
          }
        }
      ]
    }
  }
  else {
    searchParameters = {
      "scroll": "1m",
      "scroll_id": scrollID
    }
  }    

  if (option === "active") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
  }
  if(searchText.trim() !== ""){
  searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomerDetails": "@" + searchText + ".*" } })
  //searchParameters.query.bool.filter.bool.must.push({ "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } })
  //searchParameters.query.bool.filter.bool.must.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
  //searchParameters.query.bool.filter.bool.must.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
  }
  if (searchCity.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.City": "@" + searchCity.trim().toLowerCase() + ".*" } })
  }
  if (searchState.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.State": "@" + searchState.trim().toLowerCase() + ".*" } })
  }
  if (searchPinCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
  }
  if (searchCountry.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
  }
  if (searchMembership.trim() !== ""){
    searchParameters.query.bool.filter.bool.must.push({"regexp": {"Membership.Name" : "@" + searchMembership.trim().toLowerCase() + ".*"} })
  }    
  if (searchWalletFrom !== "") {
    searchParameters.query.bool.filter.bool.must.push({"range": {"WalletBalance": {"gte": searchWalletFrom} }})
  }
  if (searchWalletTo !== "") {
    searchParameters.query.bool.filter.bool.must.push({"range": { "WalletBalance": {"lte": searchWalletTo} }})
  }    
  if (customField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + customField1.trim().toLowerCase() + ".*" } })
  }
  if (customField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + customField2.trim().toLowerCase() + ".*" } })
  }
  if (customField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + customField3.trim().toLowerCase() + ".*" } })
  }
  if (customField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + customField4.trim().toLowerCase() + ".*" } })
  }
  if (customField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + customField5.trim().toLowerCase() + ".*" } })
  }
  if (customField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + customField6.trim().toLowerCase() + ".*" } })
  }

  console.log(JSON.stringify(searchParameters))
  
  return (dispatch,getState) => {
    let addMessage = ''
    // console.log("User", getState().user.user.Role)
    if (getState().user.user.Role === "Store Owner" && scrollID === "") {
      searchParameters.query.bool.must.push({
        "match": {
          "StoreID": getState().user.store.key
        }
      })
    }
    if (scrollID === "") {
      addMessage = firebase.functions().httpsCallable('getCustomersWithScrollID');
    }
    else {
      addMessage = firebase.functions().httpsCallable('getCustomersForScrollID');
    }

    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers, scrollID: sanitizedMessage._scroll_id }
    });    
  }
}

export function getCustomerProducts(customerID ) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "CustomerID.keyword": customerID
            }
          }
        ]
      }
    },
    "aggs": {
      "products": {
        "terms": {
          "field": "ProductName.keyword"
        },
        "aggs": {
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          },
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          }
        }
      }
    }
  }

   return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const products = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.products.buckets.map((data, index) => {
        let product = {
          name: data.key,
          product_type: data.key.product_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
        }
        products.push(product);
      })

      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products }
    });
  }
};




export function getLoyaltyLedgersByCustomerID(customerID) {
  return (dispatch) => {
    return database.collection("LoyaltyLedger").where("CustomerID", "==", customerID).limit(100).orderBy("TransactionDate","desc").get().then((querySnapshot) => {
      const ledgers = []
      querySnapshot.forEach((doc) => {
        ledgers.push(
          {
            key: doc.id, ...doc.data()
          }
        )
      })
      console.log("getLoyaltyLedgersByCustomerID",ledgers)
      return { totalItemsCount: ledgers.length, searchResult: ledgers }
    }).catch((error) => {
      console.log(error)
    })
  }
};

export function getPackagesByCustomerID(customerID) {
  return (dispatch) => {
    return database.collection("Packages").where("CustomerID", "==", customerID).get().then((querySnapshot) => {
      const packages = []
      querySnapshot.forEach((doc) => {

        let objPackage = {
          key: doc.id, ...doc.data()
        }
        console.log(doc.data());
        if (objPackage.PackageStatus === 'Pending' || objPackage.PackageStatus === 'Partial')
          packages.push(objPackage);

      })
      return packages;
    }).catch((error) => {
      console.log(error)
    })
  }
};


export function getTopProductsForACustomer(customerID, size) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "CustomerID.keyword": customerID
            }
          }
        ]
      }
    },
    "aggs": {
      "products": {
        "terms": {
          "field": "ProductName.keyword",
          "size": size,
          "order": {
            "total_qty": "desc"
          }
        },
        "aggs": {
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          }
        }
      }
    }
  }
   return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const products = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.products.buckets.map((data, index) => {
        let product = {
          name: data.key,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        }
        products.push(product);
      })

      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products }
    });
  }
};

export function updateCustomerNote(customerNote, key){
  return (dispatch, getState) => {
      return database.collection('Customers').doc(key).update({
        Note: customerNote,
        ActionOn : firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy : getState().user.user.Name,
        ActionByUID : getState().user.user.UID,
        ActionByEmailID : getState().user.user.EmailID,
        Action : constants.AUDIT_TRAIL_EDIT
      })
  }
}

export function initialiseCustomers(registrationID) {
  return (dispatch) => {
      return database.collection("Customers").where("RegistrationID","==",registrationID).limit(1).get()
  }
}