import React, { Component } from 'react';
import * as actions from '../../../actionTypes'
import Modal from 'react-responsive-modal'
import { isNullOrUndefined } from 'util'

import EmployeeAccess from './EmployeeAccess'
import EmployeeServiceMapping from './EmployeeServiceMapping';
import ChangeEmployeeAccess from './ChangeEmployeeAccess';

import HeaderNew from '../../HeaderNew'
import '../../Common/Common.css'
import Spinner from '../../Common/Spinner'
import ActionButton from '../../Common/ActionButton'


class ViewEmployeeDetails extends Component {

    constructor() {
        super()
        this.state = {
            employee: null,
            employeeID: '',
            user: null,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            showError: false,
            showModal: false,
            showChangeModal: false,
            showMapping: false,
            userError: '',
            loading: true,
            fabButtons: [
                { action: "edit", text: "Edit" },
                { action: "mapping", text: "Service Mapping" }
            ],
        }
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
    }

    componentDidMount() {

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Employee') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })

        this.props.getEmployeeDetails(this.props.location.state.key).then(async (employee) => {

            employee.Role = ''
            employee.Stores = [];
            if (employee.hasOwnProperty("UID") && employee.UID !== "") {
                let user = await this.props.getUserDetails(employee.UID);

                if (user) {
                    this.setState({ user: user });
                    employee.Role = user.Role;
                    employee.Stores = user.Stores;
                }
            }
            let fabButtons = this.state.fabButtons;

            if (employee && isNullOrUndefined(employee.UID)) {
                fabButtons.push({ action: "assign", text: "Assign Access" })
            }
            else if (employee && employee.hasOwnProperty("UID") && employee.UID !== "Power User") {
                fabButtons.push({ action: "change", text: "Change Access" })
            }

            this.setState({ employee: employee, employeeID: this.props.location.state.key, loading: false, fabButtons: fabButtons })
        })

    }

    onActionButtonClick(action) {
        if (action === "edit") {
            this.props.history.push({ pathname: "/home/settings/employees/editemployee", state: { action: actions.EMPLOYEE_EDIT, key: this.state.employeeID, employee: this.state.employee } })
        }
        else if (action === "mapping") {
            this.props.history.push({ pathname: '/home/settings/employees/mapping', state: { module: "servicemapping", employeeID: this.state.employeeID, employee: this.state.employee } })

        }
        else if (action === "assign") {
            this.props.history.push({ pathname: '/home/settings/employees/assignaccess', state: { module: "assignaccess", employeeID: this.state.employeeID, employee: this.state.employee } })

        }
        else if (action === "change") {
            this.props.history.push({ pathname: '/home/settings/employees/changeaccess', state: { module: "changeaccess", userAccess: this.state.user, employeeID: this.state.employeeID, employee: this.state.employee } })

        }
    }

    render() {
        const modalWidth = { width: '700px' }
        return <React.Fragment>
            {this.props.isMobileApp() && <HeaderNew {...this.props}
                back={true}
                title="View Employee"
                onBackClick={() => { this.props.history.push({ pathname: '/home/settings/employees' }) }}
            />}
            {this.state.loading && <Spinner />}
            <div className={this.props.isMobileApp() ? "" : "content-wrapper"}>
                {!this.props.isMobileApp() && <section className="content-header">
                    <h1>View Employee Details</h1>
                    <button type="submit" className="btn btn-success pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={() => this.props.history.push({
                            pathname: "/home/settings/employees/editemployee",
                            state: {
                                action: actions.EMPLOYEE_EDIT, key: this.state.employeeID,
                                employee: this.state.employee
                            }
                        })}>Edit</button>
                    {this.state.employee && <button type="button"
                        className="btn btn-default btn-md pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={() => this.setState({ showMapping: true })}>Service Mapping</button>}
                    {(this.state.employee && isNullOrUndefined(this.state.employee.UID)) && <button type="button"
                        className="btn btn-default btn-md pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={() => this.setState({ showModal: true })}>Assign Access</button>}
                    {(this.state.employee && this.state.employee.hasOwnProperty("UID") && this.state.employee.UID !== "Power User") && <button type="button"
                        className="btn btn-default btn-md pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={() => this.setState({ showChangeModal: true })}>Change Access</button>}
                    <button type="cancel" className="btn btn-default pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={() => this.props.history.push({ pathname: "/home/settings/employees" })}
                    >Back</button>
                </section>}
                {this.state.employee && <section className={this.props.isMobileApp() ? "" : "content"} style={{ marginTop: this.props.isMobileApp() ? "52px" : "0px" }}>
                    <div className="box " style={{ marginBottom: this.props.isMobileApp() ? "0px" : "" }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">General details</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >Employee Code</label>
                                        <br />
                                        <span>{this.state.employee.EmployeeCode}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <br />
                                        <span>{this.state.employee.EmployeeFirstName}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <br />
                                        <span>{this.state.employee.EmployeeLastName}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <br />
                                        <span>{this.state.employee.Gender}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >Phone Number</label>
                                        <br />
                                        <span>{this.state.employee.PhoneNo}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label>Email</label>
                                        <br />
                                        <span>{this.state.employee.EmailID}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label>Biometric ID</label>
                                        <br />
                                        <span>{this.state.employee.BiometricID}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label>Show in Sales</label>
                                        <br />
                                        <span>{(this.state.employee.ShowInSales === true ? 'Yes' : 'No')}</span>
                                    </div>
                                </div>
                                {this.state.user && <React.Fragment>
                                    <div className="col-md-3 col-xs-6">
                                        <div className="form-group" >
                                            <label>Role</label>
                                            <br />
                                            <span>{this.state.user.Role}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-xs-6">
                                        <div className="form-group" >
                                            <label>Accessible Stores</label>
                                            <br />
                                            {this.state.user.Stores && this.state.user.Stores.map((store) =>
                                                <div>Store : {store.StoreName}</div>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                    <div className="box " style={{ marginBottom: this.props.isMobileApp() ? "0px" : "" }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Address</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >Line Address 1</label>
                                        <br />
                                        <span>{this.state.employee.Address1}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <label>Line Address 2</label>
                                        <br />
                                        <span>{this.state.employee.Address2}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <label>City</label>
                                        <br />
                                        <span>{this.state.employee.City}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >State</label>
                                        <br />
                                        <span>{this.state.employee.State}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >Postal Code</label>
                                        <br />
                                        <span>{this.state.employee.PostalCode}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >Country</label>
                                        <br />
                                        <span>{this.state.employee.Country}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box " style={{ marginBottom: this.props.isMobileApp() ? "0px" : "" }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Additonal Information</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >{this.state.customField1DisplayName}</label>
                                        <br />
                                        <span>{this.state.employee.CustomField1}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group">
                                        <label>{this.state.customField2DisplayName}</label>
                                        <br />
                                        <span>{this.state.employee.CustomField2}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >{this.state.customField3DisplayName}</label>
                                        <br />
                                        <span>{this.state.employee.CustomField3}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >{this.state.customField4DisplayName}</label>
                                        <br />
                                        <span>{this.state.employee.CustomField4}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >{this.state.customField5DisplayName}</label>
                                        <br />
                                        <span>{this.state.employee.CustomField5}</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-xs-6">
                                    <div className="form-group" >
                                        <label >{this.state.customField6DisplayName}</label>
                                        <br />
                                        <span>{this.state.employee.CustomField6}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}
                {(this.state.employee && this.props.isMobileApp()) && <ActionButton
                    onClick={(action) => this.onActionButtonClick(action)}
                    fabButtons={this.state.fabButtons} />}

                    
                <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                    <div style={modalWidth}>
                        <EmployeeAccess  {...this.props}
                            employee={{ ...this.state.employee, employeeID: this.state.employeeID }}
                            onClose={() => this.setState({ showModal: false })}
                        />
                    </div>
                </Modal>
                <Modal open={this.state.showChangeModal} onClose={() => this.setState({ showChangeModal: false })} center>
                    <div style={modalWidth}>
                        <ChangeEmployeeAccess  {...this.props}
                            employee={{ ...this.state.employee, employeeID: this.state.employeeID }}
                            userAccess={this.state.user}
                            onSaveChangeEmployeeAccess={(changedUser) => {
                                // console.log("changedUser", JSON.stringify(changedUser));
                                this.setState({ showChangeModal: false, user: changedUser })
                            }}
                            onClose={() => this.setState({ showChangeModal: false })}
                        />
                    </div>
                </Modal>
                <Modal open={this.state.showMapping} onClose={() => this.setState({ showMapping: false })} center>
                    <div style={modalWidth}>
                        <EmployeeServiceMapping {...this.props}
                            employee={{ ...this.state.employee, employeeID: this.state.employeeID }}
                            onClose={() => this.setState({ showMapping: false })}
                        />
                    </div>
                </Modal>
                <Modal open={this.state.showError} onClose={() => this.setState({ showError: false })} center>
                    <div style={{ width: "300px" }}>
                        <h3 style={{ marginTop: "0px" }}>Error</h3>
                        <div className="box-body">
                            <ul>
                                {this.state.userError !== "" && <li>{this.state.userError}
                                </li>}
                            </ul>
                        </div>
                        <div className="box-footer">
                            <button type="submit" className="btn btn-flat btn-md btn-danger pull-right"
                                onClick={() => this.setState({ showError: false })}>OK</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    }
}

export default ViewEmployeeDetails