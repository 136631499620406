import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import * as actionTypes from '../../actionTypes'

class ViewIntransit extends Component {

    constructor() {
        super()
        this.state = {
            title: 'In Transit',
            loading: true,
            selectAll: false,
            lineItemSelected: false,
            page: 'In Transit - Sales',
            inventoryList: []
        }
        this.loadList = this.loadList.bind(this);
        this.onConvertTransaction = this.onConvertTransaction.bind(this);
        this.checkIfAtLeastOneSelected = this.checkIfAtLeastOneSelected.bind(this);
    }

    componentWillMount() {
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    }

    componentDidMount() {
        this.loadList();
    }

    loadList() {
        this.setState({ loading: true});
        this.props.getInTransitInventoryForAStore(this.props.user.store.key)
            .then((result) => {
                this.setState({
                    inventoryList: result.searchResult,
                    totalItemsCount: result.totalItemsCount,
                    loading: false
                })
            })
    }

    checkIfAtLeastOneSelected(){
        let lineItemSelected = false
        if(this.state.inventoryList.filter(f=> f.Selected).length > 0)
        {
            lineItemSelected= true
        }
        this.setState({lineItemSelected:lineItemSelected})
    }

    async onConvertTransaction(selectedInventory) {
        console.log('selectedInventory',selectedInventory)
        //get selected records
        let transactionType = null
        let customer = null

        //get data from firestore
        let record = await this.props.getInventoryDetails(selectedInventory.key)
        // console.log("record", record);

        customer = await this.props.getCustomerDetailsByStoreID(record.Store.key);
        // console.log("customer", customer);

        let inventoryToSale = await this.props.getInventoryToSale(record.RegistrationID);
        let convertToTransactionTypeID = '';

        let transactionTypes = this.props.config.TransactionTypes.filter(f => f.Module.toLowerCase() === "sales"
            && f.TransactionType.toLowerCase() !== "receive payment" && !f.IsDeleted);

        // console.log("inventoryToSale",inventoryToSale);
        // console.log("record transaction",record.TransactionType.key);

        if (inventoryToSale) {
            let obj = inventoryToSale.filter((f) => f.InventoryTransactionTypeID === record.TransactionType.key);
        
            if (obj.length > 0) {
                convertToTransactionTypeID = obj[0].SalesTransactionTypeID;
            }
        }
        // console.log("convertToTransactionTypeID",convertToTransactionTypeID);
        // console.log("transactionTypes", transactionTypes);

        let obj1 = transactionTypes.filter((f) => f.key === convertToTransactionTypeID);
        if (obj1.length > 0) {
            transactionType = obj1[0];
        }

        let sale = {
            TransactionType: transactionType,
            Customer: customer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: null,
            Store: this.props.user.store,
            LineItems: [],
            BillQty: record.BillQty,
            BillSubTotal: record.BillSubTotal,
            BillDiscount: record.BillDiscount,
            BillTaxAmount: record.BillTaxAmount,
            BillAmount: record.BillAmount,
            RoundOff: 0,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            Payments: null,
            ParentTransactionNo: record.TransactionNo,
            ParentTransactionKey: selectedInventory.key,
            ParentTransactionModule: record.TransactionType.Module,
            Employee: null,
            Notes: "",
            TaxSummaryList: [],
            PlaceOfSupply: "",
            BillingAddress: null,
            DeliveryCustomer: null,
            DeliveryCustomerAddress: null,
            DeliveryDate: "",
            DeliveryTime: "",
            deliveryNotes: "",
            BackEndUpdate: false,
            ReceivedPaymentAmount:0,
            ConvertedFromTransactionBalanceAmount:0,
            IsConvert:true
        }
        sale.CustomField1 = record.hasOwnProperty('CustomField1') ? record.CustomField1 : ''
        sale.CustomField2 = record.hasOwnProperty('CustomField2') ? record.CustomField2 : ''
        sale.CustomField3 = record.hasOwnProperty('CustomField3') ? record.CustomField3 : ''
        sale.CustomField4 = record.hasOwnProperty('CustomField4') ? record.CustomField4 : ''
        sale.CustomField5 = record.hasOwnProperty('CustomField5') ? record.CustomField5 : ''
        sale.CustomField6 = record.hasOwnProperty('CustomField6') ? record.CustomField6 : ''
        sale.CustomField7 = record.hasOwnProperty('CustomField7') ? record.CustomField7 : ''
        sale.CustomField8 = record.hasOwnProperty('CustomField8') ? record.CustomField8 : ''
        sale.CustomField9 = record.hasOwnProperty('CustomField9') ? record.CustomField9 : ''
        sale.CustomField10 = record.hasOwnProperty('CustomField10') ? record.CustomField10 : ''
        sale.CustomField11 = record.hasOwnProperty('CustomField11') ? record.CustomField11 : ''
        sale.CustomField12 = record.hasOwnProperty('CustomField12') ? record.CustomField12 : ''
        sale.CustomField13 = record.hasOwnProperty('CustomField13') ? record.CustomField13 : ''
        sale.CustomField14 = record.hasOwnProperty('CustomField14') ? record.CustomField14 : ''
        sale.CustomField15 = record.hasOwnProperty('CustomField15') ? record.CustomField15 : ''
        sale.CustomField16 = record.hasOwnProperty('CustomField16') ? record.CustomField16 : ''
        sale.CustomField17 = record.hasOwnProperty('CustomField17') ? record.CustomField17 : ''
        sale.CustomField18 = record.hasOwnProperty('CustomField18') ? record.CustomField18 : ''
        sale.CustomField19 = record.hasOwnProperty('CustomField19') ? record.CustomField19 : ''
        sale.CustomField20 = record.hasOwnProperty('CustomField20') ? record.CustomField20 : ''
        record.LineItems.map((l) => {
            if(l.hasOwnProperty("BatchID")){
                l.BatchID="";
            }
            l.ParentTransactionNo = record.TransactionNo
            l.ParentTransactionKey = selectedInventory.key
            l.ParentTransactionType = record.TransactionType.TransactionType
            l.ParentTransactionTypeKey = record.TransactionType.key
            l.Notes = record.TransactionNo
            l.Employee = {}
            l.RetailPrice = l.SupplyPrice
            sale.LineItems.push(l)
        })
       
        // console.log('record',record);
        
        // if (transactionType && transactionType.hasOwnProperty('UpdateStockChecked')) {
        //     if (transactionType.UpdateStockChecked) {
        //         transactionType.UpdateStockChecked = false//donot update stock again
        //     }
        // }
        console.log('sale',sale);

        if(transactionType.TransactionType.toLowerCase() === "sales return")
        {
            sale.TransactionNo = record.TransactionNo

            this.props.history.push({
                pathname: '/home/sales/sales',
                state: {
                    type: actionTypes.SALE_NEW,
                    key: selectedInventory.key,
                    transactionType: transactionType,
                    sale: sale
                }
            })
        }
        else
        {
            this.props.history.push({
                pathname: '/home/sales/sales',
                state: {
                    type: actionTypes.SALE_NEW,
                    key: "",
                    transactionType: transactionType,
                    sale: sale
                }
            })
        }        
    }

    render() {
        const btnColStyle = { width: "20px" }
        return <div className="content-wrapper" >
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row" >
                                <div className="col-md-9 col-xs-9">
                                    <font style={{ fontSize: "30px" }}>{this.state.title}</font>
                                </div>
                                <div className="col-md-3 col-xs-3 pull-right hidden-xs" style={{ paddingTop: "4px" }}>
                                    {this.state.title !== "Sales History" && <button type="cancel" className="btn btn-default pull-right btn-flat"
                                        style={{ marginLeft: "10px" }}
                                        onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                    ><i className="fa fa-arrow-left"></i> Back</button>}
                                </div>
                            </div>
                        </div>
                        <div className="box-body" style={{ padding: "0px" }}>
                            {this.state.loading && <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2"><Loader show={this.state.loading} /></div>}
                            {(!this.state.loading) && <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2">
                            <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th className="hidden-md hidden-lg">Transaction</th>
                                                <th className="hidden-xs">Transaction No</th>
                                                <th className="hidden-xs">Transaction Type</th>
                                                <th className="hidden-xs">Transaction Date</th>
                                                <th className="hidden-xs">Store Location</th>
                                                <th className="hidden-xs">Vendor/Store</th>
                                                <th className="hidden-xs">Ref No</th>
                                                <th className="hidden-xs">Notes</th>
                                                <th className="hidden-xs">Qty</th>
                                                <th className="hidden-xs">Total Tax</th>
                                                <th className="hidden-xs">Discount</th>
                                                <th className="hidden-xs">Additional Discount</th>
                                                <th className="hidden-xs">Additional Charges</th>
                                                <th className="hidden-xs">Round Off</th>
                                                <th className="hidden-xs">Amount</th>
                                                <th className="hidden-xs">Status</th>
                                                <th style={btnColStyle} className="hidden-xs"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.inventoryList && this.state.inventoryList.map((inventory, index) =>
                                                <tr key={index}>
                                                    <td className="hidden-md hidden-lg" onClick={() => {
                                                            let redirectTo = "/home/inventory/viewinventory/"+ inventory.key
                                                            this.props.history.push({pathname:redirectTo})
                                                        }}>
                                                        <div className="pull-left">
                                                            <div>{inventory.TransactionNo}</div>
                                                            <div>{inventory.TransactionType.TransactionType}</div>
                                                            <div>
                                                                {inventory.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                            </div>
                                                            <div>{(new Date(inventory.TransactionDate).toISOString().split('T')[0])}</div>
                                                            <div>{inventory.StoreLocation}</div>
                                                            {inventory.Vendor && <React.Fragment>
                                                                <div>{inventory.Vendor.VendorFirstName} {inventory.Vendor.VendorLastName}</div>
                                                                <div>{inventory.Vendor.VendorCode}</div>
                                                            </React.Fragment>}
                                                            {(inventory.FromStore && inventory.FromStore.trim() !== "") && <React.Fragment>
                                                                <div>{inventory.FromStore}</div>
                                                            </React.Fragment>}
                                                            {(inventory.ToStore && inventory.ToStore.trim() !== "") && <React.Fragment>
                                                                <div>{inventory.ToStore}</div>
                                                            </React.Fragment>}
                                                            <div>{inventory.RefNo}</div>
                                                            <div>{inventory.Notes}</div>
                                                            <div>{inventory.Status}</div>
                                                        </div>
                                                        <div className="pull-right">
                                                            <div>Qty: {inventory.BillQty}</div>
                                                            <div>Tax: {inventory.BillTaxAmount}</div>
                                                            <div>Dis: {inventory.BillDiscount}</div>
                                                            <div>Add Dis: {inventory.hasOwnProperty('BillAdditionalDiscount') ? inventory.BillAdditionalDiscount : 0}</div>
                                                            <div>Add Charges:{inventory.BillAdditionalCharges}</div>
                                                            <div>Round Off:{inventory.BillRoundOff}</div>
                                                            <div>Amt: {inventory.BillAmount}</div>
                                                            <div>Status: {inventory.Status }</div>
                                                        </div>
                                                    </td>
                                                    <td className="hidden-xs">
                                                        {inventory.TransactionNo}
                                                        <div>
                                                            {inventory.hasOwnProperty('ChildTransactions') && inventory.ChildTransactions && inventory.ChildTransactions.map(m=> <span className="badge bg-default">{m.ChildTransactionNo}</span>) }
                                                        </div>
                                                        <div>
                                                            {inventory.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                        </div>
                                                        <div>
                                                            {inventory.hasOwnProperty('ParentTransactions') && inventory.ParentTransactions && inventory.ParentTransactions.map(m=> <span className="badge bg-default">{m.ParentTransactionNo}</span>) }
                                                        </div>
                                                        
                                                    </td>
                                                    <td className="hidden-xs">
                                                        {inventory.TransactionType.TransactionType}
                                                    </td>
                                                    <td className="hidden-xs">{(new Date(inventory.TransactionDate).toISOString().split('T')[0])}</td>
                                                    <td className="hidden-xs">
                                                        {inventory.StoreLocation}
                                                    </td>
                                                    <td className="hidden-xs">
                                                        {inventory.Vendor && <React.Fragment>
                                                            <div>{inventory.Vendor.VendorFirstName} {inventory.Vendor.VendorLastName}</div>
                                                            <div>{inventory.Vendor.VendorCode}</div>
                                                        </React.Fragment>}
                                                        {(inventory.FromStore && inventory.FromStore.trim() !== "") && <React.Fragment>
                                                            <div>{inventory.FromStore}</div>
                                                        </React.Fragment>}
                                                        {(inventory.ToStore && inventory.ToStore.trim() !== "") && <React.Fragment>
                                                            <div>{inventory.ToStore}</div>
                                                        </React.Fragment>}
                                                    </td>
                                                    <td className="hidden-xs">{inventory.RefNo}</td>
                                                    <td className="hidden-xs">{inventory.Notes}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.BillQty}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.BillTaxAmount}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.BillDiscount}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.hasOwnProperty('BillAdditionalDiscount') ? inventory.BillAdditionalDiscount : 0}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.BillAdditionalCharges}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.BillRoundOff}</td>
                                                    <td className="hidden-xs" style={{textAlign:"right"}}>{inventory.BillAmount}</td>
                                                    <td className="hidden-xs">{inventory.Status}</td>
                                                    <td className="hidden-xs">
                                                        <div>
                                                        <button type="button" className="btn btn-md btn-flat btn-default"
                                                                        onClick={() => this.onConvertTransaction(inventory)}>
                                                                        <i className="fa fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
export default withRouter(ViewIntransit)