import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Delivery from "./Delivery";
import Confirm from "react-confirm-bootstrap";
import { isNullOrUndefined } from "util";
import * as constatnts from "../../Constatnts";
import CancelSales from "./CancelSales";
import Window from "../2/Controls/Window";
import ImageButton from "../2/Controls/ImageButton";
import { isMobileApp } from "../../actions/Util";
import Button from "../2/Controls/Button";
import Input from "../2/Controls/Input";
import Print from "./Print";
import {
  salesCalculateLineItem,
  salesCalculateBillTotal,
} from "../../actions/Util";
import "./DeliveryList.css";
import Spinner from "../Common/Spinner";
import Icon from "../2/Controls/Icon";
import Menu from "../2/Menu";
import RecordNotFound from "../Common/RecordNotFound";
import PageSection from "../2/Controls/PageSection";
import AuditTrail from "../AuditTrail";
import axios from "axios";

import * as constants from "../../Constatnts";

class OnlineOrdersInfinityApp extends Component {
  constructor() {
    super();
    this.state = {
      deliveryListDetail: [],
      selectedDelivery: null,
      searchCustomerDetail: "",
      searchTransactionNo: "",
      searchTransactionFromDate: "", //new Date().toISOString().split('T')[0],
      searchTransactionToDate: "",
      cashRegisterList: [],
      cashRegister: {},
      searchCashRegister: "",
      searchStore: "",
      searchEmployeeName: "",
      searchStatus: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      loading: false,
      showDelivery: false,
      showOutForDelivery: false,
      selectedEmployeeKey: "",
      selectedEmployee: "",
      sendSMSForOutForDelivery: false,
      SMSNo: "",
      employeeList: [],
      selectedSale: null,
      showSearchOptions: false,
      mobile: false,
      showFilter: false,
      enableONDC: false,
      nonONDC: false,
      cancellationReasonCodes: [],
      ONDCCancellationReason: "",
      ONDCCancellationReasonCode: "",
      ONDCCancellationReasonError: "",
      deliveryPersonKey: "",
      deliveryPersonMobileNo: "",
      deliveryPersonName: "",
      statusSummary: [],
      orderNo: "",
      showMobileRowOptions: false,
      showMobileOrderDetailWindow: false,
      activePage: 0,
      merchantInvoiceNo: "",
      showMerchantInvoiceNo: false,
      stores: [],
    };
    this.loadDeliveryDetailReport = this.loadDeliveryDetailReport.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onDeliveryAction = this.onDeliveryAction.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStatusUpdateClick = this.onStatusUpdateClick.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
    this.onRecordsPerPageCountChange = this.onRecordsPerPageCountChange.bind(
      this
    );
    this.onFirstClick = this.onFirstClick.bind(this);
    this.onLastClick = this.onLastClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onPrintClicked = this.onPrintClicked.bind(this);
    this.renderStatusFilters = this.renderStatusFilters.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
    this.renderQuickActionButtons = this.renderQuickActionButtons.bind(this);
    this.onShowEditWindow = this.onShowEditWindow.bind(this);
    this.updateSalesTransaction = this.updateSalesTransaction.bind(this);
    this.onQtyChange = this.onQtyChange.bind(this);
    this.getPendingOlineOrder = this.getPendingOlineOrder.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderMobile = this.renderMobile.bind(this);
    this.renderMobileRowOptions = this.renderMobileRowOptions.bind(this);
    this.renderMobileOrderDetails = this.renderMobileOrderDetails.bind(this);
    this.renderLineItems = this.renderLineItems.bind(this);
    this.renderCancellationText = this.renderCancellationText.bind(this);
    this.updateStatusInfinityApp = this.updateStatusInfinityApp.bind(this);
    this.cancelOrderInfinityApp = this.cancelOrderInfinityApp.bind(this);
  }

  // Order received = Created
  // Accepted
  // Packed
  // Out for delivery
  // Delivered
  // Cancelled
  // All
  // Order picked up

  async componentDidMount() {
    let stores = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      this.props.user.auth.uid
    );
    let searchStatus = "Created";
    let searchStore = this.props.user.store.key;

    this.setState({
      stores: stores,
      searchStore: searchStore,
      searchStatus: searchStatus,
    });

    this.props
      .getEmployees(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        0,
        1000,
        "active",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
      .then((employees) => {
        this.setState({ employeeList: employees.searchResult });
      });

    this.loadDeliveryDetailReport(
      this.state.recordFrom,
      this.state.itemsCountPerPage,
      searchStatus,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
    this.timer = setInterval(() => this.getPendingOlineOrder(), 10000);
  }

  async getPendingOlineOrder() {
    // console.log("Pawan");
    let startTime = new Date().getTime(); //
    let result = await this.props.getDeliveryDetailReport(
      this.props.user.user.RegistrationID,
      0,
      100,
      "Created",
      "",
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
      "",
      this.props.user.store.key,
      "",
      ""
    );
    let newOrderReceived = false;
    result.searchResult.map((r) => {
      if (r.hasOwnProperty("RequestSource") && r.RequestSource !== "") {
        let timeDiff = (startTime - r.TransactionDate) / 1000;
        if (timeDiff < 12) {
          // console.log("show record", r);
          newOrderReceived = true;
          this.props.getToastr(
            "New order received: " + r.TransactionNo,
            null,
            "",
            this.props,
            null,
            "onlineorder",
            ""
          );
        }
      }
    });
    if (newOrderReceived) {
      if (this.state.searchStatus === "Created") {
        this.loadDeliveryDetailReport(
          this.state.recordFrom,
          this.state.itemsCountPerPage,
          this.state.searchStatus,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          this.state.searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
      }
    }
  }

  async loadDeliveryDetailReport(
    from,
    itemsCountPerPage,
    searchStatus,
    searchTransactionNo,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchStore,
    searchEmployeeName,
    orderNo
  ) {
    this.setState({ loading: true });
    console.log("searchCustomerDetail", searchCustomerDetail);
    let result = await this.props.getDeliveryDetailReportInfinityApp(
      this.props.user.user.RegistrationID,
      from,
      itemsCountPerPage,
      searchStatus,
      searchTransactionNo,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCustomerDetail,
      searchStore,
      searchEmployeeName,
      orderNo
    );

    let results = [];
    let totalItemsCount = 0;
    results = result.searchResult;
    totalItemsCount = result.totalItemsCount;

    let statusSummary = await this.props.getDeliveryStatusSummary(
      this.props.user.user.RegistrationID,
      searchStore
    );

    this.setState({
      statusSummary: statusSummary,
      deliveryListDetail: results,
      totalItemsCount: totalItemsCount,
      loading: false,
      recordFrom: from,
      showSearchOptions: false,
    });

    this.showPaginationStatus();
  }

  onShowEditWindow(delivery) {
    delivery.LineItems.map((l) => {
      l.QtyChange = l.Qty;
    });
    this.setState({
      selectedDelivery: delivery,
      showEditWindow: true,
      showMobileRowOptions: false,
    });
  }

  onFirstClick() {
    let pageNumber = 1;
    let recordFrom = 0;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadDeliveryDetailReport(
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchStatus,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onPreviousClick() {
    if (this.state.activePage > 0) {
      let pageNo = Number(this.state.activePage) - 1;
      let recordFrom = pageNo * this.state.itemsCountPerPage;
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadDeliveryDetailReport(
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.searchStatus,
        this.state.searchTransactionNo,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        this.state.searchCustomerDetail,
        this.state.searchStore,
        this.state.searchEmployeeName,
        this.state.orderNo
      );
    }
  }

  onNextClick() {
    let pageNo = Number(this.state.activePage) + 1;
    let recordFrom = pageNo * this.state.itemsCountPerPage;
    if (recordFrom < this.state.totalItemsCount) {
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadDeliveryDetailReport(
        recordFrom,
        this.state.itemsCountPerPage,
        this.state.searchStatus,
        this.state.searchTransactionNo,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        this.state.searchCustomerDetail,
        this.state.searchStore,
        this.state.searchEmployeeName,
        this.state.orderNo
      );
    }
  }

  onLastClick() {
    let pageNo = Number(
      Number(
        Number(this.state.totalItemsCount) /
          Number(this.state.itemsCountPerPage)
      ).toFixed(0)
    );
    let recordFrom = Number(pageNo) * Number(this.state.itemsCountPerPage);
    this.setState({ activePage: pageNo, recordFrom: recordFrom });
    this.loadDeliveryDetailReport(
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchStatus,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onClearSearch() {
    this.setState({
      recordFrom: 0,
      searchTransactionNo: "",
      searchTransactionToDate: "",
      searchTransactionFromDate: "",
      searchCustomerDetail: "",
      searchCashRegister: "",
      searchEmployeeName: "",
      showSearchOptions: false,
      showFilter: false,
      orderNo: "",
    });
    this.loadDeliveryDetailReport(
      0,
      this.state.itemsCountPerPage,
      this.state.searchStatus,
      "",
      "",
      "",
      "",
      this.state.searchStore,
      "",
      ""
    );
  }

  onRecordsPerPageCountChange(itemsCountPerPage) {
    let pageNo = 1;
    let recordFrom = 0;
    this.setState({
      activePage: pageNo,
      recordFrom: recordFrom,
      itemsCountPerPage: Number(itemsCountPerPage),
    });
    this.loadDeliveryDetailReport(
      0,
      itemsCountPerPage,
      this.state.searchStatus,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onSearchClick() {
    this.setState({ searchFrom: 0, selected: false, showFilter: false });
    this.loadDeliveryDetailReport(
      0,
      this.state.itemsCountPerPage,
      this.state.searchStatus,
      this.state.searchTransactionNo,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onStatusChange(status) {
    let searchTransactionFromDate = "";
    let searchTransactionToDate = "";
    if (status.toLowerCase() === "all") {
      status = "";
    }
    this.setState({
      searchStatus: status,
      recordFrom: 0,
      selected: false,
    });

    this.loadDeliveryDetailReport(
      0,
      this.state.itemsCountPerPage,
      status,
      this.state.searchTransactionNo,
      searchTransactionFromDate,
      searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchStore,
      this.state.searchEmployeeName,
      this.state.orderNo
    );
  }

  onStatusUpdateClick(status) {
    this.setState({ loading: true });
    let recordsToBeUpdated = this.state.deliveryListDetail.filter(
      (f) => f.selected
    );
    if (recordsToBeUpdated.length === 0) {
      alert("No records selected");
      return;
    }
    this.setState({ loading: true });
    recordsToBeUpdated.map(async (sale) => {
      sale.TransactionStatus = status;
      await this.props.updateSales(sale, sale.key);
    });
    this.setState({
      showOutForDelivery: false,
      multipleRecordsToBeUpdated: false,
      saving: false,
      deliveryPersonKey: "",
      deliveryPersonMobileNo: "",
      deliveryPersonName: "",
    });
    setTimeout(
      function() {
        this.onSearchClick();
        this.setState({ loading: false });
      }.bind(this),
      6000
    );
  }

  showPaginationStatus() {
    let paginationStatus;
    if (this.state.totalItemsCount === 0) {
      paginationStatus = "No record(s) found";
    } else if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    return (
      <div
        className={
          isMobileApp() ? "list-page-mobile-pagination" : "list-page-pagination"
        }
      >
        {paginationStatus}
      </div>
    );
  }

  async updateSalesTransaction(delivery) {
    this.setState({ saving: true });
    let sale = await this.props.getSaleDetails(delivery.key);
    if (!sale.hasOwnProperty("OrderLineItems")) {
      sale.OrderLineItems = [];
      sale.LineItems.map((m) => {
        if (m.Product.Name.toLowerCase() !== "delivery charges") {
          sale.OrderLineItems.push({
            ProductID: m.Product.key,
            ProductName: m.Product.Name,
            ProductSKU: m.Product.SKU,
            ProductBarCode: m.Product.BarCode,
            RetailPrice: m.RetailPrice,
            Qty: m.Qty,
            Images: m.Product.hasOwnProperty("Images") ? m.Product.Images : [],
          });
        }
      });
    }
    let lineItems = [];
    let cancelledProducts = [];
    delivery.LineItems.map((l, index) => {
      if (Number(l.QtyChange) === 0) {
        // console.log("remove lineitem", index);
        // sale.LineItems.splice(index, 1);
        cancelledProducts.push({
          ProductID: l.Product.key,
          SKU: l.Product.SKU,
          CancelledQty: Number(l.QtyChange),
        });
      } else {
        sale.LineItems[index].Qty = Number(l.QtyChange);
        lineItems.push(sale.LineItems[index]);
        cancelledProducts.push({
          ProductID: l.Product.key,
          SKU: l.Product.SKU,
          CancelledQty: Number(l.Qty) - Number(l.QtyChange),
        });
      }
    });
    sale.LineItems = lineItems;
    sale.LineItems.map((l, index) => {
      sale.LineItems[index] = salesCalculateLineItem(
        index,
        sale.LineItems[index],
        sale.PlaceOfSupply,
        "",
        "",
        "",
        "",
        sale.TransactionType,
        this.props.config.ProductTaxes
      );
    });
    let totals = salesCalculateBillTotal(sale.LineItems, 0);
    //    console.log('totals',totals);
    sale.BillQty = totals.billQty;
    sale.BillSubTotal = totals.billSubTotal;
    sale.BillAmount = totals.billAmount;
    sale.BillTaxAmount = totals.billTaxAmount;
    sale.BillDiscount = totals.billDiscount;
    sale.TaxSummaryList = totals.taxSummaryList;
    sale.RoundOff = totals.roundOff;
    sale.BillPaidAmount = totals.billAmount;
    if (sale.Payments.length > 0) {
      sale.Payments[0].Amount = totals.billAmount;
    }
    await this.props.updateSales(sale, delivery.key);
    this.props.getToastr("Record saved successfully!!!");
    if (cancelledProducts.length > 0) {
      let order = {
        TransactionNo: sale.ExternalTransactionNo,
        TransactionID: sale.ExternalTransactionID,
        Products: cancelledProducts,
      };
      await this.cancelOrderInfinityApp(order);
    }

    setTimeout(
      function() {
        // this.setState({ searchFrom: 0 ,loading:false})
        this.loadDeliveryDetailReport(
          0,
          this.state.itemsCountPerPage,
          this.state.searchStatus,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          this.state.searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
        this.setState({ saving: false, showEditWindow: false });
      }.bind(this),
      3000
    );
  }

  async onPrintClicked(delivery) {
    let sale = await this.props.getSaleDetails(delivery.key);
    let printConfigs = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
    );
    let printConfig = null;
    if (printConfigs.length === 0) {
      alert("Print setup does not exists");
      return;
    }
    printConfig = printConfigs[0];
    this.printFormatting(sale);
    let printData = {
      Sale: sale,
      PrintConfig: printConfig,
    };
    this.setState({
      print: true,
      printChecked: true,
      sendEmailChecked: false,
      printData: printData,
      showMobileRowOptions: false,
    }); //TBDpawan
  }

  async printFormatting(sale) {
    if (
      sale.hasOwnProperty("ReceivedPaymentTransactions") &&
      sale.hasOwnProperty("IsConvert") &&
      sale.IsConvert
    ) {
      for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
        sale.BillAmount =
          Number(sale.BillAmount) +
          Number(sale.ReceivedPaymentTransactions[i].ReceiveAmount);
      }
    }

    sale.TransactionDateShort = new Date(sale.TransactionDate.seconds * 1000)
      .toISOString()
      .split("T")[0];
    sale.TransactionDate = new Date(sale.TransactionDate.seconds * 1000)
      .toString()
      .substring(0, 24);
    sale.AmountInWords = this.props.convertNumberToWords(sale.BillAmount);
    // console.log('sale1.AmountInWords', sale1.AmountInWords)
    let billTaxableAmount = 0;
    let lineItemsTmp = [];
    for (let i = 0; i < sale.LineItems.length; i++) {
      lineItemsTmp.push(sale.LineItems[sale.LineItems.length - (i + 1)]);
    }
    sale.LineItems = lineItemsTmp;
    let costPriceTotal = 0;
    let mrpTotal = 0;

    let lineItemsWithOutAdditionalCharges = [];
    let lineItemsWithAdditonalCharges = [];
    let subTotalWithoutAdditionalCharges = 0;

    sale.LineItems.map((lineItem, index) => {
      sale.LineItems[index].SrNo = index + 1;
      sale.LineItems[index].BasePrice = this.roundUpTo2(
        Number(sale.LineItems[index].TaxableAmount) /
          Number(sale.LineItems[index].Qty)
      );
      if (
        lineItem.TaxComponentAmount &&
        lineItem.TaxComponentAmount.length > 0 &&
        lineItem.TaxInclusive
      ) {
        let taxPercentage = 0;
        let taxAmount = 0;
        lineItem.TaxComponentAmount.map((m) => {
          taxPercentage = taxPercentage + Number(m.TaxPercentage);
        });
        taxAmount = this.roundUpTo2(
          (Number(lineItem.RetailPrice) * Number(taxPercentage)) /
            (100 + Number(taxPercentage))
        );
        sale.LineItems[index].CostPrice =
          Number(lineItem.RetailPrice) - Number(taxAmount);
        //sale.LineItems[index].MRP = Number(lineItem.Product.MRP === 0 ? lineItem.RetailPrice :  lineItem.Product.MRP) - Number(taxAmount);
      } else {
        sale.LineItems[index].CostPrice = lineItem.RetailPrice;
      }
      sale.LineItems[index].MRP = Number(
        lineItem.Product.MRP === 0 ? lineItem.RetailPrice : lineItem.Product.MRP
      );

      sale.LineItems[index].CostPriceTotal = this.roundUpTo2(
        Number(sale.LineItems[index].CostPrice) *
          Number(sale.LineItems[index].Qty)
      );
      costPriceTotal =
        costPriceTotal + Number(sale.LineItems[index].CostPriceTotal);

      sale.LineItems[index].MRPTotal = this.roundUpTo2(
        Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty)
      );
      mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal);

      billTaxableAmount = this.roundUpTo2(
        Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount)
      );

      if (
        lineItem.Product.hasOwnProperty("ShowAsAdditionalCharges") &&
        lineItem.Product.ShowAsAdditionalCharges
      ) {
        lineItemsWithAdditonalCharges.push(sale.LineItems[index]);
      } else {
        lineItemsWithOutAdditionalCharges.push(sale.LineItems[index]);
        subTotalWithoutAdditionalCharges =
          Number(subTotalWithoutAdditionalCharges) +
          Number(sale.LineItems[index].Amount);
      }
    });
    sale.BillCostPriceTotal = costPriceTotal;
    sale.BillMRPTotal = mrpTotal;
    sale.MRPSaving = this.roundUpTo2(
      this.roundUpTo2(
        Number(sale.BillMRPTotal) - this.roundUpTo2(Number(sale.BillAmount))
      )
    );

    sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount));
    let printTaxComponents = [];
    let printTaxComponentsWithRate = [];
    sale.LineItems.map((lineItem, index) => {
      //check modifers
      if (lineItem.Modifiers && lineItem.Modifiers.length > 0) {
        let modifiersPrint = [];
        for (let k = 0; k < lineItem.Modifiers.length; k++) {
          let index = -1;
          for (let l = 0; l < modifiersPrint.length; l++) {
            if (
              modifiersPrint[l].ModifierName ===
              lineItem.Modifiers[k].ModifierName
            ) {
              index = l;
              break;
            }
          }
          if (index === -1) {
            modifiersPrint.push({
              key: lineItem.Modifiers[k].key,
              ModifierName: lineItem.Modifiers[k].ModifierName,
              ModifierPrice: lineItem.Modifiers[k].ModifierPrice,
              ModifierGroup: lineItem.Modifiers[k].ModifierGroup,
              ModifierGroupID: lineItem.Modifiers[k].ModifierGroupID,
              ModifierQty: 1,
            });
          } else {
            modifiersPrint[index].ModifierQty =
              modifiersPrint[index].ModifierQty + 1;
            modifiersPrint[index].ModifierPrice =
              modifiersPrint[index].ModifierPrice +
              lineItem.Modifiers[k].ModifierPrice;
          }
        }
        // console.log('modifiersPrint', modifiersPrint)
        lineItem.ModifiersPrint = modifiersPrint;
      }
      if (lineItem.TaxID !== "") {
        lineItem.TaxComponentAmount.map((t) => {
          let indexFound = -1;
          printTaxComponents.map((pt, ptIndex) => {
            if (t.TaxType === pt.TaxType) {
              indexFound = ptIndex;
              return;
            }
          });
          if (indexFound < 0) {
            printTaxComponents.push({
              TaxType: t.TaxType,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
            });
          } else {
            printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(
              Number(printTaxComponents[indexFound].TaxAmount) +
                Number(t.TaxAmount)
            );
          }

          let indexFoundTaxRate = -1;
          printTaxComponentsWithRate.map((pt, ptIndex) => {
            if (
              t.TaxType === pt.TaxType &&
              t.TaxPercentage === pt.TaxPercentage
            ) {
              indexFoundTaxRate = ptIndex;
              return;
            }
          });
          if (indexFoundTaxRate < 0) {
            printTaxComponentsWithRate.push({
              TaxType: t.TaxType,
              TaxPercentage: t.TaxPercentage,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
              TaxableAmount: Number(lineItem.TaxableAmount),
            });
          } else {
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxAmount = this.roundUpTo2(
              Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount) +
                Number(t.TaxAmount)
            );
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxableAmount = this.roundUpTo2(
              Number(
                printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount
              ) + Number(lineItem.TaxableAmount)
            );
          }
        });
      }
    });
    sale.PrintTaxComponents = printTaxComponents;
    sale.PrintTaxComponentsWithRate = printTaxComponentsWithRate;
    sale.LineItems.map((lineItem, index) => {
      for (var i = 0; i < printTaxComponents.length; i++) {
        if (lineItem.TaxComponentAmount.length < i + 1) {
          lineItem.TaxComponentAmount.push({
            TaxName: "",
            TaxAmount: 0,
            TaxPercentage: "",
            TaxType: "",
          });
        }
      }
    });
    let previousBalance = 0;
    if (sale.Customer && sale.Customer.hasOwnProperty("OutStandingAmount")) {
      previousBalance = sale.Customer.OutStandingAmount;
    }
    sale.PreviousBalance = previousBalance;
    sale.TotalBalanceAmount = sale.BillAmount + previousBalance;
    sale.TotalBalanceAfterPaid = sale.TotalBalanceAmount - sale.BillPaidAmount;

    sale.LineItemsWithOutAdditionalCharges = lineItemsWithOutAdditionalCharges;
    sale.LineItemsWithAdditonalCharges = lineItemsWithAdditonalCharges;
    sale.SubTotalWithoutAdditionalCharges = subTotalWithoutAdditionalCharges;
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  onExportClick() {
    this.setState({ exportCalled: true });
    this.props
      .getDeliveryDetailReportInfinityApp(
        this.props.user.user.RegistrationID,
        0,
        10000,
        "all",
        this.state.searchTransactionNo,
        this.state.searchTransactionFromDate,
        this.state.searchTransactionToDate,
        this.state.searchCustomerDetail,
        this.state.searchCashRegister,
        this.state.searchEmployeeName,
        this.state.orderNo
      )
      .then((result) => {
        if (
          isNullOrUndefined(result.searchResult) ||
          result.searchResult.length === 0
        ) {
          this.props.getToastr("No Record Found");
          return;
        }
        let deliveryLists = [];
        result.searchResult.map((data) => {
          let deliveryList = {
            "Delivery Date": data.DeliveryDate,
            "Delivery Time": "",
            "Customer Name":
              data.DeliveryCustomer.FirstName +
              " " +
              data.DeliveryCustomer.LastName,
            "Customer PhoneNo.": !isNullOrUndefined(
              data.DeliveryCustomer.PhoneNo
            )
              ? data.DeliveryCustomer.PhoneNo
              : "",
            "Customer EmailID": !isNullOrUndefined(
              data.DeliveryCustomer.EmailID
            )
              ? data.DeliveryCustomer.EmailID
              : "",
            "Delivery Address": "",
            "Transaction No": data.TransactionNo,
            "Delivery Status": data.DeliveryStatus,
            "Employee Name": !isNullOrUndefined(
              data.DeliveryOutForDeliveryEmployee
            )
              ? data.DeliveryOutForDeliveryEmployee
              : "",
            "Delivery Note": data.DeliveryNotes,
          };

          let deliveryTime =
            data.DeliveryTime.HH != "" && data.DeliveryTime.MM
              ? data.DeliveryTime.HH + ":" + data.DeliveryTime.MM
              : "";
          deliveryList["Delivery Time"] = deliveryTime;
          let address = "";
          // console.log('data', data.DeliveryCustomerAddress)
          if (
            data.hasOwnProperty("DeliveryCustomerAddress") &&
            data.DeliveryCustomerAddress
          ) {
            address = address + data.DeliveryCustomerAddress.Address1 + ", ";
            address = address + data.DeliveryCustomerAddress.Address2 + ", ";
            address = address + data.DeliveryCustomerAddress.City + ", ";
            address = address + data.DeliveryCustomerAddress.State + ", ";
            address = address + data.DeliveryCustomerAddress.PostalCode + ", ";
            address = address + data.DeliveryCustomerAddress.Country;
          }
          deliveryList["Delivery Address"] = address;
          deliveryLists.push(deliveryList);
        });
        const csvData = constatnts.objToCSV(deliveryLists);
        this.setState({ exportCalled: false });
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "deliveryList.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  async updateStatusInfinityApp(sale, status) {
    let order = {
      TransactionNo: sale.ExternalTransactionNo,
      TransactionID: sale.ExternalTransactionID,
      Products: [],
    };
    sale.LineItems.map((l) => {
      order.Products.push({
        ProductID: l.Product.key,
        SKU: l.Product.SKU,
        Status: status,
      });
    });
    console.log("order", order);
    let response = await axios
      .post(
        "https://omniposibleapi-bz4mvd3zea-el.a.run.app/storefront/api/v1/order/status",
        order
      )
      .then((res) => {
        console.log("order/status", JSON.stringify(res.data));
        if (res.data.success) {
          return {
            success: true,
            message: "",
            data: res.data.data,
          };
        } else {
          return {
            success: false,
            message: res.data.message,
            data: null,
          };
        }
      })
      .catch((err) => {
        console.log("order/status", JSON.stringify(err));
        return {
          success: false,
          message: "Error while processing data",
          data: null,
        };
      });

    return response;
  }

  async cancelOrderInfinityApp(order) {
    console.log("order", order);
    let response = await axios
      .post(
        "https://omniposibleapi-bz4mvd3zea-el.a.run.app/storefront/api/v1/order/cancel",
        order
      )
      .then((res) => {
        console.log("order/cancel", JSON.stringify(res.data));
        if (res.data.success) {
          return {
            success: true,
            message: "",
            data: res.data.data,
          };
        } else {
          return {
            success: false,
            message: res.data.message,
            data: null,
          };
        }
      })
      .catch((err) => {
        console.log("order/cancel", JSON.stringify(err));
        return {
          success: false,
          message: "Error while processing data",
          data: null,
        };
      });

    return response;
  }

  async onDeliveryAction(action, sale) {
    console.log("onDeliveryAction start", action);
    this.setState({ loading: true, showMobileRowOptions: false });
    sale.TransactionStatus = action;
    await this.props.updateSalesStatus(sale.key, action);
    let response = await this.updateStatusInfinityApp(sale, action);
    console.log("onDeliveryAction response", response);
    this.props.getToastr("Record saved successfully!!!");
    setTimeout(
      function() {
        this.loadDeliveryDetailReport(
          0,
          this.state.itemsCountPerPage,
          this.state.searchStatus,
          this.state.searchTransactionNo,
          this.state.searchTransactionFromDate,
          this.state.searchTransactionToDate,
          this.state.searchCustomerDetail,
          this.state.searchStore,
          this.state.searchEmployeeName,
          this.state.orderNo
        );
      }.bind(this),
      4000
    );
  }

  renderFilter() {
    return (
      <React.Fragment>
        {this.state.showFilter && (
          <div
            className={"list-page-filter-overlay"}
            onClick={() => this.setState({ showFilter: false })}
          />
        )}

        <div
          className={
            this.state.showFilter
              ? "list-page-filter list-page-filter-show"
              : "list-page-filter"
          }
          // className="list-page-filter"
          style={{ width: isMobileApp() ? "80%" : "460px" }}
        >
          <div
            className="list-page-filter-close-button"
            onClick={() => this.setState({ showFilter: false })}
          >
            <ImageButton
              type="remove"
              onClick={() => this.setState({ showFilter: false })}
            />
          </div>
          <div className="list-page-filter-container">
            <div className="list-page-filter-header">Filter</div>
            <div
              className="list-page-filter-content"
              style={{ height: window.innerHeight - 110 + "px" }}
            >
              <Input
                label="Store"
                type="select"
                onChange={(value) => this.setState({ searchStore: value })}
                value={this.state.searchStore}
              >
                <option value="">All</option>
                {this.state.stores.map((s) => (
                  <option value={s.key}>
                    {s.StoreCode} {s.StoreName}
                  </option>
                ))}
              </Input>
              <Input
                label="Order No"
                type="text"
                onChange={(value) => this.setState({ orderNo: value })}
                value={this.state.orderNo}
              />
              <Input
                label="Search by customer name, email id, contact no"
                type="text"
                onChange={(value) =>
                  this.setState({ searchCustomerDetail: value })
                }
                value={this.state.searchCustomerDetail}
              />
              <Input
                label="Transaction No"
                type="text"
                onChange={(value) =>
                  this.setState({ searchTransactionNo: value })
                }
                value={this.state.searchTransactionNo}
              />
              {(this.state.searchStatus.toLowerCase() === "order delivered" ||
                this.state.searchStatus.toLowerCase() === "cancelled" ||
                this.state.searchStatus.toLowerCase() === "order picked up" ||
                this.state.searchStatus.toLowerCase() === "all") && (
                <React.Fragment>
                  <Input
                    label="Transaction From"
                    type="date"
                    onChange={(value) =>
                      this.setState({ searchTransactionFromDate: value })
                    }
                    value={this.state.searchTransactionFromDate}
                  />
                  <Input
                    label="Transaction To"
                    type="date"
                    onChange={(value) =>
                      this.setState({ searchTransactionToDate: value })
                    }
                    value={this.state.searchTransactionToDate}
                  />
                </React.Fragment>
              )}
            </div>
            <div className="list-page-filter-footer">
              <Button
                type="secondary"
                text="Clear"
                onClick={this.onClearSearch}
              />
              <Button
                type="primary"
                text="Search"
                onClick={this.onSearchClick}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderActionButtons(delivery) {
    let showEditButton = false;
    let showAcceptbutton = false;
    let showPackedButton = false;
    let showCancelButton = false;
    let showOutForDeliveryButton = false;
    let showDeliveredButton = false;

    if (delivery.TransactionStatus === "Created") {
      showAcceptbutton = true;
      showEditButton = true;
      showCancelButton = true;
    } else if (delivery.TransactionStatus === "Accepted") {
      showPackedButton = true;
      showEditButton = true;
      showCancelButton = true;
    } else if (delivery.TransactionStatus === "Packed") {
      showOutForDeliveryButton = true;
      showCancelButton = true;
    } else if (delivery.TransactionStatus === "Out for delivery") {
      showCancelButton = true;
      showDeliveredButton = true;
    }

    return (
      <React.Fragment>
        {showAcceptbutton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat btn-lg"
            onClick={() => this.onDeliveryAction("Accepted", delivery)}
          >
            Accept Order
          </button>
        )}
        {showPackedButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat btn-lg"
            onClick={() => this.onDeliveryAction("Packed", delivery)}
          >
            Packed
          </button>
        )}
        {showOutForDeliveryButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green btn-flat  btn-lg"
            onClick={() => this.onDeliveryAction("Out for delivery", delivery)}
          >
            Out for delivery
          </button>
        )}
        {showDeliveredButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md delivery-btn-green  btn-lg btn-flat"
            onClick={() => this.onDeliveryAction("Delivered", delivery)}
          >
            Delivered
          </button>
        )}
        {showEditButton && (
          <button
            style={{ marginBottom: "10px", width: "100%" }}
            type="button"
            className="btn btn-md btn-default btn-flat  btn-lg"
            onClick={() => this.onShowEditWindow(delivery)}
          >
            Edit
          </button>
        )}
        <button
          style={{ marginBottom: "10px", width: "100%" }}
          type="button"
          className="btn btn-md btn-default btn-lg btn-flat"
          onClick={() => this.onPrintClicked(delivery)}
        >
          Print
        </button>
        {showCancelButton && (
          <button
            type="button"
            className="btn btn-md btn-danger btn-lg btn-flat"
            style={{ marginBottom: "10px", width: "100%" }}
            onClick={() =>
              this.setState({
                showCancel: true,
                selectedRecord: delivery,
                showMobileRowOptions: false,
              })
            }
          >
            Cancel
          </button>
        )}
      </React.Fragment>
    );
  }

  renderStatusFilterText(text, status) {
    let count = 0;
    let statusSummary = this.state.statusSummary;
    if (statusSummary.filter((f) => f.status === status).length > 0) {
      count = statusSummary.filter((f) => f.status === status)[0].count;
    }
    return text + " (" + count + ")";
  }

  renderStatusFilters() {
    return (
      <>
        <button
          type="button"
          style={{
            borderTopLeftRadius: "32px",
            borderBottomLeftRadius: "32px",
          }}
          className={
            this.state.searchStatus === "Created"
              ? "btn button-flat button-border button-lg button-selected  button-left-rounded"
              : "btn button-flat button-border button-lg button-left-rounded"
          }
          onClick={() => this.onStatusChange("Created")}
        >
          {this.renderStatusFilterText("Order received", "Created")}
        </button>
        <button
          type="button"
          className={
            this.state.searchStatus === "Accepted"
              ? "btn button-flat button-border button-lg button-selected "
              : "btn button-flat button-border button-lg"
          }
          onClick={() => this.onStatusChange("Accepted")}
        >
          {/* Accepted */}
          {this.renderStatusFilterText("Accepted", "Accepted")}
        </button>

        <button
          type="button"
          className={
            this.state.searchStatus === "Packed"
              ? "btn button-flat button-border button-lg button-selected "
              : "btn button-flat button-border button-lg "
          }
          onClick={() => this.onStatusChange("Packed")}
        >
          {this.renderStatusFilterText("Packed", "Packed")}
        </button>
        <button
          type="button"
          className={
            this.state.searchStatus === "Out for delivery"
              ? "btn button-flat button-border button-lg button-selected "
              : "btn button-flat button-border button-lg "
          }
          onClick={() => this.onStatusChange("Out for delivery")}
        >
          {this.renderStatusFilterText("Out for delivery", "Out for delivery")}
        </button>
        <button
          type="button"
          className={
            this.state.searchStatus === "Delivered"
              ? "btn button-flat button-border button-lg button-selected "
              : "btn button-flat button-border button-lg "
          }
          onClick={() => this.onStatusChange("Delivered")}
        >
          Delivered
        </button>
        <button
          type="button"
          className={
            this.state.searchStatus === "Cancelled"
              ? "btn button-flat button-border button-lg button-selected "
              : "btn button-flat button-border button-lg "
          }
          onClick={() => this.onStatusChange("Cancelled")}
        >
          Cancelled
        </button>
        <button
          type="button"
          style={{
            borderTopRightRadius: "32px",
            borderBottomRightRadius: "32px",
          }}
          className={
            this.state.searchStatus === ""
              ? "btn button-flat button-border button-lg button-selected "
              : "btn button-flat button-border button-lg"
          }
          onClick={() => this.onStatusChange("")}
        >
          All
        </button>
      </>
    );
  }

  renderQuickActionButtons() {
    return (
      <React.Fragment>
        {this.state.searchStatus === "Created" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("Accepted");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Accept Order
          </button>
        )}

        {this.state.searchStatus === "Accepted" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("Packed");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Packed
          </button>
        )}

        {this.state.searchStatus === "Packed" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            // onClick={()=>{this.onStatusUpdateClick("Out-for-delivery")}}
            onClick={() => {
              this.onStatusUpdateClick("Out for delivery");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Out for delivery
          </button>
        )}

        {this.state.searchStatus === "Out for delivery" && (
          <button
            type="button"
            className="btn btn-default btn-flat btn-lg"
            onClick={() => {
              this.onStatusUpdateClick("Delivered");
            }}
            disabled={
              this.state.deliveryListDetail.filter((f) => f.selected).length ===
              0
            }
          >
            Delivered
          </button>
        )}
      </React.Fragment>
    );
  }

  onQtyChange(value, index) {
    if (value < 0) {
      return;
    }
    let delivery = this.state.selectedDelivery;
    if (value > delivery.LineItems[index].Qty) {
      return;
    }
    delivery.LineItems[index].QtyChange = value;
    this.setState({ selectedDelivery: delivery });
  }

  renderEdit() {
    return (
      <React.Fragment>
        {this.state.showEditWindow && (
          <Window
            header={"Edit"}
            width={"600px"}
            processing={this.state.saving}
            onClose={() => this.setState({ showEditWindow: false })}
            onButtonClick={async (buttonType) => {
              if (buttonType.toLowerCase() === "save") {
                console.log("delivery", this.state.selectedDelivery);
                let allQtyZero = true;
                this.state.selectedDelivery.LineItems.map((l) => {
                  if (
                    l.QtyChange !== "" &&
                    Number(l.QtyChange) > 0 &&
                    l.Product.Name !== "Delivery Charges"
                  ) {
                    allQtyZero = false;
                  }
                });
                if (allQtyZero) {
                  alert("All product quantities can not be zero.");
                  return;
                }
                //now start saving invoice
                this.updateSalesTransaction(this.state.selectedDelivery);
              } else {
                this.setState({ showCancel: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <table className="table table-bordered table-striped delivery-list-table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Product</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {this.state.selectedDelivery.LineItems.map((l, index) => (
                  <tr
                    className={
                      l.Product &&
                      l.Product.Name &&
                      l.Product.Name.toLowerCase() === "delivery charges"
                        ? "deliverylist-tr-noshow"
                        : ""
                    }
                  >
                    <td>{index + 1}</td>
                    <td>{l.Product.Name}</td>
                    <td>
                      <div className="sales-qty-control">
                        <button
                          onClick={(evt) => {
                            let value = Number(l.QtyChange) - 1;
                            this.onQtyChange(value, index);
                          }}
                          type="button"
                          className="btn button-border button-left-rounded small"
                        >
                          <Icon type="minus" />
                        </button>
                        <input
                          type="number"
                          className="sales-qty"
                          onChange={(evt) => {
                            this.onQtyChange(evt.target.value, index);
                          }}
                          value={l.QtyChange}
                        />
                        <button
                          onClick={(evt) => {
                            let value = Number(l.QtyChange) + 1;
                            this.onQtyChange(value, index);
                          }}
                          type="button"
                          className="btn button-border button-right-rounded small"
                        >
                          <Icon type="add" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Window>
        )}
      </React.Fragment>
    );
  }

  returnTotalQty(transaction) {
    let totalQty = 0;
    transaction.LineItems.filter(
      (f) =>
        f.Product &&
        f.Product.hasOwnProperty("Name") &&
        f.Product.Name.toLowerCase() !== "delivery charges"
    ).map((m) => {
      totalQty = totalQty + Number(m.Qty);
    });
    return totalQty;
  }

  showRowCheckBox(delivery) {
    let show = true;
    return show;
  }

  renderCustomerAddress(delivery) {
    let show = true;
    if (
      delivery.DeliveryBy &&
      delivery.DeliveryBy.toLowerCase() === "customer pickup"
    ) {
      show = false;
    }

    if (show) {
      return (
        <React.Fragment>
          <br></br>
          Delivery Address:
          {delivery.DeliveryCustomerAddress && (
            <div>
              <div>{delivery.DeliveryCustomerAddress.Address1}</div>
              <div>
                {delivery.DeliveryCustomerAddress.Address2}
                {delivery.DeliveryCustomerAddress.City}
              </div>
              <div>
                {delivery.DeliveryCustomerAddress.Country}
                {delivery.DeliveryCustomerAddress.PostalCode}
              </div>
              <div>{delivery.DeliveryCustomerAddress.State}</div>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderCancellationText(sale) {
    let cancellationText = "";
    if (sale.hasOwnProperty("CancellationBy") && sale.CancellationBy !== "") {
      cancellationText = sale.CancellationBy + ": ";
    }
    if (sale.CancellationReason && sale.CancellationReason !== "") {
      cancellationText = cancellationText + sale.CancellationReason;
    }
    return cancellationText;
  }

  renderAuditTrail(delivery) {
    return (
      <React.Fragment>
        <div>
          Update on:{" "}
          {delivery.ActionOn
            ? new Date(delivery.ActionOn).toString().substring(0, 24)
            : ""}
        </div>
        <AuditTrail
          {...this.props}
          module={constants.MODULE_SALES}
          parentKey={delivery.key}
        />
      </React.Fragment>
    );
  }

  renderMobileOrderDetails() {
    const delivery = this.state.selectedRow;

    return (
      <React.Fragment>
        {this.state.showMobileOrderDetailWindow && (
          <Window
            header={"Order Details"}
            modal={false}
            onClose={() =>
              this.setState({ showMobileOrderDetailWindow: false })
            }
            showBackGroudColor={true}
            buttons={[]}
          >
            <PageSection title="Transaction Details">
              {!delivery.ONDC && delivery.ExternalTransactionNo && (
                <div className="">
                  {" "}
                  <b>Online Order No:</b> {delivery.ExternalTransactionNo}
                </div>
              )}
              {delivery.ONDC &&
                delivery.ExternalTransactionNoPrefix &&
                delivery.ExternalTransactionNoSufix && (
                  <div className="">
                    {" "}
                    <b>Online Order No:</b>{" "}
                    {delivery.ExternalTransactionNoPrefix}
                    <span className="delivery-online-order-sufix">
                      {delivery.ExternalTransactionNoSufix}
                    </span>
                  </div>
                )}
              <div>
                <b>Transaction No: </b>
                {delivery.TransactionNo}
              </div>
              <div>
                <div className="">
                  <b>Online Channel:</b>{" "}
                  {delivery.RequestSource &&
                  delivery.RequestSource.toLowerCase() === "omni web"
                    ? "online"
                    : delivery.RequestSource}
                </div>
              </div>
              <div>
                <b>Store:</b> {delivery.Store.StoreCode} -{" "}
                {delivery.Store.StoreName}
              </div>
              <br></br>
              <div>
                {delivery.DeliveryNotes !== ""
                  ? "Notes: " + delivery.DeliveryNotes
                  : ""}
              </div>
              {delivery.Notes !== "" && <div>Note:{delivery.Notes}</div>}
              <div className="badge">{delivery.DeliveryStatus}</div>
              {delivery.DeliveryBy &&
                delivery.DeliveryBy.toLowerCase() === "customer pickup" && (
                  <React.Fragment>
                    <div className="badge blue uppercase">
                      {delivery.DeliveryBy}
                    </div>
                    {delivery.DeliveryStatus === "Packed" && (
                      <div>
                        Pickup confirmation code: {delivery.Customer.PhoneNo}
                        <b></b>
                      </div>
                    )}
                  </React.Fragment>
                )}
              <div className="badge red">
                {this.renderCancellationText(delivery)}
              </div>
              {delivery.DeliveryPersonName && (
                <React.Fragment>
                  <br />
                  <br />
                  <div>Delivery Person: </div>
                  <div> {delivery.DeliveryPersonName}</div>
                  <div> {delivery.DeliveryPersonMobileNo}</div>
                </React.Fragment>
              )}
            </PageSection>
            <PageSection title="Customer Details">
              {delivery.DeliveryCustomer && (
                <div className="delivery-list-lg-font">
                  {delivery.DeliveryCustomer.FirstName}
                  {delivery.DeliveryCustomer.LastName}
                </div>
              )}
              {delivery.DeliveryCustomer && (
                <div className="delivery-list-lg-font">
                  {delivery.DeliveryCustomer.PhoneNo}
                </div>
              )}
              {this.renderCustomerAddress(delivery)}
              {delivery.DeliveryCustomerAddress &&
                delivery.DeliveryCustomerAddress.Location && (
                  <React.Fragment>
                    <br></br>
                    <div>Customer Lat, Lon</div>
                    <div>
                      {delivery.DeliveryCustomerAddress.Location.lat},
                      {delivery.DeliveryCustomerAddress.Location.lon}
                    </div>
                    <a
                      // style={{
                      //   marginBottom: "10px",
                      //   width: "100%",
                      // }}
                      // type="button"
                      // className="btn btn-md btn-default btn-lg btn-flat"
                      // className="btn btn-secondary"
                      onClick={() =>
                        this.openGoogleMaps(
                          this.props.user.store.Location.lat,
                          this.props.user.store.Location.lon,
                          delivery.DeliveryCustomerAddress.Location.lat,
                          delivery.DeliveryCustomerAddress.Location.lon
                        )
                      }
                    >
                      Click to open maps
                    </a>
                  </React.Fragment>
                )}
            </PageSection>
            <PageSection title="Order Details">
              <table className="table table-bordered table-striped delivery-list-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {delivery.LineItems.filter(
                    (f) =>
                      f.Product &&
                      f.Product.hasOwnProperty("Name") &&
                      f.Product.Name.toLowerCase() !== "delivery charges" &&
                      f.Product.Name.toLowerCase() !== "packing charges"
                  ).map((m, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {m.Product.SKU}
                        {m.Product.Name}
                        <div>
                          <i>{m.Notes}</i>
                        </div>
                      </td>
                      <td className="align-right">{m.Qty}</td>
                      <td className="align-right">{m.RetailPrice}</td>
                      <td className="align-right">{m.TotalTax}</td>
                      <td className="align-right">{m.Amount}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr></tr>
                  <tr>
                    <td colSpan={5}>Total Qty</td>
                    <td className="align-right">
                      {this.returnTotalQty(delivery)}
                    </td>
                  </tr>
                  {delivery.LineItems.filter(
                    (f) =>
                      f.Product &&
                      f.Product.Name &&
                      f.Product.Name.toLowerCase() === "delivery charges"
                  ).map((m) => (
                    <tr>
                      <td colSpan={5}>Delivery Charges</td>
                      <td className="align-right">{m.RetailPrice}</td>
                    </tr>
                  ))}
                  {delivery.LineItems.filter(
                    (f) =>
                      f.Product &&
                      f.Product.Name &&
                      f.Product.Name.toLowerCase() === "packing charges"
                  ).map((m) => (
                    <tr>
                      <td colSpan={5}>Packing Charges</td>
                      <td className="align-right">{m.RetailPrice}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={5}>Total Tax</td>
                    <td className="align-right">{delivery.BillTaxAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan={5}>Total Amount</td>
                    <td className="align-right">
                      <b>{delivery.BillAmount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      Paid Amount{" "}
                      {delivery.Payments.map((p) => (
                        <React.Fragment>
                          <br />
                          <i>
                            {p.PaymentMode} ({p.Amount})
                          </i>
                        </React.Fragment>
                      ))}
                    </td>
                    <td className="align-right">
                      <b>{delivery.BillPaidAmount}</b>
                    </td>
                  </tr>
                  {delivery.BalanceAmount > 0 && (
                    <tr>
                      <td colSpan={5}>Balance Amount</td>
                      <td className="align-right delivery-balance-amt">
                        <b>{delivery.BalanceAmount}</b>
                      </td>
                    </tr>
                  )}
                </tfoot>
              </table>
            </PageSection>
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderMobileRowOptions() {
    return (
      <React.Fragment>
        {this.state.showMobileRowOptions && (
          <Window
            header={""}
            height={"200px"}
            modal={true}
            onClose={() => this.setState({ showMobileRowOptions: false })}
            buttons={[]}
          >
            {this.renderActionButtons(this.state.selectedRow)}
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderLineItems(delivery) {
    let lineItems = [];
    let orderLineItems = [];
    if (delivery.hasOwnProperty("OrderLineItems")) {
      orderLineItems = delivery.OrderLineItems;
    }
    for (let i = 0; i < delivery.LineItems.length; i++) {
      if (
        orderLineItems.filter(
          (f) => f.ProductID == delivery.LineItems[i].Product.key
        ).length > 0
      ) {
        let orderItem = orderLineItems.filter(
          (f) => f.ProductID == delivery.LineItems[i].Product.key
        )[0];
        if (orderItem.Qty !== delivery.LineItems[i].Qty) {
          delivery.LineItems[i].CancelledQty =
            Number(orderItem.Qty) - Number(delivery.LineItems[i].Qty);
        }
      }
      lineItems.push(delivery.LineItems[i]);
    }
    for (let i = 0; i < orderLineItems.length; i++) {
      if (
        delivery.LineItems.filter(
          (f) => orderLineItems[i].ProductID == f.Product.key
        ).length === 0
      ) {
        lineItems.push({
          Product: {
            Name: orderLineItems[i].ProductName,
            SKU: orderLineItems[i].hasOwnProperty("ProductSKU")
              ? orderLineItems[i].ProductSKU
              : "",
            BarCode: orderLineItems[i].hasOwnProperty("ProductBarCode")
              ? orderLineItems[i].ProductBarCode
              : "",
            Images: orderLineItems[i].hasOwnProperty("Images")
              ? orderLineItems[i].Images
              : [],
          },
          Qty: 0,
          Price: orderLineItems[i].RetailPrice,
          Tax: 0,
          Amount: 0,
          CancelledQty: orderLineItems[i].Qty,
        });
      }
    }

    return (
      <table className="table table-bordered table-striped delivery-list-table">
        <thead>
          <tr>
            <th>#</th>
            <th className="delivery-list-image-th-width"></th>
            <th>Product</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Tax</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {lineItems
            .filter(
              (f) =>
                f.Product &&
                f.Product.hasOwnProperty("Name") &&
                f.Product.Name.toLowerCase() !== "delivery charges" &&
                f.Product.Name.toLowerCase() !== "packing charges"
            )
            .map((m, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{this.renderProductImage(m.Product)}</td>
                <td>
                  <div>{m.Product.Name}</div>
                  {this.props.registration.registration.EnablePincodeSKU && (
                    <div>{m.Product.SKU}</div>
                  )}
                  <div>{m.Product.BarCode}</div>
                  <div>
                    <i>{m.Notes}</i>
                  </div>
                  {m.CancelledQty && m.CancelledQty > 0 && (
                    <div className="badge">Cancelled Qty: {m.CancelledQty}</div>
                  )}
                </td>
                <td className="align-right">{m.Qty}</td>
                <td className="align-right">{m.RetailPrice}</td>
                <td className="align-right">{m.TotalTax}</td>
                <td className="align-right">{m.Amount}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr></tr>
          <tr>
            <td colSpan={6}>Total Qty</td>
            <td className="align-right">{this.returnTotalQty(delivery)}</td>
          </tr>
          {delivery.LineItems.filter(
            (f) =>
              f.Product &&
              f.Product.Name &&
              f.Product.Name.toLowerCase() === "delivery charges"
          ).map((m) => (
            <tr>
              <td colSpan={6}>Delivery Charges</td>
              <td className="align-right">{m.RetailPrice}</td>
            </tr>
          ))}
          {delivery.LineItems.filter(
            (f) =>
              f.Product &&
              f.Product.Name &&
              f.Product.Name.toLowerCase() === "packing charges"
          ).map((m) => (
            <tr>
              <td colSpan={6}>Packing Charges</td>
              <td className="align-right">{m.RetailPrice}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={6}>Total Tax</td>
            <td className="align-right">{delivery.BillTaxAmount}</td>
          </tr>
          <tr>
            <td colSpan={6}>Total Amount</td>
            <td className="align-right">
              <b>{delivery.BillAmount}</b>
            </td>
          </tr>
          <tr>
            <td colSpan={6}>
              Paid Amount{" "}
              {delivery.Payments.map((p) => (
                <React.Fragment>
                  <br />
                  <i>
                    {p.PaymentMode} ({p.Amount})
                  </i>
                </React.Fragment>
              ))}
            </td>
            <td className="align-right">
              <b>{delivery.BillPaidAmount}</b>
            </td>
          </tr>
          {delivery.BalanceAmount > 0 && (
            <tr>
              <td colSpan={6}>Balance Amount</td>
              <td className="align-right delivery-balance-amt">
                <b>{delivery.BalanceAmount}</b>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="list-page-mobile-background"></div>
        <div className="list-page-mobile-header">
          <div className="left-70">
            {/* <ImageButton type={"leftarrow"} label={"back"} onClick={()=>this.props.history.push("/home")}/> */}
            <Menu {...this.props} />
            <div className="list-page-mobile-header-title">Online Order(s)</div>
          </div>
          <div className="right-30">
            <ImageButton
              type="filter"
              label=""
              onClick={() =>
                this.setState({ showFilter: !this.state.showFilter })
              }
            />
          </div>
        </div>
        <div className="delivery-list-mobile-status-filter">
          {this.renderStatusFilters()}
        </div>
        <div className="delivery-list-mobile-page-content">
          {this.state.loading && <Spinner />}
          {this.state.deliveryListDetail.length === 0 && !this.state.loading && (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <RecordNotFound />
            </div>
          )}
          <div
            style={{
              height: window.innerHeight - 155 + "px",
              overflowY: "auto",
            }}
          >
            {this.state.deliveryListDetail.map((delivery, index) => (
              <div className="delivery-list-mobile-row-seperator">
                <div
                  onClick={() =>
                    this.setState({
                      showMobileRowOptions: true,
                      selectedRow: delivery,
                    })
                  }
                >
                  {!delivery.ONDC && delivery.ExternalTransactionNo && (
                    <div className="">
                      {" "}
                      <b>Online Order No:</b> {delivery.ExternalTransactionNo}
                    </div>
                  )}
                  {delivery.ONDC &&
                    delivery.ExternalTransactionNoPrefix &&
                    delivery.ExternalTransactionNoSufix && (
                      <div className="">
                        {" "}
                        <b>Online Order No:</b>{" "}
                        {delivery.ExternalTransactionNoPrefix}
                        <span className="delivery-online-order-sufix">
                          {delivery.ExternalTransactionNoSufix}
                        </span>
                      </div>
                    )}
                  <div>
                    {new Date(delivery.TransactionDate)
                      .toString()
                      .substring(0, 24)}
                  </div>
                  <div>
                    <b>Transaction No: </b>
                    {delivery.TransactionNo}
                  </div>
                  {delivery.DeliveryTime.HH != "" &&
                    delivery.DeliveryTime.MM !== "" && (
                      <span>
                        {delivery.DeliveryTime.HH +
                          ":" +
                          delivery.DeliveryTime.MM}{" "}
                        <br />{" "}
                      </span>
                    )}
                  <br></br>
                  <div>
                    <b>Store:</b> {delivery.Store.StoreCode} -{" "}
                    {delivery.Store.StoreName}
                  </div>
                  <br></br>
                  <div>Customer:</div>
                  {delivery.DeliveryCustomer && (
                    <div>
                      {delivery.DeliveryCustomer.FirstName}
                      {delivery.DeliveryCustomer.LastName}
                    </div>
                  )}
                  {delivery.DeliveryCustomer && (
                    <div>{delivery.DeliveryCustomer.PhoneNo}</div>
                  )}
                  {this.renderCustomerAddress(delivery)}

                  <div className="badge">{delivery.DeliveryStatus}</div>
                  <br />
                  {delivery.DeliveryBy &&
                    delivery.DeliveryBy.toLowerCase() === "shadowfax" && (
                      <React.Fragment>
                        <div className="badge orange-red uppercase">
                          Delivery Partner: {delivery.DeliveryBy}
                        </div>
                      </React.Fragment>
                    )}
                  {delivery.DeliveryBy &&
                    delivery.DeliveryBy.toLowerCase() === "pincode" && (
                      <React.Fragment>
                        <div className="badge orange-red uppercase">
                          Delivery by Pincode
                        </div>
                      </React.Fragment>
                    )}
                  {delivery.DeliveryBy &&
                    delivery.DeliveryBy.toLowerCase() === "pincode" &&
                    delivery.DeliveryStatus === "Packed" && (
                      <div>
                        Pickup confirmation code:{" "}
                        {delivery.ExternalTransactionNoSufix.slice(-4)}
                        <b></b>
                      </div>
                    )}
                  {delivery.DeliveryPartnerOrderID &&
                    delivery.DeliveryPartnerOrderID !== "" && (
                      <div>Delivery ID: {delivery.DeliveryPartnerOrderID}</div>
                    )}
                  {/* <div className='badge red'>{delivery.CancellationReason}</div> */}
                  <div className="badge red">
                    {this.renderCancellationText(delivery)}
                  </div>
                  {delivery.DeliveryPersonName && (
                    <React.Fragment>
                      <br />
                      <br />
                      <div>Delivery Person: </div>
                      <div> {delivery.DeliveryPersonName}</div>
                      <div> {delivery.DeliveryPersonMobileNo}</div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }

  openGoogleMaps(startLat, startLon, endLat, endLon) {
    // Replace these values with your actual start and end coordinates
    // var startLat = 37.7749;  // Example start latitude
    // var startLon = -122.4194;  // Example start longitude
    // var endLat = 34.0522;  // Example end latitude
    // var endLon = -118.2437;  // Example end longitude

    // Create the Google Maps URL with the specified start and end coordinates
    var mapsUrl =
      "https://www.google.com/maps/dir/?api=1&origin=" +
      startLat +
      "," +
      startLon +
      "&destination=" +
      endLat +
      "," +
      endLon;

    // Open the URL in a new browser tab
    window.open(mapsUrl, "_blank");
  }

  renderDesktop() {
    const btnColStyle = { width: "140px" };
    return (
      <React.Fragment>
        {!this.state.print && (
          <div className="header-margin">
            <div className="list-page">
              <div className="list-page-header">
                <div className="left left-20">
                  <div className="list-page-header-title">Online Order(s)</div>
                </div>
                <div className="right right-80">
                  <div
                    className="btn-group pull-right hidden-xs"
                    style={{ paddingRight: "10px" }}
                  >
                    {this.renderStatusFilters()}
                  </div>
                  <ImageButton
                    type="filter"
                    label=""
                    onClick={() =>
                      this.setState({ showFilter: !this.state.showFilter })
                    }
                  />
                </div>
              </div>
              <div
                className="list-page-content"
                style={{
                  height: window.innerHeight - 210 + "px",
                  overflowY: "auto",
                }}
              >
                {/* {this.state.loading && <Loader show={this.state.loading} />} */}
                {this.state.loading && <Spinner hideOverlay={true} />}
                {/* <div className="row">
                  <div className="col-md-12 col-xs-12">
                    {this.renderQuickActionButtons()}
                  </div>
                </div> */}
                <table className="table table-bordered table-striped delivery-list-table">
                  <thead>
                    <tr>
                      {/* <th style={{ width: "20px" }}>
                        <input
                          type="checkbox"
                          checked={this.state.selected}
                          onChange={() => {
                            let selected = !this.state.selected;
                            let deliveryListDetail = this.state
                              .deliveryListDetail;
                            deliveryListDetail.map((m) => {
                              m.selected = selected;
                            });
                            this.setState({
                              selected: selected,
                              deliveryListDetail: deliveryListDetail,
                            });
                          }}
                        />
                      </th> */}
                      <th style={{ width: "10%" }}>Date</th>
                      <th style={{ width: "20%" }}>Customer Details</th>
                      {/* <th style={{ width: "20%" }}>Delivery Address</th> */}
                      <th style={{ width: "50%" }}>Transaction Details</th>
                      <th style={{ width: "10%" }}>Delivery Status</th>
                      <th style={btnColStyle}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.deliveryListDetail.map((delivery, index) => (
                      <tr>
                        {/* <td>
                          {this.showRowCheckBox(delivery) && (
                            <input
                              type="checkbox"
                              checked={delivery.selected}
                              onChange={() => {
                                let deliveryListDetail = this.state
                                  .deliveryListDetail;
                                deliveryListDetail[
                                  index
                                ].selected = !deliveryListDetail[index]
                                  .selected;
                                this.setState({
                                  deliveryListDetail: deliveryListDetail,
                                });
                              }}
                              disabled={() => {}}
                            />
                          )}
                        </td> */}
                        <td>
                          <div>
                            {new Date(delivery.TransactionDate)
                              .toString()
                              .substring(0, 24)}
                          </div>
                          {/* <div>{delivery.TransactionDate}</div><br/> */}
                          {/* {delivery.DeliveryDate}<br/> */}
                          {delivery.DeliveryTime.HH != "" &&
                            delivery.DeliveryTime.MM !== "" && (
                              <span>
                                {delivery.DeliveryTime.HH +
                                  ":" +
                                  delivery.DeliveryTime.MM}{" "}
                                <br />{" "}
                              </span>
                            )}
                        </td>
                        <td>
                          {delivery.DeliveryCustomer && (
                            <div className="">
                              {/* delivery-list-lg-font */}
                              <b>
                                {delivery.DeliveryCustomer.FirstName}
                                {delivery.DeliveryCustomer.LastName}
                              </b>
                            </div>
                          )}
                          {delivery.DeliveryCustomer && (
                            <div className="">
                              {/* delivery-list-lg-font */}
                              <b> {delivery.DeliveryCustomer.PhoneNo}</b>
                            </div>
                          )}
                          {this.renderCustomerAddress(delivery)}

                          {delivery.DeliveryCustomerAddress &&
                            delivery.DeliveryCustomerAddress.Location && (
                              <React.Fragment>
                                <br></br>
                                <div>Customer Lat, Lon</div>
                                <div>
                                  {
                                    delivery.DeliveryCustomerAddress.Location
                                      .lat
                                  }
                                  ,
                                  {
                                    delivery.DeliveryCustomerAddress.Location
                                      .lon
                                  }
                                </div>
                                <a
                                  onClick={() =>
                                    this.openGoogleMaps(
                                      this.props.user.store.Location.lat,
                                      this.props.user.store.Location.lon,
                                      delivery.DeliveryCustomerAddress.Location
                                        .lat,
                                      delivery.DeliveryCustomerAddress.Location
                                        .lon
                                    )
                                  }
                                >
                                  Click to open maps
                                </a>
                              </React.Fragment>
                            )}
                        </td>
                        <td>
                          <div className="">
                            <b>Online Order No:</b>{" "}
                            {delivery.ExternalTransactionNo}
                          </div>

                          <div>
                            <b>Transaction No: </b>
                            {delivery.TransactionNo}
                          </div>
                          <div>
                            <b>Store:</b> {delivery.Store.StoreCode} -{" "}
                            {delivery.Store.StoreName}
                          </div>
                          <div className="deliverylist-showmore">
                            <button
                              className="btn btn-secondary"
                              onClick={() => {
                                let deliveryListDetail = this.state
                                  .deliveryListDetail;
                                deliveryListDetail[
                                  index
                                ].showMore = !deliveryListDetail[index]
                                  .showMore;
                                this.setState({
                                  deliveryListDetail: deliveryListDetail,
                                });
                              }}
                            >
                              {delivery.showMore
                                ? "Hide Details"
                                : "Show Details"}
                            </button>
                          </div>
                          {delivery.showMore && this.renderLineItems(delivery)}
                          <div>
                            {delivery.DeliveryNotes !== ""
                              ? "Notes: " + delivery.DeliveryNotes
                              : ""}
                          </div>
                          {delivery.Notes !== "" && (
                            <div>Note:{delivery.Notes}</div>
                          )}
                        </td>
                        <td>
                          <div>{delivery.DeliveryStatus}</div>
                          {/* <br></br> */}
                          {delivery.DeliveryBy &&
                            delivery.DeliveryBy.toLowerCase() ===
                              "shadowfax" && (
                              <div className="badge orange-red uppercase">
                                Delivery Partner: {delivery.DeliveryBy}
                              </div>
                            )}
                          {delivery.DeliveryPartnerOrderID &&
                            delivery.DeliveryPartnerOrderID !== "" && (
                              <div>
                                Delivery ID: {delivery.DeliveryPartnerOrderID}
                              </div>
                            )}
                          {delivery.DeliveryBy &&
                            delivery.DeliveryBy.toLowerCase() === "pincode" && (
                              <div className="badge orange-red uppercase">
                                Delivery by Pincode
                              </div>
                            )}
                          {delivery.DeliveryBy &&
                            delivery.DeliveryBy.toLowerCase() ===
                              "customer pickup" && (
                              <div className="badge blue uppercase">
                                {delivery.DeliveryBy}
                              </div>
                            )}

                          <div className="badge red">
                            {this.renderCancellationText(delivery)}
                          </div>
                          {delivery.DeliveryPersonName && (
                            <React.Fragment>
                              <br />
                              <br />
                              <div>Delivery Person: </div>
                              <div> {delivery.DeliveryPersonName}</div>
                              <div> {delivery.DeliveryPersonMobileNo}</div>
                            </React.Fragment>
                          )}
                          <br />
                          {this.renderAuditTrail(delivery)}
                        </td>
                        <td>{this.renderActionButtons(delivery)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* } */}
              </div>
              <div className="list-page-footer">
                <div className="left">{this.showPaginationStatus()}</div>
                <div className="right">
                  <div className="list-page-footer-pagination">
                    <ImageButton
                      type={"leftarrowdouble"}
                      label={"First"}
                      onClick={() => this.onFirstClick()}
                    />
                    <ImageButton
                      type={"leftarrow"}
                      label={"Previous"}
                      onClick={() => this.onPreviousClick()}
                    />
                    <Input
                      type="select"
                      value={this.state.itemsCountPerPage}
                      onChange={(value) =>
                        this.onRecordsPerPageCountChange(value)
                      }
                    >
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </Input>
                    <ImageButton
                      type={"rightarrow"}
                      label={"Next"}
                      onClick={() => this.onNextClick()}
                    />
                    <ImageButton
                      type={"rightarrowdouble"}
                      label={"Last"}
                      onClick={() => this.onLastClick()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderProductImage(product) {
    let image =
      product.Images && product.Images.length > 0 ? product.Images[0] : null;
    if (image) {
      return (
        <div>
          <img className="delivery-list-product-image" src={image.ImagePath} />
        </div>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderFilter()}

        {this.renderEdit()}

        {this.state.print && (
          <Print
            {...this.props}
            printChecked={this.state.printChecked}
            sendEmailChecked={this.state.sendEmailChecked}
            sendSMSChecked={false}
            printData={this.state.printData}
            onClose={() => {
              this.setState({ print: false });
            }}
          />
        )}

        {this.state.showCancel && (
          <CancelSales
            {...this.props}
            salesID={this.state.selectedRecord.key}
            onSave={() => {
              this.setState({ loading: true });
              let sale = this.state.selectedRecord;
              let order = {
                TransactionNo: sale.ExternalTransactionNo,
                TransactionID: sale.ExternalTransactionID,
                Products: [],
              };
              sale.LineItems.map((l) => {
                order.Products.push({
                  ProductID: l.Product.key,
                  SKU: l.Product.SKU,
                  CancelledQty: l.Qty,
                });
              });
              this.cancelOrderInfinityApp(order);
              setTimeout(
                function() {
                  //Start the timer
                  this.loadDeliveryDetailReport(
                    this.state.recordFrom,
                    this.state.itemsCountPerPage,
                    this.state.searchStatus,
                    this.state.searchTransactionNo,
                    this.state.searchTransactionFromDate,
                    this.state.searchTransactionToDate,
                    this.state.searchCustomerDetail,
                    this.state.searchStore,
                    this.state.searchEmployeeName,
                    this.state.orderNo
                  );
                }.bind(this),
                2000
              );
              this.setState({ showCancel: false });
            }}
            onClose={() => this.setState({ showCancel: false })}
          />
        )}

        {isMobileApp() && this.renderMobile()}

        {!isMobileApp() && this.renderDesktop()}

        {this.renderMobileRowOptions()}

        {this.renderMobileOrderDetails()}
      </React.Fragment>
    );
  }
}

export default withRouter(OnlineOrdersInfinityApp);
