import React, { Component } from 'react';
import Loader from '../Loader'
// import ScratchCard from 'react-scratchcard';

class CaptureCustomerReferral extends Component {

    constructor() {
        super()
        this.state = {
            referrals:[{
                FullName:'',
                MobileNo:'',
                EmailID:'',
                Relation:'',
                CustomField1:'',
                CustomField2:'',
                CustomField3:'',
                CustomField4:'',
                CustomField5:'',
                CustomField6:'',
                FullNameError:'',
                MobileNoError:'',
                EmailIDError:'',
                RelationError:''
            }],
            saving:false,
            transactionNo:'',
            transactionID:'',
            storeID:'',
            registrationID:'',
            customerID:'',
            submissionCompleted:false,
            customerNameDisplay:true,
            customerPhoneNoDisplay:true,
            customerEmailIDDisplay:true,
            customerCustomField1Display: false,
            customerCustomField2Display: false,
            customerCustomField3Display: false,
            customerCustomField4Display: false,
            customerCustomField5Display: false,
            customerCustomField6Display: false,
            headerImage: null,
            footerImage: null,
            headerText: '',
            footerText: '',
            submissionText:'',
            headerBackgroundColor:'',
            footerBackgroundColor:'',
            headerImageAlign:'center',
            headerImageHeight:'',
            headerImageWidth:'',
            footerImageAlign:'center',
            footerImageHeight:'',
            footerImageWidth:'',
            loadingData:true
            
        }
        this.onFullNameChange = this.onFullNameChange.bind(this)
        this.onMobileNoChange = this.onMobileNoChange.bind(this)
        this.onEmailIDChange = this.onEmailIDChange.bind(this)
        this.onAddMore = this.onAddMore.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onRemove = this.onRemove.bind(this)
        this.onRelationChange = this.onRelationChange.bind(this)
    }

    async componentDidMount()
    {
        console.log('this.props.match.params.key',this.props.match.params.key)
        let key= this.props.match.params.key
        let sale = null
        if(key.trim()!=="")
        {
            sale = await this.props.apiGetSaleDetails(key)
            console.log('sale',sale)
            if(sale)
            {
                let customerReferralProgram = await this.props.apiGetCustomerReferralProgram(sale.RegistrationID)
                console.log('customerReferralProgram',customerReferralProgram)
                if(customerReferralProgram)
                {
                    this.setState({
                        key:customerReferralProgram.key,
                        headerImage: customerReferralProgram.HeaderImage,
                        footerImage: customerReferralProgram.FooterImage,
                        headerText:  customerReferralProgram.HeaderText,
                        footerText: customerReferralProgram.FooterText,
                        submissionText: customerReferralProgram.hasOwnProperty('SubmissionText') ? customerReferralProgram.SubmissionText  :'',
                        customerCustomField1Display: customerReferralProgram.CustomerCustomField1Display,
                        customerCustomField2Display: customerReferralProgram.CustomerCustomField2Display,
                        customerCustomField3Display: customerReferralProgram.CustomerCustomField3Display,
                        customerCustomField4Display: customerReferralProgram.CustomerCustomField4Display,
                        customerCustomField5Display: customerReferralProgram.CustomerCustomField5Display,
                        customerCustomField6Display: customerReferralProgram.CustomerCustomField6Display,
                        customerNameDisplay:customerReferralProgram.CustomerNameDisplay,
                        customerPhoneNoDisplay:customerReferralProgram.CustomerPhoneNoDisplay,
                        customerEmailIDDisplay:customerReferralProgram.CustomerEmailIDDisplay,
                        headerBackgroundColor:customerReferralProgram.HeaderBackgroundColor,
                        headerImageAlign:customerReferralProgram.hasOwnProperty('HeaderImageAlign') ? customerReferralProgram.HeaderImageAlign : this.state.headerImageAlign,
                        headerImageHeight:customerReferralProgram.hasOwnProperty('HeaderImageHeight') ? customerReferralProgram.HeaderImageHeight : this.state.headerImageHeight,
                        headerImageWidth:customerReferralProgram.hasOwnProperty('HeaderImageWidth') ? customerReferralProgram.HeaderImageWidth : this.state.headerImageWidth,
                        footerImageAlign:customerReferralProgram.hasOwnProperty('FooterImageAlign') ? customerReferralProgram.FooterImageAlign : this.state.footerImageAlign,
                        footerImageHeight:customerReferralProgram.hasOwnProperty('FooterImageHeight') ? customerReferralProgram.FooterImageHeight : this.state.footerImageHeight,
                        footerImageWidth:customerReferralProgram.hasOwnProperty('FooterImageWidth') ? customerReferralProgram.FooterImageWidth : this.state.footerImageWidth,
                        loadingData:false,
                        transactionNo:sale.TransactionNo,
                        transactionID:key,
                        storeID:sale.Store.key,
                        registrationID:sale.RegistrationID,
                        customerID:sale.Customer ? sale.Customer.key :''
                        })
                }
            }
        }

        
    }

    onFullNameChange(index, value)
    {
        let referrals = this.state.referrals
        referrals[index].FullName = value.trim()
        referrals[index].FullNameError= ""
        this.setState({referrals:referrals})
    }

    onRelationChange(index, value)
    {
        let referrals = this.state.referrals
        referrals[index].Relation = value.trim()
        referrals[index].RelationError= ""
        this.setState({referrals:referrals})
    }

    onMobileNoChange(index, value)
    {
        let referrals = this.state.referrals
        referrals[index].MobileNo = value.trim()
        referrals[index].MobileNoError= ""
        this.setState({referrals:referrals})
    }

    onEmailIDChange(index, value)
    {
        let referrals = this.state.referrals
        referrals[index].EmailID = value.trim()
        referrals[index].EmailIDError= ""
        this.setState({referrals:referrals})
    }
    onAddMore()
    {
        let referrals = this.state.referrals
        referrals.push({ FullName:'',
        MobileNo:'',
        EmailID:'',
        Relation:'',
        CustomField1:'',
        CustomField2:'',
        CustomField3:'',
        CustomField4:'',
        CustomField5:'',
        CustomField6:'',
        FullNameError:'',
        MobileNoError:'',
        EmailIDError:'',
        RelationError:''})
        this.setState({referrals:referrals})
    }

    onRemove(index){
        let referrals = this.state.referrals
        referrals.splice(index,1)
        this.setState({referrals:referrals})
    }

    async onSave()
    {
        this.setState({saving:true})
        let errors = false
        let referrals = this.state.referrals
        referrals.map((m,index)=>{
            if(m.FullName.trim()==="")
            {
                m.FullNameError = "Please provide name"
                errors =true
            }
            if(m.MobileNo.trim()==="")
            {
                m.MobileNoError = "Please provide mobile no"
                errors =true
            }
            if(m.Relation.trim()==="")
            {
                m.RelationError = "Please specify relation"
                errors =true
            }
        })
        if(errors)
        {
            this.setState({referrals:referrals})
            return true
        }
        await this.props.saveReferrals(this.state.referrals, this.state.customerID,
        this.state.transactionID, this.state.transactionNo, this.state.storeID, this.state.registrationID)
        console.log('saving')
        this.setState({submissionCompleted:true, saving:false})
    }

    render() {

        const settings = {
            width: 327,
            height: 205,
            image: './scratch.png',
            finishPercent: 50,
            onComplete: () => console.log('The card is now clear!')
          };

          
        return <React.Fragment> 
            {this.state.loadingData && <div style={{width:"100%",textAlign:"center", verticalAlign:"middle", height:"800px",paddingTop:"100px"}}>
            <Loader show={this.state.loading} />
                </div>}
        {!this.state.loadingData && <div>
        <div className="row" style={{backgroundColor:this.state.headerBackgroundColor}}>
            {this.state.headerImage &&  <div className="col-md-12 col-sm-12" style={{textAlign:this.state.headerImageAlign,marginTop:"20px", marginBottom:"20px"}} >
                <img src={this.state.headerImage.Preview} width={this.state.headerImageWidth !=="" ? this.state.headerImageWidth + "px": ""} 
                height={this.state.headerImageHeight !=="" ? this.state.headerImageHeight + "px" : ""}/>
            </div>}
            <div className="col-md-12 col-sm-12" style={{marginTop:"20px", marginBottom:"20px"}}>
                <div dangerouslySetInnerHTML={{__html: this.state.headerText}} />
                {/* </div> */}
                {/* {this.state.headerText} */}
            </div>
        </div>
        {!this.state.submissionCompleted && <section className="content" >
        {this.state.referrals.map((m, index)=>  <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Referral</h3>
                    </div>
                    <div className="box-body"><div className="row">
            <div className="col-md-12 col-sm-12">
                {this.state.customerNameDisplay && <div className="col-md-4 col-md-12" >
                        <div className={m.FullNameError !=="" ? "form-group has-error" : "form-group "} >
                            <label>Full Name</label>
                            <input type="text" className="form-control"
                                onChange={(evt) => this.onFullNameChange(index, evt.target.value)}
                                value={m.FullName}
                                placeholder="" />
                            {this.state.FullNameError !=="" && <span className="help-block">{this.state.FullNameError}</span>}
                        </div>
                    </div>}
                    {this.state.customerPhoneNoDisplay && <div className="col-md-4 col-md-12">
                        <div className={m.MobileNoError !=="" ? "form-group has-error" : "form-group "} >
                            <label>Mobile No</label>
                            <input type="text" className="form-control"
                                onChange={(evt) => this.onMobileNoChange(index,evt.target.value)}
                                value={m.MobileNo}
                                placeholder="" />
                            {this.state.MobileNoError !=="" && <span className="help-block">{this.state.MobileNoError}</span>}
                        </div>
                    </div>}
                    {this.state.customerEmailIDDisplay && <div className="col-md-4 col-md-12">
                        <div className={m.EmailIDError !=="" ? "form-group has-error" : "form-group "} >
                            <label>Email ID</label>
                            <input type="text" className="form-control"
                                onChange={(evt) => this.onEmailIDChange(index,evt.target.value)}
                                value={m.EmailID}
                                placeholder="" />
                                 {this.state.EmailIDError !=="" && <span className="help-block">{this.state.EmailIDError}</span>}
                        </div>
                    </div>}
                     <div className="col-md-4 col-md-12">
                        <div className={m.RelationError !=="" ? "form-group has-error" : "form-group "} >
                        <label >Relation</label>
                                <select className="form-control" value={m.Relation} onChange={(evt) => this.onRelationChange(index, evt.target.value)}>
                                        <option >Select relation</option>
                                        <option >Mother</option>
                                        <option >Father</option>
                                        <option >Wife</option>
                                        <option >Husband</option>
                                        <option >Son</option>
                                        <option >Daughter</option>
                                        <option >Friend</option>
                                        <option >Other</option>
                                    </select>
                                    {this.state.RelationError !=="" && <span className="help-block">{this.state.RelationError}</span>}
                        </div>
                    </div>
                    {index> 0 && <div className="col-md-4 col-md-12">
                        <button type="button" className="btn btn-default btn-md btn-block btn-flat"
                                onClick={() => this.onRemove(index)}>
                                <i className="glyphicon glyphicon-remove"></i>Remove</button>
                    </div>}
                    <div>
                    {/* <ScratchCard {...settings}>
                    Congratulations! You WON!
                </ScratchCard> */}
                    </div>
                </div>
                </div>
                </div>
        </div>)}
            <div className="row" style={{padding:"10px",paddingTop:"0px"}}>
                <button type="button" className="btn btn-default btn-md btn-block btn-flat"
                    disabled={this.state.saving}
                                onClick={() => this.onAddMore()}>
                                <i className="glyphicon glyphicon-plus"></i> Add More</button>
             </div>
             <div className="row" style={{padding:"10px"}}>
                <button type="button" className="btn btn-success btn-md btn-block btn-flat"
                    disabled={this.state.saving}
                                    onClick={() => this.onSave()}>
                                    Submit</button>
            </div>
        </section>}
        {this.state.submissionCompleted &&  <div className="row" >
        <div className="col-md-12 col-sm-12" style={{marginTop:"20px", marginBottom:"20px"}}>
                <div dangerouslySetInnerHTML={{__html: this.state.submissionText}} />
                {/* <ScratchCard {...settings}>
                    Congratulations! You WON!
                </ScratchCard> */}
            </div>
        </div>}
        
        {/* </div> */}
        
        {/* </div> */}
        {/* <div className="row" style={{backgroundColor:this.state.footerBackgroundColor}}>
            {this.state.footerImage &&  <div className="col-md-12 col-sm-12" >
                <img src={this.state.footerImage.Preview}/>
            </div>}
            <div className="col-md-12 col-sm-12">
                {this.state.footeText}
            </div>
        </div> */}
        </div>}
        </React.Fragment>
    }
}

export default CaptureCustomerReferral