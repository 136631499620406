import firebase, { database } from "../../firebase";
import * as constants from "../../Constants";

export async function saveSalesChannel(key, salesChannel, user) {
  salesChannel.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
  salesChannel.ActionBy = user.Name;
  salesChannel.ActionByUID = user.UID;
  salesChannel.ActionByEmailID = user.EmailID;

  console.log("key", key);
  console.log("salesChannel", salesChannel);
  if (key.trim() !== "") {
    // salesChannel.Action = constants.AUDIT_TRAIL_EDIT;
    let tmp = await database
      .collection("SalesChannels")
      .doc(key)
      .update({
        ...salesChannel,
      })
      .then(() => {
        return key;
      })
      .catch(function(error) {
        console.error("Error updating sales channel: ", error);
      });
  } else {
    salesChannel.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("SalesChannels")
      .add({
        ...salesChannel,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding sales channel: ", error);
      });
  }
}

// export async function getSalesChannel(registrationID) {
//   return database
//     .collection("SalesChannels")
//     .where("RegistrationID", "==", registrationID)
//     .get()
//     .then((querySnapshot) => {
//       const salesChannels = [];
//       querySnapshot.forEach((doc) => {
//         if (!doc.data().IsDeleted) {
//           salesChannels.push({
//             key: doc.id,
//             ...doc.data(),
//           });
//         }
//       });
//       console.log("salesChannels", salesChannels);
//       return salesChannels;
//     })
//     .catch((error) => {
//       console.log("Error getting salesChannels list: ", error);
//     });
// }

export function deleteSalesChannel(key, user) {
  return database
    .collection("SalesChannels")
    .doc(key)
    .update({
      IsDeleted: true,
      ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
      ActionBy: user.Name,
      ActionByUID: user.UID,
      ActionByEmailID: user.EmailID,
      Action: constants.AUDIT_TRAIL_DELETE,
    })
    .then(() => {
      return true;
    });
}
