import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import * as constants from '../../Constatnts'

import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'
import Avatar from '../Common/Avatar'

import Spinner from '../Common/Spinner'

class CampaignToRunCustomers extends Component {
    constructor() {
        super();
        this.state = {
            campaigns: [],
            loading: true
        }
        this.loadCampaignToRunCustomers = this.loadCampaignToRunCustomers.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {
        this.loadCampaignToRunCustomers();
    }

    loadCampaignToRunCustomers() {
        this.props.getCampaignToRunCustomers(this.props.location.state.CampaignToRunID).then((campaigns) => {
            this.setState({ campaigns: campaigns, loading: false })
        })
    }

    onExportClick() {
        let data = [];
        this.state.campaigns.map((campaign) => {
            let obj = {
                "Code": campaign.CustomerCode
                , "Name": campaign.CustomerName
                , "Email ID": campaign.EmailID
                , "Phone No": campaign.PhoneNo
                , "Gender": campaign.Gender
                , "Membership": campaign.Membership
                , "Last Visited On": campaign.LastVisitedOn
                , "Outstanding Amount": campaign.OutStandingAmount
                , "Total Purchases": campaign.TotalPurchases
                , "Wallet Balance": campaign.WalletBalance
                , "Bill No": campaign.hasOwnProperty('TransactionNo') ? campaign.TransactionNo : ''
                , "Bill Date": campaign.hasOwnProperty('TransactionDate') ? campaign.TransactionDate : ''
                , "Bill Amount": campaign.hasOwnProperty('BillAmount') ? campaign.BillAmount : ''
                //, "Status": campaign.Status
                , "Last Run Date": campaign.LastRunDate
            }
            data.push(obj);
        })

        const csvData = constants.objToCSV(data);
        this.setState({ exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "Campaign Customers.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        return <React.Fragment>
            {this.props.isMobileApp() && <HeaderNew {...this.props}
                back={true}
                title={"Campaign Customers"}
                onBackClick={() => { this.props.history.push({ pathname: '/home/m/campaign' }) }}
            />}
            <div className="content-wrapper" >
                {!this.props.isMobileApp() && <section className="content" >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box ">
                                {!this.props.isMobileApp() && <div className="box-header with-border">
                                    <div className="row">
                                        <div className="col-md-6 col-xs-9">
                                            <font style={{ fontSize: "30px" }}>Campaign Customers</font>
                                        </div>
                                        <div className="col-md-6 col-xs-3">

                                            <button type="cancel" className="btn btn-default pull-right btn-flat"
                                                style={{ marginTop: "5px", marginLeft: "5px" }}
                                                onClick={() => this.props.history.push({ pathname: "/home/crm/campaignreport" })}
                                            ><i className="fa fa-arrow-left"></i> Back</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right"
                                                style={{ marginTop: "5px" }}
                                                onClick={this.onExportClick}
                                            > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.loading && <Spinner />}
                                <div className="box-body">
                                    {!this.state.loading &&
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Code</th>
                                                    <th>Name</th>
                                                    <th>Email ID</th>
                                                    <th>Phone No</th>
                                                    <th>Gender</th>
                                                    <th>Membership</th>
                                                    <th>Last Visited On</th>
                                                    <th>Outstanding Amount</th>
                                                    <th>Total Purchase</th>
                                                    <th>Wallet Balance</th>
                                                    <th>Bill No</th>
                                                    <th>Bill Date</th>
                                                    <th>Bill Amount</th>
                                                    {/* <th>Status</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.campaigns.map((campaign, index) =>
                                                    <tr>
                                                        <td>{campaign.CustomerCode}</td>
                                                        <td>{campaign.CustomerName}</td>
                                                        <td>{campaign.EmailID}</td>
                                                        <td>{campaign.PhoneNo}</td>
                                                        <td>{campaign.Gender}</td>
                                                        <td>{campaign.Membership}</td>
                                                        <td>{campaign.LastVisitedOn}</td>
                                                        <td>{campaign.OutStandingAmount}</td>
                                                        <td>{campaign.TotalPurchases}</td>
                                                        <td>{campaign.WalletBalance}</td>

                                                        <td>{campaign.hasOwnProperty('TransactionNo') ? campaign.TransactionNo : ''}</td>
                                                        <td>{campaign.hasOwnProperty('TransactionDate') ? campaign.TransactionDate : ''}</td>
                                                        <td>{campaign.hasOwnProperty('BillAmount') ? campaign.BillAmount : ''}</td>

                                                        {/* <td>{campaign.Status}</td> */}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </section>}
                {this.props.isMobileApp() && <div className="container">
                    {this.state.loading && <Spinner />}
                    {this.props.isMobileApp() && <div className="list" style={{paddingTop: "8px"}}>
                        {this.state.campaigns.map((customer) => <div className="list-row seperator">
                            <Avatar text={customer.CustomerName} />
                            <div>
                                <div className="row-title">{customer.CustomerName}</div>
                                <div className="row-sub-text">{customer.PhoneNo}</div>
                                <div className="row-sub-text">{customer.EmailID}</div>
                                {(customer.OutStandingAmount > 0) && <span className="outstanding pull-left">O/S: {customer.OutStandingAmount}</span>}
                                {(customer.WalletBalance > 0) && <span className="wallet pull-left">Wallet: {customer.WalletBalance}</span>}
                                {(customer.Membership && customer.Membership != "") && <span className="membership pull-left"> {customer.Membership}</span>}
                            </div>
                        </div>)}
                    </div>}
                </div>}                  
                {this.props.isMobileApp() && <Footer buttonText="Export"
                    onClick={() => this.onExportClick()}
                />}
            </div>
        </React.Fragment>
    }
}

export default withRouter(CampaignToRunCustomers)