import React, { Component } from "react";
import Spinner from "../Common/Spinner";
import { isNullOrUndefined } from "util";
import ImageButton from "../2/Controls/ImageButton";
import { isMobileApp } from "../../actions/Util";
import {
  getPincodePopularProducts,
  getMerchantProductsThatArePartOfPopularProducts,
} from "../../services/api/PincodeAPI";
import "./MissingPopularItems.css";
import Input from "../2/Controls/Input";
import Button from "../2/Controls/Button";

class MissingPopularItems extends Component {
  constructor() {
    super();
    this.state = {
      popularproducts: [],
      missingPopularProducts: [],
      popularProductsThatExists: [],
      missingPopularProductsList: [],
      categories: [],
      subCategories: [],
      brands: [],
      selectedCategory: "",
      selectedBrand: "",

      searchText: "",
      itemsCountPerPage: 50,
      searchProduct: "",
      searchProductType: "",
      searchCategory: "",
      categoryID: "",
      searchSubCategory: "",
      subCategoryID: "",
      products: [],
      outOfStock: false,
      recordFrom: 0,
      totalItemsCount: 0,
      activePage: 1,
      selectedBrandTA: [],
      selectedCategoryTA: [],
    };
    this.loadList = this.loadList.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onFirstClick = this.onFirstClick.bind(this);
    this.onLastClick = this.onLastClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onSaveProduct = this.onSaveProduct.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    //first get popular products
    let popularproducts = await getPincodePopularProducts();
    // console.log("popularproducts", popularproducts);

    let codes = [];
    popularproducts.map((m) => codes.push(m.Code));
    // console.log("codes", codes);

    ///now get merchat products that already exists
    let popularProductsThatExists = await getMerchantProductsThatArePartOfPopularProducts(
      this.props.registration.registration.key,
      codes
    );
    // console.log("popularProductsThatExists", popularProductsThatExists);

    let missingPopularProducts = [];
    if (popularProductsThatExists && popularProductsThatExists.length === 0) {
      missingPopularProducts = popularproducts;
    } else {
      popularproducts.map((p) => {
        if (
          popularProductsThatExists.filter((f) => f.SKU === p.Code).length === 0
        ) {
          missingPopularProducts.push(p);
        }
      });
    }

    let categories = [];
    let subcategories = [];
    let brands = [];

    missingPopularProducts.map((m) => {
      if (categories.filter((f) => f.Name === m.Category).length === 0) {
        categories.push({ Name: m.Category });
      }
      if (brands.filter((f) => f.Name === m.Brand).length === 0) {
        brands.push({ Name: m.Brand });
      }
    });
    this.loadList(1, 50, missingPopularProducts, "", "");
    this.setState({
      categories: categories,
      brands: brands,
      popularproducts: popularproducts,
      popularProductsThatExists: popularProductsThatExists,
      missingPopularProducts: missingPopularProducts,
      loading: false,
    });
  }

  loadList(
    page,
    itemsCountPerPage,
    missingPopularProducts,
    selectedCategory,
    selectedBrand
  ) {
    let missingPopularProductsList = [];
    let start = (Number(page) - 1) * Number(itemsCountPerPage);
    let end = Number(page) * Number(itemsCountPerPage);

    let totalItemsCount = 0;

    let missingPopularProductsFiltered = [];

    missingPopularProductsFiltered = missingPopularProducts.filter((f) => {
      let show = true;
      if (selectedBrand.trim() !== "" && f.Brand !== selectedBrand.trim()) {
        show = false;
      }
      if (
        selectedCategory.trim() !== "" &&
        f.Category !== selectedCategory.trim()
      ) {
        show = false;
      }
      return show;
    });

    if (missingPopularProductsFiltered.length < end) {
      end = missingPopularProductsFiltered.length;
    }

    for (let i = start; i < end; i++) {
      missingPopularProductsList.push(missingPopularProductsFiltered[i]);
    }
    totalItemsCount = missingPopularProductsFiltered.length;

    this.setState({
      missingPopularProductsList: missingPopularProductsList,
      activePage: page,
      itemsCountPerPage: itemsCountPerPage,
      recordFrom: start,
      selectedCategory: selectedCategory,
      selectedBrand: selectedBrand,
      totalItemsCount: totalItemsCount,
    });
  }

  showPaginationStatus() {
    let paginationStatus;
    if (this.state.totalItemsCount === 0) {
      paginationStatus = "No record(s) found";
    } else if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    return (
      <div
        className={
          isMobileApp() ? "list-page-mobile-pagination" : "list-page-pagination"
        }
      >
        {paginationStatus}
      </div>
    );
  }

  onFirstClick() {
    this.loadList(
      1,
      50,
      this.state.missingPopularProducts,
      this.state.selectedCategory,
      this.state.selectedBrand
    );
  }

  onPreviousClick() {
    if (this.state.activePage > 1) {
      this.loadList(
        Number(this.state.activePage) - 1,
        50,
        this.state.missingPopularProducts,
        this.state.selectedCategory,
        this.state.selectedBrand
      );
    }
  }

  onNextClick() {
    let pageNo = Number(this.state.activePage);
    let recordFrom = pageNo * this.state.itemsCountPerPage;
    if (recordFrom < this.state.totalItemsCount) {
      this.loadList(
        Number(this.state.activePage) + 1,
        50,
        this.state.missingPopularProducts,
        this.state.selectedCategory,
        this.state.selectedBrand
      );
    }
  }

  onLastClick() {
    let pageNo = Number(
      Number(
        Number(this.state.totalItemsCount) /
          Number(this.state.itemsCountPerPage)
      ).toFixed(0)
    );

    this.loadList(
      pageNo,
      50,
      this.state.missingPopularProducts,
      this.state.selectedCategory,
      this.state.selectedBrand
    );
  }

  onCategoryChange = (value) => {
    let selectedCategoryTA = value;
    let selectedCategory = value && value.length > 0 ? value[0].Name : "";
    this.setState({
      selectedCategoryTA: selectedCategoryTA,
      selectedCategory: selectedCategory,
    });
    this.loadList(
      1,
      50,
      this.state.missingPopularProducts,
      selectedCategory,
      this.state.selectedBrand
    );
  };

  onBrandChange = (value) => {
    let selectedBrandTA = value;
    let selectedBrand = value && value.length > 0 ? value[0].Name : "";
    this.setState({
      selectedBrandTA: selectedBrandTA,
      selectedBrand: selectedBrand,
    });
    this.loadList(
      1,
      50,
      this.state.missingPopularProducts,
      this.state.selectedCategory,
      selectedBrand
    );
  };

  onSearchClick() {}

  async onSaveProduct(product, index) {
    let retailPriceError = "";
    let mrpError = "";
    if (!product.RetailPriceNew) {
      retailPriceError = "Please provide retail price";
    }
    if (product.RetailPriceNew && product.RetailPriceNew.trim() === "") {
      retailPriceError = "Please provide retail price";
    }
    if (!product.MRPNew) {
      mrpError = "Please provide MRP";
    }
    if (product.MRPNew && product.MRPNew.trim() === "") {
      mrpError = "Please provide MRP";
    }
    if (
      product.RetailPriceNew &&
      product.MRPNew &&
      product.RetailPriceNew.trim() !== "" &&
      product.MRPNew.trim() !== "" &&
      Number(product.RetailPriceNew) > Number(product.MRPNew)
    ) {
      retailPriceError = "Retail price cannot be more than MRP";
    }
    // console.log("retailPriceError", retailPriceError);
    // console.log("mrpError", mrpError);

    let missingPopularProductsList = this.state.missingPopularProductsList;
    if (retailPriceError !== "" || mrpError !== "") {
      missingPopularProductsList[index].RetailPriceError = retailPriceError;
      missingPopularProductsList[index].MRPNewError = mrpError;
      this.setState({
        missingPopularProductsList: missingPopularProductsList,
      });
      return;
    }

    missingPopularProductsList[index].Saving = true;
    this.setState({ missingPopularProductsList: missingPopularProductsList });

    let storeSpecificPrices = [];
    // let stores = this.props.config.Stores;
    // stores.map((s) => {
    //   storeSpecificPrices.push({
    //     DiscountAmount: 0,
    //     DiscountPercentage: 0,
    //     EditWalletTopUpAmount: false,
    //     MRP: product.MRPNew,
    //     MRPError: "",
    //     MSP: "",
    //     Markup: "",
    //     PriceError: "",
    //     RetailPrice: product.RetailPriceNew,
    //     Store: s.StoreCode + " " + s.StoreName,
    //     StoreID: s.key,
    //     SupplyPrice: 0,
    //     WalletTopUpAmount: 0,
    //     WalletTopUpSameAsRetailPrice: false,
    //     OMNIEnabled: true,
    //     OutOfStock: false,
    //   });
    // });
    let s = this.props.user.store;
    storeSpecificPrices.push({
      DiscountAmount: 0,
      DiscountPercentage: 0,
      EditWalletTopUpAmount: false,
      MRP: product.MRPNew,
      MRPError: "",
      MSP: "",
      Markup: "",
      PriceError: "",
      RetailPrice: product.RetailPriceNew,
      Store: s.StoreCode + " " + s.StoreName,
      StoreID: s.key,
      SupplyPrice: 0,
      WalletTopUpAmount: 0,
      WalletTopUpSameAsRetailPrice: false,
      OMNIEnabled: true,
      OutOfStock: false,
    });

    let prod = {
      RegistrationID: this.props.user.user.RegistrationID,
      Name: product.Name + " " + product.Quantity + " " + product.UOM,
      ProductTypeID: 1,
      ProductType: "Goods for sale",
      Description: "",
      SKU: product.Code,
      BarCode: product.Code,
      Category: product.Category,
      CategoryID: product.Category,
      SubCategoryID: product.Subcategory,
      SubCategory: product.Subcategory,
      BrandID: product.Brand,
      Brand: product.Brand,
      SupplyPrice: "",
      Markup: "",
      RetailPrice: product.RetailPriceNew,
      MRP: product.MRPNew,
      MSP: "",
      WalletTopUpSameAsRetailPrice: false,
      WalletTopUpAmount: "",
      AllowDiscount: true,
      AllowPriceEdit: true,
      AllowTaxChange: true,
      ChargeTaxOnProduct: false,
      TaxInclusive: false,
      TaxInclusivePurchase: false,
      HSNSACCode: "",
      TaxID: "",
      TaxGroup: "",
      PriceBasedTax: false,
      TaxPriceRange: [],
      Images: [],
      IsDeleted: false,
      EditWalletTopUpAmount: false,
      AccessibleToSelectedStores: false,
      StoreSpecificPricing: false,
      StoreSpecificPrices: storeSpecificPrices,
      DiscountAmount: "",
      DiscountPercentage: "",
      MinQty: 0,
      MaxQty: 0,
      ReorderQty: "",
      UniqueLabel: "",
      InventoryType: "FIFO",
      UOM: "",
      SearchMode: "",
      RepackProduce: false,
      //Package
      PackageServices: [],
      ValidityInDays: 0,
      QtyPrompt: false,
      Membership: "",
      MembershipID: "",
      AssociateMembership: false,
      StoreSpecificQtys: [],
      EnableAlerts: false,
      NotifyCustomerInDays: 0,
      SMSTemplateID: "",
      CustomField1: product.Code, //global product code
      CustomField2: "", //status
      CustomField3: "", //out of stock
      CustomField4: "",
      CustomField5: "",
      CustomField6: "",
      Favourite: false,
      ServiceDuration: "",
      BOM: false,
      BOMManual: false,
      CanBeUsedAsConsumable: false,
      BatchTrackingBatchNoRequired: false,
      BatchTrackingAutoBatchNoRequired: false,
      BatchTrackingMRPRequired: false,
      BatchTrackingMfgDateRequired: false,
      BatchTrackingExpDateRequired: false,
      BatchTrackingDateFormat: "",
      OMNIEnabled: true,
      OutOfStock: false,
      SecondaryUOMs: [],
      SalesUOM: "",
      PurchaseUOM: "",
      Attributes: [],
      Variants: [],
      AutoGenerateSKU: false,
      CustomSKU: product.Code,
      ShowAsAdditionalCharges: false,
      PackagingCharges: "",
      LongDescription: "",
      SequenceNo: 0,
      MaxOrderQty: "",
      PincodePackingQty: product.Quantity,
      PincodePackingUOM: product.UOM,
      PinCodeProductType: product.Type,
      PincodeProductProductType: product.ProductType,
      PincodeModel: product.Model,
      PincodePackaging: product.Packaging,
      PincodeBundling: product.Bundling,
      PincodeCancellable: true,
      PincodeReturnable: true,
      PincodeManufacturer: "",
    };
    let omniTag = "",
      prodName = "",
      prodSKU = "";
    prodName = prod.Name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, "").replace(
      /[' ']/g,
      "_"
    );
    prodSKU = prod.SKU.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, "").replace(
      /[' ']/g,
      "_"
    );
    omniTag = prodName + "_" + prodSKU;
    prod.OMNITag = omniTag;

    if (product.Image && product.Image.trim() !== "") {
      prod.Images.push({
        ImageName: product.Code,
        ImagePath: product.Image,
        ImageSize: 0,
      });
    }
    // console.log("product", prod);
    //create new product
    // console.log('product',product);
    let key = await this.props.addNewProduct(prod);
    console.log(key);
    if (key !== "") {
      this.props.getToastr("Product Saved Successfully");
      let missingPopularProducts = this.state.missingPopularProducts;
      for (let i = 0; i < missingPopularProducts.length; i++) {
        if (missingPopularProducts[i].Code === product.Code) {
          missingPopularProducts.splice(i, 1);
        }
      }
      this.setState({ missingPopularProducts: missingPopularProducts });
      this.loadList(
        this.state.activePage,
        50,
        missingPopularProducts,
        this.state.selectedCategory,
        this.state.selectedBrand
      );
    }
  }

  renderTitle() {
    if (
      this.state.missingPopularProducts &&
      this.state.missingPopularProducts.length > 0
    ) {
      return (
        "Missing Popular Items (" +
        this.state.missingPopularProducts.length +
        ")"
      );
    } else {
      return "Missing Popular Items";
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section className="content">
            <div className="box ">
              <div className="box-header with-border">
                <font className="missingpopulraitems-page-header-title">
                  {this.renderTitle()}
                </font>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-2">
                    {/* <Input
                      type="select"
                      label="Brand"
                      value={this.state.selectedBrand}
                      onChange={(value) => this.onBrandChange(value)}
                    >
                      <option value="">All</option>
                      {this.state.brands.map((c) => (
                        <option value={c.Name}>{c.Name}</option>
                      ))}
                    </Input> */}
                    <Input
                      type="typeahead"
                      label="Brand"
                      options={this.state.brands}
                      value={this.state.selectedBrandTA}
                      onChange={(value) => this.onBrandChange(value)}
                      labelKey="Name"
                    />
                  </div>
                  <div className="col-md-2">
                    {/* <Input
                      type="select"
                      label="Category"
                      value={this.state.selectedCategory}
                      onChange={(value) => this.onCategoryChange(value)}
                    >
                      <option value="">All</option>
                      {this.state.categories.map((c) => (
                        <option value={c.Name}>{c.Name}</option>
                      ))}
                    </Input> */}
                    <Input
                      type="typeahead"
                      label="Category"
                      options={this.state.categories}
                      value={this.state.selectedCategoryTA}
                      onChange={(value) => this.onCategoryChange(value)}
                      labelKey="Name"
                    />
                  </div>
                  {/* <div className="col-md-2">
                    <Input type="select" label="Sub Category">
                      <option value="">All</option>
                      {this.state.subCategories.map((c) => (
                        <option value={c.Name}>{c.Name}</option>
                      ))}
                    </Input>
                  </div> */}

                  {/* <div className="col-md-2">
                    <button
                      type="button"
                      className="btn btn-default btn-flat"
                      style={{ marginTop: "25px" }}
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      className="btn btn-default btn-flat"
                      style={{ marginTop: "25px", marginLeft: "5px" }}
                      onClick={this.onSearchClick}
                    >
                      Search
                    </button>
                  </div> */}
                </div>
                {this.state.loading && <Spinner hideOverlay={true} />}
                <div className="row">
                  <div className="col-md-12">
                    {!this.state.loading && (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Code</th>
                            <th>Product</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Brand</th>
                            <th>MRP</th>
                            <th>Retail Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.missingPopularProductsList.map(
                            (product, index) => (
                              <tr key={product.key}>
                                <td>
                                  <div className="missingpopulraitems-prodcut-image">
                                    <img src={product.Image} />
                                  </div>
                                </td>
                                <td>{product.Code}</td>
                                <td>
                                  {product.Name} {product.Quantity}
                                  {product.UOM}
                                </td>
                                <td>{product.Category}</td>
                                <td>{product.Subcategory}</td>
                                <td>{product.Brand}</td>
                                <td>
                                  <Input
                                    type="number"
                                    value={product.MRPNew}
                                    error={product.MRPNewError}
                                    disabled={product.Saving}
                                    onChange={(value) => {
                                      let missingPopularProductsList = this
                                        .state.missingPopularProductsList;
                                      missingPopularProductsList[
                                        index
                                      ].MRPNew = value;
                                      missingPopularProductsList[
                                        index
                                      ].MRPNewError = "";
                                      this.setState({
                                        missingPopularProductsList: missingPopularProductsList,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="number"
                                    value={product.RetailPriceNew}
                                    error={product.RetailPriceError}
                                    disabled={product.Saving}
                                    onChange={(value) => {
                                      let missingPopularProductsList = this
                                        .state.missingPopularProductsList;
                                      missingPopularProductsList[
                                        index
                                      ].RetailPriceNew = value;
                                      missingPopularProductsList[
                                        index
                                      ].RetailPriceError = "";
                                      this.setState({
                                        missingPopularProductsList: missingPopularProductsList,
                                      });
                                    }}
                                  />
                                </td>
                                <td className="missingpopulraitems-add-btn-col">
                                  <Button
                                    type="primary"
                                    text="Add"
                                    processing={product.Saving}
                                    onClick={() =>
                                      this.onSaveProduct(product, index)
                                    }
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                {this.state.totalItemsCount > 0 && (
                  <div className="list-page-footer">
                    <div className="left">{this.showPaginationStatus()}</div>
                    <div className="right">
                      <div className="list-page-footer-pagination">
                        <ImageButton
                          type={"leftarrowdouble"}
                          label={"First"}
                          onClick={() => this.onFirstClick()}
                        />
                        <ImageButton
                          type={"leftarrow"}
                          label={"Previous"}
                          onClick={() => this.onPreviousClick()}
                        />
                        <ImageButton
                          type={"rightarrow"}
                          label={"Next"}
                          onClick={() => this.onNextClick()}
                        />
                        <ImageButton
                          type={"rightarrowdouble"}
                          label={"Last"}
                          onClick={() => this.onLastClick()}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default MissingPopularItems;
