import React, { Component } from 'react'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'
import Switch from "react-switch"

class OutForDelivery extends Component {

    constructor() {
        super()
        this.state = {
            key:'',
            employeeList: [],
            selectedEmployeeKey: '',
            selectedEmployee: '',
            sendSMSForOutForDelivery: false,
            SMSNo: '',
            module: '',
            selectedSale : null,
        }
        this.loadEmployees = this.loadEmployees.bind(this);
        this.onSaveOutForDelivery = this.onSaveOutForDelivery.bind(this);
    }

    componentDidMount() {
        this.loadEmployees();
        if (this.props.location.state)
        {
            if(this.props.location.state.selectedSale)
                this.setState({ selectedSale: this.props.location.state.selectedSale, SMSNo: this.props.location.state.selectedSale.DeliveryCustomer.PhoneNo });
        
                if(this.props.location.state.module)
                this.setState({ module: this.props.location.state.module });
       }
    }

    loadEmployees() {
        this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key,
            0, 1000, "active", "", "", "", "", "",
            "", "", "", "", "", ""
        ).then((employees) => {
            this.setState({ employeeList: employees.searchResult })
        })
    }

    onSaveOutForDelivery() {
        if(this.state.module === 'outfordelivery')
        {
            this.props.outForDelivery(this.state.selectedSale,
                this.state.selectedEmployee,
                this.state.selectedEmployeeKey,
                this.state.SMSNo).then(() => {
                    setTimeout(function () {
                        this.props.getToastr("Delivery status changed to Out for Delievery")
                        this.props.onClose();
                    }.bind(this), 2000)
                })
        }
        else
        {
            this.props.readyForPickup(this.state.selectedSale,
                this.state.SMSNo).then(() => {
                    setTimeout(function () {
                        this.props.getToastr("Delivery status changed to Ready for Pickup")
                        this.props.onClose();
                    }.bind(this), 2000)
                })
        }
    }

    render() {
        return <div>
            {this.props.isMobileApp() &&  <HeaderNew {...this.props}
                back={true}
                title={this.state.module === 'outfordelivery' ? "Out for Delivery": 'Ready for Pickup'}
                onBackClick={() => { this.props.onClose() }}
            />}
            {!this.props.isMobileApp() && <h3 style={{ marginTop: "0px" }}>{this.state.module === 'outfordelivery' ? "Out for Delivery": 'Ready for Pickup'}</h3>}
            {this.props.isMobileApp() && <div className="header-new-background"></div>}
            <div className={this.props.isMobileApp() ? "content-wrapper": ""}>
                <div className={this.props.isMobileApp() ? "box with no-border" : ""} style={{boxShadow: "0 0px 0px rgba(0,0,0,0.1)"}}>
                    <div className="box-body">
                        <div className="row">
                            {this.state.module === 'outfordelivery' && <div className="col-md-6 col-xs-12">
                                <div className="form-group ">
                                    <label >Employee</label>
                                    <select className="form-control"
                                        onChange={(evt) => this.setState({
                                            selectedEmployeeKey: evt.target.value,
                                            selectedEmployee: evt.target.options[evt.target.selectedIndex].text
                                        })}
                                        value={this.state.selectedEmployeeKey}>
                                        <option value="">Select Employee</option>
                                        {this.state.employeeList.map((e, index) => <option key={index} value={e.key}>{e.EmployeeFirstName + ' ' + e.EmployeeLastName}</option>)}
                                    </select>
                                </div>
                            </div>}
                            <div className="col-md-6 col-xs-12">
                                <div className="form-group " >

                                {/* <input type="checkbox"
                                    onChange={() => this.setState({ sendSMSForOutForDelivery: !this.state.sendSMSForOutForDelivery })}
                                    checked={this.state.sendSMSForOutForDelivery}
                                /> */}
                                <label>Notify customer</label>
                                <br/>
                                <Switch
                                        checked={this.state.sendSMSForOutForDelivery}
                                        onChange={() => this.setState({ sendSMSForOutForDelivery: !this.state.sendSMSForOutForDelivery })}
                                    />
                                
                                <input type="number"
                                    disabled={!this.state.sendSMSForOutForDelivery}
                                    onChange={(evt => this.setState({ SMSNo: evt.target.value }))}
                                    value={this.state.SMSNo}
                                    className="form-control" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            {this.props.isMobileApp() && <Footer
                        onClick={() => this.onSaveOutForDelivery()}
                    />}
            {!this.props.isMobileApp() && <div className="box-footer">
                <button onClick={() => this.props.onClose()} className="btn btn-md btn-default btn-flat">Cancel</button>
                <button type="submit" className="btn btn-md btn-success pull-right btn-flat" onClick={() => this.onSaveOutForDelivery()} disabled={this.state.saving}>Save</button>
            </div>}
        </div>
    }
}
export default OutForDelivery