import React, { Component } from 'react';
import Loader from '../Loader'
import * as actionTypes from '../../actionTypes'

class ParkedTransactions extends Component {
    constructor() {
        super()
        this.state = {
            drafts: [],
            transactionType:"",
            loading:true,
            mobile:false
        }
        this.getParkedTransactions= this.getParkedTransactions.bind(this)
    }

    componentDidMount() {
        this.setState({mobile:this.props.isMobileApp()})
        this.getParkedTransactions()
    }

    async getParkedTransactions(){
        let transactionType = this.props.location.state.transactionType
        let drafts = await this.props.getDraftTransactions(transactionType)
        this.setState({drafts:drafts, transactionType:transactionType,loading:false})
    }

    componentWillMount(){
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    }

    render() {
        return <div className="content-wrapper" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-4 col-xs-9" >
                                        <font style={{ fontSize: "30px" }}>Parked Transactions</font>
                                    </div>
                                    <div className="col-md-8 col-xs-3 pull-right hidden-xs" style={{paddingTop:"4px"}}>
                                        <button className="pull-right btn btn-default btn-md btn-flat"
                                        onClick={this.getParkedTransactions}
                                        ><i className="fa fa-refresh"/> Refresh</button>
                                    </div>
                                    <div className="col-md-8 col-xs-3 pull-right hidden-md hidden-lg">
                                            <button className="pull-right btn btn-default btn-lg btn-flat"
                                            onClick={this.getParkedTransactions}
                                            ><i className="fa fa-refresh"/></button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body" style={{padding:"0px"}}>
                                {this.state.loading && <div style={{ width: "auto", overflow: "auto",height:"80vh"}} className="scroll2"><Loader show={this.state.loading} /></div>}
                                {(!this.state.loading && (!this.props.isMobileApp() || (!this.state.showSearchOptions && this.props.isMobileApp()) )) && <div style={{ width: "auto", overflow: "auto",height: "80vh"}} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "60px" }}></th>
                                                {this.state.mobile && <th></th>}
                                                {!this.state.mobile && <th>Transaction Time</th>}
                                                {!this.state.mobile && <th>Customer</th>}
                                                {!this.state.mobile && <th>Qty</th>}
                                                {!this.state.mobile && <th>Amount</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.drafts.map((draftSale,index) =>  <tr key={index}>
                                                    <td>
                                                        <button type="button" className="btn btn-flat btn-md btn-default "
                                                            onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: '/home/sales/sales/',
                                                                    state: {
                                                                      type: actionTypes.SALE_DRAFT,
                                                                      key: draftSale.key,
                                                                      draft: draftSale,
                                                                      transactionType:draftSale.Transaction.TransactionType
                                                                    }
                                                                  })
                                                                }
                                                            }>
                                                            <i className="fa fa-mail-forward"></i>
                                                        </button>
                                                    </td>
                                                    {this.state.mobile && <td>
                                                        <div>{(new Date(draftSale.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                                        <div>{draftSale.Transaction.Customer ? draftSale.Transaction.Customer.FirstName + " " + draftSale.Transaction.Customer.LastName: ""}</div>
                                                        <div>Qty: {draftSale.Transaction.BillQty}</div>
                                                        <div>Amount: {draftSale.Transaction.BillAmount}</div>
                                                    </td>}
                                                    {!this.state.mobile && <td>
                                                        {(new Date(draftSale.ActionOn.seconds*1000)).toString().substring(0,24)}
                                                    </td>}
                                                    {!this.state.mobile && <td>
                                                        {draftSale.Transaction.Customer ? draftSale.Transaction.Customer.FirstName + " " + draftSale.Transaction.Customer.LastName: ""}
                                                    </td>}
                                                    {!this.state.mobile && <td>
                                                        {draftSale.Transaction.BillQty}
                                                    </td>}
                                                    {!this.state.mobile && <td>
                                                        {draftSale.Transaction.BillAmount}
                                                    </td>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    }
}

export default ParkedTransactions