import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Button from "../2/Controls/Button";
import { isMobileApp } from "../../actions/Util";
import Logo from "../2/Controls/Logo";
import { auth } from "../../firebase";
import {
  getPinCodeStoreDomain,
  getPinCodeStoreType,
} from "../../services/functions/Pincode";

class PinCodeSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNo: "",
      mobileNoError: "",
      step: 1,
      processing: false,
      OTP: "",
      OTPError: "",
      domain: [],
      storeTypes: [],
      storeDomainID: "",
      storeDomainError: "",
      storeTypeID: "",
      storeType: "",
      storeTypeError: "",
      businessName: "",
      businessNameError: "",
      businessTypeID: "",
      businessType: "",
      businessTypeError: "",
      loginID: "",
      loginIDError: "",
      password: "",
      passwordError: "",
      confirmPassword: "",
      pincodeHashKey: "",
      pincodeHashKeyError: "",
      plan: null,
    };

    this.onSendOTP = this.onSendOTP.bind(this);
    this.validateOTP = this.validateOTP.bind(this);
    this.onStoreDomainSelected = this.onStoreDomainSelected.bind(this);
    this.onStoreTypeSelected = this.onStoreTypeSelected.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.createRegistration = this.createRegistration.bind(this);
  }

  async componentDidMount() {
    const domain = getPinCodeStoreDomain();
    // const storeTypes = getPinCodeStoreType();

    let plans = await this.props.getPlans();
    let plan =
      plans.filter((f) => f.Slug === "pincode-starter").length > 0
        ? plans.filter((f) => f.Slug === "pincode-starter")[0]
        : null;
    // console.log("plan",plan);
    this.setState({
      domain: domain,
      plan: plan,
      // storeTypes:storeTypes
    });
  }

  async onSendOTP() {
    //check if phone is provided and is a valid phone no
    let mobileNoError = "";
    if (this.state.mobileNo.trim() === "") {
      mobileNoError = "Please provide valid mobile no";
    }
    if (mobileNoError !== "") {
      this.setState({ mobileNoError: mobileNoError });
      return;
    }
    this.setState({ processing: true });

    // let contactNoExists = await this.props.checkIfPhoneNoExists(this.state.mobileNo.trim());

    // if (contactNoExists) {
    //     mobileNoError = 'Mobile No already registered with us. Please try a different mobile no';
    //     this.setState({mobileNoError:mobileNoError,processing:false});
    //     return;
    // }

    let otpGenerated = await this.props.generateOTPForRegistration(
      this.state.mobileNo.trim(),
      ""
    );

    if (!otpGenerated) {
      mobileNoError = "Unable to generate OTP";
      this.setState({ mobileNoError: mobileNoError, processing: false });
      return;
    } else {
      this.setState({ processing: false, step: 2 });
    }
  }

  async validateOTP() {
    let OTPError = "";
    if (this.state.OTP.trim() === "") {
      OTPError = "Please provide OTP";
    }
    if (OTPError !== "") {
      this.setState({ OTPError: OTPError });
      return;
    }
    this.setState({ processing: true });
    let validOTP = await this.props.validateOTPForRegistration(
      this.state.mobileNo.trim(),
      this.state.OTP
    );
    if (validOTP) {
      this.setState({ processing: false, step: 3 });
    } else {
      this.setState({ OTPError: "Invalid OTP.", processing: false });
    }
  }

  onStoreDomainSelected(value) {
    const storeTypes = getPinCodeStoreType(value);
    // console.log("storeTypes",storeTypes)
    this.setState({
      storeDomainID: value,
      storeTypes: storeTypes,
      storeDomainError: "",
    });
  }

  onStoreTypeSelected(value) {
    console.log("value", value);
    let storeType = "";
    if (value !== "") {
      storeType = this.state.storeTypes.filter((f) => f.id === value)[0].value;
    }
    console.log("storeType", storeType);
    this.setState({
      storeTypeID: value,
      storeType: storeType,
      storeTypeError: "",
    });
  }

  async onSubmit() {
    let businessNameError = "";
    let loginIDError = "";
    let passwordError = "";
    let storeDomainError = "";
    let storeTypeError = "";
    let pincodeHashKeyError = "";

    if (this.state.businessName.trim() === "") {
      businessNameError = "Please provide business name";
    }
    if (this.state.loginID.trim() === "") {
      loginIDError = "Please provide login id";
    }
    if (this.state.loginID.trim() !== "" && this.state.loginID.length < 6) {
      loginIDError = "Login id should be atleast six characters";
    }
    if (this.state.password.trim() === "") {
      passwordError = "Please provide password";
    } else {
      if (this.state.password.length < 6) {
        passwordError = "Password should be atleast six characters";
      } else if (
        this.state.password.trim() !== this.state.confirmPassword.trim()
      ) {
        passwordError = "Password and Confirm Password do not match";
      }
    }
    if (this.state.storeDomainID === "") {
      storeDomainError = "Please select";
    }
    if (this.state.storeTypeID === "") {
      storeTypeError = "Please select";
    }
    // const pincodeHashKeyRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6}$/;
    const pincodeHashKeyRegex = /^[A-Z0-9]{6}$/;

    const isValidPincodeHashKey = pincodeHashKeyRegex.test(
      this.state.pincodeHashKey
    );

    if (this.state.pincodeHashKey === "") {
      pincodeHashKeyError = "Please provide value";
    } else {
      if (!isValidPincodeHashKey) {
        // Password is valid
        pincodeHashKeyError = "Invalid Merchant Hash Key";
      }
    }

    if (
      businessNameError !== "" ||
      loginIDError !== "" ||
      passwordError !== "" ||
      storeDomainError !== "" ||
      storeTypeError !== "" ||
      pincodeHashKeyError !== ""
    ) {
      this.setState({
        storeDomainError: storeDomainError,
        storeTypeError: storeTypeError,
        businessNameError: businessNameError,
        loginIDError: loginIDError,
        passwordError: passwordError,
        pincodeHashKeyError: pincodeHashKeyError,
      });
      return;
    }
    this.setState({ processing: true });
    let businessUniqueName = this.state.businessName
      .replace(/[^a-zA-Z0-9]/g, "")
      .toLowerCase();
    let businessNameIsUnique = await this.props.checkBusinessNameIsUnique(
      businessUniqueName
    );
    if (!businessNameIsUnique) {
      this.setState({
        businessNameError:
          "Business name already registered with us. Please try a different business name",
        processing: false,
      });
      return;
    }
    // this.setState({ processing: true });
    auth
      .createUserWithEmailAndPassword(this.state.loginID, this.state.password)
      .then((result) => {
        const auth = result.user;
        this.createRegistration(auth);
      })
      .catch((error) => {
        alert(error);
        this.setState({ processing: false });
      });
    // this.setState({processing:false, step:4});
  }

  createRegistration(auth, businessUniqueName) {
    const registration = {
      BusinessName: this.state.businessName,
      BusinessTypeID: "4",
      BusinessType: this.state.storeDomainID,
      CountryCode: "IN",
      Country: "INDIA",
      ContactNo: this.state.mobileNo,
      StorageFolder: businessUniqueName,
      UniqueName: businessUniqueName,
      UID: auth.uid,
      EmailID: this.state.loginID,
      Name: this.state.businessName,
      Source: "web",
      ReferredBy: "",
      WhiteLabel: this.props.config.WhiteLabel.Code,
      StoreDomainID: this.state.storeDomainID,
      StoreType: this.state.storeType,
      StoreTypeID: this.state.storeTypeID,
      PincodeHashKey: this.state.pincodeHashKey,
    };

    let plan = this.state.plan;
    // console.log("plan", plan)

    if (plan) {
      registration.PlanID = plan.key;
      registration.Plan = plan.Name;
      registration.Slug = plan.Slug;
      registration.Currency = plan.Currency;
      registration.CurrencySymbol = plan.CurrencySymbol;
      registration.PlanModules = plan.Modules;
      registration.NoOfCashRegistersAllowed = plan.NoOfCashRegistersAllowed;
      registration.NoOfCustomersAllowed = plan.NoOfCustomersAllowed;
      registration.NoOfEmployeesAllowed = plan.NoOfEmployeesAllowed;
      registration.NoOfInventoryTransactionsAllowed =
        plan.NoOfInventoryTransactionsAllowed;
      registration.NoOfProductsAllowed = plan.NoOfProductsAllowed;
      registration.NoOfPromotionalSMS = plan.NoOfPromotionalSMS;
      registration.NoOfSalesTransactionsAllowed =
        plan.NoOfSalesTransactionsAllowed;
      registration.NoOfStoreLocationsAllowed = plan.NoOfStoreLocationsAllowed;
      registration.NoOfStoresAllowed = plan.NoOfStoresAllowed;
      registration.NoOfSupportHrs = plan.NoOfSupportHrs;
      registration.NoOfTransactionalSMS = plan.NoOfTransactionalSMS;
      registration.NoOfUsersAllowed = plan.NoOfUsersAllowed;
      registration.TrialPeriod = plan.TrialPeriod;
    }
    // console.log("registration", registration)

    this.props.createRegistration(registration).then(() => {
      this.setState({ processing: false, step: 4 });
      this.props.getToastr("Registration created successfully");
    });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={
            this.props.config.WhiteLabel.RegisterBackgroundCSS
              ? "login " + this.props.config.WhiteLabel.RegisterBackgroundCSS
              : "login"
          }
        >
          {isMobileApp() && <div className="header-new-background"></div>}

          <br />
          <br />
          <br />

          <div
            className={
              !isMobileApp() ? "signup-container" : "signup-container-mobile"
            }
          >
            <div className="row">
              <div className="col-md-12 signup-logo">
                <Logo
                  {...this.props}
                  logoOption={this.props.config.WhiteLabel.LoginLogoOption}
                  height={
                    this.props.config.WhiteLabel.LogoDefaultHeight
                      ? this.props.config.WhiteLabel.LogoDefaultHeight
                      : "122px"
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 signup-header">signup</div>
            </div>
            {this.state.step === 1 && (
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="mobileno"
                    label="Please provide merchant mobile No"
                    placeholder="Please provide mobile no"
                    onEnter={this.onSendOTP}
                    value={this.state.mobileNo}
                    onChange={(value) =>
                      this.setState({ mobileNo: value, mobileNoError: "" })
                    }
                    error={this.state.mobileNoError}
                  />
                </div>
                <div className="col-md-12">
                  <Button
                    type="primary"
                    text="Send OTP"
                    onClick={this.onSendOTP}
                    processing={this.state.processing}
                  />
                </div>
              </div>
            )}

            {this.state.step === 2 && (
              <div className="row">
                {/* <div className="col-md-12">
                            OTP sent to 
                            &nbsp;<b>+91 {this.state.mobileNo}</b>
                        </div> */}
                <div className="col-md-12">
                  <Input
                    type="mobileno"
                    label={"OTP sent to"}
                    value={this.state.mobileNo}
                  />
                </div>
                <div className="col-md-12 align-center">
                  <Button
                    type="link"
                    text="Change No"
                    onClick={() => this.setState({ step: 1 })}
                  />
                  <br />
                </div>
                <div className="col-md-12">
                  <Input
                    type="otp"
                    label="Please enter OTP"
                    onChange={(value) =>
                      this.setState({ OTP: value, OTPError: "" })
                    }
                    value={this.state.OTP}
                    error={this.state.OTPError}
                  />
                </div>
                <div className="col-md-12">
                  <Button
                    type="primary"
                    text="Submit OTP"
                    onClick={this.validateOTP}
                    processing={this.state.processing}
                  />
                </div>
                <div className="col-md-12 align-center">
                  <Button
                    type="link"
                    text="Resend OTP"
                    onClick={this.onSendOTP}
                  />
                </div>
              </div>
            )}

            {this.state.step === 3 && (
              <div className="row">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Business Name"
                    value={this.state.businessName}
                    onChange={(value) =>
                      this.setState({
                        businessName: value,
                        businessNameError: "",
                      })
                    }
                    error={this.state.businessNameError}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="select"
                    label="Domain"
                    value={this.state.storeDomainID}
                    onChange={(value) => this.onStoreDomainSelected(value)}
                    error={this.state.storeDomainError}
                  >
                    <option value="">Please select domain</option>
                    {this.state.domain.map((b) => (
                      <option value={b.id}>{b.value}</option>
                    ))}
                  </Input>
                </div>
                <div className="col-md-12">
                  <Input
                    type="select"
                    label="Store Type"
                    value={this.state.storeTypeID}
                    onChange={(value) => this.onStoreTypeSelected(value)}
                    error={this.state.storeTypeError}
                  >
                    <option value="">Please select store type</option>
                    {this.state.storeTypes.map((c) => (
                      <option value={c.id}>{c.value}</option>
                    ))}
                  </Input>
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Merchant Hash Key"
                    value={this.state.pincodeHashKey}
                    onChange={(value) =>
                      this.setState({
                        pincodeHashKey: value,
                        pincodeHashKeyError: "",
                      })
                    }
                    error={this.state.pincodeHashKeyError}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Please provide login id"
                    value={this.state.loginID}
                    onChange={(value) =>
                      this.setState({ loginID: value, loginIDError: "" })
                    }
                    error={this.state.loginIDError}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="password"
                    label="Please provide password"
                    value={this.state.password}
                    onChange={(value) =>
                      this.setState({ password: value, passwordError: "" })
                    }
                    error={this.state.passwordError}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    type="password"
                    label="Please confirm password"
                    value={this.state.confirmPassword}
                    onChange={(value) =>
                      this.setState({
                        confirmPassword: value,
                        passwordError: "",
                      })
                    }
                  />
                </div>
                <div className="col-md-12">
                  <Button
                    type="primary"
                    text="Sumbit"
                    onClick={this.onSubmit}
                    processing={this.state.processing}
                  />
                </div>
              </div>
            )}

            {this.state.step === 4 && (
              <div className="row">
                <div className="col-md-12 align-center">
                  <b>
                    Account created successfully. Please login with your login
                    id and password.
                  </b>
                  <br />
                  <br />
                  <Button
                    type="link"
                    text="Login"
                    onClick={() => this.props.history.push("/")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PinCodeSignUp;
