import React, { Component } from 'react';
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';

class AddTransaction extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            name: '',
            cashRegisterList: [],
            accountList: [],
            fromAccount: "",
            fromAccountID: "",
            toAccount: "",
            toAccountID: "",
            fromAccountType: "",
            toAccountType: "",
            transactionDate: new Date().toISOString().split('T')[0],
            amount: "",
            paymentTypeID: '',
            paymentType: '',
            paymentRemark: '',
            transactionDateError: '',
            toAccountError: '',
            fromAccountError: '',
            amountError: '',
            paymentTypeError: '',
            saving: false
        }
        this.onSaveTransaction = this.onSaveTransaction.bind(this)
        this.onPaymentChange = this.onPaymentChange.bind(this)
    }

    componentDidMount() {

        this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
            this.setState({ cashRegisterList: cashRegisters })
            // console.log(JSON.stringify(cashRegisters));
        });

        this.props.getAccounts(this.props.user.user.RegistrationID).then((accounts) => {

            let finalAccounts = []
            if (accounts && accounts.length > 0) {
                for (let index = 0; index < accounts.length; index++) {
                    const account = accounts[index];
                    if (!account.IsDeleted) {
                        if (account.AllStores) {
                            finalAccounts.push(account);
                        }
                        else {
                            for (let j = 0; j < account.Stores.length; j++) {
                                const store = account.Stores[j];
                                if (store.value === this.props.user.store.key) {
                                    finalAccounts.push(account);
                                }
                            }
                        }
                    }
                }
            }
            // console.log(JSON.stringify(finalAccounts));

            this.setState({ accountList: finalAccounts })
        });

        if (this.props.transaction) {
            this.setState({
                key: this.props.transaction.key,
                name: this.props.transaction.Name,
                fromAccount: this.props.transaction.FromAccount,
                toAccount: this.props.transaction.ToAccount,
                fromAccountID: this.props.transaction.FromAccountID,
                toAccountID: this.props.transaction.ToAccountID,
                transactionDate: this.props.transaction.TransactionDate,
                amount: Number(this.props.transaction.Amount),
                paymentTypeID: this.props.transaction.PaymentTypeID,
                paymentType: this.props.transaction.PaymentType,
                paymentRemark: this.props.transaction.PaymentRemark,
            })
        }
    }

    onSaveTransaction() {
        this.setState({ saving: true })
        let transactionDateError = ""
        let toAccountError = ""
        let fromAccountError = ""
        let amountError = ""
        let paymentTypeError = ""
        let fromAccountType = ""
        let toAccountType = ""

        if (this.state.transactionDate.trim() === "") {
            transactionDateError = "Please provide transaction date"
        }
        if (this.state.toAccount.trim() === "") {
            toAccountError = "Please provide account"
        }
        if (this.state.fromAccount.trim() === "") {
            fromAccountError = "Please provide account"
        }

        if (this.state.toAccount.trim() !== "" && this.state.fromAccount.trim() !== "" && this.state.fromAccount.trim() === this.state.toAccount.trim()) {
            fromAccountError = "Both Account cannnot be same"
            toAccountError = "Both Account cannnot be same"
        }
        if (this.state.paymentType.trim() === "") {
            paymentTypeError = "Please provide payment type"
        }

        if(this.state.amount.trim() === "")
        {
            amountError = "Please provide amount"
        }

        if (this.state.fromAccountID !=="") {
            let ctr = this.state.cashRegisterList.filter((e) => e.key === this.state.fromAccountID).length;
            if (ctr > 0) {
                fromAccountType = "CashRegister" ;
            }
            else{
                fromAccountType = "Account";
            }
            this.setState({ fromAccountType:fromAccountType });
        }

        if (this.state.toAccountID !=="") {

            let ctr = this.state.cashRegisterList.filter((e) => e.key === this.state.toAccountID).length;

            if (ctr > 0) {
                toAccountType = "CashRegister";
            }
            else{
                toAccountType = "Account";
            }

            this.setState({ toAccountType: toAccountType });

        }

        if (fromAccountType == "CashRegister" && this.state.paymentType !=="" && this.state.paymentType.toLowerCase() !== "cash from counter") {
            paymentTypeError = "For selected Cash Register, payment type cannot be other than Cash From Counter"
        }

        if (transactionDateError !== "" ||
            toAccountError !== "" ||
            fromAccountError !== "" ||
            amountError !== "" ||
            paymentTypeError !== "") {
            this.setState({
                transactionDateError: transactionDateError,
                toAccountError: toAccountError,
                fromAccountError: fromAccountError,
                amountError: amountError,
                paymentTypeError: paymentTypeError,
                saving: false
            })
            return
        }
        var transactionDate = new Date(this.state.transactionDate);
        transactionDate = new Date(transactionDate.setTime(new Date().getTime()));

        let transaction = {
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            FromAccount: this.state.fromAccount,
            ToAccount: this.state.toAccount,
            FromAccountID: this.state.fromAccountID,
            FromAccountType: fromAccountType,
            ToAccountID: this.state.toAccountID,
            ToAccountType: toAccountType,
            TransactionDate: transactionDate,
            Amount: Number(this.state.amount),
            PaymentTypeID: this.state.paymentTypeID,
            PaymentType: this.state.paymentType,
            PaymentRemark: this.state.paymentRemark,
            IsDeleted: false
        }
        this.props.saveTransaction(transaction, this.state.key).then(() => {
            this.props.getToastr("Transction saved successfully")
            setTimeout(function () { //Start the timer
                this.setState({ saving: false });
                    this.props.onSave();
            }.bind(this), 1000)
        });
    }

    onButtonClick(action){
        if(action==="save"){
            this.onSaveTransaction();
        }else{
            this.props.onClose()
        }
    }

    onFromAccountSelection(accountID){
        let fromAccount = "";
        fromAccount = this.returnAccountName(accountID)
        this.setState({ fromAccountID: accountID, fromAccount: fromAccount, fromAccountError: "" })
    }

    onToAccountSelection(accountID){
        let toAccount = "";
        toAccount = this.returnAccountName(accountID)
        this.setState({ toAccountID: accountID, toAccount: toAccount, toAccountError: "" }) 
    }
    
    returnAccountName(accountID){
        let fromAccount = "";
        if(this.state.cashRegisterList.filter(f=> f.key === accountID).length> 0 ){
            fromAccount = this.state.cashRegisterList.filter(f=> f.key === accountID)[0].CashRegisterCode + '-' + this.state.cashRegisterList.filter(f=> f.key === accountID)[0].CashRegisterName;
        }
        if(fromAccount ==="" && this.state.accountList.filter(f=> f.key === accountID).length> 0 ){
            fromAccount=  this.state.accountList.filter(f=> f.key === accountID)[0].Name;
        }
        return fromAccount;
    }

    onPaymentChange(value){
        let paymentType = "";
        if(this.props.config.PaymentTypes.filter((e) => e.key===value).length> 0)
        {
            paymentType = this.props.config.PaymentTypes.filter((e) => e.key===value)[0].PaymentType;
        }
        this.setState({
            paymentTypeID: value,
            paymentType: paymentType,
            paymentTypeError: ""
        });
    }

    render() {
        return <Form
            title= "Add Transaction"
            onClick={(action)=>this.onButtonClick(action)}
            processing={this.state.saving}
        >
            <Input
                type="select"
                label="From Account"
                onChange={(value) => this.onFromAccountSelection(value)}
                value={this.state.fromAccountID}
                error = {this.state.fromAccountError}
            >
                <option value="">Select Account</option>
                <optgroup label="Cash Register">
                    {this.state.cashRegisterList.map((cashRegister) =>
                        <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                    )}
                </optgroup>
                <optgroup label="Account">
                    {this.state.accountList.map((account) =>
                        <option value={account.key}>{account.Name}</option>
                    )}
                </optgroup>
            </Input>
            <Input
                type="select"
                label="To Account"
                onChange={(value) => this.onToAccountSelection(value)}
                value={this.state.toAccountID}
                error = {this.state.toAccountError}
            >
                <option value="">Select Account</option>
                <optgroup label="Cash Register">
                    {this.state.cashRegisterList.map((cashRegister) =>
                        <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                    )}
                </optgroup>
                <optgroup label="Account">
                    {this.state.accountList.map((account) =>
                        <option value={account.key}>{account.Name}</option>
                    )}
                </optgroup>
            </Input>
            <Input
                type="date"
                label="Date"
                onChange={(value) => this.setState({ transactionDate: value, transactionDateError: "" })}
                value={this.state.transactionDate}
                error={this.state.transactionDateError}
            />
            <Input
                type="number"
                label="Amount"
                onChange={(value) => this.setState({ amount: value, transactionDateError: "" })}
                value={this.state.amount}
                error={this.state.amountError}
            />
            <Input
                type="select"
                label="Payment Type"
                onChange={(value) => this.onPaymentChange(value)}
                value={this.state.paymentTypeID}
                error={this.state.paymentTypeError}
            >
                <option value="">Select Payment Type</option>
                {this.props.config.PaymentTypes.filter((e) => e.ShowInExpense && !e.IsDeleted).map((e) => <option value={e.key}>{e.PaymentType}</option>)}
            </Input>
            <Input
                type="text"
                label="Payment Remarks"
                onChange={(value) => this.setState({ paymentRemark: value })}
                value={this.state.paymentRemark}
            />            
        </Form>
    }
}

export default AddTransaction