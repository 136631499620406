import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
//import ReactHtmlParser from 'react-html-parser';

class MembershipDashboard extends Component {

    constructor() {
        super();
        this.state = {
            membershipData: []
            , memberships: []
            , membershipSales: []
            , membershipSalesData: []
            , stores: []
            , storeList: []
            , rightHeight: 40
            , searchStore: ""
            , storeID: ''
            //, searchTransactionFromDate: new Date().toISOString().split('T')[0]
            //, searchTransactionToDate: new Date().toISOString().split('T')[0],
        };

        this.loadMembershipDashboard = this.loadMembershipDashboard.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.loadMembershipDashboardData = this.loadMembershipDashboardData.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getRandomColor = this.getRandomColor.bind(this);
    };

    async componentDidMount() {

        this.setState({ storeID: this.props.user.store.key });
        let storeList = [];
        storeList.push(this.props.user.store.key);

        await this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            if (stores.length > 1) {
                stores.unshift({ key: '', StoreCode: 'All', StoreName: 'Stores' })
            }
            this.setState({ stores: stores })
        });

        this.setState({ storeList: storeList, storeID: this.props.user.store.key });
        this.loadMembershipDashboard(storeList);
    };

    componentWillMount() {
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    };

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    };

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    async loadMembershipDashboardData(storeList) {
        return this.props.getMembershipData(storeList)
            .then((result) => {
                return result.searchResult
            })
    };

    async loadExpiredMembershipData(storeList) {
        return this.props.getExpiredMembershipData(storeList)
            .then((result) => {
                return result.searchResult
            })
    };

    async loadActiveMembershipData(storeList) {
        return this.props.getActiveMembershipData(storeList)
            .then((result) => {
                return result.searchResult
            })
    };

    async loadMembershipDashboard(storeList) {
        let membershipData = await this.loadMembershipDashboardData(storeList);
        let expiredData = await this.loadExpiredMembershipData(storeList);
        let activeData = await this.loadActiveMembershipData(storeList);

        let sum_total_count = 0;
        let sum_total_active = 0;
        let sum_total_expired = 0;
        for (let m = 0; m < membershipData.length; m++) {
            const data = membershipData[m];

            if (expiredData.length > 0) {
                let obj = expiredData.filter((e) => e.name === data.name)
                if (obj.length > 0) {
                    // console.log("expired", obj);
                    data.expired_count = obj[0].total_count;
                    sum_total_expired = Number(sum_total_expired) + Number(obj[0].total_count) 
                }
            }

            if (activeData.length > 0) {
                let obj1 = activeData.filter((a) => a.name === data.name)
                if (obj1.length > 0) {
                    data.active_count = obj1[0].total_count;
                    sum_total_active = Number(sum_total_active) + Number(obj1[0].total_count) 
                }
            }
            sum_total_count = Number(sum_total_count) + Number(data.total_count) 
        }

        let sum_total_sales = 0;
        let sum_total_transactions = 0;

        let memberships = {
            labels: [],
            datasets: [
                {
                    label: 'Membership',
                    backgroundColor: ['#99A1C6', '#51CDA0', '#DF7970',
                        '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                        '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                        '#C77B85', '#C39762'],
                    hoverBackgroundColor: ['#99A1C6', '#51CDA0', '#DF7970',
                        '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                        '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                        '#C77B85', '#C39762'],
                    data: []
                }
            ]
        };
        membershipData.map((data, index) => {
            memberships.labels.push(data.name);
            memberships.datasets[0].data.push(data.total_count);
        });

        let membershipSalesData = await this.loadMembershipSales(storeList);

        let membershipSales = {
            labels: [],
            datasets: [
                {
                    label: 'Membership Wise Sales',
                    backgroundColor: ['#99A1C6', '#51CDA0', '#DF7970',
                        '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                        '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                        '#C77B85', '#C39762'],
                    hoverBackgroundColor: ['#99A1C6', '#51CDA0', '#DF7970',
                        '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
                        '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
                        '#C77B85', '#C39762'],
                    data: []
                }
            ]
        };
        membershipSalesData.map((data, index) => {
            membershipSales.labels.push(data.name);
            membershipSales.datasets[0].data.push(data.total_sales);
            sum_total_sales = Number(sum_total_sales) + Number(data.total_sales);
            sum_total_transactions = Number(sum_total_transactions) + Number(data.total_transactions);
        });

        this.setState({ memberships: memberships, membershipData: membershipData, membershipSalesData: membershipSalesData, membershipSales: membershipSales,
            sum_total_count: sum_total_count,sum_total_expired: sum_total_expired, sum_total_active: sum_total_active, sum_total_sales: sum_total_sales, sum_total_transactions: sum_total_transactions  });
    };

    async loadMembershipSales(storeList) {
        return this.props.getMembershipSales(storeList)
            .then((result) => {
                return result.searchResult
            })
    };

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true })
        this.loadMembershipDashboard(this.state.storeList)
    };

    onClearSearch() {
        let storeList = [];
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            storeList: storeList //, searchTransactionFromDate: new Date().toISOString().split('T')[0], searchTransactionToDate: new Date().toISOString().split('T')[0]
        });
        this.loadMembershipDashboard(storeList);
    };

    onStoreChange(e) {
        let storeList = []
        if (e.target.value != "") {
            storeList.push(e.target.value)
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push(store.key)
            }
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-xs-12 hidden-md hidden-lg hiddn-xl hidden-sm">
                        <font style={{ fontSize: "30px", marginBottom: "4px" }}>Sales Dashboard</font>
                    </div>
                    <div className="col-xs-12 hidden-md hidden-lg hiddn-xl hidden-sm">
                        <select type="select" className="form-control"
                            value={this.state.storeID}
                            onChange={this.onStoreChange}>
                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                            )}
                        </select>
                    </div>

                    <div className="col-xs-2 hidden-md hidden-lg hiddn-xl hidden-sm" style={{ paddingLeft: "0px" }}>
                        <button type="button" className="btn btn-primary btn-flat btn-md fa fa-search"
                            style={{ width: "100%", height: "34px" }}
                            onClick={this.onSearchClick}></button>
                    </div>
                    <div className="col-md-12 hidden-xs">
                        <div className="row">
                            <div className="col-md-4">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>Membership Dashboard</font>
                            </div>
                            <div className="col-md-8">
                                <div className="input-group pull-right">
                                    <select type="select" className="form-control" value={this.state.storeID} style={{ width: "86%" }}
                                        onChange={this.onStoreChange}>
                                        {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                            <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                        )}
                                    </select>
                                    <button type="button" className="btn btn-primary btn-flat btn-md fa fa-search" style={{ padding: "8px" }}
                                        disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        onClick={this.onSearchClick}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Memberships</h3>
                                    </div>
                                    <div className="box-body">

                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="table-responsive" style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                                    {this.state.membershipData.length > 0 && <table className="table no-margin">
                                                        <thead>
                                                            <tr>
                                                                <th>Membership</th>
                                                                <th style={{ textAlign: "right" }} >Expired</th>
                                                                <th style={{ textAlign: "right" }}>Active</th>
                                                                <th style={{ textAlign: "right" }}>Total Count</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.membershipData.map((membership) =>
                                                                <tr>
                                                                    <td>{membership.name}</td>
                                                                    <td style={{ textAlign: "right" }}>{membership.expired_count}</td>
                                                                    <td style={{ textAlign: "right" }}>{membership.active_count}</td>
                                                                    <td style={{ textAlign: "right" }}>{membership.total_count}</td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td><b>Totals</b></td>
                                                                <td style={{ textAlign: "right" }}><b>{this.state.sum_total_expired}</b></td>
                                                                <td style={{ textAlign: "right" }}><b>{this.state.sum_total_active}</b></td>
                                                                <td style={{ textAlign: "right" }}><b>{this.state.sum_total_count}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>}
                                                    {this.state.membershipData.length === 0 && "No records found"}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="chart" style={{ height: this.state.rightHeight + "vh" }}>
                                                    <Pie
                                                        width={150}
                                                        height={70}
                                                        data={this.state.memberships}
                                                        options={{
                                                            maintainAspectRatio: false
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Membership Sales</h3>
                                    </div>
                                    <div className="box-body">

                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="table-responsive" style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                                    {this.state.membershipSalesData.length > 0 && <table className="table no-margin">
                                                        <thead>
                                                            <tr>
                                                                <th>Membership</th>
                                                                <th style={{ textAlign: "right" }}>Total Transactions</th>
                                                                <th style={{ textAlign: "right" }}>Total Sales</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.membershipSalesData.map((membership) =>
                                                                <tr>
                                                                    <td>{membership.name}</td>
                                                                    <td style={{ textAlign: "right" }}>{membership.total_transactions}</td>
                                                                    <td style={{ textAlign: "right" }}>{membership.total_sales}</td>
                                                                </tr>
                                                            )}
                                                            <tr>
                                                                <td><b>Totals</b></td>
                                                                <td style={{ textAlign: "right" }}><b>{this.state.sum_total_transactions}</b></td>
                                                                <td style={{ textAlign: "right" }}><b>{this.state.sum_total_sales}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>}
                                                    {this.state.membershipSalesData.length === 0 && "No records found"}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="chart" style={{ height: this.state.rightHeight + "vh" }}>
                                                    <Pie
                                                        width={150}
                                                        height={70}
                                                        data={this.state.membershipSales}
                                                        options={{
                                                            maintainAspectRatio: false
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default MembershipDashboard;