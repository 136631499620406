import React, { Component } from "react";
import Window from '../2/Controls/Window';
import Input from '../2/Controls/Input';

class NewPet extends Component {
    constructor() {
        super();
        this.state = {
            newPet: true,
            name: "",
            gender: "",
            dob: "",
            type: "",
            breed: "",
            weight: "",
            height: "",

            nameError: "",
            genderError: "",
            dobError: "",
            typeError: "",
            breedError: "",
            weightError: "",
            heightError: "",
            petTypes: [],
            petBreeds: [],
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onSavePetDetail = this.onSavePetDetail.bind(this);
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            this.onSavePetDetail();
        }
    }

    async componentDidMount() {

        let petTypes = await this.props.getPetTypes();
        let petBreeds = await this.props.getPetBreeds();

        this.setState({petTypes: petTypes, petBreeds: petBreeds});

        if (this.props.selectedPet) {
            this.setState({
                newPet: false,
                name: this.props.selectedPet.PetName,
                gender: this.props.selectedPet.Gender,
                dob: this.props.selectedPet.Dob,
                type: this.props.selectedPet.Type,
                breed: this.props.selectedPet.Breed,
                weight: this.props.selectedPet.Weight,
                height: this.props.selectedPet.Height,
            });
        }
    }

    onSavePetDetail() {
        if (this.state.name.trim() === "") {
            this.setState({ nameError: "Please enter name" });
            return;
        }

        // if (this.state.gender.trim() === "") {
        //     this.setState({ genderError: "Please Select Gender" });
        //     return;
        // }
        // if (this.state.dob === "") {
        //     this.setState({ dobError: "Please Select Date of Birth" });
        //     return;
        // }
        // if (this.state.type === "") {
        //     this.setState({ typeError: "Please Select Type" });
        //     return;
        // }
        // if (this.state.breed === "") {
        //     this.setState({ breedError: "Please Enter breed" });
        //     return;
        // }
        // if (this.state.weight == "") {
        //     this.setState({ weightError: "Please Enter Weight" });
        //     return;
        // }
        // if (this.state.height === "") {
        //     this.setState({ heightError: "Please Enter height" });
        //     return;
        // }

        let pet = {
            PetName: this.state.name,
            Gender: this.state.gender,
            Dob: this.state.dob,
            Type: this.state.type,
            Breed: this.state.breed,
            Weight: this.state.weight,
            Height: this.state.height,
        };
        this.props.onPetAdded(pet, this.props.selectedPetIndex);
        this.props.onClose();
        // console.log(pet);
    }

    render() {
        return (
            <Window 
            header={this.props.selectedAddress ? "Edit Pet Detail" :  "Add New Pet"}  
            width={"600px"}
            modal={false}
            onClose={this.props.onClose}
            onButtonClick={(buttonType)=>{
                if(buttonType.toLowerCase()==="save"){
                    this.onSavePetDetail();
                }
                else
                {
                    this.props.onClose();
                }
            }}
            buttons={[
                {Type:"secondary", Text:"Cancel", Align:"left"},
                {Type:"primary", Text:"Save", Align:"right"},
            ]}>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            label="Name"
                            onChange={(value) =>this.setState({name: value,nameError: ""})}
                            value={this.state.name}
                            error= {this.state.nameError}
                            onEnter={this.onSavePetDetail}
                        />
                    </div>
                </div>      
               <div className="row">
                    <div className="col-md-6">
                        <Input
                            type="select"
                            label="Gender"
                            value={this.state.gender}
                            onEnter={this.onSavePetDetail}
                            onChange={(value) =>this.setState({gender: value})}>
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </Input>
                    </div>
                    <div className="col-md-6">
                        <Input  
                            type="date"
                            label="Date of Birth"
                            onChange={(value) =>this.setState({dob:value})}
                            value={this.state.dob}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            type="select"
                            label="Type"
                            value={this.state.type}
                            onEnter={this.onSavePetDetail}
                            onChange={(value) =>this.setState({type: value})}
                        >
                            <option value="">Select Type</option>
                            {this.state.petTypes.map(a => <option value={a.Value}>{a.Name}</option>)}
                        </Input>
                    </div>
                    <div className="col-md-6">
                        {this.state.type !== 'Others' &&  <Input
                            type="select"
                            label="Breed"
                            value={this.state.breed}
                            onEnter={this.onSavePetDetail}
                            onChange={(value) =>this.setState({breed: value})}
                        >
                            <option value="">Select Breed</option>
                            {this.state.petBreeds.filter((f) => f.Type === this.state.type).map(a => <option value={a.Value}>{a.Name}</option>)}
                        </Input>}
                        {this.state.type === 'Others' && <Input
                            type="text"
                            label="Breed"
                            onEnter={this.onSavePetDetail}
                            onChange={(value) =>this.setState({breed: value})
                            }
                            value={this.state.breed}
                        />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            type="number"
                            label="Weight"
                            onChange={(value) =>this.setState({weight: value})}
                            onEnter={this.onSavePetDetail}
                            value={this.state.weight}
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            type="number"
                            label="Height"
                            onChange={(value) =>this.setState({height: value})}
                            onEnter={this.onSavePetDetail}
                            value={this.state.height}
                        />
                    </div>
                </div>
            </Window>
        );
    }
}

export default NewPet;
