
import React, { Component } from 'react';
import DashboardCtrl from './DashboardCtrl';

class Dashboard extends Component {

    render(){
        return <div className="content-wrapper">
        <section className="content" >
        <DashboardCtrl {...this.props}/>
        </section>
    </div>
    }
}

export default Dashboard;