export const CONFIG_PAGEFILTERS = "CONFIG_PAGEFILTERS";
export const SIGN_OUT = "SIGN_OUT";
export const SET_USER = "SET_USER";
export const SET_AUTH = "SET_AUTH";
export const SET_REGISTRATION = "SET_REGISTRATION";
export const GET_POSTS = "GET_POSTS";
export const SAVE_POST = "SAVE_POST";
export const DELETE_POST = "DELET_POST";
export const POST_FILE_UPLOADED = "POST_FILE_UPLOADED";
export const POST_NEXT_SEQUENCE_NO_UPDATED = "POST_NEXT_SEQUENCE_NO_UPDATED";
export const CHECK_BUSINESS_NAME_IS_UNIQUE = "CHECK_BUSINESS_NAME_IS_UNIQUE";
export const CREATE_REGISTRATION = "CREATE_REGISTRATION";
export const GET_REGISTRATION = "GET_REGISTRATION";
export const LOGO_FILE_UPLOADED = "LOGO_FILE_UPLOADED";
export const FAVICON_FILE_UPLOADED = "FAVICON_FILE_UPLOADED";
export const GET_PRODUCT_CONFIG = "GET_PRODUCT_CONFIG";
export const ADD_NEW_PRODUCT_CATEGORY = "ADD_NEW_PRODUCT_CATEGORY";
export const ADD_NEW_BRAND = "ADD_NEW_BRAND";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const PARK_SALE = "PARK_SALE";
export const SALE_NEW = "SALE_NEW";
export const ONLINE_ORDER = "ONLINE_ORDER";
export const SALE_RETURN = "SALE_RETURN";
export const SALE_EDIT = "SALE_EDIT";
export const SALE_DRAFT = "SALE_DRAFT";
export const STORE_EDIT = "STORE_EDIT";
export const STORE_NEW = "STORE_NEW";
export const STORELOCATION_NEW = "STORELOCATION_NEW";
export const STORELOCATION_EDIT = "STORELOCATION_EDIT";
export const CASH_REGISTER_NEW = "CASH_REGISTER_NEW";
export const CASH_REGISTER_EDIT = "CASH_REGISTER_EDIT";

export const EMPLOYEE_NEW = "EMPLOYEE_NEW";
export const EMPLOYEE_EDIT = "EMPLOYEE_EDIT";

export const CONFIG_GET_CUSTOM_FIELDS = "CONFIG_GET_CUSTOM_FIELDS";

export const GET_TAXES = "GET_TAXES";
export const ADD_NEW_TAX = "ADD_NEW_TAX";
export const EDIT_PRODUCT_TAX = "EDIT_PRODUCT_TAX";

export const PRODUCT_CATEGORY_GET = "PRODUCT_CATEGORY_GET";
export const PRODUCT_SUB_CATEGORY_GET = "PRODUCT_SUB_CATEGORY_GET";

export const TRANSACTION_TYPES_GET = "TRANSACTION_TYPES_GET";

export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const ALL = "ALL";

export const PRODUCT_CATEGORY = "PRODUCT_CATEGORY";
export const PRODUCT_SUB_CATEGORY = "PRODUCT_SUB_CATEGORY";

export const GET_ACTIVE_PRODUCTS = "GET_ACTIVE_PRODUCTS";

export const PRODUCT_BRANDS_GET = "PRODUCT_BRANDS_GET";

export const SET_STORE = "SET_STORE";
export const SET_CASH_REGISTER = "SET_CASH_REGISTER";

export const PAYMENT_TYPES_GET = "PAYMENT_TYPES_GET";

export const PRINT_CONFIG_GET = "PRINT_CONFIG_GET";
export const PUSH_PRINT_CONFIG_TO_REDUX = "PUSH_PRINT_CONFIG_TO_REDUX";

export const PUSH_PAGE_FILTERS_TO_REDUX = "PUSH_PAGE_FILTERS_TO_REDUX";

export const PUSH_PRODUCT_TO_REDUX = "PUSH_PRODUCT_TO_REDUX";

export const PUSH_PRODUCT_CATEGORIES_TO_REDUX =
  "PUSH_PRODUCT_CATEGORIES_TO_REDUX";
export const PUSH_PRODUCT_SUB_CATEGORIES_TO_REDUX =
  "PUSH_PRODUCT_SUB_CATEGORIES_TO_REDUX";
export const PUSH_PRODUCT_BRAND_TO_REDUX = "PUSH_PRODUCT_BRAND_TO_REDUX";

export const VENDOR_NEW = "VENDOR_NEW";
export const VENDOR_EDIT = "VENDOR_EDIT";

export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const EDIT_MEMBERSHIP = "EDIT_MEMBERSHIP";
export const ADD_NEW_MEMBERSHIP = "ADD_NEW_MEMBERSHIP";

export const INVENTORY_NEW = "INVENTORY_NEW";
export const INVENTORY_PARKED = "INVENTORY_PARKED";
export const INVENTORY_EDIT = "INVENTORY_EDIT";

export const SET_ACL = "SET_ACL";

export const SET_CASH_REGISTER_CLOSING = "SET_CASH_REGISTER_CLOSING";

export const PRODUCT_TAGS_GET = "PRODUCT_TAGS_GET";

export const GET_OFFER = "GET_OFFER";

export const EDIT_OFFER = "EDIT_OFFER";

export const NEW_OFFER = "NEW_OFFER";

export const MODIFIERS_GET = "MODIFIERS_GET";

export const GET_ACTIVE_CUSTOMERS = "GET_ACTIVE_CUSTOMERS";

export const GET_APPLICABLE_OFFERS = "GET_APPLICABLE_OFFERS";

export const GET_ACTIVE_EMPLOYEES = "GET_ACTIVE_EMPLOYEES";

export const GET_ACTIVE_MEMBERSHIPS = "GET_ACTIVE_MEMBERSHIPS";

export const CONFIG_GET_KEYWORDS = "CONFIG_GET_KEYWORDS";

export const SMS_EDIT = "SMS_EDIT";
export const SMS_NEW = "SMS_NEW";

export const GET_SALES_PRODUCTS = "GET_SALES_PRODUCTS";
export const GET_SALES_SUB_PRODUCTS = "GET_SALES_SUB_PRODUCTS";

export const STORE_RESET_CONFIG = "STORE_RESET_CONFIG";
export const STORE_RESET_USER = "STORE_RESET_USER";

export const CONFIG_APP_BANNER = "CONFIG_APP_BANNER";

export const CONFIG_APP_HELP_CONTENT = "CONFIG_APP_HELP_CONTENT";

//Offline
export const GET_CONNECTION_STATUS = "GET_CONNECTION_STATUS";
export const PUSH_CUSTOMER_TO_REDUX = "PUSH_CUSTOMER_TO_REDUX";

export const CONFIG_COUNTRY_CONFIG = "CONFIG_COUNTRY_CONFIG";
export const CONFIG_GET_CAROUSELS = "CONFIG_GET_CAROUSELS";

export const CONFIG_SET_SELECTED_MENU = "CONFIG_SET_SELECTED_MENU";
export const CONFIG_SET_SELECTED_SUB_MENU = "CONFIG_SET_SELECTED_SUB_MENU";

export const CONFIG_SET_NEW_ONLINE_ORDER = "CONFIG_SET_NEW_ONLINE_ORDER";

export const SET_HAS_ACCESS_TO_MULTIPLE_STORES =
  "SET_HAS_ACCESS_TO_MULTIPLE_STORES";

export const SET_PINCODE_CATEGORIES = "SET_PINCODE_CATEGORIES";
export const SET_PINCODE_SUB_CATEGORIES = "SET_PINCODE_SUB_CATEGORIES";
export const SET_PINCODE_BRANDS = "SET_PINCODE_BRANDS";
export const SET_PINCODE_UOMS = "SET_PINCODE_UOMS";
export const SET_PINCODE_PRODUCT_TYPES = "SET_PINCODE_PRODUCT_TYPES";
export const SET_PINCODE_MODELS = "SET_PINCODE_MODELS";
export const SET_PINCODE_PACKAGING = "SET_PINCODE_PACKAGING";
export const SET_PINCODE_BUNDLING = "SET_PINCODE_BUNDLING";

export const SET_STORES = "SET_STORES";
export const SET_USER_STORES = "SET_USER_STORES";

export const SET_SALES_CHANNEL = "SET_SALES_CHANNEL";
export const SET_SELECTED_SALES_CHANNEL = "SET_SELECTED_SALES_CHANNEL";
