import React, { Component } from 'react'
import Input from '../2/Controls/Input';
import Form from '../2/Controls/Form';
import Window from '../2/Controls/Window';

class AddEditProductBrand extends Component {
    constructor() {
        super()
        this.state = {
            brand: '',
            key: '',
            brandError: '',
            saving: false
        }
        this.onSaveBrand = this.onSaveBrand.bind(this)
    }

    componentDidMount() {
        let brand = ''
        let key = ''
        if (this.props.productBrand) {
            brand = this.props.productBrand.Brand
            key = this.props.productBrand.key
        }
        this.setState({ brand: brand, key: key })
    }

    async onSaveBrand() {
        this.setState({ saving:true})
        if (this.state.brand.trim() === "") {
            this.setState({ brandError: 'Please provide brand name', saving:false})
            return
        }
        let isUnique = await this.props.checkIfProductBrandIsUnique(this.state.brand.toLowerCase(), this.state.key, this.props.user.user.RegistrationID);
        if (!isUnique) {
            this.setState({ brandError: 'Brand name already exists', saving:false})
            return
        }
        else {
            
            let omniTag = '';
            let name =this.state.brand;
            omniTag = name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/ /g,"_");
            let productBrand = {
                Brand: name,
                RegistrationID: this.props.user.user.RegistrationID, IsDeleted: false,
                OMNITag:omniTag
            }
            this.props.saveProductBrand(productBrand, this.state.key).then(
                (key) => {   
                    this.setState({saving:true});
                    setTimeout(async function () { //Start the timer
                        await this.props.getProductBrands(this.props.user.user.RegistrationID);
                        this.setState({saving:false})
                        this.props.onSave(key, productBrand.Brand); 
                        this.props.getToastr("Brand saved successfully")   
                    }.bind(this), 3000) 

                })
        }
    }

    render() {

        return <React.Fragment>
            {!this.props.showWindow && <Form
                        title={(this.state.key !== "") ? "Edit Brand" : "New Brand"}
                        onClick={(action)=>{
                            if(action==="save"){
                                this.onSaveBrand();
                            }else{
                                this.props.onClose()
                            }
                        }}
                        processing={this.state.saving}
                    >
                        <Input
                            type="text"
                            label="Brand"
                            value={this.state.brand}
                            onChange={(value) => this.setState({ brand: value, attendanceDateError: '' })}
                            error={this.state.brandError}  
                            onEnter={this.onSaveBrand}
                        />
            </Form>}
            {this.props.showWindow && <Window
                header={(this.state.key !== "") ? "Edit Brand" : "New Brand"}
                onButtonClick={(action)=>{
                    if(action.toLowerCase()==="save"){
                        this.onSaveBrand();
                    }else{
                        this.props.onClose()
                    }
                }}
                buttons={[{Type:"secondary", Text:"Cancel", Align:"left"},
                    {Type:"primary", Text:"Save", Align:"right"},
                ]}
                processing={this.state.saving}
                onClose={()=>this.props.onClose()}
            >
                <Input
                type="text"
                label="Brand"
                value={this.state.brand}
                onChange={(value) => this.setState({ brand: value, attendanceDateError: '' })}
                error={this.state.brandError}  
                onEnter={this.onSaveBrand}
                />
            </Window>}
        </React.Fragment>

        // return <Form
        //     title={(this.state.key !== "") ? "Edit Brand" : "New Brand"}
        //     onClick={(action)=>{
        //         if(action==="save"){
        //             this.onSaveBrand();
        //         }else{
        //             this.props.onClose()
        //         }
        //     }}
        //     processing={this.state.saving}
        // >
            
        // </Form>
    }
}

export default AddEditProductBrand