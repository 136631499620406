import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import Loader from '../Loader'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';

class EmployeeItemReport extends Component {
    constructor() {
        super()
        this.state = {
            stores: [],
            storeList: [],
            storeID:"",
            invoices: [],
            searchText: '',
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '',
            searchProductDetail: '',
            searchEmployeeName: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            loading: true,
            exportCalled: false
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.loadEmployeeItemDetailReport = this.loadEmployeeItemDetailReport.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this);
    }

    componentDidMount() {

        // this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });


        let searchTransactionToDate = this.state.searchTransactionToDate
        let searchTransactionFromDate = this.state.searchTransactionFromDate

        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
           
            searchTransactionToDate  = this.props.location.state.searchTransactionToDate
            searchTransactionFromDate  = this.props.location.state.searchTransactionFromDate

            this.setState({
                searchTransactionToDate: searchTransactionToDate,
                searchTransactionFromDate: searchTransactionFromDate
            });
        }

        this.loadEmployeeItemDetailReport(
            this.state.recordFrom,
            storeList,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            searchTransactionFromDate,
            searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail,
            this.state.searchEmployeeName
        )
    }

    onStoreChange(e) {
        let storeList = []
        //console.log("key", e.target.value);
        if (e.target.value !== "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        //console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    // search method
    onBackClick()
    {
        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
            this.props.history.push({ pathname: "/home/reports/salesdashboard" });
        }
        else
        {
            this.props.history.push({ pathname: "/home/reports/allreport" });
        }
    };

    loadEmployeeItemDetailReport(
        from, 
        storeList,
        searchTransactionNo, searchTransactiontype,
        searchTransactionFromDate, searchTransactionToDate,
        searchCustomerDetail, searchProductDetail, searchEmployeeName) {

        console.log('storeList', storeList)

        this.setState({ loading: true });

        this.props.getEmployeeItemDetailReport(
            this.props.user.user.RegistrationID, 
            storeList,
            from, this.state.itemsCountPerPage,
            searchTransactionNo, searchTransactiontype,
            searchTransactionFromDate, searchTransactionToDate,
            searchCustomerDetail, searchProductDetail, searchEmployeeName)
            .then((result) => {
                this.setState({ invoices: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                // console.log(JSON.stringify(this.state.invoices));
                this.showPaginationStatus()
            })
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });
        this.setState({
            storeList: storeList,
            storeID: this.props.user.store.key,
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '', searchProductDetail: '', searchEmployeeName: ''
        })
        this.loadEmployeeItemDetailReport(0,storeList, "", "", this.state.searchTransactionFromDate
            , this.state.searchTransactionToDate, "", "", "")
    }

    async onExportClick() {
        this.setState({ exportCalled: true })
        let result = await this.props.getEmployeeItemDetailReport(this.props.user.user.RegistrationID, 
            this.state.storeList,
            0, 10000,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail, this.state.searchEmployeeName
        )
            // .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let invoices = []
                result.searchResult.map((data) => {
                    let invoice = {
                        "Employee Name": data.EmployeeName,
                        "Store Name ": data.StoreName,
                        //"Cash Register": data.CashRegisterName,
                        "Customer Code": data.CustomerCode,
                        "Customer Name": data.CustomerName,
                        "Customer Mobile No.": data.CustomerPhoneNo,
                        "Transaction No": data.TransactionNo,
                        "Transaction Type": data.TransactionType,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Product Type": data.ProductType,
                        "Brand": data.Brand,
                        "Category": data.Category,
                        "Sub Category": data.SubCategory,
                        "SKU": data.SKU,
                        "Product Name": data.ProductName,
                        "Retail Price": data.RetailPrice,
                        "Qty": data.Qty,
                        "Discount": data.DiscountAmount,
                        "Taxble Amount": data.TaxableAmount,
                        "Total Tax": data.TotalTax,
                        "Total": data.Amount
                    }
                    invoices.push(invoice);
                })
                const csvData = constatnts.objToCSV(invoices);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "EmployeeItemDetails.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            // })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadEmployeeItemDetailReport(
            0,
            this.state.storeList,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail,
            this.state.searchEmployeeName
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    handlePageChange(pageNumber) {

        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadEmployeeItemDetailReport(
            recordFrom,
            this.state.storeList,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,

            this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchProductDetail,
            this.state.searchEmployeeName
        )
    }

    showPaginationStatus() {
        // console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }


    render() {
      
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Employee by Product</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={this.onBackClick}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                                </div>
                                <div className="box-body">                                
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label>Store Name</label>
                                            <select className="form-control"
                                                value={this.state.storeID}
                                                onChange={this.onStoreChange}>
                                                <option value="">All Stores</option>
                                                {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                    <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Employee Name</label>
                                                <input type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ searchEmployeeName: evt.target.value })}
                                                    value={this.state.searchEmployeeName}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Transaction No</label>
                                                <input type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                    value={this.state.searchTransactionNo}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Transaction Type</label>
                                                <select type="text" className="form-control"

                                                    onChange={(evt) => this.setState({ searchTransactiontype: evt.target.value })}
                                                    value={this.state.searchTransactiontype}
                                                >
                                                    <option value="">All</option>
                                                    {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Product Name,SKU</label>
                                                <input type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ searchProductDetail: evt.target.value })}
                                                    value={this.state.searchProductDetail}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Transaction From</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                    value={this.state.searchTransactionFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Transaction To</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                    value={this.state.searchTransactionToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Customer Name,Code,Phone Number,Email</label>
                                                <input type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ searchCustomerDetail: evt.target.value })}
                                                    value={this.state.searchCustomerDetail}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        </div>
                                    <div className="row" >
                                    <div className="col-md-12" style={ {marginTop: "-25px"} }>
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginLeft: "5px" }}
                                                onClick={this.onSearchClick}
                                                disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                                onClick={this.onClearSearch} >Clear</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px", marginRight: "5px" }} onClick={this.onExportClick} > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                        </div>
                                    </div>
                                    <br></br>
                                    {this.state.loading && <Loader show={this.state.loading} />}
                                    {!this.state.loading &&
                                        <div style={{width:"auto", overflowX:"auto"}} className="scroll2">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Transaction No</th>
                                                    <th>Transaction Type</th>
                                                    <th>Transaction Date</th>
                                                    <th>Customer Detail</th>
                                                    <th>Product Type</th>
                                                    <th>Brand</th>
                                                    <th>Category</th>
                                                    <th>Sub Category</th>
                                                    <th>SKU</th>
                                                    <th>Product Name</th>
                                                    <th>Retail Price</th>
                                                    <th>Qty</th>
                                                    <th>Discount</th>
                                                    <th>Taxable Amount</th>
                                                    <th>Total Taxes</th>
                                                    <th>Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.invoices.map((invoice) =>
                                                    <tr>
                                                        <td>{invoice.EmployeeName}</td>
                                                        <td>{invoice.TransactionNo}</td>
                                                        <td>{invoice.TransactionType} 
                                                            <div>
                                                                    {invoice.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                            </div>
                                                        </td>
                                                        <td>{(new Date(invoice.TransactionDate).toISOString().split('T')[0])}</td>
                                                        <td>
                                                            <div>{invoice.CustomerName}</div>
                                                            <div>{invoice.CustomerEmailID}</div>
                                                            <div>{invoice.CustomerPhoneNo}</div>
                                                        </td>
                                                        <td>{invoice.ProductType}</td>
                                                        <td>{invoice.Brand}</td>
                                                        <td>{invoice.Category}</td>
                                                        <td>{invoice.SubCategory}</td>
                                                        <td>{invoice.SKU}</td>
                                                        <td>{invoice.ProductName}</td>
                                                        <td>{invoice.RetailPrice}</td>
                                                        <td>{invoice.Qty}</td>
                                                        <td>{invoice.DiscountAmount}</td>
                                                        <td>{invoice.TaxableAmount}</td>
                                                        <td>{invoice.TotalTax}</td>
                                                        <td>{invoice.Amount}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table> </div> }
                                    {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                        {this.state.paginationStatus}
                                    </div>}
                                    <div className="pull-right">
                                        <Pagination
                                            activePage={this.state.activePage} //this.state.activePage
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={2}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(EmployeeItemReport)