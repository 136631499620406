import firebase, {database} from '../firebase'

export function getPlans() {
    return (dispatch) => {
        return database.collection("Plans").where("IsDeleted","==", false).get().then((querySnapshot) => {
            let plans = [];
            querySnapshot.forEach((doc) => {
                let plan = {
                    key: doc.id,
                    ...doc.data(),
                };
                plans.push(plan);
            })
            return plans;
        })
    }
  }