import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as actionTypes from "../../actionTypes";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import Print from "../Sales/Print";
import UniqueNoInput from "./UniqueNoInput";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import Window from "../2/Controls/Window";
import { getUOMs } from "../../CommonFunctions";
import { isMobileApp } from "../../actions/Util";
import ActionButton from "../2/Controls/ActionButton";
import firebase, { database } from "../../firebase";

class ViewInventory extends Component {
  constructor() {
    super();
    this.state = {
      inventory: null,
      key: "",
      transactionType: null,
      showCancel: false,
      returnTransactionType: null,
      print: false,
      showEditButton: false,
      showCancelButton: false,
      showReturnButton: false,
      includePriceAndTax: false,
      printChecked: false,
      sendEmailChecked: false,
      sendSMSChecked: false,
      printData: null,
      selectedPringConfigID: "",
      selectedPrintConfigPrintDisplayName: "",
      showUniqueNoInput: false,
      selectedLineItem: null,
      selectedProduct: null,
      processing: false,
      mobileShowMoreOptions: false,
      mobileButtonWidth: 33,
      productCustomField1DisplayName: "",
      productCustomField2DisplayName: "",
      productCustomField3DisplayName: "",
      productCustomField4DisplayName: "",
      productCustomField5DisplayName: "",
      productCustomField6DisplayName: "",
      uoms: [],
      actionOptions: [],
      editText: "Edit",
      cancelText: "Cancel",
    };
    this.load = this.load.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.printFormatting = this.printFormatting.bind(this);
    this.roundUpTo2 = this.roundUpTo2.bind(this);
    this.onCancelButtonClicked = this.onCancelButtonClicked.bind(this);
    this.onPurchaseReturnClicked = this.onPurchaseReturnClicked.bind(this);
    this.onPrintClicked = this.onPrintClicked.bind(this);
    // this.onActionButtonClick = this.onActionButtonClick.bind(this);
    this.onApproveClicked = this.onApproveClicked.bind(this);
    this.onRejectClicked = this.onRejectClicked.bind(this);
    this.renderApproveConfirmation = this.renderApproveConfirmation.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.backendProcess = this.backendProcess.bind(this);
  }

  componentDidMount() {
    let uoms = getUOMs();
    this.setState({ uoms: uoms });
    this.load();
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Product") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          productCustomField1DisplayName: customField1DisplayName,
          productCustomField2DisplayName: customField2DisplayName,
          productCustomField3DisplayName: customField3DisplayName,
          productCustomField4DisplayName: customField4DisplayName,
          productCustomField5DisplayName: customField5DisplayName,
          productCustomField6DisplayName: customField6DisplayName,
        });
      }
    });
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  load() {
    const key = this.props.match.params.key;
    if (key) {
      let showCancelButton = false;
      let showEditButton = false;
      let showReturnButton = false;
      let includePriceAndTax = false;
      let showApproveRejectButton = false;
      let selectedPringConfigID = "";
      let printConfig = null;
      let printData = null;
      let editText = "Edit";
      let cancelText = "Cancel";

      this.props.getInventoryDetails(key).then(async (inventory) => {
        console.log("view inventory", inventory);
        printConfig = this.props.config.PrintConfigs.filter(
          (e) =>
            !e.IsDeleted &&
            e.TransactionTypeID === inventory.TransactionType.key
        );

        // console.log("printConfig", JSON.stringify(printConfig));

        if (printConfig.length > 0) {
          selectedPringConfigID = printConfig[0].key;
          // selectedPrintConfigPrintDisplayName =printConfig[0].PrintDisplayName
          let inventoryPrint = { ...inventory };
          this.printFormatting(inventoryPrint);
          printData = {
            Inventory: inventoryPrint,
            PrintConfig: this.props.config.PrintConfigs.filter(
              (e) => e.key === selectedPringConfigID
            )[0],
          };
          // console.log('printData', printData)
        }

        if (inventory.TransactionType.hasOwnProperty("IncludePriceAndTax")) {
          includePriceAndTax = inventory.TransactionType.IncludePriceAndTax;
        }
        let childTransaction = false;
        if (inventory.hasOwnProperty("ChildTransactionNo")) {
          //legacy code for backward compatibility
          if (inventory.ChildTransactionNo !== "") {
            let objChild = await this.props.getInventoryDetails(
              inventory.ChildTransactionKey
            );
            // console.log("objChild", objChild);
            if (
              objChild &&
              objChild.hasOwnProperty("CancellationReason") &&
              objChild.CancellationReason !== ""
            ) {
              childTransaction = false;
            } else {
              childTransaction = true;
            }
          }
        }
        if (
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "opening stock" &&
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "stock update" &&
          !inventory.hasOwnProperty("CancellationReason") &&
          !childTransaction
          // && (!inventory.hasOwnProperty('ChildTransactionNo') && inventory.ChildTransactionNo ==="")
        ) {
          showEditButton = true;
        }
        if (
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "opening stock" &&
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "stock update" &&
          !inventory.hasOwnProperty("CancellationReason") &&
          !childTransaction
          // && (inventory.hasOwnProperty('ChildTransactionNo') && inventory.ChildTransactionNo ==="")
        ) {
          showCancelButton = true;
        }
        if (
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "opening stock" &&
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "stock update" &&
          inventory.TransactionType.TransactionType.toLowerCase() !==
            "purchase return" &&
          !inventory.hasOwnProperty("CancellationReason") &&
          inventory.TransactionType.UpdateStockChecked &&
          inventory.TransactionType.TransactionMovement === "vendortostore" &&
          !childTransaction
          // && (inventory.hasOwnProperty('ChildTransactionNo') && inventory.ChildTransactionNo ==="")
        ) {
          showReturnButton = true;
        }
        if (inventory.hasOwnProperty("Converted") && inventory.Converted) {
          showEditButton = false;
        }
        if (
          inventory.hasOwnProperty("ChildTransactions") &&
          inventory.ChildTransactions.length > 0
        ) {
          showCancelButton = false;
          showEditButton = false;
        }
        let returnTransactionType = null;
        this.props.config.TransactionTypes.filter(
          (e) =>
            !e.ISDeleted &&
            e.TransactionType.toLowerCase() === "purchase return"
        ).map((t) => {
          returnTransactionType = t;
        });
        let buttonCtr = 0;
        let buttonWidth = 70;
        if (showEditButton) {
          buttonCtr = buttonCtr + 1;
        }
        if (showReturnButton) {
          buttonCtr = buttonCtr + 1;
        }
        if (showCancelButton) {
          buttonCtr = buttonCtr + 1;
        }
        buttonWidth = buttonWidth / buttonCtr;

        // let fabButtons = []
        // fabButtons.push({action:"print", text:"Print"})
        // // fabButtons.push({action:"email", text:"Email"})
        // if(showCancelButton)
        // {
        //     fabButtons.push({action:"cancel", text:"Cancel"})
        // }
        // if(showReturnButton)
        // {
        //     fabButtons.push({action:"return", text:"Return"})
        // }
        // if(showEditButton)
        // {
        //     fabButtons.push({action:"edit", text:"Edit"})
        // }
        let billLineItemQty = 0;
        let billFreeQty = 0;
        inventory.LineItems.map((m) => {
          if (!m.hasOwnProperty("LineItemQty")) {
            m.LineItemQty = Number(m.Qty);
          }
          if (!m.hasOwnProperty("FreeQty")) {
            m.FreeQty = 0;
          }
        });
        if (!inventory.hasOwnProperty("BillLineItemQty")) {
          inventory.BillLineItemQty = Number(billLineItemQty);
        }
        if (!inventory.hasOwnProperty("BillFreeQty")) {
          inventory.BillFreeQty = Number(billFreeQty);
        }

        //check for status and approval flow
        let transactionType = null;
        if (
          inventory.Status !== "Completed" &&
          inventory.Status !== "WIP" &&
          inventory.Status !== "In Transit"
        ) {
          showCancelButton = false;
          showEditButton = false;
          showReturnButton = false;

          transactionType = this.props.config.TransactionTypes.filter(
            (e) => e.key === inventory.TransactionType.key
          )[0];
          if (
            inventory.Status === "Pending First Approval" &&
            (transactionType.FirstApprover === this.props.user.user.Role ||
              transactionType.FirstApprover === this.props.user.user.RoleKey)
          ) {
            showApproveRejectButton = true;
          }
          if (
            inventory.Status === "Pending Second Approval" &&
            (transactionType.SecondApprover === this.props.user.user.Role ||
              transactionType.SecondApprover === this.props.user.user.RoleKey)
          ) {
            showApproveRejectButton = true;
          }
          if (
            inventory.Status === "Pending Final Approval" &&
            (transactionType.FinalApprover === this.props.user.user.Role ||
              transactionType.FinalApprover === this.props.user.user.RoleKey)
          ) {
            showApproveRejectButton = true;
          }
        }
        if (
          inventory.TransactionType.TransactionType.toLowerCase() ===
          "transfer out"
        ) {
          showEditButton = false;
          if (inventory.Status.toLowerCase() !== "cancelled") {
            //chekc if transfer in is cancelled
            if (
              inventory.hasOwnProperty("ChildTransactionKey") &&
              inventory.ChildTransactionKey === ""
            ) {
              showCancelButton = false;
            }
            if (
              inventory.hasOwnProperty("ChildTransactionKey") &&
              inventory.ChildTransactionKey !== ""
            ) {
              let childTransaction = await this.props.getInventoryDetails(
                inventory.ChildTransactionKey
              );
              console.log("childTransaction", childTransaction);
              if (
                childTransaction &&
                childTransaction.hasOwnProperty("Status") &&
                (childTransaction.Status.toLowerCase() === "cancelled" ||
                  childTransaction.Status.toLowerCase() === "in transit")
              ) {
                console.log("canceled child trnasfer in");
                showCancelButton = true;
                showEditButton = true;
              }
            }
          } else {
            showCancelButton = false;
          }
        }
        if (
          inventory.TransactionType.TransactionType.toLowerCase() ===
            "transfer in" &&
          inventory.Status.toLowerCase() === "completed"
        ) {
          showEditButton = false;
        }
        if (
          inventory.TransactionType.TransactionType.toLowerCase() ===
            "transfer in" &&
          inventory.Status.toLowerCase() === "in transit"
        ) {
          editText = "Approve";
          // cancelText="Cancel";
        }
        let actionOptions = [];
        actionOptions.push({ value: "print", text: "Print", icon: "print" });
        actionOptions.push({ value: "email", text: "Email", icon: "email" });
        if (showCancelButton) {
          actionOptions.push({
            value: "cancel",
            text: "Cancel",
            icon: "cancel",
          });
        }
        if (showReturnButton) {
          actionOptions.push({
            value: "return",
            text: "Return",
            icon: "return",
          });
        }
        if (showEditButton) {
          actionOptions.push({ value: "edit", text: "Edit", icon: "edit" });
        }
        let title =
          inventory.TransactionType.TransactionType +
          "- #" +
          inventory.TransactionNo;
        this.setState({
          inventory: inventory,
          title: title,
          key: key,
          returnTransactionType: returnTransactionType,
          showCancelButton: showCancelButton,
          showEditButton: showEditButton,
          showReturnButton: showReturnButton,
          includePriceAndTax: includePriceAndTax,
          selectedPringConfigID: selectedPringConfigID,
          printConfig: printConfig,
          printData: printData,
          mobileButtonWidth: buttonWidth,
          // fabButtons: fabButtons,
          actionOptions: actionOptions,
          showApproveRejectButton: showApproveRejectButton,
          transactionType: transactionType,
          editText: editText,
          cancelText: cancelText,
        });
      });
    }
  }

  async printFormatting(inventory) {
    if (inventory.TransactionType.TransactionMovement === "storetostore") {
      //get stores
      inventory.ToStore = await this.props.getStoreDetails(inventory.ToStoreID);
    }
    if (
      inventory.TransactionType.TransactionMovement === "store" &&
      inventory.TransactionType.TransactionType === "Transfer In"
    ) {
      //get stores
      if (
        inventory.hasOwnProperty("FromStoreID") &&
        inventory.FromStoreID !== ""
      ) {
        inventory.FromStore = await this.props.getStoreDetails(
          inventory.FromStoreID
        );
        // console.log("FromStore", inventory.FromStore);
      }
    }
    if (
      inventory.TransactionType.hasOwnProperty("EnableApproval") &&
      inventory.TransactionType.EnableApproval
    ) {
      //get audit trail and get latets approval record
      let approvedBy = "";
      let approvedOn = "";
      let auditTrails = await this.props.getAuditTrails(
        this.props.user.user.RegistrationID,
        "InventoryAuditTrail",
        this.state.key
      );
      for (let i = 0; i < auditTrails.length; i++) {
        if (auditTrails[i].Action === "APPROVE") {
          approvedBy = auditTrails[i].ActionBy;
          break;
        }
      }
      inventory.ApprovedBy = approvedBy;
      // console.log('approvedBy',approvedBy);
      // console.log(auditTrails)
      // .then((auditTrails) => {
      //     this.setState({ auditTrails: auditTrails })
      //     // console.log(auditTrails)
      // })
    }
    inventory.TransactionDate = new Date(
      inventory.TransactionDate.seconds * 1000
    )
      .toString()
      .substring(0, 24);
    inventory.TransactionDateShort = inventory.TransactionDate.substring(0, 15);
    // inventory.TransactionDateShort = (new Date(inventory.TransactionDate.seconds * 1000)).toString().substring(0, 15)
    inventory.AmountInWords = this.props.convertNumberToWords(
      inventory.BillAmount
    );
    inventory.LineItems.map((lineItem, index) => {
      inventory.LineItems[index].SrNo = index + 1;
    });
    let printTaxComponents = [];
    let billTaxableAmount = 0;
    inventory.LineItems.map((lineItem, index) => {
      if (lineItem.hasOwnProperty("TaxableAmount")) {
        billTaxableAmount =
          Number(billTaxableAmount) + Number(lineItem.TaxableAmount);
      }
      if (lineItem.TaxID != "") {
        lineItem.TaxComponentAmount.map((t) => {
          let indexFound = -1;
          printTaxComponents.map((pt, ptIndex) => {
            if (t.TaxType === pt.TaxType) {
              indexFound = ptIndex;
              return;
            }
          });
          if (indexFound < 0) {
            printTaxComponents.push({
              TaxType: t.TaxType,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
            });
          } else {
            printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(
              Number(printTaxComponents[indexFound].TaxAmount) +
                Number(t.TaxAmount)
            );
          }
        });
      }
    });
    inventory.PrintTaxComponents = printTaxComponents;
    inventory.BillTaxableAmount = billTaxableAmount.toFixed(2);
    inventory.LineItems.map((lineItem, index) => {
      for (var i = 0; i < printTaxComponents.length; i++) {
        if (lineItem.TaxComponentAmount.length < i + 1) {
          lineItem.TaxComponentAmount.push({
            TaxName: "",
            TaxAmount: 0,
            TaxPercentage: "",
            TaxType: "",
          });
        }
      }
    });
    console.log("printformatting", inventory);
  }

  onApproveClicked() {
    this.setState({ processing: true });
    if (this.state.key !== "") {
      let inventory = this.state.inventory;
      let status = "";
      let secondApprover = "";
      let finalApprover = "";
      let transactionType = this.state.transactionType;
      if (
        transactionType.hasOwnProperty("SecondApprover") &&
        transactionType.SecondApprover !== ""
      ) {
        secondApprover = transactionType.SecondApprover;
      }
      if (
        transactionType.hasOwnProperty("FinalApprover") &&
        transactionType.FinalApprover !== ""
      ) {
        finalApprover = transactionType.FinalApprover;
      }
      if (inventory.Status === "Pending First Approval") {
        if (secondApprover === "" && finalApprover === "") {
          status = "Completed";
        } else if (secondApprover !== "") {
          status = "Pending Second Approval";
        } else if (finalApprover !== "") {
          status = "Pending Final Approval";
        }
      } else if (inventory.Status === "Pending Second Approval") {
        if (finalApprover === "") {
          status = "Completed";
        } else if (finalApprover !== "") {
          status = "Pending Final Approval";
        }
      } else if (inventory.Status === "Pending Final Approval") {
        status = "Completed";
      }
      this.props
        .updateInventory(
          { Status: status, ReasonForRejection: "" },
          this.state.key,
          "APPROVE"
        )
        .then((key) => {
          this.props.getToastr("Transaction approved successfully");
          setTimeout(
            function() {
              //Start the timer
              this.load();
              this.setState({
                showApproveConfirmationWindow: false,
                processing: false,
              });
            }.bind(this),
            3000
          );
        });
    }
  }

  onRejectClicked(rejectionReason) {
    this.setState({ saving: true });
    let status = "REJECT";
    if (rejectionReason.trim() !== "") {
      status = status + " (" + rejectionReason + ")";
    }
    this.props
      .updateInventory(
        { Status: "WIP", ReasonForRejection: rejectionReason },
        this.state.key,
        status
      )
      .then((key) => {
        this.props.getToastr("Transaction rejected successfully");
        setTimeout(
          function() {
            //Start the timer
            this.load();
            this.setState({ showReject: false, saving: false });
          }.bind(this),
          3000
        );
      });
  }

  async onCancelButtonClicked() {
    this.setState({ processing: true });
    // console.log('this.state.inventory', this.state.inventory)
    for (let i = 0; i < this.state.inventory.LineItems.length; i++) {
      let m = this.state.inventory.LineItems[i];
      // console.log(m)
      if (m.hasOwnProperty("UniqueNoList") && m.UniqueNoList.length > 0) {
        let uniqueNoList = m.UniqueNoList;
        // console.log('uniqueNoList', uniqueNoList)
        for (let i = 0; i < uniqueNoList.length; i++) {
          // console.log('this.props.user.store.key',this.props.user.store.key)
          // console.log('uniqueNoList',uniqueNoList[i])
          // let tmp = await this.props.checkUniqueNoExist(this.props.user.store.key,
          //     uniqueNoList[i].UniqueNo1,uniqueNoList[i].UniqueNo2,uniqueNoList[i].UniqueNo3,uniqueNoList[i].UniqueNo4
          // )
          // console.log('tmp',tmp)
          let uniqueNo = null;
          uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(
            this.props.user.store.key,
            m.Product.key,
            uniqueNoList[i].UniqueNo1,
            uniqueNoList[i].UniqueNo2,
            uniqueNoList[i].UniqueNo3,
            uniqueNoList[i].UniqueNo4
          );
          // console.log('uniqueNo from server', uniqueNo)
          if (uniqueNo && uniqueNo.TransactionID !== this.state.key.trim()) {
            alert("Unique no already used. Cannot cancel record.");
            this.setState({ processing: false });
            return;
          }
        }
      }
    }
    this.setState({ showCancel: true, processing: false });
  }

  onCancel(cancellationReason) {
    this.props.cancelInventory(cancellationReason, this.state.key).then(() => {
      this.props.getToastr("Transaction cancelled successfully");
      setTimeout(
        function() {
          //Start the timer
          this.load();
          this.setState({ showCancel: false });
        }.bind(this),
        3000
      );
    });
  }

  async onPurchaseReturnClicked() {
    this.setState({ processing: true });
    let inventory = this.state.inventory;
    for (let i = 0; i < inventory.LineItems.length; i++) {
      let m = inventory.LineItems[i];
      // console.log(m)
      if (m.hasOwnProperty("UniqueNoList") && m.UniqueNoList.length > 0) {
        let uniqueNoList = m.UniqueNoList;
        for (let i = 0; i < uniqueNoList.length; i++) {
          let uniqueNo = null;
          uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(
            this.props.user.store.key,
            m.Product.key,
            uniqueNoList[i].UniqueNo1,
            uniqueNoList[i].UniqueNo2,
            uniqueNoList[i].UniqueNo3,
            uniqueNoList[i].UniqueNo4
          );
          // console.log('uniqueNo', uniqueNo)
          if (uniqueNo && uniqueNo.TransactionID !== this.state.key.trim()) {
            alert("Unique no already used. Cannot create purchase return.");
            this.setState({ processing: false });
            return;
          } else if (!uniqueNo) {
            alert("Unique no not found. Please contact support@posible.in");
            this.setState({ processing: false });
            return;
          }
          uniqueNoList[i].key = uniqueNo.key;
        }
        inventory.LineItems[i].UniqueNoList = uniqueNoList;
      }
    }
    //console.log('inventory',inventory)
    this.setState({ processing: false });
    this.props.history.push({
      pathname: "/home/inventory/inventory/",
      state: {
        type: actionTypes.INVENTORY_NEW,
        key: this.state.key,
        transactionType: this.state.returnTransactionType,
        inventory: inventory,
      },
    });
  }

  // onActionButtonClick(action) {
  //     if (action === "edit") {
  //         this.props.history.push(
  //             {
  //                 pathname: '/home/inventory/editinventory/',
  //                 state: {
  //                     type: actionTypes.INVENTORY_EDIT,
  //                     key: this.state.key,
  //                     transactionType: this.state.inventory.TransactionType,
  //                     inventory: this.state.inventory
  //                 }
  //             })
  //     }
  //     else if (action === "return") {
  //         this.onPurchaseReturnClicked();
  //     }
  //     else if (action === "cancel") {
  //         this.setState({ showCancelSales: true })
  //     }
  //     else if (action === "print") {
  //         this.onPrintClicked()
  //     }
  //     else if (action === "email") {
  //         this.onEmailClicked()
  //     }
  // }

  renderTransactionDetails() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Transaction Details:</div>
        <div>No: {this.state.inventory.TransactionNo}</div>
        <div>
          Date:{" "}
          {new Date(this.state.inventory.TransactionDate.seconds * 1000)
            .toString()
            .substring(0, 15)}
        </div>
        <div>Store Code: {this.state.inventory.Store.StoreCode}</div>
        <div>Store Name: {this.state.inventory.Store.StoreName}</div>
        <div>Ref No: {this.state.inventory.RefNo}</div>
        {this.state.inventory.StoreLocation && (
          <div>Store Location : {this.state.inventory.StoreLocation}</div>
        )}
        {/* {this.state.inventory.ParentTransactionKey && <span className="badge bg-lg bg-green">{this.state.inventory.ParentTransactionNo} </span>} */}
        {this.state.inventory.CancellationReason && (
          <span className="badge bg-lg bg-red">
            Cancellation Reason:{this.state.inventory.CancellationReason}
          </span>
        )}
        {this.state.inventory.PurchaseReturnTransactionNo && (
          <span className="badge bg-lg bg-orange">
            Purchase return done via{" "}
            {this.state.inventory.PurchaseReturnTransactionNo}{" "}
          </span>
        )}
        {this.state.inventory.TransactionType.TransactionMovement ===
          "storelocationtostorelocation" && (
          <React.Fragment>
            <div>To Location: {this.state.inventory.ToLocation}</div>
          </React.Fragment>
        )}
        <div>Status: {this.state.inventory.Status}</div>
        {this.state.inventory &&
          this.state.inventory.ParentTransactions &&
          this.state.inventory.ParentTransactions.map((m, index) => (
            <div key={index}>
              <span
                className="badge bg-lg bg-green"
                onClick={() => {
                  this.props.history.push({
                    pathname:
                      "/home/inventory/viewinventory/" + m.ParentTransactionKey,
                  });
                }}
              >
                {m.ParentTransactionNo}{" "}
              </span>
            </div>
          ))}
        {this.state.inventory &&
          this.state.inventory.ChildTransactions &&
          this.state.inventory.ChildTransactions.map((m, index) => (
            <div key={index}>
              <span
                className="badge bg-lg bg-green"
                onClick={() => {
                  this.props.history.push({
                    pathname:
                      "/home/inventory/viewinventory/" + m.ChildTransactionKey,
                  });
                }}
              >
                {m.ChildTransactionNo}{" "}
              </span>
            </div>
          ))}
      </React.Fragment>
    );
  }

  renderVendorDetails() {
    return (
      <React.Fragment>
        {this.state.inventory.Vendor && (
          <React.Fragment>
            <div className="viewsales-section-header">Vendor:</div>
            <div>
              {this.state.inventory.Vendor.VendorCode !== "" && (
                <div>Code: {this.state.inventory.Vendor.VendorCode}</div>
              )}
              <div>
                Name: {this.state.inventory.Vendor.VendorFirstName}{" "}
                {this.state.inventory.Vendor.VendorLastName}{" "}
              </div>
              {this.state.inventory.Vendor.PhoneNo !== "" && (
                <div>Phone No: {this.state.inventory.Vendor.PhoneNo}</div>
              )}
              {this.state.inventory.Vendor.EmailID !== "" && (
                <div>Email ID: {this.state.inventory.Vendor.EmailID}</div>
              )}
            </div>
          </React.Fragment>
        )}

        {/* {!this.state.inventory.Vendor &&  <React.Fragment>
                <div className="viewsales-section-header">From Store:</div>
                <div>{this.state.inventory.Store.StoreCode + " "  + this.state.inventory.Store.StoreName}</div>
                <br/>
            </React.Fragment>} */}
        {!this.state.inventory.Vendor &&
          this.state.inventory.FromStoreID &&
          this.state.inventory.FromStoreID !== "" && (
            <React.Fragment>
              <div className="viewsales-section-header">From Store:</div>
              <div>{this.state.inventory.FromStore}</div>
              <br />
            </React.Fragment>
          )}
        {!this.state.inventory.Vendor &&
          this.state.inventory.ToStoreID &&
          this.state.inventory.ToStoreID !== "" && (
            <React.Fragment>
              <div className="viewsales-section-header">To Store:</div>
              <div>{this.state.inventory.ToStore}</div>
              <br />
            </React.Fragment>
          )}
        {/* {(this.state.inventory.TransactionType.TransactionMovement === "storetostore" && this.state.inventory.TransactionType.UpdateStockInwardOrOutward === "outward") && <React.Fragment>
                <div className="viewsales-section-header">To Store:</div>
                <div>{this.state.inventory.ToStore}</div>
            </React.Fragment>}
            {((this.state.inventory.TransactionType.TransactionMovement === "storetostore" || (this.state.inventory.TransactionType.TransactionMovement === "store" && this.state.inventory.TransactionType.TransactionType.toLowerCase() === "transfer in")) && this.state.inventory.TransactionType.UpdateStockInwardOrOutward === "inward") && <React.Fragment>
                <p className="lead" style={{ marginBottom: "5px" }}>From Store:</p>
                {this.state.inventory.FromStore}
            </React.Fragment>} */}
      </React.Fragment>
    );
  }

  renderAuditTrail() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Audit Trail:</div>
        <div>Action: {this.state.inventory.Action}</div>
        <div>Action By: {this.state.inventory.ActionBy}</div>
        <div>
          Action Date:{" "}
          {new Date(this.state.inventory.ActionOn.seconds * 1000)
            .toString()
            .substring(0, 24)}
        </div>
        <AuditTrail
          {...this.props}
          module={constants.MODULE_INVENTORY}
          parentKey={this.state.key}
        />
      </React.Fragment>
    );
  }

  renderNotes() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Notes:</div>
        {this.state.inventory.Notes}
      </React.Fragment>
    );
  }

  renderLineItems() {
    return (
      <React.Fragment>
        <br />
        <div className="viewsales-section-header">Line Items:</div>
        <div className="viewsales-table-container">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Product</th>
                <th>MRP</th>
                {this.state.includePriceAndTax && <th>Supply Price</th>}
                <th>Qty</th>
                <th>Free Qty</th>
                <th>Total Qty</th>
                <th>Unit</th>
                {this.state.includePriceAndTax && <th>Sub Total</th>}
                {this.state.includePriceAndTax && <th>Dis</th>}
                {this.state.includePriceAndTax && <th>Tax</th>}
                {this.state.includePriceAndTax && <th>Tax Amt</th>}
                {this.state.includePriceAndTax && (
                  <th style={{ width: "100px" }}>Margin %</th>
                )}
                {this.state.includePriceAndTax && (
                  <th style={{ width: "100px" }}>Profit %</th>
                )}
                {this.state.includePriceAndTax && <th>Total</th>}
              </tr>
            </thead>
            <tbody>
              {this.state.inventory.LineItems.map((lineItem, index) => (
                <tr key={index}>
                  <td className="number">{index + 1}</td>
                  <td>
                    <div className="product-with-image">
                      {lineItem.Product.Images &&
                        lineItem.Product.Images.length > 0 && (
                          <div>
                            <img src={lineItem.Product.Images[0].ImagePath} />
                          </div>
                        )}
                      <div>
                        {lineItem.Product.Name}
                        <div className="sales-sub-text">
                          SKU: {lineItem.Product.SKU}
                        </div>
                        {lineItem.Product.BarCode !== "" && (
                          <div className="sales-sub-text">
                            Barcode: {lineItem.Product.BarCode}{" "}
                          </div>
                        )}
                        {lineItem.HSNSACCode !== "" && (
                          <div className="sales-sub-text">
                            HSNSACCode: {lineItem.HSNSACCode}{" "}
                          </div>
                        )}
                        <div className="sales-sub-text">
                          {lineItem.Product.hasOwnProperty("CustomField1") &&
                            lineItem.Product.CustomField1.trim() !== "" &&
                            this.state.productCustomField1DisplayName.trim() !==
                              "" && (
                              <React.Fragment>
                                {this.state.productCustomField1DisplayName}:{" "}
                                {lineItem.Product.CustomField1}
                              </React.Fragment>
                            )}
                          {lineItem.Product.hasOwnProperty("CustomField2") &&
                            lineItem.Product.CustomField2.trim() !== "" &&
                            this.state.productCustomField2DisplayName.trim() !==
                              "" && (
                              <React.Fragment>
                                , {this.state.productCustomField2DisplayName}:{" "}
                                {lineItem.Product.CustomField2}
                              </React.Fragment>
                            )}
                          {lineItem.Product.hasOwnProperty("CustomField3") &&
                            lineItem.Product.CustomField3.trim() !== "" &&
                            this.state.productCustomField3DisplayName.trim() !==
                              "" && (
                              <React.Fragment>
                                , {this.state.productCustomField3DisplayName}:{" "}
                                {lineItem.Product.CustomField3}
                              </React.Fragment>
                            )}
                          {lineItem.Product.hasOwnProperty("CustomField4") &&
                            lineItem.Product.CustomField4.trim() !== "" &&
                            this.state.productCustomField4DisplayName.trim() !==
                              "" && (
                              <React.Fragment>
                                , {this.state.productCustomField4DisplayName}:{" "}
                                {lineItem.Product.CustomField4}
                              </React.Fragment>
                            )}
                          {lineItem.Product.hasOwnProperty("CustomField5") &&
                            lineItem.Product.CustomField5.trim() !== "" &&
                            this.state.productCustomField5DisplayName.trim() !==
                              "" && (
                              <React.Fragment>
                                , {this.state.productCustomField5DisplayName}:{" "}
                                {lineItem.Product.CustomField5}
                              </React.Fragment>
                            )}
                          {lineItem.Product.hasOwnProperty("CustomField6") &&
                            lineItem.Product.CustomField6.trim() !== "" &&
                            this.state.productCustomField6DisplayName.trim() !==
                              "" && (
                              <React.Fragment>
                                , {this.state.productCustomField6DisplayName}:{" "}
                                {lineItem.Product.CustomField6}
                              </React.Fragment>
                            )}
                        </div>
                        {lineItem.hasOwnProperty("BatchNo") &&
                          lineItem.BatchNo !== "" && (
                            <div className="sales-sub-text">
                              Batch No: {lineItem.BatchNo}
                            </div>
                          )}
                        {lineItem.hasOwnProperty("BatchMRP") &&
                          lineItem.BatchMRP.toString() !== "0" && (
                            <div className="sales-sub-text">
                              Batch MRP: {lineItem.BatchMRP}
                            </div>
                          )}
                        {lineItem.hasOwnProperty("BatchRetailPrice") &&
                          lineItem.BatchRetailPrice.toString() !== "0" && (
                            <div className="sales-sub-text">
                              Batch Retail Price: {lineItem.BatchRetailPrice}
                            </div>
                          )}
                        {lineItem.hasOwnProperty("BatchMfgDate") &&
                          lineItem.BatchMfgDate !== "" && (
                            <div className="sales-sub-text">
                              Batch Mfg Date: {lineItem.BatchMfgDate}
                            </div>
                          )}
                        {lineItem.hasOwnProperty("BatchExpDate") &&
                          lineItem.BatchExpDate !== "" && (
                            <div className="sales-sub-text">
                              Batch Exp Date: {lineItem.BatchExpDate}
                            </div>
                          )}
                        {lineItem.Notes !== "" && (
                          <div className="sales-sub-text">
                            Notes: {lineItem.Notes}{" "}
                          </div>
                        )}
                        {lineItem.hasOwnProperty("ChildTransactions") &&
                          lineItem.ChildTransactions.map((m) => (
                            <div className="sales-sub-text">
                              {m.ChildTransactionNo}
                            </div>
                          ))}
                      </div>
                    </div>
                  </td>
                  <td className="number">{lineItem.Product.MRP}</td>
                  {this.state.includePriceAndTax && (
                    <td className="number">{lineItem.SupplyPrice}</td>
                  )}
                  <td className="number">
                    {lineItem.LineItemQty}
                    {lineItem.hasOwnProperty("UniqueNoList") &&
                      lineItem.UniqueNoList.length > 0 && (
                        <div>
                          <button
                            type="submit"
                            style={{ width: "100%" }}
                            className="btn btn-md btn-default btn-flat pull-right"
                            onClick={() =>
                              this.setState({
                                showUniqueNoInput: true,
                                selectedLineItem: lineItem,
                                selectedLineItemIndex: index,
                                selectedProduct: lineItem.Product,
                              })
                            }
                          >
                            {lineItem.Product.UniqueLabel.trim() !== ""
                              ? lineItem.Product.UniqueLabel
                              : "Unique No"}
                          </button>
                        </div>
                      )}
                    {lineItem.hasOwnProperty("RemainingQty") && (
                      <div>
                        <span className="badge bg-green">
                          {Number(lineItem.Qty) - Number(lineItem.RemainingQty)}
                        </span>
                      </div>
                    )}
                  </td>
                  <td className="number">{lineItem.FreeQty}</td>
                  <td className="number">{lineItem.Qty}</td>
                  <td>
                    {lineItem.hasOwnProperty("UOM")
                      ? this.state.uoms.filter((f) => f.value === lineItem.UOM)
                          .length > 0
                        ? this.state.uoms.filter(
                            (f) => f.value === lineItem.UOM
                          )[0].text
                        : lineItem.Product.UOM
                      : ""}
                    {/* {lineItem.hasOwnProperty('UOM') ? (this.state.uoms.filter((f) => f.value === lineItem.UOM)[0].text) : lineItem.Product.UOM}  */}
                  </td>
                  {/* <td>{lineItem.InStockQty}</td> */}
                  {this.state.includePriceAndTax && (
                    <td className="number">{lineItem.SubTotal}</td>
                  )}
                  {this.state.includePriceAndTax && (
                    <td className="number">
                      {lineItem.DiscountAmount}
                      <div className="sales-sub-text">
                        {lineItem.DiscountPer} %
                      </div>
                    </td>
                  )}
                  {this.state.includePriceAndTax && (
                    <td>
                      {lineItem.TaxGroup}
                      {lineItem.TaxComponentAmount &&
                        lineItem.TaxComponentAmount.map((t) => (
                          <div className="sales-sub-text">
                            {t.TaxName} {t.TaxPercentage}%:{t.TaxAmount}
                          </div>
                        ))}
                    </td>
                  )}
                  {this.state.includePriceAndTax && (
                    <td className="number">{lineItem.TotalTax}</td>
                  )}
                  {this.state.includePriceAndTax && (
                    <td className="number">
                      {lineItem.hasOwnProperty("MarginPercentage")
                        ? lineItem.MarginPercentage
                        : 0}
                    </td>
                  )}
                  {this.state.includePriceAndTax && (
                    <td className="number">
                      {lineItem.hasOwnProperty("ProfitPercentage")
                        ? lineItem.ProfitPercentage
                        : 0}
                    </td>
                  )}
                  {this.state.includePriceAndTax && (
                    <td className="number">{lineItem.Amount}</td>
                  )}
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                {this.state.includePriceAndTax && <td></td>}
                <td className="number">
                  <b>{this.state.inventory.BillLineItemQty}</b>
                </td>
                <td className="number">
                  <b>{this.state.inventory.BillFreeQty}</b>
                </td>
                <td className="number">
                  <b>{this.state.inventory.BillQty}</b>
                </td>
                <td></td>
                {this.state.includePriceAndTax && (
                  <td className="number">
                    <b>{this.state.inventory.BillSubTotal}</b>
                  </td>
                )}
                {this.state.includePriceAndTax && (
                  <td className="number">
                    <b>{this.state.inventory.BillDiscount}</b>
                  </td>
                )}
                {this.state.includePriceAndTax && <td></td>}
                {this.state.includePriceAndTax && (
                  <td className="number">
                    <b>{this.state.inventory.BillTaxAmount}</b>
                  </td>
                )}
                {this.state.includePriceAndTax && <td></td>}
                {this.state.includePriceAndTax && <td></td>}
                {this.state.includePriceAndTax && (
                  <td className="number">
                    <b>
                      {this.state.inventory.hasOwnProperty("BillLineItemTotal")
                        ? this.state.inventory.BillLineItemTotal
                        : this.state.inventory.BillAmount}
                    </b>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  renderTaxSummaryList() {
    return (
      <React.Fragment>
        {!this.state.includePriceAndTax && (
          <React.Fragment>
            <div className="viewsales-section-header">HSN Summary:</div>
            {this.state.inventory.HSNSummary && (
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>HSN</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.inventory.HSNSummary.map((t, index) => (
                    <tr key={index}>
                      <td>{t.HSNSACCode}</td>
                      <td style={{ textAlign: "right" }}>
                        {t.hasOwnProperty("TotalQty") ? t.TotalQty : 0}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </React.Fragment>
        )}
        {this.state.includePriceAndTax && (
          <React.Fragment>
            <div className="viewsales-section-header">Tax Summary:</div>
            {this.state.inventory.TaxSummaryList && (
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>HSN</th>
                    <th>Qty</th>
                    <th>Taxable Amount</th>
                    <th>Tax</th>
                    <th>Tax Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.inventory.TaxSummaryList.map((t, index) => (
                    <tr key={index}>
                      <td>{t.HSNSACCode}</td>
                      <td style={{ textAlign: "right" }}>
                        {t.hasOwnProperty("TotalQty") ? t.TotalQty : 0}
                      </td>
                      <td style={{ textAlign: "right" }}>{t.TaxableAmount}</td>
                      <td>{t.TaxGroup}</td>
                      <td style={{ textAlign: "right" }}>{t.TotalTax}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderTotals() {
    return (
      <React.Fragment>
        {this.state.includePriceAndTax && (
          <React.Fragment>
            <div className="viewsales-section-header">Totals:</div>
            <div className="viewsales-table-container">
              <table className="table ">
                <tbody>
                  <tr>
                    <td style={{ width: "50%" }}>Additional Discount:</td>
                    <td className="text-align-right">
                      {this.state.inventory.BillAdditionalDiscount}
                    </td>
                  </tr>
                  <tr>
                    <td>Additional Charges:</td>
                    <td className="text-align-right">
                      {this.state.inventory.BillAdditionalCharges}
                    </td>
                  </tr>

                  <tr>
                    <td>Round Off:</td>
                    <td className="text-align-right">
                      {this.state.inventory.BillRoundOff}
                    </td>
                  </tr>
                  <tr>
                    <td>Total Amount:</td>
                    <td className="text-align-right">
                      {this.state.inventory.BillAmount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  async onPrintClicked() {
    if (!isMobileApp()) {
      let inventoryPrint = { ...this.state.inventory };
      await this.printFormatting(inventoryPrint);
      let printData = {
        Inventory: inventoryPrint,
        PrintConfig: this.props.config.PrintConfigs.filter(
          (e) => e.key === this.state.selectedPringConfigID
        )[0],
      };
      this.setState({ print: true, printChecked: true, printData: printData }); //TBDpawan
    }
  }

  async backendProcess() {
    //firtst check if instock history has this line item transaciton or not
    //if not then create new entry in instock ...this will create entry in instock
    let inventory = this.state.inventory;
    console.log("this.state.key", this.state.key);
    console.log("inventory", inventory);
    let newBatchCount = 0;
    for (let i = 0; i < inventory.LineItems.length; i++) {
      console.log("ctr", i);
      let lineItem = inventory.LineItems[i];
      if (lineItem.Product.InventoryType === "Batch Tracking") {
        // console.log('batch tracking')
        let batch = null;
        batch = await database
          .collection("Batches")
          .where("StoreID", "==", inventory.Store.key)
          .where("StoreLocationID", "==", inventory.StoreLocationID)
          .where("ProductID", "==", lineItem.Product.key)
          .where("BatchNo", "==", lineItem.BatchNo)
          .where("BatchMRP", "==", lineItem.BatchMRP)
          .where("BatchRetailPrice", "==", lineItem.BatchRetailPrice)
          .where("BatchMfgDate", "==", lineItem.BatchMfgDate)
          .where("BatchExpDate", "==", lineItem.BatchExpDate)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              batch = doc.data();
              batch.key = doc.id;
            });
            // console.log('checkBatchExist', batch)
            return batch;
          });
        if (!batch) {
          console.log("ctr", i);
          console.log("lineItem", lineItem);
          newBatchCount = newBatchCount + 1;
          // await database.collection("Batches").add(
          console.log({
            StoreID: inventory.Store.key,
            StoreLocationID: inventory.StoreLocationID,
            ProductID: lineItem.Product.key,
            BatchNo: lineItem.BatchNo,
            BatchMRP: lineItem.BatchMRP,
            BatchRetailPrice: lineItem.BatchRetailPrice,
            BatchMfgDate: lineItem.BatchMfgDate,
            BatchExpDate: lineItem.BatchExpDate,
            Qty: lineItem.Qty,
            SupplyPrice: lineItem.SupplyPrice,
            BatchMfgDateValue: null,
            BatchExpDateValue: null,
            TransactionNo: inventory.TransactionNo,
            TransactionID: this.state.key,
            TransactionQty: lineItem.Qty,
            BarCode: inventory.LineItems[i].Product.BarCode,
            Brand: inventory.LineItems[i].Product.Brand,
            Category: inventory.LineItems[i].Product.Category,
            ProductCustomField1: inventory.LineItems[i].Product.hasOwnProperty(
              "CustomField1"
            )
              ? inventory.LineItems[i].Product.CustomField1
              : "",
            ProductCustomField2: inventory.LineItems[i].Product.hasOwnProperty(
              "CustomField2"
            )
              ? inventory.LineItems[i].Product.CustomField2
              : "",
            ProductCustomField3: inventory.LineItems[i].Product.hasOwnProperty(
              "CustomField3"
            )
              ? inventory.LineItems[i].Product.CustomField3
              : "",
            ProductCustomField4: inventory.LineItems[i].Product.hasOwnProperty(
              "CustomField4"
            )
              ? inventory.LineItems[i].Product.CustomField4
              : "",
            ProductCustomField5: inventory.LineItems[i].Product.hasOwnProperty(
              "CustomField5"
            )
              ? inventory.LineItems[i].Product.CustomField5
              : "",
            ProductCustomField6: inventory.LineItems[i].Product.hasOwnProperty(
              "CustomField6"
            )
              ? inventory.LineItems[i].Product.CustomField6
              : "",
            ProductName: inventory.LineItems[i].Product.Name,
            ProductSKU: inventory.LineItems[i].Product.SKU,
            SubCategory: inventory.LineItems[i].Product.SubCategory,
            StoreCode: inventory.Store.StoreCode,
            StoreName: inventory.Store.StoreName,
            StoreLocation: inventory.StoreLocation,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }
        // console.log('batch',batch)
      }
      console.log("newBatchCount", newBatchCount);
      // if(inStockAuditTrail.filter(f=> f.ProductID === lineItem.Product.key).length ===0){
      //     //entry not found in audit trail
      //     console.log('ctr', i);
      //     console.log('lineItem',lineItem)
      // }
    }
    //if item is bactch then create entry in batch table
    //..now either batch will be existing or a new batch is to be created in that store
  }
  renderApproveConfirmation() {
    return (
      <React.Fragment>
        {this.state.showApproveConfirmationWindow && (
          <Window
            header={"Approve Confirmation"}
            height={"200px"}
            modal={true}
            processing={this.state.processing}
            onClose={() =>
              this.setState({ showApproveConfirmationWindow: false })
            }
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "yes") {
                this.onApproveClicked();
              } else {
                this.setState({ showApproveConfirmationWindow: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "No", Align: "left" },
              { Type: "primary", Text: "Yes", Align: "right" },
            ]}
          >
            <div>Are you sure you wish to approve this transaction?</div>
          </Window>
        )}
      </React.Fragment>
    );
  }

  onBackClick() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state &&
      this.props.location.state.type &&
      this.props.location.state.type === "pendingapprovals"
    ) {
      this.props.history.push({ pathname: "/home/pendingapprovals" });
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state &&
      this.props.location.state.type &&
      this.props.location.state.type === "intransit"
    ) {
      this.props.history.push({ pathname: "/home/inventory/intransit" });
    } else {
      this.props.history.push({ pathname: "/home/inventory/inventorylist" });
    }
  }
  renderFooter() {
    return (
      <div className="inventory-footer">
        <div className="inventory-footer-sub">
          <div className="inventory-footer-sub-50">
            <table>
              <tbody>
                <tr>
                  <td>
                    {this.state.inventory.Status === "Completed" && (
                      <button
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/home/barcodeprint/",
                            state: {
                              data: this.state.inventory,
                              transactionType: "transaction",
                            },
                          });
                        }}
                        className="btn btn-md btn-default btn-flat"
                      >
                        <i className="fa fa-barcode"></i>Print Barcode
                      </button>
                    )}
                  </td>
                  <td>
                    {this.state.selectedPringConfigID !== "" && (
                      <button
                        onClick={() => {
                          this.onPrintClicked();
                        }}
                        className="btn btn-md btn-default btn-flat"
                      >
                        Print
                      </button>
                    )}
                  </td>
                  <td>
                    {this.state.selectedPringConfigID !== "" && (
                      <select
                        className="form-control input-md"
                        value={this.state.selectedPringConfigID}
                        onChange={(evt) =>
                          this.setState({
                            selectedPringConfigID: evt.target.value,
                            selectedPrintConfigPrintDisplayName:
                              evt.target.options[evt.target.selectedIndex].text,
                          })
                        }
                      >
                        {this.props.config.PrintConfigs.filter(
                          (e) =>
                            !e.IsDeleted &&
                            e.TransactionTypeID ===
                              this.state.inventory.TransactionType.key
                        ).map((t, index) => (
                          <option key={index} value={t.key}>
                            {t.PrintDisplayName}
                          </option>
                        ))}
                      </select>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="inventory-footer-sub-50">
            {/* <Link to={{ pathname: "/home/inventory/inventorylist", state: { type: "list" } }}
                    disabled={this.state.processing}
                    className="btn btn-flat btn-default pull-right"><i className="fa fa-arrow-left"></i> Back</Link> */}

            {/* <button type="button"
                    className="btn btn-flat btn-default pull-right"
                    onClick={this.backendProcess}>
                    <i className="fa fa-arrow-left"></i> Backend processing
                </button> */}
            <button
              type="button"
              className="btn btn-flat btn-default pull-right"
              // style={{ marginRight: "5px" }}
              disabled={this.state.processing}
              onClick={this.onBackClick}
            >
              <i className="fa fa-arrow-left"></i> Back
            </button>
            {this.state.showEditButton && (
              <Link
                to={{
                  pathname: "/home/inventory/editinventory/",
                  state: {
                    type: actionTypes.INVENTORY_EDIT,
                    key: this.state.key,
                    transactionType: this.state.inventory.TransactionType,
                    inventory: this.state.inventory,
                  },
                }}
                type="button"
                style={{ marginRight: "5px" }}
                disabled={this.state.processing}
                className="btn btn-info btn-flat pull-right"
              >
                <i className="fa fa-file-o"></i>
                {this.state.editText}{" "}
                {this.state.inventory.TransactionType.TransactionType}
              </Link>
            )}
            {this.state.showReturnButton && (
              <button
                type="button"
                className="btn btn-flat btn-warning pull-right"
                style={{ marginRight: "5px" }}
                disabled={this.state.processing}
                onClick={this.onPurchaseReturnClicked}
              >
                <i className="fa fa-minus-circle"></i>Return
              </button>
            )}
            {this.state.showCancelButton && (
              <button
                type="button"
                className="btn btn-flat btn-danger pull-right"
                style={{ marginRight: "5px" }}
                disabled={this.state.processing}
                onClick={this.onCancelButtonClicked}
              >
                <i className="fa fa-minus-circle"></i> {this.state.cancelText}{" "}
                {this.state.inventory.TransactionType.TransactionType}
              </button>
            )}
            {this.state.showApproveRejectButton && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-flat btn-warning pull-right"
                  style={{ marginRight: "5px" }}
                  disabled={this.state.processing}
                  onClick={() => {
                    this.setState({ showReject: true });
                  }}
                >
                  <i className="fa fa-minus-circle"></i>Reject
                </button>
                <button
                  type="button"
                  className="btn btn-flat btn-success pull-right"
                  style={{ marginRight: "5px" }}
                  disabled={this.state.processing}
                  onClick={() => {
                    this.setState({ showApproveConfirmationWindow: true });
                  }}
                >
                  <i className="fa fa-minus-circle"></i>Approve
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.print && (
          <Page
            title={this.state.title}
            onClick={(action) => {
              if (action === "cancel") {
                this.props.history.push({
                  pathname: "/home/inventory/inventorylist",
                });
              }
            }}
          >
            {this.state.inventory && (
              <React.Fragment>
                <PageSection>
                  <div className="row">
                    <div className="col-md-3 inventory-section">
                      {this.renderTransactionDetails()}
                    </div>
                    <div className="col-md-3 inventory-section">
                      {this.renderVendorDetails()}
                    </div>
                    <div className="col-md-3 inventory-section"></div>
                    {!isMobileApp() && (
                      <div className="col-md-3 inventory-section">
                        {this.renderAuditTrail()}
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12 inventory-section">
                      {this.renderLineItems()}
                    </div>
                  </div>
                  {!isMobileApp() && (
                    <div className="row">
                      <div className="col-md-4 inventory-section">
                        {this.renderNotes()}
                      </div>
                      <div className="col-md-4 inventory-section">
                        {this.renderTaxSummaryList()}
                      </div>
                      <div className="col-md-4 inventory-section">
                        {this.renderTotals()}
                      </div>
                    </div>
                  )}
                  {isMobileApp() && (
                    <div className="row">
                      <div className="col-md-12 inventory-section">
                        {this.renderTotals()}
                      </div>
                      <div className="col-md-12 inventory-section">
                        {this.renderTaxSummaryList()}
                      </div>
                      <div className="col-md-12 inventory-section">
                        {this.renderNotes()}
                      </div>
                      <div className="col-md-12 inventory-section">
                        {this.renderAuditTrail()}
                      </div>
                    </div>
                  )}
                  <br />
                  <br />
                  <br />
                </PageSection>

                {!isMobileApp() && this.renderFooter()}
              </React.Fragment>
            )}
          </Page>
        )}

        {isMobileApp() && (
          <ActionButton
            icon="option"
            type="option"
            onClick={(action) => {
              if (action === "print") {
                this.onPrintClicked();
              } else if (action === "email") {
                this.onEmailClicked();
              } else if (action === "cancel") {
                this.onCancelButtonClicked();
              } else if (action === "return") {
                this.onPurchaseReturnClicked();
              } else if (action === "edit") {
                this.props.history.push({
                  pathname: "/home/inventory/editinventory/",
                  state: {
                    type: actionTypes.INVENTORY_EDIT,
                    key: this.state.key,
                    transactionType: this.state.inventory.TransactionType,
                    inventory: this.state.inventory,
                  },
                });
              }
            }}
            options={this.state.actionOptions}
          />
        )}

        {this.renderApproveConfirmation()}

        {this.state.showCancel && (
          <Window
            header={"Cancellation Reason"}
            height={"300px"}
            modal={true}
            processing={this.state.saving}
            onClose={() => {
              this.setState({ showCancel: false });
            }}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "save") {
                this.onCancel(this.state.cancellationReason);
              } else {
                this.setState({ showCancel: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <textarea
              className="textarea"
              style={{ width: "100%", height: "150px" }}
              onChange={(evt) =>
                this.setState({ cancellationReason: evt.target.value })
              }
              value={this.state.cancellationReason}
            ></textarea>
          </Window>
        )}

        {this.state.showUniqueNoInput && (
          <UniqueNoInput
            {...this.props}
            mobile={this.props.isMobileApp()}
            transactionID={this.state.key}
            product={this.state.selectedProduct}
            lineItem={this.state.selectedLineItem}
            mode={"view"}
            onOK={(uniqueNoList) => {
              this.setState({ showUniqueNoInput: false });
            }}
            onClose={() => this.setState({ showUniqueNoInput: false })}
          />
        )}

        {this.state.print && (
          <Print
            {...this.props}
            printChecked={this.state.printChecked}
            sendEmailChecked={this.state.sendEmailChecked}
            sendSMSChecked={this.state.sendSMSChecked}
            printData={this.state.printData}
            onClose={() => {
              this.setState({ print: false });
              this.load();
            }}
          />
        )}

        {this.state.showReject && (
          <Window
            header={"Rejection Reason"}
            height={"300px"}
            modal={true}
            processing={this.state.saving}
            onClose={() => {
              this.setState({ showReject: false });
            }}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "save") {
                this.onRejectClicked(this.state.rejectionReason);
              } else {
                this.setState({ showReject: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Save", Align: "right" },
            ]}
          >
            <textarea
              className="textarea"
              style={{ width: "100%", height: "150px" }}
              onChange={(evt) =>
                this.setState({ rejectionReason: evt.target.value })
              }
              value={this.state.rejectionReason}
            ></textarea>
          </Window>
        )}
      </React.Fragment>
    );
  }
}

export default ViewInventory;

{
  /* {this.props.isMobileApp() && <React.Fragment>
                <HeaderNew
                    {...this.props}
                    back={true}
                    title={'View ' + this.props.location.state.transactionType}
                    onBackClick={() => { this.props.history.push({ pathname: '/home/inventory/inventoryhistory' }) }}
                />
                <div className="header-new-background" />

                {!this.state.inventory && <Spinner />}

                {this.state.inventory && <div className="content-wrapper no-search" >
                    <div className="container">
                        {this.renderTransactionDetails()}
                        {this.renderVendorDetails()}
                        {this.renderNotes()}
                        {this.renderLineItems()}
                        {this.renderTaxSummaryList()}
                        {this.renderTotals()}
                        {this.renderAuditTrail()}
                        <br />
                        <br />
                        <br />
                    </div>
                </div>}
                <div className="hidden-md hidden-lg">
                                <br></br>
                                <br></br>
                            </div>
                            <div
                                className="hidden-md hidden-lg"
                                style={{
                                    height: this.state.mobileShowMoreOptions ? "100px" : "46px",
                                    backgroundColor: "white",
                                    paddingTop: "0px",
                                    position: "fixed",
                                    left: "0",
                                    bottom: "0",
                                    borderTop: "solid 1px #ddd",
                                    width: "100%"
                                }}>
                                <div className="input-group" >
                                    <span className="input-group-btn">
                                        <Link
                                            to={{ pathname: "/home/inventory/inventorylist", state: { type: "list" } }}
                                            style={{ backgroundColor: "white", width: "15%" }}
                                            className="btn btn-flat btn-lg btn-default"><i className="fa fa-arrow-left"></i>
                                        </Link>
                                        <button type="button"
                                            onClick={() => {
                                                this.setState({ mobileShowMoreOptions: !this.state.mobileShowMoreOptions })
                                            }}
                                            className="btn btn-default btn-flat btn-lg"
                                            style={{ backgroundColor: "white", width: "15%" }}>
                                            <i className="fa fa-ellipsis-v"></i>
                                        </button>
                                        {this.state.showEditButton && <Link
                                            to={
                                                {
                                                    pathname: '/home/inventory/editinventory/',
                                                    state: { type: actionTypes.INVENTORY_EDIT, key: this.state.key, transactionType: this.state.inventory.TransactionType, inventory: this.state.inventory }
                                                }}
                                            type="button"
                                            style={{ width: this.state.mobileButtonWidth + "%" }}
                                            className="btn btn-info btn-lg btn-flat">
                                            Edit
                    </Link>}
                                        {this.state.showReturnButton && <a
                                            onClick={this.onPurchaseReturnClicked}
                                            type="button" className="btn btn-lg btn-warning btn-flat"
                                            style={{ width: this.state.mobileButtonWidth + "%" }}>
                                            Return
                    </a>}
                                        {this.state.showCancelButton && <a type="button" className="btn btn-lg btn-flat btn-danger"
                                            style={{ width: this.state.mobileButtonWidth + "%" }}
                                            onClick={this.onCancelButtonClicked}>
                                            Cancel
                    </a>}
                                    </span>
                                </div>
                                {this.state.mobileShowMoreOptions && <div className="input-group" style={{ width: "100%" }}>
                                    <button type="button" className="btn btn-default btn-flat btn-lg"
                                        style={{ backgroundColor: "white", width: "20%", border: "0px" }}
                                        onClick={this.onPrintClicked}
                                    >
                                        <i className="fa fa-print"></i>
                                        <div style={{ fontSize: "10px" }}>print</div>
                                    </button>
                                    <button type="button" className="btn btn-default btn-flat btn-lg"
                                        style={{ backgroundColor: "white", width: "20%", border: "0px" }}
                                        onClick={this.onEmailClicked}
                                    >
                                        <i className="fa fa-envelope"></i>
                                        <div style={{ fontSize: "10px" }}>email</div>
                                    </button>
                                </div>}
                            </div> 
                <ActionButton
                    onClick={(action) => this.onActionButtonClick(action)}
                    fabButtons={this.state.fabButtons} />
            </React.Fragment>} */
}
