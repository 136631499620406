import React, { Component } from 'react'
import './Footer.css'

class Footer extends Component {

    constructor () {
        super()
        this.state = {
            buttonText: "",
            buttonWidth:100,
        }
      }

      componentDidMount(){
        if(this.props.buttons)
        {
            let buttonWidth = 100/this.props.buttons.length;
            this.setState({buttonWidth:buttonWidth});
        }
      }

    render() {
        return <div className="footer">

                {!this.props.buttons &&  <button 
                    type="button" 
                    className="footer-button-save" 
                    disabled={this.props.disabled}
                    onClick={() => this.props.onClick("save")}>{(this.props.buttonText && this.props.buttonText !== "") ? this.props.buttonText : "Save"}
                </button>}

                {this.props.buttons && <React.Fragment>
                    {this.props.buttons.map((m,index) => <button 
                        type="button"
                        disabled={this.props.disabled}
                        key={"footer_button_" + index} 
                        style={{width: this.state.buttonWidth  + "%"}}
                        className={m.type==="primary" ?  "footer-button-primary" : "footer-button-secondary" }
                        onClick={() => {
                            // if(!this.props.disabled)
                            // {
                                this.props.onClick(m.action)
                            // }
                        }}>
                            {m.text}
                    </button>)}
                </React.Fragment>}
                
            </div>
        }
    }

export default Footer