import React, { Component } from 'react';
import ImportMaster from "../ImportMaster";
import Confirm from 'react-confirm-bootstrap';
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';

class NewProductTags extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            name: '',
            description: '',
            nameError: '',
            optionError:'',
            key: '',
            showImport: false,
            records: [],
            selectAll:false,
            productsTypeAheadOptions: [], 
            isProductTypeAheadLoading: false
        }
        this.onSaveTags = this.onSaveTags.bind(this);
        this.onDeleteItems = this.onDeleteItems.bind(this);
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)

    }

    componentDidMount() {
        
        if(this.props.productGroup)
        {
            this.setState(
                {
                    name: this.props.productGroup.Name,
                    description: this.props.productGroup.Description,
                    records: this.props.productGroup.Products,
                    key:this.props.productGroup.key
                }
            )
        }
    }

    onSaveTags() {
        this.setState({ saving: true })
        if (this.state.name.trim() === "") {
            this.setState({ nameError: "Please provide name", saving: false })
            return
        }

        let omniTag = '';
        let name =this.state.name;
        omniTag = name.replace(/[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g, '').replace(/ /g,"_"); 

        let tag = {
            Name: name,
            Description: this.state.description,
            RegistrationID: this.props.user.user.RegistrationID,
            Products: this.state.records,
            IsDeleted: false,
            OMNITag:omniTag
        }
        const key = this.state.key ? this.state.key : "";

        this.props.saveProductTag(key, tag).then(() => {
            setTimeout(function () { //Start the timer
                this.props.getToastr("Product group saved successfully")
                this.setState({ saving: false })
                this.props.onClose();
            }.bind(this), 3000)
        })

    }

    onDeleteItems() {
        let recordsNew = []
        this.state.records.map(m=>{
            if(!m.Selected){
                recordsNew.push(m)
            }
        })
        this.setState({ records:recordsNew})
    }

    onProductTypeAheadSearch(query) {
        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProducts(this.props.user.user.RegistrationID, 0, 100, "active", query, "", "", "", "", "", "", "", true)
            .then((result) => {
                console.log('result',result)
                // let r = []
                // result.searchResult.map(m=>{
                //     if(m.ProductTypeID ===1 || m.ProductTypeID===2 || m.ProductTypeID===3)
                //     {
                //         if(m.hasOwnProperty('BOM') && m.BOM)
                //         {
                //             r.push(m)
                //         }
                //     }
                // })
                this.setState({ productsTypeAheadOptions: result.searchResult, isProductTypeAheadLoading: false })
            })
    }


    render() {
        return <Form
            title="Product Group"
            onClick={(action)=>{
                if(action==="save"){
                    this.onSaveTags();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
            <Input type="text" label="Name" placeholder="Product group name" value={this.state.name} onChange={(value)=>this.setState({name:value,nameError:""})} error={this.state.nameError}/>
            <Input type="text" label="Description" placeholder="Product group description" value={this.state.description} onChange={(value)=>this.setState({description:value})}/>
            <Input
                type="typeaheadsearch"
                label="Search and add product"
                options={this.state.productsTypeAheadOptions}
                labelKey="Name"
                filterBy={['Name', 'SKU', 'BarCode']}
                onSearch={this.onProductTypeAheadSearch}
                selected={[]}
                columns={[
                    {Column:"Name", Filter:false,SelectedText:true, SearchText:true, Main:true },
                    {Column:"SKU", Filter:true, SearchText:true,SelectedText:true }
                ]}
                onChange={(selected) => {
                    console.log('selected',selected)
                    if(!selected){
                        return;
                    }
                    if(this.state.records.filter(f=>f.ProductID === selected.key).length > 0){
                        alert('Product already added');
                        return;
                    }
                    let records = this.state.records;
                    records.push({
                        Name: selected.Name,
                        ProductID: selected.key,
                        SKU:selected.SKU
                    })    
                    this.setState({ records: records})
                }}
                renderMenuItemChildren={(option, props) => (
                    <div>
                        <div><b>{option.Name}</b></div>
                        <div>{option.SKU}</div>
                        <div>{(option.hasOwnProperty('CustomField1') && option.CustomField1.trim() !== "" && this.state.productCustomField1DisplayName.trim() !== "") && <React.Fragment>{this.state.productCustomField1DisplayName}: {option.CustomField1}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField2') && option.CustomField2.trim() !== "" && this.state.productCustomField2DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField2DisplayName}: {option.CustomField2}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField3') && option.CustomField3.trim() !== "" && this.state.productCustomField3DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField3DisplayName}: {option.CustomField3}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField4') && option.CustomField4.trim() !== "" && this.state.productCustomField4DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField4DisplayName}: {option.CustomField4}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField5') && option.CustomField5.trim() !== "" && this.state.productCustomField5DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField5DisplayName}: {option.CustomField5}</React.Fragment>}
                            {(option.hasOwnProperty('CustomField6') && option.CustomField6.trim() !== "" && this.state.productCustomField6DisplayName.trim() !== "") && <React.Fragment>, {this.state.productCustomField6DisplayName}: {option.CustomField6}</React.Fragment>}
                        </div>
                    </div>
                )}
                onClick={(type)=>{
                    if(type==="remove"){
                        this.setState({selectedProduct:null})
                    }
                }}
            />
            <ImportMaster   
                {...this.props}
                importType="producttags"
                onClose={() => this.setState({ showImport: false })}
                onImportCompletion={(importRecords) => 
                    {
                        let records = this.state.records
                        for(let i=0; i<importRecords.length; i++)
                        {
                            if(records.filter(f=> f.ProductID ===importRecords[i].ProductID ).length ===0)
                            {
                                //new product 
                                records.push(importRecords[i])
                            }
                        }
                        this.setState({ records: records, showImport: false })
                    }
                }
            />
            <br/>
            {this.state.records.length> 0 && <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <b >Selected Product(s) : {this.state.records.length} </b>
                    </div>
                    <div className="col-md-6" style={{paddingBottom:"10px"}}>
                        <Confirm
                            onConfirm={() => this.onDeleteItems()}
                            body="Are you sure you would like to remove selected product(s)?"
                            confirmText="Yes"
                            title="Remove product(s)">
                            <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i>Remove Product(s)</button>
                        </Confirm>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12" >       
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <input type="checkbox"
                                            checked={this.state.selectAll}
                                            onChange={() => {
                                                let records = this.state.records
                                                records.map((b) => {b.Selected = !this.state.selectAll})
                                                this.setState({ selectAll: !this.state.selectAll, records: records })
                                            }}
                                        />
                                    </th>
                                    <th>SKU</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.records.map((product,index) =>
                                    <tr>
                                        <td>
                                            <input type="checkbox"
                                                checked={product.Selected}
                                                onChange={() => {
                                                    let records = this.state.records
                                                    records[index].Selected = !records[index].Selected
                                                    this.setState({ records: records })
                                                }}
                                            />
                                        </td>
                                        <td>{product.SKU}
                                        </td>
                                        <td>{product.Name}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
        </React.Fragment>}
    </Form>
    }
}

export default NewProductTags