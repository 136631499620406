import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Form from "../2/Controls/Form";

class PayBill extends Component {
  constructor() {
    super();
    this.state = {
      orderID: "",
      orderDate: "",
      amount: 0,
      breakUp: [],
      status: "",
    };
  }

  componentDidMount() {
    let selectedRecord = this.props.selectedRecord;

    this.setState({
      orderID: selectedRecord ? selectedRecord.OrderID : "",
      orderDate: selectedRecord
        ? new Date(selectedRecord.CreatedOn).toString().substring(0, 24)
        : "",
      amount:
        selectedRecord && selectedRecord.Price ? selectedRecord.Price : "",
      breakUp:
        selectedRecord && selectedRecord.BreakUp ? selectedRecord.BreakUp : [],
      status:
        selectedRecord && selectedRecord.State ? selectedRecord.State : "",
      customerPhone:
        selectedRecord && selectedRecord.CustomerPhone
          ? selectedRecord.CustomerPhone
          : "",
      customerName:
        selectedRecord && selectedRecord.CustomerName
          ? selectedRecord.CustomerName
          : "",
    });
  }

  render() {
    return (
      <Form
        title={"View Pay Bill"}
        onClick={(action) => {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
        showFooter={false}
      >
        <Input type="text" label="Order ID" value={this.state.orderID} />
        <Input type="text" label="Order Date" value={this.state.orderDate} />
        <Input
          type="text"
          label="Customer Name"
          value={this.state.customerName}
        />
        <Input
          type="text"
          label="Customer Phone"
          value={this.state.customerPhone}
        />

        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Title</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {this.state.breakUp.map((a, index) => (
              <tr key={"key_" + index}>
                <td>{a["@ondc/org/title_type"]}</td>
                <td style={{ textAlign: "right" }}>
                  {a.price ? a.price.value : 0}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td style={{ textAlign: "right" }}>
                <b>{this.state.amount}</b>
              </td>
            </tr>
          </tfoot>
        </table>
      </Form>
    );
  }
}

export default PayBill;
