import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'


export function getCampaign(registrationID) {
    return (dispatch, getState) => {
        return database.collection("Campaigns").where("RegistrationID", "==", registrationID).orderBy("ActionOn","desc").get().then((querySnapshot) => {
            const campaigns = []
            querySnapshot.forEach((doc) => {
                for (let index = 0; index < doc.data().Stores.length; index++) {
                    const store = doc.data().Stores[index];
                    if (store.value === getState().user.store.key) {
                        campaigns.push(
                            {
                                key: doc.id, ...doc.data()
                            }
                        )
                    }
                }
            })
            // console.log("Get Campaign", campaigns);
            return campaigns
        }).catch((error) => { console.log(error) })
    }
}

export function saveCampaign(campaign, key) {
    return (dispatch, getState) => {

        campaign.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        campaign.ActionBy = getState().user.user.Name
        campaign.ActionByUID = getState().user.user.UID
        campaign.ActionByEmailID = getState().user.user.EmailID

        if (key !== "") {
            campaign.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Campaigns").doc(key).update({
                ...campaign
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating customer membership: ", error);
            })
        }
        else {
            campaign.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Campaigns").add({
                ...campaign
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding customer campaign: ", error);
                })
        }
    }
}

export function deleteCampaign(key) {
    return (dispatch, getState) => {
        return database.collection("Campaigns").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted: true
        }).then(() => { return true })
    }
}

export function getCampaignDetails(key) {
    return dispatch => {
        return database.collection("Campaigns").doc(key).get().then((doc) => {
            if (doc.exists) {
                // console.log("Campaign found", doc.data());
                return doc.data()
            } else {
                console.log("No such campaign!");
            }
        })
    }
}

export function getCustomersForCampaign(from, size, stores, customerVisited, customerInDays,
    membership, gender, walletBalanceFrom, walletBalanceTo, outStandingAmtFrom, outStandingAmtTo) {
    return (dispatch) => {
        let searchParameters = {
            "from": from,
            "size": size,
            "track_total_hits": true,
            "query": {
                "bool": {
                    "must": [
                        
                        { "terms": { "StoreID.keyword": stores } }
                    ],
                    "must_not": [
                        { "match": { "IsDeleted": "true" } },
                    ],
                    "filter": {
                        "bool": {
                            "must": []
                        }
                    }
                }
            }
        }
       
        if(customerVisited !=="" && customerInDays !="")
        {
            if(customerVisited === "Visited")
            {
                searchParameters.query.bool.filter.bool.must.push({
                    "range": {
                        "LastVisitedOn": {
                            "gte": "now-" + customerInDays + "d/d",
                            "lte": "now/d",
                            "format": "yyyy-MM-dd"
                        }
                    }
                })
            }
            else
            {
                searchParameters.query.bool.filter.bool.must.push({
                    "range": {
                        "LastVisitedOn": {
                            "lt": "now-" + customerInDays + "d/d",
                            "format": "yyyy-MM-dd"
                        }
                    }
                })
            }
        }

        if (membership.trim() !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "match": {
                    "Membership.Name": membership
                }
            })
        }
        if (gender.trim() !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "match": {
                    "Gender": gender
                }
            })
        }
        if (outStandingAmtFrom !== "" && outStandingAmtTo !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "range":
                {
                    "OutStandingAmount": {
                        "gte": outStandingAmtFrom,
                        "lte": outStandingAmtTo
                    }
                }
            })
        }
        if (walletBalanceFrom !== "" && walletBalanceTo !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "range":
                {
                    "WalletBalance": {
                        "gte": walletBalanceFrom,
                        "lte": walletBalanceTo
                    }
                }
            })
        }
        console.log('getCustomersForCampaign - searchParameters' , JSON.stringify(searchParameters));
        let addMessage = firebase.functions().httpsCallable('getCustomers');
        return addMessage({ text: searchParameters }).then(function (result) {
            const customers = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                customers.push({ key: data._id, ...data._source })
            })
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
        });
    }
}

export function getSalesCustomersForCampaign(customers, purchaseAmtFrom, purchaseAmtTo) {
    return (dispatch) => {
        let searchParameters = {
            "size": 0,
            "query": {
                "bool": {
                    "must": [
                        {
                            "terms": {
                                "Customer.key.keyword": customers
                            }
                        }
                    ],
                    "must_not": [
                      {
                        "exists": {"field": "CancellationReason"}
                      }
                    ]
                }
            },
            "aggs": {
                "customer": {
                    "terms": {
                        "field": "Customer.key.keyword",
                        "size": 10000
                    },
                    "aggs": {
                        "total_sales": {
                            "sum": {
                                "field": "BillAmount"
                            }
                        },
                        "sales_bucket_filter": {
                            "bucket_selector": {
                                "buckets_path": {
                                    "totalSales": "total_sales"
                                },
                                "script": "params.totalSales >= " + purchaseAmtFrom + " && params.totalSales <= " + purchaseAmtTo
                            }
                        }
                    }
                }
            }
        }
        //console.log('getSalesCustomersForCampaign - searchParameters', JSON.stringify(searchParameters));
        let addMessage = firebase.functions().httpsCallable('getSales');
        return addMessage({ text: searchParameters }).then(function (result) {
            let salesCustomers = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.aggregations.customer.buckets.map((data, index) => {
                if (data.key !== "") {
                    salesCustomers.push({
                        key: data.key,
                        total_sales: Number(data.total_sales.value).toFixed(2)
                    })
                }
            })
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: salesCustomers }
        });
    }
}

export function getNonVisitedCustomersForCampaign(from, size, stores, customerVisited, customerInDays,
    membership, gender, walletBalanceFrom, walletBalanceTo, outStandingAmtFrom, outStandingAmtTo) {
    return (dispatch) => {
        let searchParameters = {
            "from": from,
            "size": size,
            "track_total_hits": true,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "IsDeleted": "false" } },
                        { "terms": { "StoreID.keyword": stores } }
                    ],
                    "must_not": [
                    ],
                    "filter": {
                        "bool": {
                            "must": []
                        }
                    }
                }
            }
        }

        if(customerVisited !=="" && customerInDays !="")
        {
            searchParameters.query.bool.must_not.push(
                {
                    "exists": {
                        "field": "LastVisitedOn"
                    }
                }
            )
        }

        if (membership.trim() !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "match": {
                    "Membership.Name": membership
                }
            })
        }
        if (gender.trim() !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "match": {
                    "Gender": gender
                }
            })
        }
        if (outStandingAmtFrom !== "" && outStandingAmtTo !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "range":
                {
                    "OutStandingAmount": {
                        "gte": outStandingAmtFrom,
                        "lte": outStandingAmtTo
                    }
                }
            })
        }
        if (walletBalanceFrom !== "" && walletBalanceTo !== "") {
            searchParameters.query.bool.filter.bool.must.push({
                "range":
                {
                    "WalletBalance": {
                        "gte": walletBalanceFrom,
                        "lte": walletBalanceTo
                    }
                }
            })
        }
        // console.log('getCustomersForCampaign - searchParameters' , JSON.stringify(searchParameters));
        let addMessage = firebase.functions().httpsCallable('getCustomers');
        return addMessage({ text: searchParameters }).then(function (result) {
            const customers = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                customers.push({ key: data._id, ...data._source })
            })
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
        });
    }
}

export function getCampaignsToRun(registrationID, storeID) {
    return (dispatch, getState) => {
        return database.collection("CampaignsToRun").where("RegistrationID", "==", registrationID).where("StoreID", "==", storeID).orderBy("ActionOn","desc").get().then((querySnapshot) => {
            const campaigns = []
            querySnapshot.forEach((doc) => {
                campaigns.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            return campaigns
        }).catch((error) => { console.log(error) })
    }
}

export function getCampaignToRunCustomers(campaignToRunID) {
    return (dispatch, getState) => {
        return database.collection("CampaignToRunCustomers").where("CampaignToRunID", "==", campaignToRunID).get().then((querySnapshot) => {
            const campaigns = []
            querySnapshot.forEach((doc) => {
                campaigns.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            return campaigns
        }).catch((error) => { console.log(error) })
    }
}

export function getCampaignToRunByCampaignID(campaignID) {
    return (dispatch, getState) => {
        return database.collection("CampaignsToRun").where("CampaignID", "==", campaignID).orderBy("ActionOn","desc").limit(1).get().then((querySnapshot) => {
            const campaigns = []
            querySnapshot.forEach((doc) => {
                campaigns.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            return campaigns
        }).catch((error) => { console.log(error) })
    }
}