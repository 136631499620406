import firebase, { database, functions } from "../firebase";
import * as constants from "../Constatnts";

export function addInventory(inventory) {
  return (dispatch, getState) => {
    inventory.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    inventory.ActionBy = getState().user.user.Name;
    inventory.ActionByUID = getState().user.user.UID;
    inventory.ActionByEmailID = getState().user.user.EmailID;
    inventory.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("Inventory")
      .add({
        ...inventory,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error saving Inventory : ", error);
      });
  };
}

export function updateInventory(
  inventory,
  key,
  action = constants.AUDIT_TRAIL_EDIT
) {
  return (dispatch, getState) => {
    inventory.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    inventory.ActionBy = getState().user.user.Name;
    inventory.ActionByUID = getState().user.user.UID;
    inventory.ActionByEmailID = getState().user.user.EmailID;
    inventory.Action = action;
    return database
      .collection("Inventory")
      .doc(key)
      .update({
        ...inventory,
      });
  };
}

export function cancelInventory(cancellationReason, key) {
  return (dispatch, getState) => {
    let actionOn = firebase.firestore.FieldValue.serverTimestamp();
    let actionBy = getState().user.user.Name;
    let actionByUID = getState().user.user.UID;
    let actionByEmailID = getState().user.user.EmailID;
    let action = "CANCEL";
    return database
      .collection("Inventory")
      .doc(key)
      .update({
        Status: "Cancelled",
        BackEndUpdate: false,
        CancellationReason: cancellationReason,
        ActionOn: actionOn,
        ActionBy: actionBy,
        ActionByUID: actionByUID,
        ActionByEmailID: actionByEmailID,
        Action: action,
      });
  };
}

export function getInventoryRunningNo(storeID, transactionTypeID) {
  return (dispatch) => {
    return database
      .collection("TransactionRunningNo")
      .doc("inventory" + storeID + transactionTypeID)
      .get()
      .then((doc) => {
        let runningNo = 1;
        if (doc.exists) {
          runningNo = Number(doc.data().RunningNo) + 1;
        }
        return runningNo;
        // return database.collection("TransactionRunningNo").doc("inventory" + storeID + transactionTypeID).set({
        //     StoreID:storeID, TransactionTypeID:transactionTypeID, RunningNo: runningNo
        // }).then(()=>{
        //     return runningNo
        // })
      });
  };
}

export function updateInventoryRunningNo(
  storeID,
  transactionTypeID,
  runningNo
) {
  return (dispatch) => {
    return database
      .collection("TransactionRunningNo")
      .doc("inventory" + storeID + transactionTypeID)
      .set({
        StoreID: storeID,
        TransactionTypeID: transactionTypeID,
        RunningNo: runningNo,
      })
      .then(() => {
        return runningNo;
      });
  };
}

export function getInventoryDetails(key) {
  return (dispatch) => {
    return database
      .collection("Inventory")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such inventory record!");
        }
      });
  };
}

export function getInventoryList(
  storeID,
  from,
  size,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchVendorDetail,
  searchText,
  status,
  searchReferenceNo = ""
) {
  let searchParameters = {
    _source: [
      "TransactionNo",
      "TransactionType",
      "CancellationReason",
      "TransactionDate",
      "Store",
      "Vendor",
      "FromStore",
      "ToStore",
      "StoreLocation",
      "RefNo",
      "Notes",
      "Status",
      "BillAmount",
      "BillQty",
      "BillTaxAmount",
      "BillDiscount",
      "BillAdditionalDiscount",
      "BillAdditionalCharges",
      "BillRoundOff",
      "Status",
      "ChildTransactions",
      "ParentTransactions",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            match: {
              "Store.key": storeID,
            },
          },
        ],
        must_not: [],
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };
  if (status.trim() !== "") {
    searchParameters.query.bool.must.push({
      match: { Status: status.trim().toLowerCase() },
    });
  } else {
    searchParameters.query.bool.must.push({
      range: {
        TransactionDate: {
          lte: searchTransactionToDate,
          gte: searchTransactionFromDate,
          format: "yyyy-MM-dd",
        },
      },
    });
  }
  if (searchVendorDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Vendor.VendorFirstName":
          "@" + searchVendorDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Vendor.VendorLastName":
          "@" + searchVendorDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Vendor.EmailID": "@" + searchVendorDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Vendor.PhoneNo": "@" + searchVendorDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Vendor.VendorCode":
          "@" + searchVendorDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "TransactionType.key": searchTransactionType.trim().toLowerCase(),
      },
    });
  }
  if (searchReferenceNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { RefNo: searchReferenceNo },
    });
  }

  return (dispatch) => {
    console.log("searchParameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const inventory = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        inventory.push({ key: data._id, ...data._source });
      });
      console.log("inventory", inventory);
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: inventory,
      };
    });
  };
}

export function getProductInStockQty(storeID, locationID, productId) {
  // console.log("StoreID", "==", storeID + " " + "LocationID", "==", locationID + " " + "ProductID", "==", productId)
  return async (dispatch) => {
    let inStockQty = 0;
    return database
      .collection("InventoryInStock")
      .where("StoreID", "==", storeID)
      .where("LocationID", "==", locationID)
      .where("ProductID", "==", productId)
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          // console.log('doc.data()', doc.data())
          inStockQty = doc.data().InStockQty
            ? isNaN(doc.data().InStockQty)
              ? 0
              : doc.data().InStockQty
            : 0;
        });
        return inStockQty;
      });
    // console.log('inStockQty',inStockQty)
  };
  // return (dispatch) => {
  //   console.log('storeID',storeID);
  //   console.log('locationID', locationID);
  //   console.log('productId',productId);
  //   let addMessage = firebase.functions().httpsCallable('apiGetProductInStockQty');
  //   return addMessage({ storeID: storeID, locationID:locationID,productId:productId }).then(function (result) {
  //       let inStockQty  = result.data;
  //       return inStockQty
  //   });
  // }
}

export function getInStockQtyForProducts(storeID, locationID = "", productIDs) {
  let searchParameters = {
    from: 0,
    size: 10000,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
      },
    },
  };
  if (locationID !== "") {
    searchParameters.query.bool.must.push({
      match: {
        LocationID: locationID,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    return addMessage({ text: searchParameters }).then(function(result) {
      // console.log("searchParameters",JSON.stringify(searchParameters))
      // console.log("result",result)
      const inventory = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        inventory.push({
          key: data._source.ProductID,
          InStockQty: data._source.InStockQty,
        });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: inventory,
      };
    });
  };
}

export function getInwardUniqueList(
  from,
  size,
  storeID,
  productID,
  searchText
) {
  return (dispatch, getState) => {
    if (getState().config.ConnectionStatus.Status === "connected") {
      let searchParameters = {
        from: from,
        size: size,
        track_total_hits: true,
        query: {
          bool: {
            must: [
              {
                match: {
                  StoreID: storeID,
                },
              },
              {
                match: {
                  Movement: "inward",
                },
              },
              {
                match: {
                  IsDeleted: false,
                },
              },
            ],
            filter: {
              bool: {
                should: [],
                minimum_should_match: 1,
              },
            },
          },
        },
        sort: [
          {
            ActionOn: {
              order: "desc",
            },
          },
        ],
      };

      if (searchText.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo1: searchText.trim().toLowerCase() },
        });
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo2: searchText.trim().toLowerCase() },
        });
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo3: searchText.trim().toLowerCase() },
        });
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo4: searchText.trim().toLowerCase() },
        });
      }
      if (productID !== "") {
        searchParameters.query.bool.must.push({
          match: { ProductID: productID },
        });
      }
      let addMessage = firebase.functions().httpsCallable("getUniqueNos");
      return addMessage({ text: searchParameters }).then(function(result) {
        const inventory = [];
        let sanitizedMessage = result.data.text;
        sanitizedMessage.hits.hits.map((data, index) => {
          inventory.push({ key: data._id, ...data._source });
        });
        // console.log('inventory', inventory)
        return {
          totalItemsCount: sanitizedMessage.hits.total.value,
          searchResult: inventory,
        };
      });
    } else {
      let collection = database.collection("UniqueNos");
      let query = collection
        .where("StoreID", "==", storeID)
        .where("Movement", "==", "inward")
        .where("IsDeleted", "==", false);

      if (productID.trim() !== "") {
        query = query.where("ProductID", "==", productID);
      }

      return query.get().then((querySnapshot) => {
        let uniqueNos = [];
        querySnapshot.forEach((doc) => {
          let uniqueNo;
          if (searchText.trim() !== "") {
            if (
              doc
                .data()
                .UniqueNo1.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              doc
                .data()
                .UniqueNo2.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              doc
                .data()
                .UniqueNo3.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              doc
                .data()
                .UniqueNo4.toLowerCase()
                .includes(searchText.toLowerCase())
            ) {
              uniqueNo = doc.data();
              uniqueNo.key = doc.id;
              uniqueNo.ActionOn = null;
              uniqueNos.push(uniqueNo);
            }
          } else {
            uniqueNo = doc.data();
            uniqueNo.ActionOn = null;
            uniqueNo.key = doc.id;
            uniqueNos.push(uniqueNo);
          }
        });
        return { totalItemsCount: uniqueNos.length, searchResult: uniqueNos };
      });
    }
  };
}

export function getUniqueListForSalesReturn(
  from,
  size,
  storeID,
  productID,
  searchText
) {
  return (dispatch, getState) => {
    if (getState().config.ConnectionStatus.Status === "connected") {
      let searchParameters = {
        from: from,
        size: size,
        track_total_hits: true,
        query: {
          bool: {
            must: [
              {
                match: {
                  StoreID: storeID,
                },
              },
              {
                match: {
                  Movement: "outward",
                },
              },
              {
                match: {
                  TransactionTypeModule: "sales",
                },
              },
              {
                match: {
                  IsDeleted: false,
                },
              },
            ],
            filter: {
              bool: {
                should: [],
                minimum_should_match: 1,
              },
            },
          },
        },
        sort: [
          {
            ActionOn: {
              order: "desc",
            },
          },
        ],
      };

      if (searchText.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo1: searchText.trim().toLowerCase() },
        });
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo2: searchText.trim().toLowerCase() },
        });
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo3: searchText.trim().toLowerCase() },
        });
        searchParameters.query.bool.filter.bool.should.push({
          match_phrase: { UniqueNo4: searchText.trim().toLowerCase() },
        });
      }
      if (productID !== "") {
        searchParameters.query.bool.must.push({
          match: { ProductID: productID },
        });
      }

      let addMessage = firebase.functions().httpsCallable("getUniqueNos");
      return addMessage({ text: searchParameters }).then(function(result) {
        const inventory = [];
        let sanitizedMessage = result.data.text;
        sanitizedMessage.hits.hits.map((data, index) => {
          inventory.push({ key: data._id, ...data._source });
        });
        // console.log('inventory', inventory)
        return {
          totalItemsCount: sanitizedMessage.hits.total.value,
          searchResult: inventory,
        };
      });
    } else {
      let collection = database.collection("UniqueNos");
      let query = collection
        .where("StoreID", "==", storeID)
        .where("TransactionTypeModule", "==", "sales")
        .where("Movement", "==", "outward")
        .where("IsDeleted", "==", false);

      if (productID.trim() !== "") {
        query = query.where("ProductID", "==", productID);
      }

      return query.get().then((querySnapshot) => {
        let uniqueNos = [];
        querySnapshot.forEach((doc) => {
          let uniqueNo;

          if (searchText.trim() !== "") {
            if (
              doc
                .data()
                .UniqueNo1.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              doc
                .data()
                .UniqueNo2.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              doc
                .data()
                .UniqueNo3.toLowerCase()
                .includes(searchText.toLowerCase()) ||
              doc
                .data()
                .UniqueNo4.toLowerCase()
                .includes(searchText.toLowerCase())
            ) {
              uniqueNo = doc.data();
              uniqueNo.key = doc.id;
              uniqueNo.ActionOn = null;
              uniqueNos.push(uniqueNo);
            }
          } else {
            uniqueNo = doc.data();
            uniqueNo.key = doc.id;
            uniqueNo.ActionOn = null;
            uniqueNos.push(uniqueNo);
          }
        });
        return { totalItemsCount: uniqueNos.length, searchResult: uniqueNos };
      });
    }
  };
}

// export function getInwardUniqueList(from, size, storeID, productID, uniqueNo) {
//   let searchParameters = {
//     "from": from,
//     "size": size,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "match": {
//               "StoreID": storeID
//             }
//           },
//           {
//             "match": {
//               "Movement": "inward"
//             }
//           },
//           {
//             "match": {
//               "IsDeleted": false
//             }
//           }
//         ],
//         "filter": {
//           "bool": {
//             "should": [],
//             "minimum_should_match": 1
//           }
//         }
//       }
//     },
//     "sort": [
//       {
//         "ActionOn": {
//           "order": "desc"
//         }
//       }
//     ]
//   }

//   if (uniqueNo.trim() !== "") {
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo1":  uniqueNo.trim().toLowerCase()}})
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo2":  uniqueNo.trim().toLowerCase()}})
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo3":  uniqueNo.trim().toLowerCase()}})
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo4":  uniqueNo.trim().toLowerCase()}})
//   }
//   if(productID!=="")
//   {
//     searchParameters.query.bool.must.push({"match": {"ProductID": productID}});
//   }

//   return (dispatch) => {
//     console.log('searchParameters', JSON.stringify(searchParameters))
//     let addMessage = firebase.functions().httpsCallable('getUniqueNos');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const inventory = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         inventory.push({ key: data._id, ...data._source })
//       })
//       // console.log('inventory', inventory)
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: inventory }
//     });
//   }
// };

// export function getUniqueListForSalesReturn(from, size, storeID, productID, uniqueNo) {
//   let searchParameters = {
//     "from": from,
//     "size": size,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "match": {
//               "StoreID": storeID
//             }
//           },
//           {
//             "match": {
//               "Movement": "outward"
//             }
//           },
//           {
//             "match": {
//               "TransactionTypeModule": "sales"
//             }
//           },
//           {
//             "match": {
//               "IsDeleted": false
//             }
//           }
//         ],
//         "filter": {
//           "bool": {
//             "should": [],
//             "minimum_should_match": 1
//           }
//         }
//       }
//     },
//     "sort": [
//       {
//         "ActionOn": {
//           "order": "desc"
//         }
//       }
//     ]
//   }

//   if (uniqueNo.trim() !== "") {
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo1":  uniqueNo.trim().toLowerCase()}})
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo2":  uniqueNo.trim().toLowerCase()}})
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo3":  uniqueNo.trim().toLowerCase()}})
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo4":  uniqueNo.trim().toLowerCase()}})
//   }
//   if(productID!=="")
//   {
//     searchParameters.query.bool.must.push({"match": {"ProductID": productID}});
//   }

//   return (dispatch) => {
//     // console.log('searchParameters', JSON.stringify(searchParameters))
//     let addMessage = firebase.functions().httpsCallable('getUniqueNos');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const inventory = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         inventory.push({ key: data._id, ...data._source })
//       })
//       // console.log('inventory', inventory)
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: inventory }
//     });
//   }
// };

export function getUniqueNoIDBasedOnTransaction(
  storeID,
  productID,
  uniqueNo1,
  uniqueNo2,
  uniqueNo3,
  uniqueNo4
) {
  let searchParameters = {
    from: 0,
    size: 1,
    query: {
      bool: {
        must: [
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            match: {
              ProductID: productID,
            },
          },
          {
            match: {
              "UniqueNo1.keyword": uniqueNo1,
            },
          },
          {
            match: {
              "UniqueNo2.keyword": uniqueNo2,
            },
          },
          {
            match: {
              "UniqueNo3.keyword": uniqueNo3,
            },
          },
          {
            match: {
              "UniqueNo4.keyword": uniqueNo4,
            },
          },
        ],
      },
    },
  };

  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getUniqueNos");
    return addMessage({ text: searchParameters }).then(function(result) {
      let uniqueNo = null;
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        uniqueNo = { key: data._id, ...data._source };
        // console.log('uniqueNo',uniqueNo)
      });
      return uniqueNo;
    });
  };
}

export function checkIfUniqueNoIsUnique(storeID, productID, uniqueNo) {
  let searchParameters = {
    from: 0,
    size: 1,
    query: {
      bool: {
        must: [
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            match: {
              ProductID: productID,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
        filter: {
          bool: {
            should: [],
          },
        },
      },
    },
  };

  return (dispatch) => {
    if (uniqueNo.trim() !== "") {
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { UniqueNo1: uniqueNo.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { UniqueNo2: uniqueNo.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { UniqueNo3: uniqueNo.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { UniqueNo4: uniqueNo.trim().toLowerCase() },
      });
    }
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getUniqueNos");
    return addMessage({ text: searchParameters }).then(function(result) {
      // console.log('result',result.data.text)
      let uniqueNo = null;
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        uniqueNo = { key: data._id, ...data._source };
        // console.log('uniqueNo',uniqueNo)
      });
      return uniqueNo;
    });
  };
}

export function checkUniqueNoExist(
  storeID,
  uniqueNo1,
  uniqueNo2,
  uniqueNo3,
  uniqueNo4
) {
  return (dispatch) => {
    return database
      .collection("UniqueNos")
      .where("StoreID", "==", storeID)
      .where("UniqueNo1", "==", uniqueNo1)
      .where("UniqueNo2", "==", uniqueNo2)
      .where("UniqueNo3", "==", uniqueNo3)
      .where("UniqueNo4", "==", uniqueNo4)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let key = "";
        querySnapshot.forEach((doc) => {
          key = doc.id;
        });
        console.log("getUniqueNoKey", key);
        return key;
      });
  };
}

export function getInTransitInventoryForAStore(storeID) {
  let searchParameters = {
    _source: [
      "TransactionNo",
      "TransactionType",
      "CancellationReason",
      "TransactionDate",
      "Store",
      "Vendor",
      "FromStore",
      "ToStore",
      "StoreLocation",
      "RefNo",
      "Notes",
      "Status",
      "BillAmount",
      "BillQty",
      "BillTaxAmount",
      "BillDiscount",
      "BillAdditionalDiscount",
      "BillAdditionalCharges",
      "BillRoundOff",
      "Status",
      "ChildTransactions",
      "ParentTransactions",
    ],
    from: 0,
    size: 1000,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            match: {
              "Vendor.VendorStoreID": storeID,
            },
          },
          { match: { Status: "Request Raised" } },
        ],
        must_not: [],
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };
  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const inventory = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        inventory.push({ key: data._id, ...data._source });
      });
      //console.log('inventory', inventory)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: inventory,
      };
    });
  };
}

export function getInventoryToSale() {
  let salesToPurchaseTransactionType = "";
  return (dispatch, getState) => {
    return database
      .collection("Registration")
      .doc(getState().user.user.RegistrationID)
      .get()
      .then((doc) => {
        salesToPurchaseTransactionType = doc.data().InventoryToSale;
        return salesToPurchaseTransactionType;
      });
  };
}

export function getBatches(storeLocationID, productID, allBatches = false) {
  return (dispatch) => {
    //allBatches means batches with negative qty as well
    if (storeLocationID !== "") {
      return database
        .collection("Batches")
        .where("StoreLocationID", "==", storeLocationID)
        .where("ProductID", "==", productID)
        .get()
        .then((querySnapshot) => {
          const batches = [];
          querySnapshot.forEach((doc) => {
            if (allBatches) {
              batches.push({ key: doc.id, ...doc.data() });
            } else {
              if (doc.data().Qty > 0) {
                batches.push({ key: doc.id, ...doc.data() });
              }
            }
          });
          return batches;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return database
        .collection("Batches")
        .where("ProductID", "==", productID)
        .get()
        .then((querySnapshot) => {
          const batches = [];
          querySnapshot.forEach((doc) => {
            if (allBatches) {
              batches.push({ key: doc.id, ...doc.data() });
            } else {
              if (doc.data().Qty > 0) {
                batches.push({ key: doc.id, ...doc.data() });
              }
            }
          });
          return batches;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
}

export function getBatchAuditTrail(batchID) {
  return (dispatch) => {
    return database
      .collection("BatchesAuditTrail")
      .where("DocKey", "==", batchID)
      .get()
      .then((querySnapshot) => {
        // .orderBy("ActionOn","desc")
        const batcheAuditTrail = [];
        querySnapshot.forEach((doc) => {
          batcheAuditTrail.push({ key: doc.id, ...doc.data() });
        });

        batcheAuditTrail.sort(function(a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return b.ActionOn - a.ActionOn;
        });
        return batcheAuditTrail;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getPurchaseEntryForPurchaseReturn(
  storeID,
  vendorCode,
  productID
) {
  let searchParameters = {
    _source: [
      "TransactionID",
      "TransactionNo",
      "TransactionDate",
      "SupplyPrice",
    ],
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              "TransactionType.keyword": "Purchase Entry",
            },
          },
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            match: {
              ProductID: productID,
            },
          },
          {
            match: {
              VendorCode: vendorCode,
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
      },
    },
  };

  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getDenomInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const inventoryList = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        inventoryList.push({ key: data._id, ...data._source });
      });
      // console.log('inventory sanitizedMessage', sanitizedMessage)
      return inventoryList;
    });
  };
}

export function initialiseUniqueNoList(storeID) {
  return (dispatch) => {
    return database
      .collection("UniqueNos")
      .where("StoreID", "==", storeID)
      .where("IsDeleted", "==", false)
      .get();
  };
}

export function initialiseBatches(storeID) {
  return (dispatch) => {
    return database
      .collection("Batches")
      .where("StoreID", "==", storeID)
      .where("IsDeleted", "==", false)
      .get();
  };
}

export function deleteInventoryInStock(key) {
  return (dispatch, getState) => {
    return database
      .collection("InventoryInStock")
      .doc(key)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let storeID = doc.data().StoreID + "_deleted";
          let storeLocationID = doc.data().LocationID + "_deleted";

          // console.log(storeID)
          // console.log(storeLocationID)

          await database
            .collection("InventoryInStock")
            .doc(doc.id)
            .update({
              StoreID: storeID,
              LocationID: storeLocationID,
              Action: "DELETE",
              ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
              ActionBy: getState().user.user.Name,
              ActionByUID: getState().user.user.UID,
              ActionByEmailID: getState().user.user.EmailID,
            });
        }
        return "success";
      });
  };
}
