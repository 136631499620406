import * as actionTypes from '../actionTypes';

const initialState = {
    posts:[],
    postDownloadURL:'',
    postNextSequenceNo:0
}

export default function(state = initialState, action) {
    switch (action.type) {
        case actionTypes.GET_POSTS:
            return { posts:action.posts, postDownloadURL:state.postDownloadURL ,postNextSequenceNo: state.postNextSequenceNo  };
        case actionTypes.POST_FILE_UPLOADED:
            console.log(action)
            return { posts:state.posts, postDownloadURL:action.postDownloadURL ,postNextSequenceNo: state.postNextSequenceNo  };
        case actionTypes.POST_NEXT_SEQUENCE_NO_UPDATED:
            return { posts:state.posts, postDownloadURL:state.postDownloadURL ,postNextSequenceNo: action.postNextSequenceNo  };
        default:
            return state;
    }
}