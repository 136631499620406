import React,{Component} from 'react'
import Print from '../Sales/Print'

class SalesTransactionView extends Component{

    constructor()
    {
      super()
       this.state = {
        //    key:'',
        //    sale:null,
        //    printConfig:null
            printData:null
       }
       this.printFormatting = this.printFormatting.bind(this)
       this.roundUpTo2 = this.roundUpTo2.bind(this)
    }

    roundUpTo2(num){
        return Math.round(Number(num) * 100) / 100
    }

    printFormatting(sale){
        
        // sale.TransactionDate =  (new Date(sale.TransactionDate).toISOString().split('T')[0])

        console.log('sale.TransactionDate ',sale.TransactionDate )
        sale.TransactionDate = (new Date(sale.TransactionDate._seconds*1000)).toString().substring(0,24)  
        console.log('sale.TransactionDate ',sale.TransactionDate )

        sale.AmountInWords= this.props.convertNumberToWords(sale.BillAmount)  
        sale.LineItems.map((lineItem, index) => {
            sale.LineItems[index].SrNo = index +1
        })
        let printTaxComponents= []
        sale.LineItems.map((lineItem, index) => {
            if(lineItem.TaxID !="")
            {
                lineItem.TaxComponentAmount.map((t)=>{
                    let indexFound = -1 
                    printTaxComponents.map((pt,ptIndex)=>{
                        if(t.TaxType===pt.TaxType)
                        {
                            indexFound = ptIndex
                            return
                        }
                    })
                    if(indexFound<0)
                    {
                        printTaxComponents.push({ TaxType:t.TaxType,
                            TaxAmount:this.roundUpTo2(Number(t.TaxAmount))
                           })
                    }
                    else
                    {
                        printTaxComponents[indexFound].TaxAmount =this.roundUpTo2( Number(printTaxComponents[indexFound].TaxAmount )+ Number(t.TaxAmount))
                    }
                })
            }
        })
        sale.PrintTaxComponents = printTaxComponents
        sale.LineItems.map((lineItem, index)=>{
            for(var i=0; i<printTaxComponents.length; i++ )
            {
                if(lineItem.TaxComponentAmount.length<(i+1))
                {
                    lineItem.TaxComponentAmount.push({TaxName:'', TaxAmount:0, TaxPercentage:'',TaxType:''})
                }
            }
        })
    }

    async componentDidMount(){
        console.log('this.props.match.params.key',this.props.match.params.key)
        let key= this.props.match.params.key
        let sale = null
        let printConfig = null
        let printData = null
        if(key.trim()!=="")
        {
            sale = await this.props.apiGetSaleDetails(key)
            console.log('sale',sale)
            if(sale)
            {
                this.printFormatting(sale)
                printConfig = await this.props.apiGetPrintConfigByID(sale.PrintConfigID)
                console.log('printConfig',printConfig)
            }
            printData = {Sale:sale,PrintConfig:printConfig}
            console.log('printData',printData)
        }
        this.setState({printData:printData})
    }

    render(){
        return  <React.Fragment> {this.state.printData &&  <React.Fragment>
                <div className="col-md-2 col-xs-0"></div>
                <div className="col-md-8 col-xs-12" style={{background:"white"}}>
                    <Print  {...this.props}   
                    printView={true}
                    printChecked = {false} 
                    sendEmailChecked={false}
                    sendSMSChecked = {false}
                    printData = {this.state.printData}
                    onClose={()=> console.log('print done')}/> 
                </div>
                <div className="col-md-2 col-xs-0"></div>
                </React.Fragment>}
                {!this.state.printData && <div className="col-md-12"><b>Loading transaction...</b></div>}
                </React.Fragment> 
            
    }
}

export default SalesTransactionView