import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap';
import '../Common/Common.css'
import * as constatnts from '../../Constatnts'


import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';
import Grid from '../2/Controls/Grid';
import Button from '../2/Controls/Button';
import ImportMaster from '../ImportMaster';

class NewMembership extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            name: '',
            discountApplied: true,
            validity: '',
            discount: '',
            description: '',
            nameError: '',
            discountOptionError: '',
            // optionError:'',
            discountError: '',
            validityError: '',
            key: '',
            showImport: false,
            selectAll: false,
            records: [],
            isProductTypeAheadLoading: false,
            productsTypeAheadOptions: [],
            notifyCustomerInDays: 0,
            notifyCustomerInDaysError: '',
            enableAlerts: false,
            smsTemplateID: '',
            smsTemplateError: '',
            smsTemplates: []
        }
        this.onSaveMemberships = this.onSaveMemberships.bind(this);
        this.onDeleteItems = this.onDeleteItems.bind(this);
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)
        this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this)
        this.onProductSelected = this.onProductSelected.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    async componentDidMount() {
        const key = (this.props.membership ? this.props.membership.key : "");
        let smsTemplates = await this.props.getSMSTemplatesByModule(this.props.user.user.RegistrationID, "Membership").then((smsTemplates) => {
            return smsTemplates;
        });

        this.setState({ key: key, smsTemplates: smsTemplates });
        if (key && key !=="") {
            // this.props.getMembershipDetails(key).then((membership) => {
            //     membership.Products.map(m => {
            //         m.Error = ""
            //         m.Selected = false
            //     })
                let membership =  this.props.membership;

                this.setState({
                    name: membership.Name,
                    discount: membership.hasOwnProperty('Discount') ? membership.Discount : '',
                    discountApplied: membership.hasOwnProperty('DiscountApplied') ? membership.DiscountApplied : false,
                    discountAppliedValue:membership.hasOwnProperty('DiscountApplied') && membership.DiscountApplied ? "1" : "2",
                    // discountNotApplied :membership.hasOwnProperty('DiscountNotApplied') ? membership.DiscountNotApplied : false,
                    description: membership.Description,
                    records: membership.Products,
                    validity: membership.hasOwnProperty('Validity') ? membership.Validity : '',
                    notifyCustomerInDays: membership.hasOwnProperty('NotifyCustomerInDays') ? membership.NotifyCustomerInDays : 0,
                    enableAlerts: membership.hasOwnProperty('EnableAlerts') ? membership.EnableAlerts : false,
                    smsTemplateID: membership.hasOwnProperty('SMSTemplateID') ? membership.SMSTemplateID : ''
                    // appliedOnDiscountLevel:membership.hasOwnProperty('AppliedOnDiscountLevel') ? membership.AppliedOnDiscountLevel:'',
                    // appliedOnRetailLevel:membership.hasOwnProperty('AppliedOnRetailLevel') ? membership.AppliedOnRetailLevel:'',
                })
            // })
        }
    }

    onProductTypeAheadSearch = async (query) => {
        this.setState({ isProductTypeAheadLoading: true });
        let searchedProducts = [];
        searchedProducts = await this.props.config.Products.filter(p => p.SKU.toLowerCase().includes(query.toLowerCase())
            || p.Name.toLowerCase().includes(query.toLowerCase()) || p.BarCode.toLowerCase().includes(query.toLowerCase()));
        this.setState({ productsTypeAheadOptions: searchedProducts, isProductTypeAheadLoading: false })
    }

    onProductBarCodeSearch(queryText) {

        var productList = this.props.config.Products.filter((e) => e.SKU.toLowerCase() === queryText.toLowerCase() || e.BarCode.toLowerCase() === queryText.toLowerCase())
        if (productList.length === 0) {
            alert('Product not found')
            return
        }
        this.onProductSelected(productList[0])
    }

    onProductSelected(product) {
        //check if product is arelady added
        let records = this.state.records
        if (records.filter(f => f.ProductID === product.key).length > 0) {
            alert('Product already added')
            return
        }
        records.unshift({
            ProductID: product.key,
            SKU: product.SKU,
            Name: product.Name,
            DiscountPercentage: '',
            DiscountAmount: '',
            RetailPrice: '',
            Error: ''
        })
        this.setState({ records: records })
    }

    onSaveMemberships() {
        this.setState({ saving: true })
        let nameError = "", validityError = "", discountError = ""
        let notifyCustomerInDaysError = ""
        let smsTemplateError = ""
        let discountOptionError = ""
        let lineItemErrors = false
        let records = this.state.records
        if (this.state.name.trim() === "") {
            nameError = "Please provide Membership name"
        }
        if (this.state.discountApplied && this.state.discount === "") {
            discountError = "Please provide discount"
        }
        if (this.state.validity !== "") {
            if (Number(this.state.validity) < 0) {
                validityError = "Validity cannot be negative"
            }
        }
        if (!this.state.discountApplied && this.state.records.length === 0) {
            discountOptionError = "Please select product(s)"

        }
        if (!this.state.discountApplied) {
            //check lineitems
            records.map(m => {
                if (m.DiscountPercentage === "" && m.DiscountAmount === "" && m.RetailPrice === "") {
                    lineItemErrors = true
                    m.Error = "Please provide value"
                }
                if ((m.DiscountPercentage > 0 && m.DiscountAmount > 0)
                    || (m.DiscountPercentage > 0 && m.RetailPrice > 0)
                    || m.DiscountAmount > 0 && m.RetailPrice > 0) {
                    lineItemErrors = true
                    m.Error = "Please specify only one value"
                }

                if (m.DiscountPercentage > 0 && m.RetailPrice > 0) {
                    lineItemErrors = true
                    m.Error = "Please specify only one value"
                }
            })

        }
        if (this.state.enableAlerts && this.state.smsTemplateID === '') {
            smsTemplateError = 'Please select SMS Template'
        }

        if (this.state.enableAlerts && this.state.notifyCustomerInDays <= 0) {
            notifyCustomerInDaysError = 'Notify customer in days cannot be zero'
        }

        if (this.state.enableAlerts && this.state.notifyCustomerInDays > 365) {
            notifyCustomerInDaysError = 'Notify customer in days cannot be more than 365'
        }

        if (nameError !== "" || validityError !== "" || discountError !== "" || discountOptionError !== "" || lineItemErrors
            || notifyCustomerInDaysError !== "" || smsTemplateError != "") {
            this.setState({
                nameError: nameError,
                validityError: validityError,
                discountError: discountError,
                discountOptionError: discountOptionError,
                records: records,
                notifyCustomerInDaysError: notifyCustomerInDaysError,
                smsTemplateError: smsTemplateError,
                saving: false
            })
            return;
        }

        let membership = {
            Name: this.state.name,
            DiscountApplied: this.state.discountApplied,
            Discount: this.state.discountApplied ? Number(this.state.discount) : 0,
            Validity: Number(this.state.validity),
            Description: this.state.description,
            RegistrationID: this.props.user.user.RegistrationID,
            Products: !this.state.discountApplied ? this.state.records : [],
            IsDeleted: false,
            EnableAlerts: this.state.enableAlerts,
            NotifyCustomerInDays: Number(this.state.notifyCustomerInDays),
            SMSTemplateID: this.state.smsTemplateID
        }
        const key = this.state.key ? this.state.key : "";
        this.props.saveMembership(key, membership).then(() => {
            setTimeout(function () { //Start the timer
                this.props.getToastr("Membership saved successfully")
                this.setState({ saving: false })
                this.props.onSave()
            }.bind(this), 3000)
        })
    }

    onDeleteItems() {
        let records = this.state.records
        if (records.filter(f => f.Selected).length === 0) {
            alert('Please select records')
            return
        }
        let recordsTmp = []
        records.map(m => {
            if (!m.Selected) {
                recordsTmp.push(m)
            }
        })
        this.setState({ records: recordsTmp })
    }

    onExportClick() {

        let products = []
                this.state.records.map((data) => {
                    let customer = {
                        "SKU": data.SKU,
                        "Item Name": data.Name,
                        "Dis %": data.DiscountPercentage,
                        "Dis Amt": data.DiscountAmount,
                        "Retail Price": data.RetailPrice,
                    }
                    products.push(customer);
                })

        const csvData = constatnts.objToCSV(products);
        this.setState({ exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "MembershipProducts.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    render() {
        return <React.Fragment>
        <Form
        title={(this.state.key !== "") ? "Edit Membership" : "New Membership"}
        onClick={(action)=>{
            if(action==="save"){
                this.onSaveMemberships();
            }else{
                this.props.onClose()
            }
        }}
        processing={this.state.saving}
    >
        <Input
            type="text"
            label="Name"
            onChange={(value) => this.setState({ name:value, nameError: "" })}
            value={this.state.name}
            error={this.state.nameError}
            onEnter={this.onSaveMemberships}
        />
        <Input
            type="text"
            label="Description"
            onChange={(value) => this.setState({ description:value})}
            value={this.state.description}
            onEnter={this.onSaveMemberships}
        />
        <Input
            type="select"
            label="Discount Applicable On"
            onChange={(value) => { this.setState({ discountAppliedValue:value, discountApplied: value === "1" ? true : false, discountOptionError: "" }) }}
            value={this.state.discountAppliedValue}
            error={this.state.discountOptionError}
        >
            <option value={"1"}>All Products</option>
            <option value={"2"}>Specific Products</option>
        </Input>
        {this.state.discountApplied && <Input
            type="number"
            label="Discount %"
            onChange={(value) => {
                if (value > 0 && value <= 100) {
                    this.setState({ discount:value, discountError: "" })
                }
            }}
            value={this.state.discount}
            error={this.state.discountError}
        />}
        <Input
            type="number"
            label="Validity (Days)"
            onChange={(value) => {
                if (value > 0) {
                    this.setState({ validity:value })
                }
            }}
            value={this.state.validity}
            error={this.state.validityError}
        />
        <span className="help-block">Keep validity as blank or 0 if this membership does not expire.</span>
        {!this.state.discountApplied && <React.Fragment>
            <Input 
                {...this.props}
                type="productsearch"
                showImportButton={true}
                processing={this.state.isProductTypeAheadLoading}
                onSearch={(searchText)=>{
                    this.onProductTypeAheadSearch(searchText)
                }}
                onScan={(searchText)=>this.onProductBarCodeSearch(searchText)}
                options={this.state.productsTypeAheadOptions}
                onChange={(value) => {
                    this.onProductSelected(value)
                }}
                onClick={(action)=>{
                    if(action==="import"){
                        this.setState({ showImport: true })
                    }
                }}
            />
            {/* <div>Product count: {this.state.records.length}</div>
            {this.state.records.length > 0 && <Button 
                type="secondary"
                label="Export"
                onClick={this.onExportClick}
            />}
            {this.state.records.length > 0 && <Confirm
                onConfirm={() => this.onDeleteItems()}
                body="Are you sure you would like to remove selected product(s)?"
                confirmText="Yes"
                title="Remove product(s)">
                <button className="btn btn-default btn-flat">Remove slected <i className="fa fa-trash-o"></i></button>
            </Confirm>} */}
            {this.state.records.length > 0 && <div className="felx">
                <div className="left w-50">
                    Product Count: {this.state.records.length}
                </div>
                <div className="right w-50">
                    <Button type="remove" onClick={this.onDeleteItems}/>
                </div>
            </div>}
            <Grid
                    columns={[
                    {Name:'',  Type:'checkbox'},
                    {Name:'Product',  Type:'multiple',Columns:[{Column:'SKU'}, {Column:"Name"}]},
                    {Name:'Dis %',  Type:'field',Columns:[{Column:'DiscountPercentage', Type:"number", Input:true}]},
                    {Name:'Dis Amt',  Type:'field',Columns:[{Column:'DiscountAmount', Type:"number", Input:true}]},
                    {Name:'Retail Price',  Type:'field',Columns:[{Column:'RetailPrice', Type:"number", Input:true}]},
                ]}
                data={this.state.records}
                onChange={(index , record)=>{
                    let records = this.state.records;
                    records[index] = record;
                    this.setState({records:records});
                }}
                selectAll={this.state.selectAll}
                onSelectAll={(selected)=>{
                    let records = this.state.records;
                    records.map(s=> s.Selected = selected);
                    this.setState({records:records, selectAll:selected});
                }}
            />
        </React.Fragment>}
        <Input
            type="switch"
            label="Alert Customer About Membership Expiry"
            value={this.state.enableAlerts}
            onChange={(value) => { this.setState({ enableAlerts: value, notifyCustomerInDaysError: "", smsTemplateError: "", notifyCustomerInDays: 0, smsTemplateID: ""}) }}
        />
         {this.state.enableAlerts && <React.Fragment>
        <Input
            type="number"
            label="Alert Before (Days)"
            onChange={(value) => this.setState({ notifyCustomerInDays:value, notifyCustomerInDaysError: "" })}
            value={this.state.notifyCustomerInDays}
            error={this.state.notifyCustomerInDaysError}
        />
        <Input
            type="select"
            label="Expiry Message"
            value={this.state.smsTemplateID}
            error={this.state.smsTemplateError}
            onChange={(value) => this.setState({ smsTemplateID: value, smsTemplateError: "" })}
        >                      
            <option value="">Select template</option>
            {this.state.smsTemplates.map((temmplate) =>
                <option value={temmplate.key}>{temmplate.Name}</option>
            )}
        </Input>
        </React.Fragment>}
        </Form>
        {this.state.showImport && <ImportMaster   
            {...this.props}
            showWindow={true}
            importType="membership"
            onClose={() => this.setState({ showImport: false })}
            onImportCompletion={(importedRecords) => {
                let records = this.state.records
                importedRecords.map(m => {
                    if (records.filter(f => f.ProductID === m.ProductID).length === 0) {
                        m.Error = ""
                        m.Selected = false
                        records.unshift(m)
                    }
                })
                this.setState({ records: records, showImport: false })
            }}
        />} 
    </React.Fragment>
        // <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
        //             <div style={modalWidth}>
        //                 <ImportMaster   {...this.props}
        //                     importType="membership"
        //                     onClose={() => this.setState({ showImport: false })}
        //                     onImportCompletion={(importedRecords) => {
        //                         let records = this.state.records
        //                         importedRecords.map(m => {
        //                             if (records.filter(f => f.ProductID === m.ProductID).length === 0) {
        //                                 m.Error = ""
        //                                 m.Selected = false
        //                                 records.unshift(m)
        //                             }
        //                         })
        //                         this.setState({ records: records, showImport: false })
        //                     }}
        //                 />
        //             </div>
        //         </Modal>
    }
}

export default NewMembership





// {!this.state.discountApplied && <div className="col-md-8">
//                                     <div className={this.state.discountOptionError !== "" ? "form-group has-error" : "form-group "} >
//                                         <label >Select Product</label>
//                                         <div className="input-group" style={{ width: "100%" }}>
//                                             {!this.state.toggleSearch && <AsyncTypeahead
//                                                 autoFocus={true}
//                                                 allowNew={false}
//                                                 isLoading={this.state.isProductTypeAheadLoading}
//                                                 multiple={false}
//                                                 options={this.state.productsTypeAheadOptions}
//                                                 labelKey="Name"
//                                                 minLength={3}
//                                                 onSearch={this.onProductTypeAheadSearch}
//                                                 placeholder="Start typing or scanning to search products"
//                                                 filterBy={['Name', 'SKU', 'BarCode']}
//                                                 autoFocus={true}
//                                                 dropup={false}
//                                                 highlightOnlyResult={true}
//                                                 selected={this.state.productsTypeAheadSelected}
//                                                 onChange={(selected) => {
//                                                     this.onProductSelected(selected[0])
//                                                     this.setState({ productsTypeAheadSelected: [] })
//                                                 }}
//                                                 renderMenuItemChildren={(option, props) => (
//                                                     <div>
//                                                         <div><b>{option.Name}</b></div>
//                                                         <div>{option.SKU}</div>
//                                                     </div>
//                                                 )}
//                                             />}
//                                             {this.state.toggleSearch && <input type="text"
//                                                 onChange={(evt) => {
//                                                     this.setState({ queryText: evt.target.value })
//                                                 }}
//                                                 value={this.state.queryText}
//                                                 onKeyPress={this.onProductBarCodeSearch}
//                                                 placeholder="Start scanning to search products"
//                                                 className="form-control" style={{ height: "33px", fontSize: "14px" }} />}
//                                             <div className="input-group-btn">
//                                                 <button type="button"
//                                                     onClick={() => this.setState({ toggleSearch: !this.state.toggleSearch })}
//                                                     className="btn btn-default btn-lg btn-flat" style={{ height: "33px", padding: "5px 16px" }}>
//                                                     <i className="fa fa-exchange"></i>
//                                                 </button>
//                                                 {!this.props.isMobileApp() && <button type="button" onClick={() => {
//                                                     this.setState({ showImport: true })
//                                                 }}
//                                                     className="btn btn-default btn-flat">
//                                                     <i className="glyphicon glyphicon-upload"></i>Import Product
//                                             </button>}
                                                
//                                             </div>
//                                         </div>
//                                         {this.state.discountOptionError !== "" && <span className="help-block">{this.state.discountOptionError}</span>}
//                                     </div>
//                                 </div>}



// <table className="table table-bordered table-striped">
//                                                 <thead>
//                                                     <tr>
//                                                         <th style={{ width: "20px" }}>
//                                                             <input type="checkbox"
//                                                                 checked={this.state.selectAll}
//                                                                 onChange={() => {
//                                                                     let records = this.state.records
//                                                                     records.map((b) => { b.Selected = !this.state.selectAll })
//                                                                     this.setState({ selectAll: !this.state.selectAll, records: records })
//                                                                 }}
//                                                             />
//                                                         </th>
//                                                         <th>SKU</th>
//                                                         <th style={{ width: "120px" }}>Dis %</th>
//                                                         <th style={{ width: "120px" }}>Dis Amt</th>
//                                                         <th style={{ width: "120px" }}>Retail Price</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {this.state.records.map((product, index) =>
//                                                         <tr key={index}>
//                                                             <td>
//                                                                 <input type="checkbox"
//                                                                     checked={product.Selected}
//                                                                     onChange={() => {
//                                                                         let records = this.state.records
//                                                                         records[index].Selected = !records[index].Selected
//                                                                         this.setState({ records: records })
//                                                                     }}
//                                                                 />
//                                                             </td>
//                                                             <td>
//                                                                 <div>{product.SKU}</div>
//                                                                 <div>{product.Name}</div>
//                                                             </td>
//                                                             <td>
//                                                                 <div className={product.Error !== "" ? "form-group has-error" : "form-group "}>
//                                                                     <input type="number" className="form-control"
//                                                                         onChange={(evt) => {
//                                                                             if (evt.target.value <= 100) {
//                                                                                 let products = this.state.records
//                                                                                 products[index].DiscountPercentage = evt.target.value
//                                                                                 products[index].Error = ""
//                                                                                 this.setState({ records: products })
//                                                                             }
//                                                                         }}
//                                                                         value={product.DiscountPercentage}
//                                                                     />
//                                                                     {product.Error != "" && <span className="help-block">{product.Error}</span>}
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div className={product.Error !== "" ? "form-group has-error" : "form-group "}>
//                                                                     <input type="number" className="form-control"
//                                                                         onChange={(evt) => {
//                                                                             let products = this.state.records
//                                                                             products[index].DiscountAmount = evt.target.value
//                                                                             products[index].Error = ""
//                                                                             this.setState({ records: products })
//                                                                         }}
//                                                                         value={product.DiscountAmount}
//                                                                     />
//                                                                 </div>
//                                                             </td>
//                                                             <td>
//                                                                 <div className={product.Error !== "" ? "form-group has-error" : "form-group "}>
//                                                                     <input type="number" className="form-control"
//                                                                         onChange={(evt) => {
//                                                                             let products = this.state.records
//                                                                             products[index].RetailPrice = evt.target.value
//                                                                             products[index].Error = ""
//                                                                             this.setState({ records: products })
//                                                                         }}
//                                                                         value={product.RetailPrice}
//                                                                     />
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     )}
//                                                 </tbody>
//                                             </table>