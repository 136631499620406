
import React, { Component } from 'react';
import SalesDashboardCtrl from './SalesDashboardCtrl';

class SalesDashboard extends Component {

    render(){
        return <div className="content-wrapper">
        <section className="content" >
            <SalesDashboardCtrl {...this.props}/>
        </section>
        </div>
    }
}

export default SalesDashboard;

// import React, { Component } from 'react';
// import { Bar, Pie, Doughnut } from 'react-chartjs-2';
// import ReactHtmlParser from 'react-html-parser';
// import Icon from '../2/Controls/Icon';

// class SalesDashboard extends Component {

//     constructor() {
//         super();
//         this.state = {
//             type: 'today',
//             dashBoardHeader: {
//                 total_sales: 0,
//                 total_transactions: 0,
//                 total_qty: 0,
//                 total_customers: 0,
//             }
//             , expenseHeader: { total_expenses: 0 }
//             , categories: []
//             , subcategories: []
//             , brands: []
//             , storesSale: []
//             , selectePeriodTransactions: 0,
//             selectedPeriodQty: 0,
//             selectedPeriodCustomers: 0,
//             selectedPeriodSales: 0,
//             selectedPeriodAvgTicketSize: 0
//             , payments: []
//             , paymentsShort: []

//             , products: []
//             , productTypes: []
//             , store: {}
//             , storeList: []
//             , cashregister: {}
//             , cashregisterlist: []
//             , employees: []
//             , stores: []
//             , storeList: []
//             , searchStore: ""
//             , searchCashRegister: ""
//             , searchTransactionType: ""
//             , searchTransactionFromDate: new Date().toISOString().split('T')[0]
//             , searchTransactionToDate: new Date().toISOString().split('T')[0]
//             , showSalesChart: true
//             , showSalesList: false
//             , showLastSalesChart: true
//             , showLastSalesList: false
//             , showQuarterSalesChart: true
//             , showQuarterSalesList: false
//             , totalSales: 0
//             , avgMonthlySales: 0
//             , avgTicketSize: 0
//             , showPaymentModeChart: true,
//             showPaymentModeList: false
//             , goods: []
//             , services: []
//             , wallets: []
//             , packages: []
//             , totalWalkinCount: 0
//             , totalRepeatedCount: 0
//             , totalNewCount: 0
//             , sortCategoryBy: 'sales'
//             , page: 'Dashboard'

//         };

//         this.loadDashboardHeaders = this.loadDashboardHeaders.bind(this);
//         this.loadExpenseHeaders = this.loadExpenseHeaders.bind(this);
//         this.loadCategoryWiseSales = this.loadCategoryWiseSales.bind(this);
//         this.loadProductTypeData = this.loadProductTypeData.bind(this);
//         this.loadEmployeeWiseSales = this.loadEmployeeWiseSales.bind(this);
//         this.loadSalesDashboard = this.loadSalesDashboard.bind(this);
//         this.getRandomColor = this.getRandomColor.bind(this);
//         this.loadPaymentWiseDashboardData = this.loadPaymentWiseDashboardData.bind(this);
//         this.onSearchClick = this.onSearchClick.bind(this)
//         this.onCustomSearch = this.onCustomSearch.bind(this)
//         this.loadStore = this.loadStore.bind(this)
//         //this.loadCashregister = this.loadCashregister.bind(this)
//         this.loadSalesDashboardData = this.loadSalesDashboardData.bind(this);
//         this.loadSalesForLast12Months = this.loadSalesForLast12Months.bind(this);
//         this.handleKeyPress = this.handleKeyPress.bind(this)
//         this.onStoreChange = this.onStoreChange.bind(this)
//     };

//     async componentDidMount() {

//         if(this.props.registration.registration.hasOwnProperty('NewDashboard') && this.props.registration.registration.NewDashboard)
//         {
//             this.props.history.push('/home/reports/dashboard')
//         }
        
//         this.setState({ storeID: this.props.user.store.key })
//         let storeList = []
//         storeList.push({ key: this.props.user.store.key })

//         await this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
//             if (stores.length > 1) {
//                 stores.unshift({ key: '', StoreCode: 'All', StoreName: 'Stores' })
//             }
//             stores.map(m => {
//                 storeList.push({ key: m.key })
//             })
//             this.setState({ stores: stores })
//         });
//         this.setState({ storeList: storeList, storeID: storeList.length > 1 ? "" : this.props.user.store.key });

//         let pageFilters = this.props.getPageFilters(this.state.page);
//         //console.log("pageFilters", pageFilters);

//         if(pageFilters === null)
//         {
//             this.loadSalesDashboard(storeList, this.state.searchCashRegister,
//                 this.state.searchTransactionType, this.state.searchTransactionFromDate,
//                 this.state.searchTransactionToDate);

//             this.loadCategoryWiseSales(storeList, this.state.searchTransactionFromDate,
//                 this.state.searchTransactionToDate, this.state.sortCategoryBy);

//             let searchParameters = {
//                 storeList: storeList,
//                 searchCashRegister: this.state.searchCashRegister,
//                 searchTransactionType: this.state.searchTransactionType,
//                 searchTransactionFromDate: this.state.searchTransactionFromDate,
//                 searchTransactionToDate: this.state.searchTransactionToDate,
//                 type: this.state.type
//             }
//             this.props.setPageFilters(this.state.page, searchParameters)
//         }
//         else
//         {
//             this.loadSalesDashboard(pageFilters.storeList, pageFilters.searchCashRegister,
//                 pageFilters.searchTransactionType, pageFilters.searchTransactionFromDate,
//                 pageFilters.searchTransactionToDate);

//             this.loadCategoryWiseSales(pageFilters.storeList, pageFilters.searchTransactionFromDate,
//                 pageFilters.searchTransactionToDate, this.state.sortCategoryBy);

//             this.setState({ storeList: pageFilters.storeList, type: pageFilters.type, searchTransactionFromDate: pageFilters.searchTransactionFromDate, searchTransactionToDate: pageFilters.searchTransactionToDate });
//         }
        
//     };

//     componentWillMount() {
//         document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
//     }

//     getRandomColor() {
//         var letters = '0123456789ABCDEF';
//         var color = '#';
//         for (var i = 0; i < 6; i++) {
//             color += letters[Math.floor(Math.random() * 16)];
//         }
//         return color;
//     }

//     handleKeyPress(e) {
//         if (e.key === 'Enter') {
//             this.onSearchClick()
//         }
//     }

//     loadTopGoodsForSale(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getTopGoodsForSales(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, 10).then((result) => {
//             this.setState({ goods: result.searchResult, totalItemsCount: result.totalItemsCount })
//         });
//     };

//     loadTopServices(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getTopServices(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, 10).then((result) => {
//             this.setState({ services: result.searchResult, totalItemsCount: result.totalItemsCount })
//         });
//     };

//     loadTopWallets(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getTopWallets(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, 10).then((result) => {
//             this.setState({ wallets: result.searchResult, totalItemsCount: result.totalItemsCount })
//         });
//     };

//     loadTopPackages(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getTopPackages(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, 10).then((result) => {
//             this.setState({ packages: result.searchResult, totalItemsCount: result.totalItemsCount })
//         });
//     };

//     getWalkinCustomers(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         return this.props.getWalkinCustomers(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate).then((result) => {
//             return result.totalWalkinsCount
//         });
//     };

//     getSalesCustomers(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         return this.props.getSalesCustomers(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate).then((result) => {
//             return result.searchResult;
//         });
//     };

//     getNewCustomers(customers, searchTransactionFromDate, searchTransactionToDate) {
//         return this.props.getNewCustomers(customers, searchTransactionFromDate, searchTransactionToDate).then((result) => {
//             return result.totalNewCount;
//         });
//     };

//     getRepeatedCustomers(customers, searchTransactionFromDate, searchTransactionToDate) {
//         return this.props.getRepeatedCustomers(customers, searchTransactionFromDate, searchTransactionToDate).then((result) => {
//             return result.totalRepeatedCount
//         });
//     };

//     loadDashboardHeaders(storeList, searchCashRegister, searchTransactionType,
//         searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getDashboardHeaders(this.props.user.user.RegistrationID, storeList, searchCashRegister
//             , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {
//                 this.setState({ dashBoardHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadExpenseHeaders(storeList, searchCashRegister, searchTransactionType,
//         searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getExpensesforSalesDashboard(this.props.user.user.RegistrationID, storeList, searchCashRegister
//             , searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {
//                 this.setState({ expenseHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadCategoryWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate, sortBy = "sales") {
//         this.props.getCategoryWiseSales(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, 10, sortBy)
//             .then((result) => {
//                 this.setState({ categories: result.searchResult, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadSubCategoryWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getSubCategoryWiseSales(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {
//                 this.setState({ subcategories: result.searchResult, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadBrandWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getBrandWiseSales(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {
//                 this.setState({ brands: result.searchResult, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadProductWiseSales(storeList, searchTransactionType, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getProductWiseSales(this.props.user.user.RegistrationID, storeList, searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {
//                 this.setState({ products: result.searchResult, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadEmployeeWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getEmployeeWiseSales(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {
//                 let employees = result.searchResult.sort((a, b) => a.total_sales > b.total_sales)
//                 this.setState({ employees: employees, totalItemsCount: result.totalItemsCount })
//             });
//     };

//     loadSalesDashboardData(storeList, searchCashRegister, searchTransactionType,
//         searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getSalesDashboard(this.props.user.user.RegistrationID, storeList, searchCashRegister
//             , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
//             .then((result) => {


//                 let salesDashboardData = {
//                     labels: [], //['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
//                     datasets: [
//                         {
//                             label: 'Sales',
//                             backgroundColor: '#6e2d90',
//                             borderColor: '#6e2d90',
//                             hoverBorderColor: '#6e2d90',
//                             hoverBackgroundColor: '#6e2d90',
//                             data: []
//                         }
//                     ]
//                 };
//                 let selectePeriodTransactions = 0
//                 let selectedPeriodQty = 0
//                 let selectedPeriodCustomers = 0
//                 let selectedPeriodSales = 0
//                 let selectedPeriodAvgTicketSize = 0
//                 let storesSale = [];
//                 for (let l = 0; l < result.searchResult.length; l++) {
//                     const data = result.searchResult[l];
//                     for (let k = 0; k < this.state.stores.length; k++) {
//                         const store = this.state.stores[k];
//                         if (store.key === data.key) {

//                             salesDashboardData.labels.push(store.StoreCode);
//                             salesDashboardData.datasets[0].data.push(Number(data.total_sales.value).toFixed(2));

//                             storesSale.push({
//                                 "Store Name": (store.StoreCode + '-' + store.StoreName)
//                                 , "Transactions": Number(data.total_transactions.value).toFixed(0)
//                                 , "Qty": Number(data.total_qty.value).toFixed(2)
//                                 , "Customers": Number(data.total_customers.value).toFixed(0)
//                                 , "Sales": Number(data.total_sales.value).toFixed(2)
//                             })
//                             selectePeriodTransactions = Number(selectePeriodTransactions) + Number(data.total_transactions.value)
//                             selectedPeriodQty = Number(selectedPeriodQty) + Number(data.total_qty.value)
//                             selectedPeriodCustomers = Number(selectedPeriodCustomers) + Number(data.total_customers.value)
//                             selectedPeriodSales = Number(selectedPeriodSales) + Number(data.total_sales.value)
//                         }
//                     }
//                 }
//                 selectedPeriodAvgTicketSize = selectedPeriodSales === 0 ? 0 : Number(selectedPeriodSales / selectePeriodTransactions)

//                 var col = [];
//                 for (var i = 0; i < storesSale.length; i++) {
//                     for (var key in storesSale[i]) {
//                         if (col.indexOf(key) === -1) {
//                             col.push(key);
//                         }
//                     }
//                 }
//                 // console.log("columns", col)
//                 var result = "<table className='table table-bordered table-striped'><thead><tr>";
//                 for (var i = 0; i < col.length; i++) {
//                     result += "<th>";
//                     result += col[i];
//                     result += "</th>";
//                 }
//                 result += "</tr></thead>";

//                 // ADD JSON DATA TO THE TABLE AS ROWS.
//                 result += "<tbody>";
//                 for (var i = 0; i < storesSale.length; i++) {
//                     result += "<tr>";
//                     for (var j = 0; j < col.length; j++) {
//                         result += j === 0 ? "<td>" : "<td style='text-align:right'>";
//                         result += storesSale[i][col[j]] == undefined ? 0 : storesSale[i][col[j]];
//                         result += "</td>";
//                     }
//                     result += "</tr>";
//                 }
//                 result += "</tbody>";
//                 result += "<tfoot>";
//                 result += "<tr><td style='font-weight:bold'>Totals</td>"
//                 for (var j = 1; j < col.length; j++) {
//                     let total = 0
//                     for (var i = 0; i < storesSale.length; i++) {
//                         total += (storesSale[i][col[j]] === undefined ? 0 : Number(storesSale[i][col[j]]))
//                     }
//                     result += j === 0 ? "<td>" : "<td style='font-weight:bold;text-align:right'>";
//                     result += j === 1 || j == 3 ? Number(total).toFixed(0) : Number(total).toFixed(2);
//                     result += "</td>";
//                 }
//                 result += "</tr>"
//                 result += "</tfoot>";
//                 result += "</table>";
//                 this.setState({
//                     salesDashboardData: salesDashboardData,
//                     storesSale: result,
//                     selectePeriodTransactions: Number(selectePeriodTransactions).toFixed(0),
//                     selectedPeriodQty: Number(selectedPeriodQty).toFixed(0),
//                     selectedPeriodCustomers: Number(selectedPeriodCustomers).toFixed(0),
//                     selectedPeriodSales: Number(selectedPeriodSales).toFixed(0),
//                     selectedPeriodAvgTicketSize: Number(selectedPeriodAvgTicketSize).toFixed(2)
//                 });
//             });
//     };

//     loadPaymentWiseDashboardData(storeList, searchCashRegister, searchTransactionType,
//         searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getPaymentModeTransactionTypeWiseSales(this.props.user.user.RegistrationID, storeList,
//             searchTransactionType, searchTransactionFromDate, searchTransactionToDate, true)
//             .then((result) => {

//                 let paymentsData = {
//                     labels: [],
//                     datasets: [
//                         {
//                             label: 'Payment Mode',
//                             backgroundColor: ['#6e2d90', '#ff5252', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
//                                 '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
//                                 '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
//                                 '#C77B85', '#C39762'],
//                             hoverBackgroundColor: ['#6e2d90', '#ff5252', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
//                                 '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
//                                 '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
//                                 '#C77B85', '#C39762'],
//                             data: []
//                         }
//                     ]
//                 };

//                 let paymentData = []
//                 let paymentsShort = []
//                 for (let index = 0; index < result.searchResult.length; index++) {
//                     const payment = result.searchResult[index];

//                     //For Pie Chart
//                     paymentsData.labels.push(payment.name);
//                     paymentsData.datasets[0].data.push(payment.total_mode_amount);
//                     paymentsShort.push({ "PaymentMode": payment.name, "Amount": payment.total_mode_amount })
//                     var obj = {
//                         "Payment Mode": payment.name
//                     }

//                     this.props.config.TransactionTypes.filter((e) => e.PaymentModeChecked === true && e.SalesImpactChecked === true && !e.IsDeleted).map((e) => {

//                         obj[e.TransactionType] = 0;
//                         for (let index1 = 0; index1 < payment.transactionTypes.length; index1++) {

//                             if (e.TransactionType === payment.transactionTypes[index1].name) {
//                                 obj[e.TransactionType] = payment.transactionTypes[index1].total_tran_amount
//                             }
//                         }
//                     })
//                     obj["Total"] = payment.total_mode_amount
//                     paymentData.push(obj);
//                 }
//                 var col = [];
//                 for (var i = 0; i < paymentData.length; i++) {
//                     for (var key in paymentData[i]) {
//                         if (col.indexOf(key) === -1) {
//                             col.push(key);
//                         }
//                     }
//                 }
//                 var result = "<table className='table table-bordered table-striped'><thead><tr>";
//                 for (var i = 0; i < col.length; i++) {
//                     result += "<th>";
//                     result += col[i];
//                     result += "</th>";
//                 }
//                 result += "</tr></thead>";

//                 // ADD JSON DATA TO THE TABLE AS ROWS.
//                 result += "<tbody>";
//                 for (var i = 0; i < paymentData.length; i++) {
//                     result += "<tr>";
//                     for (var j = 0; j < col.length; j++) {
//                         result += j === 0 ? "<td>" : "<td style='text-align:right'>";
//                         result += paymentData[i][col[j]] == undefined ? 0 : paymentData[i][col[j]];
//                         result += "</td>";
//                     }
//                     result += "</tr>";
//                 }
//                 result += "</tbody>";
//                 result += "</table>";
//                 // console.log("table", result)
//                 this.setState({ payments: result, paymentsData: paymentsData, paymentsShort: paymentsShort });
//             });
//     };

//     loadProductTypeData(storeList, searchTransactionFromDate, searchTransactionToDate) {
//         this.props.getProductTypeWiseSales(this.props.user.user.RegistrationID, storeList,  searchTransactionFromDate, searchTransactionToDate).then((result) => {
//             this.setState({ productTypes: result.searchResult, totalItemsCount: result.totalItemsCount })
//         });
//     };

//     async loadSalesDashboard(storeList, searchCashRegister, searchTransactionType,
//         searchTransactionFromDate, searchTransactionToDate) {

//         this.loadDashboardHeaders(storeList, searchCashRegister, searchTransactionType,
//             searchTransactionFromDate, searchTransactionToDate);

//         this.loadExpenseHeaders(storeList, searchCashRegister, searchTransactionType,
//             searchTransactionFromDate, searchTransactionToDate);

//         this.loadSubCategoryWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadBrandWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadProductWiseSales(storeList, searchTransactionType,
//             searchTransactionFromDate, searchTransactionToDate);

//         this.loadEmployeeWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadProductTypeData(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadPaymentWiseDashboardData(storeList, searchCashRegister,
//             searchTransactionType, searchTransactionFromDate,
//             searchTransactionToDate);

//         this.loadSalesDashboardData(storeList, searchCashRegister,
//             searchTransactionType, searchTransactionFromDate,
//             searchTransactionToDate);

//         this.loadSalesForLast12Months(storeList);

//         this.loadQuarterWiseSalesForLast12Months(storeList);

//         this.loadTopGoodsForSale(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadTopServices(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadTopWallets(storeList, searchTransactionFromDate, searchTransactionToDate);

//         this.loadTopPackages(storeList, searchTransactionFromDate, searchTransactionToDate);

//         let totalWalkinCount = await this.getWalkinCustomers(storeList, searchTransactionFromDate, searchTransactionToDate);

//         let customers = await this.getSalesCustomers(storeList, searchTransactionFromDate, searchTransactionToDate);
//         //console.log("customers", customers);

//         let totalNewCount = await this.getNewCustomers(customers, searchTransactionFromDate, searchTransactionToDate);

//         let totalRepeatedCount = await this.getRepeatedCustomers(customers, searchTransactionFromDate, searchTransactionToDate);

//         //console.log("totalWalkinCount", totalWalkinCount);
//         //console.log("totalNewCount", totalNewCount);
//         //console.log("totalRepeatedCount", totalRepeatedCount);
//         let totalCustomers = Number(totalWalkinCount) + Number(totalNewCount) + Number(totalRepeatedCount)
//         // console.log("totalCustomers", totalCustomers);
//         this.setState({ totalWalkinCount: totalWalkinCount, totalNewCount: totalNewCount, totalRepeatedCount: totalRepeatedCount })
//     };

//     loadSalesForLast12Months(storeList) {

//         let searchTransactionFromDate = new Date();

//         searchTransactionFromDate = searchTransactionFromDate.setMonth(searchTransactionFromDate.getMonth() - 12);
//         searchTransactionFromDate = new Date(searchTransactionFromDate).toISOString().split('T')[0]

//         let searchTransactionToDate = new Date().toISOString().split('T')[0];

//         this.props.getSalesForLast12Months(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, "month")
//             .then((result) => {

//                 let sales12MonthsDashboard = {
//                     labels: [],
//                     datasets: [
//                         {
//                             label: 'Sales',
//                             backgroundColor: '#6e2d90',
//                             borderColor: '#6e2d90',
//                             hoverBorderColor: '#6e2d90',
//                             hoverBackgroundColor: '#6e2d90',
//                             data: []
//                         }
//                     ]
//                 };
//                 let sales12MonthsData = []
//                 let totalSales = 0;
//                 let totalTransactions = 0;
//                 let months = 0;
//                 let avgMonthlySales = 0;
//                 let avgTicketSize = 0;
//                 for (let index = 0; index < result.searchResult.length; index++) {
//                     const data = result.searchResult[index];
//                     sales12MonthsDashboard.labels.push(data.date);
//                     sales12MonthsDashboard.datasets[0].data.push(Number(data.total_sales).toFixed(2));
//                     var obj = {
//                         "Month": data.date,
//                         "Total": data.total_sales
//                     }
//                     totalSales = totalSales + Number(obj.Total);
//                     totalTransactions = totalTransactions + Number(data.total_transactions);
//                     months = months + 1;
//                     sales12MonthsData.push(obj);
//                 }
//                 avgMonthlySales = totalSales === 0 ? 0 : Number(totalSales) / Number(months);
//                 avgTicketSize = totalSales === 0 ? 0 : Number(totalSales) / Number(totalTransactions);
//                 // console.log("totalSales", totalSales);
//                 // console.log("avgMonthlySales", avgMonthlySales);
//                 // console.log("avgTicketSize", avgTicketSize);

//                 this.setState({ totalSales: Number(totalSales).toFixed(0), avgMonthlySales: Number(avgMonthlySales).toFixed(0), avgTicketSize: Number(avgTicketSize).toFixed(2) })

//                 var col = [];
//                 for (var i = 0; i < sales12MonthsData.length; i++) {
//                     for (var key in sales12MonthsData[i]) {
//                         if (col.indexOf(key) === -1) {
//                             col.push(key);
//                         }
//                     }
//                 }
//                 var result = "<table className='table table-bordered table-striped'><thead><tr>";
//                 for (var i = 0; i < col.length; i++) {
//                     result += "<th>";
//                     result += col[i];
//                     result += "</th>";
//                 }
//                 result += "</tr></thead>";

//                 // ADD JSON DATA TO THE TABLE AS ROWS.
//                 result += "<tbody>";
//                 for (var i = 0; i < sales12MonthsData.length; i++) {
//                     result += "<tr>";
//                     for (var j = 0; j < col.length; j++) {
//                         result += j === 0 ? "<td>" : "<td style='text-align:right'>";
//                         result += sales12MonthsData[i][col[j]] == undefined ? 0 : sales12MonthsData[i][col[j]];
//                         result += "</td>";
//                     }
//                     result += "</tr>";
//                 }
//                 result += "</tbody>";
//                 result += "</table>";
//                 // console.log("table", result)

//                 this.setState({ sales12MonthsDashboard: sales12MonthsDashboard, sales12MonthsData: result });

//             });
//     };

//     loadQuarterWiseSalesForLast12Months(storeList) {

//         let searchTransactionFromDate = new Date();


//         var date = new Date(), y = date.getFullYear();
//         var firstDay = new Date(y, 0, 1);

//         let fromDate = new Date(firstDay).setHours(23, 59)

//         // searchTransactionFromDate = searchTransactionFromDate.setMonth(-12);
//         searchTransactionFromDate = new Date(fromDate).toISOString().split('T')[0]

//         let searchTransactionToDate = new Date().toISOString().split('T')[0];

//         this.props.getSalesForLast12Months(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate, "quarter")
//             .then((result) => {

//                 let salesQuarterDashboard = {
//                     labels: [],
//                     datasets: [
//                         {
//                             label: 'Sales',
//                             backgroundColor: ['#6e2d90', '#ff5252', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
//                                 '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
//                                 '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
//                                 '#C77B85', '#C39762'],
//                             hoverBackgroundColor: ['#6e2d90', '#ff5252', '#F39C12', '#0073B7', '#51CDA0', '#DF7970',
//                                 '#4C9CA0', '#AE7D99', '#C9D45C', '#5592AD',
//                                 '#DF874D', '#52BCA8', '#8E7AA3', '#E3CB64',
//                                 '#C77B85', '#C39762'],
//                             data: []
//                         }
//                     ]
//                 };


//                 let salesQuarterDashboardData = []

//                 for (let index = 0; index < result.searchResult.length; index++) {

//                     const data = result.searchResult[index];
//                     salesQuarterDashboard.labels.push(data.date);
//                     salesQuarterDashboard.datasets[0].data.push(Number(data.total_sales).toFixed(2));

//                     var obj = {
//                         "Quarter": data.date,
//                         "Total": data.total_sales
//                     }
//                     salesQuarterDashboardData.push(obj);
//                 }

//                 var col = [];
//                 for (var i = 0; i < salesQuarterDashboardData.length; i++) {
//                     for (var key in salesQuarterDashboardData[i]) {
//                         if (col.indexOf(key) === -1) {
//                             col.push(key);
//                         }
//                     }
//                 }
//                 var result = "<table className='table table-bordered table-striped'><thead><tr>";
//                 for (var i = 0; i < col.length; i++) {
//                     result += "<th>";
//                     result += col[i];
//                     result += "</th>";
//                 }
//                 result += "</tr></thead>";

//                 // ADD JSON DATA TO THE TABLE AS ROWS.
//                 result += "<tbody>";
//                 for (var i = 0; i < salesQuarterDashboardData.length; i++) {
//                     result += "<tr>";
//                     for (var j = 0; j < col.length; j++) {
//                         result += j === 0 ? "<td>" : "<td style='text-align:right'>";
//                         result += salesQuarterDashboardData[i][col[j]] == undefined ? 0 : salesQuarterDashboardData[i][col[j]];
//                         result += "</td>";
//                     }
//                     result += "</tr>";
//                 }
//                 result += "</tbody>";
//                 result += "</table>";
//                 // console.log("table", result)

//                 this.setState({ salesQuarterDashboard: salesQuarterDashboard, salesQuarterDashboardData: result });

//             });
//     };

//     onSearchClick(type) {

//         let searchTransactionFromDate = ''
//         let searchTransactionToDate = ''

//         if (type === 'today') {
//             searchTransactionFromDate = new Date().toISOString().split('T')[0]
//             searchTransactionToDate = new Date().toISOString().split('T')[0]
//         }
//         else if (type === 'yesterday') {
//             let date = new Date();
//             date.setDate(date.getDate() - 1);
//             searchTransactionFromDate = new Date(date).toISOString().split('T')[0]
//             searchTransactionToDate = new Date(date).toISOString().split('T')[0]
//         }
//         else if (type === 'month') {
//             var date = new Date(), y = date.getFullYear(), m = date.getMonth();
//             var firstDay = new Date(y, m, 1);
//             var lastDay = new Date(y, m + 1, 0);

//             let fromDate = new Date(firstDay).setHours(23, 59)
//             searchTransactionFromDate = new Date(fromDate).toISOString().split('T')[0]

//             let toDate = new Date(lastDay).setHours(23, 59)
//             searchTransactionToDate = new Date(toDate).toISOString().split('T')[0]
//         }
//         else if (type === 'year') {
//             var date = new Date(), y = date.getFullYear();

//             let fromDate = new Date(y, 0, 1).setHours(23, 59)
//             searchTransactionFromDate = new Date(fromDate).toISOString().split('T')[0]

//             let toDate = new Date(y, 11, 31).setHours(23, 59)
//             searchTransactionToDate = new Date(toDate).toISOString().split('T')[0]
//         }
//         this.setState({ searchTransactionFromDate: searchTransactionFromDate, searchTransactionToDate: searchTransactionToDate, type: type });

//         this.loadSalesDashboard(this.state.storeList, this.state.searchCashRegister,
//             this.state.searchTransactionType, searchTransactionFromDate,
//             searchTransactionToDate);

//         this.loadCategoryWiseSales(this.state.storeList, searchTransactionFromDate, searchTransactionToDate, this.state.sortCategoryBy);

//         let searchParameters = {
//             storeList: this.state.storeList,
//             searchCashRegister: this.state.searchCashRegister,
//             searchTransactionType: this.state.searchTransactionType,
//             searchTransactionFromDate: searchTransactionFromDate,
//             searchTransactionToDate: searchTransactionToDate,
//             type: type
//         }

//         this.props.setPageFilters(this.state.page, searchParameters);
        
//     };

//     onCustomSearch() {
//         this.setState({ type: 'custom' });
//         this.loadSalesDashboard(this.state.storeList, "", "", this.state.searchTransactionFromDate, this.state.searchTransactionToDate);
//     };

//     loadStore() {
//         this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, 'active',
//             '', '', '', '', '', '', '', '', '', '', '').then((data) => {
//                 this.setState({ storeList: data.searchResult })
//             })
//     };

//     onStoreChange(e) {
//         let storeList = []
//         if (e.target.value != "") {
//             storeList.push({ key: e.target.value })
//         }
//         else {
//             for (let i = 0; i < this.state.stores.length; i++) {
//                 const store = this.state.stores[i];
//                 storeList.push({ key: store.key })
//             }
//         }
//         this.setState({ storeList: storeList, storeID: e.target.value })

//         this.loadSalesDashboard(storeList, this.state.searchCashRegister,
//             this.state.searchTransactionType, this.state.searchTransactionFromDate,
//             this.state.searchTransactionToDate);

//         this.loadCategoryWiseSales(storeList, this.state.searchTransactionFromDate, this.state.searchTransactionToDate, this.state.sortCategoryBy);
//     }

//     renderBoxHeader(report){
//         return 
//     }

//     renderStoreSales(){
//         return <div className="dashboard-box-wide">
//             <div className="dashboard-box-header">
//                 <div className="dashboard-box-title left">Store Sales</div>
//                 <div className="right">
//                     <button type="button"
//                         className="btn btn-default btn-flat btn-md pull-right fa fa-list"
//                         style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showSalesList ? "#6e2d90" : "gray" }}
//                         onClick={() => this.setState({ showSalesChart: false, showSalesList: true })}></button>
//                     <button type="button"
//                         className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
//                         style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showSalesChart ? "#6e2d90" : "gray" }}
//                         onClick={() => this.setState({ showSalesChart: true, showSalesList: false })}></button>
//                 </div>
//             </div>
//             <div className="dashboard-box-body">
//                 {this.state.showSalesChart && <div className="chart">
//                     <Bar
//                         width={500}
//                         height={100}
//                         data={this.state.salesDashboardData}
//                         options={{maintainAspectRatio: false, 
//                             scales: {xAxes: [{gridLines: {display: false}}],
//                                 yAxes: [{gridLines: {display: false},ticks: {beginAtZero: true}}]
//                             }
//                         }}
//                     />
//                 </div>}
//                 {this.state.showSalesList && <div className="table-responsive scroll2" 
//                 // style={{ height: "245px", overflowY: "scroll", overflowX: "auto" }}
//                 >
//                             {ReactHtmlParser(this.state.storesSale)}
//                 </div>}
//             </div>
//             <div className="dashboard-box-footer">
//                 <div className="description-block border-right">
//                     <h5 className="description-header">{this.state.selectedPeriodSales}</h5>
//                     <span className="description-text">TOTAL SALES</span>
//                 </div>
//                 <div className="description-block border-right">
//                     {/* <span className="description-percentage text-green"><i className="fa fa-caret-up"></i> 17%</span> */}
//                     <h5 className="description-header">{this.state.selectePeriodTransactions}</h5>
//                     <span className="description-text">TOTAL TRANSACTIONS</span>
//                 </div>
//                 <div className="description-block border-right">
//                     {/* <span className="description-percentage text-green"><i className="fa fa-caret-up"></i> 17%</span> */}
//                     <h5 className="description-header">{this.state.selectedPeriodAvgTicketSize}</h5>
//                     <span className="description-text">AVG TICKET</span>
//                 </div>
//                 <div className="description-block">
//                     {/* <span className="description-percentage text-green"><i className="fa fa-caret-up"></i> 17%</span> */}
//                     <h5 className="description-header">{this.state.selectedPeriodCustomers}</h5>
//                     <span className="description-text">CUSTOMERS</span>
//                 </div>
//             </div>
//         </div>}

//     renderPaymentMode(){
//         return <div className="dashboard-box">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Payment Mode</div>
//             <div className="right">
//                 <button type="button"
//                     className="btn btn-default btn-flat btn-md pull-right fa fa-list"
//                     style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showPaymentModeList ? "#6e2d90" : "gray" }}
//                     onClick={() => this.setState({ showPaymentModeChart: false, showPaymentModeList: true })}></button>
//                 <button type="button"
//                     className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
//                     style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showPaymentModeChart ? "#6e2d90" : "gray" }}
//                     onClick={() => this.setState({ showPaymentModeChart: true, showPaymentModeList: false })}></button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//                 {this.state.showPaymentModeList && <div className="table-responsive scroll2" 
//                     // style={{ height: "245px", overflowY: "scroll", overflowX: "auto" }}
//                     >
//                         <table className='table table-bordered table-striped'>
//                             <thead>
//                                 <tr>
//                                     <th>Payment Mode
//                                     </th>
//                                     <th>Amount</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {this.state.paymentsShort.map(m => <tr>
//                                     <td>
//                                         {m.PaymentMode}
//                                     </td>
//                                     <td style={{ textAlign: "right" }}>
//                                         {m.Amount}
//                                     </td>
//                                 </tr>)}
//                             </tbody>
//                         </table>
//                     </div>}
//                 {this.state.showPaymentModeChart && <div className="chart" 
//                     // style={{ height: "245px" }}
//                     >
//                         <Doughnut
//                             width={623}
//                             height={300}
//                             data={this.state.paymentsData}
//                             options={{
//                                 maintainAspectRatio: false
//                             }}
//                         />
//                     </div>}
//         </div>
//     </div>
//     }
    
//     render12MonthSales(){
//         return <div className="dashboard-box-wide ">
//         <div className="dashboard-box-header ">
//             <h3 className="dashboard-box-title left">Last 12 Months Sales</h3>
//             <div className="right">
//                 <button type="button"
//                     className="btn btn-default btn-flat btn-md pull-right fa fa-list"
//                     style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showLastSalesList ? "#6e2d90" : "gray" }}
//                     onClick={() => this.setState({ showLastSalesChart: false, showLastSalesList: true })}></button>
//                 <button type="button"
//                     className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
//                     style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showLastSalesChart ? "#6e2d90" : "gray" }}
//                     onClick={() => this.setState({ showLastSalesChart: true, showLastSalesList: false })}></button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             {this.state.showLastSalesChart && <div className="chart">
//                     <Bar
//                         width={500}
//                         height={300}
//                         data={this.state.sales12MonthsDashboard}
//                         options={{
//                             maintainAspectRatio: false, scales: {
//                                 xAxes: [{gridLines: {display: false}}],
//                                 yAxes: [{gridLines: {display: false},ticks: {beginAtZero: true} }]
//                             }
//                         }}
//                     />
//                 </div>}
//             {this.state.showLastSalesList && <div className="table-responsive scroll2">
//                 {ReactHtmlParser(this.state.sales12MonthsData)}
//             </div>}
//         </div>
//         <div className="dashboard-box-footer">
//             <div className="description-block border-right">
//                 <h5 className="description-header">{this.state.totalSales}</h5>
//                 <span className="description-text">TOTAL SALES</span>
//             </div>
//             <div className="description-block border-right">
//                 <h5 className="description-header">{this.state.avgMonthlySales}</h5>
//                 <span className="description-text">MONTHLY AVG SALES</span>
//             </div>
//             <div className="description-block">
//                 <h5 className="description-header">{this.state.avgTicketSize}</h5>
//                 <span className="description-text">AVG TICKET</span>
//             </div>
//         </div>
//     </div>}

//     renderQuaterlySales(){
//         return <div className="dashboard-box ">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Quarterly Sales</div>
//             <div className="right">
//                 <button type="button"
//                     className="btn btn-default btn-flat btn-md pull-right fa fa-list"
//                     style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showQuarterSalesList ? "#6e2d90" : "gray" }}
//                     onClick={() => this.setState({ showQuarterSalesChart: false, showQuarterSalesList: true })}></button>
//                 <button type="button"
//                     className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
//                     style={{ padding: "6px", marginLeft: 0, marginRight: 0, color: this.state.showQuarterSalesChart ? "#6e2d90" : "gray" }}
//                     onClick={() => this.setState({ showQuarterSalesChart: true, showQuarterSalesList: false })}></button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             {this.state.showQuarterSalesChart && <div className="chart">
//                         <Pie
//                             width={623}
//                             height={300}
//                             data={this.state.salesQuarterDashboard}
//                             options={{
//                                 maintainAspectRatio: false
//                             }}
//                         />
//                     </div>}
//             {this.state.showQuarterSalesList && <div className="table-responsive scroll2">
//                 {ReactHtmlParser(this.state.salesQuarterDashboardData)}
//             </div>}
//         </div>
//     </div>}

//     renderProduct(){
//         return <div className="dashboard-box">
//         <div className="dashboard-box-header ">
//             <div className="dashboard-box-title left">Product</div>
//             <div className="right">
//                 <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/itemsolddetail",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         storeList: this.state.storeList
//                     }
//                 })}>View More</button>
//             </div>  
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.products.length > 0 && <table className="table  table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Product</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.products.map((product) =>
//                             <tr>
//                                 <td>{product.name}</td>
//                                 <td style={{ textAlign: "right" }}>{product.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{product.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.products.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>
//     }

//     renderCategory(){
//         return <div className="dashboard-box ">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Category</div>
//             <div className="right">
//                 <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/categorywise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         storeList: this.state.storeList
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.categories.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Category</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Sales</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.categories.map((category) =>
//                             <tr>
//                                 <td>{category.name}</td>
//                                 <td style={{ textAlign: "right" }}>{category.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{category.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.categories.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>
//     }

//     renderSubCategory(){
//         return  <div className="dashboard-box ">
//         <div className="dashboard-box-header ">
//             <div className="dashboard-box-title left">Sub Category</div>
//             <div className="right">
//                 <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/subcategorywise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         storeList: this.state.storeList
//                     }
//                 })}>View More</button>
//             </div>
           
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.subcategories.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Sub Category</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.subcategories.map((subcategory) =>
//                             <tr>
//                                 <td>{subcategory.name}</td>
//                                 <td style={{ textAlign: "right" }}>{subcategory.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{subcategory.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.subcategories.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>
//     }

//     renderBrand(){
//         return <div className="dashboard-box" >
//         <div className="dashboard-box-header ">
//             <div className="dashboard-box-title left">Brand</div>
//             <div className="right">
//                 <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/brandwise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.brands.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Brand</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.brands.map((brand) =>
//                             <tr>
//                                 <td>{brand.name}</td>
//                                 <td style={{ textAlign: "right" }}>{brand.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{brand.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.brands.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>
//     }

//     renderProductType(){
//         return <div className="dashboard-box ">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Product Type</div>
//             <div className="right">
//                 <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/producttypewise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         searchProductType: ''
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.productTypes.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Product Type</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.productTypes.map((productType) =>
//                             <tr>
//                                 <td>{productType.name}</td>
//                                 <td style={{ textAlign: "right" }}>{productType.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{productType.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.productTypes.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>}

//     renderEmployee(){
//         return <div className="dashboard-box ">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Employee</div>
//             <div className="right">
//             <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/employeeitemdetail",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.employees.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Employee</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.employees.map((employee) =>
//                             <tr>
//                                 <td>{employee.name}</td>
//                                 <td style={{ textAlign: "right" }}>{employee.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{employee.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.employees.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>}

//     renderGoodsForSale(){
//         return <div className="dashboard-box">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Goods for sale</div>
//             <div className="right">
//             <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/producttypewise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         searchProductType: 'Goods for sale'
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.goods.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Name</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.goods.map((good) =>
//                             <tr>
//                                 <td>{good.name}</td>
//                                 <td style={{ textAlign: "right" }}>{good.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{good.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.goods.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>}

//     renderServices(){
//         return <div className="dashboard-box ">
//         <div className="dashboard-box-header with-border">
//             <div className="dashboard-box-title left">Services</div>
//             <div className="right">
//             <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/producttypewise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         searchProductType: 'Services'
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.services.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Name</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.services.map((service) =>
//                             <tr>
//                                 <td>{service.name}</td>
//                                 <td style={{ textAlign: "right" }}>{service.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{service.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.services.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>}

//     renderWallet(){
//         return <div className="dashboard-box ">
//         <div className="dashboard-box-header with-border">
//             <div className="dashboard-box-title left">Wallet</div>
//             <div className="right">
//             <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/producttypewise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         searchProductType: 'Wallet Recharge'
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.wallets.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Name</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.wallets.map((wallet) =>
//                             <tr>
//                                 <td>{wallet.name}</td>
//                                 <td style={{ textAlign: "right" }}>{wallet.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{wallet.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.wallets.length === 0 && "No records found"}
//             </div>
//         </div>
//     </div>}

//     renderPackages(){
//         return <div className="dashboard-box">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Packages</div>
//             <div className="right">
//             <button type="submit" className="btn pull-right btn-flat"
//                 onClick={() => this.props.history.push({
//                     pathname: "/home/reports/producttypewise",
//                     state: {
//                         action: 'Sales Dashboard',
//                         searchTransactionFromDate: this.state.searchTransactionFromDate,
//                         searchTransactionToDate: this.state.searchTransactionToDate,
//                         searchProductType: 'Packages'
//                     }
//                 })}>View More</button>
//             </div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 {this.state.packages.length > 0 && <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Name</th>
//                             <th style={{ textAlign: "right" }}>Qty</th>
//                             <th style={{ textAlign: "right" }}>Amount</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {this.state.packages.map((objPackage) =>
//                             <tr>
//                                 <td>{objPackage.name}</td>
//                                 <td style={{ textAlign: "right" }}>{objPackage.total_qty}</td>
//                                 <td style={{ textAlign: "right" }}>{objPackage.total_sales}</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>}
//                 {this.state.packages === 0 && "No records found"}
//             </div>
//         </div>
//     </div>}

//     renderCustomer(){
//         return <div className="dashboard-box">
//         <div className="dashboard-box-header">
//             <div className="dashboard-box-title left">Customers</div>
//         </div>
//         <div className="dashboard-box-body">
//             <div className="table-responsive scroll2">
//                 <table className="table table-striped no-margin">
//                     <thead>
//                         <tr>
//                             <th>Customers</th>
//                             <th style={{ textAlign: "right" }}>Count</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>Walkins</td>
//                             <td style={{ textAlign: "right" }}>{this.state.totalWalkinCount}</td>
//                         </tr>
//                         <tr>
//                             <td>New</td>
//                             <td style={{ textAlign: "right" }}>{this.state.totalNewCount}</td>
//                         </tr>
//                         <tr>
//                             <td>Repeated</td>
//                             <td style={{ textAlign: "right" }}>{this.state.totalRepeatedCount}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     </div>}

//     render() {

//         return <div className="content-wrapper">
//             <section className="content" >
//                 <div  className="dashboard-header">
//                     <div className="dashboard-header-title left">
//                         Dashboard
//                     </div>
//                     <div className="dashboard-header-options right">
//                         <div className="input-group pull-right">
//                             <select type="text" className="form-control"
//                                 value={this.state.storeID}
//                                 onChange={this.onStoreChange}>
//                                 {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
//                                     <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
//                                 )}
//                             </select>
//                             <div className="input-group-btn">
//                                 <button type="button"
//                                     className={this.state.type === 'custom' ? "btn button-selected button-flat button-border dropdown-toggle button-lg" : "btn button-flat button-border dropdown-toggle button-lg"}
//                                         data-toggle="dropdown">Custom &nbsp;
//                                         <span className="fa fa-calendar"></span>
//                                 </button>
//                                 <ul className="dropdown-menu">
//                                     <li>
//                                         <input type="date"
//                                             onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
//                                             value={this.state.searchTransactionFromDate} />
//                                         <br />
//                                         <input type="date"
//                                             onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
//                                             value={this.state.searchTransactionToDate} />
//                                         <br />
//                                         <button type="button" className="btn button-selected button-border"
//                                             disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
//                                             onClick={this.onCustomSearch}>Search</button>
//                                     </li>
//                                 </ul>
//                                 <button type="button" className={this.state.type === 'year' ? "btn button-selected button-flat button-border button-lg" : "btn button-flat button-border button-lg"}  onClick={() => this.onSearchClick('year')}>Year</button>
//                                 <button type="button" className={this.state.type === 'month' ? "btn button-selected button-flat button-border button-lg" : "btn button-flat button-border button-lg"}  onClick={() => this.onSearchClick('month')}>Month</button>
//                                 <button type="button" className={this.state.type === 'yesterday' ? "btn button-selected button-flat button-border button-lg" : "btn button-flat button-border button-lg"}  onClick={() => this.onSearchClick('yesterday')}>Yesterday</button>
//                                 <button type="button" className={this.state.type === 'today' ? "btn button-selected  button-border button-right-rounded button-lg" : "btn button-border button-right-rounded button-lg"} onClick={() => this.onSearchClick('today')}>Today</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dashboard-summary">
//                     <div className="info-box info-blue" >
//                         <div className="info-box-icon ">
//                             <Icon type="sales"/>
//                         </div>
//                         <div className="info-box-content">
//                             <div className="info-box-text">Sales</div>
//                             <div className="info-box-number">
//                                 {Number(this.state.dashBoardHeader.total_paid_sales).toFixed(0)}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="info-box info-red">
//                         <div className="info-box-icon ">
//                             <Icon type="list"/>
//                         </div>
//                         <div className="info-box-content">
//                             <div className="info-box-text">Transactions</div>
//                             <div className="info-box-number">{Number(this.state.dashBoardHeader.total_transactions).toFixed(0)} </div>
//                             {/* <div>Qty: {this.state.dashBoardHeader.total_qty}</div> */}
//                         </div>
//                     </div>
//                         <div className="info-box info-yellow">
//                             <div className="info-box-icon ">
//                                 <Icon type="customer"/>
//                             </div>
//                             <div className="info-box-content">
//                                 <div className="info-box-text">Customers</div>
//                                 <div className="info-box-number">
//                                     {Number(this.state.dashBoardHeader.total_customers).toFixed(0)}
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="info-box info-green" >
//                             <div className="info-box-icon ">
//                                 <Icon type="expense"/>
//                             </div>
//                             <div className="info-box-content">
//                                 <div className="info-box-text" >Expenses</div>
//                                 <div className="info-box-number">{this.state.expenseHeader.total_expenses}</div>
//                             </div>
//                         </div>
//                 </div>
//                 <div className="dashboard-details"> 
//                     {this.renderStoreSales()}
//                     {this.renderPaymentMode()}
//                     {this.render12MonthSales()}
//                     {this.renderQuaterlySales()}
//                     {this.renderProduct()}
//                     {this.renderCategory()}
//                     {this.renderSubCategory()}
//                     {this.renderBrand()}
//                     {this.renderProductType()}
//                     {this.renderEmployee()}
//                     {this.renderGoodsForSale()}
//                     {this.renderServices()}
//                     {this.renderWallet()}
//                     {this.renderPackages()}
//                     {this.renderCustomer()}
//                 </div>
                
//             </section>
//         </div>
//     };
// };
// export default SalesDashboard;


{/* <div className="row">
                    <div className="col-xs-12 hidden-md hidden-lg hiddn-xl hidden-sm">
                        <font style={{ fontSize: "30px", marginBottom: "4px" }}>Dashboard</font>
                    </div>
                    <div className="col-xs-12 hidden-md hidden-lg hiddn-xl hidden-sm">
                        <select type="text" className="form-control"
                            value={this.state.storeID}
                            onChange={this.onStoreChange}>
                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                            )}
                        </select>
                    </div>
                    <div className="col-xs-5 hidden-md hidden-lg hiddn-xl hidden-sm" style={{ paddingRight: "0px" }}>
                        <input type="date" className="form-control"
                            onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                            value={this.state.searchTransactionFromDate} onKeyPress={this.handleKeyPress} />
                    </div>
                    <div className="col-xs-5 hidden-md hidden-lg hiddn-xl hidden-sm" style={{ padding: "0px" }}>
                        <input type="date" className="form-control"
                            onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                            value={this.state.searchTransactionToDate} onKeyPress={this.handleKeyPress} />

                    </div>
                    <div className="col-xs-2 hidden-md hidden-lg hiddn-xl hidden-sm" style={{ paddingLeft: "0px" }}>
                        <button type="button" className="btn btn-primary btn-flat btn-md fa fa-search"
                            style={{ width: "100%", height: "34px" }}
                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                            onClick={this.onSearchClick}></button>
                    </div>
                    <div className="col-md-12 hidden-xs">
                        <div className="row">
                            <div className="col-md-7">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>Dashboard</font>
                            </div>
                            <div className="col-md-5">
                                
                            </div>
                        </div>
                    </div>
                    
                </div> */}