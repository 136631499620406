import axios from "axios";

export function getCustomerMLoyalLoyaltyPoints(
  registrationID,
  storeID,
  customerID,
  customerMobileNo
) {
  // let shopCode = this.state.reStoreCode;
  // console.log('registrationID',registrationID);
  // console.log('storeID',storeID);
  // console.log('customerID',customerID);
  // console.log('customerMobileNo',customerMobileNo);

  return axios
    .post(
      "https://us-central1-posible-app-prod.cloudfunctions.net/apiGetMLoyalCustomerLoyaltyPoints",
      {
        RegistrationID: registrationID,
        StoreID: storeID,
        CustomerID: customerID,
        CustomerMobileNo: customerMobileNo,
      }
    )
    .then((res) => {
      // console.log('getBrandList', res)
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      // console.log('Could not get businessname for provided domain name. Try again later.');
    });
}

export function validateMLoyalCustomerLoyaltyPoints(
  registrationID,
  storeID,
  storeCode,
  customerID,
  customerMobileNo,
  customerPoints
) {
  // let shopCode = this.state.reStoreCode;
  // console.log('registrationID',registrationID);
  // console.log('storeID',storeID);
  // console.log('customerID',customerID);
  // console.log('customerMobileNo',customerMobileNo);

  return axios
    .post(
      "https://us-central1-posible-app-prod.cloudfunctions.net/apiValidateMLoyalCustomerLoyaltyPoints",
      {
        RegistrationID: registrationID,
        StoreID: storeID,
        StoreCode: storeCode,
        CustomerID: customerID,
        CustomerMobileNo: customerMobileNo,
        CustomerPoints: customerPoints,
      }
    )
    .then((res) => {
      // console.log('getBrandList', res)
      console.log(res);
      return res.data;
    })
    .catch((err) => {
      console.log("validateMLoyalCustomerLoyaltyPoints", err);
    });
}

export function redeemMLoyalCustomerLoyaltyPoints(
  registrationID,
  storeID,
  storeCode,
  customerID,
  customerMobileNo,
  customerPoints,
  passCode,
  refBillNo
) {
  return axios
    .post(
      "https://us-central1-posible-app-prod.cloudfunctions.net/apiRedeemMLoyalCustomerLoyaltyPoints",
      {
        RegistrationID: registrationID,
        StoreID: storeID,
        StoreCode: storeCode,
        CustomerID: customerID,
        CustomerMobileNo: customerMobileNo,
        CustomerPoints: customerPoints,
        PassCode: passCode,
        RefBillNo: refBillNo,
      }
    )
    .then((res) => {
      // console.log('getBrandList', res)
      console.log("res", res);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
      // console.log('Could not get businessname for provided domain name. Try again later.');
    });
}
