import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App'
import "./index.css";

const startApp = () => {
    const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
    ReactDOM.render(
        <Provider store={store}><BrowserRouter><App/></BrowserRouter></Provider>,
        document.getElementById('root')
    );
}

if(window.cordova) {
  document.addEventListener('deviceready', startApp, false)
} 
else {
  startApp()
}