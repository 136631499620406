import firebase from '../firebase'
export function initiateThirdPartyPayment(transaction) {   
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('initiateThirdPartyPayment');
        return addMessage({ text: transaction }).then(function (result) {
            // let sanitizedMessage = result.data.text;
            // console.log('initiateThirdPartyPayment', result)
            return result
        });
    }
}

export function checkThirdPartyPaymentStatus(transactionID) {   
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('checkThirdPartyPaymentStatus');
        return addMessage({ text: {TransactionID:transactionID} }).then(function (result) {
            // let sanitizedMessage = result.data.text;
            // console.log('initiateThirdPartyPayment', result)
            return result
        });
    }
}

