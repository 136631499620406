import React, { Component } from 'react'
import './Spinner.css'

class Spinner extends Component {

    render() {
    // You can use them as regular CSS styles.
        return <div className={this.props.hideOverlay ? "spinner-container" : "spinner-container spinner-container-overlay"}>
                <div className="spinner-sub-container">
                    <div className="spinner"></div>
                    <div className="sinnertext">{this.props.text ?  this.props.text : "Loading data. Please wait..."}</div>
                </div>
            </div>
        }
    }

export default Spinner