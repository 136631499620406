import React, { Component } from "react";
import Window from '../2/Controls/Window';
import Grid from '../2/Controls/Grid';
import Spinner from '../Common/Spinner';

class AllStoreInStockQty extends React.Component {
    constructor() {
        super();
        this.state = {
            inStockData: [],
            loading:true
        };
    }

    async componentDidMount() {
        // console.log('salesID',this.props.salesID)
        let result = await this.props.getStores(this.props.user.user.RegistrationID, 0, 1000, "active", "","","","","","","","","","","")
        let stores = result.searchResult;
        // console.log('stores',stores);
        result = await this.props.getItemInStockData(stores, 0, 1000, "", "", "", "", this.props.product.SKU, "", "");
        let inStockData =result.searchResult;
        this.setState({inStockData:inStockData, loading:false})
    }

    render(){
        return <Window 
                header={"In Stock Qty"}  
                modal={false}
                onClose={this.props.onClose}
                buttons={[]}
                >
                    {!this.state.loading && <Grid 
                        columns={[
                            {Name:'Store',  Type:'field',Columns:[{Column:'StoreName'}]},
                            {Name:'Qty',  Type:'field',Columns:[{Column:'InStockQty', Type:'number'}]}
                        ]}
                        data={this.state.inStockData}
                        buttons={[]}
                    />}  
                    {this.state.loading && <div>Loadging...</div>}
                </Window>
    }

   }

export default AllStoreInStockQty;
