import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'

class ExpenseListReport extends Component {

    constructor() {
        super()
        this.state = {
            expense: null,
            showModal: false,
            showCancellationModal: false,
            Expenses: [],
            searchPendingPaidCancelRecords: '',
            showFilters: false,
            searchParty: '',
            searchExpenseDateFrom: new Date().toISOString().split('T')[0],
            searchExpenseDateTo: new Date().toISOString().split('T')[0],
            searchExpenseCategory: '',
            searchExpenseNumber: '',
            searchReferenceNumber: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading:false
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onSearchOptionClick = this.onSearchOptionClick.bind(this)
        this.loadExpenses = this.loadExpenses.bind(this)
        this.onPostExpenseSave = this.onPostExpenseSave.bind(this)
        this.showHideFilters = this.showHideFilters.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress=this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)

    }

    componentDidMount() {
        
        this.loadExpenses(this.state.recordFrom,
            this.state.searchPendingPaidCancelRecords,
            this.state.searchParty,
            this.state.searchExpenseDateFrom,
            this.state.searchExpenseDateTo,
            this.state.searchExpenseCategory,
            this.state.searchExpenseNumber,
            this.state.searchReferenceNumber)
    }

    loadExpenses(from, option, searchParty, searchExpenseDateFrom, searchExpenseDateTo
        , searchExpenseCategory, searchExpenseNumber, searchReferenceNumber) {
            this.setState({ loading: true })
        this.props.getExpenseList(this.props.user.user.RegistrationID, this.props.user.store.key, from
            , this.state.itemsCountPerPage, option,
            searchParty, searchExpenseDateFrom, searchExpenseDateTo, searchExpenseCategory,
            searchExpenseNumber, searchReferenceNumber).then((result) => {
                // console.log(result.searchResult);
                this.setState({ Expenses: result.searchResult, totalItemsCount: result.totalItemsCount ,loading:false})
                this.showPaginationStatus()
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onPostExpenseSave() {
        // this.setState({
        //     recordFrom: 0, searchPendingPaidCancelRecords: 'pending', searchParty: ''
        //     , searchExpenseDateFrom: new Date().toISOString().split('T')[0], searchExpenseDateTo: new Date().toISOString().split('T')[0]
        //     , searchExpenseCategory: '', searchExpenseNumber: '', searchReferenceNumber: '',
        //     showFilters: false, showModal: false, expense
        // })
        this.setState({showModal: false, expense:null})
        this.loadExpenses(0, this.state.searchPendingPaidCancelRecords, "", this.state.searchExpenseDateFrom, this.state.searchExpenseDateTo
            , "", "", "")
        }

    showHideFilters() {
        let showFilters = this.state.showFilters
        this.setState({ showFilters: !showFilters })
    }

    onExportClick() {
        this.setState({ loading: false, exportCalled: true })
        this.props.getExpenseList(this.props.user.user.RegistrationID,
            this.props.user.store.key,
            0,10000,this.state.searchPendingPaidCancelRecords,
            this.state.searchParty, 
            this.state.searchExpenseDateFrom, 
            this.state.searchExpenseDateTo, 
            this.state.searchExpenseCategory,
            this.state.searchExpenseNumber, 
            this.state.searchReferenceNumber)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let expenses = []
                result.searchResult.map((data) => {

                    // console.log(JSON.stringify(data))
                    let expense = {
                        "ExpenseNo.": data.ExpenseNo,
                        "ExpenseDate": data.ExpenseDate,
                        "Party": data.Party.PartyName,
                        "Party Type": data.Party.PartyType,
                        "Expense Category": data.ExpenseCategory,
                        "Reference No.": data.RefNo,
                        "Notes": data.Note,
                        "Payment Mode": data.PaymentType,
                        "Amount": data.Amount,
                        "Status": data.Status,
                    }
                    expenses.push(expense);
                })
                const csvData = constatnts.objToCSV(expenses);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "ExpenseListReport.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchPendingPaidCancelRecords: 'pending', searchParty: ''
            , searchExpenseDateFrom: new Date().toISOString().split('T')[0], searchExpenseDateTo: new Date().toISOString().split('T')[0]
            , searchExpenseCategory: '', searchExpenseNumber: '', searchReferenceNumber: '',
            showFilters: false,
        })
        this.loadExpenses(0, "pending", "", new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]
            , "", "", "")
    }


    onSearchOptionClick(option) {
        this.setState({ searchPendingPaidCancelRecords: option, recordFrom: 0 })
        this.loadExpenses(
            0,
            option,
            this.state.searchParty,
            this.state.searchExpenseDateFrom,
            this.state.searchExpenseDateTo,
            this.state.searchExpenseCategory,
            this.state.searchExpenseNumber,
            this.state.searchReferenceNumber
        )
    }

    onSearchClick() {
        this.loadExpenses(
            0,
            this.state.searchPendingPaidCancelRecords,
            this.state.searchParty,
            this.state.searchExpenseDateFrom,
            this.state.searchExpenseDateTo,
            this.state.searchExpenseCategory,
            this.state.searchExpenseNumber,
            this.state.searchReferenceNumber
        )
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadExpenses(
            recordFrom,
            this.state.searchPendingPaidCancelRecords,
            this.state.searchParty,
            this.state.searchExpenseDateFrom,
            this.state.searchExpenseDateTo,
            this.state.searchExpenseCategory,
            this.state.searchExpenseNumber,
            this.state.searchReferenceNumber)
    }

    showPaginationStatus() {
        // console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount
        }
        this.setState({ paginationStatus: paginationStatus })
    }

    render() {
       
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border" style={{paddingTop:"10px",paddingBottom:"10px",paddingRight:"0px"}}>
                                <div className="col-md-6 col-xs-9"  style={{padding:"0px"}}>
                                    <font style={{ fontSize: "30px" }}>Expenses Report </font>
                                </div>
                                <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Search by Party Name</label>
                                            <input type="text" className="form-control input-md"
                                                autoFocus={true}
                                                // onKeyPress={this.handleKeyPress}
                                                value={this.state.searchParty}
                                                onChange={(evt) => this.setState({ searchParty: evt.target.value })}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Expense Date from</label>
                                            <input type="date" className="form-control input-md"
                                                // autoFocus={true}
                                                // onKeyPress={this.handleKeyPress}
                                                value={this.state.searchExpenseDateFrom}
                                                onChange={(evt) => this.setState({ searchExpenseDateFrom: evt.target.value })}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Expense Date To</label>
                                            <input type="date" className="form-control input-md"
                                                // autoFocus={true}
                                                // onKeyPress={this.handleKeyPress}
                                                value={this.state.searchExpenseDateTo}
                                                onChange={(evt) => this.setState({ searchExpenseDateTo: evt.target.value })}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                    <div className="form-group">
                                            <label>Status</label>
                                            <select className="form-control"
                                        value={this.state.searchPendingPaidCancelRecords}
                                        onChange={(evt) => this.setState({ searchPendingPaidCancelRecords: evt.target.value })}
                                        onKeyPress={this.handleKeyPress}
                                        >
                                        <option value="">All</option>
                                        <option value="pending">Pending</option>
                                        <option value="paid">Paid</option>
                                        <option value="cancelled">Cancelled</option>
                                    </select>
                                        </div> 
                                    </div>
                                </div>
                                 <div className="row">
                                    
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Expense Category</label>
                                                <input type="text" className="form-control input-md"
                                                    // onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchExpenseCategory}
                                                    onChange={(evt) => this.setState({ searchExpenseCategory: evt.target.value })}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Expense Number</label>
                                                <input type="text" className="form-control input-md"
                                                    // onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchExpenseNumber}
                                                    onChange={(evt) => this.setState({ searchExpenseNumber: evt.target.value })}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Reference Number</label>
                                                <input type="text" className="form-control input-md"
                                                    // onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchReferenceNumber}
                                                    onChange={(evt) => this.setState({ searchReferenceNumber: evt.target.value })}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            disabled={this.state.searchExpenseDateFrom === '' || this.state.searchExpenseDateTo===''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right" 
                                        style={{ marginTop: "25px", marginRight: "5px" }} 
                                        onClick={this.onExportClick} 
                                        > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                    
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Expense No</th>
                                            <th>Expense Date</th>
                                            <th>Party</th>
                                            <th>Party Type</th>
                                            <th>Expense Category</th>
                                            <th>Reference Number</th>
                                            <th>Notes</th>
                                            <th>Payment Mode</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Expenses && this.state.Expenses.map((Expense) =>
                                            <tr>
                                                <td>{Expense.ExpenseNo}</td>
                                                <td>{Expense.ExpenseDate}</td>
                                                <td>{Expense.Party.PartyName} </td>
                                                <td>{Expense.Party.PartyType}</td>
                                                <td>{Expense.ExpenseCategory}</td>
                                                <td>{Expense.RefNo}</td>
                                                <td>{Expense.Note}</td>
                                                <td>{Expense.PaymentType}</td>
                                                <td>{Expense.Amount}</td> 
                                                <td>{Expense.Status}</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                                </div>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(ExpenseListReport)