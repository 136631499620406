import React, { Component } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Loader from '../Loader'

class AttendanceReport extends Component {

    constructor() {
        super();
        this.state = {
            attendanceList: []
            , attendanceData: []
            , searchStore: ""
            , searchTransactionFromDate: new Date().toISOString().split('T')[0]
            , searchTransactionToDate: new Date().toISOString().split('T')[0]
            , loading: true
            , years:[]
            , months:[]
            , selectedYear:0
            , selectedMonth:0
            , storeList: []
            , stores: []
            , attendanceType : ''
        };
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.loadAttendanceReport = this.loadAttendanceReport.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getPivotArray = this.getPivotArray.bind(this);
        this.arrayToHTMLTable = this.arrayToHTMLTable.bind(this);
        this.onExportClick = this.onExportClick.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this)
    };

    componentDidMount() {

        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()

        let years= this.props.getYears()
        let months = this.props.getMonths(selectedYear)
        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth);
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth);

        let storeList = [];
        storeList.push({ key: this.props.user.store.key });

        this.setState({ storeID: this.props.user.store.key, storeList: storeList, years: years, months: months, selectedYear: selectedYear, selectedMonth: selectedMonth, searchTransactionFromDate: searchTransactionFromDate, searchTransactionToDate: searchTransactionToDate })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });

        this.setState({ storeID: this.props.user.store.key, storeList: storeList, years: years, months: months, selectedYear: selectedYear, selectedMonth: selectedMonth, searchTransactionFromDate: searchTransactionFromDate, searchTransactionToDate: searchTransactionToDate })

        this.loadAttendanceReport(storeList, searchTransactionFromDate, searchTransactionToDate, this.state.attendanceType)
    };

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    getPivotArray(dataArray, rowIndex, colIndex, dataIndex) {
        var result = {}, ret = [];
        var newCols = [];
        for (var i = 0; i < dataArray.length; i++) {
            if (!result[dataArray[i][rowIndex]]) {
                result[dataArray[i][rowIndex]] = {};
            }
            result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];

            //To get column names
            if (newCols.indexOf(dataArray[i][colIndex]) == -1) {
                newCols.push(dataArray[i][colIndex]);
            }
        }

        newCols.sort();
        var item = [];

        //Add Header Row
        item.push('Date');
        item.push.apply(item, newCols);
        ret.push(item);

        //Add content 
        for (var key in result) {
            item = [];
            item.push(key);
            for (var i = 0; i < newCols.length; i++) {
                item.push(result[key][newCols[i]] || "-");
            }
            ret.push(item);
        }
        return ret;
    }

    loadAttendanceReport(storeList, searchTransactionFromDate, searchTransactionToDate, searchStatus) {
        this.props.getAttendanceReport(this.props.user.user.RegistrationID, storeList
            , searchTransactionFromDate, searchTransactionToDate, searchStatus)
            .then((result) => {
                // console.log(result.searchResult);
                let attendanceData = ''
                if(result.searchResult.length > 0)
                {
                    attendanceData = this.getPivotArray(result.searchResult, 0, 1, 2);
                }
                this.setState({ attendanceData: attendanceData , loading: false })
            });
    };

    arrayToHTMLTable(myArray) {
        var result = "<table class='table table-bordered'>";
        for (var i = 0; i < myArray.length; i++) {
            if (i == 0)
                result += "<thead><tr>";
            else
                result += "<tbody><tr>";
            for (var j = 0; j < myArray[i].length; j++) {
                if (i == 0)
                    result += "<th>" + myArray[i][j] + "</th>";
                else
                    result += "<td>" + myArray[i][j] + "</td>";
            }
            if (i == 0)
                result += "</tr></thead>";
            else
                result += "</tr></tbody>";
        }
        result += "</table>";
        return result;
    }

    onSearchClick() {
        this.setState({
            loading: true
        })
        this.loadAttendanceReport(this.state.storeList, this.state.searchTransactionFromDate, this.state.searchTransactionToDate, this.state.attendanceType);
    };

    async onExportClick() {
        this.props.getAttendanceReport(this.props.user.user.RegistrationID, this.state.storeList
            , this.state.searchTransactionFromDate, this.state.searchTransactionToDate, this.state.attendanceType)
            .then(async (result) => {
                let attendanceData = await this.getPivotArray(result.searchResult, 0, 1, 2);
                const csv = attendanceData.map(row => row.map(item => (typeof item === 'string' && item.indexOf(',') >= 0) ? `"${item}"`: String(item)).join(',')).join('\n');
                // Format the CSV string
                const data = encodeURI('data:text/csv;charset=utf-8,' + csv);
                const link = document.createElement('a');
                link.setAttribute('href', data);
                link.setAttribute('download', 'AttendanceReport.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            });
    }

    onClearSearch() {
      
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()

        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth) 


        this.setState({
            selectedYear: selectedYear,
            selectedMonth: selectedMonth,
            searchTransactionToDate: searchTransactionToDate,
            searchTransactionFromDate: searchTransactionFromDate,
            loading: true,
            storeID: this.props.user.store.key,
            storeList: storeList,
            attendanceType: ''
        })
        this.loadAttendanceReport(storeList, searchTransactionFromDate, searchTransactionToDate, '')
    };

    onYearChange(evt)
    {
        
        let selectedYear = evt.target.value
        // console.log('onYearChange', selectedYear)
        let months = this.props.getMonths(selectedYear)
        let selectedMonth = months[months.length-1].Month
        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth)  
        this.setState({
            months:months,
            selectedYear:selectedYear,
            selectedMonth:selectedMonth,
            searchTransactionFromDate:searchTransactionFromDate,  
            searchTransactionToDate:searchTransactionToDate
        })
    }

    onMonthChange(evt)
    {
        let selectedMonth = evt.target.value
        let searchTransactionFromDate = this.getMonthStartDate(this.state.selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(this.state.selectedYear, selectedMonth)  
        this.setState({
            selectedMonth:selectedMonth,
            searchTransactionFromDate:searchTransactionFromDate,  
            searchTransactionToDate:searchTransactionToDate
        })
    }

    getMonthStartDate(selectedYear, selectedMonth)
    {
        return this.props.getStartDateOfMonth(selectedYear, selectedMonth)
    }

    getMonthEndDate(selectedYear, selectedMonth)
    {
        return this.props.getEndDateOfMonth(selectedYear, selectedMonth)
    }

    onStoreChange(e) {
        let storeList = []
        // console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        // console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px", marginBottom: "4px" }}>Attendance Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"                                            
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                                <div className="row" style={{padding:"10px"}}>
                                    <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Status</label>
                                        <select className="form-control"
                                            onChange={(evt) => this.setState({ attendanceType: evt.target.value })}
                                            value={this.state.attendanceType}>
                                            <option>Select</option>
                                            <option>Present</option>
                                            <option>Half Day</option>
                                            <option>Leave</option>
                                            <option>Week-Off</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Year</label>
                                            <select type="text" className="form-control"
                                                value={this.state.selectedYear}
                                                onChange={(evt)=>this.onYearChange(evt)}>
                                                {this.state.years.map((e) => <option value={e}>{e}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Month</label>
                                            <select type="text" className="form-control"
                                                value={this.state.selectedMonth}
                                                onChange={this.onMonthChange}>
                                                {this.state.months.map((e) => <option value={e.Month}>{e.MonthName}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{  marginLeft: "5px" }}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right"
                                            onClick={this.onClearSearch}
                                        >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{  marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        >Export <i className="fa fa-download" ></i></button>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                        {this.state.loading && <Loader show={this.state.loading} />}
                                        {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                                {this.state.attendanceData !== '' ? ReactHtmlParser(this.arrayToHTMLTable(this.state.attendanceData)) : 'No Records found'} 
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default AttendanceReport;