import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'
import { Link } from 'react-router-dom';

class OfferSummaryReport extends Component {
    constructor() {
        super()
        this.state = {
            invoices: [],
            stores: [],
            storeList: [],
            activePage: 1,
            itemsCountPerPage: 10000,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            exportCalled: false,
            loading: true,
            productCustomField1DisplayName:'Custom Field 1',
            productCustomField2DisplayName:'Custom Field 2',
            productCustomField3DisplayName:'Custom Field 3',
            productCustomField4DisplayName:'Custom Field 4',
            productCustomField5DisplayName:'Custom Field 5',
            productCustomField6DisplayName:'Custom Field 6',
            gstinCustomFieldNo:0,
            years:[],
            months:[],
            selectedYear:0,
            selectedMonth:0,
            searchOffer: ''
        }

        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    componentDidMount() {

        let storeList = []
        storeList.push({ key: this.props.user.store.key })
        this.setState({storeID: this.props.user.store.key, storeList: storeList})
    
        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {                
            this.setState({ stores: stores })
        });
    
       
        this.loadOfferReport(storeList, this.state.searchOffer);
    }


    loadOfferReport(storeList, searchOffer) {
        this.setState({ loading: true })
        this.props.getOfferSummaryReport(this.props.user.user.RegistrationID, storeList, this.props.type, searchOffer)
            .then((result) => {
                this.setState({ offers: result.searchResult, loading: false })
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});
        this.setState({
          
            storeList : storeList,
            storeID : this.props.user.store.key, 
           searchOffer: ''
        })
        this.loadOfferReport(storeList, "");
    }

    async onExportClick() {
        this.setState({ exportCalled: true })
        let result = await this.props.getOfferSummaryReport(this.props.user.user.RegistrationID, this.state.storeList, this.props.type, this.state.searchOffer)
            // .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let offers = []
                result.searchResult.map((data) => {
                    let offer = {
                        "Name": data.name,
                        "Total Transactions":data.total_transactions,
                        "Total Customers":data.total_customers,
                        "Total Qty": data.total_qty,
                        "Total Sales": data.total_sales,
                        "Total Discount": data.total_discount,
                    }
                    offers.push(offer);
                })
                const csvData = constatnts.objToCSV(offers);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                let fileName = (this.props.type === "offer" ? "Offer Report.csv" : "Coupon Report.csv") 
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", fileName );
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            // })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true })
        this.loadOfferReport(
            this.state.storeList,
            this.state.searchOffer
        )
    }

    onStoreChange(e){
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value !== "") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>{this.props.type === "offer" ? "Offer Report" : "Coupon Report" }</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>{this.props.type === "offer" ? "Offer Name" : "Coupon Name" }</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchOffer: evt.target.value })}
                                                value={this.state.searchOffer}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download " ></i></button>
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>{this.props.type === "offer" ? "Offer Name" : "Coupon Name"}</th>
                                                <th>Total Transactions</th>
                                                <th>Total Customers</th>
                                                <th>Total Qty</th>
                                                <th>Total Discount</th>
                                                <th>Total Sales</th>
                                                <th className="hidden-xs" style={{ width: "20px" }} ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.offers.map((offer, index) =>
                                                <tr>
                                                    <td>{offer["name"]}</td>
                                                    <td>{offer["total_transactions"]}</td>
                                                    <td>{offer["total_customers"]}</td>
                                                    <td>{offer["total_qty"]}</td>
                                                    <td>{offer["total_discount"]}</td>
                                                    <td>{offer["total_sales"]}</td>
                                                    <td>
                                                        <div>
                                                        <Link to={{ pathname:  (this.props.type === "offer" ? "/home/reports/offer" : "/home/reports/coupon"), state: { offerName: offer.name } }}
                                                        type="button" className="btn btn-md btn-default btn-flat">
                                                        Show Details</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(OfferSummaryReport)