import React, { Component } from 'react'
import HeaderNew from '../HeaderNew';
import PopupMenu from '../Common/PopupMenu';
import ActionButton from '../Common/ActionButton'
import Confirmation from '../Common/Confirmation';

class LocationList extends Component {

    constructor(){
        super()
        this.state = {
            locaitons:[],
            searchText:'',
            store:null,
            selectedLocation:null,
            showPopupMenu:false,
            showDelete:false
        }
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onRowClick= this.onRowClick.bind(this)
        this.onHeaderBackClick =this.onHeaderBackClick.bind(this)
        this.onActionButtonClick = this.onActionButtonClick.bind(this)
        this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this)
        this.loadLocations = this.loadLocations.bind(this)
    }

    async componentDidMount() {

        this.setState({store:this.props.location.state.store})
        this.loadLocations()
    }

    async loadLocations(){
        let locaitons = await this.props.getStoreLocations(this.props.location.state.store.key)
        this.setState({locaitons:locaitons.filter(f=> !f.IsDeleted)})
    }

    onHeaderBackClick(){
        this.props.history.push({ pathname: '/home/settings/stores'})    
    }

    async onActionButtonClick(action) {
        this.props.history.push({ pathname: '/home/settings/stores/locations/edit', state: { module: 'location', store:this.state.store, storeLocation: null } })
    }

    async onRowClick(selectedLocation)
    {
        this.setState({selectedLocation:selectedLocation, showPopupMenu:true})
        // this.props.history.push({ pathname: '/home/settings/stores/locations/edit', state:{location:location} })
    }

    async onShowPopupMenuClick(value) {

        let location= this.state.selectedLocation;

        if (value === "edit") {
            this.setState({showPopupMenu:false, selectedStore:null})
            this.props.history.push({ pathname: "/home/settings/stores/locations/edit", state: { module:'location',store: this.state.store, storeLocation: location }})
        }
        else if (value === "delete") {
            this.setState({showDelete:true, showPopupMenu:false});
        }
        else
        {
            this.setState({showPopupMenu:false, selectedStore:null})
        }
    }


    onDeleteConfirmation(value)
    {   
        if(value)
        {
            let object= this.state.selectedLocation;
            
            this.props.deleteStoreLocations(object.key).then(() => {
                    this.props.getToastr("Location deleted successfully");
                    this.setState({ loading: true, showDelete:false })
                    this.loadLocations()
            })
        }
        else
        {
            this.setState({showDelete:false});
        }
       
    }

    render(){
        return <React.Fragment>

        <HeaderNew {...this.props}
        back={true}
        title="Location List"
        filter={false}
        onBackClick={() => this.onHeaderBackClick()}
        />
    
        <div className="header-new-background"/>

        <div className="content-wrapper">

            <div className="container">

                {/* <ListSearch 
                    value = {this.state.searchText}
                    onChange={(searchText) => {this.setState({ searchText: searchText })}}
                    searchPlaceHolder="Search location"/> */}

                    <br/>
                
                <div className="form-group">
                    <label>Store Code</label>
                    <input type="text" className="form-control"
                            value={this.state.store ? this.state.store.StoreCode : ''}
                        />
                </div>

                <div className="form-group">
                    <label>Store Name</label>
                    <input type="text" className="form-control"
                            value={this.state.store ? this.state.store.StoreName: ''}
                        />
                </div>


                <div>

                {/* {this.state.locaitons.filter(f=> {
                        let searchText = this.state.searchText;
                        if(searchText.length === 0)
                        {return true}
                        else
                        {
                            if(f.LocationName.toLowerCase().includes(searchText.toLowerCase())
                            || f.LocationCode.toLowerCase().includes(searchText.toLowerCase()))
                            {return true } 
                        }
                        return false
                    }). */}
                    {this.state.locaitons.map((location) => <div className="list-row seperator" 
                    onClick={()=> {this.onRowClick(location);}}>
                    <div>   
                        <div className="row-title">{location.LocationCode + ' - ' + location.LocationName}</div>
                    </div>
                </div>)}

                </div>

            </div>

        </div>

         <ActionButton
                    onClick={(action) => this.onActionButtonClick(action)}
                    fabButtons={[]} />

        <PopupMenu 
            show={this.state.showPopupMenu} 
            buttons ={ [{value: "delete", text: "Delete", module: 'Location'}, 
            {value: "edit", text: "Edit", module: 'Location'}]}
            onClick={(action)=> this.onShowPopupMenuClick(action)}/>

        <Confirmation show={this.state.showDelete} onClick={(value)=>this.onDeleteConfirmation(value)}/>

        </React.Fragment>
    }
}

export default LocationList

