import React, { Component } from "react";
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Input from "../2/Controls/Input";

class OmniMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index:-1,
            menuName:"",
            menuNameError:"",
            menuTypes:[
                {value:"home",text:"Home"},
                {value:"categorylink",text:"Link to category"},
                {value:"subcategorylink",text:"Link to sub category"},
                {value:"productgrouplink",text:"Link to product group"},
                {value:"brandlink",text:"Link to brand"},
                {value:"productlink",text:"Link to product"},
                {value:"pagelink",text:"Link to page"},
                {value:"contactuslink",text:"Link to contact us"},
                // {value:"storelocatorlink",text:"Link to store location"},
                // {value:"cartlink",text:"Link to cart"},
                // {value:"loginlink",text:"Link to login"},
                // {value:"signuplink",text:"Link to signup"},
            ],
            menuSequenceNo:1,
            menuType:"",
            menuTypeError:"",
            menuCategoryID:"",
            menuSubCategoryID:"",
            menuProductID:"",
            menuProduct:"",
            menuPageID:"",
            menuPageError:"",
            pages:[],
            isProductTypeAheadLoading:false,
            productsTypeAheadOptions:[],
            menuSequences:[],
            menuProductGroupID:"",
            menuBrandID:"",
            menuTag:"",
            menuProductError:"",
            menuBrandError:""
        };
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
        this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
        this.onProductSelected = this.onProductSelected.bind(this);
        this.onSave= this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    async componentDidMount() {
        let pages = await this.props.getOmniPages(this.props.user.user.RegistrationID);
        const key = this.props.match.params.key;
        // console.log('key',key)
        // console.log('pages',pages);
        let checkoutConfig = await this.props.getOmniSettings();
        let menus  = checkoutConfig ? checkoutConfig.hasOwnProperty("Menus") ? checkoutConfig.Menus : [] : [];
        let sequenceLength = menus.length + 1;
        let menuSequenceNo = menus.length + 1;
        let menuName
        let menuType="";
        let menuCategoryID="";
        let menuSubCategoryID="";
        let menuProductID="";
        let menuPageID="";
        let index=-1;
        let menuProductGroupID = "";
        let menuBrandID = "";
        let menuTag ="";

        if(key && Number(key) >= 0 && menus.length >= Number(key))
        {
            //existing
            let menu = checkoutConfig.Menus[Number(key)]; 
            menuName= menu.Name;
            menuCategoryID = menu.CategoryID;
            menuSequenceNo = Number(key)+1;
            menuType = menu.Type;
            menuSubCategoryID = menu.SubCategoryID;
            menuProductID = menu.ProductID;
            menuPageID = menu.PageID;
            menuProductGroupID = menu.hasOwnProperty("ProductGroupID") ? menu.ProductGroupID: "";
            menuBrandID = menu.hasOwnProperty("BrandID") ? menu.BrandID: "";
            menuTag = menu.hasOwnProperty("Tag") ?  menu.Tag: "";
            index = Number(key);
            sequenceLength = menus.length;
        }
        // console.log('index',index);
        // console.log('checkoutConfig', checkoutConfig);
        // console.log('menus',menus);
        // console.log('sequenceLength',sequenceLength);
        let menuSequences = [];
        for(let i=0;i<sequenceLength;i++){
            menuSequences.push({value:i+1})
        }
        this.setState({
            index:index,
            // key:key,
            pages: pages,
            loading: false,
            menus:menus,
            checkoutConfig:checkoutConfig,
            sequenceLength:sequenceLength,
            menuSequenceNo:menuSequenceNo,
            menuSequences:menuSequences,
            menuName:menuName,
            menuCategoryID:menuCategoryID,
            menuSequenceNo:menuSequenceNo,
            menuType:menuType,
            menuSubCategoryID:menuSubCategoryID,
            menuProductID:menuProductID,
            menuPageID: menuPageID,
            menuProductGroupID:menuProductGroupID,
            menuBrandID:menuBrandID,
            menuTag:menuTag
        });
    }

    async onProductTypeAheadSearch(query){
        this.setState({ isProductTypeAheadLoading: true });
        let searchedProducts = [];
        searchedProducts = await this.props.config.Products.filter(p => p.SKU.toLowerCase().includes(query.toLowerCase())
            || p.Name.toLowerCase().includes(query.toLowerCase()) || p.BarCode.toLowerCase().includes(query.toLowerCase()));
        this.setState({ productsTypeAheadOptions: searchedProducts, isProductTypeAheadLoading: false })
    }

    onProductBarCodeSearch(queryText) {

        var productList = this.props.config.Products.filter((e) => e.SKU.toLowerCase() === queryText.toLowerCase() || e.BarCode.toLowerCase() === queryText.toLowerCase())
        if (productList.length === 0) {
            alert('Product not found')
            return
        }
        this.onProductSelected(productList[0])
    }

    onProductSelected(product) {
        if(product.OMNIEnabled && product.hasOwnProperty("OMNITag")){
            this.setState({ 
                menuProductID: product.key, 
                menuProduct: product.Name, 
                productOMNITag: product.OMNITag
            })
        }
        else
        {
            alert('Product is not enabled for online store')
        }
    }

    async onSave(){
        let menuTypeError = "";
        let menuCategoryError="";
        let menuSubCategoryError="";
        let menuPageError="";
        let menuNameError = "";
        let menuTag ="";
        let menuProductGroupError ="";
        let menuProductError = "";
        let menuBrandError = "";

        if(this.state.menuName.trim()===""){
            menuNameError ="Please provide menu name";
        }
        
        if(this.state.menuType===""){
            menuTypeError="Please select menu type";
        }

        if(this.state.menuType==="categorylink" && this.state.menuCategoryID ===""){
            menuCategoryError="Please select category";
        }
        
        if(this.state.menuType==="subcategorylink" && this.state.menuCategoryID===""){
            menuCategoryError="Please select category";
        }
        
        if(this.state.menuType==="subcategorylink" && this.state.menuSubCategoryID===""){
            menuSubCategoryError="Please select sub category";
        }
        
        if(this.state.menuType==="productgrouplink" && this.state.menuProductGroupID ===""){
            menuProductGroupError ="Please select product group"
        }
        
        if(this.state.menuType==="brandlink" && this.state.menuBrandID ===""){
            menuBrandError ="Please select brand"
        }
        
        if(this.state.menuType ==="productlink" && this.state.menuProductID ===""){
            menuProductError="Please select product"
        }
        
        if(this.state.menuType==="pagelink" && this.state.menuPageID===""){
            menuPageError="Please select page";
        }
       
        if(menuNameError !=="" || 
            menuTypeError!=="" || 
            menuCategoryError!=="" || 
            menuSubCategoryError!=="" || 
            menuPageError!=="" ||
            menuProductGroupError !=="" || 
            menuProductError!=="" ||
            menuBrandError!==""){
            this.setState({
                menuNameError:menuNameError,
                menuTypeError:menuTypeError,
                menuCategoryError:menuCategoryError,
                menuSubCategoryError:menuSubCategoryError,
                menuPageError:menuPageError       ,
                menuProductGroupError:menuProductGroupError,
                menuProductError:menuProductError    ,
                menuBrandError:menuBrandError   
            });
            return;
        }

        if(this.state.menuType==="categorylink"){
            //get tag
            if(this.props.config.ProductCategories.filter((e) => this.state.menuCategoryID === e.key).length> 0){
                console.log('this.props.config.ProductCategories.filter((e) => this.state.menuCategoryID === e.key)[0]',this.props.config.ProductCategories.filter((e) => this.state.menuCategoryID === e.key)[0])
                menuTag = this.props.config.ProductCategories.filter((e) => this.state.menuCategoryID === e.key)[0].OMNITag
            }
            if(menuTag===""){
                alert('Unbale to add selected category. Please edit and save category again');
                return;
            }
        }
        else if(this.state.menuType==="subcategorylink" ){
            if(this.props.config.ProductSubCategories.filter((e) => this.state.menuSubCategoryID === e.key).length> 0){
                menuTag = this.props.config.ProductSubCategories.filter((e) => this.state.menuSubCategoryID === e.key)[0].OMNITag
            }
            if(menuTag===""){
                alert('Unbale to add selected sub category. Please edit and save sub category again');
                return;
            }
        }
        else if(this.state.menuType==="productgrouplink" ){
            if(this.props.config.ProductTags.filter((e) => this.state.menuProductGroupID === e.key).length> 0){
                menuTag = this.props.config.ProductTags.filter((e) => this.state.menuProductGroupID === e.key)[0].OMNITag
            }
            if(menuTag===""){
                alert('Unbale to add selected product group. Please edit and save product group again');
                return;
            }
        }
        else if(this.state.menuType==="pagelink" ){
            if(this.state.pages.filter(f=> f.key === this.state.menuPageID).length> 0){
                menuTag=this.state.pages.filter(f=> f.key === this.state.menuPageID)[0].Title;
            }
            if(menuTag===""){
                alert('Unbale to add selected page. Please edit and save page again');
                return;
            }
        }
        else if(this.state.menuType==="productlink" ){
        
            menuTag = this.state.productOMNITag;
            if(menuTag===""){
                alert('Unbale to add selected product. Please edit and save product again');
                return;
            }
        }
        else if(this.state.menuType==="brandlink" ){
            if(this.props.config.ProductBrands.filter((e) => this.state.menuBrandID === e.key).length> 0){
                menuTag = this.props.config.ProductBrands.filter((e) => this.state.menuBrandID === e.key)[0].hasOwnProperty("OMNITag") ? this.props.config.ProductBrands.filter((e) => this.state.menuBrandID === e.key)[0].OMNITag : ""
            }
            if(menuTag===""){
                alert('Unbale to add selected product group. Please edit and save product group again');
                return;
            }
        }
        let menu={
            Name:this.state.menuName,
            Type: this.state.menuType,
            CategoryID: this.state.menuCategoryID,
            SubCategoryID: this.state.menuSubCategoryID,
            PageID: this.state.menuPageID,
            ProductID: this.state.menuProductID,
            ProductGroupID: this.state.menuProductGroupID,
            BrandID: this.state.menuBrandID,
            Tag: menuTag
        }
        console.log('menu',menu)
        let checkoutConfig = this.state.checkoutConfig;
        if(!checkoutConfig.hasOwnProperty('Menus')){
            checkoutConfig.Menus=[]
            checkoutConfig.Menus.push(menu);
        }
        else
        {
            if(this.state.index > -1){
                checkoutConfig.Menus.splice(this.state.index, 1)
            }
            checkoutConfig.Menus.splice(this.state.menuSequenceNo -1, 0 , menu)

        }
        console.log('checkoutConfig',checkoutConfig);
        await this.props.saveOmniSettings(checkoutConfig);
        this.props.getToastr("Menu created successfully");
        this.onCancel();
    }

    onCancel(){
        this.props.history.push({ pathname: '/home/omni/menus' });
    }

    render(){
        return <Page 
        title={(this.props.match.params.key) ? "Edit Menu" : "New Menu"}
        showSaveButton={true} 
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action)=>{
            if(action==="cancel"){
                this.onCancel();
            }else if(action==="save"){
                this.onSave();
            }
        }}
        > 
         <PageSection title="General Details">
            <div className="row">  
                    <div className="col-md-3">
                        <Input
                            type="text"
                            label="Name"
                            onChange={(value) => {this.setState({ menuName: value, menuNameError: "" })}}
                            value={this.state.menuName}
                            error={this.state.menuNameError}
                        />
                    </div>
                    <div className="col-md-2">
                        <Input
                            type="select"
                            label="Sequence"
                            onChange={(value)=>{this.setState({menuSequenceNo:value, menuTypeError:""})}}
                            value={this.state.menuSequenceNo}
                            error={this.state.menuSequenceError}    
                        >
                            {this.state.menuSequences.map(m=><option value={m.value}>{m.value}</option>)}
                        </Input>
                    </div>
                     <div className="col-md-3">
                        <Input
                            type="select"
                            label="Type"
                            onChange={(value)=>{this.setState({menuType:value, menuTypeError:""})}}
                            value={this.state.menuType}
                            error={this.state.menuTypeError}    
                        >
                            <option value={""}>Select menu type</option>
                            {this.state.menuTypes.map(m=><option value={m.value}>{m.text}</option>)}
                        </Input>
                    </div>
                    {(this.state.menuType === "categorylink"  || this.state.menuType==="subcategorylink") && <div className="col-md-3">
                        <Input
                            type="select"
                            label="Category"
                            onChange={(value)=>{this.setState({menuCategoryID:value, menuCategoryError:""})}}
                            value={this.state.menuCategoryID}
                            error={this.state.menuCategoryError}    
                        >
                            <option value={""}>Select category</option>
                            {this.props.config.ProductCategories.filter((e) => e.ParentID === "" && !e.IsDeleted && e.hasOwnProperty("OMNITag") && e.OMNIEnabled).map((category) => <option value={category.key}>{category.Name}</option>)}
                        </Input>
                    </div>}
                    {(this.state.menuType === "subcategorylink") && <div className="col-md-3">
                        <Input
                            type="select"
                            label="Sub Category"
                            onChange={(value)=>{this.setState({menuSubCategoryID:value, menuSubCategoryError:""})}}
                            value={this.state.menuSubCategoryID}
                            error={this.state.menuSubCategoryError}    
                        >
                            <option value={""}>Select sub category</option>
                            {this.props.config.ProductSubCategories.filter((e) => e.ParentID === this.state.menuCategoryID && !e.IsDeleted && e.hasOwnProperty("OMNITag") && e.OMNIEnabled).map((category) => (
                            <option value={category.key}>{category.Name}</option>
                            ))}
                        </Input>
                    </div>}
                    {(this.state.menuType === "productgrouplink") && <div className="col-md-3">
                        <Input
                            type="select"
                            label="Product Group"
                            onChange={(value)=>{this.setState({menuProductGroupID:value, menuProductGroupError:""})}}
                            value={this.state.menuProductGroupID}
                            error={this.state.menuProductGroupError}    
                        >
                            <option value={""}>Select product group</option>
                            {this.props.config.ProductTags.filter((e) => !e.IsDeleted && e.hasOwnProperty("OMNITag")).map((productGroup) => (
                            <option value={productGroup.key}>{productGroup.Name}</option>
                            ))}
                        </Input>
                    </div>}
                    {(this.state.menuType === "productlink") && <div className="col-md-3">
                    <Input 
                            {...this.props}
                            type="productsearch"
                            showImportButton={false}
                            processing={this.state.isProductTypeAheadLoading}
                            onSearch={(searchText)=>{
                                this.onProductTypeAheadSearch(searchText)
                            }}
                            onScan={(searchText)=>this.onProductBarCodeSearch(searchText)}
                            options={this.state.productsTypeAheadOptions}
                            onChange={(value) => {
                                this.onProductSelected(value)
                            }}
                            onClick={(action)=>{}}
                            error={this.state.menuProductError}
                        />
                    </div>}
                    {(this.state.menuType === "pagelink") && <div className="col-md-3">
                        <Input
                            type="select"
                            label="Pages"
                            onChange={(value)=>{this.setState({menuPageID:value, menuPageIDError:""})}}
                            value={this.state.menuPageID}
                            error={this.state.menuPageIDError}    
                        >
                            <option value={""}>Select page</option>
                            {this.state.pages.filter((e) => !e.IsDeleted).map((page) => (
                            <option value={page.key}>{page.Title}</option>
                            ))}
                        </Input>
                    </div>}
                    {(this.state.menuType === "brandlink") && <div className="col-md-3">
                        <Input
                            type="select"
                            label="Brand"
                            onChange={(value)=>{this.setState({menuBrandID:value, menuBrandError:""})}}
                            value={this.state.menuBrandID}
                            error={this.state.menuBrandError}    
                        >
                            <option value={""}>Select brand</option>
                            {this.props.config.ProductBrands.filter((e) => !e.IsDeleted && e.hasOwnProperty("OMNITag")).map((brand) => (
                            <option value={brand.key}>{brand.Brand}</option>
                            ))}
                        </Input>
                    </div>}
            </div>
        </PageSection>
    </Page>}
}

export default OmniMenu;
