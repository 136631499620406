import React, { Component } from 'react'
import './style.css'

class PopupMenu extends Component {

    constructor () {
        super()
        this.onButtonClicked = this.onButtonClicked.bind(this)
    }

    onButtonClicked(action){
        this.props.onClick(action)
    }

    render() {
    // You can use them as regular CSS styles.
    return <React.Fragment>{this.props.show && <React.Fragment>
        <div className="popup-menu-overlay" onClick={()=>this.onButtonClicked("")}></div>
        <div className="popup-menu">
            <div className="popup-menu-options" >
                {this.props.buttons.map((m,index)=> <button key={"popup_button_"+ index} onClick={()=> this.onButtonClicked(m.value)} 
                className={m.value==="delete" ? "popup-menu-buttons delete": "popup-menu-buttons"}>{m.text}</button>)}
            </div>
            <div className="popup-menu-options" >
                <button 
                onClick={()=> this.onButtonClicked("")}
                className="popup-menu-buttons">Close</button>
            </div> 
        </div>
        </React.Fragment>}
    </React.Fragment>
    }
    }

export default PopupMenu