import React, { Component } from 'react';
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';

class AddAccount extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            name: '',
            specificStoreSelected: false,
            allStoresSelected: true,
            associatedStores: [],
            stores: [],
            nameError: '',
            storeSelectionError: '',
            storeNameError: '',
            saving: false
        }
        this.onSaveAccount = this.onSaveAccount.bind(this)
    }

    componentDidMount() {
        if (this.props.account) {
            this.setState({
                key: this.props.account.key,
                name: this.props.account.Name,
                specificStoreSelected: this.props.account.SpecificStore,
                allStoresSelected: this.props.account.AllStores,
                associatedStores: this.props.account.Stores,
            })
        }
    }

    onSaveAccount() {
        this.setState({ saving: true })
        let nameError = ""
        let storeSelectionError = ""
        let storeNameError = ""

        if (this.state.name.trim() === "") {
            nameError = "Please provide account name"
        }
        if (!this.state.allStoresSelected && !this.state.specificStoreSelected) {
            storeSelectionError = "Please select either All or Specific Store option."
        }
        if (this.state.specificStoreSelected) {
            if (this.state.associatedStores.length === 0) {
                storeNameError = "Please select store"
            }
        }
        if (nameError !== "" ||
            storeSelectionError !== "" ||
            storeNameError !== "") {
            this.setState({
                nameError: nameError,
                storeSelectionError: storeSelectionError,
                storeNameError: storeNameError,
                saving: false
            })
            return
        }

        this.props.checkIfAccountIsUnique(this.state.name, this.props.user.user.RegistrationID, this.state.associatedStores).then((isUnique) => {
            if (!isUnique) {
                nameError = 'Account already exists'
                this.setState({ nameError: nameError, saving: false })
                return
            }
            else {

                let account = {
                    RegistrationID: this.props.user.user.RegistrationID,
                    Name: this.state.name,
                    SpecificStore: this.state.specificStoreSelected,
                    AllStores: this.state.allStoresSelected,
                    Stores: this.state.associatedStores,
                    IsDeleted: false,
                }
                this.props.saveAccount(account, this.state.key).then(() => {
                    this.props.getToastr("Account saved successfully")
                    setTimeout(function () { //Start the timer
                        this.setState({ saving: false });
                        this.props.onSave();
                    }.bind(this), 1000)
                    //this.props.history.push('/home/expense/accountlist')
                });
            }
        });
    }

    onButtonClick(action){
        if(action==="save"){
            this.onSaveAccount();
        }else{
            this.props.onClose()
        }
    }

    render() {
        return <Form
            title= {this.state.key ? "Edit Account" : "New Account"}
            onClick={(action)=>this.onButtonClick(action)}
            processing={this.state.saving}
        >
            <Input 
                type="text" 
                label="Name"
                onChange={(value) => this.setState({ name: value, nameError: "" })}
                value={this.state.name}   
                error = {this.state.nameError}
            />
        </Form>
    }
}

export default AddAccount