import React, { Component } from "react";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import Input from "../2/Controls/Input";
import PageSection from "../2/Controls/PageSection";
import Page from "../2/Controls/Page";
import { isMobileApp } from "../../actions/Util";

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      businessType: "",
      contactNo: "",
      emailID: "",
      webSite: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      plan: "",
      showModal: false,
      showImport: false,
      showDownload: false,
      logo: null,
      shortDescription: "",
      longDescription: "",
      images: [],
    };
    this.onSaveGeneralDetails = this.onSaveGeneralDetails.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      businessName: this.props.registration.registration.BusinessName,
      contactNo: this.props.registration.registration.ContactNo,
      emailID: this.props.registration.registration.hasOwnProperty("EmailID")
        ? this.props.registration.registration.EmailID
        : "",
      webSite: this.props.registration.registration.hasOwnProperty("WebSite")
        ? this.props.registration.registration.WebSite
        : "",
      address1: this.props.registration.registration.hasOwnProperty("Address1")
        ? this.props.registration.registration.Address1
        : "",
      address2: this.props.registration.registration.hasOwnProperty("Address2")
        ? this.props.registration.registration.Address2
        : "",
      city: this.props.registration.registration.hasOwnProperty("City")
        ? this.props.registration.registration.City
        : "",
      state: this.props.registration.registration.hasOwnProperty("State")
        ? this.props.registration.registration.State
        : "",
      postalCode: this.props.registration.registration.hasOwnProperty(
        "PostalCode"
      )
        ? this.props.registration.registration.PostalCode
        : "",
      country: this.props.registration.registration.Country,
      logo: this.props.registration.registration.hasOwnProperty("Logo")
        ? this.props.registration.registration.Logo
        : null,
      shortDescription: this.props.registration.registration.hasOwnProperty(
        "ShortDescription"
      )
        ? this.props.registration.registration.ShortDescription
        : "",
      longDescription: this.props.registration.registration.hasOwnProperty(
        "LongDescription"
      )
        ? this.props.registration.registration.LongDescription
        : "",
      images: this.props.registration.registration.hasOwnProperty("Images")
        ? this.props.registration.registration.Images
        : "",
    });
  }

  onSaveGeneralDetails() {
    this.setState({ showModal: false });
    const generalDetails = {
      BusinessName: this.state.businessName,
      ContactNo: this.state.contactNo,
      EmailID: this.state.emailID,
      WebSite: this.state.webSite,
      Address1: this.state.address1,
      Address2: this.state.address2,
      City: this.state.city,
      State: this.state.state,
      PostalCode: this.state.postalCode,
      Country: this.state.country,
      Logo: this.state.logo,
      ShortDescription: this.state.shortDescription,
      LongDescription: this.state.longDescription,
      Images: this.state.images,
    };
    this.props
      .updateRegistrationGeneralDetails(
        this.props.registration.registration.key,
        generalDetails
      )
      .then(() => {
        this.props.getToastr("Registration saved successfully");
        this.props.getRegistration(this.props.registration.registration.key);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Page
          title={"Registration"}
          showSaveButton={true}
          showCancelButton={true}
          processing={this.state.saving}
          onClick={(action) => {
            if (action === "cancel") {
              this.loadData();
              if (isMobileApp()) {
                this.props.history.push("/home");
              }
            } else if (action === "save") {
              this.onSaveGeneralDetails();
            }
          }}
        >
          <PageSection title="General Details">
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Business/Brand Name"
                  value={this.state.businessName}
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Contact No"
                  placeholder="Please enter your contact no"
                  onChange={(value) => this.setState({ contactNo: value })}
                  value={this.state.contactNo}
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Email ID"
                  placeholder="Please enter your email Id"
                  onChange={(value) => this.setState({ emailID: value })}
                  value={this.state.emailID}
                />
              </div>
              <div className="col-md-3">
                <Input
                  type="text"
                  label="Website"
                  placeholder="Please enter your Website"
                  onChange={(value) => this.setState({ webSite: value })}
                  value={this.state.webSite}
                />
              </div>
              {this.state.plan !== "" && (
                <div className="col-md-3">
                  <Input type="text" label="Plan" value={this.state.plan} />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  type="textarea"
                  label="Short Description"
                  value={this.state.shortDescription}
                  onChange={(value) =>
                    this.setState({ shortDescription: value })
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="textarea"
                  label="Long Description"
                  value={this.state.longDescription}
                  onChange={(value) =>
                    this.setState({ longDescription: value })
                  }
                />
              </div>
            </div>
          </PageSection>
          {this.props.config.WhiteLabel.Code !== "PINCODE" && (
            <PageSection title="Address">
              <div className="row">
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="Line Address 1"
                    onChange={(value) => this.setState({ address1: value })}
                    value={this.state.address1}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="Line Address 2"
                    onChange={(value) => this.setState({ address2: value })}
                    value={this.state.address2}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="City"
                    onChange={(value) => this.setState({ city: value })}
                    value={this.state.city}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="State"
                    onChange={(value) => this.setState({ state: value })}
                    value={this.state.state}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="Postal Code"
                    onChange={(value) => this.setState({ postalCode: value })}
                    value={this.state.postalCode}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type="text"
                    label="Country"
                    onChange={(value) => this.setState({ country: value })}
                    value={this.state.country}
                    disabled={this.state.country.trim() !== ""}
                  />
                </div>
              </div>
            </PageSection>
          )}
          <PageSection title="Logo & Images">
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="image"
                  label="Logo"
                  value={this.state.logo ? [{ ...this.state.logo }] : null}
                  onChange={(value) =>
                    this.setState({ logo: value.length > 0 ? value[0] : null })
                  }
                  maxImageCount={1}
                  {...this.props}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="image"
                  label="Images"
                  value={this.state.images}
                  onChange={(value) => this.setState({ images: value })}
                  maxImageCount={6}
                  {...this.props}
                />
              </div>
            </div>
            <div className="row">
              {this.props.registration.registration.key && (
                <div className="col-md-12">
                  <AuditTrail
                    {...this.props}
                    module={constants.MODULE_REGISTRATION}
                    parentKey={this.props.registration.registration.key}
                  />
                </div>
              )}
            </div>
          </PageSection>
        </Page>

        {/* <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                    <div style={modalWidth}>
                        <AddRegistrationSetting   {...this.props}
                            onClose={() => this.setState({ showModal: false })}
                            onSave={this.onSaveGeneralDetails}
                        />
                    </div>
                </Modal>
                <Modal open={this.state.showDownload} onClose={() => this.setState({ showDownload: false })} center>
                    <div style={modalWidth}>
                        <DownloadSalesData   {...this.props}
                            onClose={() => this.setState({ showDownload: false })}
                        />
                    </div>
                </Modal> */}
        {/* <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                    <div style={{ width: "400px" }}>
                        <ImportMaster   {...this.props}
                            importType="salesLegacyImport"
                            onClose={() => this.setState({ showImport: false })}
                            onImportCompletion={(importRecords) => {
                                console.log(importRecords)
                                }
                            }
                        />
                    </div>
                </Modal> */}
      </React.Fragment>
    );
  }
}

export default Registration;
