import axios from "axios";
import firebase, { database, functions } from "../firebase";

export function getAllInvetoryDetailReport(
  option,
  from,
  size,
  registrationID,
  fromStoreID,
  toStoreID,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchVendorDetail,
  searchProductDetail,
  searchProductCustomField1 = "",
  searchProductCustomField2 = "",
  searchProductCustomField3 = "",
  searchProductCustomField4 = "",
  searchProductCustomField5 = "",
  searchProductCustomField6 = "",
  scrollID = ""
) {
  return (dispatch, getState) => {
    // console.log('option',option)
    if (option === "1") {
      return getAllInvetoryDetailReportOption1(
        from,
        size,
        registrationID,
        fromStoreID,
        toStoreID,
        searchTransactionNo,
        searchTransactionType,
        searchTransactionFromDate,
        searchTransactionToDate,
        searchVendorDetail,
        searchProductDetail,
        (searchProductCustomField1 = ""),
        (searchProductCustomField2 = ""),
        (searchProductCustomField3 = ""),
        (searchProductCustomField4 = ""),
        (searchProductCustomField5 = ""),
        (searchProductCustomField6 = ""),
        (scrollID = "")
      );
    } else {
      return getAllInvetoryDetailReportOption2(
        from,
        size,
        registrationID,
        fromStoreID,
        toStoreID,
        searchTransactionNo,
        searchTransactionType,
        searchTransactionFromDate,
        searchTransactionToDate,
        searchVendorDetail,
        searchProductDetail,
        (searchProductCustomField1 = ""),
        (searchProductCustomField2 = ""),
        (searchProductCustomField3 = ""),
        (searchProductCustomField4 = ""),
        (searchProductCustomField5 = ""),
        (searchProductCustomField6 = ""),
        (scrollID = "")
      );
    }
  };
}

async function getAllInvetoryDetailReportOption1(
  from,
  size,
  registrationID,
  fromStoreID,
  toStoreID,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchVendorDetail,
  searchProductDetail,
  searchProductCustomField1 = "",
  searchProductCustomField2 = "",
  searchProductCustomField3 = "",
  searchProductCustomField4 = "",
  searchProductCustomField5 = "",
  searchProductCustomField6 = "",
  scrollID = ""
) {
  let searchParameters = null;
  if (scrollID == "") {
    searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              range: {
                TransactionDate: {
                  lte: searchTransactionToDate,
                  gte: searchTransactionFromDate,
                  format: "yyyy-MM-dd",
                },
              },
            },
            {
              regexp: {
                TransactionTypeID: searchTransactionType.trim().toLowerCase(),
              },
            },
            {
              match: {
                "RegistrationID.keyword": registrationID,
              },
            },
          ],
          filter: {
            bool: {
              should: [],
              minimum_should_match: 1,
              must: [],
            },
          },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };

    if (searchVendorDetail.trim() !== "") {
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { VendorName: searchVendorDetail.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: {
          VendorEmailID: searchVendorDetail.trim().toLowerCase(),
        },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: {
          VendorPhoneNo: searchVendorDetail.trim().toLowerCase(),
        },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { VendorCode: searchVendorDetail.trim().toLowerCase() },
      });
    }
    if (searchProductDetail.trim() !== "") {
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { ProductName: searchProductDetail.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match: { SKU: searchProductDetail.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { BarCode: searchProductDetail.trim().toLowerCase() },
      });
      searchParameters.query.bool.filter.bool.should.push({
        regexp: {
          ProductName: "@" + searchProductDetail.trim().toLowerCase() + ".*",
        },
      });
      searchParameters.query.bool.filter.bool.should.push({
        regexp: { SKU: "@" + searchProductDetail.trim().toLowerCase() + ".*" },
      });
      searchParameters.query.bool.filter.bool.should.push({
        regexp: {
          BarCode: "@" + searchProductDetail.trim().toLowerCase() + ".*",
        },
      });
    }
    if (searchTransactionNo.trim() !== "") {
      searchParameters.query.bool.filter.bool.should.push({
        match_phrase: { TransactionNo: searchTransactionNo },
      });
    }
    if (searchProductCustomField1.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
        },
      });
    }
    if (searchProductCustomField2.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
        },
      });
    }
    if (searchProductCustomField3.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
        },
      });
    }
    if (searchProductCustomField4.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
        },
      });
    }
    if (searchProductCustomField5.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
        },
      });
    }
    if (searchProductCustomField6.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
        },
      });
    }
  } else {
    searchParameters = {
      scroll: "1m",
      scroll_id: scrollID,
    };
  }

  // return (dispatch, getState) => {
  if (fromStoreID !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "StoreID.keyword": fromStoreID,
      },
    });
  } else if (toStoreID !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "ToStoreID.keyword": toStoreID,
      },
    });
  }

  let addMessage = ""; //firebase.functions().httpsCallable('getDenomInventory');

  if (scrollID === "") {
    addMessage = firebase
      .functions()
      .httpsCallable("getDenomInventoryWithScrollID");
  } else {
    addMessage = firebase
      .functions()
      .httpsCallable("getDenomInventoryForScrollID");
  }

  // console.log("getAllInvetoryDetailReport - query", JSON.stringify(searchParameters));

  return addMessage({ text: searchParameters }).then(function(result) {
    const inventoryList = [];
    let sanitizedMessage = result.data.text;
    sanitizedMessage.hits.hits.map((data, index) => {
      inventoryList.push({ ...data._source, key: data._id });
    });
    return {
      totalItemsCount: sanitizedMessage.hits.total.value,
      searchResult: inventoryList,
      scrollID: sanitizedMessage._scroll_id,
    };
  });
  // }
}

async function getAllInvetoryDetailReportOption2(
  from,
  size,
  registrationID,
  fromStoreID,
  toStoreID,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchVendorDetail,
  searchProductDetail,
  searchProductCustomField1 = "",
  searchProductCustomField2 = "",
  searchProductCustomField3 = "",
  searchProductCustomField4 = "",
  searchProductCustomField5 = "",
  searchProductCustomField6 = ""
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            regexp: {
              TransactionTypeID: searchTransactionType.trim().toLowerCase(),
            },
          },
          {
            match: {
              "RegistrationID.keyword": registrationID,
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            // {
            //   "category": {
            //     "terms": {
            //       "field": "Category.keyword",
            //       "order": "asc"
            //     }
            //   }
            // },
            // {
            //   "sub_category": {
            //     "terms": {
            //       "field": "SubCategory.keyword",
            //       "missing_bucket": true,
            //       "order": "asc"
            //     }
            //   }
            // },
            // {
            //   "brand": {
            //     "terms": {
            //       "field": "Brand.keyword",
            //       "missing_bucket": true,
            //       "order": "asc"
            //     }
            //   }
            // },
            {
              sku: {
                terms: {
                  field: "SKU.keyword",
                },
              },
            },
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                  order: "asc",
                },
              },
            },
            // {
            //   "productID": {
            //     "terms": {
            //       "field": "ProductID.keyword"
            //     }
            //   }
            // },
            // {
            //   "product_type": {
            //     "terms": {
            //       "field": "ProductType.keyword"
            //     }
            //   }
            // },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_subtotal: {
            sum: {
              field: "SubTotal",
            },
          },
          // "total_SubTotalWithDiscount": {
          //   "sum": {
          //     "field": "SubTotalWithDiscount"
          //   }
          // },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_amount: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };

  if (searchVendorDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { VendorName: searchVendorDetail.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { VendorEmailID: searchVendorDetail.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { VendorPhoneNo: searchVendorDetail.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { VendorCode: searchVendorDetail.trim().toLowerCase() },
    });
  }
  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { ProductName: searchProductDetail.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match: { SKU: searchProductDetail.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { BarCode: searchProductDetail.trim().toLowerCase() },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        ProductName: "@" + searchProductDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: { SKU: "@" + searchProductDetail.trim().toLowerCase() + ".*" },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        BarCode: "@" + searchProductDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }

  if (fromStoreID !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "StoreID.keyword": fromStoreID,
      },
    });
  } else if (toStoreID !== "") {
    searchParameters.query.bool.must.push({
      match: {
        "ToStoreID.keyword": toStoreID,
      },
    });
  }

  // return (dispatch, getState) => {
  // console.log("getAllInvetoryDetailReport - query", JSON.stringify(searchParameters));

  let addMessage = firebase.functions().httpsCallable("getDenomInventory");
  return addMessage({ text: searchParameters }).then(function(result) {
    const inventoryList = [];
    let sanitizedMessage = result.data.text;
    // console.log('sanitizedMessage',sanitizedMessage)
    sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
      let item = {
        // category: data.key.category,
        // sub_category: data.key.sub_category,
        // brand: data.key.brand,
        // product_type: data.key.product_type,
        sku: data.key.sku,
        product: data.key.product,
        // productID: data.key.productID,
        total_quantity: Number(data.total_qty.value).toFixed(2),
        total_discount: Number(data.total_discount.value).toFixed(2),
        total_taxes: Number(data.total_taxes.value).toFixed(2),
        total_taxable: Number(data.total_taxable.value).toFixed(2),
        total_amount: Number(data.total_amount.value).toFixed(2),
      };
      inventoryList.push(item);
    });

    return {
      totalItemsCount: sanitizedMessage.hits.total.value,
      searchResult: inventoryList,
    };
  });
  // }
}

export function getActiveProductsForInventoryHistory(
  registrationID,
  scrollID,
  storeID,
  searchCategory = "",
  searchSubCategory = "",
  searchProductSKU = "",
  searchProductName = "",
  searchBrand = ""
) {
  let searchParameters = null;
  if (scrollID == "") {
    searchParameters = {
      from: 0,
      size: 2000,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            {
              terms: {
                ProductType: ["Goods for sale", "Goods not for sale"],
              },
            },
          ],
          must_not: [{ match: { IsDeleted: "true" } }],
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          Name: {
            order: "asc",
          },
        },
      ],
    };

    if (searchProductName.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: { Name: "@" + searchProductName.trim().toLowerCase() + ".*" },
      });
    }
    if (searchProductSKU.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: { SKU: searchProductSKU.trim() },
      });
    }
    if (searchCategory.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: { Category: searchCategory.trim().toLowerCase() },
      });
    }
    if (searchSubCategory.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
      });
    }
    if (searchBrand.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: { Brand: searchBrand.trim().toLowerCase() },
      });
    }
  } else {
    searchParameters = {
      scroll: "1m",
      scroll_id: scrollID,
    };
  }

  // console.log("query", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = "";
    if (scrollID === "") {
      addMessage = firebase
        .functions()
        .httpsCallable("getProductsWithScrollID");
    } else {
      addMessage = firebase.functions().httpsCallable("getProductsForScrollID");
    }

    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((doc, index) => {
        let product = doc._source;
        product.ActionOn = null;
        if (
          product.hasOwnProperty("AccessibleToSelectedStores") &&
          product.AccessibleToSelectedStores
        ) {
          let storePrice = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === storeID
          );
          if (storePrice.length > 0) {
            product.RetailPrice = storePrice[0].RetailPrice;
            product.SupplyPrice = storePrice[0].SupplyPrice;
            product.Markup = storePrice[0].Markup;
            product.DiscountAmount = storePrice[0].hasOwnProperty(
              "DiscountAmount"
            )
              ? storePrice[0].DiscountAmount
              : 0;
            product.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            product.MRP = storePrice[0].MRP;
            product.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            product.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            product.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            // if(product.SKU ==="PERI PERI Sauce Fries")
            // {
            //   console.log('AccessibleToSelectedStores', product)
            // }
            products.push({ key: doc._id, ...product });
          }
        } else if (
          product.hasOwnProperty("StoreSpecificPricing") &&
          product.StoreSpecificPricing
        ) {
          // console.log(e)
          let storePrice = product.StoreSpecificPrices.filter(
            (f) => f.StoreID === storeID
          );
          if (storePrice.length > 0) {
            product.RetailPrice = storePrice[0].RetailPrice;
            product.SupplyPrice = storePrice[0].SupplyPrice;
            product.Markup = storePrice[0].Markup;
            product.DiscountAmount = storePrice[0].hasOwnProperty(
              "DiscountAmount"
            )
              ? storePrice[0].DiscountAmount
              : 0;
            product.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            product.MRP = storePrice[0].MRP;
            product.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            product.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            product.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            products.push({ key: doc._id, ...product });
            // if(product.SKU ==="PERI PERI Sauce Fries")
            // {
            //   console.log('StoreSpecificPricing', product)
            // }
          } else {
            products.push({ key: doc._id, ...product });
          }
        } else {
          products.push({ key: doc._id, ...product });
        }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
        scrollID: sanitizedMessage._scroll_id,
      };
    });
  };
}

export function getAttendanceReport(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchStatus
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              Date: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      attendane_over_day: {
        date_histogram: {
          field: "Date",
          interval: "day",
          format: "dd-MMM-yyyy",
          order: {
            _key: "asc",
          },
        },
        aggs: {
          employee: {
            terms: {
              field: "Employee.label.keyword",
              size: 10000,
            },
            aggs: {
              attendance_type: {
                terms: {
                  field: "AttendanceType.keyword",
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      match: {
        StoreID: store.key,
      },
    });
  }
  if (searchStatus != "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { AttendanceType: searchStatus.trim().toLowerCase() },
    });
  }
  // console.log("Get Attendance - Query", JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getAttendance");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let attendanceList = [];
      let data = sanitizedMessage.aggregations.attendane_over_day.buckets;
      data.map((bucket) => {
        bucket.employee.buckets.map((emp) => {
          // console.log(emp);
          const attendance = [
            bucket.key_as_string,
            emp.key,
            emp.attendance_type.buckets[0].key,
          ];

          attendanceList.push(attendance);
        });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: attendanceList,
      };
    });
  };
}

export function getInvoiceDetailReport2(registrationID, searchTransactionNo) {
  let searchParameters = {
    from: 0,
    size: 1000,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }

  return (dispatch, getState) => {
    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": getState().user.store.key,
      },
    });

    // console.log("query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomSales");

    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getSubCategoryWiseSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size = 10,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_sub_category: {
        terms: {
          field: "SubCategory.keyword",
          size: size,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (sortBy === "sales") {
    searchParameters.aggs.group_by_sub_category.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.group_by_sub_category.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const subcategories = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_sub_category.buckets.map(
        (data, index) => {
          let subcategory = {
            name: data.key,
            total_qty: Number(data.total_qty.value).toFixed(2),
            total_sales: Number(data.total_sales.value).toFixed(2),
          };
          subcategories.push(subcategory);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: subcategories,
      };
    });
  };
}

export function getBrandWiseSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size = 10,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_brand: {
        terms: {
          field: "Brand.keyword",
          size: size,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (sortBy === "amount") {
    searchParameters.aggs.group_by_brand.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.group_by_brand.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const brands = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_brand.buckets.map(
        (data, index) => {
          let brand = {
            name: data.key,
            total_qty: Number(data.total_qty.value).toFixed(2),
            total_sales: Number(data.total_sales.value).toFixed(2),
          };
          brands.push(brand);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: brands,
      };
    });
  };
}

export function getPurchaseForLast12Months(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchInterval
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "TransactionType.UpdateStockInwardOrOutward": {
                value: "inward",
              },
            },
          },
          {
            exists: {
              field: "Vendor",
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      orders_over_day: {
        date_histogram: {
          field: "TransactionDate",
          interval: searchInterval,
          format: "MMM-yy",
        },
        aggs: {
          total_purchase: {
            sum: {
              field: "BillAmount",
            },
          },
          total_transactions: {
            cardinality: {
              field: "_id",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getInventory");
    // console.log("getPurchaseForLast12Months", JSON.stringify(searchParameters));
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let purchase = [];
      let data = sanitizedMessage.aggregations.orders_over_day.buckets;
      // console.log("data", data);
      data.map((bucket) => {
        const tmp = {
          date: bucket.key_as_string,
          total_purchase: bucket.total_purchase.value,
          total_transactions: bucket.total_transactions.value,
        };
        purchase.push(tmp);
      });
      // console.log("getSalesForLast12Months - data", JSON.stringify(sales));
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: purchase,
      };
    });
  };
}

export function getSalesForLast12Months(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchInterval
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "TransactionType.SalesImpactChecked": true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      orders_over_day: {
        date_histogram: {
          field: "TransactionDate",
          interval: searchInterval,
          format: "MMM-yy",
        },
        aggs: {
          total_sales: {
            sum: {
              field: "BillPaidAmount",
            },
          },
          total_transactions: {
            cardinality: {
              field: "_id",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  return async (dispatch) => {
    // let addMessage = firebase.functions().httpsCallable("getSales");
    // return addMessage({ text: searchParameters }).then(function(result) {
    //   let sanitizedMessage = result.data.text;
    //   let sales = [];
    //   let data = sanitizedMessage.aggregations.orders_over_day.buckets;
    //   data.map((bucket) => {
    //     const sale = {
    //       date: bucket.key_as_string,
    //       total_sales: bucket.total_sales.value,
    //       total_transactions: bucket.total_transactions.value,
    //     };
    //     sales.push(sale);
    //   });
    //   return {
    //     totalItemsCount: sanitizedMessage.hits.total.value,
    //     searchResult: sales,
    //   };
    // });

    let result = await axios
      .post("https://backend-o3dyulj4pa-el.a.run.app/v1/transaction/getsales", {
        text: searchParameters,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});

    let sanitizedMessage = result.text;
    let sales = [];
    let data = sanitizedMessage.aggregations.orders_over_day.buckets;
    data.map((bucket) => {
      const sale = {
        date: bucket.key_as_string,
        total_sales: bucket.total_sales.value,
        total_transactions: bucket.total_transactions.value,
      };
      sales.push(sale);
    });
    return {
      totalItemsCount: sanitizedMessage.hits.total.value,
      searchResult: sales,
    };
  };
}

export function getPaymentReceivedDaily(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchInterval
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            match_phrase: {
              TransactionType: "Receive Payment",
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      orders_over_day: {
        date_histogram: {
          field: "TransactionDate",
          interval: searchInterval,
          format: searchInterval === "month" ? "MMM-yyyy" : "dd-MMM-yyyy",
          order: {
            _key: "desc",
          },
        },
        aggs: {
          store: {
            terms: {
              field: "StoreName.keyword",
              size: 100,
            },
            aggs: {
              total_received: {
                sum: {
                  field: "PaymentAmount",
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    //console.log("getPaymentReceivedDaily - query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let paymentList = [];
      //console.log("getPaymentReceivedDaily - sanitizedMessage", JSON.stringify(sanitizedMessage));

      sanitizedMessage.aggregations.orders_over_day.buckets.map(
        (storeBucket, index) => {
          storeBucket.store.buckets.map((paymentBucket, index) => {
            let payment = {};
            payment["Store"] = paymentBucket.key;
            if (searchInterval === "month") {
              payment["Transaction Month"] = storeBucket.key_as_string;
            } else {
              payment["Transaction Date"] = storeBucket.key_as_string;
            }
            payment["Payment_total_received"] =
              paymentBucket.total_received.value === undefined
                ? 0
                : paymentBucket.total_received.value;
            //console.log("getPaymentReceivedDaily - payment", JSON.stringify(payment));

            paymentList.push(payment);
          });
        }
      );
      console.log(
        "getPaymentReceivedDaily - data",
        JSON.stringify(paymentList)
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: paymentList,
      };
    });
  };
}

export function getMembershipData(storeIDs) {
  return (dispatch) => {
    let searchParameters = {
      size: 0,
      query: {
        bool: {
          must: [
            { terms: { "StoreID.keyword": storeIDs } },
            {
              match: {
                IsDeleted: "false",
              },
            },
          ],
        },
      },
      aggs: {
        membership: {
          terms: {
            field: "Membership.Name.keyword",
            missing: "Non Member",
            size: 10,
          },
        },
      },
    };
    //console.log("getMembershipData - query",JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const memberships = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.membership.buckets.map((data, index) => {
        let membership = {
          name: data.key,
          expired_count: 0,
          active_count: 0,
          total_count: Number(data.doc_count),
        };
        memberships.push(membership);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: memberships,
      };
    });
  };
}

export function getExpiredMembershipData(storeIDs) {
  return (dispatch) => {
    let searchParameters = {
      size: 0,
      query: {
        bool: {
          must: [
            { terms: { "StoreID.keyword": storeIDs } },
            {
              range: {
                "Membership.ValidityDate": {
                  lt: "now",
                  format: "dd-MMM-yyyy",
                },
              },
            },
            {
              match: {
                IsDeleted: "false",
              },
            },
          ],
        },
      },
      aggs: {
        membership: {
          terms: {
            field: "Membership.Name.keyword",
            size: 10,
          },
        },
      },
    };
    //console.log("getMembershipData - query",JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const memberships = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.membership.buckets.map((data, index) => {
        let memberhip = {
          name: data.key,
          total_count: Number(data.doc_count),
        };
        memberships.push(memberhip);
        //console.log("memberships",memberships)
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: memberships,
      };
    });
  };
}

export function getActiveMembershipData(storeIDs) {
  return (dispatch) => {
    let searchParameters = {
      size: 0,
      query: {
        bool: {
          must: [
            { terms: { "StoreID.keyword": storeIDs } },
            {
              range: {
                "Membership.ValidityDate": {
                  gte: "now",
                  format: "dd-MMM-yyyy",
                },
              },
            },
            {
              match: {
                IsDeleted: "false",
              },
            },
          ],
        },
      },
      aggs: {
        membership: {
          terms: {
            field: "Membership.Name.keyword",
            size: 10,
          },
        },
      },
    };
    //console.log("getMembershipData - query",JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const memberships = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.membership.buckets.map((data, index) => {
        let memberhip = {
          name: data.key,
          total_count: Number(data.doc_count),
        };
        memberships.push(memberhip);
        //console.log("memberships",memberships)
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: memberships,
      };
    });
  };
}

export function getMembershipSales(storeIDs) {
  return (dispatch) => {
    let searchParameters = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              terms: {
                "StoreID.keyword": storeIDs,
              },
            },
            {
              term: {
                SalesImpacted: true,
              },
            },
            {
              regexp: {
                CustomerName: ".+",
              },
            },
          ],
          must_not: [
            {
              exists: { field: "CancellationReason" },
            },
          ],
        },
      },
      aggs: {
        membership: {
          terms: {
            field: "CustomerMembership.keyword",
            size: 10,
          },
          aggs: {
            total_sales: {
              sum: {
                field: "Amount",
              },
            },
            total_transactions: {
              cardinality: {
                field: "TransactionID",
              },
            },
          },
        },
      },
    };
    //console.log("getMembershipData - query",JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const memberships = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.membership.buckets.map((data, index) => {
        let memberhip = {
          name: data.key === "" ? "Non Member" : data.key,
          total_transactions: Number(data.total_transactions.value).toFixed(0),
          total_sales: Number(data.total_sales.value).toFixed(0),
        };
        memberships.push(memberhip);
        //console.log("memberships",memberships)
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: memberships,
      };
    });
  };
}

export function getBillWiseDetailReport(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchCustomerDetail,
  searchCashRegister = "",
  includeLineItems = false,
  deliveryStatus = []
) {
  let searchParameters = {
    _source: [
      "Store.StoreName",
      "Store.StoreCode",
      "Store.key",
      "DeliveryBy",
      "CashRegister.CashRegisterName",
      "CashRegister.CashRegisterCode",
      "Customer.CustomerCode",
      "Customer.FirstName",
      "Customer.LastName",
      "Customer.PhoneNo",
      "Customer.EmailID",
      "TransactionNo",
      "TransactionType.TransactionType",
      "TransactionDate",
      "BillQty",
      "BillTaxAmount",
      "BillSubTotal",
      "BillDiscount",
      "BillAmount",
      "BillPaidAmount",
      "ReceivedPaymentAmount",
      "BalanceAmount",
      "SalesReturnTransactionNo",
      "Customer.CustomField1",
      "Customer.CustomField2",
      "Customer.CustomField3",
      "Customer.CustomField4",
      "Customer.CustomField5",
      "Customer.CustomField6",
      "CancellationReason",
      "BillChangeDue",
      "Payments",
      "SalesReturnTransactionNo",
      "ParentTransactionKey",
      "ParentTransactionNo",
      "ChildTransactionNo",
      "ReceivedPaymentTransactions",
      "BillingAddress",
      "DeliveryStatus",
      "ExternalTransactionNo",
      "DeliveryCustomerAddress",
      "CancellationBy",
      "DeliveryType",
      "DeliveryPersonName",
      "PincodeMerchantInvoiceAmount",
      "ActionOn",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  if (deliveryStatus && deliveryStatus.length > 0) {
    searchParameters.query.bool.must.push({
      terms: {
        "DeliveryStatus.keyword": deliveryStatus,
      },
    });
  }
  if (includeLineItems) {
    searchParameters._source.push("LineItems");
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.FirstName":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.LastName":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.EmailID":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.PhoneNo":
          "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "Customer.Code": "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "TransactionType.key": searchTransactionType.trim().toLowerCase(),
      },
    });
  }
  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "CashRegister.key":
          "@" + searchCashRegister.trim().toLowerCase() + ".*",
      },
    });
  }
  // console.log("getBillWiseDetailReport", JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let invoice = { key: data._id, ...data._source };
        invoices.push(invoice);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getInvoiceDetailReport(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchCustomerDetail,
  searchProductDetail,
  searchBrand = ""
) {
  let searchParameters = {
    _source: [
      "StoreID",
      "StoreName",
      "StoreCode",
      "CashRegisterName",
      "CashRegisterCode",
      "CustomerCode",
      "CustomerName",
      "CustomerPhoneNo",
      "CustomerCustomField1",
      "CustomerCustomField2",
      "CustomerCustomField3",
      "CustomerCustomField4",
      "CustomerCustomField5",
      "CustomerCustomField6",
      "TransactionNo",
      "TransactionType",
      "TransactionDate",
      "CancellationReason",
      "ProductType",
      "HSNSACCode",
      "Brand",
      "Category",
      "SubCategory",
      "SKU",
      "BarCode",
      "ProductID",
      "ProductName",
      "ProductCustomField1",
      "ProductCustomField2",
      "ProductCustomField3",
      "ProductCustomField4",
      "ProductCustomField5",
      "ProductCustomField6",
      "MRP",
      "SupplyPrice",
      "RetailPrice",
      "Qty",
      "TaxInclusive",
      "TaxableAmount",
      "DiscountPer",
      "TaxComponentAmount",
      "SubTotal",
      "TotalTax",
      "DiscountAmount",
      "Amount",
      "UniqueNo1",
      "UniqueNo2",
      "UniqueNo3",
      "UniqueNo4",
      "BatchExpDate",
      "BatchExpDateValue",
      "BatchMRP",
      "BatchMfgDate",
      "BatchMfgDateValue",
      "BatchNo",
      "BatchRetailPrice",
      "TransactionID",
      "EmployeeName",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomerDetails: "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        ProductDetails: "@" + searchProductDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { TransactionTypeID: searchTransactionType.trim().toLowerCase() },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  // console.log("getInvoiceDetailReport - query",JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");

    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getEmployeeItemDetailReport(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionNo,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchCustomerDetail,
  searchProductDetail,
  searchEmployeeName
) {
  let searchParameters = {
    _source: [
      "EmployeeName",
      "StoreName",
      "TransactionNo",
      "TransactionType",
      "CancellationReason",
      "TransactionDate",
      "CustomerCode",
      "CustomerName",
      "CustomerEmailID",
      "CustomerPhoneNo",
      "ProductType",
      "Brand",
      "Category",
      "SubCategory",
      "SKU",
      "ProductName",
      "RetailPrice",
      "Qty",
      "DiscountAmount",
      "TaxableAmount",
      "TotalTax",
      "Amount",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            exists: {
              field: "EmployeeName",
            },
          },
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
  };
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        CustomerDetails: searchCustomerDetail.trim().toLowerCase(),
      },
    });
  }
  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductDetails: searchProductDetail.trim().toLowerCase(),
      },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: { TransactionTypeID: searchTransactionType },
    });
  }

  if (searchEmployeeName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { EmployeeName: searchEmployeeName.trim().toLowerCase() },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }

  return (dispatch, getState) => {
    for (let i = 0; i < searchStores.length; i++) {
      const store = searchStores[i];

      searchParameters.query.bool.should.push({
        term: {
          "StoreID.keyword": store.key,
        },
      });
    }

    // searchParameters.query.bool.must.push({
    //   "match": {
    //     "StoreID": getState().user.store.key
    //   }
    // })

    // console.log("employee product", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomSales");

    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getItemWiseTotalForASpecificTransactionType(
  storeID,
  toStoreID,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchProductSKU,
  searchProductName,
  searchCategory,
  searchSubCategory,
  searchBrand
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              category: {
                terms: {
                  field: "Category.keyword",
                  order: "asc",
                },
              },
            },
            {
              sub_category: {
                terms: {
                  field: "SubCategory.keyword",
                  missing_bucket: true,
                  order: "asc",
                },
              },
            },
            {
              brand: {
                terms: {
                  field: "Brand.keyword",
                  missing_bucket: true,
                  order: "asc",
                },
              },
            },
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                  order: "asc",
                },
              },
            },
            {
              productID: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              product_type: {
                terms: {
                  field: "ProductType.keyword",
                },
              },
            },
            {
              sku: {
                terms: {
                  field: "SKU.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_amount: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };
  // for (let i = 0; i < searchStoreName.length; i++) {
  //   const store = searchStoreName[i];

  //   if(storeID.trim()!==""){
  //     searchParameters.query.bool.should.push({
  //       "term": {
  //         "StoreID.keyword": store.key
  //       }
  //     })
  //     }
  //   }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }

  return (dispatch, getState) => {
    if (
      getState().registration.registration.hasOwnProperty(
        "ReportingStartTime"
      ) &&
      getState().registration.registration.hasOwnProperty("ReportingEndTime")
    ) {
      let transactionToDate = new Date(searchTransactionToDate).setHours(
        23,
        59
      );
      let transactionFromDate = new Date(searchTransactionFromDate).setHours(
        0,
        0,
        0,
        0
      );

      transactionFromDate = new Date(transactionFromDate).setHours(
        new Date(transactionFromDate).getHours() +
          getState().registration.registration.ReportingStartTime
      );
      transactionToDate = new Date(transactionToDate).setHours(
        new Date(transactionToDate).getHours() +
          getState().registration.registration.ReportingEndTime
      );

      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: transactionToDate,
            gte: transactionFromDate,
          },
        },
      });
    } else {
      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: searchTransactionToDate,
            gte: searchTransactionFromDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }
    // console.log("Product Sold - Query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemSold = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          store: data.key.store,
          brand: data.key.brand,
          category: data.key.category,
          sub_category: data.key.sub_category,
          product_type: data.key.product_type,
          sku: data.key.sku,
          product: data.key.product,
          productID: data.key.productID,
          supplyprice: data.key.supplyprice,
          retailprice: data.key.retailprice,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };
        itemSold.push(item);
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemSold,
      };
    });
  };
}

export function getItemSoldReportData(
  registrationID,
  searchStoreName,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand,
  searchProductCustomField1,
  searchProductCustomField2,
  searchProductCustomField3,
  searchProductCustomField4,
  searchProductCustomField5,
  searchProductCustomField6,
  showStoreWiseBreakUp
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              category: {
                terms: {
                  field: "Category.keyword",
                  order: "asc",
                },
              },
            },
            {
              sub_category: {
                terms: {
                  field: "SubCategory.keyword",
                  missing_bucket: true,
                  order: "asc",
                },
              },
            },
            {
              brand: {
                terms: {
                  field: "Brand.keyword",
                  missing_bucket: true,
                  order: "asc",
                },
              },
            },
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                  order: "asc",
                },
              },
            },
            {
              productID: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              product_type: {
                terms: {
                  field: "ProductType.keyword",
                },
              },
            },
            {
              sku: {
                terms: {
                  field: "SKU.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };
  console.log("showStoreWiseBreakUp", showStoreWiseBreakUp);
  if (showStoreWiseBreakUp) {
    searchParameters.aggs.my_buckets.composite.sources.push({
      store: {
        terms: {
          field: "StoreName.keyword",
        },
      },
    });
  }

  for (let i = 0; i < searchStoreName.length; i++) {
    const store = searchStoreName[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }
  return (dispatch, getState) => {
    if (
      getState().registration.registration.hasOwnProperty(
        "ReportingStartTime"
      ) &&
      getState().registration.registration.hasOwnProperty("ReportingEndTime")
    ) {
      let transactionToDate = new Date(searchTransactionToDate).setHours(
        23,
        59
      );
      let transactionFromDate = new Date(searchTransactionFromDate).setHours(
        0,
        0,
        0,
        0
      );

      transactionFromDate = new Date(transactionFromDate).setHours(
        new Date(transactionFromDate).getHours() +
          getState().registration.registration.ReportingStartTime
      );
      transactionToDate = new Date(transactionToDate).setHours(
        new Date(transactionToDate).getHours() +
          getState().registration.registration.ReportingEndTime
      );

      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: transactionToDate,
            gte: transactionFromDate,
          },
        },
      });
    } else {
      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: searchTransactionToDate,
            gte: searchTransactionFromDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }
    console.log("Product Sold - Query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemSold = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          store: data.key.store,
          brand: data.key.brand,
          category: data.key.category,
          sub_category: data.key.sub_category,
          product_type: data.key.product_type,
          sku: data.key.sku,
          product: data.key.product,
          productID: data.key.productID,
          supplyprice: data.key.supplyprice,
          retailprice: data.key.retailprice,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };
        itemSold.push(item);
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemSold,
      };
    });
  };
}

export function getProfitandLossReportOLD(
  registrationID,
  searchStoreName,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand,
  searchProductCustomField1,
  searchProductCustomField2,
  searchProductCustomField3,
  searchProductCustomField4,
  searchProductCustomField5,
  searchProductCustomField6
) {
  let searchParameters = {
    size: 2000,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              store: {
                terms: {
                  field: "StoreName.keyword",
                },
              },
            },
            {
              productID: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStoreName.length; i++) {
    const store = searchStoreName[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }
  return async (dispatch, getState) => {
    if (
      getState().registration.registration.hasOwnProperty(
        "ReportingStartTime"
      ) &&
      getState().registration.registration.hasOwnProperty("ReportingEndTime")
    ) {
      let transactionToDate = new Date(searchTransactionToDate).setHours(
        23,
        59
      );
      let transactionFromDate = new Date(searchTransactionFromDate).setHours(
        0,
        0,
        0,
        0
      );

      transactionFromDate = new Date(transactionFromDate).setHours(
        new Date(transactionFromDate).getHours() +
          getState().registration.registration.ReportingStartTime
      );
      transactionToDate = new Date(transactionToDate).setHours(
        new Date(transactionToDate).getHours() +
          getState().registration.registration.ReportingEndTime
      );

      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: transactionToDate,
            gte: transactionFromDate,
          },
        },
      });
    } else {
      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: searchTransactionToDate,
            gte: searchTransactionFromDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }
    // console.log("Product Sold - Query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("scrollData");
    let result = await addMessage({
      text: searchParameters,
      collection: "denomsales",
      scrollID: "",
    });

    const itemSold = [];
    let sanitizedMessage = result.data.text;
    let total = sanitizedMessage.hits.total.value;
    let scrollID = sanitizedMessage._scroll_id;
    sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
      let item = {
        productID: data.key.productID,
        store: data.key.store,
        total_quantity: Number(data.total_qty.value).toFixed(2),
        total_sales: Number(data.total_sales.value).toFixed(2),
        total_discount: Number(data.total_discount.value).toFixed(2),
        total_taxes: Number(data.total_taxes.value).toFixed(2),
        total_taxable: Number(data.total_taxable.value).toFixed(2),
      };
      itemSold.push(item);
    });
    // console.log('First call', itemSold.length)
    searchParameters = {
      scroll: "1m",
      scroll_id: scrollID,
    };

    // return;
    // console.log('loop finalAPIMessage',finalAPIMessage)
    for (let index = 1; index < total / 2000; index++) {
      let addMessage = firebase.functions().httpsCallable("scrollData");
      let result = await addMessage({
        text: searchParameters,
        scrollID: scrollID,
      });
      // console.log('result', result)
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          productID: data.key.productID,
          store: data.key.store,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };
        itemSold.push(item);
      });
    }

    // console.log('itemSold.length', itemSold.length);
    return itemSold;
  };
}

export function getProfitandLossReport(
  registrationID,
  searchStoreName,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand,
  searchProductCustomField1,
  searchProductCustomField2,
  searchProductCustomField3,
  searchProductCustomField4,
  searchProductCustomField5,
  searchProductCustomField6
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: {
                value: registrationID,
              },
            },
          },
          {
            term: {
              SalesImpacted: {
                value: true,
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      products: {
        terms: {
          field: "ProductID.keyword",
          include: {
            partition: 0,
            num_partitions: 2,
          },
          size: 10000,
        },
        aggs: {
          stores: {
            terms: {
              field: "StoreName.keyword",
              size: 10000,
            },
            aggs: {
              total_qty: { sum: { field: "Qty" } },
              total_discount: { sum: { field: "DiscountAmount" } },
              total_taxable: { sum: { field: "TaxableAmount" } },
              total_taxes: { sum: { field: "TotalTax" } },
              total_sales: { sum: { field: "Amount" } },
            },
          },
        },
      },
    },
  };

  for (let i = 0; i < searchStoreName.length; i++) {
    const store = searchStoreName[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }
  return async (dispatch, getState) => {
    if (
      getState().registration.registration.hasOwnProperty(
        "ReportingStartTime"
      ) &&
      getState().registration.registration.hasOwnProperty("ReportingEndTime")
    ) {
      let transactionToDate = new Date(searchTransactionToDate).setHours(
        23,
        59
      );
      let transactionFromDate = new Date(searchTransactionFromDate).setHours(
        0,
        0,
        0,
        0
      );

      transactionFromDate = new Date(transactionFromDate).setHours(
        new Date(transactionFromDate).getHours() +
          getState().registration.registration.ReportingStartTime
      );
      transactionToDate = new Date(transactionToDate).setHours(
        new Date(transactionToDate).getHours() +
          getState().registration.registration.ReportingEndTime
      );

      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: transactionToDate,
            gte: transactionFromDate,
          },
        },
      });
    } else {
      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: searchTransactionToDate,
            gte: searchTransactionFromDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }
    // console.log("Profit&Loss 1 Query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    let result = await addMessage({ text: searchParameters });
    let itemSold = [];
    let sanitizedMessage = result.data.text;
    // console.log('1 partition count', sanitizedMessage.aggregations.products.buckets.length)
    sanitizedMessage.aggregations.products.buckets.map((data, index) => {
      let productID = data.key;
      let storesData = data.stores.buckets;
      storesData.map((storeData) => {
        let item = {
          productID: productID,
          store: storeData.key,
          total_quantity: Number(storeData.total_qty.value).toFixed(2),
          total_sales: Number(storeData.total_sales.value).toFixed(2),
          total_discount: Number(storeData.total_discount.value).toFixed(2),
          total_taxes: Number(storeData.total_taxes.value).toFixed(2),
          total_taxable: Number(storeData.total_taxable.value).toFixed(2),
        };
        itemSold.push(item);
      });
    });

    //get data from second partition
    searchParameters.aggs.products.terms.include.partition = 1;
    // console.log("Profit&Loss 2", JSON.stringify(searchParameters));
    addMessage = firebase.functions().httpsCallable("getDenomSales");
    result = await addMessage({ text: searchParameters });
    sanitizedMessage = result.data.text;
    // console.log('2 partition count', sanitizedMessage.aggregations.products.buckets.length)
    sanitizedMessage.aggregations.products.buckets.map((data, index) => {
      let productID = data.key;
      let storesData = data.stores.buckets;
      storesData.map((storeData) => {
        let item = {
          productID: productID,
          store: storeData.key,
          total_quantity: Number(storeData.total_qty.value).toFixed(2),
          total_sales: Number(storeData.total_sales.value).toFixed(2),
          total_discount: Number(storeData.total_discount.value).toFixed(2),
          total_taxes: Number(storeData.total_taxes.value).toFixed(2),
          total_taxable: Number(storeData.total_taxable.value).toFixed(2),
        };
        itemSold.push(item);
      });
    });

    // console.log('itemSold.length', itemSold.length);
    return itemSold;
  };
}

export function getDashboardHeaders(
  registrationID,
  searchStores,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "TransactionType.SalesImpactChecked": true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      total_credit_sales: {
        sum: {
          field: "BalanceAmount",
        },
      },
      total_qty: {
        sum: {
          field: "BillQty",
        },
      },
      total_sales: {
        sum: {
          field: "BillAmount",
        },
      },
      total_paid_sales: {
        sum: {
          field: "BillPaidAmount",
        },
      },
      total_transactions: {
        cardinality: {
          field: "_id",
        },
      },
      total_customers: {
        cardinality: {
          field: "Customer.key.keyword",
        },
      },
    },
  };

  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  // console.log("getDashboardHeaders", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      const dashBoardHeader = {
        total_transactions: Number(
          sanitizedMessage.aggregations.total_transactions.value
        ).toFixed(2),
        total_qty: Number(
          sanitizedMessage.aggregations.total_qty.value
        ).toFixed(2),
        total_customers: Number(
          sanitizedMessage.aggregations.total_customers.value
        ).toFixed(2),
        total_sales: Number(
          sanitizedMessage.aggregations.total_sales.value
        ).toFixed(2),
        total_paid_sales: Number(
          sanitizedMessage.aggregations.total_paid_sales.value
        ).toFixed(2),
        total_credit_sales: Number(
          sanitizedMessage.aggregations.total_credit_sales.value
        ).toFixed(2),
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: dashBoardHeader,
      };
    });
  };
}

export function getCategoryWiseSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size = 10,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_category: {
        terms: {
          field: "Category.keyword",
          size: size,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  if (sortBy === "sales") {
    searchParameters.aggs.group_by_category.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.group_by_category.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }

  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    //console.log("getCategoryWiseSales", JSON.stringify(searchParameters));
    return addMessage({ text: searchParameters }).then(function(result) {
      const categories = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_category.buckets.map(
        (data, index) => {
          let category = {
            name: data.key,
            total_qty: Number(data.total_qty.value).toFixed(2),
            total_sales: Number(data.total_sales.value).toFixed(2),
          };
          categories.push(category);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: categories,
      };
    });
  };
}

export function getProductWiseSales(
  registrationID,
  searchStores,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  size = 10,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_product: {
        terms: {
          field: "ProductName.keyword",
          size: size,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (sortBy === "amount") {
    searchParameters.aggs.group_by_product.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.group_by_product.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { TransactionTypeID: searchTransactionType.trim().toLowerCase() },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_product.buckets.map(
        (data, index) => {
          let product = {
            name: data.key,
            total_sales: Number(data.total_sales.value).toFixed(2),
            total_qty: Number(data.total_qty.value).toFixed(2),
          };
          products.push(product);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

export function getTopProductType(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  type,
  size,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              ProductType: type,
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: size,
          sources: [
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (sortBy === "amount") {
    searchParameters.aggs.my_buckets.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.my_buckets.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }
  // console.log("getTopProductType", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const goods = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let good = {
          name: data.key.product,
          total_qty: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
        };
        goods.push(good);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: goods,
      };
    });
  };
}

export function getEmployeeWiseSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size = 10,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_employee: {
        terms: {
          field: "EmployeeName.keyword",
          size: size,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (sortBy === "amount") {
    searchParameters.aggs.group_by_employee.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.group_by_employee.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }

  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const employees = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_employee.buckets.map(
        (data, index) => {
          let employee = {
            name: data.key,
            total_qty: Number(data.total_qty.value).toFixed(2),
            total_sales: Number(
              data.total_sales.value - data.total_taxes.value
            ).toFixed(2),
          };
          employees.push(employee);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: employees,
      };
    });
  };
}

export function getPaymentTypeWiseSales(
  registrationID,
  searchStore,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            match: {
              StoreID: searchStore,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
      },
    },
    aggs: {
      group_by_payment_type: {
        terms: {
          field: "PaymentMode.keyword",
        },
        aggs: {
          total_amount: {
            sum: {
              field: "PaymentAmount",
            },
          },
        },
      },
    },
  };
  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "CashRegisterName.keyword":
          "@" + searchCashRegister.trim().toLowerCase() + ".*",
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      const paymentTypes = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_payment_type.buckets.map(
        (data, index) => {
          let paymentType = {
            name: data.key,
            total_amount: data.total_amount.value,
          };
          paymentTypes.push(paymentType);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: paymentTypes,
      };
    });
  };
}

export function getSalesTransaction(
  registrationID,
  searchStore,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "StoreID.keyword": searchStore,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionFromDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
      },
    },
    aggs: {
      total_sales: {
        sum: {
          field: "Amount",
        },
      },
      total_discount: {
        sum: {
          field: "DiscountAmount",
        },
      },
      total_transactions: {
        cardinality: {
          field: "TransactionID",
        },
      },
      total_qty: {
        sum: {
          field: "Qty",
        },
      },
    },
  };
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      match: {
        StoreID: getState().user.store.key,
      },
    });
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      const salesTransaction = {
        total_transactions:
          sanitizedMessage.aggregations.total_transactions.value,
        total_amount: sanitizedMessage.aggregations.total_sales.value,
        total_qty: sanitizedMessage.aggregations.total_qty.value,
        total_discount_amount:
          sanitizedMessage.aggregations.total_discount.value,
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: salesTransaction,
      };
    });
  };
}

export function getPaymentWiseTransaction(
  registrationID,
  searchStore,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "StoreID.keyword": searchStore,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionFromDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10,
          sources: [
            {
              paymentMode: {
                terms: {
                  field: "PaymentMode.keyword",
                },
              },
            },
            {
              transactionType: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_amount: {
            sum: {
              field: "PaymentAmount",
            },
          },
        },
      },
    },
  };
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let payments = [];
      sanitizedMessage.aggregations.my_buckets.buckets.map((bucket) => {
        const payment = {
          paymentMode: bucket.key.paymentMode,
          transactionType: bucket.key.transactionType,
          total_amount: bucket.total_amount.value,
        };
        payments.push(payment);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: payments,
      };
    });
  };
}

export function getWalletWiseTransaction(
  registrationID,
  searchStore,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "StoreID.keyword": searchStore,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionFromDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            term: {
              "ProductType.keyword": "Wallet Recharge",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
      },
    },
    aggs: {
      total_sales: {
        sum: {
          field: "Amount",
        },
      },
      total_transactions: {
        cardinality: {
          field: "TransactionID",
        },
      },
    },
  };
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      const wallet = {
        total_transactions:
          sanitizedMessage.aggregations.total_transactions.value,
        total_sales: sanitizedMessage.aggregations.total_sales.value,
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: wallet,
      };
    });
  };
}

export function getPurchaseDashboard(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "TransactionType.UpdateStockInwardOrOutward": {
                value: "inward",
              },
            },
          },
          {
            exists: {
              field: "Vendor",
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      stores: {
        terms: {
          field: "Store.key.keyword",
          size: 100,
        },
        aggs: {
          total_purchase: {
            sum: {
              field: "BillAmount",
            },
          },
          total_qty: {
            sum: {
              field: "BillQty",
            },
          },
          total_transactions: {
            cardinality: {
              field: "_id",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_purchase: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }

  return (dispatch) => {
    // console.log("getPurchaseDashboard", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      // console.log("sanitizedMessage", sanitizedMessage);
      const purchaseTransactions = sanitizedMessage.aggregations.stores.buckets;
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: purchaseTransactions,
      };
    });
  };
}

export function getSalesDashboard(
  registrationID,
  searchStores,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "TransactionType.SalesImpactChecked": true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      stores: {
        terms: {
          field: "Store.key.keyword",
          size: 100,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "BillPaidAmount",
            },
          },
          total_qty: {
            sum: {
              field: "BillQty",
            },
          },
          total_transactions: {
            cardinality: {
              field: "_id",
            },
          },
          total_customers: {
            cardinality: {
              field: "Customer.key.keyword",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "TransactionType.TransactionType":
          "@" + searchTransactionType.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "CashRegister.CashRegisterName.keyword":
          "@" + searchCashRegister.trim().toLowerCase() + ".*",
      },
    });
  }
  return (dispatch) => {
    // console.log("searchQuery", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      const salesTransactions = sanitizedMessage.aggregations.stores.buckets;
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: salesTransactions,
      };
    });
  };
}

export function getEmployeeDashboard(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size,
  searchTransactionType
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      group_by_employee: {
        terms: {
          field: "EmployeeName.keyword",
          size: 10000,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { TransactionTypeID: searchTransactionType.trim().toLowerCase() },
    });
  }
  // console.log("Top Performing - Employees", JSON.stringify(searchParameters));

  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const employees = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_employee.buckets.map(
        (data, index) => {
          let employee = {
            name: data.key,
            total_sales: Number(
              Number(data.total_sales.value) - Number(data.total_taxes.value)
            ).toFixed(2),
          };
          employees.push(employee);
        }
      );

      employees.splice(Number(size));

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: employees,
      };
    });
  };
}

// export function getEmployeeDashboard(registrationID, searchStores, searchTransactionFromDate,
//   searchTransactionToDate, size, searchTransactionType) {
//   let searchParameters =
//   {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "term": {
//               "RegistrationID": registrationID
//             }
//           },
//           {
//             "range": {
//               "TransactionDate": {
//                 "lte": searchTransactionToDate,
//                 "gte": searchTransactionFromDate,
//                 "format": "yyyy-MM-dd"
//               }
//             }
//           }
//         ], "must_not": [
//           { "exists": { "field": "CancellationReason" } }
//         ],"should":[],"minimum_should_match": 1,
//         "filter": {
//           "bool": {
//             "must": [
//             ]
//           }
//         }
//       }
//     },
//     "aggs": {
//       "group_by_employee": {
//         "terms": {
//           "field": "EmployeeName.keyword"
//         },
//         "aggs": {
//           "total_sales": {
//             "sum": {
//               "field": "Amount"
//             }
//           },
//           "sales_bucket_sort": {
//             "bucket_sort": {
//               "sort": [
//                 {
//                   "total_sales": {
//                     "order": "desc"
//                   }
//                 }
//               ]
//             }
//           },
//           "total_taxes": {
//             "sum": {
//               "field": "TotalTax"
//             }
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (size.trim() != "") {
//     searchParameters.aggs.group_by_employee.terms.size = size
//   }
//   else {
//     searchParameters.aggs.group_by_employee.terms.size = 10000
//   }
//   if (searchTransactionType.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionTypeID": searchTransactionType.trim().toLowerCase() } })
//   }
//   return (dispatch, getState) => {
//     let addMessage = firebase.functions().httpsCallable('getDenomSales');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const employees = [];
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.aggregations.group_by_employee.buckets.map((data, index) => {
//         let employee = {
//           name: data.key,
//           total_sales: Number(Number(data.total_sales.value)- Number(data.total_taxes.value)).toFixed(2),
//         }
//         employees.push(employee);
//       });
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: employees }
//     });
//   }
// };

export function getEmployeeProductTypeWise(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchTransactionType
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      storeid: {
        terms: {
          field: "StoreID.keyword",
          size: 10000,
        },
        aggs: {
          employeeid: {
            terms: {
              field: "EmployeeID.keyword",
              size: 10000,
            },
            aggs: {
              employee: {
                terms: {
                  field: "EmployeeName.keyword",
                },
                aggs: {
                  total_amount: {
                    sum: {
                      field: "Amount",
                    },
                  },
                  total_transactions: {
                    cardinality: {
                      field: "TransactionID",
                    },
                  },
                  sales_bucket_sort: {
                    bucket_sort: {
                      sort: [
                        {
                          total_amount: {
                            order: "desc",
                          },
                        },
                      ],
                    },
                  },
                  total_taxes: {
                    sum: {
                      field: "TotalTax",
                    },
                  },
                  total_discount: {
                    sum: {
                      field: "DiscountAmount",
                    },
                  },
                  product_type: {
                    terms: {
                      field: "ProductType.keyword",
                    },
                    aggs: {
                      total_amount: {
                        sum: {
                          field: "Amount",
                        },
                      },
                      total_taxes: {
                        sum: {
                          field: "TotalTax",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch, getState) => {
    if (searchTransactionType.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: {
          TransactionTypeID: searchTransactionType.trim().toLowerCase(),
        },
      });
    }

    console.log("getEmployeeProductTypeWise", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const employees = [];
      let sanitizedMessage = result.data.text;

      let storeid = sanitizedMessage.aggregations.storeid;
      storeid.buckets.map((data1) => {
        let empid = data1.employeeid;

        empid.buckets.map((data) => {
          let employeeID = data.key;

          data.employee.buckets.map((data, index) => {
            let employee = {
              employeeID: employeeID,
              storeID: data1.key,
              name: data.key,
              total_transactions: data.total_transactions.value,
              total_sales: Number(
                Number(data.total_amount.value) - Number(data.total_taxes.value)
              ).toFixed(2),
              total_gross_sales: Number(data.total_amount.value).toFixed(2),
              // total_gross_sales: Number(Number(data.total_amount.value) + Number(data.total_discount.value)).toFixed(2),
              product_types: [],
            };

            data.product_type.buckets.map((data, index) => {
              let productType = {};
              productType.name =
                data.key === "Goods for sale" ? "Products" : data.key;
              productType.total_sales = Number(
                Number(data.total_amount.value) - Number(data.total_taxes.value)
              ).toFixed(2);
              employee.product_types.push(productType);
            });
            employees.push(employee);
          });
        });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: employees,
      };
    });
  };
}

export function getEmployeeTransactionTypeProductTypeWise(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchTransactionTypes
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      stores: {
        terms: {
          field: "StoreID.keyword",
          size: 10000,
        },
        aggs: {
          employees: {
            terms: {
              field: "EmployeeID.keyword",
              size: 10000,
            },
            aggs: {
              transactiontypes: {
                terms: {
                  field: "TransactionTypeID.keyword",
                },
                aggs: {
                  total_amount: {
                    sum: {
                      field: "Amount",
                    },
                  },
                  total_transactions: {
                    cardinality: {
                      field: "TransactionID",
                    },
                  },
                  sales_bucket_sort: {
                    bucket_sort: {
                      sort: [
                        {
                          total_amount: {
                            order: "desc",
                          },
                        },
                      ],
                    },
                  },
                  total_taxes: {
                    sum: {
                      field: "TotalTax",
                    },
                  },
                  total_discount: {
                    sum: {
                      field: "DiscountAmount",
                    },
                  },
                  producttypes: {
                    terms: {
                      field: "ProductType.keyword",
                    },
                    aggs: {
                      total_amount: {
                        sum: {
                          field: "Amount",
                        },
                      },
                      total_taxes: {
                        sum: {
                          field: "TotalTax",
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch, getState) => {
    if (searchTransactionTypes && searchTransactionTypes.length > 0) {
      searchTransactionTypes.map((s) => {
        searchParameters.query.bool.should.push({
          match: { TransactionTypeID: s.key },
        });
      });
    }

    console.log(
      "getEmployeeTransactionTypeProductTypeWise",
      JSON.stringify(searchParameters)
    );

    let addMessage = firebase.functions().httpsCallable("getDenomSales");

    return addMessage({ text: searchParameters }).then(function(result) {
      const employees = [];

      let sanitizedMessage = result.data.text;

      let stores = sanitizedMessage.aggregations.stores;

      stores.buckets.map((store) => {
        let employeesData = store.employees;

        employeesData.buckets.map((e) => {
          let employee = {
            EmployeeID: e.key,
            EmployeeName: "",
            StoreID: store.key,
            StoreName: "",
            TransactionTypes: [],
          };

          searchTransactionTypes.map((t) => {
            let transactionCount = 0;
            let servicesDoneAmout = 0;
            let productSoldAmount = 0;
            let walletSoldAmount = 0;
            let packageSoldAmount = 0;
            let totalSalesAmount = 0;
            let totalApplicableSalesCommissionAount = 0;

            if (
              e.transactiontypes.buckets.filter((f) => f.key === t.key).length >
              0
            ) {
              let transactionTypeData = e.transactiontypes.buckets.filter(
                (f) => f.key === t.key
              )[0];

              transactionCount = transactionTypeData.total_transactions.value;

              transactionTypeData.producttypes.buckets.map((pt) => {
                if (pt.key.toLowerCase() === "services") {
                  servicesDoneAmout = Number(
                    Number(pt.total_amount.value) - Number(pt.total_taxes.value)
                  ).toFixed(2);
                } else if (pt.key.toLowerCase() === "goods for sale") {
                  productSoldAmount = Number(
                    Number(pt.total_amount.value) - Number(pt.total_taxes.value)
                  ).toFixed(2);
                } else if (pt.key.toLowerCase() === "wallet recharge") {
                  walletSoldAmount = Number(
                    Number(pt.total_amount.value) - Number(pt.total_taxes.value)
                  ).toFixed(2);
                } else if (pt.key.toLowerCase() === "package") {
                  packageSoldAmount = Number(
                    Number(pt.total_amount.value) - Number(pt.total_taxes.value)
                  ).toFixed(2);
                }
              });

              totalSalesAmount = Number(
                Number(servicesDoneAmout) +
                  Number(productSoldAmount) +
                  Number(walletSoldAmount) +
                  Number(packageSoldAmount)
              ).toFixed(2);
              totalApplicableSalesCommissionAount = (
                (Number(totalSalesAmount) * Number(t.Percentage)) /
                100
              ).toFixed(0);
            }

            employee.TransactionTypes.push({
              TransactionType: t.TransactionType,
              TransactionTypeID: t.key,
              TransactionCount: transactionCount,
              ServicesDoneAmount: servicesDoneAmout,
              ProductSoldAmount: productSoldAmount,
              WalletSoldAmount: walletSoldAmount,
              PackageSoldAmount: packageSoldAmount,
              TotalSalesAmount: totalSalesAmount,
              TotalApplicableSalesCommissionAount: totalApplicableSalesCommissionAount,
            });
          });
          employees.push(employee);
        });
      });

      console.log("employees", employees);
      return { searchResult: employees };
    });
  };
}

export function getCustomerReferenceReport(
  from,
  size,
  registrationID,
  storeID,
  month,
  dobFrom,
  dobTo
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            { match: { StoreID: storeID } },
            { match: { "DOBNew.Month": month } },
          ],
        },
      },
    };
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getProductTypeWiseSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size = 10,
  sortBy = "amount"
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_product_types: {
        terms: {
          field: "ProductType.keyword",
          size: size,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (sortBy === "amount") {
    searchParameters.aggs.group_by_product_types.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_sales: {
          order: "desc",
        },
      }
    );
  } else {
    searchParameters.aggs.group_by_product_types.aggs.sales_bucket_sort.bucket_sort.sort.push(
      {
        total_qty: {
          order: "desc",
        },
      }
    );
  }
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const productTypes = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_product_types.buckets.map(
        (data, index) => {
          let productType = {
            name: data.key,
            total_qty: Number(data.total_qty.value).toFixed(2),
            total_sales: Number(data.total_sales.value).toFixed(2),
          };
          productTypes.push(productType);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: productTypes,
      };
    });
  };
}

export function getExpensesforSalesDashboard(
  registrationID,
  searchStores,
  searchCashRegister,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              "RegistrationID.keyword": registrationID,
            },
          },
          {
            range: {
              ExpenseDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            match: {
              Status: "cancelled",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      total_expenses: {
        sum: {
          field: "Amount",
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      match: {
        StoreID: store.key,
      },
    });
  }
  // console.log("searchParameters",JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      const expenseHeader = {
        total_expenses: Number(
          sanitizedMessage.aggregations.total_expenses.value
        ).toFixed(2),
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenseHeader,
      };
    });
  };
}

export function getTotalPaymentReceived(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            match_phrase: {
              TransactionType: "Receive Payment",
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      total_received: {
        sum: {
          field: "PaymentAmount",
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let total_received = Number(
        sanitizedMessage.aggregations.total_received.value
      ).toFixed(2);
      return { total_received: total_received };
    });
  };
}

export function getExpenseList(
  RegistrationID,
  StoreID,
  from,
  size,
  option,
  searchParty,
  searchExpenseDateFrom,
  searchExpenseDateTo,
  searchExpenseCategory,
  searchExpenseNumber,
  searchReferenceNumber
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          { term: { "RegistrationID.keyword": RegistrationID } },
          { term: { "StoreID.keyword": StoreID } },
          {
            range: {
              ExpenseDate: {
                lte: searchExpenseDateTo,
                gte: searchExpenseDateFrom,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };

  if (option != "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: { Status: option.trim().toLowerCase() },
    });
  }
  if (searchParty.trim() !== "") {
    // searchParameters.query.bool.should.push({ "regexp": { "Party.PartyFirstName": "@" + searchParty.trim().toLowerCase() + ".*" } })
    // searchParameters.query.bool.should.push({ "regexp": { "Party.PartyLastName": "@" + searchParty.trim().toLowerCase() + ".*" } })

    searchParameters.query.bool.should.push({
      match_phrase: { "Party.PartyName": searchParty.trim().toLowerCase() },
    });
    //searchParameters.query.bool.should.push({ "match": { "Party.PartyLastName": searchParty.trim().toLowerCase() } })
  } else {
    searchParameters.query.bool.should.push({
      regexp: { "Party.PartyName": "@.*" },
    });
  }
  if (searchExpenseCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ExpenseCategory: searchExpenseCategory },
    });
  }
  if (searchExpenseNumber.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ExpenseNo: searchExpenseNumber },
    });
  }
  if (searchReferenceNumber.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { RefNo: searchReferenceNumber },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      const expenses = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        expenses.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenses,
      };
    });
  };
}

// export function getExpenseCategoryList(RegistrationID, StoreID, searchExpenseDateFrom, searchExpenseDateTo, searchExpenseCategory) {
//   let searchParameters = {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "term": {
//               "RegistrationID.keyword": RegistrationID
//             }
//           },
//           {
//             "term": {
//               "StoreID.keyword": StoreID
//             }
//           },
//           {
//             "range": {
//               "ExpenseDate": {
//                 "lte": searchExpenseDateTo,
//                 "gte": searchExpenseDateFrom,
//                 "format": "yyyy-MM-dd"
//               }
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "term": {
//               "Status.keyword": "Cancelled"
//             }
//           }
//         ],
//         "filter": {
//           "bool": {
//             "must": [

//             ]
//           }
//         }
//       }
//     },
//     "aggs": {
//       "expense_category": {
//         "terms": {
//           "field": "ExpenseCategory.keyword",
//           "size": 100,
//           "order": {
//             "total_sales": "desc"
//           }
//         },
//         "aggs": {
//           "total_sales": {
//             "sum": {
//               "field": "Amount"
//             }
//           }
//         }
//       }
//     }
//   }
//   if (searchExpenseCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "ExpenseCategory": searchExpenseCategory } })
//   }
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getExpenses');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const expenses = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.aggregations.expense_category.buckets.map((data, index) => {
//         expenses.push({ key: data.key, total_sales: data.total_sales.value })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: expenses }
//     });
//   }
// }

// export function getExpenseByParty(RegistrationID, StoreID, searchExpenseDateFrom, searchExpenseDateTo, searchParty) {
//   let searchParameters = {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "term": {
//               "RegistrationID.keyword": RegistrationID
//             }
//           },
//           {
//             "match": {
//               "StoreID": StoreID
//             }
//           },
//           {
//             "range": {
//               "ExpenseDate": {
//                 "gte": searchExpenseDateFrom,
//                 "lte": searchExpenseDateTo,
//                 "format": "yyyy-MM-dd"
//               }
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "term": {
//               "Status.keyword": "Cancelled"
//             }
//           }
//         ],
//         "filter": {
//           "bool": {
//             "must": [

//             ]
//           }
//         }
//       }
//     },
//     "aggs": {
//       "party": {
//         "terms": {
//           "field": "Party.PartyName.keyword",
//           "size": 100
//         },
//         "aggs": {
//           "status": {
//             "terms": {
//               "field": "Status.keyword"
//             },
//             "aggs": {
//               "total_amount": {
//                 "sum": {
//                   "field": "Amount"
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   if (searchParty.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Party.PartyName": searchParty.trim() } })
//   }
//   // console.log("Expense By Party",JSON.stringify(searchParameters))
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getExpenses');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const expenses = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.aggregations.party.buckets.map((data, index) => {
//         let expense = { key: data.key, PaidAmount: 0, PendingAmount: 0 }

//         data.status.buckets.map((data1) => {
//           if (data1.key === "Paid") {
//             expense.PaidAmount = data1.total_amount.value
//           }
//           if (data1.key === "Pending") {
//             expense.PendingAmount = data1.total_amount.value
//           }
//         })
//         expenses.push(expense);

//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: expenses }
//     });
//   }
// };

export function getExpenseCategoryList(
  RegistrationID,
  StoreID,
  searchExpenseDateFrom,
  searchExpenseDateTo,
  searchExpenseCategory
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              "RegistrationID.keyword": RegistrationID,
            },
          },
          {
            term: {
              "StoreID.keyword": StoreID,
            },
          },
          {
            range: {
              ExpenseDate: {
                lte: searchExpenseDateTo,
                gte: searchExpenseDateFrom,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            term: {
              "Status.keyword": "Cancelled",
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      expense_category: {
        terms: {
          field: "ExpenseCategory.keyword",
          size: 100,
          order: {
            total_sales: "desc",
          },
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };
  if (searchExpenseCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: { ExpenseCategory: searchExpenseCategory },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      const expenses = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.expense_category.buckets.map(
        (data, index) => {
          expenses.push({
            key: data.key,
            total_sales: Number(data.total_sales.value).toFixed(2),
          });
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenses,
      };
    });
  };
}

export function getExpenseByParty(
  RegistrationID,
  StoreID,
  searchExpenseDateFrom,
  searchExpenseDateTo,
  searchParty
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              "RegistrationID.keyword": RegistrationID,
            },
          },
          {
            match: {
              StoreID: StoreID,
            },
          },
          {
            range: {
              ExpenseDate: {
                gte: searchExpenseDateFrom,
                lte: searchExpenseDateTo,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            term: {
              "Status.keyword": "Cancelled",
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      party: {
        terms: {
          field: "Party.PartyName.keyword",
          size: 100,
        },
        aggs: {
          status: {
            terms: {
              field: "Status.keyword",
            },
            aggs: {
              total_amount: {
                sum: {
                  field: "Amount",
                },
              },
            },
          },
        },
      },
    },
  };
  if (searchParty.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { "Party.PartyName": searchParty.trim() },
    });
  }
  // console.log("Expense By Party",JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      const expenses = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.party.buckets.map((data, index) => {
        let expense = { key: data.key, PaidAmount: 0, PendingAmount: 0 };

        data.status.buckets.map((data1) => {
          if (data1.key === "Paid") {
            expense.PaidAmount = Number(data1.total_amount.value).toFixed(2);
          }
          if (data1.key === "Pending") {
            expense.PendingAmount = Number(data1.total_amount.value).toFixed(2);
          }
        });
        expenses.push(expense);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenses,
      };
    });
  };
}

export function getExpenseForClosing(
  registrationID,
  searchStore,
  searchCashRegister,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            match: {
              StoreID: searchStore,
            },
          },
          {
            range: {
              ExpenseDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match: {
              PaymentType: "Cash From Counter",
            },
          },
        ],
        must_not: [
          {
            term: {
              "Status.keyword": "Cancelled",
            },
          },
        ],
      },
    },
    aggs: {
      total_expenses: {
        sum: {
          field: "Amount",
        },
      },
    },
  };

  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        "CashRegister.CashRegisterName.keyword":
          "@" + searchCashRegister.trim().toLowerCase() + ".*",
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let expenseAmount = {
        total_expenses: sanitizedMessage.aggregations.total_expenses.value,
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenseAmount,
      };
    });
  };
}

// export function getItemInStockData(searchStores, from, size, searchStoreID, searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand) {

//   let searchParameters =
//   {
//     "from": from,
//     "size": size,
//     "query": {
//       "bool": {
//         "must": [],
//         "should":[],"minimum_should_match":1,
//         "filter": {
//           "bool": {
//             "must": []
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Location": searchStoreLocation.trim().toLowerCase() } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductSKU": searchProductSKU.trim().toLowerCase() } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName.trim().toLowerCase() } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
//   }
//   // console.log("Parameters", JSON.stringify(searchParameters));
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getInventoryInStock');
//     return addMessage({ text: searchParameters }).then(function (result) {

//       const products = []
//       let sanitizedMessage = result.data.text;

//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ ...data._source })
//       })

//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products }
//     });
//   }
// };

// export function getItemInStockData(searchStores, from, size, searchStoreID, searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand) {

//   let searchParameters =
//   {
//     "from": from,
//     "size": size,
//     "query": {
//       "bool": {
//         "must": [],
//         "should":[],"minimum_should_match":1,
//         "filter": {
//           "bool": {
//             "must": []
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Location": "@" + searchStoreLocation.trim().toLowerCase() + ".*" } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Category": "@" + searchCategory.trim().toLowerCase() + ".*" } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "SubCategory": "@" + searchSubCategory.trim().toLowerCase() + ".*" } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ProductSKU": "@" + searchProductSKU.trim().toLowerCase() + ".*" } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "ProductName": "@" + searchProductName.trim().toLowerCase() + ".*" } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Brand": "@" + searchBrand.trim().toLowerCase() + ".*" } })
//   }

//   console.log("Parameters", JSON.stringify(searchParameters));
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getInventoryInStock');
//     return addMessage({ text: searchParameters }).then(function (result) {

//       const products = []
//       let sanitizedMessage = result.data.text;

//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ ...data._source })
//       })

//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products }
//     });
//   }
// };

// export function getItemLedger(productSKU, locationID) {
//   return (dispatch) => {
//     return database.collection("InventoryInStockAuditTrail").where("LocationID", "==", locationID).where("ProductSKU", "==", productSKU).orderBy("ActionOn", "desc").get().then((querySnapshot) => {
//       const itemLedgers = []
//       querySnapshot.forEach((doc) => {
//         itemLedgers.push(
//           {
//             ActionOn: doc.data().ActionOn,
//             Brand: doc.data().Brand,
//             ProductSKU: doc.data().ProductSKU,
//             Location: doc.data().Location,
//             Category: doc.data().Category,
//             SubCategory: doc.data().SubCategory,
//             BarCode: doc.data().BarCode,
//             ProductName: doc.data().ProductName,
//             RetailPrice: doc.data().RetailPrice,
//             TransactionType: doc.data().TransactionType,
//             TransactionQty: doc.data().TransactionQty,
//             TransactionNo: doc.data().TransactionNo,
//             Action: doc.data().Action,
//             InStockQty: doc.data().InStockQty
//           })
//       })
//       return itemLedgers
//     })
//   }
// }

export function getItemLedger(productID, locationID) {
  return (dispatch) => {
    return database
      .collection("InventoryInStockAuditTrail")
      .where("LocationID", "==", locationID)
      .where("ProductID", "==", productID)
      .orderBy("ActionOn", "desc")
      .get()
      .then((querySnapshot) => {
        const itemLedgers = [];
        querySnapshot.forEach((doc) => {
          itemLedgers.push({
            ActionOn: doc.data().ActionOn,
            Brand: doc.data().Brand,
            ProductSKU: doc.data().ProductSKU,
            Location: doc.data().Location,
            Category: doc.data().Category,
            SubCategory: doc.data().SubCategory,
            BarCode: doc.data().BarCode,
            ProductName: doc.data().ProductName,
            RetailPrice: doc.data().RetailPrice,
            TransactionType: doc.data().TransactionType,
            TransactionQty: doc.data().TransactionQty,
            TransactionNo: doc.data().TransactionNo,
            Action: doc.data().Action,
            InStockQty: doc.data().InStockQty,
          });
        });
        return itemLedgers;
      });
  };
}

export function getPaymentModeWiseData(
  registrationID,
  searchStore,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              "StoreID.keyword": searchStore,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
    aggs: {
      orders_over_day: {
        date_histogram: {
          field: "TransactionDate",
          interval: "day",
          format: "dd-MMM-yy",
          order: {
            _key: "desc",
          },
        },
        aggs: {
          payment_mode: {
            terms: {
              field: "PaymentMode.keyword",
            },
            aggs: {
              total_sales: {
                sum: {
                  field: "PaymentAmount",
                },
              },
            },
          },
        },
      },
    },
  };
  return (dispatch) => {
    console.log("getPaymentModeWiseData", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let paymentList = [];
      let data = sanitizedMessage.aggregations.orders_over_day.buckets;
      data.map((bucket) => {
        bucket.payment_mode.buckets.map((pay) => {
          const payment = [
            bucket.key_as_string,
            pay.key,
            pay.total_sales.value,
          ];

          paymentList.push(payment);
        });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: paymentList,
      };
    });
  };
}

// export function getDailySales(registrationID, searchStores, searchTransactionToDate, searchTransactionFromDate, searchInterval) {
//   let searchParameters =
//   {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "match": {
//               "RegistrationID": registrationID
//             }
//           },
//           {
//             "match": {
//               "TransactionType.SalesImpactChecked": true
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "exists": {
//               "field": "CancellationReason"
//             }
//           }
//         ], "should": [], "minimum_should_match": 1
//       }
//     },
//     "aggs": {
//       "orders_over": {
//         "date_histogram": {
//           "field": "TransactionDate",
//           "interval": searchInterval,
//           "format": searchInterval === 'month' ? "MMM-yyyy" : "dd-MMM-yyyy",
//           "order": {
//             "_key": "desc"
//           }
//         },
//         "aggs": {
//           "store": {
//             "terms": {
//               "field": "Store.StoreName.keyword",
//               "size": 100
//             },
//             "aggs": {
//               "total_qty": {
//                 "sum": {
//                   "field": "BillQty"
//                 }
//               },
//               "total_discount": {
//                 "sum": {
//                   "field": "BillDiscount"
//                 }
//               },
//               "total_transactions": {
//                 "cardinality": {
//                   "field": "_id"
//                 }
//               },
//               "total_taxamount": {
//                 "sum": {
//                   "field": "BillTaxAmount"
//                 }
//               },
//               "total_sales": {
//                 "sum": {
//                   "field": "BillPaidAmount"
//                 }
//               },
//               "total_balance": {
//                 "sum": {
//                   "field": "BalanceAmount"
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];
//     searchParameters.query.bool.should.push({
//       "match": {
//         "Store.key": store.key
//       }
//     })
//   }
//   return (dispatch, getState) => {

//     if(getState().registration.registration.hasOwnProperty('ReportingStartTime') && getState().registration.registration.hasOwnProperty('ReportingEndTime'))
//     {

//       let transactionToDate = new Date(searchTransactionToDate).setHours(23, 59);
//       let transactionFromDate = new Date(searchTransactionFromDate).setHours(0, 0, 0, 0);

//       transactionFromDate = new Date(transactionFromDate).setHours(new Date(transactionFromDate).getHours() + getState().registration.registration.ReportingStartTime);
//       transactionToDate = new Date(transactionToDate).setHours(new Date(transactionToDate).getHours() + getState().registration.registration.ReportingEndTime);

//       searchParameters.query.bool.must.push({
//         "range": {
//           "TransactionDate": {
//             "lte": transactionToDate,
//             "gte": transactionFromDate
//           }
//         }
//       })
//     }
//     else
//     {
//       searchParameters.query.bool.must.push({
//         "range": {
//           "TransactionDate": {
//             "lte": searchTransactionToDate,
//             "gte": searchTransactionFromDate,
//             "format": "yyyy-MM-dd"
//           }
//         }
//       })
//     }

//     let addMessage = firebase.functions().httpsCallable('getSales');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const dailySales = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.aggregations.orders_over.buckets.map((data, index) => {
//         data.store.buckets.map((data1, index) => {
//           let dailySale = {}
//           if (searchInterval === 'month') {
//             dailySale["Transaction Month"] = data.key_as_string
//           }
//           else {
//             dailySale["Transaction Date"] = data.key_as_string
//           }
//           dailySale["Store"] = data1.key,
//             dailySale["Tax Amount"] = Number(data1.total_taxamount.value).toFixed(2),
//             dailySale["Total Discount"] = Number(data1.total_discount.value).toFixed(2)
//             dailySale["Total Transactions"] = Number(data1.total_transactions.value).toFixed(2)
//             dailySale["Total Qty"] = Number(data1.total_qty.value).toFixed(2)
//             dailySale["Total Balance"] = Number(data1.total_balance.value).toFixed(2)
//             dailySale["Total Sales"] = Number(data1.total_sales.value).toFixed(2)
//           dailySale["Gross Sales"] =Number( Number(data1.total_sales.value) + Number(data1.total_discount.value)).toFixed(2)
//           // if (data1.total_sales.value > 0)
//             dailySales.push(dailySale);
//         })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: dailySales }
//     });
//   }
// };

export function getDailySalesWithoutReceivePayment(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchInterval
) {
  return (dispatch, getState) => {
    //get

    let receivePaymentTransactionTypeKey = "";
    if (
      getState().config.TransactionTypes.filter(
        (f) => f.TransactionType === "Receive Payment"
      ).length > 0
    ) {
      receivePaymentTransactionTypeKey = getState().config.TransactionTypes.filter(
        (f) => f.TransactionType === "Receive Payment"
      )[0].key;
    }
    let searchParameters = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              match: {
                RegistrationID: registrationID,
              },
            },
            {
              match: {
                "TransactionType.SalesImpactChecked": true,
              },
            },
          ],
          must_not: [
            {
              exists: {
                field: "CancellationReason",
              },
            },
            {
              match: {
                "TransactionType.key": receivePaymentTransactionTypeKey,
              },
            },
          ],
          should: [],
          minimum_should_match: 1,
        },
      },
      aggs: {
        orders_over: {
          date_histogram: {
            field: "TransactionDate",
            interval: searchInterval,
            format: searchInterval === "month" ? "MMM-yyyy" : "dd-MMM-yyyy",
            order: {
              _key: "desc",
            },
          },
          aggs: {
            store: {
              terms: {
                field: "Store.StoreName.keyword",
                size: 100,
              },
              aggs: {
                total_qty: {
                  sum: {
                    field: "BillQty",
                  },
                },
                total_discount: {
                  sum: {
                    field: "BillDiscount",
                  },
                },
                total_transactions: {
                  cardinality: {
                    field: "_id",
                  },
                },
                total_taxamount: {
                  sum: {
                    field: "BillTaxAmount",
                  },
                },
                total_paid: {
                  sum: {
                    field: "BillPaidAmount",
                  },
                },
                total_sales: {
                  sum: {
                    field: "BillAmount",
                  },
                },
                total_balance: {
                  sum: {
                    field: "BalanceAmount",
                  },
                },
              },
            },
          },
        },
      },
    };
    for (let i = 0; i < searchStores.length; i++) {
      const store = searchStores[i];
      searchParameters.query.bool.should.push({
        match: {
          "Store.key": store.key,
        },
      });
    }

    if (
      getState().registration.registration.hasOwnProperty(
        "ReportingStartTime"
      ) &&
      getState().registration.registration.hasOwnProperty("ReportingEndTime")
    ) {
      let transactionToDate = new Date(searchTransactionToDate).setHours(
        23,
        59
      );
      let transactionFromDate = new Date(searchTransactionFromDate).setHours(
        0,
        0,
        0,
        0
      );

      transactionFromDate = new Date(transactionFromDate).setHours(
        new Date(transactionFromDate).getHours() +
          getState().registration.registration.ReportingStartTime
      );
      transactionToDate = new Date(transactionToDate).setHours(
        new Date(transactionToDate).getHours() +
          getState().registration.registration.ReportingEndTime
      );

      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: transactionToDate,
            gte: transactionFromDate,
          },
        },
      });
    } else {
      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: searchTransactionToDate,
            gte: searchTransactionFromDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }

    console.log("getDailySales", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const dailySales = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.orders_over.buckets.map((data, index) => {
        data.store.buckets.map((data1, index) => {
          let dailySale = {};
          if (searchInterval === "month") {
            dailySale["Transaction Month"] = data.key_as_string;
          } else {
            dailySale["Transaction Date"] = data.key_as_string;
          }
          dailySale["Store"] = data1.key;
          dailySale["Tax Amount"] = Number(data1.total_taxamount.value).toFixed(
            2
          );
          dailySale["Total Discount"] = Number(
            data1.total_discount.value
          ).toFixed(2);
          dailySale["Total Transactions"] = Number(
            data1.total_transactions.value
          ).toFixed(2);
          dailySale["Total Qty"] = Number(data1.total_qty.value).toFixed(2);
          dailySale["Total Sales"] = Number(data1.total_sales.value).toFixed(2);
          dailySale["Total Credit Sale"] = Number(
            data1.total_balance.value
          ).toFixed(2);
          dailySale["Total Sales Paid (A)"] = Number(
            data1.total_paid.value
          ).toFixed(2);

          // dailySale["Gross Sales"] = Number(Number(data1.total_sales.value) + Number(data1.total_discount.value)).toFixed(2)
          dailySales.push(dailySale);
        });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: dailySales,
      };
    });
  };
}

export function getDailySales(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchInterval
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationID,
            },
          },
          {
            match: {
              "TransactionType.SalesImpactChecked": true,
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
          {
            match: {
              "TransactionType.TransactionType": "Receive Payment",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      orders_over: {
        date_histogram: {
          field: "TransactionDate",
          interval: searchInterval,
          format: searchInterval === "month" ? "MMM-yyyy" : "dd-MMM-yyyy",
          order: {
            _key: "desc",
          },
        },
        aggs: {
          store: {
            terms: {
              field: "Store.StoreName.keyword",
              size: 100,
            },
            aggs: {
              total_qty: {
                sum: {
                  field: "BillQty",
                },
              },
              total_discount: {
                sum: {
                  field: "BillDiscount",
                },
              },
              total_transactions: {
                cardinality: {
                  field: "_id",
                },
              },
              total_taxamount: {
                sum: {
                  field: "BillTaxAmount",
                },
              },
              total_paid: {
                sum: {
                  field: "BillPaidAmount",
                },
              },
              total_sales: {
                sum: {
                  field: "BillAmount",
                },
              },
              total_balance: {
                sum: {
                  field: "BalanceAmount",
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      match: {
        "Store.key": store.key,
      },
    });
  }
  return (dispatch, getState) => {
    if (
      getState().registration.registration.hasOwnProperty(
        "ReportingStartTime"
      ) &&
      getState().registration.registration.hasOwnProperty("ReportingEndTime")
    ) {
      let transactionToDate = new Date(searchTransactionToDate).setHours(
        23,
        59
      );
      let transactionFromDate = new Date(searchTransactionFromDate).setHours(
        0,
        0,
        0,
        0
      );

      transactionFromDate = new Date(transactionFromDate).setHours(
        new Date(transactionFromDate).getHours() +
          getState().registration.registration.ReportingStartTime
      );
      transactionToDate = new Date(transactionToDate).setHours(
        new Date(transactionToDate).getHours() +
          getState().registration.registration.ReportingEndTime
      );

      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: transactionToDate,
            gte: transactionFromDate,
          },
        },
      });
    } else {
      searchParameters.query.bool.must.push({
        range: {
          TransactionDate: {
            lte: searchTransactionToDate,
            gte: searchTransactionFromDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }

    console.log("getDailySales", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const dailySales = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.orders_over.buckets.map((data, index) => {
        data.store.buckets.map((data1, index) => {
          let dailySale = {};
          if (searchInterval === "month") {
            dailySale["Transaction Month"] = data.key_as_string;
          } else {
            dailySale["Transaction Date"] = data.key_as_string;
          }
          dailySale["Store"] = data1.key;
          dailySale["Tax Amount"] = Number(data1.total_taxamount.value).toFixed(
            2
          );
          dailySale["Total Discount"] = Number(
            data1.total_discount.value
          ).toFixed(2);
          dailySale["Total Transactions"] = Number(
            data1.total_transactions.value
          ).toFixed(2);
          dailySale["Total Qty"] = Number(data1.total_qty.value).toFixed(2);
          dailySale["Total Credit Sale"] = Number(
            data1.total_balance.value
          ).toFixed(2);
          dailySale["Total Paid"] = Number(data1.total_paid.value).toFixed(2);
          dailySale["Total Sales"] = Number(data1.total_sales.value).toFixed(2);
          // dailySale["Gross Sales"] = Number(Number(data1.total_sales.value) + Number(data1.total_discount.value)).toFixed(2)
          dailySales.push(dailySale);
        });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: dailySales,
      };
    });
  };
}

export function getPaymentModeWiseDaily(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchInterval
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      orders_over_day: {
        date_histogram: {
          field: "TransactionDate",
          interval: searchInterval,
          format: searchInterval === "month" ? "MMM-yyyy" : "dd-MMM-yyyy",
          order: {
            _key: "desc",
          },
        },
        aggs: {
          store: {
            terms: {
              field: "StoreName.keyword",
              size: 100,
            },
            aggs: {
              payment_mode: {
                terms: {
                  field: "PaymentMode.keyword",
                },
                aggs: {
                  total_sales: {
                    sum: {
                      field: "PaymentAmount",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    // console.log('getPaymentModeWiseDaily',JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let paymentList = [];

      sanitizedMessage.aggregations.orders_over_day.buckets.map(
        (storeBucket, index) => {
          storeBucket.store.buckets.map((paymentBucket, index) => {
            paymentBucket.payment_mode.buckets.map((pay, index) => {
              let payment = {};
              payment["Store"] = paymentBucket.key;
              if (searchInterval === "month") {
                payment["Transaction Month"] = storeBucket.key_as_string;
              } else {
                payment["Transaction Date"] = storeBucket.key_as_string;
              }
              payment["PaymentMode"] = pay.key;
              payment["Payment_total_sales"] =
                pay.total_sales.value === undefined ? 0 : pay.total_sales.value;
              paymentList.push(payment);
            });
          });
        }
      );
      // console.log('',JSON.stringify(paymentList));
      // console.log('',paymentList);

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: paymentList,
      };
    });
  };
}

export function getEmployeeWorksheet(
  employeeID,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchTransactionType
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              "EmployeeID.keyword": employeeID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      sku: {
        terms: {
          field: "SKU.keyword",
          size: 10000,
        },
        aggs: {
          product: {
            terms: {
              field: "ProductName.keyword",
            },
            aggs: {
              total_amount: {
                sum: {
                  field: "Amount",
                },
              },
              total_items: {
                sum: {
                  field: "Qty",
                },
              },
              total_taxes: {
                sum: {
                  field: "TotalTax",
                },
              },
            },
          },
        },
      },
    },
  };
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { TransactionTypeID: searchTransactionType.trim().toLowerCase() },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const productDetails = [];
      let sanitizedMessage = result.data.text;
      let sku = sanitizedMessage.aggregations.sku;

      sku.buckets.map((data, index) => {
        let productDetail = {
          SKU: data.key,
        };
        data.product.buckets.map((data, index) => {
          productDetail.ProductName = data.key;
          productDetail.Amount =
            Number(data.total_amount.value).toFixed(2) -
            Number(data.total_taxes.value).toFixed(2);
          productDetail.Qty = Number(data.total_items.value).toFixed(2);
        });
        productDetails.push(productDetail);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: productDetails,
      };
    });
  };
}

export function getPaymentModeTransactionTypeWiseSales(
  registrationID,
  searchStores,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  salesImpacted
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      group_by_payment_type: {
        terms: {
          field: "PaymentMode.keyword",
        },
        aggs: {
          transaction_type: {
            terms: {
              field: "TransactionType.keyword",
            },
            aggs: {
              total_amount: {
                sum: {
                  field: "PaymentAmount",
                },
              },
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (salesImpacted) {
    searchParameters.query.bool.filter.bool.must.push({
      term: { SalesImpacted: salesImpacted },
    });
  }
  return (dispatch) => {
    // console.log(
    //   "getPaymentModeTransactionTypeWiseSales",
    //   JSON.stringify(searchParameters)
    // );
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      const paymentTypes = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_payment_type.buckets.map(
        (data, index) => {
          let paymentType = {
            name: data.key,
            total_mode_amount: 0,
            transactionTypes: [],
          };
          data.transaction_type.buckets.map((transactionData, index) => {
            let transactionType = {
              name: transactionData.key,
              total_tran_amount: transactionData.total_amount.value,
            };
            paymentType.transactionTypes.push(transactionType);
            paymentType.total_mode_amount += transactionData.total_amount.value;
          });
          paymentTypes.push(paymentType);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: paymentTypes,
      };
    });
  };
}

export function getSpecificPaymentModeSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  paymentMode
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              PaymentMode: paymentMode,
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      group_by_payment_type: {
        terms: {
          field: "PaymentMode.keyword",
        },
        aggs: {
          total_amount: {
            sum: {
              field: "PaymentAmount",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    // console.log(JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getDenomPayments");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let total_sales = 0;
      sanitizedMessage.aggregations.group_by_payment_type.buckets.map(
        (data, index) => {
          total_sales = data.total_amount.value;
        }
      );
      return { total_sales: total_sales };
    });
  };
}

export function getCustomerWalletLedger(
  RegistrationID,
  StoreID,
  from,
  size,
  option,
  searchText,
  searchWalletFrom,
  searchWalletTo
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: RegistrationID } },
            {
              match: {
                StoreID: StoreID,
              },
            },
            {
              range: {
                WalletBalance: {
                  gt: 0,
                },
              },
            },
          ],
          must_not: [],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          ActionOn: {
            order: "desc",
          },
        },
      ],
    };
    if (option === "active") {
      searchParameters.query.bool.must_not.push({
        match: { IsDeleted: "true" },
      });
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({
        match: { IsDeleted: "false" },
      });
    }
    searchParameters.query.bool.should.push({
      regexp: { CustomerDetails: "@" + searchText + ".*" },
    });
    searchParameters.query.bool.should.push({
      match: { CustomerDetails: { query: searchText, operator: "and" } },
    });
    searchParameters.query.bool.should.push({
      regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" },
    });
    searchParameters.query.bool.should.push({
      regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" },
    });

    if (searchWalletFrom !== "" && searchWalletTo !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        range: {
          WalletBalance: {
            lte: searchWalletTo,
            gte: searchWalletFrom,
          },
        },
      });
    } else {
      if (searchWalletFrom !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          range: {
            WalletBalance: {
              gte: searchWalletFrom,
            },
          },
        });
      }
      if (searchWalletTo !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          range: {
            WalletBalance: {
              lte: searchWalletTo,
            },
          },
        });
      }
    }
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      // console.log("customers",customers)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getCustomerOutStandingLedger(
  RegistrationID,
  StoreID,
  from,
  size,
  option,
  searchText,
  searchOutstandingFrom,
  searchOutstandingTo
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: RegistrationID } },
            {
              match: {
                StoreID: StoreID,
              },
            },
            {
              range: {
                OutStandingAmount: {
                  gt: 0,
                },
              },
            },
          ],
          must_not: [],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          ActionOn: {
            order: "desc",
          },
        },
      ],
    };
    if (option === "active") {
      searchParameters.query.bool.must_not.push({
        match: { IsDeleted: "true" },
      });
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({
        match: { IsDeleted: "false" },
      });
    }
    searchParameters.query.bool.should.push({
      regexp: { CustomerDetails: "@" + searchText + ".*" },
    });
    searchParameters.query.bool.should.push({
      match: { CustomerDetails: { query: searchText, operator: "and" } },
    });
    searchParameters.query.bool.should.push({
      regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" },
    });
    searchParameters.query.bool.should.push({
      regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" },
    });

    if (searchOutstandingFrom !== "" && searchOutstandingTo !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        range: {
          OutStandingAmount: {
            gte: searchOutstandingFrom,
            lte: searchOutstandingTo,
          },
        },
      });
    } else {
      if (searchOutstandingFrom !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          range: {
            OutStandingAmount: {
              gte: searchOutstandingFrom,
            },
          },
        });
      }
      if (searchOutstandingTo !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          range: {
            OutStandingAmount: {
              lte: searchOutstandingTo,
            },
          },
        });
      }
    }
    // console.log("OutStanding - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      // console.log("customers",customers)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getOutStandingLedgerByCustomerID(
  customerID,
  from,
  size,
  searchTransactionNo,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    _source: [
      "Store.StoreName",
      "Store.StoreCode",
      "CashRegister.CashRegisterName",
      "CashRegister.CashRegisterCode",
      "Customer.CustomerCode",
      "Customer.FirstName",
      "Customer.LastName",
      "Customer.PhoneNo",
      "Customer.EmailID",
      "TransactionNo",
      "TransactionType.TransactionType",
      "TransactionDate",
      "BillQty",
      "BillTaxAmount",
      "BillDiscount",
      "BillAmount",
      "BillPaidAmount",
      "ReceivedPaymentAmount",
      "BalanceAmount",
      "SalesReturnTransactionNo",
      "Customer.CustomField1",
      "Customer.CustomField2",
      "Customer.CustomField3",
      "Customer.CustomField4",
      "Customer.CustomField5",
      "Customer.CustomField6",
      "CancellationReason",
      "BillChangeDue",
      "Payments",
      "SalesReturnTransactionNo",
      "ParentTransactionKey",
      "ParentTransactionNo",
      "ChildTransactionNo",
      "ReceivedPaymentTransactions",
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              "Customer.key.keyword": customerID,
            },
          },
          {
            match: {
              "TransactionType.SalesImpactChecked": true,
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
          {
            match: {
              BalanceAmount: 0,
            },
          },
        ],
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };

  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchTransactionFromDate !== "" && searchTransactionToDate !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      range: {
        TransactionDate: {
          lte: searchTransactionToDate,
          gte: searchTransactionFromDate,
          format: "yyyy-MM-dd",
        },
      },
    });
  }
  // console.log("searchParam", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const outStandingLedger = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        if (data._source.hasOwnProperty("ReceivedPaymentTransactions")) {
          let receivedPayment = 0;
          for (
            let i = 0;
            i < data._source.ReceivedPaymentTransactions.length;
            i++
          ) {
            let r = data._source.ReceivedPaymentTransactions[i];
            if (r.hasOwnProperty("IsConvert") && r.IsConvert) {
            } else {
              receivedPayment = receivedPayment + Number(r.ReceiveAmount);
            }
          }
          if (receivedPayment > 0) {
            data._source.BillPaidAmount =
              data._source.BillPaidAmount + receivedPayment;
            data._source.BalanceAmount =
              data._source.BalanceAmount - receivedPayment;
          }
        }
        // if (data._source.BalanceAmount > 0) {
        outStandingLedger.push({ key: data._id, ...data._source });
        // }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: outStandingLedger,
      };
    });
  };
}

// export function getOutStandingLedgerByCustomerID(customerID, from, size, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate) {
//   let searchParameters = {
//     "from": from,
//     "size": size,
//     "_source": ["Store.StoreName", "Store.StoreCode", "CashRegister.CashRegisterName", "CashRegister.CashRegisterCode",
//       "Customer.CustomerCode", "Customer.FirstName", "Customer.LastName", "Customer.PhoneNo", "Customer.EmailID", "TransactionNo",
//       "TransactionType.TransactionType", "TransactionDate", "BillQty", "BillTaxAmount",
//       "BillDiscount", "BillAmount", "BillPaidAmount", "ReceivedPaymentAmount", "BalanceAmount",
//       "SalesReturnTransactionNo", "Customer.CustomField1", "Customer.CustomField2",
//       "Customer.CustomField3", "Customer.CustomField4", "Customer.CustomField5",
//       "Customer.CustomField6", "CancellationReason", "BillChangeDue", "Payments",
//       "SalesReturnTransactionNo", "ParentTransactionKey", "ParentTransactionNo", "ChildTransactionNo",
//       "ReceivedPaymentTransactions"],
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "match": {
//               "Customer.key.keyword": customerID
//             }
//           },
//           {
//             "match": {
//               "TransactionType.SalesImpactChecked": true
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "exists": {
//               "field": "CancellationReason"
//             }
//           },
//           {
//             "match": {
//               "BalanceAmount":  0
//             }
//           },
//           {

//           }
//         ],
//         "filter": {
//           "bool": {
//             "must": [

//             ],
//             "should": []
//           }
//         }
//       }
//     }, "sort": [
//       {
//         "TransactionDate": {
//           "order": "desc"
//         }
//       }
//     ]
//   };

//   if (searchTransactionNo.trim() !== "") {
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
//   }
//   if (searchTransactionFromDate !== "" && searchTransactionToDate !== "") {
//     searchParameters.query.bool.filter.bool.must.push({
//       "range": {
//         "TransactionDate": {
//           "lte": searchTransactionToDate,
//           "gte": searchTransactionFromDate,
//           "format": "yyyy-MM-dd"
//         }
//       }
//     })
//   }
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getSales');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const outStandingLedger = [];
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         if (data._source.hasOwnProperty('ReceivedPaymentTransactions')) {
//           let receivedPayment = 0
//           for (let i = 0; i < data._source.ReceivedPaymentTransactions.length; i++) {
//             let r = data._source.ReceivedPaymentTransactions[i]
//             if (r.hasOwnProperty('IsConvert') && r.IsConvert) {
//             }
//             else {
//               receivedPayment = receivedPayment + Number(r.ReceiveAmount)
//             }
//           }
//           if (receivedPayment > 0) {
//               data._source.BillPaidAmount = data._source.BillPaidAmount + receivedPayment
//               data._source.BalanceAmount = data._source.BalanceAmount - receivedPayment
//           }
//         }
//         // if (data._source.BalanceAmount > 0) {
//           outStandingLedger.push({ key: data._id, ...data._source });
//         // }
//       });
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: outStandingLedger }
//     });
//   }
// };

// export function getOutStandingLedgerByCustomerID(customerID, from, size, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate) {
//   let searchParameters = {
//     "from": from,
//     "size": size,
//     "_source": ["Store.StoreName", "Store.StoreCode", "CashRegister.CashRegisterName", "CashRegister.CashRegisterCode",
//       "Customer.CustomerCode", "Customer.FirstName", "Customer.LastName", "Customer.PhoneNo", "Customer.EmailID", "TransactionNo",
//       "TransactionType.TransactionType", "TransactionDate", "BillQty", "BillTaxAmount",
//       "BillDiscount", "BillAmount", "BillPaidAmount", "ReceivedPaymentAmount", "BalanceAmount",
//       "SalesReturnTransactionNo", "Customer.CustomField1", "Customer.CustomField2",
//       "Customer.CustomField3", "Customer.CustomField4", "Customer.CustomField5",
//       "Customer.CustomField6", "CancellationReason", "BillChangeDue", "Payments",
//       "SalesReturnTransactionNo", "ParentTransactionKey", "ParentTransactionNo", "ChildTransactionNo",
//       "ReceivedPaymentTransactions"],
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "match": {
//               "Customer.key.keyword": customerID
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "exists": {
//               "field": "CancellationReason"
//             }
//           },
//           {
//             "match": {
//               "BalanceAmount":  0
//             }
//           }
//         ],
//         "filter": {
//           "bool": {
//             "must": [

//             ],
//             "should": []
//           }
//         }
//       }
//     }, "sort": [
//       {
//         "TransactionDate": {
//           "order": "desc"
//         }
//       }
//     ]
//   };

//   if (searchTransactionNo.trim() !== "") {
//     searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
//   }
//   if (searchTransactionFromDate !== "" && searchTransactionToDate !== "") {
//     searchParameters.query.bool.filter.bool.must.push({
//       "range": {
//         "TransactionDate": {
//           "lte": searchTransactionToDate,
//           "gte": searchTransactionFromDate,
//           "format": "yyyy-MM-dd"
//         }
//       }
//     })
//   }
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getSales');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const outStandingLedger = [];
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         if (data._source.hasOwnProperty('ReceivedPaymentTransactions')) {
//           let receivedPayment = 0
//           for (let i = 0; i < data._source.ReceivedPaymentTransactions.length; i++) {
//             let r = data._source.ReceivedPaymentTransactions[i]
//             if (r.hasOwnProperty('IsConvert') && r.IsConvert) {
//             }
//             else {
//               receivedPayment = receivedPayment + Number(r.ReceiveAmount)
//             }
//           }
//           if (receivedPayment > 0) {
//               data._source.BillPaidAmount = data._source.BillPaidAmount + receivedPayment
//               data._source.BalanceAmount = data._source.BalanceAmount - receivedPayment
//           }
//         }
//         // if (data._source.BalanceAmount > 0) {
//           outStandingLedger.push({ key: data._id, ...data._source });
//         // }
//       });
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: outStandingLedger }
//     });
//   }
// };

export function getWalletLedgersByCustomer(
  customerID,
  from,
  size,
  searchTransactionNo,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            match: {
              CustomerID: customerID,
            },
          },
        ],
        filter: {
          bool: {
            must: [],
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchTransactionFromDate !== "" && searchTransactionToDate !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      range: {
        TransactionDate: {
          lte: searchTransactionToDate,
          gte: searchTransactionFromDate,
          format: "yyyy-MM-dd",
        },
      },
    });
  }
  return (dispatch) => {
    // console.log("Get Wallet Details - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getWalletLedgers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const walletLedgers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        walletLedgers.push({ key: data._id, ...data._source });
      });
      // console.log("Customer Data", JSON.stringify(walletLedgers));
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: walletLedgers,
      };
    });
  };
}

export function getUniqueNos(
  RegistrationID,
  StoreID,
  from,
  size,
  searchBrand,
  searchCategory,
  searchSubCategory,
  searchProductName,
  searchSKU,
  searchUniqueNo1,
  searchUniqueNo2,
  searchUniqueNo3,
  searchUniqueNo4
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          { match: { RegistrationID: RegistrationID } },
          {
            match: {
              StoreID: StoreID,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
        filter: {
          bool: {
            must: [],
            // ,"should": []
          },
        },
      },
    },
  };
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductBrand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductCategory: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductSubCategory: searchSubCategory.trim().toLowerCase(),
      },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName.trim().toLowerCase() },
    });
  }
  if (searchSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductSKU: searchSKU.trim().toLowerCase() },
    });
  }
  if (searchUniqueNo1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { UniqueNo1: searchUniqueNo1 },
    });
  }
  if (searchUniqueNo2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { UniqueNo2: searchUniqueNo2 },
    });
  }
  if (searchUniqueNo3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { UniqueNo3: searchUniqueNo3 },
    });
  }
  if (searchUniqueNo4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { UniqueNo4: searchUniqueNo4 },
    });
  }
  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getUniqueNos");
    return addMessage({ text: searchParameters }).then(function(result) {
      let uniqueNo = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        uniqueNo.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: uniqueNo,
      };
    });
  };
}
export function getUniqueNoLedger(docKey) {
  return (dispatch) => {
    return database
      .collection("UniqueNosAuditTrail")
      .where("DocKey", "==", docKey)
      .orderBy("ActionOn", "desc")
      .get()
      .then((querySnapshot) => {
        let uniqueNoLedgers = [];
        querySnapshot.forEach((doc) => {
          uniqueNoLedgers.push({ key: doc.id, ...doc.data() });
        });
        // console.log('uniqueNoLedgers', uniqueNoLedgers)
        return uniqueNoLedgers;
      });
  };
}

export function getBulkInstock(
  scrollID,
  searchStores,
  searchStoreLocation,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand,
  searchBarcode = "",
  searchProductCustomField1 = "",
  searchProductCustomField2 = "",
  searchProductCustomField3 = "",
  searchProductCustomField4 = "",
  searchProductCustomField5 = "",
  searchProductCustomField6 = "",
  showInstockQtyGreaterThanZero = false,
  searchProductTypeID = ""
) {
  let searchParameters = null;
  if (scrollID == "") {
    searchParameters = {
      from: 0,
      size: 2000,
      track_total_hits: true,
      query: {
        bool: {
          must: [],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
    };
  } else {
    searchParameters = {
      scroll: "1m",
      scroll_id: scrollID,
    };
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (showInstockQtyGreaterThanZero) {
    searchParameters.query.bool.filter.bool.must.push({
      range: {
        InStockQty: {
          gt: 0,
        },
      },
    });
  }

  if (searchStoreLocation.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { LocationID: searchStoreLocation.trim().toLowerCase() },
    });
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductSKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchBarcode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { BarCode: searchBarcode.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName.trim().toLowerCase() },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }
  if (searchProductTypeID.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: {
        ProductTypeID: searchProductTypeID,
      },
    });
  }

  // if (searchStoreLocation.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Location":  searchStoreLocation.trim().toLowerCase()} })
  // }

  // if (searchCategory.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
  // }
  // if (searchSubCategory.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
  // }
  // if (searchProductSKU.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductSKU": searchProductSKU.trim().toLowerCase() } })
  // }
  // if (searchBarcode.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "BarCode": searchBarcode.trim().toLowerCase() } })
  // }
  // if (searchProductName.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName.trim().toLowerCase() } })
  // }
  // if (searchBrand.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
  // }
  // if (searchProductCustomField1.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductCustomField1": searchProductCustomField1.trim().toLowerCase() } })
  // }
  // if (searchProductCustomField2.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductCustomField2": searchProductCustomField2.trim().toLowerCase() } })
  // }
  // if (searchProductCustomField3.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductCustomField3": searchProductCustomField3.trim().toLowerCase() } })
  // }
  // if (searchProductCustomField4.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductCustomField4": searchProductCustomField4.trim().toLowerCase() } })
  // }
  // if (searchProductCustomField5.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductCustomField5": searchProductCustomField5.trim().toLowerCase() } })
  // }
  // if (searchProductCustomField6.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductCustomField6": searchProductCustomField6.trim().toLowerCase() } })
  // }
  return (dispatch) => {
    console.log(
      "getBulkInstock searchParameters",
      JSON.stringify(searchParameters)
    );
    let addMessage = "";
    if (scrollID === "") {
      addMessage = firebase
        .functions()
        .httpsCallable("getInventoryInStockWithScrollID");
    } else {
      addMessage = firebase
        .functions()
        .httpsCallable("getInventoryInStockForScrollID");
    }

    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
        scrollID: sanitizedMessage._scroll_id,
      };
    });
  };
}

// export function getBulkInstock(scrollID, searchStores,
//   searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand, searchBarcode = "") {
//   let searchParameters = null
//   if (scrollID == "") {
//     searchParameters = {
//       "from": 0,
//       "size": 2000,
//       "query": {
//         "bool": {
//           "filter": {
//             "bool": {
//               "must": [],
//               "should": []
//             }
//           }
//         }
//       }
//     }
//   }
//   else {
//     searchParameters = {
//       "scroll": "1m",
//       "scroll_id": scrollID
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.filter.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Location": "@" + searchStoreLocation.trim().toLowerCase() + ".*" } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductSKU": searchProductSKU.trim().toLowerCase() } })
//   }
//   if (searchBarcode.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "BarCode": searchBarcode.trim().toLowerCase() } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName.trim().toLowerCase() } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
//   }
//   return (dispatch) => {
//     let addMessage = ''
//     if (scrollID === "") {
//       addMessage = firebase.functions().httpsCallable('getInventoryInStockWithScrollID');
//     }
//     else {
//       addMessage = firebase.functions().httpsCallable('getInventoryInStockForScrollID');
//     }

//     return addMessage({ text: searchParameters }).then(function (result) {
//       const products = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ key: data._id, ...data._source })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products, scrollID: sanitizedMessage._scroll_id }
//     });

//   }
// }

export function getItemInStockTotals(
  searchStores,
  searchStoreLocation,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand,
  searchBarcode = "",
  searchProductCustomField1 = "",
  searchProductCustomField2 = "",
  searchProductCustomField3 = "",
  searchProductCustomField4 = "",
  searchProductCustomField5 = "",
  searchProductCustomField6 = "",
  showInstockQtyGreaterThanZero = false,
  searchProductTypeID = ""
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      totalqty: {
        sum: {
          field: "InStockQty",
        },
      },
      totalpurchase: {
        sum: {
          script: {
            source: "doc['SupplyPrice'].value * doc['InStockQty'].value",
          },
        },
      },
      totalsales: {
        sum: {
          script: {
            source: "doc['RetailPrice'].value * doc['InStockQty'].value",
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  if (showInstockQtyGreaterThanZero) {
    searchParameters.query.bool.filter.bool.must.push({
      range: {
        InStockQty: {
          gt: 0,
        },
      },
    });
  }

  if (searchStoreLocation.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { LocationID: searchStoreLocation.trim().toLowerCase() },
    });
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductSKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchBarcode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { BarCode: searchBarcode.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName.trim().toLowerCase() },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }
  if (searchProductTypeID.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: {
        ProductTypeID: searchProductTypeID,
      },
    });
  }
  // console.log("getItemInStockTotals", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let inStockTotal = {
        totalsales: sanitizedMessage.aggregations.totalsales.value,
        totalqty: sanitizedMessage.aggregations.totalqty.value,
        totalpurchase: sanitizedMessage.aggregations.totalpurchase.value,
      };

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: inStockTotal,
      };
    });
  };
}

export function getItemInStockData(
  searchStores,
  from,
  size,
  searchStoreID,
  searchStoreLocation,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand,
  searchBarcode = "",
  searchProductCustomField1 = "",
  searchProductCustomField2 = "",
  searchProductCustomField3 = "",
  searchProductCustomField4 = "",
  searchProductCustomField5 = "",
  searchProductCustomField6 = "",
  showInstockQtyGreaterThanZero = false,
  searchProductTypeID = ""
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (showInstockQtyGreaterThanZero) {
    searchParameters.query.bool.filter.bool.must.push({
      range: {
        InStockQty: {
          gt: 0,
        },
      },
    });
  }

  if (searchStoreLocation.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { LocationID: searchStoreLocation.trim().toLowerCase() },
    });
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductSKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchBarcode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { BarCode: searchBarcode.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName.trim().toLowerCase() },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchProductCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField1: searchProductCustomField1.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField2: searchProductCustomField2.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField3: searchProductCustomField3.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField4: searchProductCustomField4.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField5: searchProductCustomField5.trim().toLowerCase(),
      },
    });
  }
  if (searchProductCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: {
        ProductCustomField6: searchProductCustomField6.trim().toLowerCase(),
      },
    });
  }
  if (searchProductTypeID.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match: {
        ProductTypeID: searchProductTypeID,
      },
    });
  }
  // console.log("getItemInStockData", JSON.stringify(searchParameters));

  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

// export function getItemInStockTotals(searchStores, searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand, searchBarcode = "") {
//   let searchParameters =
//   {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [],
//         "should": [], "minimum_should_match": 1,
//         "filter": {
//           "bool": {
//             "must": []
//           }
//         }
//       }
//     }, "aggs": {
//       "totalqty": {
//         "sum": {
//           "field": "InStockQty"
//         }
//       },
//       "totalpurchase": {
//         "sum": {
//           "script": {
//             "source": "doc['SupplyPrice'].value * doc['InStockQty'].value"
//           }
//         }
//       },
//       "totalsales": {
//         "sum": {
//           "script": {
//             "source": "doc['RetailPrice'].value * doc['InStockQty'].value"
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Location": searchStoreLocation.trim().toLowerCase() } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductSKU": searchProductSKU.trim().toLowerCase() } })
//   }
//   if (searchBarcode.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "BarCode": searchBarcode.trim().toLowerCase() } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName.trim().toLowerCase() } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
//   }
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getInventoryInStock');
//     return addMessage({ text: searchParameters }).then(function (result) {

//       let sanitizedMessage = result.data.text;

//       let inStockTotal = {
//         "totalsales": sanitizedMessage.aggregations.totalsales.value ,
//         "totalqty": sanitizedMessage.aggregations.totalqty.value,
//         "totalpurchase": sanitizedMessage.aggregations.totalpurchase.value,
//       }

//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: inStockTotal }
//     });
//   }
// };

// export function getItemInStockData(searchStores, from, size, searchStoreID, searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand, searchBarcode = "") {

//   let searchParameters =
//   {
//     "from": from,
//     "size": size,
//     "query": {
//       "bool": {
//         "must": [],
//         "should":[],"minimum_should_match":1,
//         "filter": {
//           "bool": {
//             "must": []
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Location": searchStoreLocation.trim().toLowerCase() } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductSKU": searchProductSKU.trim().toLowerCase() } })
//   }
//   if (searchBarcode.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "BarCode": searchBarcode.trim().toLowerCase() } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName.trim().toLowerCase() } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
//   }

//   // console.log("Parameters", JSON.stringify(searchParameters));

//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getInventoryInStock');
//     return addMessage({ text: searchParameters }).then(function (result) {

//       const products = []
//       let sanitizedMessage = result.data.text;

//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ ...data._source })
//       })

//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products }
//     });
//   }
// };

// export function getBulkInstock(scrollID, searchStores, searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand) {
//   let searchParameters = null
//   if (scrollID == "") {
//     searchParameters = {
//       "from": 0,
//       "size": 2000,
//       "query": {
//         "bool": {
//           "filter": {
//             "bool": {
//               "must": [],
//               "should": []
//             }
//           }
//         }
//       }
//     }
//   }
//   else {
//     searchParameters = {
//       "scroll": "1m",
//       "scroll_id": scrollID
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.filter.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Location": "@" + searchStoreLocation.trim().toLowerCase() + ".*" } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Category": "@" + searchCategory.trim().toLowerCase() + ".*" } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "SubCategory": "@" + searchSubCategory.trim().toLowerCase() + ".*" } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ProductSKU": "@" + searchProductSKU.trim().toLowerCase() + ".*" } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "ProductName": "@" + searchProductName.trim().toLowerCase() + ".*" } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match": { "Brand": "@" + searchBrand.trim().toLowerCase() + ".*" } })
//   }
//   console.log("Parameters", JSON.stringify(searchParameters));

//   return (dispatch) => {
//     let addMessage = ''
//     if (scrollID === "") {
//       addMessage = firebase.functions().httpsCallable('getInventoryInStockWithScrollID');
//     }
//     else {
//       addMessage = firebase.functions().httpsCallable('getInventoryInStockForScrollID');
//     }

//     return addMessage({ text: searchParameters }).then(function (result) {
//       const products = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         products.push({ key: data._id, ...data._source })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: products, scrollID: sanitizedMessage._scroll_id }
//     });

//   }
// }

// export function getItemInStockTotals(searchStores, searchStoreLocation, searchCategory, searchSubCategory,
//   searchProductSKU, searchProductName, searchBrand) {
//   let searchParameters =
//   {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [],
//         "should": [], "minimum_should_match": 1,
//         "filter": {
//           "bool": {
//             "must": []
//           }
//         }
//       }
//     }, "aggs": {
//       "totalqty": {
//         "sum": {
//           "field": "InStockQty"
//         }
//       },
//       "totalpurchase": {
//         "sum": {
//           "script": {
//             "source": "doc['SupplyPrice'].value * doc['InStockQty'].value"
//           }
//         }
//       },
//       "totalsales": {
//         "sum": {
//           "script": {
//             "source": "doc['RetailPrice'].value * doc['InStockQty'].value"
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID.keyword": store.key
//       }
//     })
//   }
//   if (searchStoreLocation.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Location": searchStoreLocation.trim().toLowerCase() } })
//   }

//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
//   }
//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductSKU": searchProductSKU.trim().toLowerCase() } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName.trim().toLowerCase() } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
//   }
//   return (dispatch) => {
//     let addMessage = firebase.functions().httpsCallable('getInventoryInStock');
//     return addMessage({ text: searchParameters }).then(function (result) {

//       let sanitizedMessage = result.data.text;

//       let inStockTotal = {
//         "totalsales": sanitizedMessage.aggregations.totalsales.value ,
//         "totalqty": sanitizedMessage.aggregations.totalqty.value,
//         "totalpurchase": sanitizedMessage.aggregations.totalpurchase.value,
//       }

//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: inStockTotal }
//     });
//   }
// };

export function getVendorLedgerFromInventory(
  storeID,
  searchText,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchTransactionNo = ""
) {
  let searchParameters = {
    from: 0,
    size: 10000,
    track_total_hits: true,
    _source: [
      "Vendor.key",
      "Vendor.VendorFirstName",
      "Vendor.VendorLastName",
      "Vendor.PhoneNo",
      "Vendor.EmailID",
      "TransactionNo",
      "TransactionDate",
      "BillAmount",
      "Status",
      "CancellationReason",
      "TransactionType.TransactionType",
      "TransactionType.UpdateStockInwardOrOutward",
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              "Store.key": storeID,
            },
          },
          {
            exists: {
              field: "Vendor.key",
            },
          },
          {
            match: {
              "Vendor.key": searchText,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };

  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }

  return (dispatch) => {
    // console.log("getVendorLedgerFromExpense", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const vendorLedgers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let obj = data._source;
        if (obj.TransactionType.UpdateStockInwardOrOutward !== "") {
          vendorLedgers.push({ key: data._id, ...data._source });
        }
      });
      // console.log("getVendorLedgerFromExpense Data", JSON.stringify(vendorLedgers));
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: vendorLedgers,
      };
    });
  };
}

export function getVendorLedgerFromExpense(
  storeID,
  searchText,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchTransactionNo = ""
) {
  let searchParameters = {
    from: 0,
    size: 10000,
    track_total_hits: true,
    _source: [
      "Party.key",
      "Party.PartyName",
      "Party.PhoneNo",
      "Party.EmailID",
      "ExpenseNo",
      "ExpenseDateTime",
      "Amount",
      "Status",
      "CancellationReason",
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            match: {
              "Party.PartyType": "Vendor",
            },
          },
          {
            match: {
              "Party.key": "vendor_" + searchText,
            },
          },
          {
            range: {
              ExpenseDateTime: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            match: {
              Status: "Pending",
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        ExpenseDateTime: {
          order: "desc",
        },
      },
    ],
  };

  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ExpenseNo: searchTransactionNo },
    });
  }
  return (dispatch) => {
    // console.log("getVendorLedgerFromExpense", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      const vendorLedgers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        vendorLedgers.push({ key: data._id, ...data._source });
      });
      // console.log("getVendorLedgerFromExpense Data", JSON.stringify(vendorLedgers));
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: vendorLedgers,
      };
    });
  };
}

export function getVendorOpeningBalanceFromInventory(
  storeID,
  searchText,
  searchTransactionFromDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              "Vendor.key": searchText,
            },
          },
          {
            match: {
              "Store.key": storeID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
      },
    },
    aggs: {
      transaction: {
        terms: {
          field: "TransactionType.UpdateStockInwardOrOutward.keyword",
        },
        aggs: {
          totalamount: {
            sum: {
              field: "BillAmount",
            },
          },
        },
      },
    },
  };
  return (dispatch) => {
    //console.log("getVendorOpeningBalanceFromInventory", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      let inventoryList = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.transaction.buckets.map((data, index) => {
        if (data.key !== "") {
          inventoryList.push({
            TransactionType: data.key,
            totalamount: Number(data.totalamount.value).toFixed(2),
          });
        }
      });

      //console.log(JSON.stringify(inventoryList));
      let totaldebit = 0;
      for (let index = 0; index < inventoryList.length; index++) {
        const inventory = inventoryList[index];
        if (inventory.TransactionType === "inward") {
          totaldebit = totaldebit + Number(inventory.totalamount);
        } else {
          totaldebit = totaldebit - Number(inventory.totalamount);
        }
      }

      //console.log(totaldebit);

      let debitAmount = { totaldebit: totaldebit };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: debitAmount,
      };
    });
  };
}

export function getVendorOpeningBalanceFromExpense(
  storeID,
  searchText,
  searchTransactionFromDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            match_phrase: {
              "Party.key": "vendor_" + searchText,
            },
          },
          {
            match: {
              "Party.PartyType": "Vendor",
            },
          },
          {
            match: {
              Status: "Paid",
            },
          },
          {
            range: {
              ExpenseDateTime: {
                lte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
      },
    },
    aggs: {
      totalexpense: {
        sum: {
          field: "Amount",
        },
      },
    },
  };
  return (dispatch) => {
    // console.log("getVendorOpeningBalanceFromExpense", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getExpenses");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;

      let expenseAmount = {
        totalexpense: sanitizedMessage.aggregations.totalexpense.value,
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: expenseAmount,
      };
    });
  };
}

export function getCustomersForMembershipValidity(
  RegistrationID,
  StoreID,
  from,
  size,
  searchText,
  searchMembership,
  searchAssignmentFromDate,
  searchAssignmentToDate,
  searchValidityFromDate,
  searchValidityToDate
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: RegistrationID } },
            {
              match: {
                StoreID: StoreID,
              },
            },
            {
              exists: {
                field: "Membership.ValidityDate",
              },
            },
          ],
          must_not: [],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          ActionOn: {
            order: "desc",
          },
        },
      ],
    };
    searchParameters.query.bool.should.push({
      regexp: { CustomerDetails: "@" + searchText + ".*" },
    });
    searchParameters.query.bool.should.push({
      match: { CustomerDetails: { query: searchText, operator: "and" } },
    });
    searchParameters.query.bool.should.push({
      regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" },
    });
    searchParameters.query.bool.should.push({
      regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" },
    });
    if (searchMembership.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: {
          "Membership.Name": "@" + searchMembership.trim().toLowerCase() + ".*",
        },
      });
    }
    if (searchAssignmentFromDate !== "" && searchAssignmentToDate !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        range: {
          "Membership.AssignmentDate": {
            gte: searchAssignmentFromDate,
            lte: searchAssignmentToDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }
    if (searchValidityFromDate !== "" && searchValidityToDate !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        range: {
          "Membership.ValidityDate": {
            gte: searchValidityFromDate,
            lte: searchValidityToDate,
            format: "yyyy-MM-dd",
          },
        },
      });
    }
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getTopSpentCustomers(
  searchStores,
  searchStartDate,
  searchEndDate,
  size
) {
  return (dispatch) => {
    let searchParameters = {
      size: 0,
      query: {
        bool: {
          must: [
            {
              range: {
                TransactionDate: {
                  gte: searchStartDate,
                  lte: searchEndDate,
                  format: "yyyy-MM-dd",
                },
              },
            },
            {
              term: {
                SalesImpacted: true,
              },
            },
          ],
          should: [],
          minimum_should_match: 1,
          must_not: [
            {
              exists: {
                field: "CancellationReason",
              },
            },
            {
              match_phrase: {
                TransactionType: "Receive Payment",
              },
            },
          ],
        },
      },
      aggs: {
        customer: {
          terms: {
            field: "CustomerID.keyword",
            size: size,
          },
          aggs: {
            total_spent: {
              sum: {
                field: "Amount",
              },
            },
            sales_bucket_sort: {
              bucket_sort: {
                sort: [
                  {
                    total_spent: {
                      order: "desc",
                    },
                  },
                ],
              },
            },
          },
        },
      },
    };

    let stores = [];
    for (let i = 0; i < searchStores.length; i++) {
      const store = searchStores[i];
      stores.push(store.key);
    }

    if (stores.length > 0) {
      searchParameters.query.bool.should.push({
        terms: {
          "StoreID.keyword": stores,
        },
      });
    }

    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    console.log(
      "getTopSpentCustomers - searchParameters",
      JSON.stringify(searchParameters)
    );
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;

      let topCustomers = [];
      sanitizedMessage.aggregations.customer.buckets.map((data, index) => {
        if (data.key !== "") {
          topCustomers.push({
            key: data.key,
            TotalTransactions: Number(data.doc_count),
            TotalSpent: Number(data.total_spent.value).toFixed(2),
          });
        }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: topCustomers,
      };
    });
  };
}

export function getTopSpentCustomersByCustomerIDs(customerIDs, size) {
  return (dispatch) => {
    let searchParameters = {
      from: 0,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [{ terms: { _id: customerIDs } }],
        },
      },
    };
    // console.log('getTopSpentCustomersByCustomerIDs - searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

// export function getTopSpentCustomers(searchStores, searchStartDate, searchEndDate) {
//   return (dispatch) => {
//     let searchParameters = {
//       "size": 0,
//       "query": {
//         "bool": {
//           "must": [
//             {
//               "range": {
//                 "TransactionDate": {
//                   "gte": searchStartDate,
//                   "lte": searchEndDate,
//                   "format": "yyyy-MM-dd"
//                 }
//               }
//             }
//           ],"should":[],"minimum_should_match": 1
//         }
//       },
//       "aggs": {
//         "customer": {
//           "terms": {
//             "field": "Customer.key.keyword",
//             "size": 50
//           },
//           "aggs": {
//             "total_spent": {
//               "sum": {
//                 "field": "BillAmount"
//               }
//             },
//             "sales_bucket_sort": {
//               "bucket_sort": {
//                 "sort": [
//                   {
//                     "total_spent": {
//                       "order": "desc"
//                     }
//                   }
//                 ]
//               }
//             }
//           }
//         }
//       }
//     }

//     let stores = []
//     for (let i = 0; i < searchStores.length; i++) {
//       const store = searchStores[i];
//       stores.push(store.key);
//     }

//     if(stores.length > 0)
//     {
//       searchParameters.query.bool.should.push({
//         "terms": {
//           "Store.key.keyword": stores
//         }
//       })
//     }

//     let addMessage = firebase.functions().httpsCallable('getSales');
//     //console.log('getTopSpentCustomers - searchParameters', JSON.stringify(searchParameters))
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const customers = []
//       let sanitizedMessage = result.data.text;

//       let topCustomers = []
//       sanitizedMessage.aggregations.customer.buckets.map((data, index) => {
//         if(data.key !== "")
//         {
//           topCustomers.push({
//             key: data.key,
//             TotalTransactions : Number(data.doc_count),
//             TotalSpent : Number(data.total_spent.value).toFixed(2)
//           })
//         }
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: topCustomers }
//     });
//   }
// }

// export function getTopSpentCustomersByCustomerIDs(customerIDs) {
//   return (dispatch) => {
//     let searchParameters = {
//       "from": 0,
//       "size": 50,
//       "query": {
//         "bool": {
//           "must": [
//             { "terms": { "_id": customerIDs } }
//           ]
//         }
//       }
//     }
//     //console.log('getTopSpentCustomersByCustomerIDs - searchParameters', JSON.stringify(searchParameters))
//     let addMessage = firebase.functions().httpsCallable('getCustomers');
//     return addMessage({ text: searchParameters }).then(function (result) {
//       const customers = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.hits.hits.map((data, index) => {
//         customers.push({ key: data._id, ...data._source })
//       })
//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
//     });
//   }
// }

///pawan

export function getProductTypeSoldReportData(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchProductType
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              store: {
                terms: {
                  field: "StoreName.keyword",
                },
              },
            },
            {
              product_type: {
                terms: {
                  field: "ProductType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };
  if (searchProductType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductType: searchProductType.trim().toLowerCase() },
    });
  }

  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  console.log(JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const productTypes = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let productType = {
          store: data.key.store,
          product_type: data.key.product_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };

        productTypes.push(productType);
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: productTypes,
      };
    });
  };
}

export function getItemBrandSoldReportData(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchBrand
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            exists: {
              field: "Brand",
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              store: {
                terms: {
                  field: "StoreName.keyword",
                },
              },
            },
            {
              brand: {
                terms: {
                  field: "Brand.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };

  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const brandSold = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let brand = {
          store: data.key.store,
          brand: data.key.brand,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };
        brandSold.push(brand);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: brandSold,
      };
    });
  };
}

export function getItemCategorySoldReportData(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchCategory
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              store: {
                terms: {
                  field: "StoreName.keyword",
                },
              },
            },
            {
              category: {
                terms: {
                  field: "Category.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const productCategories = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let productCategory = {
          store: data.key.store,
          category: data.key.category,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };
        productCategories.push(productCategory);
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: productCategories,
      };
    });
  };
}

export function getSubCategoryItemSoldReportData(
  registrationID,
  searchStores,
  searchTransactionToDate,
  searchTransactionFromDate,
  searchCategory,
  searchSubCategory
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            exists: {
              field: "SubCategory",
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              store: {
                terms: {
                  field: "StoreName.keyword",
                },
              },
            },
            {
              category: {
                terms: {
                  field: "Category.keyword",
                },
              },
            },
            {
              sub_category: {
                terms: {
                  field: "SubCategory.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_discount: {
            sum: {
              field: "DiscountAmount",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim().toLowerCase() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim().toLowerCase() },
    });
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const productSubCategories = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let productSubCategory = {
          store: data.key.store,
          category: data.key.category,
          sub_category: data.key.sub_category,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
          total_discount: Number(data.total_discount.value).toFixed(2),
          total_taxes: Number(data.total_taxes.value).toFixed(2),
          total_taxable: Number(data.total_taxable.value).toFixed(2),
        };
        productSubCategories.push(productSubCategory);
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: productSubCategories,
      };
    });
  };
}

export function getCustomersForLoyalty(
  registrationID,
  storeID,
  from,
  size,
  searchText,
  searchLoyaltyType
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            {
              match: {
                StoreID: storeID,
              },
            },
          ],
          must_not: [],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          ActionOn: {
            order: "desc",
          },
        },
      ],
    };
    searchParameters.query.bool.should.push({
      regexp: { CustomerName: "@" + searchText.trim() + ".*" },
    });
    searchParameters.query.bool.should.push({
      match_phrase: { CustomerName: searchText.trim() },
    });
    searchParameters.query.bool.should.push({
      regexp: { CustomerEmailID: "@" + searchText + ".*" },
    });
    searchParameters.query.bool.should.push({
      regexp: { CustomerPhoneNo: "@" + searchText + ".*" },
    });
    if (searchLoyaltyType.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: {
          LoyaltyType: "@" + searchLoyaltyType.trim().toLowerCase() + ".*",
        },
      });
    }
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getLoyaltyCustomer");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getLoyaltyLedger(
  registrationID,
  storeID,
  from,
  size,
  searchText,
  searchTransactionNo,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchType
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            {
              match: {
                StoreID: storeID,
              },
            },
            {
              range: {
                TransactionDate: {
                  lte: searchTransactionToDate,
                  gte: searchTransactionFromDate,
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
          must_not: [],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };
    searchParameters.query.bool.should.push({
      regexp: { CustomerName: "@" + searchText.trim() + ".*" },
    });
    searchParameters.query.bool.should.push({
      match_phrase: { CustomerName: searchText.trim() },
    });
    searchParameters.query.bool.should.push({
      regexp: { CustomerEmailID: "@" + searchText + ".*" },
    });
    searchParameters.query.bool.should.push({
      regexp: { CustomerPhoneNo: "@" + searchText + ".*" },
    });
    if (searchType.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: { Type: "@" + searchType.trim().toLowerCase() + ".*" },
      });
    }
    if (searchTransactionNo.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: {
          TransactionNo: searchTransactionNo.trim().toLowerCase(),
        },
      });
    }

    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable("getLoyaltyLedger");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getInventoryHistoryProducts(
  searchStores,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand
) {
  let searchParameters = {
    from: 0,
    size: 10000,
    track_total_hits: true,
    query: {
      bool: {
        must: [],
        must_not: [],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        Name: {
          order: "asc",
        },
      },
    ],
  };
  searchParameters.query.bool.must_not.push({ match: { IsDeleted: "true" } });

  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Name: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Category: "@" + searchCategory.trim().toLowerCase() + ".*" },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        SubCategory: "@" + searchSubCategory.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Brand: "@" + searchBrand.trim().toLowerCase() + ".*" },
    });
  }
  return (dispatch, getState) => {
    if (
      getState().user.user.Role === "Store Owner" ||
      getState().user.user.Role === "Store Manager"
    ) {
      searchParameters.query.bool.must.push({
        match: {
          "StoreSpecificPrices.StoreID": getState().user.store.key,
        },
      });
    }
    searchParameters.query.bool.must.push({
      match: { RegistrationID: getState().user.user.RegistrationID },
    });

    //console.log("getInventoryHistoryProducts - query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getProducts");

    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

export function getInventoryHistoryReport(
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              TransactionType: "Stock Update",
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "TransactionQty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    // searchParameters.query.bool.should.push({
    //   "term": {
    //     "StoreID.keyword": store.key
    //   }
    // })
    searchParameters.query.bool.should.push({
      match: {
        "StoreID.keyword": store.key,
      },
    });
  }

  return (dispatch, getState) => {
    console.log(
      "getInventoryHistoryReport - query",
      JSON.stringify(searchParameters)
    );
    let addMessage = firebase
      .functions()
      .httpsCallable("getInventoryInstockHistory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      console.log("getInventoryHistoryReport", itemStock);
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getInstockQtybyProductIDs(searchStores, productIDs) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        must_not: [],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "InStockQty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    searchParameters.query.bool.should.push({
      match: {
        StoreID: searchStores[i].key,
      },
    });
  }

  console.log(
    "getInstockQtybyProductIDs - query",
    JSON.stringify(searchParameters)
  );
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getInventoryInStock");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      console.log(
        "getInstockQtybyProductIDs sanitizedMessage",
        sanitizedMessage
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getDenomInventoryHistoryReport(
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              Status: "Completed",
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        must_not: [
          {
            match_phrase: {
              TransactionType: "Stock Update",
            },
          },
          {
            match_phrase: {
              TransactionType: "Opening Stock",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      match: {
        StoreID: store.key,
      },
    });
    // searchParameters.query.bool.should.push({
    //   "term": {
    //     "StoreID": store.key
    //   }
    // })
  }

  // if (searchProductSKU.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SKU": searchProductSKU.trim() } })
  // }
  // if (searchProductName.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName } })
  // }
  // if (searchBrand.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim() } })
  // }
  // if (searchCategory.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim()  } })
  // }
  // if (searchSubCategory.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim() } })
  // }

  console.log(
    "getDenomInventoryHistoryReport - query",
    JSON.stringify(searchParameters)
  );
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      console.log(
        "getDenomInventoryHistoryReport sanitizedMessage",
        sanitizedMessage
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

// export function getDenomInventoryHistoryReport(searchStores,searchTransactionFromDate, searchTransactionToDate,searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand) {
//   let searchParameters =
//   {
//     "size": 0,
//     "query": {
//       "bool": {
//         "must": [
//           {
//             "range": {
//               "TransactionDate": {
//                 "lte": searchTransactionToDate,
//                 "gte": searchTransactionFromDate,
//                 "format": "yyyy-MM-dd"
//               }
//             }
//           }
//         ],
//         "must_not": [
//           {
//             "match_phrase": {
//               "TransactionType": "Stock Update"
//             }
//           },
//           {
//             "match_phrase": {
//               "TransactionType": "Opening Stock"
//             }
//           }
//         ]
//         ,"should": [],
//         "minimum_should_match": 1,
//         "filter": {
//           "bool": {
//             "must": []
//           }
//         }
//       }
//     },
//     "aggs": {
//       "my_buckets": {
//         "composite": {
//           "size": 10000,
//           "sources": [
//             {
//               "sku": {
//                 "terms": {
//                   "field": "ProductID.keyword"
//                 }
//               }
//             },
//             {
//               "transaction_type": {
//                 "terms": {
//                   "field": "TransactionType.keyword"
//                 }
//               }
//             }
//           ]
//         },
//         "aggs": {
//           "total_qty": {
//             "sum": {
//               "field": "Qty"
//             }
//           }
//         }
//       }
//     }
//   }
//   for (let i = 0; i < searchStores.length; i++) {
//     const store = searchStores[i];

//     searchParameters.query.bool.should.push({
//       "term": {
//         "StoreID": store.key
//       }
//     })
//   }

//   if (searchProductSKU.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SKU": searchProductSKU.trim() } })
//   }
//   if (searchProductName.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName } })
//   }
//   if (searchBrand.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim() } })
//   }
//   if (searchCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim()  } })
//   }
//   if (searchSubCategory.trim() !== "") {
//     searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim() } })
//   }

//   //console.log("getDenomInventoryHistoryReport - query", JSON.stringify(searchParameters));
//   return (dispatch, getState) => {
//     let addMessage = firebase.functions().httpsCallable('getDenomInventory');
//     return addMessage({ text: searchParameters }).then(function (result) {

//       const itemStock = []
//       let sanitizedMessage = result.data.text;
//       sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
//         let item = {
//           sku: data.key.sku,
//           transaction_type: data.key.transaction_type,
//           total_quantity: Number(data.total_qty.value).toFixed(2),
//         }
//         itemStock.push(item);
//       })

//       return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: itemStock }
//     });
//   }
// };

export function getDenomSalesHistoryReport(
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      match: {
        StoreID: store.key,
      },
    });
  }

  console.log(
    "getDenomSalesHistoryReport - query",
    JSON.stringify(searchParameters)
  );
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      console.log("getDenomSalesHistoryReport itemStock", itemStock);
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getOpeningStockFromInventoryHistory(searchStores, productIDs) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match_phrase: {
              TransactionType: "Opening Stock",
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "TransactionQty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    // searchParameters.query.bool.should.push({
    //   "match": {
    //     "StoreID.keyword": store.key
    //   }
    // })
    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch, getState) => {
    // console.log("getOpeningStockFromInventoryHistory - query", JSON.stringify(searchParameters));
    let addMessage = firebase
      .functions()
      .httpsCallable("getInventoryInstockHistory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      // console.log('getOpeningStockFromInventoryHistory', itemStock)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getStockUpdateFromInventoryHistory(
  searchStores,
  searchTransactionFromDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lt: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              TransactionType: "Stock Update",
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "TransactionQty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  return (dispatch, getState) => {
    console.log(
      "getStockUpdateFromInventoryHistory - query",
      JSON.stringify(searchParameters)
    );
    let addMessage = firebase
      .functions()
      .httpsCallable("getInventoryInstockHistory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      // console.log('getStockUpdateFromInventoryHistory',itemStock)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getOpeningStockFromDenomInventory(
  searchStores,
  searchTransactionFromDate,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBrand
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lt: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          { match: { "Status.keyword": "Completed" } },
        ],
        must_not: [
          {
            match_phrase: {
              TransactionType: "Stock Update",
            },
          },
          {
            match_phrase: {
              TransactionType: "Opening Stock",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SKU: searchProductSKU.trim() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim() },
    });
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Category: searchCategory.trim() },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { SubCategory: searchSubCategory.trim() },
    });
  }

  return (dispatch, getState) => {
    console.log(
      "getOpeningStockFromDenomInventory - query",
      JSON.stringify(searchParameters)
    );

    let addMessage = firebase.functions().httpsCallable("getDenomInventory");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      // console.log('getOpeningStockFromDenomInventory', itemStock)
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getOpeningStockFromDenomSales(
  searchStores,
  searchTransactionFromDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                lt: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              sku: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              transaction_type: {
                terms: {
                  field: "TransactionType.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      match: {
        StoreID: store.key,
      },
    });
  }
  // console.log("getOpeningStockFromDenomSales - query", JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemStock = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          transaction_type: data.key.transaction_type,
          total_quantity: Number(data.total_qty.value).toFixed(2),
        };
        itemStock.push(item);
      });
      // console.log("getOpeningStockFromDenomSales",itemStock);
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemStock,
      };
    });
  };
}

export function getProductSoldForAMonth(
  searchStore,
  searchTransactionFromDate,
  searchTransactionToDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                gte: searchTransactionFromDate,
                lte: searchTransactionToDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match: {
              StoreID: searchStore,
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      products: {
        terms: {
          field: "ProductID.keyword",
          size: 10000,
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
        },
      },
    },
  };
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const items = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.products.buckets.map((data, index) => {
        let item = {
          key: data.key,
          total_qty: Number(data.total_qty.value).toFixed(2),
        };
        items.push(item);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: items,
      };
    });
  };
}

export function getAvgProductSoldForAPeriod(
  searchStore,
  searchTransactionFromDate,
  searchTransactionToDate,
  productIDs
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              TransactionDate: {
                gte: searchTransactionFromDate,
                lte: searchTransactionToDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match: {
              StoreID: searchStore,
            },
          },
          {
            terms: {
              "ProductID.keyword": productIDs,
            },
          },
        ],
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      products: {
        terms: {
          field: "ProductID.keyword",
          size: 10000,
        },
        aggs: {
          total_qty: {
            avg: {
              field: "Qty",
            },
          },
        },
      },
    },
  };

  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const items = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.products.buckets.map((data, index) => {
        let item = {
          key: data.key,
          total_qty: Number(data.total_qty.value).toFixed(2),
        };
        items.push(item);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: items,
      };
    });
  };
}

export function getTopGoodsForSales(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match: {
              ProductType: "Goods for sale",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: size,
          sources: [
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const goods = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let good = {
          name: data.key.product,
          total_sales: Number(data.total_sales.value).toFixed(2),
        };
        goods.push(good);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: goods,
      };
    });
  };
}

export function getTopServices(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match: {
              ProductType: "Services",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: size,
          sources: [
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const services = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let service = {
          name: data.key.product,
          total_sales: Number(data.total_sales.value).toFixed(2),
        };
        services.push(service);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: services,
      };
    });
  };
}

export function getTopWallets(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              ProductType: "Wallet Recharge",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: size,
          sources: [
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const wallets = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let wallet = {
          name: data.key.product,
          total_sales: Number(data.total_sales.value).toFixed(2),
        };
        wallets.push(wallet);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: wallets,
      };
    });
  };
}

export function getTopPackages(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate,
  size
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            match_phrase: {
              ProductType: "Packages",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: size,
          sources: [
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const packages = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let objPackage = {
          name: data.key.product,
          total_sales: Number(data.total_sales.value).toFixed(2),
        };
        packages.push(objPackage);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: packages,
      };
    });
  };
}

export function getWalkinCustomers(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "Customer",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  // console.log("getWalkinCustomers - query", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const packages = [];
      let sanitizedMessage = result.data.text;
      return { totalWalkinsCount: sanitizedMessage.hits.total.value };
    });
  };
}

export function getSalesCustomers(
  registrationID,
  searchStores,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 10000,
    _source: ["Customer.key"],
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            exists: {
              field: "Customer",
            },
          },
          {
            term: {
              "TransactionType.SalesImpactChecked": true,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  // console.log("getSalesCustomers - query", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const customers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push(data._source.Customer.key);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: customers,
      };
    });
  };
}

export function getNewCustomers(
  customerIDs,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            range: {
              CreatedOn: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          { terms: { _id: customerIDs } },
        ],
      },
    },
  };
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    // console.log("getNewCustomers - query", JSON.stringify(searchParameters));
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      return { totalNewCount: sanitizedMessage.hits.total.value };
    });
  };
}

export function getRepeatedCustomers(
  customerIDs,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [{ terms: { _id: customerIDs } }],
        must_not: [
          {
            range: {
              CreatedOn: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
      },
    },
  };
  return (dispatch) => {
    // console.log("getRepeatedCustomers - query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getCustomers");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      return { totalRepeatedCount: sanitizedMessage.hits.total.value };
    });
  };
}

export function getInvoicesForGSTR1B2B(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    _source: [
      "Customer.FirstName",
      "Customer.LastName",
      "Customer.CustomField1",
      "Customer.CustomField2",
      "Customer.CustomField3",
      "Customer.CustomField4",
      "Customer.CustomField5",
      "Customer.CustomField6",
      "TransactionNo",
      "TransactionDate",
      "BillAmount",
      "PlaceOfSupply",
      "TaxSummaryList",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            exists: {
              field: "Customer",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "TransactionType.key": searchTransactionType.trim().toLowerCase(),
      },
    });
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  // console.log("getInvoicesForGSTR1B2B - query", JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getSales");

    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      if (
        getState().config.CustomFields.filter(
          (m) => m.ModuleName === "Customer"
        ).length == 0
      ) {
        return { totalItemsCount: 0, searchResult: invoices };
      }
      let customerCustomFields = getState().config.CustomFields.filter(
        (m) => m.ModuleName === "Customer"
      )[0];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let gstinFound = false;
        if (
          customerCustomFields.CustomField1Name.trim().toLowerCase() ===
            "gstin" &&
          data._source.Customer.hasOwnProperty("CustomField1") &&
          data._source.Customer.CustomField1 !== ""
        ) {
          gstinFound = true;
        }
        if (
          customerCustomFields.CustomField2Name.trim().toLowerCase() ===
            "gstin" &&
          data._source.Customer.hasOwnProperty("CustomField2") &&
          data._source.Customer.CustomField2 !== ""
        ) {
          gstinFound = true;
        }
        if (
          customerCustomFields.CustomField3Name.trim().toLowerCase() ===
            "gstin" &&
          data._source.Customer.hasOwnProperty("CustomField3") &&
          data._source.Customer.CustomField3 !== ""
        ) {
          gstinFound = true;
        }
        if (
          customerCustomFields.CustomField4Name.trim().toLowerCase() ===
            "gstin" &&
          data._source.Customer.hasOwnProperty("CustomField4") &&
          data._source.Customer.CustomField4 !== ""
        ) {
          gstinFound = true;
        }
        if (
          customerCustomFields.CustomField5Name.trim().toLowerCase() ===
            "gstin" &&
          data._source.Customer.hasOwnProperty("CustomField5") &&
          data._source.Customer.CustomField5 !== ""
        ) {
          gstinFound = true;
        }
        if (
          customerCustomFields.CustomField6Name.trim().toLowerCase() ===
            "gstin" &&
          data._source.Customer.hasOwnProperty("CustomField6") &&
          data._source.Customer.CustomField6 !== ""
        ) {
          gstinFound = true;
        }
        if (gstinFound) {
          invoices.push({ ...data._source, key: data._id });
        }
      });
      // console.log('getInvoicesForGSTR1B2B',JSON.stringify(invoices));

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getInvoicesForGSTR1B2C(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    _source: [
      "Customer.FirstName",
      "Customer.LastName",
      "Customer.CustomField1",
      "Customer.CustomField2",
      "Customer.CustomField3",
      "Customer.CustomField4",
      "Customer.CustomField5",
      "Customer.CustomField6",
      "TransactionNo",
      "TransactionDate",
      "BillAmount",
      "PlaceOfSupply",
      "TaxSummaryList",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };

  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "TransactionType.key": searchTransactionType.trim().toLowerCase(),
      },
    });
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  console.log("searchParameters", searchParameters);
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      let gstinCustomFieldNo = 0;
      let customField = getState().config.CustomFields.filter(
        (m) => m.ModuleName === "Customer"
      )[0];
      if (customField) {
        if (customField.CustomField1Name.trim().toLowerCase() === "gstin") {
          gstinCustomFieldNo = 1;
        } else if (
          customField.CustomField2Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 2;
        } else if (
          customField.CustomField3Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 3;
        } else if (
          customField.CustomField4Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 4;
        } else if (
          customField.CustomField5Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 5;
        } else if (
          customField.CustomField6Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 6;
        }
      }
      sanitizedMessage.hits.hits.map((data) => {
        let b2c = true;
        if (gstinCustomFieldNo > 0 && data._source.Customer) {
          if (
            gstinCustomFieldNo === 1 &&
            data._source.Customer.CustomField1.trim() !== ""
          ) {
            b2c = false;
          } else if (
            gstinCustomFieldNo === 2 &&
            data._source.Customer.CustomField2.trim() !== ""
          ) {
            b2c = false;
          } else if (
            gstinCustomFieldNo === 3 &&
            data._source.Customer.CustomField3.trim() !== ""
          ) {
            b2c = false;
          } else if (
            gstinCustomFieldNo === 4 &&
            data._source.Customer.CustomField4.trim() !== ""
          ) {
            b2c = false;
          } else if (
            gstinCustomFieldNo === 5 &&
            data._source.Customer.CustomField5.trim() !== ""
          ) {
            b2c = false;
          } else if (
            gstinCustomFieldNo === 6 &&
            data._source.Customer.CustomField6.trim() !== ""
          ) {
            b2c = false;
          }
        }
        if (b2c) {
          invoices.push({ ...data._source, key: data._id });
        }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getInvoicesForGSTR1(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    _source: [
      "TaxSummaryList",
      "Customer",
      "PlaceOfSupply",
      "TransactionNo",
      "TransactionDate",
      "BillAmount",
      "",
    ],
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            should: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: {
        "TransactionType.key": searchTransactionType.trim().toLowerCase(),
      },
    });
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  // console.log("getInvoicesForGSTR1 - query" , JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getSalesPaymentModesBySalesIDs(salesIDs) {
  let searchParameters = {
    _source: ["Payments"],
    size: 10000,
    query: {
      bool: {
        must: [
          {
            terms: {
              _id: salesIDs,
            },
          },
        ],
      },
    },
  };
  return (dispatch) => {
    // console.log("getProductsByProductIDs - query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const payments = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        payments.push({ Payments: data._source.Payments, key: data._id });
      });
      return payments;
    });
  };
}

export function getProductsByProductIDs(productIDs) {
  let searchParameters = {
    size: 10000,
    query: {
      bool: {
        must: [
          {
            terms: {
              _id: productIDs,
            },
          },
        ],
      },
    },
  };
  return (dispatch) => {
    // console.log("getProductsByProductIDs - query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getProducts");
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let product = {
          key: data._id,
          brand: data._source.Brand,
          category: data._source.Category,
          sub_category: data._source.SubCategory,
          product_type: data._source.ProductType,
          sku: data._source.SKU,
          product: data._source.Name,
          supplyprice: data._source.SupplyPrice,
          taxID: data._source.TaxID,
          images: data._source.Images,
          customField1: data._source.CustomField1
            ? data._source.CustomField1
            : "",
          customField2: data._sourceCustomField2
            ? data._source.CustomField2
            : "",
          customField3: data._source.CustomField3
            ? data._source.CustomField3
            : "",
          customField4: data._source.CustomField4
            ? data._source.CustomField4
            : "",
          customField5: data._source.CustomField5
            ? data._source.CustomField5
            : "",
          customField6: data._source.CustomField6
            ? data._source.CustomField6
            : "",
          inventoryType: data._source.InventoryType
            ? data._source.InventoryType
            : "",
          taxInclusivePurchase: data._source.hasOwnProperty(
            "TaxInclusivePurchase"
          )
            ? data._source.TaxInclusivePurchase
            : data._source.TaxInclusive,
        };
        products.push(product);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

export function getSalesBasedOnTransactionTypes(
  registrationID,
  searchStores,
  transactionTypeIDs,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            terms: {
              "TransactionType.key.keyword": transactionTypeIDs,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
        should: [],
        minimum_should_match: 1,
      },
    },
    aggs: {
      total_sales: {
        sum: {
          field: "BillAmount",
        },
      },
      total_paid_sales: {
        sum: {
          field: "BillPaidAmount",
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "Store.key.keyword": store.key,
      },
    });
  }
  // console.log("getSalesBasedOnTransactionTypes - query", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      const dashBoardHeader = {
        total_sales: Number(
          sanitizedMessage.aggregations.total_sales.value
        ).toFixed(2),
        total_paid_sales: Number(
          sanitizedMessage.aggregations.total_paid_sales.value
        ).toFixed(2),
      };
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: dashBoardHeader,
      };
    });
  };
}

export function getOfferReport(
  registrationID,
  searchStores,
  from,
  size,
  searchTransactionNo,
  searchTransactionFromDate,
  searchTransactionToDate,
  searchCustomerDetail,
  searchProductDetail,
  searchOfferType,
  searchOffer
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            match: {
              OfferType: searchOfferType,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    sort: [
      {
        TransactionDate: {
          order: "desc",
        },
      },
    ],
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomerDetails: "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        ProductDetails: "@" + searchProductDetail.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { TransactionNo: searchTransactionNo },
    });
  }
  if (searchOffer.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { OfferName: searchOffer.toLowerCase() },
    });
  }
  // console.log("searchParameters", JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getDenomDiscounts");

    return addMessage({ text: searchParameters }).then(function(result) {
      const invoices = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: invoices,
      };
    });
  };
}

export function getOfferSummaryReport(
  registrationID,
  searchStores,
  searchOfferType,
  searchOffer
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            match: {
              OfferType: searchOfferType,
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
    aggs: {
      group_by_offer: {
        terms: {
          field: "OfferName.keyword",
          size: 100,
        },
        aggs: {
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_discount: {
            sum: {
              script: {
                source:
                  "doc['SubTotal'].value - doc['SubTotalWithDiscount'].value",
              },
            },
          },
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_transactions: {
            cardinality: {
              field: "TransactionID",
            },
          },
          total_customers: {
            cardinality: {
              field: "CustomerID.keyword",
            },
          },
          sales_bucket_sort: {
            bucket_sort: {
              sort: [
                {
                  total_sales: {
                    order: "desc",
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }

  if (searchOffer.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { OfferName: "@" + searchOffer.toLowerCase() + ".*" },
    });
  }

  console.log("searchParameters", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomDiscounts");
    return addMessage({ text: searchParameters }).then(function(result) {
      const offers = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_offer.buckets.map(
        (data, index) => {
          let offer = {
            name: data.key,
            total_qty: Number(data.total_qty.value).toFixed(2),
            total_sales: Number(data.total_sales.value).toFixed(2),
            total_discount: Number(data.total_discount.value).toFixed(2),
            total_customers: Number(data.total_customers.value).toFixed(2),
            total_transactions: Number(data.total_transactions.value).toFixed(
              2
            ),
          };
          offers.push(offer);
        }
      );
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: offers,
      };
    });
  };
}

export function getBatchData(
  searchStores,
  from,
  size,
  searchBatchNo,
  searchExpiryFromDate,
  searchExpiryToDate,
  searchBrand,
  searchCategory,
  searchSubCategory,
  searchProductSKU,
  searchProductName,
  searchBarCode
) {
  let searchParameters = {
    from: from,
    size: size,
    track_total_hits: true,
    query: {
      bool: {
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      term: {
        "StoreID.keyword": store.key,
      },
    });
  }
  if (
    searchExpiryFromDate &&
    searchExpiryToDate &&
    searchExpiryFromDate !== "" &&
    searchExpiryToDate !== ""
  ) {
    searchParameters.query.bool.filter.bool.must.push({
      range: {
        BatchExpDateValue: {
          lte: searchExpiryToDate,
          gte: searchExpiryFromDate,
          format: "yyyy-MM-dd",
        },
      },
    });
  }
  if (searchBatchNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { BatchNo: searchBatchNo.trim().toLowerCase() },
    });
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductSKU: searchProductSKU.trim().toLowerCase() },
    });
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { ProductName: searchProductName },
    });
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      match_phrase: { Brand: searchBrand.trim().toLowerCase() },
    });
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Category: "@" + searchCategory.trim().toLowerCase() + ".*" },
    });
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        SubCategory: "@" + searchSubCategory.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchBarCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { BarCode: "@" + searchBarCode.trim().toLowerCase() + ".*" },
    });
  }

  // console.log("getBatchData", JSON.stringify(searchParameters));

  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getBatches");
    return addMessage({ text: searchParameters }).then(function(result) {
      const products = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ ...data._source });
      });

      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: products,
      };
    });
  };
}

export function getHSNWiseSummaryReport(
  registrationID,
  searchStores,
  searchTransactionType,
  searchTransactionFromDate,
  searchTransactionToDate,
  showCategory = false
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
          {
            exists: {
              field: "HSNSACCode",
            },
          },
        ],
        must_not: [
          {
            exists: {
              field: "CancellationReason",
            },
          },
          {
            term: {
              "HSNSACCode.keyword": "",
            },
          },
        ],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            should: [],
          },
        },
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              hsn: {
                terms: {
                  field: "HSNSACCode.keyword",
                  order: "asc",
                },
              },
            },
            {
              taxGroup: {
                terms: {
                  field: "TaxGroup.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_taxable: {
            sum: {
              field: "TaxableAmount",
            },
          },
          total_taxes: {
            sum: {
              field: "TotalTax",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
          total_cgst: {
            sum: {
              field: "CGSTAmt",
            },
          },
          total_sgst: {
            sum: {
              field: "SGSTAmt",
            },
          },
          total_igst: {
            sum: {
              field: "IGSTAmt",
            },
          },
        },
      },
    },
  };
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({
      regexp: { TransactionTypeID: searchTransactionType.trim().toLowerCase() },
    });
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      match: {
        StoreID: store.key,
      },
    });
  }
  if (showCategory) {
    searchParameters.aggs.my_buckets.composite.sources.push({
      category: {
        terms: {
          field: "Category.keyword",
        },
      },
    });
  }
  console.log(
    "getHSNWiseSummaryReport - query",
    JSON.stringify(searchParameters)
  );
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("getDenomSales");

    return addMessage({ text: searchParameters }).then(function(result) {
      const hsnSummary = [];

      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data) => {
        if (showCategory) {
          let hsn = {
            "HSN Code": data.key.hsn,
            Category: showCategory ? data.key.category : "",
            "GST Rate %": data.key.taxGroup,
            "Total Qty": Number(data.total_qty.value).toFixed(2),
            "Taxable Amount": Number(data.total_taxable.value).toFixed(2),
            "Total Tax": Number(data.total_taxes.value).toFixed(2),
            CGST: Number(data.total_cgst.value).toFixed(2),
            SGST: Number(data.total_sgst.value).toFixed(2),
            IGST: Number(data.total_igst.value).toFixed(2),
            Total: Number(data.total_sales.value).toFixed(2),
          };
          hsnSummary.push(hsn);
        } else {
          let hsn = {
            "HSN Code": data.key.hsn,
            // "Category": showCategory ? data.key.category : "",
            "GST Rate %": data.key.taxGroup,
            "Total Qty": Number(data.total_qty.value).toFixed(2),
            "Taxable Amount": Number(data.total_taxable.value).toFixed(2),
            "Total Tax": Number(data.total_taxes.value).toFixed(2),
            CGST: Number(data.total_cgst.value).toFixed(2),
            SGST: Number(data.total_sgst.value).toFixed(2),
            IGST: Number(data.total_igst.value).toFixed(2),
            Total: Number(data.total_sales.value).toFixed(2),
          };
          hsnSummary.push(hsn);
        }
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: hsnSummary,
      };
    });
  };
}

export function getProductSoldBasedOnCashRegister(
  registrationID,
  cashRegisterID,
  searchTransactionFromDate,
  searchTransactionToDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            term: {
              RegistrationID: registrationID,
            },
          },
          {
            term: {
              SalesImpacted: true,
            },
          },
          {
            match: {
              CashRegisterID: cashRegisterID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "epoch_millis",
                // "format": "MM/dd/yyyy HH:mm:ss a"
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              category: {
                terms: {
                  field: "Category.keyword",
                  order: "asc",
                },
              },
            },
            {
              sub_category: {
                terms: {
                  field: "SubCategory.keyword",
                  missing_bucket: true,
                  order: "asc",
                },
              },
            },
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                  order: "asc",
                },
              },
            },
            {
              sku: {
                terms: {
                  field: "SKU.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_sales: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };

  return (dispatch, getState) => {
    // console.log("Product Sold - Query", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomSales");
    return addMessage({ text: searchParameters }).then(function(result) {
      const itemSold = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          store: data.key.store,
          category: data.key.category,
          sub_category: data.key.sub_category,
          sku: data.key.sku,
          product: data.key.product,
          productID: data.key.productID,
          supplyprice: data.key.supplyprice,
          retailprice: data.key.retailprice,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_sales: Number(data.total_sales.value).toFixed(2),
        };
        itemSold.push(item);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemSold,
      };
    });
  };
}
