import React, { Component } from "react";
import Switch from "react-switch";
import "../Common/Common.css";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import Input from "../2/Controls/Input";
import ImageButton from "../2/Controls/ImageButton";
import Button from "../2/Controls/Button";

class OmniSettings extends Component {
  constructor() {
    super();
    this.state = {
      allowOrderCancellation: false,
      orderCancellationReasons: [],
      cancellationReason: "",
      orderCancellationAllowedUptil: "1",
      orderCancellationCustomerRemarks: "",
      orderCancellationStatus: "1",
      orderCancellationTime: "",

      showBillingAddress: false,
      captureCustomerLineAddress1: false,
      captureCustomerLineAddress2: false,
      captureCustomerCity: false,
      captureCustomerState: false,
      captureCustomerPincode: false,
      captureCustomerCountry: false,
      enablePincodeChecker: false,
      pincodes: [],
      pincode: "",
      pincodeArea: "",
      pincodeCity: "",
      pincodeState: "",
      pincodeGroup: "",
      pincodeNoOfDeliveryDays: "",

      // shippingOptions: [],
      shippingOption: null,
      loading: false,
      packagingChargeType: "0",
      packagingChargeTypeError: "",
      packagingChargesServiceID: "",
      packagingChargesServiceIDError: "",
      packagingChargeError: "",
      packagingCharge: "",

      shippingType: "0",
      shippingSubType: "",
      nameAtCheckOut: "",
      descriptionForCustomers: "",
      isDisabled: false,
      sequence: 0,
      showContent: false,
      saving: false,
      titleError: "",
      specifyPickupSlot: false,
      // shippingType: "",
      shippingTypeError: "",
      shippingSubType: "",
      shippingSubTypeError: "",
      specifyPickupDate: false,
      day: "",
      pickupType: "",
      selectDayError: "",
      startSlot: "",
      startSlotError: "",
      endSlot: "",
      endSlotError: "",
      availableForPicKup: true,
      slots: [],
      startRange: null,
      startRangeError: "",
      endRange: null,
      endRangeError: "",
      deliveryCharge: null,
      deliveryChargeError: "",
      deliveryRates: [],
      shippingChargesServiceID: "",
      shippingChargesServiceIDError: "",
      notes: "",
      notesError: "",
      specificDateError: "",
      KMSFrom: "",
      KMSFromError: "",
      KMSTo: "",
      KMSToError: "",
      KMSRateType: "",
      KMSRateTypeError: "",
      KMSValue: "",
      KMSValueError: "",
      KMCharges: [],
      flatRate: 0,
      flatRateError: "",
      kmsRounding: "",

      showLineItemNotes: false,
      lineItemNotesMandatory: false,
      lineItemNotesText: "Notes",
      enableCoupon: false,
      enableRemarks: false,
      remarksText: "",
      enableAppointmentForServices: false,
      enableAppointmentEmployeeSelection: false,
    };
    this.renderCancelOptions = this.renderCancelOptions.bind(this);
    this.onSave = this.onSave.bind(this);

    this.renderBillingAddress = this.renderBillingAddress.bind(this);
    this.renderPinCodeChecker = this.renderPinCodeChecker.bind(this);
    this.renderPincodeList = this.renderPincodeList.bind(this);
    this.onAddPincode = this.onAddPincode.bind(this);

    this.addSlots = this.addSlots.bind(this);
    this.addNewCharge = this.addNewCharge.bind(this);
    this.renderDeliveryCharges = this.renderDeliveryCharges.bind(this);
  }

  async componentDidMount() {
    let showBillingAddress = false,
      captureCustomerLineAddress1 = false,
      captureCustomerLineAddress2 = false,
      captureCustomerCity = false,
      captureCustomerState = false,
      captureCustomerPincode = false,
      captureCustomerCountry = false,
      enablePincodeChecker = false,
      pincodes = [];

    let allowOrderCancellation = false;
    let orderCancellationReasons = [];
    let orderCancellationAllowedUptil = "1";
    let orderCancellationCustomerRemarks = "";
    let orderCancellationStatus = "1";
    let orderCancellationTime = "";

    // let shippingOptions = []
    let shippingOption = null;
    let packagingChargeType = "0";
    let packagingCharge = "";
    let packagingChargesServiceID = "";
    let shippingType = "0";

    let showLineItemNotes = false;
    let lineItemNotesText = "Notes";
    let lineItemNotesMandatory = false;

    let enableCoupon = false;
    let enableRemarks = false;
    let remarksText = "Remarks";
    let enableAppointmentForServices = false;
    let enableAppointmentEmployeeSelection = false;

    let checkoutConfig = await this.props.getOmniSettings();

    if (checkoutConfig) {
      allowOrderCancellation = checkoutConfig.hasOwnProperty(
        "AllowOrderCancellation"
      )
        ? checkoutConfig.AllowOrderCancellation
        : false;
      orderCancellationReasons = checkoutConfig.hasOwnProperty(
        "OrderCancellationReasons"
      )
        ? checkoutConfig.OrderCancellationReasons
        : [];
      // allowMultipleOrderCancellationReason = checkoutConfig.hasOwnProperty('AllowMultipleOrderCancellationReason') ? checkoutConfig.AllowMultipleOrderCancellationReason : false
      orderCancellationAllowedUptil = checkoutConfig.hasOwnProperty(
        "OrderCancellationAllowedUptil"
      )
        ? checkoutConfig.OrderCancellationAllowedUptil
        : "1";
      orderCancellationCustomerRemarks = checkoutConfig.hasOwnProperty(
        "OrderCancellationCustomerRemarks"
      )
        ? checkoutConfig.OrderCancellationCustomerRemarks
        : "";
      orderCancellationStatus = checkoutConfig.hasOwnProperty(
        "OrderCancellationStatus"
      )
        ? checkoutConfig.OrderCancellationStatus
        : "1";
      orderCancellationTime = checkoutConfig.hasOwnProperty(
        "OrderCancellationTime"
      )
        ? checkoutConfig.OrderCancellationTime
        : "";
      showBillingAddress = checkoutConfig.hasOwnProperty("ShowBillingAddress")
        ? checkoutConfig.ShowBillingAddress
        : false;
      showLineItemNotes = checkoutConfig.hasOwnProperty("ShowLineItemNotes")
        ? checkoutConfig.ShowLineItemNotes
        : false;
      lineItemNotesText = checkoutConfig.hasOwnProperty("LineItemNotesText")
        ? checkoutConfig.LineItemNotesText
        : "Notes";
      lineItemNotesMandatory = checkoutConfig.hasOwnProperty(
        "LineItemNotesMandatory"
      )
        ? checkoutConfig.LineItemNotesMandatory
        : false;

      enableCoupon = checkoutConfig.hasOwnProperty("EnableCoupon")
        ? checkoutConfig.EnableCoupon
        : false;
      enableRemarks = checkoutConfig.hasOwnProperty("EnableRemarks")
        ? checkoutConfig.EnableRemarks
        : false;
      remarksText = checkoutConfig.hasOwnProperty("RemarksText")
        ? checkoutConfig.RemarksText
        : "Remarks";
      enableAppointmentForServices = checkoutConfig.hasOwnProperty(
        "EnableAppointmentForServices"
      )
        ? checkoutConfig.EnableAppointmentForServices
        : false;
      enableAppointmentEmployeeSelection = checkoutConfig.hasOwnProperty(
        "EnableAppointmentEmployeeSelection"
      )
        ? checkoutConfig.EnableAppointmentEmployeeSelection
        : false;
      let checkoutDetailPageOptions = checkoutConfig.hasOwnProperty(
        "CheckoutDetailPageOptions"
      )
        ? checkoutConfig.CheckoutDetailPageOptions
        : null;
      if (checkoutDetailPageOptions) {
        captureCustomerLineAddress1 =
          checkoutDetailPageOptions.ShowCustomerLineAddress1;
        captureCustomerLineAddress2 =
          checkoutDetailPageOptions.ShowCustomerLineAddress2;
        captureCustomerCity = checkoutDetailPageOptions.ShowCustomerCity;
        captureCustomerState = checkoutDetailPageOptions.ShowCustomerState;
        captureCustomerPincode = checkoutDetailPageOptions.ShowCustomerPinCode;
        captureCustomerCountry = checkoutDetailPageOptions.ShowCustomerCountry;
        enablePincodeChecker = checkoutDetailPageOptions.hasOwnProperty(
          "EnablePincodeChecker"
        )
          ? checkoutDetailPageOptions.EnablePincodeChecker
          : false;
      }
      if (enablePincodeChecker) {
        pincodes = await this.props.getPincodes();
      }

      if (checkoutConfig && checkoutConfig.hasOwnProperty("ShippingOption")) {
        shippingOption = checkoutConfig.ShippingOption;
      }
      packagingChargeType =
        checkoutConfig && checkoutConfig.hasOwnProperty("PackagingChargeType")
          ? checkoutConfig.PackagingChargeType
          : "0";
      packagingCharge =
        checkoutConfig && checkoutConfig.hasOwnProperty("PackagingCharge")
          ? checkoutConfig.PackagingCharge
          : "";
      packagingChargesServiceID =
        checkoutConfig &&
        checkoutConfig.hasOwnProperty("PackagingChargesServiceID")
          ? checkoutConfig.PackagingChargesServiceID
          : "";

      if (shippingOption) {
        let slots = [];
        for (let i = 0; i < shippingOption.DeliveryOperatingHrs.length; i++) {
          const deliveryOperatingHr = shippingOption.DeliveryOperatingHrs[i];

          for (let j = 0; j < deliveryOperatingHr.Slots.length; j++) {
            const deliverySlot = deliveryOperatingHr.Slots[j];

            let timeArray = deliverySlot.Slots.split("-");

            let slot = {
              Day: deliveryOperatingHr.Day,
              StartSlot: deliverySlot.Slot,
              EndSlot: timeArray[1],
              AvailableForPickup: deliverySlot.AvailableForPickup,
              Slots: deliverySlot.Slots,
            };

            slots.push(slot);
          }
        }
        shippingType = shippingOption.hasOwnProperty("ShippingType")
          ? shippingOption.ShippingType
          : "";
        this.setState({
          deliveryRates: shippingOption.DeliveryRates,
          specifyPickupSlot: shippingOption.SpecifyPickupSlot,
          specifyPickupDate: shippingOption.SpecifyPickupDate,
          notes: shippingOption.Notes,
          shippingChargesServiceID: shippingOption.ShippingChargesServiceID,
          flatRate: shippingOption.hasOwnProperty("FlatRate")
            ? shippingOption.FlatRate
            : 0,
          slots: slots,
          KMCharges: shippingOption.hasOwnProperty("KMCharges")
            ? shippingOption.KMCharges
            : [],
          kmsRounding: shippingOption.hasOwnProperty("KMSRounding")
            ? shippingOption.KMSRounding
            : "",
        });
      }
    }

    this.setState({
      allowOrderCancellation: allowOrderCancellation,
      orderCancellationReasons: orderCancellationReasons,
      orderCancellationAllowedUptil: orderCancellationAllowedUptil,
      orderCancellationCustomerRemarks: orderCancellationCustomerRemarks,
      orderCancellationStatus: orderCancellationStatus,
      orderCancellationTime: orderCancellationTime,
      showBillingAddress: showBillingAddress,
      captureCustomerLineAddress1: captureCustomerLineAddress1,
      captureCustomerLineAddress2: captureCustomerLineAddress2,
      captureCustomerCity: captureCustomerCity,
      captureCustomerState: captureCustomerState,
      captureCustomerPincode: captureCustomerPincode,
      captureCustomerCountry: captureCustomerCountry,
      enablePincodeChecker: enablePincodeChecker,
      pincodes: pincodes,
      shippingOption: shippingOption,
      // shippingOptions: shippingOptions,
      packagingChargesServiceID: packagingChargesServiceID,
      packagingChargeType:
        packagingChargeType !== "" ? packagingChargeType : "0",
      packagingCharge: packagingCharge,
      shippingType: shippingType !== "" ? shippingType : "0",
      showLineItemNotes: showLineItemNotes,
      lineItemNotesText: lineItemNotesText,
      lineItemNotesMandatory: lineItemNotesMandatory,

      enableCoupon: enableCoupon,
      enableRemarks: enableRemarks,
      remarksText: remarksText,
      enableAppointmentForServices: enableAppointmentForServices,
      enableAppointmentEmployeeSelection: enableAppointmentEmployeeSelection,
    });
  }

  onAddPincode() {
    // console.log('this.state.pincode',this.state.pincode)
    if (this.state.pincode === "") {
      this.setState({ pincodeError: "Please provide pincode" });
      return;
    }
    let pincodes = this.state.pincodes;
    if (
      pincodes.length > 0 &&
      pincodes.filter((f) => f.Pincode === this.state.pincode).length > 0
    ) {
      this.setState({ pincodeError: "Pincode already exists" });
      return;
    }
    pincodes.push({
      Pincode: this.state.pincode,
      Area: this.state.pincodeArea,
      City: this.state.pincodeCity,
      State: this.state.pincodeState,
      Group: this.state.pincodeGroup,
      NoOfDeliveryDays: this.state.pincodeNoOfDeliveryDays,
    });
    // console.log('pincodes',pincodes)
    this.setState({
      pincodes: pincodes,
      pincode: "",
      pincodeCity: "",
      pincodeState: "",
      pincodeNoOfDeliveryDays: "",
      pincodeError: "",
    });
  }

  async onSave() {
    let packagingChargeError = "";
    let packagingChargeTypeError = "";
    let packagingChargesServiceIDError = "";

    let shippingTypeError = "";
    let startRangeError = "";
    let endRangeError = "";
    let deliveryChargeError = "";
    let notesError = "";
    let specificDateError = "";
    let shippingChargesServiceIDError = "";
    let selectDayError = "";
    let startSlotError = "";
    let endSlotError = "";

    if (this.state.packagingChargeType === "") {
      packagingChargeTypeError = "Please select packaging charge type";
    }
    if (
      this.state.packagingCharge === "" &&
      (this.state.packagingChargeType === "1" ||
        this.state.packagingChargeType === "2")
    ) {
      packagingChargeError = "Please provide value";
    }
    if (
      (this.state.packagingChargeType === "1" ||
        this.state.packagingChargeType === "2") &&
      this.state.packagingChargesServiceID === ""
    ) {
      packagingChargesServiceIDError = "Please select option";
    }

    if (this.state.shippingType === "") {
      shippingTypeError = "Please select Shipping/Delivery Type";
    }
    if (
      this.state.shippingType !== "" &&
      this.state.shippingType.toString() !== "0"
    ) {
      if (this.state.notes.trim() === "") {
        notesError = "Please enter Delivery/Shipping Notes";
      }
      if (this.state.shippingChargesServiceID === "") {
        shippingChargesServiceIDError = "Please select Service to be Mapped";
      }
    }

    if (this.state.specifyPickupSlot && this.state.slots.length === 0) {
      selectDayError = "Please select Day";
      startSlotError = "Please select Start Slot";
      endSlotError = "Please select End Slot";
    }

    if (
      packagingChargesServiceIDError !== "" ||
      packagingChargeTypeError !== "" ||
      packagingChargeError !== "" ||
      shippingTypeError !== "" ||
      startRangeError !== "" ||
      endRangeError !== "" ||
      deliveryChargeError !== "" ||
      notesError != "" ||
      shippingChargesServiceIDError !== "" ||
      specificDateError !== "" ||
      selectDayError !== "" ||
      startSlotError !== "" ||
      endSlotError !== ""
    ) {
      this.setState({
        packagingChargeTypeError: packagingChargeTypeError,
        packagingChargeError: packagingChargeError,
        packagingChargesServiceIDError: packagingChargesServiceIDError,

        shippingTypeError: shippingTypeError,
        startRangeError: startRangeError,
        endRangeError: endRangeError,
        deliveryChargeError: deliveryChargeError,
        notesError: notesError,
        shippingChargesServiceIDError: shippingChargesServiceIDError,
        specificDateError: specificDateError,
        selectDayError: selectDayError,
        startSlotError: startSlotError,
        endSlotError: endSlotError,
      });
      return;
    }

    let checkoutConfig = await this.props.getOmniSettings();
    if (!checkoutConfig) {
      checkoutConfig = {};
    }

    checkoutConfig.ShowBillingAddress = this.state.showBillingAddress;
    checkoutConfig.CheckoutDetailPageOptions = {
      ShowCustomerLineAddress1: this.state.captureCustomerLineAddress1,
      ShowCustomerLineAddress2: this.state.captureCustomerLineAddress2,
      ShowCustomerCity: this.state.captureCustomerCity,
      ShowCustomerState: this.state.captureCustomerState,
      ShowCustomerPinCode: this.state.captureCustomerPincode,
      ShowCustomerCountry: this.state.captureCustomerCountry,
      EnablePincodeChecker: this.state.enablePincodeChecker,
    };

    checkoutConfig.AllowOrderCancellation = this.state.allowOrderCancellation;
    checkoutConfig.OrderCancellationReasons = this.state.orderCancellationReasons;
    checkoutConfig.OrderCancellationAllowedUptil = this.state.orderCancellationAllowedUptil;
    checkoutConfig.OrderCancellationCustomerRemarks = this.state.orderCancellationCustomerRemarks;
    checkoutConfig.OrderCancellationStatus = this.state.orderCancellationStatus;
    checkoutConfig.OrderCancellationTime = this.state.orderCancellationTime;

    let deliveryRates = this.state.deliveryRates;
    let shippingType = this.state.shippingType;
    let specifyPickupSlot = this.state.specifyPickupSlot;
    let specifyPickupDate = this.state.specifyPickupDate;
    let notes = this.state.notes;
    let shippingChargesServiceID = this.state.shippingChargesServiceID;
    let KMCharges = this.state.KMCharges;
    let flatRate = this.state.flatRate;
    let deliveryOperatingHrs = [];
    for (let i = 0; i < this.state.slots.length; i++) {
      const slot = this.state.slots[i];

      if (deliveryOperatingHrs.filter((f) => f.Day === slot.Day).length > 0) {
        deliveryOperatingHrs
          .filter((f) => f.Day === slot.Day)[0]
          .Slots.push({
            Slots: slot.Slots,
            AvailableForPickup: slot.AvailableForPickup,
            Slot: slot.StartSlot,
          });
      } else {
        let deliveryOperatingHr = {
          Day: slot.Day,
          Slots: [],
        };

        deliveryOperatingHr.Slots.push({
          Slots: slot.Slots,
          AvailableForPickup: slot.AvailableForPickup,
          Slot: slot.StartSlot,
        });

        deliveryOperatingHrs.push(deliveryOperatingHr);
      }
    }

    if (this.state.shippingType === "0") {
      deliveryRates = [];
      specifyPickupSlot = false;
      specifyPickupDate = false;
      notes = "";
      shippingChargesServiceID = "";
      KMCharges = [];
      flatRate = 0;
      deliveryOperatingHrs = [];
    }

    let shippingOption = {
      DeliveryRates: deliveryRates,
      ShippingType: shippingType,
      SpecifyPickupSlot: specifyPickupSlot,
      SpecifyPickupDate: specifyPickupDate,
      Notes: notes,
      ShippingChargesServiceID: shippingChargesServiceID,
      KMCharges: KMCharges,
      FlatRate: flatRate,
      DeliveryOperatingHrs: deliveryOperatingHrs,
      KMSRounding: this.state.kmsRounding,
    };
    checkoutConfig.ShippingOption = shippingOption;

    checkoutConfig.PackagingChargeType = this.state.packagingChargeType;
    if (this.state.packagingChargeType === "0") {
      checkoutConfig.PackagingCharge = "";
      checkoutConfig.PackagingChargesServiceID = "";
    } else {
      checkoutConfig.PackagingCharge = this.state.packagingCharge;
      checkoutConfig.PackagingChargesServiceID = this.state.packagingChargesServiceID;
    }

    checkoutConfig.ShowLineItemNotes = this.state.showLineItemNotes;
    checkoutConfig.LineItemNotesMandatory = this.state.lineItemNotesMandatory;
    checkoutConfig.LineItemNotesText = this.state.lineItemNotesText;

    checkoutConfig.EnableCoupon = this.state.enableCoupon;
    checkoutConfig.EnableRemarks = this.state.enableRemarks;
    checkoutConfig.RemarksText = this.state.remarksText;

    checkoutConfig.EnableAppointmentEmployeeSelection = this.state.enableAppointmentEmployeeSelection;
    checkoutConfig.EnableAppointmentForServices = this.state.enableAppointmentForServices;

    console.log("checkoutConfig", JSON.stringify(checkoutConfig));
    await this.props.saveOmniSettings(checkoutConfig);
    this.props.getToastr("Data saved successfully");
  }

  renderCancelOptions() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Allow customer to cancel order &nbsp;</label>
              <div className="input-group">
                <Switch
                  checked={this.state.allowOrderCancellation}
                  onChange={() => {
                    this.setState({
                      allowOrderCancellation: !this.state
                        .allowOrderCancellation,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.allowOrderCancellation && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Order cancelation option available &nbsp;</label>
                  <div style={{ display: "flex" }}>
                    <select
                      className="form-control"
                      style={{ width: "300px" }}
                      value={this.state.orderCancellationAllowedUptil}
                      onChange={(evt) => {
                        // console.log(' evt.target.value', evt.target.value)
                        this.setState({
                          orderCancellationAllowedUptil: evt.target.value,
                        });
                      }}
                    >
                      <option value={1}>Upto a particular time</option>
                      <option value={2}>Upto a particular status</option>
                    </select>
                    {this.state.orderCancellationAllowedUptil === "1" && (
                      <div style={{ width: "200px", display: "flex" }}>
                        <input
                          style={{ width: "100px" }}
                          type="number"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({
                              orderCancellationTime: e.target.value,
                            })
                          }
                        />
                        <div style={{ padding: "5px" }}>hrs</div>
                      </div>
                    )}
                    {this.state.orderCancellationAllowedUptil === "2" && (
                      <React.Fragment>
                        <select
                          className="form-control"
                          style={{ width: "200px" }}
                          value={this.state.orderCancellationStatus}
                          onChange={(evt) =>
                            this.setState({
                              orderCancellationStatus: evt.target.value,
                            })
                          }
                        >
                          <option value="1">Pending</option>
                          <option value="2">Confirmed</option>
                          <option value="3">Out For delivery</option>
                          <option value="4">Delivered</option>
                        </select>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Cancellation reason(s) &nbsp;</label>
                  <table className="table table-bordered table-striped">
                    <tbody>
                      <tr>
                        <td>
                          <input
                            className="form-control"
                            value={this.state.cancellationReason}
                            onChange={(e) =>
                              this.setState({
                                cancellationReason: e.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-md btn-default btn-flat"
                            onClick={() => {
                              if (this.state.cancellationReason.trim() !== "") {
                                let orderCancellationReasons = this.state
                                  .orderCancellationReasons;
                                orderCancellationReasons.push({
                                  Reason: this.state.cancellationReason,
                                });
                                this.setState({
                                  orderCancellationReasons: orderCancellationReasons,
                                  cancellationReason: "",
                                });
                              }
                            }}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                      {this.state.orderCancellationReasons.map((c, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              className="form-control"
                              value={c.Reason}
                              onChange={(e) => {
                                let orderCancellationReasons = this.state
                                  .orderCancellationReasons;
                                orderCancellationReasons[index].Reason =
                                  e.target.value;
                                this.setState({
                                  orderCancellationReasons: orderCancellationReasons,
                                  cancellationReason: "",
                                });
                              }}
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-md btn-default btn-flat"
                              onClick={() => {
                                let orderCancellationReasons = this.state
                                  .orderCancellationReasons;
                                orderCancellationReasons.splice(index, 1);
                                this.setState({
                                  orderCancellationReasons: orderCancellationReasons,
                                  cancellationReason: "",
                                });
                              }}
                            >
                              <i className="fa fa-remove"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* {this.state.orderCancellationReasons.length> 0 &&  <div className="row">   
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Allow customer to specify more than one cancellation reason &nbsp;</label>
                        <div className="input-group">
                        <Switch
                            checked={this.state.allowMultipleOrderCancellationReason}
                            onChange={() => { this.setState({ allowMultipleOrderCancellationReason: !this.state.allowMultipleOrderCancellationReason }) }}
                        />
                        </div>
                    </div>
                </div>
            </div>} */}
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>Capture customer remarks&nbsp;</label>
                  <div className="input-group">
                    <Switch
                      checked={this.state.orderCancellationCustomerRemarks}
                      onChange={() => {
                        this.setState({
                          orderCancellationCustomerRemarks: !this.state
                            .orderCancellationCustomerRemarks,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderPincodeList() {
    return (
      <React.Fragment>
        {this.state.enablePincodeChecker && (
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Pincode</th>
                    <th>Area</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Group</th>
                    <th>Delivery within days</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ pincode: evt.target.value })
                        }
                        value={this.state.pincode}
                      />
                      {this.state.pincodeError !== "" && (
                        <span className="help-block">
                          {this.state.pincodeError}
                        </span>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ pincodeArea: evt.target.value })
                        }
                        value={this.state.pincodeArea}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ pincodeCity: evt.target.value })
                        }
                        value={this.state.pincodeCity}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ pincodeState: evt.target.value })
                        }
                        value={this.state.pincodeState}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({ pincodeGroup: evt.target.value })
                        }
                        value={this.state.pincodeGroup}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.setState({
                            pincodeNoOfDeliveryDays: evt.target.value,
                          })
                        }
                        value={this.state.pincodeNoOfDeliveryDays}
                      />
                    </td>
                    <td>
                      <button
                        type="submit"
                        className="btn btn-md btn-default btn-flat"
                        onClick={this.onAddPincode}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                  {this.state.pincodes.map((p, index) => (
                    <tr key={"pincode_" + index}>
                      <td>{p.Pincode}</td>
                      <td>{p.Area}</td>
                      <td>{p.City}</td>
                      <td>{p.State}</td>
                      <td>{p.Group}</td>
                      <td>{p.NoOfDeliveryDays}</td>
                      <td>
                        <button
                          type="submit"
                          className="btn btn-md btn-default btn-flat"
                          onClick={() => {
                            let pincodes = this.state.pincodes;
                            pincodes.splice(index, 1);
                            this.setState({ pincodes: pincodes });
                          }}
                        >
                          <i className="fa fa-remove"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderBillingAddress() {
    return (
      <React.Fragment>
        {this.state.showBillingAddress && (
          <React.Fragment>
            <Input
              type="switch"
              label="Line Address 1"
              value={this.state.captureCustomerLineAddress1}
              onChange={(value) => {
                this.setState({ captureCustomerLineAddress1: value });
              }}
            />
            <Input
              type="switch"
              label="Line Address 2"
              value={this.state.captureCustomerLineAddress2}
              onChange={(value) => {
                this.setState({ captureCustomerLineAddress2: value });
              }}
            />
            <Input
              type="switch"
              label="City"
              value={this.state.captureCustomerCity}
              onChange={(value) => {
                this.setState({ captureCustomerCity: value });
              }}
            />
            <Input
              type="switch"
              label="State"
              value={this.state.captureCustomerState}
              onChange={(value) => {
                this.setState({ captureCustomerState: value });
              }}
            />
            <Input
              type="switch"
              label="Country"
              value={this.state.captureCustomerCountry}
              onChange={(value) => {
                this.setState({ captureCustomerCountry: value });
              }}
            />
            <Input
              type="switch"
              label="Pincode"
              value={this.state.captureCustomerPincode}
              onChange={(value) => {
                this.setState({ captureCustomerPincode: value });
              }}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderPinCodeChecker() {
    return (
      <React.Fragment>
        {this.state.captureCustomerPincode && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4">
                <Input
                  type="switch"
                  label="Enable pincode checker"
                  value={this.state.enablePincodeChecker}
                  onChange={(value) => {
                    this.setState({ enablePincodeChecker: value });
                  }}
                />
              </div>
            </div>
            {this.renderPincodeList()}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderPackagingCharges() {
    return (
      <React.Fragment>
        <div className="box">
          <div className="box-header">
            <label>Packaging Charges</label>
          </div>
          <div className="box-content">
            <div className="row">
              <div className="col-md-3">
                <Input
                  type="select"
                  label="Packaging charge"
                  value={this.state.packagingChargeType}
                  error={this.state.packagingChargeTypeError}
                  onChange={(value) => {
                    // console.log('value', value);
                    this.setState({
                      packagingChargeType: value,
                      packagingChargeTypeError: "",
                    });
                  }}
                >
                  {/* <option value="">Please select</option> */}
                  <option value={0}>None</option>
                  <option value={1}>Fixed amount</option>
                  <option value={2}>% of order value</option>
                  <option value={3}>Product level</option>
                </Input>
              </div>
            </div>
            {(this.state.packagingChargeType === "1" ||
              this.state.packagingChargeType === "2") && (
              <div className="row">
                <div className="col-md-3">
                  <Input
                    type="number"
                    label={
                      this.state.packagingChargeType === "1" ? "Amount" : "%"
                    }
                    error={this.state.packagingChargeError}
                    onChange={(value) => {
                      this.setState({
                        packagingCharge: value,
                        packagingChargeError: "",
                      });
                    }}
                    value={this.state.packagingCharge}
                  />
                </div>
              </div>
            )}
            {this.state.packagingChargeType !== "" &&
              this.state.packagingChargeType !== "0" && (
                <div className="row">
                  <div className="col-md-3 col-xs-12">
                    <Input
                      type="select"
                      label="Service to which packaging charges will be mapped when sales transaction is created"
                      value={this.state.packagingChargesServiceID}
                      onChange={(value) =>
                        this.setState({
                          packagingChargesServiceIDError: "",
                          packagingChargesServiceID: value,
                        })
                      }
                      error={this.state.packagingChargesServiceIDError}
                    >
                      <option value="">Please select</option>
                      {this.props.config.Products.filter(
                        (f) => !f.IsDeleted && f.ProductTypeID === 3
                      ).map((service, index) => (
                        <option value={service.key} key={index}>
                          {service.Name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  addSlots() {
    let selectDayError = "";
    let startSlotError = "";
    let endSlotError = "";
    let re = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
    if (this.state.day === "") {
      selectDayError = "Please Select Day";
    }
    if (!this.state.startSlot.match(re)) {
      startSlotError = "Please enter valid Slot Start";
    }
    if (!this.state.endSlot.match(re)) {
      endSlotError = "Please enter valid Slot End";
    }

    if (selectDayError !== "" || startSlotError !== "" || endSlotError !== "") {
      this.setState({
        selectDayError: selectDayError,
        startSlotError: startSlotError,
        endSlotError: endSlotError,
      });
      return;
    }

    let slots = this.state.slots;
    let slot = {
      Day: this.state.day,
      StartSlot: this.state.startSlot,
      EndSlot: this.state.endSlot,
      AvailableForPickup: this.state.availableForPicKup,
      Slots: this.state.startSlot.trim() + " - " + this.state.endSlot.trim(),
    };
    slots.push(slot);

    this.setState({
      slots: slots,
      day: "",
      startSlot: "",
      endSlot: "",
      availableForPicKup: true,
      selectDayError: "",
      startSlotError: "",
      endSlotError: "",
    });
  }

  addNewCharge() {
    let startRangeError = "";
    let endRangeError = "";
    let deliveryChargeError = "";

    if (!this.state.startRange) {
      startRangeError = "Please enter Order Value From";
    }

    if (!this.state.endRange) {
      endRangeError = "Please enter Order Value To";
    }

    if (!this.state.deliveryCharge) {
      deliveryChargeError = "Please enter Delivery Charge";
    }

    if (Number(this.state.startRange) < 0) {
      startRangeError = "Order Value From should be greater than zero";
    }

    if (Number(this.state.startRange) > Number(this.state.endRange)) {
      startRangeError = "Order Value From must be smaller than Order Value To";
    }

    if (Number(this.state.endRange) < 0) {
      endRangeError = "Order Value To should be greater than zero";
    }

    if (
      startRangeError !== "" ||
      endRangeError !== "" ||
      deliveryChargeError !== ""
    ) {
      this.setState({
        startRangeError: startRangeError,
        endRangeError: endRangeError,
        deliveryChargeError: deliveryChargeError,
      });
      return;
    }

    let deliveryRates = this.state.deliveryRates;
    let charge = {
      StartRange: Number(this.state.startRange),
      EndRange: Number(this.state.endRange),
      DeliveryCharge: Number(this.state.deliveryCharge),
    };
    deliveryRates.push(charge);
    this.setState({
      deliveryRates: deliveryRates,
      startRange: "",
      endRange: "",
      deliveryCharge: "",
      startRangeError: "",
      endRangeError: "",
      deliveryChargeError: "",
    });
  }

  addKMS() {
    let KMSFromError = "";
    let KMSToError = "";
    let KMSRateTypeError = "";
    let KMSValueError = "";
    if (this.state.KMSFrom.trim() === "") {
      KMSFromError = "Please provide value";
    }
    if (this.state.KMSTo.trim() === "") {
      KMSToError = "Please provide value";
    }
    if (this.state.KMSRateType.trim() === "") {
      KMSRateTypeError = "Please select value";
    }
    if (this.state.KMSValue.trim() === "") {
      KMSValueError = "Please provide value";
    }
    if (
      KMSFromError !== "" ||
      KMSToError !== "" ||
      KMSRateTypeError !== "" ||
      KMSValueError !== ""
    ) {
      this.setState({
        KMSFromError: KMSFromError,
        KMSToError: KMSToError,
        KMSRateTypeError: KMSRateTypeError,
        KMSValueError: KMSValueError,
      });
      return;
    }
    let KMCharges = this.state.KMCharges;
    KMCharges.push({
      KMSFrom: this.state.KMSFrom,
      KMSTo: this.state.KMSTo,
      KMSRateType: this.state.KMSRateType,
      KMSValue: this.state.KMSValue,
    });
    this.setState({
      KMCharges: KMCharges,
      KMSFrom: "",
      KMSFromError: "",
      KMSTo: "",
      KMSToError: "",
      KMSRateType: "",
      KMSRateTypeError: "",
      KMSValue: "",
      KMSValueError: "",
    });
  }

  renderKMSSlot() {
    return (
      <React.Fragment>
        <table className="table  table-striped">
          <thead>
            <tr>
              <th style={{ width: "150px" }}>KMS From</th>
              <th style={{ width: "150px" }}>KMS To</th>
              <th style={{ width: "150px" }}>Type</th>
              <th style={{ width: "150px" }}>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Input
                  // label="KMS From"
                  type="number"
                  onChange={(value) =>
                    this.setState({ KMSFrom: value, KMSFromError: "" })
                  }
                  value={this.state.KMSFrom}
                  error={this.state.KMSFromError}
                />
              </td>
              <td>
                <Input
                  // label="KMS To"
                  type="number"
                  onChange={(value) =>
                    this.setState({ KMSTo: value, KMSToError: "" })
                  }
                  value={this.state.KMSTo}
                  error={this.state.KMSToError}
                />
              </td>
              <td>
                <Input
                  type="select"
                  // label="Type"
                  error={this.state.KMSRateTypeError}
                  onChange={(value) =>
                    this.setState({ KMSRateType: value, KMSRateTypeError: "" })
                  }
                >
                  <option value="">Select</option>
                  <option value={1}>Fixed rate</option>
                  <option value={2}>Per KM</option>
                </Input>
              </td>
              <td>
                <Input
                  type="number"
                  // label="Value"
                  onChange={(value) =>
                    this.setState({ KMSValue: value, KMSValueError: "" })
                  }
                  value={this.state.KMSValue}
                  error={this.state.KMSValueError}
                />
              </td>
              <td>
                <ImageButton
                  type="add"
                  onClick={() => {
                    this.addKMS();
                  }}
                />
              </td>
              <td></td>
            </tr>
            {this.state.KMCharges.map((charge, index) => (
              <tr key={index}>
                <td>
                  <Input
                    type="number"
                    onChange={(value) => {
                      let KMCharges = this.state.KMCharges;
                      KMCharges[index].KMSFrom = value;
                      this.setState({ KMCharges: KMCharges });
                    }}
                    value={charge.KMSFrom}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    onChange={(value) => {
                      let KMCharges = this.state.KMCharges;
                      KMCharges[index].KMSTo = value;
                      this.setState({ KMCharges: KMCharges });
                    }}
                    value={charge.KMSTo}
                  />
                </td>
                <td>
                  <Input
                    type="select"
                    value={charge.KMSRateType}
                    onChange={(value) => {
                      let KMCharges = this.state.KMCharges;
                      KMCharges[index].KMSRateType = value;
                      this.setState({ KMCharges: KMCharges });
                    }}
                  >
                    <option value="">Select</option>
                    <option value={1}>Fixed rate</option>
                    <option value={2}>Per KM</option>
                  </Input>
                </td>
                <td>
                  <Input
                    type="number"
                    onChange={(value) => {
                      let KMCharges = this.state.KMCharges;
                      KMCharges[index].KMSValue = value;
                      this.setState({ KMCharges: KMCharges });
                    }}
                    value={charge.KMSValue}
                  />
                </td>
                <td>
                  <ImageButton
                    type="remove"
                    onClick={() => {
                      let KMCharges = this.state.KMCharges;
                      KMCharges.splice(index, 1);
                      this.setState({ KMCharges: KMCharges });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderDeliverySlots() {
    return (
      <React.Fragment>
        {this.state.specifyPickupSlot === true && (
          <div className="row">
            <div className="col-md-6">
              <table className="table  table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "150px" }}>Day</th>
                    <th style={{ width: "150px" }}>Slot Start</th>
                    <th style={{ width: "150px" }}>Slot End</th>
                    <th style={{ width: "150px" }}>Available</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        type="select"
                        error={this.state.selectDayError}
                        onChange={(value) =>
                          this.setState({ day: value, selectDayError: "" })
                        }
                      >
                        <option value="">Select Day</option>
                        <option value="Sunday">Sunday</option>
                        <option value="Monday">Monday</option>
                        <option value="Tuesday">Tuesday</option>
                        <option value="Wednesday">Wednesday</option>
                        <option value="Thursday">Thursday</option>
                        <option value="Friday">Friday</option>
                        <option value="Saturday">Saturday</option>
                      </Input>
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="00:00 PM/AM"
                        onChange={(value) =>
                          this.setState({
                            startSlot: value,
                            startSlotError: "",
                          })
                        }
                        value={this.state.startSlot}
                        error={this.state.startSlotError}
                      />
                    </td>
                    <td>
                      <Input
                        type="text"
                        placeholder="00:00 PM/AM"
                        onChange={(value) =>
                          this.setState({ endSlot: value, endSlotError: "" })
                        }
                        value={this.state.endSlot}
                        error={this.state.endSlotError}
                      />
                    </td>
                    <td>
                      <Button
                        type="primary"
                        text="Add"
                        onClick={() => {
                          this.addSlots();
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                  {this.state.slots.map((slot, index) => (
                    <tr key={index}>
                      <td>
                        <Input
                          type="select"
                          value={slot.Day}
                          onChange={(value) => {
                            let slots = this.state.slots;
                            slots[index].Day = value;
                            this.setState({ slots: slots });
                          }}
                        >
                          <option value="Sunday">Sunday</option>
                          <option value="Monday">Monday</option>
                          <option value="Tuesday"> Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                          <option value="Saturday"> Saturday </option>
                        </Input>
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={(value) => {
                            let slots = this.state.slots;
                            slots[index].StartSlot = value;
                            slots[index].Slot = value;
                            slots[index].Slots =
                              value.trim() +
                              " - " +
                              slots[index].EndSlot.trim();
                            this.setState({ slots: slots });
                          }}
                          value={slot.StartSlot}
                        />
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={(e) => {
                            let slots = this.state.slots;
                            slots[index].EndSlot = e.target.value;
                            slots[index].Slots =
                              slots[index].StartSlot.trim() +
                              " - " +
                              e.target.value.trim();
                            this.setState({ slots: slots });
                          }}
                          value={slot.EndSlot}
                        />
                      </td>
                      <td>
                        <Switch
                          checked={slot.AvailableForPickup}
                          onChange={(e) => {
                            let slots = this.state.slots;
                            slots[
                              index
                            ].AvailableForPickup = !slot.AvailableForPickup;
                            this.setState({ slots: slots });
                          }}
                        />
                      </td>
                      <td>
                        <ImageButton
                          type="remove"
                          onClick={() => {
                            let slots = this.state.slots;
                            slots.splice(index, 1);
                            this.setState({ slots: slots });
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderOrderValue() {
    return (
      <React.Fragment>
        <table className="table  table-striped">
          <thead>
            <tr>
              <th style={{ width: "150px" }}>Order Value From</th>
              <th style={{ width: "150px" }}>Order Value To</th>
              <th style={{ width: "150px" }}> Delivery Charge</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Input
                  type="number"
                  value={this.state.startRange}
                  error={this.state.startRangeError}
                  onChange={(value) => {
                    this.setState({ startRange: value, startRangeError: "" });
                  }}
                />
              </td>
              <td>
                <Input
                  type="number"
                  value={this.state.endRange}
                  error={this.state.endRangeError}
                  onChange={(value) => {
                    this.setState({ endRange: value, endRangeError: "" });
                  }}
                />
              </td>
              <td>
                <Input
                  type="number"
                  value={this.state.deliveryCharge}
                  error={this.state.deliveryChargeError}
                  onChange={(value) => {
                    this.setState({
                      deliveryCharge: value,
                      deliveryChargeError: "",
                    });
                  }}
                />
              </td>
              <td>
                <ImageButton
                  type="add"
                  onClick={() => {
                    this.addNewCharge();
                  }}
                />
              </td>
            </tr>
            {this.state.deliveryRates.map((charge, index) => (
              <tr key={index}>
                <td>
                  <Input
                    type="number"
                    onChange={(value) => {
                      let deliveryRates = this.state.deliveryRates;
                      deliveryRates[index].StartRange = value;
                      this.setState({ deliveryRates: deliveryRates });
                    }}
                    value={charge.StartRange}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    onChange={(value) => {
                      let deliveryRates = this.state.deliveryRates;
                      deliveryRates[index].EndRange = value;
                      this.setState({ deliveryRates: deliveryRates });
                    }}
                    value={charge.EndRange}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    onChange={(value) => {
                      let deliveryRates = this.state.deliveryRates;
                      deliveryRates[index].DeliveryCharge = value;
                      this.setState({ deliveryRates: deliveryRates });
                    }}
                    value={charge.DeliveryCharge}
                  />
                </td>
                <td>
                  <ImageButton
                    type="remove"
                    onClick={() => {
                      let deliveryRates = this.state.deliveryRates;
                      deliveryRates.splice(index, 1);
                      this.setState({ deliveryRates: deliveryRates });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderDeliveryCharges() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-3">
            <Input
              type="select"
              label="Delivery charge"
              value={this.state.shippingType}
              error={this.state.shippingTypeError}
              onChange={(value) => {
                this.setState({ shippingType: value, shippingTypeError: "" });
              }}
            >
              {/* <option value="">Please select</option> */}
              <option value={0}>None</option>
              <option value={1}>Flat rate</option>
              <option value={2}>Order value</option>
              <option value={3}>Pincode</option>
              <option value={4}>KMS</option>
            </Input>
          </div>
        </div>
        {this.state.shippingType !== "" && this.state.shippingType !== "0" && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <Input
                  type="text"
                  label="Delivery notes shown to customer at the time of checkout"
                  value={this.state.notes}
                  onChange={(value) =>
                    this.setState({ notesError: "", notes: value })
                  }
                  error={this.state.notesError}
                />
              </div>
            </div>
            {this.state.shippingType === "1" && (
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <Input
                    type="number"
                    label="Flat rate"
                    value={this.state.flatRate}
                    onChange={(value) =>
                      this.setState({ flatRateError: "", flatRate: value })
                    }
                    error={this.state.flatRateError}
                  />
                </div>
              </div>
            )}
            {this.state.shippingType === "2" && (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  {this.renderOrderValue()}
                </div>
              </div>
            )}
            {this.state.shippingType === "4" && (
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <Input
                    type="select"
                    label="Rounding off amount"
                    value={this.state.kmsRounding}
                    onChange={(value) => {
                      this.setState({ kmsRounding: value });
                    }}
                  >
                    <option value="">Please select</option>
                    <option value={0}>No decimal values</option>
                    <option value={1}>Upto 2 decimal values</option>
                    <option value={2}>Nearest 10</option>
                  </Input>
                </div>
              </div>
            )}
            {this.state.shippingType === "4" && (
              <div className="row">
                <div className="col-md-6 col-xs-12">{this.renderKMSSlot()}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <Input
                  type="select"
                  label="Service to which delivery charges will be mapped when sales transaction is created"
                  value={this.state.shippingChargesServiceID}
                  onChange={(value) =>
                    this.setState({
                      shippingChargesServiceIDError: "",
                      shippingChargesServiceID: value,
                    })
                  }
                  error={this.state.shippingChargesServiceIDError}
                >
                  <option value="">Please select</option>
                  {this.props.config.Products.filter(
                    (f) => !f.IsDeleted && f.ProductTypeID === 3
                  ).map((service, index) => (
                    <option value={service.key} key={index}>
                      {service.Name}
                    </option>
                  ))}
                </Input>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <Input
                  type="switch"
                  label="Allow customer to select delivery date"
                  value={this.state.specifyPickupDate}
                  error={this.state.specificDateError}
                  onChange={(value) => {
                    this.setState({
                      specifyPickupDate: value,
                      specificDateError: "",
                    });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-xs-12">
                <Input
                  type="switch"
                  label="Allow customer to select delivery time slots"
                  value={this.state.specifyPickupSlot}
                  onChange={(value) => {
                    this.setState({ specifyPickupSlot: value });
                  }}
                />
              </div>
            </div>
            {this.renderDeliverySlots()}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <Page
        title="Online Store - Settings"
        showSaveButton={true}
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action) => {
          if (action === "cancel") {
            this.props.history.push("/home");
          } else if (action === "save") {
            this.onSave();
          }
        }}
      >
        <PageSection title="Check out settings">
          <div className="row">
            <div className="col-md-4">
              <Input
                type="switch"
                label="Enable online appointment booking for services"
                value={this.state.enableAppointmentForServices}
                onChange={(value) => {
                  this.setState({ enableAppointmentForServices: value });
                }}
              />
            </div>
            {this.state.enableAppointmentForServices && (
              <div className="col-md-4">
                <Input
                  type="switch"
                  label="Allow user to select preferred employee for appointment service"
                  value={this.state.enableAppointmentEmployeeSelection}
                  onChange={(value) => {
                    this.setState({
                      enableAppointmentEmployeeSelection: value,
                    });
                  }}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input
                type="switch"
                label="Capture customer address at the time of checkout"
                value={this.state.showBillingAddress}
                onChange={(value) => {
                  this.setState({ showBillingAddress: value });
                }}
              />
              {this.renderBillingAddress()}
            </div>
            <div className="col-md-4">
              <Input
                type="switch"
                label="Enable line item notes"
                value={this.state.showLineItemNotes}
                onChange={(value) => {
                  this.setState({ showLineItemNotes: value });
                }}
              />
              {this.state.showLineItemNotes && (
                <React.Fragment>
                  <Input
                    type="text"
                    label="Line item notes text"
                    value={this.state.lineItemNotesText}
                    onChange={(value) => {
                      this.setState({ lineItemNotesText: value });
                    }}
                  />
                  <Input
                    type="switch"
                    label="Make line item notes mandatory"
                    value={this.state.lineItemNotesMandatory}
                    onChange={(value) => {
                      this.setState({ lineItemNotesMandatory: value });
                    }}
                  />
                </React.Fragment>
              )}
            </div>
            <div className="col-md-4">
              <Input
                type="switch"
                label="Enable option to apply coupon"
                value={this.state.enableCoupon}
                onChange={(value) => {
                  this.setState({ enableCoupon: value });
                }}
              />
              <Input
                type="switch"
                label="Allow customer to provide remarks"
                value={this.state.enableRemarks}
                onChange={(value) => {
                  this.setState({ enableRemarks: value });
                }}
              />
              {this.state.enableRemarks && (
                <React.Fragment>
                  <Input
                    type="text"
                    label="Remarks text"
                    value={this.state.remarksText}
                    onChange={(value) => {
                      this.setState({ remarksText: value });
                    }}
                  />
                </React.Fragment>
              )}
            </div>
          </div>

          {this.renderPinCodeChecker()}
        </PageSection>
        <PageSection title="Delivery charges">
          {this.renderDeliveryCharges()}
        </PageSection>
        <PageSection title="Packaging charges">
          {this.renderPackagingCharges()}
        </PageSection>
        <PageSection title="Order calcellation settings">
          {this.renderCancelOptions()}
        </PageSection>
      </Page>
    );
  }
}

export default OmniSettings;
