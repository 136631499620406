import React, { Component } from 'react'
import './Avatar.css'

class Avatar extends Component {

    big() {
        if (this.props.big) {
            return true
        }
        return false
    }

    render() {
        // You can use them as regular CSS styles.
        return <React.Fragment>
            {(this.props.image && this.props.image !== "") && <img className={this.big() ? "avatar-circle bg" : "avatar-circle"} src={this.props.image} />}
            {(!this.props.image && !this.props.userAvatar) && <div className={this.big() ? "avatar-circle bg" : "avatar-circle"}>
                <span className={this.big() ? "initials bg" : "initials"}>{this.props.text.substring(0, 1).toUpperCase()}</span>
            </div>}
            {this.props.userAvatar && <div className={"avatar-user"}>
                <span className={"initials-user"}>{this.props.text.substring(0, 1).toUpperCase()}</span>
            </div>}
        </React.Fragment>
    }
}

export default Avatar