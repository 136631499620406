import React, { Component } from 'react';
import Input from '../2/Controls/Input';
import Form from '../2/Controls/Form';

class AddAttendance extends Component {

    constructor() {
        super()
        this.state = {
            key:'', 
            employees: [],
            employee: null,
            inTime: {HH:'', MM:'', AMPM:''},
            outTime: {HH:'', MM:'', AMPM:''},
            inTimeDate: null,
            outTimeDate: null,
            attendanceType: '',
            remarks: '',
            attendanceDate: new Date().toISOString().split('T')[0],
            saving: false,
            employeeError: "",
            attendanceDateError: "",
            attendanceTypeError: "",
            inTimeError: "",
            outTimeError:""
        }
        this.onCreateAttendance = this.onCreateAttendance.bind(this);
        this.loadEmployees = this.loadEmployees.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    componentDidMount() {
        this.loadEmployees();
        // console.log('this.props.attendance',this.props.attendance)
        if(this.props.attendance)
        {
            this.setState(
                {
                    key: this.props.attendance.key,
                    employee:this.props.attendance.Employee,
                    attendanceDate: this.props.attendance.Date,
                    attendanceType:this.props.attendance.AttendanceType,
                    inTime:this.props.attendance.InTime,
                    outTime:this.props.attendance.OutTime,
                    remarks:this.props.attendance.Remarks
                }
            )
        }
    }

    loadEmployees() {
        this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key, 0, 10000, 'active', '',
            '', '', '', '', '', '', '', '', '', '').then((result) => {
                let employees = [];
                result.searchResult.map((emp) => {
                    let obj = {
                        value: emp.key
                    }
                    let name = (emp.EmployeeFirstName.trim() + ' ' + (emp.EmployeeLastName ? emp.EmployeeLastName.trim() : ""))
                    name = name.trim()
                    obj.label = name;
                    employees.push(obj);
                });
                this.setState({ employees: employees })
            })
    }

    handleOnChange(employeeID) {
        if(this.state.employees.filter(f=> f.value === employeeID).length> 0){
            this.setState({ employee: this.state.employees.filter(f=> f.value === employeeID)[0],employeeError:"" });
        }
        
    };

    onCreateAttendance() {

        this.setState({ saving: true })
        let employeeError = "";
        let attendanceDateError = "";
        let attendanceTypeError = "";
        let inTimeError = "";
        let outTimeError = "";
        if (!this.state.employee) { employeeError = 'Please select employee' }
        if (this.state.attendanceType.trim() === '') { attendanceTypeError = 'Please select an option' }
        if (this.state.attendanceDate.trim() === '') { attendanceDateError = 'Please select date' }

        // console.log('this.state.inTime.', this.state.inTime)
        if(this.state.attendanceType.trim() !== '' && this.state.attendanceType.trim() !== "Leave" && this.state.attendanceType.trim() !== "Week-Off")
        {
            if(this.state.inTime.HH.trim()==='' || this.state.inTime.MM==='' || this.state.inTime.AMPM==='')
            {
                inTimeError = "Please specify time"
            }
        }
        // console.log('inTimeError', inTimeError)
        if (employeeError!=="" || attendanceDateError !==""|| attendanceTypeError !==""|| inTimeError!=="" || outTimeError!=="") {
            this.setState({
                employeeError: employeeError,
                attendanceDateError: attendanceDateError,
                attendanceTypeError: attendanceTypeError,
                inTimeError: inTimeError,
                outTimeError:outTimeError,
                saving: false
            })
            return;
        }
        let attendance = {
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            Employee : this.state.employee,
            Date: this.state.attendanceDate,
            AttendanceType: this.state.attendanceType,
            InTime: this.state.inTime,
            OutTime: this.state.outTime,
            Remarks: this.state.remarks
        }
        // console.log('attendance', attendance);
        // return;
        this.props.saveAttendance(attendance,this.state.key).then((key) => {
            this.props.getToastr("Attendance Saved Successfully");
            setTimeout(function () { 
                this.setState({saving:false})
                    this.props.onSave()
            }.bind(this), 2000)
        })
    }

    render() {
        return <Form
                title={(this.state.key !== "") ? "Edit Attendance" : "New Attendance"}
                onClick={(action)=>{
                    if(action==="save"){
                        this.onCreateAttendance();
                    }else{
                        this.props.onClose()
                    }
                }}
                processing={this.state.saving}
            >
                <Input 
                    type="select"
                    label="Employee"
                    value={this.state.employee ? this.state.employee.value: ""}
                    onChange={(value)=>this.handleOnChange(value)}
                    error ={this.state.employeeError}
                    onEnter={this.onCreateAttendance}
                >
                    <option>Please select employee</option>
                    {this.state.employees.map((e,index)=><option  key={"emp_" + index} value={e.value}>{e.label}</option>)}
                </Input>
                <Input
                    type="date"
                    label="Date"
                    value={this.state.attendanceDate}
                    onChange={(value) => this.setState({ attendanceDate: value, attendanceDateError: '' })}
                    error={this.state.attendanceDateError}  
                    onEnter={this.onCreateAttendance}
                />
                <Input
                    type="select"
                    label="Status"
                    onChange={(value) => this.setState({ attendanceType:value, attendanceTypeError: '', inTime: { HH: '', MM: '', AMPM: '' }, outTime: { HH: '', MM: '', AMPM: '' } })}
                    value={this.state.attendanceType}
                    error={this.state.attendanceTypeError}  
                    onEnter={this.onCreateAttendance}
                >
                    <option value="">Select</option>
                    <option value="Present">Present</option>
                    <option value="Half Day">Half Day</option>
                    <option value="Leave">Leave</option>
                    <option value="Week-Off">Week-Off</option>
                </Input>
                {(this.state.attendanceType != "Leave" && this.state.attendanceType != "Week-Off") && <React.Fragment>
                    <Input
                        type="timepicker"
                        label="In Time"
                        onChange={(value) => this.setState({ inTime: value, inTimeError: '' })}
                        value= {this.state.inTime}
                        error = {this.state.inTimeError}
                    />
                    <Input
                        type="timepicker"
                        label="Out Time"
                        onChange={(value) => this.setState({ outTime: value, outTimeError: '' })}
                        value= {this.state.outTime}
                        error = {this.state.outTimeError}
                    />
                </React.Fragment>}
                <Input
                    type="text"
                    label="Remarks"
                    value={this.state.remarks}
                    onChange={(value) => this.setState({ remarks: value })}
                    onEnter={this.onCreateAttendance}
                />
            </Form>
    
    }
}
export default AddAttendance 


