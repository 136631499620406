import React,{Component} from 'react'
import Window from '../2/Controls/Window';
import './ProductVariants.css';

class ProductVariants extends Component{

    constructor(props)
    {
      super(props)
        this.state = {
            attributeLabel: '',
            attributes1: [],
            attributes2: [],
            attributes3: [],
            selectedAttribute1: '',
            selectedAttribute2: '',
            selectedAttribute3: '',
            showAttribute1: true,
            showAttribute2: false,
            showAttribute3: false,
        }
        this.showNextLevel = this.showNextLevel.bind(this);
    }

    componentDidMount() {
        let attributeLabel = ''
        console.log('select product', this.props.selectedVariantProduct)
        if(this.props.selectedVariantProduct && this.props.selectedVariantProduct.Attributes.length === 3)
        {
            attributeLabel = 'Select ' + this.props.selectedVariantProduct.Attributes[0].Attribute + ' > ' + 
            this.props.selectedVariantProduct.Attributes[1].Attribute + ' > ' + this.props.selectedVariantProduct.Attributes[2].Attribute

            this.setState({attributes1: this.props.selectedVariantProduct.Attributes[0].AttributeValues, 
                attributes2: this.props.selectedVariantProduct.Attributes[1].AttributeValues,
                attributes3: this.props.selectedVariantProduct.Attributes[2].AttributeValues })
        }
        if(this.props.selectedVariantProduct.Attributes.length === 2)
        {
            attributeLabel = 'Select ' + this.props.selectedVariantProduct.Attributes[0].Attribute + ' > ' + this.props.selectedVariantProduct.Attributes[1].Attribute
            this.setState({attributes1: this.props.selectedVariantProduct.Attributes[0].AttributeValues, 
                attributes2: this.props.selectedVariantProduct.Attributes[1].AttributeValues,
                })
        }
        if(this.props.selectedVariantProduct.Attributes.length === 1)
        {
            attributeLabel = 'Select ' + this.props.selectedVariantProduct.Attributes[0].Attribute
            this.setState({attributes1: this.props.selectedVariantProduct.Attributes[0].AttributeValues, 
                })
        }

        this.setState({attributeLabel: attributeLabel})
    }

    showNextLevel(attribute1, attribute2="", attribute3="") {
        let showAttribute1 = this.state.showAttribute1;
        let showAttribute2 = false
        let showAttribute3 = false
        let variantProduct = this.props.selectedVariantProduct;


        if (variantProduct.Variants.filter(f => f.Attribute1.toLowerCase().trim() === attribute1.toLowerCase().trim() && f.Attribute2.toLowerCase().trim() === attribute2.toLowerCase().trim() && f.Attribute3.toLowerCase().trim() === attribute3.toLowerCase().trim()).length > 0) {
            let variantFound = variantProduct.Variants.filter(f => f.Attribute1.toLowerCase().trim() === attribute1.toLowerCase().trim() && f.Attribute2.toLowerCase().trim() === attribute2.toLowerCase().trim() && f.Attribute3.toLowerCase().trim() === attribute3.toLowerCase().trim())[0];
            // console.log("variantFound", variantFound);
            if(variantFound)
            {
                // console.log(this.props.config.Products);
                
                if (this.props.config.Products.filter(f => f.key === variantFound.ProductID).length > 0)
                {
                    let product  = this.props.config.Products.filter(f => f.key === variantFound.ProductID)[0];
                    // console.log("product", product);
                    if(product)
                    {
                        this.props.onOK(product);
                    }
                }
            }
        }
        else
        {
            if(attribute2 ==="")
            {
                showAttribute2 = true
                showAttribute1 = false
            }
            if(attribute2 !=="" && attribute3 ==="")
            {
                showAttribute1 = false
                showAttribute3 = true
            }
        }
        this.setState({ selectedAttribute1: attribute1, selectedAttribute2: attribute2, selectedAttribute3: attribute3, 
            showAttribute1: showAttribute1, showAttribute2: showAttribute2, showAttribute3: showAttribute3 })
    }

    render() {
        return  <Window 
            header={this.props.selectedVariantProduct.Name}  
            width={"600px"}
            modal={false}
            onClose={this.props.onClose}
            buttons={[]}>
                {/* <div className="box-body"> */}
                    <div className="variant-title">{this.state.attributeLabel}</div>
                    <div className="variants">
                        {this.state.showAttribute1 && 
                            <React.Fragment>
                                {this.state.attributes1.map((attribute, index) => (
                                    <div
                                        key={index}
                                        className="variant"
                                        onClick={() =>this.showNextLevel(attribute.label)}
                                    >
                                        <div>
                                            <div className="" >
                                                {attribute.label}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        }

                        {this.state.showAttribute2 && <React.Fragment>
                            {this.state.attributes2.map((attribute, index) => (
                                <div
                                    key={index}
                                    className="variant"
                                    onClick={() =>this.showNextLevel(this.state.selectedAttribute1, attribute.label) }
                                >
                                    <div>
                                        <div className="">
                                            {attribute.label}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </React.Fragment>}

                        {this.state.showAttribute3 && <React.Fragment>
                                {this.state.attributes3.map((attribute, index) => (
                                    <div
                                        key={index}
                                        className="variant"
                                        onClick={() =>
                                            this.showNextLevel(
                                                this.state.selectedAttribute1,
                                                this.state.selectedAttribute2,
                                                attribute.label
                                            )
                                        }
                                    >
                                        <div>
                                            <div className="">
                                                {attribute.label}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </React.Fragment>}
                        <div className="variant-dummy"></div>
                        <div className="variant-dummy"></div>
                        <div className="variant-dummy"></div>
                        <div className="variant-dummy"></div>
                    </div>
                    
                    
                    {/* {this.state.showAttribute3 && (
                        <div>
                            {this.state.attributes3.map((attribute, index) => (
                                <a
                                    key={index}
                                    className="btn btn-flat btn-app product-selection-btn"
                                    style={{
                                        width: "30%",
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() =>
                                        this.showNextLevel(
                                            this.state.selectedAttribute1,
                                            this.state.selectedAttribute2,
                                            attribute.label
                                        )
                                    }
                                >
                                    <div>
                                        <div
                                            className="row product-select-btn-text-full-product-name1"
                                            style={{
                                                marginTop: "36px",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                fontSize: "23px",
                                            }}
                                        >
                                            {attribute.label}
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    )} */}

                {/* </div> */}
        </Window>
    }
}
export default ProductVariants