import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import "./KOTCustomerDisplay.css";
import alertSound from '../../audio/audio/success-83493.mp3';

class KOTCustomerDisplay extends Component {

    constructor() {
        super()
        this.state = {
            
            pendingOrders: [],
            readyOrders:[]
        }
        this.loadKOTOrders = this.loadKOTOrders.bind(this);
        this.handleStartStop = this.handleStartStop.bind(this);
        this.fecthOrders = this.fecthOrders.bind(this);
    }

    componentDidMount() {
        this.handleStartStop();
        this.loadKOTOrders(true);
    }

    handleStartStop() {
        setInterval(this.fecthOrders, 10000);
    }

    fecthOrders() {
        this.loadKOTOrders();
    }

    async loadKOTOrders(initialLoad=false) {
        let pendingOrders = await this.props.getKOTS("Pending");
        let readyOrders = await this.props.getKOTS("Ready");
        if(!initialLoad){
            console.log("check for dound")
            let playSound = false;
            readyOrders.map(o=>{
                if(this.state.readyOrders.filter(f=> f.key === o.key).length ===0){
                    //play sound 
                    playSound= true;
                }
            })
            // console.log("playSound",playSound)
            if(playSound){
                //make some noise for desi boys
                const audio = new Audio(alertSound);
                audio.play(); 
                setTimeout(function(){
                    audio.pause();
                  }, 2000)
            }
        }
        this.setState({ pendingOrders: pendingOrders, readyOrders: readyOrders });
    }

    render() {

        return   <div className='header-margin'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="kot-customer-display-section">
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='kot-customer-display-pending-title'>Preparing</div>
                            </div>
                        </div>
                        <div className='row'>
                            {this.state.pendingOrders.map((p,index)=><div key={index +"_pendingorders"} className='col-md-12'>
                                <div className='kot-customer-display-pending'>{p.KOTNo}</div>
                            </div>)} 
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="kot-customer-display-section">
                        <div className='row'>
                            <div className='col-md-12'>
                            <div className='kot-customer-display-ready-title'>Ready</div>
                            </div>
                        </div>
                        <div className='row'>
                            {this.state.readyOrders.map((p,index)=><div key={index +"_readyOrders"} className='col-md-12'>
                                <div className='kot-customer-display-ready'>{p.KOTNo}</div>
                            </div>)} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withRouter(KOTCustomerDisplay)
