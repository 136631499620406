import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import { HotKeys } from 'react-hotkeys';
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';

class MembershipExpiry extends Component {

    constructor() {
        super()
        this.state = {
            customers: [],
            searchText: '',
            searchMembership: '',
            searchAssignmentFromDate: '',
            searchAssignmentToDate: '',
            searchValidityFromDate: '',
            searchValidityToDate: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading: true,
            showImport: false,
            exportCalled: false
        }
        this.loadCustomers = this.loadCustomers.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }


    componentDidMount() {
        this.loadCustomers(this.state.recordFrom, this.state.searchText, this.state.searchMembership, this.state.searchAssignmentFromDate, this.state.searchAssignmentToDate, this.state.searchValidityFromDate, this.state.searchValidityToDate)
    }

    loadCustomers(from, searchText, searchMembership) {
        this.setState({ loading: true })
        this.props.getCustomersForMembershipValidity(this.props.user.user.RegistrationID, this.props.user.store.key, from, this.state.itemsCountPerPage, searchText, searchMembership, this.state.searchAssignmentFromDate, this.state.searchAssignmentToDate, this.state.searchValidityFromDate, this.state.searchValidityToDate)
            .then((result) => {
                this.setState({ customers: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }


    onClearSearch() {
        this.setState({
            recordFrom: 0,
            searchText: '',
            searchMembership: '',
            searchAssignmentFromDate: '',
            searchAssignmentToDate: '',
            searchValidityFromDate: '',
            searchValidityToDate: ''
        })
        this.loadCustomers(0, "", "", "", "", "", "")
    }

    onSearchActiveInactiveAllRecordsClicked(opiton) {
        this.setState({ searchActiveInactiveAllRecords: opiton, recordFrom: 0 })
        this.loadCustomers(
            0,
            this.state.searchText,
            this.state.searchMembership,
            this.state.searchAssignmentFromDate,
            this.state.searchAssignmentToDate,
            this.state.searchValidityFromDate,
            this.state.searchValidityToDate)
    }

    // onExportClick() {
    //     this.setState({ exportCalled: true })
    //     this.props.getCustomersForMembershipValidity(
    //         this.props.user.user.RegistrationID,
    //         this.props.user.store.key, 0,
    //         this.state.itemsCountPerPage,
    //         this.state.searchText,
    //         this.state.searchMembership,
    //         this.state.searchAssignmentFromDate,
    //         this.state.searchAssignmentToDate,
    //         this.state.searchValidityFromDate,
    //         this.state.searchValidityToDate
    //     )
    //         .then((result) => {
    //             if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
    //                 this.props.getToastr("No Record Found")
    //                 return
    //             }
    //             let customers = []
    //             result.searchResult.map((data) => {
    //                 let customer = {
    //                     "Customer Code": data.CustomerCode,
    //                     "First Name": data.FirstName,
    //                     "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
    //                     "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
    //                     "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
    //                     "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
    //                     "Assignment Date" : new Date(data.Membership.AssignmentDate).toISOString().split('T')[0],
    //                     "Validity Date" : new Date(data.Membership.ValidityDate).toISOString().split('T')[0],
    //                     "Expiry (in Days)" : ((new Date(data.Membership.ValidityDate).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / (1000 * 60 * 60 * 24))
    //                 }
    //                 customers.push(customer);
    //             })
    //             const csvData = constatnts.objToCSV(customers);
    //             this.setState({ exportCalled: false })
    //             const csvBlob = new Blob([csvData], { type: "text/csv" });
    //             const url = window.URL.createObjectURL(csvBlob);
    //             const a = document.createElement("a");
    //             a.setAttribute("hidden", "");
    //             a.setAttribute("href", url);
    //             a.setAttribute("download", "MembershipExpiry.csv");
    //             document.body.appendChild(a);
    //             a.click();
    //             document.body.removeChild(a);
    //         })
    // }


    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getCustomersForMembershipValidity(
            this.props.user.user.RegistrationID,
            this.props.user.store.key, 0,
            5000,
            this.state.searchText,
            this.state.searchMembership,
            this.state.searchAssignmentFromDate,
            this.state.searchAssignmentToDate,
            this.state.searchValidityFromDate,
            this.state.searchValidityToDate
        )
            .then((result) => {
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let customers = []
                result.searchResult.map((data) => {
                    let customer = {
                        "Customer Code": data.CustomerCode,
                        "First Name": data.FirstName,
                        "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                        "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                        "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                        "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                        "Assignment Date" : new Date(data.Membership.AssignmentDate).toISOString().split('T')[0],
                        "Validity Date" : new Date(data.Membership.ValidityDate).toISOString().split('T')[0],
                        "Expiry (in Days)" : ((new Date(data.Membership.ValidityDate).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / (1000 * 60 * 60 * 24))
                    }
                    customers.push(customer);
                })
                const csvData = constatnts.objToCSV(customers);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "MembershipExpiry.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }
        
    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadCustomers(
            0,
            this.state.searchText,
            this.state.searchMembership,
            this.state.searchAssignmentFromDate,
            this.state.searchAssignmentToDate,
            this.state.searchValidityFromDate,
            this.state.searchValidityToDate)
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadCustomers(
            recordFrom,
            this.state.searchText,
            this.state.searchMembership,
            this.state.searchAssignmentFromDate,
            this.state.searchAssignmentToDate,
            this.state.searchValidityFromDate,
            this.state.searchValidityToDate
        )
    }




    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }


    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    render() {
        const handlers = {
            new: this.openNewCustomer
        };
        return <HotKeys
            handlers={handlers}><div className="content-wrapper" >
                <section className="content" >
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box ">
                                <div className="box-header with-border">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <font style={{ fontSize: "30px" }}>Membership Expiry</font>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Search by customer name, code, email id, contact no</label>
                                                <input type="text"
                                                    onKeyPress={this.handleKeyPress}
                                                    autoFocus={true}
                                                    className="form-control input-md"
                                                    value={this.state.searchText}
                                                    onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Membership</label>
                                                <input type="text" className="form-control input-md"
                                                    value={this.state.searchMembership}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => this.setState({ searchMembership: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Assignment From</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchAssignmentFromDate: evt.target.value })}
                                                    value={this.state.searchAssignmentFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Assignment To</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchAssignmentToDate: evt.target.value })}
                                                    value={this.state.searchAssignmentToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Validity From</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchValidityFromDate: evt.target.value })}
                                                    value={this.state.searchValidityFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Validity To</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchValidityToDate: evt.target.value })}
                                                    value={this.state.searchValidityToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginLeft: "5px" }}
                                                onClick={this.onSearchClick}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                                onClick={this.onClearSearch} >Clear</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginRight: "5px" }}
                                                onClick={this.onExportClick}
                                            >Export <i className="glyphicon glyphicon-download-alt " ></i></button>

                                        </div>
                                    </div><br></br>
                                    {this.state.loading && <Loader show={this.state.loading} />}
                                    {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Customer Detail</th>
                                                    <th>Contact Details</th>
                                                    <th>Membership Name</th>
                                                    <th>Assignment Date</th>
                                                    <th>Validity Date</th>
                                                    <th>Expiry (in Days)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.customers.map((customer) =>
                                                    <tr>
                                                        <td>{customer.FirstName + ' ' + customer.LastName}
                                                            <div>{customer.CustomerCode}</div>
                                                            <div>{customer.Gender}</div>
                                                        </td>
                                                        <td>{customer.PhoneNo}
                                                            <div>{customer.EmailID}</div>
                                                        </td>
                                                        <td>{customer.Membership.Name}</td>
                                                        <td>{(new Date(customer.Membership.AssignmentDate).toISOString().split('T')[0])}</td>
                                                        <td>{(new Date(customer.Membership.ValidityDate).toISOString().split('T')[0])}</td>
                                                        <td>{ ((new Date(customer.Membership.ValidityDate).setHours(0,0,0,0) - new Date().setHours(0,0,0,0)) / (1000 * 60 * 60 * 24)) }</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table> </div>}
                                    {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                        {this.state.paginationStatus}
                                    </div>}
                                    <div className="pull-right">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsCountPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={2}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                </div>   </HotKeys>
    }
}

export default withRouter(MembershipExpiry)
