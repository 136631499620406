import React, { Component } from 'react'
import Input from '../2/Controls/Input';
import Form from '../2/Controls/Form';

class AddEditModifier extends Component {
    constructor() {
        super()
        this.state = {
            isProductTypeAheadLoading: false,
            saving: false,
            modifierGroup: '',
            modifierGroupID:'',
            modifier: '',
            price: '',
            allProducts: true,
            specificProducts: false,
            associatedProducts: [],
            productsTypeAheadSelected:null,
            modifierGroupError: '',
            modifierError: '',
            priceError: '',
            optionError: '',
            associatedProductsError:'',
            key:'',
            modifierGroups:[]
        }
        this.onSaveModifier = this.onSaveModifier.bind(this)
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)
        this.onProductSelected = this.onProductSelected.bind(this)
    }

    onProductTypeAheadSearch = (query) => {
        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProducts(this.props.user.user.RegistrationID, 0, 100, 'active', query, "", "", "",
            "", "", "","")
            .then((result) => {
                this.setState({ productsTypeAheadOptions: result.searchResult })
                this.setState({ isProductTypeAheadLoading: false });
            })
    }

    onProductSelected(product) {
        console.log('product',product)
        if(!product){
            return;
        }
        let selectedProduct = {
            BarCode: product.BarCode,
            Brand:product.Brand,
            BrandID: product.BrandID,
            Category: product.Category,
            CategoryID: product.CategoryID,
            Description: product.Description,
            MRP:product.MRP,
            Name:product.Name,
            ProductType: product.ProductType,
            RetailPrice: product.RetailPrice,
            SKU:product.SKU,
            SubCategory:product.SubCategory,
            SubCategoryID: product.SubCategoryID,
            SupplyPrice: product.SupplyPrice,
            ProductID: product.key
        }
        let associatedProducts = this.state.associatedProducts;
        associatedProducts.push(selectedProduct);
        this.setState({associatedProducts: associatedProducts});
    }

    componentDidMount() {
        if(this.props.modifier)
        {
            this.setState(
                {
                    key: this.props.modifier.key,
                    modifier: this.props.modifier.ModifierName,
                    associatedProducts: this.props.modifier.AssociatedProducts,
                    price: this.props.modifier.ModifierPrice.toString(),
                    modifierGroup: this.props.modifier.ModifierGroup,
                    modifierGroupID: this.props.modifier.ModifierGroupID,
                    allProducts: this.props.modifier.AllProducts,
                    specificProducts: this.props.modifier.SpecificProducts
                }
            )
        }
        this.props.getModifierGroups(this.props.user.user.RegistrationID).then((modifierGroups) => {
            this.setState({modifierGroups : modifierGroups})
        })
    }

    onSaveModifier() {
        this.setState({saving:true});

        let modifierError = ''
        let modifierGroupError = ''
        let priceError = ''
        let optionError = ''
        let associatedProductsError = ''

        if (this.state.modifierGroup.trim() === "") {
            modifierGroupError = 'Please select group';
        }
        if (this.state.modifier.trim() === "") {
            modifierError = 'Please enter modifier';
        }
        if (this.state.price.trim() === "") {
            priceError = 'Please enter price';
        }
        if (this.state.allProducts === false && this.state.specificProducts === false) {
            optionError = 'Please select either All or Specific option';
        }
        if(this.state.specificProducts)
        {
            if(this.state.associatedProducts.length === 0)
            {
                associatedProductsError ='Please select atleast one product';
            }
        }

        if(modifierGroupError !=="" || modifierError !=="" 
        || optionError !=="" || associatedProductsError !==""
        || priceError !=="")
        {
            this.setState({modifierGroupError:modifierGroupError,modifierError:modifierError,optionError:optionError,
                associatedProductsError:associatedProductsError,priceError:priceError, saving:false})
            return;
        }

        let modifier = {
            ModifierGroup: this.state.modifierGroup
            , ModifierGroupID: this.state.modifierGroupID
            , ModifierName: this.state.modifier
            , ModifierPrice: Number(this.state.price)
            , IsDeleted: false
            , RegistrationID: this.props.user.user.RegistrationID
            , AllProducts: this.state.allProducts
            , SpecificProducts: this.state.specificProducts
            , AssociatedProducts: this.state.associatedProducts
        }
        this.props.saveModifiers(modifier, this.state.key).then(
            (key) => {
                this.props.getToastr("Modifier Saved Successfully")
                this.setState({ saving: false })
                setTimeout(function () {
                    this.props.onSave();
                }.bind(this), 1000)
            })
    }

    render() {
        return <Form
            title={(this.state.key !== "") ? "Edit Modifier" : "New Modifier"}
            onClick={(action)=>{
                if(action==="save"){
                    this.onSaveModifier();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
            <Input
                type="select"
                label="Group"
                value={this.state.modifierGroupID}
                onChange={(value) => this.setState({ 
                    modifierGroup: this.state.modifierGroups.filter((e) => e.key===value)[0].Name,
                    modifierGroupID:value,  
                    modifierGroupError: '' })}
                error={this.state.modifierGroupError}
            >
                <option value="">Select Group</option>
                {this.state.modifierGroups.filter((e) => !e.IsDeleted).map((modifierGroup) =>
                        <option value={modifierGroup.key}>{modifierGroup.Name}</option>
                )}
            </Input>
            <Input
                type="text"
                label="Modifier"
                placeholder="Enter Modifier"
                onChange={(value) => this.setState({ modifier: value, modifierError : '' })}
                value={this.state.modifier} 
                error={this.state.modifierError}
            />
            <Input
                type="number"
                label="Price"
                placeholder="Enter Price"
                onChange={(value) => this.setState({ price: value, priceError:''})}
                value={this.state.price} 
                error={this.state.priceError}
            />
            <Input
                type="switch"
                label="Applicable to specific products"
                value={this.state.specificProducts}
                onChange={(value)=>{this.setState({ specificProducts: value, 
                    allProducts: !value, 
                    associatedProducts: [], 
                    optionError: '', 
                    })
                }}
                error={this.state.optionError}
            />
            {this.state.specificProducts && <Input
                type="typeaheadselection"
                label="Please select product"
                placeholder="Start typing to search for products"
                options={this.state.productsTypeAheadOptions}
                labelKey="Name"
                filterBy={['Name', 'SKU', 'BarCode']}
                processing={this.state.isProductTypeAheadLoading}
                onSearch={this.onProductTypeAheadSearch}
                selected={this.state.productsTypeAheadSelected}
                columns={[]}
                onChange={(selected) => {
                    this.onProductSelected(selected)
                    this.setState({ productsTypeAheadSelected:null})
                }}
                renderMenuItemChildren={(option, props) => (
                    <div>
                        <div><b>{option.Name}</b></div>
                        <div>{option.SKU}</div>
                        <div>{this.props.user.store.Currency}  {option.RetailPrice}</div>
                    </div>
                )}
                onClick={(type)=>{}}
                error={this.state.associatedProductsError}
            />}
            {this.state.associatedProducts  && this.state.associatedProducts.length > 0 && 
            <table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Retail Price</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {this.state.associatedProducts.map((product,index) =>
                    <tr>
                        <td>{product.SKU}
                        </td>
                        <td>{product.Name}
                        </td>
                        <td>{product.RetailPrice}</td>
                        <td>
                            <button type="button" className="btn btn-default btn-md" 
                                onClick={() => {
                                    let associatedProducts = this.state.associatedProducts
                                    associatedProducts.splice(index, 1)
                                    this.setState({ associatedProducts: associatedProducts })
                                }}>
                                <i className="glyphicon glyphicon-remove"></i> </button>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>}
    </Form>}
}

export default AddEditModifier