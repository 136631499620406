import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function addStoreLocation(storeLocation){
    return (dispatch, getState) => {
        storeLocation.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        storeLocation.ActionBy = getState().user.user.Name
        storeLocation.ActionByUID = getState().user.user.UID
        storeLocation.ActionByEmailID = getState().user.user.EmailID

        storeLocation.Action = constants.AUDIT_TRAIL_NEW
        return database.collection("StoreLocations").add({            
          ...storeLocation
        })
        .then(function(docRef) {
            return docRef.id                        
        })
        .catch(function(error) {
            console.error("Error adding store location: ", error);
        })        
    }
}


export function getStoreLocations(storeID) {
    return (dispatch) => {
        //  return database.collection("Product").where("RegistrationID","==",registrationID).orderBy("Name","asc").get().then((querySnapshot) => {
        return database.collection("StoreLocations").where("StoreID","==",storeID).get().then((querySnapshot) => {
        
            const storeLocations = []
            querySnapshot.forEach((doc) => {
                storeLocations.push(
                  {
                    key:doc.id,...doc.data()
                  }
                )
           })
           return storeLocations
        }).catch((error) => {
            console.log("Error getting store location list: ", error)
        })
    }
}

export function deleteStoreLocations(key){
    return (dispatch, getState) =>{
        return database.collection("StoreLocations").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            
            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}

export function getStoreLocationDetails(key){
    return (dispatch) => {
    return database.collection("StoreLocations").doc(key).get().then((doc) => {
        if (doc.exists) {
           return doc.data()
        } else {
            console.log("No such store location found!");
        }
    })
    }
}

export function updateStoreLocation(storeLocaiton, key){
    return (dispatch, getState) => {
        storeLocaiton.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        storeLocaiton.ActionBy = getState().user.user.Name
        storeLocaiton.ActionByUID = getState().user.user.UID
        storeLocaiton.ActionByEmailID = getState().user.user.EmailID

        storeLocaiton.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("StoreLocations").doc(key).update({
          ...storeLocaiton
        })
    }
}