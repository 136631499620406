import React, { Component } from "react";
import { connect } from "react-redux";
import Window from '../2/Controls/Window';
import Button from "../2/Controls/Button";
import './SalesOptions.css';

class SalesOptions extends Component {
    constructor() {
        super();
        this.state = {
        };
    }

    render(){
        return <Window 
                header={""}  
                height={"300px"}
                modal={true}
                processing={this.state.saving}
                onClose={this.props.onClose}
                onButtonClick={(buttonType)=>{}}
                buttons={[]}
                >
                    <div className="salesoptions">
                    {/* / Offers / Coupon */}
                        {this.props.showDiscountOption && <Button type="secondary" text="Discount" onClick={()=>this.props.onButtonClick("discount")} />}
                        <Button type="secondary" text="Notes" onClick={()=>this.props.onButtonClick("notes")}/>
                        {this.props.showParkSaleOption && <Button type="secondary" text="Park Sale" onClick={()=>this.props.onButtonClick("parksale")}/>}
                        {this.props.showRetrieveSaleOption &&  <Button type="secondary" text="Retrieve Sales" onClick={()=>this.props.onButtonClick("retrievesales")}/>}
                        {this.props.showDiscardSaleOption && <Button type="secondary" text="Discard Sale" onClick={()=>this.props.onButtonClick("discardsale")}/>}
                        {this.props.showCustomFields && <Button type="secondary" text="Additional Info" onClick={()=>this.props.onButtonClick("additionalinfo")}/>}
                        {this.props.showDeliveryOption && <Button type="secondary" text="Delivery" onClick={()=>this.props.onButtonClick("delivery")}/>}
                        {this.props.showAddnCharges && <Button type="secondary" text="Addn Charges" onClick={()=>this.props.onButtonClick("addncharges")}/>}

                    </div>
                   
            </Window>
    }
}

const mapStateToProps = (state) => ({
    user: state.user
  });
  
  export default connect(mapStateToProps, {})(SalesOptions);