import React, { Component } from 'react';
import Icon from './Icon';
import Tooltip from './Tooltip';
import Window from './Window';
import Button from './Button';
import './ActionButton.css';

class ActionButton extends Component {

    constructor(){
        super();
        this.state={
            showActionButtonOptions:false
        }
        
    }

   renderButton(){
       return <React.Fragment>
        <div 
            className={"action-btn"} 
            style={{bottom:this.props.bottom ?  this.props.botton : "10px"}}
            onClick={()=>{
                if(this.props.hasOwnProperty('onClick')){
                    if(!this.props.options){
                        this.props.onClick()
                    }
                    else if(this.props.options){
                        this.setState({showActionButtonOptions:true})
                    }
                }
            }}
            >
            <Icon type={this.props.icon ? this.props.icon : this.props.type}/>
        </div>
    </React.Fragment>
   }

    render(){
        return <React.Fragment>

            {!this.state.showActionButtonOptions && <React.Fragment>
            
                {this.props.label && <Tooltip content={this.props.label} direction="top">
                    {this.renderButton()}
                </Tooltip>}

                {!this.props.label && this.renderButton()}
                
            </React.Fragment>}

           {this.state.showActionButtonOptions && <React.Fragment>
            <Window 
                header={""}  
                height={"200px"}
                modal={true}
                onClose={()=>this.setState({showActionButtonOptions:false})}
                buttons={[]}>
                     {this.props.options.map((button)=><Button 
                        type="secondary" 
                        text={button.text} 
                        label={button.text} 
                        icon={button.icon} 
                        onClick={()=>{
                            if(this.props.hasOwnProperty('onClick')){
                                this.props.onClick(button.value);
                                this.setState({showActionButtonOptions:false});
                            }
                        }}/>
                    )}
            </Window>
           </React.Fragment>}

        </React.Fragment>
    }
}

export default ActionButton;
