import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'

export function saveBOM(bom, key) {
    return (dispatch, getState) => {
        bom.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        bom.ActionBy = getState().user.user.Name
        bom.ActionByUID = getState().user.user.UID
        bom.ActionByEmailID = getState().user.user.EmailID

        if (key.trim() !== "") {
            bom.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("BOM").doc(key).update({
                ...bom
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating bom: ", error);
            })
        }
        else {
            bom.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("BOM").add({
                ...bom
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding bom: ", error);
                })
        }
    }
}

export function deleteBOMs(key) {
    return (dispatch, getState) => {
        return database.collection("BOM").doc(key).update({
            IsDeleted: true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}

export function getBOMList(registrationID, from, size, option, searchText) {
    let searchParameters = {
        "from": from,
        "size": size,
        "track_total_hits": true,
        "query": {
            "bool": {
                "must": [
                    { "match": { "RegistrationID": registrationID } }
                ],
                "must_not": [
                ], 
                "should": [
                
                ],
                "minimum_should_match": 1
            }
        }
    }
    if (option === "active") { searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } }) }
    if (option === "inactive") { searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } }) }

    if(searchText.trim() != "")
    {
        searchParameters.query.bool.should.push({ "match_phrase": { "Product.Name": searchText.trim().toLowerCase()} })
        searchParameters.query.bool.should.push({ "match_phrase": { "BOMName": searchText.trim().toLowerCase()} })    
        
        searchParameters.query.bool.should.push({ "regexp": { "Product.Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
        searchParameters.query.bool.should.push({ "regexp": { "BOMName": "@"+ searchText.trim().toLowerCase() +  ".*" }})    
    }
    else
    {
        searchParameters.query.bool.should.push({ "regexp": { "Product.Name": "@.*" }})
        searchParameters.query.bool.should.push({ "regexp": { "BOMName": "@.*"}})    
        
    }
    console.log('searchParameters', JSON.stringify(searchParameters))
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getBOMs');
        // console.log('Function called')
        return addMessage({ text: searchParameters }).then(function (result) {
            const boms = []
            let sanitizedMessage = result.data.text;
            console.log('data', result.data.text)
            sanitizedMessage.hits.hits.map((data, index) => {
                boms.push({ key: data._id, ...data._source })
            })
            // console.log('sanitizedMessage.hits.total.value',sanitizedMessage.hits.total.value)
            // return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: boms }
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: boms }

        });
    }
}

export function getBOMDetails(key){
    return dispatch => {
    return database.collection("BOM").doc(key).get().then((doc) => {
        if (doc.exists) {
            // console.log("BOM found", doc.data());
           return doc.data()
        } else {
            console.log("No such BOM!");
        }
    })
    }
}