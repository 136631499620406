import axios from 'axios';

export function getEasyRewardWorkflowWidget(
    registrationID, 
    storeCode, 
    customerMobileNo
    ){
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/apiGetEasyRewardWorkflowWidget',
            {
                RegistrationID:registrationID, 
                StoreCode:storeCode,
                CustomerMobileNo:customerMobileNo,
            })
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log('getEasyRewardWorkflowWidget', err);
        });
    }

export function getCustomerEasyRewardzLoyaltyPoints(
    registrationID, 
    storeID, 
    storeCode, 
    customerID, 
    customerMobileNo
    ){
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/apiGetCustomerEasyRewardzLoyaltyPoints',
            {RegistrationID:registrationID, 
                StoreID:storeID,
                StoreCode:storeCode,
                CustomerID:customerID, 
                CustomerMobileNo:customerMobileNo,
            })
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log('getCustomerEasyRewardzLoyaltyPoints', err);
        });
    }

    export function validateEasyRewardzCustomerLoyaltyPoints(
        registrationID, 
        storeID,
        storeCode, 
        customerID, 
        customerMobileNo, 
        transactionCode, 
        redemptionDate,
        amount,
        easyPoints,
        ){

            // console.log('validateEasyRewardzCustomerLoyaltyPoints called')
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/apiValidateEasyRewardzCustomerLoyaltyPoints',
            {
                RegistrationID:registrationID, 
                StoreID: storeID,
                StoreCode:storeCode, 
                CustomerID:customerID, 
                CustomerMobileNo:customerMobileNo, 
                TransactionCode:transactionCode, 
                RedemptionDate:redemptionDate,
                Amount:amount,
                EasyPoints:easyPoints,
            })
        .then(res => {
            // console.log('getBrandList', res)
            console.log('validateEasyRewardzCustomerLoyaltyPoints res',res);
            return res.data;
        })
        .catch(err => {
            console.log('err',err)
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

    export function validateEasyRewardzOTP(registrationID,customerMobileNo, storeCode, transactionCode, transactionDate, OTP){
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/apiValidateEasyRewardzOTP',
            {   RegistrationID:registrationID, 
                CustomerMobileNo:customerMobileNo,
                StoreCode: storeCode,
                TransactionCode: transactionCode,
                TransactionDate: transactionDate,
                OTP:OTP,
            })
        .then(res => {
            // console.log('getBrandList', res)
            console.log('res',res);
            return res.data;
        })
        .catch(err => {
            console.log('err',err)
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

        

    
    export function releaseEasyRewardzRedemptionPoints(registrationID,customerMobileNo, storeCode, transactionCode, transactionDate){
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/apiReleaseEasyRewardzRedemptionPoints',
            {   RegistrationID:registrationID, 
                CustomerMobileNo:customerMobileNo,
                StoreCode: storeCode,
                TransactionCode: transactionCode,
                TransactionDate: transactionDate
            })
        .then(res => {
            // console.log('getBrandList', res)
            console.log('res',res);
            return res.data;
        })
        .catch(err => {
            console.log('err',err)
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }