import { roundUpTo2, convertNumberToWords } from "../../actions/Util" 
//" ../../services/util";

export function onLineItemChange(
    lineItemIndex,
    lineItem,
    placeOfSupply,
    discountType,
    discountID,
    discountInfo,
    transactionLevelDiscount,
    transactionType) {
    return (dispatch, getState) => {
        let subTotal = roundUpTo2(Number(lineItem.RetailPrice) * Number(lineItem.Qty));
        subTotal = roundUpTo2(subTotal + (lineItem.ModifierAmount ? lineItem.ModifierAmount : 0));
        let discountAmt = 0;
        let discountPer = 0;
        let allowDiscount = true;
        if (lineItem.Product.hasOwnProperty("AllowDiscount")) {
            allowDiscount = lineItem.Product.AllowDiscount;
        }
        if (allowDiscount) {
            if (Number(lineItem.DiscountAmount) > 0 && Number(lineItem.DiscountPer) === 0
            ) {
                //calculate discount per based on discount amount
                discountPer = Number(((Number(lineItem.DiscountAmount) * 100) /Number(lineItem.SubTotal)).toFixed(3));
                discountAmt = roundUpTo2(lineItem.DiscountAmount);
            } else {
                discountPer = Number(lineItem.DiscountPer);
                discountAmt = roundUpTo2((Number(subTotal) * Number(lineItem.DiscountPer)) / 100);
            }
            if (discountInfo) {
                if (discountInfo.trim() !== "") {
                    lineItem.Discounts = [];
                    lineItem.Discounts.push({
                        DiscountType: discountType,
                        DiscountID: discountID,
                        DiscountInfo: discountInfo,
                        DiscountAmount: discountAmt,
                        DiscountPercentage: discountPer,
                        TransactionLevelDiscount: transactionLevelDiscount,
                    });
                }
            } else if (discountAmt === 0) {
                lineItem.Discounts = [];
            }
        } else {
            lineItem.Discounts = [];
        }
    
        let subTotalWithDiscount = roundUpTo2(Number(subTotal) - Number(discountAmt));
        let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct;
        let priceBasedTax = lineItem.PriceBasedTax;
        let taxInclusive = lineItem.TaxInclusive;
        let taxableAmount = 0;
        let totalTax = 0;
        let amount = 0;
        let taxComponentAmount = [];
       
        // Pawan
        // let transactionTypeForCalculation = transactionType;
        // if (transactionTypeForCalculation) {
        //     if (transactionTypeForCalculation.hasOwnProperty("CalculateTaxChecked")) {
        //         if (!transactionTypeForCalculation.CalculateTaxChecked) {
        //             chargeTaxOnProduct = false;
        //         } else if (transactionType) {
        //             chargeTaxOnProduct = true;
        //         }
        //     }
        // }
        
        if (chargeTaxOnProduct) {
            if (priceBasedTax) {
                let unitPriceAfterDiscount = roundUpTo2(Number(subTotalWithDiscount) / Number(lineItem.Qty));
                lineItem.Product.TaxPriceRange.map((t) => {
                    if ( Number(unitPriceAfterDiscount) >= Number(t.FromPrice) && Number(unitPriceAfterDiscount) <= Number(t.ToPrice)) {
                        lineItem.TaxID = t.TaxID;
                        lineItem.TaxGroup = t.TaxGroup;
                        return;
                    }
                });
            }
            let taxfound = false;
            let customerFromSameState = true; //TBD IMP IMP
    
            
            if (placeOfSupply !== "" && getState().user.store.State !== "") {
                if (placeOfSupply.toLowerCase() !== this.props.user.store.State.toLowerCase()) {
                    customerFromSameState = false;
                }
            }
            getState().config.ProductTaxes.filter((t) => t.key === lineItem.TaxID.trim()).map((taxGroup, index) => {
                taxfound = true;
                let taxAmount = 0;
                let taxComponentTemp = [];
                taxGroup.Taxes.map((t) => {
                    if (customerFromSameState && (t.TaxType === "CGST" || t.TaxType === "SGST")) {
                        taxComponentTemp.push({
                            TaxName: t.TaxName,
                            TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType,
                            TaxAmount: 0,
                        });
                    } 
                    else if (!customerFromSameState && t.TaxType === "IGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName,
                            TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType,
                            TaxAmount: 0,
                        });
                    } 
                    else if (t.TaxType !== "CGST" && t.TaxType !== "IGST" && t.TaxType !== "SGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName,
                            TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType,
                            TaxAmount: 0,
                        });
                    }
                });
    
                if (taxInclusive) {
                    //tax inclusive
                    let totalTaxRate = 0;
                    taxComponentTemp.map((t) => {totalTaxRate = roundUpTo2(Number(t.TaxPercentage) + Number(totalTaxRate));
                    });
                    totalTax = roundUpTo2((Number(subTotalWithDiscount) * Number(totalTaxRate)) /(100 + Number(totalTaxRate)));
                    taxableAmount = roundUpTo2(Number(subTotalWithDiscount) - Number(totalTax));
                    amount = roundUpTo2(subTotalWithDiscount);
                    taxComponentTemp.map((t) => {taxAmount = roundUpTo2((Number(totalTax) * ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate))) /100);
                        taxComponentAmount.push({
                            TaxName: t.TaxName,
                            TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType,
                            TaxAmount: taxAmount,
                        });
                    });
                } else {
                    taxableAmount = subTotalWithDiscount;
                    taxComponentTemp.map((t) => {
                        taxAmount = roundUpTo2((Number(taxableAmount) * Number(t.TaxPercentage)) /100);
                        totalTax = roundUpTo2(Number(totalTax) + Number(taxAmount));
                        taxComponentAmount.push({
                            TaxName: t.TaxName,
                            TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType,
                            TaxAmount: taxAmount,
                        });
                    });
                    amount = roundUpTo2(taxableAmount + totalTax);
                }
            });
            //tax is not found then
            if (!taxfound) {
                //alert("Tax not found for selected product. Please check item ")
                taxableAmount = 0;
                taxComponentAmount = [];
                totalTax = 0;
                amount = subTotalWithDiscount;
            }
        } else {
            taxableAmount = 0;
            taxComponentAmount = [];
            totalTax = 0;
            amount = subTotalWithDiscount;
        }
        lineItem.SubTotal = roundUpTo2(subTotal);
        lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
        lineItem.TaxInclusive = taxInclusive;
        lineItem.DiscountPer = roundUpTo2(discountPer);
        lineItem.DiscountAmount = roundUpTo2(discountAmt);
        lineItem.SubTotalWithDiscount = roundUpTo2(subTotalWithDiscount);
        lineItem.TaxableAmount = roundUpTo2(taxableAmount);
        lineItem.TaxComponentAmount = taxComponentAmount;
        lineItem.TotalTax = roundUpTo2(totalTax);
        lineItem.Amount = roundUpTo2(amount);
        return lineItem;
    }   
}

export function printFormatting(varSale) {

    //check if any line item has showAsAdditionalCharges
    let sale = {...varSale}
    if (sale.hasOwnProperty('ReceivedPaymentTransactions')) {
        for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
            sale.BillAmount = Number(sale.BillAmount) + Number(sale.ReceivedPaymentTransactions[i].ReceiveAmount)
        }
    }

    sale.TransactionDateShort = (new Date(sale.TransactionDate)).toISOString().split('T')[0]
    sale.TransactionDate = sale.TransactionDate.toString().substring(0, 24)
    sale.AmountInWords = convertNumberToWords(sale.BillAmount)
    let billTaxableAmount = 0
    let lineItemsTmp = []
    for (let i = 0; i < sale.LineItems.length; i++) {
        lineItemsTmp.push(sale.LineItems[sale.LineItems.length - (i + 1)])
    }
    let lineItemsWithOutAdditionalCharges = []
    let lineItemsWithAdditonalCharges = []
    let subTotalWithoutAdditionalCharges = 0

    sale.LineItems = lineItemsTmp
    let costPriceTotal = 0
    let mrpTotal = 0
    sale.LineItems.map((lineItem, index) => {
        sale.LineItems[index].SrNo = index + 1
        sale.LineItems[index].BasePrice = roundUpTo2(Number(sale.LineItems[index].TaxableAmount) / Number(sale.LineItems[index].Qty))
        if (lineItem.TaxComponentAmount && lineItem.TaxComponentAmount.length > 0 && lineItem.TaxInclusive) {
            let taxPercentage = 0
            let taxAmount = 0
            lineItem.TaxComponentAmount.map(m => {
                taxPercentage = taxPercentage + Number(m.TaxPercentage)
            })
            taxAmount = roundUpTo2(Number(lineItem.RetailPrice) * Number(taxPercentage) / (100 + Number(taxPercentage)))
            sale.LineItems[index].CostPrice = Number(lineItem.RetailPrice) - Number(taxAmount)
        }
        else {
            sale.LineItems[index].CostPrice = lineItem.RetailPrice
        }
        sale.LineItems[index].CostPriceTotal = roundUpTo2(Number(sale.LineItems[index].CostPrice) * Number(sale.LineItems[index].Qty))
        costPriceTotal = costPriceTotal + Number(sale.LineItems[index].CostPriceTotal)

        sale.LineItems[index].MRP = Number(lineItem.Product.MRP === 0 ? lineItem.RetailPrice : lineItem.Product.MRP);

        sale.LineItems[index].MRPTotal = roundUpTo2(Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty));
        mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal)

        billTaxableAmount = roundUpTo2(Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount))

        if(lineItem.Product.hasOwnProperty('ShowAsAdditionalCharges') && lineItem.Product.ShowAsAdditionalCharges)
        {
            lineItemsWithAdditonalCharges.push(sale.LineItems[index])
        }
        else
        {
            lineItemsWithOutAdditionalCharges.push(sale.LineItems[index])
            subTotalWithoutAdditionalCharges= Number(subTotalWithoutAdditionalCharges) + Number(sale.LineItems[index].Amount)
        }
    })

    sale.BillCostPriceTotal = costPriceTotal
    sale.BillMRPTotal = mrpTotal
    sale.MRPSaving = roundUpTo2(roundUpTo2(Number(sale.BillMRPTotal) - roundUpTo2(Number(sale.BillAmount))));

    sale.BillTaxableAmount = roundUpTo2(Number(billTaxableAmount))
    let printTaxComponents = []
    let printTaxComponentsWithRate = []
    sale.LineItems.map((lineItem, index) => {
        //check modifers 
        if (lineItem.Modifiers && lineItem.Modifiers.length > 0) {
            let modifiersPrint = []
            for (let k = 0; k < lineItem.Modifiers.length; k++) {
                let index = -1
                for (let l = 0; l < modifiersPrint.length; l++) {
                    if (modifiersPrint[l].ModifierName === lineItem.Modifiers[k].ModifierName) {
                        index = l
                        break
                    }
                }
                if (index === -1) {
                    modifiersPrint.push({
                        key: lineItem.Modifiers[k].key,
                        ModifierName: lineItem.Modifiers[k].ModifierName,
                        ModifierPrice: lineItem.Modifiers[k].ModifierPrice,
                        ModifierGroup: lineItem.Modifiers[k].ModifierGroup,
                        ModifierGroupID: lineItem.Modifiers[k].ModifierGroupID,
                        ModifierQty: 1
                    })
                }
                else {
                    modifiersPrint[index].ModifierQty = modifiersPrint[index].ModifierQty + 1
                    modifiersPrint[index].ModifierPrice = modifiersPrint[index].ModifierPrice + lineItem.Modifiers[k].ModifierPrice
                }
            }
            lineItem.ModifiersPrint = modifiersPrint
        }
        if (lineItem.TaxID !== "") {
            lineItem.TaxComponentAmount.map((t) => {
                let indexFound = -1
                printTaxComponents.map((pt, ptIndex) => {
                    if (t.TaxType === pt.TaxType) {
                        indexFound = ptIndex
                        return
                    }
                })
                if (indexFound < 0) {
                    printTaxComponents.push({
                        TaxType: t.TaxType,
                        TaxAmount: roundUpTo2(Number(t.TaxAmount))
                    })
                }
                else {
                    printTaxComponents[indexFound].TaxAmount = roundUpTo2(Number(printTaxComponents[indexFound].TaxAmount) + Number(t.TaxAmount))
                }
                let indexFoundTaxRate = -1
                printTaxComponentsWithRate.map((pt, ptIndex) => {
                    if (t.TaxType === pt.TaxType && t.TaxPercentage === pt.TaxPercentage) {
                        indexFoundTaxRate = ptIndex
                        return
                    }
                })
                if (indexFoundTaxRate < 0) {
                    printTaxComponentsWithRate.push({
                        TaxType: t.TaxType, TaxPercentage: t.TaxPercentage,
                        TaxAmount: roundUpTo2(Number(t.TaxAmount)), TaxableAmount: Number(lineItem.TaxableAmount)
                    })
                }
                else {
                    printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount = roundUpTo2(Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount) + Number(t.TaxAmount))
                    printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount = roundUpTo2(Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount) + Number(lineItem.TaxableAmount))
                }
            })
        }
    })
    sale.PrintTaxComponents = printTaxComponents
    sale.PrintTaxComponentsWithRate = printTaxComponentsWithRate
    sale.LineItems.map((lineItem, index) => {
        for (var i = 0; i < printTaxComponents.length; i++) {
            if (lineItem.TaxComponentAmount.length < (i + 1)) {
                lineItem.TaxComponentAmount.push({ TaxName: '', TaxAmount: 0, TaxPercentage: '', TaxType: '' })
            }
        }
    })
    let previousBalance = 0 
    if(sale.Customer && sale.Customer.hasOwnProperty('OutStandingAmount') )
    {
        previousBalance = sale.Customer.OutStandingAmount
    }
    sale.PreviousBalance = previousBalance
    sale.TotalBalanceAmount =sale.BalanceAmount + previousBalance
    sale.TotalBalanceAfterPaid = sale.TotalBalanceAmount - sale.BillPaidAmount

    sale.LineItemsWithOutAdditionalCharges =  lineItemsWithOutAdditionalCharges 
    sale.LineItemsWithAdditonalCharges =  lineItemsWithAdditonalCharges
    sale.SubTotalWithoutAdditionalCharges  =  subTotalWithoutAdditionalCharges

    return sale;
}

export function wiseposPrinting(sale, printConfig){
       
    // let printConfig=this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key)[0]
    if(printConfig.StoreCodeDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.StoreCode,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreNameDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.StoreName,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StorePhoneNoDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.PhoneNo,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.EmailID)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.EmailID,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreAddressLine1Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.Address1,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreAddressLine2Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.Address2,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreAddressCityDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.City,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreAddressStateDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.State,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreAddressPostalCodeDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.PostalCode,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreAddressCountryDisplay)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.Country,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreCustomField1Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.CustomField1,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreCustomField2Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.CustomField2,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreCustomField3Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.CustomField3,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreCustomField4Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.CustomField4,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreCustomField5Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.CustomField5,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    if(printConfig.StoreCustomField6Display)
    {
        window.cordova.plugins.mswipe.print({text:sale.Store.CustomField6,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"CENTER",
        isLineBreak:true})
    }
    window.cordova.plugins.mswipe.print({text:"",
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})
    //transaction no and date
    if(printConfig.TransactionNoDisplay)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.TransactionNoDisplayName + ": " +  sale.TransactionNo,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(printConfig.TransactionDateDisplay)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.TransactionDateDisplayName + ": " +  sale.TransactionDate,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
   
    window.cordova.plugins.mswipe.print({text:"",
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})
    
    //customer details
    if(sale.Customer && printConfig.CustomerCodeDisplay)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCodeDisplayName + ": " +  sale.Customer.CustomerCode,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerNameDisplay)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerNameDisplayName + ": " +  sale.Customer.FirstName + " "  + sale.Customer.LastName ,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerPhoneNoDisplay)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerPhoneNoDisplayName + ": " +  sale.Customer.PhoneNo,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerEmailIDDisplay)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerEmailIDDisplayName + ": " +  sale.Customer.EmailID,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerCustomField1Display)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCustomField1DisplayName + ": " +  sale.Customer.CustomField1,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerCustomField2Display)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCustomField2DisplayName + ": " +  sale.Customer.CustomField2,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerCustomField3Display)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCustomField3DisplayName + ": " +  sale.Customer.CustomField3,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerCustomField4Display)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCustomField4DisplayName + ": " +  sale.Customer.CustomField4,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerCustomField5Display)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCustomField5DisplayName + ": " +  sale.Customer.CustomField5,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    if(sale.Customer && printConfig.CustomerCustomField6Display)
    {
        window.cordova.plugins.mswipe.print({text:printConfig.CustomerCustomField6DisplayName + ": " +  sale.Customer.CustomField6,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }
    
    if(sale.Customer){
        window.cordova.plugins.mswipe.print({text:"",
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
    }

    //sale line items
    // window.cordova.plugins.mswipe.print({text:"--------------------------------",
    // fontSize:"TWENTY_FOUR",
    // isBold:false,
    // Align:"LEFT",
    // isLineBreak:false})
    
    window.cordova.plugins.mswipe.print({text:"--------------------------------" + "Item           Qty  Rate  Amount" + "-------------------------------",
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})
    
    for(let i=0;i<sale.LineItems.length;i++)
    {
        let l = sale.LineItems[i]
        let printText = l.Product.Name.substring(0,14) + " ".repeat((14 - l.Product.Name.toString().length) > 0 ? (14 - l.Product.Name.toString().length) : 0)
        printText = printText + " " +  l.Qty.toString() + " ".repeat((4 - l.Qty.toString().length) > 0 ? (4 - l.Qty.toString().length) : 0)
        printText = printText + " " + " ".repeat((4 - l.RetailPrice.toString().length) > 0 ? (4 - l.RetailPrice.toString().length) : 0) + l.RetailPrice.toString() 
        printText = printText + " " + " ".repeat((6 - l.Amount.toString().length > 0 )? (6 - l.Amount.toString().length) : 0) + l.Amount.toString() 
        window.cordova.plugins.mswipe.print({text:printText,
        fontSize:"TWENTY_FOUR",
        isBold:false,
        Align:"LEFT",
        isLineBreak:true})
        //check for modifiers
        if(l.hasOwnProperty('Modifiers'))
        {
            let modifierText= ""
            for(let ctrm=0; ctrm< l.Modifiers.length;ctrm++){
                modifierText = modifierText  +  ", " + l.Modifiers[ctrm].ModifierName
            }   
            window.cordova.plugins.mswipe.print({text:modifierText,
            fontSize:"TWENTY_FOUR",
            isBold:false,
            Align:"LEFT",
            isLineBreak:true})
        }
        //check for uniqueno
        if(l.hasOwnProperty('UniqueNoList'))
        {
            for(let ctri=0;ctri<l.UniqueNoList.length;ctri++)
            {
                // let printText = l.Product.UniqueLabel + ": " 
                // if(l.UniqueNoList[ctri].UniqueNo1.trim()!=="")
                // {
                //     printText=printText+    l.UniqueNoList[ctri].UniqueNo1 
                // }
                // + l.UniqueNoList[ctri].UniqueNo1 +  l.UniqueNoList[ctri].UniqueNo2.trim()!=="" ? ", ": " " + l.UniqueNoList[ctri].UniqueNo2.trim() +  l.UniqueNoList[ctri].UniqueNo3.trim()!=="" ? ", ": " " + l.UniqueNoList[ctri].UniqueNo3.trim() +  l.UniqueNoList[ctri].UniqueNo4.trim()!=="" ? ", ": " " + l.UniqueNoList[ctri].UniqueNo4.trim()
                if(l.UniqueNoList[ctri].UniqueNo1.trim()!=="")
                {
                    window.cordova.plugins.mswipe.print({text:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1,
                    fontSize:"TWENTY_FOUR",
                    isBold:false,
                    Align:"LEFT",
                    isLineBreak:true})
                }
                if(l.UniqueNoList[ctri].UniqueNo2.trim()!=="")
                {
                    window.cordova.plugins.mswipe.print({text:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2,
                    fontSize:"TWENTY_FOUR",
                    isBold:false,
                    Align:"LEFT",
                    isLineBreak:true})
                }
                if(l.UniqueNoList[ctri].UniqueNo3.trim()!=="")
                {
                    window.cordova.plugins.mswipe.print({text:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3,
                    fontSize:"TWENTY_FOUR",
                    isBold:false,
                    Align:"LEFT",
                    isLineBreak:true})
                }
                if(l.UniqueNoList[ctri].UniqueNo4.trim()!=="")
                {
                    window.cordova.plugins.mswipe.print({text:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4,
                    fontSize:"TWENTY_FOUR",
                    isBold:false,
                    Align:"LEFT",
                    isLineBreak:true})
                }
               
                
            }
        }
    }

    window.cordova.plugins.mswipe.print({text: "-".repeat(32)+ " ".repeat(15) + " ".repeat((4 - sale.BillQty.toString().length) > 0 ? (4 - sale.BillQty.toString().length) : 0) +  sale.BillQty + "       " + " ".repeat((6 - sale.BillAmount.toString().length > 0 )? (6 - sale.BillAmount.toString().length) : 0) + sale.BillAmount.toString() + "-".repeat(32),
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})

    window.cordova.plugins.mswipe.print({text:"Discount:" + " ".repeat((23 - sale.BillDiscount.toString().length) > 0 ? (23 - sale.BillDiscount.toString().length) : 0),
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})

    if(sale.PrintTaxComponents.length> 0)
    {
        for(let l=0;l<sale.PrintTaxComponents.length;l++)
        {
            let p = sale.PrintTaxComponents[l]
            window.cordova.plugins.mswipe.print({text:p.TaxType + ":" +  " ".repeat((30 - p.TaxType.toString().length - p.TaxAmount.toString().length) > 0 ? (30 - p.TaxType.toString().length - p.TaxAmount.toString().length) : 0) + p.TaxAmount.toString(),
            fontSize:"TWENTY_FOUR",
            isBold:false,
            Align:"LEFT",
            isLineBreak:true})
        }
    }

    window.cordova.plugins.mswipe.print({text:"-".repeat(32)+ "Total:" +  " ".repeat((26 - sale.BillAmount.toString().length) > 0 ? (26 - sale.BillAmount.toString().length) : 0) + sale.BillAmount.toString()+ "-".repeat(31),
    fontSize:"TWENTY_FOUR",
    isBold:true,
    Align:"LEFT",
    isLineBreak:true})

    if(sale.Payments.length> 0)
    {
        for(let l=0;l<sale.Payments.length;l++)
        {
            let p = sale.Payments[l]
            window.cordova.plugins.mswipe.print({text:p.PaymentMode + ":" +  " ".repeat((31 - p.PaymentMode.toString().length - p.Amount.toString().length) > 0 ? (31 - p.PaymentMode.toString().length - p.Amount.toString().length) : 0) + p.Amount.toString(),
            fontSize:"TWENTY_FOUR",
            isBold:false,
            Align:"LEFT",
            isLineBreak:true})
        }
    }

    window.cordova.plugins.mswipe.print({text: "Balance:" +  " ".repeat((24 - sale.BalanceAmount.toString().length) > 0 ? (24 - sale.BalanceAmount.toString().length) : 0) + sale.BalanceAmount.toString(),
    fontSize:"TWENTY_FOUR",
    isBold:true,
    Align:"LEFT",
    isLineBreak:true})

    window.cordova.plugins.mswipe.print({text:"",
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})

    window.cordova.plugins.mswipe.print({text:"",
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})
    
    window.cordova.plugins.mswipe.print({text:"",
    fontSize:"TWENTY_FOUR",
    isBold:false,
    Align:"LEFT",
    isLineBreak:true})
}

export function worldlineDevicePrinting(sale, printConfig){
    // alert('worldlineDevicePrinting');
    // alert(JSON.stringify(sale));
    // alert(JSON.stringify(printConfig));
    let printData = []
    // let printConfig = this.props.PrintConfigs.filter((e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key)[0];

    printData.push({PrintType:"text",PrintText:"SALE INVOICE", Alignment:"center", Bold:true})
    if (printConfig.StoreCodeDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.StoreCode, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreNameDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.StoreName, Alignment:"center", Bold:true})
    }
    if (printConfig.StorePhoneNoDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.PhoneNo, Alignment:"center", Bold:true})
    }
    if (printConfig.EmailID) {
        printData.push({PrintType:"text",PrintText:sale.Store.EmailID, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreAddressLine1Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.Address1, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreAddressLine2Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.Address2, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreAddressCityDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.City, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreAddressStateDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.State, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreAddressPostalCodeDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.PostalCode, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreAddressCountryDisplay) {
        printData.push({PrintType:"text",PrintText:sale.Store.Country, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreCustomField1Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.CustomField1, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreCustomField2Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.CustomField2, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreCustomField3Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.CustomField3, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreCustomField4Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.CustomField4, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreCustomField5Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.CustomField5, Alignment:"center", Bold:true})
    }
    if (printConfig.StoreCustomField6Display) {
        printData.push({PrintType:"text",PrintText:sale.Store.CustomField6, Alignment:"center", Bold:true})
    }
    printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})
   
    if (printConfig.TransactionNoDisplay) {
        printData.push({PrintType:"text",PrintText:printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo, Alignment:"left", Bold:true})
    }
    if (printConfig.TransactionDateDisplay) {
        printData.push({PrintType:"text",PrintText:printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate, Alignment:"left", Bold:true})
    }
    printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})
    //customer details
    if (sale.Customer && printConfig.CustomerCodeDisplay && sale.Customer.CustomerCode!=="") {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCodeDisplayName + ": " + sale.Customer.CustomerCode, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerNameDisplay) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerNameDisplayName + ": " + sale.Customer.FirstName + " " + sale.Customer.LastName, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerCustomField1Display) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCustomField1DisplayName + ": " + sale.Customer.CustomField1, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerCustomField2Display) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCustomField2DisplayName + ": " + sale.Customer.CustomField2, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerCustomField3Display) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCustomField3DisplayName + ": " + sale.Customer.CustomField3, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerCustomField4Display) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCustomField4DisplayName + ": " + sale.Customer.CustomField4, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerCustomField5Display) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCustomField5DisplayName + ": " + sale.Customer.CustomField5, Alignment:"left", Bold:true})
    }
    if (sale.Customer && printConfig.CustomerCustomField6Display) {
        printData.push({PrintType:"text",PrintText:printConfig.CustomerCustomField6DisplayName + ": " + sale.Customer.CustomField6, Alignment:"left", Bold:true})
    }

    if (sale.Customer) {
        printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})           
    }

    printData.push({PrintType:"header",PrintText:"", Alignment:"left", Bold:true})           
   
    for (let i = 0; i < sale.LineItems.length; i++) {
        let l = sale.LineItems[i]
        printData.push({PrintType:"lineitem",Item:l.Product.Name,
            Qty:l.Qty.toString(), Rate:l.RetailPrice.toString(),
            Amount:l.Amount.toString(),Alignment:"left", Bold:true})

        //check for modifiers
        if (l.hasOwnProperty('Modifiers')) {
            let modifierText = ""
            for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
                modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName
            }
            printData.push({PrintType:"text",PrintText:modifierText, Alignment:"left", Bold:true})
        }
        //check for uniqueno
        if (l.hasOwnProperty('UniqueNoList')) {
            for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
                if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
                    printData.push({PrintType:"text",PrintText:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1, Alignment:"left", Bold:true})
                }
                if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
                    printData.push({PrintType:"text",PrintText:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2, Alignment:"left", Bold:true})
                }
                if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
                    printData.push({PrintType:"text",PrintText:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3, Alignment:"left", Bold:true})
                }
                if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
                    printData.push({PrintType:"text",PrintText:l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4, Alignment:"left", Bold:true})
               }
            }
        }
    }

    printData.push({PrintType:"seperator",Bold:true});

    if(printConfig.TransactionTotalQtyDisplay){
        printData.push({PrintType:"leftright",LeftText:printConfig.TransactionTotalQtyDisplayName + ":", RightText:sale.BillQty.toString(),Bold:true})
    }
    if(printConfig.TransactionDiscountDisplay){
        //printData.push({PrintType:"leftright",LeftText:printConfig.TransactionDiscountDisplayName + ":", RightText:sale.BillQty.toString(),Bold:true})
    }
    if(printConfig.TransactionTotalDisplay){
        printData.push({PrintType:"leftright",LeftText:printConfig.TransactionTotalDisplayName + ":", RightText:sale.BillAmount.toString(),Bold:true})
    }
    // let paymentMode = ""
    if (sale.Payments.length > 0) {
        for (let l = 0; l < sale.Payments.length; l++) {
            let p = sale.Payments[l]
            // paymentMode = paymentMode+ " " + p.PaymentMode.toString();
            printData.push({PrintType:"leftright",LeftText:p.PaymentMode.toString() + ":", RightText:p.Amount.toString(),Bold:true})

        }
    }
    if(printConfig.TransactionPaidDisplay){
        printData.push({PrintType:"leftright",LeftText:printConfig.TransactionPaidDisplayName + ":", RightText:sale.BillPaidAmount.toString(),Bold:true})
    }
    if(printConfig.TransactionBalanceDisplay){
        printData.push({PrintType:"leftright",LeftText:printConfig.TransactionBalanceDisplayName + ":", RightText:sale.BalanceAmount.toString(),Bold:true})
    }
    if(printConfig.TransactionRoundOffDisplay){
        printData.push({PrintType:"leftright",LeftText:printConfig.TransactionRoundOffDisplayName + ":", RightText:sale.RoundOff.toString(),Bold:true})
    }
    
    // if(printConfig.TransactionPaymentModeDisplay){
    //     printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})           
    //     printData.push({PrintType:"leftright",LeftText:printConfig.TransactionPaymentModeDisplayName + ":", RightText:paymentMode,Bold:true})
    // }
    if(printConfig.TransactionNotesDisplay && sale.Notes){
        printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})           
        printData.push({PrintType:"leftright",LeftText:printConfig.TransactionNotesDisplayName + ":", RightText:sale.Notes,Bold:true})
    }
    if(printConfig.FooterText){
        // printData.push({PrintType:"text",PrintText:printConfig.FooterText, Alignment:"center", Bold:true})           
    }
    // if (sale.PrintTaxComponents.length > 0) {
    //     for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
    //         let p = sale.PrintTaxComponents[l]
    //         printData.push({
    //             "PrintDataType": 0,
    //             "PrinterWidth": 24,
    //             "IsCenterAligned": true,
    //             "DataToPrint": p.TaxType + ":" + " ".repeat((30 - p.TaxType.toString().length - p.TaxAmount.toString().length) > 0 ? (30 - p.TaxType.toString().length - p.TaxAmount.toString().length) : 0) + p.TaxAmount.toString(),
    //             "ImagePath": "",
    //             "ImageData": ""
    //         })
    //     }
    // }
    // console.log(printData);
    // alert('worldlineDevicePrinting end' + JSON.stringify(printData));
    return printData;
    // alert("worline print data");
  
}

export function pinelabsDevicePrinting(sale, printConfig, pineLabsApplicationID, userId){
    let printData = []

    //                 { 
    //                 "PrintDataType": "0", 
    //                 "PrinterWidth": 24, 
    //                 "IsCenterAligned": true, 
    //                 "DataToPrint": "ABC"
    //                 } 
// console.log('sale.TransactionDate',sale.TransactionDate)
   
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":"SALE INVOICE"})
    if (printConfig.StoreCodeDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.StoreCode})
    }
    if (printConfig.StoreNameDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.StoreName})
    }
    if (printConfig.StorePhoneNoDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.PhoneNo})
    }
    if (printConfig.EmailID) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.EmailID})
    }
    if (printConfig.StoreAddressLine1Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.Address1})
    }
    if (printConfig.StoreAddressLine2Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.Address2})
    }
    if (printConfig.StoreAddressCityDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.City})
    }
    if (printConfig.StoreAddressStateDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.State})
    }
    if (printConfig.StoreAddressPostalCodeDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.PostalCode})
    }
    if (printConfig.StoreAddressCountryDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.Country})
    }
    if (printConfig.StoreCustomField1Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.CustomField1})
    }
    if (printConfig.StoreCustomField2Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.CustomField2})
    }
    if (printConfig.StoreCustomField3Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.CustomField3})
    }
    if (printConfig.StoreCustomField4Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.CustomField4})
    }
    if (printConfig.StoreCustomField5Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.CustomField5})
    }
    if (printConfig.StoreCustomField6Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":sale.Store.CustomField6})
    }
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":""})   
    if (printConfig.TransactionNoDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo})   
    }
    if (printConfig.TransactionDateDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate})   
    }
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": true,  "DataToPrint":""})   
    //customer details
    if (sale.Customer && printConfig.CustomerCodeDisplay && sale.Customer.CustomerCode!=="") {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCodeDisplayName + ": " + sale.Customer.CustomerCode})   
    }
    if (sale.Customer && printConfig.CustomerNameDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerNameDisplayName + ": " + sale.Customer.FirstName + " " + sale.Customer.LastName})   
    }
    if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo})   
    }
    if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID})   
    }
    if (sale.Customer && printConfig.CustomerCustomField1Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCustomField1DisplayName + ": " + sale.Customer.CustomField1})   
    }
    if (sale.Customer && printConfig.CustomerCustomField2Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCustomField2DisplayName + ": " + sale.Customer.CustomField2})   
    }
    if (sale.Customer && printConfig.CustomerCustomField3Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCustomField3DisplayName + ": " + sale.Customer.CustomField3})   
    }
    if (sale.Customer && printConfig.CustomerCustomField4Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCustomField4DisplayName + ": " + sale.Customer.CustomField4})   
    }
    if (sale.Customer && printConfig.CustomerCustomField5Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCustomField5DisplayName + ": " + sale.Customer.CustomField5})   
    }
    if (sale.Customer && printConfig.CustomerCustomField6Display) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.CustomerCustomField6DisplayName + ": " + sale.Customer.CustomField6})   
    }

    if (sale.Customer) {
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":""})   
    }
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":"--------------------------------"})   
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":"ITEM         QTY   RATE   AMOUNT"})   
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":"--------------------------------"})   
    
    for (let i = 0; i < sale.LineItems.length; i++) {
        let l = sale.LineItems[i]
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":l.Product.Name});
        let strLineItem = "          " + String("     " + l.Qty.toString()).slice(-5) + " X " + String("     " + l.RetailPrice.toString()).slice(-5) + " = " + String("     " + l.Amount.toString()).slice(-5)
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":strLineItem}); 
        //check for modifiers
        if (l.hasOwnProperty('Modifiers')) {
            let modifierText = ""
            for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
                modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName
            }
            printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":modifierText});
            // printData.push({PrintType:"text",PrintText:modifierText, Alignment:"left", Bold:true})
        }
        //check for uniqueno
        if (l.hasOwnProperty('UniqueNoList')) {
            for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
                if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
                    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1});
                }
                if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
                    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2});
                }
                if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
                    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3});
                }
                if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
                    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4});
               }
            }
        }
    }

    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":"--------------------------------"})   

    if(printConfig.TransactionTotalQtyDisplay){
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionTotalQtyDisplayName + ":" + " ".repeat((32- (printConfig.TransactionTotalQtyDisplayName.length + sale.BillQty.toString().length + 1))) + sale.BillQty.toString()});
        // printData.push({PrintType:"leftright",LeftText:printConfig.TransactionTotalQtyDisplayName + ":", RightText:sale.BillQty.toString(),Bold:true})
    }
    if(printConfig.TransactionDiscountDisplay){
        //printData.push({PrintType:"leftright",LeftText:printConfig.TransactionDiscountDisplayName + ":", RightText:sale.BillQty.toString(),Bold:true})
    }
    if(printConfig.TransactionTotalDisplay){
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionTotalDisplayName + ":" + " ".repeat((32- (printConfig.TransactionTotalDisplayName.length + sale.BillAmount.toString().length + 1))) + sale.BillAmount.toString()});
        // printData.push({PrintType:"leftright",LeftText:printConfig.TransactionTotalDisplayName + ":", RightText:sale.BillAmount.toString(),Bold:true})
    }
    // let paymentMode = ""
    if (sale.Payments.length > 0) {
        for (let l = 0; l < sale.Payments.length; l++) {
            let p = sale.Payments[l]
            // paymentMode = paymentMode+ " " + p.PaymentMode.toString();
            // printData.push({PrintType:"leftright",LeftText:p.PaymentMode.toString() + ":", RightText:p.Amount.toString(),Bold:true})
            printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":p.PaymentMode.toString() + ":" + " ".repeat((32- (p.PaymentMode.toString().length + p.Amount.toString().length + 1))) + p.Amount.toString()});

        }
    }
    if(printConfig.TransactionPaidDisplay){
        // printData.push({PrintType:"leftright",LeftText:printConfig.TransactionPaidDisplayName + ":", RightText:sale.BillPaidAmount.toString(),Bold:true})
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionPaidDisplayName + ":" + " ".repeat((32- (printConfig.TransactionPaidDisplayName.length + sale.BillPaidAmount.toString().length + 1))) + sale.BillPaidAmount.toString()});
        
    }
    if(printConfig.TransactionBalanceDisplay){
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionBalanceDisplayName + ":" + " ".repeat((32- (printConfig.TransactionBalanceDisplayName.length + sale.BalanceAmount.toString().length + 1))) + sale.BalanceAmount.toString()});
        // printData.push({PrintType:"leftright",LeftText:printConfig.TransactionBalanceDisplayName + ":", RightText:sale.BalanceAmount.toString(),Bold:true})
    }
    if(printConfig.TransactionRoundOffDisplay){
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionRoundOffDisplayName + ":" + " ".repeat((32- (printConfig.TransactionRoundOffDisplayName.length + sale.RoundOff.toString().length + 1))) + sale.RoundOff.toString()});
        // printData.push({PrintType:"leftright",LeftText:printConfig.TransactionRoundOffDisplayName + ":", RightText:sale.RoundOff.toString(),Bold:true})
    }
    
    // if(printConfig.TransactionPaymentModeDisplay){
    //     printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})           
    //     printData.push({PrintType:"leftright",LeftText:printConfig.TransactionPaymentModeDisplayName + ":", RightText:paymentMode,Bold:true})
    // }
    if(printConfig.TransactionNotesDisplay && sale.Notes){
        // printData.push({PrintType:"text",PrintText:"", Alignment:"center", Bold:true})           
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":""})   
        // printData.push({PrintType:"leftright",LeftText:printConfig.TransactionNotesDisplayName + ":", RightText:sale.Notes,Bold:true});
        printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":printConfig.TransactionNotesDisplayName + ":" + " ".repeat((32- (printConfig.TransactionNotesDisplayName.length + sale.Notes.toString().length + 1))) + sale.Notes.toString()}); 

    }
    if(printConfig.FooterText){
        // printData.push({PrintType:"text",PrintText:printConfig.FooterText, Alignment:"center", Bold:true})           
    }
    // if (sale.PrintTaxComponents.length > 0) {
    //     for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
    //         let p = sale.PrintTaxComponents[l]
    //         printData.push({
    //             "PrintDataType": 0,
    //             "PrinterWidth": 24,
    //             "IsCenterAligned": true,
    //             "DataToPrint": p.TaxType + ":" + " ".repeat((30 - p.TaxType.toString().length - p.TaxAmount.toString().length) > 0 ? (30 - p.TaxType.toString().length - p.TaxAmount.toString().length) : 0) + p.TaxAmount.toString(),
    //             "ImagePath": "",
    //             "ImageData": ""
    //         })
    //     }
    // }
    // console.log(printData);
    // alert('worldlineDevicePrinting end' + JSON.stringify(printData));
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":" "})   
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":" "})   
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":" "})   
    printData.push({"PrintDataType":"0", "PrinterWidth": 32,  "IsCenterAligned": false,  "DataToPrint":" "})   


    let printPayload = {
        "Header": { 
                "ApplicationId": pineLabsApplicationID, 
                "UserId": userId, 
                "MethodId": "1002", 
                "VersionNo": "1.0" 
            }, 
            "Detail": { 
                "PrintRefNo": "PrintRefNo", 
                "SavePrintData": true, 
                "Data":printData
            }
        }
    console.log('printPayload', printPayload);
    return printPayload;
    // alert("worline print data");
  
}