import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "../Loader";
import ReactHtmlParser from "react-html-parser";
import * as constants from "../../Constatnts";
import MultiSelect from "@khanacademy/react-multi-select";
import Input from "../2/Controls/Input";

class MonthlySalesReport extends Component {
  constructor() {
    super();
    this.state = {
      dailySales: [],
      stores: [],
      storeList: [],
      // searchTransactionFromDate: new Date("2-Dec-2018").toISOString().split('T')[0],
      // searchTransactionToDate: new Date().toISOString().split('T')[0],
      exportCalled: false,
      searchStoreName: "",
      loading: true,
      finalTable: null,
      selectedStores: [],
      searchTransactionFromDate: null,
      searchTransactionToDate: null,
      years: [],
      months: [],
      selectedYear: 0,
      selectedMonth: 0,
    };
    this.onSearchClick = this.onSearchClick.bind(this);
    this.loadMonthlySalesData = this.loadMonthlySalesData.bind(this);
    this.loadPaymentModeData = this.loadPaymentModeData.bind(this);
    this.loadPaymentReceivedData = this.loadPaymentReceivedData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.loadCategoryData = this.loadCategoryData.bind(this);
  }

  async componentDidMount() {
    let selectedYear = new Date().getFullYear();
    let selectedMonth = new Date().getMonth();
    let years = this.props.getYears();
    let months = this.props.getMonths(selectedYear);
    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );

    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    let selectedStores = [];
    selectedStores.push(this.props.user.store.key);

    let result = await this.props.getUserStores(
      this.props.user.user.RegistrationID,
      ""
    );
    let stores = [];
    result.map((store) => {
      let obj = { value: store.key, label: store.StoreName };
      stores.push(obj);
    });

    this.setState({
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      stores: stores,
      storeList: storeList,
      selectedStores: selectedStores,
      years: years,
      months: months,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });

    this.loadData(
      searchTransactionToDate,
      searchTransactionFromDate,
      storeList
    );
  }

  async loadCategoryData(
    storeList,
    searchTransactionToDate,
    searchTransactionFromDate
  ) {
    if (this.props.registration.registration.key !== "3jmetD9dqz2VWf1SyU76") {
      return [];
    }
    let result = await this.props.getSubCategoryItemSoldReportData(
      this.props.registration.registration.key,
      storeList,
      searchTransactionToDate,
      searchTransactionFromDate,
      "",
      "Chilled Beverages"
    );
    console.log("result", result);
    return result.searchResult;
  }

  async loadData(
    searchTransactionToDate,
    searchTransactionFromDate,
    storeList
  ) {
    let dailySales = await this.loadMonthlySalesData(
      searchTransactionToDate,
      searchTransactionFromDate,
      storeList
    );
    let payments = await this.loadPaymentModeData(
      searchTransactionToDate,
      searchTransactionFromDate,
      storeList
    );
    let received = await this.loadPaymentReceivedData(
      searchTransactionToDate,
      searchTransactionFromDate,
      storeList
    );
    let categoryResult = await this.loadCategoryData(
      storeList,
      searchTransactionToDate,
      searchTransactionFromDate
    );
    // console.log('categoryResult', categoryResult);
    // console.log('payments', payments);
    // console.log('received', received);
    // For Creating Columns
    for (let index = 0; index < dailySales.length; index++) {
      let sale = dailySales[index];
      sale["Total Credit Received (B)"] = 0;
      sale["Total Collection (A+B)"] = 0;
      if (this.props.registration.registration.key === "3jmetD9dqz2VWf1SyU76") {
        sale["Chilled Beverages"] = 0;
        for (let index1 = 0; index1 < categoryResult.length; index1++) {
          let obj = categoryResult[index1];
          if (sale["Store"].trim() === obj["store"].trim()) {
            sale["Chilled Beverages"] =
              obj.total_sales === undefined ? 0 : obj.total_sales;
          }
        }
      }

      for (
        let index = 0;
        index < this.props.config.PaymentTypes.length;
        index++
      ) {
        if (
          this.props.config.PaymentTypes[index].ShowInSales &&
          !this.props.config.PaymentTypes[index].IsDeleted
        )
          sale[this.props.config.PaymentTypes[index].PaymentType] = 0;
      }

      for (let index1 = 0; index1 < payments.length; index1++) {
        let payment = payments[index1];

        if (
          sale["Transaction Month"] === payment["Transaction Month"] &&
          sale["Store"].trim() === payment["Store"].trim()
        ) {
          sale[payment.PaymentMode] =
            payment.Payment_total_sales === undefined
              ? 0
              : payment.Payment_total_sales;
        }
      }

      for (let index2 = 0; index2 < received.length; index2++) {
        let obj = received[index2];
        if (
          sale["Transaction Month"] === obj["Transaction Month"] &&
          sale["Store"].trim() === obj["Store"].trim()
        ) {
          // console.log(obj);
          sale["Total Credit Received (B)"] = obj.Payment_total_received;
          // console.log("sale", sale);
        }
      }
      sale["Total Collection (A+B)"] =
        Number(sale["Total Credit Received (B)"]) +
        Number(sale["Total Sales Paid (A)"]);
    }

    var col = [];
    for (var i = 0; i < dailySales.length; i++) {
      for (var key in dailySales[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }

    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < dailySales.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        if (j > 1) {
          result += "<td style='text-align:right'>";
        } else {
          result += "<td>";
        }

        result +=
          dailySales[i][col[j]] == undefined ? 0 : dailySales[i][col[j]];
        result += "</td>";
      }
      result += "</tr>";
    }

    // ADD Totals
    result += "<tr><td style='font-weight:bold'>Totals</td>";
    result += "<td style='font-weight:bold'>-</td>";
    for (var j = 2; j < col.length; j++) {
      let total = 0;
      for (var i = 0; i < dailySales.length; i++) {
        total +=
          dailySales[i][col[j]] === undefined
            ? 0
            : Number(dailySales[i][col[j]]);
      }
      // result += "<td style='font-weight:bold'>";
      if (j > 1) {
        result += "<td style='font-weight:bold;text-align:right'>";
      } else {
        result += "<td style='font-weight:bold'>";
      }
      result += Number(total).toFixed(2);
      result += "</td>";
    }
    result += "</tr>";
    result += "</tbody>";
    result += "</table>";
    this.setState({ finalTable: result, loading: false });
  }

  async onExportClick() {
    let dailySales = await this.loadMonthlySalesData(
      this.state.searchTransactionToDate,
      this.state.searchTransactionFromDate,
      this.state.storeList
    );
    let payments = await this.loadPaymentModeData(
      this.state.searchTransactionToDate,
      this.state.searchTransactionFromDate,
      this.state.storeList
    );
    let received = await this.loadPaymentReceivedData(
      this.state.searchTransactionToDate,
      this.state.searchTransactionFromDate,
      this.state.storeList
    );
    let categoryResult = await this.loadCategoryData(
      this.state.storeList,
      this.state.searchTransactionToDate,
      this.state.searchTransactionFromDate
    );

    // For Creating Columns
    for (let index = 0; index < dailySales.length; index++) {
      let sale = dailySales[index];
      sale["Total Credit Received (B)"] = 0;
      sale["Total Collection (A+B)"] = 0;
      if (this.props.registration.registration.key === "3jmetD9dqz2VWf1SyU76") {
        sale["Chilled Beverages"] = 0;
        for (let index1 = 0; index1 < categoryResult.length; index1++) {
          let obj = categoryResult[index1];
          if (sale["Store"].trim() === obj["store"].trim()) {
            sale["Chilled Beverages"] =
              obj.total_sales === undefined ? 0 : obj.total_sales;
          }
        }
      }

      for (
        let index = 0;
        index < this.props.config.PaymentTypes.length;
        index++
      ) {
        if (
          this.props.config.PaymentTypes[index].ShowInSales &&
          !this.props.config.PaymentTypes[index].IsDeleted
        )
          sale[this.props.config.PaymentTypes[index].PaymentType] = 0;
      }

      for (let index1 = 0; index1 < payments.length; index1++) {
        let payment = payments[index1];

        if (
          sale["Transaction Month"] === payment["Transaction Month"] &&
          sale["Store"].trim() === payment["Store"].trim()
        ) {
          sale[payment.PaymentMode] =
            payment.Payment_total_sales === undefined
              ? 0
              : payment.Payment_total_sales;
        }
      }

      for (let index2 = 0; index2 < received.length; index2++) {
        let obj = received[index2];
        if (
          sale["Transaction Month"] === obj["Transaction Month"] &&
          sale["Store"].trim() === obj["Store"].trim()
        ) {
          // console.log(obj);
          sale["Total Credit Received (B)"] = obj.Payment_total_received;
          // console.log("sale", sale);
        }
      }
      sale["Total Collection (A+B)"] =
        Number(sale["Total Credit Received (B)"]) +
        Number(sale["Total Sales Paid (A)"]);
    }

    var col = [];
    for (var i = 0; i < dailySales.length; i++) {
      for (var key in dailySales[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }

    var finalSales = [];
    for (var i = 0; i < dailySales.length; i++) {
      //result += "<tr>";
      var sale = dailySales[i];
      for (var j = 0; j < col.length; j++) {
        sale[col[j]] = sale[col[j]] === undefined ? 0 : sale[col[j]];
      }
      finalSales.push(sale);
    }

    const csvData = constants.objToCSV(finalSales);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "MonthlySales.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async loadMonthlySalesData(
    searchTransactionToDate,
    searchTransactionFromDate,
    storeList
  ) {
    let dailySales = [];
    return this.props
      .getDailySalesWithoutReceivePayment(
        this.props.user.user.RegistrationID,
        storeList,
        searchTransactionToDate,
        searchTransactionFromDate,
        "month"
      )
      .then((result) => {
        dailySales = result.searchResult;
        return dailySales;
      });
  }

  async loadPaymentModeData(
    searchTransactionToDate,
    searchTransactionFromDate,
    storeList
  ) {
    let payments = [];
    return this.props
      .getPaymentModeWiseDaily(
        this.props.user.user.RegistrationID,
        storeList,
        searchTransactionToDate,
        searchTransactionFromDate,
        "month"
      )
      .then((result) => {
        payments = result.searchResult;
        return payments;
      });
  }

  async loadPaymentReceivedData(
    searchTransactionToDate,
    searchTransactionFromDate,
    storeList
  ) {
    let payments = [];
    return this.props
      .getPaymentReceivedDaily(
        this.props.user.user.RegistrationID,
        storeList,
        searchTransactionToDate,
        searchTransactionFromDate,
        "month"
      )
      .then((result) => {
        payments = result.searchResult;
        return payments;
      });
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    let selectedStores = [];

    selectedStores.push(this.props.user.store.key);

    this.setState({
      searchTransactionFromDate: new Date("2-Dec-2018")
        .toISOString()
        .split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      storeList: storeList,
      selectedStores: selectedStores,
      loading: true,
    });
    this.loadData(
      new Date().toISOString().split("T")[0],
      new Date("2-Dec-2018").toISOString().split("T")[0],
      storeList
    );
  }

  onSearchClick() {
    this.setState({
      loading: true,
    });
    this.loadData(
      this.state.searchTransactionToDate,
      this.state.searchTransactionFromDate,
      this.state.storeList
    );
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  onYearChange(value) {
    let selectedYear = value;
    let months = this.props.getMonths(selectedYear);
    let selectedMonth = months[months.length - 1].Month;
    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );
    this.setState({
      months: months,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });
  }

  getMonthStartDate(selectedYear, selectedMonth) {
    return this.props.getStartDateOfMonth(selectedYear, selectedMonth);
  }

  getMonthEndDate(selectedYear, selectedMonth) {
    return this.props.getEndDateOfMonth(selectedYear, selectedMonth);
  }

  onMonthChange(value) {
    let selectedMonth = value;
    let searchTransactionFromDate = this.getMonthStartDate(
      this.state.selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      this.state.selectedYear,
      selectedMonth
    );
    this.setState({
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>Monthly Sales</font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Store Name</label>
                      <MultiSelect
                        selectAllLabel="All Stores"
                        options={this.state.stores}
                        selected={this.state.selectedStores}
                        onSelectedChanged={(selected) => {
                          let storeList = [];
                          if (selected && selected.length > 0) {
                            for (let i = 0; i < selected.length; i++) {
                              storeList.push({ key: selected[i] });
                            }
                          } else {
                            storeList.push({ key: this.props.user.store.key });
                            selected.push(this.props.user.store.key);
                          }
                          this.setState({
                            storeList: storeList,
                            selectedStores: selected,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Year"
                        value={this.state.selectedYear}
                        onChange={this.onYearChange}
                      >
                        {this.state.years.map((e) => (
                          <option value={e}>{e}</option>
                        ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Month"
                        value={this.state.selectedMonth}
                        onChange={this.onMonthChange}
                      >
                        {this.state.months.map((e) => (
                          <option value={e.Month}>{e.MonthName}</option>
                        ))}
                      </Input>
                    </div>
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export{" "}
                        <i className="glyphicon glyphicon-download-alt "></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.finalTable)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(MonthlySalesReport);
