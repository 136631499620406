import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Form from "../2/Controls/Form";
import "./PincodeIssue.css";

class PincodeIssue extends Component {
  constructor() {
    super();
    this.state = {
      issueID: "",
      orderID: "",
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      issueDate: "",
      issueShortDesription: "",
      issueLongDescription: "",
      storeName: "",
      issueImages: [],
      status: "",
      items: [],
    };
  }

  componentDidMount() {
    let selectedRecord = this.props.selectedRecord;

    this.setState({
      items:
        selectedRecord &&
        selectedRecord.OrderDetails &&
        selectedRecord.OrderDetails.items
          ? selectedRecord.OrderDetails.items
          : [],
      issueID: selectedRecord ? selectedRecord.IssueID : "",
      orderID: selectedRecord ? selectedRecord.OrderID : "",
      issueDate: selectedRecord
        ? new Date(selectedRecord.CreatedOn).toString().substring(0, 24)
        : "",
      issueShortDesription:
        selectedRecord && selectedRecord.IssueShortDescription
          ? selectedRecord.IssueShortDescription
          : "",
      issueLongDescription:
        selectedRecord && selectedRecord.IssueLongDescription
          ? selectedRecord.IssueLongDescription
          : "",
      issueImages:
        selectedRecord && selectedRecord.IssueImages
          ? selectedRecord.IssueImages
          : [],

      status:
        selectedRecord && selectedRecord.State ? selectedRecord.State : "",
      customerPhone:
        selectedRecord && selectedRecord.CustomerPhone
          ? selectedRecord.CustomerPhone
          : "",
      customerName:
        selectedRecord && selectedRecord.CustomerName
          ? selectedRecord.CustomerName
          : "",
      customerEmail:
        selectedRecord && selectedRecord.CustomerEmail
          ? selectedRecord.CustomerEmail
          : "",
    });
  }

  render() {
    return (
      <Form
        title={"Customer Complaint"}
        onClick={(action) => {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
        showFooter={false}
      >
        <Input type="text" label="Issue ID" value={this.state.issueID} />
        <Input type="text" label="Issue Date" value={this.state.issueDate} />
        <Input type="text" label="Order ID" value={this.state.orderID} />
        <Input
          type="text"
          label="Customer Name"
          value={this.state.customerName}
        />
        {/* <Input
          type="text"
          label="Customer Phone"
          value={this.state.customerPhone}
        /> */}
        {/* <Input
          type="text"
          label="Customer Email"
          value={this.state.customerEmail}
        /> */}
        <Input
          type="text"
          label="Issue"
          value={this.state.issueShortDesription}
        />
        <Input
          type="textarea"
          label="Description"
          value={this.state.issueLongDescription}
        />
        {this.state.issueImages.map((i) => (
          <img className="issue-img" src={i} />
        ))}
        <br />
        <br />
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {this.state.items.map((s, index) => (
              <tr>
                <td>
                  <div>{s.SKU}</div>
                  <div>{s.BarCode}</div>
                </td>
                <td>{s.Name}</td>
                <td>{s.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Form>
    );
  }
}

export default PincodeIssue;
