import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import Loader from '../Loader'

class MinQtyReport extends Component {
    constructor() {
        super()
        this.state = {
            items: [],
            stores: [],
            storeList: [],
            searchText: '',
            searchCategory: '',
            searchSubCategory: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchProductSKU: '',
            searchProductName: '',
            searchBrand: '',
            exportCalled: false,
            loading: true,
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.loadMinQtyReportData = this.loadMinQtyReportData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });

        this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        var date = this.props.addDays(-30);
        date = date.toISOString().split('T')[0]
        this.setState({ searchTransactionFromDate: date});

        this.loadMinQtyReportData(this.props.user.store.key, 
            this.state.searchCategory,
            this.state.searchSubCategory, this.state.searchProductSKU,
            this.state.searchProductName, this.state.searchBrand);
    }

    async loadMinQtyReportData(storeID, searchCategory, searchSubCategory,
        searchProductSKU, searchProductName, searchBrand) {
        this.setState({ loading: true })

        //Getting all non-deleted products
        let products = await this.props.getInventoryHistoryProducts(this.state.storeList, searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand)
            .then((result) => {
                return result.searchResult;
            });
            console.log("Products", JSON.stringify(products));

        //Getting product instock qty
        let productsInstock = await this.props.getItemInStockData(this.state.storeList, 0, 10000, this.state.storeID, '', searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand)
            .then((result) => {
                //console.log(JSON.stringify(result.searchResult))
                return result.searchResult;
            });
            // console.log("productsInstock", JSON.stringify(productsInstock));
        
        let finalProducts = [];
        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            let finalProduct = {
                Brand: product.Brand,
                Category: product.Category,
                SubCategory: product.SubCategory,
                SKU: product.SKU,
                Name: product.Name,
                SupplyPrice: product.SupplyPrice,
                RetailPrice: product.RetailPrice,
                MinQty: 0,
                InStockQty: 0
            }

            if (product.hasOwnProperty('StoreSpecificQtys') && product.StoreSpecificQtys && product.StoreSpecificQtys.length > 0) {
                finalProduct.MinQty = (product.StoreSpecificQtys.filter((e) => e.StoreID === this.state.storeID).length > 0 ?
                    product.StoreSpecificQtys.filter((e) => e.StoreID === this.state.storeID)[0].MinQty : 0);
            }

            if (productsInstock && productsInstock.length > 0) {
                finalProduct.InStockQty = (productsInstock.filter((e) => e.ProductID === product.key).length > 0 ? productsInstock.filter((e) => e.ProductID === product.key)[0].InStockQty : 0);
                finalProduct.InStockQty =  Number(finalProduct.InStockQty).toFixed(2);
            }

            //console.log("finalProduct", finalProduct);

            finalProduct.StoreName = this.state.stores.filter((e) => e.key === storeID)[0].StoreName;

            if (finalProduct.MinQty > finalProduct.InStockQty)
                finalProducts.push(finalProduct);
        }
        // console.log("finalProducts", JSON.stringify(finalProducts));
        this.setState({ finalProducts: finalProducts, loading: false });
    }

    onClearSearch() {
        let storeList = [];
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            storeList: storeList, storeID: this.props.user.store.key,
            searchCategory: '', searchSubCategory: '',
            searchProductSKU: '', searchProductName: '', searchBrand: ''
        });

        this.loadMinQtyReportData(this.props.user.store.key, "", "", "", "", "");
    }

    async onExportClick() {
        
        //Getting all non-deleted products
        let products = await this.props.getInventoryHistoryProducts(this.state.storeList, this.state.searchCategory, this.state.searchSubCategory, this.state.searchProductSKU, this.state.searchProductName, this.state.searchBrand)
            .then((result) => {
                return result.searchResult;
            });

        //Getting product instock qty
        let productsInstock = await this.props.getItemInStockData(this.state.storeList, 0, 10000, this.state.storeID, '', this.state.searchCategory, this.state.searchSubCategory, this.state.searchProductSKU, this.state.searchProductName, this.state.searchBrand)
            .then((result) => {
                //console.log(JSON.stringify(result.searchResult))
                return result.searchResult;
            });
        
        let finalProducts = [];
        for (let i = 0; i < products.length; i++) {
            const product = products[i];

            let finalProduct = {
                Brand: product.Brand,
                Category: product.Category,
                ["Sub Category"]: product.SubCategory,
                SKU: product.SKU,
                Name: product.Name,
                "Supply Price": product.SupplyPrice,
                "Retail Price": product.RetailPrice,
                "Min Qty": 0,
                "InStock Qty": 0
            }

            if (product.hasOwnProperty('StoreSpecificQtys') && product.StoreSpecificQtys && product.StoreSpecificQtys.length > 0) {
                finalProduct["Min Qty"] = (product.StoreSpecificQtys.filter((e) => e.StoreID === this.state.storeID) ?
                    product.StoreSpecificQtys.filter((e) => e.StoreID === this.state.storeID)[0].MinQty : 0);
            }

            if (productsInstock && productsInstock.length > 0) {
                finalProduct["InStock Qty"] = (productsInstock.filter((e) => e.ProductID === product.key).length > 0 ? productsInstock.filter((e) => e.ProductID === product.key)[0].InStockQty : 0);
                finalProduct["InStock Qty"] =  Number(finalProduct["InStock Qty"]).toFixed(2);
            }

            finalProduct["Store Name"] = this.state.stores.filter((e) => e.key === this.state.storeID)[0].StoreName;

            if (finalProduct["Min Qty"] > finalProduct["InStock Qty"])
                finalProducts.push(finalProduct);
        }
        const csvData = constatnts.objToCSV(finalProducts);
        // this.setState({ loading: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "MinQty.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    onSearchClick() {
        this.setState({ loading: true })
        this.loadMinQtyReportData(
            this.state.storeID,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e) {
        let storeList = []
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Min Qty Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchBrand}
                                                onChange={(evt) => this.setState({ searchBrand: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{ padding: "0px" }}>
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductSKU}
                                                onChange={(evt) => this.setState({ searchProductSKU: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductName}
                                                onChange={(evt) => this.setState({ searchProductName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ height: "50vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Store Name</th>
                                                <th>Brand</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>SKU</th>
                                                <th>Product Name</th>
                                                <th>Supply Price</th>
                                                <th>Retail Price</th>
                                                <th>Min Qty</th>
                                                <th>In Stock Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.finalProducts.map((prod, index) =>
                                                <tr>
                                                    <td>{prod.StoreName}</td>
                                                    <td>{prod.Brand}</td>
                                                    <td>{prod.Category}</td>
                                                    <td>{prod.SubCategory}</td>
                                                    <td>{prod.SKU}</td>
                                                    <td>{prod.Name}</td>
                                                    <td>{prod.SupplyPrice}</td>
                                                    <td>{prod.RetailPrice}</td>
                                                    <td>{prod.MinQty}</td>
                                                    <td>{prod.InStockQty}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(MinQtyReport)