import React, { Component } from 'react';
import CustomerAddress from './CustomerAddress'
// import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { HotKeys } from 'react-hotkeys';
// import CustomerDate from './CustomerDate'
// import HeaderNew from '../HeaderNew'
// import Footer from '../Common/Footer'
import NewPet from "./AddNewPet";
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Input from '../2/Controls/Input';
import Grid from '../2/Controls/Grid';
import Button from '../2/Controls/Button';
import {isMobileApp} from '../../actions/Util';

const keyMap = {
    save: 'ctrl+s',
    back: 'ctrl+b'
};

class Customer extends Component {
    constructor() {
        super()
        this.state = {
            mobileNoPrefix:"+91",
            quickAdd: true,
            isMobileApp: false,
            key: '',
            stores: [],
            saving: false,
            saleToPurchaseStoreID: '',
            saleToPurchaseStore: '',
            storeID: '',
            customerCode: '',
            firstName: '',
            aadharNo: '',
            lastName: '',
            emailID: '',
            phoneNo: '',
            outStandingAmount: 0,
            customerAcceptsMarketing: false,
            note: '',
            dobNew: { Day: '', Month: '', Year: '' },
            anniversaryNew: { Day: '', Month: '', Year: '' },
            membership: {},
            previousMembership: {},
            membershiplist: [],
            membershipValidity: '',
            membershipValidityDate: null,
            membershipAssignedOn: null,
            walletOpeningBalance: 0,
            openingOutStandingAmount: 0,
            gender: '',
            tag: '',
            firstNameError: false,
            emailORPhoneError: '',
            addresses: [],
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            customField6: '',
            selectedAddress: null,
            selectedAddressIndex: -1,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            references: [],
            referredByCustomer: null,
            walletBalance: 0,
            phoneNoError: "",
            referredBy: '',
            referredByOptions: [],
            disableMembership: false,

            // referredByName: '',

            address1:'',
            address2:'',
            city:'',
            state:'',
            postalCode:'',
            country:'',
            states: [],
            stateCode: '',
            addressType: 'Home',
            pets: [],
            selectedPet: null,
            selectedPetIndex: -1,
            addressColumns:[
                // {Name:'Address Type',  Type:'field',Columns:[{Column:'AddressType'}]},
                // {Name:'Line Address 1',  Type:'field',Columns:[{Column:'Address1'}]},
                // {Name:'Line Address 2',  Type:'field',Columns:[{Column:'Address2'}]},
                // {Name:'City',  Type:'field',Columns:[{Column:'City'}]},
                // {Name:'State',  Type:'field',Columns:[{Column:'State'}]},
                // {Name:'Country',  Type:'field',Columns:[{Column:'Country'}]},
                // {Name:'Postal Code',  Type:'field',Columns:[{Column:'PostalCode'}]},
            ],
            petColumns:[]
        }
        this.addressAddedCallBack = this.addressAddedCallBack.bind(this)
        this.onAddressRemove = this.onAddressRemove.bind(this)
        this.onSaveCustomer = this.onSaveCustomer.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onMembershipChange = this.onMembershipChange.bind(this)
        this.loadMembership = this.loadMembership.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.petAddedCallBack = this.petAddedCallBack.bind(this);
        this.onPetSelected = this.onPetSelected.bind(this);
        this.onPetRemove = this.onPetRemove.bind(this);
        this.renderPage= this.renderPage.bind(this);
    }

    async componentDidMount() {

        let addressColumns = [];
        let petColumns =[];
        if(!isMobileApp()){
            addressColumns=[
                {Name:'Address Type',  Type:'field',Columns:[{Column:'AddressType'}]},
                {Name:'Line Address 1',  Type:'field',Columns:[{Column:'Address1'}]},
                {Name:'Line Address 2',  Type:'field',Columns:[{Column:'Address2'}]},
                {Name:'City',  Type:'field',Columns:[{Column:'City'}]},
                {Name:'State',  Type:'field',Columns:[{Column:'State'}]},
                {Name:'Country',  Type:'field',Columns:[{Column:'Country'}]},
                {Name:'Postal Code',  Type:'field',Columns:[{Column:'PostalCode'}]},
            ];
            petColumns=[
                {Name:'Name',  Type:'field',Columns:[{Column:'PetName'}]},
                {Name:'Gender',  Type:'field',Columns:[{Column:'Gender'}]},
                {Name:'Date of Birth',  Type:'field',Columns:[{Column:'Dob'}]},
                {Name:'Type',  Type:'field',Columns:[{Column:'Type'}]},
                {Name:'Breed',  Type:'field',Columns:[{Column:'Breed'}]},
                {Name:'Weight',  Type:'field',Columns:[{Column:'Weight'}]},
                {Name:'Height',  Type:'field',Columns:[{Column:'Height'}]},
            ];
        }else
        {
            addressColumns=[
                {Name:'Address ',  Type:'columns',Columns:[
                    {Column:'AddressType'},
                    {Column:'Address1'},
                    {Column:'Address2'},
                    {Column:'City'},
                    {Column:'State'},
                    {Column:'Country'},
                    {Column:'PostalCode'}
                ]}
            ];
            petColumns=[
                {Name:'Pet',  Type:'columns',Columns:[
                {Column:'PetName'},
                {Column:'Gender'},
                {Column:'Dob'},
                {Column:'Type'},
                {Column:'Breed'},
                {Column:'Weight'},
                {Column:'Height'}
                ]}
            ]
        }
        this.setState({addressColumns:addressColumns, petColumns:petColumns});

        let mobileNoPrefix = "+91"
        if(this.props.registration.registration.Country !=="" && this.props.registration.registration.Country!=="IN")
        {
            let countries = this.props.getCountries()
            if(countries.filter(f=> f.Name === this.props.registration.registration.Country).length > 0)
            {
                mobileNoPrefix = countries.filter(f=> f.Name === this.props.registration.registration.Country)[0].PhoneCode
            }
        }
        this.setState({ isMobileApp: this.props.isMobileApp(),mobileNoPrefix: mobileNoPrefix })

        let states = await this.props.getStateCodeMaster();
        let country = this.props.user.store.Country;
        let stateCode = this.props.user.store.hasOwnProperty('StateCode') ? this.props.user.store.StateCode : '';
        let city = this.props.user.store.City;

        this.setState({ states: states, city: city, stateCode: stateCode, country: country });

        if ((this.props.registration.registration.hasOwnProperty('AutoUpgradeMembership') && this.props.registration.registration.AutoUpgradeMembership)
            || (this.props.registration.registration.hasOwnProperty('AutoAssignMembership') && this.props.registration.registration.AutoAssignMembership)) {
            this.setState({ disableMembership: true })
        }
        if (this.props.hasOwnProperty('setOnSaveClick')) {
            this.props.setOnSaveClick(this.onSaveCustomer)
        }
        if (this.props.user.store.EnableSaleToPurchase && this.props.config.ConnectionStatus.Status === 'connected')
            this.loadStores();

        this.loadMembership();

        //set display field names if its defined 
        let r = this.props.config.Keywords.filter(f => f.Type === "customer-referred-by")
        if (r.length > 0) {
            // console.log('r[0].Options', r[0].Options)
            this.setState({ referredByOptions: r[0].Options })

        }
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        let key = ""
        if (this.props.calledFromInvoice) {

            if (this.props.selectedCustomer) {
                key = this.props.selectedCustomer.key

            }
        }
        else {
            if (this.props.match.params.key) {
                key = this.props.match.params.key
            }
        }

        if (key.trim() === "") {
            // console.log('this.props.newCustomer',this.props.newCustomer)
            if (this.props.newCustomer) {
                // console.log('this.props.newCustomer',this.props.newCustomer)
                this.setState({
                    firstName: this.props.newCustomer.FirstName, emailID: this.props.newCustomer.EmailID,
                    phoneNo: this.props.newCustomer.PhoneNo,
                })
            }
            return
        }

        this.props.getCustomerDetails(key).then((customer) => {
            // console.log('customer', customer)
            let membershipAssignedOn = null
            let membershipValidity = ''
            let membershipValidityDate = null
            if (customer.Membership && customer.Membership.hasOwnProperty('Validity')) {
                membershipValidity = customer.Membership.Validity
            }
            if (customer.Membership && customer.Membership.hasOwnProperty('AssignmentDate') && customer.Membership.AssignmentDate) {
                membershipAssignedOn = (new Date(customer.Membership.AssignmentDate.seconds * 1000)).toString().substring(0, 15)
            }
            if (customer.Membership && customer.Membership.hasOwnProperty('ValidityDate') && customer.Membership.ValidityDate) {
                membershipValidityDate = (new Date(customer.Membership.ValidityDate.seconds * 1000)).toString().substring(0, 15)
            }
            this.setState({
                quickAdd: false,
                key: key,
                storeID: this.props.user.store.key,
                customerCode: customer.CustomerCode,
                firstName: customer.FirstName,
                aadharNo: customer.hasOwnProperty('AadharNo') ? customer.AadharNo : '',
                lastName: customer.LastName,
                emailID: customer.EmailID,
                phoneNo: customer.PhoneNo,
                dob: null,
                anniversary: null,
                dobNew: customer.hasOwnProperty('DOBNew') ? customer.DOBNew : { Day: '', Month: '', Year: '' },
                anniversaryNew: customer.hasOwnProperty('AnniversaryNew') ? customer.AnniversaryNew : { Day: '', Month: '', Year: '' },
                membership: customer.Membership,
                membershipValidity: membershipValidity,
                membershipAssignedOn: membershipAssignedOn,
                membershipValidityDate: membershipValidityDate,
                previousMembership: customer.Membership,
                walletOpeningBalance: customer.WalletOpeningBalance,
                openingOutStandingAmount: customer.OpeningOutStandingAmount,
                customerAcceptsMarketing: customer.CustomerAcceptsMarketing,
                note: customer.hasOwnProperty('Note') ? customer.Note : "",
                gender: customer.Gender,
                tag: customer.Tag,
                outStandingAmount: customer.OutStandingAmount,
                addresses: customer.Addresses,
                customField1: customer.CustomField1,
                customField2: customer.CustomField2,
                customField3: customer.CustomField3,
                customField4: customer.CustomField4,
                customField5: customer.CustomField5,
                customField6: customer.CustomField6,
                references: customer.References,
                referredByCustomer: customer.ReferredByCustomer,
                walletBalance: customer.hasOwnProperty('WalletBalance') ? customer.WalletBalance : 0,
                saleToPurchaseStoreID: customer.hasOwnProperty('SaleToPurchaseStoreID') ? customer.SaleToPurchaseStoreID : '',
                referredBy: customer.hasOwnProperty('ReferredBy') ? customer.ReferredBy : '',
                pets: customer.hasOwnProperty("PetDetails")
                    ? customer.PetDetails
                    : [],
            })
            console.log('customer',customer)
        })
        
    }

    addressAddedCallBack = (address, index) => {
        if (index === -1) {
            //add addresss
            let addresses = this.state.addresses
            addresses.push(address)
            this.setState({ addresses: addresses })
        }
        else {
            let addresses = this.state.addresses
            addresses[index] = address
            this.setState({ addresses: addresses })
        }
    }

    onAddressSelected(index, address) {
        // console.log('selectedAddressIndex', index)
        // console.log('address', address)
        this.setState({ selectedAddressIndex: index, selectedAddress: address, isShowingModalCustomerAddress: true })
    }

    loadStores() {
        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
            , "", "", "", "").then((result) => {

                this.setState({ stores: result.searchResult })
            });
    }

    onAddressRemove(index) {
        let addresses = this.state.addresses
        addresses.splice(index, 1)
        this.setState({ addresses: addresses })
    }

    onCancel() {
        if (this.props.calledFromInvoice) {
            this.props.onClose()
        }
        else {
            this.props.history.push("/home/customer")
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveCustomer()
        }
    }

    async  onSaveCustomer() {
        this.setState({ saving: true })
        let firstNameError = ""
        let dobError = ""
        let anniversaryError = ""
        let emailError = ""
        let phoneNoError = ""
        let saleToPurchaseStoreError=""
        const key = this.state.key
        if (this.state.firstName.trim() === "") {
            firstNameError = "Please provide Customer Name"

        }
        if (this.state.dobNew.Day.toString().trim() !== "" && this.state.dobNew.Month.toString().trim() === "") {
            dobError = "Please select month"
        }
        if (this.state.dobNew.Day.toString().trim() === "" && this.state.dobNew.Month.toString().trim() !== "") {
            dobError = "Please select day"
        }
        if (this.state.anniversaryNew.Day.toString().trim() !== "" && this.state.anniversaryNew.Month.toString().trim() === "") {
            anniversaryError = "Please select month"
        }
        if (this.state.anniversaryNew.Day.toString().trim() === "" && this.state.anniversaryNew.Month.toString().trim() !== "") {
            anniversaryError = "Please select day"
        }
        if (this.state.phoneNo.trim() !== "" && this.state.mobileNoPrefix ==="+91" && this.state.phoneNo.length < 10) {
            phoneNoError = "Invalid phone no"
        }
        if (this.state.phoneNo.trim() === "" && this.state.emailID.trim() === "") {
            phoneNoError = "Please enter either Phone Number or Email"
            emailError = phoneNoError;
        }
        
        
        if (this.state.phoneNo !== "") {
            if (this.props.registration.registration.hasOwnProperty('IgnoreCustomerPhoneNoUniqueness') && this.props.registration.registration.IgnoreCustomerPhoneNoUniqueness) {
                // isUnique = true
                // console.log('IgnoreCustomerPhoneNoUniqueness', this.props.registration.registration.IgnoreCustomerPhoneNoUniqueness)
            }
            else {
                let isUnique = true
                isUnique = await this.props.checkIfCustomerPhoneNoIsUnique(this.state.phoneNo, key)
                if (!isUnique) {
                    phoneNoError = 'Phone number already exists'
                    // this.setState({ phoneNoError: 'Phone Number already exists', saving: false })
                    // return
                }
            }
        }
    
        let mappingExists = false
        if (this.state.saleToPurchaseStoreID !== "")
        {
            mappingExists = await this.props.checkIfCustomerStoreMappingExists(this.state.saleToPurchaseStoreID, key)
            if (mappingExists) {
                saleToPurchaseStoreError= 'Store is already mapped to a customer'
                // this.setState({ saleToPurchaseStoreError: 'Store is already mapped to a customer', saving: false })
            }
        }

        if (firstNameError !== "" 
        || dobError !== "" 
        || anniversaryError !== "" 
        || emailError !== "" 
        || phoneNoError !== ""
        || saleToPurchaseStoreError!=="") {
            this.setState({
                firstNameError: firstNameError,
                dobError: dobError,
                phoneNoError: phoneNoError,
                saleToPurchaseStoreError:saleToPurchaseStoreError,
                anniversaryError: anniversaryError,
                emailError: emailError,
                saving: false
            })
            return
        }

        if (key !== "") {
            //update customer data
            // console.log('update')
            let customer = {
                CustomerCode: this.state.customerCode,
                AadharNo: this.state.aadharNo,
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                EmailID: this.state.emailID,
                PhoneNo: this.state.phoneNo,
                DOB: null,
                Anniversary: null,
                DOBNew: this.state.dobNew,
                AnniversaryNew: this.state.anniversaryNew,
                Membership: this.state.membership,
                CustomerAcceptsMarketing: this.state.customerAcceptsMarketing,
                Note: this.state.note,
                Gender: this.state.gender, 
                Addresses: this.state.addresses,
                CustomField1: this.state.customField1,
                CustomField2: this.state.customField2,
                CustomField3: this.state.customField3,
                CustomField4: this.state.customField4,
                CustomField5: this.state.customField5,
                CustomField6: this.state.customField6,
                RegistrationID: this.props.user.user.RegistrationID,
                StoreID: this.props.user.store.key,
                ReferredBy: this.state.referredBy,
                IsDeleted: false,
                BackEndUpdate: false,
                PetDetails: this.state.pets,
                WalletBalance: this.state.walletBalance,
                SaleToPurchaseStoreID : this.state.saleToPurchaseStoreID,
                SaleToPurchaseStore : this.state.saleToPurchaseStore
            }
            this.props.updateCustomer(customer, key).then(() => {
                this.props.getToastr("Customer Saved Successfully")
                setTimeout(function () { //Start the timer
                    // this.setState({ saving: false })
                    if (this.props.calledFromInvoice) {
                        let selectedCustomer = {
                            key: key,
                            AadharNo: customer.hasOwnProperty('AadharNo') ? customer.AadharNo : "",
                            CustomerCode: customer.CustomerCode,
                            FirstName: customer.FirstName,
                            LastName: customer.LastName,
                            EmailID: customer.EmailID,
                            PhoneNo: customer.PhoneNo,
                            Membership: customer.Membership,
                            Note: customer.hasOwnProperty('Note') ? customer.Note : "",
                            CustomField1: customer.CustomField1,
                            CustomField2: customer.CustomField2,
                            CustomField3: customer.CustomField3,
                            CustomField4: customer.CustomField4,
                            CustomField5: customer.CustomField5,
                            CustomField6: customer.CustomField6,
                            WalletBalance: this.state.walletBalance,
                            Addresses: customer.Addresses,
                            OutStandingAmount: this.state.outStandingAmount,
                            PetDetails: customer.PetDetails,
                        }
                        this.props.onSave({ ...selectedCustomer })
                    }
                    else {
                        this.props.history.push("/home/customer")
                    }
                }.bind(this), 2000)

            })
        }
        else {
            // console.log('new')
            let addresses = this.state.addresses;

            if (this.state.address1.trim() !== "") {
                let address = {
                    AddressType: this.state.addressType,
                    Address1: this.state.address1,
                    Address2: this.state.address2,
                    City: this.state.city,
                    State: this.state.state,
                    StateCode: this.state.stateCode,
                    PostalCode: this.state.postalCode,
                    Country: this.state.country
                }
                addresses.push(address);
            }

            let customer = {
                CustomerCode: this.state.customerCode,
                AadharNo: this.state.aadharNo,
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                EmailID: this.state.emailID,
                PhoneNo: this.state.phoneNo,
                Tag: this.state.tag,
                DOB: null,
                Anniversary: null,
                DOBNew: this.state.dobNew,
                AnniversaryNew: this.state.anniversaryNew,
                Membership: this.state.membership,
                WalletOpeningBalance: this.state.walletOpeningBalance ? Number(this.state.walletOpeningBalance) : 0,
                WalletBalance: this.state.walletOpeningBalance ? Number(this.state.walletOpeningBalance) : 0,
                OpeningOutStandingAmount: this.state.openingOutStandingAmount ? Number(this.state.openingOutStandingAmount) : 0,
                OpeningOutStandingAmountCurrent: this.state.openingOutStandingAmount ? Number(this.state.openingOutStandingAmount) : 0,
                OpeningOutStandingAmountReceived: 0,
                OutStandingAmount: this.state.openingOutStandingAmount ? Number(this.state.openingOutStandingAmount) : 0,
                OutStandingAmountReceived: 0,
                CustomerAcceptsMarketing: this.state.customerAcceptsMarketing,
                Note: this.state.note,
                Gender: this.state.gender,
                Addresses: addresses,
                CustomField1: this.state.customField1,
                CustomField2: this.state.customField2,
                CustomField3: this.state.customField3,
                CustomField4: this.state.customField4,
                CustomField5: this.state.customField5,
                CustomField6: this.state.customField6,
                RegistrationID: this.props.user.user.RegistrationID,
                StoreID: this.props.user.store.key,
                IsDeleted: false,
                BackEndUpdate: false,
                SaleToPurchaseStoreID: this.state.saleToPurchaseStoreID,
                SaleToPurchaseStore: this.state.saleToPurchaseStore,
                ReferredBy: this.state.referredBy,
                PetDetails: this.state.pets,
            }
            // this.props.addCustomer(customer).then((key) => {
            let key = await this.props.addCustomer(customer)
            console.log('customer save key', key)
            this.props.getToastr("Customer Saved Successfully")
            //show toast that customer is saved 
            //redirect to customer list
            setTimeout(function () { //Start the timer
                if (this.props.calledFromInvoice) {

                    let cust = { ...customer, key: key }
                    this.props.onSave(cust)
                }
                else {
                    // this.props.history.push("/home/customer")
                    if (this.props.isMobileApp()) {
                        this.props.history.push({
                            pathname: '/home/m/customer/',
                            state: { module: "customer", customerID: key, action: 'showactionbutton' }
                        })
                    }
                    else {
                        this.props.history.push("/home/customer")
                    }
                }
            }.bind(this), 2000)
            // })
        }

    }

    loadMembership() {
        // this.props.getMembership(this.props.user.user.RegistrationID, 0, 10000, "active", "").then((memberships) => {
        //     this.setState({ membershiplist: memberships.searchResult })
        // })
        this.setState({ membershiplist: this.props.config.ActiveMemberships })
    }

    onMembershipChange(membershipID) {

        let previousMembership = this.state.previousMembership
        let membershipAssignedOn = null
        let membershipValidity = ''
        let membershipValidityDate = null
        let membership = {}

        if (membershipID === "") {
            this.setState({
                membership: membership, membershipValidity: membershipValidity,
                membershipAssignedOn: membershipAssignedOn, membershipValidityDate: membershipValidityDate
            })
            return
        }
        // console.log('previousMembership', previousMembership)
        let tmp = this.props.config.ActiveMemberships.filter(f => f.key === membershipID)
        if (tmp.length === 0) {
            alert('Invalid membership selected. Please signout and signin again. If the problem still exists please contact support team')
            return
        }

        if (previousMembership && previousMembership.MembershipID === membershipID) {
            membership.MembershipID = previousMembership.MembershipID
            membership.Name = previousMembership.Name
            membership.Validity = previousMembership.hasOwnProperty('Validity') ? previousMembership.Validity : null
            membership.AssignmentDate = previousMembership.hasOwnProperty('AssignmentDate') && previousMembership.AssignmentDate !== "" ? (new Date(previousMembership.AssignmentDate.seconds * 1000)).toString().substring(0, 15) : null
            membership.ValidityDate = previousMembership.hasOwnProperty('ValidityDate') && previousMembership.ValidityDate !== "" ? (new Date(previousMembership.ValidityDate.seconds * 1000)).toString().substring(0, 15) : null
            membershipValidity = membership.Validity
            membershipAssignedOn = membership.AssignmentDate
            membershipValidityDate = membership.ValidityDate
        }
        else {
            membership.MembershipID = membershipID;
            membership.Name = tmp[0].Name;
            membership.Validity = tmp[0].hasOwnProperty('Validity') ? tmp[0].Validity : null
            membership.AssignmentDate = new Date();
            if (tmp[0].hasOwnProperty('Validity') && tmp[0].Validity !== "" && tmp[0].Validity !== 0) {
                let date = new Date();
                // console.log('tmp[0].Validity',tmp[0].Validity)
                date.setDate(date.getDate() + Number(tmp[0].Validity));
                // console.log('date',date)
                membership.ValidityDate = new Date(date.getFullYear() + " " + (date.getMonth() + 1) + " " + date.getDate() + " 23:59")
                // console.log('membership.ValidityDate',membership.ValidityDate)
                membershipValidity = membership.Validity
                membershipAssignedOn = membership.AssignmentDate.toString().substring(0, 15)
                membershipValidityDate = membership.ValidityDate.toString().substring(0, 15)
            }
            // else
            // {
            //     membership.AssignmentDate=null
            //     membership.ValidityDate =null
            // }

        }
        this.setState({
            membership: membership, membershipValidity: membershipValidity,
            membershipAssignedOn: membershipAssignedOn, membershipValidityDate: membershipValidityDate
        })
    }

    renderQuickAddControls() {
        return <React.Fragment>
            <div style={{ marginBottom: this.props.isMobileApp() ? "0px" : "" }}>
                <div className="box-header">
                    <div className="box-title">General Details</div>
                </div>
                <div className="box-body">
                    <div className="row">
                        <div className="col-md-3">
                            <div className={this.state.firstNameError ? "form-group has-error" : "form-group "} >
                                <label >Full Name</label>
                                <input type="text" className="form-control"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(evt) => { this.setState({ firstName: evt.target.value, firstNameError: '' }) }}
                                    value={this.state.firstName} />
                                {this.state.firstNameError && <span className="help-block">{this.state.firstNameError}</span>}
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(evt) => this.setState({ lastName: evt.target.value })}
                                    value={this.state.lastName}
                                />
                            </div>
                        </div> */}
                        <div className="col-md-3">
                            <div className={(this.state.emailORPhoneError!=="" || this.state.phoneNoError!=="") ? "form-group has-error" : "form-group "}  >
                                <label>Phone Number</label>
                                <div className="input-group">
                                    <span className="input-group-addon">+91</span>
                                    <input type="number" className="form-control"
                                        onKeyPress={this.handleKeyPress}
                                        onChange={(evt) => this.setState({ phoneNo: evt.target.value.length > 10 ? this.state.phoneNo : evt.target.value, emailORPhoneError: '', phoneNoError: '' })}
                                        value={this.state.phoneNo}
                                    />
                                </div>
                                {this.state.emailORPhoneError!=="" && <span className="help-block">{this.state.emailORPhoneError}</span>}
                                {this.state.phoneNoError!=="" && <span className="help-block">{this.state.phoneNoError}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className={this.state.emailORPhoneError!=="" ? "form-group has-error" : "form-group "} >
                                <label >Email</label>
                                <input type="text" className="form-control"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(evt) => this.setState({ emailID: evt.target.value, emailORPhoneError: "" })}
                                    value={this.state.emailID}
                                />
                                {this.state.emailORPhoneError !=="" && <span className="help-block">{this.state.emailORPhoneError}</span>}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group ">
                                <label >Aadhar No</label>
                                <input type="text" className="form-control"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(evt) => { this.setState({ aadharNo: evt.target.value }) }}
                                    value={this.state.aadharNo} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >House / Flat / Block No</label>
                                <input type="text"
                                    onKeyPress={this.handleKeyPress}
                                    className="form-control"
                                    onChange={(evt) => this.setState({ address1: evt.target.value })}
                                    value={this.state.address1}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >Landmark</label>
                                <input type="text"
                                    onKeyPress={this.handleKeyPress}
                                    className="form-control"
                                    onChange={(evt) => this.setState({ address2: evt.target.value })}
                                    value={this.state.address2}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >City</label>
                                <input type="text"
                                    onKeyPress={this.handleKeyPress}
                                    className="form-control"
                                    onChange={(evt) => this.setState({ city: evt.target.value })}
                                    value={this.state.city}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >Postal Code</label>
                                <input type="text"
                                    onKeyPress={this.handleKeyPress}
                                    autoFocus={true}
                                    className="form-control"
                                    onChange={(evt) => this.setState({ postalCode: evt.target.value })}
                                    value={this.state.postalCode}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >State</label>
                                <select className="form-control"
                                    value={this.state.stateCode}
                                    onChange={(evt) => this.setState({ stateCode: evt.target.value, state: evt.target.options[evt.target.selectedIndex].text })}>
                                    <option value="">Select</option>
                                    {this.state.states.map(m => <option value={m.code}>{m.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label >Country</label>
                                <input type="text"
                                    onKeyPress={this.handleKeyPress}
                                    autoFocus={true}
                                    className="form-control"
                                    onChange={(evt) => this.setState({ country: evt.target.value })}
                                    value={this.state.country}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button className="quick-add-button" onClick={() => this.setState({ quickAdd: false })}>View more details</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    renderGeneralDetails() {

        return <PageSection title="General Details">
                    <div className="row">
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="First Name"
                                onChange={(value) => { this.setState({ firstName:value, firstNameError:""}) }}
                                value={this.state.firstName} 
                                error={this.state.firstNameError}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Last Name"
                                onChange={(value) => { this.setState({ lastName:value, firstNameError:""}) }}
                                value={this.state.lastName} 
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="mobileno"
                                label="Phone Number"
                                onChange={(value) => { this.setState({ phoneNo:value,phoneNoError:"", emailError: ""}) }}
                                value={this.state.phoneNo} 
                                error={this.state.phoneNoError}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="emailid"
                                label="Email"
                                onChange={(value) => this.setState({ emailID: value, emailError: "",phoneNoError:"" })}
                                value={this.state.emailID}
                                error={this.state.emailError}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <Input
                                type="dateentry"
                                label="Date Of Birth"
                                value={this.state.dobNew}
                                error={this.state.dobError}
                                onChange={(value) => this.setState({ dobNew: value, dobError: "" })}
                                onEnter={this.onSaveCustomer}
                            />
                            {/* <div className={this.state.dobError ? "form-group has-error" : "form-group "} >
                                <label >Date Of Birth</label>
                                <CustomerDate
                                    customerDate={this.state.dobNew}
                                    error={this.state.dobError}
                                    onChange={(dobNew) => this.setState({ dobNew: dobNew, dobError: "" })}
                                />
                                {this.state.dobError !== "" && <span className="help-block">{this.state.dobError}</span>}
                            </div> */}
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="dateentry"
                                label="Anniversary Date"
                                value={this.state.anniversaryNew}
                                error={this.state.anniversaryError}
                                onChange={(value) => this.setState({ anniversaryNew: value, anniversaryError: "" })}
                                onEnter={this.onSaveCustomer}
                            />
                            {/* <div className="form-group" >
                                <label >Anniversary Date</label>
                                <CustomerDate
                                    customerDate={this.state.anniversaryNew}
                                    error={this.state.anniversaryError}
                                    onChange={(anniversaryNew) => this.setState({ anniversaryNew: anniversaryNew, anniversaryError: "" })}
                                />
                                {this.state.anniversaryError !== "" && <span className="help-block">{this.state.anniversaryError}</span>}
                            </div> */}
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label="Customer Code"
                                onChange={(value) => { this.setState({ customerCode:value }) }}
                                value={this.state.customerCode} 
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="select"
                                label="Gender"
                                value={this.state.gender} 
                                onChange={(value) => this.setState({ gender:value})}
                                onEnter={this.onSaveCustomer}
                            >
                                <option >Select gender</option>
                                <option >Female</option>
                                <option >Male</option>
                            </Input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                        type="number"
                                        label="Wallet Opening Amount"
                                        disabled={this.state.key !== "" ? true : false}
                                        onChange={(value) => this.setState({ walletOpeningBalance: value, walletOpeningBalanceError:""})}
                                        value={this.state.walletOpeningBalance}
                                        onEnter={this.onSaveCustomer}
                                        error={this.state.walletOpeningBalanceError}
                                    />
                                </div>
                                {this.state.key !== "" && <div className="col-md-4">
                                    <Input  
                                        type="number"
                                        label="Wallet Balance"
                                        disabled={(this.props.user.user.Role ==="Power User" || this.props.user.user.Role ==="Store Owner") ? false : true}
                                        onChange={(value) => this.setState({ walletBalance: value })}
                                        value={this.state.walletBalance}
                                        onEnter={this.onSaveCustomer}
                                    />
                                </div>}
                                <div className="col-md-4">
                                    <Input
                                        type="number"
                                        label="Outstanding Opening Amount"
                                        disabled={this.state.key !== "" ? true : false}
                                        onChange={(value) => this.setState({ openingOutStandingAmount:value })}
                                        value={this.state.openingOutStandingAmount}
                                        onEnter={this.onSaveCustomer}
                                    />
                                </div>
                            </div>
                            {/* <div className="row">
                                {this.state.referredByCustomer && <div className="col-md-6">
                                    <Input
                                        type="text"
                                        label="Referred By"
                                        value={this.state.referredByCustomer.Name + ' ' + this.state.referredByCustomer.PhoneNo}
                                        onChange={()=>{}}
                                    />
                                </div>}
                            </div> */}
                            <div className="row">
                                {/* {this.state.referredByOptions.length > 0 && <div className="col-md-4">
                                    <Input
                                        type="select"
                                        label="Referred By"
                                        value={this.state.referredBy}
                                        onChange={(value) => this.setState({ referredBy:value })}
                                    >
                                        <option value="">Select</option>
                                        {this.state.referredByOptions.map(m => <option value={m}>{m}</option>)}
                                    </Input>
                                </div>}
                                <div className="col-md-4">
                                    <Input
                                        type="checkbox"
                                        label="Customer accepts marketing"
                                        value={this.state.customerAcceptsMarketing}
                                        onChange={(value) => this.setState({ customerAcceptsMarketing:value})} 
                                    />
                                </div> */}
                                {this.props.user.store.hasOwnProperty('EnableSaleToPurchase') && this.props.user.store.EnableSaleToPurchase && <div className="col-md-4">
                                    <Input
                                        type="select"
                                        value={this.state.saleToPurchaseStoreID}
                                        disabled={this.state.key !== "" ? true : false}
                                        onChange={(value) => this.setState({ saleToPurchaseStoreID: value, saleToPurchaseStore: "", saleToPurchaseStoreError: '' })}
                                        error={this.state.saleToPurchaseStoreError }
                                        onEnter={this.onSaveCustomer}
                                    >
                                        <option value="">Select Store</option>
                                        {this.state.stores.filter((e) => e.key !== this.props.user.store.key && !e.IsDeleted).map((store) =>
                                            <option value={store.key}>{store.StoreName}</option>
                                        )}
                                    </Input>
                                </div>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="textarea"
                                label="Notes"
                                onChange={(value) => this.setState({ note:value})}
                                value={this.state.note}
                            />
                        </div>
                    </div>
        </PageSection>
    }

    renderMembershipDetails() {
        return <PageSection title="Membership Details">
            <div className="row">
                <div className="col-md-3">
                    <Input
                        type="select"
                        label="Membership"
                        value={this.state.membership ? this.state.membership.MembershipID : null}
                        onChange={(value)=>this.onMembershipChange(value)}
                        disabled={this.state.disableMembership}
                        error={this.state.membershipError}
                        onEnter={this.onSaveCustomer}
                    >
                        <option value="">Select Membership</option>
                        {this.state.membershiplist.map((membership) =>
                            <option value={membership.key}>{membership.Name}</option>
                        )}
                    </Input>
                </div>
                {(this.state.membership && this.state.membershipAssignedOn) && <div className="col-md-3">
                    <Input
                        type="text"
                        label="Assigned On"
                        value={this.state.membershipAssignedOn}
                        onEnter={this.onSaveCustomer}
                    />
                </div>}
                {(this.state.membership && this.state.membershipValidity !== "" && this.state.membershipValidity !== 0) && <div className="col-md-3">
                    <Input
                        type="text"
                        label="Validity"
                        value={this.state.membershipValidity}
                        onEnter={this.onSaveCustomer}
                    />
                </div>}
                {(this.state.membership && this.state.membershipValidityDate) &&<div className="col-md-3">
                     <Input
                            type="text"
                            label="Valid Till"
                            value={this.state.membershipValidityDate}
                            onEnter={this.onSaveCustomer}
                    />
                </div>}
            </div>
        </PageSection>}

    // renderAddress() {
    //     const btnColStyle = { width: "120px" }

    //     return <React.Fragment>
    //         <div className={this.props.isMobileApp() ? "box with no-border" : "box"} style={{ marginBottom: this.props.isMobileApp() ? "0px" : "" }}>
    //             <div className={this.props.isMobileApp() ? "box-header" : "box-header with-border"}>
    //                 <div className="box-title">Address</div>
    //                 <button className="btn btn-primary pull-right btn-flat" onClick={() => this.setState({ isShowingModalCustomerAddress: true, selectedAddressIndex: -1, selectedAddress: null })}>Add Address</button>
    //             </div>
    //             <div className="box-body">
    //                 <div className="row">
    //                     <div className="col-md-12">
    //                         {!this.props.isMobileApp() && <table className="table table-bordered table-striped">
    //                             <thead>
    //                                 <tr>
    //                                     <th>Address Type</th>
    //                                     <th>Line Address 1</th>
    //                                     <th>Line Address 2</th>
    //                                     <th>City</th>
    //                                     <th>State</th>
    //                                     <th>Country</th>
    //                                     <th>Postal Code</th>
    //                                     <th style={btnColStyle}></th>
    //                                 </tr>
    //                             </thead>
    //                             <tbody>
    //                                 {this.state.addresses.map((address, index) =>
    //                                     <tr>
    //                                         <td>{address.AddressType}</td>
    //                                         <td>{address.Address1}</td>
    //                                         <td>{address.Address2}</td>
    //                                         <td>{address.City}</td>
    //                                         <td>{address.State}</td>
    //                                         <td>{address.Country}</td>
    //                                         <td>{address.PostalCode}</td>
    //                                         <td>
    //                                             <div className="btn-group">
    //                                                 <button type="button" className="btn btn-md btn-flat btn-default " onClick={() => this.onAddressSelected(index, address)} ><i className="fa fa-edit"></i></button>
    //                                                 <button type="button" className="btn btn-default btn-flat btn-md" onClick={() => this.onAddressRemove(index)}><i className="fa fa-trash-o"></i></button>
    //                                             </div>
    //                                         </td>
    //                                     </tr>
    //                                 )}
    //                             </tbody>
    //                         </table>}
    //                         {this.props.isMobileApp() && <div>
    //                             {this.state.addresses.map((address, index) => <div>
    //                                 <div className="col-xs-10">
    //                                     <div><i className="fa fa-map-marker"></i> {address.AddressType}</div>
    //                                     <div>{address.Address1}</div>
    //                                     <div>{address.Address2}</div>
    //                                     <div>{address.City}</div>
    //                                     <div>{address.State}</div>
    //                                     <div>{address.PostalCode}</div>
    //                                     <div>{address.Country}</div>
    //                                 </div>
    //                                 <div className="col-xs-2">
    //                                     <button type="button" className="btn btn-md btn-flat btn-default "
    //                                         onClick={() => this.onAddressSelected(index, address)} ><i className="fa fa-edit"></i></button>
    //                                     <button type="button" style={{ width: "40px", marginTop: "10px" }} className="btn btn-default btn-flat btn-md" onClick={() => this.onAddressRemove(index)}><i className="fa fa-trash-o"></i></button>
    //                                 </div>
    //                             </div>)}
    //                         </div>}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </React.Fragment>
    // }

    renderAddress() {
        return <PageSection title="Address">
            <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2">
                    <Button type="primary" text="Add" icon="add" onClick={()=>this.setState({ isShowingModalCustomerAddress: true, selectedAddressIndex: -1, selectedAddress: null })}/>
                </div>
            </div>
            <Grid 
                columns={this.state.addressColumns}
                data={this.state.addresses}
                buttons={[
                    {ButtonType:"ImageButton",Type:"edit", Text:"Edit", Icon:"edit"},
                    {ButtonType:"ImageButton",Type:"delete", Text:"Delete", Icon:"delete"},
                ]}

                onRowButtonClicked={(button, index , record)=>{
                    if(button.Type==="edit"){
                        this.onAddressSelected(index, record);
                    }
                    else
                    {
                        this.onAddressRemove(index);
                    }
                }}
            />
    </PageSection>}

    renderAdditionalDetails() {
        return <PageSection title="Additonal Information">
                    <div className="row">
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label={this.state.customField1DisplayName}
                                onChange={(value) => this.setState({ customField1:value})}
                                value={this.state.customField1}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label={this.state.customField2DisplayName}
                                onChange={(value) => this.setState({ customField2:value})}
                                value={this.state.customField2}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label={this.state.customField3DisplayName}
                                onChange={(value) => this.setState({ customField3:value})}
                                value={this.state.customField3}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label={this.state.customField4DisplayName}
                                onChange={(value) => this.setState({ customField4:value})}
                                value={this.state.customField4}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label={this.state.customField5DisplayName}
                                onChange={(value) => this.setState({ customField5:value})}
                                value={this.state.customField5}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                        <div className="col-md-3">
                            <Input
                                type="text"
                                label={this.state.customField6DisplayName}
                                onChange={(value) => this.setState({ customField6:value})}
                                value={this.state.customField6}
                                onEnter={this.onSaveCustomer}
                            />
                        </div>
                    </div>
        </PageSection>}

    renderPetDetails() {
        return <PageSection title="Pet Details">
        <div className="row">
            <div className="col-md-10"></div>
            <div className="col-md-2">
                <Button type="primary" 
                    text="Add" 
                    icon="add" 
                    onClick={()=> this.setState({
                    isShowingModalPet: true,
                    selectedPetIndex: -1,
                    selectedPet: null,
                })}/>
            </div>
        </div>
            
            <Grid 
                columns={this.state.petColumns}
                data={this.state.pets}
                buttons={[
                    {ButtonType:"ImageButton",Type:"edit", Text:"Edit", Icon:"edit"},
                    {ButtonType:"ImageButton",Type:"delete", Text:"Delete", Icon:"delete"},
                ]}

                onRowButtonClicked={(button, index , record)=>{
                    if(button.Type==="edit"){
                        this.onPetSelected( index,record);
                    }
                    else
                    {
                        this.onPetRemove(index)
                    }
                }}
            />
    </PageSection>}

    petAddedCallBack = (pet, index) => {
        if (index === -1) {
            let pets = this.state.pets;
            pets.push(pet);
            this.setState({ pets: pets });
        } else {
            let pets = this.state.pets;
            pets[index] = pet;
            this.setState({ pets: pets });
        }
    };

    onPetSelected(index, pet) {
        this.setState({
            selectedPet: pet,
            selectedPetIndex: index,
            isShowingModalPet: true,
        });
    }

    onPetRemove(index) {
        let pets = this.state.pets;
        pets.splice(index, 1);
        this.setState({ pets: pets });
    }

    renderPage(){
        return <Page 
        title="Customer" 
        showSaveButton={true} 
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action)=>{
            // console.log('action', action)
            if(action==="cancel"){
                this.onCancel();
            }else if(action==="save"){
                this.onSaveCustomer();
            }
        }}
        >

                {this.renderGeneralDetails()}
                
                {this.state.membershiplist.length >  0 &&  this.renderMembershipDetails()}

                {this.props.registration.registration.hasOwnProperty("BusinessTypeID") && this.props.registration.registration.BusinessTypeID === "7" && this.renderPetDetails()}

                {this.renderAddress()}

                 {this.renderAdditionalDetails()}

    </Page>
    }

    render() {

        const handlers = {
            save: this.onSaveCustomer,
            back: this.onCancel
        };

        return <React.Fragment>

            {/* Desktop - Start */}
            {!this.props.isMobileApp() && <HotKeys
                keyMap={keyMap}
                handlers={handlers}>

                {this.renderPage()}
            
            </HotKeys>}

            {this.props.isMobileApp() && this.renderPage()}
            {/* Desktop - End */}

            {/* Mobile - Start */}
            {/* {this.props.isMobileApp() && <React.Fragment>

                <div className="header-new-background"></div>
                {!this.props.showAsComponent && <HeaderNew {...this.props}
                    back={true}
                    title={(this.props.match.params.key && this.props.match.params.key !== "") ? "Edit Customer" : "New Customer"}
                    onBackClick={() => { this.props.history.push({ pathname: '/home/customer' }) }}
                />}
                <br />
                <br />
                <br />

                {this.state.quickAdd && this.renderQuickAddControls()}

                {!this.state.quickAdd && <React.Fragment>
                    {this.renderGeneralDetails()}

                    {this.props.registration.registration.hasOwnProperty(
                                    "BusinessTypeID"
                                ) &&
                                    this.props.registration.registration
                                        .BusinessTypeID === "7" &&
                                    this.renderPetDetails()}
                                    
                    {this.renderMembershipDetails()}

                    {this.renderAddress()}

                    {this.renderReferences()}

                    {this.renderAdditionalDetails()}
                </React.Fragment>}


                {!this.props.showAsComponent && <Footer
                    disabled={this.state.saving}
                    onClick={() => this.onSaveCustomer()} />} 

            </React.Fragment>}*/}
            {/* Mobile - End */}
            
            {this.state.isShowingModalCustomerAddress && <CustomerAddress   {...this.props}
            mobile={this.props.isMobileApp()}
            selectedAddressIndex={this.state.selectedAddressIndex}
            selectedAddress={this.state.selectedAddress}
            onAddressAdded={this.addressAddedCallBack}
            onClose={() => this.setState({ isShowingModalCustomerAddress: false })} />}
               
            {this.state.isShowingModalPet && <NewPet
                {...this.props}
                mobile={this.props.isMobileApp()}
                selectedPetIndex={this.state.selectedPetIndex}
                selectedPet={this.state.selectedPet}
                onPetAdded={this.petAddedCallBack}
                onClose={() =>
                    this.setState({
                        isShowingModalPet: false,
                    })
                }
            />}
            

        </React.Fragment>
    }
}

export default withRouter(Customer)