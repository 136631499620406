import React,{Component} from 'react'
import Modal from 'react-responsive-modal'

class CustomerAddress extends Component
{
    constructor()
    {
        super()
        this.state={
            // customer:null,
            // selectedAddress:null,
            showCustomerAddressSelection:false
        }
        this.onAddressSelected = this.onAddressSelected.bind(this)
    }

    // componentDidMount()
    // {
    //     let showCustomerAddressSelection = false
    //     let selectedAddress = null
    //     //console.log('CustomerAddress.componentDidMount',this.props.selectedAddress)
    //     if(this.props.customer)
    //     {
    //         if(!this.props.selectedAddress)
    //         {
    //             if(this.props.customer.Addresses.length > 1)
    //             {
    //                 showCustomerAddressSelection = true 
    //                 selectedAddress = this.props.customer.Addresses[0]
    //                 this.props.onAddressChange(selectedAddress)
                    
    //             }
    //             else if (this.props.customer.Addresses.length ===1)
    //             {
    //                 selectedAddress = this.props.customer.Addresses[0]
    //                 this.props.onAddressChange(selectedAddress)
    //             }
    //         }
    //     }
    //     this.setState({showCustomerAddressSelection:showCustomerAddressSelection})
    // }

    componentDidMount()
    {
        let showCustomerAddressSelection = false
        let selectedAddress = null
        //console.log('CustomerAddress.componentDidMount',this.props.selectedAddress)

        // console.log('customer', this.props.customer);
        if(this.props.customer)
        {
            if(!this.props.selectedAddress)
            {
                if(this.props.customer.Addresses)
                {
                    if(this.props.customer.Addresses.length > 1)
                    {
                        showCustomerAddressSelection = true 
                        selectedAddress = this.props.customer.Addresses[0]
                        this.props.onAddressChange(selectedAddress)
                        
                    }
                    else if (this.props.customer.Addresses.length ===1)
                    {
                        selectedAddress = this.props.customer.Addresses[0]
                        this.props.onAddressChange(selectedAddress)
                    }
                }
            }
        }
        this.setState({showCustomerAddressSelection:showCustomerAddressSelection})
    }

    onAddressSelected(index){
        let address = this.props.customer.Addresses[index]
        this.setState({showCustomerAddressSelection: false})
        this.props.onAddressChange(address)
    }

    render(){
        return <React.Fragment>
             {this.props.selectedAddress && <button type="button" 
                onClick={()=> {
                        if(this.props.customer.Addresses.length > 1)
                        {
                            this.setState({showCustomerAddressSelection:true})
                        }
                    }}
                className="btn btn-default btn-block btn-flat btn-lg" style={{marginTop:"5px",padding:"0px",height:"46px",whiteSpace: "normal"}}>
                 <div style={{fontSize:"12px", marginLeft:"5px"}} className="pull-left">
                    {this.props.selectedAddress.AddressType}: 
                    {this.props.selectedAddress.Address1 !=="" ? this.props.selectedAddress.Address1 + ", ":""}
                    {this.props.selectedAddress.Address2 !=="" ? this.props.selectedAddress.Address2  + ", ":""}
                    {this.props.selectedAddress.City !=="" ? this.props.selectedAddress.City  + ", ":""}
                    {this.props.selectedAddress.State !=="" ? this.props.selectedAddress.State  + ", ":""}
                    {this.props.selectedAddress.PostalCode !=="" ? this.props.selectedAddress.PostalCode  + ", ":""}
                    {this.props.selectedAddress.Country!== "" ? this.props.selectedAddress.Country : ""}
                </div>
            </button>}
            <Modal open={this.state.showCustomerAddressSelection} 
                onClose={() => this.setState({ showCustomerAddressSelection: false })} center>
               {this.state.showCustomerAddressSelection &&  <div style={{ width: "300px" }}>
                    <h3 style={{ marginTop: "0px" }}>Address Selection</h3>
                    <div className="box-body">
                        {this.props.customer.Addresses.map((c, index)=>
                                <button type="button" onClick={()=> this.onAddressSelected(index)}
                                    className="btn btn-default btn-block btn-flat btn-lg" style={{padding:"10px",height:"100px",whiteSpace: "normal"}}>
                                    <div style={{fontSize:"15px", marginLeft:"5px"}} className="pull-left">
                                        {c.AddressType}: 
                                        {c.Address1 !=="" ? c.Address1 + ", ":""}
                                        {c.Address2 !=="" ? c.Address2  + ", ":""}
                                        {c.City !=="" ? c.City  + ", ":""}
                                        {c.State !=="" ? c.State  + ", ":""}
                                        {c.PostalCode !=="" ? c.PostalCode  + ", ":""}
                                        {c.Country}
                                    </div>
                                </button>
                        )}
                    </div>
                </div>}
            </Modal>
        </React.Fragment>
    }
}

export default CustomerAddress