import React from 'react'
import Confirm from 'react-confirm-bootstrap'
import Switch from "react-switch"

class CashRegisterSetup extends React.Component {
    constructor() {
        super()
        this.state = {
            paymentProvider:"",
            paymentProviderName:"",
            loginID:"",
            password:"",
            paymentModes:[],
            showSetup:false,
            selectedPaymentModeIndex:-1,
            providerError:"",
            paymentModeError:"",
            loginIdError:"",
            passwordError:"",
            saving:false,
            printer:"",
            storeID:"",
            customerCode:"",
            terminalID:"",
            saltKey:"",
            storeIDError:"",
            customerCodeError:"",
            saltKeyError:"",
            customerCodeVerification:"",
            customerCodeVerificationError:"",
            phonePeMerchantID:"",
            phonePeMerchantIDError:"",
            phonePeSaltKey:"",
            phonePeSaltKeyError:"",
            phonePeSaltKeyIndex:1,
            phonePeSaltKeyIndexError:"",
            wisePOSTokenAPIClientCode:"",
            wisePOSTokenAPIClientCodeError:"",
            wisePOSVerificationAPIClientCode:"",
            wisePOSVerificationAPIClientCodeError:"",
            wisePOSStoreID:"",
            wisePOSStoreIDError:"",
            wisePOSTerminalID:"",
            wisePOSSaltKey:"",
            wisePOSSaltKeyError:"",
            wisePOSSDKLoginID:"",
            wisePOSSDKLoginIDError:"",
            wisePOSSDKPassword:"",
            wisePOSSDKPasswordError:"",
            wisePOSVerificationAPIUserID:"",
            wisePOSVerificationAPIPassword:"",
            wisePOSVerificationAPIPasswordError:"",
            payTMMerchantID:"",
            payTMMerchantIDError:"",
            payTMPeMerchantKey:"",
            payTMPeMerchantKeyError:"",
            printerConfig:null,
            worldLineTerminalID:"",
            worldLineTerminalIDError:"",
            testMode:false
            // blueToothPrinterConfig:null
        }
        this.onPaymentProviderChange = this.onPaymentProviderChange.bind(this)
        this.onPaymentSetUpSelected = this.onPaymentSetUpSelected.bind(this)
        this.onNewSetUpSelected =this.onNewSetUpSelected.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onPrinterSave = this.onPrinterSave.bind(this)
        this.onConnectBlueToothPrinter = this.onConnectBlueToothPrinter.bind(this)
        // this.onPineLabsClicked = this.onPineLabsClicked.bind(this)
        this.onPaymentSetUpDeleted = this.onPaymentSetUpDeleted.bind(this)
        this.onBlueToothTestPrint= this.onBlueToothTestPrint.bind(this)
    }

    // onPineLabsClicked(){
    //     alert('plutus call start')
    //     window.cordova.plugins.plutus.call(
    //         {
    //           "Header": {
    //             "ApplicationId": "79099725d3304811bc39a917ae358078",
    //             "UserId": "1234",
    //             "MethodId": "1001",
    //             "VersionNo": "1.0"
    //           },
    //           "Detail": {
    //             "TransactionType": 4001,
    //             "BillingRefNo": "TXN12345678",
    //             "PaymentAmount": 500
    //           }
    //         },
    //         (result) => {
    //           alert(result)
    //           let result_json = JSON.parse(result);
    //           this.setState({
    //             last_response: result_json
    //           })
    //         },
    //         (error) => {
    //           alert("error"+error)
    //         }
    //       );
    //       alert('plutus call end') 
    // }

    onPaymentSetUpDeleted(index){
        
        let cashRegisterPaymentSetup =  this.props.user.cashRegister.CashRegisterPaymentSetup
        cashRegisterPaymentSetup.splice(index, 1)
        this.props.updateCashRegister({CashRegisterPaymentSetup: cashRegisterPaymentSetup}, this.props.user.cashRegister.key).then(()=> {
            this.props.getToastr("Saved Successfully")
            let cashRegister =this.props.user.cashRegister
            cashRegister.key = this.props.user.cashRegister.key
            cashRegister.CashRegisterPaymentSetup = cashRegisterPaymentSetup
            this.props.setCashRegister(cashRegister)
        })
    }

    onConnectBlueToothPrinter() {
        // alert('onConnectBlueToothPrinter called')
        window.BTPrinter.connect((data) => {
            alert(JSON.stringify(data))
            if(data.name && data.address){
                this.setState({
                    printerConfig: {
                        Name: data.name,
                        Address: data.address
                    }
                })
            }
            alert("Printer successfully connected");
        },
        (error) => {
            alert("Error connecting printer - "+error);
        });
    }

    async onBlueToothTestPrint()
    {
        await this.props.printTextBlueToothPrinter(this.state.printerConfig.Name, this.state.printerConfig.Address,"Line 1", "center","normal",30)
        await this.props.printTextBlueToothPrinter(this.state.printerConfig.Name, this.state.printerConfig.Address,"Line 2", "center","normal",30)
        await this.props.printTextBlueToothPrinter(this.state.printerConfig.Name, this.state.printerConfig.Address,"Line 3", "center","normal",30)

        // let response = await window.BTPrinter.printText((data) =>{
        //     alert(JSON.stringify(data));
        //     return true
        //   }, (err) => {
        //     alert(JSON.stringify(err));
        //     console.log("Error");
        //     console.log(err)
        //     return false
        //   }, {
        //     name: this.state.printerConfig.Name,
        //     address: this.state.printerConfig.Address,
        //     text: "printText1",
        //     alignment:"center", // center, left, right
        //     fontSize: "normal", // large, normal
        //     lineSpacing: 30
        //   })
          
        //   let response1 = await window.BTPrinter.printText((data) =>{
        //     alert(JSON.stringify(data));
        //     return true
        //   }, (err) => {
        //     alert(JSON.stringify(err));
        //     console.log("Error");
        //     console.log(err)
        //     return false
        //   }, {
        //     name: this.state.printerConfig.Name,
        //     address: this.state.printerConfig.Address,
        //     text: "printText1",
        //     alignment:"center", // center, left, right
        //     fontSize: "normal", // large, normal
        //     lineSpacing: 30
        //   })
    }
    async componentDidMount() {     
        let paymentModes =  this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted)
        paymentModes.map(m=> m.selected=false)
        // let showSetup = true
        // if(this.props.user.cashRegister && this.props.user.cashRegister.hasOwnProperty('CashRegisterPaymentSetup') && this.props.user.cashRegister.CashRegisterPaymentSetup.length > 0 )
        // {
        //     showSetup= false
        // }
        this.setState({paymentModes:paymentModes, 
            // showSetup:showSetup, 
            printer:this.props.user.cashRegister.hasOwnProperty('Printer')? this.props.user.cashRegister.Printer : "",
            printerConfig: this.props.user.cashRegister.hasOwnProperty('PrinterConfig')? this.props.user.cashRegister.PrinterConfig : null })
    }

    onPaymentProviderChange(evt)
    {
        this.setState({ paymentProviderName: evt.target.options[evt.target.selectedIndex].text, 
            paymentProvider: evt.target.value })
    }
    
    onNewSetUpSelected()
    {
        let paymentModes =  this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted)
        paymentModes.map(m=> m.selected=false)
        this.setState({showSetup:true,
            selectedPaymentModeIndex:-1,  
            paymentProvider:"",
            paymentProviderName:"",
            loginID:"",
            password:"",
            paymentModes:paymentModes,
            providerError:"",
            paymentModeError:"",
            loginIdError:"",
            passwordError:""})
    }

    onPaymentSetUpSelected(index)
    {
        let paymentModes =  this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted)
        paymentModes.map(m=> {
            if( this.props.user.cashRegister.CashRegisterPaymentSetup[index].PaymentModes.filter(f=> f.PaymentTypeID ==m.key ).length > 0  )
            {
                m.selected=true
            }
            else
            {
                m.selected=false
            }
        })
        this.setState({showSetup:true,
            selectedPaymentModeIndex:index, 
            paymentModes:paymentModes, 
            paymentProviderName: this.props.user.cashRegister.CashRegisterPaymentSetup[index].PaymentProviderName,
            loginID: this.props.user.cashRegister.CashRegisterPaymentSetup[index].LoginID,
            password: this.props.user.cashRegister.CashRegisterPaymentSetup[index].Password,
            paymentProvider:this.props.user.cashRegister.CashRegisterPaymentSetup[index].PaymentProvider,
            storeID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('StoreID') ?this.props.user.cashRegister.CashRegisterPaymentSetup[index].StoreID:"",
            customerCode:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('CustomerCode') ?this.props.user.cashRegister.CashRegisterPaymentSetup[index].CustomerCode:"",
            terminalID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('TerminalID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].TerminalID:"",
            saltKey:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('SaltKey') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].SaltKey:"",
            wisePOSTokenAPIClientCode:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSTokenAPIClientCode') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSTokenAPIClientCode:"",
            wisePOSVerificationAPIClientCode:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSVerificationAPIClientCode') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSVerificationAPIClientCode:"",
            wisePOSStoreID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSStoreID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSStoreID:"",
            wisePOSTerminalID: this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSTerminalID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSTerminalID:"",
            wisePOSSaltKey:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSSaltKey') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSSaltKey:"",
            wisePOSSDKLoginID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSSDKLoginID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSSDKLoginID:"",
            wisePOSSDKPassword:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSSDKPassword') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSSDKPassword:"",
            wisePOSVerificationAPIUserID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSVerificationAPIUserID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSVerificationAPIUserID:"",
            wisePOSVerificationAPIPassword: this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WisePOSVerificationAPIPassword') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WisePOSVerificationAPIPassword:"",
            phonePeMerchantID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('PhonePeMerchantID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].PhonePeMerchantID:"",
            phonePeSaltKey:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('PhonePeSaltKey') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].PhonePeSaltKey:"",
            phonePeSaltKeyIndex:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('PhonePeSaltKeyIndex') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].PhonePeSaltKeyIndex:"",
            payTMMerchantID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('PayTMMerchantID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].PayTMMerchantID:"",
            payTMMerchantKey:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('PayTMMerchantKey') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].PayTMMerchantKey:"",    
            worldLineTerminalID:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('WorldLineTerminalID') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].WorldLineTerminalID:"",    
            testMode:this.props.user.cashRegister.CashRegisterPaymentSetup[index].hasOwnProperty('TestMode') ? this.props.user.cashRegister.CashRegisterPaymentSetup[index].TestMode:false, 
            phonePeMerchantIDError:"",
            phonePeSaltKeyError:"",
            phonePeSaltKeyIndexError:"",
            payTMMerchantIDError:"",
            payTMPeMerchantKeyError:"",
            providerError:"",
            paymentModeError:"",
            loginIdError:"",
            passwordError:"",
            storeIDError:"",
            customerCodeError:"",
            storeIDError:"",
            saltKeyError:""
            })
    }

    onSave()
    {
        
        let providerError =""
        let paymentModeError = ""
        let loginIdError =""
        let passwordError =""
        let storeIDError =""
        let customerCodeError=""
        let saltKeyError= ""
        let phonePeMerchantIDError = ""
        let phonePeSaltKeyError=""
        let phonePeSaltKeyIndexError=""
        let wisePOSTokenAPIClientCodeError=""
        let wisePOSVerificationAPIClientCodeError=""
        let wisePOSStoreIDError=""
        let wisePOSSaltKeyError=""
        let wisePOSSDKLoginIDError=""
        let wisePOSSDKPasswordError=""
        let wisePOSVerificationAPIUserIDError=""
        let wisePOSVerificationAPIPasswordError=""
        let payTMMerchantIDError=""
        let payTMMerchantKeyError=""
        let worldLineTerminalIDError=""

        if(this.state.paymentProvider.trim()==="")
        {
            providerError = "Please select provider"
        }
        if(this.state.paymentProvider.trim()==="worldline" && this.state.worldLineTerminalID.trim()==""){
            worldLineTerminalIDError = "Please provide terminal id"
        }
        if(this.state.paymentModes.filter(m=> m.selected).length ===0)
        {
            paymentModeError="Please select payment mode"
        }
        if(this.state.paymentProvider.trim()==="wisepos")
        {
            if(this.state.loginID.trim()==="")
            {
                loginIdError="Please provide login id"
            }
            if(this.state.password.trim()==="")
            {
                passwordError= "Please provide password"
            }
        }
        if(this.state.paymentProvider.trim()==="wisepos collect call")
        {
            // if(this.state.loginID.trim()==="")
            // {
            //     loginIdError="Please provide login id"
            // }
            // if(this.state.password.trim()==="")
            // {
            //     passwordError= "Please provide password"
            // }
            // if(this.state.storeID.trim()==="")
            // {
            //     storeIDError="Please provide store id"
            // }
            // if(this.state.customerCode.trim()==="")
            // {
            //     customerCodeError= "Please provide customer code"
            // }
            // if(this.state.saltKey.trim()==="")
            // {
            //     saltKeyError= "Please provide salt key"
            // }
            if(this.state.wisePOSTokenAPIClientCode.trim()==="")
            {
                wisePOSTokenAPIClientCodeError="Please provide client code"
            }
            if(this.state.wisePOSVerificationAPIClientCode.trim()==="")
            {
                wisePOSVerificationAPIClientCodeError="Please provide client code"
            }
            if(this.state.wisePOSStoreID.trim()==="")
            {
                wisePOSStoreIDError="Please provide store id"
            }
            if(this.state.wisePOSSaltKey.trim()==="")
            {
                wisePOSSaltKeyError="Please provide salt key"
            }
            if(this.state.wisePOSSDKLoginID.trim()==="")
            {
                wisePOSSDKLoginIDError="Please provide login id"
            }
            if(this.state.wisePOSSDKPassword.trim()==="")
            {
                wisePOSSDKPasswordError="Please provide password"
            }
            if(this.state.wisePOSVerificationAPIUserID.trim()==="")
            {
                wisePOSVerificationAPIUserIDError="Please provide user id"
            }
            if(this.state.wisePOSVerificationAPIPassword.trim()==="")
            {
                wisePOSVerificationAPIPasswordError="Please provide passowrd"
            }

        }
        if(this.state.paymentProvider.trim()==="phonepe" || this.state.paymentProvider.trim()==="phonepe qr")
        {
            if(this.state.phonePeMerchantID.trim()==="")
            {
                phonePeMerchantIDError="Please provide merchant id provided by PhonePe"
            }
            if(this.state.phonePeSaltKey.trim()==="")
            {
                phonePeSaltKeyError="Please provide salt key provided by PhonePe"
            }
            if(this.state.phonePeSaltKeyIndex ==="")
            {
                phonePeSaltKeyIndexError="Please provide salt key index provided by PhonePe"
            }
            
        }
        if(this.state.paymentProvider.trim()==="paytm")
        {
            if(this.state.payTMMerchantID.trim()==="")
            {
                payTMMerchantIDError="Please provide merchant id provided by PayTM"
            }
            if(this.state.payTMMerchantKey.trim()==="")
            {
                payTMMerchantKeyError="Please provide key provided by PayTM"
            }
        }
        if(this.state.paymentProvider.trim()==="pinelabs")
        {
            if(this.state.loginID.trim()==="")
            {
                loginIdError="Please provide user id"
            }
        }
        //check if this provider is already setup
        let cashRegisterPaymentSetup= this.props.user.cashRegister.hasOwnProperty('CashRegisterPaymentSetup') ? this.props.user.cashRegister.CashRegisterPaymentSetup : []
        for(let i=0;i<cashRegisterPaymentSetup.length;i++)
        {
            if(cashRegisterPaymentSetup[i].PaymentProvider.trim() === this.state.paymentProvider.trim() && i!== this.state.selectedPaymentModeIndex)
            {
                providerError = "Setup exists for selected provider"
                break
            }
        }
        //check if payment mode is part of someother provider setup
        //do it when you have multiple

        if(providerError.trim()!=="" || paymentModeError.trim()!==""
        || loginIdError.trim()!=="" || passwordError.trim()!==""
        || storeIDError.trim()!=="" || customerCodeError.trim()!=="" || saltKeyError.trim()!==""
        || phonePeMerchantIDError.trim()!=="" ||  phonePeSaltKeyError.trim()!=="" 
        || phonePeSaltKeyIndexError.trim()!==""
        || wisePOSTokenAPIClientCodeError.trim()!==""
        || wisePOSVerificationAPIClientCodeError.trim()!==""
        || wisePOSStoreIDError.trim()!==""
        || wisePOSSaltKeyError.trim()!==""
        || wisePOSSDKLoginIDError.trim()!==""
        || wisePOSSDKPasswordError.trim()!==""
        || wisePOSVerificationAPIUserIDError.trim()!==""
        || wisePOSVerificationAPIPasswordError.trim()!==""
        || payTMMerchantKeyError.trim()!==""
        || payTMMerchantIDError.trim()!==""
        || worldLineTerminalIDError.trim()!==""
        )
        {
            this.setState({providerError:providerError,paymentModeError:paymentModeError,
                loginIdError:loginIdError,
                passwordError:passwordError,
                storeIDError:storeIDError,
                customerCodeError:customerCodeError,
                phonePeMerchantIDError:phonePeMerchantIDError,
                phonePeSaltKeyError:phonePeSaltKeyError,
                phonePeSaltKeyIndexError:phonePeSaltKeyIndexError,
                saltKeyError:saltKeyError,
                wisePOSTokenAPIClientCodeError:wisePOSTokenAPIClientCodeError,
                wisePOSVerificationAPIClientCodeError:wisePOSVerificationAPIClientCodeError,
                wisePOSStoreIDError:wisePOSStoreIDError,
                wisePOSSaltKeyError:wisePOSSaltKeyError,
                wisePOSSDKLoginIDError:wisePOSSDKLoginIDError,
                wisePOSSDKPasswordError:wisePOSSDKPasswordError,
                wisePOSVerificationAPIUserIDError:wisePOSVerificationAPIUserIDError,
                wisePOSVerificationAPIPasswordError:wisePOSVerificationAPIPasswordError,
                payTMMerchantKeyError:payTMMerchantKeyError,
                payTMMerchantIDError:payTMMerchantIDError,
                worldLineTerminalIDError:worldLineTerminalIDError
                })
                return
        }
        let cashRegister = {CashRegisterCode: this.props.user.cashRegister.CashRegisterCode,
            CashRegisterName:this.props.user.cashRegister.CashRegisterName,
            StoreLocationID: this.props.user.cashRegister.StoreLocationID,
            StoreLocation: this.props.user.cashRegister.StoreLocation,
            StoreID: this.props.user.cashRegister.StoreID,
            RegistrationID: this.props.user.user.RegistrationID,
            Printer:this.props.user.cashRegister.hasOwnProperty('Printer') ? this.props.user.cashRegister.Printer : "",
            CashRegisterPaymentSetup:this.props.user.cashRegister.hasOwnProperty('CashRegisterPaymentSetup') ? this.props.user.cashRegister.CashRegisterPaymentSetup : [],
            IsDeleted: false}
        let paymentModes = []
        this.state.paymentModes.filter(f=> f.selected).map(m=>paymentModes.push({PaymentType:m.PaymentType, PaymentTypeID:m.key}))
       
        if(this.state.selectedPaymentModeIndex===-1)
        {
            //new
            cashRegister.CashRegisterPaymentSetup.push({
                PaymentProvider:this.state.paymentProvider,
                PaymentProviderName: this.state.paymentProviderName,
                LoginID: this.state.loginID,
                Password: this.state.password,
                CustomerCode:this.state.customerCode,
                StoreID:this.state.storeID,
                TerminalID: this.state.terminalID,
                PaymentModes:paymentModes,
                SaltKey: this.state.saltKey,
                PhonePeMerchantID:this.state.phonePeMerchantID,
                PhonePeSaltKey:this.state.phonePeSaltKey,
                PhonePeSaltKeyIndex: this.state.phonePeSaltKeyIndex,
                WisePOSTokenAPIClientCode:this.state.wisePOSTokenAPIClientCode,
                WisePOSVerificationAPIClientCode:this.state.wisePOSVerificationAPIClientCode,
                WisePOSStoreID:this.state.wisePOSStoreID,
                WisePOSTerminalID: this.state.wisePOSTerminalID,
                WisePOSSaltKey:this.state.wisePOSSaltKey,
                WisePOSSDKLoginID:this.state.wisePOSSDKLoginID,
                WisePOSSDKPassword:this.state.wisePOSSDKPasswordError,
                WisePOSVerificationAPIUserID:this.state.wisePOSVerificationAPIUserID,
                WisePOSVerificationAPIPassword: this.state.wisePOSVerificationAPIPassword,
                WorldLineTerminalID: this.state.worldLineTerminalID,
                TestMode: this.state.testMode
                // PayTMMerchantID:this.state.payTMMerchantID,
                // PayTMMerchantKey:this.state.payTMMerchantKey
            })
        }
        else
        {
            //udpdate 
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PaymentProvider = this.state.paymentProvider
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PaymentProviderName = this.state.paymentProviderName
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].LoginID = this.state.loginID
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].Password = this.state.password
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].CustomerCode=this.state.customerCode
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].StoreID=this.state.storeID
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].TerminalID= this.state.terminalID
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].SaltKey= this.state.saltKey            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PhonePeMerchantID= this.state.phonePeMerchantID            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PhonePeSaltKey= this.state.phonePeSaltKey            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PhonePeSaltKeyIndex= this.state.phonePeSaltKeyIndex            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PayTMMerchantID= this.state.payTMMerchantID            
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].WorldLineTerminalID= this.state.worldLineTerminalID 
            cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].TestMode= this.state.testMode 
            // cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PayTMMerchantKey= this.state.payTMMerchantKey                        
            // cashRegister.CashRegisterPaymentSetup[this.state.selectedPaymentModeIndex].PaymentModes = paymentModes
            
        }
        // console.log('cashRegister',cashRegister)
        console.log('cashRegister', cashRegister)
        // return
        this.props.updateCashRegister(cashRegister, this.props.user.cashRegister.key).then(()=> {
            this.props.getToastr("Saved Successfully")
            cashRegister.key = this.props.user.cashRegister.key
            this.props.setCashRegister(cashRegister)
            this.setState({ saving:false, showSetup:false})     
            //update redux  
        })
    }

    onPrinterSave()
    {
        if(this.state.printer==="bluetooth"  && !this.state.printerConfig)
        {
            alert('Please search for bluetooth printers first')
            return
        }
        let cashRegister = {CashRegisterCode: this.props.user.cashRegister.CashRegisterCode,
            CashRegisterName:this.props.user.cashRegister.CashRegisterName,
            StoreLocationID: this.props.user.cashRegister.StoreLocationID,
            StoreLocation: this.props.user.cashRegister.StoreLocation,
            StoreID: this.props.user.cashRegister.StoreID,
            RegistrationID: this.props.user.user.RegistrationID,
            Printer:this.state.printer,
            PrinterConfig: this.state.printerConfig,
            CashRegisterPaymentSetup:this.props.user.cashRegister.hasOwnProperty('CashRegisterPaymentSetup') ? this.props.user.cashRegister.CashRegisterPaymentSetup : [],
            IsDeleted: false}
            // console.log('cashRegister',cashRegister)
        this.props.updateCashRegister(cashRegister, this.props.user.cashRegister.key).then(()=> {
            this.props.getToastr("Saved Successfully")
            cashRegister.key = this.props.user.cashRegister.key
            this.props.setCashRegister(cashRegister)
            this.setState({ saving:false, showSetup:false})     
            //update redux  
        })
    }

    render() {
        return <div className="content-wrapper">
             <section className="content" >
                {!this.state.showSetup && <div className="col-md-6 col-sm-12">
                    <div className="box ">
                    <div className="box-body">
                        <div >
                            <button type="cancel" 
                            className="btn btn-flat btn-success"
                            onClick={this.onNewSetUpSelected}
                                style={{ marginBottom:"10px",width:"100%" }}
                            >New payment provider
                            </button>
                        </div>
                        <div >
                            {(this.props.user.cashRegister && this.props.user.cashRegister.hasOwnProperty('CashRegisterPaymentSetup')) &&<table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Payment provider</th>
                                        <th>Payment mode(s)</th>
                                        <th style={{width:"40px"}}></th>
                                        <th style={{width:"40px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.user.cashRegister.CashRegisterPaymentSetup.map((paymentSetup, index) =>
                                    <tr>
                                        <td>{paymentSetup.PaymentProviderName}</td>
                                        <td>{paymentSetup.PaymentModes.map(m=><div>{m.PaymentType}</div>)}</td>
                                        <td>
                                            <button type="button" 
                                            className="btn btn-md btn-flat btn-default "
                                            onClick={()=>this.onPaymentSetUpSelected(index)}><i className="fa fa-edit"></i></button>
                                        </td>
                                        <td>
                                            <Confirm
                                                onConfirm={() => this.onPaymentSetUpDeleted(index)}
                                                body="Are you sure you would like to delete this record?"
                                                confirmText="Yes"
                                                title="Payment Provider Delete">
                                                <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                            </Confirm>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>}
                        </div>
                    </div>             
                </div>
                </div>}
                {!this.state.showSetup && <div className="col-md-6 col-sm-12"><div className="box">
                    <div className="box-body">
                        <div className="form-group">
                            <label>Printer</label>
                            <select className="form-control"
                                value={this.state.printer}
                                onChange={(evt)=>{this.setState({printer: evt.target.value })}}>
                                <option value="">Select</option>
                                <option value="pinelabs">Pine Labs</option>
                                <option value="wisepos">Mswipe - wisepos</option>
                                <option value="bluetooth">Bluetooth Printer</option>
                            </select>
                        </div>
                        {this.state.printerConfig &&<div className="form-group">
                                <div>Name: {this.state.printerConfig.Name}</div>
                                <div>Address: {this.state.printerConfig.Address}</div>
                        </div>}
                        <div className="form-group">
                            {this.state.printer ==="bluetooth" &&<button type="submit" 
                                className="btn btn-flat btn-md btn-flat btn-primary" 
                                onClick={this.onConnectBlueToothPrinter}>Search bluetooth printer(s)</button>}
                                {this.state.printer ==="bluetooth" &&<button type="submit" 
                                className="btn btn-flat btn-md btn-flat btn-primary" 
                                onClick={this.onBlueToothTestPrint}>Bluetooth Test Print</button>}
                        </div>
                        <br/>
                        {/* <div className="form-group">
                            <button type="submit" 
                                className="btn btn-flat btn-md btn-flat btn-primary" 
                                onClick={this.onPineLabsClicked}>PineLabs test</button>
                        </div> */}
                        <br/>
                        <div>
                            <button type="submit" 
                            className="btn btn-flat btn-md btn-flat btn-success pull-right" 
                            disabled={this.state.saving}
                            onClick={this.onPrinterSave}>Save</button>
                        </div>
                    </div>
                </div>
                </div>}
                {this.state.showSetup && <div className="box ">
                    <div className="box-body">
                    <div className="col-md-6 col-sm-12">
                        <div className={this.state.providerError ? "form-group has-error" : "form-group "}>
                            <label>Payment Provider</label>
                            <select className="form-control"
                                value={this.state.paymentProvider}
                                onChange={this.onPaymentProviderChange}
                            >
                                <option value="">Select</option>
                                {/* {this.props.isMobileApp() &&  */}
                                {/* <option value="pinelabs">Pine Labs</option> */}
                                {/* } */}
                                {/* {this.props.isMobileApp() &&  */}
                                <option value="wisepos">Mswipe - wisepos</option>
                                {/* } */}
                                {!this.props.isMobileApp() &&<option value="wisepos collect call">Mswipe - wisepos collect Call</option>}
                                <option value="paytm">PayTM</option>
                                <option value="phonepe">Phonepe</option>
                                <option value="phonepe qr">Phonepe QR</option>
                                <option value="worldline">Worldline</option>
                            </select>
                            {this.state.providerError !== "" && <span className="help-block">{this.state.providerError}</span>}
                        </div>
                        {this.state.paymentProvider ==="worldline" && <div className={this.state.loginIdError ? "form-group has-error" : "form-group "}>
                            <label>Terminal id</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ worldLineTerminalID: evt.target.value })}
                            value={this.state.worldLineTerminalID} />
                            {this.state.worldLineTerminalIDError !== "" && <span className="help-block">{this.state.worldLineTerminalIDError}</span>}
                        </div>}
                        
                        {this.state.paymentProvider ==="pinelabs" && <div className={this.state.loginIdError ? "form-group has-error" : "form-group "}>
                            <label>User id</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ loginID: evt.target.value })}
                            value={this.state.loginID} />
                            {this.state.loginIdError !== "" && <span className="help-block">{this.state.loginIdError}</span>}
                        </div>}
                        {this.state.paymentProvider ==="paytm" && <React.Fragment>
                            <div className={this.state.payTMMerchantIDError ? "form-group has-error" : "form-group "}>
                                <label>Merchant ID</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ payTMMerchantID: evt.target.value })}
                                value={this.state.payTMMerchantID} />
                                {this.state.payTMMerchantIDError !== "" && <span className="help-block">{this.state.payTMMerchantIDError}</span>}
                            </div>
                            <div className={this.state.payTMMerchantKeyError ? "form-group has-error" : "form-group "}>
                                <label>Merchant Key</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ payTMMerchantKey: evt.target.value })}
                                value={this.state.payTMMerchantKey} />
                                {this.state.payTMMerchantKeyError !== "" && <span className="help-block">{this.state.payTMMerchantKeyError}</span>}
                            </div>
                        </React.Fragment>}
                        {(this.state.paymentProvider ==="phonepe"  || this.state.paymentProvider ==="phonepe qr") && <React.Fragment>
                            <div className={this.state.phonePeMerchantIDError ? "form-group has-error" : "form-group "}>
                                <label>Merchant ID</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ phonePeMerchantID: evt.target.value })}
                                value={this.state.phonePeMerchantID} />
                                {this.state.phonePeMerchantIDError !== "" && <span className="help-block">{this.state.phonePeMerchantIDError}</span>}
                            </div>
                            <div className={this.state.phonePeMerchantIDError ? "form-group has-error" : "form-group "}>
                                <label>Salt Key</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ phonePeSaltKey: evt.target.value })}
                                value={this.state.phonePeSaltKey} />
                                {this.state.phonePeSaltKeyError !== "" && <span className="help-block">{this.state.phonePeSaltKeyError}</span>}
                            </div>
                            <div className={this.state.phonePeMerchantIDError ? "form-group has-error" : "form-group "}>
                                <label>Salt Key Index</label>
                                <input type="number" className="form-control"
                                style={{width:"50px"}}
                                onChange={(evt) => this.setState({ phonePeSaltKeyIndex: evt.target.value })}
                                value={this.state.phonePeSaltKeyIndex} />
                                {this.state.phonePeSaltKeyIndexError !== "" && <span className="help-block">{this.state.phonePeSaltKeyIndexError}</span>}
                            </div>
                        </React.Fragment>}
                        {this.state.paymentProvider ==="wisepos collect call" && <React.Fragment>
                            <div className={this.state.wisePOSTokenAPIClientCodeError ? "form-group has-error" : "form-group "}>
                                <label>Client Code (Token API)</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSTokenAPIClientCode: evt.target.value })}
                                value={this.state.wisePOSTokenAPIClientCode} />
                                {this.state.wisePOSTokenAPIClientCodeError !== "" && <span className="help-block">{this.state.wisePOSTokenAPIClientCodeError}</span>}
                            </div>
                            <div className={this.state.wisePOSVerificationAPIClientCodeError ? "form-group has-error" : "form-group "}>
                                <label>Client Code (Verification API)</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSVerificationAPIClientCode: evt.target.value })}
                                value={this.state.wisePOSVerificationAPIClientCode} />
                                {this.state.wisePOSVerificationAPIClientCodeError !== "" && <span className="help-block">{this.state.wisePOSVerificationAPIClientCodeError}</span>}
                            </div>
                            <div className={this.state.wisePOSStoreIDError ? "form-group has-error" : "form-group "}>
                                <label>Store ID</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSStoreID: evt.target.value })}
                                value={this.state.wisePOSStoreID} />
                                {this.state.wisePOSStoreIDError !== "" && <span className="help-block">{this.state.wisePOSStoreIDError}</span>}
                            </div>
                            <div className="form-group ">
                                <label>Terminal ID</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSTerminalID: evt.target.value })}
                                value={this.state.wisePOSTerminalID} />
                            </div>
                            <div className={this.state.wisePOSSaltKeyError ? "form-group has-error" : "form-group "}>
                                <label>Salt Key</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSSaltKey: evt.target.value })}
                                value={this.state.wisePOSSaltKey} />
                                {this.state.wisePOSSaltKeyError !== "" && <span className="help-block">{this.state.wisePOSSaltKeyError}</span>}
                            </div>
                            {/* <div className={this.state.wisePOSSDKLoginIDError ? "form-group has-error" : "form-group "}>
                                <label>SDK Login id</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSSDKLoginID: evt.target.value })}
                                value={this.state.wisePOSSDKLoginID} />
                                {this.state.wisePOSSDKLoginIDError !== "" && <span className="help-block">{this.state.wisePOSSDKLoginIDError}</span>}
                            </div>
                            <div className={this.state.wisePOSSDKPasswordError ? "form-group has-error" : "form-group "}>
                                <label>SDK Password</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSSDKPassword: evt.target.value })}
                                value={this.state.wisePOSSDKPassword} />
                                {this.state.wisePOSSDKPasswordError !== "" && <span className="help-block">{this.state.wisePOSSDKPasswordError}</span>}
                            </div> */}
                            <div className={this.state.wisePOSVerificationAPIUserIDError ? "form-group has-error" : "form-group "}>
                                <label>Verification API UserId</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSVerificationAPIUserID: evt.target.value })}
                                value={this.state.wisePOSVerificationAPIUserID} />
                                {this.state.wisePOSVerificationAPIUserIDError !== "" && <span className="help-block">{this.state.wisePOSVerificationAPIUserIDError}</span>}
                            </div>
                            <div className={this.state.wisePOSVerificationAPIPasswordError ? "form-group has-error" : "form-group "}>
                                <label>Verfication API password</label>
                                <input type="text" className="form-control"
                                onChange={(evt) => this.setState({ wisePOSVerificationAPIPassword: evt.target.value })}
                                value={this.state.wisePOSVerificationAPIPassword} />
                                {this.state.wisePOSVerificationAPIPasswordError !== "" && <span className="help-block">{this.state.wisePOSVerificationAPIPasswordError}</span>}
                            </div>
                            
                        </React.Fragment>}
                        {this.state.paymentProvider ==="wisepos" && <div className={this.state.loginIdError ? "form-group has-error" : "form-group "}>
                            <label>Login id</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ loginID: evt.target.value })}
                            value={this.state.loginID} />
                            {this.state.loginIdError !== "" && <span className="help-block">{this.state.loginIdError}</span>}
                        </div>}
                        {this.state.paymentProvider ==="wisepos" && <div className={this.state.passwordError ? "form-group has-error" : "form-group "}>
                            <label>Password</label>
                            <input type="text" className="form-control"
                            onChange={(evt) => this.setState({ password: evt.target.value })}
                            value={this.state.password} />
                            {this.state.providerError !== "" && <span className="help-block">{this.state.passwordError}</span>}
                        </div>}
                        {this.state.paymentProvider !=="" &&<div className={this.state.paymentModeError ? "form-group has-error" : "form-group "} >
                            <label>Payment modes</label>
                            {this.state.paymentModes.map((m,index)=><div>
                                    <input type="checkbox"  
                                    checked={m.selected}
                                    onChange={()=>
                                    {let paymentModes= this.state.paymentModes
                                        paymentModes[index].selected = !paymentModes[index].selected 
                                        this.setState({paymentModes: paymentModes})
                                    }
                                    }/>
                                    <label >{m.PaymentType}</label>
                                </div>)}
                            {this.state.paymentModeError !== "" && <span className="help-block">{this.state.paymentModeError}</span>}
                        </div>}
                        {this.state.paymentProvider !=="" && <div className="form-group " >
                            <label>Test mode</label><br/>
                            <Switch
                                checked={this.state.testMode}
                                onChange={() => { this.setState({ testMode: !this.state.testMode }) }}/>
                        </div>}
                        </div>
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-md btn-flat btn-default" onClick={()=> this.setState({ saving:false, showSetup:false})  } >Cancel</button>
                        <button type="submit" 
                        className="btn btn-flat btn-md btn-flat btn-success pull-right" 
                        disabled={this.state.saving}
                        onClick={this.onSave}>Save</button>
                    </div>
                </div>}
            </section>
        </div>
    }
}

export default CashRegisterSetup