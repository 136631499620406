import React, { Component } from "react";
// import HeaderNew from "../HeaderNew";
import "../Common/Common.css";
// import Footer from "../Common/Footer";
import { SketchPicker } from 'react-color';
// import Modal from 'react-responsive-modal';
import Switch from "react-switch";
import Dropzone from 'react-dropzone'
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Input from "../2/Controls/Input";
import Window from '../2/Controls/Window';

class OmniHomeSectionEdit extends Component {
    constructor() {
        super();
        this.state = {
            index:-1,
            section: null,
            title: "",
            subTitle:"",
            type: "",
            css: "",
            content: "",
            subCategoryID: "",
            productGroupID: "",
            categoryID: "",
            count: 8,
            saving: false,
            titleError: "",
            typeError: "",
            categoryError: "",
            countError: "",
            productGroupError: "",
            subCategoryError: "",
            contentError: "",
            sequence: 0,
            styleArray: [{type: "menu",css: "section1"},
                {type: "products",css: "section2"},
                {type: "content",css: "section5"},
            ],
            showProductBy: '',
            showProductByError: '',
            displayDevice:'both',
            displayStyle:'listthumbnail',
            sectionMargin:{
                Top:0,
                Bottom:0,
                Left:0,
                Right:0
            },
            sectionPadding:{
                Top:0,
                Bottom:0,
                Left:0,
                Right:0
            },
            sectionTitleMargin:{
                Top:0,
                Bottom:0,
                Left:0,
                Right:0
            },
            sectionTitlePadding:{
                Top:0,
                Bottom:0,
                Left:0,
                Right:0
            },
            blocks:[],
            rows:"1",
            columns:"2",
            blockImage:null, 
            blockText:'', 
            blockTextFont:'', 
            blockVideoURL:'', 
            selectedBlock:null, 
            selectedBlockIndex:-1, 
            // blockImage: '',
            // blockText: '',
            blockTextAlignment: "left",
            // blockTextFont: '',
            blockType: 'image',
            // blockVideoURL: '',
            hideSectionTitle:false,
            sectionBackgroundColor:'',
            sectionTitleAlignment:'',
            sectionTitleColor:'',
            sectionTitleFontSize:'',
            sectionTitleUnderlineColor:'',
            sectionCount:[],
            sectionCSSName:""

        };
        this.onHeaderBackClick = this.onHeaderBackClick.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.handleOnTypeChange = this.handleOnTypeChange.bind(this);
        this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
        this.onColorSelected= this.onColorSelected.bind(this);
        this.onMarginPaddingSet= this.onMarginPaddingSet.bind(this);
        this.onBlockSet = this.onBlockSet.bind(this);
        this.onImageDrop = this.onImageDrop.bind(this)
    }

    async componentDidMount() {

        // let registration = this.props.registration.registration;
        // let checkoutConfig = registration.CheckoutConfig;
        let checkoutConfig = await this.props.getOmniSettings();
        let homeSections  = checkoutConfig ? checkoutConfig.hasOwnProperty("HomeSections") ? checkoutConfig.HomeSections : [] : [];
        let sectionCountTmp = 0
        let sectionCount = []
        let sequence = 0;
        
        if(homeSections && homeSections.length > 0){
            sectionCountTmp = homeSections.length;
        }
        // console.log('this.props.location.state.index',this.props.location.state.index)
        const key = this.props.match.params.key;
        if(checkoutConfig && key && key > -1 && key <= homeSections.length)
        {
            sequence = Number(key) + 1;
            let section = homeSections[Number(key)];
            this.setState({
                index:Number(key),
                title: section.hasOwnProperty("Title") ? section.Title : "",
                subTitle: section.hasOwnProperty("SubTitle") ? section.SubTitle : "",
                type: section.hasOwnProperty("Type") ? section.Type : "",
                css: section.hasOwnProperty("CSS") ? section.CSS : "",
                content: section.hasOwnProperty("Content")? section.Content: "",
                subCategoryID: section.hasOwnProperty("SubCategoryID")? section.SubCategoryID: "",
                productGroupID: section.hasOwnProperty("ProductGroupID")? section.ProductGroupID: "",
                categoryID: section.hasOwnProperty("CategoryID")? section.CategoryID: "",
                count: section.hasOwnProperty("Count") ? section.Count : 8,
                sequence: section.hasOwnProperty("Sequence")? section.Sequence: 0,
                showProductBy: section.hasOwnProperty("ShowProductBy")? section.ShowProductBy: '',
                displayDevice:section.hasOwnProperty("DisplayDevice")? section.DisplayDevice:'both',
                displayStyle:section.hasOwnProperty("DisplayStyle")? section.DisplayStyle:'listthumbnail',              
                sectionBackgroundColor:section.hasOwnProperty("SectionBackgroundColor")? section.SectionBackgroundColor:'',
                hideSectionTitle:section.hasOwnProperty("HideSectionTitle")? section.HideSectionTitle:false,
                sectionTitleAlignment:section.hasOwnProperty("SectionTitleAlignment")? section.SectionTitleAlignment:'left',
                sectionTitleColor:section.hasOwnProperty("SectionTitleColor")? section.SectionTitleColor:'',
                sectionTitleFontSize:section.hasOwnProperty("SectionTitleFontSize")? section.SectionTitleFontSize:'',
                sectionTitleUnderlineColor:section.hasOwnProperty("SectionTitleUnderlineColor")? section.SectionTitleUnderlineColor:'',
                sectionMargin:section.hasOwnProperty("SectionMargin")? section.SectionMargin:{Top:0, Bottom:0, Left: 0 , Right:0},
                sectionPadding:section.hasOwnProperty("SectionPadding")? section.SectionPadding:{Top:0, Bottom:0, Left: 0 , Right:0},
                sectionTitleMargin:section.hasOwnProperty("SectionTitleMargin")? section.SectionTitleMargin:{Top:0, Bottom:0, Left: 0 , Right:0},
                sectionTitlePadding:section.hasOwnProperty("SectionTitlePadding")? section.SectionTitlePadding:{Top:0, Bottom:0, Left: 0 , Right:0},
                blocks:section.hasOwnProperty("SectionBlocks") ? section.SectionBlocks : [],
                columns: section.hasOwnProperty("SectionColumns") ?  section.SectionColumns: "2",
                rows: section.hasOwnProperty("SectionRows") ?  section.SectionRows: "1",
                sectionCSSName: section.hasOwnProperty("SectionCSSName")?section.SectionCSSName : "",
            });
        }
        else
        {
            sectionCountTmp = 1 + sectionCountTmp;
            sequence = sectionCountTmp;
        }
        for(let i=0;i< sectionCountTmp;i++){
            sectionCount.push({a:i+1})
        }
        this.setState({sectionCount:sectionCount, sequence:sequence});
    }

    onImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 300) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ imageLoading: true })

            let fileName = + new Date().getTime() + acceptedFiles[0].name;
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/omniimages', fileName).then((downloadURL) => {
                //update product with images url
                console.log('file uploaded')
                let bucket = 'posible.in/'
                let folder = this.props.registration.registration.StorageFolder + '/omniimages/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + fileName

                let image = { Name: fileName, Size: acceptedFiles[0].size, Preview: url }
                this.setState({ blockImage: image })
                //console.log('File available at', downloadURL);
                this.setState({ imageLoading: false })
            })
        }
        else { alert("No file selected") }
    }

    onImageRemove() {
        this.setState({ block: null })
    }

    async onSave() {
        let categoryError = "";
        let subCategoryError ="";
        let productGroupError ="";
        let titleError = "";
        let typeError = "";
        let countError = "";
        let contentError = "";
        let showProductByError = "";

        if (this.state.title.trim() === "") {
            titleError = "Please enter title";
        }
        if (this.state.type === "") {
            typeError = "Please select type";
        }
        if (this.state.type === "content") {
            if (this.state.content.trim() === "") {
                contentError = "Please Enter Some Content";
            }
        }

        if (this.state.type === "products") {

            if(this.state.showProductBy === "")
            {
                showProductByError = "Please select show product by"
            }

            if(this.state.showProductBy === "category") {
                if (this.state.categoryID === "") {
                    categoryError =
                        "Please select category.";
                }
            }

            if(this.state.showProductBy === "subCategory") {
                if (this.state.subCategoryID === "") {
                    categoryError =
                        "Please select sub category.";
                }
            }
            
            if(this.state.showProductBy === "productGroup") {
                if (this.state.productGroupID === "") {
                    categoryError =
                        "Please select product group.";
                }
            }
            
            if (this.state.count <= 0) {
                countError = "Count should be greater than zero";
            }
        }

        if ( showProductByError !== "" ||
            categoryError !== "" || 
            subCategoryError !== "" || 
            productGroupError !== "" ||
            titleError !== "" ||
            typeError !== "" ||
            countError !== "" ||
            contentError !== ""
        ) {
            this.setState({
                showProductByError: showProductByError,
                categoryError: categoryError,
                subCategoryError: subCategoryError,
                productGroupError: productGroupError,
                titleError: titleError,
                typeError: typeError,
                countError: countError,
                contentError: contentError,
            });
            return;
        }

        let omniTag="";
        if(this.state.subCategoryID!==""){
            if(this.props.config.ProductSubCategories.filter(f=> f.key === this.state.subCategoryID).length> 0)
            {
                let tmp =this.props.config.ProductSubCategories.filter(f=> f.key === this.state.subCategoryID)[0];
                omniTag = tmp.hasOwnProperty("OMNITag") ? tmp.OMNITag  : "";
            }
        }else if(this.state.categoryID!==""){
            let tmp = this.props.config.ProductCategories.filter(f=> f.key === this.state.categoryID)[0];
            omniTag = tmp.hasOwnProperty("OMNITag") ? tmp.OMNITag  : "";
        } else if(this.state.productGroupID!==""){

        }

        let section = {
            Title: this.state.title,
            SubTitle: this.state.subTitle,
            Type: this.state.type,
            CSS: this.state.css,
            ShowProductBy: this.state.showProductBy,
            SubCategoryID: this.state.subCategoryID,
            ProductGroupID: this.state.productGroupID,
            CategoryID: this.state.categoryID,
            Count: this.state.count,
            Content: this.state.content,
            Sequence: this.state.sequence,
            DisplayDevice: this.state.displayDevice,
            DisplayStyle: this.state.displayStyle,
            SectionBackgroundColor: this.state.sectionBackgroundColor,
            HideSectionTitle: this.state.hideSectionTitle,
            SectionTitleAlignment: this.state.sectionTitleAlignment,
            SectionTitleColor: this.state.sectionTitleColor,
            SectionTitleFontSize: this.state.sectionTitleFontSize,
            SectionTitleUnderlineColor: this.state.sectionTitleUnderlineColor,
            SectionMargin: this.state.sectionMargin,
            SectionPadding: this.state.sectionPadding,
            SectionTitleMargin: this.state.sectionTitleMargin,
            SectionTitlePadding: this.state.sectionTitlePadding,
            SectionBlocks: this.state.blocks,
            SectionColumns: this.state.columns,
            SectionRows:this.state.rows,
            SectionCSSName: this.state.sectionCSSName,
            SectionOmniTag:omniTag
        };

        let checkoutConfig = await this.props.getOmniSettings();
        let homeSections  = checkoutConfig ? checkoutConfig.hasOwnProperty("HomeSections") ? checkoutConfig.HomeSections : [] : [];
        
        if(homeSections.length ===0){
            checkoutConfig.HomeSections=[]
            checkoutConfig.HomeSections.push(section);
        }
        else
        {
            if(this.state.index > -1){
                checkoutConfig.HomeSections.splice(this.state.index, 1)
            }
            checkoutConfig.HomeSections.splice(this.state.sequence -1, 0 , section)

        }
       
        console.log('checkoutConfig',checkoutConfig)
        await this.props.saveOmniSettings(checkoutConfig);
        if(this.state.index > -1){
            this.props.getToastr("Home section update successfully");
        }else
        {
            this.props.getToastr("Home section created successfully");
        }
        this.onCancelClick();
    }

    onHeaderBackClick() {
        this.props.history.push("/home/omni/homesections");
    }

    onCancelClick() {
        this.props.history.push("/home/omni/homesections");
    }

    onSubCategoryChange = (e) => {
        let subCategoryID = e.target.value;
        this.setState({
            subCategoryID: subCategoryID,
        });
    };

    onShowColorPicker(control, color){
        this.setState({showColorPicker:true, control:control, selectedColor:color})
    }

    onShowMarginPadding(control, value){
        this.setState({showMarginPadding: true, control: control, top: value.Top, bottom: value.Bottom, left: value.Left, right:value.Right});
    }

    designControls(){
        return <React.Fragment>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section background color</label>
                        <div className="input-group">
                            <input className="form-control"
                                    value={this.state.sectionBackgroundColor}
                                    onChange={(e)=> this.setState({sectionBackgroundColor:e.target.value})}/>
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowColorPicker("sectionBackgroundColor", this.state.sectionBackgroundColor)}>Pick color</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section margin</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={"Top:" + this.state.sectionMargin.Top + "px, Bottom: " + this.state.sectionMargin.Bottom + "px, Left: "+ this.state.sectionMargin.Left+ "px, Right: " + this.state.sectionMargin.Right + "px" }/>
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowMarginPadding("sectionmargin", this.state.sectionMargin)}>Set margin</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section padding</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={"Top:" + this.state.sectionPadding.Top + "px, Bottom: " + this.state.sectionPadding.Bottom + "px, Left: "+ this.state.sectionPadding.Left+ "px, Right: " + this.state.sectionPadding.Right + "px" }/>                            
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowMarginPadding("sectionpadding", this.state.sectionPadding)}>Set padding</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Hide section title</label>
                        <br/>
                        <Switch
                            checked={this.state.hideSectionTitle}
                            onChange={() => this.setState({ hideSectionTitle: !this.state.hideSectionTitle })}
                        />
                    </div>
                </div>
                {!this.state.hideSectionTitle && <React.Fragment>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section title alignment</label>
                        <select className="form-control" 
                            value={this.state.sectionTitleAlignment}
                            onChange={(e)=> this.setState({sectionTitleAlignment: e.target.value})}>
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section title font size (px)</label>
                        <input className="form-control" type="number"
                                value={this.state.sectionTitleFontSize}
                                onChange={(e)=> this.setState({sectionTitleFontSize:e.target.value})}/>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section title font color</label>
                        <div className="input-group">
                            <input className="form-control"
                                    value={this.state.sectionTitleColor}
                                    onChange={(e)=> this.setState({sectionTitleColor:e.target.value})}/>
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowColorPicker("sectionTitleColor", this.state.sectionTitleColor)}>Pick color</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section title underline color</label>
                        <div className="input-group">
                            <input className="form-control"
                                    value={this.state.sectionTitleUnderlineColor}
                                    onChange={(e)=> this.setState({sectionTitleUnderlineColor:e.target.value})}/>
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowColorPicker("sectionTitleUnderlineColor", this.state.sectionTitleUnderlineColor)}>Pick color</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section title margin</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={"Top:" + this.state.sectionTitleMargin.Top + "px, Bottom: " + this.state.sectionTitleMargin.Bottom + "px, Left: "+ this.state.sectionTitleMargin.Left+ "px, Right: " + this.state.sectionTitleMargin.Right + "px" }/>
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowMarginPadding("sectiontitlemargin", this.state.sectionTitleMargin)}>Set margin</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Section title padding</label>
                        <div className="input-group">
                            <input type="text" className="form-control" value={"Top:" + this.state.sectionTitlePadding.Top + "px, Bottom: " + this.state.sectionTitlePadding.Bottom + "px, Left: "+ this.state.sectionTitlePadding.Left+ "px, Right: " + this.state.sectionTitlePadding.Right + "px" }/>                            
                            <div className="input-group-btn">
                                <button className="btn btn-default btn-md" onClick={()=>this.onShowMarginPadding("sectiontitlepadding", this.state.sectionTitlePadding)}>Set padding</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <Input
                        type="text"
                        label="css name"
                        value={this.state.sectionCSSName}
                        onChange={(value)=>this.setState({sectionCSSName:value})}
                    />
                </div>
                </React.Fragment>}
        </React.Fragment>
    }

    createBolcks(rows, columns){
        let blocksTmp = this.state.blocks;
        let col = 12 / Number(columns);
        // console.log('col',col)
        let blocks = []
        let ctr = Number(rows) * Number(columns);
        // console.log('ctr', ctr);

        for(let i=0; i<ctr;i++){
            if(blocksTmp.length > i){
                blocks.push({
                    ...blocksTmp, Class:"col-md-" + col
                })
            }
            else{
                blocks.push({Class:"col-md-" + col,
                BlockType: 'image',
                BlockImage: null,
                BlockText: '',
                BlockTextFontSize: '',
                BlockTextAlignment: 'left',
                BlockVideoURL: ''})
            }
        }
        this.setState({rows: rows, columns: columns, blocks: blocks})
    }

    formControls() {
        return (
            <React.Fragment>
                {/* <div className="row"> */}
                    <div className="col-md-12">
                        <Input
                            type="text"
                            label="Title"
                            onChange={(value)=>this.setState({title:value,titleError:""})}
                            value={this.state.title}
                            error={this.state.titleError}
                        />
                    </div>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            label="Sub Title"
                            onChange={(value)=>this.setState({subTitle:value})}
                            value={this.state.subTitle}
                        />
                    </div>
                    <div className="col-md-12">
                        <div
                            className={
                                this.state.typeError !== ""
                                    ? "form-group has-error"
                                    : "form-group "
                            }
                        >
                            <label>What would you like to display</label>
                            <select
                                className="form-control"
                                value={this.state.type}
                                onChange={this.handleOnTypeChange}
                            >
                                <option value="">Please select</option>
                                <option value="products">Products</option>
                                <option value="menu">Menu</option>
                                <option value="content">Content</option>
                                <option value="blocks">Blocks</option>
                            </select>
                            {this.state.typeError !== "" && (
                                <span className="help-block">
                                    {this.state.typeError}
                                </span>
                            )}
                        </div>
                    </div>
                {/* </div> */}
                {this.state.type==="blocks" && <React.Fragment>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Columns</label>
                            <select className="form-control" value={this.state.columns} onChange={(e)=> this.createBolcks(this.state.rows , e.target.value)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                {/* <option value="5">5</option> */}
                                <option value="6">6</option>
                                {/* <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option> */}
                                <option value="12">12</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Rows</label>
                            <select className="form-control" value={this.state.rows} onChange={(e)=> this.createBolcks(e.target.value, this.state.columns)}>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                {/* <option value="11">11</option> */}
                                {/* <option value="12">12</option> */}
                            </select>
                        </div>
                    </div>
                    
                </React.Fragment>}
                {this.state.type === "products" && (
                    <React.Fragment>
                        {/* <div className="row"> */}
                            <div className="col-md-12">
                                <div
                                    className={this.state.showProductByError !== ""? "form-group has-error": "form-group "}
                                >
                                    <label>Display products by</label>
                                    <select className="form-control" value={this.state.showProductBy}
                                        onChange={(e) => {
                                            this.setState({
                                                showProductBy: e.target.value,
                                                showProductByError: "",
                                                categoryID:"",
                                                subCategoryID:"",
                                                productGroupID:""
                                            });
                                        }}
                                    >
                                        <option value="">Please select </option>
                                        <option value="category">Category</option>
                                        <option value="subCategory">Sub Category</option>
                                        <option value="productGroup">Product Group</option>
                                        <option value="allProducts">All Products</option>
                                    </select>
                                    {this.state.showProductByError !== "" && (
                                        <span className="help-block">
                                            {this.state.showProductByError}
                                        </span>
                                    )}
                                </div>
                            </div>
                            {(this.state.showProductBy === "category" || this.state.showProductBy === "subCategory") && <div className="col-md-12">
                                <div
                                    className={
                                        this.state.categoryError !== ""
                                            ? "form-group has-error"
                                            : "form-group "
                                    }
                                >
                                    <label>Category to be displayed</label>
                                    <select
                                        className="form-control"
                                        value={this.state.categoryID}
                                        onChange={(e) => {
                                            this.setState({
                                                categoryID: e.target.value,
                                                categoryError: "",
                                            });
                                        }}
                                    >
                                        <option value="">
                                            Select category
                                        </option>
                                        {this.props.config.ProductCategories.filter(
                                            (e) =>
                                                e.ParentID === "" &&
                                                !e.IsDeleted
                                        ).map((category) => (
                                            <option value={category.key}>
                                                {category.Name}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.categoryError !== "" && (
                                        <span className="help-block">
                                            {this.state.categoryError}
                                        </span>
                                    )}
                                </div>
                            </div>}
                            {(this.state.showProductBy === "subCategory") && <div className="col-md-12">
                                <div
                                    className={this.state.subCategoryError !== ""? "form-group has-error": "form-group "}
                                >
                                    <label>Sub category to be displayed</label>
                                    <select
                                        className="form-control"
                                        value={this.state.subCategoryID}
                                        onChange={(e) => {
                                            this.setState({
                                                subCategoryID: e.target.value,
                                                subCategoryError: "",
                                            });
                                        }}
                                    >
                                        <option value="">
                                            Select sub category
                                        </option>
                                        {this.props.config.ProductSubCategories.filter(
                                            (e) =>
                                                e.ParentID ===
                                                    this.state.categoryID &&
                                                !e.IsDeleted
                                        ).map((subCategory, index) => (
                                            <option value={subCategory.key}>
                                                {subCategory.Name}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.subCategoryError !== "" && (
                                        <span className="help-block">
                                            {this.state.subCategoryError}
                                        </span>
                                    )}
                                </div>
                            </div>}
                            {(this.state.showProductBy === "productGroup") && <div className="col-md-12">
                                <div
                                    className={
                                        this.state.productGroupError !== ""
                                            ? "form-group has-error"
                                            : "form-group "
                                    }
                                >
                                    <label>Product group to be displayed</label>
                                    <select
                                        className="form-control"
                                        value={this.state.productGroupID}
                                        onChange={(e) => {
                                            this.setState({
                                                productGroupID: e.target.value,
                                                productGroupError: "",
                                            });
                                        }}
                                    >
                                        <option value="">
                                            Select product group
                                        </option>
                                        {this.props.config.ProductTags.filter(
                                            (e) => !e.IsDeleted
                                        ).map((tag, index) => (
                                            <option value={tag.key}>
                                                {tag.Name}
                                            </option>
                                        ))}
                                    </select>
                                    {this.state.productGroupError !== "" && (
                                        <span className="help-block">
                                            {this.state.productGroupError}
                                        </span>
                                    )}
                                </div>
                            </div>}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Style</label>
                                    <select className="form-control"
                                        value={this.state.displayStyle}
                                        onChange={(e) => {this.setState({displayStyle: e.target.value});}}>
                                        <option value="listthumbnail">List (Mobile) / Thumbnail (Desktop)</option>
                                        <option value="list">List</option>
                                        <option value="thumbnail">Thumbnail</option>
                                        <option value="thumbnailscroll">Thumbnail Scroll</option>                                        
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className={this.state.countError !== ""? "form-group has-error": "form-group "}>
                                    <label>No of {this.state.type.toLowerCase()} to display</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => {this.setState({count: evt.target.value,countError: ''});}}
                                        value={this.state.count}
                                    />
                                    {this.state.countError !== "" && (<span className="help-block">{this.state.countError}</span>)}
                                </div>
                            </div>
                    </React.Fragment>)}

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Devices on which to be displayed</label>
                        <select className="form-control"
                            value={this.state.displayDevice}
                            onChange={(e) => {this.setState({displayDevice: e.target.value});}}>
                            <option value="both">Desktop / Mobile</option>
                            <option value="desktop">Desktop</option>
                            <option value="mobile">Mobile</option>                                        
                        </select>
                    </div>
                </div>
                {this.state.type !== "" && <div className="col-md-12">
                    <div>
                        <label>Sequence</label>
                        <select className="form-control" onChange={(e)=> this.setState({sequence: e.target.value})} value={this.state.sequence}>
                        {this.state.sectionCount.map((m,index) =><option value={m.a}>{m.a}</option>)}
                        </select>
                        {/* <input type="number" className="form-control" value={this.state.sequence} onChange={(evt) => {
                            this.setState({sequence: Number(evt.target.value)});
                        }} /> */}
                    </div>
            </div>}
            </React.Fragment>
        );
    }

    handleOnTypeChange = (e) => {
        let type = e.target.value;

        this.setState({ type: type, typeError: "" });
        if(type==="blocks"){
            this.createBolcks(this.state.rows, this.state.columns)
        }
    };

    onColorChangeComplete = (color, event) => {
        this.setState({ selectedColor: color.hex });
      };

    onColorSelected(){
        this.setState({ showColorPicker: false });
        if(this.state.control==="sectionBackgroundColor")
        {
            this.setState({sectionBackgroundColor: this.state.selectedColor})
        }
        if(this.state.control==="sectionTitleColor")
        {
            this.setState({sectionTitleColor: this.state.selectedColor})
        }
        if(this.state.control==="sectionTitleUnderlineColor")
        {
            this.setState({sectionTitleUnderlineColor: this.state.selectedColor})
        }
        
    }

    onMarginPaddingSet(){
        this.setState({showMarginPadding:false});
        let value= {Top: this.state.top,
        Bottom: this.state.bottom,
        Left: this.state.left,
        Right: this.state.right}
    //     console.log('value', value)
    // console.log('this.state.control', this.state.control)
        if(this.state.control ==="sectiontitlemargin")
        {
            this.setState({sectionTitleMargin: value})
        }
        if(this.state.control ==="sectiontitlepadding")
        {
            this.setState({sectionTitlePadding: value})
        }
        if(this.state.control ==="sectionmargin")
        {
            this.setState({sectionMargin: value})
        }
        if(this.state.control ==="sectionpadding")
        {
            this.setState({sectionPadding: value})
        }
    }

    onBlockSelected(block, index){
        this.setState({selectedBlock: block, 
            selectedBlockIndex: index, 
            blockType: block.hasOwnProperty("BlockType") ? block.BlockType : "image",
            blockImage:block.hasOwnProperty("BlockImage") ? block.BlockImage  : "", 
            blockText:block.hasOwnProperty("BlockText") ? block.BlockText  : "", 
            blockFontSize:block.hasOwnProperty("BlockTextFontSize") ? block.BlockTextFontSize  : "", 
            blockVideoURL:block.hasOwnProperty("BlockVideoURL") ? block.BlockVideoURL  : "", 
            blockImageText: block.hasOwnProperty("BlockImageText") ? block.BlockImageText : "",
            blockClass : block.hasOwnProperty("Class") ? block.Class : "",
            blockLinkType: block.hasOwnProperty("BlockLinkType") ? block.BlockLinkType : "",
            blockCategoryID: block.hasOwnProperty("BlockCategoryID") ? block.BlockCategoryID:"",
            blockSubCategoryID: block.hasOwnProperty("BlockSubCategoryID") ? block.BlockSubCategoryID:"",
            blockProductGroupID: block.hasOwnProperty("BlockProductGroupID") ? block.BlockProductGroupID:"",
            blockPageID: block.hasOwnProperty("BlockPageID") ? block.BlockPageID : "",
            blockOmniTag: block.hasOwnProperty("BlockOmniTag") ? block.BlockOmniTag : "",
            showBlock:true});
    }

    onBlockSet(){
        if(this.state.blockType==="image" && !this.state.blockImage)
        {
            alert('Please select image');
            return;
        }
        if(this.state.blockType ==="text" && this.state.blockText ===""){
            alert('Please specify text');
            return;
        }
        if(this.state.blockType ==="video" && this.state.blockVideoURL ===""){
            alert('Please specify video url');
            return;
        }
        let blockOmniTag = "";
        if(this.state.blockLinkType==="subcategory"){
            if(this.props.config.ProductSubCategories.filter(f=> f.key === this.state.blockSubCategoryID).length> 0)
            {
                let tmp =this.props.config.ProductSubCategories.filter(f=> f.key === this.state.blockSubCategoryID)[0];
                blockOmniTag = tmp.hasOwnProperty("OMNITag") ? tmp.OMNITag  : "";
            }
        }else if(this.state.blockLinkType==="category"){
            let tmp = this.props.config.ProductCategories.filter(f=> f.key === this.state.blockCategoryID)[0];
            blockOmniTag = tmp.hasOwnProperty("OMNITag") ? tmp.OMNITag  : "";
        } else if(this.state.blockLinkType==="productgroup"){

        }
        let block = {
            Class: this.state.blockClass,
            BlockType: this.state.blockType,
            BlockImage: this.state.blockImage,
            BlockText: this.state.blockText,
            BlockTextFontSize: this.state.blockFontSize,
            BlockTextAlignment: this.state.blockTextAlignment,
            BlockVideoURL: this.state.blockVideoURL,
            BlockImageText: this.state.blockImageText,
            BlockLinkType: this.state.blockLinkType,
            BlockCategoryID: this.state.blockCategoryID,
            BlockSubCategoryID: this.state.blockSubCategoryID,
            BlockProductGroupID: this.state.blockProductGroupID,
            BlockPageID: this.state.blockPageID,
            BlockOmniTag: blockOmniTag,
        }
        console.log('block', block);
        let blocks = this.state.blocks;
        blocks[this.state.selectedBlockIndex] = block;
        this.setState({block:blocks, 
            blockClass:"",
            blockType:"",
            blockImage:null, 
            blockText:"", 
            blockImageText:"",
            blockFontSize:"", 
            blockVideoURL:"", 
            selectedBlock:null, 
            selectedBlockIndex:-1, 
            showBlock:false,
            blockLinkType:"",
            blockCategoryID:"",
            blockSubCategoryID:"",
            blockProductGroupID:"",
            blockPageID:"",
            blockOmniTag:"",
            blockImageText:""})
    }

    renderColorPickerControl(){
        return <Window
        header="Color Selection"
        onButtonClick={(action)=>{
            if(action==="OK"){
                this.onColorSelected();
            }else{
                this.setState({ showColorPicker: false })
            }
        }}
        buttons={[{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"},
        ]}
        processing={this.state.saving}
        onClose={()=>this.setState({ showColorPicker: false })}
    >
        <SketchPicker
                color={ this.state.selectedColor }
                onChangeComplete={ this.onColorChangeComplete }/>
    </Window>
        
        // <Modal open={this.state.showColorPicker} onClose={() => this.setState({ showColorPicker: false })} center>
        //     <div className="box-body">
        //         <SketchPicker
        //         color={ this.state.selectedColor }
        //         onChangeComplete={ this.onColorChangeComplete }/>
        //     </div>
        //     <div className="box-footer">
        //         <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showColorPicker: false })} >Cancel</button>
        //         <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.onColorSelected}>OK</button>
        //     </div>
        // </Modal>
    }

    renderMarginPaddingControl(){
        return <Window
        header=""
        onButtonClick={(action)=>{
            if(action==="OK"){
                this.onMarginPaddingSet();
            }else{
                this.setState({ showMarginPadding: false })
            }
        }}
        buttons={[{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"},
        ]}
        processing={this.state.saving}
        onClose={()=>this.setState({ showMarginPadding: false })}
        >
       <div className="row">
            <div className="col-md-12">
                <Input
                    type="number"
                    label="Top (px)"
                    value={this.state.top}
                    onChange={(value)=> this.setState({top:value})}
                />
                {/* <div className= "form-group ">
                    <label>Top (px)</label>
                    <input className="form-control" type="number"
                            value={this.state.top}
                            onChange={(e)=> this.setState({top:e.target.value})}/>
                </div> */}
            </div>
            <div className="col-md-12">
                <Input
                    type="number"
                    label="Bottom (px)"
                    value={this.state.bottom}
                    onChange={(value)=> this.setState({bottom:value})}
                />
                {/* <div className= "form-group ">
                    <label>Bottom (px)</label>
                    <input className="form-control" type="number"
                            value={this.state.bottom}
                            onChange={(e)=> this.setState({bottom:e.target.value})}/>
                </div> */}
            </div>
            <div className="col-md-12">
                <Input
                    type="number"
                    label="Left (px)"
                    value={this.state.left}
                    onChange={(value)=> this.setState({left:value})}
                />
                {/* <div className= "form-group ">
                    <label>Left (px)</label>
                    <input className="form-control" type="number"
                            value={this.state.left}
                            onChange={(e)=> this.setState({left:e.target.value})}/>
                </div> */}
            </div>
            <div className="col-md-12">
                <Input
                    type="number"
                    label="Right (px)"
                    value={this.state.right}
                    onChange={(value)=> this.setState({right:value})}
                />
                {/* <div className= "form-group ">
                    <label>Right (px)</label>
                    <input className="form-control" type="number"
                            value={this.state.right}
                            onChange={(e)=> this.setState({right:e.target.value})}/>
                </div> */}
            </div>
        </div>
    </Window>
    }
    
    // renderMarginPaddingControl(){
    //     return <Modal open={this.state.showMarginPadding} onClose={() => this.setState({ showMarginPadding: false })} center>
    //         <div className="box-body">
    //             <div className="row">
    //                 <div className="col-md-12">
    //                     <div className= "form-group ">
    //                         <label>Top (px)</label>
    //                         <input className="form-control" type="number"
    //                                 value={this.state.top}
    //                                 onChange={(e)=> this.setState({top:e.target.value})}/>
    //                     </div>
    //                 </div>
    //                 <div className="col-md-12">
    //                     <div className= "form-group ">
    //                         <label>Bottom (px)</label>
    //                         <input className="form-control" type="number"
    //                                 value={this.state.bottom}
    //                                 onChange={(e)=> this.setState({bottom:e.target.value})}/>
    //                     </div>
    //                 </div>
    //                 <div className="col-md-12">
    //                     <div className= "form-group ">
    //                         <label>Left (px)</label>
    //                         <input className="form-control" type="number"
    //                                 value={this.state.left}
    //                                 onChange={(e)=> this.setState({left:e.target.value})}/>
    //                     </div>
    //                 </div>
    //                 <div className="col-md-12">
    //                     <div className= "form-group ">
    //                         <label>Right (px)</label>
    //                         <input className="form-control" type="number"
    //                                 value={this.state.right}
    //                                 onChange={(e)=> this.setState({right:e.target.value})}/>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="box-footer">
    //             <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showMarginPadding: false })} >Cancel</button>
    //             <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.onMarginPaddingSet}>OK</button>
    //         </div>
    //     </Modal>}

    renderBlock(){

        return <Window
        header="Block"
        onButtonClick={(action)=>{
            if(action==="OK"){
                this.onBlockSet();
            }else{
                this.setState({ showBlock: false })
            }
        }}
        buttons={[{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"},
        ]}
        processing={this.state.saving}
        onClose={()=>this.setState({ showBlock: false })}
        >
            <div className="row">
                <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Block Type</label>
                        <select className="form-control" onChange={(e)=>this.setState({blockType:e.target.value})} value={this.state.blockType}>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                            <option value="text">Text Content</option>
                        </select>
                    </div>
                </div>
                {this.state.blockType ==="text" && <div className="col-md-12">
                    <div className= "form-group ">
                        <label>Text Content</label>
                        <input className="form-control" type="text"
                                value={this.state.blockText}
                                onChange={(e)=> this.setState({blockText:e.target.value})}/>
                    </div>
                </div>}
                {this.state.blockType ==="text" && <div className="col-md-6">
                    <div className= "form-group ">
                        <label>Font size (px)</label>
                        <input className="form-control" type="number"
                                value={this.state.blockFontSize}
                                onChange={(e)=> this.setState({blockFontSize:e.target.value})}/>
                    </div>
                </div>}
                {this.state.blockType ==="text" && <div className="col-md-6">
                    <div className= "form-group ">
                        <label>Alignment</label>
                        <select className="form-control" value={this.state.blockTextAlignment} onChange={(e)=>{this.setState({blockTextAlignment: e.target.value})}}>
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </select>
                    </div>
                </div>}
                {this.state.blockType ==="video" &&<div className="col-md-12">
                    <div className= "form-group ">
                        <label>Video url</label>
                        <input className="form-control" type="text"
                                value={this.state.blockVideoURL}
                                onChange={(e)=> this.setState({blockVideoURL:e.target.value})}/>
                    </div>
                </div>}
                {this.state.blockType ==="image" && <div className="col-md-12">
                    <Input
                    type="text"
                    label="Text"
                    value={this.state.blockImageText}
                    onChange={(value)=>this.setState({blockImageText:value})}
                    />
                </div>}
                {this.state.blockType ==="image" && <div className="col-md-12">
                    {!this.state.imageLoading && <div className="form-group">
                            <label>Image</label>
                            {!this.state.blockImage &&
                                            <Dropzone onDrop={(files) => this.onImageDrop(files)}>
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                            {this.state.blockImage && <div className="form-group">
                                <ul className="mailbox-attachments clearfix">
                                    <li>
                                        <span className="mailbox-attachment-icon has-img"><img src={this.state.blockImage.Preview}
                                            alt="Attachment" /></span>
                                        <div className="mailbox-attachment-info">
                                            <span className="mailbox-attachment-name">{this.state.blockImage.Name.length > 20 ? this.state.blockImage.Name.substr(0, 20) + "..." : this.state.blockImage.Name}</span>
                                            <span className="mailbox-attachment-size">
                                                {Math.round(this.state.blockImage.Size / 1024)} KB
                                                        <a onClick={this.onImageRemove}
                                                    className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>}
                        </div>}
                        {this.state.imageLoading && <div>Uploading image</div>}
                </div>}
                <div className="col-md-12">
                    <Input
                        label="Link to"
                        type="select"
                        value={this.state.blockLinkType}
                        onChange={(value)=>this.setState({blockLinkType:value})}
                    >
                        <option value="">None</option>
                        <option value="category">Category</option>
                        <option value="subcategory">Sub Category</option>
                        <option value="productgroup">Product Group</option>
                        {/* <option value="allProducts">All Products</option>
                        <option value="page">Pages</option> */}
                    </Input>
                </div>
                {(this.state.blockLinkType === "category" || this.state.blockLinkType === "subCategory") && <div className="col-md-12">
                    <Input
                        label="Category to be displayed"
                        type="select"
                        value={this.state.blockCategoryID}
                        onChange={(value) => {this.setState({blockCategoryID:value,blockCategoryIDError: ""});}}
                        error={this.state.blockCategoryIDError}
                    >
                        <option value="">Select category</option>
                        {this.props.config.ProductCategories.filter((e) => e.ParentID === "" && !e.IsDeleted).map((category) => (
                        <option value={category.key}>{category.Name}</option>))}
                    </Input>
                </div>}
                {(this.state.blockLinkType === "subCategory") && <div className="col-md-12">
                    <Input
                        label="Sub category to be displayed"
                        type="select"
                        value={this.state.blockSubCategoryID}
                        onChange={(value) => {this.setState({blockSubCategoryID:value,blockSubCategoryIDError: ""});}}
                        error={this.state.blockSubCategoryIDError}
                    >
                        <option value="">Select sub category</option>
                        {this.props.config.ProductSubCategories.filter((e) => e.ParentID === this.state.blockCategoryID && !e.IsDeleted).map((subCategory) => (
                        <option value={subCategory.key}>{subCategory.Name}</option>))}
                    </Input>
                </div>}
                {(this.state.showProductBy === "productGroup") && <div className="col-md-12">
                    <Input
                        type="select"
                        label="Product group to be displayed"
                        value={this.state.blockProductGroupID}
                        onChange={(value)=>this.setState({blockProductGroupID: value, blockProductGroupIDError:""})}
                        error={this.state.blockProductGroupIDError}
                    >
                        <option value="">Select product group</option>
                        {this.props.config.ProductTags.filter((e) => !e.IsDeleted).map((tag, index) => (
                            <option value={tag.key}>{tag.Name}</option>
                        ))}
                    </Input>
                </div>}
            </div>
        </Window>
}

render(){
    return <React.Fragment>
    
        {this.state.showColorPicker && this.renderColorPickerControl()}
        
        {this.state.showMarginPadding &&  this.renderMarginPaddingControl()}
        
        {this.state.showBlock &&  this.renderBlock()}

        <Page 
        title={(this.state.index > -1) ? "Edit Home Section" : "New Home Section"}
        showSaveButton={true} 
        showCancelButton={true}
        processing={this.state.saving}
        onClick={(action)=>{
            if(action==="cancel"){
                this.onCancelClick();
            }else if(action==="save"){
                this.onSave();
            }
        }}
        > 
            
            <PageSection title="General Details">
                <div className="row">
                    <div className="col-md-3">
                        {this.formControls()}
                    </div>
                    <div className="col-md-9">
                            {this.state.type === "content" && (<div className="col-md-12">
                                <div className={this.state.contentError !== ""? "form-group has-error": "form-group "}>
                                    <label>HTML content</label>
                                    <textarea style={{ width: "100%", height: "200px"}} className="form-control" 
                                        value={this.state.content} 
                                        onChange={(evt) => {
                                            this.setState({content: evt.target.value,contentError: ''});}}>
                                    </textarea>
                                    {this.state.contentError !== "" && (<span className="help-block">{this.state.contentError}</span>)}
                                </div>
                            </div>)}
                            {this.state.type === "blocks" && <React.Fragment>
                                        <div className="col-md-12">Blocks</div>
                                        <div className="col-md-12"><i>Select individual blocks to set image, video or text</i></div>
                                        <div>
                                            {this.state.blocks.map((b,index)=><div className={b.Class} key={"block" + index}>
                                                <div style={{border:"solid 1px #d2d6de", textAlign:"center", height:"50px"}} onClick={()=>this.onBlockSelected(b, index)}>Block {index + 1}</div>
                                            </div>)}
                                        </div>
                            </React.Fragment>}      
                    </div>
                </div>
            </PageSection>

            <PageSection title="Layout Settings">
                <div className="row">
                    <div className="col-md-3">
                        {this.designControls()}
                    </div>
                </div>
            </PageSection>

        </Page>
    </React.Fragment>
}

    // render() {
    //     return (
    //         <React.Fragment>
    //             {this.renderColorPickerControl()}
    //             {this.renderMarginPaddingControl()}
    //             {this.renderBlock()}
    //             {this.props.isMobileApp() && (
    //                 <HeaderNew
    //                     {...this.props}
    //                     back={true}
    //                     title={
    //                         this.props.location.state.page
    //                             ? "Edit Home Section"
    //                             : "New Home Section"
    //                     }
    //                     onBackClick={() => this.onHeaderBackClick()}
    //                 />
    //             )}
    //             {this.props.isMobileApp() && (
    //                 <div className="header-new-background"></div>
    //             )}
    //             {!this.props.isMobileApp() && (
    //                 <div className="content-wrapper">
    //                     <section className="content-header">
    //                         <h1>Home Section</h1>
    //                         <button
    //                             type="submit"
    //                             className="btn btn-md btn-success btn-flat pull-right"
    //                             style={{
    //                                 marginTop: "-25px",
    //                                 marginLeft: "10px",
    //                             }}
    //                             onClick={this.onSave}
    //                             disabled={this.state.saving}
    //                         >
    //                             Save
    //                         </button>
    //                         <button
    //                             className="btn btn-md btn-flat btn-default pull-right"
    //                             style={{ marginTop: "-25px" }}
    //                             onClick={() => this.onCancelClick()}
    //                         >
    //                             Cancel
    //                         </button>
    //                     </section>
    //                     <section className="content">
    //                         <div className="box box-body">
    //                             <div className="row">
    //                                 <div className="col-md-3">
    //                                     {this.formControls()}
    //                                 </div>
    //                                 <div className="col-md-3">
    //                                     {this.designControls()}
    //                                 </div>
    //                                 <div className="col-md-6">
                                        
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </section>
    //                 </div>
    //             )}

    //         </React.Fragment>
    //     );
    // }

}
export default OmniHomeSectionEdit;
