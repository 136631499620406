import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';

class ItemSoldReport extends Component {
    constructor() {
        super()
        this.state = {
            itemSold: [],
            stores: [],
            storeList: [],
            searchText: '',
            searchCategory: '',
            searchSubCategory: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchProductSKU: '',
            searchProductName: '', 
            searchBrand: '',
            exportCalled: false,
            loading: true,
            productCustomField1DisplayName: 'Custom Field 1',
            productCustomField2DisplayName: 'Custom Field 2',
            productCustomField3DisplayName: 'Custom Field 3',
            productCustomField4DisplayName: 'Custom Field 4',
            productCustomField5DisplayName: 'Custom Field 5',
            productCustomField6DisplayName: 'Custom Field 6',
            searchProductCustomField1: '',
            searchProductCustomField2: '',
            searchProductCustomField3: '',
            searchProductCustomField4: '',
            searchProductCustomField5: '',
            searchProductCustomField6: '',
            storeSpecificBreakup: true,
            showCustomFields: true
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.loadItemSoldReportData = this.loadItemSoldReportData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Product') {
                let productCustomField1DisplayName = customField.CustomField1Name.trim() === '' ? 'ProductCustomField1' : customField.CustomField1Name.trim()
                let productCustomField2DisplayName = customField.CustomField2Name.trim() === '' ? 'ProductCustomField2' : customField.CustomField2Name.trim()
                let productCustomField3DisplayName = customField.CustomField3Name.trim() === '' ? 'ProductCustomField3' : customField.CustomField3Name.trim()
                let productCustomField4DisplayName = customField.CustomField4Name.trim() === '' ? 'ProductCustomField4' : customField.CustomField4Name.trim()
                let productCustomField5DisplayName = customField.CustomField5Name.trim() === '' ? 'ProductCustomField5' : customField.CustomField5Name.trim()
                let productCustomField6DisplayName = customField.CustomField6Name.trim() === '' ? 'ProductCustomField6' : customField.CustomField6Name.trim()

                this.setState({
                    productCustomField1DisplayName: productCustomField1DisplayName,
                    productCustomField2DisplayName: productCustomField2DisplayName,
                    productCustomField3DisplayName: productCustomField3DisplayName,
                    productCustomField4DisplayName: productCustomField4DisplayName,
                    productCustomField5DisplayName: productCustomField5DisplayName,
                    productCustomField6DisplayName: productCustomField6DisplayName
                })
            }
        })

        let searchTransactionToDate = this.state.searchTransactionToDate
        let searchTransactionFromDate = this.state.searchTransactionFromDate
        let storeSpecificBreakup = this.state.storeSpecificBreakup

        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
           
            searchTransactionToDate  = this.props.location.state.searchTransactionToDate
            searchTransactionFromDate  = this.props.location.state.searchTransactionFromDate
            storeList = this.props.location.state.storeList
            storeSpecificBreakup = false

            this.setState({
                searchTransactionToDate: searchTransactionToDate,
                searchTransactionFromDate: searchTransactionFromDate,
                storeList: storeList,
                storeID: storeList.length > 1 ? "" : storeList[0].key,
                storeSpecificBreakup : storeSpecificBreakup
            });
        }

        this.loadItemSoldReportData(storeList, 
            searchTransactionToDate,
            searchTransactionFromDate,
            this.state.searchCategory,
            this.state.searchSubCategory, 
            this.state.searchProductSKU,
            this.state.searchProductName, 
            this.state.searchBrand, 
            this.state.searchProductCustomField1, 
            this.state.searchProductCustomField2, 
            this.state.searchProductCustomField3, 
            this.state.searchProductCustomField4, 
            this.state.searchProductCustomField5, 
            this.state.searchProductCustomField6,
            storeSpecificBreakup
            );
    }

    onBackClick()
    {
        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
            this.props.history.push({ pathname: "/home/reports/salesdashboard" });
        }
        else
        {
            this.props.history.push({ pathname: "/home/reports/allreport" });
        }
    };

    async loadItemSoldReportData(storeList, 
        searchTransactionToDate, 
        searchTransactionFromDate, 
        searchCategory, 
        searchSubCategory,
        searchProductSKU, 
        searchProductName, 
        searchBrand, 
        searchProductCustomField1, 
        searchProductCustomField2, 
        searchProductCustomField3, 
        searchProductCustomField4, 
        searchProductCustomField5, 
        searchProductCustomField6 , 
        storeSpecificBreakup) {
        console.log("storeSpecificBreakup",storeSpecificBreakup)
            this.setState({ loading: true });

        let res = await this.props.getItemSoldReportData(this.props.user.user.RegistrationID, 
            storeList, 
            searchTransactionToDate,
            searchTransactionFromDate, 
            searchCategory, 
            searchSubCategory, 
            searchProductSKU, 
            searchProductName, 
            searchBrand, 
            searchProductCustomField1, 
            searchProductCustomField2, 
            searchProductCustomField3, 
            searchProductCustomField4, 
            searchProductCustomField5,
            searchProductCustomField6, 
            storeSpecificBreakup);

        if(res && res.searchResult && res.searchResult.length > 0)
        {
            let productIDs = [];
            for (let i = 0; i < res.searchResult.length; i++) {
                const productID = res.searchResult[i].productID;
                productIDs.push(productID);
            }
    
            let res1 = await this.props.getProductsByProductIDs(productIDs);
            let productList = res1.searchResult;
           
            let products = [];
    
            res.searchResult.map((data) => {
    
                // console.log(data);
                let product = {};
    
                if (this.state.storeSpecificBreakup) {
                    product["Store Name"] = data.store ? data.store : ''
                }
                product["Brand"] = data.brand === null ? '' : data.brand
                product["Category"] = data.category
                product["Sub Category"] = data.sub_category === null ? '' : data.sub_category
                product["Product Type"] = data.product_type
                product["SKU"] = data.sku
    
                product["Product Name"] = data.product

                if(productList && productList.length > 0)
                {
                    let objProd = productList.filter((p) => p.key === data.productID);
                    if (objProd && objProd.length > 0 ) {

                        if (this.state.showCustomFields) {
                            product[this.state.productCustomField1DisplayName] = objProd[0].hasOwnProperty('customField1') ? objProd[0].customField1 : ""
                            product[this.state.productCustomField2DisplayName] = objProd[0].hasOwnProperty('customField2') ? objProd[0].customField2 : ""
                            product[this.state.productCustomField3DisplayName] = objProd[0].hasOwnProperty('customField3') ? objProd[0].customField3 : ""
                            product[this.state.productCustomField4DisplayName] = objProd[0].hasOwnProperty('customField4') ? objProd[0].customField4 : ""
                            product[this.state.productCustomField5DisplayName] = objProd[0].hasOwnProperty('customField5') ? objProd[0].customField5 : ""
                            product[this.state.productCustomField6DisplayName] = objProd[0].hasOwnProperty('customField6') ? objProd[0].customField6 : ""
                        }
                        product.Image = (objProd[0].images && objProd[0].images.length > 0) ? objProd[0].images[0].ImagePath : ''
                    }
                }
                product["Qty"] = data.total_quantity
                product["Discount"] = data.total_discount
                product["Taxable Amount"] = data.total_taxable
                product["Total Tax"] = data.total_taxes
                product["Total Sales"] = data.total_sales
    
                products.push(product);
            })
    
            var col = [];
            for (var i = 0; i < products.length; i++) {
                for (var key in products[i]) {
                    if (col.indexOf(key) === -1) {
                        col.push(key);
                    }
                }
            }
    
            var result = "<table class='table table-bordered'><thead><tr>";
            for (var i = 0; i < col.length; i++) {
                if (col[i] !== 'Image') 
                {
                    result += "<th>";
                    result += col[i];
                    result += "</th>";
    
                }
            }
            result += "</tr></thead>";
    
            // ADD JSON DATA TO THE TABLE AS ROWS.
            result += "<tbody>";
            for (var i = 0; i < products.length; i++) {
                result += "<tr>";
                for (var j = 0; j < col.length; j++) {
                    if (col[j] !== 'Image') {
                        result += "<td>";
                        if (col[j] === 'Product Name' && products[i]["Image"] !== "") {
                            result += products[i][col[j]] == undefined ? 0 + '<div> <img width="93px" height="43px" class="photo" src=' + products[i]["Image"] + ' /> </div>' :
                                products[i][col[j]] + '<div> <img width="93px" height="43px" class="photo" src=' + products[i]["Image"] + ' /> </div>'
                        }
                        else
                            result += products[i][col[j]] == undefined ? 0 : products[i][col[j]];
                        result += "</td>";
                    }
                }
                result += "</tr>";
            }
            result += "</tbody>";
            result += "</table>";
    
            //console.log(result);
    
            this.setState({ itemSold: result, totalItemsCount: res.totalItemsCount, loading: false })
        }
        else
        {
            this.setState({ loading: false, itemSold: [], totalItemsCount: 0 })
        }

    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            storeList: storeList, storeID: this.props.user.store.key,
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0], searchCategory: '', searchSubCategory: '',
            searchProductSKU: '', searchProductName: '', searchBrand: '', searchProductCustomField1: '', searchProductCustomField2: '', searchProductCustomField3: '',
            searchProductCustomField4: '', searchProductCustomField5: '', searchProductCustomField6: '', storeSpecificBreakup: true
        })
        this.loadItemSoldReportData(storeList, new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "", "", "", "", "", "", "", "", "", "", true);
    }

    async onExportClick() {
        this.setState({ loading: false, exportCalled: true })
        
        let result = await this.props.getItemSoldReportData(
            this.props.user.user.RegistrationID,
            this.state.storeList,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand,
            this.state.searchProductCustomField1,
            this.state.searchProductCustomField2,
            this.state.searchProductCustomField3,
            this.state.searchProductCustomField4,
            this.state.searchProductCustomField5,
            this.state.searchProductCustomField6,
            this.state.storeSpecificBreakup
        );

        if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
            this.props.getToastr("No Record Found")
            return
        }

        let productIDs = [];
        for (let i = 0; i < result.searchResult.length; i++) {
            const productID = result.searchResult[i].productID;
            productIDs.push(productID);
        }

        let productList = [];
        if (this.state.showCustomFields) {
            let res1 = await this.props.getProductsByProductIDs(productIDs);
            productList = res1.searchResult;
        };

        let products = [];

        result.searchResult.map((data) => {

            // let product = {
            //     "Store Name": data.store,
            //     "Brand": data.brand === null ? '' : data.brand,
            //     "Category": data.category,
            //     "Sub Category": data.sub_category === null ? '' : data.sub_category,
            //     "Product Type": data.product_type,
            //     "SKU": "'" + data.sku,
            //     "Product Name": data.product.replace(/,/g, ""),
            // }

            let product = {};
    
            if (this.state.storeSpecificBreakup) {
                product["Store Name"] = data.store ? data.store : ''
            }
            product["Brand"] = data.brand === null ? '' : data.brand
            product["Category"] = data.category
            product["Sub Category"] = data.sub_category === null ? '' : data.sub_category
            product["Product Type"] = data.product_type
            product["SKU"] = data.sku
            product["Product Name"] = data.product

            if (productList && productList.length > 0) {
                let objProd = productList.filter((p) => p.key === data.productID);
                if (objProd && objProd.length > 0) {

                    product[this.state.productCustomField1DisplayName] = objProd[0].hasOwnProperty('customField1') ? objProd[0].customField1 : ""
                    product[this.state.productCustomField2DisplayName] = objProd[0].hasOwnProperty('customField2') ? objProd[0].customField2 : ""
                    product[this.state.productCustomField3DisplayName] = objProd[0].hasOwnProperty('customField3') ? objProd[0].customField3 : ""
                    product[this.state.productCustomField4DisplayName] = objProd[0].hasOwnProperty('customField4') ? objProd[0].customField4 : ""
                    product[this.state.productCustomField5DisplayName] = objProd[0].hasOwnProperty('customField5') ? objProd[0].customField5 : ""
                    product[this.state.productCustomField6DisplayName] = objProd[0].hasOwnProperty('customField6') ? objProd[0].customField6 : ""
                }
            }

            product["Qty"] = data.total_quantity
            product["Discount"] = data.total_discount
            product["Taxable Amount"] = data.total_taxable
            product["Total Tax"] = data.total_taxes
            product["Total Sales"] = data.total_sales
            products.push(product);
        })
        const csvData = constatnts.objToCSV(products);
        this.setState({ loading: false, exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "ProductSold.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    onSearchClick() {
        this.setState({ loading: true })
        this.loadItemSoldReportData(
            this.state.storeList,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand,
            this.state.searchProductCustomField1, this.state.searchProductCustomField2, this.state.searchProductCustomField3, 
            this.state.searchProductCustomField4, this.state.searchProductCustomField5, this.state.searchProductCustomField6,
            this.state.storeSpecificBreakup
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e) {
        let storeList = []
        // console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        // console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Product Sold Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={this.onBackClick}>
                                        <i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Store Name</label>
                                                <select className="form-control"
                                                    value={this.state.storeID}
                                                    onChange={this.onStoreChange}>
                                                    <option value="">All Stores</option>
                                                    {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                        <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <input type="checkbox" checked={this.state.storeSpecificBreakup}
                                                    onChange={() => {
                                                        this.setState({ storeSpecificBreakup: !this.state.storeSpecificBreakup })
                                                        this.loadItemSoldReportData(this.state.storeList, this.state.searchTransactionToDate, this.state.searchTransactionFromDate, this.state.searchCategory, this.state.searchSubCategory, this.state.searchProductSKU,
                                                            this.state.searchProductName, this.state.searchBrand, this.state.searchProductCustomField1, this.state.searchProductCustomField2, this.state.searchProductCustomField3,
                                                            this.state.searchProductCustomField4, this.state.searchProductCustomField5, this.state.searchProductCustomField6,
                                                            !this.state.storeSpecificBreakup)
                                                    }} />
                                                &nbsp;&nbsp;Show Store-Wise break-up
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchBrand}
                                                onChange={(evt) => this.setState({ searchBrand: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{ padding: "0px" }}>
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductSKU}
                                                onChange={(evt) => this.setState({ searchProductSKU: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductName}
                                                onChange={(evt) => this.setState({ searchProductName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Show Product Custom Fields</label><br/>
                                            <input type="checkbox" checked={this.state.showCustomFields}
                                            onChange={() => {
                                                this.setState({ showCustomFields: !this.state.showCustomFields })
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.showCustomFields && <React.Fragment> <div className="row" style={{ marginTop: "10px" }}>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField1DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField1}
                                                onChange={(evt) => this.setState({ searchProductCustomField1: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField2DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField2}
                                                onChange={(evt) => this.setState({ searchProductCustomField2: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField3DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField3}
                                                onChange={(evt) => this.setState({ searchProductCustomField3: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.productCustomField4DisplayName}</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductCustomField4}
                                                onChange={(evt) => this.setState({ searchProductCustomField4: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-3" >
                                            <div className="form-group">
                                                <label>{this.state.productCustomField5DisplayName}</label>
                                                <input type="text" className="form-control input-md"
                                                    value={this.state.searchProductCustomField5}
                                                    onChange={(evt) => this.setState({ searchProductCustomField5: evt.target.value })}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" >
                                            <div className="form-group">
                                                <label>{this.state.productCustomField6DisplayName}</label>
                                                <input type="text" className="form-control input-md"
                                                    value={this.state.searchProductCustomField6}
                                                    onChange={(evt) => this.setState({ searchProductCustomField6: evt.target.value })}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>}
                                <div className="row" >
                                    <div className="col-md-12" >
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download" ></i></button>

                                        {/* <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a> */}
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    {ReactHtmlParser(this.state.itemSold)}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(ItemSoldReport)