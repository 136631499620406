import firebase from '../firebase'

export function getData(api, from, size, option, searchText, searchFilters) {
  return (dispatch,getState) => {
    let searchParameters = {
      "from": from,
      "size": size,
      "track_total_hits": true,
      "query": {
        "bool": {
          "must": [
          
          ],
          "must_not": [
          ],
          "should": [],
          "filter": {
            "bool": {
              "must": []
            }
          }
        }
      },
      "sort": [
        
      ]
    }
    if (option === "active") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
    }
    if(api ==="getCustomers")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})
      searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText.trim().toLowerCase(), "operator": "and" } } })
      searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })  
   
      searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
   
    }
    else if(api === "getVendors")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });

      searchParameters.query.bool.should.push({ "match_phrase": { "VendorFirstName": searchText.trim().toLowerCase() } })
      searchParameters.query.bool.should.push({ "match_phrase": { "VendorLastName": searchText.trim().toLowerCase() } })
      searchParameters.query.bool.should.push({ "regexp": { "VendorFirstName": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "VendorLastName": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "VendorCode": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
      
      searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });

    }
    else if(api==="getProducts")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    else if(api ==="getSales")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"Store.key": getState().user.store.key}})
      searchParameters.query.bool.should.push({ "regexp": { "TransactionNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.minimum_should_match = 1;
 
      searchParameters.sort.push({ "TransactionDate": { "order": "desc" } });
    }
    else if(api ==="getInventory")
    {
      searchParameters.query.bool.must.push({"match": {"Store.key": getState().user.store.key}})
      searchParameters.query.bool.should.push({ "regexp": { "TransactionNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.minimum_should_match = 1;
 
      searchParameters.sort.push({ "TransactionDate": { "order": "desc" } });
    }
    else if(api ==="getInventoryInTransit")
    {
      api = "getInventory"
      searchParameters.query.bool.must.push({"match": {"Store.key": getState().user.store.key}})
      searchParameters.query.bool.must.push({"match_phrase": {"Status": "In Transit"}})
      searchParameters.query.bool.should.push({ "regexp": { "TransactionNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.minimum_should_match = 1;
 
      searchParameters.sort.push({ "TransactionDate": { "order": "desc" } });
    }
    else if(api ==="getAccountManagement")
    {
      searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})
      //searchParameters.query.bool.minimum_should_match = 1;
      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    else if(api ==="getAttendance")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})
    
      searchParameters.sort.push({ "Date": { "order": "desc" } });
    }
    else if(api ==="getExpenses")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})
     
      searchParameters.query.bool.must.push({ "match": { "Status": option.trim().toLowerCase() } })
 
      searchParameters.query.bool.should.push({ "regexp": { "ExpenseNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.minimum_should_match = 1;
 
      searchParameters.sort.push({ "ExpenseDateTime": { "order": "desc" } });
    }
    else if(api==="getMemberships")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      // searchParameters.query.bool.should.push({ "match_phrase": { "Membership.Name": searchText.trim().toLowerCase()  }})
      searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    else if(api ==="getPackages")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})

      if (option.trim() !== "")
        searchParameters.query.bool.must.push({ "match_phrase": { "PackageStatus": option } })

      if (searchText.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "PackageName": searchText.trim() } })
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerName": searchText.trim() } })
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerEmailID": searchText.trim() } })
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerPhoneNo": searchText.trim() } })
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "CustomerCode": searchText.trim() } })
      }
      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    else if(api ==="getStores")
    {
      searchParameters.query.bool.should.push({ "regexp": { "StoreCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.should.push({ "match_phrase": { "StoreName": searchText.trim().toLowerCase() }})
      searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})

      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });

    }
    if(api ==="getEmployees")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})
      searchParameters.query.bool.should.push({ "regexp": { "EmployeeFirstName": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "EmployeeLastName": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "EmployeeCode": "@" + searchText.trim().toLowerCase() + ".*" } })
    
   
      searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    if(api ==="getModifiers")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
      searchParameters.query.bool.must.push({ "regexp": { "ModifierName": "@" + searchText.trim().toLowerCase() + ".*" } })

      // searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    else if(api==="getBOMs")
    {
      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.should.push({ "regexp": { "BOMName": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      searchParameters.query.bool.minimum_should_match = 1;

      searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
    }
    else if(api==="getDeliveryList")
    {
      api = "getSales";

      searchParameters.query.bool.must.push({ "match": { "RegistrationID": getState().user.user.RegistrationID } });
      searchParameters.query.bool.must.push({"match": {"Store.key": getState().user.store.key}})
      searchParameters.query.bool.should.push({ "match_phrase": { "DeliveryCustomer.FirstName": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "match_phrase": { "DeliveryCustomer.LastName": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "DeliveryCustomer.EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "DeliveryCustomer.PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.should.push({ "regexp": { "DeliveryCustomer.Code": "@" + searchText.trim().toLowerCase() + ".*" } })
      searchParameters.query.bool.minimum_should_match = 1;
      searchParameters.query.bool.must_not.push({ "exists": { "field": "CancellationReason" } })
      // console.log("option", option);

      if (option === "pending") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "pending" } })
      }
      if (option === "confirmed") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "confirmed" } })
      }
      if (option === "out for delivery") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Out For Delivery".toLowerCase() } })
      }
      if (option === "ready for pickup") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Ready For Pickup".toLowerCase() } })
      }
      if (option === "delivered") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "delivered" } })
      }
      searchParameters.query.bool.should.push({ "regexp": { "TransactionNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})

      searchParameters.sort.push({ "TransactionDate": { "order": "desc" } });
    }
    //console.log('searchFilters', searchFilters)
    for (let i = 0; i < searchFilters.length; i++) {
      let f = searchFilters[i]
      if (f.value !== "" && f.type ==="text") {
        // console.log('f',f)
        let jsonObj = JSON.parse('{"regexp":{"' + f.field + '":"@' + f.value.trim().toLowerCase() + '.*"}}')
        // console.log('jsonObj',jsonObj)
        searchParameters.query.bool.filter.bool.must.push(jsonObj)
      }
      else if (f.value !== "" && f.type ==="selection") {
        // console.log('f',f)
        let jsonObj = JSON.parse('{"match_phrase":{"' + f.field + '":"' + f.value.trim().toLowerCase() + '"}}')
        // console.log('jsonObj',jsonObj)
        searchParameters.query.bool.filter.bool.must.push(jsonObj)
      }
    }

    let dateParameters = searchFilters.filter((f) => f.type === "date");
    if(dateParameters && dateParameters.length > 0)
    {
       let from = dateParameters.filter((d) => d.range ==="from")[0];
       let to = dateParameters.filter((d) => d.range ==="to")[0];

      if (from && to &&  from.value !=="" &&  to.value !=="") {
        let dateJSON = JSON.parse('{"range":{"' + from.field + '":{"lte":"' + to.value +'","gte":"' + from.value + '","format": "yyyy-MM-dd"}}}')
        searchParameters.query.bool.must.push(dateJSON);
      }
    } 

    // console.log('searchParameters',JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable(api);
    //getCustomers
    return addMessage({ text: searchParameters }).then(function (result) {
      const lists = []
      let sanitizedMessage = result.data.text;
      // console.log('sanitizedMessage', sanitizedMessage)
      sanitizedMessage.hits.hits.map((data, index) => {
        lists.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: lists }
    });
  }
}

// export function getData(api, from, size, option, searchText, searchFilters) {
//     return (dispatch,getState) => {
//       let searchParameters = {
//         "from": from,
//         "size": size,
//         "query": {
//           "bool": {
//             "must": [
//               { "match": { "RegistrationID": getState().user.user.RegistrationID  } }
//             ],
//             "must_not": [
//             ],
//             "should": [],
//             "filter": {
//               "bool": {
//                 "must": []
//               }
//             }
//           }
//         },
//         "sort": [
          
//         ]
//       }
//       if (option === "active") {
//         searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
//       }
//       if (option === "inactive") {
//         searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
//       }
//       if(api ==="getCustomers")
//       {
//         searchParameters.query.bool.must.push({"match": {"StoreID": getState().user.store.key}})
//         searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText.trim().toLowerCase() + ".*" } })
//         searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText.trim().toLowerCase(), "operator": "and" } } })
//         searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
//         searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })  
     
//         searchParameters.query.bool.minimum_should_match = 1;

//         searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
     
//       }
//       else if(api==="getProducts")
//       {
//         searchParameters.query.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//         searchParameters.query.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//         searchParameters.query.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//         searchParameters.query.bool.minimum_should_match = 1;

//         searchParameters.sort.push({ "ActionOn": { "order": "desc" } });
//       }
//       else if(api ==="getSales")
//       {
//         searchParameters.query.bool.must.push({"match": {"Store.key": getState().user.store.key}})
//         searchParameters.query.bool.should.push({ "regexp": { "TransactionNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//         searchParameters.query.bool.minimum_should_match = 1;
   
//         searchParameters.sort.push({ "TransactionDate": { "order": "desc" } });
//       }
//       //console.log('searchFilters', searchFilters)
//       for (let i = 0; i < searchFilters.length; i++) {
//         let f = searchFilters[i]
//         if (f.value !== "" && f.type ==="text") {
//           // console.log('f',f)
//           let jsonObj = JSON.parse('{"regexp":{"' + f.field + '":"@' + f.value.trim().toLowerCase() + '.*"}}')
//           // console.log('jsonObj',jsonObj)
//           searchParameters.query.bool.filter.bool.must.push(jsonObj)
//         }
//       }

//       let dateParameters = searchFilters.filter((f) => f.type === "date");
//       if(dateParameters && dateParameters.length > 0)
//       {
//          let from = dateParameters.filter((d) => d.range ==="from")[0];
//          let to = dateParameters.filter((d) => d.range ==="to")[0];

//         if (from && to) {
//           let dateJSON = JSON.parse('{"range":{"' + from.field + '":{"lte":"' + to.value +'","gte":"' + from.value + '","format": "yyyy-MM-dd"}}}')
//           searchParameters.query.bool.must.push(dateJSON);
//         }
//       } 

//       console.log('searchParameters',JSON.stringify(searchParameters))
//       let addMessage = firebase.functions().httpsCallable(api);
//       //getCustomers
//       return addMessage({ text: searchParameters }).then(function (result) {
//         const customers = []
//         let sanitizedMessage = result.data.text;
//         sanitizedMessage.hits.hits.map((data, index) => {
//           customers.push({ key: data._id, ...data._source })
//         })
//         return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: customers }
//       });
//     }
//   }
  

