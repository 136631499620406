import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Dropzone from 'react-dropzone'
import {storageRef} from 'firebase'
import { withRouter } from 'react-router-dom'

class Post extends Component {

    constructor(props){
        super(props);
        this.state={
          filesPreview:[],
          filesToBeSent:[],
          printcount:1,
          filecount:0,
          PostDescription:'',
          PostRedirectionURL:'' 
        }
        this.onDrop= this.onDrop.bind(this)
        this.onPostPublish= this.onPostPublish.bind(this)
        this.onFileRemove= this.onFileRemove.bind(this)
        this.handleChangePostDescription = this.handleChangePostDescription.bind(this)
        this.handleChangePostRedirectionURL = this.handleChangePostRedirectionURL.bind(this)
        this.onPostSaveAsDraft = this.onPostSaveAsDraft.bind(this)
        this.savePost =  this.savePost.bind(this)
    }

    handleChangePostDescription(event)
    {
        this.setState({PostDescription: event.target.value});
    }
    handleChangePostRedirectionURL(event)
    {
        this.setState({PostRedirectionURL: event.target.value});
    }

    onDrop(acceptedFiles, rejectedFiles) {
        const imgTobeUploadedStyle = {
            width:'200px'
        } 
        var filesToBeSent=this.state.filesToBeSent;
        if(filesToBeSent.length < this.state.printcount){
          filesToBeSent.push(acceptedFiles);
          this.setState({filecount: 1})
          var filesPreview=[];
          for(var i in filesToBeSent){
              console.log(filesToBeSent[i][0])
            filesPreview.push(<div >
                <img style={imgTobeUploadedStyle}  src={filesToBeSent[i][0].preview}/>
              </div>
            )
          }
          this.setState({filesToBeSent,filesPreview});
        }
        else{
          alert("You have reached the limit of printing files at a time")
        }
     }

     onFileRemove()
     {
        this.setState({
            filesPreview:[],
            filesToBeSent:[],
            filecount:0})
     }

     onPostPublish()
     {this.savePost(1)}
        
     onPostSaveAsDraft()
     {this.savePost(0)}

    savePost(status)
    {
        //1- upload image to server
        //2-get next sequence no for the post for given registratoin
        //3- save the post
        //alert('onPostPublish')
        const PostDescription = this.state.PostDescription
        const PostRedirectionURL = this.state.PostRedirectionURL
        if(PostDescription.trim() ==='')
        {alert('Title cannot be blank');return}
        if(PostRedirectionURL.trim() ==='')
        {alert('Redirect To cannot be blank');return}
        if(this.state.filesToBeSent.length===0)
        {alert('Please select image to uploded');return}

        const post = {
            PostDescription: PostDescription,
            PostRedirectionURL: PostRedirectionURL,
            PostSourceType: '',
            PostSourceUrl: '',
            Registration: '',
            RegistrationID: this.props.user.user.RegistrationID,
            Sequence: 0,
            Status: status
    }

    console.log(post)

        this.props.uploadFileToServer(this.state.filesToBeSent, this.props.registration.registration.StorageFolder).then((()=>{
            this.props.getNextSequenceNo().then(()=>{
                post.Sequence = this.props.post.postNextSequenceNo    
                post.PostSourceUrl = this.props.post.postDownloadURL
                console.log(post)
                this.props.savePost(post).then(()=>{
                    this.props.history.push('/home/post')
                })  
            })
        }))
     }
    render()
    {
        const textAlign = {
            textAlign:'Left'
        };
         
        

    return<div className="content-wrapper" >
        <section className="content-header">
            <h1>
            New Post
            </h1>
        </section>  
        <section className="content" style={textAlign}>                    
            <div className="row">
                <div className="col-md-12">
                    <div className="box box-success">
                        <div className="box-header with-border">
                            <h3 className="box-title">Create New Post</h3>
                        </div>
                        <div className="box-body">
                                <div className="form-group">
                                <label>Title</label>
                                <input type="text" name="PostDescription" className="form-control" placeholder="Enter Post Title..." value={this.state.PostDescription} onChange={this.handleChangePostDescription}  />
                                </div>
                                <div className="form-group">
                                <label>Redirect To</label>
                                <input type="text" name="PostRedirectionURL" className="form-control" placeholder="Enter URL to which you would like to redirect" value={this.state.PostRedirectionURL} onChange={this.handleChangePostRedirectionURL}  />
                                </div>
                                <div className="form-group">
                                    <label>Image</label>  
                                    {this.state.filecount === 0 &&
                                    <Dropzone onDrop={(files) => this.onDrop(files)} >
                                        <div>Try dropping some files here, or click to select files to upload.</div>
                                    </Dropzone>
                                    }
                                    {this.state.filecount === 1 && <div>
                                    <div className="form-group">
                                        {this.state.filesPreview} 
                                    </div>
                                    <div className="form-group">
                                        <button  className="btn btn-default pull-left" onClick={this.onFileRemove} >Remove Image</button>
                                    </div>
                                    </div>
                                    }
                                </div>
                            
                        </div>
                        <div className="box-footer">
                            <Link  to="/home/post"  className="btn btn-default">Cancel</Link>
                            <div className="pull-right">
                            <button className="btn btn-default" onClick={this.onPostSaveAsDraft}>Draft</button>
                            <text> </text>
                            <button className="btn btn-primary" onClick={this.onPostPublish} >Publish</button>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section> 
    </div>
}
}

export default withRouter(Post)