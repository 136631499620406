import React, { Component } from 'react';
import CreatableSelect from 'react-select'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'
import Avatar from '../Common/Avatar'
import Spinner from '../Common/Spinner'

class CreateAppointmentBooking extends Component {

    constructor() {
        super()
        this.state = {
            appointment: null,
            appointments: [],
            bookingType: '',
            startDate: new Date().toISOString().split('T')[0],
            saving: false,
            key: '',
            selectedCustomer: null,
            customerError: '',
            comments: '',
            resources: [],
            packages:[],
            employeeServices: [],
            employeeServiceMapping: [],
            packageID: '',
            slotStartDisplay: '',
            slotEndDisplay: '',
            employeeID: '',
            service: null,
            status:'', 
            loading: true,
            selectedIndex: -1
        }
        this.onAddAppointment = this.onAddAppointment.bind(this);
        this.handleOnEmployeeChange = this.handleOnEmployeeChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnPackageChange = this.handleOnPackageChange.bind(this);
    }

    async componentDidMount() {

        let appointments = this.props.location.state.appointments;
        let bookingType = this.props.location.state.bookingType;
        let startDate = this.props.location.state.startDate;
        let selectedCustomer = this.props.location.state.selectedCustomer;
        let appointment = this.props.location.state.appointment;

        this.setState({ appointments: appointments, bookingType: bookingType, startDate: startDate, selectedCustomer: selectedCustomer, appointment: appointment });

        if(selectedCustomer)
        {
            let packages = await this.props.getPackagesByCustomerID(selectedCustomer.key);
            console.log('packages',packages)
            this.setState({packages: packages})
        }

        let resources = await this.loadResources();
        this.setState({ allResources: resources });

        let services = this.props.config.Products.filter((f => !f.IsDeleted && f.ProductTypeID === 3));
        const serviceList = [];
    
        services.map((service) => {
          let obj = {
            value: service.key, label: service.Name
          }
          serviceList.push(obj);
        });
    
        // this.setState({ services: serviceList });

        let employeeServiceMapping = [];
        for (let i = 0; i < resources.length; i++) {
            const resource = resources[i];
            if (resource) {
                let mapping = await this.props.getEmployeeServices(resource.id);
                if (mapping && mapping.Products.length > 0) {
                    for (let j = 0; j < mapping.Products.length; j++) {
                        const product = mapping.Products[j];

                        if (product) {
                            let service = await services.filter((e) => e.key === product.ProductID);

                            let objService = {
                                EmployeeID: resource.id,
                                EmployeeName: resource.name,
                                EmployeeStatus: resource.status,
                                value: product.ProductID,
                                label: product.Name
                            }

                            if (service.length > 0)
                                objService.ServiceDuration = service[0].ServiceDuration ? service[0].ServiceDuration : '0';

                            employeeServiceMapping.push(objService);
                        }
                    }
                }
            }
        }
        this.setState({ employeeServiceMapping: employeeServiceMapping });

        if(appointment)
        {

            let slotStart = this.props.location.state.startDate + ' ' + appointment.SlotStartDisplay
            let slotEnd = this.props.location.state.startDate + ' ' + appointment.SlotEndDisplay
        
            let startDate = new Date(slotStart);
            let endDate = new Date(slotEnd);
        
            var res = Math.abs(startDate - endDate) / 1000;
            // get hours        
            var hours = Math.floor(res / 3600) % 24;
            // get minutes
            var minutes = Math.floor(res / 60) % 60;
        
            var duration = hours > 0 ? ((hours * 60) + minutes) : minutes
        

            this.setState({
                key: appointment.key ? appointment.key : '',
                slotStart: appointment.SlotStart,
                slotEnd: appointment.SlotEnd ,
                slotStartDisplay: appointment.SlotStartDisplay ,
                slotEndDisplay: appointment.SlotEndDisplay ,
                packageID: appointment.PackageID,
                service: appointment.Service,
                employeeID: appointment.EmployeeID,
                employee: appointment.EmployeeName,
                status: appointment.Status,
                duration: duration,
                selectedIndex: this.props.location.state.selectedIndex
            })
        }
        this.setState({ loading: false })
    };

    async loadResources() {
        let resources = await this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key, 0, 10000, 'active', '', '', '', '', '',
            '', '', '', '', '', '', 'yes')
            .then((result) => {
                let resources = []
                for (let index = 0; index < result.searchResult.length; index++) {
                    const resource = result.searchResult[index];
                    resources.push(
                        {
                            id: resource.key,
                            status: '',
                            name: (resource.EmployeeFirstName.trim() + ' ' + resource.EmployeeLastName.trim()).trim()
                        }
                    )
                }
                this.setState({ resources: resources, totalItemsCount: result.totalItemsCount, loading: false });
                return resources;
            })
        return resources;
    }

    handleOnEmployeeChange = async (evt) => {
        let employeeID = evt.target.value;
        let employee = evt.target.options[evt.target.selectedIndex].text;
        if (employeeID != "" && this.state.bookingType === 'Services') {

            let employeeServiceMapping = this.state.employeeServiceMapping;
            let services = employeeServiceMapping.filter((f) => f.EmployeeID === employeeID);

            this.setState({
                service: null,
                employeeID: employeeID,
                employee: employee,
                employeeError: "",
                employeeServices: services
            })
        }
        else {
            this.setState({
                employeeID: employeeID,
                employee: employee,
                employeeError: "",
            })
        }
    };

    handleOnChange(service, e) {
        if (this.state.bookingType === 'Packages' && service) {
            let employeeServiceMapping = this.state.employeeServiceMapping;
            let employees = employeeServiceMapping.filter((f) => f.value === service.value);
            //console.log(employees);
            let resources = [];
            for (let index = 0; index < employees.length; index++) {
                const employee = employees[index];
                if (resources.filter((e) => e.id === employee.EmployeeID).length === 0) {
                    resources.push({
                        id: employee.EmployeeID,
                        name: employee.EmployeeName,
                        status: employee.EmployeeStatus
                    })
                }
            }
            this.setState({ service: service, resources: resources, serviceError: '' });
        }
        else {
            this.setState({ service: service, serviceError: '' });
        }
    };

    handleOnPackageChange = async (evt) => {

        let employeeServices = [];

        let packageID = evt.target.value;
        //let packageName = evt.target.options[evt.target.selectedIndex].text;

        let packages = this.state.packages;

        if (packageID !== "") {

            let objPackage = packages.filter((e) => e.key === packageID)[0];

            if (objPackage) {

                let packageServices = objPackage.PackageServices.filter((p) => p.Status !== 'Completed');

                if (packageServices && packageServices.length > 0) {
                    for (let index = 0; index < packageServices.length; index++) {
                        const obj = packageServices[index];
                        employeeServices.push({
                            value: obj.ServiceID, label: obj.Service
                        });
                    }
                }
            }
        }
        console.log('employeeServices',employeeServices)
        this.setState({
            service: null,
            employeeID: '',
            packageID: packageID,
            employeeServices: employeeServices,
        });
    }

    onAddAppointment() {

        let serviceError = ""
        let slotEndDisplayError = ""
        let slotStartDisplayError = ""
        let employeeError = ""

        this.setState({ adding: true })

        if (this.state.service === null) {
            serviceError = "Please select service"
        }

        if (this.state.employeeID === '')
            employeeError = "Please select employee"

        if (this.state.slotStartDisplay === '')
            slotStartDisplayError = "Please select start time"

        if (this.state.slotEndDisplay === '')
            slotEndDisplayError = "Please select end time"

        //console.log("slotStart", this.state.slotStart)
        //console.log("slotEnd", this.state.slotEnd)

        if (new Date(this.state.slotStart) >= new Date(this.state.slotEnd)) {
            slotStartDisplayError = "Slot Start should be less than Slot End"
        }

        if (serviceError !== "" || employeeError !== "" || slotStartDisplayError !== "" || slotEndDisplayError !== "") {
            this.setState({
                serviceError: serviceError,
                employeeError: employeeError,
                slotEndDisplayError: slotEndDisplayError,
                slotStartDisplayError: slotStartDisplayError,
                adding: false
            })
            return
        }

        let service = this.props.config.Products.filter((f) => f.key === this.state.service.value)[0];
        //console.log("service", service);

        let appointments = this.state.appointments;
        let selectedIndex = this.state.selectedIndex;
        // console.log("selectedIndex", selectedIndex);

        let appointment = {
            key: this.state.key,
            AppointmentDate: new Date(this.state.startDate),
            SlotStart: this.state.slotStart,
            SlotEnd: this.state.slotEnd,
            SlotStartDisplay: this.state.slotStartDisplay,
            SlotEndDisplay: this.state.slotEndDisplay,
            Customer: this.state.selectedCustomer,
            PackageID: this.state.packageID ? this.state.packageID : '',
            Service: this.state.service,
            RetailPrice: service.RetailPrice,
            Comments: this.state.comments,
            EmployeeID: this.state.employeeID,
            EmployeeName: this.state.employee,
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            StoreName: this.props.user.store.StoreName,
            Status: this.state.status
        }

        if (selectedIndex >= 0)
            appointments.splice(selectedIndex, 1);

        appointments.push(appointment);

        let source = 'booking'
        if (this.props.location.state && this.props.location.state.source !== '') {
            source = 'editbooking'
        }
        this.props.history.push({ pathname: '/home/appointment/create', state: { appointments: this.state.appointments, source: source, selectedCustomer: this.state.selectedCustomer, startDate: this.state.startDate } })

    };

    render() {
        return <React.Fragment>
            <HeaderNew {...this.props}
                back={true}
                title={(this.state.bookingType === "Services") ? "Service Booking" : "Package Booking"}
                onBackClick={() =>  this.props.history.push({ pathname: '/home/appointment/create', state: { appointments: this.state.appointments, source: 'booking', selectedCustomer: this.state.selectedCustomer, startDate: this.state.startDate } })}
            />
            <div className="header-new-background"></div>
            {this.state.loading && <Spinner />}
            {!this.state.loading &&<div style={{ marginTop: "50px" }}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="box with no-border" style={{ boxShadow: "0 0px 0px rgba(0,0,0,0.1)" }}>
                            <div className="box-body">
                                {this.state.selectedCustomer && <div className="list-row">
                                    <Avatar text={this.state.selectedCustomer.FirstName} big={false} />
                                    <div >
                                        <div className="text1">{this.state.selectedCustomer.FirstName + " " + this.state.selectedCustomer.LastName} </div>
                                        {this.state.selectedCustomer.PhoneNo !== "" && <div><i className="fa fa-phone" /> {this.state.selectedCustomer.PhoneNo}</div>}
                                        {this.state.selectedCustomer.EmailID !== "" && <div><i className="fa fa-envelope" /> {this.state.selectedCustomer.EmailID}</div>}
                                        {this.state.selectedCustomer.hasOwnProperty('AadharNo') && this.state.selectedCustomer.AadharNo !== "" && <div>AadharNo:  {this.state.selectedCustomer.AadharNo}</div>}
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.bookingType === "Services" &&
                                            <div style={{ margin: '10px' }}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className={this.state.employeeError ? "form-group has-error" : "form-group "}  >
                                                            <label>Service By</label>
                                                            <select className="form-control"
                                                                value={this.state.employeeID}
                                                                onChange={this.handleOnEmployeeChange}>
                                                                <option value="">Select Employee</option>
                                                                {this.state.resources.filter((e) => e.status !== 'Leave').map(m => <option value={m.id}>{m.name}</option>)}
                                                            </select>
                                                            {this.state.employeeError && <span className="help-block">{this.state.employeeError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className={this.state.serviceError ? "form-group has-error" : "form-group "} >
                                                            <label>Service</label>
                                                            <CreatableSelect
                                                                isSearchable
                                                                isMulti={false}
                                                                placeholder="Select Service"
                                                                value={this.state.service}
                                                                options={this.state.employeeServices}
                                                                onChange={this.handleOnChange}
                                                                style={{ padding: "10px 16px", height: "46px", fontSize: "18px" }}
                                                            />
                                                            {this.state.serviceError && <span className="help-block">{this.state.serviceError}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className={this.state.slotStartDisplayError ? "form-group has-error" : "form-group "}>
                                                            <label>Slot Start</label>
                                                            <select className="form-control"
                                                                value={this.state.slotStartDisplay}
                                                                onChange={(evt) => {

                                                                    let slotStartDisplay = evt.target.value;
                                                                    let slotEndDisplay = this.state.slotEndDisplay;
                                                                    let service = this.state.service;

                                                                    if (service && service.hasOwnProperty('ServiceDuration') && Number(service.ServiceDuration) > 0) {
                                                                        let slotStart = this.state.startDate + ' ' + slotStartDisplay

                                                                        let endDate = new Date(slotStart)
                                                                        endDate = endDate.setMinutes(endDate.getMinutes() + Number(service.ServiceDuration));
                                                                        endDate = new Date(endDate);
                                                                        //console.log('endDate', endDate);

                                                                        slotEndDisplay = (endDate.getHours() > 12) ? (endDate.getHours() + ":" + (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes())) : (endDate.getHours() + ":" + (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes()));
                                                                        //console.log('endDateDisplay', slotEndDisplay);

                                                                        this.setState({ slotEndDisplay: slotEndDisplay })
                                                                    }
                                                                    else {
                                                                        slotEndDisplay = evt.target.value;
                                                                        this.setState({ slotEndDisplay: evt.target.value });
                                                                    }

                                                                    if (slotStartDisplay !== "" && slotEndDisplay !== "") {

                                                                        let slotStart = this.state.startDate + ' ' + slotStartDisplay
                                                                        let slotEnd = this.state.startDate + ' ' + slotEndDisplay

                                                                        let startDate = new Date(slotStart);
                                                                        let endDate = new Date(slotEnd);

                                                                        var res = Math.abs(startDate - endDate) / 1000;
                                                                        // get hours
                                                                        var hours = Math.floor(res / 3600) % 24;
                                                                        // get minutes
                                                                        var minutes = Math.floor(res / 60) % 60;

                                                                        var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

                                                                        this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration });
                                                                    }

                                                                    this.setState({
                                                                        slotStartDisplay: evt.target.value,
                                                                        slotStartDisplayError: ""
                                                                    })
                                                                }}>
                                                                <option value="">Select Start</option>
                                                                <option value="7:00">7:00</option>
                                                                <option value="7:30">7:30</option>
                                                                <option value="8:00">8:00</option>
                                                                <option value="8:30">8:30</option>
                                                                <option value="9:00">9:00</option>
                                                                <option value="9:30">9:30</option>
                                                                <option value="10:00">10:00</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="11:00">11:00</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="12:00">12:00</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="13:00">13:00</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="14:00">14:00</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="15:00">15:00</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="16:00">16:00</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="17:00">17:00</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="18:00">18:00</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="19:00">19:00</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="20:00">20:00</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="21:00">21:00</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="22:00">22:00</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="23:00">23:00</option>
                                                            </select>
                                                            {this.state.slotStartDisplayError && <span className="help-block">{this.state.slotStartDisplayError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={this.state.slotEndDisplayError ? "form-group has-error" : "form-group "} >
                                                            <label >Slot End</label>
                                                            <select className="form-control"
                                                                value={this.state.slotEndDisplay}
                                                                onChange={(evt) => {
                                                                    let slotEndDisplay = evt.target.value;
                                                                    let slotStartDisplay = this.state.slotStartDisplay;

                                                                    if (slotStartDisplay !== "" && slotEndDisplay !== "") {

                                                                        let slotStart = this.state.startDate + ' ' + slotStartDisplay
                                                                        let slotEnd = this.state.startDate + ' ' + slotEndDisplay

                                                                        let startDate = new Date(slotStart);
                                                                        let endDate = new Date(slotEnd);

                                                                        var res = Math.abs(startDate - endDate) / 1000;
                                                                        // get hours        
                                                                        var hours = Math.floor(res / 3600) % 24;
                                                                        // get minutes
                                                                        var minutes = Math.floor(res / 60) % 60;

                                                                        var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

                                                                        this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration });
                                                                    }
                                                                    this.setState({
                                                                        slotEndDisplay: evt.target.value,
                                                                        slotEndDisplayError: ""
                                                                    })
                                                                }}>
                                                                <option value="">Select End</option>
                                                                <option value="7:00">7:00</option>
                                                                <option value="7:30">7:30</option>
                                                                <option value="8:00">8:00</option>
                                                                <option value="8:30">8:30</option>
                                                                <option value="9:00">9:00</option>
                                                                <option value="9:30">9:30</option>
                                                                <option value="10:00">10:00</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="11:00">11:00</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="12:00">12:00</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="13:00">13:00</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="14:00">14:00</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="15:00">15:00</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="16:00">16:00</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="17:00">17:00</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="18:00">18:00</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="19:00">19:00</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="20:00">20:00</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="21:00">21:00</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="22:00">22:00</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="23:00">23:00</option>
                                                            </select>
                                                            {this.state.slotEndDisplayError && <span className="help-block">{this.state.slotEndDisplayError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group" >
                                                            <label>Duration (in mins)</label>
                                                            <input type="text" className="form-control"
                                                                autoFocus={true} disabled
                                                                value={this.state.duration} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.bookingType === "Packages" &&
                                            <div style={{ margin: '10px' }}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className={this.state.packageError ? "form-group has-error" : "form-group "}  >
                                                            <label>Package</label>
                                                            <select className="form-control"
                                                                value={this.state.packageID}
                                                                onChange={this.handleOnPackageChange}>
                                                                <option value="">Select Package</option>
                                                                {this.state.packages.map(m => <option value={m.key}>{m.PackageName}</option>)}
                                                            </select>
                                                            {this.state.packageError && <span className="help-block">{this.state.packageError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={this.state.serviceError ? "form-group has-error" : "form-group "} >
                                                            <label>Service</label>
                                                            <CreatableSelect
                                                                isSearchable
                                                                isMulti={false}
                                                                placeholder="Select Service"
                                                                value={this.state.service}
                                                                options={this.state.employeeServices}
                                                                onChange={this.handleOnChange}
                                                                style={{ padding: "10px 16px", height: "46px", fontSize: "18px" }}
                                                            />
                                                            {this.state.serviceError && <span className="help-block">{this.state.serviceError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={this.state.employeeError ? "form-group has-error" : "form-group "}  >
                                                            <label>Service By</label>
                                                            <select className="form-control"
                                                                value={this.state.employeeID}
                                                                onChange={this.handleOnEmployeeChange}>
                                                                <option value="">Select Employee</option>
                                                                {this.state.resources.filter((e) => e.Status !== 'Leave').map(m => <option value={m.id}>{m.name}</option>)}
                                                            </select>
                                                            {this.state.employeeError && <span className="help-block">{this.state.employeeError}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className={this.state.slotStartDisplayError ? "form-group has-error" : "form-group "}>
                                                            <label>Slot Start</label>
                                                            <select className="form-control"
                                                                value={this.state.slotStartDisplay}
                                                                onChange={(evt) => {

                                                                    let slotStartDisplay = evt.target.value;
                                                                    let slotEndDisplay = this.state.slotEndDisplay;

                                                                    let service = this.state.service;

                                                                    if (service && service.hasOwnProperty('ServiceDuration') && Number(service.ServiceDuration) > 0) {
                                                                        let slotStart = this.state.startDate + ' ' + slotStartDisplay

                                                                        let endDate = new Date(slotStart)
                                                                        endDate = endDate.setMinutes(endDate.getMinutes() + Number(service.ServiceDuration));
                                                                        endDate = new Date(endDate);
                                                                        //console.log('endDate', endDate);

                                                                        slotEndDisplay = (endDate.getHours() > 12) ? (endDate.getHours() + ":" + (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes())) : (endDate.getHours() + ":" + (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes()));
                                                                        //console.log('endDateDisplay', slotEndDisplay);

                                                                        this.setState({ slotEndDisplay: slotEndDisplay })
                                                                    }
                                                                    else {
                                                                        slotEndDisplay = evt.target.value;
                                                                        this.setState({ slotEndDisplay: evt.target.value });
                                                                    }

                                                                    if (slotStartDisplay !== "" && slotEndDisplay !== "") {

                                                                        let slotStart = this.state.startDate + ' ' + slotStartDisplay
                                                                        let slotEnd = this.state.startDate + ' ' + slotEndDisplay

                                                                        let startDate = new Date(slotStart);
                                                                        let endDate = new Date(slotEnd);

                                                                        var res = Math.abs(startDate - endDate) / 1000;
                                                                        // get hours
                                                                        var hours = Math.floor(res / 3600) % 24;
                                                                        // get minutes
                                                                        var minutes = Math.floor(res / 60) % 60;

                                                                        var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

                                                                        this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration });
                                                                    }

                                                                    this.setState({
                                                                        slotStartDisplay: evt.target.value,
                                                                        slotStartDisplayError: ""
                                                                    })
                                                                }}>
                                                                <option value="">Select Start</option>
                                                                <option value="10:00">10:00</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="11:00">11:00</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="12:00">12:00</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="13:00">13:00</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="14:00">14:00</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="15:00">15:00</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="16:00">16:00</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="17:00">17:00</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="18:00">18:00</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="19:00">19:00</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="20:00">20:00</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="21:00">21:00</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="22:00">22:00</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="23:00">23:00</option>
                                                            </select>
                                                            {this.state.slotStartDisplayError && <span className="help-block">{this.state.slotStartDisplayError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className={this.state.slotEndDisplayError ? "form-group has-error" : "form-group "} >
                                                            <label>Slot End</label>
                                                            <select className="form-control"
                                                                value={this.state.slotEndDisplay}
                                                                onChange={(evt) => {
                                                                    let slotEndDisplay = evt.target.value;
                                                                    let slotStartDisplay = this.state.slotStartDisplay;

                                                                    if (slotStartDisplay !== "" && slotEndDisplay !== "") {

                                                                        let slotStart = this.state.startDate + ' ' + slotStartDisplay
                                                                        let slotEnd = this.state.startDate + ' ' + slotEndDisplay

                                                                        let startDate = new Date(slotStart);
                                                                        let endDate = new Date(slotEnd);

                                                                        var res = Math.abs(startDate - endDate) / 1000;
                                                                        // get hours        
                                                                        var hours = Math.floor(res / 3600) % 24;
                                                                        // get minutes
                                                                        var minutes = Math.floor(res / 60) % 60;

                                                                        var duration = hours > 0 ? ((hours * 60) + minutes) : minutes

                                                                        this.setState({ slotStart: slotStart, slotEnd: slotEnd, duration: duration });
                                                                    }
                                                                    this.setState({
                                                                        slotEndDisplay: evt.target.value,
                                                                        slotEndDisplayError: ""
                                                                    })
                                                                }}>
                                                                <option value="">Select End</option>
                                                                <option value="10:00">10:00</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="11:00">11:00</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="12:00">12:00</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="13:00">13:00</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="14:00">14:00</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="15:00">15:00</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="16:00">16:00</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="17:00">17:00</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="18:00">18:00</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="19:00">19:00</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="20:00">20:00</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="21:00">21:00</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="22:00">22:00</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="23:00">23:00</option>
                                                            </select>
                                                            {this.state.slotEndDisplayError && <span className="help-block">{this.state.slotEndDisplayError}</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group" >
                                                            <label>Duration (in mins)</label>
                                                            <input type="text" className="form-control"
                                                                autoFocus={true} disabled
                                                                value={this.state.duration} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                {this.props.isMobileApp() && <Footer buttonText="OK"
                    onClick={() => this.onAddAppointment()}
                />}
            </div>}
        </React.Fragment>
    }
}

export default CreateAppointmentBooking