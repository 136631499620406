import React, { Component } from 'react';

class WidgetTopProductTypes extends Component {

    constructor({ stores, fromDate, toDate, widget }) {
        super();
        this.state = {
            stores: stores,
            fromDate: fromDate, 
            toDate: toDate,
            widget: widget,
            productTypes: []
        }
    }

    async componentDidMount() {
        this.loadData(this.state.stores, this.state.fromDate, this.state.toDate, this.state.widget);
    }

    loadData(stores, fromDate, toDate, widget) {
        this.props.getProductTypeWiseSales(this.props.user.user.RegistrationID, stores, fromDate, toDate, widget.WidgetOptionTopX, widget.WidgetOptionAmtOrQty).then((result) => {
            this.setState({ productTypes: result.searchResult})
        });
    }

    componentWillReceiveProps({ stores, fromDate, toDate, widget }) {
        this.loadData(stores, fromDate, toDate, widget);
    }

    render() {
        return   <div className="dashboard-box ">
            <div className="dashboard-box-header">
                <div className="dashboard-box-title left">Product Type</div>
                <div className="right">
                <button type="submit" className="btn pull-right btn-flat btn-default"
                    onClick={() => this.props.history.push({
                        pathname: "/home/reports/producttypewise",
                        state: {
                            action: 'Sales Dashboard',
                            searchTransactionFromDate: this.state.searchTransactionFromDate,
                            searchTransactionToDate: this.state.searchTransactionToDate,
                            searchProductType: '',
                            storeList: this.state.stores
                        }
                    })}>View More</button>
                </div>
            </div>
            <div className="dashboard-box-body">
                <div className="table-responsive scroll2">
                    {this.state.productTypes.length > 0 && <table className="table table-striped no-margin">
                        <thead>
                            <tr>
                                <th>Product Type</th>
                                <th style={{ textAlign: "right" }}>Qty</th>
                                <th style={{ textAlign: "right" }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.productTypes.map((productType, index) =>
                                <tr key={"prodTypes_" + index}>
                                    <td>{productType.name}</td>
                                    <td style={{ textAlign: "right" }}>{productType.total_qty}</td>
                                    <td style={{ textAlign: "right" }}>{productType.total_sales}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>}
                    {this.state.productTypes.length === 0 && "No records found"}
                </div>
            </div>
        </div>
    }
}

export default WidgetTopProductTypes