import React, { Component } from "react";
import Print from "./Print";
import Loader from "../Loader";
import HeaderNew from "../HeaderNew";
import Spinner from "../Common/Spinner";
import Footer from "../Common/Footer";
import { mSwipeCRClosingPrinting } from "../../actions/Util";
import ReactHtmlParser from "react-html-parser";

class CashRegisterClosing extends Component {
  constructor() {
    super();
    this.state = {
      cashRegisterClosing: null,
      closingPaymentModes: [],
      closingRemarks: "",
      closingTotal: 0,
      saving: true,
      print: false,
      printProduct: false,
      printChecked: false,
      printData: null,
      printProductData: null,
      accountList: [],
      cashClosingBalance: 0,
      totalCashSales: 0,
      handoverAmount: 0,
      depositedAccount: "",
      depositedAccountID: "",
      depositedAccountError: "",
      handoverAmountError: "",
      loading: true,
      products: [],
    };
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  async componentDidMount() {
    //get cash register closing details

    if (!this.props.location.state.cashRegisterClosing) {
      return;
    }
    let cashRegisterClosing = this.props.location.state.cashRegisterClosing;

    this.props
      .getAccounts(this.props.user.user.RegistrationID)
      .then((accounts) => {
        let finalAccounts = [];
        if (accounts && accounts.length > 0) {
          for (let index = 0; index < accounts.length; index++) {
            const account = accounts[index];
            if (!account.IsDeleted) {
              if (account.AllStores) {
                finalAccounts.push(account);
              } else {
                for (let j = 0; j < account.Stores.length; j++) {
                  const store = account.Stores[j];
                  if (store.value === this.props.user.store.key) {
                    finalAccounts.push(account);
                  }
                }
              }
            }
          }
        }
        this.setState({ accountList: finalAccounts });
      });

    let cashCreditEntries = await this.props.getCashCreditEntriesFromAccountManagement(
      cashRegisterClosing.CashRegisterID,
      new Date(cashRegisterClosing.OpeningTime.seconds * 1000)
    );
    //console.log(cashCreditEntries);
    let cashDebitEntries = await this.props.getCashDebitEntriesFromAccountManagement(
      cashRegisterClosing.CashRegisterID,
      new Date(cashRegisterClosing.OpeningTime.seconds * 1000)
    );
    // console.log(cashDebitEntries);

    let paymentsResult = await this.props.getPaymenDetailstForCashRegisterClosing(
      this.props.user.user.RegistrationID,
      cashRegisterClosing.CashRegisterID,
      new Date(cashRegisterClosing.OpeningTime.seconds * 1000)
    );

    let cashExpense = await this.props.getCashFromCounterAmountForCashRegisterClosing(
      cashRegisterClosing.CashRegisterID,
      new Date(cashRegisterClosing.OpeningTime.seconds * 1000)
    );
    let closingPaymentModes = [];
    let cashSales = 0;
    let cashOpeningBalance = cashRegisterClosing.OpeningBalance;
    let cashComponents = [];
    let cashArr = paymentsResult.filter(
      (e) => e.PaymentMode.toLowerCase() === "cash"
    );
    if (cashArr.length > 0) {
      cashSales = cashArr[0].Amount;
    }
    cashComponents.push({
      Name: "Cash Register Credit Entries",
      Amount: cashCreditEntries,
    });
    cashComponents.push({
      Name: "Cash Register Debit Entries",
      Amount: cashDebitEntries,
    });
    cashComponents.push({
      Name: "Opening Balance",
      Amount: cashOpeningBalance,
    });
    cashComponents.push({ Name: "Sales", Amount: cashSales });
    cashComponents.push({ Name: "Expense", Amount: cashExpense });

    console.log("cashCreditEntries", cashCreditEntries);
    let cash = {
      PaymentMode: "Cash",
      Amount:
        Number(cashOpeningBalance) +
        Number(cashCreditEntries) +
        Number(cashSales) -
        Number(cashExpense) -
        Number(cashDebitEntries),
      Components: cashComponents,
    };

    closingPaymentModes.push(cash);
    paymentsResult
      .filter(
        (e) =>
          e.PaymentMode.toLowerCase() !== "cash" &&
          e.PaymentMode.toLowerCase() !== "wallet"
      )
      .map((m) => {
        closingPaymentModes.push({
          PaymentMode: m.PaymentMode,
          Amount: m.Amount,
          Components: [{ Name: "Sales", Amount: m.Amount }],
        });
      });
    let closingTotal = 0;
    closingPaymentModes.map((m) => {
      closingTotal = Number(m.Amount) + Number(closingTotal);
    });
    //    console.log('closingPaymentModes', closingPaymentModes)
    //    console.log('cashClosingBalance', cash.Amount)
    //    console.log('closingPaymentModes', closingPaymentModes)

    if (
      this.props.user.store.hasOwnProperty(
        "CashRegisterClosingIncludeProductSaleDetails"
      ) &&
      this.props.user.store.CashRegisterClosingIncludeProductSaleDetails
    ) {
      const fromDate = new Date(
        cashRegisterClosing.OpeningTime.seconds * 1000
      ).getTime();
      const toDate = new Date().getTime();
      // console.log(dateInMillis);

      await this.loadProductSoldData(
        this.props.user.user.RegistrationID,
        cashRegisterClosing.CashRegisterID,
        fromDate,
        toDate
      );
    }
    console.log("cashRegisterClosing", cashRegisterClosing);
    console.log("closingPaymentModes", closingPaymentModes);
    this.setState({
      saving: false,
      loading: false,
      cashRegisterClosing: cashRegisterClosing,
      closingPaymentModes: closingPaymentModes,
      closingTotal: closingTotal,
      cashClosingBalance: cash.Amount,
      totalCashSales: cash.Amount,
    });
  }

  // onUpdate()
  // {

  // }
  onSave() {
    this.setState({ saving: true });
    let depositedAccountError = "";
    let handoverAmountError = "";

    if (
      this.state.handoverAmount > 0 &&
      this.state.handoverAmount > this.state.totalCashSales
    ) {
      handoverAmountError =
        "Amount to be deposited cannot be more than Total Cash Sales";
    }

    if (this.state.handoverAmount > 0 && this.state.depositedAccount === "") {
      depositedAccountError = "Please select account";
    }

    if (depositedAccountError !== "" || handoverAmountError !== "") {
      this.setState({
        depositedAccountError: depositedAccountError,
        handoverAmountError: handoverAmountError,
        saving: false,
      });
      return;
    }
    if (this.state.handoverAmount > 0) {
      let paymentMode = this.props.config.PaymentTypes.filter(
        (e) =>
          e.PaymentType.toLowerCase() === "cash from counter" && !e.IsDeleted
      )[0];
      if (!paymentMode) {
        alert(
          "'Cash From Counter' payment mode is not found. Please create this payment mode and then try or keep 'Amount to be deposited' as 0."
        );
        return;
      }
    }
    let cashRegisterClosing = {
      ClosingRemarks: this.state.closingRemarks,
      ClosingTotal: this.state.closingTotal,
      ClosingPaymentModes: this.state.closingPaymentModes,
      ClosingTime: new Date(),
      ClosingByUserID: this.props.user.user.UID,
      ClosingByUserName: this.props.user.user.Name,
      Status: "Closed",
      AmountDeposited: this.state.handoverAmount,
      DepositedToAccount: this.state.depositedAccount,
      DepositedToAccountID: this.state.depositedAccountID,
    };
    // console.log("cashRegisterClosing", JSON.stringify(cashRegisterClosing));

    this.props
      .closeCashRegister(
        this.state.cashRegisterClosing.key,
        cashRegisterClosing
      )
      .then(async () => {
        if (this.state.handoverAmount > 0) {
          let paymentMode = this.props.config.PaymentTypes.filter(
            (e) =>
              e.PaymentType.toLowerCase() === "cash from counter" &&
              !e.IsDeleted
          )[0];
          if (!paymentMode) {
            alert(
              "'Cash From Counter' payment mode is not found. Please create this payment mode and then try or keep 'Amount to be deposited' as 0."
            );
            return;
          }
          let transaction = {
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            FromAccount:
              this.state.cashRegisterClosing.CashRegisterCode +
              "-" +
              this.state.cashRegisterClosing.CashRegisterName,
            ToAccount: this.state.depositedAccount,
            FromAccountID: this.state.cashRegisterClosing.key,
            FromAccountType: "CashRegister",
            ToAccountID: this.state.depositedAccountID,
            ToAccountType: "Account",
            TransactionDate: new Date(),
            Amount: Number(this.state.handoverAmount),
            PaymentTypeID: paymentMode.key,
            PaymentType: paymentMode.PaymentType,
            PaymentRemark: this.state.closingRemarks,
            IsDeleted: false,
          };
          // console.log("Accounts Entry", JSON.stringify(transaction));
          this.props.saveTransaction(transaction, "");
        }

        // console.log("cashClosingBalance", this.state.cashClosingBalance);

        this.props.updateLastClosingBalance(
          this.state.cashRegisterClosing.CashRegisterID,
          this.state.cashClosingBalance
        );

        let CashRegisterClosingPrint = await this.props.getCashRegisterClosingDetails(
          this.state.cashRegisterClosing.key
        );
        CashRegisterClosingPrint.ClosingTime = new Date(
          CashRegisterClosingPrint.ClosingTime.seconds * 1000
        )
          .toString()
          .substring(0, 24);
        CashRegisterClosingPrint.OpeningTime = new Date(
          CashRegisterClosingPrint.OpeningTime.seconds * 1000
        )
          .toString()
          .substring(0, 24);
        this.props.getToastr("Cash register closed successfully");
        this.props.setCashRegister(null);
        this.props.setCashRegisterClosing(null);

        if (this.state.printChecked) {
          let printConfig = {};
          printConfig.PrintSize = "3 inch";
          printConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty(
            "CashRegisterClosingTemplate"
          )
            ? this.props.registration.registration.CashRegisterClosingTemplate
            : "";
          let printData = {
            CashRegisterClosing: CashRegisterClosingPrint,
            PrintConfig: printConfig,
          };

          // console.log("printData", printData);

          if (
            this.props.user.store.hasOwnProperty(
              "CashRegisterClosingIncludeProductSaleDetails"
            ) &&
            this.props.user.store.CashRegisterClosingIncludeProductSaleDetails
          ) {
            let printProductConfig = {};
            printProductConfig.PrintSize = "3 inch";
            printProductConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty(
              "ProductSoldTemplate"
            )
              ? this.props.registration.registration.ProductSoldTemplate
              : "";

            let printProductData = {
              Products: this.state.products,
              PrintConfig: printProductConfig,
            };

            console.log(printProductData);

            this.setState({
              print: true,
              printProductData: printProductData,
              printData: printData,
              printProduct: true,
            });
          } else {
            this.setState({ print: true, printData: printData });
          }
        } else if (this.props.isMobileApp()) {
          //get cash register list to find out if mswipe or
          // alert('cordova app')
          // console.log('CashRegisterClosingPrint',CashRegisterClosingPrint)
          mSwipeCRClosingPrinting(CashRegisterClosingPrint);
          this.props.history.push("/home/sales/cashregisterclosinglist/");
        } else {
          this.props.history.push("/home/");
        }
      });
  }

  onCancel() {
    this.props.history.push("/home/");
  }

  renderOpeningDetails() {
    return (
      <React.Fragment>
        <br />
        <div className="form-group">
          <label>Cash Resgiter</label>
          <input
            type="text"
            className="form-control"
            value={
              this.state.cashRegisterClosing.CashRegisterCode +
              " - " +
              this.state.cashRegisterClosing.CashRegisterName
            }
          />
        </div>
        <div className="form-group">
          <label>Opening Time</label>
          <input
            type="text"
            className="form-control"
            value={new Date(
              this.state.cashRegisterClosing.OpeningTime.seconds * 1000
            )
              .toString()
              .substring(0, 24)}
          />
        </div>
        <div className="form-group">
          <label>Opening By</label>
          <input
            type="text"
            className="form-control"
            value={this.state.cashRegisterClosing.ActionBy}
          />
        </div>
      </React.Fragment>
    );
  }

  renderPaymentDetails() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {this.state.closingPaymentModes.map((paymentMode) => (
              <React.Fragment>
                <div style={{ fontSize: "20px" }}>
                  {paymentMode.PaymentMode}
                </div>
                {paymentMode.Components.map((c) => (
                  <div>
                    {c.Name} <span className="pull-right">{c.Amount}</span>
                  </div>
                ))}
                <div style={{ fontWeight: "bold" }}>
                  Total{" "}
                  <span
                    className="pull-right"
                    style={{ borderTop: "solid 1px" }}
                  >
                    {paymentMode.Amount}
                  </span>
                </div>
                <br></br>
              </React.Fragment>
            ))}
            <div
              className="pull-right"
              style={{ fontSize: "20px", borderTop: "solid 1px" }}
            >
              {this.state.closingTotal}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderClosingDetails() {
    return (
      <React.Fragment>
        <div
          className={
            this.state.handoverAmountError !== ""
              ? "form-group has-error"
              : "form-group "
          }
        >
          <label>Amount to be deposited</label>
          <input
            type="number"
            className="form-control"
            onChange={(evt) => {
              let cashClosingBalance = this.state.totalCashSales;
              cashClosingBalance =
                cashClosingBalance - Number(evt.target.value);
              this.setState({
                handoverAmount: evt.target.value,
                cashClosingBalance: cashClosingBalance,
                handoverAmountError: "",
              });
            }}
            value={this.state.handoverAmount}
          />
          {this.state.handoverAmountError !== "" && (
            <span className="help-block">{this.state.handoverAmountError}</span>
          )}
        </div>
        <div
          className={
            this.state.depositedAccountError !== ""
              ? "form-group has-error"
              : "form-group "
          }
        >
          <label>Deposited to Account</label>
          <select
            type="text"
            className="form-control"
            onChange={(evt) =>
              this.setState({
                depositedAccountID: evt.target.value,
                depositedAccount:
                  evt.target.options[evt.target.selectedIndex].text,
                depositedAccountError: "",
              })
            }
            value={this.state.depositedAccountID}
            onKeyPress={this.handleKeyPress}
          >
            <option value="">Select Account</option>
            <optgroup label="Account">
              {this.state.accountList.map((account) => (
                <option value={account.key}>{account.Name}</option>
              ))}
            </optgroup>
          </select>
          {this.state.depositedAccountError !== "" && (
            <span className="help-block">
              {this.state.depositedAccountError}
            </span>
          )}
        </div>
        <div className="form-group">
          <label>Cash Closing Balance</label>
          <label className="form-control">
            {this.state.cashClosingBalance}
          </label>
        </div>
        <div className="form-group">
          <label>Closing Remarks</label>
          <input
            type="text"
            className="form-control"
            onChange={(evt) =>
              this.setState({ closingRemarks: evt.target.value })
            }
            value={this.state.closingRemarks}
          />
        </div>
        <br />
        <br />
        <br />
      </React.Fragment>
    );
  }

  async loadProductSoldData(registrationID, cashRegisterID, fromDate, toDate) {
    let res = await this.props.getProductSoldBasedOnCashRegister(
      registrationID,
      cashRegisterID,
      fromDate,
      toDate
    );

    if (res && res.searchResult && res.searchResult.length > 0) {
      let products = [];
      res.searchResult.map((data) => {
        let product = {};
        product["Category"] = data.category;
        product["Sub Category"] =
          data.sub_category === null ? "" : data.sub_category;
        product["SKU"] = data.sku;
        product["Product Name"] = data.product;
        product["Qty"] = data.total_quantity;
        product["Total Sales"] = data.total_sales;
        products.push(product);
      });

      var col = [];
      for (var i = 0; i < products.length; i++) {
        for (var key in products[i]) {
          if (col.indexOf(key) === -1) {
            col.push(key);
          }
        }
      }

      var result = "<table class='table table-bordered'><thead><tr>";
      for (var i = 0; i < col.length; i++) {
        if (col[i] !== "Image") {
          result += "<th>";
          result += col[i];
          result += "</th>";
        }
      }
      result += "</tr></thead>";

      // ADD JSON DATA TO THE TABLE AS ROWS.
      result += "<tbody>";
      for (var i = 0; i < products.length; i++) {
        result += "<tr>";
        for (var j = 0; j < col.length; j++) {
          if (col[j] !== "Image") {
            result += "<td>";
            result +=
              products[i][col[j]] == undefined ? 0 : products[i][col[j]];
            result += "</td>";
          }
        }
        result += "</tr>";
      }
      result += "</tbody>";
      result += "</table>";

      // console.log(result);

      this.setState({ itemSold: result, products: products });
    } else {
      this.setState({ itemSold: [] });
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.isMobileApp() && !this.state.print && (
          <div className="content-wrapper">
            <section className="content-header">
              <h1>Cash Register Closing</h1>
              <div>
                <button
                  type="submit"
                  className="btn btn-md btn-success btn-flat pull-right"
                  style={{ marginTop: "-25px", marginLeft: "10px" }}
                  onClick={this.onSave}
                  disabled={this.state.saving}
                >
                  Save
                </button>
                <button
                  className="btn btn-md btn-flat btn-default pull-right"
                  style={{ marginTop: "-25px" }}
                  onClick={this.onCancel}
                >
                  Cancel
                </button>
                <span
                  className="pull-right"
                  style={{ marginTop: "5px", marginRight: "10px" }}
                >
                  <input
                    type="checkbox"
                    checked={this.state.printChecked}
                    onChange={() =>
                      this.setState({ printChecked: !this.state.printChecked })
                    }
                  />
                  <label>Print</label>
                </span>
              </div>
            </section>
            {!this.state.cashRegisterClosing && <Spinner />}
            {this.state.cashRegisterClosing && (
              <section className="content">
                {this.state.loading && <Loader show={this.state.loading} />}
                {!this.state.loading && (
                  <React.Fragment>
                    <div
                      style={{
                        width: "auto",
                        overflow: "auto",
                        height: "70vh",
                      }}
                      className="scroll2 row"
                    >
                      <div className="col-md-4">
                        <div className="box ">
                          <div className="box-body">
                            {this.renderPaymentDetails()}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="box ">
                          <div className="box-body">
                            {this.renderOpeningDetails()}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="box ">
                          <div className="box-body">
                            {this.renderClosingDetails()}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.props.user.store.hasOwnProperty(
                      "CashRegisterClosingIncludeProductSaleDetails"
                    ) &&
                      this.props.user.store
                        .CashRegisterClosingIncludeProductSaleDetails &&
                      this.state.products.length > 0 && (
                        <div className="row" style={{ marginTop: "10px" }}>
                          <div className="col-md-12">
                            <h3>Item Sold Summary</h3>
                            <div
                              style={{
                                width: "auto",
                                overflowX: "auto",
                                background: "white",
                              }}
                              className="scroll2"
                            >
                              {ReactHtmlParser(this.state.itemSold)}
                            </div>
                          </div>
                        </div>
                      )}
                  </React.Fragment>
                )}
              </section>
            )}
          </div>
        )}

        {this.state.print && (
          <div>
            <Print
              {...this.props}
              printChecked={this.state.printChecked}
              sendEmailChecked={false}
              sendSMSChecked={false}
              printData={this.state.printData}
              onClose={() => {
                if (this.state.printProduct) {
                  this.setState({
                    printData: this.state.printProductData,
                    printProduct: false,
                    print: true,
                  });
                } else {
                  this.setState({ print: false });
                  this.props.history.push("/home/");
                }
              }}
            />
          </div>
        )}

        {this.props.isMobileApp() && (
          <div>
            <HeaderNew
              {...this.props}
              back={true}
              title="Close Cash Register"
              onBackClick={() => {
                this.props.history.push({
                  pathname: "/home/sales/cashregisterclosinglist/",
                });
              }}
            />

            <div className="header-new-background"></div>

            <div className="content-wrapper">
              {!this.state.cashRegisterClosing && <Spinner />}

              {this.state.cashRegisterClosing && (
                <div className="container">
                  {!this.state.loading && this.renderOpeningDetails()}

                  {!this.state.loading && this.renderPaymentDetails()}

                  {!this.state.loading && this.renderClosingDetails()}
                </div>
              )}
            </div>

            {this.state.cashRegisterClosing && (
              <Footer
                disabled={this.state.saving}
                buttons={[{ action: "save", text: "Save", type: "primary" }]}
                onClick={(action) => this.onSave()}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CashRegisterClosing;
