import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'
import * as actionTypes from '../actionTypes'

export function getCarousels(registrationID)
{
    return (dispatch, getState) => {
        let q = database.collection("Carousels").where("RegistrationID", "==", registrationID).orderBy("ActionOn","desc");
        
        return q.get().then((querySnapshot) => {
            const carousels = []
            querySnapshot.forEach((doc) => {
                carousels.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
           dispatch({type: actionTypes.CONFIG_GET_CAROUSELS,carousels})
            return carousels
        }).catch((error) => {console.log(error)})
    }
}

export function saveCarousel(carousel, key){
    return (dispatch, getState) => {
        carousel.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        carousel.ActionBy = getState().user.user.Name
        carousel.ActionByUID = getState().user.user.UID
        carousel.ActionByEmailID = getState().user.user.EmailID
        carousel.IsDeleted = false
        if(key !== "")
        {
            carousel.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Carousels").doc(key).update({
                ...carousel
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating Carousels: ", error);
              })
        }
        else
        {
            carousel.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Carousels").add({
                ...carousel
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding Carousels: ", error);
              })
        }
    }
}

export function deleteCarousel(key)
{
    return (dispatch, getState) => {
        return database.collection("Carousels").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted : true
        }).then(()=> {return true})
    }
}