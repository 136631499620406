import firebase from "../../firebase";

export function checkIfProductAlreadyExist(registrationid, barCode, SKU = "") {
  let searchParameters = {
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationid,
            },
          },
          {
            match: {
              IsDeleted: false,
            },
          },
        ],
      },
    },
  };
  if (SKU.trim() !== "") {
    searchParameters.query.bool.must.push({
      match: {
        SKU: SKU,
      },
    });
  } else {
    searchParameters.query.bool.must.push({
      match: {
        BarCode: barCode,
      },
    });
  }
  let addMessage = firebase.functions().httpsCallable("getProducts");
  return addMessage({ text: searchParameters }).then(function(result) {
    let sanitizedMessage = result.data.text;
    if (sanitizedMessage.hits.total.value > 0) {
      return true;
    } else {
      return false;
    }
  });
}
