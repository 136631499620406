

export const getUOMs=()=>{
    return [
        { value: "BAG", text: "BAGS" },
        { value: "BAL", text: "BALE" },
        { value: "BDL", text: "BUNDLES" },
        { value: "BKL", text: "BUCKLES" },
        { value: "BOU", text: "BILLION OF UNITS" },
        { value: "BOX", text: "BOX" },
        { value: "BTL", text: "BOTTLES" },
        { value: "BUN", text: "BUNCHES" },
        { value: "CAN", text: "CANS" },
        { value: "CBM", text: "CUBIC METERS" },
        { value: "CCM", text: "CUBIC CENTIMETERS" },
        { value: "CMS", text: "CENTI METERS" },
        { value: "CTN", text: "CARTONS" },
        { value: "DOZ", text: "DOZENS" },
        { value: "DRM", text: "DRUMS" },
        { value: "GGK", text: "GREAT GROSS" },
        { value: "GMS", text: "GRAMMES" },
        { value: "GRS", text: "GROSS" },
        { value: "GYD", text: "GROSS YARDS" },
        { value: "KGS", text: "KILOGRAMS" },
        { value: "KLR", text: "KILOLITRE" },
        { value: "KME", text: "KILOMETRE" },
        { value: "LTR", text: "LITRES" },
        { value: "MTR", text: "METERS" },
        { value: "MLT", text: "MILILITRE" },
        { value: "MTS", text: "METRIC TON" },
        { value: "NOS", text: "NUMBERS" },
        { value: "OTH", text: "OTHERS" },
        { value: "PAC", text: "PACKS" },
        { value: "PCS", text: "PIECES" },
        { value: "PRS", text: "PAIRS" },
        { value: "QTL", text: "QUINTAL" },
        { value: "ROL", text: "ROLLS" },
        { value: "SET", text: "SETS" },
        { value: "SQF", text: "SQUARE FEET" },
        { value: "SQM", text: "SQUARE METERS" },
        { value: "SQY", text: "SQUARE YARDS" },
        { value: "TBS", text: "TABLETS" },
        { value: "TGM", text: "TEN GROSS" },
        { value: "THD", text: "THOUSANDS" },
        { value: "TON", text: "TONNES" },
        { value: "TUB", text: "TUBES" },
        { value: "UGS", text: "US GALLONS" },
        { value: "UNT", text: "UNITS" },
        { value: "YDS", text: "YARDS" },
        { value: "RUI", text: "RUN INCH" },
    ]
}

export const checkDateFormat=(data, format, type)=>{
    let dd = ""
    let mm = ""
    let yy = ""
    if (format.toLowerCase() === "mm/yy" || format.toLowerCase() === "mm-yy") {
        if (data.length > 5) {
            return null
        }
        let pattern = /^[0-9]{2}[\/\-][0-9]{2}/i
        let result = pattern.test(data)
        if (!result) {
            return null
        }
        // let datas = data.split("/")
        let datas = []
        if (format.toLowerCase() === "mm/yy") {
            datas = data.split("/")
        }
        else {
            datas = data.split("-")
        }
        mm = datas[0]
        yy = datas[1]
        if (Number(mm) < 0 && Number(mm) > 12) {
            return null
        }
        return new Date("20" + yy, type === "exp" ? mm : Number(mm) - 1, type === "exp" ? 0 : 1)
    }
    else if (format.toLowerCase() === "mmm/yy" || format.toLowerCase() === "mmm-yy") {
        if (data.length > 5) {
            return null
        }
        let pattern = /^[a-z]{3}[\/\-][0-9]{2}/i
        let result = pattern.test(data)
        if (!result) {
            return null
        }
        // let datas = data.split("/")
        let datas = []
        if (format.toLowerCase() === "mmm/yy") {
            datas = data.split("/")
        }
        else {
            datas = data.split("-")
        }
        mm = getMonth(datas[0])
        if (mm.trim() === "") {
            return null
        }
        yy = datas[1]
        return new Date("20" + yy, type === "exp" ? mm : Number(mm) - 1, type === "exp" ? 0 : 1)
    }
    else if (format.toLowerCase() === "mmm/yyyy" || format.toLowerCase() === "mmm-yyyy") {
        if (data.length > 5) {
            return null
        }
        let pattern = /^[a-z]{3}[\/\-][0-9]{4}/i
        let result = pattern.test(data)
        if (!result) {
            return null
        }
        // let datas = data.split("/")
        let datas = []
        if (format.toLowerCase() === "mmm/yyyy") {
            datas = data.split("/")
        }
        else {
            datas = data.split("-")
        }

        mm = getMonth(datas[0])
        if (mm.trim() === "") {
            return null
        }
        yy = datas[1]
        // return new Date("20" + yy, mm, "01")
        return new Date("20" + yy, type === "exp" ? mm : Number(mm) - 1, type === "exp" ? 0 : 1)
    }
    else if (format.toLowerCase() === "dd/mm/yy" || format.toLowerCase() === "dd-mm-yy") {
        if (data.length > 8) {
            return null
        }
        let pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{2}$/
        let result = pattern.test(data)
        if (!result) {
            return null
        }
        let datas = []
        if (format.toLowerCase() === "dd/mm/yy") {
            datas = data.split("/")
        }
        else {
            datas = data.split("-")
        }
        dd = datas[0]
        mm = datas[1]
        yy = datas[2]
        if (Number(mm) < 0 && Number(mm) > 12) {
            return null
        }
        return new Date("20" + yy, Number(mm) - 1, dd)

    }
    else if (format.toLowerCase() === "dd/mmm/yyyy") {
        if (data.length > 11) {
            return null
        }

        let pattern = /^([012]?\d|3[01])[/]([Jj][Aa][Nn]|[Ff][Ee][bB]|[Mm][Aa][Rr]|[Aa][Pp][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Nn]|[Jj][u]l|[aA][Uu][gG]|[Ss][eE][pP]|[oO][Cc][Tt]|[Nn][oO][Vv]|[Dd][Ee][Cc])[/](19|20)\d\d$/
        let result = pattern.test(data)
        if (!result) {
            return null
        }

        let datas = []
        if (format.toLowerCase() === "dd/mmm/yyyy") {
            datas = data.split("/")
        }
        dd = datas[0]
        mm = getMonth(datas[1])
        yy = datas[2]
        if (mm.trim() === "" || dd.trim() === "") {
            return null
        }
         return new Date(yy, Number(mm) - 1, dd)
    }
    else if (format.toLowerCase() === "dd-mmm-yyyy") {
        if (data.length > 11) {
            return null
        }

        let pattern = /^([012]?\d|3[01])[-]([Jj][Aa][Nn]|[Ff][Ee][bB]|[Mm][Aa][Rr]|[Aa][Pp][Rr]|[Mm][Aa][Yy]|[Jj][Uu][Nn]|[Jj][u]l|[aA][Uu][gG]|[Ss][eE][pP]|[oO][Cc][Tt]|[Nn][oO][Vv]|[Dd][Ee][Cc])[-](19|20)\d\d$/
        let result = pattern.test(data)
        if (!result) {
            return null
        }

        let datas = []
        datas = data.split("-")
        dd = datas[0]
        mm = getMonth(datas[1])
        yy = datas[2]
        if (mm.trim() === "" || dd.trim() === "") {
            return null
        }
         return new Date(yy, Number(mm) - 1, dd)
    }
}

export const getMonth=(month)=> {
    switch (month.toLowerCase()) {
        case "jan":
            return "01"
        case "feb":
            return "02"
        case "mar":
            return "03"
        case "apr":
            return "04"
        case "may":
            return "05"
        case "jun":
            return "06"
        case "jul":
            return "07"
        case "aug":
            return "08"
        case "sep":
            return "09"
        case "oct":
            return "10"
        case "nov":
            return "11"
        case "dec":
            return "12"
        default:
            return ""
    }
}

export const roundUpTo2=(num)=> {
    return Math.round(Number(num) * 100) / 100
}

export const showModule=(module, forRole, props) =>{
    
    if (forRole) {
        if(props.user.user.hasOwnProperty('RoleKey') && this.props.user.user.RoleKey !== "" ) 
        {
            return props.getVisibility(module, "read") ;
        }
        else
        {
            return true;
        }
    }
    else {
        return props.getVisibility(module, "read");
    }

    // return this.getVisibility(module, "read");
  }

//   export const getVisibility=(module, access)=>{
//     let response = this.props.getVisibility(module, access)
//     if(response ==="inline" ){
//         return true;
//     }
//     else
//     {//: "none"
//         return false;
//     }
// }