import React from "react";
import Window from '../2/Controls/Window';

class CustomFieldEntry extends React.Component {
    constructor() {
        super();
        this.state = {
            customField1DisplayName: "",
            customField2DisplayName: "",
            customField3DisplayName: "",
            customField4DisplayName: "",
            customField5DisplayName: "",
            customField6DisplayName: "",
            customField7DisplayName: "",
            customField8DisplayName: "",
            customField9DisplayName: "",
            customField10DisplayName: "",
            customField11DisplayName: "",
            customField12DisplayName: "",
            customField13DisplayName: "",
            customField14DisplayName: "",
            customField15DisplayName: "",
            customField16DisplayName: "",
            customField17DisplayName: "",
            customField18DisplayName: "",
            customField19DisplayName: "",
            customField20DisplayName: "",
            customField1: "",
            customField2: "",
            customField3: "",
            customField4: "",
            customField5: "",
            customField6: "",
            customField7: "",
            customField8: "",
            customField9: "",
            customField10: "",
            customField11: "",
            customField12: "",
            customField13: "",
            customField14: "",
            customField15: "",
            customField16: "",
            customField17: "",
            customField18: "",
            customField19: "",
            customField20: "",
            saving: false,
        };
    }

    componentDidMount() {
        this.setState({
            customField1DisplayName: this.props.customField1DisplayName,
            customField2DisplayName: this.props.customField2DisplayName,
            customField3DisplayName: this.props.customField3DisplayName,
            customField4DisplayName: this.props.customField4DisplayName,
            customField5DisplayName: this.props.customField5DisplayName,
            customField6DisplayName: this.props.customField6DisplayName,
            customField7DisplayName: this.props.customField7DisplayName,
            customField8DisplayName: this.props.customField8DisplayName,
            customField9DisplayName: this.props.customField9DisplayName,
            customField10DisplayName: this.props.customField10DisplayName,
            customField11DisplayName: this.props.customField11DisplayName,
            customField12DisplayName: this.props.customField12DisplayName,
            customField13DisplayName: this.props.customField13DisplayName,
            customField14DisplayName: this.props.customField14DisplayName,
            customField15DisplayName: this.props.customField15DisplayName,
            customField16DisplayName: this.props.customField16DisplayName,
            customField17DisplayName: this.props.customField17DisplayName,
            customField18DisplayName: this.props.customField18DisplayName,
            customField19DisplayName: this.props.customField19DisplayName,
            customField20DisplayName: this.props.customField20DisplayName,
            customField1: this.props.customField1,
            customField2: this.props.customField2,
            customField3: this.props.customField3,
            customField4: this.props.customField4,
            customField5: this.props.customField5,
            customField6: this.props.customField6,
            customField7: this.props.customField7,
            customField8: this.props.customField8,
            customField9: this.props.customField9,
            customField10: this.props.customField10,
            customField11: this.props.customField11,
            customField12: this.props.customField12,
            customField13: this.props.customField13,
            customField14: this.props.customField14,
            customField15: this.props.customField15,
            customField16: this.props.customField16,
            customField17: this.props.customField17,
            customField18: this.props.customField18,
            customField19: this.props.customField19,
            customField20: this.props.customField20,
        });
    }

    render() {
        return  <Window 
        // modal={true}
        header={"Additional Info"}  
        width={"600px"}
        processing={this.state.saving}
        onClose={this.props.onClose}
        onButtonClick={(buttonType)=>{
            if(buttonType.toLowerCase()==="ok"){
                this.props.onOK(
                    this.state.customField1,
                    this.state.customField2,
                    this.state.customField3,
                    this.state.customField4,
                    this.state.customField5,
                    this.state.customField6,
                    this.state.customField7,
                    this.state.customField8,
                    this.state.customField9,
                    this.state.customField10,
                    this.state.customField11,
                    this.state.customField12,
                    this.state.customField13,
                    this.state.customField14,
                    this.state.customField15,
                    this.state.customField16,
                    this.state.customField17,
                    this.state.customField18,
                    this.state.customField19,
                    this.state.customField20,
                    this.state.customField21,
                    this.state.customField22
                )
            }
            else
            {
                this.props.onClose()
            }
        }}
        buttons={
            [{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"}]
        }
        >
                    <div className="box-body">
                        <div
                            className="scroll2 newHeight"
                            style={{
                                height: "50vh",
                                overflowY: "scroll",
                                overflowX: "hidden",
                            }}
                        >
                            <table className="table table-bordered table-striped">
                                <tbody>
                                    {this.state.customField1DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td style={{ width: "50%" }}>
                                                    {this.state.customField1DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField1DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField1:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField1
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField2DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField2DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField2DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField2:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField2
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField3DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField3DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField3DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField3:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField3
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField4DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField4DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField4DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField4:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField4
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField5DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField5DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField5DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField5:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField5
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField6DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField6DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField6DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField6:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField6
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField7DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField7DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {/* <div className="md-hidden lg-hidden">
                                                    {this.state.customField7DisplayName.trim()}
                                                </div> */}
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField7DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField7:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField7
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField8DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField8DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField8DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField8:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField8
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField9DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField9DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {this.state.customField9DisplayName.trim()}
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField9:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField9
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField10DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField10DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField10DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField10:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField10
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField11DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {
                                                        this.state
                                                            .customField11DisplayName
                                                    }
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField11DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField11:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField11
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField12DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {
                                                        this.state
                                                            .customField12DisplayName
                                                    }
                                                </td>
                                            )}
                                            <td>
                                                {/* <div className="md-hidden lg-hidden">
                                                    {
                                                        this.state
                                                            .customField12DisplayName
                                                    }
                                                </div> */}
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField12DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField12:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField12
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField13DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField13DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField13DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField13:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField13
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField14DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField14DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField14DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField14:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField14
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField15DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField15DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField15DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField15:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField15
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField16DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField16DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField16DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField16:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField16
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField17DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField17DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField17DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField17:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField17
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField18DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField18DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField18DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField18:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField18
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField19DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField19DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField19DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField19:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField19
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {this.state.customField20DisplayName.trim() !==
                                        "" && (
                                        <tr>
                                            {!this.props.mobile && (
                                                <td>
                                                    {this.state.customField20DisplayName.trim()}
                                                </td>
                                            )}
                                            <td>
                                                {this.props.mobile && (
                                                    <div className="md-hidden lg-hidden">
                                                        {
                                                            this.state
                                                                .customField20DisplayName
                                                        }
                                                    </div>
                                                )}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onChange={(evt) =>
                                                        this.setState({
                                                            customField20:
                                                                evt.target
                                                                    .value,
                                                        })
                                                    }
                                                    value={
                                                        this.state.customField20
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
               </Window>
    }
}

export default CustomFieldEntry;
