import React from 'react'
import AddEditProductCategory from '../Catalogue/AddEditProductCategory'
import CustomerViewNew from '../CRM/CustomerViewNew';
import AddAccount from '../Account/AddEditAccount';
import * as actionTypes from '../../actionTypes';
import AddEditExpenseCategory from '../Settings/AddEditExpenseCategory'
import AddEditProductBrand from '../Catalogue/AddEditProductBrand'
import AddEditProductTax from '../Catalogue/AddEditProductTax'
import AddEditPaymentTypes from '../Settings/AddEditPaymentTypes'
import AddEditStoreLocation from '../Settings/AddEditStoreLocation'
import AddEditCashRegister from '../Settings/AddEditCashRegister'
import AddRegistrationSettings from '../Settings/AddRegistrationSettings'
import EmployeeAccess from '../Settings/Employee/EmployeeAccess'
import EmployeeServiceMapping from '../Settings/Employee/EmployeeServiceMapping';
import ChangeEmployeeAccess from '../Settings/Employee/ChangeEmployeeAccess';
import AddEditModifier from '../Catalogue/AddEditModifier'
import OutForDelivery from '../Sales/OutForDelivery'

class GenericRedirectPage extends React.Component
{
    render()
    {
        return <React.Fragment>
        {/* this is for category and sub categor both */}
        {this.props.location.state.module==='category' && <AddEditProductCategory
            {...this.props}
            productCategory={this.props.location.state.category}
            parentID={this.props.location.state.parentID}
            parent={this.props.location.state.parent}
            type={this.props.location.state.type}
            onSave={()=>{
                if (this.props.location.state.type === actionTypes.PRODUCT_CATEGORY) {
                    this.props.history.push({ pathname: '/home/catalogue/categories' });
                }
                else
                {
                     this.props.history.push({ pathname: '/home/catalogue/subcategories/', 
                     state: { parentID:  this.props.location.state.parentID, 
                    parent:this.props.location.state.parent} })
                }
            }}
        />}
        {/* {this.props.location.state.module ==='cutomerviewnew' && 
            <CustomerViewNew customerID={this.props.location.state.customerID} showAsComponent={false}/>
        } */}
        {this.props.location.state.module ==='customer' && 
            <CustomerViewNew 
            {...this.props}     
            customerID={this.props.location.state.customerID} 
            action={this.props.location.state.action ? this.props.location.state.action : ''}
            showAsComponent={false}/>
        }
        {/* {this.props.location.state.module ==='account' && 
            <AddAccount 
            {...this.props}     
            onSave={()=>{
                this.props.history.push({ pathname:'/home/expense/accountlist'})
            }}
            Account={this.props.location.state.account}/>
        } */}
        {this.props.location.state.module ==='brand' && 
            <AddEditProductBrand 
            {...this.props}     
            onSave={()=>{
                this.props.history.push({ pathname:'/home/catalogue/brands'})
            }}
            onClose={()=>{
                this.props.history.push({ pathname:'/home/catalogue/brands'})
            }}
            productBrand={this.props.location.state.productBrand}/>
        }
        {this.props.location.state.module ==='expense category' && 
            <AddEditExpenseCategory 
            {...this.props}     
            onSave={()=>{
                this.props.history.push({ pathname:'/home/expensecategorieslist'})
            }}
            ExpenseCategory={this.props.location.state.expenseCategory}/>
        }
        {this.props.location.state.module ==='tax' && 
            <AddEditProductTax 
            {...this.props}     
            onSave={()=>{
                this.props.history.push({ pathname:'/home/catalogue/taxes'})
            }}

            onClose={()=>{
                this.props.history.push({ pathname:'/home/catalogue/taxes'})
            }}
            
            productTax={this.props.location.state.productTax}/>
        }
        {this.props.location.state.module ==='paymenttypes' && 
            <AddEditPaymentTypes 
            
            {...this.props}     

            onSave={()=>{
                this.props.history.push({ pathname:'/home/settings/paymenttypes'})
            }}

            onClose={()=>{
                this.props.history.push({ pathname:'/home/settings/paymenttypes'})
            }}
            
            paymentType={this.props.location.state.paymentType}/>
        }

        {this.props.location.state.module ==='location' && 
            <AddEditStoreLocation 
            
            {...this.props}     

            store={this.props.location.state.store}

            storeLocation = {this.props.location.state.storeLocation}

            onSave={()=>{
                this.props.history.push({ pathname: "/home/settings/stores/locations/", state: { store: this.props.location.state.store}})
            }}

            onClose={()=>{
                this.props.history.push({ pathname: "/home/settings/stores/locations/", state: { store: this.props.location.state.store}})
            }}
            
            paymentType={this.props.location.state.paymentType}/>
        }

        {this.props.location.state.module ==='cashregister' && 
            <AddEditCashRegister 
            
            {...this.props}     

            store={this.props.location.state.store}

            storeID = {this.props.location.state.storeID}

            cashRegister = {this.props.location.state.cashRegister}

            onSave={()=>{
                this.props.history.push({ pathname: "/home/settings/crlist", state:{store:this.props.location.state.store}})
            }}

            onClose={()=>{
                this.props.history.push({ pathname: "/home/settings/crlist", state:{store:this.props.location.state.store}})
            }}
            
            paymentType={this.props.location.state.paymentType}/>
        }

        {this.props.location.state.module === 'registration' &&
            <AddRegistrationSettings 
            {...this.props}
            onSave={() => {
                this.props.history.push({ pathname: '/home' })
            }}

            onClose={() => {
                this.props.history.push({ pathname: '/home' })
            }}
            />
        }
        
            {this.props.location.state.module === 'servicemapping' &&
                <EmployeeServiceMapping
                    {...this.props}
                    employee={{ ...this.props.location.state.employee, employeeID: this.props.location.state.employeeID }}
                    onClose={() => {
                        this.props.history.push({ pathname: '/home/settings/employees' })
                    }}
                />
            }
            {this.props.location.state.module === 'assignaccess' &&
            <EmployeeAccess
                {...this.props}
                employee={{ ...this.props.location.state.employee, employeeID: this.props.location.state.employeeID }}
                onClose={() => {
                    this.props.history.push({ pathname: '/home/settings/employees' })
                }}
            />
            }
            {this.props.location.state.module === 'changeaccess' &&
            <ChangeEmployeeAccess
                {...this.props}
                userAccess= {this.props.location.state.userAccess}
                employee={{ ...this.props.location.state.employee, employeeID: this.props.location.state.employeeID }}
                onSaveChangeEmployeeAccess={() => {
                    this.props.history.push({ pathname: '/home/settings/employees' })
                }}
                onClose={() => {
                    this.props.history.push({ pathname: '/home/settings/employees' })
                }}
            />
            }

            {this.props.location.state.module === 'modifier' &&
            <AddEditModifier
                {...this.props}
                modifier= {this.props.location.state.modifier}
                onSave={() => {
                    this.props.history.push({ pathname: '/home/catalogue/modifiers' })
                }}
                onClose={() => {
                    this.props.history.push({ pathname: '/home/catalogue/modifiers' })
                }}
            />
            }

            {(this.props.location.state.module === 'outfordelivery' || this.props.location.state.module === 'readyforpickup')  &&
                <OutForDelivery
                    {...this.props}
                    selectedSale= {this.props.location.state.selectedSale}
                    module = {this.props.location.state.module}
                    onClose={() => {
                        this.props.history.push({ pathname: '/home/sales/deliverylist' })
                        //deliverylist
                    }}
                />
            }

        </React.Fragment>
    }
}

export default GenericRedirectPage
