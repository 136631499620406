import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';

class LoyaltyLedger extends Component {

    constructor() {
        super()
        this.state = {
            ledgers: [],
            searchText: '',
            searchType: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading: true,
            searchTransactionNo : '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0]
        }
        this.loadLedger = this.loadLedger.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }


    componentDidMount() {
        this.loadLedger(this.state.recordFrom, this.state.searchText, this.state.searchTransactionNo, this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate, this.state.searchType)
    }

    loadLedger(from, searchText, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate, searchType) {
        this.setState({ loading: true })
        this.props.getLoyaltyLedger(this.props.user.user.RegistrationID, this.props.user.store.key, from, this.state.itemsCountPerPage, searchText, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate, searchType)
            .then((result) => {
                this.setState({ ledgers: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }


    onClearSearch() {
        this.setState({
            recordFrom: 0,
            searchText: '',
            searchTransactionNo: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0], 
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchType: '',
        })
        this.loadLedger(0, "","", new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "");
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getLoyaltyLedger( 
            this.props.user.user.RegistrationID,
            this.props.user.store.key, 0,
            10000,
            this.state.searchText,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchType
        ).then((result) => {
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let ledgers = []
                result.searchResult.map((data) => {
                    let customer = {
                        "Customer Code": data.CustomerCode,
                        "Name": data.CustomerName,
                        "PhoneNo.": !isNullOrUndefined(data.CustomerPhoneNo) ? data.CustomerPhoneNo : "",
                        "EmailID": !isNullOrUndefined(data.CustomerEmailID) ? data.CustomerEmailID : "",
                        "Transaction No": data.TransactionNo,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Type": data.Type,
                        "Adjusted Point": data.hasOwnProperty('AdjustedPoints') ? data.AdjustedPoints : 0,
                        "Balance Point": data.hasOwnProperty('BalancePoints') ? data.BalancePoints : 0,
                        "Loyality Point": data.LoyaltyPoints
                    }
                    ledgers.push(customer);
                })
                const csvData = constatnts.objToCSV(ledgers);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "LoyaltyLedger.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadLedger(
            0,
            this.state.searchText,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchType
        )
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadLedger(
            recordFrom,
            this.state.searchText,this.state.searchTransactionNo, this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchType
        )
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }


    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }


    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Loyalty Ledger</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"                                            
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Search Customer</label>
                                            <input type="text"
                                                onKeyPress={this.handleKeyPress}
                                                autoFocus={true}
                                                className="form-control input-md"
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Type</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchType}
                                                onKeyPress={this.handleKeyPress}
                                                onChange={(evt) => this.setState({ searchType: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress}
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>                                    
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        >Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Customer Code</th>
                                                <th>Customer Name</th>
                                                <th>Contact Phone No</th>
                                                <th>Customer Email</th>
                                                <th>Transaction No</th>
                                                <th>Transaction Date</th>
                                                <th>Type</th>
                                                <th>Adjusted Points</th>
                                                <th>Balance Points</th>
                                                <th>Loyalty Points</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ledgers.map((ledger) =>
                                                <tr>
                                                    <td>{ledger.CustomerCode}</td>
                                                    <td>{ledger.CustomerName}</td>
                                                    <td>{ledger.CustomerPhoneNo}</td>
                                                    <td>{ledger.CustomerEmailID}</td>
                                                    <td>{ledger.TransactionNo}</td>
                                                    <td>{(new Date(ledger.TransactionDate).toISOString().split('T')[0])}</td>
                                                    <td>{ledger.Type}</td>
                                                    <td>{ledger.hasOwnProperty('AdjustedPoints') ? ledger.AdjustedPoints : 0}</td>
                                                    <td>{ledger.hasOwnProperty('BalancePoints') ? ledger.BalancePoints : 0}</td>
                                                    <td>{ledger.LoyaltyPoints}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table> </div>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(LoyaltyLedger)
