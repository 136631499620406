import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Print from '../Sales/Print'
import PageSection from '../2/Controls/PageSection';
import Page from '../2/Controls/Page';
import {isMobileApp} from '../../actions/Util';

class EditPackage extends Component {

    constructor() {
        super()

        this.state = {
            saving: false,
            employees: [],
            employee: null,
            key: '',
            packageServices: [],
            serviceDate: '',
            selectedService: {},
            employeeError: false,
            print: false,
            printChecked: false,
            printData: null,
            mobile:false,
            expandDetails:false,
            packageTracker:null
        }
        this.onSavePackage = this.onSavePackage.bind(this);
        this.renderPackageDetails = this.renderPackageDetails.bind(this)
    }

    componentDidMount() {
        const key = this.props.match.params.key;
        this.setState({ key: key});
        if (key) {
            this.props.getPackageDetails(key).then((data) => {

                let packageServices = [];
                for (let index = 0; index < data.PackageServices.length; index++) {
                    const service = data.PackageServices[index];
                    service.Employee = service.hasOwnProperty('Employee') ? service.Employee : { EmployeeID: '', EmployeeName: '' }
                    service.ServiceDate = service.hasOwnProperty('ServiceDate') ? service.ServiceDate : ''
                    service.Status = service.hasOwnProperty('Status') ? service.Status : "Pending"
                    service.Notes = service.hasOwnProperty('Notes') ? service.Notes : ''
                    service.errMessage = ""
                    packageServices.push(service);
                }

                let startDate = new Date(data.PackageStartDate.seconds * 1000).toString().substring(0, 24);
                startDate = new Date(startDate).toISOString().split('T')[0]
                let endDate = new Date(data.PackageEndDate.seconds * 1000).toString().substring(0, 24);
                endDate = new Date(endDate).toISOString().split('T')[0]

                this.setState({
                    packageCode: data.PackageCode,
                    packageStartDate: startDate,
                    packageEndDate: endDate,
                    customerName: data.CustomerName,
                    packageName: data.PackageName,
                    customerPhoneNo: data.CustomerPhoneNo,
                    packageCost: data.PackageCost,
                    customerEmailID: data.CustomerEmailID,
                    packageServices: packageServices,
                    packageCost: data.PackageCost,
                    transactionNo: data.TransactionNo,
                    packageValidityInDays: data.PackageValidityInDays,
                    packageTracker:data
                })
                this.props.getEmployees(this.props.user.user.RegistrationID, 
                    this.props.user.store.key,
                    0, 1000, "active", "", "", "", "", "",
                    "", "", "", "", "", ""
                ).then((employees) => {
                    this.setState({ employees: employees.searchResult })

                })
            })
        }
    }

    onSavePackage() { 

        // console.log(JSON.stringify(this.state.packageServices));
        this.setState({ saving: true })

        let completedCount = 0
        let status = 'Pending'

        for (let index = 0; index < this.state.packageServices.length; index++) {
            let packageService = this.state.packageServices[index]
            if (packageService.Status === 'Completed')
                completedCount += 1;
        }

        // console.log(completedCount);
        if (completedCount > 0) {
            if (completedCount === this.state.packageServices.length) {
                status = 'Completed'
            }
            if (completedCount < this.state.packageServices.length) {
                status = 'Partial'
            }
        }
        let lineItemError = false
        let packageServices = this.state.packageServices
        for (let index = 0; index < packageServices.length; index++) {

            let packageService = packageServices[index]

            if ((packageService.ServiceDate.trim() === '' && packageService.Employee.EmployeeID.trim() !== '') ||
                (packageService.ServiceDate.trim() !== '' && packageService.Employee.EmployeeID.trim() === '')) {
                packageService.errMessage = "Please select service data and employee "
                lineItemError = true
            }
        }

        if (lineItemError) {
            this.setState({ saving: false, packageServices: packageServices })
            return;
        }

        let objPackage = {
            PackageServices: this.state.packageServices,
            PackageStatus: status,
        }

        const key = this.props.match.params.key
        if (key) {
            this.props.updatePackage(objPackage, key).then(() => {
                this.props.getToastr("Package updated Successfully")
                let packageTracker = {
                    PackageServices: this.state.packageServices,
                    PackageStatus: status,
                    PackageName: this.state.packageName,
                    PackageCost: this.state.packageCost,
                    TransactionNo: this.state.transactionNo,
                    CustomerName: this.state.customerName,
                    CustomerPhoneNo: this.state.customerPhoneNo,
                    CustomerEmailID: this.state.customerEmailID,
                    PackageStartDate: this.state.packageStartDate,
                    PackageEndDate: this.state.packageEndDate,
                    PackageValidityInDays: this.state.packageValidityInDays,
                }
                // if (this.state.printChecked) {
                    
                //     // console.log("printdata", JSON.stringify(printData))
                // }

                setTimeout(function () {
                    this.setState({ saving: false })

                    this.props.history.push('/home/packages/packagelist')
                }.bind(this), 3000)
            })
        }
    }

    renderPackageDetails(){

        return <React.Fragment>
            <div className="row">
                    <div className="col-md-3 col-xs-12">
                        <div className="form-group" >
                            <label >Transaction No.</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.transactionNo}</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                        <div className="form-group" >
                            <label >Start Date</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.packageStartDate}</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                        <div className="form-group" >
                            <label >End Date</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.packageEndDate}</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                        <div className="form-group" >
                            <label >Validity</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.packageValidityInDays} Day's</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                        <div className="form-group">
                            <label >Package Cost</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.packageCost}</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                        <div className="form-group" >
                            <label >Customer Name</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.customerName}</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-6">
                        <div className="form-group" >
                            <label >Phone No</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.customerPhoneNo}</span>
                        </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                        <div className="form-group" >
                            <label >Email ID</label>
                            <span style={{ display: "block", fontWeight: " " }}>{this.state.customerEmailID}</span>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    }
    
    renderDeskTopServices(){
      
        return <React.Fragment>
            {(this.state.packageServices.length > 0) && <table className="table no-margin">
                    <tbody>
                        <tr>
                            <th className="hidden-xs">Sr No.</th>
                            <th className="hidden-xs">Service Name</th>
                            <th className="hidden-xs">Retail Price</th>
                            <th className="hidden-xs"style={{width:"100px"}}>Date</th>
                            <th className="hidden-xs">Employee Name</th>
                            <th className="hidden-xs">Notes</th>
                            <th className="hidden-xs">Status</th>
                        </tr>
                        {this.state.packageServices.map((service, index) =>
                            <tr>
                                <td className="hidden-xs">{index+1}</td>
                                <td className="hidden-xs">{service.Service}</td>
                                <td className="hidden-xs">{service.RetailPrice}</td>
                                <td className="hidden-xs">
                                    <div className={service.errMessage !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="date" className="form-control"
                                            onChange={(evt) => {
                                                let packageServices = this.state.packageServices;
                                                let packageService = this.state.packageServices[index]
                                                packageService.errMessage = ''
                                                packageService.ServiceDate = evt.target.value
                                                if (packageService.Status === "Pending" && packageService.ServiceDate != '' && packageService.Employee.EmployeeID != '')
                                                    packageService.Status = "Completed"

                                                packageServices[index] = packageService
                                                this.setState({ packageServices: packageServices })
                                            }}
                                            value={service.ServiceDate}
                                        />
                                        {service.errMessage !== "" && <span className="help-block">Please select employee and date.</span>}
                                    </div>
                                </td>
                                <td className="hidden-xs">
                                    <div className={service.errMessage !== "" ? "form-group has-error" : "form-group "}>
                                        <select className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => {
                                                let packageServices = this.state.packageServices;
                                                let packageService = this.state.packageServices[index]
                                                packageService.errMessage = ''
                                                packageService.Employee = { EmployeeID: '', EmployeeName: '' }
                                                this.state.employees.filter((e) => e.key === evt.target.value).map((f) => {
                                                    f.EmployeeName = f.EmployeeFirstName + " " + f.EmployeeLastName
                                                    packageService.Employee = { EmployeeID: f.key, EmployeeName: f.EmployeeName }
                                                    if (packageService.Status === "Pending" && packageService.ServiceDate != '' && packageService.Employee.EmployeeID != '')
                                                        packageService.Status = "Completed"

                                                    packageServices[index] = packageService
                                                    this.setState({ packageServices: packageServices })
                                                })
                                                packageServices[index] = packageService
                                                this.setState({ packageServices: packageServices })
                                            }}
                                            value={service.Employee.EmployeeID}>
                                            <option value=''>Select Employee</option>
                                            {this.state.employees.map((e, index1) => <option key={index1} value={e.key}>{e.EmployeeFirstName + ' ' + e.EmployeeLastName}</option>)}
                                        </select>
                                        {service.errMessage !== '' && <span className="help-block">Please select employee and date.</span>}
                                    </div>
                                </td>
                                <td className="hidden-xs">
                                    <textarea className="text"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(evt) => {
                                        let packageServices = this.state.packageServices;
                                        let packageService = this.state.packageServices[index]
                                        packageService.Notes = evt.target.value
                                        packageServices[index] = packageService
                                        this.setState({ packageServices: packageServices })
                                    }}
                                    value={service.Notes}>
                                    </textarea>
                                </td>
                                <td className="hidden-xs">{service.Status}</td>
                            </tr>
                        )}
                    </tbody>
                </table>}
        </React.Fragment>
    }

    renderMobileServices(){
       
        return  <React.Fragment>
            {this.state.packageServices.map((service, index) => <div>
                            <div><b>{service.Service}</b></div>
                            <div>{service.Status}</div>
                            <div className={service.errMessage !== "" ? "form-group has-error" : "form-group "}>
                                <input type="date" className="form-control"
                                    onChange={(evt) => {
                                        let packageServices = this.state.packageServices;
                                        let packageService = this.state.packageServices[index]
                                        packageService.errMessage = ''
                                        packageService.ServiceDate = evt.target.value
                                        if (packageService.Status === "Pending" && packageService.ServiceDate != '' && packageService.Employee.EmployeeID != '')
                                            packageService.Status = "Completed"

                                        packageServices[index] = packageService
                                        this.setState({ packageServices: packageServices })
                                    }}
                                    value={service.ServiceDate}
                                />
                                {service.errMessage !== "" && <span className="help-block">Please select employee and date.</span>}
                            </div>
                            <div className={service.errMessage !== "" ? "form-group has-error" : "form-group "}>
                                <select className="form-control"
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(evt) => {
                                        let packageServices = this.state.packageServices;
                                        let packageService = this.state.packageServices[index]
                                        packageService.errMessage = ''
                                        packageService.Employee = { EmployeeID: '', EmployeeName: '' }
                                        this.state.employees.filter((e) => e.key === evt.target.value).map((f) => {
                                            f.EmployeeName = f.EmployeeFirstName + " " + f.EmployeeLastName
                                            packageService.Employee = { EmployeeID: f.key, EmployeeName: f.EmployeeName }
                                            if (packageService.Status === "Pending" && packageService.ServiceDate != '' && packageService.Employee.EmployeeID != '')
                                                packageService.Status = "Completed"

                                            packageServices[index] = packageService
                                            this.setState({ packageServices: packageServices })
                                        })
                                        packageServices[index] = packageService
                                        this.setState({ packageServices: packageServices })
                                    }}
                                    value={service.Employee.EmployeeID}>
                                    <option value=''>Select Employee</option>
                                    {this.state.employees.map((e, index1) => <option key={index1} value={e.key}>{e.EmployeeFirstName + ' ' + e.EmployeeLastName}</option>)}
                                </select>
                                {service.errMessage !== '' && <span className="help-block">Please select employee and date.</span>}
                            </div>
                            <div>
                                <textarea className="text"
                                onKeyPress={this.handleKeyPress}
                                onChange={(evt) => {
                                    let packageServices = this.state.packageServices;
                                    let packageService = this.state.packageServices[index]
                                    packageService.Notes = evt.target.value
                                    packageServices[index] = packageService
                                    this.setState({ packageServices: packageServices })
                                }}
                                value={service.Notes}>
                                </textarea>
                            </div>
                            <br></br>
                            <br></br>
            </div>)}
        </React.Fragment>
    }

    render() {
        
        return <React.Fragment>
        
        {!this.state.print && <Page 
            title={"Edit Package - " + this.state.packageName + "(" + this.state.packageCode + ")"} 
            showSaveButton={true} 
            showCancelButton={true}
            showPrintButton={true}
            processing={this.state.saving}
            onClick={(action)=>{
                if(action==="cancel"){
                    this.props.history.push("/home/packages/packagelist");
                }else if(action==="save"){
                    this.onSavePackage();
                }else if(action==="print"){
                    // this.setState({ print:true })
                    let printConfig = {}
                    printConfig.PrintSize = "3 inch"
                    printConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty("PackageTrackerTemplate") ? this.props.registration.registration.PackageTrackerTemplate : "";
                    let packageTracker = this.state.packageTracker;
                    let printData = {
                        packageTracker: packageTracker,
                        PrintConfig: printConfig
                    }
                    this.setState({ print: true, printData: printData })
                }
            }}
            >
            <PageSection title="Package Details">
                {this.renderPackageDetails()}
            </PageSection>
            <PageSection title="Services">
                {!isMobileApp() && this.renderDeskTopServices()}
                {isMobileApp() && this.renderMobileServices()}
            </PageSection>
        </Page>}

        {this.state.print && <div>
            <Print  {...this.props}
                printChecked={this.state.print}
                sendEmailChecked={false}
                sendSMSChecked={false}
                printData={this.state.printData}
                onClose={() => {
                    this.setState({ print: false })
                    this.props.history.push('/home/packages/packagelist')
                    }
                }
            />
        </div>}
        
    </React.Fragment>
    }
}

export default withRouter(EditPackage)