import React, { Component } from 'react';
import CreatableSelect from 'react-select'
import Confirm from 'react-confirm-bootstrap';
import ImportMaster from "../ImportMaster";
import Modal from 'react-responsive-modal';
import Input from '../2/Controls/Input';
import Form from '../2/Controls/Form';

class AddEditOffer extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            name: '',
            description: '',

            couponType: '',
            couponCode: '',
            codePrefix: '',
            noOfCoupons: '',
            uploadedCodes: [],
            uploadedClientCodes: [],
            autoGeneratedCodes: [],

            couponTypeError: '',
            codeTypeError: '',
            codePrefixError: '',
            couponsNoError: '',

            priority: '',
            combineSelected: false,
            validityFrom: new Date().toISOString().split('T')[0],
            validityTo: new Date().toISOString().split('T')[0],
            specificDaySelected: '',
            specificDays: [],
            sundaySelected: false,
            mondaySelected: false,
            tuesdaySelected: false,
            wednesdaySelected: false,
            thursdaySelected: false,
            fridaySelected: false,
            saturdaySelected: false,
            specificTimeSelected: '',
            inTime: { HH: '', MM: '', AMPM: '' },
            outTime: { HH: '', MM: '', AMPM: '' },
            specificStoreSelected: false,
            allStoresSelected: true,
            associatedStores: [],
            stores: [],
            applicableCustomers: '',
            customerTags: [],
            offerType: '',
            offerTypeID: '',
            purchaseGroup: '',
            purchaseGroupID: '',
            purchaseQty: '',
            freeGroup: '',
            freeGroupID: '',
            freeQty: '',
            invoiceAmount: '',
            discount: '',
            discountValue: '',
            offerGroup: '',
            offerGroupID: '',
            productTags: [],

            nameError: '',
            priorityError: '',
            combineSelectedError: '',
            validityFromError: '',
            validityToError: '',
            specificDaySelectedError: '',
            specificTimeSelectedError: '',
            dayError: '',
            inTimeError: '',
            outTimeError: '',
            storeSelectionError: '',
            storeNameError: '',
            applicableCustomerError: '',
            typeError: '',
            op1PurchaseError: '',
            QtyError: '',
            freeError: '',
            invoiceAmountError: '',
            discountInError: '',
            valueError: '',
            offerGroupingError: '',
            saving: false,
            discountPercentageError: '',
            discountPercentage: 0,
            spreadDiscount: false,

            showImport: false,
            showClientSpecificImport: false,
            getXFreeDiscountPercentage:100,
            getXFreeDiscountPercentageError:"",
            couponTypes:[
                {value:"SC", text:"Single Code"},
                {value:"AG", text:"Auto-generated"},
                {value:"CAG", text:"Client Specific (Campaign)"},
                {value:"UC", text:"Upload Pre-defined Codes"},
                {value:"CSC", text:"Client Specific Codes"},
            ]
        }
        this.onSaveOffer = this.onSaveOffer.bind(this)
        this.onProductTagChange = this.onProductTagChange.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onSpecificDaySelected = this.onSpecificDaySelected.bind(this)
        this.onGenerateClick = this.onGenerateClick.bind(this)
        this.onDeleteMultipleCodeUpload = this.onDeleteMultipleCodeUpload.bind(this)
    }

    componentDidMount() {

        var date = this.props.addDays(7);
        date = date.toISOString().split('T')[0]
        this.setState({ validityTo: date });

        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
            , "", "", "", "").then((result) => {
                let stores = result.searchResult;
                const storeList = [];
                stores.map((store) => {
                    let obj = {
                        value: store.key, label: store.StoreName
                    }
                    storeList.push(obj);
                });
                this.setState({ stores: storeList })
            });

        this.props.getProductTags(this.props.user.user.RegistrationID).then((productTag) => {
            this.setState({ productTags: productTag })
        })

        const key = this.props.selectedRecord  ?  this.props.selectedRecord.key : "";
        if (key) {
            this.setState({ key: key })

            this.props.getOfferDetails(key).then(async (offer) => {
                this.setState({
                    key: key,
                    name: offer.Name,
                    description: offer.Description,
                    couponCode: offer.hasOwnProperty('CouponCode') ? offer.CouponCode : "",
                    priority: offer.Priority ? offer.Priority.toString() : "",
                    combineSelected: offer.Combinable,
                    validityFrom: offer.ValidityFrom,
                    validityTo: offer.ValidityTo,
                    specificDaySelected: offer.SpecificDay,
                    specificDays: offer.SpecificDays,
                    specificTimeSelected: offer.SpecificTime,
                    inTime: offer.OfferStartTime ? offer.OfferStartTime : { HH: '', MM: '', AMPM: '' },
                    outTime: offer.OfferEndTime ? offer.OfferEndTime : { HH: '', MM: '', AMPM: '' },
                    specificStoreSelected: offer.SpecificStore,
                    allStoresSelected: offer.AllStores,
                    associatedStores: offer.Stores,
                    applicableCustomers: offer.CustomersTags,
                    offerTypeID: offer.OfferTypeID,
                    offerType: offer.OfferType,
                    purchaseGroup: offer.PurchaseGroup,
                    purchaseGroupID: offer.PurchaseGroupID,
                    purchaseQty: offer.PurchaseQty ? offer.PurchaseQty.toString() : "",
                    freeGroup: offer.FreeGroup,
                    freeGroupID: offer.FreeGroupID,
                    freeQty: offer.FreeQty ? offer.FreeQty.toString() : "",
                    invoiceAmount: offer.InvoiceAmount ? offer.InvoiceAmount.toString() : "",
                    discount: offer.Discount,
                    discountValue: offer.DiscountValue ? offer.DiscountValue.toString() : "",
                    offerGroup: offer.OfferGroup,
                    offerGroupID: offer.OfferGroupID,
                    discountPercentage: offer.hasOwnProperty('DiscountPercentage') ? offer.DiscountPercentage : 0,
                    spreadDiscount: offer.hasOwnProperty('SpreadDiscount') ? offer.SpreadDiscount : false,
                    couponType: offer.hasOwnProperty('CouponType') ? offer.CouponType : '',
                    codePrefix: offer.hasOwnProperty('CodePrefix') ? offer.CodePrefix : '',
                    noOfCoupons: offer.hasOwnProperty('NoOfCoupons') ? offer.NoOfCoupons : 0
                })

                let coupons = await this.props.getCouponDetails(key);
                //console.log("coupons", coupons);
                if (this.state.couponType === "AG") {
                    this.setState({ autoGeneratedCodes: coupons });
                }
                else if (this.state.couponType === "UC") {
                    this.setState({ uploadedCodes: coupons });
                }
                else if (this.state.couponType === "CSC") {
                    this.setState({ uploadedClientCodes: coupons });
                }
                if (offer.SpecificDays && offer.SpecificDays.length > 0) {
                    for (let index = 0; index < offer.SpecificDays.length; index++) {
                        const day = offer.SpecificDays[index];
                        if (day === "Sunday") {
                            this.setState({ sundaySelected: true });
                        }
                        if (day === "Monday") {
                            this.setState({ mondaySelected: true })
                        }
                        if (day === "Tuesday") {
                            this.setState({ tuesdaySelected: true })
                        }
                        if (day === "Wednesday") {
                            this.setState({ wednesdaySelected: true })
                        }
                        if (day === "Thursday") {
                            this.setState({ thursdaySelected: true })
                        }
                        if (day === "Friday") {
                            this.setState({ fridaySelected: true })
                        }
                        if (day === "Saturday") {
                            this.setState({ saturdaySelected: true })
                        }
                    }
                }
            })
        }
    }

    onSaveOffer() {
        this.setState({ saving: true })
        let nameError = ""
        let priorityError = ""
        let validityFromError = ""
        let validityToError = ""
        let dayError = ""
        let inTimeError = ""
        let outTimeError = ""
        let storeSelectionError = ""
        let storeNameError = ""
        let applicableCustomerError = ""
        let typeError = ""
        let op1PurchaseError = ""
        let QtyError = ""
        let freeError = ""
        let invoiceAmountError = ""
        let discountInError = ""
        let valueError = ""
        let offerGroupingError = ""
        let discountPercentageError = ""

        let codeTypeError = ''
        let singleCodeNameError = ''
        let codePrefixError = ''
        let couponsNoError = ''
        let couponTypeError = ''
        let getXFreeDiscountPercentageError= '';

        if (this.state.name.trim() === "") {
            nameError = "Please provide " + (this.props.type === "coupons" ? "coupon name" : "offer name");
        }

        if (this.props.type === "coupons") {

            if (this.state.couponType.trim() === '') {
                couponTypeError = 'Please select Coupon Type'
            }

            if (this.state.couponType === "SC") {
                if (this.state.couponCode === '') {
                    singleCodeNameError = 'Please enter code'
                }
            }

            if (this.state.couponType === "AG") {
                if (this.state.codePrefix.trim() === '') {
                    codePrefixError = 'Please enter Code Prefix'
                }

                if (this.state.noOfCoupons.trim() === '') {
                    couponsNoError = 'Please enter the No. of coupons'
                }

                if (this.state.autoGeneratedCodes.length === 0) {
                    couponTypeError = 'Please generate codes'
                }
            }

            if (this.state.couponType === "UC") {
                if (this.state.uploadedCodes.length === 0) {
                    couponTypeError = 'Please upload codes'
                }
            }

            if (this.state.couponType === "CAG") {
                if (this.state.codePrefix.trim() === '') {
                    codePrefixError = 'Please enter Code Prefix'
                }
            }

            if (this.state.couponType === "CSC") {
                if (this.state.uploadedClientCodes.length === 0) {
                    couponTypeError = 'Please upload codes'
                }
            }
        }

        if (this.state.validityFrom.trim() === "") {
            validityFromError = "Please provide validity start date"
        }

        if (this.state.validityTo.trim() === "") {
            validityToError = "Please provide validity end date"
        }

        if (this.state.validityFrom.trim() !== "" && this.state.validityTo.trim() !== "") {
            if (new Date(this.state.validityTo) < new Date(this.state.validityFrom)) {
                validityToError = "validity end date cannot be less than validity start date"
            }
        }

        if (this.state.specificDaySelected === "Yes") {
            if (this.state.sundaySelected === false && this.state.mondaySelected === false && this.state.tuesdaySelected === false
                && this.state.wednesdaySelected === false && this.state.thursdaySelected === false && this.state.fridaySelected === false &&
                this.state.saturdaySelected === false) {
                dayError = "Please select specific day"
            }
        }

        if (this.state.specificTimeSelected === "Yes") {
            if (this.state.inTime.HH === '' || this.state.inTime.MM === '' || this.state.inTime.AMPM === '') {
                inTimeError = "Please specify In Time"
            }
            if (this.state.outTime.HH === '' || this.state.outTime.MM === '' || this.state.outTime.AMPM === '') {
                outTimeError = "Please specify Out Time"
            }
        }

        if (!this.state.allStoresSelected && !this.state.specificStoreSelected) {
            storeSelectionError = "Please select either All or Specific Store option."
        }

        if (this.state.specificStoreSelected) {
            if (this.state.associatedStores.length === 0) {
                storeNameError = "Please select store"
            }
        }

        if (this.state.offerType.trim() === "") {
            typeError = "Please select type";
        }

        if (this.state.offerType) {
            if (this.state.offerTypeID === "buyxgety") {
                if (this.state.purchaseGroup.trim() === "") {
                    op1PurchaseError = "Please provide purchase group"
                }
                if (this.state.purchaseQty.trim() === "") {
                    QtyError = "Please provide quantity"
                }
                if (this.state.freeGroup.trim() === "") {
                    freeError = "Please provide free product group"
                }
                if (this.state.freeQty.trim() === "") {
                    QtyError = "Please provide quantity"
                }
            }

            if (this.state.offerTypeID === "buyxqtygetydiscountpercentage") {
                if (this.state.purchaseGroup.trim() === "") {
                    op1PurchaseError = "Please provide purchase group"
                }
                if (this.state.purchaseQty.trim() === "") {
                    QtyError = "Please provide quantity"
                }
                if (this.state.discountPercentage.trim() === "") {
                    freeError = "Please provide discount percentage"
                }
            }
            if (this.state.offerTypeID === "invoicediscount") {
                if (this.state.invoiceAmount.trim() === "") {
                    invoiceAmountError = "Please provide invoice amount"
                }
                if (this.state.discount.trim() === "") {
                    discountInError = "Please provide discount type"
                }
                if ((this.state.discount === "Percentage" || this.state.discount === "Amount") && this.state.discountValue.trim() === "") {
                    valueError = "Please provide discount value"
                }
                if (this.state.discount === "GetXFree" && this.state.freeGroupID.trim() === "") {
                    freeError = "Please provide discount value"
                }
                if (this.state.discount === "GetXFree" && this.state.freeQty.trim() === "") {
                    QtyError = "Please provide free qty"
                }
                if(this.state.discount === "GetXFree" && this.state.getXFreeDiscountPercentage===""){
                    getXFreeDiscountPercentageError= "Please provide value";
                }
                else if(this.state.discount === "GetXFree" && this.state.getXFreeDiscountPercentage>100){
                    getXFreeDiscountPercentageError= "Discount can not be more than 100%";
                }
            }

            if (this.state.discount.trim() === "perc") {
                if (this.state.value >= "100") {
                    valueError = "Percentage should be less than 100"
                }
            }
        }

        if (nameError !== "" || priorityError !== "" || dayError !== "" || inTimeError !== "" ||
            storeSelectionError !== "" || typeError !== "" ||
            op1PurchaseError !== "" || QtyError !== "" || freeError !== "" || invoiceAmountError !== "" ||
            discountInError !== "" || valueError !== "" || offerGroupingError !== "" || storeNameError !== "" ||
            validityFromError !== "" || validityToError !== "" || outTimeError !== "" || couponTypeError !== ""
            || discountPercentageError !== "" || codeTypeError !== "" || singleCodeNameError !== "" ||
            codePrefixError != '' || couponsNoError !== "" || getXFreeDiscountPercentageError!==""
        ) {
            this.setState({

                codeTypeError: codeTypeError,
                singleCodeNameError: singleCodeNameError,
                codePrefixError: codePrefixError,
                couponsNoError: couponsNoError,
                couponTypeError: couponTypeError,
                nameError: nameError,
                priorityError: priorityError,
                validityFromError: validityFromError,
                validityToError: validityToError,
                dayError: dayError,
                inTimeError: inTimeError,
                outTimeError: outTimeError,
                storeSelectionError: storeSelectionError,
                storeNameError: storeNameError,
                typeError: typeError,
                op1PurchaseError: op1PurchaseError,
                QtyError: QtyError,
                freeError: freeError,
                invoiceAmountError: invoiceAmountError,
                discountInError: discountInError,
                valueError: valueError,
                offerGroupingError: offerGroupingError,
                discountPercentageError: discountPercentageError,
                getXFreeDiscountPercentageError:getXFreeDiscountPercentageError,
                // couponCodeError:couponCodeError,
                saving: false
            })
            return
        }

        let specificDays = []
        if (this.state.sundaySelected) {
            specificDays.push("Sunday");
        }

        if (this.state.mondaySelected) {
            specificDays.push("Monday");
        }

        if (this.state.tuesdaySelected) {
            specificDays.push("Tuesday");
        }

        if (this.state.wednesdaySelected) {
            specificDays.push("Wednesday");
        }

        if (this.state.thursdaySelected) {
            specificDays.push("Thursday");
        }

        if (this.state.fridaySelected) {
            specificDays.push("Friday");
        }

        if (this.state.saturdaySelected) {
            specificDays.push("Saturday");
        }


        let offer = {
            RegistrationID: this.props.user.user.RegistrationID,
            Name: this.state.name,
            Type: this.props.type === "offers" ? "Offer" : "Coupon",
            CouponCode: this.state.couponCode,
            Description: this.state.description,
            Priority: this.state.priority ? Number(this.state.priority) : 0,
            Combinable: this.state.combineSelected,
            ValidityFrom: this.state.validityFrom,
            ValidityTo: this.state.validityTo,
            SpecificDay: this.state.specificDaySelected,
            SpecificDays: specificDays,
            SpecificTime: this.state.specificTimeSelected,
            OfferStartTime: this.state.inTime,
            OfferEndTime: this.state.outTime,
            SpecificStore: this.state.specificStoreSelected,
            AllStores: this.state.allStoresSelected,
            Stores: this.state.associatedStores,
            CustomersTags: this.state.applicableCustomers,
            PurchaseGroupID: this.state.purchaseGroupID,
            PurchaseGroup: this.state.purchaseGroup,
            PurchaseQty: this.state.purchaseQty ? Number(this.state.purchaseQty) : 0,
            FreeGroupID: this.state.freeGroupID,
            FreeGroup: this.state.freeGroup,
            FreeQty: this.state.freeQty ? Number(this.state.freeQty) : 0,
            InvoiceAmount: this.state.invoiceAmount ? Number(this.state.invoiceAmount) : 0,
            Discount: this.state.discount,
            DiscountValue: this.state.discountValue ? Number(this.state.discountValue) : 0,
            OfferGroupID: this.state.offerGroupID,
            OfferGroup: this.state.offerGroup,
            OfferType: this.state.offerType,
            OfferTypeID: this.state.offerTypeID,
            DiscountPercentage: this.state.discountPercentage,
            SpreadDiscount: this.state.spreadDiscount,
            IsDeleted: false,

            CouponType: this.state.couponType,
            CodePrefix: this.state.codePrefix,
            NoOfCoupons: this.state.noOfCoupons,
            GetXFreeDiscountPercentage: this.state.getXFreeDiscountPercentage
            // AutoGeneratedCodeRecords: this.state.autoGeneratedCodes,
            // UploadedCodes: this.state.uploadedCodes,
            // UploadedClientCodes : this.state.uploadedClientCodes
        }
        this.props.saveOffer(offer, this.state.key).then(async (key) => {
            if (this.props.type === "coupons") {
                let coupon = {
                    CouponID: key,
                    RegistrationID: this.props.user.user.RegistrationID,
                    Name: this.state.name,
                    Type: this.props.type === "offers" ? "Offer" : "Coupon",
                    Description: this.state.description,
                    Priority: this.state.priority ? Number(this.state.priority) : 0,
                    Combinable: this.state.combineSelected,
                    ValidityFrom: this.state.validityFrom,
                    ValidityTo: this.state.validityTo,
                    SpecificDay: this.state.specificDaySelected,
                    SpecificDays: specificDays,
                    SpecificTime: this.state.specificTimeSelected,
                    OfferStartTime: this.state.inTime,
                    OfferEndTime: this.state.outTime,
                    SpecificStore: this.state.specificStoreSelected,
                    AllStores: this.state.allStoresSelected,
                    Stores: this.state.associatedStores,
                    CustomersTags: this.state.applicableCustomers,
                    PurchaseGroupID: this.state.purchaseGroupID,
                    PurchaseGroup: this.state.purchaseGroup,
                    PurchaseQty: this.state.purchaseQty ? Number(this.state.purchaseQty) : 0,
                    FreeGroupID: this.state.freeGroupID,
                    FreeGroup: this.state.freeGroup,
                    FreeQty: this.state.freeQty ? Number(this.state.freeQty) : 0,
                    InvoiceAmount: this.state.invoiceAmount ? Number(this.state.invoiceAmount) : 0,
                    Discount: this.state.discount,
                    DiscountValue: this.state.discountValue ? Number(this.state.discountValue) : 0,
                    OfferGroupID: this.state.offerGroupID,
                    OfferGroup: this.state.offerGroup,
                    OfferType: this.state.offerType,
                    OfferTypeID: this.state.offerTypeID,
                    DiscountPercentage: this.state.discountPercentage,
                    SpreadDiscount: this.state.spreadDiscount,
                    CouponType: this.state.couponType,
                    CodePrefix: this.state.codePrefix,
                    NoOfCoupons: this.state.noOfCoupons,
                    IsDeleted: false,
                }
                if (this.state.key === "") {
                    if (this.state.couponType === "UC" && this.state.uploadedCodes.length > 0) {
                        for (let index = 0; index < this.state.uploadedCodes.length; index++) {
                            const code = this.state.uploadedCodes[index];
                            coupon.CouponCode = code.CouponCode;
                            await this.props.saveCouponCode(coupon);
                        }
                    }
                    else if (this.state.couponType === "CSC" && this.state.uploadedClientCodes.length > 0) {
                        for (let index = 0; index < this.state.uploadedClientCodes.length; index++) {
                            const code = this.state.uploadedClientCodes[index];
                            coupon.CouponCode = code.CouponCode;
                            coupon.CustomerID = code.CustomerID;
                            coupon.CustomerName = code.CustomerName;
                            coupon.PhoneNo = code.PhoneNo;
                            await this.props.saveCouponCode(coupon);
                        }
                    }
                    else if (this.state.couponType === "AG" && this.state.autoGeneratedCodes.length > 0) {
                        for (let index = 0; index < this.state.autoGeneratedCodes.length; index++) {
                            const code = this.state.autoGeneratedCodes[index];
                            coupon.CouponCode = code.CouponCode;
                            await this.props.saveCouponCode(coupon);
                        }
                    }
                }
            }
            this.props.getToastr((this.props.type === "offers" ? "Offer" : "Coupon") + "saved successfully")
            setTimeout(function () { //Start the timer
                this.setState({ saving: false })
                this.props.onSave();
            }.bind(this), 1000)


            // if (this.props.type === "offers") {
            //     if (this.props.isMobileApp())
            //         this.props.history.push('/home/offers')
            //     else
            //         this.props.history.push('/home/catalogue/offer')
            // }
            // else {
            //     if (this.props.isMobileApp())
            //         this.props.history.push('/home/couponList/')
            //     else
            //         this.props.history.push('/home/coupons/')
            // }

        });
    }

    onGenerateClick() {

        if (this.state.couponType === "AG" && this.state.codePrefix !== "") {
            let codes = []
            for (let couponCode = 0; couponCode < this.state.noOfCoupons; couponCode++) {
                let coupon = this.state.codePrefix + (couponCode + 1);
                codes.push({ CouponCode: coupon });
            }
            this.setState({ autoGeneratedCodes: codes });
        }
    }

    onDeleteMultipleCodeUpload() {
        this.setState({ uploadedCodes: [] })
    }

    onProductTagChange(e) {
        let productTag = {
            ProductTagID: e.target.value,
            Name: e.target.options[e.target.selectedIndex].text
        }
        this.setState({ productTags: productTag })
    }

    handleOnChange(storeList) {
        this.setState({ associatedStores: storeList, storeNameError: "" });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveOffer()
        }
    }

    onSpecificDaySelected = (e, day) => {
        let specificDays = this.state.specificDays;
        alert(e.checked + " " + day);
        if (e.checked) {
            specificDays.push(day);
        }
        this.setState({ specificDays: specificDays });
    }

    render() {
        return <React.Fragment>
            <Form
                title={this.props.type === "offers" ? this.state.key !== "" ? "Edit Offer" : "New Offer" : this.state.key !== "" ? "Edit Coupons" : "New Coupons"}
                onClick={(action)=>{
                    if(action==="save"){
                        this.onSaveOffer();
                    }else{
                        this.props.onClose()
                    }
                }}
                processing={this.state.saving}
            >
                <Input
                    type="text"
                    label="Name"
                    value={this.state.name}
                    onChange={(value) => this.setState({ name: value, nameError: '' })}
                    error={this.state.nameError}  
                    onEnter={this.onSaveOffer}
                />
                <Input
                    type="text"
                    label="Description"
                    value={this.state.description}
                    onChange={(value) => this.setState({ description: value, descriptionError: '' })}
                    error={this.state.descriptionError}  
                    onEnter={this.onSaveOffer}
                />
                <Input
                    type="number"
                    label="Priority"
                    value={this.state.priority}
                    onChange={(value) => this.setState({ priority: value, priorityError: '' })}
                    error={this.state.priorityError}  
                    onEnter={this.onSaveOffer}
                />
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            type="date"
                            label="Validity from"
                            value={this.state.validityFrom}
                            onChange={(value) => this.setState({ validityFrom: value, validityFromError: '' })}
                            error={this.state.validityFromError}  
                            onEnter={this.onSaveOffer}
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            type="date"
                            label="Validity to"
                            value={this.state.validityTo}
                            onChange={(value) => this.setState({ validityTo: value, validityToError: '' })}
                            error={this.state.validityToError}  
                            onEnter={this.onSaveOffer}
                        />
                    </div>
                </div>                
                 {this.props.type === "coupons" && <React.Fragment>
                     <Input
                        type="select"
                        label="Coupon Type"
                        disabled={this.state.key !== ""}
                        value={this.state.couponType}
                        error={this.state.couponTypeError}
                        onEnter={this.onSaveOffer}
                        onChange={(value) => {
                            this.setState({
                                couponType: this.state.couponTypes.filter((f)=>f.value === value)[0].value, 
                                couponTypeError: "", 
                                autoGeneratedCodes: [],
                                uploadedClientCodes: [], 
                                uploadedCodes: [], 
                                codePrefix: "", 
                                codePrefixError: '',
                                couponsNoError: '', 
                                noOfCoupons: ""
                            })
                        }}>
                        <option value="">Select Coupon Type</option>
                        {this.state.couponTypes.map(m=><option value={m.value}>{m.text}</option>)}
                        {/* <option value="SC">Single Code</option>
                        <option value="AG">Auto-generated</option>
                        <option value="CAG">Client Specific (Campaign)</option>
                        <option value="UC">Upload Pre-defined Codes</option>
                        <option value="CSC">Client Specific Codes</option> */}
                    </Input>
                    {this.state.couponType === "SC" && <Input
                        type="text"
                        label="Code"
                        onChange={(value) => this.setState({ couponCode:value, singleCodeNameError: "" })}
                        value={this.state.couponCode}
                        error={this.state.singleCodeNameError}
                        onEnter={this.onSaveOffer}
                    />}
                </React.Fragment>}
                {(this.state.couponType === "AG" || this.state.couponType === "CAG") &&
                <div className="row" >
                    <div className="col-md-6">
                        <Input
                            type="text"
                            label="Code Prefix"
                            onChange={(value) => this.setState({ codePrefix:value, codePrefixError: "" })}
                            value={this.state.codePrefix}
                            error={this.state.codePrefixError}
                            onEnter={this.onSaveOffer}
                        />
                    </div>
                    {this.state.couponType === "AG" && <div className="col-md-6">
                        <Input
                            type="number"
                            label="No of Coupons"
                            disabled={this.state.key !== "" && this.state.couponType === "AG"}
                            onChange={(value) => this.setState({ noOfCoupons:value, couponsNoError: "" })}
                            value={this.state.noOfCoupons}
                            error={this.state.couponsNoError}
                            onEnter={this.onSaveOffer}
                        />
                    </div>}
                    {(this.state.noOfCoupons && this.state.autoGeneratedCodes.length === 0) && <div className="col-md-2" style={{ marginBottom: "10px" }}>
                            <button type="submit" 
                                className="btn btn-flat btn-md btn-primary"
                                onClick={this.onGenerateClick}> Generate</button>
                    </div>}
                    {this.state.autoGeneratedCodes.length > 0 && <div className="row" style={{ marginBottom: "10px" }}> <div className="col-md-12">
                            <label>&nbsp;&nbsp;Coupon Code(s) ({this.state.autoGeneratedCodes.length})&nbsp;
                                        <Confirm
                                            onConfirm={() => this.setState({ autoGeneratedCodes: [] })}
                                            body="Are you sure you would like to remove uploaded code(s)?"
                                            confirmText="Yes"
                                            title="Coupon Code(s) Delete">
                                            <button
                                                disabled={(this.state.key !== "" && this.state.couponType === "AG")}
                                                className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                        </Confirm>
                            </label>
                        </div>  
                    </div>}
                
                    {this.state.couponType === "AG" && this.state.autoGeneratedCodes.length > 0 && <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-md-10">
                            <div style={{ height: "180px", overflowY: "scroll" }} className="scroll2">
                                <table className="table table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>Coupon Code(s)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.autoGeneratedCodes.map((coupon, index) =>
                                            <tr>
                                                <td>{coupon.CouponCode}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    </div>}
                    {this.state.couponType === "UC" &&
                        <div style={{ marginBottom: "10px" }}>
                            <div className="row">
                                {this.state.uploadedCodes.length > 0 && <div className="col-md-12">
                                    <label>&nbsp;&nbsp;Coupon Code(s) ({this.state.uploadedCodes.length})&nbsp;
                                    <Confirm
                                            onConfirm={() => this.onDeleteMultipleCodeUpload()}
                                            body="Are you sure you would like to remove uploaded code(s)?"
                                            confirmText="Yes"
                                            title="Coupon Code(s) Delete">
                                            <button
                                                disabled={this.state.key !== "" && this.state.couponType === "UC"}
                                                className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                        </Confirm>
                                    </label>
                                </div>}
                                {this.state.uploadedCodes.length === 0 &&
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-flat btn-md btn-default pull-left"
                                            onClick={() => this.setState({
                                                showImport: true
                                            })}
                                        >Upload Code(s)</button>
                                    </div>}
                            </div>
                            {this.state.uploadedCodes.length > 0 && <div className="row">
                                <div className="col-md-10">
                                    <div style={{ height: "180px", overflowY: "scroll", margin: "10px" }} className="scroll2">
                                        <table className="table table-bordered" >
                                            <thead>
                                                <tr>
                                                    <th>Coupon Code(s) </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.uploadedCodes.map((coupon, index) =>
                                                    <tr>
                                                        <td>{coupon.CouponCode}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>}
                    </div>}
                    {this.state.couponType === "CSC" && <div style={{ marginBottom: "10px" }}>
                        <div className="row" style={{ marginBottom: "10px" }}>
                            {this.state.uploadedClientCodes.length > 0 && <div className="col-md-12">
                                <label>&nbsp;&nbsp;Coupon Code(s) ({this.state.uploadedClientCodes.length})&nbsp;
                            <Confirm
                                        onConfirm={() => this.setState({ uploadedClientCodes: [] })}
                                        body="Are you sure you would like to remove uploaded code(s)?"
                                        confirmText="Yes"
                                        title="Coupon Code(s) Delete">
                                        <button
                                            disabled={(this.state.key !== "" && this.state.couponType === "CSC")}
                                            className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                    </Confirm>
                                </label>
                            </div>}
                            {this.state.uploadedClientCodes.length === 0 &&
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-flat btn-md btn-default pull-left"
                                        onClick={() => this.setState({
                                            showClientSpecificImport: true
                                        })}
                                    >Upload Code(s)</button>
                                </div>}
                        </div>
                        {this.state.uploadedClientCodes.length > 0 &&
                            <div className="row">
                                <div className="col-md-10">
                                    <div style={{ height: "180px", overflowY: "scroll", margin: "10px" }} className="scroll2">
                                        <table className="table table-bordered" >
                                            <thead>
                                                <tr>
                                                    <th>Coupon Code(s) </th>
                                                    <th>Customer Name </th>
                                                    <th>Phone No</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.uploadedClientCodes.map((coupon, index) =>
                                                    <tr>
                                                        <td>{coupon.CouponCode}
                                                        </td>
                                                        <td>{coupon.CustomerName}
                                                        </td>
                                                        <td>{coupon.PhoneNo}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>}
                    </div>}
                

                <Input
                    type="select"
                    label= "Specific Day"
                    value={this.state.specificDaySelected}
                    onChange={(value) => { this.setState({ specificDaySelected: value, specificDaySelectedError: "" }) }}
                    error={this.state.specificDaySelectedError}
                >
                     <option>Select Day</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </Input>
                {this.state.specificDaySelected === "Yes" && <React.Fragment>
                    <Input
                        type="switch"
                        label="Sun"
                        value={this.state.sundaySelected}
                        onChange={(value) => { this.setState({ sundaySelected: value, specificDaySelectedError: "" }) }} 
                    />
                    <Input
                        type="switch"
                        label="Mon"
                        value={this.state.mondaySelected}
                        onChange={(value) => { this.setState({ mondaySelected: value, specificDaySelectedError: "" }) }} 
                    />
                    <Input
                        type="switch"
                        label="Tue"
                        value={this.state.tuesdaySelected}
                        onChange={(value) => { this.setState({ tuesdaySelected: value, specificDaySelectedError: "" }) }} 
                    />
                    <Input
                        type="switch"
                        label="Wed"
                        value={this.state.wednesdaySelected}
                        onChange={(value) => { this.setState({ wednesdaySelected: value, specificDaySelectedError: "" }) }} 
                    />
                    <Input
                        type="switch"
                        label="Thu"
                        value={this.state.thursdaySelected}
                        onChange={(value) => { this.setState({ thursdaySelected: value, specificDaySelectedError: "" }) }} 
                    />
                    <Input
                        type="switch"
                        label="Fri"
                        value={this.state.fridaySelected}
                        onChange={(value) => { this.setState({ fridaySelected: value, specificDaySelectedError: "" }) }} 
                    />
                    <Input
                        type="switch"
                        label="Sat"
                        value={this.state.saturdaySelected}
                        onChange={(value) => { this.setState({ saturdaySelected: value, specificDaySelectedError: "" }) }} 
                    />

                </React.Fragment>}
                <Input
                    type="select"
                    label= "Specific Time"
                    value={this.state.specificTimeSelected}
                    onChange={(value) => { this.setState({ specificTimeSelected: value, specificTimeSelectedError: "" }) }}
                    error={this.state.specificTimeSelectedError}
                >
                    <option>Select Time</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </Input>
                {this.state.specificTimeSelected === "Yes" && <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="timepicker"
                                    label="From"
                                    value={this.state.inTime}
                                    onChange={(value) => this.setState({ inTime: value, inTimeError: '' })} 
                                    error={this.state.inTimeError}
                                />
                            </div>
                            <div className="col-md-12">
                                <Input
                                    type="timepicker"
                                    label="From"
                                    value={this.state.outTime}
                                    onChange={(value) => this.setState({ outTime: value, outTimeError: '' })} 
                                    error={this.state.outTimeError}
                                />
                            </div>
                </div>}
                <Input
                    type="switch"
                    label="Specific Stores"
                    value={this.state.specificStoreSelected}
                    onChange={(value) => { this.setState({ specificStoreSelected: value, allStoresSelected: !value, storeSelectionError: "" }) }} 
                    error={this.state.storeSelectionError}
                />
                {this.state.specificStoreSelected === true &&
                <div className={this.state.storeNameError !== "" ? "form-group has-error" : "form-group "}>
                    <CreatableSelect
                        defaultValue={this.state.associatedStores}
                        options={this.state.stores}
                        onChange={this.handleOnChange}
                        isMulti
                    />
                    {this.state.storeNameError !== "" && <span className="help-block">{this.state.storeNameError}</span>}
                </div>}
                <div className={this.state.typeError !== "" ? "form-group has-error" : "form-group "}>
                    <label>Type</label>
                    <select className="form-control" value={this.state.offerTypeID} onKeyPress={this.handleKeyPress}
                        onChange={(evt) => this.setState({
                            offerTypeID: evt.target.value, offerType: evt.target.options[evt.target.selectedIndex].text, typeError: "", purchaseGroup: "", freeGroup: "", purchaseQty: "",
                            freeQty: "", invoiceAmount: "", discount: "", discountValue: "", offerGroup: ""
                        })}>
                        <option>Select</option>
                        <option value="buyxgety">Buy X get Y free</option>
                        <option value="buyxqtygetydiscountpercentage">Buy X get Y discount percentage</option>
                        <option value="invoicediscount">Discount on invoice amount</option>
                        {/* <option value="invoicefreeitem">Free item on invoice amount</option>
                        <option value="offergroupdiscountitem">Discount on offer grouping qty(Discount on item)</option>
                        <option value="offergroupdiscount">Discount on offer grouping amount</option>
                        <option value="offergroupqtyfree">Free item on offer grouping qty</option>
                        <option value="offergroupamtfree">Free item on offer grouping amount</option> */}
                    </select>
                    {this.state.typeError !== "" && <span className="help-block">{this.state.typeError}</span>}
                </div>
                {this.state.offerTypeID === "buyxgety" && <Input 
                    type="switch" 
                    label="Spread Free Amount Equally To All Offer Products"
                    value={this.state.spreadDiscount} 
                    onChange={(value) => this.setState({ spreadDiscount:value})} 
                />}
                {(this.state.offerTypeID === "buyxgety" || this.state.offerTypeID === "buyxqtygetydiscountpercentage") && <React.Fragment>
                    <Input
                        type="select"
                        label="Purchase Group"
                        value={this.state.purchaseGroupID}
                        onChange={(value) => this.setState({ purchaseGroupID: value, 
                            purchaseGroup: this.props.config.ProductTags.filter(f=> f.key ===value)[0].Name, 
                            op1PurchaseError: "" })}
                        error={this.state.op1PurchaseError}
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input  
                        type="number"
                        label="Purchase Qty"
                        onChange={(value) => this.setState({ 
                            purchaseQty:value, 
                            QtyError: "", 
                            typeError: "" })}
                        value={this.state.purchaseQty}
                        error = {this.state.QtyError}
                    />
                    {this.state.offerTypeID === "buyxgety" && 
                    <Input   
                        type="select"
                        label="Free Group"
                        value={this.state.freeGroupID}
                        onChange={(value) => this.setState({ 
                            freeGroupID: value, 
                            freeGroup: this.props.config.ProductTags.filter(f=> f.key === value)[0].Name, 
                            freeError: "" })}
                        error={this.state.freeError}
                        >
                            <option value="">Select Product group</option>
                            {this.props.config.ProductTags.map((productTag) =>
                                <option value={productTag.key}>{productTag.Name}</option>
                            )}
                    </Input>}
                    {this.state.offerTypeID === "buyxqtygetydiscountpercentage" && 
                    <Input   
                        type="select"
                        label="Discounted Group"
                        value={this.state.freeGroupID}
                        onChange={(value) => this.setState({ 
                            freeGroupID: value, 
                            freeGroup: this.props.config.ProductTags.filter(f=> f.key === value)[0].Name, 
                            freeError: "" })}
                        error={this.state.freeError}
                        >
                            <option value="">Select Product group</option>
                            {this.props.config.ProductTags.map((productTag) =>
                                <option value={productTag.key}>{productTag.Name}</option>
                            )}
                    </Input>}
                    {this.state.offerTypeID === "buyxgety" && <Input
                        type="number"
                        label="Free Qty"
                        onChange={(value) => this.setState({ freeQty: value, QtyError: "", typeError: "" })}
                        value={this.state.freeQty}
                        error={this.state.QtyError}
                    />}
                    {this.state.offerTypeID === "buyxqtygetydiscountpercentage" && <Input
                        type="number"
                        label="Discount Percentage"
                        onChange={(value) => this.setState({ discountPercentage: value, discountPercentageError: "", typeError: "" })}
                        value={this.state.discountPercentage} 
                        error= {this.state.discountPercentageError}
                    />}
                    
                </React.Fragment>}
                {this.state.offerTypeID === "invoicediscount" && <React.Fragment>
                    <Input  
                        type="number"
                        label="Invoice Amount"
                        onChange={(value) => this.setState({ invoiceAmount: value, invoiceAmountError: "" })}
                        value={this.state.invoiceAmount}
                        error= {this.state.invoiceAmountError}
                    />
                    <Input
                        type="select"
                        label="Discount Type"
                        value={this.state.discount} 
                        onChange={(value) => this.setState({ discount: value, discountInError: "" })}
                        error={this.state.discountInError}
                    >
                        <option>Select</option>
                        <option value="Percentage">Percentage</option>
                        <option value="Amount">Amount</option>
                        <option value="GetXFree">Get X free</option>
                    </Input>
                    {(this.state.discount === "Percentage" || this.state.discount === "Amount") && <Input  
                        type="number"
                        label="Value"
                        onChange={(value) => this.setState({ discountValue:value, valueError: "" })}
                        value={this.state.discountValue}
                        error= {this.state.valueError}
                    />}
                    {this.state.discount === "GetXFree" && <React.Fragment> 
                        <Input  
                            type="select"
                            label="Free Group"
                            value={this.state.freeGroupID}
                            onChange={(value) => this.setState({ freeGroupID: value, 
                                freeGroup: this.props.config.ProductTags.filter(f=> f.key === value)[0].Name, 
                                freeError: "" 
                            })}
                            error={this.state.freeError}
                            >
                                <option value="">Select Product group</option>
                                {this.props.config.ProductTags.map((productTag) =>
                                    <option value={productTag.key}>{productTag.Name}</option>
                                )}
                        </Input>
                        <Input   
                            type="number"
                            label="Free Qty"
                            onChange={(value) => this.setState({ freeQty:value, QtyError: "", typeError: "" })}
                            value={this.state.freeQty} 
                            error={this.state.QtyError}
                        />
                        <Input   
                            type="number"
                            label="Discount Percentage"
                            onChange={(value) => this.setState({ getXFreeDiscountPercentage:value, getXFreeDiscountPercentageError: ""})}
                            value={this.state.getXFreeDiscountPercentage} 
                            error={this.state.getXFreeDiscountPercentageError}
                        />

                    </React.Fragment>}
                </React.Fragment>}
                {this.state.offerTypeID === "offergroupdiscountitem" && <React.Fragment>
                    <Input
                        type="select"
                        label="Offer Grouping"
                        value={this.state.offerGroupID}
                        onChange={(value) => this.setState({ offerGroupID: value, 
                            offerGroup: this.props.config.ProductTags.filter(f=> f.key ===value)[0].Name, 
                            offerGroupingError: "" 
                        })}
                        error={this.state.offerGroupingError}    
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input  
                        type="number"
                        label="Qty"
                        onChange={(value) => this.setState({ purchaseQty:value, QtyError: "" })}
                        value={this.state.purchaseQty}
                        error={this.state.QtyError}
                    />
                    <Input  
                        type="select"
                        value={this.state.discount}
                        onChange={(value) => this.setState({ discount: value, discountInError: "" })}
                        error = {this.state.discountInError}
                    >
                         <option>Select</option>
                        <option value="Percentage">Percentage</option>
                        <option value="Amount">Amount</option>
                    </Input>
                    <Input  
                        type="number"
                        label="Value"
                        onChange={(value) => this.setState({ discountValue: value, valueError: "" })}
                        value={this.state.discountValue}
                        error={this.state.valueError}
                    />
                </React.Fragment>}
                {this.state.offerTypeID === "offergroupdiscount" && <React.Fragment>
                    <Input  
                        type="select"
                        label="Offer Grouping"
                        value={this.state.offerGroupID}
                        onChange={(value) => this.setState({ 
                            offerGroupID: value, 
                            offerGroup: this.props.config.ProductTags.filter(f=> f.key === value)[0].Name, 
                            offerGroupingError: "" 
                        })}
                        error={this.state.offerGroupingError}    
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input
                        type="number"
                        label="Qty"
                        onChange={(value) => this.setState({ purchaseQty: value, QtyError: "" })}
                        value={this.state.purchaseQty}
                        error = {this.state.QtyError}
                    />
                    <Input
                        type="select"
                        label="Discount Type"
                        value={this.state.discount}
                        onChange={(value) => this.setState({ discount: value, discountInError: "" })}
                        error={this.state.discountInError}
                        >
                            <option>Select</option>
                            <option value="Percentage">Percentage</option>
                            <option value="Amount">Amount</option>
                    </Input>
                    <Input
                        type="number"
                        label="Value"
                        onChange={(value) => this.setState({ discountValue:value, valueError: "" })}
                        value={this.state.discountValue} 
                        error={this.state.valueError}    
                    />
                </React.Fragment>}
                {this.state.offerTypeID === "offergroupqtyfree" && <React.Fragment>
                    <Input
                        type="select"
                        label="Offer Grouping"
                        value={this.state.offerGroupID}
                        onChange={(value) => this.setState({ 
                            offerGroupID: value,
                            offerGroup: this.props.config.ProductTags.filter(f=>f.key ===value)[0].Name, 
                            offerGroupingError: "" 
                        })}
                        error={this.state.offerGroupingError}
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input  
                        type="number"
                        label="Qty"
                        onChange={(value) => this.setState({ purchaseQty:value, QtyError: "" })}
                        value={this.state.purchaseQty} 
                        error = {this.state.QtyError}
                    />
                    <Input
                        type="select"
                        label="Free Offer Grouping"
                        value={this.state.freeGroupID}
                        onChange={(value) => this.setState({ 
                            freeGroupID: value,
                            freeGroup: this.props.config.ProductTags.filter(f=>f.key ===value)[0].Name, 
                            freeError: "" 
                        })}
                        error={this.state.freeError}
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input  
                        type="number"
                        label="Qty"
                        onChange={(value) => this.setState({ freeQty:value, QtyError: "" })}
                        value={this.state.freeQty} 
                        error = {this.state.QtyError}
                    />
                    <Input
                        type="select"
                        label="Discount Type"
                        value={this.state.discount}
                        onChange={(value) => this.setState({ discount: value, discountInError: "" })}
                        error={this.state.discountInError}
                        >
                            <option>Select</option>
                            <option value="Percentage">Percentage</option>
                            <option value="Amount">Amount</option>
                    </Input>
                    <Input
                        type="number"
                        label="Value"
                        onChange={(value) => this.setState({ discountValue:value, valueError: "" })}
                        value={this.state.discountValue} 
                        error={this.state.valueError}    
                    />
                </React.Fragment>}
                {this.state.offerTypeID === "offergroupamtfree" && <React.Fragment>
                    <Input
                        type="select"
                        label="Offer Grouping"
                        value={this.state.offerGroupID}
                        onChange={(value) => this.setState({ 
                            offerGroupID: value,
                            offerGroup: this.props.config.ProductTags.filter(f=>f.key ===value)[0].Name, 
                            offerGroupingError: "" 
                        })}
                        error={this.state.offerGroupingError}
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input
                        type="number"
                        label="Amount"
                        onChange={(value) => this.setState({ discountValue: value, valueError: "" })}
                        value={this.state.discountValue} 
                        error={this.state.valueError}
                    />
                   <Input
                        type="select"
                        label="Free Offer Grouping"
                        value={this.state.freeGroupID}
                        onChange={(value) => this.setState({ 
                            freeGroupID: value,
                            freeGroup: this.props.config.ProductTags.filter(f=>f.key ===value)[0].Name, 
                            freeError: "" 
                        })}
                        error={this.state.freeError}
                    >
                        <option value="">Select Product group</option>
                        {this.props.config.ProductTags.map((productTag) =>
                            <option value={productTag.key}>{productTag.Name}</option>
                        )}
                    </Input>
                    <Input
                        type="number"
                        label="Qty"
                        onChange={(value) => this.setState({ freeQty:value, QtyError: "" })}
                        value={this.state.freeQty} 
                        error={this.state.QtyError}    
                    />
                </React.Fragment>}
            </Form>
            <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                <div style={{ width: "500px" }}>
                    <ImportMaster   {...this.props}
                        importType="multiplecouponcodes"
                        onClose={() => this.setState({ showImport: false })}
                        onImportCompletion={(records) => this.setState({ uploadedCodes: records, showImport: false })}
                    />
                </div>
            </Modal>
            <Modal open={this.state.showClientSpecificImport} onClose={() => this.setState({ showClientSpecificImport: false })} center>
                <div style={{ width: "500px" }}>
                    <ImportMaster   {...this.props}
                        importType="multipleclientcouponcodes"
                        onClose={() => this.setState({ showClientSpecificImport: false })}
                        onImportCompletion={(records) => this.setState({ uploadedClientCodes: records, showClientSpecificImport: false })}
                    />
                </div>
            </Modal>
        </React.Fragment>
    }
}

export default AddEditOffer