import React, { Component } from "react";
import ReactMustache from "react-mustache";
import ReactDOM from "react-dom";
import htmlToImage from "html-to-image";
import Window from "../2/Controls/Window";

class Print extends Component {
  constructor() {
    super();
    this.state = {
      width: "100%",
      // sale:null,
      // printConfig:null,
      // template:'',
      // printData:null
    };
  }

  async componentDidMount() {
    //let sale = this.props.printData.Sale
    // this.props.printData.Sale.LineItems.map((lineItem, index) => {
    //     this.props.printData.Sale.LineItems[index].SrNo = index +1
    // })
    // this.setState({printData:this.props.printData})
    console.log("printdata", this.props.printData);
    // this.setState({updateState:true})
    let printHeight = 0;

    // console.log("props",this.props.printData.PrintConfig.PrintSize);
    if (this.props.printData.PrintConfig.PrintSize === "2 inch") {
      this.setState({ width: "250px" });
    }
    if (
      this.props.printData.PrintConfig.hasOwnProperty("PrintHeight") &&
      this.props.printData.PrintConfig.PrintHeight !== 0
    ) {
      printHeight = this.props.printData.PrintConfig.PrintHeight;
    } else {
      if (this.props.printData.PrintConfig.PrintSize === "A4") {
        printHeight = 1000;
      } else if (this.props.printData.PrintConfig.PrintSize === "A5") {
        if (
          this.props.printData.PrintConfig.hasOwnProperty("PrintOrientation") &&
          this.props.printData.PrintConfig.PrintOrientation === "landscape"
        ) {
          printHeight = 600;
        } else {
          printHeight = 720;
        }
      } else {
        printHeight = 0;
      }
    }
    //    console.log('printHeight',printHeight)
    let rowCount = 0;
    if (this.props.printData.hasOwnProperty("Sale")) {
      //    alert(printHeight)
      rowCount = this.props.printData.Sale.LineItems.length;
    }
    if (this.props.printData.hasOwnProperty("Inventory")) {
      //    alert(printHeight)
      rowCount = this.props.printData.Inventory.LineItems.length;
    }
    console.log("rowCount", rowCount);
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.innerHTML =
      "var footer = document.getElementById('footer'); if(footer!==null){ var footerHeight = document.getElementById('footer').offsetHeight;var footerTop =document.getElementById('footer').offsetTop;var trheight = " +
      printHeight +
      " - (footerTop + footerHeight);document.getElementById('" +
      rowCount +
      "').style.height = trheight + 'px';  } ";
    console.log(" s.innerHTML", s.innerHTML);
    this.instance.appendChild(s);

    this.setState({ updateState: true });
  }

  async componentDidUpdate() {
    // console.log('this.props.sendWhatsAppChecked', this.props.sendWhatsAppChecked)
    // var footerTop = document.getElementById('printtable').offsetTop;
    // alert('footerTop', footerTop)
    // var tableheight = document.getElementById('printtable').offsetTop;
    // alert('tableheight', tableheight)
    //     var top = ReactDOM.findDOMNode("footer")
    //   .getBoundingClientRect().top
    //   alert('top', top)
    // console.log('this.props.printChecked', this.props.printChecked)
    // alert('print - componentDidUpdate')
    let HtmlBody = ReactDOM.findDOMNode(this).innerHTML;
    if (
      this.props.printData.PrintConfig.PrintSize === "3 inch" &&
      !this.props.isMobileApp()
    ) {
      HtmlBody = "<div style='width:300px'>" + HtmlBody + "</div>";
      // console.log("HtmlBody", HtmlBody)
    }
    if (this.props.printChecked) {
      // alert('printChecked')
      // if(this.props.isMobileApp())
      // {
      // alert('cordova')
      // window.plugins.printer.print();
      // window.cordova.plugins.mswipe.cardSale({
      // window.printer.print()
      // alert('window.cordova')
      // if(this.props.user.cashRegister.Printer.toLowerCase()==="wisepos")
      // {
      //     let node = document.getElementById('print')
      //     // let img = new Image()
      //     let dataUrl = await htmlToImage.toPng(node)
      //     // img.src = dataUrl
      //     let parts = dataUrl.split(";base64,");
      //     let base64 = parts[1];
      //     window.cordova.plugins.mswipe.print([{"PrintData":base64}],
      //         (result) => {console.log('Mswipe Print Completed ' + result);},
      //         (error) => {alert("MSwipe Print Error: "+error);}
      //     )
      // }
      // else
      // {
      // alert(' window.cordova.plugins.printer.print(HtmlBody)')
      // window.cordova.plugins.printer.print(HtmlBody)
      // }
      // }
      // else
      // {
      // alert('not window.cordova')
      // window.print()
      // }
      // console.log("HtmlBody", HtmlBody)

      if (this.props.isMobileApp() && window.cordova) {
        window.cordova.plugins.printer.print(HtmlBody);
      } else if (this.props.isElectron()) {
        const ipcRenderer = window.require("electron").ipcRenderer;
        ipcRenderer.send("print", this.props.kotPrinter);
      } else if (this.props.isMobileApp()) {
        //donot print
      } else {
        window.print();
      }
      // window.print()
      //https://www.npmjs.com/package/html-to-image
      // var node = document.getElementById('print');
      // htmlToImage.toPng(node)
      // .then(function (dataUrl) {
      //     var img = new Image();
      //     img.src = dataUrl;
      //     console.log(img)
      //     // document.body.appendChild(img);
      // })
      // .catch(function (error) {
      //     console.error('oops, something went wrong!', error);
      // });
      // let node = document.getElementById('print')
      // let img = new Image()
      // let dataUrl = await htmlToImage.toPng(node)
      // img.src = dataUrl
      // let parts = dataUrl.split(";base64,");
      // let base64 = parts[1];
      // let decodedData = window.atob(base64)
      // document.body.appendChild(img)
      // console.log(base64)
    }
    if (this.props.sendEmailChecked) {
      if (this.props.printData.hasOwnProperty("Sale")) {
        if (this.props.printData.Sale.EmailID.trim() === "") {
          return;
        }

        let email = {
          RegistrationID: this.props.printData.Sale.RegistrationID,
          StoreID: this.props.printData.Sale.Store.key,
          UserID: this.props.printData.Sale.ActionByUID,
          To: this.props.printData.Sale.EmailID,
          From: "noreply@posible.in",
          Subject: "Invoice #" + this.props.printData.Sale.TransactionNo,
          TextBody: "Invoice",
          HTMLBody: HtmlBody,
        };
        this.props.sendEmail(email);
        this.props.getToastr("Email sent");
      } else if (this.props.printData.hasOwnProperty("Inventory")) {
        if (this.props.printData.Inventory.EmailID.trim() === "") {
          return;
        }

        let email = {
          RegistrationID: this.props.printData.Inventory.RegistrationID,
          StoreID: this.props.printData.Inventory.Store.key,
          UserID: this.props.printData.Inventory.ActionByUID,
          To: this.props.printData.Inventory.EmailID,
          From: "noreply@posible.in",
          Subject:
            this.props.printData.Inventory.TransactionType.TransactionType +
            " #" +
            this.props.printData.Inventory.TransactionNo,
          TextBody: "Invoice",
          HTMLBody: HtmlBody,
        };
        this.props.sendEmail(email);
        this.props.getToastr("Email sent");
      }
    }
    if (this.props.sendSMSChecked) {
      let sale = {
        key: this.props.printData.Sale.key,
        TransactionNo: this.props.printData.Sale.TransactionNo,
        BillAmount: this.props.printData.Sale.BillAmount,
        BalanceAmount: this.props.printData.Sale.BalanceAmount,
        BillPaidAmount: this.props.printData.Sale.BillPaidAmount,
        TransactionType: this.props.printData.Sale.TransactionType,
        WalletBalance: this.props.printData.Sale.WalletBalance,
        Store: this.props.printData.Sale.Store,
        Customer: this.props.printData.Sale.Customer,
      };
      let sms = {
        RegistrationID: this.props.printData.Sale.RegistrationID,
        StoreID: this.props.printData.Sale.Store.key,
        UserID: this.props.printData.Sale.ActionByUID,
        Module: "sales",
        TransactionType: this.props.printData.Sale.TransactionType
          .TransactionType,
        TransactionData: sale,
        MobileNo: this.props.printData.Sale.SMSNo,
      };
      // console.log("sms", sms);
      this.props.sendSMS(sms);
    }
    if (this.props.sendWhatsAppChecked) {
      let sale = {
        key: this.props.printData.Sale.key,
        TransactionNo: this.props.printData.Sale.TransactionNo,
        BillAmount: this.props.printData.Sale.BillAmount,
        BalanceAmount: this.props.printData.Sale.BalanceAmount,
        BillPaidAmount: this.props.printData.Sale.BillPaidAmount,
        TransactionType: this.props.printData.Sale.TransactionType,
        WalletBalance: this.props.printData.Sale.WalletBalance,
        Store: this.props.printData.Sale.Store,
        Customer: this.props.printData.Sale.Customer,
      };
      let phoneNo = this.props.printData.Sale.SMSNo;
      let invoiceURL = "",
        message = "";
      if (
        this.props.registration.registration &&
        this.props.registration.registration.hasOwnProperty(
          "SendInvoiceLink"
        ) &&
        this.props.registration.registration.SendInvoiceLink
      ) {
        // invoiceURL = "https://app.posible.in/s/" + sale.key;
        invoiceURL = "https://i.posible.in/" + sale.key;
      }

      // if (invoiceURL !== '')
      //     message = "Thank you for your purchase worth Rs. " + sale.BillAmount + " of Transaction No: " + sale.TransactionNo + " from " + sale.Store.StoreName + ". Click here " + invoiceURL + " details"
      // else
      //     message = "Thank you for your purchase worth Rs. " + sale.BillAmount + " of Transaction No: " + sale.TransactionNo + " from " + sale.Store.StoreName + "."

      message = "Hi " + (sale.Customer ? sale.Customer.FirstName : "") + ",%0A";
      message = message + "%0aThanks for visiting us today! ";
      if (invoiceURL !== "") {
        message = message + "%0aHere is your invoice:" + invoiceURL;
      } else {
        message =
          message +
          "%0aThank you for your purchase worth Rs. " +
          sale.BillAmount +
          " of Transaction No: " +
          sale.TransactionNo;
      }
      if (
        this.props.user.store.hasOwnProperty("GoogleReviewLink") &&
        this.props.user.store.GoogleReviewLink !== ""
      ) {
        message =
          message +
          "%0aPlease Rate us on Google:" +
          this.props.user.store.GoogleReviewLink;
      }
      message = message + "%0a%0aSee you soon! ";
      message = message + "%0aFrom " + sale.Store.StoreName;

      // window.open('https://wa.me/91' + sale.Customer.PhoneNo.trim() + '/?text=' + encodeURI(message), true);\
      console.log("message", message);
      window.open(
        "https://wa.me/91" + phoneNo.trim() + "/?text=" + message,
        true
      );

      // window.open('https://wa.me/91' + phoneNo.trim() + '/?text=' + encodeURI(message), true);
    }
    // if(!window.cordova)
    // {//in android print if onclose is called then print windows show billing screen
    // this.props.onClose()
    // }
    if (this.props.isElectron()) {
      setTimeout(
        function() {
          this.props.onClose();
        }.bind(this),
        2000
      );
    } else if (this.props.isMobileApp()) {
      // setTimeout(function () {
      //     this.props.onClose();
      // }.bind(this), 5000)
    } else {
      this.props.onClose();
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <button onClick={()=> {
                
                window.cordova.plugins.printer.print();
            
        }}
            >Print </button> */}
        {/* <button onClick={()=> {
                if(window.cordova)
                {
                   
                    var options = {
                        message: 'invoice', // not supported on some apps (Facebook, Instagram)
                        subject: 'the subject', // fi. for email
                        files: ['', ''], // an array of filenames either locally or remotely
                        url: 'https://www.website.com/foo/#bar?a=b',
                        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title,
                      };
                      
                      var onSuccess = function(result) {
                        console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
                        console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
                      };
                      
                      var onError = function(msg) {
                        console.log("Sharing failed with message: " + msg);
                      };
                      window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
                }
            }}
                >Share</button> */}

        {this.props.isMobileApp() && (
          <Window
            header={"Print"}
            modal={false}
            onClose={() => this.props.onClose()}
            onButtonClick={(buttonType) => {}}
            buttons={[]}
          >
            <button className="no-print" onClick={() => window.print()}>
              Print
            </button>
            <div
              id="print"
              style={{ width: this.state.width }}
              ref={(el) => (this.instance = el)}
            >
              {this.props.printData !== null && (
                <ReactMustache
                  template={this.props.printData.PrintConfig.PrintTemplate}
                  data={this.props.printData}
                />
              )}
            </div>
          </Window>
        )}

        {!this.props.isMobileApp() && (
          <div
            id="print"
            style={{ width: this.state.width }}
            ref={(el) => (this.instance = el)}
          >
            {this.props.printData !== null && (
              <ReactMustache
                template={this.props.printData.PrintConfig.PrintTemplate}
                data={this.props.printData}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Print;
