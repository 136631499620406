import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';
import firebase from '../../firebase';

class GSTR1B2C extends Component {
    constructor() {
        super()
        this.state = {
            invoices: [],
            stores: [],
            storeList: [],
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            activePage: 1,
            itemsCountPerPage: 10000,
            totalItemsCount: 0,
            recordFrom: 0,
            loading: true,
            gstinCustomFieldNo:0,
            includeGSTImportColumnsOnly:false,
            years:[],
            months:[],
            selectedYear:0,
            selectedMonth:0,
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.loadInvoiceDetailReport = this.loadInvoiceDetailReport.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this)
        this.onYearChange = this.onYearChange.bind(this)
        this.getElasticData = this.getElasticData.bind(this)
        
    }

    componentDidMount() {
        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()
        // console.log('selectedMonth', selectedMonth)
        let years= this.props.getYears()
        let months = this.props.getMonths(selectedYear)
        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth)  
        let storeList = []
        storeList.push({ key: this.props.user.store.key })
        this.setState({storeID: this.props.user.store.key, storeList: storeList, years: years, months:months,selectedYear:selectedYear,selectedMonth:selectedMonth,searchTransactionFromDate:searchTransactionFromDate,  searchTransactionToDate:searchTransactionToDate})

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let gstinCustomFieldNo= 0
                if(customField.CustomField1Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=1
                }
                else if(customField.CustomField2Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=2
                }
                else if(customField.CustomField3Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=3
                }
                else if(customField.CustomField4Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=4
                }
                else if(customField.CustomField5Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=5
                }
                else if(customField.CustomField6Name.trim().toLowerCase() ==="gstin")
                {
                    gstinCustomFieldNo=6
                }
                this.setState({gstinCustomFieldNo:gstinCustomFieldNo})
            }

        })

        this.loadInvoiceDetailReport(storeList,
            this.state.recordFrom,
            this.state.itemsCountPerPage,
            this.state.searchTransactiontype,
            searchTransactionFromDate,
            searchTransactionToDate)
    }

    onYearChange(evt)
    {
        let selectedYear = evt.target.value
        let months = this.props.getMonths(selectedYear)
        let selectedMonth = months[months.length-1].Month
        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth)  
        this.setState({
            months:months,
            selectedYear:selectedYear,
            selectedMonth:selectedMonth,
            searchTransactionFromDate:searchTransactionFromDate,  
            searchTransactionToDate:searchTransactionToDate
        })
    }

    onMonthChange(evt)
    {
        let selectedMonth = evt.target.value
        let searchTransactionFromDate = this.getMonthStartDate(this.state.selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(this.state.selectedYear, selectedMonth)  
        this.setState({
            selectedMonth:selectedMonth,
            searchTransactionFromDate:searchTransactionFromDate,  
            searchTransactionToDate:searchTransactionToDate
        })
    }

    getMonthStartDate(selectedYear, selectedMonth)
    {
        return this.props.getStartDateOfMonth(selectedYear, selectedMonth)
    }

    getMonthEndDate(selectedYear, selectedMonth)
    {
        return this.props.getEndDateOfMonth(selectedYear, selectedMonth)
    }
    
    // async loadData(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate) {

    //     console.log('pawan')
    //     let result = await this.props.getInvoicesForGSTR1B2C(this.props.user.user.RegistrationID, storeList, from, size, searchTransactiontype,
    //         searchTransactionFromDate, searchTransactionToDate)

    //     console.log('result abc',result)
    //     let b2cRecords = [];     
    //     for (let index = 0; index < result.searchResult.length; index++) {
    //         const data = result.searchResult[index];
    //         if (!data.hasOwnProperty('TaxSummaryList')) {
    //             continue
    //         }
    //         let placeOFSupply= data.PlaceOfSupply
    //         if(data.TaxSummaryList)
    //         {
    //             let taxRate= 0 
    //             let cessAmount = 0 
    //             let taxAmount = 0 
    //             for (let l = 0; l < data.TaxSummaryList.length; l++) {
    //                 taxRate= 0;
    //                 cessAmount = 0 ;
    //                 taxAmount = 0;
    //                 data.TaxSummaryList[l].TaxComponentAmount.map(m=>{
    //                     if(m.TaxType==="CGST" || m.TaxType==="SGST")
    //                     {
    //                         taxAmount = Number(taxAmount) + m.TaxAmount;
    //                         taxRate = Number(m.TaxPercentage * 2)
    //                     }
    //                     else if(m.TaxType==="IGST")
    //                     {
    //                         taxAmount = Number(taxAmount) + m.TaxAmount;
    //                         taxRate = Number(m.TaxPercentage)
    //                     }
    //                     else if(m.TaxType==="CESS")
    //                     {
    //                         cessAmount = Number(cessAmount)+ Number(m.TaxAmount)
    //                     }
    //                 })
    //                 let recordFound = false;
    //                 for(let b=0;b<b2cRecords.length;b++){
    //                     if(b2cRecords[b].taxRate === taxRate && b2cRecords[b].placeOFSupply.trim() === placeOFSupply.trim()){
    //                         if(cessAmount > 0 && b2cRecords[b].cessAmount > 0){
    //                             recordFound= true;
    //                             // if(taxRate ===28){
    //                             //     console.log('cess amount', b2cRecords[b])
    //                             // }
    //                             // console.log('cess amount', b2cRecords[b])
    //                             b2cRecords[b].taxableAmount = Number( b2cRecords[b].taxableAmount) + Number(data.TaxSummaryList[l].TaxableAmount)
    //                             b2cRecords[b].taxAmount = Number(b2cRecords[b].taxAmount) + Number(taxAmount);
    //                             b2cRecords[b].cessAmount =Number(b2cRecords[b].cessAmount)  + Number(cessAmount) 
                  
    //                         }else if(cessAmount===0){
    //                             recordFound= true;
    //                             if(taxRate ===28){
    //                                 console.log('28 - no cess amount', data.TaxSummaryList[l])
    //                             }

    //                             b2cRecords[b].taxableAmount = Number( b2cRecords[b].taxableAmount) + Number(data.TaxSummaryList[l].TaxableAmount)
    //                             b2cRecords[b].taxAmount = Number(b2cRecords[b].taxAmount) + Number(taxAmount);
    //                             b2cRecords[b].cessAmount =Number(b2cRecords[b].cessAmount)  + Number(cessAmount) 
    //                         }
    //                     }
    //                 }
    //                 if(!recordFound){
    //                     b2cRecords.push({
    //                         placeOFSupply:placeOFSupply,
    //                         taxRate:taxRate, 
    //                         taxAmount: Number(taxAmount),
    //                         cessAmount:cessAmount, 
    //                         taxableAmount:Number(data.TaxSummaryList[l].TaxableAmount)
    //                         })
    //                 }
    //                 // if(b2cRecords.filter(f=> f.taxRate === taxRate 
    //                 //     && f.placeOFSupply.trim() === placeOFSupply.trim()
    //                 //     && (cessAmount > 0 && f.cessAmount > 0)
    //                 // ).length> 0)
    //                 // {
    //                 //     b2cRecords.map(k=>{
    //                 //         if(k.taxRate===taxRate && k.placeOFSupply.trim() === placeOFSupply.trim() && (cessAmount > 0 && k.cessAmount > 0))
    //                 //         {
    //                 //             k.taxableAmount = Number( k.taxableAmount) + Number(data.TaxSummaryList[l].TaxableAmount)
    //                 //             k.taxAmount = Number(k.taxAmount) + Number(taxAmount);
    //                 //             k.cessAmount =Number(k.cessAmount)  + Number(cessAmount) 
    //                 //         }
    //                 //     })
    //                 // }
    //                 // else
    //                 // {
    //                 //     b2cRecords.push({
    //                 //         placeOFSupply:placeOFSupply,
    //                 //         taxRate:taxRate, 
    //                 //         taxAmount: Number(taxAmount),
    //                 //         cessAmount:cessAmount, 
    //                 //         taxableAmount:Number(data.TaxSummaryList[l].TaxableAmount)
    //                 //         })
    //                 // }
    //                 // if(taxRate === 28){
    //                 //     console.log('data.TaxSummaryList[l].TaxComponentAmount',data.TaxSummaryList[l].TaxComponentAmount)
    //                 // }
    //             }
    //         }
    //     }
    //     console.log('b2cRecords',b2cRecords)
    //     let b2cDataRecords =[]
    //     for(let m=0;m<b2cRecords.length;m++)
    //     {
    //         let b2cData= {}
    //         b2cData["Type"] = "OE"
    //         b2cData["Place Of Supply"] = b2cRecords[m].placeOFSupply
    //         b2cData["Applicable % of Tax Rate"] = ""
    //         b2cData["Rate"] = Number(b2cRecords[m].taxRate)
    //         b2cData["Taxable Value"] = Number(b2cRecords[m].taxableAmount).toFixed(2)
    //         b2cData["Tax Amount"] = Number(b2cRecords[m].taxAmount).toFixed(2)
    //         b2cData["Cess Amount"] = Number(b2cRecords[m].cessAmount).toFixed(2)
    //         b2cData["E-Commerce GSTIN"] = ""
    //         b2cDataRecords.push(b2cData);
    //     }
    //     console.log('b2cDataRecords',b2cDataRecords)
    //     return b2cDataRecords
    // }

    async getElasticData(registrationID, searchStores,searchTransactionType, searchTransactionFromDate, searchTransactionToDate){
        let searchParameters = {
            "_source": ["TaxSummaryList","Customer","PlaceOfSupply","TransactionNo","TransactionDate", "BillAmount","BillTaxAmount", "LineItems"], 
            "from": 0,
            "size": 100,
            "track_total_hits": true,
            "query": {
              "bool": {
                "must": [
                  {
                    "term": {
                      "RegistrationID": registrationID
                    }
                  },
                  {
                    "range": {
                      "TransactionDate": {
                        "lte": searchTransactionToDate,
                        "gte": searchTransactionFromDate,
                        "format": "yyyy-MM-dd"
                      }
                    }
                  }
                ],
                "must_not": [
                  {
                    "exists": {
                      "field": "CancellationReason"
                    }
                  }
                ],
                "should":[],"minimum_should_match": 1,
                "filter": {
                  "bool": {
                    "should": [
        
                    ]
                  }
                }
              }
            },
            "sort": [
              {
                "TransactionDate": {
                  "order": "desc"
                }
              }
            ]
          }
          if (searchTransactionType.trim() !== "") {
            searchParameters.query.bool.filter.bool.should.push({ "regexp": { "TransactionType.key": searchTransactionType.trim().toLowerCase() } })
          }
          for (let i = 0; i < searchStores.length; i++) {
            const store = searchStores[i];
            
            searchParameters.query.bool.should.push({
              "term": {
                "Store.key.keyword": store.key
              }
            })
          }
        
        //   console.log('searchParameters',JSON.stringify(searchParameters))
        // finalAPI= "scrollData";
        // finalAPIMessage.collection = "sales1";
        // finalAPIMessage.scrollID=""
          let addMessage = firebase.functions().httpsCallable('scrollData');
          let result = await addMessage({ text: searchParameters, collection:'sales1', scrollID:'' });
         // console.log('searchParameters pawan',JSON.stringify(searchParameters))
          const invoices = []
        //   console.log('result', result);
        //   return;
          let sanitizedMessage = result.data.text;
          let total = sanitizedMessage.hits.total.value;
          sanitizedMessage.hits.hits.map((data, index) => {
              invoices.push({ ...data._source, key: data._id })
          })
          
        //   console.log('result',result);
        //   console.log('invoices', invoices)
          if(total> 100){
        
              let scrollID = sanitizedMessage._scroll_id
            //   console.log('scrollID', scrollID)
              searchParameters = {
                  "scroll": "1m",
                  "scroll_id": scrollID
                }
              for (let index = 1; index < (total / 100); index++) {
                  let addMessage = firebase.functions().httpsCallable("scrollData");
                  let loopResult = await addMessage({ text: searchParameters, scrollID:scrollID });
                    //   console.log('loopResult',loopResult)
                  loopResult.data.text.hits.hits.map((data, index) => {
                    invoices.push({ ...data._source, key: data._id })
                  })
              }
          }
        //   console.log('invoices',invoices)
          return invoices;

    }


    async loadData(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate) {

        // console.log('pawan')
        // let result = await this.props.getInvoicesForGSTR1B2C(this.props.user.user.RegistrationID, storeList, from, size, searchTransactiontype,
        //     searchTransactionFromDate, searchTransactionToDate)

        // console.log('result abc',result)
        let result = await this.getElasticData(this.props.user.user.RegistrationID, storeList, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate);

        // getElasticData(registrationID, searchStores,searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
        // console.log('result loadData',result)

        let b2cRecords = [];     
        for (let index = 0; index < result.length; index++) {
            const data = result[index];
            if (!data.hasOwnProperty('LineItems')) {
                continue
            }
            let placeOFSupply= data.PlaceOfSupply
            if(data.LineItems)
            {
                for (let l = 0; l < data.LineItems.length; l++) {
                    let taxRate= 0;
                    let cessAmount = 0 ;
                    let taxAmount = 0;
                    data.LineItems[l].TaxComponentAmount.map(m=>{
                        if(m.TaxType==="CGST" || m.TaxType==="SGST")
                        {
                            taxAmount = Number(taxAmount) + m.TaxAmount;
                            taxRate = Number(m.TaxPercentage * 2)
                        }
                        else if(m.TaxType==="IGST")
                        {
                            taxAmount = Number(taxAmount) + m.TaxAmount;
                            taxRate = Number(m.TaxPercentage)
                        }
                        else if(m.TaxType==="CESS")
                        {
                            cessAmount = Number(cessAmount)+ Number(m.TaxAmount)
                        }
                    })
                    let recordFound = false;
                    for(let b=0;b<b2cRecords.length;b++){
                        if(b2cRecords[b].taxRate === taxRate && b2cRecords[b].placeOFSupply.trim() === placeOFSupply.trim()){
                            if(cessAmount !== 0 && b2cRecords[b].cessAmount !== 0){
                                recordFound= true;
                                b2cRecords[b].taxableAmount = Number( b2cRecords[b].taxableAmount) + Number(data.LineItems[l].TaxableAmount)
                                b2cRecords[b].taxAmount = Number(b2cRecords[b].taxAmount) + Number(taxAmount);
                                b2cRecords[b].cessAmount =Number(b2cRecords[b].cessAmount)  + Number(cessAmount) 
                  
                            }else if(cessAmount === 0 && b2cRecords[b].cessAmount===0){
                                recordFound= true;
                                if(taxRate ===28){
                                    // console.log('28 - no cess amount', data.LineItems[l])
                                }

                                b2cRecords[b].taxableAmount = Number( b2cRecords[b].taxableAmount) + Number(data.LineItems[l].TaxableAmount)
                                b2cRecords[b].taxAmount = Number(b2cRecords[b].taxAmount) + Number(taxAmount);
                                // b2cRecords[b].cessAmount =Number(b2cRecords[b].cessAmount)  + Number(cessAmount) 
                            }
                        }
                    }
                    if(!recordFound){
                        b2cRecords.push({
                            placeOFSupply:placeOFSupply,
                            taxRate:taxRate, 
                            taxAmount: Number(taxAmount),
                            cessAmount:cessAmount, 
                            taxableAmount:Number(data.LineItems[l].TaxableAmount)
                            })
                    }
                }
            }
        }
        // console.log('b2cRecords',b2cRecords)
        let b2cDataRecords =[]
        for(let m=0;m<b2cRecords.length;m++)
        {
            let b2cData= {}
            b2cData["Type"] = "OE"
            b2cData["Place Of Supply"] = b2cRecords[m].placeOFSupply
            b2cData["Applicable % of Tax Rate"] = ""
            b2cData["Rate"] = Number(b2cRecords[m].taxRate)
            b2cData["Taxable Value"] = Number(b2cRecords[m].taxableAmount).toFixed(2)
            b2cData["Tax Amount"] = Number(b2cRecords[m].taxAmount).toFixed(2)
            b2cData["Cess Amount"] = Number(b2cRecords[m].cessAmount).toFixed(2)
            b2cData["E-Commerce GSTIN"] = ""
            b2cDataRecords.push(b2cData);
        }
        // console.log('b2cDataRecords',b2cDataRecords)
        return b2cDataRecords
    }

    async loadInvoiceDetailReport(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate) {

        this.setState({ loading: true })

        let invoices = await this.loadData(storeList, from, size, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate)
        var col = [];
        for (var i = 0; i < invoices.length; i++) {
            for (var key in invoices[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < invoices.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result += "<td>";
                result += invoices[i][col[j]] == undefined ? 0 : invoices[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }
        result += "</tbody>";
        result += "</table>";
        //console.log(result);
        this.setState({ invoices: result, loading: false })

    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        
        let selectedYear = new Date().getFullYear()
        let selectedMonth = new Date().getMonth()

        let searchTransactionFromDate = this.getMonthStartDate(selectedYear, selectedMonth)
        let searchTransactionToDate = this.getMonthEndDate(selectedYear, selectedMonth) 


        this.setState({
            selectedYear: selectedYear,
            selectedMonth: selectedMonth,
            searchTransactionFromDate: searchTransactionFromDate,
            searchTransactionToDate: searchTransactionToDate,
            storeList: storeList,
            storeID: this.props.user.store.key,
            recordFrom: 0, 
            searchTransactiontype: '',
          
        })
        this.loadInvoiceDetailReport(storeList, 0, this.state.itemsCountPerPage, "", searchTransactionFromDate, searchTransactionToDate);
    }

    async onExportClick() {
    
        let invoices = await this.loadData(this.state.storeList,
            0, 1000,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate)

        const csvData = constatnts.objToCSV(invoices);
        this.setState({ loading: false, exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "GSTR1B2C.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    onSearchClick() {
        this.setState({ recordFrom: 0, loading: true })
        this.loadInvoiceDetailReport(this.state.storeList,0,
            this.state.itemsCountPerPage,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate, this.state.searchTransactionToDate)
    }

    onStoreChange(e) {
        let storeList = []
        // console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        // console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>GSTR1 - B2C</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction Type</label>
                                            <select type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactiontype: evt.target.value })}
                                                value={this.state.searchTransactiontype}
                                            >
                                                <option value="">All</option>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Year</label>
                                            <select type="text" className="form-control"
                                                value={this.state.selectedYear}
                                                onChange={this.onYearChange}>
                                                {this.state.years.map((e) => <option value={e}>{e}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Month</label>
                                            <select type="text" className="form-control"
                                                value={this.state.selectedMonth}
                                                onChange={this.onMonthChange}>
                                                {this.state.months.map((e) => <option value={e.Month}>{e.MonthName}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Show</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download " ></i></button>
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                        {ReactHtmlParser(this.state.invoices)}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(GSTR1B2C)