import React, { Component } from 'react';
import PageSection from '../2/Controls/PageSection';
import Page from '../2/Controls/Page';

class AddEditLoyaltyType extends Component {
    constructor() {
        super()
        this.state = {
            key: '',
            loyaltyType: '',
            rangeFrom: 0,
            rangeTo: 0,
            birthdayPoint: '',
            anniversaryPoint: '',
            purchaseAmount: '',
            purchasePointValidity: '',
            birthdayPointValidity: '',
            anniversaryPointValidity: '',
            purchasePointMax: '',
            loyaltyTypeError: '',
            rangeFromError: '',
            rangeToError: '',
            purchaseAmountError: '',
            saving: false,
            minPurchaseValue:""

        }
        this.onSaveLoyaltyType = this.onSaveLoyaltyType.bind(this)
    }

    async componentDidMount() {

        const key = this.props.match.params.key
        if (key) {
            //edit
            let loyaltyType = await this.props.getLoyaltyTypeDetails(key)
            this.setState({
                key: key,
                loyaltyType: loyaltyType.LoyaltyType,
                rangeFrom: loyaltyType.RangeFrom,
                rangeTo: loyaltyType.RangeTo,
                purchaseAmount: loyaltyType.PurchaseAmount,
                birthdayPoint: loyaltyType.BirthdayPoint,
                anniversaryPoint: loyaltyType.AnniversaryPoint,
                purchasePointValidity: loyaltyType.PurchasePointValidity,
                birthdayPointValidity: loyaltyType.BirthdayPointValidity,
                anniversaryPointValidity: loyaltyType.AnniversaryPointValidity,
                purchasePointMax: loyaltyType.PurchasePointMax,
                minPurchaseValue: loyaltyType.hasOwnProperty("MinPurchaseValue") ? loyaltyType.MinPurchaseValue : ""
            })
        }
    }

    onSaveLoyaltyType() {
        this.setState({ saving: true })

        let loyaltyTypeError = ""
        let rangeFromError = ""
        let rangeToError = ""
        let purchaseAmountError = ""
        if (this.state.loyaltyType.trim() === "") {
            loyaltyTypeError = 'Please provide loyalty type name'
        }

        if (this.state.rangeTo === 0) {
            rangeToError = 'Please enter range to'
        }

        if (Number(this.state.rangeFrom) > Number(this.state.rangeTo)) {
            rangeToError = 'Range to should be greater than range from'
        }
        //check if range already exits 
        if (this.state.purchaseAmount === 0) {
            purchaseAmountError = "Please provide value"
        }
        if (loyaltyTypeError !== "" || rangeFromError !== "" || rangeToError !== "" || purchaseAmountError !== "") {
            this.setState({
                loyaltyTypeError: loyaltyTypeError,
                rangeFromError: rangeFromError,
                rangeToError: rangeToError,
                purchaseAmountError: purchaseAmountError,
                saving: false
            })
            return
        }

        let loyaltyType = {
            RegistrationID: this.props.user.user.RegistrationID,
            LoyaltyType: this.state.loyaltyType,
            RangeFrom: this.state.rangeFrom ? Number(this.state.rangeFrom) : 0,
            RangeTo: this.state.rangeTo ? Number(this.state.rangeTo) : 0,
            BirthdayPoint: this.state.birthdayPoint,
            AnniversaryPoint: this.state.anniversaryPoint,
            PurchaseAmount: this.state.purchaseAmount,
            PurchasePointValidity: this.state.purchasePointValidity,
            BirthdayPointValidity: this.state.birthdayPointValidity,
            AnniversaryPointValidity: this.state.anniversaryPointValidity,
            PurchasePointMax: this.state.purchasePointMax,
            MinPurchaseValue: this.state.minPurchaseValue,
            IsDeleted: false
        }
        // console.log('this.state.key',this.state.key)
        // console.log('loyaltyType', loyaltyType)
        this.props.saveLoyaltyType(loyaltyType, this.state.key).then(
            (key) => {
                this.props.getToastr("Loyalty type saved successfully")
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    this.props.history.push('/home/loyalty/loyaltytype')
                }.bind(this), 2000)
            })
    }

    render() {

        return <Page 
            title={(this.state.key !== "") ? "Edit Loyalty Type" : "New Loyalty Type"}
            showSaveButton={true} 
            showCancelButton={true}
            processing={this.state.saving}
            onClick={(action)=>{
                if(action==="cancel"){
                    this.props.history.push({ pathname: '/home/loyalty/loyaltytype' })
                }else if(action==="save"){
                    this.onSaveLoyaltyType();
                }
            }}
            >
            <PageSection title="">
                <div className="row">
                    <div className="col-md-6">
                        <div className={this.state.loyaltyTypeError ? "form-group has-error" : "form-group "} >
                            <label>Loyalty Type</label>
                            <input type="text" className="form-control"
                                onChange={(evt) => {
                                    this.setState({ loyaltyType: evt.target.value, loyaltyTypeError: '' })
                                }}
                                value={this.state.loyaltyType} />
                            {this.state.loyaltyTypeError !== "" && <span className="help-block">{this.state.loyaltyTypeError}</span>}
                        </div>
                    </div>
                    <div className="col-md-2 col-xs-6">
                        <div className={this.state.rangeFromError ? "form-group has-error" : "form-group "} >
                            <label>Range From</label>
                            <input type="number" className="form-control"
                                onChange={(evt) => {
                                    this.setState({ rangeFrom: evt.target.value, rangeFromError: '' })
                                }}
                                value={this.state.rangeFrom} />
                            {this.state.rangeFromError !== "" && <span className="help-block">{this.state.rangeFromError}</span>}
                        </div>
                    </div>
                    <div className="col-md-2 col-xs-6">
                        <div className={this.state.rangeToError ? "form-group has-error" : "form-group "} >
                            <label>Range To</label>
                            <input type="number" className="form-control"
                                onChange={(evt) => {
                                    this.setState({ rangeTo: evt.target.value, rangeToError: '' })
                                }}
                                value={this.state.rangeTo} />
                            {this.state.rangeToError !== "" && <span className="help-block">{this.state.rangeToError}</span>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Transaction</th>
                                    <th>Point(s) earned</th>
                                    <th style={{ width: "100px" }}>Validity (days)</th>
                                    <th style={{ width: "100px" }}>Min purchase value</th>
                                    <th style={{ width: "100px" }}>Max point(s) earned</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Purchase</td>
                                    <td>
                                        <div className="col-md-6" style={{ padding: "0px" }}>
                                            <div className={this.state.rangeToError ? "form-group has-error" : "form-group "} >
                                                <input type="number" className="form-control"
                                                    placeholder=""
                                                    onChange={(evt) => this.setState({ purchaseAmount: evt.target.value, purchaseAmountError: "" })}
                                                    value={this.state.purchaseAmount} />
                                                {this.state.purchaseAmountError !== "" && <span className="help-block">{this.state.purchaseAmountError}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6" style={{ padding: "0px" }}>
                                            INR = 1 Point
                                        </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control"
                                            placeholder=""
                                            onChange={(evt) => this.setState({ purchasePointValidity: evt.target.value })}
                                            value={this.state.purchasePointValidity} />
                                    </td>
                                    <td>
                                        <input type="number" className="form-control"
                                            placeholder=""
                                            onChange={(evt) => this.setState({ minPurchaseValue: evt.target.value })}
                                            value={this.state.minPurchaseValue} />
                                    </td>
                                    <td>
                                        <input type="number" className="form-control"
                                            placeholder=""
                                            onChange={(evt) => this.setState({ purchasePointMax: evt.target.value })}
                                            value={this.state.purchasePointMax} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Birthday</td>
                                    <td>
                                        <div className="col-md-6" style={{ padding: "0px" }}>
                                            <input type="number" className="form-control"
                                                placeholder=""
                                                onChange={(evt) => this.setState({ birthdayPoint: evt.target.value })}
                                                value={this.state.birthdayPoint} />
                                        </div>
                                        <div className="col-md-6" style={{ padding: "0px" }}>
                                            Point(s)
                            </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control"
                                            placeholder=""
                                            onChange={(evt) => this.setState({ birthdayPointValidity: evt.target.value })}
                                            value={this.state.birthdayPointValidity} />

                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Anniversary</td>
                                    <td>
                                        <div className="col-md-6" style={{ padding: "0px" }}>
                                            <input type="number" className="form-control"
                                                placeholder=""
                                                onChange={(evt) => this.setState({ anniversaryPoint: evt.target.value })}
                                                value={this.state.anniversaryPoint} />
                                        </div>
                                        <div className="col-md-6" style={{ padding: "0px" }}>
                                            Point(s)
                            </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control"
                                            placeholder=""
                                            onChange={(evt) => this.setState({ anniversaryPointValidity: evt.target.value })}
                                            value={this.state.anniversaryPointValidity} />
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </PageSection>
        </Page>
    }
}

export default AddEditLoyaltyType