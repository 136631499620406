import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'

export function saveAppointment(appointment, key) {
    return (dispatch, getState) => {
        if (key !== "") {
            appointment.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
            appointment.ActionBy = getState().user.user.Name
            appointment.ActionByUID = getState().user.user.UID
            appointment.ActionByEmailID = getState().user.user.EmailID
            appointment.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Appointment").doc(key).update({
                ...appointment
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating appointment: ", error);
            })
        }
        else {
            appointment.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
            appointment.ActionBy = getState().user.user.Name
            appointment.ActionByUID = getState().user.user.UID
            appointment.ActionByEmailID = getState().user.user.EmailID
            appointment.Action = constants.AUDIT_TRAIL_NEW

            return database.collection("Appointment").add({
                ...appointment
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding appointment: ", error);
                })
        }
    }
}

export function getAppointmentDetails(key) {
    return (dispatch) => {
        return database.collection("Appointment").doc(key).get().then((doc) => {
            if (doc.exists) {
                //console.log("Appointment found", doc.data());
                return doc.data()
            } else {
                console.log("No such Appointment!");
            }
        })
    }
}

export function getAppointments(storeID, appointmentDate) {
    let startDate = new Date(appointmentDate).setHours(0, 0, 0, 0);
    let endDate = new Date(appointmentDate).setHours(23, 59, 0, 0);

    // console.log("appointmentDate", appointmentDate);
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);

    return (dispatch) => {
        return database.collection("Appointment").where("StoreID", "==", storeID).where("AppointmentDate", ">=", new Date(startDate)).where("AppointmentDate", "<=", new Date(endDate)).get().then((querySnapshot) => {
            let appointmentList = []
            querySnapshot.forEach((doc) => {
                // console.log(doc.id);
                let appointment = {
                    ...doc.data()
                }
                appointment.key = doc.id
                // console.log("appointment", appointment);
                appointmentList.push(appointment);
            });
            //console.log("appointmentList", appointmentList);
            return appointmentList;
        });
    }
};

export function getAppointmentsForAPeriod(registrationID, startDate, endDate) {
    // let startDate = new Date(appointmentDate).setHours(0, 0, 0, 0);
    // let endDate = new Date(appointmentDate).setHours(23, 59, 0, 0);

    return (dispatch) => {
        return database.collection("Appointment").where("RegistrationID", "==", registrationID).where("AppointmentDate", ">=", new Date(startDate)).where("AppointmentDate", "<=", new Date(endDate)).get().then((querySnapshot) => {
            let appointmentList = []
            querySnapshot.forEach((doc) => {
                appointmentList.push({ key: doc.id, ...doc.data() })
            });
            return appointmentList;
        });
    }
};

export function updateAppointmentStatus(status, key) {
    return (dispatch, getState) => {
        return database.collection("Appointment").doc(key).update({
            Status: status
            , ActionOn: firebase.firestore.FieldValue.serverTimestamp()
            , ActionBy: getState().user.user.Name
            , ActionByUID: getState().user.user.UID
            , ActionByEmailID: getState().user.user.EmailID
            , Action: constants.AUDIT_TRAIL_EDIT
        }).then(() => {
            return key
        }).catch(function (error) {
            console.error("Error updating appointment: ", error);
        })
    }
}

export function cancelAppointment(cancellationReason, key) {
    return (dispatch, getState) => {
        return database.collection("Appointment").doc(key).update({
            Status: 'Cancelled'
            , CancellationReason : cancellationReason
            , ActionOn: firebase.firestore.FieldValue.serverTimestamp()
            , ActionBy: getState().user.user.Name
            , ActionByUID: getState().user.user.UID
            , ActionByEmailID: getState().user.user.EmailID
            , Action: 'CANCEL'
        }).then(() => {
            return key
        }).catch(function (error) {
            console.error("Error cancelling appointment: ", error);
        })
    }
}

export function getAllAppointments(storeID, startDate, endDate, employeeID, status) {
    
    return (dispatch) => {
        let appointmentsRef = database.collection("Appointment");
        let query = appointmentsRef.where("StoreID", "==", storeID).where("AppointmentDate", ">=", new Date(startDate)).where("AppointmentDate", "<=", new Date(endDate))
        if(employeeID !== '')
            query = query.where("EmployeeID", "==", employeeID)
        if(status !== '')
            query = query.where("Status", "==", status)

        return query.get().then((querySnapshot) => {
            let appointmentList = []
            querySnapshot.forEach((doc) => {
                let appointment = {
                    ...doc.data()
                }
                appointment.key = doc.id

                appointmentList.push(appointment);
            })
            // console.log(appointmentList);
            return appointmentList;
        }).catch((error) => {
            console.log(error);
            return null
        })
    }
};

export function getUpcomingAppointmentsForCustomer(customerID) {
    let date = new Date().setHours(0, 0, 0, 0);
    return (dispatch) => {
        return database.collection("Appointment").where("Customer.key", "==", customerID).where("AppointmentDate", ">=", new Date(date)).limit(5).get().then((querySnapshot) => {
            let appointmentList = []
            querySnapshot.forEach((doc) => {
                // console.log(doc.id);
                let appointment = {
                    ...doc.data()
                }
                appointment.key = doc.id
                // console.log("appointment", appointment);
                appointmentList.push(appointment);
            });
            return appointmentList;
        });
    }
};

export function getPastAppointmentsForCustomer(customerID) {
    let date = new Date().setHours(0, 0, 0, 0);
    return (dispatch) => {
        return database.collection("Appointment").where("Customer.key", "==", customerID).where("AppointmentDate", "<=", new Date(date)).limit(5).get().then((querySnapshot) => {
            let appointmentList = []
            querySnapshot.forEach((doc) => {
                console.log(doc.id);
                let appointment = {
                    ...doc.data()
                }
                appointment.key = doc.id
                console.log("appointment", appointment);
                appointmentList.push(appointment);
            });
            return appointmentList;
        });
    }
};

export function getAppointmentsForCustomer(customerID, appointmentDate) {
    let startDate = new Date(appointmentDate).setHours(0, 0, 0, 0);
    let endDate = new Date(appointmentDate).setHours(23, 59, 0, 0);

    return (dispatch) => {
        return database.collection("Appointment").where("Customer.key", "==", customerID).where("AppointmentDate", ">=", new Date(startDate)).where("AppointmentDate", "<=", new Date(endDate)).get().then((querySnapshot) => {
            let appointmentList = []
            querySnapshot.forEach((doc) => {
                console.log(doc.id);
                let appointment = {
                    ...doc.data()
                }
                appointment.key = doc.id
                appointmentList.push(appointment);
            });
            return appointmentList;
        });
    }
};