import React, {Component} from 'react'
import Loader from '../Loader'
import { database } from '../../firebase';
import Window from '../2/Controls/Window';

class UniqueNoSelection extends Component{
    constructor () {
        super()
        this.state={
           uniqueNoList:[],
            loading: false,
            searchText:'',
            selectAll:false,
            buttons:[]
        }
        this.loadList = this.loadList.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.updateInStockQty = this.updateInStockQty.bind(this);
    }

    componentDidMount(){ 
        this.loadList(0)
        if(!this.props.hideSelection)
        {
            this.setState({buttons:[{Type:"secondary", Text:"Cancel", Align:"left"},
            {Type:"primary", Text:"OK", Align:"right"},
            ]})
        }
     }

    loadList(recordFrom) {
        this.setState({loading:true});
        let lineItem = null
        if (this.props.salesReturn) {
            if (this.props.negativeQty) {
                if(this.props.lineItems.filter(f=> (f.Product.key === this.props.product.key  && f.hasOwnProperty('UniqueNoList') && f.Qty< 0)).length > 0)
                {
                    lineItem=this.props.lineItems.filter(f=> (f.Product.key === this.props.product.key  && f.hasOwnProperty('UniqueNoList') && f.Qty))[0]
                }
                this.props.getUniqueListForSalesReturn(recordFrom, 1000, this.props.user.store.key,
                    this.props.product.key,
                    this.state.searchText).then((result) => {
                        let newResult = []
                        result.searchResult.map(m => {
                            if (lineItem && lineItem.hasOwnProperty('UniqueNoList')) {
                                if (lineItem.UniqueNoList.filter(f => f.key === m.key).length === 0) {
                                    m.Selected = false
                                    newResult.push(m)
                                }
                            }
                            else {
                                m.Selected = false
                                newResult.push(m)
                            }

                        })
                        this.setState({
                            uniqueNoList: newResult,
                            loading: false
                        })
                    })
            }
            else {
                
                if(this.props.lineItems.filter(f=> (f.Product.key === this.props.product.key  && f.hasOwnProperty('UniqueNoList') && f.Qty> 0)).length > 0)
                {
                    lineItem=this.props.lineItems.filter(f=> (f.Product.key === this.props.product.key  && f.hasOwnProperty('UniqueNoList') && f.Qty))[0]
                }
                this.props.getInwardUniqueList(recordFrom, 1000, this.props.user.store.key,
                    this.props.product.key,
                    this.state.searchText).then((result) => {
                        let newResult = []
                        result.searchResult.map(m => {
                            if (lineItem && lineItem.hasOwnProperty('UniqueNoList')) {
                                if (this.props.lineItem.UniqueNoList.filter(f => f.key === m.key).length === 0) {
                                    m.Selected = false
                                    newResult.push(m)
                                }
                            }
                            else {
                                m.Selected = false
                                newResult.push(m)
                            }

                        })
                        this.setState({
                            uniqueNoList: newResult,
                            loading: false
                        })
                    })
            }
        }
        else {
            if(this.props.lineItems.filter(f=> (f.Product.key === this.props.product.key  && f.hasOwnProperty('UniqueNoList') && f.Qty> 0)).length > 0)
            {
                lineItem=this.props.lineItems.filter(f=> (f.Product.key === this.props.product.key  && f.hasOwnProperty('UniqueNoList') && f.Qty))[0]
            }
            this.props.getInwardUniqueList(recordFrom, 1000, this.props.user.store.key,
                this.props.product.key,
                this.state.searchText).then((result) => {
                    let newResult = []
                    result.searchResult.map(m => {
                        if (lineItem &&lineItem.hasOwnProperty('UniqueNoList')) {
                            if (lineItem.UniqueNoList.filter(f => f.key === m.key).length === 0) {
                                m.Selected = false
                                newResult.push(m)
                            }
                        }
                        else {
                            m.Selected = false
                            newResult.push(m)
                        }

                    })
                    this.setState({
                        uniqueNoList: newResult,
                        // totalItemsCount: result.totalItemsCount, 
                        loading: false
                    })
                    // this.showPaginationStatus()
                })
        }
    }
    
    onClearSearch() {
        this.setState({recordFrom: 0, searchText: ''})
        this.loadList(0)
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadList(0)
    }

    async updateInStockQty(){
        if(this.props.id){
            await database.collection("InventoryInStock").doc(this.props.id).update({ InStockQty: this.state.uniqueNoList.length })
            // alert('Instock qty updated successfully');
            this.props.getToastr("Instock qty updated successfully");
            setTimeout(async function () { //Start the timer
                this.props.reLoad();   
            }.bind(this), 3000)
        }
    }

    render() {
        return (
            <Window 
                header={this.props.product.Name}  
                width={"600px"}
                // height={"200px"}
                modal={false}
                // processing={this.state.deleting}
                onClose={this.props.onClose}
                onButtonClick={(buttonType)=>{
                    if(buttonType.toLowerCase()==="ok"){
                        let selectedUniqueNos = this.state.uniqueNoList.filter(
                            (f) => f.Selected
                        );
                        if (selectedUniqueNos.length === 0) {
                            alert("Please select record(s)");
                            return;
                        }
                        this.props.onOK(selectedUniqueNos);
                    }
                    else
                    {
                        this.props.onClose()
                    }
                }}
                buttons={this.state.buttons}
                >
                <div
                    style={{
                        height: "73vh",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        padding: "0px",
                    }}
                >
                    <div className="row">
                        <div className="col-md-12">
                            <table>
                                <tr>
                                    <td style={{ padding: "5px",width: "200px" }} >
                                        {this.props.product.hasOwnProperty( "UniqueLabel") &&
                                        this.props.product.UniqueLabel.trim() !==""? "Search " + this.props.product.UniqueLabel.trim() : "Search Unique No 1"}
                                    </td>
                                    <td style={{padding: "5px",width: "300px"}}>
                                        <input
                                            autoFocus
                                            type="text"
                                            className="form-control input-md"
                                            value={this.state.searchText}
                                            onChange={(evt) =>
                                                this.setState({searchText:evt.target.value})
                                            }
                                        />
                                    </td>
                                    <td style={{ padding: "5px" }}>
                                        <button
                                            type="submit"
                                            className="btn btn-flat btn-md btn-default "
                                            onClick={this.onSearchClick}
                                        >
                                            Search
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    {this.state.loading && <Loader show={this.state.loading} />}
                    {!this.state.loading && (<React.Fragment>
                        {(this.props.inStockQty && Number(this.props.inStockQty) !== Number(this.state.uniqueNoList.length)) && 
                        <div className="row">
                            <div className="col-md-4">In Stock Qty: <br/>{this.props.inStockQty}</div>
                            <div className="col-md-4">Total Qty:<br/>{this.state.uniqueNoList.length}</div>
                            <div className="col-md-4">
                                <button 
                                    type="submit" 
                                    className="btn btn-flat btn-md btn-default btn-rounded" 
                                    onClick={()=>this.updateInStockQty()}>
                                    Update In Stock Qty
                                </button>
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {!this.props.hideSelection && <th style={{ width: "20px" }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.selectAll}
                                                        onChange={() => {
                                                            let uniqueNoList = this.state.uniqueNoList;
                                                            uniqueNoList.map((b) => {b.Selected = !this.state.selectAll;});
                                                            this.setState({selectAll: !this.state.selectAll,uniqueNoList: uniqueNoList});
                                                        }}
                                                    />
                                                </th>}
                                                <th>#</th>
                                                {this.props.mobile && (
                                                    <th>
                                                        {this.props.product.hasOwnProperty("UniqueLabel") &&
                                                        this.props.product.UniqueLabel.trim() !==""? this.props.product.UniqueLabel.trim(): "Unique No"}
                                                    </th>
                                                )}
                                                {!this.props.mobile && (
                                                    <th>
                                                        {this.props.product.hasOwnProperty("UniqueLabel") &&
                                                        this.props.product.UniqueLabel.trim() !==""? this.props.product.UniqueLabel.trim() + " 1" : "Unique No 1"}
                                                    </th>
                                                )}
                                                {!this.props.mobile && (
                                                    <th>
                                                        {this.props.product.hasOwnProperty("UniqueLabel") &&
                                                        this.props.product.UniqueLabel.trim() !==""? this.props.product.UniqueLabel.trim() +" 2" : "Unique No 2"}
                                                    </th>
                                                )}
                                                {!this.props.mobile && (
                                                    <th>
                                                        {this.props.product.hasOwnProperty("UniqueLabel") &&
                                                        this.props.product.UniqueLabel.trim() !== ""? this.props.product.UniqueLabel.trim() + " 3" : "Unique No 3"}
                                                    </th>
                                                )}
                                                {!this.props.mobile && (
                                                    <th>
                                                        {this.props.product.hasOwnProperty( "UniqueLabel") &&
                                                        this.props.product.UniqueLabel.trim() !== ""? this.props.product.UniqueLabel.trim() + " 4" : "Unique No 4"}
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.uniqueNoList.map((m, index) => (
                                                <tr key={index}>
                                                    {!this.props.hideSelection && <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={m.Selected}
                                                            onChange={() => {
                                                                let uniqueNoList = this.state.uniqueNoList;
                                                                uniqueNoList[ index].Selected = !uniqueNoList[ index ].Selected;
                                                                this.setState({uniqueNoList: uniqueNoList });
                                                            }}
                                                        />
                                                    </td>}
                                                    <td>{index + 1 }</td>
                                                    {this.props.mobile && (
                                                        <td>
                                                            {m.UniqueNo1}
                                                            {m.UniqueNo2.trim() !== "" && (
                                                                <div>{m.UniqueNo2}</div>
                                                            )}
                                                            {m.UniqueNo3.trim() !== "" && (
                                                                <div>{m.UniqueNo3}</div>
                                                            )}
                                                            {m.UniqueNo4.trim() !== "" && (
                                                                <div>{m.UniqueNo4}</div>
                                                            )}
                                                        </td>
                                                    )}
                                                    {!this.props.mobile && (
                                                        <td>{m.UniqueNo1}</td>
                                                    )}
                                                    {!this.props.mobile && (
                                                        <td>{m.UniqueNo2}</td>
                                                    )}
                                                    {!this.props.mobile && (
                                                        <td>{m.UniqueNo3}</td>
                                                    )}
                                                    {!this.props.mobile && (
                                                        <td>{m.UniqueNo4}</td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                    </div>
                    </React.Fragment>)}
                </div>
            </Window>
        );
    }
}

export default UniqueNoSelection