import React, { Component } from 'react';
import TabStrip from '../Common/TabStrip'
import Input from '../2/Controls/Input';
import Button from '../2/Controls/Button';
import Spinner from '../Common/Spinner';
import axios from 'axios';

class RESettings extends Component {

    constructor(props) {

        super(props);
        this.state={
            selectedTabIndex:0,
            selectedSalesDate:new Date().toISOString().split('T')[0],
            selectedPurchaseDate:new Date().toISOString().split('T')[0],
            selectedDamageDate:new Date().toISOString().split('T')[0],
            sales:[],
            salesTotalQty:0,
            loadingText:"Please wait...",
            rePurchaseTransactionType:"",
            reStoreCode:"",
            rePurchaseTransactionType:"",
            reDamageTransactionType:"",
            purchases:[],
            logs:[],
            damage:[], 
            damageTotalQty:0, 
            damageTotalAmount:0
        }
        this.renderSales = this.renderSales.bind(this);
        this.renderDamage = this.renderDamage.bind(this);
        this.getSalesData = this.getSalesData.bind(this);
        this.getDamageData = this.getDamageData.bind(this);
        this.uploadSalesDataToExcisePortal = this.uploadSalesDataToExcisePortal.bind(this);
        this.uploadDamageDataToExciePortal = this.uploadDamageDataToExciePortal.bind(this);
        this.getCatalogue = this.getCatalogue.bind(this);
        this.renderSetup= this.renderSetup.bind(this);
        this.onSaveExciseSetup = this.onSaveExciseSetup.bind(this);
        this.getPurchaseEntry = this.getPurchaseEntry.bind(this);
        this.getFormattedDate = this.getFormattedDate.bind(this);
    }

    async componentDidMount(){
        let reStoreCode="";
        let rePurchaseTransactionType ="";
        let reDamageTransactionType="";

        if(this.props.user.store){
            if(this.props.user.store.hasOwnProperty('REStoreCode')){
                reStoreCode = this.props.user.store.REStoreCode;
            }
            if(this.props.user.store.hasOwnProperty('REPurchaseTransactionType')){
                rePurchaseTransactionType = this.props.user.store.REPurchaseTransactionType;
            }
            if(this.props.user.store.hasOwnProperty('REDamageTransactionType')){
                reDamageTransactionType = this.props.user.store.REDamageTransactionType;
            }
        }
        let storeLocationsTmp = await this.props.getStoreLocations(this.props.user.store.key) 
        let storeLocations = storeLocationsTmp.filter(f => !f.IsDeleted)
        this.onTabSelected(0)
        this.setState({reStoreCode:reStoreCode,
            rePurchaseTransactionType:rePurchaseTransactionType,
            reDamageTransactionType:reDamageTransactionType,
            storeLocations:storeLocations[0]
        })
    }

    async getSalesData(){
        this.setState({loading:true})
        let result = await this.props.getItemSoldReportData(
            this.props.user.user.RegistrationID, 
            [{ key: this.props.user.store.key }], 
            this.state.selectedSalesDate,
            this.state.selectedSalesDate, 
            "", "", "", "", "", "", "", "", "", "", "", "");
        let sales = result.searchResult;
        //sales = result;
        let salesTotalQty = 0;
        let salesTotalAmount = 0;
        for(let i=0; i<sales.length; i++){
            salesTotalQty= salesTotalQty+ Number(sales[i].total_quantity);
            salesTotalAmount = salesTotalAmount + Number(sales[i].total_sales)
        }
        // console.log('sales',sales)
        this.setState({sales:sales, salesTotalQty:salesTotalQty, salesTotalAmount:salesTotalAmount, loading:false});
    }

    async getDamageData(){
        if(this.state.reDamageTransactionType===""){
            alert('Please select transaction type for damage');
            return;
        }
        this.setState({loading:true})
        let result = await this.props.getDamageDataForExcise(
            this.state.reDamageTransactionType,
            this.props.user.user.RegistrationID, 
            this.props.user.store.key, 
            this.state.selectedDamageDate,
            this.state.selectedDamageDate, 
            "", "", "", "", "", "", "", "", "", "", "", "");
        console.log('result',result);
        let damage = result.searchResult;
        //sales = result;
        let damageTotalQty = 0;
        let damageTotalAmount = 0;
        for(let i=0; i<damage.length; i++){
            damageTotalQty= damageTotalQty+ Number(damage[i].total_quantity);
            damageTotalAmount = damageTotalAmount + Number(damage[i].total_damage)
        }
        // console.log('sales',sales)
        this.setState({damage:damage, damageTotalQty:damageTotalQty, damageTotalAmount:damageTotalAmount, loading:false});
    }

    async uploadDamageDataToExciePortal(){
        this.setState({loading:true})
        let damage = this.state.damage;
        console.log('damage',damage)
        let formmattedDate = this.getFormattedDate(this.state.selectedSalesDate);
        let  invoices  = [];
        let qty = 0;
        let amount = 0 ;
        let transactions=[]
        for(let i=0; i<damage.length; i++){
            let productRes = this.props.config.Products.filter(f=> f.SKU === damage[i].sku && !f.IsDeleted);
            if(productRes.length ===0){
                alert('Product not found. Please download excise catalogue.');
                this.setState({loading:false})
                return;
            }
            let product = productRes[0];
            let invoice = {
                GroupCode:product.RE_Group_Code,
                BrandCode:product.RE_Brand_Code,
                PackingCode:product.RE_Packing_Code,
                Sale_Qty_In_Btl:Number(damage[i].total_quantity).toFixed(0),
                Amount:Number(damage[i].total_damage).toFixed(0),
                ShopCode:this.state.reStoreCode,
                SaleDate:formmattedDate,
                InvoiceType:"DMG"
            }
            qty = Number(qty) + Number(invoice.Sale_Qty_In_Btl);
            amount = Number(amount) + Number(invoice.Amount);
            transactions.push({
                Product:product.Name,
                ProductID:product.key,
                GroupCode:product.RE_Group_Code,
                BrandCode:product.RE_Brand_Code,
                PackingCode:product.RE_Packing_Code,
                Sale_Qty_In_Btl:Number(damage[i].total_quantity).toFixed(0),
                Amount:Number(damage[i].total_sales).toFixed(0),
                ShopCode:this.state.reStoreCode,
                SaleDate:formmattedDate,
                InvoiceType:"DMG"
            })
            invoices.push(invoice);
        }
        let result = await this.rjexciseInsertInvoiceDetail(this.state.reStoreCode,  invoices);
        await this.props.saveREExciseLog({Type:"Damage", TransactionDate : formmattedDate, Transactions: transactions, Count:invoices.length,Qty:qty , Amount: amount, APIResult:result});
        alert('Data uploaded to excise portal');
        this.onTabSelected(this.state.selectedTabIndex);
        this.setState({loading:false})
    }

    async uploadSalesDataToExcisePortal(){
        this.setState({loading:true})
        let sales = this.state.sales;
        console.log('sales',sales)
        let formmattedDate = this.getFormattedDate(this.state.selectedSalesDate);
        let  invoices  = [];
        let qty = 0;
        let amount = 0 ;
        let transactions=[]
        for(let i=0; i<sales.length; i++){
            let productRes = this.props.config.Products.filter(f=> f.SKU === sales[i].sku && !f.IsDeleted);
            if(productRes.length ===0){
                alert('Product not found. Please download excise catalogue.');
                this.setState({loading:false})
                return;
            }
            let product = productRes[0];
            let invoice = {
                GroupCode:product.RE_Group_Code,
                BrandCode:product.RE_Brand_Code,
                PackingCode:product.RE_Packing_Code,
                Sale_Qty_In_Btl:Number(sales[i].total_quantity).toFixed(0),
                Amount:Number(sales[i].total_sales).toFixed(0),
                ShopCode:this.state.reStoreCode,
                SaleDate:formmattedDate,
                InvoiceType:"SALE"
            }
            qty = Number(qty) + Number(invoice.Sale_Qty_In_Btl);
            amount = Number(amount) + Number(invoice.Amount);
            transactions.push({
                Product:product.Name,
                ProductID:product.key,
                GroupCode:product.RE_Group_Code,
                BrandCode:product.RE_Brand_Code,
                PackingCode:product.RE_Packing_Code,
                Sale_Qty_In_Btl:Number(sales[i].total_quantity).toFixed(0),
                Amount:Number(sales[i].total_sales).toFixed(0),
                ShopCode:this.state.reStoreCode,
                SaleDate:formmattedDate,
                InvoiceType:"SALE"
            })
            invoices.push(invoice);
            // [{"Licensee_Code":"89024", 
            // "GroupCode":"2", "BrandCode" :"7264", "PackingCode" :"12", "Sale_Qty_In_Btl" :"5",  "Amount" :"500",  "ShopCode" :"80191", 
            // "SaleDate" :"07-JUL-2021", "InvoiceType" :"SALE","Authentication_Key" :"1df21b57-8c4c-4af0-bf89-7bcc1ea50e4a"},

        }
        // console.log('invoices', invoices)
        let result = await this.rjexciseInsertInvoiceDetail(this.state.reStoreCode,  invoices);
        // console.log('result', result);
        // console.log('qty',qty);
        // console.log('amount',amount);
        // console.log('transactions', transactions)
        await this.props.saveREExciseLog({Type:"Sales", TransactionDate : formmattedDate, Transactions: transactions, Count:invoices.length,Qty:qty , Amount: amount, APIResult:result});
        alert('Data uploaded to excise portal');
        this.onTabSelected(this.state.selectedTabIndex);
        this.setState({loading:false})
    }

    async getCatalogue(){
        this.setState({loading:true, loadingText:"Getting master data from excise portal"});
        let brands = await this.rjexciseGetBrandMasterList();
        console.log('brands', brands);
        let category = await this.rjexciseGetProductCat();
        console.log('category',category);
        let items = await this.rjexciseGetPriceListDetail();
        console.log('items', items);
        let packagingList = await this.rjexciseGetPackingList();
        console.log('packagingList', packagingList);
        // return;
        let newCategoryAdded= 0;
        let newProductAdded = 0 
        //first create category 
        this.setState({loadingText:"Processing category"});
        for(let c=0;c<category.length;c++){
            let res = this.props.config.ProductCategories.filter(f=> f.RE_Category_ID === category[c].Category_ID);
            if(res.length > 0 ){
                category[c].key = res[0].key;
                console.log('category exists', res[0].key)
                continue;
            }
            let productCategory = {
                Parent: "",
                ParentID: "",
                Name: category[c].Category_Name,
                Sequence: c+1,
                Color: "",
                Image: null,
                RegistrationID: this.props.user.user.RegistrationID,
                IsDeleted: false,
                BackEndUpdate: false ,
                OMNIEnabled:false,
                RE_Category_ID:category[c].Category_ID,
                RE_Group_Code: category[c].Group_Code
            }
            let key = await this.props.saveProductCategory("", productCategory);
            category[c].key = key;
            console.log('category created', c);
            newCategoryAdded = newCategoryAdded + 1;
            this.setState({loadingText:"Category created - " + category[c].Category_Name });
        }
        //other category 
        let otherCategory = null;
        if(this.props.config.ProductCategories.filter(f=>f.Name ==="Other").length ===0){
            let otherCategory = {
                Parent: "",
                ParentID: "",
                Name: "Other",
                Sequence: category.length+1,
                Color: "",
                Image: null,
                RegistrationID: this.props.user.user.RegistrationID,
                IsDeleted: false,
                BackEndUpdate: false ,
                OMNIEnabled:false,
                Category_Name:"Other"
            }
            otherCategory.key = await this.props.saveProductCategory("", otherCategory);
            console.log('other category created')
        }else
        {
            otherCategory = this.props.config.ProductCategories.filter(f=>f.Name ==="Other")[0];
            console.log('other category exists',otherCategory);
        }
        // let subCategories = this.props.config.ProductSubCategories.filter(f=> !f.IsDeleted);
        // return;
        //create poducts
        for(let i=0;i<items.length;i++){
            
            let item =items[i];
            let key = "";
            if(this.props.config.Products.filter(f=> f.RE_Item_ID===item.item_id).length > 0){
                console.log('item already exits', item.item_id);
                key= this.props.config.Products.filter(f=> f.RE_Item_ID===item.item_id)[0].key;
                // continue;
            }
            let brandResult = brands.filter(f=> f.Brand_Code === item.brand_code);
            if(brandResult.length > 0){
                let brand = brandResult[0];
                let categoryObj = null
                if(brand.Flavour_Code!==""){
                    //serch for catrgory
                    let cat = category.filter(f=> f.Category_ID === brand.Flavour_Code)
                    if(cat.length > 0 ){
                        //cat found 
                        categoryObj = cat[0];
                    }
                    else{
                        categoryObj = otherCategory;
                    }
                }
                else{
                    categoryObj = otherCategory;
                }
                console.log('categoryObj',categoryObj);
                console.log('item', item)
                if(!categoryObj){
                    alert('categoryObj null');
                    console.log('categoryObj null')
                }
                let pckgRes = packagingList.filter(f=>item.packing_code === f.Packing_Code)
                let pcktext = ""
                if(pckgRes.length > 0){
                    pcktext = pckgRes[0].Packing_Desc
                }
                // let subCategoryName = "";
                // if(pcktext.toLowerCase()==="quarts"){
                //     subCategoryName = "QUARTS"
                // }
                // if(pcktext.toLowerCase()==="pints"){
                //     subCategoryName = "PINTS";
                // }
                // if(pcktext.toLowerCase()==="pints"){
                //     subCategoryName = "PINTS";
                // }
                let group = ""
                if(item.group_code ==="1"){
                    group =  "Country Liquor" 
                }
                else if(item.group_code ==="2"){
                        group = "Beer";
                }
                else if( item.group_code ==="3"){
                    group = "IMFL";
                }
                else if(categoryObj.Category_Name.toLowerCase() ==="wine"){
                    group = "Wine";
                }
                else if(categoryObj.Category_Name.toLowerCase() ==="imported"){
                    group = "Imported Foreign Liquor";
                }else
                {
                    //other category or nothing is found then 
                    group = "IMFL";
                }
                console.log('group', group);
                //calculate qty in liter per bottle
                let qtyInBottle = 1;
                let product = {
                    RE_Item_ID:item.item_id,
                    RE_Brand_Code: item.brand_code,
                    RE_Packing_Code: item.packing_code,
                    RE_Group_Code: item.group_code,
                    RE_Group: group,
                    RE_QTY_IN_BTL:qtyInBottle,
                    RegistrationID: this.props.user.user.RegistrationID,
                    Name: brand.Brand_Name + " "  + pcktext,
                    ProductTypeID: 1,
                    ProductType: 'Goods for sale',
                    Description: '',
                    SKU: item.item_id,
                    BarCode: '',
                    Category: categoryObj.Category_Name,
                    CategoryID: categoryObj.key,
                    SubCategoryID: '',
                    SubCategory: '',
                    //Tags:this.state.tags,
                    BrandID: '',
                    Brand: '',
                    SupplyPrice: 0,
                    Markup: 0,
                    RetailPrice: item.mrp,
                    MRP:item.mrp,
                    MSP: item.hasOwnProperty('msp') ? item.msp : item.mrp,
                    WalletTopUpSameAsRetailPrice: false,
                    WalletTopUpAmount: 0,
                    AllowDiscount: true,
                    AllowPriceEdit: true,
                    AllowTaxChange: true,
                    ChargeTaxOnProduct: false,
                    TaxInclusive: false,
                    TaxInclusivePurchase: false,
                    HSNSACCode: '',
                    TaxID: '',
                    TaxGroup: '',
                    PriceBasedTax: false,
                    TaxPriceRange: [],
                    Images: [],
                    IsDeleted: false,
                    EditWalletTopUpAmount: false,
                    AccessibleToSelectedStores: false,
                    StoreSpecificPricing: false,
                    StoreSpecificPrices: [],
                    DiscountAmount: 0,
                    DiscountPercentage: 0,
                    MinQty: 0,
                    MaxQty: 0,
                    ReorderQty: 0,
                    UniqueLabel: '',
                    InventoryType: 'FIFO',
                    UOM: 'BTL',
                    SearchMode: 'All',
                    RepackProduce: false,
                    //Package
                    PackageServices: [],
                    ValidityInDays: 0,
                    QtyPrompt: false,
                    Membership: '',
                    MembershipID: '',
                    AssociateMembership: false,
                    StoreSpecificQtys: [],
                    EnableAlerts: false,
                    NotifyCustomerInDays: 0,
                    SMSTemplateID: '',
                    CustomField1: '',
                    CustomField2: '',
                    CustomField3: '',
                    CustomField4: '',
                    CustomField5: '',
                    CustomField6: '',
                    Favourite: false,
                    ServiceDuration: 0,
                    BOM: false,
                    BOMManual: false,
                    CanBeUsedAsConsumable: false,
                    BatchTrackingBatchNoRequired: false,
                    BatchTrackingAutoBatchNoRequired: false,
                    BatchTrackingMRPRequired: false,
                    BatchTrackingMfgDateRequired:false,
                    BatchTrackingExpDateRequired: false,
                    BatchTrackingDateFormat: 'mm/yy',
                    OMNIEnabled:false,
                    //UOMs
                    SecondaryUOMs: '',
                    SalesUOM: '',
                    PurchaseUOM: '',
                    //Variants
                    Attributes: [],
                    Variants: [],
                    AutoGenerateSKU: false,
                    CustomSKU: '',
                    ShowAsAdditionalCharges: false
                }
                console.log('product',product)

                if(key && key!==""){
                    // await this.props.updateProduct(product, key)
                }
                else{
                    await this.props.addNewProduct(product);
                }
                newProductAdded = newProductAdded+ 1;
                this.setState({loadingText:"New product created - " + product.Name });
            }
            else{
                console.log('Item brand not found', item.item_id)
            }
        }
        let noCatlogueChanges = false;
        if(newProductAdded ===0 && newCategoryAdded===0){
            noCatlogueChanges = true
        }
        //log excise portal 
        await this.props.saveREExciseLog({
            Type:"Catalogue",
            NewProductAdded: newProductAdded,
            NewCategoryAdded:newCategoryAdded
        });
        this.setState({loading:false, loadingText:"", 
        noCatlogueChanges:noCatlogueChanges,
        newProductAdded:newProductAdded, 
        newCategoryAdded: newCategoryAdded});
        this.onTabSelected(this.state.selectedTabIndex);
    };

    async rjexciseInsertInvoiceDetail(shopCode, invoices){
        // let shopCode = this.state.reStoreCode;
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/rjexciseInsertInvoiceDetail',{invoices:invoices, shopCode:shopCode})
        .then(res => {
            // console.log('getBrandList', res)
            return res.data;
        })
        .catch(err => {
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

    async rjexciseGetBrandMasterList(){
        let shopCode = this.state.reStoreCode;
        console.log('rjexciseGetBrandMasterList shopCode',shopCode)
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/rjexciseGetBrandMasterList',{shopCode:shopCode})
        .then(res => {
            // console.log('getBrandList', res)
            return res.data;
        })
        .catch(err => {
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

    async rjexciseGetProductCat(){
        let shopCode =this.state.reStoreCode;
        console.log('rjexciseGetProductCat shopCode',shopCode)
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/rjexciseGetProductCat',{shopCode:shopCode})
        .then(res => {
            // console.log('rjexciseGetProductCat', res)
            return res.data;
        })
        .catch(err => {
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

    async rjexciseGetPackingList(){
        let shopCode = this.state.reStoreCode;
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/rjexciseGetPackingList',{shopCode:shopCode})
        .then(res => {
            // console.log('rjexciseGetPackingList', res)
            return res.data;
        })
        .catch(err => {
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }
    
    async rjexciseGetPriceListDetail(){
        let shopCode = this.state.reStoreCode;
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/rjexciseGetPriceListDetail',{shopCode:shopCode})
        .then(res => {
            // console.log('rjexciseGetPriceListDetail', res)
            return res.data;
        })
        .catch(err => {
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

    async rjexciseGetLicenseeInvoiceDetail(shopCode, date){
       
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/rjexciseGetLicenseeInvoiceDetail',{shopCode:shopCode, date:date})
        .then(res => {
            // console.log('rjexciseGetPriceListDetail', res)
            return res.data;
        })
        .catch(err => {
            // console.log('Could not get businessname for provided domain name. Try again later.');
        });
    }

    async onSaveExciseSetup(){
        let reStoreCodeError = "";
        let rePurchaseTransactionTypeError ="";
        let reDamageTransactionTypeError="";

        if(this.state.reStoreCode.trim()===""){
            reStoreCodeError= "Please provide excise store code";
        }
        if(this.state.rePurchaseTransactionType.trim()===""){
            rePurchaseTransactionTypeError= "Please select purchase transaction type that will be mapped to excise purchases";
        }
        if(this.state.reDamageTransactionType.trim()===""){
            reDamageTransactionTypeError= "Please select damage transaction type that needs to be pushed to excise portal";
        }
        if(reStoreCodeError!=="" || rePurchaseTransactionTypeError!=="" || reDamageTransactionTypeError!==""){
            this.setState({
                reStoreCodeError:reStoreCodeError,
                rePurchaseTransactionTypeError:rePurchaseTransactionTypeError,
                reDamageTransactionTypeError:reDamageTransactionTypeError
            });
            return;
        }

        this.setState({loading:true});

        this.props.updateStore({
            REStoreCode: this.state.reStoreCode,
            REPurchaseTransactionType:this.state.rePurchaseTransactionType,
            REDamageTransactionType:this.state.reDamageTransactionType
         }, this.props.user.store.key).then(() => {
            this.props.getToastr("Store Saved Successfully");
            this.setState({loading:false});
        })
    }

    getFormattedDate(dateVar){
        let purchaseDate = new Date(dateVar);
        let month = purchaseDate.getMonth();
        let year = purchaseDate.getFullYear();
        let date = purchaseDate.getDate();
        let months= ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
        let formattedDate = date + "-" + months[month] + "-" + year;
        console.log('formattedDate', formattedDate);
        return formattedDate

    }

    async getPurchaseEntry(){
        //call api to get purchase entry 
        // console.log(this.state.purchaseDate);
        this.setState({loading:true, loadingText:"Getting purchase invoice from excise portal. Please wait.."});
        let formattedDate = this.getFormattedDate(this.state.selectedPurchaseDate);
        let shopCode = this.state.reStoreCode;
        let result = await this.rjexciseGetLicenseeInvoiceDetail(shopCode,formattedDate);
        // console.log('result', result)
        let purchases = []
        let noDataFound= true;
        for(let i=0; i<result.length;i++){
            if(result[i].Invoice_No===""){
                continue;
            }
            let index = purchases.findIndex(f=> f.RE_Invoice_No ===result[i].Invoice_No);
            let itemfilter = this.props.config.Products.filter(f=> f.RE_Item_ID === result[i].Item_Id)
            if(itemfilter.length === 0 ){
                alert('Item not found. Please download excise catalogue first');
                break;
            }
            let product =  itemfilter[0];
            if(index===-1){
                let purchaseEntry = {
                    RE_Invoice_No:result[i].Invoice_No,
                    TransactionDate:new Date(result[i].Invoice_Date),
                    LineItems:[]
                }
                purchaseEntry.LineItems.push(this.getPurchaseLineItem(result[i], product))
                purchases.push(purchaseEntry);
            }
            else
            {
                purchases[index].LineItems.push(this.getPurchaseLineItem(result[i], product))
            }
            noDataFound= false;
        }
        for(let i=0;i<purchases.length;i++){
            let totalQty = 0 
            purchases[i].LineItems.map(m=>{
                totalQty = Number(totalQty) + Number(m.Qty)
            })
            purchases[i].BillQty = totalQty
            
        }
        this.setState({purchases:purchases, loading:false, noDataFound:noDataFound})
        // console.log('result', result);
    }

    async onSavePurchaseEntry(index){
        let purchases = this.state.purchases;
        let inventory = purchases[index];
        let storeLocations = this.state.storeLocations;
        console.log('rePurchaseTransactionType',this.state.rePurchaseTransactionType);
        let res = this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted && e.key === this.state.rePurchaseTransactionType);
        console.log('res',res)
        let transactionType = res[0]
        // purchaseEntry
        inventory.TransactionType= transactionType;
        inventory.Vendor = null;
        inventory.RegistrationID= this.props.user.user.RegistrationID
        inventory.Store = this.props.user.store
        inventory.BillLineItemQty = 0 
        inventory.BillFreeQty=0
        inventory.BillSubTotal=0
        inventory.BillDiscount=0
        inventory.BillTaxAmount=0
        inventory.BillAmount=0
        inventory.ParentTransactionNo=''
        inventory.ParentTransactionKey=''
        inventory.Notes=''
        inventory.TaxSummaryList=[]
        inventory.HSNSummary=[]
        inventory.PlaceOfSupply=''
        inventory.StoreLocation= storeLocations.LocationCode + " " +  storeLocations.LocationName
        inventory.StoreLocationID = storeLocations.key
        inventory.RefNo = inventory.RE_Invoice_No
        inventory.BackEndUpdate = false
        inventory.Status= "Completed"
        inventory.ToStoreID =""
        inventory.ToStore =""
        inventory.ChildTransactionNo =""
        inventory.ChildTransactionKey =""
        inventory.BillLineItemTotal = 0 
        inventory.BillRoundOff = 0 
        inventory.BillAdditionalCharges = 0 
        inventory.BillAdditionalDiscount = 0 
        inventory.Converted = false
        inventory.ParentTransactions = null
        inventory.ToLocation= ""
        inventory.ToLocationID=""
        console.log('purchaseEntry', inventory)
        let transactionNo = this.getTransactioNo(transactionType)
        let nextRunningNo = await this.props.getInventoryRunningNo(inventory.Store.key, inventory.TransactionType.key)
        transactionNo = transactionNo + nextRunningNo
        inventory.TransactionNo = transactionNo
        this.props.addInventory(inventory).then((key) => {
            this.props.getToastr("Inventory #" + transactionNo + " saved successfully")
            this.props.updateInventoryRunningNo(inventory.Store.key, inventory.TransactionType.key, nextRunningNo)
            this.props.saveREExciseLog({Type:"Purchase", ExciseTransactionNo: inventory.RE_Invoice_No , PosibleTransactionNo:transactionNo})
        })
    }

    getPurchaseLineItem(obj, product){
        return {
            RE_Item_Id:obj.Item_Id,
            RE_Packing_Code: obj.Packing_Code,
            RE_Brand_Code:obj.Brand_Code,
            RE_Qty_in_Btl: obj.Qty_in_Btl,
            RE_Group_Code: obj.Group_Code,
            LineItemQty: obj.Qty_in_Btl,
            FreeQty : 0,
            Qty:obj.Qty_in_Btl,
            SupplyPrice: 0,
            UOM : '',
            SubTotal : 0,
            ChargeTaxOnProduct:false,
            PriceBasedTax:  false,
            HSNSACCode:'',
            TaxInclusive: false,
            TaxGroup : '',
            TaxID : '',
            DiscountPer : 0,
            DiscountAmount : 0,
            SubTotalWithDiscount: 0,
            TaxableAmount: 0,
            TotalTax : 0,
            Amount : 0,
            Product : product,
            Notes : ''
        }
    }

    getTransactioNo(transactionType) {
        let transactionNo = ""
        transactionType.NumberingFormat.map((parameters) => {
            let para = ""
            if (parameters.Option === "text") {
                para = parameters.Value
            }
            else if (parameters.Option === "storecode") {
                para = this.props.user.store.StoreCode
            }
            // else if (parameters.Option == "locationcode") {
            //     para = this.props.user.cashRegister.LocationCode
            // }
            else if (parameters.Option === "calendardmonthyear") {
                let d = new Date();
                let a = d.getFullYear() + ""
                para = (d.getMonth() + 1) + a.substring(2, 4)
                if (para.length < 3) {
                    para = "0" + para
                }
            }
            else if (parameters.Option === "fiscalyear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            else if (parameters.Option === "calendarmonth") {
                let d = new Date();
                let a = (d.getMonth() + 1) + ""
                if (a.length < 2) {
                    para = "0" + a
                }
            }
            else if (parameters.Option === "calendaryear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            transactionNo = transactionNo + para + parameters.Separator
        })
        return transactionNo
    }

     onTabSelected(index){
        if(index===3){
            this.props.getRELogs("Catalogue", this.props.user.user.RegistrationID, this.props.user.store.key).then((result)=>{
                this.setState({logCount:result.totalItemsCount , logs:result.searchResult})
            })
        }
        else if(index===1){
            this.props.getRELogs("Purchase", this.props.user.user.RegistrationID, this.props.user.store.key).then((result)=>{
                this.setState({logCount:result.totalItemsCount , logs:result.searchResult})
            })
        }
        else if(index===0){
            this.props.getRELogs("Sales", this.props.user.user.RegistrationID, this.props.user.store.key).then((result)=>{
                this.setState({logCount:result.totalItemsCount , logs:result.searchResult})
            })
        }
        else if(index===2){
            this.props.getRELogs("Damage", this.props.user.user.RegistrationID, this.props.user.store.key).then((result)=>{
                this.setState({logCount:result.totalItemsCount , logs:result.searchResult})
            })
        }
        // {this.state.selectedTabIndex===0 && this.renderSales()}
        // {this.state.selectedTabIndex===1 && this.renderPurchase()}
        // {this.state.selectedTabIndex===3 && this.renderCatalogue()}
        // {this.state.selectedTabIndex ===4 && this.renderSetup()}

        this.setState({selectedTabIndex:index})
    }

    renderLogs(){
        return <React.Fragment>
            <br/>
            <br/>
        <div className="row">
            <div className="col-md-6">
                <b>Logs</b>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
             <table className = "table table-bordered table-striped">
                    <thead>
                        <tr>
                            <td>Action On</td>
                            <td>Action By</td>
                            {this.state.selectedTabIndex===0  && <React.Fragment>
                                <td>Count</td>
                                <td>Qty</td>
                                <td>Amount</td>
                                {/* <td>API response</td>                                 */}
                            </React.Fragment>}
                            {this.state.selectedTabIndex===2  && <React.Fragment>
                                <td>Count</td>
                                <td>Qty</td>
                                {/* <td>Amount</td> */}
                            </React.Fragment>}
                            {this.state.selectedTabIndex===3  && <React.Fragment>
                                <td>Category Added</td>
                                <td>Products Added</td>                                
                            </React.Fragment>}
                            {this.state.selectedTabIndex===1  && <React.Fragment>
                                <td>Excise Transaction No</td>
                                <td>Posible Transaction No</td>                                
                            </React.Fragment>}
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.logs.map((m,index)=><tr key={"tr_"+ index}>
                        <td>{(new Date(m.ActionOn).toISOString().split('T')[0])}</td>
                        <td>{m.ActionBy}</td>
                        {this.state.selectedTabIndex===0  && <React.Fragment>
                                <td>{m.Count}</td>
                                <td>{m.Qty}</td>
                                <td>{m.Amount}</td>
                                {/* <td>{m.APIResult}</td>                                 */}
                            </React.Fragment>}
                        {this.state.selectedTabIndex===2  && <React.Fragment>
                                <td>{m.Count}</td>
                                <td>{m.Qty}</td>
                                {/* <td>{m.Amount}</td> */}
                            </React.Fragment>}
                        {this.state.selectedTabIndex===3  && <React.Fragment>
                                <td>{m.NewCategoryAdded}</td>
                                <td>{m.NewProductAdded}</td>                     
                            </React.Fragment>}
                        {this.state.selectedTabIndex===1  && <React.Fragment>
                            <td>{m.ExciseTransactionNo}</td>
                            <td>{m.PosibleTransactionNo}</td>                                
                        </React.Fragment>}
                    </tr>)} 
                    </tbody>
                </table>
                </div>
            </div>
        </React.Fragment>
    }

    renderDamage(){
        return <React.Fragment>
        <div className="row">
            <div className="col-md-2">
                <Input  type="date" text="Date" lable="Please select date for which transactions are to be pushed" value={this.state.selectedDamageDate} onChange={(value)=>{this.setState({selectedSalesDate:value})}}/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-2">
                <Button type="secondary" text="Get damage data" onClick={this.getDamageData}/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <table className = "table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Product</th>
                            <th>Qty</th>
                            {/* <th>Amount</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.damage.map((m,index)=><tr>
                                <td>{index + 1}</td>
                                <td>{m.product}</td>
                                <td>{m.total_quantity}</td>
                                {/* <td>{m.total_damage}</td> */}
                        </tr>)}
                        <tr>
                            <td colSpan="2">Total</td>
                            <td>{this.state.damageTotalQty}</td>
                            {/* <td>{this.state.damageTotalAmount}</td> */}

                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
       {this.state.damage.length> 0 && <div className="row">
            <div className="col-md-3">
                <Button type="primary" text="Upload data to Excise portal" onClick={this.uploadDamageDataToExciePortal}/>
            </div>
        </div>}
        {this.renderLogs()}
        </React.Fragment> 
    }

    renderSales(){
        return <React.Fragment>
        <div className="row">
            <div className="col-md-2">
                <Input  type="date" text="Date" lable="Please select date for which transactions are to be pushed" value={this.state.selectedSalesDate} onChange={(value)=>{this.setState({selectedSalesDate:value})}}/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-2">
                <Button type="secondary" text="Get sales data" onClick={this.getSalesData}/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <table className = "table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Product</th>
                            <th>Qty</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.sales.map((m,index)=><tr>
                                <td>{index + 1}</td>
                                <td>{m.product}</td>
                                <td>{m.total_quantity}</td>
                                <td>{m.total_sales}</td>
                        </tr>)}
                        <tr>
                            <td colSpan="2">Total</td>
                            <td>{this.state.salesTotalQty}</td>
                            <td>{this.state.salesTotalAmount}</td>

                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
       {this.state.sales.length> 0 && <div className="row">
            <div className="col-md-3">
                <Button type="primary" text="Upload data to Excise portal" onClick={this.uploadSalesDataToExcisePortal}/>
            </div>
        </div>}
        {this.renderLogs()}
        </React.Fragment> 
    }

    renderSetup(){
        return <React.Fragment>
            <div className="row">
                <div className="col-md-2">
                    <Input type="text" 
                    label="Excise Store Code" 
                    value={this.state.reStoreCode} 
                    error={this.state.reStoreCodeError}
                    onChange={(value)=>{this.setState({reStoreCode:value.trim()})}}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                 <Input type={"select"} 
                    label={"Purchase transaction type"} 
                    error={this.state.rePurchaseTransactionTypeError}
                    value={this.state.rePurchaseTransactionType} 
                    onChange={(value)=>{this.setState({rePurchaseTransactionType:value})}}>
                    <option value="">Select</option>
                    {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                </Input>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                 <Input type={"select"} 
                 label={"Damage transaction type"} 
                 value={this.state.reDamageTransactionType} 
                 error={this.state.reDamageTransactionTypeError}
                    onChange={(value)=>{this.setState({reDamageTransactionType:value})}}>
                    <option value="">Select</option>
                    {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                </Input>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <Button type="primary" text="Save" onClick={this.onSaveExciseSetup}/>
                </div>
            </div>
        </React.Fragment>
    }

    renderCatalogue(){
        return <React.Fragment>
            <div className="row">
                <div className="col-md-2">
                    <Button type="secondary" text="Get catalogue" onClick={this.getCatalogue}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {this.state.newCategoryAdded > 0 &&<div>No of new categories added - {this.state.newCategoryAdded}</div>}
                    {this.state.newProductAdded > 0 &&<div>No of new prodcts added - {this.state.newProductAdded }</div>}  
                    {this.state.noCatlogueChanges && <div>No new changes found</div>} 
                </div>
            </div>
            {this.renderLogs()}
        </React.Fragment>
    }

    renderPurchase(){
        return <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <Input type="date" 
                    label="Please select purchase date" 
                    value={this.state.selectedPurchaseDate} 
                    onChange={(value)=>{this.setState({selectedPurchaseDate:value})}}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <Button type="secondary" text="Get purchase data from excise portal" onClick={this.getPurchaseEntry}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <table className = "table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Invoice No</th>
                                {/* <th>Item Count</th> */}
                                <th>Qty</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.purchases.map((m,index)=><React.Fragment><tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        <div>{m.RE_Invoice_No}</div>
                                        <div><a onClick={()=> this.setState({lineItemSelected:index})}>Click here to view items</a></div>
                                    </td>
                                    {/* <td>{(m.LineItems.length + 1)}</td> */}
                                    <td>{m.BillQty}</td>
                                    <td><Button type="secondary" text="SAVE" onClick={()=>{this.onSavePurchaseEntry(index)}}/></td>
                                </tr>
                                {this.state.lineItemSelected === index && <tr>
                                    <td colSpan="4">
                                        <table className = "table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No.</th>
                                                    <th>Item</th>
                                                    <th>Qty</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {m.LineItems.map((l,index)=><tr>
                                                    <td>{index + 1}</td>
                                                    <td>{l.Product.Name}</td>
                                                    <td>{l.Qty}</td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>}
                            </React.Fragment>)}
                            {(this.state.purchases.length ===0 && this.state.noDataFound) && <tr>
                                <td colSpan="4">No purchase invoices found for selected date</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            {this.renderLogs()}
        </React.Fragment>
    }

    render(){
       return <React.Fragment>
           <div className="header-margin">
                <div className="list-page">
                    <TabStrip tabButtons={[
                        {text:"SAlES"},
                        {text:"PURCHASE"},
                        {text:"DAMAGE"},
                        {text:"CATALOGUE"},
                        {text:"SETUP"},
                        ]}
                        selectedTabIndex={this.state.selectedTabIndex}
                        onTabSelected={(index)=>{this.onTabSelected(index)}}
                    />
                     <div className="list-page-content">
                        {this.state.loading && <Spinner text={this.state.loadingText}/>} 
                        {this.state.selectedTabIndex===0 && this.renderSales()}
                        {this.state.selectedTabIndex===1 && this.renderPurchase()}
                        {this.state.selectedTabIndex===2 && this.renderDamage()}
                        {this.state.selectedTabIndex===3 && this.renderCatalogue()}
                        {this.state.selectedTabIndex ===4 && this.renderSetup()}
                     </div>
                    {/* {this.state.selectedTabIndex === 1 && this.renderPurchase()}
                    {this.state.selectedTabIndex === 2 && this.renderSetup()} */}
                </div> 
        </div>
        </React.Fragment>
    }
}

export default RESettings;