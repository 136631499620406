import React, { Component } from "react";
import Input from "../2/Controls/Input";
import Button from "../2/Controls/Button";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import "./PincodeIntegrationSettings.css";
import { values } from "lodash";

class PincodeIntegrationSettings extends Component {
  constructor() {
    super();
    this.state = {
      pincodeClientID: "",
      pincodeClientIDError: "",
      pincodeClientSecret: "",
      pincodeClientSecretError: "",
      //   pincodeMerchantIDError: "",
      //   pincodeMerchantID: "",
      processing: false,
      processingStoreSave: "",
      stores: [],
    };
    this.onSaveSettings = this.onSaveSettings.bind(this);
    this.onSaveStoreSettings = this.onSaveStoreSettings.bind(this);
  }

  componentDidMount() {
    let stores = this.props.config.Stores;
    let pincodeClientID = this.props.registration.registration.hasOwnProperty(
      "PincodeIntegration_Client_ID"
    )
      ? this.props.registration.registration.PincodeIntegration_Client_ID
      : "";
    let pincodeClientSecret = this.props.registration.registration.hasOwnProperty(
      "PincodeIntegration_Client_Secret"
    )
      ? this.props.registration.registration.PincodeIntegration_Client_Secret
      : "";

    // let pincodeMerchantID = this.props.registration.registration.hasOwnProperty(
    //   "PincodeIntegration_Merchant_ID"
    // )
    //   ? this.props.registration.registration.PincodeIntegration_Merchant_ID
    //   : "";
    this.setState({
      pincodeClientID: pincodeClientID,
      pincodeClientSecret: pincodeClientSecret,
      //   pincodeMerchantID: pincodeMerchantID,
      stores: stores,
    });
  }

  onSaveSettings() {
    this.setState({ processing: true });

    let pincodeClientIDError = "";
    let pincodeClientSecretError = "";
    let pincodeMerchantIDError = "";
    if (this.state.pincodeClientID.trim() === "") {
      pincodeClientIDError = "Please provide value";
    }
    if (this.state.pincodeClientSecret.trim() === "") {
      pincodeClientSecretError = "Please provide value";
    }
    // if (this.state.pincodeMerchantID.trim() === "") {
    //   pincodeMerchantIDError = "Please provide value";
    // }

    if (pincodeClientIDError !== "" || pincodeClientSecretError !== "") {
      this.setState({
        pincodeClientIDError: pincodeClientIDError,
        pincodeClientSecretError: pincodeClientSecretError,
        // pincodeMerchantIDError: pincodeMerchantIDError,
        processing: false,
      });
      return;
    }

    // PincodeIntegration_Merchant_ID: this.state.pincodeMerchantID,
    this.props
      .updateRegistrationGeneralDetails(
        this.props.registration.registration.key,
        {
          PincodeIntegration_Client_ID: this.state.pincodeClientID,
          PincodeIntegration_Client_Secret: this.state.pincodeClientSecret,
        }
      )
      .then(() => {
        this.props.getToastr("Settings saved successfully");
        this.props.getRegistration(this.props.registration.registration.key);
        this.setState({ processing: false });
        // this.props.onClose()
      });
  }

  async onSaveStoreSettings() {
    this.setState({ processingStoreSave: true });
    let stores = this.state.stores;
    for (let i = 0; i < stores.length; i++) {
      await this.props.updateStore(
        {
          PincodeServiceProviderID: stores[i].hasOwnProperty(
            "PincodeServiceProviderID"
          )
            ? stores[i].PincodeServiceProviderID
            : "",
        },
        stores[i].key,
        "Pincode setting update"
      );
    }
    this.props.getToastr("Store settings saved successfully");
    stores = await this.props
      .getStores(this.props.registration.registration.key, 0, 1000)
      .then((result) => {
        return result.searchResult;
      });
    this.props.setStores(stores);
    this.setState({ processingStoreSave: false });
  }

  render() {
    return (
      <Page
        title="Pincode Settings"
        showSaveButton={false}
        showCancelButton={false}
        processing={this.state.saving}
      >
        <PageSection title="Client ID and Secret Key">
          <div className="row">
            <div className="col-md-3">
              <div className="pincodeintegrationsetting-info">
                Client Id and Client Sceret will be provided by Pincode team.
                Please keep this information confidential.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Input
                type="text"
                label="Client Id"
                onChange={(value) => {
                  this.setState({
                    pincodeClientID: value,
                    pincodeClientIDError: "",
                  });
                }}
                value={this.state.pincodeClientID}
                error={this.state.pincodeClientIDError}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Input
                type="text"
                label="Client Secret"
                onChange={(value) => {
                  this.setState({
                    pincodeClientSecret: value,
                    pincodeClientSecretError: "",
                  });
                }}
                value={this.state.pincodeClientSecret}
                error={this.state.pincodeClientSecretError}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-3">
              <Input
                type="text"
                label="Pincode Merchant Id"
                onChange={(value) => {
                  this.setState({
                    pincodeMerchantID: value,
                    pincodeMerchantIDError: "",
                  });
                }}
                value={this.state.pincodeMerchantID}
                error={this.state.pincodeMerchantIDError}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-3">
              <Button
                type="primary"
                text="Save"
                onClick={this.onSaveSettings}
                processing={this.state.processing}
              />
            </div>
          </div>
        </PageSection>
        <PageSection title="Pincode Store Mapping">
          {/* <div className="row">
            <div className="col-md-3">
              <div className="pincodeintegrationsetting-info">
                Pincode store mapping
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-4">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th className="pincodeintegration-col">Store</th>
                    <th className="pincodeintegration-col">Pincode Store ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.stores.map((s, index) => (
                    <tr>
                      <td>
                        {s.StoreCode} - {s.StoreName}
                      </td>
                      <td>
                        <Input
                          type="text"
                          onChange={(value) => {
                            let stores = this.state.stores;
                            stores[index].PincodeServiceProviderID = value;
                            this.setState({ stores: stores });
                          }}
                          value={
                            s.hasOwnProperty("PincodeServiceProviderID")
                              ? s.PincodeServiceProviderID
                              : ""
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <Button
                type="primary"
                text="Save"
                onClick={this.onSaveStoreSettings}
                processing={this.state.processingStoreSave}
              />
            </div>
          </div>
        </PageSection>
      </Page>
    );
  }
}

export default PincodeIntegrationSettings;
