import React, { Component } from "react";
import { isNullOrUndefined } from "util";
import Spinner from "../Common/Spinner";
import ImageButton from "../2/Controls/ImageButton";
import { isMobileApp } from "../../actions/Util";

import "./ProductQuickEdit.css";

class ProductQuickEdit extends Component {
  constructor() {
    super();
    this.state = {
      searchText: "",
      itemsCountPerPage: 20,
      searchProduct: "",
      searchProductType: "",
      searchCategory: "",
      categoryID: "",
      searchSubCategory: "",
      subCategoryID: "",
      products: [],
      outOfStock: false,
      recordFrom: 0,
      totalItemsCount: 0,
      activePage: 1,
    };
    this.loadProducts = this.loadProducts.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onFirstClick = this.onFirstClick.bind(this);
    this.onLastClick = this.onLastClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }

  async componentDidMount() {
    this.loadProducts(
      0,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      "",
      "",
      "",
      ""
    );
  }

  showPaginationStatus() {
    let paginationStatus;
    if (this.state.totalItemsCount === 0) {
      paginationStatus = "No record(s) found";
    } else if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    return (
      <div
        className={
          isMobileApp() ? "list-page-mobile-pagination" : "list-page-pagination"
        }
      >
        {paginationStatus}
      </div>
    );
  }

  onFirstClick() {
    let pageNumber = 1;
    let recordFrom = 0;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadProducts(
      0,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      "",
      "",
      "",
      ""
    );
  }

  onPreviousClick() {
    if (this.state.activePage > 0) {
      let pageNo = Number(this.state.activePage) - 1;
      console.log("pageNo", pageNo);
      let recordFrom = pageNo * this.state.itemsCountPerPage;
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadProducts(
        recordFrom,
        "active",
        this.state.searchText,
        this.state.searchProductType,
        this.state.searchCategory,
        this.state.searchSubCategory,
        "",
        "",
        "",
        ""
      );
    }
  }

  onNextClick() {
    let pageNo = Number(this.state.activePage) + 1;
    let recordFrom = pageNo * this.state.itemsCountPerPage;
    if (recordFrom < this.state.totalItemsCount) {
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadProducts(
        recordFrom,
        "active",
        this.state.searchText,
        this.state.searchProductType,
        this.state.searchCategory,
        this.state.searchSubCategory,
        "",
        "",
        "",
        ""
      );
    }
  }

  onLastClick() {
    let pageNo = Number(
      Number(
        Number(this.state.totalItemsCount) /
          Number(this.state.itemsCountPerPage)
      ).toFixed(0)
    );
    // console.log("pageNo", pageNo);

    let recordFrom = Number(pageNo) * Number(this.state.itemsCountPerPage);
    this.setState({ activePage: pageNo, recordFrom: recordFrom });
    this.loadProducts(
      recordFrom,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      "",
      "",
      "",
      ""
    );
    // this.loadDeliveryDetailReport(
    //   recordFrom,
    //   this.state.itemsCountPerPage,
    //   this.state.searchPendingReadyForPickupDeliveredAllRecords,
    //   this.state.searchTransactionNo,
    //   this.state.searchTransactionFromDate,
    //   this.state.searchTransactionToDate,
    //   this.state.searchCustomerDetail,
    //   this.state.searchStore,
    //   this.state.searchEmployeeName,
    //   this.state.orderNo
    // );
  }

  onCategoryChange = (e) => {
    let categoryID = e.target.value;
    let searchCategory = e.target.options[e.target.selectedIndex].text;
    this.setState({
      searchCategory: searchCategory,
      categoryID: categoryID,
      searchSubCategory: "",
      subCategoryID: "",
    });
    //this.setState({subCategoryList: this.props.product.Categories[categoryIndex].SubCategories})
  };

  onSubCategoryChange = (e) => {
    let subCategoryID = e.target.value;
    let searchSubCategory = e.target.options[e.target.selectedIndex].text;
    this.setState({
      searchSubCategory: searchSubCategory,
      subCategoryID: subCategoryID,
    });
  };

  loadProducts(
    from,
    option,
    searchText,
    searchProductType,
    searchCategory,
    searchSubCategory,
    searchBrand,
    searchTags,
    searchDescription,
    searchHSNSAC
  ) {
    this.setState({ loading: true });
    // console.log('searchText',searchText)
    this.props
      .getProducts(
        this.props.user.user.RegistrationID,
        from,
        this.state.itemsCountPerPage,
        option,
        searchText,
        searchProductType,
        searchCategory,
        searchSubCategory,
        searchBrand,
        searchTags,
        searchDescription,
        searchHSNSAC
      )
      .then((result) => {
        let products = [];
        result.searchResult.map((data) => {
          // console.log(JSON.stringify(data))
          let product = {
            ProductType: data.ProductType,
            SKU: data.SKU,
            BarCode: !isNullOrUndefined(data.BarCode) ? data.BarCode : "",
            Brand: !isNullOrUndefined(data.Brand) ? data.Brand : "",
            Category: data.Category,
            SubCategory: !isNullOrUndefined(data.SubCategory)
              ? data.SubCategory
              : "",
            Name: data.Name,
            Description: !isNullOrUndefined(data.Description)
              ? data.Description
              : "",
            SupplyPrice: !isNullOrUndefined(data.SupplyPrice)
              ? data.SupplyPrice
              : "",
            RetailPrice: !isNullOrUndefined(data.RetailPrice)
              ? data.RetailPrice
              : "",
            MRP: !isNullOrUndefined(data.MRP) ? data.MRP : "",
            key: data.key,
            Variants: data.hasOwnProperty("Variants") ? data.Variants : [],
            InventoryType: data.hasOwnProperty("InventoryType")
              ? data.InventoryType
              : "",
            OMNIEnabled: data.hasOwnProperty("OMNIEnabled")
              ? data.OMNIEnabled
              : false,
            OutOfStock: data.hasOwnProperty("OutOfStock")
              ? data.OutOfStock
              : false,
            ParentID: "",
            StoreSpecificPrices: data.StoreSpecificPrices,
            StoreSpecificPricing: data.StoreSpecificPricing,
            AccessibleToSelectedStores: data.AccessibleToSelectedStores,
          };

          if (data.hasOwnProperty("AccessibleToSelectedStores")) {
            if (data.AccessibleToSelectedStores) {
              let storePrice = data.StoreSpecificPrices.filter(
                (f) => f.StoreID === this.props.user.store.key
              );
              if (storePrice.length > 0) {
                product.RetailPrice = storePrice[0].RetailPrice;
                product.SupplyPrice = storePrice[0].SupplyPrice;
                product.MRP = storePrice[0].MRP;
              }
            }
          }
          if (data.hasOwnProperty("StoreSpecificPricing")) {
            if (data.StoreSpecificPricing) {
              let storePrice = data.StoreSpecificPrices.filter(
                (f) => f.StoreID === this.props.user.store.key
              );

              if (storePrice.length > 0) {
                product.SupplyPrice = storePrice[0].SupplyPrice;
                product.Markup = storePrice[0].Markup;
                product.RetailPrice = storePrice[0].RetailPrice;
                product.MRP = storePrice[0].MRP;
              }
            }
          }
          products.push(product);

          if (product.Variants.length > 0) {
            for (let i = 0; i < product.Variants.length; i++) {
              let variant = product.Variants[i];
              console.log("variant", variant);
              if (variant.IsDeleted) {
                continue;
              }
              let variantProduct = {
                ProductType: data.ProductType,
                SKU: variant.SKU,
                BarCode: !isNullOrUndefined(data.BarCode) ? data.BarCode : "",
                Brand: !isNullOrUndefined(data.Brand) ? data.Brand : "",
                Category: data.Category,
                SubCategory: !isNullOrUndefined(data.SubCategory)
                  ? data.SubCategory
                  : "",
                Name: data.Name + " / " + variant.Name,
                Description: !isNullOrUndefined(data.Description)
                  ? data.Description
                  : "",
                Type: data.ProductType,
                SupplyPrice: !isNullOrUndefined(variant.SupplyPrice)
                  ? variant.SupplyPrice
                  : "",
                RetailPrice: !isNullOrUndefined(variant.RetailPrice)
                  ? variant.RetailPrice
                  : "",
                MRP: !isNullOrUndefined(variant.MRP) ? variant.MRP : "",
                Variants: [],
                InventoryType: data.hasOwnProperty("InventoryType")
                  ? data.InventoryType
                  : "",
                OMNIEnabled: variant.hasOwnProperty("OMNIEnabled")
                  ? variant.OMNIEnabled
                  : data.hasOwnProperty("OMNIEnabled")
                  ? data.OMNIEnabled
                  : false,
                OutOfStock: variant.hasOwnProperty("OutOfStock")
                  ? variant.OutOfStock
                  : data.hasOwnProperty("OutOfStock")
                  ? data.OutOfStock
                  : false,
                ParentID: data.key,
                key: variant.ProductID,
              };
              products.push(variantProduct);
            }
          }
        });
        this.setState({
          products: products,
          totalItemsCount: result.totalItemsCount,
          loading: false,
          recordFrom: from,
        });
        // this.showPaginationStatus()
      });
  }

  onSearchClick() {
    this.setState({ recordFrom: 0 });
    this.loadProducts(
      0,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      "",
      "",
      "",
      ""
    );
  }

  async onSaveClick() {
    let products = this.state.products;
    if (products.filter((f) => f.Updated).length === 0) {
      alert("No records to update");
      return;
    }
    this.setState({ loading: true });
    for (let i = 0; i < products.length; i++) {
      let product = products[i];

      if (product.Updated) {
        console.log("onSaveClick product", product);
        products[i].Updated = false;
        //if parent is has variant or has variants
        if (product.Variants.length > 0) {
          //parent prodcut with variants
          // let parentId = product.Variants.length > 0 ? product.key : product.ParentID
          let variantProducts = [];
          for (let p = 0; p < products.length; p++) {
            if (products[p].ParentID === product.key) {
              products[p].Updated = false;
              variantProducts.push(products[p]);
            }
          }
          for (let l = 0; l < product.Variants.length; l++) {
            for (let k = 0; k < variantProducts.length; k++) {
              if (product.Variants[l].ProductID === variantProducts[k].key) {
                product.Variants[l].SupplyPrice =
                  variantProducts[k].SupplyPrice;
                product.Variants[l].RetailPrice =
                  variantProducts[k].RetailPrice;
                product.Variants[l].MRP = variantProducts[k].MRP;
                product.Variants[l].OMNIEnabled =
                  variantProducts[k].OMNIEnabled;
                product.Variants[l].OutOfStock = variantProducts[k].OutOfStock;
              }
            }
          }
          await this.props.updateProduct(
            {
              SupplyPrice: product.SupplyPrice,
              RetailPrice: product.RetailPrice,
              MRP: product.MRP,
              OMNIEnabled: product.OMNIEnabled,
              OutOfStock: product.OutOfStock,
              Variants: product.Variants,
            },
            product.key
          );
          // console.log('Parent condition')
        } else if (product.ParentID !== "") {
          //child product
          let parentProduct = products.filter(
            (f) => f.key === product.ParentID
          )[0];
          let variantProducts = products.filter(
            (f) => f.ParentID === product.key
          );
          for (let l = 0; l < parentProduct.Variants.length; l++) {
            for (let k = 0; k < variantProducts.length; k++) {
              if (
                parentProduct.Variants[l].ProductID === variantProducts[k].key
              ) {
                parentProduct.Variants[l].SupplyPrice =
                  variantProducts[k].SupplyPrice;
                parentProduct.Variants[l].RetailPrice =
                  variantProducts[k].RetailPrice;
                parentProduct.Variants[l].MRP = variantProducts[k].MRP;
                parentProduct.Variants[l].OMNIEnabled =
                  variantProducts[k].OMNIEnabled;
                parentProduct.Variants[l].OutOfStock =
                  variantProducts[k].OutOfStock;
              }
            }
          }
          await this.props.updateProduct(
            {
              SupplyPrice: parentProduct.SupplyPrice,
              RetailPrice: parentProduct.RetailPrice,
              MRP: parentProduct.MRP,
              OMNIEnabled: parentProduct.OMNIEnabled,
              OutOfStock: parentProduct.OutOfStock,
              Variants: parentProduct.Variants,
            },
            parentProduct.key
          );
          // console.log('Child condition')
        } else {
          //product without variant
          console.log("update called");
          console.log(
            "product.StoreSpecificPricing",
            product.StoreSpecificPricing
          );
          if (
            product.StoreSpecificPrices &&
            product.StoreSpecificPrices.length > 0
          ) {
            for (let i = 0; i < product.StoreSpecificPrices.length; i++) {
              if (
                product.StoreSpecificPrices[i].StoreID ===
                this.props.user.store.key
              ) {
                product.StoreSpecificPrices[i].SupplyPrice =
                  product.SupplyPrice;
                product.StoreSpecificPrices[i].RetailPrice =
                  product.RetailPrice;
                product.StoreSpecificPrices[i].MRP = product.MRP;
              }
            }
          }

          await this.props.updateProduct(
            {
              SupplyPrice: product.SupplyPrice,
              RetailPrice: product.RetailPrice,
              MRP: product.MRP,
              OMNIEnabled: product.OMNIEnabled,
              OutOfStock: product.OutOfStock,
              StoreSpecificPrices: product.StoreSpecificPrices,
            },
            product.key
          );
          // console.log('Product condition')
        }
      }
    }
    alert("Data saved successfully");
    setTimeout(
      function() {
        //Start the timer
        this.setState({ loading: false });
        this.onSearchClick();
      }.bind(this),
      3000
    );
  }

  renderProductImage(prodcut) {
    let image = "";
    if (prodcut.Images && prodcut.Images.length > 0) {
      image = prodcut.Images[0].ImagePath;
    }
    return (
      <div className="productquickedit-prodcut-image">
        {image !== "" && <img src={image} />}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section className="content">
            <div className="box ">
              <div className="box-header with-border">
                <font className="productquickedit-page-header-title">
                  Products - Quick Edit
                </font>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group">
                      <label>Product Type</label>
                      <select
                        type="text"
                        className="form-control"
                        onKeyPress={this.handleKeyPress}
                        onChange={(evt) => {
                          this.setState({
                            searchProductType:
                              evt.target.options[evt.target.selectedIndex].text,
                          });
                        }}
                        value={this.state.productTypeID}
                      >
                        <option value="">All</option>
                        <option value={1}>Goods for sale</option>
                        <option value={2}>Goods not for sale</option>
                        <option value={3}>Services</option>
                        <option value={4}>Wallet Recharge</option>
                        <option value={5}>Package</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                    <div className="form-group">
                      <label>Category</label>
                      <select
                        className="form-control"
                        value={this.state.categoryID}
                        onChange={this.onCategoryChange}
                      >
                        <option value="">All</option>
                        {this.props.config.ProductCategories.filter(
                          (e) => e.ParentID === "" && !e.IsDeleted
                        ).map((category) => (
                          <option value={category.key}>{category.Name}</option>
                        ))}
                      </select>
                      {/* <input type="text" className="form-control input-md"
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCategory}
                                            onChange={(evt) => this.setState({ searchCategory: evt.target.value })} /> */}
                    </div>
                  </div>
                  <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                    <div className="form-group">
                      <label>Sub Category</label>
                      <select
                        className="form-control"
                        value={this.state.subCategoryID}
                        onChange={this.onSubCategoryChange}
                      >
                        <option value="">All</option>
                        {this.props.config.ProductSubCategories.filter(
                          (e) =>
                            e.ParentID === this.state.categoryID && !e.IsDeleted
                        ).map((subCategory, index) => (
                          <option value={subCategory.key}>
                            {subCategory.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2" style={{ paddingLeft: "0px" }}>
                    <div className="form-group">
                      <label>Product name, SKU, Barcode</label>
                      <input
                        type="text"
                        className="form-control input-md"
                        onKeyPress={this.handleKeyPress}
                        value={this.state.searchText}
                        onChange={(evt) =>
                          this.setState({ searchText: evt.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-2" style={{ marginLeft: "0px" }}>
                    <div className="form-group">
                      <label>No of records</label>
                      <select
                        type="text"
                        className="form-control"
                        onKeyPress={this.handleKeyPress}
                        onChange={(evt) => {
                          this.setState({
                            itemsCountPerPage: evt.target.value,
                          });
                        }}
                        value={this.state.productTypeID}
                      >
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-2" style={{ marginLeft: "0px" }}>
                    <button
                      type="button"
                      className="btn btn-default btn-flat btn-md pull-right"
                      style={{ marginTop: "25px", marginLeft: "5px" }}
                      onClick={this.onSearchClick}
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-default btn-flat btn-md pull-right"
                      style={{ marginTop: "25px" }}
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                {!this.state.loading && this.state.totalItemsCount > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-success btn-flat btn-md pull-right"
                        onClick={this.onSaveClick}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
                <br />
                {this.state.loading && <Spinner hideOverlay={true} />}
                <div className="row">
                  <div className="col-md-12">
                    {!this.state.loading && (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Code</th>
                            <th>Product</th>
                            {/* <th>Supply Price</th> */}
                            <th>Retail Price</th>
                            <th>MRP</th>
                            <th>
                              <input
                                type="checkbox"
                                checked={this.state.showOnline}
                                onChange={() => {
                                  let showOnline = !this.state.showOnline;
                                  let products = this.state.products;
                                  products.map((m) => {
                                    m.OMNIEnabled = showOnline;
                                    m.Updated = true;
                                  });
                                  this.setState({
                                    products: products,
                                    showOnline: showOnline,
                                  });
                                }}
                              />
                              Show Online
                            </th>
                            <th>
                              <input
                                type="checkbox"
                                checked={this.state.outOfStock}
                                onChange={() => {
                                  let outOfStock = !this.state.outOfStock;
                                  let products = this.state.products;
                                  products.map((m) => {
                                    m.OutOfStock = outOfStock;
                                    m.Updated = true;
                                  });
                                  this.setState({
                                    products: products,
                                    outOfStock: outOfStock,
                                  });
                                }}
                              />
                              Out Of Stock
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.products.map((product, index) => (
                            <tr key={product.key}>
                              <td>{this.renderProductImage(product)}</td>
                              <td>
                                <div>{product.SKU}</div>
                                <div>{product.BarCode}</div>
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {product.ParentID === "" && (
                                  <div>{product.Name}</div>
                                )}
                                {product.ParentID !== "" && (
                                  <div>
                                    <i className="fa fa-arrow-right"></i>
                                    &nbsp;&nbsp;&nbsp;{product.Name}
                                  </div>
                                )}
                              </td>
                              {/* <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <input
                                  type="number"
                                  value={product.SupplyPrice}
                                  onChange={(e) => {
                                    let products = this.state.products;
                                    products[index].SupplyPrice =
                                      e.target.value;
                                    products[index].Updated = true;
                                    this.setState({ products: products });
                                  }}
                                />
                              </td> */}
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <input
                                  type="number"
                                  value={product.RetailPrice}
                                  onChange={(e) => {
                                    let products = this.state.products;
                                    products[index].RetailPrice =
                                      e.target.value;
                                    products[index].Updated = true;
                                    this.setState({ products: products });
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <input
                                  type="number"
                                  value={product.MRP}
                                  onChange={(e) => {
                                    let products = this.state.products;
                                    products[index].MRP = e.target.value;
                                    products[index].Updated = true;
                                    this.setState({ products: products });
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={product.OMNIEnabled}
                                  onChange={() => {
                                    let products = this.state.products;
                                    products[index].OMNIEnabled = !products[
                                      index
                                    ].OMNIEnabled;
                                    products[index].Updated = true;
                                    if (products[index].Variants.length > 0) {
                                      //parent prodcut with variants
                                      for (
                                        let p = 0;
                                        p < products.length;
                                        p++
                                      ) {
                                        if (
                                          products[p].ParentID ===
                                          products[index].key
                                        ) {
                                          products[p].Updated = true;
                                          products[p].OMNIEnabled =
                                            products[index].OMNIEnabled;
                                        }
                                      }
                                    }
                                    this.setState({ products: products });
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={product.OutOfStock}
                                  onChange={() => {
                                    let products = this.state.products;
                                    products[index].OutOfStock = !products[
                                      index
                                    ].OutOfStock;
                                    products[index].Updated = true;
                                    if (products[index].Variants.length > 0) {
                                      //parent prodcut with variants
                                      for (
                                        let p = 0;
                                        p < products.length;
                                        p++
                                      ) {
                                        if (
                                          products[p].ParentID ===
                                          products[index].key
                                        ) {
                                          products[p].Updated = true;
                                          products[p].OutOfStock =
                                            products[index].OutOfStock;
                                        }
                                      }
                                    }
                                    this.setState({ products: products });
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                {this.state.totalItemsCount > 0 && (
                  <div className="list-page-footer">
                    <div className="left">{this.showPaginationStatus()}</div>
                    <div className="right">
                      <div className="list-page-footer-pagination">
                        <ImageButton
                          type={"leftarrowdouble"}
                          label={"First"}
                          onClick={() => this.onFirstClick()}
                        />
                        <ImageButton
                          type={"leftarrow"}
                          label={"Previous"}
                          onClick={() => this.onPreviousClick()}
                        />
                        <ImageButton
                          type={"rightarrow"}
                          label={"Next"}
                          onClick={() => this.onNextClick()}
                        />
                        <ImageButton
                          type={"rightarrowdouble"}
                          label={"Last"}
                          onClick={() => this.onLastClick()}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.loading && this.state.totalItemsCount > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-success btn-flat btn-md pull-right"
                        onClick={this.onSaveClick}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductQuickEdit;
