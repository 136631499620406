import React, { Component } from "react";
import "../Common/Common.css";
import * as constatnts from "../../Constatnts";
import Form from "../2/Controls/Form";
import Input from "../2/Controls/Input";
import Grid from "../2/Controls/Grid";
import Button from "../2/Controls/Button";
import ImportMaster from "../ImportMaster";
import { saveSalesChannel } from "../../services/api/SalesChannelAPI";

class NewSalesChanel extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      name: "",
      productSpecificMarkup: false,
      markupValue: "",
      markupType: "per",
      markupValueError: "",
      nameError: "",
      productSpecificMarkupError: "",
      key: "",
      showImport: false,
      selectAll: false,
      records: [],
      isProductTypeAheadLoading: false,
      productsTypeAheadOptions: [],
    };
    this.onSave = this.onSave.bind(this);
    this.onDeleteItems = this.onDeleteItems.bind(this);
    this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
    this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
    this.onProductSelected = this.onProductSelected.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  async componentDidMount() {
    const key = this.props.record ? this.props.record.key : "";
    if (key && key !== "") {
      let record = this.props.record;
      this.setState({
        key: key,
        name: record.Name,
        productSpecificMarkup: record.hasOwnProperty("ProductSpecificMarkup")
          ? record.ProductSpecificMarkup
          : false,
        markupValue: record.hasOwnProperty("MarkupValue")
          ? record.MarkupValue
          : "",
        markupType: record.hasOwnProperty("MarkupType")
          ? record.MarkupType
          : "per",
        records: record.Products,
      });
    }
  }

  onProductTypeAheadSearch = async (query) => {
    this.setState({ isProductTypeAheadLoading: true });
    let searchedProducts = [];
    searchedProducts = await this.props.config.Products.filter(
      (p) =>
        p.SKU.toLowerCase().includes(query.toLowerCase()) ||
        p.Name.toLowerCase().includes(query.toLowerCase()) ||
        p.BarCode.toLowerCase().includes(query.toLowerCase())
    );
    this.setState({
      productsTypeAheadOptions: searchedProducts,
      isProductTypeAheadLoading: false,
    });
  };

  onProductBarCodeSearch(queryText) {
    var productList = this.props.config.Products.filter(
      (e) =>
        e.SKU.toLowerCase() === queryText.toLowerCase() ||
        e.BarCode.toLowerCase() === queryText.toLowerCase()
    );
    if (productList.length === 0) {
      alert("Product not found");
      return;
    }
    this.onProductSelected(productList[0]);
  }

  onProductSelected(product) {
    //check if product is arelady added
    let records = this.state.records;
    if (records.filter((f) => f.ProductID === product.key).length > 0) {
      alert("Product already added");
      return;
    }
    records.unshift({
      ProductID: product.key,
      SKU: product.SKU,
      Name: product.Name,
      MarkupPercentage: "",
      MarkupDiscount: "",
      RetailPrice: "",
      Error: "",
    });
    this.setState({ records: records });
  }

  onSave() {
    this.setState({ saving: true });
    let nameError = "";
    let markupValueError = "";
    let productSpecificMarkupError = "";
    let lineItemErrors = false;
    let records = this.state.records;
    if (this.state.name.trim() === "") {
      nameError = "Please provide sales channel name";
    }
    if (!this.state.productSpecificMarkup && this.state.markupValue === "") {
      markupValueError = "Please provide value";
    }

    if (!this.state.productSpecificMarkup && this.state.records.length === 0) {
      productSpecificMarkupError = "Please select product(s)";
    }
    if (this.state.productSpecificMarkup) {
      //check lineitems
      records.map((m) => {
        if (
          m.MarkupPercentage === "" &&
          m.MarkupAmount === "" &&
          m.RetailPrice === ""
        ) {
          lineItemErrors = true;
          m.Error = "Please provide value";
        }
        if (
          (m.MarkupPercentage > 0 && m.MarkupAmount > 0) ||
          (m.MarkupPercentage > 0 && m.RetailPrice > 0) ||
          (m.MarkupAmount > 0 && m.RetailPrice > 0)
        ) {
          lineItemErrors = true;
          m.Error = "Please specify only one value";
        }
      });
    }

    if (
      nameError !== "" ||
      markupValueError !== "" ||
      productSpecificMarkupError !== "" ||
      lineItemErrors
    ) {
      this.setState({
        nameError: nameError,
        markupValueError: markupValueError,
        productSpecificMarkupError: productSpecificMarkupError,
        records: records,
        saving: false,
      });
      return;
    }

    let salesChannel = {
      Name: this.state.name,
      ProductSpecificMarkup: this.state.productSpecificMarkup,
      MarkupValue: this.state.markupValue,
      MarkupType: this.state.markupType,
      RegistrationID: this.props.user.user.RegistrationID,
      Products: this.state.productSpecificMarkup ? this.state.records : [],
      IsDeleted: false,
    };
    const key = this.state.key ? this.state.key : "";
    saveSalesChannel(key, salesChannel, this.props.user.user).then(() => {
      setTimeout(
        function() {
          //Start the timer
          this.props.getToastr("Sales channel saved successfully");
          this.setState({ saving: false });
          this.props.onSave();
        }.bind(this),
        3000
      );
    });
  }

  onDeleteItems() {
    let records = this.state.records;
    if (records.filter((f) => f.Selected).length === 0) {
      alert("Please select records");
      return;
    }
    let recordsTmp = [];
    records.map((m) => {
      if (!m.Selected) {
        recordsTmp.push(m);
      }
    });
    this.setState({ records: recordsTmp });
  }

  onExportClick() {
    let products = [];
    this.state.records.map((data) => {
      let record = {
        SKU: data.SKU,
        "Item Name": data.Name,
        "Markup Percentage": data.MarkupPercentage,
        "Markup Discount": data.MarkupDiscount,
        "Retail Price": data.RetailPrice,
      };
      products.push(record);
    });

    const csvData = constatnts.objToCSV(products);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "SalesChannelProducts.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    return (
      <React.Fragment>
        <Form
          title={
            this.state.key !== "" ? "Edit Sales Channel" : "New Sales Channel"
          }
          onClick={(action) => {
            if (action === "save") {
              this.onSave();
            } else {
              this.props.onClose();
            }
          }}
          processing={this.state.saving}
        >
          <Input
            type="text"
            label="Name"
            onChange={(value) => this.setState({ name: value, nameError: "" })}
            value={this.state.name}
            error={this.state.nameError}
            onEnter={this.onSave}
          />
          <Input
            type="switch"
            label="Apply product specific markup"
            value={this.state.productSpecificMarkup}
            onChange={(value) =>
              this.setState({ productSpecificMarkup: value })
            }
          />
          {!this.state.productSpecificMarkup && (
            <div className="row">
              <div className="col-md-6">
                <label>Markup</label>
                <div className="timePicker">
                  <input
                    type="number"
                    onChange={(evt) => {
                      this.setState({ markupValue: evt.target.value });
                    }}
                    value={this.state.markupValue}
                    className="form-control"
                  ></input>
                  <select
                    onChange={(evt) => {
                      this.setState({ markupType: evt.target.value });
                    }}
                    value={this.state.markupType}
                    className="form-control"
                  >
                    <option value="per">%</option>
                    <option value="amount">₹</option>
                  </select>
                </div>
              </div>
            </div>
          )}
          {this.state.productSpecificMarkup && (
            <React.Fragment>
              <br></br>
              <Input
                {...this.props}
                type="productsearch"
                showImportButton={true}
                processing={this.state.isProductTypeAheadLoading}
                onSearch={(searchText) => {
                  this.onProductTypeAheadSearch(searchText);
                }}
                onScan={(searchText) => this.onProductBarCodeSearch(searchText)}
                options={this.state.productsTypeAheadOptions}
                onChange={(value) => {
                  this.onProductSelected(value);
                }}
                onClick={(action) => {
                  if (action === "import") {
                    this.setState({ showImport: true });
                  }
                }}
              />
              {this.state.records.length > 0 && (
                <div className="felx">
                  <div className="left w-50">
                    Product Count: {this.state.records.length}
                  </div>
                  <div className="right w-50">
                    <Button type="remove" onClick={this.onDeleteItems} />
                  </div>
                </div>
              )}
              <Grid
                columns={[
                  { Name: "", Type: "checkbox" },
                  {
                    Name: "Product",
                    Type: "multiple",
                    Columns: [{ Column: "SKU" }, { Column: "Name" }],
                  },
                  {
                    Name: "Markup %",
                    Type: "field",
                    Columns: [
                      {
                        Column: "MarkupPercentage",
                        Type: "number",
                        Input: true,
                      },
                    ],
                  },
                  {
                    Name: "Markup Amt",
                    Type: "field",
                    Columns: [
                      { Column: "MarkupAmount", Type: "number", Input: true },
                    ],
                  },
                  {
                    Name: "Retail Price",
                    Type: "field",
                    Columns: [
                      { Column: "RetailPrice", Type: "number", Input: true },
                    ],
                  },
                ]}
                data={this.state.records}
                onChange={(index, record) => {
                  let records = this.state.records;
                  record.Error = "";
                  records[index] = record;
                  this.setState({ records: records });
                }}
                selectAll={this.state.selectAll}
                onSelectAll={(selected) => {
                  let records = this.state.records;
                  records.map((s) => (s.Selected = selected));
                  this.setState({ records: records, selectAll: selected });
                }}
              />
            </React.Fragment>
          )}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Form>
        {this.state.showImport && (
          <ImportMaster
            {...this.props}
            showWindow={true}
            importType="saleschannel"
            onClose={() => this.setState({ showImport: false })}
            onImportCompletion={(importedRecords) => {
              let records = this.state.records;
              importedRecords.map((m) => {
                if (
                  records.filter((f) => f.ProductID === m.ProductID).length ===
                  0
                ) {
                  m.Error = "";
                  m.Selected = false;
                  records.unshift(m);
                }
              });
              this.setState({ records: records, showImport: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default NewSalesChanel;
