import React, { Component } from 'react';
// import logoPosible from '../../../images/logo/PosibleLogo.png';
// import logoWRX from '../../../images/logo/WRXLogo.svg';
// import logoSMAPP from '../../../images/logo/SMLogo.png';
import logoOption1 from "../../../whitelabel_asset/logo_option1.png"
import logoOption2 from "../../../whitelabel_asset/logo_option2.png"


class Logo extends Component {

    renderLogo(){
        // if(this.props.config  && this.props.config.WhiteLabel && this.props.config.WhiteLabel.Code==="POSIBLE"){
        //     return <img  style={{ height: this.props.height ?  this.props.height : "122px" }} src={logoPosible}/>
        // }
        // if(this.props.config  && this.props.config.WhiteLabel && this.props.config.WhiteLabel.Code==="WRX"){
        //     return <img  style={{ height: this.props.height ?  this.props.height : "122px" }} src={logoWRX}/>            
        // }
        // if(this.props.config  && this.props.config.WhiteLabel && this.props.config.WhiteLabel.Code==="SMAPP"){
        //     return <img style={{ height: this.props.height ?  this.props.height : "122px" }} src={logoSMAPP}/>            
        // }

        if(this.props.logoOption ==="1")
        {
            return <img  style={{ height: this.props.height ?  this.props.height : "122px" }} src={logoOption1}/>
        }
        if(this.props.logoOption ==="2")
        {
            return <img  style={{ height: this.props.height ?  this.props.height : "122px" }} src={logoOption2}/>
        }
        return <div></div>
    }

    render(){
        return <React.Fragment>
            {this.renderLogo()}
        </React.Fragment>
    }
}

export default Logo;
