import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import Modal from 'react-responsive-modal'
import * as actionTypes from '../../actionTypes';
import Print from './Print'

class FoodDeliveryOrders extends Component {

    constructor() {
        super()
        this.state = {
            inProgressOrders: [],
            newOrders: [],
            searchType: 'placed',
            sale: null,
            showPrintOptions: false,
            showErrorMessage: false,
            errorMessage: "",
            printReceiptSelected: true,
            printKOTSelected: false,
            printReceipt: false,
            printKOT: false,

            printConfigs: [],
            selectedPringConfigID: "",
            printData: null,
            printKOTData: null,
            processing: false,
            showCancel: false,
            cancellationReason: "",
            cancellationReasons: [
                {value: "item_out_of_stock", name: "Product out of stock"},
                {value: "store_closed", name: "Store Closed"},
                {value: "store_busy", name: "Store Busy"},
                {value: "rider_not_available", name: "Rider not available"},
                {value: "out_of_delivery_radius", name: "Out of delivery radius"},
                {value: "connectivity_issue", name: "Connectivity Issue"},
                {value: "total_missmatch", name: "Total Mismacth"},
                {value: "invalid_item", name: "Product not found"},
                {value: "option_out_of_stock", name: "Product out of stock"},
                {value: "invalid_option", name: "Wrong option"},
                {value: "unspecified", name: "Unspecified"},
            ],
            saving: false,
            selectedOrderID: "",
            isMobileApp: false,
            foodDeliveryPlatforms: [],


            deliveryListDetail: [],
            selectedDelivery: null,
            searchCustomerDetail: '',
            searchTransactionNo: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            cashRegisterList: [],
            cashRegister: {},
            searchCashRegister: '',
            searchEmployeeName: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            loading: false,
            mobile: false
        }
        this.printFormatting = this.printFormatting.bind(this)
        this.loadNewOrders = this.loadNewOrders.bind(this)
        this.onNewOrderAcknowledge = this.onNewOrderAcknowledge.bind(this)
        this.onPrintClicked = this.onPrintClicked.bind(this)
        this.onCancelSave = this.onCancelSave.bind(this)
        this.onUpdateOrderStatus = this.onUpdateOrderStatus.bind(this)
    }

    componentWillMount() {
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    }

    componentDidMount() {
        this.setState({ mobile: this.props.isMobileApp(), foodDeliveryPlatforms: this.props.user.store.FoodDeliveryPlatforms })
        this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
            this.setState({ cashRegisterList: cashRegisters })
        })
        if (this.props.location.state.orderID && this.props.location.state.orderID !== "") {
            // alert(this.props.location.state.orderID)
            this.onNewOrderAcknowledge(this.props.location.state.orderID)
        }
        this.loadNewOrders()
 
    }

    async loadNewOrders() {
        let newOrders = await this.props.getFoodDeliveryNewOrders()
        // console.log(newOrders)
        this.setState({ newOrders: newOrders })
    }


    async loadInProgressOrders() {
        let inProgressOrders = await this.props.getInProgressOrders()
        console.log(inProgressOrders)
        this.setState({ newOrders: inProgressOrders })
    }

    async loadCompletedCancelledOrders(option) {
        let inProgressOrders = await this.props.getCompletedCancelledOrders(option)
        console.log(inProgressOrders)
        this.setState({ newOrders: inProgressOrders })
    }

    async  onNewOrderAcknowledge(newOrderID) {
        if (!this.props.user.cashRegister) {
            //make user select cash register 
            this.props.history.push({
                pathname: '/home/sales/sales/',
                state: { orderID: newOrderID, transactionType: null, type: actionTypes.ONLINE_ORDER }
            })
            return
        }
        this.setState({ processing: true })
        let response = await this.props.createSalesTransactionForUrbanPiperOrder(newOrderID)
        // console.log('response', response)
        if (response.data.Status === "Success") {
            //show toast 
            this.props.getToastr(response.data.Data.TransactionNo + " created successfully ")
            let printConfigs = this.props.config.PrintConfigs.filter((e) => !e.IsDeleted && e.TransactionTypeID === response.data.Data.TransactionType.key)
            let selectedPringConfigID = ""
            let showPrintOptions = false
            if (printConfigs.length > 0) {
                selectedPringConfigID = printConfigs[0].key
                showPrintOptions = true
            }
            else {
                showPrintOptions = false
            }
            //reload
            this.loadNewOrders()
            //show print dialog

            let printConfig = this.props.config.PrintConfigs.filter((e) => !e.IsDeleted
                && e.TransactionTypeID === response.data.Data.TransactionType.key)
            if (printConfig.length > 0) {
                this.setState({ selectedPringConfigID: printConfig[0].key })
            }
            this.setState({ sale: response.data.Data, showPrintOptions: showPrintOptions, printConfigs: printConfigs })
        }
        else {
            //show error message
            this.setState({ showErrorMessage: true, errorMessage: response.data.Message })
        }
        this.setState({ processing: false })
    }

    async onUpdateOrderStatus(sale, status) {
        await this.props.updateOrderStatus(sale, status);
        this.loadInProgressOrders();
    }

    async onCancelSave() {
        if (this.state.cancellationReason.trim() === '') {
            alert('Please provide reason for cancellation')
            return
        }
        this.setState({ saving: true });

        this.props.cancelUrbanPiperOrder(this.state.selectedOrderID, this.state.cancellationReason).then(() => {
            this.props.getToastr("Order cancelled successfully");
            setTimeout(function () { //Start the timer
                this.loadNewOrders()
                this.setState({ saving: false, selectedOrderID: "", cancellationReason: "", showCancel: false })
            }.bind(this), 5000)
        })
    }

    async onPrintClicked() {
        if (this.state.printReceiptSelected || this.state.printKOTSelected) {
            let salePrint = { ...this.state.sale }
            let printData = null
            let printKOTConfig = null
            let printKOTData = null

            await this.printFormatting(salePrint)
            // console.log("salePrint", salePrint);
            if (this.state.printReceiptSelected) {
                printData = { Sale: salePrint, PrintConfig: this.props.config.PrintConfigs.filter((e) => e.key === this.state.selectedPringConfigID)[0] }
            }
            if (this.state.printKOTSelected) {
                printKOTConfig = {}
                printKOTConfig.PrintSize = "3 inch"
                printKOTConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty("KOTTemplate") ? this.props.registration.registration.KOTTemplate : ""
                printKOTData = { Sale: salePrint, PrintConfig: printKOTConfig }
            }
            // console.log("printData", printData);
            this.setState({
                printChecked: true,
                sendSMSChecked: false,
                sendEmailChecked: false,
                printReceipt: this.state.printReceiptSelected,
                printData: printData,
                printKOT: this.state.printKOTSelected,
                printKOTData: printKOTData,
                showPrintOptions: false,
                saving: false
            })//TBDpawan
        }
    }

    async printFormatting(sale) {

        // sale1.TransactionDate =  (new Date(sale.TransactionDate.seconds*1000)).toString().substring(0,24)
        //in case of sales order to invoice 
        if (sale.hasOwnProperty('ReceivedPaymentTransactions')) {
            for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
                sale.BillAmount = Number(sale.BillAmount) + Number(sale.ReceivedPaymentTransactions[i].ReceiveAmount)
            }
        }

        sale.TransactionDateShort = (new Date(sale.TransactionDate.seconds * 1000)).toString().substring(0, 24)//(new Date(sale.TransactionDate)).toISOString().split('T')[0]
        sale.TransactionDate = (new Date(sale.TransactionDate.seconds * 1000)).toString().substring(0, 24)//sale.TransactionDate.toString().substring(0, 24)
        sale.AmountInWords = this.props.convertNumberToWords(sale.BillAmount)
        // console.log('sale1.AmountInWords', sale1.AmountInWords)
        let billTaxableAmount = 0
        let lineItemsTmp = []
        for (let i = 0; i < sale.LineItems.length; i++) {
            lineItemsTmp.push(sale.LineItems[sale.LineItems.length - (i + 1)])
        }
        sale.LineItems = lineItemsTmp

        sale.LineItems.map((lineItem, index) => {
            sale.LineItems[index].SrNo = index + 1
            sale.LineItems[index].BasePrice = this.roundUpTo2(Number(sale.LineItems[index].TaxableAmount) / Number(sale.LineItems[index].Qty))
            billTaxableAmount = this.roundUpTo2(Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount))
        })

        sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount))
        let printTaxComponents = []
        let printTaxComponentsWithRate = []
        sale.LineItems.map((lineItem, index) => {
            //check modifers 
            if (lineItem.Modifiers && lineItem.Modifiers.length > 0) {
                let modifiersPrint = []
                for (let k = 0; k < lineItem.Modifiers.length; k++) {
                    let index = -1
                    for (let l = 0; l < modifiersPrint.length; l++) {
                        if (modifiersPrint[l].ModifierName === lineItem.Modifiers[k].ModifierName) {
                            index = l
                            break
                        }
                    }
                    if (index === -1) {
                        modifiersPrint.push({

                            key: lineItem.Modifiers[k].key,
                            ModifierName: lineItem.Modifiers[k].ModifierName,
                            ModifierPrice: lineItem.Modifiers[k].ModifierPrice,
                            ModifierGroup: lineItem.Modifiers[k].ModifierGroup,
                            ModifierGroupID: lineItem.Modifiers[k].ModifierGroupID,
                            ModifierQty: 1
                        })
                    }
                    else {
                        modifiersPrint[index].ModifierQty = modifiersPrint[index].ModifierQty + 1
                        modifiersPrint[index].ModifierPrice = modifiersPrint[index].ModifierPrice + lineItem.Modifiers[k].ModifierPrice
                    }
                }
                // console.log('modifiersPrint', modifiersPrint)
                lineItem.ModifiersPrint = modifiersPrint
            }
            if (lineItem.TaxID !== "") {
                lineItem.TaxComponentAmount.map((t) => {
                    let indexFound = -1
                    printTaxComponents.map((pt, ptIndex) => {
                        if (t.TaxType === pt.TaxType) {
                            indexFound = ptIndex
                            return
                        }
                    })
                    if (indexFound < 0) {
                        printTaxComponents.push({
                            TaxType: t.TaxType,
                            TaxAmount: this.roundUpTo2(Number(t.TaxAmount))
                        })
                    }
                    else {
                        printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(Number(printTaxComponents[indexFound].TaxAmount) + Number(t.TaxAmount))
                    }


                    let indexFoundTaxRate = -1
                    printTaxComponentsWithRate.map((pt, ptIndex) => {
                        if (t.TaxType === pt.TaxType && t.TaxPercentage === pt.TaxPercentage) {
                            indexFoundTaxRate = ptIndex
                            return
                        }
                    })
                    if (indexFoundTaxRate < 0) {
                        printTaxComponentsWithRate.push({
                            TaxType: t.TaxType, TaxPercentage: t.TaxPercentage,
                            TaxAmount: this.roundUpTo2(Number(t.TaxAmount)), TaxableAmount: Number(lineItem.TaxableAmount)
                        })
                    }
                    else {
                        printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount = this.roundUpTo2(Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount) + Number(t.TaxAmount))
                        printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount = this.roundUpTo2(Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount) + Number(lineItem.TaxableAmount))
                    }
                })
            }
        })
        sale.PrintTaxComponents = printTaxComponents
        sale.PrintTaxComponentsWithRate = printTaxComponentsWithRate
        sale.LineItems.map((lineItem, index) => {
            for (var i = 0; i < printTaxComponents.length; i++) {
                if (lineItem.TaxComponentAmount.length < (i + 1)) {
                    lineItem.TaxComponentAmount.push({ TaxName: '', TaxAmount: 0, TaxPercentage: '', TaxType: '' })
                }
            }
        })

        console.log('sale print', sale)
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }

    onSearchType(option) {
        this.setState({ searchType: option, recordFrom: 0 })
        if (option === "placed") {
            this.loadNewOrders()
        }
        else if (option === "inprogress") {
            this.loadInProgressOrders()
        }
        else {
            this.loadCompletedCancelledOrders(option);
        }
    }

    render() {
        const mobileFooter = {
            height: "46px",
            backgroundColor: "white",
            paddingTop: "0px",
            position: "fixed",
            left: "0",
            bottom: "0",
            borderTop: "solid 1px #ddd",
            width: "100%"
        }

        return <div className="content-wrapper" >
            {(!this.state.printReceipt && !this.state.printKOT) && <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-4 col-xs-9" >
                                    <font style={{ fontSize: "30px" }}>Online Orders</font>
                                </div>
                                {!this.state.mobile && <div className="col-md-8 col-xs-3 pull-right" style={{ paddingTop: "4px" }}>
                                    <div className="btn-group pull-right" style={{ paddingRight: "10px" }}>
                                        <button type="button" className={this.state.searchType === "placed" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                            onClick={() => this.onSearchType("placed")}>Placed</button>
                                        <button type="button" className={this.state.searchType === "inprogress" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                            onClick={() => this.onSearchType("inprogress")}>In Progress</button>
                                        <button type="button" className={this.state.searchType === "Completed" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                            onClick={() => this.onSearchType("Completed")}>Completed</button>
                                        <button type="button" className={this.state.searchType === "Cancelled" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md "}
                                            onClick={() => this.onSearchType("Cancelled")}>Cancelled</button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="box-body" style={{ padding: "0px" }}>
                            <div className="row">
                                {this.state.mobile && <div className="col-xs-12" style={{ marginBottom: "10px" }}>
                                    <div style={{ overflow: "auto", whiteSpace: "nowrap" }}>
                                        <button type="button" className={this.state.searchType === "new" ? "btn btn-primary btn-flat btn-lg " : "btn btn-default btn-flat btn-lg "}
                                            onClick={() => this.onSearchType("places")}>Placed</button>
                                        <button type="button" className={this.state.searchType === "wip" ? "btn btn-primary btn-flat btn-lg " : "btn btn-default btn-flat btn-lg "}
                                            onClick={() => this.onSearchType("outfordelivery")}>In Progress</button>
                                        <button type="button" className={this.state.searchType === "closed" ? "btn btn-primary btn-flat btn-lg " : "btn btn-default btn-flat btn-lg "}
                                            onClick={() => this.onSearchType("Completed")}>Completed</button>
                                        <button type="button" className={this.state.searchType === "cancelled" ? "btn btn-primary btn-flat btn-lg " : "btn btn-default btn-flat btn-lg "}
                                            onClick={() => this.onSearchType("Cancelled")}>Cancelled</button>
                                    </div>
                                </div>}
                            </div>
                            {this.state.loading && <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2"><Loader show={this.state.loading} /></div>}
                            <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "100px" }}>Channel</th>
                                            <th>Order</th>
                                            <th>Customer</th>
                                            <th>Order Details</th>
                                            <th>Order Status</th>
                                            <th style={{ width: "140px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.newOrders.map((newOrder) => <tr>
                                            <td>
                                                {newOrder.Order.order.details.channel === "swiggy" && <div><img style={{ height: "50px" }} src={require('../../images/swiggy_logo.png')} /></div>}
                                                {newOrder.Order.order.details.channel === "zomato" && <div><img style={{ height: "50px" }} src={require('../../images/zomato_logo.png')} /></div>}
                                                {newOrder.Order.order.details.channel === "foodpanda" && <div><img style={{ height: "50px" }} src={require('../../images/foodpanda_logo.jpg')} /></div>}
                                                {newOrder.Order.order.details.channel === "ubereats" && <div><img style={{ height: "50px" }} src={require('../../images/ubereats_logo.png')} /></div>}
                                                {newOrder.Order.order.details.channel}
                                            </td>
                                            <td>
                                                {newOrder.hasOwnProperty('TransactionNo') && <div>Transaction No: {newOrder.TransactionNo}</div>}
                                                <div>Order No: {newOrder.Order.order.details.ext_platforms.length > 0 ? newOrder.Order.order.details.ext_platforms[0].id : ''}</div>
                                                <div>Order Date: {new Date(newOrder.Order.order.details.created).toString().substring(0, 21)}</div>
                                                <div>Delivery Time: {new Date(newOrder.Order.order.details.delivery_datetime).toString().substring(0, 21)}</div>
                                                {newOrder.Order.order.details.ext_platforms.length > 0 && newOrder.Order.order.details.ext_platforms[0].instructions !== "" && <div>Notes: {newOrder.Order.order.details.instructions}</div>}
                                            </td>
                                            <td>
                                                {newOrder.Order.customer && <React.Fragment>
                                                    <div>{newOrder.Order.customer.name}</div>
                                                    <div>{newOrder.Order.customer.email}</div>
                                                    <div>{newOrder.Order.customer.phone}</div>
                                                </React.Fragment>}
                                            </td>
                                            <td>
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {newOrder.Order.order.items.map(m => <tr>
                                                            <td>{m.title}</td>
                                                            <td>{m.quantity}</td>
                                                        </tr>)}
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                {newOrder.Status}
                                            </td>
                                            <td>
                                                {(!newOrder.hasOwnProperty('SaleID') && newOrder.Status !== "Cancelled") && <button type="button" className="btn btn-success btn-flat btn-md"
                                                    style={{ width: "100%" }}
                                                    disabled={this.state.processing}
                                                    onClick={() => this.onNewOrderAcknowledge(newOrder.key)}>
                                                    Acknowledge
                                                    </button>}
                                                {(this.state.searchType !== "completed" && this.state.searchType !== "cancelled") &&
                                                    <React.Fragment>
                                                        {newOrder.Status === "Acknowledged" && <button type="button"
                                                            className="btn btn-default btn-flat btn-md"
                                                            disabled={this.state.processing}
                                                            style={{ width: "100%" }}
                                                            onClick={() => this.onUpdateOrderStatus(newOrder, 'Food Ready')}>
                                                            Food Ready
                                                        </button>}
                                                        {newOrder.Status === "Food Ready" && <button type="button" className="btn btn-default btn-flat btn-md"
                                                            style={{ width: "100%" }}
                                                            disabled={this.state.processing}
                                                            onClick={() => this.onUpdateOrderStatus(newOrder, 'Dispatched')}>
                                                            Dispatched
                                                    </button>}
                                                    </React.Fragment>
                                                }
                                                {(newOrder.Status === "Dispatched") && <button type="button"
                                                    style={{ width: "100%" }}
                                                    disabled={this.state.processing}
                                                    className="btn btn-default btn-flat btn-md"
                                                    onClick={() => this.onUpdateOrderStatus(newOrder, 'Completed')}>
                                                    Completed
                                                    </button>}
                                                {newOrder.Status !== "Cancelled" && <button type="button"
                                                    style={{ width: "100%" }}
                                                    disabled={this.state.processing}
                                                    className="btn btn-danger btn-flat btn-md"
                                                    onClick={() => { this.setState({ showCancel: true, selectedOrderID: newOrder.key }) }}>
                                                    Cancel
                                                    </button>}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Modal open={this.state.showPrintOptions}
                onClose={() => this.setState({ showPrintOptions: false })} center>
                <div style={{ width: "300px" }}>
                    <h3 style={{ marginTop: "0px" }}>Print</h3>
                    <div className="box-body">
                        {this.state.sale && <div><b>Tansaction No: </b>{this.state.sale.TransactionNo}  </div>}
                        {this.state.sale && <div><b>Order No: </b>{this.state.sale.RequestSourceID}  </div>}
                        {this.state.sale && <div><b>Channel: </b>{this.state.sale.RequestSource}  </div>}
                        <input type="checkbox"
                            onChange={() => this.setState({ printReceiptSelected: !this.state.printReceiptSelected })}
                            checked={this.state.printReceiptSelected}
                        />
                        <label>Print Receipt</label>
                        <br />
                        <input type="checkbox"
                            onChange={() => this.setState({ printKOTSelected: !this.state.printKOT })}
                            checked={this.state.printKOTSelected}
                        />
                        <label>Print KOT</label>
                        <br />
                        {this.state.sale && <select className="form-control input-md"
                            value={this.state.selectedPringConfigID}
                            onChange={(evt) => this.setState({
                                selectedPringConfigID: evt.target.value,
                                selectedPrintConfigPrintDisplayName: evt.target.options[evt.target.selectedIndex].text
                            })}>
                            {this.props.config.PrintConfigs.filter((e) => !e.IsDeleted
                                && e.TransactionTypeID === this.state.sale.TransactionType.key).map((t, index) =>
                                    <option key={index} value={t.key}>{t.PrintDisplayName}</option>
                                )}
                        </select>}
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-default"
                            onClick={() => this.setState({ showPrintOptions: false })} >Cancel</button>
                        <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                            onClick={() => this.onPrintClicked()}>OK</button>
                    </div>
                </div>
            </Modal>
            {this.state.printReceipt && <div>
                <Print  {...this.props}
                    printChecked={this.state.printChecked}
                    sendEmailChecked={false}
                    sendSMSChecked={false}
                    printData={this.state.printData}
                    onClose={() => {
                        this.setState({ printReceipt: false })
                    }
                    }
                />
            </div>}
            {(this.state.printKOT && !this.state.printReceipt) && <div>
                <Print  {...this.props}
                    printChecked={this.state.printKOT}
                    sendEmailChecked={false}
                    sendSMSChecked={false}
                    printData={this.state.printKOTData}
                    onClose={() => { this.setState({ printKOT: false }) }} />
            </div>}
            <Modal open={this.state.showErrorMessage} onClose={() => this.setState({ showErrorMessage: false })} center>
                <div style={{ width: "300px" }}>
                    <h3 style={{ marginTop: "0px" }}>Error</h3>
                    <div className="box-body">
                        {this.state.errorMessage}
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-danger pull-right"
                            onClick={() => this.setState({ showErrorMessage: false })}>OK</button>
                    </div>
                </div>
            </Modal>
            <Modal open={this.state.showCancel} onClose={() => this.setState({ showCancel: false })} center>
                <div style={{ width: "300px" }}>
                    <h3 style={{ marginTop: "0px" }}>Cancel</h3>
                    <div className="box-body">
                        <select className="form-control"
                            onKeyPress={this.handleKeyPress}
                            onChange={(evt) => {
                                this.setState({ cancellationReason: evt.target.value })
                            }}
                            value={this.state.cancellationReason}>
                            <option value=''>Select Reason</option>
                            {this.state.cancellationReasons.map((e, index1) => <option key={index1} value={e.value}>{e.name}</option>)}
                        </select>
                        {/* <textarea className="textarea" style={{ width: "100%", height: "200px" }}
                            onChange={(evt) => this.setState({ cancellationReason: evt.target.value })}
                            value={this.state.cancellationReason}
                        ></textarea> */}
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-md btn-flat btn-default" onClick={() => this.setState({ showCancel: false })} >Cancel</button>
                        <button type="submit"
                            className="btn btn-flat btn-md btn-flat btn-success pull-right"
                            disabled={this.state.saving}
                            onClick={this.onCancelSave}>Save</button>
                    </div>
                </div>
            </Modal>
        </div>
    }
}

export default withRouter(FoodDeliveryOrders)