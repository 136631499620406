import React, { Component } from 'react';
import Form from '../2/Controls/Form';
import Dropzone from 'react-dropzone';
import Input from '../2/Controls/Input';

class OmniSlider extends Component {

    constructor()
    {
        super()
        this.state = {
            image: null,
            imageURL: '',
            key: '',
            imageTitle: '',
            imageSubTitle: '',
            sequenceNo: 0
        }
        this.onImageRemove = this.onImageRemove.bind(this)
        this.onImageDrop = this.onImageDrop.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    async componentDidMount()
    {
        // console.log('this.props.selectedRecord',this.props.selectedRecord)
        if(this.props.selectedRecord)
        {
            let slider = this.props.selectedRecord;
            this.setState({ 
                key: slider.key ,
                imageTitle: slider.hasOwnProperty('Title') ? slider.Title : '',
                imageSubTitle:  slider.hasOwnProperty('SubTitle') ? slider.SubTitle : '',
                image: slider.hasOwnProperty('Image') ?  slider.Image : null,
                sequenceNo: slider.hasOwnProperty('SequenceNo') ?  slider.SequenceNo : 0
            })
        }
    }
 
    onImageDrop(acceptedFiles, rejectedFiles) {
        
        if (acceptedFiles.length > 0) {
            if ((acceptedFiles[0].size / 1024) > 600) {
                alert('File size should not be more than 600 KB')
                return
            }
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/ImageTemplate').then((downloadURL) => {
                let bucket = 'posible.in/'
                let folder =  this.props.registration.registration.StorageFolder + '/ImageTemplate/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + acceptedFiles[0].name
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: url }
                this.setState({ image: image, imageURL: url})
            })
        }
        else { alert("No file selected") }
    }

    onImageRemove() {
        this.setState({ image: null })
    }


    async onSave()
    {
        this.setState({saving:true});
        let slider = {
            Title: this.state.imageTitle,
            SubTitle: this.state.imageSubTitle,
            Image: this.state.image,
            SequenceNo: Number(this.state.sequenceNo),
            RegistrationID: this.props.user.user.RegistrationID,
        }
        this.props.saveCarousel(slider, this.state.key).then(() => {
            this.props.getToastr("Carousel saved successfully");
            this.props.onSave()
        })
    }

    render(){
        return <Form
            title={(this.state.key !== "") ? "Edit Slider" : "New Slider"}
            onClick={(action)=>{
                if(action==="save"){
                    this.onSave();
                }else{
                    this.props.onClose()
                }
            }}
            processing={this.state.saving}
        >
          
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                    {!this.state.image && <React.Fragment>
                            <label >Image</label>
                            <Dropzone onDrop={(files) => this.onImageDrop(files)}>
                                <div>Try dropping some files here, or click to select files to upload.</div>
                            </Dropzone>
                        </React.Fragment>}
                        {this.state.image && <div id="imageTemplate" className="image-container">
                            <img className="message-img" src={this.state.image.Preview} alt="Image" />
                        </div>}
                    </div>
                </div>
            </div>
            {this.state.image && <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <button type="button" className="footer-button-secondary" onClick={() => this.onImageRemove()}>Delete Image</button>
                    </div>
                </div>
            </div>}
            <div className="row">
                <div className="col-md-12">
                    <Input
                        type="number"
                        label="Sequence No"
                        value={this.state.sequenceNo}
                        onChange={(value)=>this.setState({sequenceNo:value})}
                    />
                </div>
            </div>
        </Form>
    }

}
export default OmniSlider

