import firebase, { database } from "../firebase";
import * as constants from "../Constatnts";

export function addCashRegister(cashRegister) {
  return (dispatch, getState) => {
    cashRegister.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    cashRegister.ActionBy = getState().user.user.Name;
    cashRegister.ActionByUID = getState().user.user.UID;
    cashRegister.ActionByEmailID = getState().user.user.EmailID;

    cashRegister.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("CashRegisters")
      .add({
        ...cashRegister,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding cash register: ", error);
      });
  };
}

export function getCashRegisters(storeID) {
  // return (dispatch) => {
  //     return database.collection("CashRegisters").where("StoreID","==",storeID).get().then((querySnapshot) => {

  //         const cashRegisters = []
  //         querySnapshot.forEach((doc) => {
  //             cashRegisters.push(
  //               {
  //                 key:doc.id,...doc.data()
  //               }
  //             )
  //        })
  //        console.log('cashRegisters',cashRegisters)
  //        return cashRegisters
  //     }).catch((error) => {
  //         console.log("Error getting cash register list: ", error)
  //     })
  // }
  return (dispatch) => {
    // console.log('storeID', storeID)
    let addMessage = firebase.functions().httpsCallable("apiGetCashRegisters");
    return addMessage({ storeID: storeID }).then(function(result) {
      let cashRegisters = result.data;
      // console.log('cashRegisters',cashRegisters)
      return cashRegisters;
    });
  };
}

export function deleteCashRegister(key) {
  return (dispatch, getState) => {
    return database
      .collection("CashRegisters")
      .doc(key)
      .update({
        IsDeleted: true,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,

        Action: constants.AUDIT_TRAIL_DELETE,
      })
      .then(() => {
        return true;
      });
  };
}

export function getCashRegisterDetails(key) {
  return (dispatch) => {
    return database
      .collection("CashRegisters")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such cash register found!");
        }
      });
  };
}

export function updateCashRegister(cashRegister, key) {
  return (dispatch, getState) => {
    cashRegister.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    cashRegister.ActionBy = getState().user.user.Name;
    cashRegister.ActionByUID = getState().user.user.UID;
    cashRegister.ActionByEmailID = getState().user.user.EmailID;
    cashRegister.Action = constants.AUDIT_TRAIL_EDIT;
    return database
      .collection("CashRegisters")
      .doc(key)
      .update(cashRegister);
  };
}

export function updateLastClosingBalance(key, lastClosingBalance) {
  return (dispatch, getState) => {
    return database
      .collection("CashRegisters")
      .doc(key)
      .update({
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,
        Action: constants.AUDIT_TRAIL_EDIT,

        LastClosingBalance: lastClosingBalance,
      })
      .then(() => {
        return true;
      });
  };
}

export function getLastClosingBalance(key) {
  return (dispatch) => {
    let lastClosingBalance = 0;
    return database
      .collection("CashRegisters")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          lastClosingBalance = doc.data().hasOwnProperty("LastClosingBalance")
            ? doc.data().LastClosingBalance
            : 0;
        } else {
          console.log("No such cash register found!");
        }
        return lastClosingBalance;
      });
  };
}
