import React from 'react'
import * as actions from '../../actionTypes'
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Footer from '../Common/Footer'

class AddEditCashRegister extends React.Component{
    constructor () {
        super()
        this.state={
            store:null,
            saving: false,
            cashRegisterCode:'',
            cashRegisterName:'',
            storeLocationID:'',
            storeID:'',
            action:'',
            key:'',
            storeLocations:[],
            cashRegisterCodeError:false,
            cashRegisterNameError:false,
            locationError:false,
            storeLocation:''
        }
        this.onSaveCashRegister = this.onSaveCashRegister.bind(this)
        this.formControls = this.formControls.bind(this)
    }

    async componentDidMount()
    {
        let storeLocations = await this.props.getStoreLocations(this.props.storeID)
        this.setState({storeID:this.props.storeID, action: this.props.action, storeLocations:storeLocations, store:this.props.store})

        if(this.props.cashRegister)
        {
            this.setState({key: this.props.cashRegister.key, 
                cashRegisterCode : this.props.cashRegister.CashRegisterCode,
                cashRegisterName: this.props.cashRegister.CashRegisterName,
                storeLocationID: this.props.cashRegister.StoreLocationID
            })
        }
    }

    onSaveCashRegister()
    {
        this.setState({ saving:true})
        //check if  code and  name is entered 
        let cashRegisterCodeError = false
        let cashRegisterNameError = false 
        let locationError = false
        if(this.state.cashRegisterCode.trim()==="")
        {cashRegisterCodeError= true}
        if(this.state.cashRegisterName.trim()==="")
        {cashRegisterNameError= true}
        if(this.state.storeLocationID.trim()==="")
        {locationError= true}
        this.setState({ cashRegisterCodeError:cashRegisterCodeError,
            cashRegisterNameError:cashRegisterNameError, locationError:locationError})
        if(cashRegisterCodeError || cashRegisterNameError || locationError)
        {return}
        //check code is unique
        let cashRegister = {CashRegisterCode: this.state.cashRegisterCode,
        CashRegisterName:this.state.cashRegisterName,
        StoreLocationID: this.state.storeLocationID,
        StoreLocation: this.state.storeLocation,
        StoreID: this.state.storeID,
        RegistrationID: this.props.user.user.RegistrationID,
        IsDeleted: false}
        
        // if(this.state.action === actions.CASH_REGISTER_NEW)
        if(this.state.key ==="")
        {
            this.props.addCashRegister(cashRegister).then(()=> {
                this.props.getToastr("Cash Register Saved Successfully")
                this.setState({ saving:false})
                this.props.onSave()
            })
        }
        // if(this.state.action === actions.CASH_REGISTER_EDIT)
        else
        {
            this.props.updateCashRegister(cashRegister,this.state.key).then(()=> {
                this.props.getToastr("Cash Register Saved Successfully")
                this.setState({ saving:false})
                this.props.onSave()})
        }
    }

    formControls(){
        return <React.Fragment>
            <div className={this.state.cashRegisterCodeError ? "form-group has-error" : "form-group "} >
                <label>Cash Register Code</label>
                <input type = "text" className="form-control" 
                onChange={(evt)=> this.setState({cashRegisterCode:evt.target.value,cashRegisterCodeError:"" })}
                value={this.state.cashRegisterCode}/>
                {this.state.cashRegisterCodeError &&  <span className="help-block">Please provide code</span> }
            </div>
            <div className={this.state.cashRegisterNameError ? "form-group has-error" : "form-group "} >
                <label>Cash Register Name</label>
                <input type = "text" className="form-control" 
                onChange={(evt)=> this.setState({cashRegisterName:evt.target.value, cashRegisterNameError:""})}
                value={this.state.cashRegisterName}/>
                {this.state.cashRegisterNameError &&  <span className="help-block">Please provide name</span> }

            </div>
            <div className={this.state.locationError ? "form-group has-error" : "form-group "} >
                <label>Location </label>
                <select className="form-control"
                    value={this.state.storeLocationID} 
                    onChange= {(evt)=> {this.setState({storeLocationID: evt.target.value,storeLocation:evt.target.options[evt.target.selectedIndex].text, locationError:""})}}>
                        <option value="">Select Location</option>
                        {this.state.storeLocations.filter((f)=> !f.IsDeleted).map((storeLocation)=> 
                        <option value={storeLocation.key}>{storeLocation.LocationCode} - {storeLocation.LocationName}</option>
                        )}
                </select>
                {this.state.locationError &&  <span className="help-block">Please select location</span> }
            </div>
        </React.Fragment>
    }

    render(){
        return <React.Fragment>

        {!this.props.isMobileApp() && <React.Fragment>
            <section className="content" >
                <section className="content-header">
                    {this.state.action === actions.CASH_REGISTER_NEW &&  <h1> Add New Cash Register</h1>}
                    {this.state.action === actions.CASH_REGISTER_EDIT &&  <h1> Edit Cash Register</h1>}
                </section> 
                <div className="box-body">                            
                
                    {this.formControls()}

                </div>
                <div className="box-footer">
                    <button type="submit" className="btn btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                    <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveCashRegister} disabled={this.state.saving}>Save</button>
                </div>
            </section>
        </React.Fragment>}

       {this.props.isMobileApp() &&  <React.Fragment>   
            <HeaderNew {...this.props}
                back={true}
                title= { this.state.key === "" ? "Add New Cashregister" : "Edit Cashregister" }
                onBackClick={() => { this.props.onClose()}}
            />
            <div className="header-new-background"/>
            <div className="content-wrapper">
                <div className="container">
                    
                    <br/>
                    
                    <div className="form-group">
                        <label>Store Code</label>
                        <input type="text" className="form-control"
                            value={this.state.store ? this.state.store.StoreCode : ''}
                        />
                    </div>

                    <div className="form-group">
                        <label>Store Name</label>
                        <input type="text" className="form-control"
                                value={this.state.store ? this.state.store.StoreName: ''}
                            />
                    </div>
                    
                    {this.formControls()}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Footer onClick={()=> this.onSaveCashRegister()} />
                </div>
            </div>
        </React.Fragment>}

        </React.Fragment>
        
        
    }
}

export default AddEditCashRegister