import React,{Component} from 'react'
import Button from '../2/Controls/Button'
import {getCustomerMLoyalLoyaltyPoints, validateMLoyalCustomerLoyaltyPoints, redeemMLoyalCustomerLoyaltyPoints} from '../../services/api/MLoyal';
import {getCustomerEasyRewardzLoyaltyPoints, validateEasyRewardzCustomerLoyaltyPoints, validateEasyRewardzOTP, releaseEasyRewardzRedemptionPoints} from '../../services/api/EasyRewardz';

class PaymentLoyaltyWidget extends Component{

    constructor(){
        super()
        this.state={
            loyaltyAmountToRedeem:0,
            remarks:"",
            OTP:"",
            amountEntered:false,
            loyaltyType:"",
            loyaltyPoints:0,
            loyaltyValue:0,
            pointRedemption:0,
            OTPVerificationRequired:false,
            processing:true,
            transactionCode:"",
            redemptionDate:"",
        }
        this.onSubmitClicked = this.onSubmitClicked.bind(this);
        this.onNextClicked = this.onNextClicked.bind(this);
        this.onValidateOTP = this.onValidateOTP.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    async componentDidMount(){

        let loyaltyType="";
        let loyaltyTypeID = "";
        let loyaltyPoints=0;
        let loyaltyValue=0;
        let pointRedemption=0;
        let OTPVerificationRequired=false;
        if(this.props.registration.registration.EnableMLoyal){
            //call mloyal api
            // console.log('call mloyal api');
            let response = await getCustomerMLoyalLoyaltyPoints(this.props.user.store.RegistrationID, 
                this.props.user.store.key, 
                this.props.Customer.key, 
                this.props.Customer.PhoneNo)
            if(response=== ""){
                this.setState({ processing: false });
                alert("Customer loyalty not found. Please try again or contact system administrator.");
                return;
            }
            loyaltyType = response.CUSTOMER_DETAILS[0].Slab;
            loyaltyPoints = Number(response.CUSTOMER_DETAILS[0].LoyalityPoints);
            loyaltyValue = Number(response.CUSTOMER_DETAILS[0].LoyalityPointsValue);
            pointRedemption = Number(response.CUSTOMER_DETAILS[0].Point_Per_Value);
            OTPVerificationRequired= true;
        }
        else if(this.props.registration.registration.EnableEasyRewardz){
            
            let response = await getCustomerEasyRewardzLoyaltyPoints(
                this.props.user.store.RegistrationID, 
                this.props.user.store.key, 
                this.props.user.store.StoreCode,
                this.props.Customer.key, 
                this.props.Customer.PhoneNo)
            
            if(!response.Success){
                this.setState({ processing: false });
                alert("Customer loyalty not found. Please try again or contact system administrator.");
                return;
            }
            loyaltyType = "Loyalty";
            loyaltyPoints = Number(response.Data.AvailablePoints);
            loyaltyValue = Number(response.Data.PointValue);
            pointRedemption = Number(response.Data.PointRate);
            OTPVerificationRequired= true;
        }
        else
        {
            //get customer current points ..loyalty type and points
            let customerLoyalty = await this.props.getCustomerLoyaltyPoints(this.props.Customer.key)
            if (!customerLoyalty) {
                alert("Customer loyalty not found. Please try again or contact system administrator.")
                // if (!window.cordova) { this.refs.txtPaymentModeAmount.focus() }
                this.setState({ processing: false })
                return
            }
            let loyatySetup = await this.props.getLoyaltySetupDetails(this.props.registration.registration.key)
            let pointRedemption = 1
            if (loyatySetup) {
                pointRedemption = Number(loyatySetup.PointRedemption)
            }
            loyaltyPoints = Number(customerLoyalty.LoyaltyPoints)
            loyaltyValue = Number(loyaltyPoints) / Number(pointRedemption === 0 ? 1 : pointRedemption)
            loyaltyType= customerLoyalty.LoyaltyType
            loyaltyTypeID=customerLoyalty.LoyaltyTypeID
            let loyaltyPayment = this.props.config.PaymentTypes.filter(f=> f.PaymentType.toLowerCase()==="loyalty")
            if(loyaltyPayment.length > 0){
                if(loyaltyPayment[0].OTPVerification){
                    OTPVerificationRequired = loyaltyPayment[0].OTPVerification;
                }
            }
            // if (this.props.PaymentMode.OTPVerification) {OTPVerificationRequired=this.props.PaymentMode.OTPVerification}
        }

        this.setState({
            loyaltyType:loyaltyType,
            loyaltyPoints:loyaltyPoints,
            loyaltyValue:loyaltyValue,
            pointRedemption:pointRedemption,
            OTPVerificationRequired:OTPVerificationRequired,
            loyaltyTypeID:loyaltyTypeID,
            processing:false
        })
    }

    // async loadLoyaltyControl(){
    //     if(this.props.registration.registration.EnableMLoyal){
    //         //call mloyal api
    //         // console.log('call mloyal api');
    //         let response = await getCustomerMLoyalLoyaltyPoints(this.props.user.store.RegistrationID, this.props.user.store.key, this.state.sale.Customer.key, this.state.sale.Customer.PhoneNo)
    //         if(response=== ""){
    //             this.setState({ selectedPaymentOptionLoading: false });
    //             alert("Customer loyalty not found. Please try again or contact system administrator.");
    //             return;
    //         }
    //         // .then(response =>{
    //             // console.log('response', response);
    //             // console.log('response.CUSTOMER_DETAILS.Slab',response.CUSTOMER_DETAILS[0].Slab);
    //             // console.log('response.CUSTOMER_DETAILS.LoyalityPoints',response.CUSTOMER_DETAILS[0].LoyalityPoints)
                   
    //         // });
    //         let loyaltyType = response.CUSTOMER_DETAILS[0].Slab;
    //         let loyaltyPoints = response.CUSTOMER_DETAILS[0].LoyalityPoints;
    //         let loyaltyValue = response.CUSTOMER_DETAILS[0].LoyalityPointsValue;
    //         let pointRedemption = response.CUSTOMER_DETAILS[0].Point_Per_Value;
    //         if(Number(loyaltyValue)< Number(this.state.paymentModeAmount)){
    //             this.setState({ selectedPaymentOptionLoading: false });
    //             alert("Available loyalty point value is " + loyaltyValue + ". Please select amount less than or equal to available loyalty point value" );
    //             return;
    //         }
    //         //call OTP validate method
    //         let validateReponse = await validateMLoyalCustomerLoyaltyPoints(this.props.user.store.RegistrationID, this.props.user.store.key,this.props.user.store.StoreCode,  this.state.sale.Customer.key, this.state.sale.Customer.PhoneNo , this.state.paymentModeAmount)
    //         // console.log('validateReponse',validateReponse)
    //         if(validateReponse && !validateReponse.Success){
    //             this.setState({ selectedPaymentOptionLoading: false });
    //             alert(validateReponse.Message);
    //             return;
    //         }

    //         // !validateReponse.hasOwnProperty("points_value")

    //         this.setState({
    //             loyaltyPoints: loyaltyPoints,
    //             loyaltyValue: loyaltyValue,
    //             pointRedemption: pointRedemption,
    //             loyaltyType: loyaltyType,
    //             loyaltyTypeID: loyaltyType,
    //             selectedPaymentOptionLoading: false
    //         })
    //         OTPVerificationRequired= true;
    //         this.paymentModeSelected(
    //             paymentMode.key,
    //             paymentMode.PaymentType,
    //             this.state.paymentModeAmount,
    //             walletBalance,
    //             OTPVerificationRequired)
    //     }
    //     else if(this.props.registration.registration.EnableEasyRewardz){
            
    //         let response = await getCustomerEasyRewardzLoyaltyPoints(
    //             this.props.user.store.RegistrationID, 
    //             this.props.user.store.key, 
    //             this.props.user.store.StoreCode,
    //             this.state.sale.Customer.key, 
    //             this.state.sale.Customer.PhoneNo)
            
    //         if(!response.Success){
    //             this.setState({ selectedPaymentOptionLoading: false });
    //             alert("Customer loyalty not found. Please try again or contact system administrator.");
    //             return;
    //         }
    //         let loyaltyType = "Loyalty";
    //         let loyaltyPoints = response.Data.AvailablePoints;
    //         let loyaltyValue = response.Data.PointValue;
    //         let pointRedemption = response.Data.PointRate;
    //         if(Number(loyaltyValue)< Number(this.state.paymentModeAmount)){
    //             this.setState({ selectedPaymentOptionLoading: false });
    //             alert("Available loyalty point value is " + loyaltyValue + ". Please select amount less than or equal to available loyalty point value" );
    //             return;
    //         }
    //         //call OTP validate method
    //         let transactionCode = this.props.user.store.StoreCode + Math.floor(Math.random() * 10000000000);
    //         const monthNames = ["January", "February", "March", "April", "May", "June",
    //         "July", "August", "September", "October", "November", "December"
    //         ];
    //         let redemptionDate = new Date().getDate() + " " + monthNames[new Date().getMonth()] + " " + new Date().getFullYear();
    //         let validateReponse = await validateEasyRewardzCustomerLoyaltyPoints(
    //             this.props.user.store.RegistrationID, 
    //             this.props.user.store.key,
    //             this.props.user.store.StoreCode,  
    //             this.state.sale.Customer.key, 
    //             this.state.sale.Customer.PhoneNo , 
    //             transactionCode,
    //             redemptionDate,
    //             this.state.sale.BillAmount,
    //             this.state.paymentModeAmount
    //             )

    //         // console.log('validateReponse',validateReponse)
    //         if(validateReponse && !validateReponse.Success){
    //             this.setState({ selectedPaymentOptionLoading: false });
    //             alert(validateReponse.Message);
    //             return;
    //         }
    //         this.setState({
    //             loyaltyPoints: Number(loyaltyPoints),
    //             loyaltyValue: Number(loyaltyValue),
    //             pointRedemption: pointRedemption,
    //             loyaltyType: loyaltyType,
    //             loyaltyTypeID: loyaltyType,
    //             selectedPaymentOptionLoading: false
    //         })
    //         OTPVerificationRequired= true;
    //         this.paymentModeSelected(
    //             paymentMode.key,
    //             paymentMode.PaymentType,
    //             this.state.paymentModeAmount,
    //             walletBalance,
    //             OTPVerificationRequired)
    //     }
    //     else
    //     {
    //         //get customer current points ..loyalty type and points
    //         let customerLoyalty = await this.props.getCustomerLoyaltyPoints(this.state.sale.Customer.key)
    //         if (!customerLoyalty) {
    //             alert("Customer loyalty not found. Please try again or contact system administrator.")
    //             if (!window.cordova) { this.refs.txtPaymentModeAmount.focus() }
    //             this.setState({ selectedPaymentOptionLoading: false })
    //             return
    //         }
            
    //         let loyatySetup = await this.props.getLoyaltySetupDetails(this.props.registration.registration.key)
    //         let pointRedemption = 1
    //         if (loyatySetup) {
    //             pointRedemption = Number(loyatySetup.PointRedemption)
    //         }
    //         if (paymentMode.OTPVerification) {
    //             OTPVerificationRequired = paymentMode.OTPVerification
    //             let otpGenerated = await this.props.generateOTP(
    //                 this.props.user.user.RegistrationID, 
    //                 this.props.user.store.key,
    //                 this.props.user.cashRegister.key, 
    //                 this.state.sale.Customer.key, 
    //                 "loyaltyotp")
    //             if (!otpGenerated) {
    //                 alert("Unable to generate OTP")
    //                 if (!window.cordova) { this.refs.txtPaymentModeAmount.focus() }
    //                 this.setState({ selectedPaymentOptionLoading: false })
    //                 return
    //             }
    //         }
    //         let loyaltyPoints = Number(customerLoyalty.LoyaltyPoints)
    //         let loyaltyValue = Number(loyaltyPoints) / Number(pointRedemption === 0 ? 1 : pointRedemption)
    //         this.setState({
    //             loyaltyPoints: loyaltyPoints,
    //             loyaltyValue: loyaltyValue,
    //             pointRedemption: pointRedemption,
    //             loyaltyType: customerLoyalty.LoyaltyType,
    //             loyaltyTypeID: customerLoyalty.LoyaltyTypeID,
    //             selectedPaymentOptionLoading: false
    //         })

    //         this.paymentModeSelected(
    //             paymentMode.key,
    //             paymentMode.PaymentType,
    //             this.state.balanceAmount,
    //             walletBalance,
    //             OTPVerificationRequired)
    //     }
    // }

 
    async onNextClicked(){
        if(this.state.loyaltyAmountToRedeem < 1){
            alert("Please provide loyalty amount to redeem");
            return;
        }
        console.log('loyaltyAmountToRedeem',this.state.loyaltyAmountToRedeem)
        if(Number(this.state.loyaltyAmountToRedeem) > Number(this.state.loyaltyValue)){
            alert("Available loyalty point value is " + this.state.loyaltyValue + ". Please select amount less than or equal to available loyalty point value" );
            return;
        }
        if(Number(this.props.BalanceAmount)< Number(this.state.loyaltyAmountToRedeem)){
            alert("Loylaty amount to redeem can not be more than " + this.props.BalanceAmount);
            return;
        }
        this.setState({processing:true});
        if(this.props.registration.registration.EnableMLoyal){
            let validateReponse = await validateMLoyalCustomerLoyaltyPoints(this.props.user.store.RegistrationID, 
                this.props.user.store.key,this.props.user.store.StoreCode,  
                this.props.Customer.key, this.props.Customer.PhoneNo , this.state.loyaltyAmountToRedeem)
            // console.log('validateReponse',validateReponse)
            if(validateReponse && !validateReponse.Success){
                this.setState({ selectedPaymentOptionLoading: false });
                alert(validateReponse.Message);
                return;
            }
            this.setState({amountEntered:true, processing:false});   
        }
        else if(this.props.registration.registration.EnableEasyRewardz){
            let transactionCode = this.props.user.store.StoreCode + Math.floor(Math.random() * 10000000000);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            let day = new Date().getDate();
            if(day.toString().length===1){
                day="0" + day;
            }
            let redemptionDate = day + " " + monthNames[new Date().getMonth()] + " " + new Date().getFullYear();
            let validateReponse = await validateEasyRewardzCustomerLoyaltyPoints(
                this.props.user.store.RegistrationID, 
                this.props.user.store.key,
                this.props.user.store.StoreCode,  
                this.props.Customer.key, 
                this.props.Customer.PhoneNo , 
                transactionCode,
                redemptionDate,
                this.props.Sale.BillAmount,
                this.state.loyaltyAmountToRedeem
                )

            // console.log('validateReponse',validateReponse)
            if(validateReponse && !validateReponse.Success){
                this.setState({ selectedPaymentOptionLoading: false });
                alert(validateReponse.Message);
                return;
            }
            this.setState({
                transactionCode:transactionCode,
                redemptionDate:redemptionDate,
                amountEntered:true,
                processing:false
            })
            this.props.redemptionInitiated(transactionCode, redemptionDate);
        }else{
            let otpGenerated = await this.props.generateOTP(
                    this.props.user.user.RegistrationID, 
                    this.props.user.store.key,
                    this.props.user.cashRegister.key, 
                    this.props.Customer.key, 
                    "loyaltyotp")
            if (!otpGenerated) {
                alert("Unable to generate OTP");
            }
            this.setState({ processing: false, amountEntered:true })
        }
    }

    onSubmitClicked(){
        this.setState({processing:true})
        if (Number(this.state.loyaltyAmountToRedeem) > Number(this.state.loyaltyValue)) {
            alert("Insuficient loyalty balance")
            this.setState({ processing: false })
            return
        }
        // this.props.addToPayments(this.state.loyaltyAmountToRedeem);

        this.props.addToPayments(
            this.state.loyaltyAmountToRedeem,
            this.state.transactionCode,
            this.state.redemptionDate,
            this.state.OTP.trim());
    }

    async onValidateOTP(){
        this.setState({processing:true})
        if (Number(this.state.loyaltyAmountToRedeem) > Number(this.state.loyaltyValue)) {
            alert("Insuficient loyalty balance")
            this.setState({ processing: false })
            return
        }
        if (this.state.OTP.trim() === "") {
            alert("Please provide OTP")
            this.setState({ processing: false })
            return
        }
        if(this.props.registration.registration.EnableMLoyal){
            // console.log('mloyal otp verification');
            let transactionID = this.props.user.store.StoreCode + Math.floor(Math.random() * 10000000000)
            let response = await redeemMLoyalCustomerLoyaltyPoints(
                this.props.user.store.RegistrationID, 
                this.props.user.store.key,
                this.props.user.store.StoreCode,  
                this.props.Customer.key, 
                this.props.Customer.PhoneNo, 
                this.state.loyaltyAmountToRedeem,
                this.state.OTP.trim(),
                transactionID
            )
            // console.log('response',response);
            if(!response.Success){
                alert('Invalid OTP');
                this.setState({ processing: false });
                return;
            }
        }
        else if(this.props.registration.registration.EnableEasyRewardz){

            // registrationID,customerMobileNo, storeCode, transactionCode, transactionDate, OTP
           let response = await validateEasyRewardzOTP(
                this.props.user.store.RegistrationID, 
                this.props.Customer.PhoneNo,
                this.props.user.store.StoreCode,
                this.state.transactionCode,
                this.state.redemptionDate,
                this.state.OTP.trim()
                )
            if(!response.Success){
                alert('Invalid OTP');
                this.setState({processing:false});
                return;
            }
        }
        else
        {
            let otpVerified = await this.props.validateOTP(this.props.user.user.RegistrationID, 
                this.props.user.store.key,
                this.props.user.cashRegister.key, 
                this.props.Customer.key, this.state.OTP.trim())
            if (!otpVerified) {
                alert('Invalid OTP')
                this.setState({ processing: false })
                return
            }
        }

        this.setState({
            processing: false, 
            loyaltyPoints: 0,
            loyaltyValue: 0,
            pointRedemption: 0,
            loyaltyType: '',
            loyaltyTypeID: ''
        })
        this.props.addToPayments(
            this.state.loyaltyAmountToRedeem,
            this.state.transactionCode,
            this.state.redemptionDate,
            this.state.OTP.trim());

    }

    async onCancel(){
        this.setState({processing:true});
        if(this.props.registration.registration.EnableEasyRewardz){
            let resposne = await releaseEasyRewardzRedemptionPoints(
                this.props.user.store.RegistrationID,
                this.props.Customer.PhoneNo, 
                this.props.user.store.StoreCode,
                this.state.transactionCode,
                this.state.redemptionDate
             )
            if(!resposne.Success){
                alert("Unable to release blocked points. Please try again.");
                this.setState({processing:false});
                return;
            }
            this.props.onCancel()
        }
    }

    render(){
        return <React.Fragment>
            {/* {this.props.mobile && <div className="row">
                <div className="col-xs-4" style={{padding:"0px"}}>
                    <input type="number" 
                        className="form-control input-lg" 
                        value={this.props.paymentModeAmount}
                        onChange={(evt)=> this.props.onPaymentModeAmount(evt.target.value) }
                        />
                    </div>
                    <div className="col-xs-8" style={{padding:"0px"}}>
                        <input type="text" 
                        className="form-control input-lg"  
                        onChange={(evt)=> this.props.onPaymentModeRemarks(evt.target.value)}
                        value={this.props.paymentModeRemarks}
                        placeholder = "Remarks"/>
                </div>
            </div>} */}

            <div className="row">
                <div className="form-group">
                            <label>Loyalty Type</label>
                            <input type="text" className="form-control input-lg" 
                            disabled={true}
                            value={this.state.loyaltyType}
                            />
                </div>
                <div className="form-group">
                            <label>Available Loyalty Point(s)</label>
                            <input type="text" className="form-control input-lg" 
                            disabled={true}
                            value={this.state.loyaltyPoints}
                                />
                </div>
                <div className="form-group">
                            <label>Available Loyalty Amount</label>
                            <input type="text" className="form-control input-lg" 
                            disabled={true}
                            value={this.state.loyaltyValue}
                                />
                </div>
                <div className="form-group">
                            <label>Loyalty Amount To Redeem</label>
                            <input type="number" className="form-control input-lg" 
                            disabled={this.state.amountEntered}
                            value={this.state.loyaltyAmountToRedeem}
                            onChange={(evt)=>this.setState({loyaltyAmountToRedeem:evt.target.value})}
                            />
                </div>
                {/* <div className="form-group">
                            <label>Remarks</label>
                            <input type="text" className="form-control input-lg" 
                            value={this.state.remarks}
                            onChange={(evt)=>this.setState({remarks:evt.target.value})}
                />
                </div> */}

                {!this.state.OTPVerificationRequired  && <Button type="primary" text="Submit" processing={this.state.processing} onClick={this.onSubmitClicked}/>}

                {this.state.OTPVerificationRequired  &&  !this.state.amountEntered &&  <Button type="primary" text="Next"  processing={this.state.processing} onClick={this.onNextClicked}/>}
                
                {this.state.OTPVerificationRequired  &&  this.state.amountEntered &&  <React.Fragment>
                    <div className="form-group">
                        <label>OTP</label>
                        <input type="text"  className="form-control input-lg" 
                        value={this.state.OTP}
                        onChange={(evt)=> this.setState({OTP:evt.target.value})} />
                    </div>

                    {this.props.registration.registration.EnableEasyRewardz && <Button type="secondary" text="Cancel" onClick={this.onCancel}  processing={this.state.processing}/>}

                    <Button type="primary" text="Validate OTP" onClick={this.onValidateOTP}  processing={this.state.processing}/>

                </React.Fragment>}
            </div>

            {/* {this.props.mobile && <div className="row">
                <button type="submit" className="btn btn-flat btn-md btn-default" onClick={this.props.onCancelClicked} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.props.onOKClicked}>OK</button>
            </div>}  */}

        </React.Fragment>
    }
}

export default PaymentLoyaltyWidget