import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'

class CategoryWiseDetail extends Component {
    constructor() {
        super()
        this.state = {
            itemSold: [],
            stores: [],
            storeList: [],
            searchCategory: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],            
            exportCalled: false,
            loading: true,
            storeSpecificBreakup: true
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
        this.loadItemCategorySoldReportData = this.loadItemCategorySoldReportData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this);
    }

    async componentDidMount() {

        await this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            if(stores.length > 1)
            {
                stores.unshift({ key: '', StoreCode: 'All', StoreName: 'Stores' })
            }
            this.setState({ stores: stores })
        });
        let storeList = []
        storeList.push({ key: this.props.user.store.key})
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });


        let searchTransactionToDate = this.state.searchTransactionToDate
        let searchTransactionFromDate = this.state.searchTransactionFromDate
        let storeSpecificBreakup = this.state.storeSpecificBreakup

        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
           
            searchTransactionToDate  = this.props.location.state.searchTransactionToDate
            searchTransactionFromDate  = this.props.location.state.searchTransactionFromDate
            storeList = this.props.location.state.storeList
            storeSpecificBreakup = false

            this.setState({
                searchTransactionToDate: searchTransactionToDate,
                searchTransactionFromDate: searchTransactionFromDate,
                storeList: storeList,
                storeID: storeList.length > 1 ? "" : storeList[0].key,
                storeSpecificBreakup : storeSpecificBreakup
            });
        }

        this.loadItemCategorySoldReportData(storeList, searchTransactionToDate,
            searchTransactionFromDate,
            this.state.searchCategory,
            storeSpecificBreakup
        );
    }

    loadItemCategorySoldReportData(storeList, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchStoreSpecific) {
        this.setState({ loading: true })
        this.props.getItemCategorySoldReportData(this.props.user.user.RegistrationID, storeList, searchTransactionToDate,
            searchTransactionFromDate, searchCategory, searchStoreSpecific)
            .then((result) => {
                // console.log(JSON.stringify(result.searchResult))
                this.setState({ itemSold: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
            })

    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({ storeList: storeList, searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0], searchCategory: '', searchSubCategory: '',
            searchProductSKU: '', searchProductName: '', searchBrand: '', storeSpecificBreakup: true
        })
        this.loadItemCategorySoldReportData(storeList, new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", true);
    }

    onExportClick() {
        this.setState({ loading: false, exportCalled: true })
        this.props.getItemCategorySoldReportData(this.props.user.user.RegistrationID,
            this.state.storeList,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory, this.state.storeSpecificBreakup)
            .then((result) => {
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let products = []
                result.searchResult.map((data) => {

                    let product = {
                        "Store": data.store,
                        "Category": data.category,
                        "Qty": data.total_quantity,
                        "Discount": data.total_discount,
                        "Taxable Amount": data.total_taxable,
                        "Total Tax": data.total_taxes,
                        "Total Sales": data.total_sales
                    }
                    products.push(product);
                })
                const csvData = constatnts.objToCSV(products);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "CategoryWise.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.loadItemCategorySoldReportData(
            this.state.storeList,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory,
            this.state.storeSpecificBreakup
        )
    }

    onStoreChange(e) {
        let storeList = []
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onBackClick()
    {
        if (this.props.location.state && this.props.location.state.action === 'Sales Dashboard') {
            this.props.history.push({ pathname: "/home/reports/salesdashboard" });
        }
        else
        {
            this.props.history.push({ pathname: "/home/reports/allreport" });
        }
    };

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">                                       
                                        <font style={{ fontSize: "30px" }}>Category Wise Sold Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={this.onBackClick}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Store Name</label>
                                            <select type="text" className="form-control"
                                                value={this.state.storeID}
                                                onChange={this.onStoreChange}>
                                                {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                    <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                                )}
                                            </select>
                                            <input type="checkbox" checked={this.state.storeSpecificBreakup}
                                                    onChange={() => { 
                                                        this.setState({ storeSpecificBreakup: !this.state.storeSpecificBreakup })
                                                        this.loadItemCategorySoldReportData(this.state.storeList,this.state.searchTransactionToDate, this.state.searchTransactionFromDate, this.state.searchCategory, !this.state.storeSpecificBreakup)
                                                        }} />
                                                &nbsp;&nbsp;<label><strong>Show Store-Wise break-up</strong></label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row" >
                                    <div className="col-md-12" style={ {marginTop: "-25px"} }>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate===''}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px", marginRight: "5px" }} onClick={this.onExportClick} > Export <i className="glyphicon glyphicon-download-alt " ></i></button>

                                        {/* <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a> */}
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {this.state.storeSpecificBreakup && <th>Store Name</th> }
                                                <th>Category</th>
                                                <th>Qty</th>
                                                <th>Discount</th>
                                                <th>Taxable Amount</th>
                                                <th>Total Taxes</th>
                                                <th>Total Sales</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.itemSold.map((item, index) =>
                                                <tr>
                                                    {this.state.storeSpecificBreakup &&<td>{item.store}</td>}
                                                    <td>{item.category}</td>
                                                    <th>{item.total_quantity}</th>
                                                    <th>{item.total_discount}</th>
                                                    <th>{item.total_taxable}</th>
                                                    <th>{item.total_taxes}</th>
                                                    <th>{item.total_sales}</th>


                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    </div>}                                    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(CategoryWiseDetail)
