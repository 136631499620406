import React,{Component} from 'react';
import {database} from '../../firebase'


class OmniTransactionCheck extends Component
{
    constructor()
    {
        super();
        this.state={
            orderID:'',
            records:[],
            orderIDError:''
        }
        this.getOnlineOrderDetails= this.getOnlineOrderDetails.bind(this)
        this.createOrder = this.createOrder.bind(this)
     }

    async componentDidMount() {
        let q = database.collection("OmniLog")
        console.log('omni');
        q.get().then((querySnapshot) => {
            const records = []
            let ctr = 0;
            querySnapshot.forEach((doc) => {
                let rec = doc.data();
                ctr= ctr+ 1;
                if(rec.hasOwnProperty('LogData')){
                    if(rec.LogData.hasOwnProperty('Sale')){
                        if(rec.LogData.Sale.Customer.PhoneNo ==="9022498323"){
                            records.push(
                                {
                                  key:doc.id,...doc.data()
                                })
                        }
                    }
                }
               console.log('ctr', ctr)
           })
           console.log('records',records)
            this.setState({records:records}) 
        })
    }

    getOnlineOrderDetails(){
        if(this.state.orderID.trim()==="")
        {
            this.setState({orderIDError:"Please specify order id"})
            return;
        }

        let q = database.collection("OmniLog").where("Key", "==", this.state.orderID);
        
         q.get().then((querySnapshot) => {
            const records = []
            querySnapshot.forEach((doc) => {
                records.push(
                  {
                    key:doc.id,...doc.data()
                  })
           })
           console.log('records',records)
            this.setState({records:records}) 
        })
    }

    async createOrder(record){
        if(record.hasOwnProperty('LogData') && record.LogData.hasOwnProperty('Sale')){
            //create order
            var response=await fetch('https://us-central1-posible-app-prod.cloudfunctions.net/apiCreateSales',{method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({Sale: record.LogData.Sale}) });
                // body: {Sale: record.LogData.Sale}
            // });

                // if (response.status === 200 && response.data.status === "Success") {
                    console.log('response', response)
        }
        else
        {
            alert('Sale record not found')
        }
    }
    render() {
        return <div className="content-wrapper" >
                    <section className="content-header">
                        <h1>Check online payment status</h1>
                    </section>
                    <section className="content">
                        <div className="row">
                            <div className="col-md-4">
                                <div className={this.state.orderIDError !== "" ? "form-group has-error" : "form-group "} >
                                    <label>Order ID</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => {this.setState({ orderID: evt.target.value, titleError: "" })}}
                                        value={this.state.orderID}
                                    />
                                    {this.state.orderIDError !== "" && <span className="help-block">{this.state.orderIDError}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                                    onClick={this.getOnlineOrderDetails}>Get
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                {this.state.records.map((m,index)=> <div key={index}>
                                        {m.Key} {m.Action}
                                        {m.Action === "razorpay_start" && <button className="btn btn-md btn-flat" onClick={()=>this.createOrder(m)}>Create Order</button>}
                                </div>)}
                            </div>
                        </div>
                    </section>
                </div>

    }
}

export default OmniTransactionCheck;