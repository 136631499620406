import axios from 'axios';

export function getUserIP( ){
        return axios
        .post('https://us-central1-posible-app-prod.cloudfunctions.net/getUserIP',{})
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log('validateMLoyalCustomerLoyaltyPoints', err);
        });
    }
