import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Button from "../2/Controls/Button";
import firebase from "../../firebase";

class GSTR1TransactionWise extends Component {
  constructor() {
    super();
    this.state = {
      taxes: [
        { name: "CGST", perc: "CGST%" },
        { name: "SGST", perc: "SGST%" },
        { name: "IGST", perc: "IGST%" },
        { name: "CESS", perc: "CESS%" },
      ],
      invoices: [],
      stores: [],
      storeList: [],
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      activePage: 1,
      itemsCountPerPage: 10000,
      totalItemsCount: 0,
      recordFrom: 0,
      title: "",
      loading: false,
      gstinCustomFieldNo: 0,
      includeGSTImportColumnsOnly: false,
      years: [],
      months: [],
      selectedYear: 0,
      selectedMonth: 0,
    };
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
    this.getElasticData = this.getElasticData.bind(this);
  }

  componentDidMount() {
    let selectedYear = new Date().getFullYear();
    let selectedMonth = new Date().getMonth();
    let years = this.props.getYears();
    let months = this.props.getMonths(selectedYear);
    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });
    this.setState({
      storeID: this.props.user.store.key,
      storeList: storeList,
      years: years,
      months: months,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Customer") {
        let gstinCustomFieldNo = 0;
        if (customField.CustomField1Name.trim().toLowerCase() === "gstin") {
          gstinCustomFieldNo = 1;
        } else if (
          customField.CustomField2Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 2;
        } else if (
          customField.CustomField3Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 3;
        } else if (
          customField.CustomField4Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 4;
        } else if (
          customField.CustomField5Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 5;
        } else if (
          customField.CustomField6Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 6;
        }
        this.setState({ gstinCustomFieldNo: gstinCustomFieldNo });
      }
    });
  }

  onYearChange(evt) {
    let selectedYear = evt.target.value;
    console.log("onYearChange", selectedYear);
    let months = this.props.getMonths(selectedYear);
    let selectedMonth = months[months.length - 1].Month;
    let searchTransactionFromDate = this.getMonthStartDate(
      selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      selectedYear,
      selectedMonth
    );
    this.setState({
      months: months,
      selectedYear: selectedYear,
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });
  }

  onMonthChange(evt) {
    let selectedMonth = evt.target.value;
    let searchTransactionFromDate = this.getMonthStartDate(
      this.state.selectedYear,
      selectedMonth
    );
    let searchTransactionToDate = this.getMonthEndDate(
      this.state.selectedYear,
      selectedMonth
    );
    this.setState({
      selectedMonth: selectedMonth,
      searchTransactionFromDate: searchTransactionFromDate,
      searchTransactionToDate: searchTransactionToDate,
    });
  }

  getMonthStartDate(selectedYear, selectedMonth) {
    return this.props.getStartDateOfMonth(selectedYear, selectedMonth);
  }

  getMonthEndDate(selectedYear, selectedMonth) {
    return this.props.getEndDateOfMonth(selectedYear, selectedMonth);
  }

  async getElasticData(
    registrationID,
    searchStores,
    searchTransactionType,
    searchTransactionFromDate,
    searchTransactionToDate
  ) {
    let searchParameters = {
      _source: [
        "TaxSummaryList",
        "Customer",
        "PlaceOfSupply",
        "TransactionNo",
        "TransactionDate",
        "BillAmount",
        "BillTaxAmount",
        "LineItems",
      ],
      from: 0,
      size: 100,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                RegistrationID: registrationID,
              },
            },
            {
              range: {
                TransactionDate: {
                  lte: searchTransactionToDate,
                  gte: searchTransactionFromDate,
                  format: "yyyy-MM-dd",
                },
              },
            },
          ],
          must_not: [
            {
              exists: {
                field: "CancellationReason",
              },
            },
          ],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              should: [],
            },
          },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };
    if (searchTransactionType.trim() !== "") {
      searchParameters.query.bool.filter.bool.should.push({
        regexp: {
          "TransactionType.key": searchTransactionType.trim().toLowerCase(),
        },
      });
    }
    for (let i = 0; i < searchStores.length; i++) {
      const store = searchStores[i];

      searchParameters.query.bool.should.push({
        term: {
          "Store.key.keyword": store.key,
        },
      });
    }

    // finalAPI= "scrollData";
    // finalAPIMessage.collection = "sales1";
    // finalAPIMessage.scrollID=""
    let addMessage = firebase.functions().httpsCallable("scrollData");
    let result = await addMessage({
      text: searchParameters,
      collection: "sales1",
      scrollID: "",
    });
    console.log("searchParameters pawan", JSON.stringify(searchParameters));
    const invoices = [];
    //   console.log('result', result);
    //   return;
    let sanitizedMessage = result.data.text;
    let total = sanitizedMessage.hits.total.value;
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ ...data._source, key: data._id });
    });

    //   console.log('result',result);
    //   console.log('invoices', invoices)
    if (total > 100) {
      let scrollID = sanitizedMessage._scroll_id;
      console.log("scrollID", scrollID);
      searchParameters = {
        scroll: "1m",
        scroll_id: scrollID,
      };
      for (let index = 1; index < total / 100; index++) {
        let addMessage = firebase.functions().httpsCallable("scrollData");
        let loopResult = await addMessage({
          text: searchParameters,
          scrollID: scrollID,
        });
        //   console.log('loopResult',loopResult)
        loopResult.data.text.hits.hits.map((data, index) => {
          invoices.push({ ...data._source, key: data._id });
        });
      }
    }
    console.log("invoices", invoices);
    return invoices;
  }

  async loadData(
    storeList,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate
  ) {
    let result = await this.getElasticData(
      this.props.user.user.RegistrationID,
      storeList,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate
    );

    let invoices = [];
    let gstinCustomFieldNo = this.state.gstinCustomFieldNo;
    for (let index = 0; index < result.length; index++) {
      const data = result[index];
      if (!data.hasOwnProperty("LineItems")) {
        continue;
      }

      if (data.LineItems) {
        let invoiceTaxes = [];
        for (let l = 0; l < data.LineItems.length; l++) {
          let taxRate = 0;
          let cessAmount = 0;
          let cgstPerc = 0;
          let cgstAmount = 0;
          let sgstPerc = 0;
          let sgstAmount = 0;
          let igstPerc = 0;
          let igstAmount = 0;
          let cessPerc = 0;
          if (data.LineItems[l].TotalTax === 0) {
            continue;
          }
          data.LineItems[l].TaxComponentAmount.map((m) => {
            if (
              m.TaxType === "CGST" ||
              m.TaxType === "SGST" ||
              m.TaxType === "IGST"
            ) {
              taxRate = Number(taxRate) + Number(m.TaxPercentage);
              if (m.TaxType === "CGST") {
                cgstPerc = Number(m.TaxPercentage);
                cgstAmount = Number(cgstAmount) + Number(m.TaxAmount);
              }
              if (m.TaxType === "SGST") {
                sgstPerc = Number(m.TaxPercentage);
                sgstAmount = Number(sgstAmount) + Number(m.TaxAmount);
              }
              if (m.TaxType === "IGST") {
                igstPerc = Number(m.TaxPercentage);
                igstAmount = Number(igstAmount) + Number(m.TaxAmount);
              }
            } else if (m.TaxType === "CESS") {
              cessPerc = Number(m.TaxPercentage);
              cessAmount = Number(cessAmount) + Number(m.TaxAmount);
            }
          });
          if (invoiceTaxes.filter((f) => f.taxRate === taxRate).length > 0) {
            invoiceTaxes.map((k) => {
              if (k.taxRate === taxRate) {
                k.taxableAmount =
                  Number(k.taxableAmount) +
                  Number(data.LineItems[l].TaxableAmount);
                k.cessAmount = Number(k.cessAmount) + Number(cessAmount);
                k.cgstPerc = Number(k.cgstPerc);
                k.cgstAmount = Number(k.cgstAmount) + Number(cgstAmount);
                k.sgstPerc = Number(k.sgstPerc);
                k.sgstAmount = Number(k.sgstAmount) + Number(sgstAmount);
                k.igstPerc = Number(k.igstPerc);
                k.igstAmount = Number(k.igstAmount) + Number(igstAmount);
                k.cessPerc = Number(k.cessPerc);
              }
            });
          } else {
            invoiceTaxes.push({
              taxRate: taxRate,
              cessAmount: cessAmount,
              taxableAmount: data.LineItems[l].TaxableAmount,
              cgstPerc: cgstPerc,
              cgstAmount: cgstAmount,
              sgstPerc: sgstPerc,
              sgstAmount: sgstAmount,
              igstPerc: igstPerc,
              igstAmount: igstAmount,
              cessPerc: cessPerc,
            });
          }
        }
        // console.log('invoiceTaxes',invoiceTaxes)
        for (let g = 0; g < invoiceTaxes.length; g++) {
          let invoice = {
            "Customer Name": data.Customer
              ? data.Customer.FirstName + " " + data.Customer.LastName
              : "",
            "Customer GSTIN": "",
          };
          if (gstinCustomFieldNo === 1) {
            invoice["Customer GSTIN"] = data.Customer
              ? data.Customer.CustomField1
              : "";
          } else if (gstinCustomFieldNo === 2) {
            invoice["Customer GSTIN"] = data.Customer
              ? data.Customer.CustomField2
              : "";
          } else if (gstinCustomFieldNo === 3) {
            invoice["Customer GSTIN"] = data.Customer
              ? data.Customer.CustomField3
              : "";
          } else if (gstinCustomFieldNo === 4) {
            invoice["Customer GSTIN"] = data.Customer
              ? data.Customer.CustomField4
              : "";
          } else if (gstinCustomFieldNo === 5) {
            invoice["Customer GSTIN"] = data.Customer
              ? data.Customer.CustomField5
              : "";
          } else if (gstinCustomFieldNo === 6) {
            invoice["Customer GSTIN"] = data.Customer
              ? data.Customer.CustomField6
              : "";
          }
          invoice["Place Of Supply"] = data.PlaceOfSupply;
          invoice["Transaction Number"] = data.TransactionNo;
          invoice["Transaction Date"] = new Date(data.TransactionDate)
            .toISOString()
            .split("T")[0];
          invoice["Transaction Amount"] = data.BillAmount;
          // invoice["Rate"] = invoiceTaxes[g].taxRate
          invoice["Taxable Amount"] = Number(
            invoiceTaxes[g].taxableAmount
          ).toFixed(2);
          // invoice["Cess Amount"] = Number(invoiceTaxes[g].cessAmount).toFixed(2)
          // if(!this.state.includeGSTImportColumnsOnly)
          // {
          invoice["CGST %"] = Number(invoiceTaxes[g].cgstPerc);
          invoice["CGST Amount"] = Number(invoiceTaxes[g].cgstAmount).toFixed(
            2
          );
          invoice["SGST %"] = Number(invoiceTaxes[g].sgstPerc);
          invoice["SGST Amount"] = Number(invoiceTaxes[g].sgstAmount).toFixed(
            2
          );
          invoice["IGST %"] = Number(invoiceTaxes[g].igstPerc);
          invoice["IGST Amount"] = Number(invoiceTaxes[g].igstAmount).toFixed(
            2
          );
          invoice["CESS %"] = Number(invoiceTaxes[g].cessPerc);
          invoice["CESS Amount"] = Number(invoiceTaxes[g].cessAmount).toFixed(
            2
          );
          let totTaxAmt = Number(
            Number(invoiceTaxes[g].cgstAmount) +
              Number(invoiceTaxes[g].sgstAmount) +
              Number(invoiceTaxes[g].igstAmount) +
              Number(invoiceTaxes[g].cessAmount)
          ).toFixed(2);
          invoice["Total Tax Amount"] = totTaxAmt; //Number(Number(invoiceTaxes[g].cgstAmount) +  Number(invoiceTaxes[g].sgstAmount)  +   Number(invoiceTaxes[g].igstAmount) + Number(invoiceTaxes[g].cessAmount)).toFixed(2);
          // if(totTaxAmt !== data.BillTaxAmount){
          //     console.log('data.TransactionNo',data.TransactionNo)
          //     console.log('Calculated tax amt',totTaxAmt)
          //     console.log('data.BillTaxAmount',data.BillTaxAmount)

          // }
          invoices.push(invoice);
        }
      }
    }
    return invoices;
  }

  async onExportClick() {
    this.setState({ loading: true });
    let invoices = await this.loadData(
      this.state.storeList,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );

    const csvData = constatnts.objToCSV(invoices);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "GSTR1TransactionWise.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ loading: false });
  }

  onStoreChange(e) {
    let storeList = [];
    // console.log("key", e.target.value);
    if (e.target.value != "") {
      storeList.push({ key: e.target.value });
    }
    // console.log("storeList", JSON.stringify(storeList));
    this.setState({ storeList: storeList, storeID: e.target.value });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        GSTR1 - Transaction Wise
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Store Name</label>
                      <select
                        className="form-control"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>Transaction Type</label>
                        <select
                          type="text"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactiontype: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactiontype}
                        >
                          <option value="">All</option>
                          {this.props.config.TransactionTypes.filter(
                            (e) => e.Module === "sales" && !e.IsDeleted
                          ).map((e) => (
                            <option value={e.key}>{e.TransactionType}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Year</label>
                        <select
                          type="text"
                          className="form-control"
                          value={this.state.selectedYear}
                          onChange={(evt) => this.onYearChange(evt)}
                        >
                          {this.state.years.map((e) => (
                            <option value={e}>{e}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Month</label>
                        <select
                          type="text"
                          className="form-control"
                          value={this.state.selectedMonth}
                          onChange={this.onMonthChange}
                        >
                          {this.state.months.map((e) => (
                            <option value={e.Month}>{e.MonthName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <Button
                        type="primary"
                        text={
                          this.state.loading
                            ? "Downloading data please wait"
                            : "Download report"
                        }
                        processing={this.state.loading}
                        onClick={this.onExportClick}
                      />
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  {/* <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Show</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="fa fa-download " ></i></button>
                                    </div>
                                </div><br></br> */}
                  {/* {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                        {ReactHtmlParser(this.state.invoices)}
                                    </div>
                                } */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(GSTR1TransactionWise);
