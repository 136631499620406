import React, { Component } from 'react'

class InventoryIntermediate extends Component {

    componentDidMount() {
        this.props.history.push({pathname: "/home/inventory/newinventory/", 
        state: { transactionType: this.props.location.state.transactionType, 
            type:this.props.location.state.type, 
            inventory:!this.props.location.state.inventory ? null: this.props.location.state.inventory  }})
    }
    render()
    {
        return <div>Loading..</div>
    }
}

export default InventoryIntermediate