import firebase, {database} from '../firebase'
import * as constants from '../Constatnts'

export function getProductConsumptionDetails(key){
    return dispatch => {
    return database.collection("ProductConsumption").doc(key).get().then((doc) => {
        if (doc.exists) {
           return doc.data()
        } else {
            console.log("No such record found!");
        }
    })
    }
}

export function saveProductConsumption(key, productConsumption)
{
    return (dispatch, getState) =>{
        productConsumption.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        productConsumption.ActionBy = getState().user.user.Name
        productConsumption.ActionByUID = getState().user.user.UID
        productConsumption.ActionByEmailID = getState().user.user.EmailID
        if(key !== "")
        {
            productConsumption.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("ProductConsumption").doc(key).update({
                ...productConsumption
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating Product Consumption: ", error);
              })
        }else if(key == "")
        {
            productConsumption.Action = constants.AUDIT_TRAIL_NEW
            productConsumption.CreatedOn = firebase.firestore.FieldValue.serverTimestamp()
            return database.collection("ProductConsumption").add({
                ...productConsumption
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding Product Consumption: ", error);
              })
        }
    }
}