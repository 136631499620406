import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromRaw } from 'draft-js';
import { SketchPicker } from 'react-color';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

class CustomerReferralProgram extends Component {

    constructor() {
        super()
        this.state = {
            key:"",
            headerImage: null,
            headerImageLoading: false,
            footerImage: null,
            footerImageLoading: false,   
            headerEditorState: EditorState.createEmpty(),
            footerEditorState: EditorState.createEmpty(),
            submissionEditorState:EditorState.createEmpty(),
            customerCustomField1Display: false,
            customerCustomField2Display: false,
            customerCustomField3Display: false,
            customerCustomField4Display: false,
            customerCustomField5Display: false,
            customerCustomField6Display: false,
            customerCustomField1DisplayName: 'Custom Field 1',
            customerCustomField2DisplayName: 'Custom Field 2',
            customerCustomField3DisplayName: 'Custom Field 3',
            customerCustomField4DisplayName: 'Custom Field 4',
            customerCustomField5DisplayName: 'Custom Field 5',
            customerCustomField6DisplayName: 'Custom Field 6',
            customerNameDisplay:true,
            customerPhoneNoDisplay:true,
            customerEmailIDDisplay:true,
            headerBackgroundColor:'',
            footerBackgroundColor:'',
            headerImageAlign:'center',
            headerImageHeight:'',
            headerImageWidth:'',
            footerImageAlign:'center',
            footerImageHeight:'',
            footerImageWidth:'',
            
        }   
        this.onHeaderImageDrop= this.onHeaderImageDrop.bind(this)
        this.onHeaderImageRemove = this.onHeaderImageRemove.bind(this)
        this.onHeaderEditorStateChange = this.onHeaderEditorStateChange.bind(this)
        this.onHeaderColorSelection= this.onHeaderColorSelection.bind(this)
        this.onFooterImageDrop= this.onFooterImageDrop.bind(this)
        this.onFooterImageRemove = this.onFooterImageRemove.bind(this)
        this.onFooterEditorStateChange = this.onFooterEditorStateChange.bind(this)
        this.onFooterColorSelection= this.onFooterColorSelection.bind(this)
        this.onSave= this.onSave.bind(this)
        this.onSubmissionEditorStateChange = this.onSubmissionEditorStateChange.bind(this)
        
    }

    async componentDidMount()
    {
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customerCustomField1DisplayName: customField1DisplayName,
                    customerCustomField2DisplayName: customField2DisplayName,
                    customerCustomField3DisplayName: customField3DisplayName,
                    customerCustomField4DisplayName: customField4DisplayName,
                    customerCustomField5DisplayName: customField5DisplayName,
                    customerCustomField6DisplayName: customField6DisplayName,
                })
            }
        })
        let customerReferralProgram = await this.props.getCustomerReferralProgram(this.props.registration.registration.key)
        if(customerReferralProgram)
        {
            let hblocksFromHtml = htmlToDraft(customerReferralProgram.HeaderText);
            let hcontentState = ContentState.createFromBlockArray(hblocksFromHtml.contentBlocks, hblocksFromHtml.entityMap);
            let heditorState = EditorState.createWithContent(hcontentState);

            let fblocksFromHtml = htmlToDraft(customerReferralProgram.FooterText);
            let fcontentState = ContentState.createFromBlockArray(fblocksFromHtml.contentBlocks, fblocksFromHtml.entityMap);
            let feditorState = EditorState.createWithContent(fcontentState);

            let sblocksFromHtml = htmlToDraft(customerReferralProgram.hasOwnProperty('SubmissionText') ?  customerReferralProgram.SubmissionText: '');
            let scontentState = ContentState.createFromBlockArray(sblocksFromHtml.contentBlocks, sblocksFromHtml.entityMap);
            let seditorState = EditorState.createWithContent(scontentState);


            this.setState({
                key:customerReferralProgram.key,
                headerImage: customerReferralProgram.HeaderImage,
                footerImage: customerReferralProgram.FooterImage,
                headerEditorState:  heditorState ? heditorState : EditorState.createEmpty(),
                footerEditorState: feditorState ? feditorState: EditorState.createEmpty(),
                submissionEditorState: seditorState ?  seditorState : EditorState.createEmpty(),
                customerCustomField1Display: customerReferralProgram.CustomerCustomField1Display,
                customerCustomField2Display: customerReferralProgram.CustomerCustomField2Display,
                customerCustomField3Display: customerReferralProgram.CustomerCustomField3Display,
                customerCustomField4Display: customerReferralProgram.CustomerCustomField4Display,
                customerCustomField5Display: customerReferralProgram.CustomerCustomField5Display,
                customerCustomField6Display: customerReferralProgram.CustomerCustomField6Display,
                customerNameDisplay:customerReferralProgram.CustomerNameDisplay,
                customerPhoneNoDisplay:customerReferralProgram.CustomerPhoneNoDisplay,
                customerEmailIDDisplay:customerReferralProgram.CustomerEmailIDDisplay,
                headerBackgroundColor:customerReferralProgram.HeaderBackgroundColor,
                footerBackgroundColor: customerReferralProgram.hasOwnProperty('FooterBackgroundColor')? customerReferralProgram.FooterBackgroundColor:'',
                headerImageAlign:customerReferralProgram.hasOwnProperty('HeaderImageAlign') ? customerReferralProgram.HeaderImageAlign : '',
                headerImageHeight:customerReferralProgram.hasOwnProperty('HeaderImageHeight') ? customerReferralProgram.HeaderImageHeight : '',
                headerImageWidth:customerReferralProgram.hasOwnProperty('HeaderImageWidth') ? customerReferralProgram.HeaderImageWidth : '',
                footerImageAlign:customerReferralProgram.hasOwnProperty('FooterImageAlign') ? customerReferralProgram.FooterImageAlign : '',
                footerImageHeight:customerReferralProgram.hasOwnProperty('FooterImageHeight') ? customerReferralProgram.FooterImageHeight : '',
                footerImageWidth:customerReferralProgram.hasOwnProperty('FooterImageWidth') ? customerReferralProgram.FooterImageWidth : ''
                })
        }
    }


    onHeaderColorSelection = (color) => {
        console.log('color', color)
        this.setState({ headerBackgroundColor: color.hex });
      };

    onHeaderEditorStateChange(editorState) {
        this.setState({
            headerEditorState: editorState
        })
    }

    onSubmissionEditorStateChange(editorState) {
        this.setState({
            submissionEditorState: editorState
        })
    }

    onHeaderImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ headerImageLoading: true })
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/PrintConfig').then((downloadURL) => {
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                this.setState({ headerImage: image })
                this.setState({ headerImageLoading: false })
            })
        }
        else { alert("No file selected") }
    }

    onHeaderImageRemove() {
        this.setState({ headerImage: null, headerImageLoading: false })
    }
    
    onFooterColorSelection = (color) => {
        this.setState({ footerBackgroundColor: color.hex });
      };

    onFooterEditorStateChange(editorState) {
        this.setState({
            footerEditorState: editorState
        })
    }

    onFooterImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ footerImageLoading: true })
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/PrintConfig').then((downloadURL) => {
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                this.setState({ footerImage: image })
                this.setState({ footerImageLoading: false })
            })
        }
        else { alert("No file selected") }
    }

    onFooterImageRemove() {
        this.setState({ footerImage: null, footerImageLoading: false })
    }

    onSave()
    {
        this.setState({saving:true})
        //check for mandatory fields
        if(!this.state.customerNameDisplay && !this.state.customerPhoneNoDisplay && !this.state.customerEmailIDDisplay )
        {
            alert('Please select either Full Name, Email ID or Phone No')
            return
        }
        let customerReferralProgram = {
            HeaderText: draftToHtml(convertToRaw(this.state.headerEditorState.getCurrentContent())),
            HeaderImage: this.state.headerImage,
            FooterText: draftToHtml(convertToRaw(this.state.footerEditorState.getCurrentContent())),
            SubmissionText:draftToHtml(convertToRaw(this.state.submissionEditorState.getCurrentContent())),
            FooterImage: this.state.footerImage,
            CustomerCustomField1Display: this.state.customerCustomField1Display,
            CustomerCustomField2Display: this.state.customerCustomField2Display,
            CustomerCustomField3Display: this.state.customerCustomField3Display,
            CustomerCustomField4Display: this.state.customerCustomField4Display,
            CustomerCustomField5Display: this.state.customerCustomField5Display,
            CustomerCustomField6Display: this.state.customerCustomField6Display,
            CustomerNameDisplay:this.state.customerNameDisplay,
            CustomerPhoneNoDisplay:this.state.customerPhoneNoDisplay,
            CustomerEmailIDDisplay:this.state.customerEmailIDDisplay,
            HeaderBackgroundColor:this.state.headerBackgroundColor,
            FooterBackgroundColor: this.state.footerBackgroundColor,
            RegistrationID: this.props.registration.registration.key,
            HeaderImageAlign:this.state.headerImageAlign,
            HeaderImageHeight:this.state.headerImageHeight,
            HeaderImageWidth:this.state.headerImageWidth,
            FooterImageAlign:this.state.footerImageAlign,
            FooterImageHeight:this.state.footerImageHeight,
            FooterImageWidth:this.state.footerImageWidth,

        }
        console.log('customerReferralProgram', customerReferralProgram)
        this.props.saveCustomerReferralProgram(customerReferralProgram, this.state.key).then(()=>
            this.props.getToastr("Data saved successfully"))

    }
    render() {
        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Customer Referral Program</h1>
                <button type="submit"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-md btn-success pull-right btn-flat"
                    onClick={this.onSave}>Save</button>
                {/* <button onClick={() => this.onCancelClick()}
                    style={{ marginTop: "-25px" }}
                    className="btn btn-md btn-default btn-flat pull-right">Cancel</button> */}
            </section>
            <section className="content" >
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Header</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <Editor
                                        editorState={this.state.headerEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onHeaderEditorStateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Header Image</label>
                                    {!this.state.headerImageLoading && <React.Fragment>
                                        {!this.state.headerImage &&
                                            <Dropzone onDrop={(files) => this.onHeaderImageDrop(files)} >
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                                        {this.state.headerImage && <div className="form-group">
                                            <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.headerImage.Preview}
                                                        alt="Attachment" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{this.state.headerImage.Name.length > 20 ? this.state.headerImage.Name.substr(0, 20) + "..." : this.state.headerImage.Name}</span>
                                                        <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.headerImage.Size / 1024)} KB
                                                                                <a onClick={this.onHeaderImageRemove}
                                                                className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <table>
                                                <tbody><tr>
                                                    <td>
                                                        Align:
                                                    </td>
                                                    <td>
                                                        <select onChange={(evt)=> this.setState({headerImageAlign: evt.target.value})} 
                                                            value={this.state.headerImageAlign}>
                                                            <option value="center">Center</option>
                                                            <option value="left">Left</option>
                                                            <option value="right">Right</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Width:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number" 
                                                        onChange={(evt)=> this.setState({headerImageWidth: evt.target.value})} 
                                                        value={this.state.headerImageWidth}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Height:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number"  
                                                        onChange={(evt)=> this.setState({headerImageHeight: evt.target.value})} 
                                                        value={this.state.headerImageHeight}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>}
                                    </React.Fragment>}
                                    {this.state.headerImageLoading && <div>Uploading image</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group">
                                <label >Background Color</label>
                                <SketchPicker
                                    color={ this.state.headerBackgroundColor }
                                    onChangeComplete={ this.onHeaderColorSelection }
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Referred customer information to be captured</h3>
                    </div>
                    <div className="box-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th>Field</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerNameDisplay}
                                        onChange={() =>this.setState({ customerNameDisplay: !this.state.customerNameDisplay})}/>
                                    </td>
                                    <td>Full Name</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerPhoneNoDisplay}
                                        onChange={() =>this.setState({ customerPhoneNoDisplay: !this.state.customerPhoneNoDisplay})}/>
                                    </td>
                                    <td>Phone No</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerEmailIDDisplay}
                                        onChange={() =>this.setState({ customerEmailIDDisplay: !this.state.customerEmailIDDisplay})}/>
                                    </td>
                                    <td>Email ID</td>
                                </tr>
                                <tr>                                    
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField1Display}
                                        onChange={() =>this.setState({ customerCustomField1Display: !this.state.customerCustomField1Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField1DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField2Display}
                                        onChange={() =>this.setState({ customerCustomField2Display: !this.state.customerCustomField2Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField2DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField3Display}
                                        onChange={() =>this.setState({ customerCustomField3Display: !this.state.customerCustomField3Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField3DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField4Display}
                                        onChange={() =>this.setState({ customerCustomField4Display: !this.state.customerCustomField4Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField4DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField5Display}
                                        onChange={() =>this.setState({ customerCustomField5Display: !this.state.customerCustomField5Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField5DisplayName}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField6Display}
                                        onChange={() =>this.setState({ customerCustomField6Display: !this.state.customerCustomField6Display})}/>
                                    </td>
                                    <td>{this.state.customerCustomField6DisplayName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Footer</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <Editor
                                        editorState={this.state.footerEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onFooterEditorStateChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Footer Image</label>
                                    {!this.state.footerImageLoading && <React.Fragment>
                                        {!this.state.footerImage &&
                                            <Dropzone onDrop={(files) => this.onHeaderImageDrop(files)} >
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                                        {this.state.footermage && <div className="form-group">
                                            <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.footerImage.Preview}
                                                        alt="Attachment" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{this.state.footerImage.Name.length > 20 ? this.state.footerImage.Name.substr(0, 20) + "..." : this.state.footerImage.Name}</span>
                                                        <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.footerImage.Size / 1024)} KB
                                                                                <a onClick={this.onFooterImageRemove}
                                                                className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                            <table>
                                                <tbody><tr>
                                                    <td>
                                                        Align:
                                                    </td>
                                                    <td>
                                                        <select onChange={(evt)=> this.setState({footerImageAlign: evt.target.value})} 
                                                            value={this.state.footerImageAlign}>
                                                            <option value="center">Center</option>
                                                            <option value="left">Left</option>
                                                            <option value="right">Right</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Width:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number" 
                                                        onChange={(evt)=> this.setState({footerImageWidth: evt.target.value})} 
                                                        value={this.state.footerImageWidth}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                    <tr>
                                                    <td>
                                                        Height:
                                                    </td>
                                                    <td style={{padding:"5px"}}><input type="number"  
                                                        onChange={(evt)=> this.setState({footerImageHeight: evt.target.value})} 
                                                        value={this.state.footerImageHeight}
                                                        style={{width:"50px"}}/> (px)
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>}
                                    </React.Fragment>}
                                    {this.state.footerImageLoading && <div>Uploading image</div>}
                                </div>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group">
                                <label >Background Color</label>
                                <SketchPicker
                                    color={ this.state.footerBackgroundColor }
                                    onChangeComplete={ this.onFooterColorSelection }
                                />
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Thank you message</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div>
                                    <Editor
                                        editorState={this.state.submissionEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onSubmissionEditorStateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}
export default CustomerReferralProgram