import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getMembership(RegistrationID, from, size, option, searchText)
{    
        let searchParameters =   {
        "from": from,
        "size": size, 
        "track_total_hits": true,
        "query": {
          "bool": {
            "must": [
              { "match": { "RegistrationID": RegistrationID}}
            ],
            "must_not": [
            ]
          }
        },
        "sort": [
            {
              "Name.keyword": {
                "order": "asc"
              }
            }
          ]
      }

      if(option === "active")
      {
        searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
      }
      if(option === "inactive")
      {
        searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
      }
      searchParameters.query.bool.must.push({ "regexp": { "Name": "@"+ searchText.trim().toLowerCase() +  ".*" }})
      return (dispatch) => {
          let addMessage =  firebase.functions().httpsCallable('getMemberships');
        return addMessage({text: searchParameters}).then(function(result) {
            // console.log('membership search result',result)
            const memberships = []
            let sanitizedMessage = result.data.text;
                sanitizedMessage.hits.hits.map((data, index)=>{
                    memberships.push({key: data._id, ...data._source})
            })
            return {totalItemscount:  sanitizedMessage.hits.total.value, searchResult:memberships}
            });
    }
}
        
export function saveMembership(key, membership){
    return (dispatch, getState) =>{ 
        membership.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        membership.ActionBy = getState().user.user.Name
        membership.ActionByUID = getState().user.user.UID
        membership.ActionByEmailID = getState().user.user.EmailID
   
        if(key !== "")
        {
            membership.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Memberships").doc(key).update({
                ...membership
              }).then(()=>{                  
                return key
              }).catch(function(error) {
                  console.error("Error updating customer membership: ", error);
              })
        }
        
        else if(key == "")
        {
            membership.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Memberships").add({
                ...membership
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding customer membership: ", error);
              })
            
        }
    }
}

export function deleteMembership(key)
{
    return (dispatch, getState) => {
        return database.collection("Memberships").doc(key).update({
            IsDeleted : true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID :getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE
        }).then(()=> {return true})
    }
}

export function getMembershipDetails(key){
    return dispatch => {
    return database.collection("Memberships").doc(key).get().then((doc) => {
        if (doc.exists) {
            // console.log("Membership found", doc.data());
           return doc.data()
        } else {
            console.log("No such Membership!");
        }
    })
    }
}

export function getActiveMemberships(registrationID)
{
    return (dispatch) => {
        return database.collection("Memberships").where("RegistrationID","==",registrationID).where("IsDeleted","==",false).orderBy("Name","asc").get().then((querySnapshot) => {        
            const activeMemberships = []
            querySnapshot.forEach((doc) => {
                activeMemberships.push(
                  {
                    key:doc.id,...doc.data()
                  }
                )
           })
         dispatch({ type: actionTypes.GET_ACTIVE_MEMBERSHIPS, activeMemberships })

        }).catch((error) => {console.log(error)})
    }
}