import firebase, { database } from '../firebase'

export function updateKOTStatus(key, status) {
    return (dispatch, getState) => {
        return database.collection("KOTs").doc(key).update({
            Status: status,
            KOTStatusUpdatedOn: firebase.firestore.FieldValue.serverTimestamp(),
            BackEndUpdate: true
        })
    }
}


export function getKOTS(status) {
    return (dispatch, getState) => {
        let startDate = new Date().setHours(0, 0, 0, 0);
        let endDate = new Date().setHours(23, 59, 0, 0);
        return database.collection("KOTs").where("StoreID", "==", getState().user.store.key).where("Status", "==", status).where("TransactionDate", ">=", new Date(startDate)).where("TransactionDate", "<=", new Date(endDate)).orderBy("TransactionDate", "asc").get().then((querySnapshot) => {
            const kots = []
            querySnapshot.forEach((doc) => {
                kots.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            return kots
        }).catch((error) => { console.log(error) })
    }
}