import React, { Component } from "react";
import { Link } from "react-router-dom";
import CancelSales from "./CancelSales";
import * as actionTypes from "../../actionTypes";
import AuditTrail from "../AuditTrail";
import * as constants from "../../Constatnts";
import Print from "./Print";
import UniqueNoInput from "../Inventory/UniqueNoInput";
import "../Common/Common.css";
import "./ViewSales.css";
import { mSwipePrinting } from "../../actions/Util";
import Page from "../2/Controls/Page";
import PageSection from "../2/Controls/PageSection";
import Window from "../2/Controls/Window";
import Input from "../2/Controls/Input";
import { getUOMs } from "../../CommonFunctions";
import { isMobileApp } from "../../actions/Util";
import ActionButton from "../2/Controls/ActionButton";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";

class ViewSales extends Component {
  constructor() {
    super();
    this.state = {
      // fabButtons: [],
      sale: null,
      title: "",
      salePrint: null,
      key: "",
      showCancelSales: false,
      salesReturnTransactionType: null,
      print: false,
      selectedPringConfigID: "",
      printData: null,
      selectedLineItem: null,
      selectedLineItemIndex: 0,
      emailID: "",
      emailError: "",
      showEmail: false,
      printChecked: false,
      sendEmailChecked: false,
      editAllowed: false,
      returnAllowed: false,
      cancelAllowed: false,
      customField1Name: "",
      customField2Name: "",
      customField3Name: "",
      customField4Name: "",
      customField5Name: "",
      customField6Name: "",
      customField7Name: "",
      customField8Name: "",
      customField9Name: "",
      customField10Name: "",
      customField11Name: "",
      customField12Name: "",
      customField13Name: "",
      customField14Name: "",
      customField15Name: "",
      customField16Name: "",
      customField17Name: "",
      customField18Name: "",
      customField19Name: "",
      customField20Name: "",
      productCustomField1DisplayName: "",
      productCustomField2DisplayName: "",
      productCustomField3DisplayName: "",
      productCustomField4DisplayName: "",
      productCustomField5DisplayName: "",
      productCustomField6DisplayName: "",
      showPoductConsumption: false,
      showUniqueNoInput: false,
      mobileButtonWidth: 38,
      mobileShowMoreOptions: false,
      productConsumptionID: "",
      productConsumptionLineItems: null,
      uoms: [],
      actionOptions: [],
      eInvocieAllowed: false,
      eInvoicingErrors: [],
      showEInvoicingErrors: false,
    };
    this.load = this.load.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.printFormatting = this.printFormatting.bind(this);
    this.roundUpTo2 = this.roundUpTo2.bind(this);
    this.onPrintClicked = this.onPrintClicked.bind(this);
    this.onEmailClicked = this.onEmailClicked.bind(this);
    this.wiseposPrinting = this.wiseposPrinting.bind(this);
    this.onShowProductConsumption = this.onShowProductConsumption.bind(this);
    this.renderCustomFields = this.renderCustomFields.bind(this);
    this.renderTransactionDetails = this.renderTransactionDetails.bind(this);
    this.renderCustomerDetails = this.renderCustomerDetails.bind(this);
    this.renderNotes = this.renderNotes.bind(this);
    this.renderLineItems = this.renderLineItems.bind(this);
    this.renderPaymentMethods = this.renderPaymentMethods.bind(this);
    this.renderTaxSummaryList = this.renderTaxSummaryList.bind(this);
    this.renderTotals = this.renderTotals.bind(this);
    this.renderError = this.renderError.bind(this);
    // this.onActionButtonClick = this.onActionButtonClick.bind(this);
  }

  componentDidMount() {
    let uoms = getUOMs();
    this.setState({ uoms: uoms });
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Sales") {
        // console.log('customField1',customField.CustomField1Name)
        this.setState({
          customField1Name: customField.CustomField1Name.trim(),
          customField2Name: customField.CustomField2Name.trim(),
          customField3Name: customField.CustomField3Name.trim(),
          customField4Name: customField.CustomField4Name.trim(),
          customField5Name: customField.CustomField5Name.trim(),
          customField6Name: customField.CustomField6Name.trim(),
          customField7Name: customField.CustomField7Name.trim(),
          customField8Name: customField.CustomField8Name.trim(),
          customField9Name: customField.CustomField9Name.trim(),
          customField10Name: customField.CustomField10Name.trim(),
          customField11Name: customField.CustomField11Name.trim(),
          customField12Name: customField.CustomField12Name.trim(),
          customField13Name: customField.CustomField13Name.trim(),
          customField14Name: customField.CustomField14Name.trim(),
          customField15Name: customField.CustomField15Name.trim(),
          customField16Name: customField.CustomField16Name.trim(),
          customField17Name: customField.CustomField17Name.trim(),
          customField18Name: customField.CustomField18Name.trim(),
          customField19Name: customField.CustomField19Name.trim(),
          customField20Name: customField.CustomField20Name.trim(),
        });
      } else if (customField.ModuleName === "Product") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          productCustomField1DisplayName: customField1DisplayName,
          productCustomField2DisplayName: customField2DisplayName,
          productCustomField3DisplayName: customField3DisplayName,
          productCustomField4DisplayName: customField4DisplayName,
          productCustomField5DisplayName: customField5DisplayName,
          productCustomField6DisplayName: customField6DisplayName,
        });
      }
    });
    this.load();
  }

  load() {
    const key = this.props.match.params.key;
    // console.log('key',key);
    if (key) {
      let selectedPringConfigID = "";
      this.props.getSaleDetails(key).then(async (sale) => {
        //check if wallte recharge
        // console.log('sale',sale)
        let returnAllowed = true;
        let editAllowed = true;
        let cancelAllowed = true;
        let eInvocieAllowed = false;
        this.setState({
          title:
            sale.TransactionType.TransactionType + " - #" + sale.TransactionNo,
        });
        let printConfig = this.props.config.PrintConfigs.filter(
          (e) =>
            !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
        );
        if (printConfig.length > 0) {
          selectedPringConfigID = printConfig[0].key;
        }
        // console.log("printConfig", JSON.stringify(printConfig));
        let walletRechargeItem = sale.LineItems.filter(
          (e) => e.Product.ProductType === "Wallet Recharge"
        );
        if (walletRechargeItem.length > 0) {
          editAllowed = false;
          returnAllowed = false;
        }
        // if(sale.LineItems.filter((e)=> e.Product.ProductTypeID === 5).length> 0 )
        // {
        //     editAllowed= false
        //     returnAllowed= false
        // }
        let packageItem = sale.LineItems.filter(
          (e) => e.Product.ProductTypeID === 5
        );
        if (packageItem.length > 0) {
          //check if package is status pending
          let result = await this.props.getPackages(
            this.props.user.user.RegistrationID,
            sale.Store.key,
            0,
            100,
            "",
            "",
            sale.TransactionNo,
            ""
          );
          // console.log("result", result);
          if (result) {
            if (
              result.searchResult.filter(
                (f) => f.PackageStatus.toLowerCase() !== "pending"
              ).length > 0
            ) {
              cancelAllowed = false;
            }
          }
          editAllowed = false;
          returnAllowed = false;
        }
        let walletRedeem = sale.Payments.filter(
          (e) => e.PaymentMode === "Wallet"
        );
        if (walletRedeem.length > 0) {
          editAllowed = false;
          returnAllowed = false;
        }
        if (sale.CancellationReason) {
          editAllowed = false;
          returnAllowed = false;
          cancelAllowed = false;
          // console.log('CancellationReason',cancelAllowed)
        }
        if (
          sale.TransactionType.TransactionType.toLowerCase() === "sales return"
        ) {
          returnAllowed = false;
        }
        if (!sale.TransactionType.SalesImpactChecked) {
          returnAllowed = false;
        }
        if (sale.hasOwnProperty("SalesReturnTransactionNo")) {
          if (sale.SalesReturnTransactionNo.trim() !== "") {
            returnAllowed = false;
            cancelAllowed = false;
            editAllowed = false;
            // console.log('SalesReturnTransactionNo',cancelAllowed)
          }
        }
        if (sale.hasOwnProperty("ChildTransactionNo")) {
          if (sale.ChildTransactionNo.trim() !== "") {
            returnAllowed = false;
            editAllowed = false;
            cancelAllowed = false;
            // console.log('ChildTransactionNo',cancelAllowed)
          }
        }
        if (
          sale.TransactionType.TransactionType.toLowerCase() ===
          "receive payment"
        ) {
          returnAllowed = false;
          editAllowed = false;
        }
        if (
          sale.hasOwnProperty("ReceivedPaymentAmount") &&
          sale.ReceivedPaymentAmount > 0
        ) {
          editAllowed = false;
          cancelAllowed = false;
        }
        // console.log('this.props.getVisibility("Sales","update")',this.props.getVisibility("Sales","update"))
        if (this.props.getVisibility("Sales", "update") === "none") {
          editAllowed = false;
        }
        let salesReturnTransactionType = null;
        this.props.config.TransactionTypes.filter(
          (e) =>
            !e.IsDeleted && e.TransactionType.toLowerCase() === "sales return"
        ).map((t) => {
          salesReturnTransactionType = t;
        });

        //22-May-2019
        if (sale.hasOwnProperty("ReceivedPaymentTransactions")) {
          let receivedPayment = 0;
          for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
            let r = sale.ReceivedPaymentTransactions[i];
            if (r.hasOwnProperty("IsConvert") && r.IsConvert) {
              //ignore
            } else {
              receivedPayment = receivedPayment + Number(r.ReceiveAmount);
            }
          }
          if (receivedPayment > 0) {
            sale.BalanceAmount = sale.BalanceAmount - receivedPayment;
          }
        }

        let buttonCtr = 0;
        let buttonWidth = 70;
        if (editAllowed) {
          buttonCtr = buttonCtr + 1;
        }
        if (returnAllowed) {
          buttonCtr = buttonCtr + 1;
        }
        if (cancelAllowed) {
          buttonCtr = buttonCtr + 1;
        }
        buttonWidth = buttonWidth / buttonCtr;
        let salePrint = { ...sale };
        this.printFormatting(salePrint);

        if (
          this.props.user.user.hasOwnProperty("RoleKey") &&
          this.props.user.user.RoleKey !== "" &&
          this.props.getVisibility(
            sale.TransactionType.TransactionType,
            "delete"
          ) === "none"
        ) {
          cancelAllowed = false;
        }
        // console.log('this.props.getVisibility(sale.TransactionType.TransactionType,"update")',this.props.getVisibility(sale.TransactionType.TransactionType,"update"))

        if (
          this.props.user.user.hasOwnProperty("RoleKey") &&
          this.props.user.user.RoleKey !== "" &&
          this.props.getVisibility(
            sale.TransactionType.TransactionType,
            "update"
          ) === "none"
        ) {
          editAllowed = false;
        }

        //if logged in store is dirrent from the invoice store then dont allow edit
        // if()
        // editAllowed
        if (this.props.user.store.key !== sale.Store.key) {
          editAllowed = false;
        }

        if (
          sale.hasOwnProperty("ExternalTransactionNo") &&
          sale.ExternalTransactionNo !== ""
        ) {
          editAllowed = false;
          cancelAllowed = false;
          returnAllowed = false;
        }
        let actionOptions = [];
        actionOptions.push({ value: "print", text: "Print", icon: "print" });
        actionOptions.push({
          value: "sales_email",
          text: "Email",
          icon: "email",
        });
        if (cancelAllowed) {
          actionOptions.push({
            value: "cancel",
            text: "Cancel",
            icon: "cancel",
          });
        }
        if (returnAllowed) {
          actionOptions.push({
            value: "return",
            text: "Return",
            icon: "return",
          });
        }
        if (editAllowed) {
          actionOptions.push({ value: "edit", text: "Edit", icon: "edit" });
        }
        // cancelAllowed=true;
        // console.log('sale',sale)

        let store = await this.props.getStoreDetails(sale.Store.key);
        let sellerGSTIN = "";
        let buyerGSTIN = "";

        if (
          this.props.registration.registration.EInvoicing &&
          sale.Customer &&
          !sale.hasOwnProperty("EInvocieIrn")
        ) {
          //check if store has GSTIN and customer has GSTIN
          console.log("EInvoicing");
          this.props.config.CustomFields.map((m) => {
            if (m.ModuleName === "Store") {
              if (m.CustomField1Name.trim().toLowerCase() === "gstin") {
                sellerGSTIN = store.hasOwnProperty("CustomField1")
                  ? store.CustomField1
                  : "";
              }
              if (m.CustomField2Name.trim().toLowerCase() === "gstin") {
                sellerGSTIN = store.hasOwnProperty("CustomField2")
                  ? store.CustomField2
                  : "";
              }
              if (m.CustomField3Name.trim().toLowerCase() === "gstin") {
                sellerGSTIN = store.hasOwnProperty("CustomField3")
                  ? store.CustomField3
                  : "";
              }
              if (m.CustomField4Name.trim().toLowerCase() === "gstin") {
                sellerGSTIN = store.hasOwnProperty("CustomField4")
                  ? store.CustomField4
                  : "";
              }
              if (m.CustomField5Name.trim().toLowerCase() === "gstin") {
                sellerGSTIN = store.hasOwnProperty("CustomField5")
                  ? store.CustomField5
                  : "";
              }
              if (m.CustomField6Name.trim().toLowerCase() === "gstin") {
                sellerGSTIN = store.hasOwnProperty("CustomField6")
                  ? store.CustomField6
                  : "";
              }
            }
            if (m.ModuleName === "Customer" && sale.Customer) {
              if (m.CustomField1Name.trim().toLowerCase() === "gstin") {
                buyerGSTIN = sale.Customer.hasOwnProperty("CustomField1")
                  ? sale.Customer.CustomField1
                  : "";
              }
              if (m.CustomField2Name.trim().toLowerCase() === "gstin") {
                buyerGSTIN = sale.Customer.hasOwnProperty("CustomField2")
                  ? sale.Customer.CustomField2
                  : "";
              }
              if (m.CustomField3Name.trim().toLowerCase() === "gstin") {
                buyerGSTIN = sale.Customer.hasOwnProperty("CustomField3")
                  ? sale.Customer.CustomField3
                  : "";
              }
              if (m.CustomField4Name.trim().toLowerCase() === "gstin") {
                buyerGSTIN = sale.Customer.hasOwnProperty("CustomField4")
                  ? sale.Customer.CustomField4
                  : "";
              }
              if (m.CustomField5Name.trim().toLowerCase() === "gstin") {
                buyerGSTIN = sale.Customer.hasOwnProperty("CustomField5")
                  ? sale.Customer.CustomField5
                  : "";
              }
              if (m.CustomField6Name.trim().toLowerCase() === "gstin") {
                buyerGSTIN = sale.Customer.hasOwnProperty("CustomField6")
                  ? sale.Customer.CustomField6
                  : "";
              }
            }
          });
          console.log("sellerGSTIN", sellerGSTIN);
          console.log("buyerGSTIN", buyerGSTIN);
          if (sellerGSTIN !== "" && buyerGSTIN !== "") {
            eInvocieAllowed = true;
          }
        }
        this.setState({
          sale: sale,
          key: key,
          salesReturnTransactionType: salesReturnTransactionType,
          selectedPringConfigID: selectedPringConfigID,
          salePrint: salePrint,
          editAllowed: editAllowed,
          returnAllowed: returnAllowed,
          cancelAllowed: cancelAllowed,
          mobileButtonWidth: buttonWidth,
          actionOptions: actionOptions,
          eInvocieAllowed: eInvocieAllowed,
          sellerGSTIN: sellerGSTIN,
          buyerGSTIN: buyerGSTIN,
        });
      });
    }
  }

  async onShowProductConsumption(lineItem, index) {
    this.props.history.push({
      pathname: "/home/consumption/",
      state: {
        productConsumptionID: lineItem.hasOwnProperty(
          "ManualProductConsumptionID"
        )
          ? lineItem.ManualProductConsumptionID
          : "",
        sale: this.state.sale,
        saleID: this.state.key,
        selectedLineItemIndex: index,
      },
    });
  }

  sendEmail() {
    if (this.state.emailID.trim() === "") {
      this.setState({ emailError: "Please specify email id" });
      return;
    }
    let sale = this.state.salePrint;
    sale.EmailID = this.state.emailID;
    let printData = {
      Sale: sale,
      PrintConfig: this.props.config.PrintConfigs.filter(
        (e) => e.key === this.state.selectedPringConfigID
      )[0],
    };
    this.setState({
      print: true,
      printChecked: false,
      sendEmailChecked: true,
      printData: printData,
      showEmail: false,
    }); //TBDpawan
  }

  async generateEInvoice() {
    let sellerGSTIN = this.state.sellerGSTIN;
    let buyerGSTIN = this.state.buyerGSTIN;
    let sale = this.state.sale;
    let errors = [];
    if (sellerGSTIN === "") {
      errors.push({ Error: "Store GSTIN missing" });
      // alert();
      // return;
    }
    if (buyerGSTIN === "") {
      errors.push({ Error: "Customer GSTIN missing" });
      // return;
    }
    if (sellerGSTIN !== "" && sellerGSTIN.length !== 15) {
      errors.push({
        Error:
          "Store GSTIN is invalid. GSTIN should be 15 characters in length.",
      });
    }
    if (buyerGSTIN !== "" && buyerGSTIN.length !== 15) {
      errors.push({
        Error:
          "Customer GSTIN is invalid. GSTIN should be 15 characters in length.",
      });
    }
    if (sale.Store.PostalCode === "") {
      errors.push({ Error: "Store Postal Code not defined" });
    }
    if (!sale.BillingAddress) {
      errors.push({ Error: "Customer billing address not selected" });
    }
    if (sale.BillingAddress && sale.BillingAddress.PostalCode === "") {
      errors.push({ Error: "Customer postal code is missing" });
    }
    if (sale.BillingAddress && sale.BillingAddress.StateCode === "") {
      errors.push({ Error: "Customer state code is missing" });
    }
    if (
      sale.BillingAddress &&
      sale.BillingAddress.StateCode !== "" &&
      buyerGSTIN !== ""
    ) {
      //now check if GSTIN and state code are matching
      if (
        Number(buyerGSTIN.substring(0, 2)) !==
        Number(sale.BillingAddress.StateCode)
      ) {
        errors.push({
          Error:
            "Customer GSTIN adoes not match selected billing address state code",
        });
      }
    }
    //check if HSN code is provided
    sale.LineItems.map((l, index) => {
      if (l.TaxGroup.trim() === "") {
        errors.push({
          Error:
            "Tax not configured for line item Sr. No." +
            (index + 1) +
            " , product name " +
            l.Product.Name,
        });
      }
      if (l.HSNSACCode.trim() === "") {
        errors.push({
          Error:
            "HSNSACCode missing for line item Sr. No." +
            (index + 1) +
            " , product name " +
            l.Product.Name,
        });
      }
    });
    //all line items
    if (errors.length > 0) {
      console.log("eInvoicingErrors", errors);
      this.setState({ eInvoicingErrors: errors, showEInvoicingErrors: true });
      return;
    }
    //
    // 27AAOFT3600K1ZY
    //check if state code is present in store and customer
    sale.SellerGSTIN = sellerGSTIN;
    sale.BuyerGSTIN = buyerGSTIN;
    sale.key = this.state.key;
    this.setState({ generatingEInvoice: true });
    let result = await this.props.generateEInvoice(sale);
    if (result.success) {
      console.log("generateEInvoice - success");
      alert("EInvoice generated successfully");
      this.setState({ generatingEInvoice: false });
      this.load();
    } else {
      console.log("generateEInvoice - error");
      alert(result.message);
      this.setState({ generatingEInvoice: false });
    }
  }

  async printFormatting(sale) {
    // sale1.TransactionDate =  (new Date(sale.TransactionDate.seconds*1000)).toString().substring(0,24)
    //in case of sales order to invoice
    if (
      sale.hasOwnProperty("ReceivedPaymentTransactions") &&
      sale.hasOwnProperty("IsConvert") &&
      sale.IsConvert
    ) {
      for (let i = 0; i < sale.ReceivedPaymentTransactions.length; i++) {
        sale.BillAmount =
          Number(sale.BillAmount) +
          Number(sale.ReceivedPaymentTransactions[i].ReceiveAmount);
      }
    }

    sale.TransactionDateShort = new Date(sale.TransactionDate.seconds * 1000)
      .toISOString()
      .split("T")[0];
    sale.TransactionDate = new Date(sale.TransactionDate.seconds * 1000)
      .toString()
      .substring(0, 24);
    sale.AmountInWords = this.props.convertNumberToWords(sale.BillAmount);
    // console.log('sale1.AmountInWords', sale1.AmountInWords)
    let billTaxableAmount = 0;
    let lineItemsTmp = [];
    for (let i = 0; i < sale.LineItems.length; i++) {
      lineItemsTmp.push(sale.LineItems[sale.LineItems.length - (i + 1)]);
    }
    sale.LineItems = lineItemsTmp;
    let costPriceTotal = 0;
    let mrpTotal = 0;

    let lineItemsWithOutAdditionalCharges = [];
    let lineItemsWithAdditonalCharges = [];
    let subTotalWithoutAdditionalCharges = 0;

    sale.LineItems.map((lineItem, index) => {
      sale.LineItems[index].SrNo = index + 1;
      sale.LineItems[index].BasePrice = this.roundUpTo2(
        Number(sale.LineItems[index].TaxableAmount) /
          Number(sale.LineItems[index].Qty)
      );
      if (
        lineItem.TaxComponentAmount &&
        lineItem.TaxComponentAmount.length > 0 &&
        lineItem.TaxInclusive
      ) {
        let taxPercentage = 0;
        let taxAmount = 0;
        lineItem.TaxComponentAmount.map((m) => {
          taxPercentage = taxPercentage + Number(m.TaxPercentage);
        });
        taxAmount = this.roundUpTo2(
          (Number(lineItem.RetailPrice) * Number(taxPercentage)) /
            (100 + Number(taxPercentage))
        );
        sale.LineItems[index].CostPrice =
          Number(lineItem.RetailPrice) - Number(taxAmount);
        //sale.LineItems[index].MRP = Number(lineItem.Product.MRP === 0 ? lineItem.RetailPrice :  lineItem.Product.MRP) - Number(taxAmount);
      } else {
        sale.LineItems[index].CostPrice = lineItem.RetailPrice;
      }
      sale.LineItems[index].MRP = Number(
        lineItem.Product.MRP === 0 ? lineItem.RetailPrice : lineItem.Product.MRP
      );

      sale.LineItems[index].CostPriceTotal = this.roundUpTo2(
        Number(sale.LineItems[index].CostPrice) *
          Number(sale.LineItems[index].Qty)
      );
      costPriceTotal =
        costPriceTotal + Number(sale.LineItems[index].CostPriceTotal);

      sale.LineItems[index].MRPTotal = this.roundUpTo2(
        Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty)
      );
      mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal);

      billTaxableAmount = this.roundUpTo2(
        Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount)
      );

      if (
        lineItem.Product.hasOwnProperty("ShowAsAdditionalCharges") &&
        lineItem.Product.ShowAsAdditionalCharges
      ) {
        lineItemsWithAdditonalCharges.push(sale.LineItems[index]);
      } else {
        lineItemsWithOutAdditionalCharges.push(sale.LineItems[index]);
        subTotalWithoutAdditionalCharges =
          Number(subTotalWithoutAdditionalCharges) +
          Number(sale.LineItems[index].Amount);
      }
    });
    sale.BillCostPriceTotal = costPriceTotal;
    // console.log("mrpTotal", mrpTotal);
    sale.BillMRPTotal = mrpTotal;
    sale.MRPSaving = this.roundUpTo2(
      this.roundUpTo2(
        Number(sale.BillMRPTotal) - this.roundUpTo2(Number(sale.BillAmount))
      )
    );

    sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount));
    let printTaxComponents = [];
    let printTaxComponentsWithRate = [];
    sale.LineItems.map((lineItem, index) => {
      //check modifers
      if (lineItem.Modifiers && lineItem.Modifiers.length > 0) {
        let modifiersPrint = [];
        for (let k = 0; k < lineItem.Modifiers.length; k++) {
          let index = -1;
          for (let l = 0; l < modifiersPrint.length; l++) {
            if (
              modifiersPrint[l].ModifierName ===
              lineItem.Modifiers[k].ModifierName
            ) {
              index = l;
              break;
            }
          }
          if (index === -1) {
            modifiersPrint.push({
              key: lineItem.Modifiers[k].key,
              ModifierName: lineItem.Modifiers[k].ModifierName,
              ModifierPrice: lineItem.Modifiers[k].ModifierPrice,
              ModifierGroup: lineItem.Modifiers[k].ModifierGroup,
              ModifierGroupID: lineItem.Modifiers[k].ModifierGroupID,
              ModifierQty: 1,
            });
          } else {
            modifiersPrint[index].ModifierQty =
              modifiersPrint[index].ModifierQty + 1;
            modifiersPrint[index].ModifierPrice =
              modifiersPrint[index].ModifierPrice +
              lineItem.Modifiers[k].ModifierPrice;
          }
        }
        // console.log('modifiersPrint', modifiersPrint)
        lineItem.ModifiersPrint = modifiersPrint;
      }
      if (lineItem.TaxID !== "") {
        lineItem.TaxComponentAmount.map((t) => {
          let indexFound = -1;
          printTaxComponents.map((pt, ptIndex) => {
            if (t.TaxType === pt.TaxType) {
              indexFound = ptIndex;
              return;
            }
          });
          if (indexFound < 0) {
            printTaxComponents.push({
              TaxType: t.TaxType,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
            });
          } else {
            printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(
              Number(printTaxComponents[indexFound].TaxAmount) +
                Number(t.TaxAmount)
            );
          }

          let indexFoundTaxRate = -1;
          printTaxComponentsWithRate.map((pt, ptIndex) => {
            if (
              t.TaxType === pt.TaxType &&
              t.TaxPercentage === pt.TaxPercentage
            ) {
              indexFoundTaxRate = ptIndex;
              return;
            }
          });
          if (indexFoundTaxRate < 0) {
            printTaxComponentsWithRate.push({
              TaxType: t.TaxType,
              TaxPercentage: t.TaxPercentage,
              TaxAmount: this.roundUpTo2(Number(t.TaxAmount)),
              TaxableAmount: Number(lineItem.TaxableAmount),
            });
          } else {
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxAmount = this.roundUpTo2(
              Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount) +
                Number(t.TaxAmount)
            );
            printTaxComponentsWithRate[
              indexFoundTaxRate
            ].TaxableAmount = this.roundUpTo2(
              Number(
                printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount
              ) + Number(lineItem.TaxableAmount)
            );
          }
        });
      }
    });
    sale.PrintTaxComponents = printTaxComponents;
    sale.PrintTaxComponentsWithRate = printTaxComponentsWithRate;
    sale.LineItems.map((lineItem, index) => {
      for (var i = 0; i < printTaxComponents.length; i++) {
        if (lineItem.TaxComponentAmount.length < i + 1) {
          lineItem.TaxComponentAmount.push({
            TaxName: "",
            TaxAmount: 0,
            TaxPercentage: "",
            TaxType: "",
          });
        }
      }
    });
    let previousBalance = 0;
    if (sale.Customer && sale.Customer.hasOwnProperty("OutStandingAmount")) {
      previousBalance = sale.Customer.OutStandingAmount;
    }
    sale.PreviousBalance = previousBalance;
    sale.TotalBalanceAmount = sale.BillAmount + previousBalance;
    sale.TotalBalanceAfterPaid = sale.TotalBalanceAmount - sale.BillPaidAmount;

    sale.LineItemsWithOutAdditionalCharges = lineItemsWithOutAdditionalCharges;
    sale.LineItemsWithAdditonalCharges = lineItemsWithAdditonalCharges;
    sale.SubTotalWithoutAdditionalCharges = subTotalWithoutAdditionalCharges;

    // console.log('sale print', sale);
    //wallet balance
    // sale.Payments.filter(f=> f.PaymentMode.toLowerCase()==="wallet").map(m=> sale.WalletBalance = Number(sale.Customer.WalletBalance )- Number(m.Amount))
    // console.log('print sale', sale)
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  async onPrintClicked() {
    // alert('onPrintClicked')
    // alert(this.state.selectedPringConfigID)
    if (this.state.selectedPringConfigID === "") {
      alert("Print setup does not exists");
      return;
    }
    if (isMobileApp()) {
      let printer = localStorage.getItem("printer");
      if (printer.toLowerCase() === "wisepos") {
        //print
        mSwipePrinting(
          this.state.salePrint,
          this.props.config.PrintConfigs.filter(
            (e) => e.key === this.state.salePrint.PrintConfigID
          )[0]
        );
      } else {
        //andorid printing
      }
      //get cash regiter list
      // let crs = await this.props.getCashRegisters(this.state.salePrint.Store.key)
      // if(crs.filter(f=> f.key === this.state.salePrint.CashRegister.key).length > 0)
      // {
      //     let cr = crs.filter(f=> f.key === this.state.salePrint.CashRegister.key)[0]
      //     if(cr.Printer.toLowerCase()==="wisepos")
      //     {//print
      //         // this.wiseposPrinting(this.state.salePrint)
      //         // alert('mswipe printing')
      //         mSwipePrinting(this.state.salePrint,this.props.config.PrintConfigs.filter((e)=> e.key === this.state.salePrint.PrintConfigID)[0])
      //     }
      // }
      // else
      // {
      //     alert('Cashregister not found')
      // }
    } else {
      let printData = {
        Sale: this.state.salePrint,
        PrintConfig: this.props.config.PrintConfigs.filter(
          (e) => e.key === this.state.selectedPringConfigID
        )[0],
      };
      this.setState({
        print: true,
        printChecked: true,
        sendEmailChecked: false,
        printData: printData,
      }); //TBDpawan
    }
    this.setState({ mobileShowMoreOptions: false });
    // alert('2')
  }

  onEmailClicked() {
    let emailID = "";
    if (this.state.sale.hasOwnProperty("EmailID")) {
      if (this.state.sale.EmailID.trim() !== "") {
        emailID = this.state.sale.EmailID.trim();
      } else if (this.state.sale.Customer) {
        emailID = this.state.sale.Customer.EmailID.trim();
      }
    }
    this.setState({
      emailID: emailID,
      mobileShowMoreOptions: false,
      showEmail: true,
      emailError: "",
    });
  }

  async wiseposPrinting(sale) {
    let printConfig = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key
    )[0];
    if (printConfig.StoreCodeDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.StoreCode,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreNameDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.StoreName,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StorePhoneNoDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.PhoneNo,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.EmailID) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.PhoneNo,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressLine1Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.Address1,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressLine2Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.Address2,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressCityDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.City,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressStateDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.State,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressStateDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.State,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressPostalCodeDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.PostalCode,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreAddressCountryDisplay) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.Country,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreCustomField1Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.CustomField1,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreCustomField2Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.CustomField2,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreCustomField3Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.CustomField3,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreCustomField4Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.CustomField4,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreCustomField5Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.CustomField5,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    if (printConfig.StoreCustomField6Display) {
      window.cordova.plugins.mswipe.print({
        text: sale.Store.CustomField6,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "CENTER",
        isLineBreak: true,
      });
    }
    window.cordova.plugins.mswipe.print({
      text: "",
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });
    //transaction no and date
    if (printConfig.TransactionNoDisplay) {
      window.cordova.plugins.mswipe.print({
        text: printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (printConfig.TransactionDateDisplay) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }

    window.cordova.plugins.mswipe.print({
      text: "",
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });

    //customer details
    if (sale.Customer && printConfig.CustomerCodeDisplay) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCodeDisplayName +
          ": " +
          sale.Customer.CustomerCode,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerNameDisplay) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerNameDisplayName +
          ": " +
          sale.Customer.FirstName +
          " " +
          sale.Customer.LastName,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField1Display) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField1DisplayName +
          ": " +
          sale.Customer.CustomField1,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField2Display) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField2DisplayName +
          ": " +
          sale.Customer.CustomField2,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField3Display) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField3DisplayName +
          ": " +
          sale.Customer.CustomField3,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField4Display) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField4DisplayName +
          ": " +
          sale.Customer.CustomField4,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField5Display) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField5DisplayName +
          ": " +
          sale.Customer.CustomField5,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }
    if (sale.Customer && printConfig.CustomerCustomField6Display) {
      window.cordova.plugins.mswipe.print({
        text:
          printConfig.CustomerCustomField6DisplayName +
          ": " +
          sale.Customer.CustomField6,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }

    if (sale.Customer) {
      window.cordova.plugins.mswipe.print({
        text: "",
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
    }

    //sale line items
    // window.cordova.plugins.mswipe.print({text:"--------------------------------",
    // fontSize:"TWENTY_FOUR",
    // isBold:false,
    // Align:"LEFT",
    // isLineBreak:false})

    window.cordova.plugins.mswipe.print({
      text:
        "--------------------------------" +
        "Item           Qty  Rate  Amount" +
        "-------------------------------",
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });

    for (let i = 0; i < sale.LineItems.length; i++) {
      let l = sale.LineItems[i];
      let printText =
        l.Product.Name.substring(0, 14) +
        " ".repeat(
          14 - l.Product.Name.toString().length > 0
            ? 14 - l.Product.Name.toString().length
            : 0
        );
      printText =
        printText +
        " " +
        l.Qty.toString() +
        " ".repeat(
          4 - l.Qty.toString().length > 0 ? 4 - l.Qty.toString().length : 0
        );
      printText =
        printText +
        " " +
        " ".repeat(
          4 - l.RetailPrice.toString().length > 0
            ? 4 - l.RetailPrice.toString().length
            : 0
        ) +
        l.RetailPrice.toString();
      printText =
        printText +
        " " +
        " ".repeat(
          6 - l.Amount.toString().length > 0
            ? 6 - l.Amount.toString().length
            : 0
        ) +
        l.Amount.toString();
      window.cordova.plugins.mswipe.print({
        text: printText,
        fontSize: "TWENTY_FOUR",
        isBold: false,
        Align: "LEFT",
        isLineBreak: true,
      });
      //check for modifiers
      if (l.hasOwnProperty("Modifiers")) {
        let modifierText = "";
        for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
          modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName;
        }
        window.cordova.plugins.mswipe.print({
          text: modifierText,
          fontSize: "TWENTY_FOUR",
          isBold: false,
          Align: "LEFT",
          isLineBreak: true,
        });
      }
      //check for uniqueno
      if (l.hasOwnProperty("UniqueNoList")) {
        for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
          // let printText = l.Product.UniqueLabel + ": "
          // if(l.UniqueNoList[ctri].UniqueNo1.trim()!=="")
          // {
          //     printText=printText+    l.UniqueNoList[ctri].UniqueNo1
          // }
          // + l.UniqueNoList[ctri].UniqueNo1 +  l.UniqueNoList[ctri].UniqueNo2.trim()!=="" ? ", ": " " + l.UniqueNoList[ctri].UniqueNo2.trim() +  l.UniqueNoList[ctri].UniqueNo3.trim()!=="" ? ", ": " " + l.UniqueNoList[ctri].UniqueNo3.trim() +  l.UniqueNoList[ctri].UniqueNo4.trim()!=="" ? ", ": " " + l.UniqueNoList[ctri].UniqueNo4.trim()
          if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
            window.cordova.plugins.mswipe.print({
              text:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1,
              fontSize: "TWENTY_FOUR",
              isBold: false,
              Align: "LEFT",
              isLineBreak: true,
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
            window.cordova.plugins.mswipe.print({
              text:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2,
              fontSize: "TWENTY_FOUR",
              isBold: false,
              Align: "LEFT",
              isLineBreak: true,
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
            window.cordova.plugins.mswipe.print({
              text:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3,
              fontSize: "TWENTY_FOUR",
              isBold: false,
              Align: "LEFT",
              isLineBreak: true,
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
            window.cordova.plugins.mswipe.print({
              text:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4,
              fontSize: "TWENTY_FOUR",
              isBold: false,
              Align: "LEFT",
              isLineBreak: true,
            });
          }
        }
      }
    }

    window.cordova.plugins.mswipe.print({
      text:
        "-".repeat(32) +
        " ".repeat(15) +
        " ".repeat(
          4 - sale.BillQty.toString().length > 0
            ? 4 - sale.BillQty.toString().length
            : 0
        ) +
        sale.BillQty +
        "       " +
        " ".repeat(
          6 - sale.BillAmount.toString().length > 0
            ? 6 - sale.BillAmount.toString().length
            : 0
        ) +
        sale.BillAmount.toString() +
        "-".repeat(32),
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });

    window.cordova.plugins.mswipe.print({
      text:
        "Discount:" +
        " ".repeat(
          23 - sale.BillDiscount.toString().length > 0
            ? 23 - sale.BillDiscount.toString().length
            : 0
        ),
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });

    if (sale.PrintTaxComponents.length > 0) {
      for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
        let p = sale.PrintTaxComponents[l];
        window.cordova.plugins.mswipe.print({
          text:
            p.TaxType +
            ":" +
            " ".repeat(
              30 - p.TaxType.toString().length - p.TaxAmount.toString().length >
                0
                ? 30 -
                    p.TaxType.toString().length -
                    p.TaxAmount.toString().length
                : 0
            ) +
            p.TaxAmount.toString(),
          fontSize: "TWENTY_FOUR",
          isBold: false,
          Align: "LEFT",
          isLineBreak: true,
        });
      }
    }

    window.cordova.plugins.mswipe.print({
      text:
        "-".repeat(32) +
        "Total:" +
        " ".repeat(
          26 - sale.BillAmount.toString().length > 0
            ? 26 - sale.BillAmount.toString().length
            : 0
        ) +
        sale.BillAmount.toString() +
        "-".repeat(31),
      fontSize: "TWENTY_FOUR",
      isBold: true,
      Align: "LEFT",
      isLineBreak: true,
    });

    if (sale.Payments.length > 0) {
      for (let l = 0; l < sale.Payments.length; l++) {
        let p = sale.Payments[l];
        window.cordova.plugins.mswipe.print({
          text:
            p.PaymentMode +
            ":" +
            " ".repeat(
              31 -
                p.PaymentMode.toString().length -
                p.Amount.toString().length >
                0
                ? 31 -
                    p.PaymentMode.toString().length -
                    p.Amount.toString().length
                : 0
            ) +
            p.Amount.toString(),
          fontSize: "TWENTY_FOUR",
          isBold: false,
          Align: "LEFT",
          isLineBreak: true,
        });
      }
    }

    window.cordova.plugins.mswipe.print({
      text:
        "Balance:" +
        " ".repeat(
          24 - sale.BalanceAmount.toString().length > 0
            ? 24 - sale.BalanceAmount.toString().length
            : 0
        ) +
        sale.BalanceAmount.toString(),
      fontSize: "TWENTY_FOUR",
      isBold: true,
      Align: "LEFT",
      isLineBreak: true,
    });

    window.cordova.plugins.mswipe.print({
      text: "",
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });

    window.cordova.plugins.mswipe.print({
      text: "",
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });

    window.cordova.plugins.mswipe.print({
      text: "",
      fontSize: "TWENTY_FOUR",
      isBold: false,
      Align: "LEFT",
      isLineBreak: true,
    });
  }

  renderCustomFields() {
    return (
      <React.Fragment>
        {this.props.config.CustomFields.filter(
          (f) => f.ModuleName.toLowerCase() === "sales"
        ).length > 0 && (
          <div className="row">
            <div className="col-xs-12">
              <div className="viewsales-section-header">Additional Info:</div>
              {this.state.customField1Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField1Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField1")
                          ? this.state.sale.CustomField1
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField2Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField2Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField2")
                          ? this.state.sale.CustomField2
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField3Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField3Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField3")
                          ? this.state.sale.CustomField3
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField4Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField4Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField4")
                          ? this.state.sale.CustomField4
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField5Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField5Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField5")
                          ? this.state.sale.CustomField5
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField6Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField6Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField6")
                          ? this.state.sale.CustomField6
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField7Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField7Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField7")
                          ? this.state.sale.CustomField7
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField8Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField8Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField8")
                          ? this.state.sale.CustomField8
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField9Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField9Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField9")
                          ? this.state.sale.CustomField9
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField10Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField10Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField10")
                          ? this.state.sale.CustomField10
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField11Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField11Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField11")
                          ? this.state.sale.CustomField11
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField12Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField12Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField12")
                          ? this.state.sale.CustomField12
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField13Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField12Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField12")
                          ? this.state.sale.CustomField12
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField14Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField14Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField14")
                          ? this.state.sale.CustomField14
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField15Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField15Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField15")
                          ? this.state.sale.CustomField15
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField16Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField16Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField16")
                          ? this.state.sale.CustomField16
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField17Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField17Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField17")
                          ? this.state.sale.CustomField17
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField18Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField18Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField18")
                          ? this.state.sale.CustomField18
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField19Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField19Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField19")
                          ? this.state.sale.CustomField19
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
              {this.state.customField20Name.trim() !== "" && (
                <div className="col-md-2">
                  <div className="form-group">
                    <label>{this.state.customField20Name.trim()}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.state.sale.hasOwnProperty("CustomField20")
                          ? this.state.sale.CustomField20
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderTransactionDetails() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Transaction Details:</div>
        <div>No: {this.state.sale.TransactionNo}</div>
        <div>
          Date:{" "}
          {new Date(this.state.sale.TransactionDate.seconds * 1000)
            .toString()
            .substring(0, 24)}
        </div>
        <div>Store Code: {this.state.sale.Store.StoreCode}</div>
        <div>Store Name: {this.state.sale.Store.StoreName}</div>
        <div>CR Code: {this.state.sale.CashRegister.CashRegisterCode}</div>
        <div>CR Name: {this.state.sale.CashRegister.CashRegisterName}</div>
        {this.state.sale.ParentTransactionKey && (
          <span className="badge bg-lg bg-green">
            Sales return for {this.state.sale.ParentTransactionNo}{" "}
          </span>
        )}
        {this.state.sale.CancellationReason && (
          <span className="badge bg-lg bg-red">
            Cancellation Reason:{this.state.sale.CancellationReason}
          </span>
        )}
        {this.state.sale.SalesReturnTransactionNo && (
          <span className="badge bg-lg bg-orange">
            Sales return done via {this.state.sale.SalesReturnTransactionNo}{" "}
          </span>
        )}
        {this.state.sale.EInvocieIrn && (
          <div className="viewsales-section-header">
            EInvoice IRN: {this.state.sale.EInvocieIrn}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderCustomerDetails() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Customer:</div>
        {this.state.sale.Customer && (
          <div>
            {this.state.sale.Customer.CustomerCode !== "" && (
              <div>Code: {this.state.sale.Customer.CustomerCode}</div>
            )}
            <div>
              Name: {this.state.sale.Customer.FirstName}{" "}
              {this.state.sale.Customer.LastName}{" "}
            </div>
            {this.state.sale.Customer.PhoneNo !== "" && (
              <div>Phone No: {this.state.sale.Customer.PhoneNo}</div>
            )}
            {this.state.sale.Customer.EmailID !== "" && (
              <div>Email ID: {this.state.sale.Customer.EmailID}</div>
            )}

            {this.state.sale.BillingAddress && (
              <div>
                <div>
                  <b>{this.state.sale.BillingAddress.AddressType}</b>
                </div>
                <div>{this.state.sale.BillingAddress.Address1}</div>
                <div>{this.state.sale.BillingAddress.Address2}</div>
                <div>
                  {this.state.sale.BillingAddress.City},{" "}
                  {this.state.sale.BillingAddress.State}
                </div>
                {/* <div>
                                            
                                        </div> */}
                <div>
                  {this.state.sale.BillingAddress.Country}-{" "}
                  {this.state.sale.BillingAddress.PostalCode}
                </div>
                {/* <div>
                                            
                                        </div> */}
              </div>
            )}

            {this.state.sale.DeliveryCustomer && (
              <React.Fragment>
                {this.state.sale.DeliveryDate && (
                  <div>Delivery Date: {this.state.sale.DeliveryDate}</div>
                )}
                {this.state.sale.DeliveryDate && (
                  <div>
                    Delivery Time: {this.state.sale.DeliveryTime.HH}:
                    {this.state.sale.DeliveryTime.MM}
                  </div>
                )}
                {this.state.sale.DeliveryNotes !== "" && (
                  <div>{"Notes: " + this.state.sale.DeliveryNotes}</div>
                )}
                {this.state.sale.Customer &&
                  this.state.sale.DeliveryCustomer &&
                  this.state.sale.Customer.key !==
                    this.state.sale.DeliveryCustomer.key && (
                    <div>
                      {this.state.sale.DeliveryCustomer && (
                        <div>
                          {this.state.sale.DeliveryCustomer.FirstName}
                          {this.state.sale.DeliveryCustomer.LastName}
                        </div>
                      )}
                      {this.state.sale.DeliveryCustomer && (
                        <div>{this.state.sale.DeliveryCustomer.PhoneNo}</div>
                      )}
                    </div>
                  )}
                {this.state.sale.DeliveryCustomerAddress && (
                  <div>
                    <div>
                      {this.state.sale.DeliveryCustomerAddress.AddressType}
                    </div>
                    <div>
                      {this.state.sale.DeliveryCustomerAddress.Address1}
                    </div>
                    <div>
                      {this.state.sale.DeliveryCustomerAddress.Address2}
                    </div>
                    <div>{this.state.sale.DeliveryCustomerAddress.City}</div>
                    <div>{this.state.sale.DeliveryCustomerAddress.Country}</div>
                    <div>
                      {this.state.sale.DeliveryCustomerAddress.PostalCode}
                    </div>
                    <div>{this.state.sale.DeliveryCustomerAddress.State}</div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderLineItems() {
    return (
      <React.Fragment>
        <br />
        <div className="viewsales-section-header">Line Items:</div>
        {this.state.sale.TransactionType.TransactionType.toLowerCase() !==
          "receive payment" && (
          <div className="viewsales-table-container">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>MRP</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Unit</th>
                  <th>Sub total</th>
                  <th>Discount</th>
                  <th>Tax</th>
                  <th>Tax Amount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.sale.LineItems.map((lineItem, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="product-with-image">
                        {lineItem.Product.Images &&
                          lineItem.Product.Images.length > 0 && (
                            <div>
                              <img src={lineItem.Product.Images[0].ImagePath} />
                            </div>
                          )}
                        <div>
                          {lineItem.Product.Name}
                          <div className="sales-sub-text">
                            SKU - {lineItem.Product.SKU}
                          </div>
                          {lineItem.Product.BarCode !== "" && (
                            <div className="sales-sub-text">
                              Barcode - {lineItem.Product.BarCode}{" "}
                            </div>
                          )}
                          {lineItem.HSNSACCode !== "" && (
                            <div className="sales-sub-text">
                              HSNSACCode - {lineItem.HSNSACCode}{" "}
                            </div>
                          )}
                          {lineItem.hasOwnProperty("BatchNo") &&
                            lineItem.BatchNo !== "" && (
                              <div className="sales-sub-text">
                                Batch No: {lineItem.BatchNo}
                              </div>
                            )}
                          {lineItem.hasOwnProperty("BatchMRP") &&
                            lineItem.BatchMRP.toString() !== "0" && (
                              <div className="sales-sub-text">
                                Batch MRP: {lineItem.BatchMRP}
                              </div>
                            )}
                          {lineItem.hasOwnProperty("BatchRetailPrice") &&
                            lineItem.BatchRetailPrice.toString() !== "0" && (
                              <div className="sales-sub-text">
                                Batch Retail Price: {lineItem.BatchRetailPrice}
                              </div>
                            )}
                          {lineItem.hasOwnProperty("BatchMfgDate") &&
                            lineItem.BatchMfgDate !== "" && (
                              <div className="sales-sub-text">
                                Batch Mfg Date: {lineItem.BatchMfgDate}
                              </div>
                            )}
                          {lineItem.hasOwnProperty("BatchExpDate") &&
                            lineItem.BatchExpDate !== "" && (
                              <div className="sales-sub-text">
                                Batch Exp Date: {lineItem.BatchExpDate}
                              </div>
                            )}
                          <div className="sales-sub-text">
                            {lineItem.Product.hasOwnProperty("CustomField1") &&
                              lineItem.Product.CustomField1.trim() !== "" &&
                              this.state.productCustomField1DisplayName.trim() !==
                                "" && (
                                <React.Fragment>
                                  {this.state.productCustomField1DisplayName}:{" "}
                                  {lineItem.Product.CustomField1}
                                </React.Fragment>
                              )}
                            {lineItem.Product.hasOwnProperty("CustomField2") &&
                              lineItem.Product.CustomField2.trim() !== "" &&
                              this.state.productCustomField2DisplayName.trim() !==
                                "" && (
                                <React.Fragment>
                                  , {this.state.productCustomField2DisplayName}:{" "}
                                  {lineItem.Product.CustomField2}
                                </React.Fragment>
                              )}
                            {lineItem.Product.hasOwnProperty("CustomField3") &&
                              lineItem.Product.CustomField3.trim() !== "" &&
                              this.state.productCustomField3DisplayName.trim() !==
                                "" && (
                                <React.Fragment>
                                  , {this.state.productCustomField3DisplayName}:{" "}
                                  {lineItem.Product.CustomField3}
                                </React.Fragment>
                              )}
                            {lineItem.Product.hasOwnProperty("CustomField4") &&
                              lineItem.Product.CustomField4.trim() !== "" &&
                              this.state.productCustomField4DisplayName.trim() !==
                                "" && (
                                <React.Fragment>
                                  , {this.state.productCustomField4DisplayName}:{" "}
                                  {lineItem.Product.CustomField4}
                                </React.Fragment>
                              )}
                            {lineItem.Product.hasOwnProperty("CustomField5") &&
                              lineItem.Product.CustomField5.trim() !== "" &&
                              this.state.productCustomField5DisplayName.trim() !==
                                "" && (
                                <React.Fragment>
                                  , {this.state.productCustomField5DisplayName}:{" "}
                                  {lineItem.Product.CustomField5}
                                </React.Fragment>
                              )}
                            {lineItem.Product.hasOwnProperty("CustomField6") &&
                              lineItem.Product.CustomField6.trim() !== "" &&
                              this.state.productCustomField6DisplayName.trim() !==
                                "" && (
                                <React.Fragment>
                                  , {this.state.productCustomField6DisplayName}:{" "}
                                  {lineItem.Product.CustomField6}
                                </React.Fragment>
                              )}
                          </div>
                          {lineItem.Notes !== "" && (
                            <div className="sales-sub-text">
                              Notes - {lineItem.Notes}{" "}
                            </div>
                          )}
                          {lineItem.Employee.key !== "" && (
                            <div className="sales-sub-text">
                              {" "}
                              <i className="fa fa-user" />{" "}
                              {lineItem.Employee.EmployeeFirstName}{" "}
                              {lineItem.Employee.EmployeeLastName}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="number">{lineItem.Product.MRP}</td>
                    <td className="number">{lineItem.RetailPrice}</td>
                    <td className="number">
                      {lineItem.Qty}
                      {lineItem.hasOwnProperty("UniqueNoList") &&
                        lineItem.UniqueNoList.length > 0 && (
                          <div>
                            <button
                              type="submit"
                              style={{ width: "100%" }}
                              className="btn btn-md btn-default btn-flat"
                              onClick={() =>
                                this.setState({
                                  showUniqueNoInput: true,
                                  selectedLineItem: lineItem,
                                  selectedLineItemIndex: index,
                                  selectedProduct: lineItem.Product,
                                })
                              }
                            >
                              {lineItem.Product.UniqueLabel.trim() !== ""
                                ? lineItem.Product.UniqueLabel
                                : "Unique No"}
                            </button>
                          </div>
                        )}
                    </td>
                    <td>
                      {lineItem.hasOwnProperty("UOM")
                        ? this.state.uoms.filter(
                            (f) => f.value === lineItem.UOM
                          ).length > 0
                          ? this.state.uoms.filter(
                              (f) => f.value === lineItem.UOM
                            )[0].text
                          : lineItem.Product.UOM
                        : ""}
                    </td>
                    <td className="number">{lineItem.SubTotal}</td>
                    <td className="number">
                      {lineItem.DiscountAmount}
                      <div className="sales-sub-text">
                        {lineItem.DiscountPer} %
                      </div>
                    </td>
                    <td>
                      {lineItem.TaxGroup}
                      {lineItem.TaxComponentAmount.map((t, index) => (
                        <div key={index} className="sales-sub-text">
                          {t.TaxName} {t.TaxPercentage}%:{t.TaxAmount}
                        </div>
                      ))}
                    </td>
                    <td className="number">{lineItem.TotalTax}</td>
                    <td className="number">{lineItem.Amount}</td>
                  </tr>
                ))}
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className="number">
                    <b>{this.state.sale.BillQty}</b>
                  </th>
                  <th></th>
                  <th className="number">
                    <b>{this.state.sale.BillSubTotal}</b>
                  </th>
                  <th className="number">
                    <b>{this.state.sale.BillDiscount}</b>
                  </th>
                  <th></th>
                  <th className="number">
                    <b>{this.state.sale.BillTaxAmount}</b>
                  </th>
                  <th className="number">
                    <b>{this.state.sale.BillAmount}</b>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        {this.state.sale.TransactionType.TransactionType.toLowerCase() ===
          "receive payment" && (
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: "10px" }}>#</th>
                <th>Transaction No</th>
                <th>Transaction Type</th>
                <th>Transaction Amount</th>
                <th>Balance Amount</th>
                <th>Receive Amount</th>
              </tr>
            </thead>
            <tbody>
              {this.state.sale.OutStandingPayments.map((lineItem, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{lineItem.TransactionNo}</td>
                  <td>{lineItem.TransactionType}</td>
                  <td>{lineItem.TransactionAmount}</td>
                  <td>{lineItem.OutStandingAmount}</td>
                  <td>{lineItem.ReceiveAmount}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <b>{this.state.sale.BillAmount}</b>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </React.Fragment>
    );
  }

  renderNotes() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Notes:</div>
        {this.state.sale.Notes}
      </React.Fragment>
    );
  }

  renderAuditTrail() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Audit Trail:</div>
        <div>Action: {this.state.sale.Action}</div>
        <div>Action By: {this.state.sale.ActionBy}</div>
        <div>
          Action Date:{" "}
          {this.state.sale.ActionOn.seconds
            ? new Date(this.state.sale.ActionOn.seconds * 1000)
                .toString()
                .substring(0, 24)
            : ""}
        </div>
        <AuditTrail
          {...this.props}
          module={constants.MODULE_SALES}
          parentKey={this.state.key}
        />
      </React.Fragment>
    );
  }

  renderPaymentMethods() {
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Payment Methods:</div>
        <table className="table">
          {this.state.sale.Payments.map((payment, index) => (
            <tr key={index}>
              <td style={{ width: "50%" }}>{payment.PaymentMode}:</td>
              <td className="text-align-right">{payment.Amount}</td>
            </tr>
          ))}
        </table>
      </React.Fragment>
    );
  }

  renderTaxSummaryList() {
    return (
      <React.Fragment>
        {this.state.sale.TaxSummaryList && (
          <React.Fragment>
            <div className="viewsales-section-header">Tax Summary:</div>
            <div className="viewsales-table-container">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>HSN/SAC</th>
                    <th>Qty</th>
                    <th>Taxable Amount</th>
                    <th>Tax</th>
                    <th>Tax Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sale.TaxSummaryList.map((t, index) => (
                    <tr key={index}>
                      <td>{t.HSNSACCode}</td>
                      <td className="number">
                        {t.hasOwnProperty("TotalQty") ? t.TotalQty : 0}
                      </td>
                      <td className="number">{t.TaxableAmount}</td>
                      <td>{t.TaxGroup}</td>
                      <td className="number">{t.TotalTax}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderTotals() {
    return (
      <React.Fragment>
        {this.state.sale.TransactionType.TransactionType.toLowerCase() !==
          "receive payment" && (
          <React.Fragment>
            <div className="viewsales-section-header">Total:</div>
            <div className="viewsales-table-container">
              <table className="table">
                <tr>
                  <td style={{ width: "50%" }}>Qty:</td>
                  <td className="text-align-right">
                    {this.state.sale.BillQty}
                  </td>
                </tr>
                <tr>
                  <td>Subtotal:</td>
                  <td className="text-align-right">
                    {this.state.sale.BillSubTotal}
                  </td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td className="text-align-right">
                    {this.state.sale.BillDiscount}
                  </td>
                </tr>
                <tr>
                  <td>Tax:</td>
                  <td className="text-align-right">
                    {this.state.sale.BillTaxAmount}
                  </td>
                </tr>
                {/* {this.state.sale.RoundOff &&  */}
                <tr>
                  <td>Round Off:</td>
                  <td className="text-align-right">
                    {this.state.sale.RoundOff}
                  </td>
                </tr>
                {/* } */}
                {this.state.sale.hasOwnProperty("TCSAmount") && (
                  <tr>
                    <td>
                      TCS (
                      {(this.state.sale.hasOwnProperty("TCSAmountAppliedOn")
                        ? this.state.sale.TCSAmountAppliedOn
                        : "") +
                        " @ " +
                        (this.state.sale.hasOwnProperty("TCSPercentage")
                          ? this.state.sale.TCSPercentage + "%"
                          : "")}
                      ):
                    </td>
                    <td className="text-align-right">
                      {this.state.sale.TCSAmount}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Total:</td>
                  <td className="text-align-right">
                    {this.state.sale.BillAmount}
                  </td>
                </tr>
                <tr>
                  <td>Paid Amount:</td>
                  <td className="text-align-right">
                    {this.state.sale.BillPaidAmount}
                  </td>
                </tr>
                {this.state.sale.ReceivedPaymentTransactions &&
                  this.state.sale.ReceivedPaymentTransactions.map(
                    (m, index) => (
                      <tr key={index}>
                        <td>
                          <i>{m.ReceivePaymentNo}</i>
                        </td>
                        <td className="text-align-right">
                          <i>{m.ReceiveAmount}</i>
                        </td>
                      </tr>
                    )
                  )}
                <tr>
                  <td>Balance Amount:</td>
                  <td className="text-align-right">
                    {this.state.sale.BalanceAmount}
                  </td>
                  {/* <td>{this.state.sale.hasOwnProperty('ReceivedPaymentAmount') ?  this.state.sale.BalanceAmount -  this.state.sale.ReceivedPaymentAmount:  this.state.sale.BalanceAmount}</td> */}
                </tr>
                {this.state.sale.BillChangeDue !== 0 && (
                  <tr>
                    <td>Change Due:</td>
                    <td className="text-align-right">
                      {this.state.sale.BillChangeDue}
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  // onActionButtonClick(action) {
  //     if (action === "edit") {
  //         this.props.history.push({
  //             pathname: "/home/sales/sales",
  //             // pathname:'/home/sales/editsales/',
  //             state: {
  //                 type: actionTypes.SALE_EDIT,
  //                 key: this.state.key,
  //                 transactionType: this.state.sale.TransactionType,
  //                 sale: this.state.sale,
  //             },
  //         });
  //     } else if (action === "return") {
  //         this.props.history.push({
  //             pathname: "/home/sales/salesreturn/",
  //             state: {
  //                 type: actionTypes.SALE_NEW,
  //                 key: this.state.key,
  //                 transactionType: this.state.salesReturnTransactionType,
  //                 sale: this.state.sale,
  //             },
  //         });
  //     } else if (action === "cancel") {
  //         this.setState({ showCancelSales: true });
  //     } else if (action === "print") {
  //         this.onPrintClicked();
  //     } else if (action === "sales_email") {
  //         this.onEmailClicked();
  //     }
  // }

  renderFooter() {
    return (
      <div className="inventory-footer">
        <div className="inventory-footer-sub">
          <div className="left w30">
            {/* <button 
                    type="button"
                    onClick={()=>{
                        let sale = this.state.sale;
                        console.log(sale)
                        // sale.ChildTransactionKey="";
                        // sale.ChildTransactionNo="";
                        // sale.ChildTransactionType="";
                        sale.BackEndUpdate=true;
                        // sale.UpdateVersion="1.1"
                        sale.RegistrationID = sale.RegistrationID + "_deleted";
                        sale.Store.key =sale.Store.key + "_deleted";
                        this.props.updateSales(sale,this.state.key)
                    }}
                    className="btn btn-flat btn-default"
                    style={{marginRight: "5px"}}
                >
                    <i className="fa fa-envelope "></i>{" "}Test
                </button> */}
            <button
              type="button"
              onClick={this.onEmailClicked}
              className="btn btn-flat btn-default"
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-envelope "></i> Email
            </button>
            {/* <button
                    type="button"
                    onClick={this.onPrintClicked}
                    className="btn btn-flat btn-default"
                    style={{marginRight: "5px"}}
                >
                    <i className="fa fa-print"></i>{" "}Print
                </button> */}
            {this.state.selectedPringConfigID !== "" && (
              <React.Fragment>
                <button
                  type="button"
                  onClick={this.onPrintClicked}
                  className="btn btn-flat btn-default"
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-print"></i> Print
                </button>
                <select
                  className="form-control input-md"
                  value={this.state.selectedPringConfigID}
                  onChange={(evt) =>
                    this.setState({
                      selectedPringConfigID: evt.target.value,
                      selectedPrintConfigPrintDisplayName:
                        evt.target.options[evt.target.selectedIndex].text,
                    })
                  }
                >
                  {this.props.config.PrintConfigs.filter(
                    (e) =>
                      !e.IsDeleted &&
                      e.TransactionTypeID ===
                        this.state.sale.TransactionType.key
                  ).map((t, index) => (
                    <option key={index} value={t.key}>
                      {t.PrintDisplayName}
                    </option>
                  ))}
                </select>
              </React.Fragment>
            )}

            {/* <div className="input-group">
                        <div className="input-group-btn">
                            
                        </div>
                        
                </div>} */}
          </div>
          <div className="right w70">
            {/* <button
                        type="button"
                        className="btn btn-flat btn-danger pull-right"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                            let sale = this.state.sale
                            // sale.Payments[1].PaymentMode="Cash";
                            sale.BackEndUpdate = true;
                            // sale.RegistrationID = sale.RegistrationID + "_deleted";
                            // sale.Store.key = sale.Store.key + "_deleted";
                            sale.Version = "1.1.1";
                            console.log('key', this.state.key)
                            console.log('sale', sale)
                            this.props.updateSales(sale, this.state.key)
                            
                            }}>
                        <i className="fa fa-minus-circle"></i>Backend Update
                    </button> */}
            {/* <div className="col-md-8 pull-right" style={{paddingTop: "5px",paddingRight: "10px"}} > */}

            {this.state.eInvocieAllowed && (
              <button
                type="button"
                className="btn btn-flat pull-right"
                disabled={this.state.generatingEInvoice}
                style={{ marginRight: "5px" }}
                onClick={() => {
                  this.generateEInvoice();
                }}
              >
                <i className="fa fa-file-o"></i> Generate eInvoice
              </button>
            )}

            {this.state.editAllowed && (
              <Link
                to={{
                  pathname: "/home/sales/sales",
                  state: {
                    type: actionTypes.SALE_EDIT,
                    key: this.state.key,
                    transactionType: this.state.sale.TransactionType,
                    sale: this.state.sale,
                  },
                }}
                type="button"
                style={{ marginRight: "5px" }}
                className="btn btn-info btn-flat pull-right"
              >
                <i className="fa fa-file-o"></i>Edit{" "}
                {this.state.sale.TransactionType.TransactionType}
              </Link>
            )}
            {/* "/home/sales/salesreturn/ */}
            {this.state.returnAllowed && (
              <Link
                to={{
                  pathname: "/home/sales/sales/",
                  state: {
                    type: actionTypes.SALE_NEW,
                    key: this.state.key,
                    transactionType: this.state.salesReturnTransactionType,
                    sale: this.state.sale,
                  },
                }}
                type="button"
                className="btn btn-warning btn-flat pull-right"
                style={{ marginRight: "5px" }}
              >
                <i className="fa  fa-mail-forward"></i>
                Sales Return
              </Link>
            )}
            {this.state.cancelAllowed && (
              <button
                type="button"
                className="btn btn-flat btn-danger pull-right"
                style={{ marginRight: "5px" }}
                onClick={() => {
                  this.setState({ showCancelSales: true });
                }}
              >
                <i className="fa fa-minus-circle"></i> Cancel{" "}
                {this.state.sale.TransactionType.TransactionType}
              </button>
            )}
            <Link
              to={{
                pathname: "/home/sales/saleslist",
                state: { type: "list" },
              }}
              className="btn btn-flat btn-default pull-right"
            >
              <i className="fa fa-arrow-left"></i> Back
            </Link>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <React.Fragment>
        {this.state.showEInvoicingErrors && (
          <Window
            header={"Error"}
            // height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showEInvoicingErrors: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showEInvoicingErrors: false });
            }}
            buttons={[{ Type: "primary", Text: "OK", Align: "right" }]}
          >
            <ul>
              {this.state.eInvoicingErrors.map((e) => (
                <li>{e.Error}</li>
              ))}
            </ul>
          </Window>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderError()}

        {this.state.sale && !this.state.print && (
          <Page
            title={
              this.state.sale.TransactionType.TransactionType +
              "- #" +
              this.state.sale.TransactionNo
            }
            onClick={(action) => {
              if (action === "cancel") {
                this.props.history.push({ pathname: "/home/sales/saleslist" });
              }
            }}
          >
            <PageSection>
              {!isMobileApp() && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-3 viewsales-section">
                      {this.renderTransactionDetails()}
                    </div>
                    <div className="col-md-3 viewsales-section">
                      {this.renderCustomerDetails()}
                    </div>
                    <div className="col-md-3 viewsales-section">
                      {this.renderNotes()}
                    </div>
                    <div className="col-md-3 viewsales-section">
                      {this.renderAuditTrail()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 viewsales-section">
                      {this.renderLineItems()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 viewsales-section">
                      {this.renderCustomFields()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 col-12 viewsales-section">
                      {this.renderTaxSummaryList()}
                    </div>
                    <div className="col-md-3 col-12 viewsales-section">
                      {this.renderPaymentMethods()}
                    </div>
                    <div className="col-md-4 col-12 viewsales-section">
                      {this.renderTotals()}
                    </div>
                  </div>
                </React.Fragment>
              )}

              {isMobileApp() && (
                <React.Fragment>
                  <div className="row">
                    <div className="col-md-3 viewsales-section">
                      {this.renderTransactionDetails()}
                    </div>
                    <div className="col-md-3 viewsales-section">
                      {this.renderCustomerDetails()}
                    </div>
                    <div className="col-md-3 viewsales-section">
                      {this.renderNotes()}
                    </div>
                    <div className="col-md-3 viewsales-section">
                      {this.renderAuditTrail()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 viewsales-section">
                      {this.renderLineItems()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5 col-12 viewsales-section">
                      {this.renderTaxSummaryList()}
                    </div>
                    <div className="col-md-3 col-12 viewsales-section">
                      {this.renderPaymentMethods()}
                    </div>
                    <div className="col-md-4 col-12 viewsales-section">
                      {this.renderTotals()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 viewsales-section">
                      {this.renderCustomFields()}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </PageSection>

            {!isMobileApp() && this.renderFooter()}

            {isMobileApp() && (
              <ActionButton
                icon="option"
                type="option"
                onClick={(action) => {
                  if (action === "print") {
                    this.onPrintClicked();
                  } else if (action === "email") {
                    this.onEmailClicked();
                  } else if (action === "cancel") {
                    this.setState({ showCancelSales: true });
                  } else if (action === "return") {
                    this.props.history.push({
                      pathname: "/home/sales/salesreturn/",
                      state: {
                        type: actionTypes.SALE_NEW,
                        key: this.state.key,
                        transactionType: this.state.salesReturnTransactionType,
                        sale: this.state.sale,
                      },
                    });
                  } else if (action === "edit") {
                    this.props.history.push({
                      pathname: "/home/sales/sales",
                      state: {
                        type: actionTypes.SALE_EDIT,
                        key: this.state.key,
                        transactionType: this.state.sale.TransactionType,
                        sale: this.state.sale,
                      },
                    });
                  }
                }}
                options={this.state.actionOptions}
              />
            )}
          </Page>
        )}

        {this.state.showCancelSales && (
          <CancelSales
            {...this.props}
            salesID={this.state.key}
            onSave={() => {
              this.load();
              this.setState({ showCancelSales: false });
            }}
            onClose={() => this.setState({ showCancelSales: false })}
          />
        )}

        {this.state.showEmail && (
          <Window
            header={"Send Email"}
            height={"300px"}
            modal={true}
            processing={this.state.saving}
            onClose={() => {
              this.setState({ showEmail: false });
            }}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "send") {
                this.sendEmail();
              } else {
                this.setState({ showCancel: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "Send", Align: "right" },
            ]}
          >
            <Input
              type="text"
              label="Please provide email id"
              onChange={(value) => this.setState({ emailID: value })}
              value={this.state.emailID}
              error={this.state.emailError}
            />
          </Window>
        )}

        {this.state.showApproveConfirmationWindow && (
          <Window
            header={"Approve Confirmation"}
            height={"200px"}
            modal={false}
            processing={this.state.processing}
            onClose={() =>
              this.setState({ showApproveConfirmationWindow: false })
            }
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "yes") {
                this.onApproveClicked();
              } else {
                this.setState({ showApproveConfirmationWindow: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "No", Align: "left" },
              { Type: "primary", Text: "Yes", Align: "right" },
            ]}
          >
            <div>Are you sure you wish to approve this transaction?</div>
          </Window>
        )}

        {this.state.showUniqueNoInput && (
          <UniqueNoInput
            {...this.props}
            mobile={isMobileApp()}
            transactionID={this.state.key}
            product={this.state.selectedProduct}
            lineItem={this.state.selectedLineItem}
            mode={"view"}
            onOK={(uniqueNoList) => {
              this.setState({ showUniqueNoInput: false });
            }}
            onClose={() => this.setState({ showUniqueNoInput: false })}
          />
        )}

        {this.state.print && (
          <Print
            {...this.props}
            printChecked={this.state.printChecked}
            sendEmailChecked={this.state.sendEmailChecked}
            sendSMSChecked={false}
            printData={this.state.printData}
            onClose={() => {
              this.setState({ print: false });
              this.load();
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ViewSales;
