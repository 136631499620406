import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap';
import * as actions from '../../actionTypes'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import Loader from '../Loader'

class CampaignsToRun extends Component {
    constructor() {
        super();
        this.state = {
            campaigns: [],
            loading: true,
            stores: [],
        }
        this.onStoreChange = this.onStoreChange.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.loadCampaignsToRun = this.loadCampaignsToRun.bind(this)
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            this.setState({ stores: stores })
        });

        this.setState({ storeList: storeList, storeID: this.props.user.store.key });
        this.loadCampaignsToRun(this.props.user.store.key);
    }

    loadCampaignsToRun(storeID) {
        this.props.getCampaignsToRun(this.props.user.user.RegistrationID, storeID).then((campaigns) => {
            this.setState({ campaigns: campaigns, loading: false })
        })
    }

    onStoreChange(e) {
        this.setState({ storeID: e.target.value })
    }

    onSearchClick() {
        this.setState({ loading: true })
        this.loadCampaignsToRun(this.state.storeID)
    }

    onClearSearch() {
        this.setState({
            storeID: this.props.user.store.key,
        });
        this.setState({ loading: true })
        this.loadCampaignsToRun(this.props.user.store.key)
    }

    render() {
        const btnColStyle = { width: "120px" }
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Campaigns Report</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-9">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        {/* <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i className="glyphicon glyphicon-download-alt " ></i></button> */}
                                    </div>
                                </div>
                                <br/>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Options</th>
                                                <th>Stores</th>
                                                <th>Status</th>
                                                <th>Message</th>
                                                <th>Total Sent</th>
                                                <th>Total Visited</th>
                                                <th>Run Date</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.campaigns.map((campaign, index) =>
                                                <tr>
                                                    <td>{campaign.Name}</td>
                                                    <td>{campaign.Type}</td>
                                                    <td>
                                                        {campaign.Type === "One Time" &&
                                                            <React.Fragment>
                                                                <div>Date: {campaign.StartDate}</div>
                                                                <div> Slot: {campaign.Slot === 8 ? "8:00 - 10:00" : campaign.Slot === 10 ? "10:00 - 12:00" :
                                                                    campaign.Slot === 12 ? "12:00 - 14:00" : campaign.Slot === 14 ? "14:00 - 16:00" : campaign.Slot === 16 ? "16:00 - 18:00" :
                                                                        campaign.Slot === 18 ? "18:00 - 20:00" : campaign.Slot === 20 ? "20:00 - 2:00" : ''}</div>
                                                            </React.Fragment>}
                                                        {campaign.AllCustomersSelected && <div>Customers: All Customers</div>
                                                        }
                                                        {campaign.CustomerLastVisited &&
                                                            <div>Customer Last Visited in {campaign.CustomerInDays} days</div>}

                                                        {campaign.PurchaseAmountSelected &&
                                                            <div>Purchase Amount : From {campaign.PurchaseAmountFrom} to {campaign.PurchaseAmountTo}</div>
                                                        }
                                                        {campaign.MembershipSelected &&
                                                            <div>Membership : {campaign.Membership.Name}</div>
                                                        }
                                                        {campaign.CustomerGenderSelected &&
                                                            <div>Gender : {campaign.CustomerGender}</div>
                                                        }

                                                        {campaign.WalletBalanceSelected &&
                                                            <div>Wallet Balance : From {campaign.WalletBalanceFrom} to {campaign.WalletBalanceTo}</div>
                                                        }
                                                        {campaign.OutStandingAmtSelected &&
                                                            <div>OutStanding Amount : From {campaign.OutStandingAmtFrom} to {campaign.OutStandingAmtTo}</div>
                                                        }
                                                    </td>
                                                    {(campaign.AllStoresSelected || campaign.Stores) && <td>
                                                        {campaign.Stores && campaign.Stores.map((store) =>
                                                            <div>Store : {store.label}</div>
                                                        )}
                                                        {/* {campaign.AllStoresSelected && <div>Stores: All Stores</div>} */}
                                                    </td>}
                                                    <td>
                                                        {campaign.Status}
                                                    </td>
                                                    <td>
                                                        {campaign.Message}
                                                    </td>
                                                    <td>
                                                        {campaign.hasOwnProperty('NoOfCustomers') ? campaign.NoOfCustomers: ''}
                                                    </td>
                                                    <td>
                                                        {campaign.hasOwnProperty('NoOfConverted') ? campaign.NoOfConverted : ''}
                                                    </td>
                                                    <td>
                                                        <div>{campaign.ActionOn ? (new Date(campaign.ActionOn.seconds * 1000)).toString().substring(0, 24) : ''}</div>
                                                    </td>
                                                    <td><Link to={{ pathname: "/home/crm/campaigncustomers", state: { CampaignToRunID: campaign.key } }}
                                                        type="button" className="btn btn-md btn-default btn-flat">
                                                        Show Report</Link></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(CampaignsToRun)