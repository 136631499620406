import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'

class VendorOutstanding extends Component {
    constructor() {
        super()
        this.state = {
            vendors: [],
            showFilters: false,
            searchText: '',
            searchCity: '',
            searchState: '',
            searchPinCode: '',
            searchCountry: '',
            searchCustomField1: '',
            searchCustomField2: '',
            searchCustomField3: '',
            searchCustomField4: '',
            searchCustomField5: '',
            searchCustomField6: '',
            searchNotes: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            searchActiveInactiveAllRecords: 'active',
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            loading: true
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.showHideFilters = this.showHideFilters.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Vendor') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        this.loadVendor(this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity,
            this.state.searchState,
            this.state.searchPinCode,
            this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    loadVendor(from, option, searchText, searchCity, searchState, searchPinCode, searchCountry, searchCustomField1, searchCustomField2, searchCustomField3,
        searchCustomField4, searchCustomField5, searchCustomField6) {

        this.props.getVendors(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option, searchText, searchCity, searchState, searchCountry, searchPinCode,
            searchCustomField1, searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6)
            .then((result) => {
                this.setState({ vendors: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })

    }

    showHideFilters() {
        let showFilters = this.state.showFilters
        this.setState({ showFilters: !showFilters })
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchText: '', searchCity: '',
            searchState: '', searchPinCode: '', searchCountry: '', searchCustomField1: '', searchCustomField2: '',
            searchCustomField3: '', searchCustomField4: '', searchCustomField5: '', searchCustomField6: '',
            showFilters: false,
            searchActiveInactiveAllRecords: 'active'
        })
        this.loadVendor(0, "active", "", "", "", "", "", "", "", "", "", "", "")
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadVendor(
            0,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity,
            this.state.searchState,
            this.state.searchPinCode,
            this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6)
    }

    onExportClick() {

        this.props.getVendors(this.props.user.user.RegistrationID, 0, 10000, this.state.searchActiveInactiveAllRecords,
            this.state.searchText,this.state.searchCity,this.state.searchState,this.state.searchPinCode,this.state.searchCountry,
            this.state.searchCustomField1,this.state.searchCustomField2,this.state.searchCustomField3,this.state.searchCustomField4,
            this.state.searchCustomField5,this.state.searchCustomField6)
            .then((result) => {
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let vendors = []
                result.searchResult.map((data) => {
                    let vendor = {
                        "Vendor Code": data.VendorCode,
                        "Name": data.VendorFirstName + ' ' + data.VendorLastName,
                        "Email ID": data.EmailID,
                        "Phone No": data.PhoneNo,
                        "Last Inventory Amount" : data.hasOwnProperty('LastInventoryAmount') ? data.LastInventoryAmount : 0,
                        "Last Inventory Date" : data.hasOwnProperty('LastInventoryDate') ? (new Date(data.LastInventoryDate).toISOString().split('T')[0]) : '',
                        "Last Inventory No": data.hasOwnProperty('LastInventoryNo') ? data.LastInventoryNo : '',
                        "Last Expense Amount" : data.hasOwnProperty('LastExpenseAmount') ? data.LastExpenseAmount : 0,
                        "Last Expense Date": data.hasOwnProperty('LastExpenseDate') ? (new Date(data.LastExpenseDate).toISOString().split('T')[0]) : '',
                        "Last Expense No": data.hasOwnProperty('LastExpenseNo') ? data.LastInventoryNo : '',
                        "Due Amount" : data.hasOwnProperty('DueAmount') ? data.DueAmount : 0
                    }
                    vendors.push(vendor);
                })
                const csvData = constatnts.objToCSV(vendors);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "VendorOutstanding.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadVendor(
            recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity,
            this.state.searchState,
            this.state.searchPinCode,
            this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6)
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "30px" }}>Vendor Due Amount</font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="form-group">
                                            <label>Search by Vendor name, code, email id,phone no</label>
                                            <input type="text" className="form-control input-md"
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button className="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px", marginRight:"5px"  }}
                                            onClick={this.onExportClick}
                                        >Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                        <a type="button" className="btn btn-md pull-right" style={{ marginTop: "25px" }} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a>
                                    </div>
                                </div>
                                {this.state.showFilters && <div className="row">
                                    <div className="col-md-9">
                                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchCity}
                                                    onChange={(evt) => this.setState({ searchCity: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style1={{ padding: "0px" }}>
                                            <div className="form-group">
                                                <label>State</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchState}
                                                    onChange={(evt) => this.setState({ searchState: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style1={{ padding: "0px" }}>
                                            <div className="form-group">
                                                <label>Pincode</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchPinCode}
                                                    onChange={(evt) => this.setState({ searchPinCode: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style1={{ padding: "0px" }}>
                                            <div className="form-group">
                                                <label>Country</label>
                                                <input type="text" className="form-control input-md"
                                                    value={this.state.searchCountry}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => this.setState({ searchCountry: evt.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.showFilters && <div className="row">
                                    <div className="col-md-9">
                                        <div className="col-md-3" style={{ paddingLeft: "0px" }}>
                                            <div className="form-group">
                                                <label>{this.state.customField1DisplayName}</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchCustomField1}
                                                    onChange={(evt) => this.setState({ searchCustomField1: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style1={{ padding: "0px" }}>
                                            <div className="form-group">
                                                <label>{this.state.customField2DisplayName}</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchCustomField2}
                                                    onChange={(evt) => this.setState({ searchCustomField2: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style1={{ padding: "0px" }}>
                                            <div className="form-group">
                                                <label>{this.state.customField3DisplayName}</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchCustomField3}
                                                    onChange={(evt) => this.setState({ searchCustomField3: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" style1={{ padding: "0px" }}>
                                            <div className="form-group">
                                                <label>{this.state.customField4DisplayName}</label>
                                                <input type="text" className="form-control input-md"
                                                    onKeyPress={this.handleKeyPress}
                                                    value={this.state.searchCustomField4}
                                                    onChange={(evt) => this.setState({ searchCustomField4: evt.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Vendor Code</th>
                                                <th>Name</th>
                                                <th>Email ID</th>
                                                <th>Phone No</th>
                                                <th>Last Purchased Details</th>
                                                <th>Last Expense Details</th>
                                                <th>Due Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.vendors.map((vendor) =>
                                                <tr>
                                                    <td>{vendor.VendorCode}</td>
                                                    <td>{vendor.VendorFirstName + ' ' + vendor.VendorLastName}</td>
                                                    <td>{vendor.EmailID}</td>
                                                    <td>{vendor.PhoneNo}</td>
                                                    <td>
                                                        <div>Last Inventory Amount : {vendor.hasOwnProperty('LastInventoryAmount') ? vendor.LastInventoryAmount : 0}</div>
                                                        <div>Last Inventory Date : {vendor.hasOwnProperty('LastInventoryDate') ? (new Date(vendor.LastInventoryDate).toISOString().split('T')[0]) : ''}</div>
                                                        <div>Last Inventory No : {vendor.hasOwnProperty('LastInventoryNo') ? vendor.LastInventoryNo : ''}</div>
                                                    </td>
                                                    <td>
                                                        <div>Last Expense Amount : {vendor.hasOwnProperty('LastExpenseAmount') ? vendor.LastExpenseAmount : 0}</div>
                                                        <div>Last Expense Date : {vendor.hasOwnProperty('LastExpenseDate') ? (new Date(vendor.LastExpenseDate).toISOString().split('T')[0]) : ''}</div>
                                                        <div>Last Expense No : {vendor.hasOwnProperty('LastExpenseNo') ? vendor.LastInventoryNo : ''}</div>
                                                    </td>
                                                    <td>
                                                        {vendor.hasOwnProperty('DueAmount') ? vendor.DueAmount: 0}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    </div>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(VendorOutstanding)