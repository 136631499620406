import React, { Component } from 'react';
import HeaderNew from '../HeaderNew'
import '../Common/Common.css'
import Spinner from '../Common/Spinner'
import ActionButton from '../Common/ActionButton'
import RecordNotFound from '../Common/RecordNotFound';
import TabStrip from '../Common/TabStrip'
import Filter from '../Common/Filter'
import PopupMenu from '../Common/PopupMenu';
import * as actionTypes from '../../actionTypes'
import Confirmation from '../Common/Confirmation';

class AppointmentList extends Component {

    constructor() {
        super();
        this.state = {
            searchText: '',
            showFilter: false,
            filterOptions:[
                {label:"Appointment From", type:"date", range:"from" , value: new Date().toISOString().split('T')[0],field:"AppointmentDate"},
                {label:"Appointment To", type:"date", range:"to" ,value: new Date().toISOString().split('T')[0],field:"AppointmentDate"},
               ],
            appointments: [],
            showPopupMenu: false,
            loading: true,
            tabButtons:[],
            selectedTabIndex: 0,
            selectedTab: '',
            selectedAppointment: null,
            popButtons: [],
            showCancelled: false,
        };
        this.onActionButtonClick = this.onActionButtonClick.bind(this);
        this.onTabSelected= this.onTabSelected.bind(this);
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onCancellation = this.onCancellation.bind(this)
    };

    async componentDidMount() {

        let tabButtons = [{ text: "Pending" }, { text: "Guest Arrived" }, { text: "Service Done" }, { text: "Invoice Created" }, { text: "Cancelled" }]
        await this.loadAppointments(this.state.filterOptions[0].value, this.state.filterOptions[1].value, '', '');

        this.setState({
            tabButtons: tabButtons,
        });
    };

    loadAppointments(startDate, endDate, employeeID, status) {
        
        // console.log("startDate", startDate);
        // console.log("endDate", endDate);
        this.props.getAllAppointments(this.props.user.user.RegistrationID, startDate, endDate, employeeID, status).then((appointments) => {
            // console.log("appointments", appointments);
            this.setState({ appointments: appointments, loading: false });
        })
    };

    onActionButtonClick(action) {
        this.props.history.push({ pathname: '/home/appointment/create' });
    };

    onTabSelected(selectedTabIndex){
        let selectedTab = this.state.tabButtons[selectedTabIndex].text;
        if(selectedTab === "Pending")
        {
            selectedTab ='';
        }
        this.setState({ selectedTabIndex: selectedTabIndex, selectedTab: selectedTab })
    };

    onRowClick(appointment) {
        let popButtons = [];
        let showPopupMenu = false;
        if(this.state.selectedTab === '')
        {
            popButtons.push(
                { value: "edit", text: "Edit", module: 'appointment' },
                { value: "ga", text: "Guest Arrived", module: 'appointment' },
                { value: "cancel", text: "Cancel", module: 'appointment' },
            )
            showPopupMenu = true;
        }
        else if(this.state.selectedTab === 'Guest Arrived')
        {
            popButtons.push(
                { value: "edit", text: "Edit", module: 'appointment' },
                { value: "sd", text: "Service Done", module: 'appointment' },
                { value: "cancel", text: "Cancel", module: 'appointment' },
            )
            showPopupMenu = true;
        }
        else if(this.state.selectedTab === 'Service Done')
        {
            if(appointment.hasOwnProperty('PackageID') && appointment.PackageID === '')
            {
                showPopupMenu = true;

                popButtons.push(
                    { value: "ca", text: "Create Invoice", module: 'appointment' },
                )
            }
        }
        this.setState({popButtons: popButtons, selectedAppointment: appointment, showPopupMenu: showPopupMenu })
    };

    async onShowPopupMenuClick(value) {
        let object = this.state.selectedAppointment;
        // console.log("object", object);
        if (value === "edit") {
            this.setState({ showPopupMenu: false, selectedAppointment: null });
            this.props.history.push({ pathname: '/home/appointment/edit/', state: { source: 'edit', selectedCustomer: object.Customer, startDate: (new Date(object.AppointmentDate.seconds * 1000)).toISOString().split('T')[0] } })
        }
        else if (value === "cancel") {
            this.setState({ showCancelled: true, showPopupMenu: false });
        }
        else if (value === "ga") {
            let appointmentList = this.state.appointments.filter((e => object.Customer.key === e.Customer.key));
            for (let i = 0; i < appointmentList.length; i++) {
                const obj = appointmentList[i];
                await this.props.updateAppointmentStatus('Guest Arrived', obj.key);
            };
            this.setState({  showPopupMenu: false });
            this.loadAppointments(this.state.filterOptions[0].value, this.state.filterOptions[1].value, '', '');
        }
        else if (value === "sd") {
            await this.props.updateAppointmentStatus('Service Done', object.key);
            if(object.hasOwnProperty('PackageID') && object.PackageID !== "")
            {
                this.updatePackageDetails(object);
            }
            this.setState({  showPopupMenu: false, selectedAppointment: null });
            this.loadAppointments(this.state.filterOptions[0].value, this.state.filterOptions[1].value, '', '');
        }
        else if (value === "ca") {
            this.setState({  showPopupMenu: false, selectedAppointment: null });
            this.createInvoice(object);
        }
        else {
            this.setState({ showPopupMenu: false, selectedAppointment: null })
        }
    };

    async updatePackageDetails(appointment)
    {
        let objPackage = await this.props.getPackageDetails(appointment.PackageID);
        // console.log("objPackage", objPackage);
        let service = objPackage.PackageServices.filter((f) => f.ServiceID === appointment.Service.value)[0];
        if (service) {
          let completedCount = 0;
          let status = 'Pending';
          service.ServiceDate = (appointment.AppointmentDate.hasOwnProperty('seconds') ? (new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0] : (new Date(appointment.AppointmentDate)).toISOString().split('T')[0]);
          let employee = {
            EmployeeID: appointment.EmployeeID,
            EmployeeName: appointment.EmployeeName,
          }
          service.Employee = employee;
          service.Status = 'Completed';
        //   console.log("service", service);
        
          let serviceIndex = -1;
          objPackage.PackageServices.map((f, index) => { if (f.ServiceID === service.ServiceID) serviceIndex = index });
          if (serviceIndex >= 0)
            objPackage.PackageServices.splice(serviceIndex, 1);

          objPackage.PackageServices.push(service);

          for (let index = 0; index < objPackage.PackageServices.length; index++) {
            let packageService = objPackage.PackageServices[index]
            if (packageService.Status === 'Completed')
              completedCount += 1;
          }
          if (completedCount > 0) {
            if (completedCount === objPackage.PackageServices.length) {
              status = 'Completed'
            }
            if (completedCount < objPackage.PackageServices.length) {
              status = 'Partial'
            }
          }
          objPackage.PackageStatus = status;
          objPackage.ActionOn = null;
        //   console.log("objPackage", objPackage);
          this.props.updatePackage(objPackage, appointment.PackageID);
        }
    };

    async createInvoice(appointment) {
        let customerAppointments = this.state.appointments.filter((f) => f.Customer.key === appointment.Customer.key && (f.Status.trim() === 'Service Done' || f.Status.trim() === 'Guest Arrived') && f.PackageID === '');
        console.log("customerAppointments", customerAppointments);
        
        if (customerAppointments && customerAppointments.length > 0) {
            let transactionType = this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted && e.TransactionType === 'Sales Invoice')[0];
            
            if(transactionType)
            {
                let sale = {
                    TransactionType: transactionType,
                    Customer: customerAppointments[0].Customer,
                    RegistrationID: this.props.user.user.RegistrationID,
                    CashRegister: null,
                    Store: this.props.user.store,
                    LineItems: [],
                    BillQty: 0,
                    BillSubTotal: 0,
                    BillDiscount: 0,
                    BillTaxAmount: 0,
                    BillAmount: 0,
                    RoundOff: 0,
                    BillBalanceAmount: 0,
                    BillPaidAmount: 0,
                    BillChangeDue: 0,
                    Payments: null,
                    ParentTransactionNo: "",
                    ParentTransactionKey: "",
                    Employee: null,
                    Notes: "",
                    TaxSummaryList: [],
                    PlaceOfSupply: "",
                    BillingAddress: null,
                    DeliveryCustomer: null,
                    DeliveryCustomerAddress: null,
                    DeliveryDate: "",
                    DeliveryTime: "",
                    deliveryNotes: "",
                    BackEndUpdate: false,
                    ReceivedPaymentAmount: null,
                    ConvertedFromTransactionBalanceAmount: null,
                    IsAppointmentInvoice: true,
                }
    
                let billQty = 0,
                    billSubTotal = 0,
                    billDiscount = 0,
                    billAmount = 0
    
                for (let i = 0; i < customerAppointments.length; i++) {
                    let lineItem = {};
    
                    const appointment = customerAppointments[i];
                    //console.log("appointment", appointment);
    
                    let service = this.props.config.Products.filter((f) => f.key === appointment.Service.value)[0];
                    // console.log("service", service);
    
                    let employee = this.props.config.ActiveEmployees.filter((e) => e.key === appointment.EmployeeID)[0];
                    employee.EmployeeName = (employee.EmployeeFirstName.trim() + ' ' + employee.EmployeeLastName.trim()).trim()
                    //console.log("employee", employee);
    
                    lineItem.RetailPrice = service.RetailPrice
                    lineItem.DiscountPer = 0;
                    lineItem.DiscountAmount = 0;
                    lineItem.Qty = 1;
                    lineItem.SubTotal = service.RetailPrice
                    lineItem.ChargeTaxOnProduct = false
                    lineItem.PriceBasedTax = false
                    lineItem.HSNSACCode = service.HSNSACCode
                    lineItem.TaxInclusive = service.TaxInclusive
                    lineItem.TaxGroup = null
                    lineItem.TaxID = ''
                    lineItem.SubTotalWithDiscount = 0
                    lineItem.TaxableAmount = 0
                    lineItem.TaxComponentAmount = []
                    lineItem.TotalTax = 0
                    lineItem.Amount = service.RetailPrice
                    lineItem.Product = service
                    lineItem.Employee = employee //{ key: appointment.EmployeeID, EmployeeFirstName: appointment.EmployeeName }
                    lineItem.Notes = ''
                    lineItem.AppointmentID = appointment.key
                    billQty = billQty + lineItem.Qty
                    billSubTotal = billSubTotal + lineItem.SubTotal
                    billDiscount = billDiscount + lineItem.DiscountAmount
                    billAmount = billAmount + lineItem.Amount
                    sale.LineItems.push(lineItem);
                }
    
                if (sale) {
                    sale.BillQty = billQty
                    sale.BillSubTotal = billSubTotal
                    sale.BillDiscount = billDiscount
                    sale.BillAmount = billAmount
                }
                // console.log("sale", sale);
    
                this.props.history.push({
                    pathname: '/home/sales/sales',
                    state: {
                        type: actionTypes.SALE_NEW,
                        key: "",
                        transactionType: transactionType,
                        sale: sale
                    }
                })
            }
        }
    };

    onCancellation(value) {
        if (value) {
            let object = this.state.selectedAppointment;
            this.setState({ loading: true, showCancelled: false })
            this.props.cancelAppointment('Appointment Cancelled', object.key).then(() => {
                setTimeout(function () {
                    this.props.getToastr("Appointment cancelled successfully");
                    this.loadAppointments(this.state.filterOptions[0].value, this.state.filterOptions[1].value, '', '');
                }.bind(this), 2000)
            })
        }
        else {
            this.setState({ showCancelled: false });
        }
    };

    render() {
        return <React.Fragment>
            {this.props.isMobileApp() && <HeaderNew {...this.props}
                back={true}
                filter={true}
                title="Appointments"
                onFilterClick={() => this.setState({ showFilter: !this.state.showFilter })}
                onBackClick={() => { this.props.history.push({ pathname: '/home' }) }}
            />}
            <div className="header-new-background"></div>

            <div className="content-wrapper" >
                <div className="container" style={{marginTop: "-60px"}}>

                    {this.state.loading && <Spinner />}
                    <div className="list">
                        <TabStrip tabButtons={this.state.tabButtons}
                            selectedTabIndex={this.state.selectedTabIndex}
                            onTabSelected={(index) => this.onTabSelected(index)}
                        />
                       <React.Fragment>
                            {this.state.appointments.filter((f)=> f.Status === this.state.selectedTab).map((appointment, index) => 
                            <div className="list-row seperator" onClick={() => this.onRowClick(appointment)}>
                                    <div>
                                        <div className="row-text">{(new Date(appointment.AppointmentDate.seconds * 1000)).toISOString().split('T')[0]}</div>
                                        <div className="row-text">{(appointment.Customer.FirstName.trim() + ' ' + appointment.Customer.LastName.trim()).trim()}
                                            {appointment.Customer.EmailID !== "" && <div>{appointment.Customer.EmailID}</div>}
                                            {appointment.Customer.PhoneNo !== "" && <div>{appointment.Customer.PhoneNo}</div>}
                                        </div>
                                        <div className="row-text"><i><b>Service:</b></i> {appointment.Service.label}</div>
                                        <div className="row-text"><i><b>Service By:</b></i> {appointment.EmployeeName}</div>
                                        <div className="row-text"><i><b>Slot:</b></i> {appointment.SlotStartDisplay + ' - ' + appointment.SlotEndDisplay}</div>
                                    </div>
                            </div>)}

                            {(this.state.appointments.filter((f) => f.Status === this.state.selectedTab).length === 0) && <div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <RecordNotFound title="Appointment not found" text="Please click + button to create new appointment" />
                            </div>}

                        </React.Fragment>
                    </div>
                </div>
            </div>
            <Confirmation title="Cancel Appointment" buttonText= "Cancel" show={this.state.showCancelled} onClick={(value) => this.onCancellation(value)} />

            <ActionButton
                onClick={(action) => this.onActionButtonClick(action)}
                fabButtons={this.state.fabButtons} />
            <PopupMenu
                show={this.state.showPopupMenu}
                buttons={this.state.popButtons}
                onClick={(action) => this.onShowPopupMenuClick(action)} />

            {this.state.showFilter && <Filter filterOptions={this.state.filterOptions}
                onCloseClick={()=>this.setState({ showFilter: false})}
                onApplyClick={(filterOptions) => {
                    this.loadAppointments(filterOptions[0].value, filterOptions[1].value, '', '')
                    this.setState({ showFilter: false, filterOptions: filterOptions })
                }}
                onClearClick={(filterOptions) => {
                    this.loadAppointments(filterOptions[0].value, filterOptions[1].value, '', '')
                    this.setState({ showFilter: false, filterOptions: filterOptions })
                }}
            />}
        </React.Fragment>
    };
}

export default AppointmentList

