import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';


class TopCustomers extends Component {
    constructor() {
        super()
        this.state = {
            size: 50,
            customers: [],
            searchStore: '',
            stores: [],
            loading: true,
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            customField1DisplayName:'Custom Field 1',
            customField2DisplayName:'Custom Field 2',
            customField3DisplayName:'Custom Field 3',
            customField4DisplayName:'Custom Field 4',
            customField5DisplayName:'Custom Field 5',
            customField6DisplayName:'Custom Field 6',
        }
        this.loadTopCustomers = this.loadTopCustomers.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    async componentDidMount() {
        await this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {
            if (stores.length > 1) {
                stores.unshift({ key: '', StoreCode: 'All', StoreName: 'Stores' })
            }
            this.setState({ stores: stores })
        });
        let storeList = []
        storeList.push({ key: this.props.user.store.key })
        this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer')
            {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName:customField1DisplayName,
                    customField2DisplayName:customField2DisplayName,
                    customField3DisplayName:customField3DisplayName,
                    customField4DisplayName:customField4DisplayName,
                    customField5DisplayName:customField5DisplayName,
                    customField6DisplayName:customField6DisplayName
                })
            }
        })

        this.loadTopCustomers(storeList, this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate, this.state.size);
    }

    async loadTopCustomers(searchStore, searchStartDate, searchEndDate, size) {
        this.setState({ loading: true })
        let topCustomersSpentList = await this.getTopSpentCustomers(searchStore, searchStartDate, searchEndDate, size);

        let customerIDs = [];
        for (let index = 0; index < topCustomersSpentList.length; index++) {
            const obj = topCustomersSpentList[index];
            customerIDs.push(obj.key);
        }
        let result = await this.props.getTopSpentCustomersByCustomerIDs(customerIDs, size);

        for (let j = 0; j < topCustomersSpentList.length; j++) {
            const topC = topCustomersSpentList[j];
            let customer = result.searchResult.filter((f) => f.key === topC.key)[0];

            topC.CustomerCode = customer.CustomerCode;
            topC.FirstName = customer.FirstName;
            topC.LastName = customer.LastName;
            topC.PhoneNo = customer.PhoneNo;
            topC.EmailID = customer.EmailID;
            topC.Gender = customer.Gender;
            topC.DOBNew = customer.DOBNew;
            topC.AnniversaryNew = customer.AnniversaryNew;
            topC.CreatedOn = customer.hasOwnProperty('CreatedOn') ? customer.CreatedOn : undefined;
            topC.LastVisitedOn = customer.hasOwnProperty('LastVisitedOn') ? customer.LastVisitedOn : undefined;
            topC[this.state.customField1DisplayName] = customer.CustomField1
            topC[this.state.customField2DisplayName] = customer.CustomField2
            topC[this.state.customField3DisplayName] = customer.CustomField3
            topC[this.state.customField4DisplayName] = customer.CustomField4
            topC[this.state.customField5DisplayName] = customer.CustomField5
            topC[this.state.customField6DisplayName] = customer.CustomField6
        }

        this.setState({ loading: false, customers: topCustomersSpentList })
    }

    getTopSpentCustomers(searchStore, searchTransactionFromDate, searchTransactionToDate, size) {
        return this.props.getTopSpentCustomers(searchStore, searchTransactionFromDate, searchTransactionToDate, size)
            .then((result) => {
                return result.searchResult
            })
    };

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            size: 50,
            storeList: storeList, searchTransactionFromDate: new Date().toISOString().split('T')[0], searchTransactionToDate: new Date().toISOString().split('T')[0]
        });

        this.loadTopCustomers(storeList, new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], 50);
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadTopCustomers(this.state.storeList, this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate, this.state.size);
    }


    async onExportClick() {
        this.setState({ loading: true });

        let topCustomersSpentList = await this.getTopSpentCustomers(this.state.storeList, this.state.searchTransactionFromDate, this.state.searchTransactionToDate, this.state.size);

        let customerIDs = [];
        for (let index = 0; index < topCustomersSpentList.length; index++) {
            const obj = topCustomersSpentList[index];
            customerIDs.push(obj.key);
        }
        let result = await this.props.getTopSpentCustomersByCustomerIDs(customerIDs, this.state.size);

        for (let j = 0; j < topCustomersSpentList.length; j++) {
            const topC = topCustomersSpentList[j];
            let customer = result.searchResult.filter((f) => f.key === topC.key)[0];

            topC.CustomerCode = customer.CustomerCode;
            topC.FirstName = customer.FirstName;
            topC.LastName = customer.LastName;
            topC.PhoneNo = customer.PhoneNo;
            topC.EmailID = customer.EmailID;
            topC.Gender = customer.Gender;
            topC.DOBNew = customer.DOBNew;
            topC.AnniversaryNew = customer.AnniversaryNew;
            topC.CreatedOn = customer.hasOwnProperty('CreatedOn') ? customer.CreatedOn : undefined;
            topC.LastVisitedOn = customer.hasOwnProperty('LastVisitedOn') ? customer.LastVisitedOn : undefined;
            topC.StoreID = customer.StoreID
            topC[this.state.customField1DisplayName] = customer.CustomField1
            topC[this.state.customField2DisplayName] = customer.CustomField2
            topC[this.state.customField3DisplayName] = customer.CustomField3
            topC[this.state.customField4DisplayName] = customer.CustomField4
            topC[this.state.customField5DisplayName] = customer.CustomField5
            topC[this.state.customField6DisplayName] = customer.CustomField6
        }

        let finalCustomers = []
        topCustomersSpentList.map((data) => {
            let customer = {
                "Customer Code": data.CustomerCode,
                "Customer Name": (data.FirstName.trim() + ' ' + data.LastName.trim()).trim(),
                "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                "Date of Birth": "",
                "Aniversary Date": "",
                "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
            }
            if (data.DOBNew) {
                if (data.DOBNew.hasOwnProperty('Day') &&
                    data.DOBNew.hasOwnProperty('Month') &&
                    data.DOBNew.Day !== "" &&
                    data.DOBNew.Month !== "") {
                    customer["Date of Birth"] = data.DOBNew.Day + "/" + data.DOBNew.Month + (data.DOBNew.hasOwnProperty('Year') && data.DOBNew.Year !== "" ? "/" + data.DOBNew.Year : "")
                }
            }
            if (data.AnniversaryNew) {
                if (data.AnniversaryNew.hasOwnProperty('Day') &&
                    data.AnniversaryNew.hasOwnProperty('Month') &&
                    data.AnniversaryNew.Day !== "" &&
                    data.AnniversaryNew.Month !== "") {
                    customer["Aniversary Date"] = data.AnniversaryNew.Day + "/" + data.AnniversaryNew.Month + (data.AnniversaryNew.hasOwnProperty('Year') && data.AnniversaryNew.Year !== "" ? "/" + data.AnniversaryNew.Year : "")
                }
            }
            customer["Total Spent"] = data.TotalSpent;
            customer["Total Transactions"] = data.TotalTransactions;
            customer["Created On"] = (data.hasOwnProperty('CreatedOn') && data.CreatedOn) ? (new Date(data.CreatedOn).toISOString().split('T')[0]) : ""
            customer["Last Visited On"] = (data.hasOwnProperty('LastVisitedOn') && data.LastVisitedOn) ? (new Date(data.LastVisitedOn).toISOString().split('T')[0]) : ""
            customer["Created At"] = this.state.stores.filter((s) => s.key === data.StoreID)[0].StoreName
            customer[this.state.customField1DisplayName] = data[this.state.customField1DisplayName]
            customer[this.state.customField2DisplayName] = data[this.state.customField2DisplayName]
            customer[this.state.customField3DisplayName] = data[this.state.customField3DisplayName]
            customer[this.state.customField4DisplayName] = data[this.state.customField4DisplayName]
            customer[this.state.customField5DisplayName] = data[this.state.customField5DisplayName]
            customer[this.state.customField6DisplayName] = data[this.state.customField6DisplayName]
            finalCustomers.push(customer);
        });

        const csvData = constatnts.objToCSV(finalCustomers);
        this.setState({ loading: false, exportCalled: false })
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "customers.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e) {
        let storeList = []
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Top N Customers</font>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Count</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.size}
                                                onChange={(evt) => this.setState({ size: Number(evt.target.value) })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{paddingBottom:"10px", paddingRight:"10px"}}>
                                    <div  className="col-md-12  col-xs-12">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" 
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" 
                                            onClick={this.onExportClick} >Export <i className="fa fa-download" ></i></button>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <div style={{ width: "auto", overflow: "auto", height: "80vh" }} className="scroll2">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Customer Code</th>
                                                    <th>Customer Name</th>
                                                    <th>Phone No</th>
                                                    <th>Email ID</th>
                                                    <th>Gender</th>
                                                    <th>Date of Birth</th>
                                                    <th>Aniversary Date</th>
                                                    <th>Membership</th>
                                                    <th>Amount Spent</th>
                                                    <th>Transaction Count</th>
                                                    <th>Created On</th>
                                                    <th>Last Visited On</th>
                                                    <th>{this.state.customField1DisplayName}</th>
                                                    <th>{this.state.customField2DisplayName}</th>
                                                    <th>{this.state.customField3DisplayName}</th>
                                                    <th>{this.state.customField4DisplayName}</th>
                                                    <th>{this.state.customField5DisplayName}</th>
                                                    <th>{this.state.customField6DisplayName}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.customers.map((customer) =>
                                                    <tr>
                                                        <td>{customer.CustomerCode}</td>
                                                        <td>{customer.FirstName + ' ' + customer.LastName}</td>
                                                        <td>{customer.PhoneNo}</td>
                                                        <td>{customer.EmailID}</td>
                                                        <td>{customer.Gender}</td>
                                                        <td>
                                                            {customer.DOBNew && customer.DOBNew.hasOwnProperty('Day') && customer.DOBNew.hasOwnProperty('Month')
                                                                && customer.DOBNew.Day !== "" && customer.DOBNew.Month !== "" ?
                                                                customer.DOBNew.Day + "/" + customer.DOBNew.Month + (customer.DOBNew.hasOwnProperty('Year') && customer.DOBNew.Year !== "" ? "/" + customer.DOBNew.Year : "") : ""
                                                            }</td>
                                                        <td>
                                                            {customer.AnniversaryNew && customer.AnniversaryNew.hasOwnProperty('Day') && customer.AnniversaryNew.hasOwnProperty('Month')
                                                                && customer.AnniversaryNew.Day !== "" && customer.AnniversaryNew.Month !== "" ?
                                                                customer.AnniversaryNew.Day + "/" + customer.AnniversaryNew.Month + (customer.AnniversaryNew.hasOwnProperty('Year') && customer.AnniversaryNew.Year !== "" ? "/" + customer.AnniversaryNew.Year : "") : ""
                                                            }</td>
                                                        <td>{customer.Membership && customer.Membership.hasOwnProperty('MembershipID') && customer.Membership.MembershipID !== "" &&
                                                            <div>Membership: {customer.Membership.Name} </div>
                                                        }</td>
                                                        <td>{customer.TotalSpent}</td>
                                                        <td>{customer.TotalTransactions}</td>
                                                        <td>{(customer.hasOwnProperty('CreatedOn') && customer.CreatedOn) ? (new Date(customer.CreatedOn).toISOString().split('T')[0]) : ""}</td>
                                                        <td>{(customer.hasOwnProperty('LastVisitedOn') && customer.LastVisitedOn) ? (new Date(customer.LastVisitedOn).toISOString().split('T')[0]) : ""}</td>
                                                        <td>{customer[this.state.customField1DisplayName]}</td>
                                                        <td>{customer[this.state.customField2DisplayName]}</td>
                                                        <td>{customer[this.state.customField3DisplayName]}</td>
                                                        <td>{customer[this.state.customField4DisplayName]}</td>
                                                        <td>{customer[this.state.customField5DisplayName]}</td>
                                                        <td>{customer[this.state.customField6DisplayName]}</td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(TopCustomers)
