import React, { Component } from "react";
import { Route } from "react-router-dom";
import ReactGA from "react-ga";
import Login from "./Login";
import Home from "./Home";
import ListPage from "./2/ListPage";
import Header from "./2/Header";
import "./2/style.css";
import Posts from "./Post/Posts";
import Post from "./Post/Post";
// import Register from './Register'
import Registration from "./Settings/Registration";
import AuthenticatedComponent from "./AuthenticatedComponent";
import Product from "./Catalogue/Product";
import ProductQuickEdit from "./Catalogue/ProductQuickEdit";
import PincodeProductQuickEdit from "./Pincode/PincodeProductQuickEdit";
import ProductImageUpload from "./Catalogue/ProductImageUpload";
import FoodDeliveryPlatformProducts from "./Catalogue/FoodDeliveryPlatformProducts";
import Customer from "./CRM/Customer";
import Sales from "./Sales/Sales";
import SalesIntermediate from "./Sales/SalesIntermediate";
import CashRegisterClosing from "./Sales/CashRegisterClosing";
import ReceivePayment from "./Sales/ReceivePayment";
import DeliveryList from "./Sales/DeliveryList";
import DeliveryListNew from "./Sales/DeliveryListNew";
import InventoryIntermediate from "./Inventory/InventoryIntermediate";
import Inventory from "./Inventory/Inventory";
import ViewInventory from "./Inventory/ViewInventory";
import ViewSales from "./Sales/ViewSales";
import LocationList from "./Settings/LocationList";
import AddEditStore from "./Settings/AddEditStore";
import ViewStoreDetails from "./Settings/ViewStoreDetails";
import AddEditEmployee from "./Settings/Employee/AddEditEmployee";
import ViewEmployeeDetails from "./Settings/Employee/ViewEmployeeDetails";
import CustomFields from "./Settings/CustomFields";
import PrintConfigList from "./Settings/PrintConfigList";
import PrintConfigListNew from "./Settings/PrintConfigListNew";
import PrintConfig from "./Settings/PrintConfig";
import AddEditVendor from "./Settings/Vendor/AddEditVendor";
import CustomerReferenceDetail from "./Reports/CustomerReferenceDetail";
import CustomerDashboard from "./Reports/CustomerDashboard";
import SalesDashboard from "./Reports/SalesDashboard";
import Dashboard from "./Reports/Dashboard";
import DashboardSettings from "./Reports/DashboardSettings";
import BillWiseDetail from "./Reports/BillWiseDetail";
import ItemSoldReport from "./Reports/ItemSoldReport";
import InvoiceDetail from "./Reports/InvoiceDetail";
import EmployeeItemReport from "./Reports/EmployeeItemReport";
import CategoryWiseDetail from "./Reports/CategoryWiseDetail";
import BrandWiseDetail from "./Reports/BrandWiseDetail";
import SubCategoryWiseDetail from "./Reports/SubCategoryWiseDetail";
import ProductTypeWise from "./Reports/ProductTypeWise";
import AllReport from "./Reports/AllReport";
import EmployeeDashboard from "./Reports/EmployeeDashboard";
import ExpenseListReport from "./Reports/ExpenseListReport";
import ExpenseByExpenseCategoryReport from "./Reports/ExpenseByExpenseCategoryReport";
import ExpenseByPartyReport from "./Reports/ExpenseByParty";
import Instock from "./Reports/InStockReport";
import ItemLedger from "./Reports/ItemLedger";
import DailySalesReport from "./Reports/DailySalesReport";
import MonthlySalesReport from "./Reports/MonthlySalesReport";
import PaymentModeWise from "./Reports/PaymentModeWise";
import EmployeeWorkSheet from "./Reports/EmployeeWorkSheet";
import PaymentModeTransactionTypeReport from "./Reports/PaymentModeTransactionTypeReport";
import InvoiceDetail1 from "./Reports/InvoiceDetail1";
import AllInventoryReport from "./Reports/AllInventoryReport";
import CustomerWalletLedgerReport from "./Reports/CustomerWalletLedgerReport";
import CustomerOutstandingLedgerReport from "./Reports/CustomerOutstandingLedgerReport";
import WalletLedger from "./Reports/WalletLedger";
import OutStandingLedger from "./Reports/OutStandingLedger";
import UniqueNoReport from "./Reports/UniqueNoReport";
import UniqueNoLedger from "./Reports/UniqueNoLedger";
import SalesTransactionView from "./Public/SalesTransactionView";
import EditPackage from "./Packages/EditPackage";
import BarcodePrintSettings from "./Settings/BarcodePrintSettings";
import BarcodePrint from "./Inventory/BarcodePrint";
import AddTransaction from "./Account/AddTransaction";
import VendorLedger from "./Reports/VendorLedger";
import VendorOutstanding from "./Reports/VendorOutstanding";
import LoyaltySetup from "./Loyalty/LoyaltySetup";
import AddEditLoyalty from "./Loyalty/AddEditLoyaltyType";
import MembershipExpiry from "./Reports/MembershipExpiry";
import CustomerReferralProgram from "./Referral/CustomerReferralProgram";
import CaptureCustomerReferral from "./Referral/CaptureCustomerReferral";
import Survey from "./Survey/Survey";
import TopCustomers from "./Reports/TopCustomers";
import LoyaltyByCustomer from "./Reports/LoyaltyByCustomer";
import LoyaltyLedger from "./Reports/LoyaltyLedger";
import LoyaltyDashboard from "./Loyalty/LoyaltyDashboard";
import InventoryHistory from "./Reports/InventoryHistory";
import ProductOrderReport from "./Reports/ProductOrderReport";
import CashRegisterSetup from "./Settings/CashRegisterSetup";
import AppointmentView from "./Appointment/AppointmentView";
import AppointmentList from "./Appointment/AppointmentList";
import CreateUpdateAppointment from "./Appointment/CreateUpdateAppointmentNew";
import CreateAppointmentBooking from "./Appointment/CreateAppointmentBooking";
import GSTR2Report from "./Reports/GSTR2Report";
import MembershipDashboard from "./Reports/MembershipDashboard";
import MinQtyReport from "./Reports/MinQtyReport";
import MaxQtyReport from "./Reports/MaxQtyReport";
import ProductConsumption from "./Sales/ProductConsumption";
import ParkedTransaction from "./Common/ParkedTransaction";
import ProfitLossReport from "./Reports/ProfitLossReport";
import InvoiceDetail2 from "./Reports/InvoiceDetail2";
import GSTR1B2B from "./Reports/GSTR1B2B";
import GSTR1B2C from "./Reports/GSTR1B2C";
import GSTR1TransactionWise from "./Reports/GSTR1TransactionWise";
import FoodDeliveryOrders from "./Sales/FoodDeliveryOrders";
import ViewIntransit from "./Sales/ViewIntransit";
import CampaignsToRun from "./CRM/CampaignsToRun";
import CampaignToRunCustomers from "./CRM/CampaignToRunCustomers";
import GenericRedirectPage from "./Common/GenericRedirectPage";
import ViewBusinessCard from "./CRM/ViewBusinessCard";
import EditBusinessCard from "./CRM/EditBusinessCard";
import CashRegisterList from "./Settings/CashRegisterList";
import CashRegisterPrinterSettings from "./Settings/CashRegisterPrinterSettings";
import CashRegisterPaymentProvider from "./Settings/CashRegisterPaymentProvider";
import CashRegisterPaymentProviderNewEdit from "./Settings/CashRegisterPaymentProviderNewEdit";
import ViewImageTemplates from "./CRM/ViewImageTemplates";
import EditImageTemplate from "./CRM/EditImageTemplate";
import ViewInquiries from "./Omni/OmniInquiries";
import OfferReport from "./Reports/OfferReport";
import OfferSummaryReport from "./Reports/OfferSummaryReport";
import OnBoarding from "./Settings/OnBoarding";
import AttendanceReport from "./Reports/AttendanceReport";
import KDS from "./KDS/KDS";
import KOTCustomerDisplay from "./KDS/KOTCustomerDisplay";
import BatchExpiryReport from "./Reports/BatchExpiryReport";
import OmniPayment from "./Omni/OmniPayment";
import OmniProfile from "./Omni/OmniProfile";
import OmniProductDetailsPage from "./Omni/OmniProductDetailsPage";
import BulkPurchaseReturn from "./Inventory/BulkPurchaseReturn";
// import OmniShippingList from './Omni/OmniShippingList';
// import OmniShippingEdit from './Omni/OmniShippingEdit';
import OmniSettings from "./Omni/OmniSettings";
import CustomerDisplay from "./CustomerDisplay/CustomerDisplay";
import CustomerDisplaySettings from "./CustomerDisplay/CustomerDisplaySettings";
import TableOrder from "./TableOrder/TableOrder";
import Tables from "./Settings/Tables";
import TablesNew from "./Settings/TablesNew";
import HSNWiseReport from "./Reports/HSNWiseReport";
import ForgotPassword from "./ForgotPassword";
import OmniHomeSectionEdit from "./Omni/OmniHomeSectionEdit";
import OmniTransactionCheck from "./Omni/OmniTransactionCheck";
import RESettings from "./Settings/RESettings";
import AddRegistrationSettings from "./Settings/AddRegistrationSettings";
import OmniMenu from "./Omni/OmniMenu";
import EmployeeIncentiveReport from "./Reports/EmployeeIncentiveReport";

import InventoryPurchaseSalesInStock from "./Reports/InventoryPurchaseSalesInStock";
import CategoryWiseTransactionReport from "./Reports/CategoryWiseTransactionReport";
import RI from "./RI";
import PinCodeSignUp from "./Pincode/PincodeSignUp";
import MissingPopularItems from "./Pincode/MissingPopularItems";
import TransactionReport from "./Reports/TransactionReport";
import ProductExport from "./Reports/ProductExport";
import PincodeOrderReport from "./Reports/PincodeOrderReport";
import PincodeItemReport from "./Reports/PincodeItemReport";
import PincodeCustomerReport from "./Reports/PincodeCustomerReport";
import PinCodeSignUpNew from "./Pincode/PincodeSignUpNew";
import OnlineOrdersInfinityApp from "./Sales/OnlineOrdersInfinityApp";
import PincodeIntegrationSettings from "./Pincode/PincodeIntegrationSettings";

class Main extends Component {
  constructor() {
    super();
    ReactGA.initialize("UA-135749742-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div>
        <Route
          exact
          path="/"
          render={({ history }) => (
            <div>
              <Login {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/ri"
          render={({ history }) => (
            <div>
              <RI {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/forgotpassword"
          render={({ history }) => (
            <div>
              <ForgotPassword {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/excise"
          render={({ history }) => (
            <div>
              <RESettings {...this.props} onHistory={history} />
            </div>
          )}
        />
        <Route
          exact
          path="/salestransactionview/:key"
          render={(params) => (
            <div>
              <SalesTransactionView {...this.props} {...params} />
            </div>
          )}
        />
        <Route
          exact
          path="/s/:key"
          render={(params) => (
            <div>
              <SalesTransactionView {...this.props} {...params} />
            </div>
          )}
        />
        <Route
          exact
          path="/r/:key"
          render={(params) => (
            <div>
              <CaptureCustomerReferral {...this.props} {...params} />
            </div>
          )}
        />
        <Route
          exact
          path="/customerdisplay"
          render={(params) => (
            <div>
              <CustomerDisplay {...this.props} {...params} />
            </div>
          )}
        />
        <Route
          exact
          path="/pincodesignup"
          render={(history) => (
            <div>
              <PinCodeSignUpNew {...this.props} />
            </div>
          )}
        />
        {/* <Route
          exact
          path="/signup"
          render={(history) => (
            <div>
              <PinCodeSignUp {...this.props} />
            </div>
          )}
        /> */}

        <AuthenticatedComponent {...this.props}>
          {this.props.user.store && <Header {...this.props} />}
          {!this.props.isMobileApp() && (
            <Route
              exact
              path="/home/settings/tables"
              render={(params) => (
                <div>
                  <Tables {...this.props} {...params} />
                </div>
              )}
            />
          )}
          {this.props.isMobileApp() && (
            <Route
              exact
              path="/home/settings/tables"
              render={(params) => (
                <div>
                  <TablesNew {...this.props} {...params} />
                </div>
              )}
            />
          )}
          <Route
            exact
            path="/home/reports/categorywisetransaction"
            render={(params) => (
              <CategoryWiseTransactionReport {...this.props} {...params} />
            )}
          />
          <Route
            exact
            path="/home/reports/productexport"
            render={(params) => <ProductExport {...this.props} {...params} />}
          />
          <Route
            exact
            path="/home/reports/employeeincentive"
            render={(params) => (
              <div>
                <EmployeeIncentiveReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/onlineorders"
            render={(params) => (
              <div>
                <OmniTransactionCheck {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/appolineorders"
            render={(params) => (
              <div>
                <OnlineOrdersInfinityApp {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/tables/new"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/tables/edit"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/tableorder"
            render={(params) => (
              <div>
                <TableOrder {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/settings"
            render={(params) => (
              <div>
                <OmniSettings {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/missingpopularproducts"
            render={(params) => (
              <div>
                <MissingPopularItems {...this.props} {...params} />
              </div>
            )}
          />

          <Route
            exact
            path="/home/product/pincodequickedit/"
            render={(params) => (
              <div>
                <PincodeProductQuickEdit {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/product/quickedit/"
            render={(params) => (
              <div>
                <ProductQuickEdit {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/pendingapprovals/"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="pendingapprovals" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/onlinepaymenttransactions"
            render={(params) => (
              <div>
                <ListPage
                  {...this.props}
                  {...params}
                  type="onlinepaymenttransactions"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/homesections/"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="omnihomesections" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/homesections/edit/:key"
            render={(params) => (
              <div>
                <OmniHomeSectionEdit {...this.props} {...params} />{" "}
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/homesections/new"
            render={(params) => (
              <div>
                <OmniHomeSectionEdit {...this.props} {...params} />{" "}
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/bulkpurchasereturn"
            render={(params) => (
              <div>
                <BulkPurchaseReturn {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/productdetail"
            render={(params) => (
              <div>
                <OmniProductDetailsPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/profile"
            render={(params) => (
              <div>
                <OmniProfile {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/payment"
            render={(params) => (
              <div>
                <OmniPayment {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/batchexpiryreport"
            render={(params) => (
              <div>
                <BatchExpiryReport {...this.props} {...params} />
              </div>
            )}
          />

          <Route
            exact
            path="/home/KDS"
            render={(params) => (
              <div>
                <KDS {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/kotcustomerdisplay"
            render={(params) => (
              <div>
                <KOTCustomerDisplay {...this.props} {...params} />
              </div>
            )}
          />

          <Route
            exact
            path="/home/reports/attendancereport"
            render={(params) => (
              <div>
                <AttendanceReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/registration/additionalsettings"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/offers"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="offers" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/pages"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="omnipages" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/couponList"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="coupons" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/menus"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="omnimenus" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/menu/new"
            render={(params) => (
              <div>
                <OmniMenu {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/menu/edit/:key"
            render={(params) => (
              <div>
                <OmniMenu {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/offer"
            render={(params) => (
              <div>
                <OfferReport {...this.props} {...params} type="offer" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/coupon"
            render={(params) => (
              <div>
                <OfferReport {...this.props} {...params} type="coupon" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/offersummary"
            render={(params) => (
              <div>
                <OfferSummaryReport {...this.props} {...params} type="offer" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/couponsummary"
            render={(params) => (
              <div>
                <OfferSummaryReport {...this.props} {...params} type="coupon" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/vendors/"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="vendor" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/messagetemplates"
            render={() => (
              <div>
                <ViewImageTemplates {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/messagetemplate/edit/"
            render={(params) => (
              <div>
                <EditImageTemplate {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/customroles"
            render={() => (
              <div>
                <ListPage {...this.props} type="customroles" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/onlineorders"
            render={(params) => (
              <div>
                <FoodDeliveryOrders {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/intransit"
            render={(params) => (
              <div>
                <ViewIntransit {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/slider"
            render={() => (
              <div>
                <ListPage {...this.props} type="omnisliders" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/omni/inquiries"
            render={(params) => (
              <div>
                <ViewInquiries {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/crpaymentprovidersnewedit"
            render={(params) => (
              <div>
                <CashRegisterPaymentProviderNewEdit
                  {...this.props}
                  {...params}
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/crpaymentproviders"
            render={(params) => (
              <div>
                <CashRegisterPaymentProvider {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/crsettings"
            render={(params) => (
              <div>
                <CashRegisterPrinterSettings {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/crlist"
            render={(params) => (
              <div>
                <CashRegisterList {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/crnew"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/credit"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/businesscard"
            render={() => (
              <div>
                <ViewBusinessCard {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/businesscard/edit"
            render={(params) => (
              <div>
                <EditBusinessCard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/fooddeliveryplatformproducts"
            render={(params) => (
              <div>
                <FoodDeliveryPlatformProducts {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/invoicedetail2"
            render={(params) => (
              <div>
                <InvoiceDetail2 {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/gstr1transactionwise"
            render={(params) => (
              <div>
                <GSTR1TransactionWise {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/gstr1b2b"
            render={(params) => (
              <div>
                <GSTR1B2B {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/gstr1b2c"
            render={(params) => (
              <div>
                <GSTR1B2C {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/hsnreport"
            render={(params) => (
              <div>
                <HSNWiseReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/profitlossreport"
            render={(params) => (
              <div>
                <ProfitLossReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/parkedtransactions/"
            render={(params) => (
              <div>
                <ParkedTransaction {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/consumption"
            render={(params) => (
              <div>
                <ProductConsumption {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/minqtyreport"
            render={(params) => (
              <div>
                <MinQtyReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/maxqtyreport"
            render={(params) => (
              <div>
                <MaxQtyReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/membershipdashboard"
            render={(params) => (
              <div>
                <MembershipDashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/inventoryhistory"
            render={(params) => (
              <div>
                <InventoryHistory {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/productorderreport"
            render={(params) => (
              <div>
                <ProductOrderReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/loyaltydashboard"
            render={(params) => (
              <div>
                <LoyaltyDashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/loyaltycustomer"
            render={(params) => (
              <div>
                <LoyaltyByCustomer {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/loyaltyledger"
            render={(params) => (
              <div>
                <LoyaltyLedger {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/topcustomers"
            render={(params) => (
              <div>
                <TopCustomers {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/transactionalsmstemplates/"
            render={(params) => (
              <div>
                <ListPage
                  {...this.props}
                  {...params}
                  type="transactionalsmstemplates"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/promotionalsmstemplates/"
            render={(params) => (
              <div>
                <ListPage
                  {...this.props}
                  {...params}
                  type="promotionalsmstemplates"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/cashregister/setup/"
            render={({ history }) => (
              <div>
                <CashRegisterSetup {...this.props} onHistory={history} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/gstr2"
            render={(params) => (
              <div>
                <GSTR2Report {...this.props} {...params} />
              </div>
            )}
          />
          {!this.props.isMobileApp() && (
            <Route
              exact
              path="/home/survey/"
              render={(params) => (
                <div>
                  <ListPage {...this.props} {...params} type="survey" />
                </div>
              )}
            />
          )}
          <Route
            exact
            path="/home/survey/new"
            render={(params) => (
              <div>
                <Survey {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/survey/edit/:key"
            render={(params) => (
              <div>
                <Survey {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/loyalty/loyaltytype"
            render={({ history }) => (
              <ListPage
                {...this.props}
                onHistory={history}
                type="loyaltytype"
              />
            )}
          />
          <Route
            exact
            path="/home/loyalty/new"
            render={() => (
              <div>
                <AddEditLoyalty {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/loyalty/edit/:key"
            render={(params) => (
              <div>
                <AddEditLoyalty {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/loyalty/setup"
            render={(params) => (
              <div>
                <LoyaltySetup {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/appointment"
            render={(params) => (
              <div>
                <AppointmentView {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/appointments"
            render={(params) => (
              <div>
                <AppointmentList {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/appointment/create"
            render={(params) => (
              <div>
                <CreateUpdateAppointment {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/appointment/edit"
            render={(params) => (
              <div>
                <CreateUpdateAppointment {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/appointment/book"
            render={(params) => (
              <div>
                <CreateAppointmentBooking {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home"
            render={({ history }) => (
              <Home {...this.props} onHistory={history} />
            )}
          />
          <Route
            exact
            path="/home/post"
            render={({ history }) => (
              <Posts {...this.props} onHistory={history} />
            )}
          />
          <Route
            exact
            path="/home/post/newpost"
            render={({ history }) => (
              <Post {...this.props} onHistory={history} />
            )}
          />
          <Route
            exact
            path="/home/settings/registration"
            render={() => <Registration {...this.props} />}
          />

          <Route
            exact
            path="/home/itemnotfoundinmastercatalogue"
            render={() => (
              <div>
                <ListPage
                  {...this.props}
                  type="itemnotfoundinmastercatalogue"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/complaints"
            render={() => (
              <div>
                <ListPage {...this.props} type="complaints" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/product"
            render={() => (
              <div>
                <ListPage {...this.props} type="product" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/products"
            render={() => (
              <div>
                <ListPage {...this.props} type="products" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/product/newproduct"
            render={() => (
              <div>
                <Product {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/product/edit/:key"
            render={(params) => (
              <div>
                <Product {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/tags"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="productgroup" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/offer"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="offers" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/modifiers"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="modifier" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/customer/newcustomer"
            render={() => (
              <div>
                <Customer {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/customer"
            render={() => (
              <div>
                <ListPage {...this.props} type="customer" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/m/customer/"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/customer/edit/:key"
            render={(params) => (
              <div>
                <Customer {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/customer/customerdashboard/:key"
            render={(params) => (
              <div>
                <CustomerDashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/campaign"
            render={() => (
              <div>
                <ListPage {...this.props} type="campaign" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/coupons"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="coupons" />
              </div>
            )}
          />

          <Route
            exact
            path="/home/reports/inventorysalespurchaseinstock"
            render={(params) => (
              <div>
                <InventoryPurchaseSalesInStock {...this.props} {...params} />
              </div>
            )}
          />

          <Route
            exact
            path="/home/reports/membershipexpiry"
            render={(params) => (
              <div>
                <MembershipExpiry {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/newsales"
            render={(params) => (
              <div>
                <Sales {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/sales"
            render={(params) => (
              <div>
                <SalesIntermediate {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/receivepayment"
            render={(params) => (
              <div>
                <ReceivePayment {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/packages/packagelist"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="packagelist" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/package/edit/:key"
            render={(params) => (
              <div>
                <EditPackage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/saleslist"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="saleshistory" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/paybill"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="paybill" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/inventory"
            render={(params) => (
              <div>
                <InventoryIntermediate {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/newinventory"
            render={(params) => (
              <div>
                <Inventory {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/inventorylist"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="inventorylist" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/intransit"
            render={(params) => (
              <div>
                <ListPage
                  {...this.props}
                  {...params}
                  type="inventoryintransit"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/viewinventory/:key"
            render={(params) => (
              <div>
                <ViewInventory {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/inventory/editinventory"
            render={(params) => (
              <div>
                <Inventory {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/viewsales/:key"
            render={(params) => (
              <div>
                <ViewSales {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/editsales/"
            render={(params) => (
              <div>
                <Sales {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/salesreturn/"
            render={(params) => (
              <div>
                <Sales {...this.props} {...params} />
              </div>
            )}
          />
          {/* {this.props.isMobileApp() && <Route path='/home/sales/deliverylist' render={(params) => (<div><DeliveryListNew {...this.props} {...params} /></div>)} />}
                {!this.props.isMobileApp() &&  */}
          <Route
            path="/home/sales/deliverylist"
            render={(params) => (
              <div>
                <DeliveryList {...this.props} {...params} />
              </div>
            )}
          />
          {/* } */}
          <Route
            exact
            path="/home/sales/delivery/outfordelivery"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/cashregisterclosing/"
            render={(params) => (
              <div>
                <CashRegisterClosing {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/sales/cashregisterclosinglist/"
            render={(params) => (
              <div>
                <ListPage
                  {...this.props}
                  {...params}
                  type="cashregisterclosinglist"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="store" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/locations/"
            render={(params) => (
              <div>
                <LocationList {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/locations/edit"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/newstore/"
            render={(params) => (
              <div>
                <AddEditStore {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/editstore/:key"
            render={(params) => (
              <div>
                <AddEditStore {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/viewstoredetails/:key"
            render={(params) => (
              <div>
                <ViewStoreDetails {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="employeelist" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/mapping/"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/assignaccess/"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/changeaccess/"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/newemployee"
            render={(params) => (
              <div>
                <AddEditEmployee {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/editemployee"
            render={(params) => (
              <div>
                <AddEditEmployee {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/employees/viewemployeedetails"
            render={(params) => (
              <div>
                <ViewEmployeeDetails {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/customfields"
            render={(params) => (
              <div>
                <CustomFields {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/paymenttypes"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="paymenttypes" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/payments/new"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/payments/edit"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/brands/edit"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/brands/new"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/brands"
            render={() => (
              <div>
                <ListPage {...this.props} type="brand" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/taxes"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="tax" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/taxes/edit"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/taxes/new"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/category"
            render={() => (
              <div>
                <ListPage {...this.props} type="category" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/subcategory"
            render={() => (
              <div>
                <ListPage {...this.props} type="subcategory" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/editcategory"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          {!this.props.isMobileApp() && (
            <Route
              exact
              path="/home/settings/stores/printconfig"
              render={(params) => (
                <div>
                  <PrintConfigList {...this.props} {...params} />
                </div>
              )}
            />
          )}
          {this.props.isMobileApp() && (
            <Route
              exact
              path="/home/settings/stores/printconfig"
              render={(params) => (
                <div>
                  <PrintConfigListNew {...this.props} {...params} />
                </div>
              )}
            />
          )}
          <Route
            exact
            path="/home/settings/stores/printconfig/newprintconfig"
            render={(params) => (
              <div>
                <PrintConfig {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/printconfig/editprintconfig"
            render={(params) => (
              <div>
                <PrintConfig {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/addtionalsettings"
            render={(params) => (
              <div>
                <AddRegistrationSettings {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/pincodeintegrationsettings"
            render={(params) => (
              <div>
                <PincodeIntegrationSettings {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/moduleconfig"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="moduleconfig" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/vendors/newvendor"
            render={(params) => (
              <div>
                <AddEditVendor {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/vendors/edit/:key"
            render={(params) => (
              <div>
                <AddEditVendor {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expenselist"
            render={() => (
              <div>
                <ListPage {...this.props} type="expense" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expensecategorieslist"
            render={() => (
              <div>
                <ListPage {...this.props} type="expensecategory" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expense/expensecategory/"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/salesdashboard"
            render={(params) => (
              <div>
                <SalesDashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/dashboard"
            render={(params) => (
              <div>
                <Dashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/dashboardsettings"
            render={(params) => (
              <div>
                <DashboardSettings {...this.props} {...params} />
              </div>
            )}
          />

          <Route
            exact
            path="/home/reports/sales/pincode/itemreport"
            render={(params) => (
              <div>
                <PincodeItemReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/sales/pincode/orderreport"
            render={(params) => (
              <div>
                <PincodeOrderReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/sales/pincode/customerreport"
            render={(params) => (
              <div>
                <PincodeCustomerReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/sales/transaction"
            render={(params) => (
              <div>
                <TransactionReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/sales/transactiondetail"
            render={(params) => (
              <div>
                <InvoiceDetail {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/itemsolddetail"
            render={(params) => (
              <div>
                <ItemSoldReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/employeeitemdetail"
            render={(params) => (
              <div>
                <EmployeeItemReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/categorywise"
            render={(params) => (
              <div>
                <CategoryWiseDetail {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/brandwise"
            render={(params) => (
              <div>
                <BrandWiseDetail {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/subcategorywise"
            render={(params) => (
              <div>
                <SubCategoryWiseDetail {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/producttypewise"
            render={(params) => (
              <div>
                <ProductTypeWise {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/allreport"
            render={(params) => (
              <div>
                <AllReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/employeedashboard"
            render={(params) => (
              <div>
                <EmployeeDashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/customereferencedetail"
            render={(params) => (
              <div>
                <CustomerReferenceDetail {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/membership"
            render={() => (
              <div>
                <ListPage {...this.props} type="membership" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/saleschannel"
            render={() => (
              <div>
                <ListPage {...this.props} type="saleschannel" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/attendancelist"
            render={() => (
              <div>
                <ListPage {...this.props} type="attendance" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/expenseListreport"
            render={(params) => (
              <div>
                <ExpenseListReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/expenseByExpenseCategoryreport"
            render={(params) => (
              <div>
                <ExpenseByExpenseCategoryReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/expenseByPartyreport"
            render={(params) => (
              <div>
                <ExpenseByPartyReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/Instock"
            render={(params) => (
              <div>
                <Instock {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/ItemLedger"
            render={(params) => (
              <div>
                <ItemLedger {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/dailysales"
            render={(params) => (
              <div>
                <DailySalesReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/monthlysales"
            render={(params) => (
              <div>
                <MonthlySalesReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/paymentmodewise"
            render={(params) => (
              <div>
                <PaymentModeWise {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/employeeworksheet"
            render={(params) => (
              <div>
                <EmployeeWorkSheet {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/onBoarding"
            render={(params) => (
              <div>
                <OnBoarding {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/paymentmodetransaction"
            render={(params) => (
              <div>
                <PaymentModeTransactionTypeReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/gstr1"
            render={(params) => (
              <div>
                <InvoiceDetail1 {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/report/inventoryreports"
            render={(params) => (
              <div>
                <AllInventoryReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/customerwalletledger"
            render={(params) => (
              <div>
                <CustomerWalletLedgerReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/customeroutstandingledger"
            render={(params) => (
              <div>
                <CustomerOutstandingLedgerReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/walletledger"
            render={(params) => (
              <div>
                <WalletLedger {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/outstandingledger"
            render={(params) => (
              <div>
                <OutStandingLedger {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/uniqueno"
            render={(params) => (
              <div>
                <UniqueNoReport {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/uniquenoledger"
            render={(params) => (
              <div>
                <UniqueNoLedger {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/stores/barcodesettings"
            render={(params) => (
              <div>
                <BarcodePrintSettings {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/barcodeprint"
            render={(params) => (
              <div>
                <BarcodePrint {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expense/accounts"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="account" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expense/addaccount"
            render={(params) => (
              <div>
                <GenericRedirectPage {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expense/addtransaction"
            render={(params) => (
              <div>
                <AddTransaction {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/expense/transactionlist"
            render={(params) => (
              <div>
                <ListPage
                  {...this.props}
                  {...params}
                  type="accountmanagement"
                />
              </div>
            )}
          />
          <Route
            exact
            path="/home/catalogue/bom"
            render={(params) => (
              <div>
                <ListPage {...this.props} {...params} type="bom" />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/vendorledgerreport"
            render={(params) => (
              <div>
                <VendorLedger {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/reports/vendoroutstanding"
            render={(params) => (
              <div>
                <VendorOutstanding {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/referral"
            render={(params) => (
              <div>
                <CustomerReferralProgram {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/loyalty/loyaltydashboard"
            render={(params) => (
              <div>
                <LoyaltyDashboard {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/crm/campaignreport"
            render={() => (
              <div>
                <CampaignsToRun {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/crm/campaigncustomers"
            render={() => (
              <div>
                <CampaignToRunCustomers {...this.props} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/imageupload"
            render={(params) => (
              <div>
                <ProductImageUpload {...this.props} {...params} />
              </div>
            )}
          />
          <Route
            exact
            path="/home/settings/customerdisplay"
            render={() => <CustomerDisplaySettings {...this.props} />}
          />
        </AuthenticatedComponent>
      </div>
    );
  }
}

export default Main;
