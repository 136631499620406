import React, { Component } from 'react';
import Switch from "react-switch"
import PageSection from '../2/Controls/PageSection';
import Page from '../2/Controls/Page';
import Input   from '../2/Controls/Input';

class LoyaltySetup extends Component {
    constructor() {
        super()
        this.state = {
            saving: false,
            pointRedemption: 1,
            pointRedemptionError: '',
            birthdayPointsToBeCreditedBeforeDays: 0,
            anniversaryPointsToBeCreditedBeforeDays: 0,
            paymentModes: [],
            noLoyaltyPointEarnOnLoyaltyRedemptionTransaction:false,
            noLoyaltyPointEarnOnDiscountedTransaction:false
        }
        this.save = this.save.bind(this)
    }

    async componentDidMount() {
        let paymentModes = this.props.config.PaymentTypes.filter((e) => e.ShowInSales && !e.IsDeleted)
        paymentModes.map(m => m.selected = false)

        let loyaltySetup = await this.props.getLoyaltySetupDetails(this.props.registration.registration.key)
        if (loyaltySetup) {
            for (let i = 0; i < paymentModes.length; i++) {
                if (loyaltySetup.SelectedPaymentModes.filter(f => f.key === paymentModes[i].key).length > 0) {
                    paymentModes[i].selected = true
                }
            }
            this.setState({
                birthdayPointsToBeCreditedBeforeDays: loyaltySetup.hasOwnProperty('BirthdayPointsToBeCreditedBeforeDays') ? loyaltySetup.BirthdayPointsToBeCreditedBeforeDays : 0,
                anniversaryPointsToBeCreditedBeforeDays: loyaltySetup.hasOwnProperty('AnniversaryPointsToBeCreditedBeforeDays') ? loyaltySetup.AnniversaryPointsToBeCreditedBeforeDays : 0,
                noLoyaltyPointEarnOnLoyaltyRedemptionTransaction: loyaltySetup.hasOwnProperty("NoLoyaltyPointEarnOnLoyaltyRedemptionTransaction") ? loyaltySetup.NoLoyaltyPointEarnOnLoyaltyRedemptionTransaction : false,
                noLoyaltyPointEarnOnDiscountedTransaction: loyaltySetup.hasOwnProperty("NoLoyaltyPointEarnOnDiscountedTransaction") ? loyaltySetup.NoLoyaltyPointEarnOnDiscountedTransaction:false,
                pointRedemption: loyaltySetup.PointRedemption,
            })
        }
        this.setState({ paymentModes: paymentModes })
    }

    save() {
        this.setState({ saving: true })
        let pointRedemptionError = ""
        if (this.state.pointRedemption <= 0) {
            pointRedemptionError = "Please provide value"
        }
        if (pointRedemptionError.trim() !== "") {
            this.setState({
                pointRedemptionError: pointRedemptionError
            })
            return
        }
        let selectedPaymentModes = []
        if (!this.state.paymentModesAll) {
            for (let i = 0; i < this.state.paymentModes.length; i++) {
                if (this.state.paymentModes[i].selected) {
                    selectedPaymentModes.push({
                        key: this.state.paymentModes[i].key,
                        PaymentType: this.state.paymentModes[i].PaymentType
                    })
                }
            }
        }
        let loyaltySetup = {
            RegistrationID: this.props.registration.registration.key,
            PointRedemption: this.state.pointRedemption,
            PaymentModesAll: false,
            SelectedPaymentModes: selectedPaymentModes,
            BirthdayPointsToBeCreditedBeforeDays: this.state.birthdayPointsToBeCreditedBeforeDays,
            AnniversaryPointsToBeCreditedBeforeDays: this.state.anniversaryPointsToBeCreditedBeforeDays,
            NoLoyaltyPointEarnOnDiscountedTransaction:this.state.noLoyaltyPointEarnOnDiscountedTransaction,
            NoLoyaltyPointEarnOnLoyaltyRedemptionTransaction: this.state.noLoyaltyPointEarnOnLoyaltyRedemptionTransaction
        }
        this.props.saveLoyaltySetup(loyaltySetup).then(() => {
            this.props.getToastr("Loyalty setup saved successfully")
            this.setState({ saving: false })
        })
    }

    render() {

        return <Page 
            title={"Loyalty Setup"} 
            showSaveButton={true} 
            showCancelButton={true}
            processing={this.state.saving}
            onClick={(action)=>{
                if(action==="cancel"){
                    this.props.history.push("/home");
                }else if(action==="save"){
                    this.save();
                }
            }}
            >
            <PageSection title="">
                <div className="row">
                    <div className="col-md-4 col-xs-12">
                        <div className={this.state.pointRedemptionError ? "form-group has-error" : "form-group "} >
                            <label>Point Redemption</label>
                            <div className="input-group" style={{ width: "200px" }}>
                                <input type="number" className="form-control"
                                    onChange={(evt) => this.setState({ pointRedemption: evt.target.value, pointRedemptionError: "" })}
                                    value={this.state.pointRedemption} />
                                <span className="input-group-addon">point(s) = 1 INR</span>
                            </div>
                            {this.state.pointRedemptionError !== "" && <span className="help-block">{this.state.pointRedemptionError}</span>}

                        </div>
                        {this.props.registration.registration.LoyaltySetup1 && <React.Fragment><Input
                        type="switch"
                        label="No loyalty points to be given on sales transactions that have discount"
                        value={this.state.noLoyaltyPointEarnOnDiscountedTransaction}
                        onChange={(value)=> this.setState({noLoyaltyPointEarnOnDiscountedTransaction:value})}
                        />
                        <Input
                        type="switch"
                        label="No loyalty points to be given on sales transactions that have loyalty point redemption"
                        value={this.state.noLoyaltyPointEarnOnLoyaltyRedemptionTransaction}
                        onChange={(value)=> this.setState({noLoyaltyPointEarnOnLoyaltyRedemptionTransaction:value})}
                        />
                        </React.Fragment>}
                        <div className="form-group ">
                            <label>Payment modes to be considered for earning points</label>
                            {this.state.paymentModes.map((m, index) => <div>
                                <label>{m.PaymentType}</label>
                                <div className="input-group">
                                    <Switch
                                        checked={m.selected}
                                        onChange={() => {
                                            let paymentModes = this.state.paymentModes
                                            paymentModes[index].selected = !paymentModes[index].selected
                                            this.setState({ paymentModes: paymentModes })
                                        }}
                                    />
                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div className="col-md-4 col-xs-12">
                        <div className="form-group" >
                            <label>Birthday points to be credited before</label>
                            <div className="input-group" style={{ width: "150px" }}>
                                <input type="number" className="form-control"
                                    onChange={(evt) => this.setState({ birthdayPointsToBeCreditedBeforeDays: evt.target.value })}
                                    value={this.state.birthdayPointsToBeCreditedBeforeDays} />
                                <span className="input-group-addon">days</span>
                            </div>
                        </div>
                    
                        <div className="form-group" >
                            <label>Anniversary points to be credited before</label>
                            <div className="input-group" style={{ width: "150px" }}>
                                <input type="number" className="form-control"
                                    onChange={(evt) => this.setState({ anniversaryPointsToBeCreditedBeforeDays: evt.target.value })}
                                    value={this.state.anniversaryPointsToBeCreditedBeforeDays} />
                                <span className="input-group-addon">days</span>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
        </Page>
    }

}

export default LoyaltySetup