import firebase, { database } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getCustomRoles(registrationID) {
    return (dispatch) => {
        return database.collection("CustomRoles").where("RegistrationID", "==", registrationID).orderBy("ActionOn","desc").get().then((querySnapshot) => {
            let roles = []
            querySnapshot.forEach((doc) => {
                roles.push({ key: doc.id, ...doc.data() })
            })
            //now sort data as per module and 
            return roles;
        })
    }
}

export function deleteCustomRoles(key) {
    return (dispatch, getState) => {
        return database.collection("CustomRoles").doc(key).update({
            IsDeleted: true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}

export function  saveCustomRoles(customRole, key) {
    return async(dispatch, getState) => {
      
        if (key.trim() !== "") {
            database.collection("CustomRoles").doc(key).update({
                IsDeleted: false,
                RegistrationID: getState().user.user.RegistrationID,
                Action : constants.AUDIT_TRAIL_EDIT,
                ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
                ActionBy: getState().user.user.Name,
                ActionByUID: getState().user.user.UID,
                ActionByEmailID: getState().user.user.EmailID});
                
                for (let j = 0; j < customRole.Modules.length; j++) {
                    database.collection("CustomRoles").doc(key).collection('aclModules').doc(customRole.Modules[j].name).set(
                        customRole.Modules[j]);
                }
                return key;
        }
        else {
            //console.log(customRole);
            key = getState().user.user.RegistrationID + '_' + customRole.Name
            await database.collection("CustomRoles").doc(key).set({
                IsDeleted: false,
                Role: customRole.Name, 
                RegistrationID: getState().user.user.RegistrationID,
                ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
                ActionBy: getState().user.user.Name,
                ActionByUID: getState().user.user.UID,
                ActionByEmailID: getState().user.user.EmailID,
                Action : constants.AUDIT_TRAIL_NEW
            });
            for (let j = 0; j < customRole.Modules.length; j++) {
               database.collection("CustomRoles").doc(key).collection('aclModules').doc(customRole.Modules[j].name).set(customRole.Modules[j]);
            }

            return key;
        }
    }
}

export function getCustomRoleDetails(key) {
    return (dispatch) => {
        return database.collection('CustomRoles').doc(key).get().then((doc) => {
            if (doc.exists) {
                return doc.data()
            } else {
                console.log('No such role!');
            }
        })
    }
}

export function getModulesForARole(key) {
    return (dispatch) => {
        return database.collection('CustomRoles').doc(key).collection('aclModules').get().then((querySnapshot) => {
            let modules = [];
            querySnapshot.forEach((doc) => {
                let module = {
                    name: doc.id, ...doc.data()
                };
                modules.push(module);
            })
            return modules;
        });
    }
}