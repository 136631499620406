import React from 'react'
import Form from '../2/Controls/Form';
import Input from '../2/Controls/Input';

class AddEditExpenseCategory extends React.Component{
    constructor () {
        super()
        this.state={
            expenseCategory:'',
            expenseCategoryError:'',
            key:'',
            saving: false
        }
        this.onSaveExpenseCategory = this.onSaveExpenseCategory.bind(this)
    }

    componentDidMount()
    {
        if(this.props.expenseCategory )
        {
            this.setState({expenseCategory: this.props.expenseCategory.ExpenseCategory, key: this.props.expenseCategory.key})
        }
    }
   
    onSaveExpenseCategory ()
    {
        this.setState({ saving:true})
        let expenseCategoryError = ''
        if(this.state.expenseCategory.trim() === ""){
             expenseCategoryError = 'Please provide Expense Category'
             this.setState({expenseCategoryError: expenseCategoryError,saving:false})
             return 
        }
        this.props.checkIfExpenseCategoriesIsUnique(this.state.expenseCategory,this.props.user.user.RegistrationID,this.props.user.store.key).then((isUnique)=>{
            if(!isUnique){
                expenseCategoryError = 'Expense Category is already exists'
                this.setState({expenseCategoryError: expenseCategoryError,saving:false})
                return 
            }
            else
            {
                let expenseCategory = {
                ExpenseCategory: this.state.expenseCategory, 
                RegistrationID: this.props.user.user.RegistrationID,
                StoreID : this.props.user.store.key,
                IsDeleted:false}
                if(this.state.key ==="")
                {
                    expenseCategory = {...expenseCategory}
                }
                this.props.saveExpenseCategories(expenseCategory,this.state.key).then((key)=> {
                    this.props.getToastr("Expense Category Saved Successfully")
                    this.setState({ saving:false})
                    this.props.onSave()
                }) 
            }
        })   
    }

    onButtonClick(action){
        if(action==="save"){
            this.onSaveExpenseCategory();
        }else{
            this.props.onClose()
        }
    }

    render(){
        return  <Form
            title= {this.state.key ? "Edit Expense Category" : "New Expense Category"}
            onClick={(action)=>this.onButtonClick(action)}
            processing={this.state.saving}
        >
            <Input
                type="text"
                label="Expense Category"
                onChange={(value)=> this.setState({expenseCategory:value,expenseCategoryError:"" })}
                value={this.state.expenseCategory}
                error={this.state.expenseCategoryError}
                onEnter={this.onSaveExpenseCategory}
            />
    </Form>
    }
}

export default AddEditExpenseCategory