// import firebase from '../firebase'

export const isMobileApp = () => {
  if (window.screen.width <= 480) {
    return true;
  } else {
    return false;
  }
};

export function roundUpTo2(num) {
  return Math.round(Number(num) * 100) / 100;
}

export function getCities(country) {
  return [
    { City: "Delhi" },
    { City: "Ghaziabad" },
    { City: "Meerut" },
    { City: "Gurgaon" },
    { City: "Faridabad" },
    { City: "Saharanpur" },
    { City: "Haridwar" },
    { City: "Bijnor" },
    { City: "Dehradun" },
    { City: "Kotdwara" },
    { City: "Jaipur" },
    { City: "Motihari" },
    { City: "Bansur" },
    { City: "Sriganganagar" },
    { City: "Mohali" },
    { City: "Chandigarh" },
    { City: "Jammu" },
    { City: "Srinagar" },
    { City: "Leh" },
    { City: "Pune" },
    { City: "Mumbai" },
    { City: "Thane" },
    { City: "Dhule" },
    { City: "Surat" },
    { City: "Navasari" },
    { City: "Vadodara" },
    { City: "Nadiad" },
    { City: "Rajkot" },
    { City: "Kolkata" },
    { City: "Gangtok" },
    { City: "Guwahati" },
    { City: "Shillong" },
    { City: "Barpeta" },
    { City: "Hyderabad" },
    { City: "Kancheepuram" },
    { City: "Tiruvellore" },
    { City: "Tindivanam" },
    { City: "Vellore" },
    { City: "Tirupur" },
    { City: "Coimbatore" },
    { City: "Erode" },
    { City: "Mettupalayam" },
    { City: "Pollachi" },
    { City: "Trichy" },
    { City: "Hosur" },
    { City: "Krishnagiri" },
    { City: "Kumbakonam" },
    { City: "Mayiladutjurai" },
    { City: "Chennai" },
    { City: "Madurai" },
    { City: "Theni" },
    { City: "Ramanathpuram" },
    { City: "Tirunelvelli" },
    { City: "Thiruvananthapuram" },
    { City: "Quilon" },
    { City: "Alappuzha" },
    { City: "Mavelikkara" },
    { City: "Ernakulam" },
    { City: "Trichur" },
    { City: "Kozhikode" },
    { City: "Cannanore" },
    { City: "Kanpur" },
    { City: "Lucknow" },
    { City: "Hamirpur" },
    { City: "Gorakhpur" },
    { City: "Agra" },
    { City: "Bareilly" },
    { City: "Lakhimpur Kheri" },
    { City: "Moradabad" },
    { City: "Bilari" },
    { City: "Chapara" },
    { City: "Cuttack" },
    { City: "Chhindwara" },
    { City: "Indore" },
    { City: "Bareli" },
    { City: "Bhopal" },
    { City: "Ahmedabad" },
    { City: "Bengaluru" },
    { City: "Magadi" },
    { City: "Kolar" },
    { City: "Udupi" },
    { City: "Mysuru" },
    { City: "Mandya" },
    { City: "Mangaluru" },
    { City: "Kaup" },
    { City: "Karkala" },
    { City: "Chikmagalur" },
    { City: "Kadur" },
    { City: "Bailhongal" },
    { City: "Panaji" },
    { City: "Porvorim" },
    { City: "Bijapur" },
    { City: "Bagalkot" },
    { City: "Hubli" },
    { City: "Ranebennur" },
    { City: "Gulbarga" },
    { City: "Chittoor" },
    { City: "Vuyyuru" },
    { City: "Bhimavaram" },
    { City: "Vizayanagaram" },
    { City: "Amritsar" },
    { City: "Mandi Dabwali" },
    { City: "Udaipur" },
    { City: "JHAJJAR" },
    { City: "PALGHAT" },
    { City: "KALYAN" },
    { City: "Calicut" },
    { City: "SITAMARHI" },
    { City: "Nadiad" },
    { City: "Greater Noida" },
    { City: "FATEHPUR" },
    { City: "Thriuvannamala" },
    { City: "chatrapur" },
    { City: "Jalpaiguri" },
    { City: "Krishnanagar" },
    { City: "vijaypur" },
    { City: "GIDDALUR" },
    { City: "ladakh" },
    { City: "Kutch" },
    { City: "Varanasi" },
    { City: "Mainpuri" },
    { City: "Rampur" },
    { City: "Bijnore" },
    { City: "Husenganj" },
    { City: "Amety" },
    { City: "Kanoj" },
    { City: "Unnao" },
    { City: "Lalitpur" },
    { City: "Pala" },
    { City: "Aurangabad" },
    { City: "Ganjam" },
    { City: "Nagpur" },
    { City: "Anand" },
    { City: "Sirsa" },
    { City: "Fatehabad" },
    { City: "Kundli" },
    { City: "Dahisar" },
    { City: "Jalgaon" },
    { City: "Nashik" },
    { City: "Bokaro" },
    { City: "Cachar" },
    { City: "Jeypore" },
    { City: "Burdwan" },
    { City: "Asansol" },
    { City: "Ludhiana" },
    { City: "Agartala" },
    { City: "Alwar" },
    { City: "Wardha" },
    { City: "Anndman Island" },
  ];
}

export function convertNumberToWords(amount) {
  var words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + " ";
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return "Rupees " + words_string;
}

export const mSwipePrinting = (sale, printConfig) => {
  // alert('util.mSwipePrinting called')
  // console.log('printConfig',printConfig)
  let printData = [];
  // let printConfig=this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted && e.TransactionTypeID === sale.TransactionType.key)[0]
  if (printConfig.StoreCodeDisplay) {
    // alert('StoreCodeDisplay')
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.StoreCode,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreNameDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.StoreName,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StorePhoneNoDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.PhoneNo,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.EmailID) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.EmailID,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreAddressLine1Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.Address1,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreAddressLine2Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.Address2,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreAddressCityDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.City,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreAddressStateDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.State,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreAddressPostalCodeDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.PostalCode,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreAddressCountryDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.Country,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreCustomField1Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.CustomField1,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreCustomField2Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.CustomField2,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreCustomField3Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.CustomField3,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreCustomField4Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.CustomField4,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreCustomField5Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.CustomField5,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (printConfig.StoreCustomField6Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "center",
      PrintTextTypeFace: "normal",
      PrintText: sale.Store.CustomField6,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "                        ",
    PrintImagePath: "",
    PrintImageData: "",
  });

  if (printConfig.TransactionNoDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.TransactionNoDisplayName + ": " + sale.TransactionNo,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }

  if (printConfig.TransactionDateDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.TransactionDateDisplayName + ": " + sale.TransactionDate,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "                        ",
    PrintImagePath: "",
    PrintImageData: "",
  });

  //customer details
  if (sale.Customer && printConfig.CustomerCodeDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCodeDisplayName + ": " + sale.Customer.CustomerCode,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerNameDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerNameDisplayName +
        ": " +
        sale.Customer.FirstName +
        " " +
        sale.Customer.LastName,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerPhoneNoDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerPhoneNoDisplayName + ": " + sale.Customer.PhoneNo,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerEmailIDDisplay) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerEmailIDDisplayName + ": " + sale.Customer.EmailID,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerCustomField1Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCustomField1DisplayName +
        ": " +
        sale.Customer.CustomField1,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerCustomField2Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCustomField2DisplayName +
        ": " +
        sale.Customer.CustomField2,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerCustomField3Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCustomField3DisplayName +
        ": " +
        sale.Customer.CustomField3,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerCustomField4Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCustomField4DisplayName +
        ": " +
        sale.Customer.CustomField4,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerCustomField5Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCustomField5DisplayName +
        ": " +
        sale.Customer.CustomField5,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }
  if (sale.Customer && printConfig.CustomerCustomField6Display) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        printConfig.CustomerCustomField6DisplayName +
        ": " +
        sale.Customer.CustomField6,
      PrintImagePath: "",
      PrintImageData: "",
    });
  }

  if (sale.Customer) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText: "    ",
      PrintImagePath: "",
      PrintImageData: "",
    });
  }

  if (sale.LineItems.length > 0) {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        "-----------------------------------------------------------------",
      PrintImagePath: "",
      PrintImageData: "",
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText: "Item                          Qty      Rate     Amount",
      PrintImagePath: "",
      PrintImageData: "",
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        "-----------------------------------------------------------------",
      PrintImagePath: "",
      PrintImageData: "",
    });

    for (let i = 0; i < sale.LineItems.length; i++) {
      let l = sale.LineItems[i];
      // let printText = l.Product.Name.substring(0,14) + " ".repeat((14 - l.Product.Name.toString().length) > 0 ? (14 - l.Product.Name.toString().length) : 0)
      // printText = printText + " " +  l.Qty.toString() + " ".repeat((4 - l.Qty.toString().length) > 0 ? (4 - l.Qty.toString().length) : 0)
      // printText = printText + " " + " ".repeat((4 - l.RetailPrice.toString().length) > 0 ? (4 - l.RetailPrice.toString().length) : 0) + l.RetailPrice.toString()
      // printText = printText + " " + " ".repeat((6 - l.Amount.toString().length > 0 )? (6 - l.Amount.toString().length) : 0) + l.Amount.toString()

      let printText =
        l.Product.Name.substring(0, 14) +
        " ".repeat(
          14 - l.Product.Name.toString().length > 0
            ? 14 - l.Product.Name.toString().length
            : 0
        );

      let printTextRight =
        l.Qty.toString() +
        "  ".repeat(
          4 - l.Qty.toString().length > 0 ? 4 - l.Qty.toString().length : 0
        );
      printTextRight =
        printTextRight +
        "    " +
        "  ".repeat(
          4 - l.RetailPrice.toString().length > 0
            ? 4 - l.RetailPrice.toString().length
            : 0
        ) +
        l.RetailPrice.toString();
      printTextRight =
        printTextRight +
        "    " +
        "   ".repeat(
          6 - l.Amount.toString().length > 0
            ? 6 - l.Amount.toString().length
            : 0
        ) +
        l.Amount.toString();

      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "leftright",
        PrintTextTypeFace: "normal",
        PrintText: printText,
        PrintTextRight: printTextRight,
        PrintImagePath: "",
        PrintImageData: "",
      });

      //check for modifiers
      if (l.hasOwnProperty("Modifiers")) {
        let modifierText = "";
        for (let ctrm = 0; ctrm < l.Modifiers.length; ctrm++) {
          modifierText = modifierText + ", " + l.Modifiers[ctrm].ModifierName;
        }
        printData.push({
          PrintDataType: "text",
          PrintTextFontSize: 24,
          PrintTextAlign: "left",
          PrintTextTypeFace: "normal",
          PrintText: modifierText,
          PrintImagePath: "",
          PrintImageData: "",
        });
      }
      //check for uniqueno
      if (l.hasOwnProperty("UniqueNoList")) {
        for (let ctri = 0; ctri < l.UniqueNoList.length; ctri++) {
          if (l.UniqueNoList[ctri].UniqueNo1.trim() !== "") {
            printData.push({
              PrintDataType: "text",
              PrintTextFontSize: 24,
              PrintTextAlign: "left",
              PrintTextTypeFace: "normal",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo1,
              PrintImagePath: "",
              PrintImageData: "",
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo2.trim() !== "") {
            printData.push({
              PrintDataType: "text",
              PrintTextFontSize: 24,
              PrintTextAlign: "left",
              PrintTextTypeFace: "normal",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo2,
              PrintImagePath: "",
              PrintImageData: "",
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo3.trim() !== "") {
            printData.push({
              PrintDataType: "text",
              PrintTextFontSize: 24,
              PrintTextAlign: "left",
              PrintTextTypeFace: "normal",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo3,
              PrintImagePath: "",
              PrintImageData: "",
            });
          }
          if (l.UniqueNoList[ctri].UniqueNo4.trim() !== "") {
            printData.push({
              PrintDataType: "text",
              PrintTextFontSize: 24,
              PrintTextAlign: "left",
              PrintTextTypeFace: "normal",
              PrintText:
                l.Product.UniqueLabel + ": " + l.UniqueNoList[ctri].UniqueNo4,
              PrintImagePath: "",
              PrintImageData: "",
            });
          }
        }
      }
    }

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText:
        "-----------------------------------------------------------------",
      PrintImagePath: "",
      PrintImageData: "",
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "leftright",
      PrintTextTypeFace: "normal",
      PrintText: "Sub Total:",
      PrintTextRight: sale.BillSubTotal.toString(),
      PrintImagePath: "",
      PrintImageData: "",
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "leftright",
      PrintTextTypeFace: "normal",
      PrintText: "Discount:",
      PrintTextRight: sale.BillDiscount.toString(),
      PrintImagePath: "",
      PrintImageData: "",
    });
  }

  if (sale.hasOwnProperty("OutStandingPayments")) {
    if (sale.OutStandingPayments.length > 0) {
      //print receive payment details
      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "left",
        PrintTextTypeFace: "normal",
        PrintText:
          "-----------------------------------------------------------------",
        PrintImagePath: "",
        PrintImageData: "",
      });

      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "leftright",
        PrintTextTypeFace: "normal",
        PrintText: "Transaction No",
        PrintTextRight: "Amount",
        PrintImagePath: "",
        PrintImageData: "",
      });

      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "left",
        PrintTextTypeFace: "normal",
        PrintText:
          "-----------------------------------------------------------------",
        PrintImagePath: "",
        PrintImageData: "",
      });

      for (let i = 0; i < sale.OutStandingPayments.length; i++) {
        printData.push({
          PrintDataType: "text",
          PrintTextFontSize: 24,
          PrintTextAlign: "leftright",
          PrintTextTypeFace: "normal",
          PrintText: sale.OutStandingPayments[i].TransactionNo,
          PrintTextRight: sale.OutStandingPayments[i].ReceiveAmount.toString(),
          PrintImagePath: "",
          PrintImageData: "",
        });
      }
    }
  }

  if (sale.PrintTaxComponents.length > 0) {
    for (let l = 0; l < sale.PrintTaxComponents.length; l++) {
      let p = sale.PrintTaxComponents[l];
      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "leftright",
        PrintTextTypeFace: "normal",
        PrintText: p.TaxType + ":",
        PrintTextRight: p.TaxAmount.toString(),
        PrintImagePath: "",
        PrintImageData: "",
      });
    }
  }

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText:
      "-----------------------------------------------------------------",
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "leftright",
    PrintTextTypeFace: "normal",
    PrintText: "Total:",
    PrintTextRight: sale.BillAmount.toString(),
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText:
      "-----------------------------------------------------------------",
    PrintImagePath: "",
    PrintImageData: "",
  });

  if (sale.Payments.length > 0) {
    for (let l = 0; l < sale.Payments.length; l++) {
      let p = sale.Payments[l];
      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "leftright",
        PrintTextTypeFace: "normal",
        PrintText: p.PaymentMode,
        PrintTextRight: p.Amount.toString(),
        PrintImagePath: "",
        PrintImageData: "",
      });
    }
  }

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "leftright",
    PrintTextTypeFace: "normal",
    PrintText: "Balance:",
    PrintTextRight: sale.BalanceAmount.toString(),
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "",
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "",
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "",
    PrintImagePath: "",
    PrintImageData: "",
  });

  // console.log('printData',printData)
  // return

  window.cordova.plugins.mswipe.print(
    printData,
    (result) => {
      console.log("Mswipe Print Completed " + result);
    },
    (error) => {
      alert("MSwipe Print Error: " + error);
    }
  );
};

export const mSwipeCRClosingPrinting = (cashRegisterClosing) => {
  // alert('util.mSwipeCRClosingPrinting called')
  let printData = [];

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Store Code: " + cashRegisterClosing.StoreCode,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Store Name: " + cashRegisterClosing.StoreName,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "CR Code: " + cashRegisterClosing.CashRegisterCode,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "CR Name: " + cashRegisterClosing.CashRegisterName,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Opening Time: " + cashRegisterClosing.OpeningTime,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Opened By: " + cashRegisterClosing.OpeningByUserName,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Closing Time: " + cashRegisterClosing.ClosingTime,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Closed By: " + cashRegisterClosing.ClosingByUserName,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText: "Closing Remarks: " + cashRegisterClosing.ClosingRemarks,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "leftright",
    PrintTextTypeFace: "normal",
    PrintText: "Opening Amt: ",
    PrintTextRight: cashRegisterClosing.OpeningBalance.toString(),
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText:
      "-----------------------------------------------------------------",
    PrintImagePath: "",
    PrintImageData: "",
  });

  cashRegisterClosing.ClosingPaymentModes.map((m) => {
    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "left",
      PrintTextTypeFace: "normal",
      PrintText: m.PaymentMode,
      PrintImagePath: "",
      PrintImageData: "",
    });

    m.Components.map((k) => {
      printData.push({
        PrintDataType: "text",
        PrintTextFontSize: 24,
        PrintTextAlign: "leftright",
        PrintTextTypeFace: "normal",
        PrintText: k.Name + ": ",
        PrintTextRight: k.Amount.toString(),
        PrintImagePath: "",
        PrintImageData: "",
      });
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "right",
      PrintTextTypeFace: "normal",
      PrintText: "-------------------------------",
      PrintImagePath: "",
      PrintImageData: "",
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "leftright",
      PrintTextTypeFace: "normal",
      PrintText: "Total:",
      PrintTextRight: m.Amount.toString(),
      PrintImagePath: "",
      PrintImageData: "",
    });

    printData.push({
      PrintDataType: "text",
      PrintTextFontSize: 24,
      PrintTextAlign: "right",
      PrintTextTypeFace: "normal",
      PrintText: "-------------------------------",
      PrintImagePath: "",
      PrintImageData: "",
    });
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "leftright",
    PrintTextTypeFace: "normal",
    PrintText: "Closing Amt:",
    PrintTextRight: cashRegisterClosing.ClosingTotal.toString(),
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "left",
    PrintTextTypeFace: "normal",
    PrintText:
      "-----------------------------------------------------------------",
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "leftright",
    PrintTextTypeFace: "normal",
    PrintText: "Deposited To:",
    PrintTextRight: cashRegisterClosing.DepositedToAccount,
    PrintImagePath: "",
    PrintImageData: "",
  });

  printData.push({
    PrintDataType: "text",
    PrintTextFontSize: 24,
    PrintTextAlign: "leftright",
    PrintTextTypeFace: "normal",
    PrintText: "Deposited Amt:",
    PrintTextRight: cashRegisterClosing.AmountDeposited.toString(),
    PrintImagePath: "",
    PrintImageData: "",
  });

  window.cordova.plugins.mswipe.print(
    printData,
    (result) => {
      console.log("Mswipe Print Completed " + result);
    },
    (error) => {
      alert("MSwipe Print Error: " + error);
    }
  );
};

export function isElectron() {
  return false;
  if (typeof require !== "function") return false;
  if (typeof window !== "object") return false;
  try {
    const electron = require("electron");
    if (typeof electron !== "object") return false;
  } catch (e) {
    return false;
  }
  return true;
}

export function salesCalculateLineItem(
  index,
  lineItem,
  placeOfSupply,
  discountType,
  discountID,
  discountInfo,
  transactionLevelDiscount,
  transactionType,
  productTaxes
) {
  let subTotal = roundUpTo2(
    Number(lineItem.RetailPrice) * Number(lineItem.Qty)
  );
  subTotal = roundUpTo2(
    subTotal + (lineItem.ModifierAmount ? lineItem.ModifierAmount : 0)
  );
  let discountAmt = 0;
  let discountPer = 0;
  let allowDiscount = true;
  if (lineItem.Product.hasOwnProperty("AllowDiscount")) {
    allowDiscount = lineItem.Product.AllowDiscount;
  }
  if (allowDiscount) {
    if (
      Number(lineItem.DiscountAmount) > 0 &&
      Number(lineItem.DiscountPer) === 0
    ) {
      //calculate discount per based on discount amount
      //&& Number(lineItem.DiscountPer) === 0
      discountPer = Number(
        (
          (Number(lineItem.DiscountAmount) * 100) /
          Number(lineItem.SubTotal)
        ).toFixed(3)
      );
      discountAmt = roundUpTo2(lineItem.DiscountAmount);
    } else {
      discountPer = Number(lineItem.DiscountPer);
      discountAmt = roundUpTo2(
        (Number(subTotal) * Number(lineItem.DiscountPer)) / 100
      );
    }
    // console.log('discountPer', discountPer)
    if (discountInfo) {
      if (discountInfo.trim() !== "") {
        // if(!lineItem.hasOwnProperty('Discounts'))
        // {
        //     lineItem.Discounts = []
        // }
        console.log("discountInfo", discountInfo);
        lineItem.Discounts = [];
        lineItem.Discounts.push({
          DiscountType: discountType,
          DiscountID: discountID,
          DiscountInfo: discountInfo,
          DiscountAmount: discountAmt,
          DiscountPercentage: discountPer,
          TransactionLevelDiscount: transactionLevelDiscount,
        });
      }
    } else if (discountAmt === 0) {
      lineItem.Discounts = [];
    }
  } else {
    lineItem.Discounts = [];
  }

  let subTotalWithDiscount = roundUpTo2(Number(subTotal) - Number(discountAmt));
  let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct;
  let priceBasedTax = lineItem.PriceBasedTax;
  let taxInclusive = lineItem.TaxInclusive;
  let taxableAmount = 0;
  let totalTax = 0;
  let amount = 0;
  let taxComponentAmount = [];
  let retailPriceWithoutTax = 0; //TBD

  let transactionTypeForCalculation = transactionType;
  if (transactionTypeForCalculation) {
    if (transactionTypeForCalculation.hasOwnProperty("CalculateTaxChecked")) {
      if (!transactionTypeForCalculation.CalculateTaxChecked) {
        chargeTaxOnProduct = false;
      } else if (transactionType) {
        chargeTaxOnProduct = true;
      }
    }
  }

  if (chargeTaxOnProduct) {
    if (priceBasedTax) {
      let unitPriceAfterDiscount = roundUpTo2(
        Number(subTotalWithDiscount) / Number(lineItem.Qty)
      );
      lineItem.Product.TaxPriceRange.map((t) => {
        if (
          Number(unitPriceAfterDiscount) >= Number(t.FromPrice) &&
          Number(unitPriceAfterDiscount) <= Number(t.ToPrice)
        ) {
          lineItem.TaxID = t.TaxID;
          lineItem.TaxGroup = t.TaxGroup;
          return;
        }
      });
    }
    let taxfound = false;
    let customerFromSameState = true; //TBD IMP IMP
    // console.log(" this.props.user.store.State", this.props.user.store.State);
    // console.log("placeOfSupply", placeOfSupply);
    // if (
    //   this.props.user.store.State &&
    //   placeOfSupply !== "" &&
    //   this.props.user.store.State !== ""
    // ) {
    //   if (
    //     placeOfSupply.toLowerCase() !==
    //     this.props.user.store.State.toLowerCase()
    //   ) {
    //     customerFromSameState = false;
    //   }
    // }
    productTaxes
      .filter((t) => t.key === lineItem.TaxID.trim())
      .map((taxGroup, index) => {
        taxfound = true;
        let taxAmount = 0;
        let taxComponentTemp = [];
        taxGroup.Taxes.map((t) => {
          if (
            customerFromSameState &&
            (t.TaxType === "CGST" || t.TaxType === "SGST")
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          } else if (!customerFromSameState && t.TaxType === "IGST") {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          } else if (
            t.TaxType !== "CGST" &&
            t.TaxType !== "IGST" &&
            t.TaxType !== "SGST"
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          }
        });

        if (taxInclusive) {
          //tax inclusive
          let totalTaxRate = 0;
          taxComponentTemp.map((t) => {
            totalTaxRate = roundUpTo2(
              Number(t.TaxPercentage) + Number(totalTaxRate)
            );
          });
          totalTax = roundUpTo2(
            (Number(subTotalWithDiscount) * Number(totalTaxRate)) /
              (100 + Number(totalTaxRate))
          );
          taxableAmount = roundUpTo2(
            Number(subTotalWithDiscount) - Number(totalTax)
          );
          amount = roundUpTo2(subTotalWithDiscount);
          taxComponentTemp.map((t) => {
            taxAmount = roundUpTo2(
              (Number(totalTax) *
                ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate))) /
                100
            );
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
            });
          });
        } else {
          taxableAmount = subTotalWithDiscount;
          taxComponentTemp.map((t) => {
            taxAmount = roundUpTo2(
              (Number(taxableAmount) * Number(t.TaxPercentage)) / 100
            );
            totalTax = roundUpTo2(Number(totalTax) + Number(taxAmount));
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
            });
          });
          //console.log('totalTax',totalTax)
          amount = roundUpTo2(taxableAmount + totalTax);
        }
      });
    //tax is not found then
    if (!taxfound) {
      //alert("Tax not found for selected product. Please check item ")
      taxableAmount = 0;
      taxComponentAmount = [];
      totalTax = 0;
      amount = subTotalWithDiscount;
    }
  } else {
    taxableAmount = 0;
    taxComponentAmount = [];
    totalTax = 0;
    amount = subTotalWithDiscount;
  }

  // lineItem.Qty = qty
  // lineItem.RetailPrice = price
  lineItem.SubTotal = roundUpTo2(subTotal);
  lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
  lineItem.TaxInclusive = taxInclusive;
  lineItem.DiscountPer = roundUpTo2(discountPer);
  lineItem.DiscountAmount = roundUpTo2(discountAmt);
  lineItem.SubTotalWithDiscount = roundUpTo2(subTotalWithDiscount);
  lineItem.TaxableAmount = roundUpTo2(taxableAmount);
  lineItem.TaxComponentAmount = taxComponentAmount;
  lineItem.TotalTax = roundUpTo2(totalTax);
  lineItem.Amount = roundUpTo2(amount);
  return lineItem;
}

export function salesCalculateBillTotal(lineItems, receivedPaymentAmount = 0) {
  let billQty = 0;
  let billAmount = 0;
  let billSubTotal = 0;
  let billTaxAmount = 0;
  let billDiscount = 0;
  let taxSummaryList = [];
  let roundOff = 0;

  lineItems.map((lineItem, index) => {
    if (lineItem.TaxID != "") {
      let indexFound = -1;
      taxSummaryList.map((t, index) => {
        if (
          t.TaxID === lineItem.TaxID &&
          t.HSNSACCode === lineItem.HSNSACCode
        ) {
          let sameTaxComponent = true;
          t.TaxComponentAmount.map((a) => {
            if (
              lineItem.TaxComponentAmount.filter((f) => f.TaxName === a.TaxName)
                .length === 0
            ) {
              sameTaxComponent = false;
              return;
            }
          });
          if (sameTaxComponent) {
            indexFound = index;
            return;
          }
        }
      });
      if (indexFound < 0) {
        let taxSummary = {
          TaxID: lineItem.TaxID,
          TaxGroup: lineItem.TaxGroup,
          HSNSACCode: lineItem.HSNSACCode,
          TaxableAmount: roundUpTo2(lineItem.TaxableAmount),
          TotalTax: roundUpTo2(lineItem.TotalTax),
          TotalQty: roundUpTo2(lineItem.Qty),
          TaxComponentAmount: [],
        };
        lineItem.TaxComponentAmount.map((a) => {
          taxSummary.TaxComponentAmount.push({ ...a });
        });
        taxSummaryList.push(taxSummary);
      } else {
        taxSummaryList[indexFound].TaxableAmount = roundUpTo2(
          Number(taxSummaryList[indexFound].TaxableAmount) +
            Number(lineItem.TaxableAmount)
        );
        taxSummaryList[indexFound].TotalTax = roundUpTo2(
          Number(taxSummaryList[indexFound].TotalTax) +
            Number(lineItem.TotalTax)
        );
        taxSummaryList[indexFound].TotalQty = roundUpTo2(
          Number(taxSummaryList[indexFound].TotalQty) + Number(lineItem.Qty)
        );
        taxSummaryList[indexFound].TaxComponentAmount.map((t, index) => {
          t.TaxAmount = roundUpTo2(
            Number(t.TaxAmount) +
              Number(lineItem.TaxComponentAmount[index].TaxAmount)
          );
        });
      }
    }
    //console.log('taxSummaryList', taxSummaryList)
    billQty = roundUpTo2(Number(billQty) + Number(lineItem.Qty));
    billSubTotal = roundUpTo2(Number(billSubTotal) + Number(lineItem.SubTotal));
    billAmount = roundUpTo2(Number(billAmount) + Number(lineItem.Amount));
    billTaxAmount = roundUpTo2(
      Number(billTaxAmount) + Number(lineItem.TotalTax)
    );
    billDiscount = roundUpTo2(
      Number(billDiscount) + Number(lineItem.DiscountAmount)
    );
  });
  roundOff = roundUpTo2(Math.round(billAmount) - billAmount);
  // console.log('roundOff', Math.round(roundOff))
  billAmount = Math.round(billAmount - Number(receivedPaymentAmount));
  return {
    billQty: billQty,
    billSubTotal: billSubTotal,
    billAmount: billAmount,
    billTaxAmount: billTaxAmount,
    billDiscount: billDiscount,
    taxSummaryList: taxSummaryList,
    roundOff: roundOff,
  };
}
