import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'

export function getTransactions(from, size, registrationID, storeID, fromDate, toDate, paymentType, toAccount, fromAccount) {
    let searchParameters = {
        "from": from,
        "size": size,
        "track_total_hits": true,
        "query": { 
            "bool": {
                "must": [
                    {
                        "match": { "RegistrationID": registrationID }
                    },
                    {
                        "match": { "StoreID": storeID }
                    },
                    {
                        "term": { "IsDeleted": false }
                    },
                    {
                        "range": {
                            "TransactionDate": {
                                "gte": fromDate,
                                "lte": toDate,
                                "format": "yyyy-MM-dd"
                            }
                        }
                    }
                ],
                "filter": {
                    "bool": {
                        "should": [

                        ],
                        "minimum_should_match": 1
                    }
                }
            }
        },
        "sort": [
            { "ActionOn": { "order": "desc" } }
        ]
    }
    if (paymentType.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({ "match": { "PaymentTypeID": paymentType.trim() } })
    }
    if (fromAccount.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({ "match": { "FromAccountID": fromAccount.trim() } })
    }
    if (toAccount.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({ "match": { "ToAccountID": toAccount.trim() } })
    }
    console.log(JSON.stringify(searchParameters));
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getAccountManagement');

        return addMessage({ text: searchParameters }).then(function (result) {
            const transactions = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                transactions.push({ key: data._id, ...data._source })
            })
            return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: transactions }
        });
    }
}

export function saveTransaction(transaction, key) {
    return (dispatch, getState) => {
        transaction.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        transaction.ActionBy = getState().user.user.Name
        transaction.ActionByUID = getState().user.user.UID
        transaction.ActionByEmailID = getState().user.user.EmailID

        if (key !== "") {
            transaction.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("AccountManagement").doc(key).update({
                ...transaction
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating transaction: ", error);
            })
        }
        else {
            transaction.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("AccountManagement").add({
                ...transaction
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding transaction: ", error);
                })
        }
    }
}

export function cancelTransaction(cancellationReason, key) {
    return (dispatch, getState) => {
        return database.collection("AccountManagement").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: "CANCEL",
            CancellationReason: cancellationReason
        }).then(() => { return true })
    }
}

export function getTransactionDetails(key) {
    return dispatch => {
        return database.collection("AccountManagement").doc(key).get().then((doc) => {
            if (doc.exists) {
                console.log("transaction found", doc.data());
                return doc.data()
            } else {
                console.log("No such account!");
            }
        })
    }
}

export function getCashDebitEntriesFromAccountManagement(cashRegisterID, fromDate) {
    return (dispatch, getState) => {
        return database.collection("AccountManagement").where("FromAccountID", "==", cashRegisterID).where("FromAccountType", "==", "CashRegister").where("TransactionDate", "<=", new Date()).where("TransactionDate", ">=", fromDate).get().then((querySnapshot) => {
            let amount = 0
            querySnapshot.forEach((doc) => {
                if (!doc.data().hasOwnProperty('CancellationReason'))
                    amount = amount + Number(doc.data().Amount)
            })
            return amount
        }).catch((error) => {
            console.log("Error getting getCashDebitEntriesFromAccountManagement : ", error)
        })
    }
}

export function getCashCreditEntriesFromAccountManagement(cashRegisterID, fromDate) {

    console.log("CashRegisterID: " + cashRegisterID + " From Date: " + fromDate)

    return (dispatch, getState) => {
        return database.collection("AccountManagement").where("ToAccountID", "==", cashRegisterID).where("ToAccountType", "==", "CashRegister").where("PaymentType", "==", "Cash").where("TransactionDate", "<=", new Date()).where("TransactionDate", ">=", fromDate).get().then((querySnapshot) => {
            let amount = 0
            querySnapshot.forEach((doc) => {
                if (!doc.data().hasOwnProperty('CancellationReason'))
                    amount = amount + Number(doc.data().Amount)
            })
            return amount
        }).catch((error) => {
            console.log("Error getting getCashDebitEntriesFromAccountManagement : ", error)
        })
    }
}