import React, { Component } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { SketchPicker } from 'react-color';

const SortableItem = SortableElement(({widget, index}) => <div key={"o"+ index}
style={{height:"50px"}}
className="btn btn-block btn-default btn-flat" 
><b>Title:{widget.Title}</b><br></br><div><i>Widget:{widget.Name}</i></div>
</div>);

const SortableList = SortableContainer(({items}) => {
  return (
    <div>
      {items.map((widget, index) => (
        <SortableItem key={`item-${index}`} index={index} widget={widget}/>
      ))}
    </div>
  );
});

class DashboardSettings extends Component {
    constructor() {
        super();
        this.state = {
            key:"",
            widgets:[
                {Name:"Total Sales", Widget:"WidgetTotalSales", Title:"Sales",Description:"Paid amount + Balance amount", WidgetOptionLeftBlockColor:"#00a65a", WidgetOptionLeftBlockIcon:"fa fa-money"},
                {Name:"Total Transactions", Widget:"WidgetTotalTransactions", Title:"Transactions",Description:"",WidgetOptionLeftBlockColor:"#00c0ef", WidgetOptionLeftBlockIcon:"fa fa-file-o"},
                {Name:"Total Customers", Widget:"WidgetTotalCustomers", Title:"Customers",Description:"", WidgetOptionLeftBlockColor:"#f39c12", WidgetOptionLeftBlockIcon:"fa fa-users"},
                {Name:"Total Expense", Widget:"WidgetTotalExpenses", Title:"Expense",Description:"", WidgetOptionLeftBlockColor:"#dd4b39", WidgetOptionLeftBlockIcon:"fa fa-cart-plus"},
                {Name:"Total Credit Sale", Widget:"WidgetTotalCreditSales", Title:"Credit Sale",Description:"", WidgetOptionLeftBlockColor:"#00a65a", WidgetOptionLeftBlockIcon:"fa fa-inr"},
                {Name:"Total Received", Widget:"WidgetTotalReceived", Title:"Received",Description:"", WidgetOptionLeftBlockColor:"#00a65a", WidgetOptionLeftBlockIcon:"fa fa-inr"},
                {Name:"Store Comparison", Widget:"WidgetTotalStoresSales", Title:"Store Comparison",Description:""},
                {Name:"Payment Mode Comparison", Widget:"WidgetPaymentDashboard", Title:"Payment Mode",Description:""},
                {Name:"Last 12 Month Sales", Widget:"WidgetLast12MonthsSales", Title:"Last 12 Month Sales",Description:""},
                {Name:"Quarterly Sales", Widget:"WidgetQuarterSales", Title:"Quarterly Sales",Description:""},
                {Name:"Category Top X", Widget:"WidgetTopCategories", Title:"Category",Description:""},
                {Name:"Sub Cateogry Top X", Widget:"WidgetTopSubCategories", Title:"Sub Cateogry",Description:""},
                {Name:"Brand Top X", Widget:"WidgetTopBrands", Title:"Brand",Description:""},
                {Name:"Products Top X", Widget:"WidgetTopProducts", Title:"Top Products",Description:""},         
                {Name:"Top X Product Type", Widget:"WidgetTopProductTypes", Title:"Top Product Type",Description:""},
                {Name:"Customers Analysis", Widget:"WidgetCustomerAnalysis", Title:"Customers",Description:""},
                {Name:"Employees Top X", Widget:"WidgetTopEmployees",Title:"Employees", Description:""},
                {Name:"Specific Product Type", Widget:"WidgetTopProductType", Title:"",Description:""},
                {Name:"Specific Payment Mode", Widget:"WidgetSpecificPaymentSales", Title:"",Description:""},      
                {Name:"Last 12 Month Sales vs Purchase", Widget:"WidgetLast12MonthsSalesVsPurchase", Title:"Last 12 Months Sales vs Purchase",Description:""},      
                {Name:"Store Wise - Sales vs Purchase", Widget:"WidgetSalesVsPurchaseStoreWise", Title:"",Description:""}         
                
            ],
            icons:[
                {Name:"Sales", Icon:"fa fa-money"},
                {Name:"Transaction", Icon:"fa fa-file-o"},
                {Name:"Customers", Icon:"fa fa-users"},
                {Name:"Shopping Cart", Icon:"fa fa-cart-plus"},
                {Name:"Address Book", Icon:"fa-address-book"},
                {Name:"Cog", Icon:"fa fa-cog"},
                {Name:"Calendar", Icon:"fa fa-calendar"},
                {Name:"Cube", Icon:"fa fa-cube"},
                {Name:"Cubes", Icon:"fa fa-cubes"},
                {Name:"Truck", Icon:"fa fa-truck"},
                {Name:"Trophy", Icon:"fa fa-trophy"},
                {Name:"Credit Card", Icon:"fa fa-credit-card"},
                {Name:"Pie Chart", Icon:"fa fa-pie-chart"},
                {Name:"Inr", Icon:"fa fa-inr"},
                {Name:"Dollar", Icon:"fa fa-dollar"},
                {Name:"Euro", Icon:"fa fa-euro"},
                {Name:"Heart", Icon:"fa fa-heart"},
                {Name:"User", Icon:"fa fa-user"},
                {Name:"Bank", Icon:"fa fa-bank"},
                {Name:"Building", Icon:"fa fa-building"}                
                ],
            selectedWidget:"",
            selectedWidgetIndex:-1,
            widgetOptionAmtOrQty:"amount",
            widgetOptionTopX:5,
            widgetOptionProductType:"goods for sale",
            widgetOptionPaymentMode:"",
            widgetOptionPaymentModeID:"",
          
            widgetOptionTransactionTypeIDs:[],
            widgetOptionIncludeBalance:false,
            widgetOptionIncludeTransactionTypes: false,

            widgetOptionLeftBlockColor:'',
            widgetOptionLeftBlockIcon:'',
            selectedWidgets:[],
            selectedWidgetTitle:"",
            showWidgetRemoveOption:false,
            titleError:"",
            colorError:"",
            iconError:"",
            paymentModeError:""
        }
        this.onSave = this.onSave.bind(this)
        this.onCancel= this.onCancel.bind(this)
        this.onWidgetSelected = this.onWidgetSelected.bind(this)
        this.onAddWidget = this.onAddWidget.bind(this)
        this.onSortEnd= this.onSortEnd.bind(this)
        this.onWidgetSelectedForRemoval = this.onWidgetSelectedForRemoval.bind(this)
        this.onTransactionTypeSelected = this.onTransactionTypeSelected.bind(this)
        this.onRemoveSelectedWidgets = this.onRemoveSelectedWidgets.bind(this)
        this.onColorSelection = this.onColorSelection.bind(this)
    }

    async componentDidMount() {
        let dashboardSetting = await this.props.getDashBoardSettings(this.props.user.user.RegistrationID)
        // console.log('dashboardSetting', dashboardSetting)
        if(dashboardSetting)
        {
            this.setState({key:dashboardSetting.key, selectedWidgets:dashboardSetting.Widgets})
        }
    }

    onColorSelection = (color) => {
        this.setState({ widgetOptionLeftBlockColor: color.hex });
    }


    onWidgetSelectedForRemoval(index)
    {
        let selectedWidgets = this.state.selectedWidgets
        selectedWidgets[index].Selected =  selectedWidgets[index].hasOwnProperty('Selected') ? !selectedWidgets[index].Selected : true
        this.setState({selectedWidgets:selectedWidgets})
    }


    onTransactionTypeSelected(key)
    {
        let selectedTransactionTypes = this.state.widgetOptionTransactionTypeIDs;
        if(!selectedTransactionTypes.includes(key))
            selectedTransactionTypes.push(key);

        // console.log("selectedTransactionTypes", selectedTransactionTypes)
        this.setState({widgetOptionTransactionTypeIDs:selectedTransactionTypes})
    }

    
    onRemoveSelectedWidgets()
    {
        let widgetsToBeRetained = []
        this.state.selectedWidgets.map(m=>{
            if(m.hasOwnProperty('Selected') && m.Selected)
            {
                //to be ignored
            }
            else
            {
                widgetsToBeRetained.push(m)
            }
        })
        this.setState({selectedWidgets:widgetsToBeRetained, showWidgetRemoveOption:false})
    }

    onWidgetSelected(index)
    {
        this.setState({selectedWidget:index >=0 ? this.state.widgets[index].Widget:"", 
            selectedWidgetIndex:index,
            selectedWidgetTitle:this.state.widgets[index].Title,
            widgetOptionTopX:5, 
            widgetOptionAmtOrQty:"amount", 
            widgetOptionLeftBlockColor:this.state.widgets[index].hasOwnProperty('WidgetOptionLeftBlockColor') ? this.state.widgets[index].WidgetOptionLeftBlockColor :"",
            widgetOptionLeftBlockIcon:this.state.widgets[index].hasOwnProperty('widgetOptionLeftBlockIcon') ?  this.state.widgets[index].widgetOptionLeftBlockIcon:"",
            widgetOptionProductType:"goods for sale"})
    }

    onAddWidget()
    {
        let titleError = ""
        let colorError=""
        let iconError=""
        let paymentModeError=""
        if(this.state.selectedWidgetTitle.trim()==="")
        {
            titleError = "Plase provide title"
        }
        if(this.state.selectedWidget ==="WidgetTotalSales"
                                           || this.state.selectedWidget ==="WidgetTotalTransactions" 
                                           || this.state.selectedWidget ==="WidgetTotalCustomers" 
                                           || this.state.selectedWidget ==="WidgetTotalExpenses" 
                                           || this.state.selectedWidget ==="WidgetTotalCreditSales" 
                                           || this.state.selectedWidget ==="WidgetTotalReceived" 
                                           || this.state.selectedWidget ==="WidgetTotalCreditReceived"
                                           || this.state.selectedWidget === "WidgetSpecificPaymentSales")
        {
            if(this.state.widgetOptionLeftBlockColor==="")
            {
                colorError="Please select color"
            }
            if(this.state.widgetOptionLeftBlockIcon==="")
            {
                iconError="Please select image"
            }
        }
        if(this.state.selectedWidget === "WidgetSpecificPaymentSales" && this.state.widgetOptionPaymentMode.trim()==="")
        {
            paymentModeError="Please select payment mode"
        }
        if(titleError.trim()!=="" || colorError.trim()!=="" || iconError.trim()!=="" || paymentModeError.trim()!=="")
        {
            this.setState({titleError:titleError, colorError:colorError,iconError:iconError, paymentModeError:paymentModeError })
            return 
        }
        let widget = {Name:this.state.widgets[this.state.selectedWidgetIndex].Name,
        Title: this.state.selectedWidgetTitle,
        Widget:this.state.widgets[this.state.selectedWidgetIndex].Widget,
        WidgetOptionAmtOrQty:this.state.widgetOptionAmtOrQty,
        WidgetOptionTopX: this.state.widgetOptionTopX,
        WidgetOptionProductType: this.state.widgetOptionProductType,
        WidgetOptionPaymentMode: this.state.widgetOptionPaymentMode,
        WidgetOptionPaymentModeID: this.state.widgetOptionPaymentModeID,
        WidgetOptionIncludeBalance:this.state.widgetOptionIncludeBalance,
        WidgetOptionIncludeTransactionTypes:this.state.widgetOptionIncludeTransactionTypes,
        WidgetOptionTransactionTypeIDs: this.state.widgetOptionTransactionTypeIDs,

        WidgetOptionLeftBlockColor:this.state.widgetOptionLeftBlockColor,
        WidgetOptionLeftBlockIcon:this.state.widgetOptionLeftBlockIcon,
        }
        let infoText = ""
        if(this.state.selectedWidget === "WidgetTopCategories" || 
        this.state.selectedWidget === "WidgetTopSubCategories" ||
        this.state.selectedWidget === "WidgetTopBrands" ||
        this.state.selectedWidget === "WidgetCustomersTop" || 
        this.state.selectedWidget === "WidgetTopEmployees" ||
        this.state.selectedWidget === "WidgetSpecificProductType")
        {
            infoText = "Show top " + this.state.widgetOptionTopX + " by " + this.state.widgetOptionAmtOrQty
        }
        widget.InfoText = infoText
        let selectedWidgets= this.state.selectedWidgets
        selectedWidgets.push(widget)
        console.log('widget', widget)
        this.setState({selectedWidgets:selectedWidgets})
    }

    async onSave()
    {
        if(this.state.selectedWidgets.length ===0)
        {
            alert('Please select widgets')
            return
        }
        let dashboardSettings = {
            UID:this.props.user.user.UID,
            RegistrationID: this.props.user.user.RegistrationID,
            Widgets: this.state.selectedWidgets
        }
       let key =  await this.props.saveDashboardSettings(this.state.key , dashboardSettings)
       if(key==="")
       {
           alert('Error saving')
       }
       else
       {
           this.setState({key:key})
           this.props.getToastr("Saved Successfully") 
           this.onCancel()
       }
    }

    onCancel()
    {
        this.props.history.push("/home/reports/dashboard")
    }

    onSortEnd({oldIndex, newIndex}){
        let selectedWidgets = this.state.selectedWidgets
        const movingItem = selectedWidgets[oldIndex];
        selectedWidgets.splice(oldIndex, 1);
        selectedWidgets.splice(newIndex, 0, movingItem);
        this.setState({selectedWidgets:selectedWidgets})
    }

    render() { 
        return <div className="content-wrapper">
                    <section className="content-header">
                        <h1>Dashboard Settings</h1>
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right" 
                            style={{marginTop:"-25px",marginLeft:"10px"}} 
                            onClick={this.onSave} disabled={this.state.saving}>Save
                        </button>
                        <button  className="btn btn-md btn-flat btn-default pull-right"  style={{marginTop:"-25px"}} 
                             onClick={this.onCancel} >Cancel
                        </button>         
                    </section>  
                    <section className="content" >
                        <div className="box ">
                            <div className="box-body">     
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="col-md-12">
                                            <div className="form-group ">
                                                    <label>Please select widget</label>
                                                    <select className="form-control"
                                                        value={this.state.selectedWidgetIndex}
                                                        onChange={(evt)=> this.onWidgetSelected(evt.target.value)}>
                                                        <option key={"select"} value={-1}>Select</option>
                                                        {this.state.widgets.map((m, index) =>
                                                            <option key={m.Widget} value={index}>{m.Name}</option>
                                                        )}
                                                    </select>
                                            </div>
                                        </div>
                                        {this.state.selectedWidgetIndex > -1 && <div className="col-md-12">
                                            <div className={this.state.titleError!=="" ? "form-group has-error" : "form-group "} >
                                                <label>Title</label>
                                                <input type="text"
                                                    className="form-control"
                                                    onChange={(evt) => {
                                                        this.setState({ selectedWidgetTitle: evt.target.value, titleError:"" })
                                                    }}
                                                    value={this.state.selectedWidgetTitle}
                                                />
                                                {this.state.titleError!=="" && <span className="help-block">{this.state.titleError}</span>}
                                            </div>
                                        </div>}
                                        {(this.state.selectedWidget === "WidgetTotalSales") && <div className="col-md-12">
                                            <div className="form-group ">
                                                <label>Include balance amount</label>
                                                <input type="checkbox"
                                                    onChange={(evt) => {
                                                        this.setState({widgetOptionIncludeBalance: !this.state.widgetOptionIncludeBalance })
                                                    }}
                                                    value={this.state.widgetOptionIncludeBalance}
                                                />
                                            </div>
                                            <div className="form-group ">
                                                <label>Include specific Transaction Types</label>
                                                <input type="checkbox"
                                                    onChange={(evt) => {
                                                        this.setState({widgetOptionIncludeTransactionTypes: !this.state.widgetOptionIncludeTransactionTypes })
                                                    }}
                                                    value={this.state.widgetOptionIncludeTransactionTypes}
                                                />
                                            </div>
                                            {this.state.widgetOptionIncludeTransactionTypes &&  <div className="form-group ">
                                                <label>Select Transaction Types</label>
                                                {this.props.config.TransactionTypes.filter(f => !f.IsDeleted && f.Module === "sales").map((m) =>
                                                    <div>
                                                        <input type="checkbox" className="pull-left" onClick={() => this.onTransactionTypeSelected(m.key)}></input>&nbsp;
                                                        <b>{m.TransactionType}</b>
                                                    </div>
                                                )}
                                            </div>}
                                        </div>}
                                        {this.state.selectedWidget ==="WidgetSpecificPaymentSales" && <div className="col-md-12">
                                            <div className="form-group ">
                                                    <label>Please select payment mode</label>
                                                    <select className="form-control"
                                                        value={this.state.widgetOptionPaymentModeID}
                                                        onChange={(e)=> {this.setState({widgetOptionPaymentMode:e.target.options[e.target.selectedIndex].text, widgetOptionPaymentModeID:e.target.value, paymentModeError:""})}}>
                                                        <option key={"select"} value={-1}>Select</option>
                                                        {this.props.config.PaymentTypes.filter( f=> !f.IsDeleted && f.ShowInSales ).map((m) =>
                                                            <option key={m.key} value={m.key}>{m.PaymentType}</option>
                                                        )}
                                                    </select>
                                            </div>
                                        </div>}
                                        {(this.state.selectedWidget === "WidgetTopCategories" || 
                                            this.state.selectedWidget === "WidgetTopSubCategories" ||
                                            this.state.selectedWidget === "WidgetTopBrands" ||
                                            this.state.selectedWidget === "WidgetCustomersTop" || 
                                            this.state.selectedWidget === "WidgetTopEmployees" ||
                                            this.state.selectedWidget === "WidgetTopProductType") && <div className="col-md-12">
                                                <label>Show top</label>&nbsp;
                                                <input type="number" style={{ width: "50px"}}
                                                    onChange={(evt) => this.setState({ widgetOptionTopX: evt.target.value})}
                                                    value={this.state.widgetOptionTopX}
                                                /> &nbsp;
                                                {this.state.selectedWidget === "WidgetTopProductType" && <select 
                                                    value={this.state.widgetOptionProductType}
                                                    onChange={(evt)=> this.setState({widgetOptionProductType:evt.target.value})}>
                                                    <option value="goods for sale">goods for sale</option>
                                                    <option value="services">services</option>
                                                    <option value="wallet recharge">wallet recharge</option>
                                                    <option value="packages">packages</option>
                                                </select>}&nbsp;
                                                 <label>{this.state.selectedWidget === "WidgetCustomersTop" ? "customers" : ""}</label>
                                                <label>{this.state.selectedWidget === "WidgetTopEmployees" ? "employees" : ""}</label>
                                                <label>{this.state.selectedWidget === "WidgetTopCategories" ? "categories" : ""}</label>
                                                <label>{this.state.selectedWidget === "WidgetTopSubCategories" ? "sub categories" : ""}</label>
                                                <label>{this.state.selectedWidget === "WidgetTopBrands" ? "brands" : ""}</label>&nbsp;
                                                <label>by</label>&nbsp;
                                                <select 
                                                    value={this.state.widgetOptionAmtOrQty}
                                                    onChange={(evt)=> this.setState({widgetOptionAmtOrQty:evt.target.value})}>
                                                    <option value="amount">sales amount</option>
                                                    <option value="qty">sales qty</option>
                                                </select>
                                            </div>}
                                            {(this.state.selectedWidget ==="WidgetTotalSales"
                                           || this.state.selectedWidget ==="WidgetTotalTransactions" 
                                           || this.state.selectedWidget ==="WidgetTotalCustomers" 
                                           || this.state.selectedWidget ==="WidgetTotalExpenses" 
                                           || this.state.selectedWidget ==="WidgetTotalCreditSales" 
                                           || this.state.selectedWidget ==="WidgetTotalReceived" 
                                           || this.state.selectedWidget ==="WidgetTotalCreditReceived"
                                           || this.state.selectedWidget === "WidgetSpecificPaymentSales")
                                        && <React.Fragment>
                                            <div className="col-md-6 col-xs-12">
                                                <div className={this.state.colorError!=="" ? "form-group has-error" : "form-group "} >
                                                    <label>Image</label>
                                                    {this.state.iconError!=="" && <span className="help-block">{this.state.iconError}</span>}
                                                    {this.state.icons.map((i)=> <div>
                                                        <input type="radio" value={i.Icon} 
                                                                    checked={this.state.widgetOptionLeftBlockIcon === i.Icon} 
                                                                    onChange={(evt)=>{this.setState({widgetOptionLeftBlockIcon:evt.target.value,iconError:""})}} />
                                                        &nbsp;<i className={i.Icon} ></i> &nbsp; {i.Name} 
                                                    </div>)}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                            <div className={this.state.colorError!=="" ? "form-group has-error" : "form-group "} >
                                                <label>Color</label>
                                                {this.state.colorError!=="" && <span className="help-block">{this.state.colorError}</span>}
                                                <SketchPicker
                                                    color={ this.state.widgetOptionLeftBlockColor }
                                                    onChangeComplete={ this.onColorSelection}
                                                />
                                            </div>
                                        </div>
                                        </React.Fragment>}
                                            <div className="col-md-12">
                                                 <button type="submit" className="btn btn-default pull-right btn-flat"
                                                 disabled={this.state.selectedWidgetIndex ===-1} 
                                                 onClick={this.onAddWidget}>Add</button>
                                            </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-md-12">
                                            <label>Selected widgets</label>
                                        </div>
                                        {!this.state.showWidgetRemoveOption && <React.Fragment>
                                            {this.state.selectedWidgets.length > 0 &&<div className="col-md-12" style={{paddingBottom:"10px"}}>
                                                <button type="submit" className="btn btn-default pull-right btn-flat"
                                                 onClick={()=>this.setState({showWidgetRemoveOption:true})}>Remove Widgets</button>
                                            </div>}
                                            <div className="col-md-12">
                                                <SortableList items={this.state.selectedWidgets} onSortEnd={this.onSortEnd} distance={30}/>
                                            </div>
                                        </React.Fragment>}
                                        {this.state.showWidgetRemoveOption && <React.Fragment> <div className="col-md-12" style={{paddingBottom:"10px"}}>
                                                <button type="submit" className="btn btn-default pull-right btn-flat"
                                                 onClick={()=>this.setState({showWidgetRemoveOption:false})}>Cancel</button>
                                                <button type="submit" className="btn btn-default pull-right btn-flat"
                                                 onClick={this.onRemoveSelectedWidgets}>Remove Selected Widgets</button>
                                        </div>
                                        <div className="col-md-12">
                                            {this.state.selectedWidgets.map((widget, index)=><div key={"o"+ index}
                                            style={{height:"50px"}}
                                            className="btn btn-block btn-default btn-flat" 
                                            >
                                                <input type="checkbox" className="pull-left" onClick={()=>this.onWidgetSelectedForRemoval(index)}></input><b>Title:{widget.Title}</b><br></br><div><i>Widget:{widget.Name}</i></div>
                                            </div>)}
                                        </div>
                                        </React.Fragment>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
    }
}

export default DashboardSettings