import React from 'react';
import './FormSection.css';

export default function FormSection(props){
    return <React.Fragment>
    <div className="form-section">
        {props.title}
    </div>
    <div className="form-section-content">
        {props.children}
    </div>
</React.Fragment>}