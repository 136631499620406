import React, { Component } from 'react';
import Input from '../2/Controls/Input';
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Button from '../2/Controls/Button';

class EmployeeIncentiveReport extends Component {

    constructor(){
        super();
        this.state={
            employeeComissionPercentage:100,
            fromDate: new Date().toISOString().split('T')[0],
            toDate: new Date().toISOString().split('T')[0],
            transactionTypeID:"",
            transactionTypeIDError:"",
            employeeProductTypeWiseSales:[],
            selectedTransactionTypes:[]
        }
        this.loadData= this.loadData.bind(this);
    }

    componentDidMount(){
        let selectedTransactionTypes=[];
        this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) =>{
            selectedTransactionTypes.push({
                ...e,
                Selected:false,
                Percentage:100
            })
        })
        this.setState({selectedTransactionTypes:selectedTransactionTypes})
    }

    async loadData(){
        
        // if(this.state.transactionTypeID===""){
        //     this.setState({transactionTypeIDError:"Please select"});
        //     return;
        // }

        // console.log("this.state.transactionTypes.filter(f=>f.Selected)",this.state.selectedTransactionTypes.filter(f=>f.Selected))
        let employees = await this.props.getEmployees(
            this.props.user.user.RegistrationID, 
            this.props.user.store.key, 
            0, 
            1000,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "");
        console.log("employees",employees);
        let selectedTransactionTypesSearch = this.state.selectedTransactionTypes.filter(f=>f.Selected);
        let result =  await this.props.getEmployeeTransactionTypeProductTypeWise(
            this.props.user.user.RegistrationID, 
            [{key: this.props.user.store.key}], 
            this.state.fromDate, 
            this.state.toDate,
            selectedTransactionTypesSearch
        );
        console.log("result",result);

        let employeeProductTypeWiseSales = result.searchResult;
        employeeProductTypeWiseSales.map(e=>{
            let employeeName = "";
            let totalSales = 0;
            let totalApplicableSalesCommissionAmount = 0;
            if(employees.searchResult.filter(f=> f.key === e.EmployeeID).length>0){
                let emp = employees.searchResult.filter(f=> f.key === e.EmployeeID)[0];
                employeeName = emp.EmployeeFirstName +  " " + emp.EmployeeLastName;
            }
            e.EmployeeName = employeeName;
            e.TransactionTypes.map(t=>{
                totalSales = Number(totalSales) + Number(t.TotalSalesAmount);
                totalApplicableSalesCommissionAmount = Number(totalApplicableSalesCommissionAmount) + Number(t.TotalApplicableSalesCommissionAount)
            })
            e.TotalSalesAmount = totalSales;
            e.TotalApplicableSalesCommissionAount = totalApplicableSalesCommissionAmount

        })
        console.log("employeeProductTypeWiseSales",employeeProductTypeWiseSales)
        console.log("selectedTransactionTypesSearch",selectedTransactionTypesSearch)
        this.setState({employeeProductTypeWiseSales:employeeProductTypeWiseSales, selectedTransactionTypesSearch:selectedTransactionTypesSearch})
    }

    render(){
        return <Page 
        title="Employee Incentive" 
        showSaveButton={false} 
        showCancelButton={false}
        processing={this.state.saving}
        onClick={(action)=>{}}
        >
            <PageSection>
                <div className='row'>
                    {/* <div className='col-md-3'>
                        <Input 
                            type="select"
                            label="Sales Transaction"
                            value={this.state.transactionTypeID}
                            onChange={(value)=>this.setState({transactionTypeID:value,transactionTypeIDError:""})}
                            error={this.state.transactionTypeIDError}
                        >
                            <option value={""}>Please select</option>
                            {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                        </Input>
                    </div> */}
                    <div className='col-md-3'>
                        <Input
                            type="date"
                            label="From Date"
                            value={this.state.fromDate}
                            onChange={(value)=> this.setState({fromDate:value})}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Input
                            type="date"
                            label="To Date"
                            value={this.state.toDate}
                            onChange={(value)=> this.setState({toDate:value})}
                        />
                    </div>
                    {/* <div className='col-md-2'>
                        <Input
                            type="number"
                            label="Employee Comission %"
                            value={this.state.employeeComissionPercentage}
                            onChange={(value)=> this.setState({employeeComissionPercentage:value})}
                        />
                    </div> */}
                   
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <table className='table table-bordered table-striped'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Sales Transactions</th>
                                    <th>Sales amount % to be considred for incentive</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.selectedTransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e,index) =><tr>
                                <td><Input type="checkbox" 
                                    value={e.Selected}
                                    onChange={(value)=>{
                                        let selectedTransactionTypes= this.state.selectedTransactionTypes;
                                        selectedTransactionTypes[index].Selected = value;
                                        this.setState({selectedTransactionTypes:selectedTransactionTypes})
                                    }}/>
                                </td>
                                <td>{e.TransactionType}</td>
                                <td>{e.Selected && <Input type="number" 
                                    value={e.Percentage}
                                    onChange={(value)=>{
                                        let selectedTransactionTypes= this.state.selectedTransactionTypes;
                                        selectedTransactionTypes[index].Percentage = value;
                                        this.setState({selectedTransactionTypes:selectedTransactionTypes})
                                    }}
                                />}</td>
                            </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-2'>
                        <Button type="primary" text="Search" onClick={this.loadData}/>
                    </div>
                </div>        
                <div className='row'>
                    <div className='col-md-12'>
                    {this.state.employeeProductTypeWiseSales.length > 0 && <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Employee</th>
                                    {this.state.selectedTransactionTypesSearch.map(t=><th colSpan={6}>{t.TransactionType}</th>)}
                                    <th rowSpan={2}>Total Sales Amt</th>
                                    <th rowSpan={2}>Total Amt Applicable For Sales Commission</th>
                                </tr>
                                <tr>
                                    {/* <th></th> */}
                                    {this.state.selectedTransactionTypesSearch.map(t=><React.Fragment>
                                        <th>Bill Count</th>
                                        <th>Services Done Amt </th>
                                        <th>Product Sold Amt </th>
                                        <th>Package Sold Amt </th>
                                        <th>Wallet Recharge Amount </th>
                                        <th>Total Sales Amt</th>
                                        <th>Total Amt Applicable For Sales Commission</th>
                                    </React.Fragment>)}
                                    {/* <th></th>
                                    <th></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.employeeProductTypeWiseSales.map((empProd) =>
                                    <tr>
                                        <td >{empProd.EmployeeName}</td>
                                        {empProd.TransactionTypes.map(t=><React.Fragment>
                                        <td style={{textAlign:"right"}}>{t.TransactionCount}</td>
                                        <td style={{textAlign:"right"}}>{t.ServicesDoneAmount}</td>
                                        <td style={{textAlign:"right"}}>{t.ProductSoldAmount}</td>
                                        <td style={{textAlign:"right"}}>{t.PackageSoldAmount}</td>
                                        <td style={{textAlign:"right"}}>{t.WalletSoldAmount}</td>
                                        <td style={{textAlign:"right"}}>{t.TotalSalesAmount}</td>
                                        <td style={{textAlign:"right"}}>{t.TotalApplicableSalesCommissionAount}</td>
                                    </React.Fragment>)}
                                    <td style={{textAlign:"right"}}>{empProd.TotalSalesAmount}</td>
                                    <td style={{textAlign:"right"}}>{empProd.TotalApplicableSalesCommissionAount}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>}
                        {this.state.employeeProductTypeWiseSales.length === 0 && "No records found"}
                    </div>
                </div>
            </PageSection>
        </Page>
    }
}

export default EmployeeIncentiveReport;
