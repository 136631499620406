import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'

export function getAccounts(registrationID) {
    return (dispatch) => {
        return database.collection("Accounts").where("RegistrationID", "==", registrationID).get().then((querySnapshot) => {
            const accounts = []
            querySnapshot.forEach((doc) => {
                accounts.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            return accounts
        }).catch((error) => { console.log(error) })
    }
}


export function saveAccount(account, key) {
    return (dispatch, getState) => {
        account.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        account.ActionBy = getState().user.user.Name
        account.ActionByUID = getState().user.user.UID
        account.ActionByEmailID = getState().user.user.EmailID

        if (key !== "") {
            account.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Accounts").doc(key).update({
                ...account
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating account: ", error);
            })
        }
        else {
            account.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("Accounts").add({
                ...account
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding account: ", error);
                })
        }
    }
}

export function deleteAccount(key) {
    return (dispatch, getState) => {
        return database.collection("Accounts").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted: true
        }).then(() => { return true })
    }
}

export function getAccountDetails(key) {
    return dispatch => {
        return database.collection("Accounts").doc(key).get().then((doc) => {
            if (doc.exists) {
                // console.log("account found", doc.data());
                return doc.data()
            } else {
                console.log("No such account!");
            }
        })
    }
}


export function checkIfAccountIsUnique(account, registrationID, stores) {
    return (dispatch) => {
        let accountIsUnique = true;
        return database.collection("Accounts").where("RegistrationID", "==", registrationID).where("Name", "==", account).where("IsDeleted", "==", false).limit(1).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                if (doc.data().AllStores) {
                    accountIsUnique = false;
                    return accountIsUnique;
                }
                else {
                    if (doc.data().Stores.length > 0 && stores.length === 0) {
                        accountIsUnique = false;
                        return accountIsUnique;
                    }
                    for (let index = 0; index < doc.data().Stores.length; index++) {
                        const store = doc.data().Stores[index];

                        for (let index1 = 0; index1 < stores.length; index1++) {
                            const associatedStore = stores[index1];

                            if (associatedStore.value === store.value) {
                                accountIsUnique = false;
                                return accountIsUnique;
                            }
                        }
                    }
                }
            })
            return accountIsUnique
        }).catch((error) => {
            console.log(error)
        })
    }
}