import React, { Component } from 'react'
import HeaderNew from '../HeaderNew'
import Spinner from '../Common/Spinner'
import Filter from '../Common/Filter'
import RecordNotFound from '../Common/RecordNotFound';
import '../Common/Common.css'
import InfiniteScroll from 'react-infinite-scroller'
import ListSearch from '../Common/ListSearch'
import TabStrip from '../Common/TabStrip'
import PopupMenu from '../Common/PopupMenu';
import * as actionTypes from '../../actionTypes';

class DeliveryListNew extends Component {

    constructor() {
        super()
        this.state = {
            showFilter: false,
            filterOptions: [
                { label: "Customer First Name", type: "text", value: "", field: "DeliveryCustomer.FirstName" },
                { label: "Customer Last Name", type: "text", value: "", field: "DeliveryCustomer.LastName" },
                { label: "Customer Email", type: "text", value: "", field: "DeliveryCustomer.EmailID" },
                { label: "Customer Phone No", type: "text", value: "", field: "DeliveryCustomer.PhoneNo" },
                { label: "Customer Code", type: "text", value: "", field: "DeliveryCustomer.Code" },                
                { label: "Transaction No", type: "text", value: "", field: "TransactionNo" },
                { label: "Delivery From", type: "date", range:"from" , value: new Date().toISOString().split('T')[0], field: "DeliveryDate" },
                { label: "Delivery To", type: "date", range: "to", value: new Date().toISOString().split('T')[0], field: "DeliveryDate" },
                { label: "Employee Name", type: "text", value: "", field: "DeliveryOutForDeliveryEmployee" },
            ],
            selectedTabIndex:0,
            tabButtons:[
                {text:"PENDING"},
                {text:"CONFIRMED"},
                {text:"OUT FOR DELIVERY"},
                {text:"READY FOR PICKUP"},
                {text:"DELIVERED"},
            ],
            searchPlaceHolder: 'Search by customer or transactionno',
            title: 'Delivery Tracker',
            list: [],
            loading: true,
            recordFrom: 0,
            itemsCountPerPage: 20,
            totalItemsCount: 0, 
            searchText: '',
            currentPage: 0,
            scrolling: false,
            selectedDelivery: null,
            showPopupMenu: false,
            popButtons:[],
        }
        this.load = this.load.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onHeaderBackClick = this.onHeaderBackClick.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.onShowPopupMenuClick = this.onShowPopupMenuClick.bind(this)
        this.onTabSelected= this.onTabSelected.bind(this)
    }

    async componentDidMount() {
        this.load(this.state.recordFrom,
            this.state.selectedTabIndex,
            this.state.searchText,
            this.state.filterOptions)
    }

    load(from, option, searchText, filterOptions) {
        this.setState({ loading: true })
        this.props.getData('getDeliveryList', from, this.state.itemsCountPerPage, this.state.tabButtons[option].text.toLowerCase(), searchText, filterOptions)
            .then((result) => {
                this.setState({ list: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
            })
    }

    onTabSelected(selectedTabIndex){
        let selectedTab = this.state.tabButtons[selectedTabIndex].text;
        this.setState({ selectedTabIndex: selectedTabIndex, selectedTab: selectedTab })
        this.load(0, selectedTabIndex, this.state.searchText, this.state.filterOptions);
    }

    async handleScroll() {
        this.setState({ scrolling: true })
        let pageNumber = Number(this.state.currentPage) + 1;
        let recordFrom = ((pageNumber - 1)  * this.state.itemsCountPerPage);
        this.setState({ recordFrom: recordFrom, currentPage: pageNumber});
        let result = await  this.props.getData('getDeliveryList', recordFrom, this.state.itemsCountPerPage, this.state.selectedTabIndex, this.state.searchText, this.state.filterOptions);
        // console.log(result);
        let list = this.state.list;
        // console.log(products.length);
        if(result && result.searchResult && result.searchResult.length > 0)
        {
            for (let index = 0; index < result.searchResult.length; index++) {
                const obj = result.searchResult[index];
                list.push(obj);
            }
            this.setState({list: list})
        }
        this.setState({ scrolling: false })
    }

    async onShowPopupMenuClick(value)
    {
        let selectedSale = this.state.selectedDelivery;
        console.log('value')
        if(value === "edit")
        {
            let saleEdit = await this.props.getSaleDetails(selectedSale.key)
            saleEdit.key = selectedSale.key

            this.props.history.push(
                {
                    pathname: '/home/sales/sales',
                    state: {
                        type: actionTypes.SALE_EDIT,
                        key: selectedSale.key,
                        transactionType: saleEdit.TransactionType,
                        sale: saleEdit
                    }
                })
            return;
        }
        else if(value === "confirmed")
        {
            this.setState({loading:true, showPopupMenu: false})
            await this.props.orderConfirmed(selectedSale,selectedSale.DeliveryCustomer ? selectedSale.DeliveryCustomer.PhoneNo: "")
            // return;
            // this.props.history.push({ pathname: '/home/sales/delivery/outfordelivery', state: { module: "outfordelivery" , selectedSale: selectedSale } })
        }
        else if(value === "outfordelivery")
        {
            this.props.history.push({ pathname: '/home/sales/delivery/outfordelivery', state: { module: "outfordelivery" , selectedSale: selectedSale } })
            return;
        }
        else if(value === "readyforpickup")
        {
            this.props.history.push({ pathname: '/home/sales/delivery/outfordelivery', state: { module: "readyforpickup" , selectedSale: selectedSale } })
            return;
            
        }
        else if(value === "delivered")
        {
            this.props.markDelivered(selectedSale).then(async () => {
                if(selectedSale.BalanceAmount> 0)
                {
                    //get sales details
                    let saleEdit = await this.props.getSaleDetails(selectedSale.key)
                    saleEdit.key = selectedSale.key
                    this.props.history.push({
                        pathname: '/home/sales/editsales/',
                        state: {
                          type: actionTypes.SALE_EDIT,
                          key: saleEdit.key,
                          transactionType: saleEdit.TransactionType,
                          sale: saleEdit,
                          show:"payment"
                        }
                      })
                    return;
                }
                // else {
                    
                // }
            })
        }
        else if(value==="call")
        {
            window.open('tel:' + selectedSale.DeliveryCustomer.PhoneNo)
            this.setState({ showPopupMenu: false, selectedSale: null })

            return;
        }
        // else
        // {
        //     this.setState({ showPopupMenu: false, selectedSale: null })
        // }
        this.setState({ showPopupMenu: false, selectedSale: null })
        if(value !== ""){
            setTimeout(function () {
                this.props.getToastr("Delivery status updated!!!")
                this.setState({ recordFrom: 0 })
                this.load(0,
                    this.state.selectedTabIndex,
                    this.state.searchText,
                    this.state.filterOptions);
            }.bind(this), 3000)
        }   
    }


    onRowClick(selectedDelivery) {
        let popButtons = [];
        if(this.state.selectedTabIndex === 0)
        {
            popButtons.push({ value: "edit", text: "Edit", module: 'delivery' },
                { value: "confirmed", text: "Confirm", module: 'confirmed' },
                { value: "outfordelivery", text: "Out For Delivery", module: 'delivery' },
                { value: "readyforpickup", text: "Ready For Pickup", module: 'delivery' },
                { value: "delivered", text: "Delivered", module: 'delivery' }
            )
        }
        if(this.state.selectedTabIndex === 1)
        {
            popButtons.push({ value: "edit", text: "Edit", module: 'delivery' },
                { value: "outfordelivery", text: "Out For Delivery", module: 'delivery' },
                { value: "readyforpickup", text: "Ready For Pickup", module: 'delivery' },
                { value: "delivered", text: "Delivered", module: 'delivery' }
            )
        }
        else if(this.state.selectedTabIndex === 2)
        {
            popButtons.push({ value: "edit", text: "Edit", module: 'delivery' },
                { value: "delivered", text: "Delivered", module: 'delivery' }
            )
        }
        else if(this.state.selectedTabIndex === 3)
        {
            popButtons.push({ value: "delivered", text: "Delivered", module: 'delivery' })
        }
        if(selectedDelivery.DeliveryCustomer && selectedDelivery.DeliveryCustomer.PhoneNo!==""){
            popButtons.push({ value: "call", text: "Call +91" + selectedDelivery.DeliveryCustomer.PhoneNo, module: 'delivery' })

        }
        
        this.setState({ selectedDelivery: selectedDelivery, showPopupMenu: true, popButtons });
    }

    onHeaderBackClick() {
        this.props.history.push({ pathname: '/home' })
    }


    renderDeliveryAddress(p){
        let address = p.DeliveryCustomerAddress.Address1;
        address = address +  (address !=="" && p.DeliveryCustomerAddress.Address2 !=="" ? ", " : "") + p.DeliveryCustomerAddress.Address2;
        address = address +  (address !=="" && p.DeliveryCustomerAddress.City !==""  ? ", " : "") + p.DeliveryCustomerAddress.City;
        address = address +  (address !=="" && p.DeliveryCustomerAddress.State !=="" ? ", " : "") + p.DeliveryCustomerAddress.State;
        address = address +  (address !=="" && p.DeliveryCustomerAddress.Country !=="" ? ", " : "") + p.DeliveryCustomerAddress.Country;
        address = address +  (address !==""  && p.DeliveryCustomerAddress.PostalCode !==""? ", " : "") + p.DeliveryCustomerAddress.PostalCode;

       return <div className="delivery-address">
            <div className="row-sub-text ">{p.DeliveryCustomerAddress.AddressType}</div>
            <div className="row-sub-text ">{address}</div>
        </div>

    }

    renderLineItems(delivery,index){
        return <React.Fragment>
            <div 
                onClick={()=>this.setState({selectedIndex:this.state.selectedIndex ===index ? -1 : index})} 
                className="delivery-lineitems">Products ordered &nbsp;
                {this.state.selectedIndex ===index && <i className="fa fa-caret-up"></i>}
                {this.state.selectedIndex !==index && <i className="fa fa-caret-down"></i>}

                {this.state.selectedIndex === index && <React.Fragment>
                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {delivery.LineItems.map(m => <tr>
                                <td>{m.Product.Name}<div><i>{m.Notes}</i></div></td>
                                <td style={{textAlign:"right"}}>{m.Qty}</td>
                            </tr>)}
                            <tr>
                                <td><b>Total Qty:</b></td>
                                <td style={{textAlign:"right"}}><b>{delivery.BillQty}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment>}
                
            </div>
           
        </React.Fragment>
    }

    render() {
        return <React.Fragment>
            <HeaderNew {...this.props}
                back={true}
                title={this.state.title}
                filter={true}
                onBackClick={() => this.onHeaderBackClick()}
                onFilterClick={() => this.setState({ showFilter: !this.state.showFilter })}
            />
            <div className="header-new-background"></div>
            {/* {!this.state.showFilter &&  */}
            <div className="content-wrapper" >
                <div className="container">

                    <ListSearch 
                            value = {this.state.searchText}
                            onChange={(searchText) => {
                                if (searchText.length > 2)
                                    this.load(this.state.recordFrom,
                                    this.state.selectedTabIndex,
                                    searchText,
                                    this.state.filterOptions)

                                if (searchText.length === 0)
                                    this.load(this.state.recordFrom,
                                    this.state.selectedTabIndex,
                                    "",
                                    this.state.filterOptions)

                                this.setState({ searchText: searchText, recordFrom: 0, currentPage: 0 })
                        }} 
                        searchPlaceHolder={this.state.searchPlaceHolder} />

                        <div style={{marginTop:"75px"}}>
                        <TabStrip tabButtons={this.state.tabButtons}
                            selectedTabIndex={this.state.selectedTabIndex}
                            onTabSelected={(index)=>{
                                this.setState({selectedTabIndex:index,recordFrom:0});
                                this.load(0,
                                    index,
                                    this.state.searchText,
                                    this.state.filterOptions)
                            }}
                            />
                        </div>

                    {this.state.loading && <Spinner />}
                    {this.state.list.length > 0 && <React.Fragment>
                        
                            <div className="list" style={{ height: "600px", overflowY: "auto", paddingTop: "6px" }}>
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.handleScroll}
                                    hasMore={this.state.recordFrom < this.state.totalItemsCount}
                                    threshold={100}
                                    initialLoad={false}
                                    useWindow={false}>
                                    {this.state.list.map((p, index) => <div className="list-row seperator" >
                                        <div style={{width:"100%"}}>
                                            <div onClick={() => this.onRowClick(p)}>
                                            <div className="row-title ">{p.TransactionNo}</div>
                                            <div>Order Date: {(new Date(p.TransactionDate)).getDate()} {(new Date(p.TransactionDate)).toLocaleString('default', { month: 'short' })}
                                            {/* // toISOString().split('T')[0])} */}
                                            </div>
                                            {/* <div className="row-sub-text ">Delivery Date: {p.DeliveryDate}</div> */}
                                            <div className="row-sub-text">Delivery Date: {(new Date(p.DeliveryDate)).getDate()} {(new Date(p.DeliveryDate)).toLocaleString('default', { month: 'short' })} &nbsp; {p.DeliveryTime.HH !=="" &&<span>{p.DeliveryTime.HH}:{p.DeliveryTime.MM}</span>}</div>
                                            {/* <div className="row-sub-text ">Delivery Time: {p.DeliveryTime.HH !=="" &&<span>{p.DeliveryTime.HH}:{p.DeliveryTime.MM}</span>}</div> */}
                                            {p.DeliveryCustomer && <div className="delivery-customer">
                                                <div className="row-sub-text ">{p.DeliveryCustomer.FirstName}{p.DeliveryCustomer.LastName}</div>
                                                <div className="row-sub-text ">{p.DeliveryCustomer.PhoneNo}</div>
                                            </div>}
                                            {p.DeliveryCustomerAddress && this.renderDeliveryAddress(p)}
                                            
                                            {/* {p.DeliveryCustomerAddress && <div className="delivery-address">
                                                <div className="row-sub-text ">{p.DeliveryCustomerAddress.AddressType}</div>
                                                <div className="row-sub-text ">{p.DeliveryCustomerAddress.Address1} {p.DeliveryCustomerAddress.Address2 !=="" ? ", " : " "} {p.DeliveryCustomerAddress.Address2}</div>
                                                <div className="row-sub-text ">{p.DeliveryCustomerAddress.City} {p.DeliveryCustomerAddress.State!=="" ? ", " : " "} {p.DeliveryCustomerAddress.State}</div>
                                                <div className="row-sub-text ">{p.DeliveryCustomerAddress.Country}</div>
                                                <div className="row-sub-text ">{p.DeliveryCustomerAddress.PostalCode}</div>
                                                <div className="row-sub-text "></div> */}
                                            {/* </div>} */}
                                            </div>
                                            {this.renderLineItems(p,index)}
                                            <div className="row-sub-text"> Amount: ₹ {p.BillAmount} <span className={p.BalanceAmount > 0 ? "delivery-balance" : "delivery-paid"}>{p.BalanceAmount > 0 ? "Balance Amount: ₹" + p.BalanceAmount : "Paid"}</span></div>
                                            {p.DeliveryOutForDeliveryEmployee && <div className="row-sub-text delivery-by">Delivery By : {p.DeliveryOutForDeliveryEmployee}</div>}
                                        </div>
                                        
                                    </div>)}
                                </InfiniteScroll>
                                {this.state.scrolling && <Spinner />}
                            </div>
                        </React.Fragment>}
                    {this.state.list.length===0 && <div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <RecordNotFound/>
                    </div>}
                </div>
            </div>
        
            <PopupMenu
                show={this.state.showPopupMenu}
                buttons={this.state.popButtons}
                onClick={(action) => this.onShowPopupMenuClick(action)} />

            {this.state.showFilter && <Filter filterOptions={this.state.filterOptions}
                onCloseClick={()=>this.setState({ showFilter: false})}
                onApplyClick={(filterOptions) => {
                    //console.log('filterOptions', filterOptions)
                    this.load(
                        0,
                        this.state.selectedTabIndex,
                        this.state.searchText,
                        filterOptions)
                    this.setState({ showFilter: false, filterOptions: filterOptions, recordFrom: 0, currentPage:0 })
                }}
                onClearClick={(filterOptions) => {
                    this.load(
                        0,
                        this.state.selectedTabIndex,
                        this.state.searchText,
                        { label: "Customer First Name", type: "text", value: "", field: "DeliveryCustomer.FirstName" },
                        { label: "Customer Last Name", type: "text", value: "", field: "DeliveryCustomer.LastName" },
                        { label: "Customer Email", type: "text", value: "", field: "DeliveryCustomer.EmailID" },
                        { label: "Customer Phone No", type: "text", value: "", field: "DeliveryCustomer.PhoneNo" },
                        { label: "Customer Code", type: "text", value: "", field: "DeliveryCustomer.Code" },                
                        { label: "Transaction No", type: "text", value: "", field: "TransactionNo" },
                        { label: "Delivery From", type: "date", range:"from" , value: new Date().toISOString().split('T')[0], field: "DeliveryDate" },
                        { label: "Delivery To", type: "date", range: "to", value: new Date().toISOString().split('T')[0], field: "DeliveryDate" },
                        { label: "Employee Name", type: "text", value: "", field: "DeliveryOutForDeliveryEmployee" }
                        )
                    this.setState({
                        showFilter: false, filterOptions: [
                            { label: "Customer First Name", type: "text", value: "", field: "DeliveryCustomer.FirstName" },
                            { label: "Customer Last Name", type: "text", value: "", field: "DeliveryCustomer.LastName" },
                            { label: "Customer Email", type: "text", value: "", field: "DeliveryCustomer.EmailID" },
                            { label: "Customer Phone No", type: "text", value: "", field: "DeliveryCustomer.PhoneNo" },
                            { label: "Customer Code", type: "text", value: "", field: "DeliveryCustomer.Code" },
                            { label: "Transaction No", type: "text", value: "", field: "TransactionNo" },
                            { label: "Delivery From", type: "date", range: "from", value: new Date().toISOString().split('T')[0], field: "DeliveryDate" },
                            { label: "Delivery To", type: "date", range: "to", value: new Date().toISOString().split('T')[0], field: "DeliveryDate" },
                            { label: "Employee Name", type: "text", value: "", field: "DeliveryOutForDeliveryEmployee" }
                        ], recordFrom: 0
                    })
                }}
            />}
        </React.Fragment>
    }
}

export default DeliveryListNew
