import axios from 'axios';

export function shadowfaxPlaceOrder(key)
{
    return (dispatch, getState) => {
      console.log("key",key)
       return axios
        .post(
            "https://us-central1-posible-app-prod.cloudfunctions.net/shadowfaxPlaceOrder",
            {key:key}
        )
        .then(res => {
            console.log('shadowfaxPlaceOrder', JSON.stringify(res.data));
            if(res.data.success){
              return {
                success:true,
                message:"",
                data:res.data.data
              }
            }else
            {
              return {
                success:false,
                message:res.data.message,
                data:null
              }
            }
        })
        .catch(err => {
            console.log('shadowfaxPlaceOrder', JSON.stringify(err));
            return {
              success:false,
              message:"Error while processing data",
              data:null
            }
        });
    }
}

export function shadowfaxUpdateOrder(key)
{
    return (dispatch, getState) => {
      console.log("shadowfaxUpdateOrder called")
      console.log("key",key);
       return axios.post("https://us-central1-posible-app-prod.cloudfunctions.net/shadowfaxUpdateOrder",{"key":key}
        ).then(res => {
            console.log('shadowfaxUpdateOrder result', JSON.stringify(res.data));
            if(res.data.success){
              return {
                success:true,
                message:"",
                data:res.data.data
              }
            }else
            {
              return {
                success:false,
                message:res.data.message,
                data:null
              }
            }
        })
        .catch(err => {
            console.log('shadowfaxUpdateOrder error', JSON.stringify(err));
            return {
              success:false,
              message:"Error while processing data",
              data:null
            }
        });
    }
}

export function shadowfaxCancelOrder(deliveryPartnerOrderID, cancellationReason)
{
    return (dispatch, getState) => {
      return axios.post(
        "https://us-central1-posible-app-prod.cloudfunctions.net/shadowfaxCancelOrder",
          {
            "deliveryPartnerOrderID": deliveryPartnerOrderID,
            "cancellationReason": cancellationReason
          }
      )
      .then(res => {
        console.log('shadowfaxCancelOrder',JSON.stringify(res.data));
        if(res.data.success){
          return {
            success:true,
            message:"",
            data:res.data.data
          }
        }else
        {
          return {
            success:false,
            message:res.data.message,
            data:null
          }
        }
      })
      .catch(err => {
          console.log('shadowfaxCancelOrder', JSON.stringify(err));
          return {
            success:false,
            message:"Error while processing data",
            data:null
          }
      });
    }
}
