import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import ImportMaster  from '../../ImportMaster'
import '../../Common/Common.css'
import Input from '../../2/Controls/Input';
import PageSection from '../../2/Controls/PageSection';
import Page from '../../2/Controls/Page'

class AddEditVendor extends Component {

    constructor() {
        super()
        this.state = {
            vendorCode: '',
            vendorFirstName: '',
            vendorLastName: '',
            // gender: '',
            emailID: '',
            phoneNo: '',
            storeID: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            customField6: '',
            vendorCodeError: '',
            vendorFirstNameError: '',
            action: '',
            key: '',
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            saving: false,
            isMobileApp:false,
            productsTypeAheadOptions: [],
            isProductTypeAheadLoading: false,
            productsTypeAheadSelected: [],
            vendorProducts:[],
            storeSpecificVendorPricing:false,
            selectAll:false,
            associatedStores:[],
            storeList:[],
            storeSpecificPrices:[],
            showStoreSpecificPricingwindow:false,
            states: [],
            stateCode: '',
        }
        this.onSaveVendor = this.onSaveVendor.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onProductSelected = this.onProductSelected.bind(this)
        // this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)
        this.onRemoveLineItem = this.onRemoveLineItem.bind(this)
        this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this)
        this.onLineItemDiscountChange = this.onLineItemDiscountChange.bind(this)
        this.onSelectAll = this.onSelectAll.bind(this)
        this.onLineItemSelected = this.onLineItemSelected.bind(this)
        this.onAddStoreSpecificPricing = this.onAddStoreSpecificPricing.bind(this)
        this.onImportCompletion = this.onImportCompletion.bind(this)
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this);
        this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
    }

    async componentDidMount() {

        let states = await this.props.getStateCodeMaster();
        this.setState({ states: states });

        const storeList = [];
        let result = await this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
        , "", "", "", "")
        let stores = result.searchResult;
        stores.map((store) => {
            let obj = {
                StoreID: store.key, StoreCode:store.StoreCode, StoreName: store.StoreName, Selected: this.props.user.store.key === store.key ? true : false
            }
            storeList.push(obj);
        });
        this.setState({ storeList: storeList })

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Vendor') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        let key = ""
        if(this.props.hasOwnProperty('source') && this.props.source ==="inventory")
        {
            key = this.props.vendor && this.props.vendor.key !=="" ? this.props.vendor.key : ""
        }
        else{
            // console.log('this.props.location', this.props.location)
            key = this.props.match.params.key;     
        }

        if(key && key!==""){
            let vendor = await this.props.getVendorDetails(key);//this.props.location.state.vendor
            let associatedStores= vendor.hasOwnProperty('AssociatedStores') ? vendor.AssociatedStores : []
            storeList.map(m=>{
                if(associatedStores.filter(f=> f.StoreID ===m.StoreID).length > 0)
                {m.Selected = true }
                else
                {m.Selected = false }
            })
            // console.log('vendor', vendor)
            this.setState({
                key: key,
                vendorCode: vendor.VendorCode,
                vendorFirstName: vendor.VendorFirstName,
                vendorLastName: vendor.VendorLastName,                
                emailID: vendor.EmailID,
                phoneNo: vendor.PhoneNo,
                storeID: vendor.StoreID,
                address1: vendor.Address1,
                address2: vendor.Address2,
                city: vendor.City,
                state: vendor.State,
                stateCode: vendor.hasOwnProperty('StateCode') ? vendor.StateCode : '',
                postalCode: vendor.PostalCode,
                country: vendor.Country,
                customField1: vendor.CustomField1,
                customField2: vendor.CustomField2,
                customField3: vendor.CustomField3,
                customField4: vendor.CustomField4,
                customField5: vendor.CustomField5,
                customField6: vendor.CustomField6,
                associatedStores:associatedStores,
                vendorProducts:vendor.hasOwnProperty('VendorProducts') ? vendor.VendorProducts :[],
                storeList:storeList
            })
        }
        else
        {
            this.setState({storeID: this.props.user.store.key,key:""}) 
        }       
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveVendor()
        }
    }

    async onImportCompletion(importedRecords) {
        // let products = [];
        // let productsWithInStock = []

        // console.log(importedRecords)
        let errors=[]
        let vendorProductsToBeAdded = []
        for(let i=0;i<importedRecords.length;i++)
        {
            //check store code is valid 
            let productID="" 
            let productName="" 
            let productSKU=""
            let storeID=""
            let storeName=""
            let storeCode = ""
            let supplyPrice=0 
            let discount=0
            let error = ""
            // console.log('associatedStores', this.state.associatedStores)
            if(importedRecords[i].ProductSKU.trim()==="")
            {
                error="Product SKU missing"
                errors.push({RowNo:i+1,Error:error,})
                console.log('error',error)
                continue
            }
            if(importedRecords[i].SupplyPrice.trim()==="")
            {
                error="Supply Price missing"
                console.log('error',error)
                errors.push({RowNo:i+1,Error:error,})
                continue
            }

            
            if(importedRecords[i].StoreCode.trim()!=="")
            {
                if (this.state.storeList.filter(f => f.StoreCode === importedRecords[i].StoreCode.trim().replace("'", "") && f.Selected).length === 0) {
                    error = "Store not found"
                    console.log('error',error)
                    errors.push({ RowNo: i + 1, Error: error, })
                    continue
                }
                else {
                    let store = this.state.storeList.filter(f => f.StoreCode === importedRecords[i].StoreCode.trim().replace("'", "") && f.Selected)[0]
                    storeID = store.StoreID
                    storeName = store.StoreName
                    storeCode = store.StoreCode
                }
            }
            //check if valid product 
            let results = await this.props.getProductsByBarCode(this.props.user.user.RegistrationID, 0,1, importedRecords[i].ProductSKU.trim())
            let products = results.searchResult
            if(products.length===0)
            {
                error="Product not found"
                // console.log('error',error)
                errors.push({RowNo:i+1,Error:error,})
                continue
            }
            else
            {
                productID = products[0].key
                productSKU= products[0].SKU
                productName = products[0].Name
            }
            supplyPrice = importedRecords[i].SupplyPrice
            discount = importedRecords[i].Discount
            vendorProductsToBeAdded.push({ProductID: productID, 
                ProductName:productName, 
                ProductSKU: productSKU, 
                StoreID:storeID, 
                StoreCode:storeCode,
                StoreName:storeName, 
                SupplyPrice:supplyPrice, 
                Discount:discount})
        }      
        // console.log('errors', errors)
        if(errors.length > 0)
        {
           
            this.setState({importErrors:errors})
            return errors
        }
        let vendorProducts = this.state.vendorProducts;
        // console.log('vendorProductsToBeAdded', vendorProductsToBeAdded);
        for(let k=0;k<vendorProductsToBeAdded.length;k++)
        {
            if(vendorProducts.filter(f=> f.StoreID === vendorProductsToBeAdded[k].StoreID && f.ProductID === vendorProductsToBeAdded[k].ProductID).length> 0)
            {
                //update existing 
                // let index = -1
                vendorProducts.map(f=>{
                    if(f.StoreID === vendorProductsToBeAdded[k].StoreID && f.ProductID === vendorProductsToBeAdded[k].ProductID)
                    {
                        f.SupplyPrice = vendorProductsToBeAdded[k].SupplyPrice
                        f.Discount = vendorProductsToBeAdded[k].Discount
                    }
                })                 
            }
            else
            {
                vendorProducts.push(vendorProductsToBeAdded[k])
            }
        }
        this.setState({vendorProducts:vendorProducts, showImport:false})
        return errors
    }

    onProductTypeAheadSearch = (query) => {

        let excludeUniqueNo =  true;

        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProductsForInventorySearch(this.props.user.user.RegistrationID, query, excludeUniqueNo)
            .then((result) => {
                let productSearchResult = []
                let rowCount = 0
                for (let i = 0; i < result.searchResult.length; i++) {
                    if (rowCount > 10) {
                        break
                    }
                    let e = result.searchResult[i]

                    if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                        if (e.AccessibleToSelectedStores) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.MRP = storePrice[0].MRP
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                productSearchResult.push(e)
                                rowCount = rowCount + 1
                            }
                            continue
                        }
                    }
                    if (e.hasOwnProperty('StoreSpecificPricing')) {
                        if (e.StoreSpecificPricing) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                e.MRP = storePrice[0].MRP
                            }
                        }
                    }
                    rowCount = rowCount + 1
                    productSearchResult.push(e)
                }
                this.setState({ productsTypeAheadOptions: productSearchResult })
                this.setState({ isProductTypeAheadLoading: false });
            })
    }

    onProductBarCodeSearch(scanedCode) {
        var productList = this.props.config.Products.filter((e) => e.SKU.toLowerCase() === scanedCode.toLowerCase() || e.BarCode.toLowerCase() === scanedCode.toLowerCase());
        let e = productList[0]

        if (e) {

            if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                if (e.AccessibleToSelectedStores) {
                    let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                    if (storePrice.length > 0) {
                        e.RetailPrice = storePrice[0].RetailPrice
                        e.SupplyPrice = storePrice[0].SupplyPrice
                        e.Markup = storePrice[0].Markup
                        e.MRP = storePrice[0].MRP
                        e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                        e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                        e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                        e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                        e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                    }
                }
            }
            if (e.hasOwnProperty('StoreSpecificPricing')) {
                if (e.StoreSpecificPricing) {
                    let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                    if (storePrice.length > 0) {
                        e.RetailPrice = storePrice[0].RetailPrice
                        e.SupplyPrice = storePrice[0].SupplyPrice
                        e.Markup = storePrice[0].Markup
                        e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                        e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                        e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                        e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                        e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                        e.MRP = storePrice[0].MRP
                    }
                }
            }
            this.setState({ queryText: '' });
            this.props.onChange(e);
        }
    }


    onSaveVendor() {

        let vendorCodeError = ''
        let vendorFirstNameError = ''
        this.setState({ saving: true })

        if (this.state.vendorCode.trim() === '') { vendorCodeError = 'Please provide vendor code' }
        if (this.state.vendorFirstName.trim() === '') { vendorFirstNameError ='Please provide vendor name' }

        if (vendorCodeError!=="" || vendorFirstNameError!=="") {
            this.setState({ vendorCodeError: vendorCodeError, vendorFirstNameError: vendorFirstNameError, saving: false })
            return
        }
        let associatedStores = this.state.storeList.filter(f=> f.Selected)
        let state ="";
        if(this.state.stateCode!==""){
            if(this.state.states.filter(f=>f.code.toString()  ===this.state.stateCode.toString() ).length>0){
                state = this.state.states.filter(f=>f.code.toString() ===this.state.stateCode.toString())[0].name;
            }
        }
        // console.log('state', state)
        let vendor = {
            VendorCode: this.state.vendorCode,
            VendorFirstName: this.state.vendorFirstName,
            VendorLastName: this.state.vendorLastName,
            // Gender: this.state.gender,
            EmailID: this.state.emailID,
            PhoneNo: this.state.phoneNo,
            Address1: this.state.address1,
            Address2: this.state.address2,
            City: this.state.city,
            State: state,
            StateCode: this.state.stateCode,
            PostalCode: this.state.postalCode,
            Country: this.state.country,
            CustomField1: this.state.customField1,
            CustomField2: this.state.customField2,
            CustomField3: this.state.customField3,
            CustomField4: this.state.customField4,
            CustomField5: this.state.customField5,
            CustomField6: this.state.customField6,
            StoreID: associatedStores.length > 0 ? "" : this.props.user.store.key,
            RegistrationID: this.props.user.user.RegistrationID,
            IsDeleted: false,
            AssociatedStores: associatedStores,
            VendorProducts: this.state.vendorProducts
        }
        // console.log(vendor)
        // console.log('this.state.action',this.state.action)
        // console.log('this.state.key', this.state.key)
        if (this.state.key ==="") {
           
            this.props.addNewVendor(vendor).then((key) => {                
                //redirect to store view page
                // console.log('vendor saved',key)
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    this.props.getToastr("Vendor saved successfully");
                    if(this.props.hasOwnProperty("source") && this.props.source==="inventory")
                    {
                        vendor.key = key
                        this.props.onSaveVendor(vendor)
                    }
                    else
                    {

                        this.props.history.push({
                            pathname: "/home/settings/vendors",
                            state: { key: key }
                        })
                    }
                }.bind(this), 2000)
            })
        }
        else {
            this.props.updateVendor(vendor, this.state.key).then(() => {

                setTimeout(function () {
                    this.props.getToastr("Vendor saved successfully")
                    this.setState({ saving: false })
                    if(this.props.hasOwnProperty("source") && this.props.source==="inventory")
                    {
                        vendor.key = this.state.key
                        this.props.onSaveVendor(vendor)
                    }
                    else
                    {
                        this.props.history.push({
                            pathname: "/home/settings/vendors",
                            state: { key: this.state.key }
                        })
                    }
                    
                }.bind(this), 2000)
            })
        }
    }

    onRemoveLineItem(index){
        let vendorProducts = this.state.vendorProducts
        vendorProducts.splice(index, 1)
        this.setState({vendorProducts:vendorProducts})
    }

    onCancelClick() {
        if(this.props.hasOwnProperty("source") && this.props.source==="inventory")
        {
            this.props.onCancel()
        }
        else
        {
            this.props.history.push("/home/settings/vendors")
        }   
    }

    async onProductSelected(product) {
        // console.log('onProductSelected.product', product)
        // console.log('product', product)
        if(this.state.storeSpecificVendorPricing)
        {
            // console.log('storeSpecificVendorPricing',this.state.storeSpecificVendorPricing)
            let storeSpecificPrices = []
            this.state.storeList.filter(f=> f.Selected).map(m=>{
                storeSpecificPrices.push({StoreID: m.StoreID, StoreName:m.StoreName,SupplyPrice:0, Discount:0})
            })
            this.setState({showStoreSpecificPricingwindow:true, 
                selectedProduct:product, 
                storeSpecificPrices:storeSpecificPrices})
            return 
        }
        let vendorProducts = this.state.vendorProducts
        if(vendorProducts.filter(f=> product.key === f.ProductID && f.StoreID ==="").length> 0)
        {
            //product already added 
            // console.log('Product already added')
            return
        }
        vendorProducts.unshift({ProductID: product.key, ProductName:product.Name, ProductSKU: product.SKU, StoreID:"", StoreName:"", SupplyPrice:product.SupplyPrice, Discount:0})
        this.setState({vendorProducts:vendorProducts})
    }

    // onProductTypeAheadSearch = (query) => {

    //     let excludeUniqueNo =  true

    //     this.setState({ isProductTypeAheadLoading: true });
    //     this.props.getProductsForInventorySearch(this.props.user.user.RegistrationID, query, excludeUniqueNo)
    //         .then((result) => {
    //             let productSearchResult = []
    //             let rowCount = 0
    //             for (let i = 0; i < result.searchResult.length; i++) {
    //                 if (rowCount > 10) {
    //                     break
    //                 }
    //                 let e = result.searchResult[i]

    //                 if (e.hasOwnProperty('AccessibleToSelectedStores')) {
    //                     if (e.AccessibleToSelectedStores) {
    //                         let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
    //                         if (storePrice.length > 0) {
    //                             e.RetailPrice = storePrice[0].RetailPrice
    //                             e.SupplyPrice = storePrice[0].SupplyPrice
    //                             e.Markup = storePrice[0].Markup
    //                             e.MRP = storePrice[0].MRP
    //                             e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
    //                             e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
    //                             e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
    //                             productSearchResult.push(e)
    //                             rowCount = rowCount + 1
    //                         }
    //                         continue
    //                     }
    //                 }
    //                 if (e.hasOwnProperty('StoreSpecificPricing')) {
    //                     if (e.StoreSpecificPricing) {
    //                         let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

    //                         if (storePrice.length > 0) {
    //                             e.RetailPrice = storePrice[0].RetailPrice
    //                             e.SupplyPrice = storePrice[0].SupplyPrice
    //                             e.Markup = storePrice[0].Markup
    //                             e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
    //                             e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
    //                             e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
    //                             e.MRP = storePrice[0].MRP
    //                         }
    //                     }
    //                 }
    //                 rowCount = rowCount + 1
    //                 productSearchResult.push(e)
    //             }
    //             this.setState({ productsTypeAheadOptions: productSearchResult })
    //             this.setState({ isProductTypeAheadLoading: false });
    //         })
    // }

    onLineItemPriceChange(index, value)
    {
        let vendorProducts = this.state.vendorProducts
        vendorProducts[index].SupplyPrice = value
        this.setState({vendorProducts:vendorProducts})
    }

    onLineItemDiscountChange(index, value)
    {
        let vendorProducts = this.state.vendorProducts
        vendorProducts[index].Discount = value
        this.setState({vendorProducts:vendorProducts})
    }

    onSelectAll()
    {
        let selected = !this.state.selectAll
        let vendorProducts = this.state.vendorProducts
        vendorProducts.map(m=> m.Selected = selected)
        this.setState({vendorProducts:vendorProducts, selectAll:selected})
    }

    onLineItemSelected(index)
    {
        let vendorProducts = this.state.vendorProducts
        vendorProducts[index].Selected =!vendorProducts[index].Selected
        this.setState({vendorProducts:vendorProducts})
    }

    onAddStoreSpecificPricing()
    {
        let vendorProducts = this.state.vendorProducts
        let selectedProduct= this.state.selectedProduct
        for(let i=0;i<this.state.storeSpecificPrices.length;i++)
        {
            let storeSpecificPrice = this.state.storeSpecificPrices[i]
            if(vendorProducts.filter(f=> f.StoreID === storeSpecificPrice.StoreID && f.ProductID ===selectedProduct.key).length> 0)
            {
                //product alredy exists 
                vendorProducts.map((m,index)=>{
                    if(m.StoreID === storeSpecificPrice.StoreID && m.ProductID ===selectedProduct.key)
                    {
                        m.SupplyPrice = storeSpecificPrice.SupplyPrice
                        m.Discount = storeSpecificPrice.Discount
                    }
                })
            }
            else
            {
                vendorProducts.unshift({ProductID: selectedProduct.key, ProductName:selectedProduct.Name, ProductSKU: selectedProduct.SKU, StoreID:storeSpecificPrice.StoreID, StoreName: storeSpecificPrice.StoreName, SupplyPrice:storeSpecificPrice.SupplyPrice, Discount:storeSpecificPrice.Discount})
            }
        }
        this.setState({vendorProducts:vendorProducts, showStoreSpecificPricingwindow:false})
    }

    render() {
        return <React.Fragment>
            <Page 
                title={this.state.key !=="" ? "Edit Vendor" : "New Vendor"} 
                showSaveButton={true} 
                showCancelButton={true}
                processing={this.state.saving}
                onClick={(action)=>{
                    // console.log('action', action)
                    if(action==="cancel"){
                        // console.log('cancel called')
                        this.onCancelClick();
                    }else if(action==="save"){
                        // console.log('save called')
                        this.onSaveVendor();
                    }
                }}
                >
                        <PageSection title="General Details">
                            <div className="row">
                                <div className="col-md-3">
                                    <Input 
                                    type="text" 
                                    label="Vendor Code"
                                    onChange={(value) => this.setState({ vendorCode: value, vendorCodeError: "" })}
                                    value={this.state.vendorCode}
                                    error={this.state.vendorCodeError}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Input 
                                    type="text" 
                                    label="Vendor Name"
                                    onChange={(value) => this.setState({ vendorFirstName: value, vendorFirstNameError: "" })}
                                    value={this.state.vendorFirstName}
                                    error={this.state.vendorFirstNameError}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <Input 
                                    type="text" 
                                    label="Contact Person Name"
                                    onChange={(value) => this.setState({ vendorLastName: value})}
                                    value={this.state.vendorLastName}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Input 
                                    type="mobileno" 
                                    label="Mobile No"
                                    onChange={(value) => this.setState({ phoneNo: value})}
                                    value={this.state.phoneNo}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <Input 
                                    type="emailid" 
                                    label="Email"
                                    onChange={(value) => this.setState({ emailID: value})}
                                    value={this.state.emailID}
                                    />
                                </div>
                            </div>
                        </PageSection>
                        <PageSection title="Address">
                            <div className="row">
                                <div className="col-md-4">
                                    <Input 
                                    type="text" 
                                    label="Line Address 1"
                                    onChange={(value) => this.setState({ address1: value})}
                                    value={this.state.address1}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                    type="text" 
                                    label="Line Address 2"
                                    onChange={(value) => this.setState({ address2: value})}
                                    value={this.state.address2}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                    type="text" 
                                    label="City"
                                    onChange={(value) => this.setState({ city: value})}
                                    value={this.state.city}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input 
                                    type="select" 
                                    label="State"
                                    onChange={(value) => this.setState({ stateCode: value})}
                                    value={this.state.stateCode}
                                    >
                                        <option value="">Select</option>
                                        {this.state.states.map(m => <option value={m.code}>{m.name}</option>)}
                                    </Input>
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                    type="text" 
                                    label="Postal Code"
                                    onChange={(value) => this.setState({ postalCode: value})}
                                    value={this.state.postalCode}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                    type="text" 
                                    label="Country"
                                    onChange={(value) => this.setState({ country: value})}
                                    value={this.state.country}
                                    />
                                </div>
                            </div>
                        </PageSection>
                    
                    <PageSection title="Product(s)">
                            <div className="row">
                                <div className="col-md-6 ">
                                    <Input 
                                        {...this.props}
                                        type="productsearch"
                                        showImportButton={true}
                                        processing={this.state.isProductTypeAheadLoading}
                                        onSearch={(searchText)=>{
                                            this.onProductTypeAheadSearch(searchText)
                                        }}
                                        onScan={(searchText)=>this.onProductBarCodeSearch(searchText)}
                                        options={this.state.productsTypeAheadOptions}
                                        onChange={(value) => {
                                            this.onProductSelected(value)
                                        }}
                                        onClick={(action)=>{
                                            if(action==="import"){
                                                this.setState({ showImport: true })
                                            }
                                        }}
                                    />
                                        {/* <Input 
                                        {...this.props}
                                        type="productsearch"
                                        showImportButton={true}
                                        onChange={(value) => {
                                            this.onProductSelected(value)
                                            this.setState({ productsTypeAheadSelected: [] })
                                        }}
                                        onClick={(action)=>{
                                            if(action==="import"){
                                                this.setState({ showImport: true })
                                            }
                                        }}
                                        /> */}
                                </div> 
                                {(this.state.storeList.filter(f => f.Selected).length > 1) && <div className="col-md-6">
                                    <Input 
                                    type="checkbox" 
                                    label="Store specific supply price"
                                    onChange={(value) => this.setState({ storeSpecificVendorPricing: value})}
                                    value={this.state.storeSpecificVendorPricing}
                                    />
                                </div>}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {/* <th style={{ width: "50px" }}>
                                                    <Input 
                                                    type="checkbox" 
                                                    noMargin={true}
                                                    onChange={this.onSelectAll}
                                                    value={this.state.selectAll}
                                                    />
                                                </th>
                                                <th>Store</th> */}
                                                <th>Product</th>
                                                <th style={{ width: "150px" }}>Supply Price</th>
                                                {/* <th style={{ width: "150px" }}>Discount</th> */}
                                                <th style={{ width: "50px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.vendorProducts.map((m, index) => <tr key={index}>
                                                {/* <td>
                                                    <Input 
                                                        type="checkbox"
                                                        noMargin={true}
                                                        value={m.Selected}
                                                        onChange={() => this.onLineItemSelected(index)} />
                                                </td>
                                                <td>{m.StoreName === "" ? "All" : m.StoreName}</td> */}
                                                <td>
                                                    <div>SKU: {m.ProductSKU}</div>
                                                    <div>Name: {m.ProductName}</div>
                                                </td>
                                                <td>
                                                    <Input type="number"
                                                        onChange={(value) => this.onLineItemPriceChange(index, value)}
                                                        value={m.SupplyPrice} />
                                                </td>
                                                {/* <td>
                                                    <Input type="number"
                                                        onChange={(value) => this.onLineItemDiscountChange(index, value)}
                                                        value={m.Discount} />
                                                </td> */}
                                                <td style={{ textAlign: "center" }}>
                                                    <a onClick={() => this.onRemoveLineItem(index)}><i className="fa fa-lg fa-trash-o"></i></a>
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </PageSection>
                        <PageSection title="Store(s)">
                            <div className="row">
                                <div className="col-md-6">
                                    {this.state.storeList.map((m, index) => <div key={index}> 
                                        <Input 
                                        type="switch"
                                        label={m.StoreName}
                                        onChange={(value) => { 
                                            let storeList = this.state.storeList;
                                            storeList[index].Selected = value;
                                            this.setState({ storeList: storeList})
                                        }}
                                        value={m.Selected}
                                        />
                                    </div>)}
                                </div>
                            </div>
                        </PageSection>
                        <PageSection title="Additional Information">
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                    type="text"
                                    label={this.state.customField1DisplayName}
                                    onChange={(value) => this.setState({ customField1:value })}
                                    value={this.state.customField1}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                        type="text" 
                                        label={this.state.customField2DisplayName}
                                        onChange={(value) => this.setState({ customField2: value })}
                                        value={this.state.customField2}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                        type="text" 
                                        label={this.state.customField3DisplayName}
                                        onChange={(value) => this.setState({ customField3: value })}
                                        value={this.state.customField3}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Input 
                                        type="text" 
                                        label={this.state.customField4DisplayName}
                                        onChange={(value) => this.setState({ customField4: value })}
                                        value={this.state.customField4}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                        type="text" 
                                        label={this.state.customField5DisplayName}
                                        onChange={(value) => this.setState({ customField5: value })}
                                        value={this.state.customField5}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input 
                                        type="text" 
                                        label={this.state.customField6DisplayName}
                                        onChange={(value) => this.setState({ customField6: value })}
                                        value={this.state.customField6}
                                    />
                                </div>
                            </div>
                    </PageSection>
                </Page>          
                <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                    <div style={{ width: "500px" }}>
                        <ImportMaster {...this.props}
                            importType="vendorproducts"
                            inventoryInWardOrOutward=""
                            onClose={() => this.setState({ showImport: false })}
                            onImportCompletion={(records) =>
                                this.onImportCompletion(records)} />
                    </div>
                </Modal>
        </React.Fragment>
    }
}

export default withRouter(AddEditVendor)