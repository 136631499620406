import { combineReducers } from 'redux';
import userReducer from './userReducer';
import PostReducer from './PostReducer'
import registrationReducer from './registrationReducer'
import fileUploadReducer from './fileUploadReducer';
// import productReducer from './ProductReducer'
import salesReducer from './SalesReducer'
import configReducer from './ConfigReducer'

const rootReducer = combineReducers({
    user: userReducer,
    post:PostReducer,
    registration:registrationReducer,
    fileUpload:fileUploadReducer,
    // product: productReducer,
    sales: salesReducer,
    config:configReducer
});

export default rootReducer;
