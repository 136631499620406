import React,{Component} from 'react';
import Page from '../2/Controls/Page';
import PageSection from '../2/Controls/PageSection';
import Input from '../2/Controls/Input';

class OmniProductDetailsPage extends Component
{
    constructor()
    {
        super();
        this.state={
            showProductSKU:false,
            showProductBarCode:false,
            showProductDescription:false,
            showProductAvailbleQty:false,
            showProductMRP:false,
            showProductCustomField1:false,
            showProductCustomField2:false,
            showProductCustomField3:false,
            showProductCustomField4:false,
            saving:false,
            configFound:true
        }
        this.onSave= this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.loadData= this.loadData.bind(this);
    }

    async componentDidMount() {
       this.loadData();
    }

    async loadData(){
        let checkoutConfig = await this.props.getOmniSettings();
        if(checkoutConfig){

            let showProductSKU= false
            let showProductBarCode= false
            let showProductDescription=false
            let showProductAvailbleQty=false
            let showProductMRP=false
            let showProductCustomField1=false
            let showProductCustomField2=false
            let showProductCustomField3=false
            let showProductCustomField4=false

            if(checkoutConfig.hasOwnProperty('ProductDetailPageOptions'))
            {
                let productDetailPageOptions = checkoutConfig.ProductDetailPageOptions
                showProductSKU = productDetailPageOptions.hasOwnProperty('ShowProductSKU') ? productDetailPageOptions.ShowProductSKU : false
                showProductBarCode = productDetailPageOptions.hasOwnProperty('ShowProductBarCode') ? productDetailPageOptions.ShowProductBarCode : false
                showProductDescription = productDetailPageOptions.hasOwnProperty('ShowProductDescription') ? productDetailPageOptions.ShowProductDescription : false
                showProductAvailbleQty = productDetailPageOptions.hasOwnProperty('ShowProductAvailbleQty') ? productDetailPageOptions.ShowProductAvailbleQty : false
                showProductMRP = productDetailPageOptions.hasOwnProperty('ShowProductMRP') ? productDetailPageOptions.ShowProductMRP : false
                showProductCustomField1 = productDetailPageOptions.hasOwnProperty('ShowProductCustomField1') ? productDetailPageOptions.ShowProductCustomField1 : false
                showProductCustomField2 = productDetailPageOptions.hasOwnProperty('ShowProductCustomField2') ? productDetailPageOptions.ShowProductCustomField2 : false
                showProductCustomField3 = productDetailPageOptions.hasOwnProperty('ShowProductCustomField3') ? productDetailPageOptions.ShowProductCustomField3 : false
                showProductCustomField4 = productDetailPageOptions.hasOwnProperty('ShowProductCustomField4') ? productDetailPageOptions.ShowProductCustomField4 : false
            }
            this.setState({
                showProductSKU: showProductSKU,
                showProductBarCode:showProductBarCode,
                showProductDescription: showProductDescription,
                showProductMRP:showProductMRP,
                showProductAvailbleQty: showProductAvailbleQty,
                showProductCustomField1:showProductCustomField1,
                showProductCustomField2:showProductCustomField2,
                showProductCustomField3:showProductCustomField3,
                showProductCustomField4:showProductCustomField4
            })

        }
    }

    async onSave(){
        let checkoutConfig = await this.props.getOmniSettings();
        if(!checkoutConfig){
            checkoutConfig = {}
        }
        checkoutConfig.ProductDetailPageOptions = {
            ShowProductSKU: this.state.showProductSKU,
            ShowProductBarCode:this.state.showProductBarCode,
            ShowProductDescription: this.state.showProductDescription,
            ShowProductMRP:this.state.showProductMRP,
            ShowProductAvailbleQty: this.state.showProductAvailbleQty,
            ShowProductCustomField1:this.state.showProductCustomField1,
            ShowProductCustomField2:this.state.showProductCustomField2,
            ShowProductCustomField3:this.state.showProductCustomField3,
            ShowProductCustomField4:this.state.showProductCustomField4,
        };

        await this.props.saveOmniSettings(checkoutConfig)
        this.props.getToastr("Data saved successfully")
    }

    onCancel(){
        ///reload data
        this.loadData();
    }

    render() {
        return <Page 
            title="Online Store - Product Detail"
            showSaveButton={true} 
            showCancelButton={true}
            processing={this.state.saving}
            onClick={(action)=>{
                if(action==="cancel"){
                    this.onCancel();
                }else if(action==="save"){
                    this.onSave();
                }
            }}
        > 
            <PageSection title="Product information to be dispayed on product details page">
                <Input
                    type="switch"
                    label="SKU"
                    value={this.state.showProductSKU}
                    onChange={(value) => { this.setState({ showProductSKU: value }) }}
                />
                <Input
                    type="switch"
                    label="Barcode"
                    value={this.state.showProductBarCode}
                    onChange={(value) => { this.setState({ showProductBarCode: value }) }}
                />
                <Input
                    type="switch"
                    label="Description"
                    value={this.state.showProductDescription}
                    onChange={(value) => { this.setState({ showProductDescription: value }) }}
                />
                <Input
                    type="switch"
                    label="MRP"
                    value={this.state.showProductMRP}
                    onChange={(value) => { this.setState({ showProductMRP: value }) }}
                />
                <Input
                    type="switch"
                    label="Custom field 1"
                    value={this.state.showProductCustomField1}
                    onChange={(value) => { this.setState({ showProductCustomField1: value }) }}
                />
                <Input
                    type="switch"
                    label="Custom field 2"
                    value={this.state.showProductCustomField2}
                    onChange={(value) => { this.setState({ showProductCustomField2: value }) }}
                />
                <Input
                    type="switch"
                    label="Custom field 3"
                    value={this.state.showProductCustomField3}
                    onChange={(value) => { this.setState({ showProductCustomField3: value }) }}
                />
                <Input
                    type="switch"
                    label="Custom field 4"
                    value={this.state.showProductCustomField4}
                    onChange={(value) => { this.setState({ showProductCustomField4: value }) }}
                />
                <Input
                    type="switch"
                    label="Show available qty"
                    value={this.state.showProductAvailbleQty}
                    onChange={(value) => { this.setState({ showProductAvailbleQty: value }) }}
                />
            </PageSection>
        </Page>}
}

export default OmniProductDetailsPage