import React, { Component } from 'react';
import Loader from '../Loader'

class FoodDeliveryPlatformProducts extends Component {
    constructor() {
        super()
        this.state = {
            foodDeliveryPlatforms:[],
            products:[],
            loading:true
        }
        this.onSelectionChange= this.onSelectionChange.bind(this)
        this.onRetailPriceChange = this.onRetailPriceChange.bind(this)
        this.onFoodDeliveryPlatformSelectionChanged = this.onFoodDeliveryPlatformSelectionChanged.bind(this)
        this.onSaveClicked= this.onSaveClicked.bind(this)
    }

    async componentDidMount() 
    {
        //get store specific providers
        let foodDeliveryPlatforms  = this.props.user.store.FoodDeliveryPlatforms
        foodDeliveryPlatforms.map(m=> m.Selected=false)
        let foodDeliveryPlatformProducts = await this.props.getFoodDeliveryPlatformProducts(this.props.user.store.key)
        let productsTemp = this.props.config.Products.filter(f=> !f.IsDeleted && (f.ProductTypeID ===1 || f.ProductTypeID===3))
        let products = []
        productsTemp.map(m=>{

            let product= {ProductID: m.key, 
                Name:m.Name, SKU:m.SKU, 
                Catgory:m.Category, 
                SubCategory:m.SubCategory, 
                RetailPrice:m.RetailPrice, 
                FoodDeliveryPlatformID:"", 
                FoodDeliveryPlatforms:[]}
                foodDeliveryPlatforms.map(m=> product.FoodDeliveryPlatforms.push({Selected: false, Code: m.Code, Name: m.Name}))  

            
            if(foodDeliveryPlatformProducts.filter(g=> g.ProductID === product.ProductID).length > 0)
            {
                let result = foodDeliveryPlatformProducts.filter(g=> g.ProductID === product.ProductID)[0]
                console.log('result', result)
                product.FoodDeliveryPlatformID = result.key
                product.RetailPrice = result.RetailPrice
                product.FoodDeliveryPlatforms.map(m=> {
                    if(result.FoodDeliveryPlatforms.filter(f=> f.Code ===  m.Code).length > 0 && !result.IsDeleted)
                    {
                        m.Selected = true 
                    }
                })
            }
            products.push(product)

            // foodDeliveryPlatforms.map(f=>{
            //     let foodDeliveryPlatformID = ""
            //     let selected= false
            //     let retailPrice = m.RetailPrice
            //     if(result.length> 0)
            //     {
            //         product.FoodDeliveryPlatformID = foodDeliveryPlatformID
            //         foodDeliveryPlatformID = result[0].key
            //         retailPrice = result[0].RetailPrice
            //         selected = !result[0].IsDeleted
            //     }
                
            //     product = result[0].RetailPrice
            //     product.FoodDeliveryPlatforms.push({
            //     // FoodDeliveryPlatformID:foodDeliveryPlatformID,
            //     FoodDeliveryPlatformName: f.Name,
            //     FoodDeliveryPlatformCode: f.Code,
            //     Selected:selected,
            //     // RetailPrice:retailPrice,
            //     Changed:false
            //     })
            // })
           
        })
        this.setState({foodDeliveryPlatforms:foodDeliveryPlatforms, products:products, loading:false})
        console.log('products',products)
        console.log('foodDeliveryPlatforms', foodDeliveryPlatforms)
    }

    onFoodDeliveryPlatformSelectionChanged(selected, foodDeliveryIndex)
    {
        let products = this.state.products
        let foodDeliveryPlatforms = this.state.foodDeliveryPlatforms
        products.map(m=> m.FoodDeliveryPlatforms[foodDeliveryIndex].Selected = selected)
        foodDeliveryPlatforms[foodDeliveryIndex].Selected = selected
        this.setState({products:products})
    }

    onSelectionChange(index, foodDeliveryIndex)
    {
        let products = this.state.products
        products[index].FoodDeliveryPlatforms[foodDeliveryIndex].Selected = !products[index].FoodDeliveryPlatforms[foodDeliveryIndex].Selected
        this.setState({products:products})
    }

    onRetailPriceChange(index, retailPrice)
    {
        let products = this.state.products
        products[index].RetailPrice = retailPrice
        this.setState({products:products})
    }

    async onSaveClicked()
    {
        let products = this.state.products
        let dataToBePushedToFoodDeliveryPlatforms = false
        for(let i=0;i<products.length;i++)
        {
            let product = products[i]
            if(product.FoodDeliveryPlatforms.filter(f=> f.Selected).length > 0 && product.FoodDeliveryPlatformID ==="")
            {
                dataToBePushedToFoodDeliveryPlatforms= true 
                await this.props.addProductsFoodDeliveryPlatforms({
                    ProductID: product.ProductID, 
                    RegistrationID: this.props.user.user.RegistrationID,
                    StoreID: this.props.user.store.key, 
                    RetailPrice:product.RetailPrice,
                    FoodDeliveryPlatforms: product.FoodDeliveryPlatforms.filter(f=> f.Selected),
                    PushedToFoodDeliveryPlatform:false,
                    IsDeleted:false
                    })
            }
            else if (product.FoodDeliveryPlatforms.filter(f=> f.Selected).length > 0 && product.FoodDeliveryPlatformID !=="")
            {
                    //update
                    dataToBePushedToFoodDeliveryPlatforms= true 
                    await this.props.updateProductsFoodDeliveryPlatforms(
                        product.FoodDeliveryPlatformID, 
                        {RetailPrice: product.RetailPrice,
                        FoodDeliveryPlatforms: product.FoodDeliveryPlatforms.filter(f=> f.Selected),
                        PushedToFoodDeliveryPlatform:false,
                        IsDeleted:false
                        })

            }
            else if (product.FoodDeliveryPlatforms.filter(f=> f.Selected).length === 0 && product.FoodDeliveryPlatformID !=="")
            {
                    //delete
                    dataToBePushedToFoodDeliveryPlatforms= true 
                    await this.props.updateProductsFoodDeliveryPlatforms(
                        product.FoodDeliveryPlatformID, 
                        {PushedToFoodDeliveryPlatform:false,
                        IsDeleted:true
                        })
            }

            // for(let l=0; l< product.FoodDeliveryPlatforms.length ; l++)
            // {
            //     if(product.FoodDeliveryPlatforms[l].Selected && product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformID ==="")
            //     {
            //         //add new 
            //         dataToBePushedToFoodDeliveryPlatforms= true 
            //         // await this.props.addProductsFoodDeliveryPlatforms({ProductID: product.ProductID, 
            //         //     RegistrationID: this.props.user.user.RegistrationID,
            //         //     StoreID: this.props.user.store.key, 
            //         //     FoodDeliveryPlatformCode: product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformCode,
            //         //     FoodDeliveryPlatformName:product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformName,
            //         //     RetailPrice: product.FoodDeliveryPlatforms[l].RetailPrice,
            //         //     PushedToFoodDeliveryPlatform:false,
            //         //     IsDeleted:false
            //         //     })

            //         await this.props.addProductsFoodDeliveryPlatforms({
            //             ProductID: product.ProductID, 
            //             RegistrationID: this.props.user.user.RegistrationID,
            //             StoreID: this.props.user.store.key, 
            //             RetailPrice:product.RetailPrice,
            //             FoodDeliveryPlatforms: product.FoodDeliveryPlatforms,
            //             PushedToFoodDeliveryPlatform:false,
            //             IsDeleted:false
            //             })
            //     }
            //     else if(!product.FoodDeliveryPlatforms[l].Selected && product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformID !=="")
            //     {
            //         //to be deleted
            //         dataToBePushedToFoodDeliveryPlatforms= true 
            //         await this.props.updateProductsFoodDeliveryPlatforms(
            //             product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformID, 
            //             {PushedToFoodDeliveryPlatform:false,
            //             IsDeleted:true
            //             })
            //     }
            //     else if(product.FoodDeliveryPlatforms[l].Selected && product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformID !=="")
            //     {
            //         //to be deleted
            //         dataToBePushedToFoodDeliveryPlatforms= true 
            //         await this.props.updateProductsFoodDeliveryPlatforms(product.FoodDeliveryPlatforms[l].FoodDeliveryPlatformID, 
            //             {RetailPrice: product.FoodDeliveryPlatforms[l].RetailPrice,
            //             PushedToFoodDeliveryPlatform:false,
            //             IsDeleted:false
            //             })
            //     }
            // }    
        }
        if(dataToBePushedToFoodDeliveryPlatforms)
        {
            this.props.addToFoodDeliveryPlatformUpdateQueue({ StoreID: this.props.user.store.key})
            this.props.getToastr("Data Saved Successfully")     
        }
    }
    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Products</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <button type="button" className="btn btn-success btn-flat btn-md" onClick={() => this.onSaveClicked()}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th style={{width:"120px" }}>Retail Price</th>
                                                {this.state.foodDeliveryPlatforms.map((m,index)=><th style={{width:"120px" }}>
                                                    <input type="checkbox" checked={m.Selected} 
                                                    onClick={()=>this.onFoodDeliveryPlatformSelectionChanged(!m.Selected, index)} style={{width:"20px"}}/>
                                                    {m.Name}
                                                </th>)}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((product, index) =>
                                                <tr>
                                                    <td>
                                                        <div><b>{product.Name}</b></div>
                                                        <div>{product.SKU}</div>
                                                        <div>{product.Category}</div>
                                                        <div>{product.SubCategory}</div>
                                                    </td>
                                                    <td>
                                                     <input type="number" 
                                                            className="form-control" 
                                                            value={product.RetailPrice} 
                                                            onChange={(evt)=>this.onRetailPriceChange(index, evt.target.value)}
                                                           />
                                                    </td>
                                                    {product.FoodDeliveryPlatforms.map((f,foodDeliveryIndex)=><td>{
                                                            <input type="checkbox" checked={f.Selected} onChange={()=>this.onSelectionChange(index, foodDeliveryIndex)}/>
                                                    }</td>)}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default FoodDeliveryPlatformProducts