import React, { Component } from 'react';
import Switch from "react-switch";
import HeaderNew from '../HeaderNew'
import Footer from '../Common/Footer'
// import ReactTooltip from 'react-tooltip'
import Dropzone from 'react-dropzone'

class CustomerDisplaySettings extends Component {
    constructor() {
        super();
        this.state = {
            enableCustomerDisplay: false,
            showProductSKU: true,
            showProductBarCode: false,
            showProductName: false,
            showProductQty: true,
            showProductMRP: false,
            showProductRetailPrice: true,
            showProductCustomField1: false,
            showProductCustomField2: false,
            showProductCustomField3: false,
            showProductCustomField4: false,
            showProductCustomField5: false,
            showProductCustomField6: false,
            saving: false,
            banners: [],
        }
        this.formControls = this.formControls.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onImageRemove = this.onImageRemove.bind(this)
        this.onImageDrop = this.onImageDrop.bind(this)
    }

    async componentDidMount() {

        let customerDisplaySettings = this.props.user.store.hasOwnProperty('CustomerDisplaySettings') ? this.props.user.store.CustomerDisplaySettings : null ;
        let enableCustomerDisplay = (this.props.user.store.hasOwnProperty('EnableCustomerDisplay') && this.props.user.store.EnableCustomerDisplay) ? true : false

        let showProductSKU = true
        let showProductBarCode = false
        let showProductName = true
        let showProductQty = true
        let showProductMRP = false
        let showProductRetailPrice = true
        let showProductCustomField1 = false
        let showProductCustomField2 = false
        let showProductCustomField3 = false
        let showProductCustomField4 = false
        let showProductCustomField5 = false
        let showProductCustomField6 = false
        let banners = [];

        if (customerDisplaySettings) {
            showProductSKU = customerDisplaySettings.ShowProductSKU
            showProductBarCode = customerDisplaySettings.ShowProductBarCode
            showProductName = customerDisplaySettings.ShowProductName
            showProductQty = customerDisplaySettings.ShowProductQty
            showProductMRP = customerDisplaySettings.ShowProductMRP
            showProductRetailPrice = customerDisplaySettings.ShowProductRetailPrice
            showProductCustomField1 = customerDisplaySettings.ShowProductCustomField1
            showProductCustomField2 = customerDisplaySettings.ShowProductCustomField2
            showProductCustomField3 = customerDisplaySettings.ShowProductCustomField3
            showProductCustomField4 = customerDisplaySettings.ShowProductCustomField4
            showProductCustomField5 = customerDisplaySettings.ShowProductCustomField5
            showProductCustomField6 = customerDisplaySettings.ShowProductCustomField6
            banners = customerDisplaySettings.Banners ? customerDisplaySettings.Banners : []
        }

        this.setState({
            showProductSKU: showProductSKU,
            showProductBarCode: showProductBarCode,
            showProductName: showProductName,
            showProductMRP: showProductMRP,
            showProductRetailPrice: showProductRetailPrice,
            showProductQty: showProductQty,
            showProductCustomField1: showProductCustomField1,
            showProductCustomField2: showProductCustomField2,
            showProductCustomField3: showProductCustomField3,
            showProductCustomField4: showProductCustomField4,
            showProductCustomField5: showProductCustomField5,
            showProductCustomField6: showProductCustomField6,
            banners: banners,
            enableCustomerDisplay: enableCustomerDisplay
        })
    }

    onSave() {

        let store = this.props.user.store;

        let customerDisplaySettings = {
            ShowProductSKU: this.state.showProductSKU,
            ShowProductBarCode: this.state.showProductBarCode,
            ShowProductName: this.state.showProductName,
            ShowProductMRP: this.state.showProductMRP,
            ShowProductRetailPrice: this.state.showProductRetailPrice,
            ShowProductQty: this.state.showProductQty,
            ShowProductCustomField1: this.state.showProductCustomField1,
            ShowProductCustomField2: this.state.showProductCustomField2,
            ShowProductCustomField3: this.state.showProductCustomField3,
            ShowProductCustomField4: this.state.showProductCustomField4,
            ShowProductCustomField5: this.state.showProductCustomField5,
            ShowProductCustomField6: this.state.showProductCustomField6,
            Banners: this.state.banners,
        };

        store.CustomerDisplaySettings = customerDisplaySettings;
        store.EnableCustomerDisplay = this.state.enableCustomerDisplay

        this.props.updateStore(store, store.key).then(() => {
            this.props.getToastr("Customer Display Settings Saved Successfully")
        });

    }

    onCancel() {

    }

    async onImageDrop(acceptedFiles, rejectedFiles) {
        let banners = this.state.banners;
        if (acceptedFiles.length > 0) {
            for (let index = 0; index < acceptedFiles.length; index++) {
                const file = acceptedFiles[index];
                await this.props.uploadFileToServer(file, this.props.registration.registration.StorageFolder + '/CustomerDisplayBanners');
                let bucket = 'posible.in/'
                let folder = this.props.registration.registration.StorageFolder + '/CustomerDisplayBanners/'
                let url = 'https://storage.googleapis.com/' + bucket + folder + file.name
                let image = { Name: file.name, Size: file.size, Preview: url }
                banners.push(image);
            }
            console.log('banners', JSON.stringify(banners));
            this.setState({ banners: banners })
        }
        else { alert("No file selected") };
    }

    onImageRemove(index) {
        let banners = [...this.state.banners]
        banners.splice(index, 1);
        this.setState({ banners: banners });
    }
    
    formControls() {
        return <div className={this.props.isMobileApp() ? "box with no-border" : "box"} >
            <div className={this.props.isMobileApp() ? "box-header" : "box-header with-border"}>
                <h3 className="box-title">Customer Display Settings</h3>
            </div>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <table>
                                <tr>
                                    <td>
                                        <Switch
                                            checked={this.state.enableCustomerDisplay}
                                            onChange={() => { this.setState({ enableCustomerDisplay: !this.state.enableCustomerDisplay }) }}
                                        />
                                    </td>
                                    <td>
                                        &nbsp;Enable Customer Display
                                </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                {this.state.enableCustomerDisplay && <React.Fragment>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductSKU}
                                                    onChange={() => { this.setState({ showProductSKU: !this.state.showProductSKU }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;SKU
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductBarCode}
                                                    onChange={() => { this.setState({ showProductBarCode: !this.state.showProductBarCode }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Barcode
                                </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductName}
                                                    onChange={() => { this.setState({ showProductName: !this.state.showProductName }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Name
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductMRP}
                                                    onChange={() => { this.setState({ showProductMRP: !this.state.showProductMRP }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;MRP
                                       </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductRetailPrice}
                                                    onChange={() => { this.setState({ showProductRetailPrice: !this.state.showProductRetailPrice }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Retail Price
                                       </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductQty}
                                                    onChange={() => { this.setState({ showProductQty: !this.state.showProductQty }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Qty
                                       </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductCustomField1}
                                                    onChange={() => { this.setState({ showProductCustomField1: !this.state.showProductCustomField1 }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Custom Field 1
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductCustomField2}
                                                    onChange={() => { this.setState({ showProductCustomField2: !this.state.showProductCustomField2 }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Custom Field 2
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductCustomField3}
                                                    onChange={() => { this.setState({ showProductCustomField3: !this.state.showProductCustomField3 }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Custom Field 3
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductCustomField4}
                                                    onChange={() => { this.setState({ showProductCustomField4: !this.state.showProductCustomField4 }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Custom Field 4
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductCustomField5}
                                                    onChange={() => { this.setState({ showProductCustomField5: !this.state.showProductCustomField5 }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Custom Field 5
                                        </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Switch
                                                    checked={this.state.showProductCustomField6}
                                                    onChange={() => { this.setState({ showProductCustomField6: !this.state.showProductCustomField6 }) }}
                                                />
                                            </td>
                                            <td>
                                                &nbsp;Custom Field 6
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-xs-12">
                            <Dropzone onDrop={(files) => this.onImageDrop(files)}>
                                <div>Try dropping some files here, or click to select files to upload.</div>
                            </Dropzone>
                        </div>
                        <div className="col-md-3 col-xs-12">
                            <ul className="mailbox-attachments clearfix">
                                {this.state.banners.map((image, index) =>
                                    <li>
                                        {/* {image.ImagePath} */}
                                        <span className="mailbox-attachment-icon has-img"><img src={image.Preview} alt="Attachment" /></span>
                                        <div className="mailbox-attachment-info">
                                            <span className="mailbox-attachment-name">{image.Name}</span>
                                            <span className="mailbox-attachment-size">
                                                {Math.round(image.Size / 1024)} KB
                                                        <a onClick={() => this.onImageRemove(index)} className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </React.Fragment>}
            </div>
        </div>
    }

    render() {
        return <React.Fragment>
            {!this.props.isMobileApp() && <React.Fragment>
                <div className="content-wrapper" >
                    <section className="content-header">
                        <h1>Customer Display Settings</h1>
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                            style={{ marginTop: "-25px", marginLeft: "10px" }}
                            onClick={this.onSave} disabled={this.state.saving}>Save
                        </button>
                        {/* <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginTop: "-25px" }}
                            onClick={this.onCancel} >Cancel
                        </button> */}
                    </section>
                    <section className="content">
                        {this.formControls()}
                        <div>
                            <button type="submit"
                                style={{ marginBottom: "10px", marginLeft: "10px" }}
                                className="btn btn-md btn-success btn-flat pull-right"
                                onClick={this.onSave} disabled={this.state.saving}>Save
                            </button>
                            {/* <button style={{ marginBottom: "10px" }}
                                onClick={this.onCancel}
                                className="btn btn-md btn-default btn-flat  pull-right">Cancel
                            </button> */}
                        </div>
                    </section>
                </div>
            </React.Fragment>}

            {this.props.isMobileApp() && <React.Fragment>
                <HeaderNew {...this.props}
                    back={true}
                    title="Customer Display Settings"
                    onBackClick={this.onCancel}
                />
                <div className="header-new-background" />
                <div className="content-wrapper">
                    <div className="container">
                        <br />
                        {this.formControls()}
                        <br />
                        <br />
                        <Footer
                            disabled={this.state.saving}
                            onClick={() => this.onSave()}
                        />
                    </div>
                </div>
            </React.Fragment>}
        </React.Fragment>
    }
}

export default CustomerDisplaySettings