import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import Print from '../Sales/Print'

class EmployeeWorkSheet extends Component {
    constructor() {
        super()
        this.state = {
            employeeWorksheet: {},
            loading: true,
            print:false,
            printData:null
        }
        this.loadEmployeeWorksheet = this.loadEmployeeWorksheet.bind(this);
    }

    componentDidMount() {
        this.loadEmployeeWorksheet()
    }

    loadEmployeeWorksheet() {
        this.setState({ loading: true })
        let employeeWorksheet = {
            EmployeeName: this.props.location.state.name,
            Store: this.props.user.store.StoreName,
            TransactionFromDate: this.props.location.state.searchTransactionFromDate,
            TransactionToDate: this.props.location.state.searchTransactionToDate,
            TransactionType: this.props.location.state.searchTransactionTypeName
        }
        
        this.props.getEmployeeWorksheet(this.props.location.state.key,
            this.props.location.state.searchTransactionFromDate, this.props.location.state.searchTransactionToDate,this.props.location.state.searchTransactionType
        ).then((result) => {
            employeeWorksheet.products = result.searchResult;
            this.setState({ employeeWorksheet: employeeWorksheet, totalItemsCount: result.totalItemsCount, loading: false })
        })
    }

    render() {
        return <React.Fragment>
        {!this.state.print && <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6 col-xs-9">
                                        <font style={{ fontSize: "25px" }}>
                                            Employee Name : {this.props.location.state.name}<br></br>
                                            Store Name: {this.props.user.store.StoreName}<br></br>
                                            Transaction Type : {this.props.location.state.searchTransactionTypeName}<br></br>
                                            Date : {this.props.location.state.searchTransactionFromDate} - To: {this.props.location.state.searchTransactionToDate} <br></br>
                                        </font>
                                    </div>
                                    <div className="col-md-6 col-xs-3">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/employeedashboard" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                         <button type="submit" className="btn btn-default pull-right btn-flat"
                                            onClick={()=>{
                                                let printConfig = {}
                                                printConfig.PrintSize ="3 inch" 
                                                printConfig.PrintTemplate  = this.props.registration.registration.hasOwnProperty("EmployeeWorkSheetTemplate") ? this.props.registration.registration.EmployeeWorkSheetTemplate :""
                                                let printData = {employeeWorksheet:this.state.employeeWorksheet,PrintConfig:printConfig}
                                                // console.log('printData',printData)
                                                this.setState({print:true,printData:printData })
                                            }}
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                        ><i className="fa fa-print"></i> Print</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && <div style={{ width: "auto", overflowX: "auto" }} className="scroll2">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Product Name</th>
                                                <th>Total Amount</th>
                                                <th>Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.employeeWorksheet.products.map((product) =>
                                                <tr>
                                                    <td>{product.SKU}</td>
                                                    <td>{product.ProductName}</td>
                                                    <td>{product.Amount}</td>
                                                    <td>{product.Qty}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>}
           {this.state.print && <React.Fragment>
            <Print  {...this.props}   
                printChecked = {true}
                sendEmailChecked={false}
                sendSMSChecked = {false}
                printData = {this.state.printData}   
                onClose={()=> {
                this.setState({print:false})
                }}/>
        </React.Fragment>}
    </React.Fragment>
    }
}
export default withRouter(EmployeeWorkSheet)